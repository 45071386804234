<section id="productReviewsListSec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 my-auto">
                <div class="card m-2">
                    <div class="card-body">
                        <!-- product-reviews-list-heading-row start -->
                        <div class="row product-reviews-list-heading-row">
                            <div class="col-xxl-6 col-xl-6 col-lg-4 col-md-4 col-sm-12 col-xs-12 my-auto">
                                <div class="text-center text-md-left mt-2 mt-md-0">
                                    <h2>Product Reviews</h2>
                                    <p class="mb-2 mb-md-0">{{reviewsList.length}} Results Found</p>
                                </div>
                            </div>
                            <div class="col-xxl-6 col-xl-6 col-lg-8 col-md-8 col-sm-12 col-xs-12 my-auto">
                                <div
                                    class="has-search-d-flex justify-content-center justify-content-md-end align-items-start align-items-md-center flex-nowrap flex-md-wrap">
                                    <app-common-search (onEnter)="search($event)"
                                        class="mb-2 mb-md-0"></app-common-search>
                                    <div *ngIf="!filterApplied">
                                        <button type="button" class="btn filter-btn d-none d-md-block" (click)="
                                                showFilterModalDialog('right')" *ngIf="reviewsList?.length>10">
                                            <span class="material-symbols-outlined me-1">
                                                filter_alt
                                            </span>
                                            Filter
                                        </button>
                                        <button type="button" class="btn filter-btn d-block d-md-none" (click)="
                                                                                    showFilterModalDialog('right')"
                                            *ngIf="reviewsList?.length>10">
                                            <span class="material-symbols-outlined">
                                                filter_alt
                                            </span>
                                        </button>
                                    </div>
                                    <div *ngIf="filterApplied">
                                        <button type="button" class="btn after-filter-btn me-3 d-none d-md-block"
                                            (click)="showFilterModalDialog('right')">
                                            <i class="fas fa-filter"></i>
                                            Filter
                                        </button>
                                        <button type="button" class="btn after-filter-btn me-3 d-block d-md-none"
                                            (click)="showFilterModalDialog('right')">
                                            <i class="fas fa-filter"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- product-reviews-list-heading-row end -->
                <div class="card mb-1 ms-2 me-2 table-card" *ngFor="let review of reviewsList">
                    <div class="card-body">
                        <!-- product-reviews-list-table-row start -->
                        <div class="row product-reviews-list-table-row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <td>
                                                    <img [src]="review.productImage" />
                                                </td>
                                                <td colspan="2" (click)="redirectToCustomerReviewPage(review)">
                                                    <h6>{{review.productName}}</h6>
                                                    <label>
                                                        <p class="truncate-lines">
                                                            {{review.title}}</p>
                                                    </label>
                                                </td>
                                                <td>
                                                    <i class="fas fa-eye-slash" title="Hidden"
                                                        *ngIf="review?.removeAbusiveComment"></i>
                                                    <button type="button" class="btn" id="blinking-button"
                                                        *ngIf="(review.abusive || isAbusive(review.reviewComment)) && !review?.removeAbusiveComment">Abusive
                                                        content found</button>
                                                </td>
                                                <td class="text-end">
                                                    <div class="dropdown dropstart pe-2">
                                                        <i class="fas fa-ellipsis-v" id="dropdownMenuButton1"
                                                            data-bs-toggle="dropdown" aria-expanded="false"
                                                            data-bs-auto-close="inside"
                                                            data-bs-auto-close="outside"></i>
                                                        <ul class="dropdown-menu dropdown-menu-position"
                                                            aria-labelledby="dropdownMenuButton1">
                                                            <li [ngClass]="{ disable: !access['edit'] }">
                                                                <a class="dropdown-item"
                                                                    (click)="redirectToCustomerReviewPage(review)">
                                                                    <span
                                                                        class="material-symbols-rounded view-icon me-3">
                                                                        visibility </span>View
                                                                </a>
                                                            </li>
                                                            <li [ngClass]="{ disable: !access['delete'] }" (click)="
                                                        showDeleteConfirmationModalDialog(
                                                            review
                                                        )
                                                    ">
                                                                <a class="dropdown-item"><span
                                                                        class="material-symbols-outlined delete-icon"
                                                                        title="Delete">delete
                                                                    </span>Delete</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <label class="customer-name-label">Customer Name</label>
                                                    <h6>{{review.userName}}</h6>
                                                </td>
                                                <td nowrap>
                                                    <label>Email</label>
                                                    <h6>
                                                        {{review.email}}
                                                    </h6>
                                                </td>
                                                <td nowrap>
                                                    <label>Last Modified</label>
                                                    <h6 *ngIf="review.reviewStatus=='Open'">{{ review.createdAt | date:
                                                        'dd/MM/yyyy' : 'UTC' }} {{ review.createdAt| date:'shortTime' }}</h6>
                                                    <h6 *ngIf="review.reviewStatus!='Open'">{{ review.repliedOnDate |
                                                        date:
                                                        'dd/MM/yyyy' : 'UTC' }} {{ review.repliedOnDate| date:'shortTime' }}
                                                    </h6>
                                                </td>
                                                <td nowrap>
                                                    <label>Rating</label>
                                                    <div class="d-flex">
                                                        <p-rating [(ngModel)]="review.overallRating"
                                                            [cancel]="false"></p-rating>
                                                        <p class="mb-0">({{review.overallRating}} Rating)</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colspan="4" class="pb-2">
                                                    <label>Review Remarks</label>
                                                    <h6>
                                                        {{review.reviewComment}}
                                                    </h6>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <!-- product-reviews-list-table-row end -->
                    </div>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3"
                    *ngIf="reviewsList.length==0">
                    <app-no-result-found></app-no-result-found>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- displayFilterModalToggle start -->
<p-dialog [draggable]="false" [(visible)]="displayFilterModalToggle" [position]="position" [modal]="true"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false" [showHeader]="false"
    [style]="{width: '34vw', minHeight: '100%', maxHeight: '100%', margin: '0'}"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}">
    <div id="displayFilterModalToggle">
        <div class="modal-header">
            <h5 class="modal-title">Filters</h5>
            <i class="fas fa-times" (click)="closeFilter()"></i>
        </div>
        <div class="body mt-3" [formGroup]="filterForm">
            <div class="row">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Rating</label>
                    <input type="text" class="form-control" placeholder="Enter" formControlName="rating" />
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Requested on </label>
                    <p-calendar [iconDisplay]="'input'" [showIcon]="true" inputId="icondisplay" selectionMode="range"
                        formControlName="date" placeholder="From-To"></p-calendar>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="d-flex justify-content-end gap-2">
            <p-button label="Cancel" styleClass="filter-cancel-btn" (click)="closeFilterModalDialog()"></p-button>
            <p-button label="Apply" styleClass="filter-apply-btn" (click)="applyFilter()"></p-button>
        </div>
    </ng-template>
</p-dialog>
<!-- displayFilterModalToggle end -->
<!-- deleteModalToggle start -->
<p-dialog [showHeader]="false" [(visible)]="deleteModalToggle" [style]="{width: '25vw'}" [baseZIndex]="10000"
    [modal]="true">
    <div class="body mt-3" id="deleteModalToggle">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="delete-image">
                    <img src="../../../../assets/img/delete.png" />
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="confirm-msg">
                    <h6>Are You Sure?</h6>
                    <p>You will not be able to recover this product review!</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="confirm-btns">
                    <button type="button" class="btn cancel-btn" (click)="deleteModalToggle = false">
                        Cancel
                    </button>
                    <button type="button" class="btn delete-confirm-btn" [disabled]="!access['delete']"
                        (click)="deleteProductReview()">
                        Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</p-dialog>
<!-- {/* // deleteModalToggle end */} -->