<!-- Brand Logo -->
<a [routerLink]="['/']" class="brand-link">
    <pf-image [src]="secondaryLogo" [alt]="companyName" class="brand-image" height="32" width="32" rounded="true"
        style="opacity: 0.8"></pf-image>
    <span class="brand-text font-weight-bold d-none"><span>{{companyName}}</span></span>
    <span class="brand-text font-weight-bold"><span>Admin Portal</span></span>
</a>

<!-- Sidebar -->
<div class="sidebar">
    <div class="form-inline">
        <app-sidebar-search></app-sidebar-search>
    </div>

    <!-- Sidebar Menu -->
    <nav class="mt-2" style="overflow-y: hidden">
        <ul class="nav nav-pills nav-sidebar flex-column" *ngFor="let item of menu" data-widget="treeview" role="menu"
            data-accordion="false">
            <app-menu-item *ngIf="item.view" [menuItem]="item" />
        </ul>
    </nav>
</div>