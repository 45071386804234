import { Component, OnInit } from '@angular/core';
import {
    confirmResetPassword,
    type ConfirmResetPasswordInput
} from 'aws-amplify/auth';
import { ApiService } from '@services/api/api.service';
import {
    FormBuilder,
    Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAuthService } from '@services/user-auth/user-auth.service';
import { MessageService } from 'primeng/api';
import { Messages } from '@/Toaster-messages';
import { CommonService } from '@services/common/common.service';

@Component({
    selector: 'app-confirm-reset-password',
    templateUrl: './confirm-reset-password.component.html',
    styleUrl: './confirm-reset-password.component.scss'
})
export class ConfirmResetPasswordComponent implements OnInit {
    email: any
    confirmationCode: any
    submitted: boolean = false;
    loader = false;
    resetPasswordForm = this.fb.group({
        newPassword: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]],
        confirmPassword: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]],
    });

    ngOnInit(): void {
        this.email = this.route.snapshot.queryParamMap?.get('email')
        this.confirmationCode = this.route.snapshot.queryParamMap?.get('confirmationCode')
    }
    constructor(
        private common: CommonService,
        private awsApi: ApiService,
        private messageService: MessageService,
        private _router: Router,
        private fb: FormBuilder,
        public auth: UserAuthService,
        private route: ActivatedRoute,
    ) { }

    async handleConfirmResetPassword() {
        if (this.resetPasswordForm.controls.newPassword.value === this.resetPasswordForm.controls.confirmPassword.value) {
            try {
                let data = {
                    "email": this.email ?? '',
                    "new_password": this.resetPasswordForm.value.newPassword ?? ''
                }
                this.common.forgotPassword(data).subscribe(async (result) => {
                    if (result.status) {
                        this.awsApi.loading.next(false);
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Success',
                            detail: Messages.PASSWORD_CHANGED_SUCCESS
                        });
                    }
                    else {
                        this.awsApi.loading.next(false);
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: Messages.SOMETHING_WENT_WRONG
                        });
                    }
                    this._router.navigate(['login'])
                })
            } catch (error) {
                switch (error.name) {
                    case "CodeMismatchException":
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: Messages.INVALID_OTP
                        });
                        this._router.navigate(['confirm'], { queryParams: { email: this.email, type: 'forgot_password' } })
                        break;
                    case "InvalidLambdaResponseException":
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Success',
                            detail: Messages.PASSWORD_CHANGED_SUCCESS
                        });
                        this._router.navigate(['login'])
                        break;
                }
            }
        }
        else {
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: Messages.PASSWORD_MISMATCH
            });
        }
    }

    redirectToForgotPasswordPage() {
        this._router.navigate(['forgot-password'])
    }
}
