<section id="productListSec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 my-auto">
                <div class="card m-2">
                    <div class="card-body">
                        <!-- product-list-heading-row start -->
                        <div class="row product-list-heading-row">
                            <div class="col-xxl-5 col-xl-5 col-lg-4 col-md-4 col-sm-12 col-xs-12 my-auto">
                                <div class="text-center text-md-left mt-2 mt-md-0">
                                    <h2>Products</h2>
                                    <p class="mb-2 mb-md-0">
                                        {{ noOfProducts }} Results Found
                                    </p>
                                </div>
                            </div>
                            <div class="col-xxl-7 col-xl-7 col-lg-8 col-md-8 col-sm-12 col-xs-12 my-auto">
                                <div class="has-search-d-flex justify-content-center justify-content-md-end align-items-start align-items-md-center flex-nowrap flex-md-wrap"
                                    [formGroup]="filterForm">
                                    <div class="input-group has-search me-3 mb-2 mb-md-0">
                                        <input type="text" class="form-control" placeholder="Search"
                                            formControlName="searchText" (keyup.enter)="applyFilter()">
                                        <span class="fa fa-search input-group-text"
                                            *ngIf="!filterForm.controls.searchText.value"
                                            (click)="applyFilter()"></span>
                                        <span class="fa fa-times input-group-text"
                                            *ngIf="filterForm.controls.searchText.value" (click)="clearText()"></span>
                                    </div>
                                    <div *ngIf="!filterApplied">
                                        <button type="button" class="btn filter-btn me-3 d-none d-md-block"
                                            (click)="showFilterModalDialog('right')" *ngIf="productList?.length>10">
                                            <span class="material-symbols-outlined me-1">
                                                filter_alt
                                            </span>
                                            Filter
                                        </button>
                                        <button type="button" class="btn filter-btn me-3 d-block d-md-none"
                                            (click)="showFilterModalDialog('right')" *ngIf="productList?.length>10">
                                            <span class="material-symbols-outlined">
                                                filter_alt
                                            </span>
                                        </button>
                                    </div>
                                    <div *ngIf="filterApplied">
                                        <button type="button" class="btn after-filter-btn me-3 d-none d-md-block"
                                            (click)="showFilterModalDialog('right')">
                                            <i class="fas fa-filter"></i>
                                            Filter
                                        </button>
                                        <button type="button" class="btn after-filter-btn me-3 d-block d-md-none"
                                            (click)="showFilterModalDialog('right')">
                                            <i class="fas fa-filter"></i>
                                        </button>
                                    </div>
                                    <div>
                                        <button [disabled]="!access['add']" type="button"
                                            class="btn add-btn d-none d-md-block" (click)="redirectToAddProductPage()">
                                            Add Product
                                        </button>
                                        <button [disabled]="!access['add']" type="button"
                                            class="btn add-btn d-block d-md-none" (click)="redirectToAddProductPage()">
                                            <span class="material-symbols-outlined">
                                                add
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- product-list-heading-row end -->
                <div class="card mb-1 ms-2 me-2 scroll-container table-card" (window:scroll)="onScroll()"
                    *ngFor="let product of productDataList;let productIndex=index">
                    <div class="card-body">
                        <!-- product-list-table-row start -->
                        <div class="row product-list-table-row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="table-responsive">
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <img *ngIf="product.featuredImage" [src]="
                                                                                                product.featuredImage.url
                                                                                            " />
                                                    <img *ngIf="!product.featuredImage" [src]="" />
                                                </td>
                                                <td colspan="6">
                                                    <a [attr.href]="domainName+product.id+'&scId='+product.subCategoryID+'&type=edit'"
                                                        rel="noopener noreferrer">
                                                        <h6>
                                                            {{ product.productName }}
                                                        </h6>
                                                        <label>
                                                            <p class="truncate-lines">
                                                                {{removeHtmlTags(product.productDescription)}}</p>
                                                        </label>
                                                    </a>
                                                </td>
                                                <td>
                                                    <label>Status</label>
                                                    <ng-container [ngSwitch]="product?.status">
                                                        <h6 class="draft" *ngSwitchCase="'draft'">Draft</h6>
                                                        <h6 class="published" *ngSwitchCase="'published'">Published</h6>
                                                        <h6 class="scheduled" *ngSwitchCase="'scheduled'">Scheduled
                                                            <span>{{product?.scheduleDate | date:'dd/MM/yyyy' : 'UTC' }}</span>
                                                        </h6>
                                                        <h6 class="inactive" *ngSwitchCase="'inactive'">Inactive</h6>
                                                        <h6 class="published" *ngSwitchDefault>Draft</h6>
                                                    </ng-container>
                                                </td>
                                                <td class="text-end">
                                                    <div class="dropdown dropstart pe-2">
                                                        <i class="fas fa-ellipsis-v" id="dropdownMenuButton1"
                                                            data-bs-toggle="dropdown" aria-expanded="false"
                                                            data-bs-auto-close="inside"
                                                            data-bs-auto-close="outside"></i>
                                                        <ul class="dropdown-menu dropdown-menu-position"
                                                            aria-labelledby="dropdownMenuButton1">
                                                            <li [ngClass]="{ disable: !access['edit'] }">
                                                                <a class="dropdown-item"
                                                                    [routerLink]="[
                                                                                                                                                                '/edit-product'
                                                                                                                                                            ]"
                                                                    [queryParams]="{
                                                                                                                                                                id: product.id,
                                                                                                                                                                scId: product.subCategoryID,
                                                                                                                                                                type: 'edit'
                                                                                                                                                            }"><span
                                                                        class="material-symbols-outlined edit-icon me-3"
                                                                        title="Edit">edit_square </span>Edit
                                                                </a>
                                                            </li>
                                                            <li [ngClass]="{ disable: !access['add'] }">
                                                                <a class="dropdown-item"
                                                                    [routerLink]="[
                                                                                                                                                    '/edit-product'
                                                                                                                                                ]"
                                                                    [queryParams]="{
                                                                                                                                                    id: product.id,
                                                                                                                                                    scId: product.subCategoryID,
                                                                                                                                                    type: 'copy'
                                                                                                                                                }">
                                                                    <span
                                                                        class="material-symbols-rounded copy-icon me-3"
                                                                        title="Edit">copy_all </span>Copy
                                                                </a>
                                                            </li>
                                                            <li [ngClass]="{ disable: !access['delete'] }"
                                                                (click)="
                                                                                                        deleteProductItem(product)">
                                                                <a class="dropdown-item"><span
                                                                        class="material-symbols-outlined delete-icon"
                                                                        title="Delete">delete
                                                                    </span>Delete</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">
                                                    <label>Brand</label>
                                                    <h6>{{ product.brandName }}</h6>
                                                </td>
                                                <td>
                                                    <label>Category</label>
                                                    <h6>{{ product.mainCategory }} - {{ product.subCategory }}</h6>
                                                </td>
                                                <td>
                                                    <label>Created On</label>
                                                    <h6>{{ product.createdAt | date }}</h6>
                                                </td>
                                                <td>
                                                    <label>Total Stock</label>
                                                    <h6 *ngIf="product.totalStockQuantity">{{ product.totalStockQuantity
                                                        }}</h6>
                                                    <h6 *ngIf="!product.totalStockQuantity">0</h6>
                                                </td>
                                                <td>
                                                    <label>Reserved Stock</label>
                                                    <h6 *ngIf="product.totalReservedStock">{{ product.totalReservedStock
                                                        }}</h6>
                                                    <h6 *ngIf="!product.totalReservedStock">0</h6>
                                                </td>
                                                <td>
                                                    <label>Available</label>
                                                    <h6>{{ getAvailableStock(product) }}</h6>
                                                </td>
                                                <td>
                                                    <label>View Count</label>
                                                    <h6 *ngIf="product?.status == 'published'">{{
                                                        visitCount[productIndex]}}
                                                        <i *ngIf="visitCount[productIndex]>0"
                                                            [ngClass]="visitCount[productIndex]>0 ? 'fas fa-eye' : 'fas'"
                                                            (click)="showViewCountsModalDialog('right',productIndex)"></i>
                                                    </h6>
                                                    <h6 *ngIf="product?.status != 'published'">0</h6>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <!-- product-list-table-row end -->
                    </div>
                </div>
                <div *ngIf="loading && productDataList.length>5">Loading...</div>
            </div>
        </div>
    </div>
</section>

<!-- displayFilterModalToggle start -->
<p-dialog [draggable]="false" [(visible)]="displayFilterModalToggle" [position]="position" [modal]="true"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false" [showHeader]="false"
    [style]="{width: '34vw', minHeight: '100%', maxHeight: '100%', margin: '0'}"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}">
    <div id="displayFilterModalToggle" [formGroup]="filterForm">
        <div class="modal-header">
            <h5 class="modal-title">Filter</h5>
            <i class="fas fa-times" (click)="closeFilter()"></i>
        </div>
        <div class="body mt-3">
            <div class="row">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Brand</label>
                    <p-multiSelect display="chip" [options]="brandList" display="chip" formControlName="brand"
                        optionLabel="brandName" placeholder="Select Brand" />
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Main Category</label>
                    <select class="form-select" [(ngModel)]="selectedMainCategory" formControlName="mainCategory"
                        (change)="onSelectMainCategory()">
                        <option [ngValue]="null">Select Main Category</option>
                        <option *ngFor="let item of mainCategoryList" [ngValue]="item">{{ item.catName }}
                        </option>
                    </select>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2"
                    *ngIf="filterForm.get('mainCategory').value">
                    <label>Sub Category</label>
                    <p-multiSelect display="chip" [options]="subCategoryList" formControlName="subCategory"
                        optionLabel="catName" placeholder="Select Sub Category" />
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Status</label>
                    <p-multiSelect display="chip" [options]="productStatusList" display="chip" formControlName="status"
                        optionLabel="name" placeholder="Select Status" />
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Created On</label>
                    <p-calendar [iconDisplay]="'input'" [showIcon]="true" inputId="createOn" selectionMode="range"
                        formControlName="createOn" placeholder="From-To"></p-calendar>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="d-flex justify-content-end gap-2">
            <p-button label="Cancel" styleClass="filter-cancel-btn" (click)="closeFilterModalDialog()"></p-button>
            <p-button label="Apply" styleClass="filter-apply-btn" (click)="applyFilter()"></p-button>
        </div>
    </ng-template>
</p-dialog>
<!-- displayFilterModalToggle end -->
<!-- deleteModalToggle start -->
<p-dialog [showHeader]="false" [(visible)]="deleteModalToggle" [style]="{width: '25vw'}" [baseZIndex]="10000"
    [modal]="true">
    <div class="body mt-3" id="deleteModalToggle">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="delete-image">
                    <img src="../../../../../assets/img/delete.png" />
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="confirm-msg">
                    <h6>Are You Sure?</h6>
                    <p>You will not be able to recover this product!</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="confirm-btns">
                    <button type="button" class="btn cancel-btn" (click)="deleteModalToggle = false">
                        Cancel
                    </button>
                    <button type="button" class="btn delete-confirm-btn"
                        [disabled]="!(access['delete'] || access['edit'])" (click)="confirmDeleteProduct()">
                        Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</p-dialog>
<!-- {/* // deleteModalToggle end */} -->
<!-- displayViewCountsModalToggle start -->
<p-dialog [draggable]="false" [(visible)]="displayViewCountsModalToggle" [position]="position" [modal]="true"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false" [showHeader]="false"
    [style]="{width: '45vw', minHeight: '100%', maxHeight: '100%', margin: '0'}"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}">
    <div id="displayViewCountsModalToggle">
        <div class="modal-header">
            <h5 class="modal-title">View Counts</h5>
            <i class="fas fa-times" (click)="closeViewCountsModalDialog()"></i>
        </div>
        <div class="body mt-3" *ngIf="visitorsList.length>0">
            <div class="card mb-1" *ngFor="let visitor of visitorsList">
                <div class="card-body">
                    <div class="row">
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="table-row action-table-responsive table-responsive">
                                <table class="table table-borderless">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div>
                                                    <p>Customer name</p>
                                                    <h6 *ngIf="visitor?.userName">{{visitor?.userName}}</h6>
                                                    <h6 *ngIf="!visitor?.userName">Anonymous</h6>
                                                </div>
                                            </td>
                                            <td *ngIf="visitor?.visitedInfo && visitor?.visitedInfo.length>0">
                                                <div>
                                                    <p>Last accessed location</p>
                                                    <h6
                                                        *ngIf="(visitor?.visitedInfo[visitor?.visitedInfo.length-1]?.location && visitor?.visitedInfo[visitor?.visitedInfo.length-1]?.location != 'Unknown location')">
                                                        {{visitor?.visitedInfo[visitor?.visitedInfo.length-1]?.location}}
                                                    </h6>
                                                    <h6
                                                        *ngIf="visitor?.visitedInfo && visitor?.visitedInfo.length>0 
                                                        && (!visitor?.visitedInfo[visitor?.visitedInfo.length-1]?.location || visitor?.visitedInfo[visitor?.visitedInfo.length-1]?.location=='Unknown location')">
                                                        N/A</h6>
                                                    <h6 *ngIf="!visitor?.visitedInfo">N/A</h6>
                                                </div>
                                            </td>
                                            <td>
                                                <div *ngIf="visitor?.visitedInfo.length>1">
                                                    <p>Viewed on</p>
                                                    <h6>{{visitor?.updatedAt |
                                                        date:'dd/MM/yyyy' : 'UTC' }} ({{visitor?.visitedInfo.length}})
                                                        <span class="popover__wrapper ms-2">
                                                            <i class="fas fa-info-circle"></i>
                                                            <div class="popover__content">
                                                                <table class="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Time</th>
                                                                            <th>Place</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let visitedInformation of visitor?.visitedInfo">
                                                                            <td>{{visitedInformation.time}}</td>
                                                                            <td>
                                                                                <span
                                                                                    *ngIf="visitedInformation?.location && visitedInformation?.location!='Unknown location'">
                                                                                    {{visitedInformation?.location}}
                                                                                </span>
                                                                                <span
                                                                                    *ngIf="!visitedInformation?.location || visitedInformation?.location=='Unknown location'">
                                                                                    N/A
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </span>
                                                    </h6>
                                                </div>
                                                <div *ngIf="visitor?.visitedInfo.length==1">
                                                    <p>Viewed on</p>
                                                    <h6>{{visitor?.updatedAt | date:'dd/MM/yyyy' : 'UTC' }} {{visitor?.updatedAt
                                                        |
                                                        date:'shortTime'}}</h6>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</p-dialog>
<!-- displayViewCountsModalToggle end -->