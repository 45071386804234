<section id="returnRefundSec">
    <div class="container-fluid">
        <div class="row mt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card heading-card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex justify-content-start align-items-center">
                                        <i class="fas fa-chevron-left" (click)="redirectToReturnAndRefund()"></i>
                                        <h6>{{returnItemDetails?.returnRefundActivity?.returnReference}}</h6>
                                    </div>
                                    <div class="d-flex justify-content-end align-items-center">
                                        <div class="dropdown dropstart">
                                            <button class="btn" type="button" id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                <i class="fas fa-print print-icon" (click)="showReturnPdf()"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mt-2 return-details-card">
                    <div class="card-header">
                        <h2>General Information</h2>
                    </div>
                    <div class="card-body">
                        <div class="row mt-3">
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 mb-2">
                                <label>Customer Name</label>
                                <p>{{returnItemDetails?.userName}}</p>
                            </div>
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 mb-2">
                                <label>Ordered On</label>
                                <p>{{returnItemDetails?.createdAt | date:'dd/MM/yyyy' : 'UTC' }}</p>
                            </div>
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 mb-2">
                                <label>Order Ref</label>
                                <p>{{orderDetails?.id}}</p>
                            </div>
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 mb-2">
                                <label>Email</label>
                                <p>{{orderDetails?.email}}</p>
                            </div>
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 mb-2">
                                <label>Phone</label>
                                <p
                                    *ngIf="returnItemDetails?.phoneNumber && returnItemDetails?.phoneNumber!='undefined' && returnItemDetails?.phoneNumber!='null'">
                                    {{returnItemDetails?.phoneNumber}}</p>
                                <p
                                    *ngIf="!returnItemDetails?.phoneNumber || returnItemDetails?.phoneNumber=='undefined' || returnItemDetails?.phoneNumber=='null'">
                                    N/A</p>
                            </div>
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 mb-2">
                                <label>Status</label>
                                <p *ngIf="lastUpdatedStatus==returnStatusesList.shipped">
                                    Item Picked</p>
                                <p *ngIf="lastUpdatedStatus==returnStatusesList.cancelled">Request Cancelled</p>
                                <p *ngIf="lastUpdatedStatus==returnStatusesList.accepted">Request Accepted</p>
                                <p *ngIf="lastUpdatedStatus==returnStatusesList.rejected">Request Rejected</p>
                                <p *ngIf="lastUpdatedStatus==returnStatusesList.received">Product Received</p>
                                <p *ngIf="lastUpdatedStatus==returnStatusesList.refundApproved ||
                                                    lastUpdatedStatus == returnStatusesList.refundRejected ||
                                                    lastUpdatedStatus==returnStatusesList.requested">
                                    {{ lastUpdatedStatus | formatDocType }}</p>
                            </div>
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 mb-2">
                                <label>Payment Method</label>
                                <p>{{ returnItemDetails?.returnRefundActivity?.paymentMethod | formatDocType }}</p>
                            </div>
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 mb-2">
                                <label>Requested Date</label>
                                <p>{{ returnItemDetails?.returnRefundActivity?.createdAt | date:'dd/MM/yyyy' : 'UTC' }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card billing-details-card">
                    <div class="card-header">
                        <h2>Address</h2>
                    </div>
                    <div class="card-body">
                        <div class="row mt-2">
                            <div class="col-md-4" *ngIf="orderDetails?.billingAddress">
                                <h6>BILLING ADDRESS</h6>
                                <div class="bill-ship-details">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>Building/Flat No :</td>
                                                <th *ngIf="orderDetails?.billingAddress?.addressLine1">{{
                                                    orderDetails?.billingAddress?.addressLine1 }}</th>
                                                <th *ngIf="!orderDetails?.billingAddress?.addressLine1">N/A</th>
                                            </tr>
                                            <tr>
                                                <td>Street :</td>
                                                <th *ngIf="orderDetails?.billingAddress?.street">{{
                                                    orderDetails?.billingAddress?.street }}</th>
                                                <th *ngIf="!orderDetails?.billingAddress?.street">N/A</th>
                                            </tr>
                                            <tr>
                                                <td>Nearest Landmark :</td>
                                                <th *ngIf="orderDetails?.billingAddress?.landmark">{{
                                                    orderDetails?.billingAddress?.landmark }}</th>
                                                <th *ngIf="!orderDetails?.billingAddress?.landmark">N/A</th>
                                            </tr>
                                            <tr>
                                                <td>City :</td>
                                                <th *ngIf="orderDetails?.billingAddress?.city">{{
                                                    orderDetails?.billingAddress?.city }}</th>
                                                <th *ngIf="!orderDetails?.billingAddress?.city">N/A</th>
                                            </tr>
                                            <tr>
                                                <td>State :</td>
                                                <th *ngIf="orderDetails?.billingAddress?.state">{{
                                                    orderDetails?.billingAddress?.state }}</th>
                                                <th *ngIf="!orderDetails?.billingAddress?.state">N/A</th>
                                            </tr>
                                            <tr>
                                                <td>Postal Code :</td>
                                                <th *ngIf="orderDetails?.billingAddress?.postalCode">{{
                                                    orderDetails?.billingAddress?.postalCode }}</th>
                                                <th *ngIf="!orderDetails?.billingAddress?.postalCode">N/A</th>
                                            </tr>
                                            <tr>
                                                <td>Country :</td>
                                                <th *ngIf="orderDetails?.billingAddress?.country">{{
                                                    orderDetails?.billingAddress?.country }}</th>
                                                <th *ngIf="!orderDetails?.billingAddress?.country">N/A</th>
                                            </tr>
                                            <tr>
                                                <td>Phone Number :</td>
                                                <th *ngIf="orderDetails?.billingUserDetails?.phoneNumber"> {{
                                                    orderDetails?.billingUserDetails?.phoneNumber }}</th>
                                                <th *ngIf="!orderDetails?.billingUserDetails?.phoneNumber">N/A</th>
                                            </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="col-md-4"
                                *ngIf="orderDetails?.shippingAddress && orderDetails?.deliveryInfo?.deliveryType == deliveryTypeList.doorstep">
                                <h6>SHIPPING ADDRESS</h6>
                                <div class="bill-ship-details">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>Building/Flat No :</td>
                                                <th *ngIf="orderDetails?.shippingAddress?.addressLine1">{{
                                                    orderDetails?.shippingAddress?.addressLine1 }}</th>
                                                <th *ngIf="!orderDetails?.shippingAddress?.addressLine1">N/A</th>
                                            </tr>
                                            <tr>
                                                <td>Street :</td>
                                                <th *ngIf="orderDetails?.shippingAddress?.street">{{
                                                    orderDetails?.shippingAddress?.street }}</th>
                                                <th *ngIf="!orderDetails?.shippingAddress?.street">N/A</th>
                                            </tr>
                                            <tr>
                                                <td>Nearest Landmark :</td>
                                                <th *ngIf="orderDetails?.shippingAddress?.landmark">{{
                                                    orderDetails?.shippingAddress?.landmark }}</th>
                                                <th *ngIf="!orderDetails?.shippingAddress?.landmark">N/A</th>
                                            </tr>
                                            <tr>
                                                <td>City :</td>
                                                <th *ngIf="orderDetails?.shippingAddress?.city">{{
                                                    orderDetails?.shippingAddress?.city }}</th>
                                                <th *ngIf="!orderDetails?.shippingAddress?.city">N/A</th>
                                            </tr>
                                            <tr>
                                                <td>State :</td>
                                                <th *ngIf="orderDetails?.shippingAddress?.state">{{
                                                    orderDetails?.shippingAddress?.state }}</th>
                                                <th *ngIf="!orderDetails?.shippingAddress?.state">N/A</th>
                                            </tr>
                                            <tr>
                                                <td>Postal Code :</td>
                                                <th *ngIf="orderDetails?.shippingAddress?.postalCode">{{
                                                    orderDetails?.shippingAddress?.postalCode }}</th>
                                                <th *ngIf="!orderDetails?.shippingAddress?.postalCode">N/A</th>
                                            </tr>
                                            <tr>
                                                <td>Country :</td>
                                                <th *ngIf="orderDetails?.shippingAddress?.country">{{
                                                    orderDetails?.shippingAddress?.country }}</th>
                                                <th *ngIf="!orderDetails?.shippingAddress?.country">N/A</th>
                                            </tr>
                                            <tr>
                                                <td>Phone Number:</td>
                                                <th *ngIf="orderDetails?.shippingUserDetails.phoneNumber"> {{
                                                    orderDetails?.shippingUserDetails.phoneNumber }}</th>
                                                <th *ngIf="!orderDetails?.shippingUserDetails.phoneNumber">N/A</th>
                                            </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <h6>DELIVERY DETAILS</h6>
                                <div class="bill-ship-details"
                                    *ngIf="orderDetails?.deliveryInfo?.deliveryType == deliveryTypeList.selfPickup">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>Pickup By :</td>
                                                <th>Customer</th>
                                            </tr>
                                            <tr
                                                *ngIf="returnItemDetails?.deliveryTracking[returnItemDetails?.deliveryTracking.length-1]?.date">
                                                <td>Delivered On :</td>
                                                <th>{{
                                                    returnItemDetails?.deliveryTracking[returnItemDetails?.deliveryTracking.length-1]?.date
                                                    | date:'dd/MM/yyyy' : 'UTC' }} </th>
                                            </tr>
                                            <tr
                                                *ngIf="returnItemDetails?.deliveryTracking[returnItemDetails?.deliveryTracking.length-1]?.trackingBy ">
                                                <td>Name :</td>
                                                <th>{{
                                                    returnItemDetails?.deliveryTracking[returnItemDetails?.deliveryTracking.length-1]?.trackingBy
                                                    }}</th>
                                            </tr>
                                            <tr
                                                *ngIf="returnItemDetails?.deliveryTracking[returnItemDetails?.deliveryTracking.length-1]?.phoneNumber ">
                                                <td>Mobile Number :</td>
                                                <th>{{
                                                    returnItemDetails?.deliveryTracking[returnItemDetails?.deliveryTracking.length-1]?.phoneNumber
                                                    }}</th>
                                            </tr>
                                    </table>
                                </div>
                                <div class="bill-ship-details"
                                    *ngIf="orderDetails?.deliveryInfo?.deliveryType == deliveryTypeList.doorstep && returnItemDetails?.deliveryStatus == statusList.delivered">
                                    <table>
                                        <tbody>
                                            <tr *ngIf="returnItemDetails?.deliveryBy">
                                                <td>Delivery By :</td>
                                                <th>{{ returnItemDetails?.deliveryBy?.replace('By','') }}</th>
                                                <th *ngIf="returnItemDetails?.deliveryBy==delivery.deliveryPartner">
                                                    - {{
                                                    returnItemDetails?.deliveryPartner }}</th>
                                            </tr>
                                            <tr
                                                *ngIf="returnItemDetails?.deliveryTracking[returnItemDetails?.deliveryTracking.length-1]?.date">
                                                <td>Delivered On :</td>
                                                <th>{{
                                                    returnItemDetails?.deliveryTracking[returnItemDetails?.deliveryTracking.length-1]?.date
                                                    | date:'dd/MM/yyyy' : 'UTC' }} </th>
                                            </tr>
                                            <tr *ngIf="returnItemDetails?.deliveryBy == delivery.company">
                                                <td>Name :</td>
                                                <th>{{ returnItemDetails?.deliveryPersonInfo?.name }}</th>
                                            </tr>
                                            <tr *ngIf="returnItemDetails?.deliveryBy == delivery.company">
                                                <td>Mobile Number :</td>
                                                <th>{{ returnItemDetails?.deliveryPersonInfo?.contactNumber }}</th>
                                            </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card return-items-table-card">
                    <div class="card-header">
                        <h2>Returned Items</h2>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table mb-0">
                                        <tbody>
                                            <tr class="border-color-transparent">
                                                <td class="border-top-unset border-bottom-unset">
                                                    <img [src]="returnItemDetails?.itemDetail?.image" width="75px"
                                                        height="75px" />
                                                </td>
                                                <td class="border-top-unset">
                                            <tr class="w-100" style="display: inline-table;">
                                                <td colspan="6" class="border-top-unset border-bottom-unset ps-0">
                                                    <label>Product Name</label>
                                                    <p>{{ returnItemDetails?.itemDetail?.productName }}
                                                        <span
                                                            *ngIf="returnItemDetails?.attrName || returnItemDetails?.attrValue">
                                                            -
                                                            {{returnItemDetails?.attrName}}
                                                            {{returnItemDetails?.attrValue}}</span>
                                                    </p>
                                                </td>
                                                <td class="border-top-unset" nowrap>
                                                    <label>Return Status</label>
                                                    <p *ngIf="lastUpdatedStatus==returnStatusesList.shipped">Item Picked
                                                    </p>
                                                    <p *ngIf="lastUpdatedStatus==returnStatusesList.cancelled">Request
                                                        Cancelled</p>
                                                    <p *ngIf="lastUpdatedStatus==returnStatusesList.accepted">Request
                                                        Accepted</p>
                                                    <p *ngIf="lastUpdatedStatus==returnStatusesList.rejected">Request
                                                        Rejected</p>
                                                    <p *ngIf="lastUpdatedStatus==returnStatusesList.received">Product
                                                        Received</p>
                                                    <p *ngIf="lastUpdatedStatus==returnStatusesList.refundApproved ||
                                                    lastUpdatedStatus == returnStatusesList.refundRejected ||
                                                    lastUpdatedStatus==returnStatusesList.requested">{{
                                                        lastUpdatedStatus |
                                                        formatDocType
                                                        }}
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr class="border-color-transparent"
                                                style="display: inline-table; width: 93%;">
                                                <td class="border-top-unset border-bottom-unset pt-0 ps-0">
                                                    <label>Brand</label>
                                                    <p
                                                        *ngIf="returnItemDetails?.itemDetail?.brand && returnItemDetails?.itemDetail?.brand != 'null' && returnItemDetails?.itemDetail?.brand != 'undefined'">
                                                        {{ returnItemDetails?.itemDetail?.brand }}</p>
                                                    <p
                                                        *ngIf="!returnItemDetails?.itemDetail?.brand || returnItemDetails?.itemDetail?.brand == 'null' || returnItemDetails?.itemDetail?.brand == 'undefined'">
                                                        N/A</p>
                                                </td>
                                                <td nowrap class="border-top-unset border-bottom-unset pt-0">
                                                    <label>SKU</label>
                                                    <p *ngIf="returnItemDetails?.itemDetail?.sku &&
                                                    returnItemDetails?.itemDetail?.sku !=''">{{
                                                        returnItemDetails?.itemDetail?.sku }}</p>

                                                    <p *ngIf="!returnItemDetails?.itemDetail?.sku ||
                                                    returnItemDetails?.itemDetail?.sku ==''">N/A</p>
                                                </td>
                                                <td nowrap class="border-top-unset border-bottom-unset pt-0">
                                                    <label>Unit Price</label>
                                                    <p>{{currency}} {{ returnItemDetails?.itemDetail?.price | number:
                                                        '.2' }}</p>
                                                </td>
                                                <td nowrap class="border-top-unset border-bottom-unset pt-0">
                                                    <label>Return Qty</label>
                                                    <p>{{ returnItemDetails?.returnRefundActivity?.quantity }}</p>
                                                </td>
                                                <td nowrap class="border-top-unset border-bottom-unset pt-0">
                                                    <label>Total</label>
                                                    <p>{{currency}} {{ returnItemDetails?.returnRefundActivity?.quantity
                                                        *
                                                        returnItemDetails?.itemDetail?.price | number: '.2' }}</p>
                                                </td>
                                                <td nowrap class="border-top-unset border-bottom-unset pt-0">
                                                    <label>Warehouse</label>
                                                    <p
                                                        *ngIf="returnItemDetails?.wareHouseName && returnItemDetails?.wareHouseName!='null' && returnItemDetails?.wareHouseName != 'None'">
                                                        {{ returnItemDetails?.wareHouseName}}</p>
                                                    <p
                                                        *ngIf="!returnItemDetails?.wareHouseName || returnItemDetails?.wareHouseName=='null' || returnItemDetails?.wareHouseName == 'None'">
                                                        N/A</p>
                                                </td>
                                            </tr>
                                            </td>
                                            </tr>
                                            <tr>
                                                <td colspan="1" class="border-top-unset border-bottom-unset pt-0">
                                                </td>
                                                <td colspan="6" class="border-top-unset border-bottom-unset pt-0">
                                                    <label>Return Notes</label>
                                                    <p *ngIf="returnDetails?.reason">{{ returnDetails?.reason }}</p>
                                                    <p *ngIf="!returnDetails?.reason">N/A</p>
                                                    <button type="button" class="btn btn-link" data-bs-toggle="modal"
                                                        *ngIf="returnDetails?.attachments && returnDetails?.attachments.length>0"
                                                        href="#viewImagesModalToggle" (click)="setImageArray()">View
                                                        Images <i class="fas fa-angle-double-right"></i></button>
                                                </td>
                                            </tr>
                                            <tr *ngIf="returnItemDetails?.returnRefundActivity?.receivedInfo">
                                                <td colspan="1" class="border-top-unset border-bottom-unset pt-0 pb-0">
                                                </td>
                                                <td colspan="6" class="border-top-unset border-bottom-unset pt-0 pb-0">
                                            <tr class="w-100" style="display: inline-table;">
                                                <td colspan="6"
                                                    class="border-top-unset border-bottom-unset pt-0 pb-0 ps-0">
                                                    <label>Received On</label>
                                                    <p>{{
                                                        returnItemDetails?.returnRefundActivity?.receivedInfo?.receivedOn
                                                        | date:'dd/MM/yyyy' : 'UTC' }}</p>
                                                </td>
                                                <td class="border-top-unset border-bottom-unset pt-0 pb-0">
                                                    <label>Product Received As</label>
                                                    <p>{{
                                                        returnItemDetails?.returnRefundActivity?.receivedInfo?.receivedAs
                                                        }}</p>
                                                </td>
                                            </tr>
                                            </td>
                                            </tr>
                                            <tr *ngIf="returnItemDetails?.returnRefundActivity?.receivedInfo?.images">
                                                <td colspan="1" class="border-top-unset border-bottom-unset pt-0 pb-0">
                                                </td>
                                                <td class="border-top-unset border-bottom-unset pt-0 pb-0">
                                                    <button type="button" class="btn btn-link ps-0"
                                                        data-bs-toggle="modal" href="#viewImagesModalToggle"
                                                        (click)="setImageArray('received')">View Images <i
                                                            class="fas fa-angle-double-right"></i></button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="9" class="border-top-unset border-bottom-unset pt-0">
                                                    <div class="d-flex justify-content-end gap-2">
                                                        <button [disabled]="!access['edit']" type="button"
                                                            class="btn update-status-btn"
                                                            (click)="showUpdateStatusModalDialog('right')"
                                                            *ngIf="returnItemDetails?.returnRefundActivity?.returnStatusUpdate[returnItemDetails?.returnRefundActivity?.returnStatusUpdate.length-1]?.returnStatus == returnStatusesList.requested ||
                                                             returnItemDetails?.returnRefundActivity?.returnStatusUpdate[returnItemDetails?.returnRefundActivity?.returnStatusUpdate.length-1]?.returnStatus == returnStatusesList.accepted ||
                                                             returnItemDetails?.returnRefundActivity?.returnStatusUpdate[returnItemDetails?.returnRefundActivity?.returnStatusUpdate.length-1]?.returnStatus == returnStatusesList.shipped">
                                                            <i class="fas fa-file-signature"></i> Update
                                                            Status</button>
                                                        <button [disabled]="!access['edit']" type="button"
                                                            class="btn update-status-btn"
                                                            (click)="showAwaitingRefundModalDialog('right')"
                                                            *ngIf="returnItemDetails?.returnRefundActivity?.returnStatusUpdate[returnItemDetails?.returnRefundActivity?.returnStatusUpdate.length-1]?.returnStatus == returnStatusesList.received">
                                                            <i class="fas fa-file-signature"></i> Awaiting
                                                            Refund</button>
                                                        <button type="button" class="btn return-tracking-btn"
                                                            (click)="showRefundTrackingModalDialog('right')"><i
                                                                class="fas fa-route"></i> Return Tracking</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <td class="w-100"></td>
                                                <td class="alignment">
                                                    <p>Total:</p>
                                                </td>
                                                <td class="total-table-row">
                                                    <p>{{currency}} {{ returnItemDetails?.returnValue | number: '.2'}}
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card refund-details-table-card"
                    *ngIf="returnItemDetails?.returnRefundActivity?.returnStatusUpdate[returnItemDetails?.returnRefundActivity?.returnStatusUpdate.length-1]?.returnStatus == returnStatusesList.refundApproved ||returnItemDetails?.returnRefundActivity?.returnStatusUpdate[returnItemDetails?.returnRefundActivity?.returnStatusUpdate.length-1]?.returnStatus == returnStatusesList.refundRejected ">
                    <div class="card-header">
                        <h2>Refund Details</h2>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <td class="ps-1 border-top-unset">
                                                    <div>
                                                        <label>Status</label>
                                                        <p
                                                            *ngIf="returnItemDetails.returnRefundActivity.returnStatusUpdate[returnItemDetails.returnRefundActivity.returnStatusUpdate.length-1]?.returnStatus == returnStatusesList.refundApproved">
                                                            Refund Approved</p>
                                                        <p
                                                            *ngIf="returnItemDetails.returnRefundActivity.returnStatusUpdate[returnItemDetails.returnRefundActivity.returnStatusUpdate.length-1]?.returnStatus == returnStatusesList.refundRejected">
                                                            Refund Rejected</p>
                                                    </div>
                                                </td>
                                                <td class="ps-1 border-top-unset"
                                                    *ngIf="returnItemDetails.returnRefundActivity.returnStatusUpdate[returnItemDetails.returnRefundActivity.returnStatusUpdate.length-1]?.returnStatus == returnStatusesList.refundApproved">
                                                    <div>
                                                        <label>Refund Method</label>
                                                        <p
                                                            *ngIf="returnItemDetails.returnRefundActivity.refundInfo.paymentMethod!='CashOnDelivery'">
                                                            {{
                                                            returnItemDetails.returnRefundActivity.refundInfo.paymentMethod
                                                            | formatDocType }}</p>
                                                        <p
                                                            *ngIf="returnItemDetails.returnRefundActivity.refundInfo.paymentMethod=='CashOnDelivery'">
                                                            Cash</p>
                                                    </div>
                                                </td>
                                                <td class="ps-1 border-top-unset"
                                                    *ngIf="returnItemDetails.returnRefundActivity.returnStatusUpdate[returnItemDetails.returnRefundActivity.returnStatusUpdate.length-1]?.returnStatus == returnStatusesList.refundApproved">
                                                    <div>
                                                        <label>Refund Amount</label>
                                                        <p>{{currency}} {{
                                                            returnItemDetails.returnRefundActivity.refundInfo.refundAmount
                                                            | number: '.2' }}</p>
                                                    </div>
                                                </td>
                                                <td class="ps-1 border-top-unset">
                                                    <label
                                                        *ngIf="returnItemDetails.returnRefundActivity.returnStatusUpdate[returnItemDetails.returnRefundActivity.returnStatusUpdate.length-1]?.returnStatus == returnStatusesList.refundRejected">
                                                        Rejected On</label>
                                                    <label
                                                        *ngIf="returnItemDetails.returnRefundActivity.returnStatusUpdate[returnItemDetails.returnRefundActivity.returnStatusUpdate.length-1]?.returnStatus == returnStatusesList.refundApproved">
                                                        Approved On</label>
                                                    <p>{{
                                                        returnItemDetails.returnRefundActivity.returnStatusUpdate[returnItemDetails.returnRefundActivity.returnStatusUpdate.length-1].updatedAt
                                                        | date:'dd/MM/yyyy' : 'UTC' }}</p>
                                                </td>
                                                <td class="ps-1 border-top-unset"
                                                    *ngIf="returnItemDetails.returnRefundActivity.refundInfo.proofImage">
                                                    <label>
                                                        Proof</label>
                                                    <div class="attachments-div">
                                                        <div class="attachments-img">
                                                            <img class="img-fluid"
                                                                [src]="returnItemDetails.returnRefundActivity.refundInfo.proofImage" />
                                                            <div class="view-icon">
                                                                <i class="fas fa-eye"
                                                                    (click)="showImageViewModal(returnItemDetails.returnRefundActivity.refundInfo.proofImage)"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr
                                                *ngIf="returnItemDetails.returnRefundActivity.returnStatusUpdate[returnItemDetails.returnRefundActivity.returnStatusUpdate.length-1]?.returnStatus == returnStatusesList.refundRejected">
                                                <td class="ps-1">
                                                    <label>Reason</label>
                                                    <p>{{
                                                        returnItemDetails.returnRefundActivity.refundInfo.rejectedReason}}
                                                    </p>
                                                </td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- displayUpdateStatusModalToggle start -->
<p-dialog [draggable]="false" [(visible)]="displayUpdateStatusModalToggle" [position]="position" [modal]="true"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false" [showHeader]="false"
    [style]="{width: '34vw', minHeight: '100%', maxHeight: '100%', margin: '0'}"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}">
    <div id="displayUpdateStatusModalToggle">
        <div class="modal-header">
            <h5 class="modal-title">Update Status</h5>
            <i class="fas fa-times" (click)="closeUpdateStatusModalDialog()"></i>
        </div>
        <div class="body mt-3" [formGroup]="returnStatusForm">
            <div class="row">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Return Status</label>
                    <select class="form-select form-control" formControlName="status"
                        aria-label="Default select example" [(ngModel)]="defaultStatus" (change)="onSelectStatus()">
                        <option [ngValue]="null" disabled>
                            Choose
                        </option>
                        <option *ngFor="let refundStatus of copyReturnStatusList" [ngValue]="refundStatus.value">
                            {{ refundStatus.valueToShow | formatDocType }} </option>
                    </select>
                </div>
                <div *ngIf="orderType == deliveryTypeList.doorstep && selectedStatus == 'Accept'"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Pickup <span class="mandatory">*</span></label>
                    <select formControlName="pickedBy" (change)="onSelectPickedUpBy()" class="form-select form-control"
                        aria-label="Default select example">
                        <option [ngValue]="null" disabled>
                            Choose
                        </option>
                        <option *ngFor="let pickedUp of pickedUpByList" [ngValue]="pickedUp">
                            {{ pickedUp }} </option>
                    </select>
                </div>
                <div *ngIf="selectedPickedUpBy == delivery.deliveryPartner && currentStatus == returnStatusesList.requested && selectedStatus == 'Accept'"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Delivery Partner <span class="mandatory">*</span></label>
                    <select formControlName="deliveryPartner" class="form-select form-control"
                        aria-label="Default select example">
                        <option [ngValue]="null" disabled>
                            Choose
                        </option>
                        <option *ngFor="let deliveryPartner of deliveryPartnersList"
                            [ngValue]="deliveryPartner.companyName">
                            {{ deliveryPartner.companyName }} </option>
                    </select>
                </div>
                <div *ngIf="selectedPickedUpBy == delivery.company && currentStatus == returnStatusesList.requested && selectedStatus == 'Accept'"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Name <span class="mandatory">*</span></label>
                    <input formControlName="name" type="text" class="form-control" placeholder="Enter" />
                </div>
                <div *ngIf="selectedPickedUpBy == delivery.company && currentStatus == returnStatusesList.requested && selectedStatus == 'Accept'"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Mobile Number <span class="mandatory">*</span></label>
                    <input formControlName="mobileNumber" maxlength="14" type="text" class="form-control"
                        placeholder="Enter" />
                    <span class="text-danger"
                        *ngIf="(returnStatusForm.controls.mobileNumber.touched) && !returnStatusForm.controls.mobileNumber?.valid">
                        <div *ngIf="returnStatusForm.controls.mobileNumber.errors['pattern']">
                            Invalid mobile number.
                        </div>
                    </span>
                </div>
                <div *ngIf="selectedPickedUpBy == delivery.company && currentStatus == returnStatusesList.requested && selectedStatus == 'Accept'"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Expected Pickup Date <span class="mandatory">*</span></label>
                    <input formControlName="expectedPickedDate" type="date" [min]="getCurrentDate()"
                        class="form-control" placeholder="Select" />
                </div>
                <div *ngIf="currentStatus == returnStatusesList.requested && selectedStatus == 'Reject'"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Reason <span class="mandatory">*</span></label>
                    <textarea formControlName="rejectReason" class="form-control" rows="5"
                        placeholder="Enter"></textarea>
                </div>
                <div *ngIf="selectedPickedUpBy && currentStatus == returnStatusesList.requested && selectedStatus == 'Accept'"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Pickup Address</label>
                    <div class="bill-ship-details">
                        <table>
                            <tbody>
                                <tr>
                                    <td>Building/Flat No :</td>
                                    <th *ngIf="pickupAddress.addressLine1">{{ pickupAddress.addressLine1 }}</th>
                                    <th *ngIf="!pickupAddress.addressLine1">N/A</th>
                                </tr>
                                <tr>
                                    <td>Street :</td>
                                    <th *ngIf="pickupAddress.addressLine2">{{ pickupAddress.addressLine2 }}</th>
                                    <th *ngIf="!pickupAddress.addressLine2">N/A</th>
                                </tr>
                                <tr>
                                    <td>Nearest Landmark :</td>
                                    <th *ngIf="pickupAddress.landmark">{{ pickupAddress.landmark }}</th>
                                    <th *ngIf="!pickupAddress.landmark">N/A</th>
                                </tr>
                                <tr>
                                    <td>City :</td>
                                    <th *ngIf="pickupAddress.city">{{ pickupAddress.city }}</th>
                                    <th *ngIf="!pickupAddress.city">N/A</th>
                                </tr>
                                <tr>
                                    <td>State :</td>
                                    <th *ngIf="pickupAddress.state">{{ pickupAddress.state }}</th>
                                    <th *ngIf="!pickupAddress.state">N/A</th>
                                </tr>
                                <tr>
                                    <td>Postal Code :</td>
                                    <th *ngIf="pickupAddress.postalCode">{{ pickupAddress.postalCode }}</th>
                                    <th *ngIf="!pickupAddress.postalCode">N/A</th>
                                </tr>
                                <tr>
                                    <td>Country :</td>
                                    <th *ngIf="pickupAddress.country">{{ pickupAddress.country }}</th>
                                    <th *ngIf="!pickupAddress.country">N/A</th>
                                </tr>
                                <tr>
                                    <td>Phone Number :</td>
                                    <th *ngIf="orderDetails?.shippingUserDetails.phoneNumber">{{
                                        orderDetails?.shippingUserDetails.phoneNumber }}</th>
                                    <th *ngIf="!orderDetails?.shippingUserDetails.phoneNumber">N/A</th>
                                </tr>
                        </table>
                    </div>
                </div>
                <div *ngIf="returnAddress && selectedPickedUpBy == delivery.deliveryPartner && selectedStatus == 'Accept' && currentStatus == returnStatusesList.requested"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Return Address(Store Address)</label>
                    <div class="bill-ship-details">
                        <table>
                            <tbody>
                                <tr>
                                    <td>Building/Flat No :</td>
                                    <th *ngIf="returnAddress.addressLine1">{{ returnAddress.addressLine1 }}</th>
                                    <th *ngIf="!returnAddress.addressLine1">N/A</th>
                                </tr>
                                <tr>
                                    <td>Street :</td>
                                    <th *ngIf="returnAddress.addressLine2">{{ returnAddress.addressLine2 }}</th>
                                    <th *ngIf="!returnAddress.addressLine2">N/A</th>
                                </tr>
                                <tr>
                                    <td>Nearest Landmark :</td>
                                    <th *ngIf="returnAddress.landmark">{{ returnAddress.landmark }}</th>
                                    <th *ngIf="!returnAddress.landmark">N/A</th>
                                </tr>
                                <tr>
                                    <td>City :</td>
                                    <th *ngIf="returnAddress.city">{{ returnAddress.city }}</th>
                                    <th *ngIf="!returnAddress.city">N/A</th>
                                </tr>
                                <tr>
                                    <td>State :</td>
                                    <th *ngIf="returnAddress.state">{{ returnAddress.state }}</th>
                                    <th *ngIf="!returnAddress.state">N/A</th>
                                </tr>
                                <tr>
                                    <td>Postal Code :</td>
                                    <th *ngIf="returnAddress.postalCode">{{ returnAddress.postalCode }}</th>
                                    <th *ngIf="!returnAddress.postalCode">N/A</th>
                                </tr>
                                <tr>
                                    <td>Country :</td>
                                    <th *ngIf="returnAddress.country">{{ returnAddress.country }}</th>
                                    <th *ngIf="!returnAddress.country">N/A</th>
                                </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="selectedStatus != null && ((currentStatus == returnStatusesList.accepted && orderType==deliveryTypeList.selfPickup) || 
            (currentStatus == returnStatusesList.shipped && orderType=='Doorstep'))">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Received On <span class="mandatory">*</span></label>
                    <input formControlName="receivedOn" type="date" [min]="getLastUpdatedStatusDate()"
                        [max]="getCurrentDate()" class="form-control" />
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Product Received As <span class="mandatory">*</span></label>
                    <select formControlName="receivedAs" class="form-select form-control"
                        aria-label="Default select example">
                        <option [ngValue]="null" disabled>
                            Choose
                        </option>
                        <option *ngFor="let receivedAs of receivedAsList" [ngValue]="receivedAs">
                            {{ receivedAs }} </option>
                    </select>
                </div>
                <div *ngIf="(currentStatus == returnStatusesList.accepted && orderType == deliveryTypeList.selfPickup) || (currentStatus == returnStatusesList.shipped && orderType == deliveryTypeList.doorstep)"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <div class="row">
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                            <label>Received Product Images</label>
                            <form id="image-upload-form" class="image-uploader">
                                <input formControlName="image" #productFileUpload multiple id="image-upload" type="file"
                                    (change)="fileSave($event,'return')" name="fileUpload" accept="image/*" />
                                <label for="image-upload" id="file-drag">
                                    <div id="start">
                                        <div class="upload-caption">
                                            <i class="far fa-image"></i>
                                            <h5>Upload Product Images</h5>
                                            <p>add images in jpg or png</p>
                                        </div>
                                    </div>
                                </label>
                            </form>
                        </div>
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2"
                            *ngIf="returnStatusForm.get('image')?.value.length>0">
                            <div class="row">
                                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <label>Uploaded Images</label>
                                </div>

                                <div *ngFor="let image of imagesFormArray.controls; let i = index"
                                    class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 mb-2">
                                    <div id="after-upload">
                                        <img [src]="image.value" alt="Received image" />
                                        <i (click)="deleteImage(i)" class="fas fa-times-circle pt-1"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="productDetail!=null && returnStatusForm.controls.receivedAs.value == receivedAsList[0] && 
                ((currentStatus == returnStatusesList.accepted && orderType == deliveryTypeList.selfPickup) || 
                (currentStatus == returnStatusesList.shipped && orderType == deliveryTypeList.doorstep))"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <div class="form-check">
                        <input formControlName="updateInStock" (change)="onSelectUpdateToStock()"
                            class="form-check-input" type="checkbox" id="updateStockCheck" />
                        <label class="form-check-label ms-2 mt-1" for="updateStockCheck">
                            Update this product to stock
                        </label>
                    </div>
                </div>
                <div *ngIf="returnStatusForm.controls.receivedAs.value == receivedAsList[0] && 
                returnStatusForm.get('updateInStock')?.value && selectedStatus == returnStatusesList.received && productDetail!=null"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Quantity to be restocked: {{returnedQuantity}}</label>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="d-flex justify-content-end gap-2">
            <p-button label="Cancel" styleClass="filter-cancel-btn" (click)="closeUpdateStatusModalDialog()"></p-button>
            <p-button label="Update" styleClass="filter-apply-btn" (click)="updateReturnRefundStatus()"></p-button>
        </div>
    </ng-template>
</p-dialog>
<!-- displayUpdateStatusModalToggle end -->

<!-- displayAwaitingRefundModalToggle start -->
<p-dialog [draggable]="false" [(visible)]="displayAwaitingRefundModalToggle" [position]="position" [modal]="true"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false" [showHeader]="false"
    [style]="{width: '34vw', minHeight: '100%', maxHeight: '100%', margin: '0'}"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}">
    <div id="displayAwaitingRefundModalToggle">
        <div class="modal-header">
            <h5 class="modal-title">Refund</h5>
            <i class="fas fa-times" (click)="closeAwaitingRefundModalDialog()"></i>
        </div>
        <div class="body mt-3" [formGroup]="refundStatusForm">
            <div class="row">
                <div *ngIf="currentStatus == returnStatusesList.received"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Refund Status <span class="mandatory">*</span></label>
                    <select class="form-select form-control" formControlName="status"
                        aria-label="Default select example" [(ngModel)]="refundStatus"
                        (change)="onSelectRefundStatus()">
                        <option [ngValue]="null" disabled>
                            Choose
                        </option>
                        <option *ngFor="let refundStatus of refundStatusList" [ngValue]="refundStatus.value">
                            {{ refundStatus.value | formatDocType }} </option>
                    </select>
                </div>
                <div *ngIf="selectedStatus == returnStatusesList.refundApproved && currentStatus == returnStatusesList.received"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Date <span class="mandatory">*</span></label>
                    <input formControlName="date" type="date" [min]="getReceivedDate()" class="form-control"
                        placeholder="Select" />
                </div>
                <div *ngIf="selectedStatus == returnStatusesList.refundApproved && currentStatus == returnStatusesList.received"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Refund Method <span class="mandatory">*</span></label>
                    <select class="form-select form-control" formControlName="refundMethod"
                        aria-label="Default select example">
                        <option [ngValue]="null" disabled>
                            Choose
                        </option>
                        <option *ngFor="let paymentMethod of paymentMethodList" [ngValue]="paymentMethod.value">
                            {{ paymentMethod.title | formatDocType }} </option>
                    </select>
                </div>
                <div *ngIf="selectedStatus == returnStatusesList.refundApproved && currentStatus == returnStatusesList.received"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Refund Amount <span class="mandatory">*</span></label>
                    <input formControlName="refundAmount" type="number" (keypress)="preventE($event)" [max]="refundAmt"
                        class="form-control" placeholder="Select" />
                    <span class="text-danger">
                        <div *ngIf="refundStatusForm.get('refundAmount').hasError('amountExceeded')">
                            Refund amount cannot exceed {{ refundAmt }}
                        </div>
                        <div *ngIf="refundStatusForm.get('refundAmount').hasError('amountLessThanMinimum')">
                            Refund amount is required.
                        </div>
                    </span>
                </div>
                <div *ngIf="selectedStatus == returnStatusesList.refundApproved && currentStatus == returnStatusesList.received"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Refund Information</label>
                    <textarea formControlName="refundInformation" class="form-control" rows="5"
                        placeholder="Enter"></textarea>
                </div>
                <div *ngIf="selectedStatus == returnStatusesList.refundApproved && currentStatus == returnStatusesList.received"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Proof For Refund</label>
                    <form id="image-upload-form" class="image-uploader">
                        <input formControlName="proof" #productFileUpload id="image-upload" type="file"
                            (change)="fileSave($event,'refund')" name="fileUpload" accept="image/*" />
                        <label for="image-upload" id="file-drag">
                            <div id="start">
                                <div class="after-upload-gallery-image" *ngIf="refundStatusForm.get('proof')?.value">
                                    <img [src]="refundStatusForm.get('proof')?.value" alt="Proof image" />
                                </div>
                                <div class="upload-caption" *ngIf="!refundStatusForm.get('proof')?.value">
                                    <i class="far fa-image"></i>
                                    <h5>Upload Proof Image</h5>
                                    <p>add image in jpg or png</p>
                                </div>
                            </div>
                        </label>
                        <div id="after-upload" *ngIf="refundStatusForm.get('proof')?.value">
                            <div class="d-flex">
                                <div class="uploaded-filename">
                                    {{ refundStatusForm.get('proof')?.value.split('public/')[1] }}
                                </div>
                                <div>
                                    <i (click)="deleteImage()" class="fas fa-trash-alt pt-1"></i>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div *ngIf="selectedStatus == returnStatusesList.refundRejected && currentStatus == returnStatusesList.received"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Reject Reason <span class="mandatory">*</span></label>
                    <textarea formControlName="reason" class="form-control" rows="5" placeholder="Enter"></textarea>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="d-flex justify-content-end gap-2">
            <p-button label="Cancel" styleClass="filter-cancel-btn"
                (click)="closeAwaitingRefundModalDialog()"></p-button>
            <p-button label="Update" styleClass="filter-apply-btn" [disabled]="selectedStatus == ''"
                (click)="updateReturnRefundStatus()"></p-button>
        </div>
    </ng-template>
</p-dialog>
<!-- displayAwaitingRefundModalToggle end -->


<!-- displayRefundTrackingModalToggle start -->
<p-dialog [draggable]="false" [(visible)]="displayRefundTrackingModalToggle" [position]="position" [modal]="true"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false" [showHeader]="false"
    [style]="{width: '30vw', minHeight: '100%', maxHeight: '100%', margin: '0'}"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}">
    <div id="displayRefundTrackingModalToggle">
        <div class="modal-header">
            <h5 class="modal-title">Refund Tracking</h5>
            <i class="fas fa-times" (click)="closeDeliveryTrackingModalDialog()"></i>
        </div>
        <div class="body mt-3">
            <div class="row">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <div class="timeline-position">
                        <div class="timeline-body" *ngFor="let refundUpdate of refundUpdatesList">
                            <div
                                *ngIf="refundUpdate.refundStatus == returnStatusesList.requested && refundUpdate.isUpdated">
                                <img src="../../../../assets/img/Requested R.png" />
                                <div class="status-txt">
                                    <h5>Requested</h5>
                                    <h6>{{
                                        returnItemDetails?.returnRefundActivity?.returnStatusUpdate[refundUpdate.trackingListIndex]?.updatedAt
                                        | date:'dd/MM/yyyy' : 'UTC' }},
                                        {{
                                        returnItemDetails?.returnRefundActivity?.returnStatusUpdate[refundUpdate.trackingListIndex]?.updatedAt
                                        | date:'shortTime' }}</h6>
                                    <p>Requested By: <span>{{ orderDetails?.userName }}</span></p>
                                    <p>Location: <span>{{ orderDetails?.shippingAddress?.state}}</span></p>
                                </div>
                            </div>
                            <div
                                *ngIf="refundUpdate.refundStatus == returnStatusesList.requested && !refundUpdate.isUpdated">
                                <img src="../../../../assets/img/Requested G.png" />
                                <div class="status-txt">
                                    <h5>Requested</h5>
                                </div>
                            </div>
                            <div
                                *ngIf="refundUpdate.refundStatus == returnStatusesList.accepted && refundUpdate.isUpdated">
                                <img src="../../../../assets/img/Accepted R.png" />
                                <div class="status-txt">
                                    <h5>Accepted</h5>
                                    <h6>{{
                                        returnItemDetails?.returnRefundActivity?.returnStatusUpdate[refundUpdate.trackingListIndex]?.updatedAt
                                        | date:'dd/MM/yyyy' : 'UTC' }},
                                        {{
                                        returnItemDetails?.returnRefundActivity?.returnStatusUpdate[refundUpdate.trackingListIndex]?.updatedAt
                                        | date:'shortTime' }}</h6>
                                    <p>Accepted By: <span>{{
                                            returnItemDetails?.returnRefundActivity?.returnStatusUpdate[refundUpdate.trackingListIndex]?.updatedBy.split('#')[0]
                                            }}</span></p>
                                    <p *ngIf="orderType!='Self Pickup' &&
                                    returnItemDetails?.returnRefundActivity?.pickUpDetails.pickUpBy">Pickup:
                                        <span>{{
                                            returnItemDetails?.returnRefundActivity?.pickUpDetails.pickUpBy
                                            }}</span>
                                    </p>
                                    <p
                                        *ngIf="returnItemDetails?.returnRefundActivity?.pickUpDetails.pickUpBy==delivery.company">
                                        Contact: <span>{{
                                            returnItemDetails?.returnRefundActivity?.pickUpDetails.name
                                            }}
                                            ({{returnItemDetails?.returnRefundActivity?.pickUpDetails.phoneNumber}})</span>
                                    </p>
                                    <p
                                        *ngIf="returnItemDetails?.returnRefundActivity?.pickUpDetails.pickUpBy==delivery.deliveryPartner">
                                        Delivery Partner: <span>{{
                                            returnItemDetails?.returnRefundActivity?.pickUpDetails.name
                                            }}</span></p>
                                </div>
                            </div>
                            <div
                                *ngIf="refundUpdate.refundStatus == returnStatusesList.accepted && !refundUpdate.isUpdated">
                                <img src="../../../../assets/img/Accepted G.png" />
                                <div class="status-txt">
                                    <h5>Accepted</h5>
                                </div>
                            </div>
                            <div
                                *ngIf="refundUpdate.refundStatus == returnStatusesList.rejected && refundUpdate.isUpdated">
                                <img src="../../../../assets/img/Rejected R.png" />
                                <div class="status-txt">
                                    <h5>Rejected</h5>
                                    <h6>{{
                                        returnItemDetails?.returnRefundActivity?.returnStatusUpdate[refundUpdate.trackingListIndex]?.updatedAt
                                        | date:'dd/MM/yyyy' : 'UTC' }},
                                        {{
                                        returnItemDetails?.returnRefundActivity?.returnStatusUpdate[refundUpdate.trackingListIndex]?.updatedAt
                                        | date:'shortTime' }}</h6>
                                    <p>Updated By: <span>{{
                                            returnItemDetails?.returnRefundActivity?.returnStatusUpdate[refundUpdate.trackingListIndex]?.updatedBy.split('#')[0]
                                            }}</span></p>
                                    <p>Reason: <span>{{
                                            returnItemDetails?.returnRefundActivity?.rejectReason
                                            }}</span></p>
                                </div>
                            </div>
                            <div
                                *ngIf="orderType == deliveryTypeList.doorstep && refundUpdate.refundStatus == returnStatusesList.shipped && refundUpdate.isUpdated">
                                <img src="../../../../assets/img/Received R.png" />
                                <div class="status-txt">
                                    <h5>Item Picked</h5>
                                    <h6>{{
                                        returnItemDetails?.returnRefundActivity?.returnStatusUpdate[refundUpdate.trackingListIndex]?.updatedAt
                                        | date:'dd/MM/yyyy' : 'UTC' }},
                                        {{
                                        returnItemDetails?.returnRefundActivity?.returnStatusUpdate[refundUpdate.trackingListIndex]?.updatedAt
                                        | date:'shortTime' }}</h6>
                                    <p>Updated By: <span>{{
                                            returnItemDetails?.returnRefundActivity?.returnStatusUpdate[refundUpdate.trackingListIndex]?.updatedBy.split('#')[0]
                                            }}</span></p>
                                </div>
                            </div>
                            <div
                                *ngIf="orderType == deliveryTypeList.doorstep && refundUpdate.refundStatus == returnStatusesList.shipped && !refundUpdate.isUpdated">
                                <img src="../../../../assets/img/Received G.png" />
                                <div class="status-txt">
                                    <h5>Item Picked</h5>
                                </div>
                            </div>

                            <div
                                *ngIf="refundUpdate.refundStatus == returnStatusesList.received && refundUpdate.isUpdated">
                                <img src="../../../../assets/img/Received R.png" />
                                <div class="status-txt">
                                    <h5>Received</h5>
                                    <h6>{{
                                        returnItemDetails?.returnRefundActivity?.returnStatusUpdate[refundUpdate.trackingListIndex]?.updatedAt
                                        | date:'dd/MM/yyyy' : 'UTC' }},
                                        {{
                                        returnItemDetails?.returnRefundActivity?.returnStatusUpdate[refundUpdate.trackingListIndex]?.updatedAt
                                        | date:'shortTime' }}</h6>
                                    <p>Verified By:
                                        <span>{{returnItemDetails?.returnRefundActivity?.returnStatusUpdate[refundUpdate.trackingListIndex]?.updatedBy.split('#')[0]}}
                                        </span>
                                    </p>
                                    <p>Condition: <span>{{
                                            returnItemDetails?.returnRefundActivity?.receivedInfo.receivedAs.split('
                                            ')[0] |
                                            formatDocType}}</span></p>
                                    <p
                                        *ngIf="returnItemDetails?.returnRefundActivity?.updateInStock && productDetail!=null">
                                        Quantity
                                        restocked:
                                        <span>
                                            {{returnedQuantity}}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div
                                *ngIf="refundUpdate.refundStatus == returnStatusesList.received && !refundUpdate.isUpdated">
                                <img src="../../../../assets/img/Received G.png" />
                                <div class="status-txt">
                                    <h5>Received</h5>
                                </div>
                            </div>
                            <div
                                *ngIf="refundUpdate.refundStatus == returnStatusesList.refundApproved && refundUpdate.isUpdated">
                                <img src="../../../../assets/img/Refund Approved R.png" />
                                <div class="status-txt">
                                    <h5>Refund Approved</h5>
                                    <h6>{{
                                        returnItemDetails?.returnRefundActivity?.returnStatusUpdate[refundUpdate.trackingListIndex]?.updatedAt
                                        | date:'dd/MM/yyyy' : 'UTC' }},
                                        {{
                                        returnItemDetails?.returnRefundActivity?.returnStatusUpdate[refundUpdate.trackingListIndex]?.updatedAt
                                        | date:'shortTime' }}</h6>
                                    <p>Approved By: <span>{{
                                            returnItemDetails?.returnRefundActivity?.returnStatusUpdate[refundUpdate.trackingListIndex]?.updatedBy.split('#')[0]
                                            }}</span></p>
                                    <p>Expected Refund Date: <span>{{
                                            returnItemDetails?.returnRefundActivity?.refundInfo.date |
                                            date:'dd/MM/yyyy' : 'UTC' }}</span></p>
                                </div>
                            </div>
                            <div
                                *ngIf="refundUpdate.refundStatus == returnStatusesList.refundApproved && !refundUpdate.isUpdated">
                                <img src="../../../../assets/img/Refund Approved G.png" />
                                <div class="status-txt">
                                    <h5>Refund Approved</h5>
                                </div>
                            </div>
                            <div
                                *ngIf="refundUpdate.refundStatus == returnStatusesList.refundRejected && refundUpdate.isUpdated">
                                <img src="../../../../assets/img/Rejected R.png" />
                                <div class="status-txt">
                                    <h5>Refund Rejected</h5>
                                    <h6>{{
                                        returnItemDetails?.returnRefundActivity?.returnStatusUpdate[refundUpdate.trackingListIndex]?.updatedAt
                                        | date:'dd/MM/yyyy' : 'UTC' }},
                                        {{
                                        returnItemDetails?.returnRefundActivity?.returnStatusUpdate[refundUpdate.trackingListIndex]?.updatedAt
                                        | date:'shortTime' }}</h6>
                                    <p>Rejected By: <span>{{
                                            returnItemDetails?.returnRefundActivity?.returnStatusUpdate[refundUpdate.trackingListIndex]?.updatedBy.split('#')[0]
                                            }}</span></p>
                                </div>
                            </div>
                            <div *ngIf="refundUpdate.refundStatus == statusList.cancelled && refundUpdate.isUpdated">
                                <img src="../../../../assets/img/Rejected R.png" />
                                <div class="status-txt">
                                    <h5>Cancelled</h5>
                                    <h6>{{
                                        returnItemDetails?.returnRefundActivity?.returnStatusUpdate[refundUpdate.trackingListIndex]?.updatedAt
                                        | date:'dd/MM/yyyy' : 'UTC' }},
                                        {{
                                        returnItemDetails?.returnRefundActivity?.returnStatusUpdate[refundUpdate.trackingListIndex]?.updatedAt
                                        | date:'shortTime' }}</h6>
                                    <p>Rejected By: <span>{{
                                            returnItemDetails?.returnRefundActivity?.returnStatusUpdate[refundUpdate.trackingListIndex]?.updatedBy.split('#')[0]
                                            }}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</p-dialog>
<!-- displayRefundTrackingModalToggle end -->
<div class="modal fade" id="viewImagesModalToggle" aria-hidden="true" aria-labelledby="viewImagesModalToggleLabel"
    tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="viewImagesModalToggleLabel">Product Images</h5>
                <i class="fas fa-times" data-bs-dismiss="modal" aria-label="Close"></i>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <ng-image-slider [images]="imageObject" [imagePopup]="false" #nav></ng-image-slider>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>