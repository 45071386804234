/*****************************************Developer Comments***********************************/
// updateToStatus in getReturnStatusUpdate() will be 
// 1. RefundApproved/RefundRejected if the latest updated status is 'Received', 
// 2. Received if the latest updated status is 'Accept'and if Self pickup
// 2. Shipped and then Received if the latest updated status is 'Accept'and if Doorstep,
// 3. Accepted/Rejected if the latest updated status is 'Requested'
/*********************************************************************************************/
import { Component } from '@angular/core';
import { ApiService } from '@services/api/api.service';
import * as query from 'graphql/queries';
import { ActivatedRoute, Router } from '@angular/router';
import { DeliveryBy, DeliveryByList, DeliveryTypeList, OrderStatuses, ReceivedAsList, RefundStatusList, ReturnItemStatusList, ReturnPaymentMethodList, ReturnStatuses, ReturnStatusList } from '@/common-list';
import _ from 'underscore';
import { MessageService } from 'primeng/api';
import { Messages } from '@/Toaster-messages';
import { ROUTES } from '@/redirect-routes';
import { AbstractControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ReturnActivityStatus, Status } from 'API';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonService } from '@services/common/common.service';
import { exit } from 'process';
import * as mutation from 'graphql/mutations';
import { ImageViewPopupModalComponent } from '@/shared/image-view-popup-modal/image-view-popup-modal.component';

@Component({
  selector: 'app-return-and-refund-details',
  templateUrl: './return-and-refund-details.component.html',
  styleUrl: './return-and-refund-details.component.scss'
})
export class ReturnAndRefundDetailsComponent {
  ref: DynamicDialogRef | undefined;
  imageObject: Array<object>
  value: number = 5;
  position: any;
  type: any
  valid: boolean = true
  isRefundAmountValid: boolean = true
  orderType: any
  refundAmt: any
  returnedQuantity: any
  displayShippingInfoModalToggle = false
  displayUpdateStatusModalToggle = false;
  displayAwaitingRefundModalToggle = false
  displayRefundTrackingModalToggle = false;
  deleteModalToggle: any
  orderId: any
  orderItemId: any
  returnItemDetails: any
  orderDetails: any
  selectedStatus: any = ''
  lastUpdatedStatus: any
  currentStatus: any
  selectedPickedUpBy: any
  refundStatus: string | null = null;
  defaultStatus: string | null = null;
  currentStock: any
  statusEdit: boolean = false
  currency: any
  updatedAt: any

  returnStatusUpdate: any = []
  pickUpDetailsList: any = []
  productDetail: any
  returnAndRefundActivity: any = {}
  copyReturnStatusList: any = []
  refundUpdatesList: any = []
  deliveryPartnersList: any = []
  companyDetails: any = []
  wareHouseDetails: any = []

  pickedUpByList = DeliveryByList
  receivedAsList = ReceivedAsList
  statusList = OrderStatuses
  returnStatusList = ReturnStatusList
  refundStatusList = RefundStatusList
  returnItemsStatusList = ReturnItemStatusList
  paymentMethodList = ReturnPaymentMethodList
  deliveryTypeList = DeliveryTypeList
  delivery = DeliveryBy
  returnStatusesList = ReturnStatuses

  returnedOn: any
  returnDetails: any
  pickupAddress: any = {}
  returnAddress: any = {}
  access: any = {}

  returnStatusForm = this.formbuilder.group({
    status: ['', [Validators.required]],
    pickedBy: [''],
    deliveryPartner: [],
    name: [''],
    mobileNumber: ['', Validators.pattern('[- +0-9]{10,15}')],
    expectedPickedDate: [''],
    rejectReason: [''],
    pickupAddress: [''],
    returnAddress: [''],
    receivedOn: [''],
    receivedAs: [''],
    image: this.formbuilder.array([]),
    updateInStock: [false],
  });

  get imagesFormArray() {
    return this.returnStatusForm.get('image') as FormArray;
  }

  refundStatusForm = this.formbuilder.group({
    status: ['', [Validators.required]],
    date: [''],
    refundMethod: [''],
    refundAmount: ['', [this.maxAmountValidator.bind(this)]],
    refundInformation: [''],
    proof: [''],
    reason: ['']
  });
  constructor(public dialogService: DialogService, private common: CommonService, private router: Router, private formbuilder: FormBuilder, private activatedRoute: ActivatedRoute, private messageService: MessageService, private awsApi: ApiService) { }

  async getOrderItems() {
    return await this.awsApi.client.graphql({
      query: query.getOrderItem,
      variables: {
        pk: 'ORDERI#' + this.orderId,
        id: this.orderItemId
      }
    });
  }

  async getOrder() {
    return await this.awsApi.client.graphql({
      query: query.getOrder,
      variables: {
        pk: 'ORDER#',
        id: this.orderId
      }
    });
  }

  async getCompanyDetails() {
    return await this.awsApi.client.graphql({
      query: query.listCompanyDetails
    });
  }

  async getProductDetail() {
    let items = [];
    let nextToken = null;
    do {
      const response = await this.awsApi.client.graphql({
        query: query.productDetailsByProductID,
        variables: {
          pk: 'PRODETAIL#',
          productID: { eq: this.returnItemDetails.productId },
          filter: {},
          limit: 650,
          nextToken: nextToken,
          sortDirection: null
        }
      })
      items = items.concat(response.data.productDetailsByProductID.items);
      if (items.length > 0) { return items; }
      nextToken = response.data.productDetailsByProductID.nextToken;
    } while (nextToken !== null)
    return items
  }

  async getWarehouseDetail() {
    return await this.awsApi.client.graphql({
      query: query.listWareHouses,
      variables: {
        pk: 'WARE#',
        id: { eq: this.returnItemDetails.wareHouseId }
      }
    });
  }

  async ngOnInit() {
    this.getRoleManagement()
    this.currency = await this.common.getCurrencyOfCompany()
    this.position = 'right';
    this.startingFunction()
  }

  async getRoleManagement() {
    try {
      await this.common.getPermissionsOfUser('Orders', 'Return and Refund');
      this.access = this.common.access;
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  async startingFunction() {
    this.activatedRoute.queryParamMap.subscribe(async (params) => {
      this.orderId = params.get('orderId') ?? ''
      this.orderItemId = params.get('orderItemId') ?? ''
      if (this.orderId != '' && this.orderItemId != '') {
        this.awsApi.loading.next(true);
        Promise.all([this.getOrderItems(), this.getOrder(), await this.common.getDeliveryPartners({ status: { eq: Status.ACTIVE } }, ['pk', 'id', 'companyName', 'createdAt']), this.getCompanyDetails()])
          .then(async (response) => {
            this.returnItemDetails = response[0].data.getOrderItem
            this.orderDetails = response[1].data.getOrder
            this.deliveryPartnersList = response[2].sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
            this.companyDetails = response[3].data.listCompanyDetails.items[0]
            this.returnDetails = this.returnItemDetails.returnRefundActivity ? this.returnItemDetails.returnRefundActivity : undefined
            this.updatedAt = this.returnDetails?.returnStatusUpdate[this.returnDetails?.returnStatusUpdate.length - 1].updatedAt.split('T')[0]
            this.returnedQuantity = this.returnDetails?.quantity ? this.returnDetails?.quantity : 0
            this.refundAmt = this.returnItemDetails?.returnValue ? this.returnItemDetails?.returnValue : 0
            this.lastUpdatedStatus = this.returnDetails?.returnStatusUpdate[this.returnDetails?.returnStatusUpdate.length - 1].returnStatus
            await this.getProduct()
            await this.getReturnAddress()
            this.awsApi.loading.next(false);
          }).catch((error) => {
            this.awsApi.loading.next(false);
          });
      }
    })
  }

  async getProduct() {
    this.productDetail = null
    this.getProductDetail().then((response) => {
      if (response.length > 0) {
        let productDets = response.filter(details => {
          return !details.isDeleted;
        });
        if (this.returnItemDetails.attrValueID && this.returnItemDetails.attrValueID != 'null') {
          this.productDetail = productDets.filter(details => {
            return details.attrValueID === this.returnItemDetails.attrValueID;
          });
        } else {
          this.productDetail = productDets[0]
        }
        this.currentStock = this.productDetail ? this.productDetail.stockQuantity : 0
      }
    }).catch((error) => {
      this.currentStock = 0
    });
  }

  async getReturnAddress() {
    let response = await this.getWarehouseDetail()
    this.wareHouseDetails = response.data.listWareHouses.items.length > 0 ? response.data.listWareHouses.items : []
    this.returnAddress = this.wareHouseDetails.length > 0 ?
      {
        addressLine1: this.wareHouseDetails[0]?.addressLine1 ? this.wareHouseDetails[0].addressLine1 : undefined,
        addressLine2: this.wareHouseDetails[0]?.addressLine2 ? this.wareHouseDetails[0].addressLine2 : undefined,
        state: this.wareHouseDetails[0]?.state ? this.wareHouseDetails[0].state : undefined,
        city: this.wareHouseDetails[0]?.city ? this.wareHouseDetails[0].city : undefined,
        postalCode: this.wareHouseDetails[0]?.zipCode ? this.wareHouseDetails[0].zipCode : undefined,
        country: this.wareHouseDetails[0]?.country ? this.wareHouseDetails[0].country : undefined,
      } : this.companyDetails.address ? this.getAddress(this.companyDetails.address) : undefined
  }

  setImageArray(type: any = null) {
    this.imageObject = []
    let attatchments = []
    if (type == 'received') {
      if (this.returnItemDetails?.returnRefundActivity?.receivedInfo?.images[0].includes(',')) {
        _.each(this.returnItemDetails?.returnRefundActivity?.receivedInfo?.images[0].replace(/\[|\]/g, '').split(','), (item) => {
          attatchments.push(item.toString())
        })
      }
      else {
        attatchments.push(this.returnItemDetails?.returnRefundActivity?.receivedInfo?.images[0].replace(/\[|\]/g, '').toString())
      }
    }
    else {
      attatchments = this.returnItemDetails?.returnRefundActivity?.attachments
    }
    if (attatchments.length > 0) {
      _.each(attatchments, (item, index) => {
        this.imageObject.push({
          image: item,
          thumbImage: item,
          alt: item.split('public/')[1],
          order: index
        })
      })
    }
  }

  getReturnStatusUpdate() {
    let updateToStatus, status
    this.returnStatusUpdate = []
    if (this.returnItemDetails?.returnRefundActivity?.returnStatusUpdate.length > 0) {
      _.each(this.returnItemDetails?.returnRefundActivity?.returnStatusUpdate, (item) => {
        delete item.__typename
        this.returnStatusUpdate.push(item)
      })
      status = this.returnItemDetails?.returnRefundActivity?.status
      updateToStatus =
        status == 'Received' ? this.refundStatusForm.controls.status.value :
          status == 'Accepted' && this.orderType == this.deliveryTypeList.selfPickup ? ReturnActivityStatus.Received :
            status == 'Accepted' && this.orderType == this.deliveryTypeList.doorstep ? ReturnActivityStatus.Shipped :
              status == 'Shipped' && this.orderType == this.deliveryTypeList.doorstep ? ReturnActivityStatus.Received :
                status == 'Requested' && this.returnStatusForm.controls.status.value == 'Accept' ?
                  ReturnActivityStatus.Accepted : ReturnActivityStatus.Rejected

      this.returnStatusUpdate.push({
        returnStatus: updateToStatus,
        updatedAt: new Date().toISOString(),
        updatedBy: localStorage.getItem('userName').toString() + '#' + localStorage.getItem('role').toString(),
      })
    }
    else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  GetPickUpDetails(): any {
    delete this.returnItemDetails?.returnRefundActivity?.pickUpDetails.__typename
    if (this.returnItemDetails?.returnRefundActivity?.pickUpDetails?.pickUpAddress) {
      delete this.returnItemDetails?.returnRefundActivity?.pickUpDetails?.pickUpAddress.__typename
    }
    return this.returnItemDetails?.returnRefundActivity?.pickUpDetails
  }

  GetReceivedInfo() {
    delete this.returnItemDetails?.returnRefundActivity?.receivedInfo.__typename
    return this.returnItemDetails?.returnRefundActivity?.receivedInfo
  }

  GetRefundInfo() {
    delete this.returnItemDetails?.returnRefundActivity?.refundInfo.__typename
    return this.returnItemDetails?.returnRefundActivity?.refundInfo
  }

  formatPickupDate(date): any {
    const formattedTime = new Date().getUTCHours().toString().padStart(2, '0') + ':' +
      new Date().getUTCMinutes().toString().padStart(2, '0') + ':' +
      new Date().getUTCSeconds().toString().padStart(2, '0') + '.' +
      new Date().getUTCMilliseconds().toString().padStart(3, '0') + 'Z'
    return (new Date(date).toISOString()).split('T')[0] + 'T' + formattedTime
  }

  async getReturnAndRefundActivity() {
    this.returnAndRefundActivity = {
      returnReference: this.returnItemDetails?.returnRefundActivity?.returnReference,
      reason: this.returnItemDetails?.returnRefundActivity?.reason,
      comment: this.returnItemDetails?.returnRefundActivity?.comment,
      quantity: this.returnItemDetails?.returnRefundActivity?.quantity,
      attachments: this.returnItemDetails?.returnRefundActivity?.attachments,
      paymentMode: this.returnItemDetails?.returnRefundActivity?.paymentMode,
      paymentMethod: this.returnItemDetails?.returnRefundActivity?.paymentMethod,
      returnType: this.returnItemDetails?.returnRefundActivity?.returnType,
      returnAddress: Object.keys(this.returnAddress || {}).length !== 0 ? this.getAddress(this.returnAddress) : undefined,

      pickUpDetails: this.returnStatusUpdate[this.returnStatusUpdate.length - 1]?.returnStatus == 'Accepted' ?
        {
          pickUpAddress: Object.keys(this.pickupAddress || {}).length !== 0 ? this.getAddress(this.pickupAddress) : undefined,
          pickUpBy: this.returnStatusForm.controls.pickedBy?.value ? this.returnStatusForm.controls.pickedBy.value : undefined,
          name: this.returnStatusForm.controls.pickedBy?.value == this.delivery.company ? this.returnStatusForm.controls.name?.value ? this.returnStatusForm.controls.name.value : undefined : this.returnStatusForm.controls.deliveryPartner?.value,
          phoneNumber: this.returnStatusForm.controls.mobileNumber?.value ? this.returnStatusForm.controls.mobileNumber.value : undefined,
          expectedPickUpDate: this.returnStatusForm.controls.expectedPickedDate?.value ? this.formatPickupDate(this.returnStatusForm.controls.expectedPickedDate.value) : undefined,
        } : (this.returnItemDetails?.returnRefundActivity?.pickUpDetails ? this.GetPickUpDetails() : undefined),

      receivedInfo: this.returnStatusForm.controls.receivedOn?.value ? {
        receivedOn: new Date(this.returnStatusForm.controls.receivedOn.value).toISOString(),
        receivedAs: this.returnStatusForm.controls.receivedAs?.value ? this.returnStatusForm.controls.receivedAs.value : undefined,
        images: this.returnStatusForm.controls.image?.value.length > 0 ? this.returnStatusForm.controls.image.value : undefined
      } : (this.returnItemDetails?.returnRefundActivity?.receivedInfo ? this.GetReceivedInfo() : undefined),

      refundInfo: this.refundStatusForm.controls.status?.value ?
        this.refundStatusForm.controls.status?.value == ReturnActivityStatus.RefundApproved ?
          {
            date: this.refundStatusForm.controls.date?.value ? new Date(this.refundStatusForm.controls.date.value).toISOString() : new Date().toISOString(),
            paymentMethod: this.refundStatusForm.controls.refundMethod?.value ? this.refundStatusForm.controls.refundMethod.value : undefined,
            refundAmount: this.refundStatusForm.controls.refundAmount?.value ? this.refundStatusForm.controls.refundAmount.value : undefined,
            refundInfo: this.refundStatusForm.controls.refundInformation?.value ? this.refundStatusForm.controls.refundInformation.value : undefined,
            proofImage: this.refundStatusForm.controls.proof?.value ? this.refundStatusForm.controls.proof.value : undefined
          } :
          {
            date: this.refundStatusForm.controls.date?.value ? new Date(this.refundStatusForm.controls.date.value).toISOString() : new Date().toISOString(),
            rejectedReason: this.refundStatusForm.controls.reason?.value ? this.refundStatusForm.controls.reason.value : undefined
          } : this.returnItemDetails?.returnRefundActivity?.refundInfo ? this.GetRefundInfo() : undefined,

      updateInStock: this.returnItemDetails?.returnRefundActivity?.updateInStock ? this.returnItemDetails?.returnRefundActivity?.updateInStock : this.returnStatusForm.controls.updateInStock.value,
      updateInStockQty: this.returnItemDetails?.returnRefundActivity?.updateInStock ?
        this.returnItemDetails?.returnRefundActivity?.updateInStockQty :
        this.returnStatusForm.controls.updateInStock.value == true ? this.returnItemDetails?.returnRefundActivity?.quantity : undefined,
      status: this.returnStatusUpdate[this.returnStatusUpdate.length - 1]?.returnStatus,
      returnStatusUpdate: this.returnStatusUpdate,
      rejectReason: this.returnStatusUpdate[this.returnStatusUpdate.length - 1]?.returnStatus == ReturnActivityStatus.Rejected ?
        this.returnStatusForm.controls.rejectReason.value : this.returnItemDetails?.returnRefundActivity?.rejectReason ?
          this.returnItemDetails?.returnRefundActivity.rejectReason : undefined,
      createdAt: this.returnItemDetails?.returnRefundActivity?.createdAt,
    }
    if (this.returnStatusForm.controls.updateInStock.value == true && this.productDetail != null) {
      await this.updateInStock()
    }
  }

  getAddress(address) {
    delete address.__typename
    return address
  }

  async updateInStock() {
    let data = {
      pk: 'PRODETAIL#',
      id: this.productDetail.id,
      stockQuantity: this.currentStock + parseFloat(this.returnedQuantity)
    }
    this.awsApi.client.graphql({
      query: mutation.updateProductDetails,
      variables: {
        input: {
          ...data
        }
      }
    }).then((response) => { }).catch((error) => { });
  }

  displayValidationErrorMessage() {
    this.awsApi.loading.next(false);
    let message = (!this.isRefundAmountValid && this.selectedStatus === this.returnStatusesList.refundApproved) ? Messages.INVALID_REFUND_AMOUNT : Messages.PLEASE_FILL_REQUIRED_FIELDS
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: message
    });
  }

  async updateReturnRefundStatus() {
    this.valid = false
    if (!(this.returnStatusForm.controls.status.value || this.refundStatusForm.controls.status.value)) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.PLEASE_FILL_REQUIRED_FIELDS
      });
      return;
    }
    this.awsApi.loading.next(true);
    this.valid = true
    this.getReturnStatusUpdate()
    const lastReturnStatus = this.returnStatusUpdate[this.returnStatusUpdate.length - 1]?.returnStatus;
    if (lastReturnStatus === this.returnStatusesList.accepted && this.orderType == this.deliveryTypeList.doorstep) {
      if (this.returnStatusForm.controls.pickedBy?.value) {
        if (this.returnStatusForm.controls.pickedBy.value === this.delivery.company) {
          if (!this.returnStatusForm.controls.name.value ||
            !this.returnStatusForm.controls.mobileNumber.value ||
            !this.returnStatusForm.controls.expectedPickedDate.value) {
            this.valid = false
          }
        } else if (this.returnStatusForm.controls.pickedBy.value === this.delivery.deliveryPartner) {
          if (!this.returnStatusForm.controls.deliveryPartner.value) {
            this.valid = false
          }
        }
      } else { this.valid = false }
    }
    if (lastReturnStatus === this.returnStatusesList.received &&
      (!this.returnStatusForm.controls.receivedOn.value || !this.returnStatusForm.controls.receivedAs.value)) {
      this.valid = false
    }
    if (this.selectedStatus === this.returnStatusesList.refundApproved) {
      if (!this.refundStatusForm.controls.date.value ||
        !this.refundStatusForm.controls.refundMethod.value ||
        !this.refundStatusForm.controls.refundAmount.value || !this.isRefundAmountValid) {
        this.valid = false
      }
    }
    if (lastReturnStatus === this.returnStatusesList.refundRejected) {
      if (!this.refundStatusForm.controls.reason.value) { this.valid = false }
    }
    if (lastReturnStatus === ReturnActivityStatus.Rejected) {
      if (!this.returnStatusForm.controls.rejectReason.value) { this.valid = false }
    }
    if (this.valid) {
      this.displayUpdateStatusModalToggle = false;
      this.displayAwaitingRefundModalToggle = false
      await this.getReturnAndRefundActivity()
      let data = {
        pk: 'ORDERI#' + this.orderId,
        id: this.orderItemId,
        returnRefundActivity: this.returnAndRefundActivity
      }
      this.awsApi.loading.next(true);
      this.awsApi.client.graphql({
        query: mutation.updateOrderItem,
        variables: {
          input: {
            ...data
          }
        }
      }).then((response) => {
        this.updatedAt =
          this.awsApi.loading.next(false);
        this.successMessage(Messages.STATUS_UPDATED)
        this.updatedAt = this.returnStatusUpdate[this.returnStatusUpdate.length - 1]?.updatedAt.split('T')[0]
        this.returnStatusForm.reset()
        this.refundStatusForm.reset()
        this.selectedPickedUpBy = null
        this.selectedStatus = null
        this.startingFunction()
      }).catch((error) => {
        this.catchError()
      });
    }
    else {
      this.displayValidationErrorMessage()
    }
  }

  successMessage(succMessage: any) {
    this.awsApi.loading.next(false);
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: succMessage
    });
  }

  catchError() {
    this.awsApi.loading.next(false);
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: Messages.SOMETHING_WENT_WRONG
    });
  }

  showShippingInfoModalDialog(position: string) {
    this.position = position;
    this.displayShippingInfoModalToggle = true;
  }

  closeShippingInfoModalDialog() {
    this.displayShippingInfoModalToggle = false;
  }

  redirectToReturnAndRefund() {
    this.router.navigate([ROUTES.RETURN_AND_REFUND])
  }

  showDropdownReturnItemStatus() { this.statusEdit = true }

  hideDropdownReturnItemStatus() { this.statusEdit = false }

  onSelectStatus() {
    this.selectedStatus = this.returnStatusForm.controls.status.value
    this.resetFormControls(this.returnStatusForm)
    if (this.selectedStatus == 'Accept') {
      this.autoFillDetails()
    }
  }

  resetFormControls(selectedFormGroup) {
    // Resetting all the form controls in 'returnStatusForm', except 'status'
    Object.keys(selectedFormGroup.controls).forEach(key => {
      if (key !== 'status' && key != 'refundAmount') {
        selectedFormGroup.controls[key].reset();
      }
    });
    this.refundStatusForm.patchValue({
      date: new Date().toISOString().split('T')[0]
    })
  }

  onSelectRefundStatus() {
    this.selectedStatus = this.refundStatusForm.controls.status.value
    this.resetFormControls(this.refundStatusForm)
  }

  onSelectPickedUpBy() {
    this.selectedPickedUpBy = this.returnStatusForm.controls.pickedBy.value
    this.returnStatusForm.patchValue({
      name: this.returnItemDetails.deliveryPersonInfo ? this.returnItemDetails.deliveryPersonInfo.name : null,
      mobileNumber: this.returnItemDetails.deliveryPersonInfo ? this.returnItemDetails.deliveryPersonInfo.contactNumber : null,
      deliveryPartner: this.returnItemDetails.deliveryPartner ? this.returnItemDetails.deliveryPartner : null,
    })
  }

  onSelectUpdateToStock() {
    this.returnedQuantity = this.returnItemDetails.returnRefundActivity.quantity ? this.returnItemDetails.returnRefundActivity.quantity : 0
  }

  autoFillDetails() {
    if (this.returnItemDetails?.deliveryBy == this.delivery.company || this.orderType == this.deliveryTypeList.selfPickup) {
      const deliveredItemTrackingDetails = this.returnItemDetails.deliveryTracking.find(item => item.status = this.statusList.delivered);
      this.returnStatusForm.patchValue({
        name: deliveredItemTrackingDetails?.trackingBy,
        mobileNumber: deliveredItemTrackingDetails?.phoneNumber
      })
    }
    if (this.orderType == this.deliveryTypeList.doorstep && this.returnItemDetails?.deliveryBy) {
      this.returnStatusForm.patchValue({
        pickedBy: this.returnItemDetails?.deliveryBy
      })
      this.onSelectPickedUpBy()
    }
    this.pickupAddress = this.returnItemDetails?.returnRefundActivity?.pickUpDetails?.pickUpAddress ? this.returnItemDetails?.returnRefundActivity?.pickUpDetails?.pickUpAddress : this.orderDetails.shippingAddress ? this.orderDetails.shippingAddress : ''
  }

  getReturnRefundStatus() {
    if (this.returnItemDetails?.returnRefundActivity) {
      this.currentStatus = this.returnItemDetails?.returnRefundActivity?.status
    }
  }

  getRefundTrackingDetails() {
    let trackingItem, index, statusList: any = [], returnStatusList
    this.refundUpdatesList = []
    let returnRefundActivities: any = this.returnItemDetails.returnRefundActivity
    let copyReturnStatusList = [...this.returnItemsStatusList];
    // Remove the third item (Shipped), if the orderType is 'Self Pickup'
    if (this.orderType == this.deliveryTypeList.selfPickup) {
      copyReturnStatusList.splice(3, 1);
      returnStatusList = copyReturnStatusList;
    } else {
      returnStatusList = this.returnItemsStatusList;
    }
    _.each(returnStatusList, (item) => {
      trackingItem = returnRefundActivities.returnStatusUpdate.find(trackingItem => trackingItem.returnStatus === item['value'])
      if (this.refundUpdatesList.length == 1 &&
        returnRefundActivities.returnStatusUpdate[returnRefundActivities.returnStatusUpdate.length - 1].returnStatus == 'Rejected' &&
        returnRefundActivities.returnStatusUpdate[returnRefundActivities.returnStatusUpdate.length - 2].returnStatus == 'Requested') {
        this.refundUpdatesList.push({
          refundStatus: 'Rejected',
          trackingListIndex: 1,
          isUpdated: true
        })
        exit;
      }
      else {
        index = returnRefundActivities.returnStatusUpdate.indexOf(trackingItem)
        if (index >= 0) {
          this.refundUpdatesList.push({
            refundStatus: returnRefundActivities.returnStatusUpdate[index].returnStatus,
            trackingListIndex: index,
            isUpdated: true
          })
        }
      }
    })
    let lastUpdatedTrackingItem = this.refundUpdatesList[this.refundUpdatesList.length - 1].refundStatus
    if (lastUpdatedTrackingItem != 'Cancelled') {
      _.each(returnStatusList, (item) => {
        if (lastUpdatedTrackingItem == item['value']) {
          statusList = returnStatusList.slice(returnStatusList.indexOf(item) + 1)
        }
      })
    }
    if (this.returnDetails.status == 'RefundApproved' ||
      this.returnDetails.status == 'RefundRejected') {
      this.refundUpdatesList.push({
        refundStatus: this.returnDetails.status,
        trackingListIndex: returnRefundActivities.returnStatusUpdate.length - 1,
        isUpdated: true
      })
    }
    else {
      if (this.refundUpdatesList[this.refundUpdatesList.length - 1].refundStatus != 'RefundApproved' &&
        this.refundUpdatesList[this.refundUpdatesList.length - 1].refundStatus != 'RefundRejected' &&
        this.refundUpdatesList[this.refundUpdatesList.length - 1].refundStatus != 'Cancelled' &&
        this.refundUpdatesList[this.refundUpdatesList.length - 1].refundStatus != 'Rejected') {
        statusList.push({
          title: '4', value: ReturnActivityStatus.RefundApproved, valueToShow: 'Refund Approved'
        })
      }
      _.each(statusList, (item) => {
        this.refundUpdatesList.push({
          refundStatus: item.value,
          isUpdated: false
        })
      })
    }
  }

  async fileSave(event: any, type: any) {
    this.awsApi.loading.next(true);
    let files, file
    if (event.target instanceof HTMLInputElement && event.target.files) {
      if (type == 'return') {
        files = event.target.files;
        try {
          for (let i = 0; i < files.length; i++) {
            const fileItem = files[i];
            this.awsApi.loading.next(false);
            let imageUrl = await this.common.getS3Url(fileItem)
            this.imagesFormArray.push(this.formbuilder.control(imageUrl))
          }
        } catch (error) {
          this.awsApi.loading.next(false);
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: Messages.SOMETHING_WENT_WRONG
          });
        }
      }
      if (type == 'refund') {
        file = event.target.files[0];
        try {
          this.awsApi.loading.next(false);
          let imageUrl = await this.common.getS3Url(file)
          this.refundStatusForm.patchValue({
            proof: imageUrl
          })
        } catch (error) {
          this.awsApi.loading.next(false);
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: Messages.SOMETHING_WENT_WRONG
          });
        }
      }
    }
  }

  deleteImage(index: any = -1) {
    if (this.selectedStatus == this.returnStatusesList.refundApproved || this.selectedStatus == this.returnStatusesList.refundRejected) {
      this.refundStatusForm.controls.proof.reset();
    }
    else {
      if (index > -1) {
        this.imagesFormArray.removeAt(index)
      }
    }
    (document.getElementById('image-upload') as HTMLInputElement).value = '';
  }

  showRefundTrackingModalDialog(position: string) {
    this.position = position;
    this.displayRefundTrackingModalToggle = true;
    this.orderType = this.orderDetails?.deliveryInfo?.deliveryType
    this.getRefundTrackingDetails()
  }
  closeUpdateStatusModalDialog() {
    this.displayUpdateStatusModalToggle = false;
    this.returnStatusForm.reset()
    this.selectedStatus = ''
  }
  closeAwaitingRefundModalDialog() {
    this.refundStatusForm.reset()
    this.selectedStatus = ''
    this.displayAwaitingRefundModalToggle = false;
  }
  closeDeliveryTrackingModalDialog() {
    this.displayRefundTrackingModalToggle = false;
  }

  showUpdateStatusModalDialog(position: string) {
    this.orderType = this.orderDetails?.deliveryInfo?.deliveryType
    this.selectedPickedUpBy = null
    this.selectedStatus = null
    let status = this.returnItemDetails?.returnRefundActivity?.status
    this.getStatusList(status)
    this.getReturnRefundStatus()
    this.position = position;
    this.displayUpdateStatusModalToggle = true;
  }

  getStatusList(status: any = null) {
    switch (status) {
      case 'Requested':
        this.copyReturnStatusList = this.orderType == this.deliveryTypeList.selfPickup ?
          this.returnStatusList.filter(item => item.value !== 'Shipped') :
          this.returnStatusList
        break;
      case 'Accepted':
        this.copyReturnStatusList = this.orderType === this.deliveryTypeList.doorstep ? [{ id: 3, value: 'Shipped', valueToShow: 'Item Picked' }] : [{ id: 3, value: 'Received', valueToShow: 'Item Received' }]
        break;
      case 'Shipped':
        if (this.orderType === this.deliveryTypeList.doorstep) {
          this.copyReturnStatusList = [{ id: 4, value: 'Received', valueToShow: 'Received' }];
        }
        break;
      default:
        this.copyReturnStatusList = this.refundStatusList
        break;
    }
  }

  showAwaitingRefundModalDialog(position: string) {
    this.orderType = this.orderDetails?.deliveryInfo?.deliveryType
    this.getStatusList()
    this.getReturnRefundStatus()
    this.autoFillAwaitingRefundModalDialog()
    this.position = position;
    this.displayAwaitingRefundModalToggle = true;
  }

  autoFillAwaitingRefundModalDialog() {
    this.refundStatusForm.patchValue({
      refundAmount: this.refundAmt.toFixed(2)
    })
  }

  getCurrentDate(): string {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    return currentDate.toISOString().split('T')[0];
  }

  getReceivedDate(): string {
    let receivedDate = (this.returnDetails.receivedInfo.receivedOn).toString().split('T')[0]
    return receivedDate
  }

  showInvoice() {
    this.awsApi.loading.next(true);
    if (this.orderDetails.invoiceURL != '') {
      this.awsApi.loading.next(false);
      window.open(this.orderDetails.invoiceURL, '_blank')
    }
    else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Invoice not found!'
      });
    }
  }

  async showReturnPdf() {
    let data: any = {};
    data['id'] = this.orderItemId
    this.common.returnDetailsPdf(data, await this.common.getHeader()).subscribe((result) => {
      if (result.message == 'Success' && result.objecturl) {
        window.open(result.objecturl, '_blank')
      }
    })
  }

  maxAmountValidator(control: AbstractControl) {
    this.isRefundAmountValid = true;
    if (control.value || control.value != '') {
      if (control.value > this.refundAmt) {
        this.isRefundAmountValid = false;
        return { amountExceeded: true };
      }
      if (control.value < 1) {
        this.isRefundAmountValid = false;
        return { amountLessThanMinimum: true };
      }
    }
    return null;
  }

  preventE(event) {
    if (event.which === 101) {
      event.preventDefault();
    }
  }

  getLastUpdatedStatusDate() {
    this.returnStatusForm.patchValue({
      receivedOn: new Date().toISOString().split('T')[0]
    })
    return this.updatedAt.split('T')[0]
  }

  showImageViewModal(url: any) {
    if (url.toString().slice(-3) === 'pdf') {
      window.open(url)
    }
    else {
      this.ref = this.dialogService.open(ImageViewPopupModalComponent,
        {
          data: {
            image: url
          },
          header: 'View Image',
          width: '50vw',
          modal: true,
          breakpoints: {
            '960px': '75vw',
            '640px': '90vw'
          },
        });
    }
  }
}