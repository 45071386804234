<div class="card card-outline card-primary">
    <div class="card-header text-center">
        <a [routerLink]="['/']" class="h1"><b>Admin</b>LTE</a>
    </div>
    <div class="card-body">
        <p class="login-box-msg">Sign in to start your session</p>

        <form [formGroup]="loginForm" (ngSubmit)="loginByAuth()">
            <div class="input-group mb-3">
                <input
                    formControlName="email"
                    type="email"
                    class="form-control"
                    placeholder="Email"
                />
                <div class="input-group-append">
                    <div class="input-group-text">
                        <span class="fas fa-envelope"></span>
                    </div>
                </div>
            </div>
            <div class="input-group mb-3">
                <input
                    formControlName="password"
                    type="password"
                    class="form-control"
                    placeholder="Password"
                />
                <div class="input-group-append">
                    <div class="input-group-text">
                        <span class="fas fa-lock"></span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-7">
                    <div class="icheck-primary">
                        <input type="checkbox" id="remember" />
                        <label for="remember"> Remember Me </label>
                    </div>
                </div>
                <!-- /.col -->
                <div class="col-5">
                    <pf-button
                        [variant]="'primary'"
                        [loading]="isAuthLoading"
                        [disabled]="isFacebookLoading || isGoogleLoading"
                        (click)="loginByAuth()"
                    >
                        Sign In
                    </pf-button>
                </div>
                <!-- /.col -->
            </div>
        </form>

        <div class="social-auth-links text-center mb-3">
            <pf-button
                class="mb-2"
                [variant]="'primary'"
                [loading]="isFacebookLoading"
                [disabled]="isAuthLoading || isGoogleLoading"
                (click)="loginByFacebook()"
            >
                <i class="fab fa-facebook me-2"></i>
                Sign in using Facebook
            </pf-button>
            <pf-button
                [variant]="'danger'"
                [loading]="isGoogleLoading"
                [disabled]="isAuthLoading || isFacebookLoading"
                (click)="loginByGoogle()"
            >
                <i class="fab fa-google me-2"></i>
                Sign in using Google+
            </pf-button>
        </div>

        <p class="mb-1">
            <a [routerLink]="['/forgot-password']">I forgot my password</a>
        </p>
        <p class="mb-0">
            <a [routerLink]="['/register']" class="text-center">
                Register a new membership
            </a>
        </p>
    </div>
    <!-- /.login-card-body -->
</div>
