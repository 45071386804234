import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import { MessageService } from 'primeng/api';
import * as query from 'graphql/queries';
import { CommonService } from '@services/common/common.service';
import { Messages } from '@/Toaster-messages';

@Component({
  selector: 'app-cart-details',
  templateUrl: './cart-details.component.html',
  styleUrl: './cart-details.component.scss'
})
export class CartDetailsComponent implements OnInit {
  cartList: any = []
  cartData: any = []
  currentStock: any = []
  userId: any
  pk: any
  hide: boolean
  access: any = {}
  cartTotal: any = 0
  currency: any

  constructor(private common: CommonService, private awsApi: ApiService, private messageService: MessageService, private fb: FormBuilder,
    private _activatedroute: ActivatedRoute) { }

  async ngOnInit() {
    this.getRoleManagement()
    await this.getAllCart()
  }

  async getAllCart() {
    this.currency = await this.common.getCurrencyOfCompany()
    this._activatedroute.queryParamMap.subscribe((params) => {
      this.userId = params.get('id') ?? '';
      if (this.userId != '') {
        this.awsApi.loading.next(true);
        Promise.all([this.getCartByUserId()])
          .then(async (response) => {
            this.cartData = response[0]
            this.cartTotal = this.cartData.reduce((sum, cartItem) => sum + (cartItem.salesPrice * cartItem.selectedQuantity), 0);
            await this.fetchCurrentStock()
            this.awsApi.loading.next(false);
          })
          .catch((error) => {
            this.awsApi.loading.next(false);
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: Messages.SOMETHING_WENT_WRONG
            });
          });
      }
    });
  }

  async fetchCurrentStock() {
    for (let i = 0; i < this.cartData.length; i++) {
      try {
        let filterObject: any = {}
        filterObject['productID'] = { eq: this.cartData[i].productID }
        if (this.cartData[i].attrValueID && this.cartData[i].attrValueID != 'null') {
          filterObject['attrValueID'] = { eq: this.cartData[i].attrValueID }
        }
        let response = await this.common.getProductDetailsWithEditQuery(filterObject, ['pk', 'id', 'stockQuantity', 'reservedStock'])
        let prodDetail: any
        if (response.length > 0) {
          prodDetail = response[0]
        }
        this.currentStock[i] = prodDetail ? (parseFloat(prodDetail.stockQuantity) - parseFloat(prodDetail.reservedStock ?? 0)) : 0
      } catch (error) { }
    }
  }

  async getRoleManagement() {
    try {
      await this.common.getPermissionsOfUser('Orders', 'Manage Carts');
      this.access = this.common.access;
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  async getCartByUserId() {
    let items = [];
    let nextToken = null;
    do {
      const response = await this.awsApi.client.graphql({
        query: query.cartByUserId,
        variables: {
          pk: 'CART#',
          userId: { eq: this.userId },
          filter: {},
          limit: 650,
          nextToken: nextToken,
          sortDirection: null
        }
      })
      items = items.concat(response.data.cartByUserId.items);
      nextToken = response.data.cartByUserId.nextToken;
    } while (nextToken !== null)
    return items
  }

  getTotalPrice(cartItem) {
    return this.cartData.reduce((total, item) => {
      if (cartItem.id == item.id) {
        return total + (cartItem.salesPrice * cartItem.selectedQuantity);
      }
      return total;
    }, 0);
  }
}
