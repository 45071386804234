<section id="productReportListSec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 my-auto" id="products-list">
                <div class="card m-2">
                    <div class="card-body">
                        <!-- products-list-heading-row start -->
                        <div class="row products-list-heading-row">
                            <div class="col-xxl-6 col-xl-6 col-lg-4 col-md-4 col-sm-12 col-xs-12 my-auto">
                                <div class="text-center text-md-left mt-2 mt-md-0">
                                    <div class="d-flex justify-content-start align-items-center">
                                        <i class="fas fa-chevron-left" (click)="redirectToReportListPage()"></i>
                                        <h6>Product-wise Order Summary Report</h6>
                                    </div>
                                    <p class="mb-2 mb-md-0 mt-2" *ngIf="productReportList?.length > 0">
                                        {{productReportList?.length}} Results Found
                                    </p>
                                </div>
                            </div>
                            <div class="col-xxl-6 col-xl-6 col-lg-8 col-md-8 col-sm-12 col-xs-12 my-auto">
                                <div class="has-search-d-flex justify-content-center justify-content-md-end align-items-start align-items-md-center flex-nowrap flex-md-wrap"
                                    [formGroup]="filterForm">
                                    <div class="mb-2 mb-md-0" *ngIf="!filterApplied">
                                        <button type="button" class="btn filter-btn me-3 d-none d-md-block" (click)="
                                        showFilterModalDialog('right')">
                                            <span class="material-symbols-outlined me-1">
                                                filter_alt
                                            </span>
                                            Filter
                                        </button>
                                        <button type="button" class="btn filter-btn me-3 d-block d-md-none"
                                            (click)="showFilterModalDialog('right')">
                                            <span class="material-symbols-outlined">
                                                filter_alt
                                            </span>
                                        </button>
                                    </div>
                                    <div class="mb-2 mb-md-0" *ngIf="filterApplied">
                                        <button type="button" class="btn after-filter-btn me-3 d-none d-md-block"
                                            (click)="showFilterModalDialog('right')">
                                            <i class="fas fa-filter"></i>
                                            Filter
                                        </button>
                                        <button type="button" class="btn after-filter-btn me-3 d-block d-md-none"
                                            (click)="showFilterModalDialog('right')">
                                            <i class="fas fa-filter"></i>
                                        </button>
                                    </div>
                                    <div>
                                        <button type="button" class="btn export-btn d-none d-md-block"
                                            *ngIf="productReportList?.length>0" (click)="exportToExcel()">
                                            <i class="fas fa-file-export me-1" (click)="exportToExcel()"></i> Export
                                        </button>
                                        <button type="button" class="btn export-btn d-block d-md-none"
                                            *ngIf="productReportList?.length>0" (click)="exportToExcel()">
                                            <i class="fas fa-file-export"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-1 ms-2 me-2" *ngIf="productReportList?.length > 0">
                    <div class="card-body px-2 px-1">
                        <div class="row products-name-row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 my-1">
                                <label>Product Name: </label>
                                <span> {{productName}}</span>
                            </div>
                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4"
                                *ngIf="filterForm.controls.sku.value">
                                <label>Current Stock: </label>
                                <span> {{currentStock}}</span>
                            </div>
                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4"
                                *ngIf="filterForm.controls.sku.value">
                                <label>Pending Orders: </label>
                                <span> {{pendingOrders}}</span>
                            </div>
                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4"
                                *ngIf="filterForm.controls.sku.value">
                                <label>SKU: </label>
                                <span> {{filterForm.controls.sku.value}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-1 ms-2 me-2" *ngIf="!filterApplied && productReportList?.length == 0">
                    <div class="card-body p-0 sku-selection">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <label>Please select SKU or Product Name</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-1 ms-2 me-2" *ngIf="filterApplied && filterForm.controls.sku.value">
                    <div class="card-body p-0">
                        <!-- products-list-table-row start -->
                        <div class="row products-list-table-row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                *ngIf="productReportList?.length > 0">
                                <div class="table-row table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Order Date</th>
                                                <th>Order By</th>
                                                <th>Delivery Location</th>
                                                <th>Order Qty</th>
                                                <th class="text-end">Unit Price</th>
                                                <th class="text-end">Total</th>
                                                <th class="text-center">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of productReportList;let productIndex=index">
                                                <td>{{item.createdAt | date: 'dd/MM/yyyy' : 'UTC' }}</td>
                                                <td>{{item.userName}}</td>
                                                <td>{{locationList[productIndex]}}</td>
                                                <td class="text-center">{{item.quantity}}</td>
                                                <td class="text-end">{{item.itemDetail.price | number: '.2'}}</td>
                                                <td class="text-end">{{getTotalPrice(item) | number: '.2'}}</td>
                                                <td class="text-center">
                                                    <span *ngIf="item.deliveryStatus=='Received'">Confirmed</span>
                                                    <span *ngIf="item.deliveryStatus!='Received'">{{item.deliveryStatus
                                                        | formatDocType}}</span>
                                                    <span *ngIf="!item.deliveryStatus">Pending</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td><strong>Total</strong></td>
                                                <td class="text-center"><strong>{{totalQuantity}}</strong></td>
                                                <td></td>
                                                <td class="text-end"><strong>{{total | number: '.2'}}</strong></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3"
                                *ngIf="page=='1' && !loader && productReportList.length == 0">
                                <app-no-result-found></app-no-result-found>
                            </div>
                        </div>
                        <!-- products-list-table-row end -->
                    </div>
                </div>
                <div class="card mb-1 ms-2 me-2" *ngIf="filterApplied && filterForm.controls.product.value">
                    <div class="card-body p-0">
                        <!-- products-list-table-row start -->
                        <div class="row products-list-table-row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                *ngIf="productReportList.length > 0">
                                <div class="table-row table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Order Date</th>
                                                <th>Order By</th>
                                                <th>Delivery Location</th>
                                                <th *ngIf="!singleVariant">Variant</th>
                                                <th *ngIf="!singleVariant">SKU</th>
                                                <th>Order Qty</th>
                                                <th class="text-end">Unit Price</th>
                                                <th class="text-end">Total</th>
                                                <th class="text-center">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of productReportList;let productIndex=index">
                                                <td>{{item.createdAt | date: 'dd/MM/yyyy' : 'UTC' }}</td>
                                                <td><span *ngIf="item.userName">{{item.userName}}</span>
                                                    <span *ngIf="!item.userName">N/A</span>
                                                </td>
                                                <td>{{locationList[productIndex]}}</td>
                                                <td *ngIf="!singleVariant">
                                                    {{variantList[productIndex]}}</td>
                                                <td *ngIf="!singleVariant">
                                                    {{skuList[productIndex]}}</td>
                                                <td>{{item.quantity}}</td>
                                                <td class="text-end">{{item.itemDetail.price | number: '.2'}}</td>
                                                <td class="text-end">{{getTotalPrice(item) | number: '.2'}}</td>
                                                <td class="text-center">
                                                    <span *ngIf="item.deliveryStatus=='Received'">Confirmed</span>
                                                    <span *ngIf="!item.deliveryStatus">Pending</span>
                                                    <span *ngIf="item.deliveryStatus!='Received'">{{item.deliveryStatus
                                                        | formatDocType}}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td><strong>Total</strong></td>
                                                <td *ngIf="!singleVariant"></td>
                                                <td *ngIf="!singleVariant">
                                                </td>
                                                <td><strong>{{totalQuantity}}</strong></td>
                                                <td></td>
                                                <td class="text-end"><strong>{{total | number: '.2'}}</strong></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3"
                                *ngIf="page=='1' && !loader && productReportList.length == 0">
                                <app-no-result-found></app-no-result-found>
                            </div>
                        </div>
                        <!-- products-list-table-row end -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- displayFilterModalToggle start -->
<p-dialog [draggable]="false" [(visible)]="displayFilterModalToggle" [position]="position" [modal]="true"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false" [showHeader]="false"
    [style]="{width: '34vw', minHeight: '100%', maxHeight: '100%', margin: '0'}"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}">
    <div id="displayFilterModalToggle">
        <div class="modal-header">
            <h5 class="modal-title">Filter</h5>
            <i class="fas fa-times" (click)="closeFilterModalDialog()"></i>
        </div>
        <div class="body mt-3" [formGroup]="filterForm">
            <div class="row">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Product</label>
                    <select formControlName="reportType" (change)="onSelectReportType()"
                        [(ngModel)]="selectedReportType" class="form-select form-control"
                        aria-label="Default select example">
                        <option [ngValue]="null" disabled>Choose...</option>
                        <option *ngFor="let reportItem of productReportBy" [ngValue]="reportItem">
                            {{reportItem}}
                        </option>
                    </select>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2"
                    *ngIf="filterForm.controls.reportType.value == 'SKU'">
                    <label>Product SKU</label>
                    <input formControlName="sku" type="text" class="form-control" placeholder="Enter Product SKU" />
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2"
                    *ngIf="filterForm.controls.reportType.value == 'Product Name'">
                    <label>Main Category</label>
                    <select class="form-select" [(ngModel)]="selectedMainCategory" formControlName="mainCategory"
                        (change)="onSelectMainCategory()">
                        <option [ngValue]="null" disabled>Select Main Category</option>
                        <option *ngFor="let item of mainCategoryList" [ngValue]="item">{{ item.catName }}
                        </option>
                    </select>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2"
                    *ngIf="filterForm.get('mainCategory').value && filterForm.controls.reportType.value == 'Product Name'">
                    <label>Sub Category</label>
                    <select class="form-select" [(ngModel)]="selectedSubCategory" formControlName="subCategory"
                        (change)="onSelectSubCategory()">
                        <option [ngValue]="null" disabled>Select Sub Category</option>
                        <option *ngFor="let item of subCategoryList" [ngValue]="item">{{ item.catName }}
                        </option>
                    </select>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2"
                    *ngIf="filterForm.get('subCategory').value && filterForm.controls.reportType.value == 'Product Name'">
                    <label>Product Name</label>
                    <select formControlName="product" [(ngModel)]="selectedProduct" class="form-select form-control"
                        aria-label="Default select example">
                        <option [ngValue]="null" disabled>Select</option>
                        <option *ngFor="let product of productsList" [ngValue]="product">
                            {{ product.productName }}
                        </option>
                    </select>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2"
                    *ngIf="filterForm.controls.sku.value || filterForm.controls.product.value">
                    <label>Order Date</label>
                    <p-calendar [iconDisplay]="'input'" formControlName="orderedOn" [showIcon]="true"
                        inputId="icondisplay" selectionMode="range" placeholder="From-To"></p-calendar>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="d-flex justify-content-end gap-2">
            <p-button label="Cancel" styleClass="filter-cancel-btn" (click)="cancelFilter()"></p-button>
            <p-button label="Apply" styleClass="filter-apply-btn" (click)="applyFilter()"></p-button>
        </div>
    </ng-template>
</p-dialog>
<!-- displayFilterModalToggle end -->