<section id="brandListSec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 my-auto">
                <div class="card m-2">
                    <div class="card-body">
                        <!-- brand-list-heading-row start -->
                        <div class="row brand-list-heading-row">
                            <div class="col-xxl-6 col-xl-6 col-lg-4 col-md-4 col-sm-12 col-xs-12 my-auto">
                                <div class="text-center text-md-left mt-2 mt-md-0">
                                    <h2>Brand</h2>
                                    <p class="mb-2 mb-md-0">{{ brandList?.length }} Results Found</p>
                                </div>
                            </div>
                            <div class="col-xxl-6 col-xl-6 col-lg-8 col-md-8 col-sm-12 col-xs-12 my-auto">
                                <div
                                    class="has-search-d-flex justify-content-center justify-content-md-end align-items-start align-items-md-center flex-nowrap flex-md-wrap">
                                    <app-common-search (onEnter)="search($event)"
                                        class="mb-2 mb-md-0"></app-common-search>
                                    <div>
                                        <button [disabled]="!access['add']" type="button"
                                            class="btn add-btn d-none d-md-block" (click)="redirectToAddBrandPage()">
                                            Add Brand
                                        </button>
                                        <button [disabled]="!access['add']" type="button"
                                            class="btn add-btn d-block d-md-none" (click)="redirectToAddBrandPage()">
                                            <span class="material-symbols-outlined">
                                                add
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- brand-list-heading-row end -->
                @for (
                brand of brandList;
                track brand.id
                ) {
                <div class="card mb-1 ms-2 me-2 table-card">
                    <div class="card-body">
                        <!-- brand-list-table-row start -->
                        <div class="row brand-list-table-row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="table-row action-table-responsive table-responsive">
                                    <table class="table table-borderless">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <img [src]="
                                                                brand.brandLogo
                                                            " />
                                                </td>
                                                <td>
                                                    <div (click)="redirectToEditBrandPage(brand)">
                                                        <h6>{{brand.brandName}}</h6>
                                                        <label class="truncate-lines"
                                                            [innerHTML]="brand.brandDescription">
                                                            ...</label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <label>
                                                            Manufacturer
                                                            Name
                                                        </label>
                                                        <h6>
                                                            {{
                                                            brand.manufacturerName
                                                            }}
                                                        </h6>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <label>
                                                            Country of
                                                            Origin
                                                        </label>
                                                        <h6>
                                                            {{
                                                            brand.countryOfOrigin
                                                            }}
                                                        </h6>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <label>Status</label>
                                                        <h6 class="active" *ngIf="brand.status=='ACTIVE'">Active</h6>
                                                        <h6 class="inactive" *ngIf="brand.status=='INACTIVE'">Inactive
                                                        </h6>
                                                    </div>
                                                </td>
                                                <td class="text-end">
                                                    <div class="dropdown dropstart">
                                                        <i class="fas fa-ellipsis-v" id="dropdownMenuButton1"
                                                            data-bs-toggle="dropdown" aria-expanded="false"
                                                            data-bs-auto-close="inside"
                                                            data-bs-auto-close="outside"></i>
                                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li [ngClass]="{ disable: !access['edit'] }">
                                                                <a [routerLink]="[
                                                                            '/edit-brand'
                                                                        ]" [queryParams]="{
                                                                            id: brand.id,
                                                                            pk: brand.pk
                                                                        }" class="dropdown-item">
                                                                    <span
                                                                        class="material-symbols-outlined edit-icon me-3"
                                                                        title="Edit">
                                                                        edit_square
                                                                    </span>
                                                                    Edit
                                                                </a>
                                                            </li>
                                                            <li [ngClass]="{ disable: !access['delete'] }" (click)="
                                                                        deleteBrandItem(
                                                                            brand
                                                                        )
                                                                    ">
                                                                <a class="dropdown-item">
                                                                    <span class="material-symbols-outlined delete-icon"
                                                                        title="Delete">
                                                                        delete
                                                                    </span>
                                                                    Delete</a>
                                                            </li>
                                                            <li [ngClass]="{ disable: !access['edit'] }">
                                                                <a class="dropdown-item"
                                                                    (click)="changeStatus(brand.id,'Deactivate')"
                                                                    *ngIf="brand.status=='ACTIVE'">
                                                                    <span
                                                                        class="material-symbols-outlined deactivate-icon"
                                                                        title="Delete">
                                                                        block
                                                                    </span>
                                                                    Deactivate</a>
                                                                <a class="dropdown-item"
                                                                    (click)="changeStatus(brand.id,'Activate')"
                                                                    *ngIf="brand.status=='INACTIVE'">
                                                                    <span
                                                                        class="material-symbols-outlined activate-icon"
                                                                        title="Delete">
                                                                        add_task
                                                                    </span>
                                                                    Activate</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
              <app-no-result-found></app-no-result-found>
            </div> -->
                        </div>
                        <!-- brand-list-table-row end -->
                    </div>
                </div>
                }
            </div>
        </div>
    </div>
</section>

<!-- displayFilterModalToggle start -->
<p-dialog [draggable]="false" [(visible)]="displayFilterModalToggle" [position]="position" [modal]="true"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false" [showHeader]="false"
    [style]="{width: '34vw', minHeight: '100%', maxHeight: '100%', margin: '0'}"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}">
    <div id="displayFilterModalToggle">
        <div class="modal-header">
            <h5 class="modal-title">Filter</h5>
            <i class="fas fa-times" (click)="closeFilterModalDialog()"></i>
        </div>
        <div class="body mt-3">
            <div class="row">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Template title <span class="mandatory">*</span></label>
                    <input type="text" class="form-control" placeholder="Enter" />
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Subject <span class="mandatory">*</span></label>
                    <input type="text" class="form-control" placeholder="Enter" />
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Email Content <span class="mandatory">*</span></label>
                    <textarea class="form-control" rows="5" placeholder="Enter"></textarea>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="d-flex justify-content-end gap-2">
            <p-button label="Cancel" styleClass="filter-cancel-btn" (click)="closeFilterModalDialog()"></p-button>
            <p-button label="Apply" styleClass="filter-apply-btn" (click)="closeFilterModalDialog()"></p-button>
        </div>
    </ng-template>
</p-dialog>
<!-- displayFilterModalToggle end -->
<!-- deleteModalToggle start -->
<p-dialog [showHeader]="false" [(visible)]="deleteModalToggle" [style]="{width: '25vw'}" [baseZIndex]="10000"
    [modal]="true">
    <div class="body mt-3" id="deleteModalToggle">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="delete-image">
                    <img src="../../../../../assets/img/delete.png" />
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="confirm-msg">
                    <h6>Are You Sure?</h6>
                    <p>You will not be able to recover this brand!</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="confirm-btns">
                    <button type="button" class="btn cancel-btn" (click)="deleteModalToggle = false">
                        Cancel
                    </button>
                    <button type="button" class="btn delete-confirm-btn" (click)="confirmDeleteBrand()">
                        Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</p-dialog>
<!-- {/* // deleteModalToggle end */} -->