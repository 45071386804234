import { Component } from '@angular/core';

@Component({
  selector: 'app-no-result-found',
  templateUrl: './no-result-found.component.html',
  styleUrl: './no-result-found.component.scss'
})
export class NoResultFoundComponent {

}
