import { Component, OnInit } from '@angular/core';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import * as mutation from 'graphql/mutations';
import * as query from 'graphql/queries';
import slugify from '@sindresorhus/slugify';
import { uploadData } from 'aws-amplify/storage';
import amplifyconfig from 'amplifyconfiguration.json';
import { v4 as uuidv4 } from 'uuid';
import * as _ from 'underscore';
import { ApiService } from '@services/api/api.service';
import { ROUTES } from '@/redirect-routes';
import { CommonService } from '@services/common/common.service';
import { Messages } from '@/Toaster-messages';
import { Categories, CategoryTypeList } from '@/common-list';
import { CategoryType } from 'API';

@Component({
    selector: 'app-category-add',
    templateUrl: './category-add.component.html',
    styleUrl: './category-add.component.scss'
})
export class CategoryAddComponent implements OnInit {
    public Editor = ClassicEditor;
    access: any = {}

    public onReady(editor: ClassicEditor) { }
    public onChange({ editor }: ChangeEvent) { }

    addCategoryForm: FormGroup;

    mainCategoryList: any = [];
    subCategoryList: any = [];
    selectedCatType: any = null
    categoryTypeList = CategoryTypeList
    categories = Categories
    defaultCategory: any = null

    constructor(
        private awsApi: ApiService,
        private _router: Router,
        private fb: FormBuilder,
        private common: CommonService,
        private messageService: MessageService
    ) {
        this.addCategoryForm = this.fb.group({
            catType: ['', [Validators.required]],
            catName: ['', [Validators.required, Validators.pattern(/^(?!\s*$)(?=.*[a-zA-Z])[a-zA-Z0-9\s]+$/)]],
            mainCategory: [''],
            categoryImg: ['', [Validators.required]],
            description: [''],
            webBannerImg: [''],
            webBannerImgBottom: [''],
            mobBannerImg: [''],
            mobBannerImgBottom: [''],
            sequenceNumber: ['']
        });
    }

    get addCategoryFormControl() {
        return this.addCategoryForm.controls;
    }

    getAllMainCategory() {
        this.awsApi.loading.next(true);
        this.awsApi.client
            .graphql({
                query: query.listCategories,
                variables: {
                    pk: 'MAIN#'
                }
            })
            .then((response) => {
                this.mainCategoryList = [
                    ...response.data.listCategories.items
                ];
                this.awsApi.loading.next(false);
            })
            .catch((error) => {
                this.awsApi.loading.next(false);
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: Messages.SOMETHING_WENT_WRONG
                });
            });
    }

    ngOnInit(): void {
        this.getAllMainCategory();
        this.getRoleManagement()
    }

    async getRoleManagement() {
        try {
            await this.common.getPermissionsOfUser('Products', 'Manage Category');
            this.access = this.common.access;
            this.awsApi.loading.next(false);
        } catch (error) {
            this.awsApi.loading.next(false);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: Messages.SOMETHING_WENT_WRONG
            });
        }
    }

    onOptionChange() { }

    onSelectCategoryType() {
        this.addCategoryForm.controls.catName.reset()
        this.addCategoryForm.controls.sequenceNumber.reset()
        this.selectedCatType = this.addCategoryFormControl['catType'].value
        if (this.selectedCatType == this.categories.main) {
            this.mainCategoryList.sort((a: any, b: any) => a.sequenceNumber - b.sequenceNumber)
            this.addCategoryForm.patchValue({
                sequenceNumber: ((this.mainCategoryList.sort((a: any, b: any) => a.sequenceNumber - b.sequenceNumber)).slice(-1)[0].sequenceNumber) + 1
            })
        }
    }

    onSubmit() {
        this.awsApi.loading.next(true);
        if (this.selectedCatType == this.categories.sub &&
            this.addCategoryForm.valid &&
            this.addCategoryForm.value.mainCategory) {
            this.addCategory()
        }
        else if (this.selectedCatType == this.categories.main &&
            this.addCategoryForm.valid) {
            this.addCategory()
        }
        else {
            this.awsApi.loading.next(false);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: Messages.PLEASE_FILL_REQUIRED_FIELDS
            });
        }
    }

    async isCategoryAlreadyExists(): Promise<Boolean> {
        let pk = this.selectedCatType == this.categories.main ? 'MAIN#' : 'SUB#' + this.addCategoryForm.value.mainCategory
        let res = await this.common.getAllCategory({}, ['catName', 'pk', 'slug'])
        if (res.length > 0 &&
            res.some((item) => item.slug == slugify(this.addCategoryFormControl['catName'].value))) {
            return true
        }
        return false
    }

    async sequenceNumberAlreadyExists(seqNumber): Promise<Boolean> {
        let sequenceNumbers = this.mainCategoryList
            .map((item) => { return item.sequenceNumber; })
        return sequenceNumbers.includes(seqNumber)
    }

    async addCategory() {
        let seqNumberValid: boolean = true;
        try {
            let id = uuidv4();
            let str = this.addCategoryFormControl['catName'].value.toLowerCase();
            str = str.replace(/\s+/g, '');
            str = str.trim();
            const seqNumber = this.addCategoryFormControl['sequenceNumber'].value ? parseInt(this.addCategoryFormControl['sequenceNumber'].value) : null;
            if (this.selectedCatType == this.categories.main && seqNumber !== null) {
                seqNumberValid = !isNaN(seqNumber) && seqNumber > 0 && !(await this.sequenceNumberAlreadyExists(seqNumber));
            }
            if (seqNumberValid) {
                if (!await this.isCategoryAlreadyExists()) {
                    let inputData: any = {
                        slug: slugify(this.addCategoryFormControl['catName'].value),
                        catName: this.addCategoryFormControl['catName'].value,
                        catStatus: true,
                        searchKey: str,
                        categoryImg: this.addCategoryFormControl['categoryImg'].value,
                        description: this.addCategoryFormControl['description'].value,
                        id: id,
                        webBannerImg: this.addCategoryFormControl['webBannerImg'].value,
                        webBannerImgBottom: this.addCategoryFormControl['webBannerImgBottom'].value,
                        mobBannerImg: this.addCategoryFormControl['mobBannerImg'].value,
                        mobBannerImgBottom: this.addCategoryFormControl['mobBannerImgBottom'].value,
                        sequenceNumber: seqNumber
                    };
                    if (this.selectedCatType == this.categories.main) {
                        inputData['pk'] = 'MAIN#';
                        inputData['categoryType'] = CategoryType.MAIN;
                    } else {
                        inputData['pk'] = 'SUB#' + this.addCategoryForm.value.mainCategory;
                        inputData['categoryType'] = CategoryType.SUB;
                        let mainCategory = _.find(this.mainCategoryList, (item) => {
                            return item.id == this.addCategoryForm.value.mainCategory;
                        });
                        inputData['mainCategory'] = mainCategory.catName;
                        inputData['mainCategoryID'] = mainCategory.id;
                    }
                    this.awsApi.client
                        .graphql({
                            query: mutation.createCategory,
                            variables: {
                                input: {
                                    ...inputData
                                }
                            }
                        })
                        .then((response) => {
                            this.awsApi.loading.next(false);
                            this.messageService.add({
                                severity: 'success',
                                summary: 'Success',
                                detail: 'Category created successfully!'
                            });
                            this._router.navigate(['/list-category']);
                        })
                        .catch((error) => {
                            this.awsApi.loading.next(false);
                            this.messageService.add({
                                severity: 'error',
                                summary: 'Error',
                                detail: Messages.SOMETHING_WENT_WRONG
                            });
                        });
                } else {
                    this.awsApi.loading.next(false);
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: Messages.CATEGORY_ALREADY_EXISTS
                    });
                }
            } else {
                this.awsApi.loading.next(false);
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: Messages.INVALID_SEQUENCE_NUMBER
                });
            }
        } catch (error) {
            this.awsApi.loading.next(false);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: Messages.SOMETHING_WENT_WRONG
            });
        }
    }

    async fileSave(event: any, type: any) {
        this.awsApi.loading.next(true);
        let isImageValid
        if (event.target instanceof HTMLInputElement && event.target.files) {
            let file = event.target.files[0];
            let isValid = await this.common.isValidFileType(event)
            if (!isValid) { return; }
            isImageValid = type == 'web-banner' ?
                await this.common.validateImageDimensions(file, 'category', 'web') : type == 'mobile-banner' ?
                    await this.common.validateImageDimensions(file, 'category', 'mobile') : true
            if (!isImageValid) {
                this.awsApi.loading.next(false);
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: Messages.INVALID_IMAGE_SIZE
                });
                return;
            }
            try {
                this.awsApi.loading.next(false);
                let imageUrl = await this.common.getS3Url(file)
                if (type == 'catImage') {
                    this.addCategoryForm.patchValue({
                        categoryImg: imageUrl
                    });
                } else if (type == 'web-banner') {
                    this.addCategoryForm.patchValue({
                        webBannerImg: imageUrl
                    });
                } else if (type == 'mobile-banner') {
                    this.addCategoryForm.patchValue({
                        mobBannerImg: imageUrl
                    });
                }
            } catch (error) {
                this.awsApi.loading.next(false);
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: Messages.SOMETHING_WENT_WRONG
                });
            }
        }
    }

    redirectToCategoryListPage() {
        this._router.navigate([ROUTES.CATEGORY_LIST]);
    }

    deleteUrl(type) {
        if (type == 'catImage') {
            this.addCategoryForm.patchValue({
                categoryImg: ''
            });
            const fileInput = document.getElementById('category-image-upload') as HTMLInputElement;
            if (fileInput) {
                fileInput.value = '';
            }
        } else if (type == 'web-banner') {
            this.addCategoryForm.patchValue({
                webBannerImg: ''
            });
            const fileInput = document.getElementById('web-banner-image-upload') as HTMLInputElement;
            if (fileInput) {
                fileInput.value = '';
            }
        }
        else if (type == 'mobile-banner') {
            this.addCategoryForm.patchValue({
                mobBannerImg: ''
            });
            const fileInput = document.getElementById('mobile-banner-image-upload') as HTMLInputElement;
            if (fileInput) {
                fileInput.value = '';
            }
        } else if (type == 'web-banner-bottom') {
            this.addCategoryForm.patchValue({
                webBannerImgBottom: ''
            });
            const fileInput = document.getElementById('web-banner-image-bottom-upload') as HTMLInputElement;
            if (fileInput) {
                fileInput.value = '';
            }
        }
        else if (type == 'mobile-banner-bottom') {
            this.addCategoryForm.patchValue({
                mobBannerImgBottom: ''
            });
            const fileInput = document.getElementById('mobile-banner-image-bottom-upload') as HTMLInputElement;
            if (fileInput) {
                fileInput.value = '';
            }
        }
    }
}