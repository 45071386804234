<section id="unitsListSec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 my-auto">
                <div class="card m-2" *ngIf="!(edit || add)">
                    <div class="card-body">
                        <!-- units-list-heading-row start -->
                        <div class="row units-list-heading-row">
                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 my-auto">
                                <div class="text-center text-sm-left mt-2 mt-sm-0">
                                    <h2>Units</h2>
                                    <p class="mb-2 mb-sm-0">{{unitsList.length}} Results Found</p>
                                </div>
                            </div>
                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 my-auto">
                                <div
                                    class="has-search-d-flex justify-content-center justify-content-sm-end align-items-start align-items-sm-center flex-nowrap flex-sm-wrap">
                                    <div>
                                        <button [disabled]="!access['add']" type="button"
                                            class="btn add-btn mb-2 mb-sm-0" (click)="showAdd()">
                                            Add Units
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- units-list-heading-row end -->

                <div class="card units-information-card mt-2 mb-2 table-card" *ngIf="add || edit">
                    <div class="card-header ps-2">
                        <h6>Units Information</h6>
                    </div>
                    <div class="card-body" [formGroup]="unitsForm">
                        <div class="row">
                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 my-auto">
                                <label>Unit Name <span class="mandatory">*</span></label>
                                <input formControlName="unitsName" maxlength="50" type="text" class="form-control"
                                    placeholder="Enter">
                                <span class="text-danger"
                                    *ngIf="unitsForm.controls.unitsName.touched && !unitsForm.controls.unitsName?.valid">
                                    <div *ngIf="unitsForm.controls.unitsName.errors['required']">
                                        Unit name is required.
                                    </div>
                                    <div *ngIf="unitsForm.controls.unitsName.errors['pattern']">
                                        Invalid unit name.
                                    </div>
                                </span>
                            </div>
                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 my-auto">
                                <label>Type <span class="mandatory">*</span></label>
                                <select class="form-select form-control" formControlName="type"
                                    [(ngModel)]="defaultType" aria-label="Default select example">
                                    <option [ngValue]="null" disabled>Choose..</option>
                                    <option *ngFor="let unitType of unitsTypeList" [ngValue]="unitType.id">
                                        {{ unitType.id }} </option>
                                </select>
                                <span class="text-danger"
                                    *ngIf="unitsForm.controls.type.touched && !unitsForm.controls.type?.valid">
                                    <div *ngIf="unitsForm.controls.type.errors['required']">
                                        Type is required.
                                    </div>
                                </span>
                            </div>
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 my-3">
                                <div class="text-end">
                                    <button type="button" *ngIf="edit || add" class="btn cancel-btn"
                                        (click)="redirectTounitsList()">Cancel</button>&nbsp;
                                    <button type="button" [disabled]="unitsForm.invalid && !access['edit']" *ngIf="edit"
                                        class="btn save-btn" (click)="editunits()">Update</button>
                                    <button type="button" [disabled]="unitsForm.invalid && !access['add']" *ngIf="add"
                                        class="btn save-btn" (click)="addunits()">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="unitsList.length>0 && !(add || edit)">
                    <div class="card table-card mb-1" *ngFor="let unitsDetail of unitsList">
                        <div class="card-body">
                            <!-- units-list-table-row start -->
                            <div class="row units-list-table-row">
                                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="table-row action-table-responsive table-responsive">
                                        <table class="table table-borderless">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div (click)="showEdit(unitsDetail)">
                                                            <label>Display Unit</label>
                                                            <h6 nowrap>{{unitsDetail.value}}</h6>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div (click)="showEdit(unitsDetail)">
                                                            <label>Type</label>
                                                            <h6 nowrap>{{ unitsDetail.type | formatDocType }}</h6>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <label>Status</label>
                                                            <h6 class="active" *ngIf="unitsDetail.status=='ACTIVE'">
                                                                Active
                                                            </h6>
                                                            <h6 class="inactive" *ngIf="unitsDetail.status=='INACTIVE'">
                                                                Inactive</h6>
                                                        </div>
                                                    </td>
                                                    <td class="text-end">
                                                        <div class="dropdown dropstart">
                                                            <i class="fas fa-ellipsis-v" id="dropdownMenuButton1"
                                                                data-bs-toggle="dropdown" aria-expanded="false"
                                                                data-bs-auto-close="inside"
                                                                data-bs-auto-close="outside"></i>
                                                            <ul class="dropdown-menu"
                                                                aria-labelledby="dropdownMenuButton1">
                                                                <li [ngClass]="{ disable: !access['edit'] }">
                                                                    <a class="dropdown-item"
                                                                        (click)="showEdit(unitsDetail)">
                                                                        <span
                                                                            class="material-symbols-outlined edit-icon me-3"
                                                                            title="Edit">
                                                                            edit_square
                                                                        </span>
                                                                        Edit
                                                                    </a>
                                                                </li>
                                                                <li [ngClass]="{ disable: !access['edit'] }">
                                                                    <a class="dropdown-item"
                                                                        *ngIf="unitsDetail.status=='INACTIVE'"
                                                                        (click)="editStatus(unitsDetail.id,'Activate')">
                                                                        <span
                                                                            class="material-symbols-rounded activate-icon"
                                                                            title="Activate">
                                                                            add_task
                                                                        </span>
                                                                        Activate</a>
                                                                    <a class="dropdown-item"
                                                                        *ngIf="unitsDetail.status=='ACTIVE'"
                                                                        (click)="editStatus(unitsDetail.id,'Deactivate')">
                                                                        <span
                                                                            class="material-symbols-rounded deactivate-icon"
                                                                            title="Deactivate">
                                                                            block
                                                                        </span>
                                                                        Deactivate</a>
                                                                </li>
                                                                <li [ngClass]="{ disable: !access['delete'] }">
                                                                    <a class="dropdown-item"
                                                                        (click)="deleteunits(unitsDetail)">
                                                                        <span
                                                                            class="material-symbols-outlined delete-icon"
                                                                            title="Delete">
                                                                            delete
                                                                        </span>
                                                                        Delete</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
              <app-no-result-found></app-no-result-found>
            </div> -->
                            </div>
                            <!-- units-list-table-row end -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- deleteModalToggle start -->
<p-dialog [showHeader]="false" [(visible)]="deleteModalToggle" [style]="{width: '25vw'}" [baseZIndex]="10000"
    [modal]="true">
    <div class="body mt-3" id="deleteModalToggle">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="delete-image">
                    <img src="../../../../assets/img/delete.png" />
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="confirm-msg">
                    <h6>Are You Sure?</h6>
                    <p>You will not be able to recover this units!</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="confirm-btns">
                    <button type="button" class="btn cancel-btn" (click)="deleteModalToggle = false">
                        Cancel
                    </button>
                    <button type="button" class="btn delete-confirm-btn" [disabled]="!access['delete']" *ngIf="add">
                        Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</p-dialog>
<!-- {/* // deleteModalToggle end */} -->