import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import * as query from 'graphql/queries';
import { ROUTES } from '@/redirect-routes';
import * as mutation from 'graphql/mutations';
import { MessageService } from 'primeng/api';
import { CommonService } from '@services/common/common.service';
import { productStatus } from 'API';
import { Messages } from '@/Toaster-messages';

@Component({
  selector: 'app-country-list',
  templateUrl: './country-list.component.html',
  styleUrl: './country-list.component.scss'
})
export class CountryListComponent implements OnInit {
  position: any;
  displayFIlterModalToggle = false;
  deleteModalToggle: any;
  filterObject: any = {}
  searchKey: any
  searchText: any = ''
  countryList: any = [];
  selectedCountry: any
  access: any = {}

  constructor(
    private common: CommonService,
    private router: Router,
    private awsApi: ApiService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.position = 'right';
    this.getRoleManagement()
    this.getAllCountry();
  }

  async getRoleManagement() {
    this.awsApi.loading.next(true);
    try {
      await this.common.getPermissionsOfUser('Settings', 'Manage Country');
      this.access = this.common.access;
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  getAllCountry() {
    this.awsApi.loading.next(true);
    this.awsApi.client
      .graphql({
        query: query.listCountries,
        variables: {
          pk: 'COUNTRY#',
          filter: this.filterObject,
          limit: 9999,
          nextToken: null,
          sortDirection: null
        }
      })
      .then((response) => {
        this.countryList = response.data.listCountries.items.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        this.awsApi.loading.next(false);
      })
      .catch((error) => {
        this.awsApi.loading.next(false);
      });
  }

  showNewEmailTemplateModalDialog(position: string) {
    this.position = position;
    this.displayFIlterModalToggle = true;
  }

  search(searchText: string) {
    this.searchText = searchText;
    if (this.searchText != '') {
      this.searchKey = this.searchText.toLowerCase();
      this.searchKey = this.searchKey.trim().replace(/\s+/g, '');
      this.filterObject['searchKey'] = { contains: this.searchKey };
      this.getAllCountry();
    } else {
      delete this.filterObject['searchKey'];
      this.searchKey = null;
      this.getAllCountry();
    }
  }

  closeNewEmailTemplateModalDialog() {
    this.displayFIlterModalToggle = false;
  }
  showDeleteCountryModalDialog(item: any, category: any) {
    this.deleteModalToggle = true;
    this.selectedCountry = category
  }
  redirectToAddAttributePage() {
    this.router.navigate(['/add-country']);
  }
  redirectToCountryDetails(country) {
    this.router.navigate([ROUTES.COUNTRY_EDIT], { queryParams: { id: country.id, pk: country.pk, slug: country.slug } });
  }

  async deleteCountry() {
    try {
      this.deleteModalToggle = false
      let filter = {}
      filter['countryID'] = { eq: this.selectedCountry.id }
      filter['status'] = { ne: productStatus.deleted }
      if (!await this.common.isAnyProductAvailable(filter, 'country')) {
        let data = {
          pk: this.selectedCountry.pk,
          slug: this.selectedCountry.slug
        }
        this.awsApi.client
          .graphql({
            query: mutation.deleteCountry,
            variables: {
              input: {
                ...data
              }
            }
          })
          .then((response) => {
            this.awsApi.loading.next(false);
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Country deleted successfully!'
            });
            this.ngOnInit();
          })
          .catch((error) => {
            this.awsApi.loading.next(false);
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: Messages.SOMETHING_WENT_WRONG
            });
          });
      }
    } catch (error) { }
  }
}