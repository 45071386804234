import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {generateClient} from 'aws-amplify/api';
import {BehaviorSubject, throwError} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    client = generateClient();
    public loading = new BehaviorSubject(false);
    constructor() {}
}
