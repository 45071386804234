import { Component } from '@angular/core';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import * as query from 'graphql/queries';
import * as mutation from 'graphql/mutations';
import { ApiService } from '@services/api/api.service';
import { Messages } from '@/Toaster-messages';
import { ROUTES } from '@/redirect-routes';
import { CommonService } from '@services/common/common.service';
@Component({
  selector: 'app-create-content-section',
  templateUrl: './create-content-section.component.html',
  styleUrl: './create-content-section.component.scss'
})
export class CreateContentSectionComponent {
  viewContent: boolean = true
  addContent: boolean = false
  editCont: boolean = false
  selectedContent: any = []
  contentId: any
  contentAdded: any
  access: any = {}
  addContentForm = this.formbuilder.group({
    title: ['', [Validators.required]],
    content: ['', [Validators.required]]
  })

  constructor(private router: Router, private messageService: MessageService, private formbuilder: FormBuilder,
    private activatedRoute: ActivatedRoute, private awsApi: ApiService, private common: CommonService) { }
  ngOnInit(): void {
    this.startingFunction()
  }

  async startingFunction() {
    this.getRoleManagement()
    this.viewContent = true
    this.addContent = false
    this.editCont = false
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.contentId = params.get('id') ?? ''
      if (this.contentId != '')
        this.awsApi.loading.next(true);
      Promise.all([this.getContent()])
        .then((response) => {
          this.awsApi.loading.next(false);
          this.selectedContent = response[0].data.getContent
        }).catch((error) => {

          this.awsApi.loading.next(false);
        });
    })
  }

  async getRoleManagement() {
    try {
      await this.common.getPermissionsOfUser('Others', 'Contents');
      this.access = this.common.access;
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  async getContent() {
    return await this.awsApi.client.graphql({
      query: query.getContent,
      variables: {
        pk: 'CONT#',
        id: this.contentId
      }
    })
  }

  public Editor = ClassicEditor;
  public onReady(editor: ClassicEditor) { }
  public onChange({ editor }: ChangeEvent) { }

  redirectToContentListPage() {
    this.router.navigate([ROUTES.LIST_CONTENT]);
  }

  showaddNewContent() {
    this.addContentForm.reset()
    this.viewContent = false
    this.addContent = true
    this.editCont = false
  }

  showContent() {
    this.viewContent = true
    this.editCont = false
    this.addContent = false
  }

  refresh() {
    this.addContentForm.reset()
  }

  updateContent(dataToSave) {
    this.awsApi.loading.next(true);
    let data = {
      pk: 'CONT#',
      id: this.selectedContent.id,
      contentName: this.selectedContent.contentName,
      title: dataToSave.title,
      content: dataToSave.content,
      searchKey: dataToSave.searchKey,
      updatedAt: new Date().toISOString()
    };
    this.awsApi.client
      .graphql({
        query: mutation.updateContent,
        variables: {
          input: {
            ...data
          }
        }
      })
      .then((response) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: dataToSave.message
        });
        this.startingFunction()
      })
      .catch((error) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      });
  }

  addNewContent() {
    let dataToSave = {
      title: this.addContentForm.controls.title.value,
      content: this.addContentForm.controls.content.value,
      searchKey: this.selectedContent.contentName.replace(/\s/g, "").toLowerCase() + '#' + this.addContentForm.controls.title.value.replace(/\s/g, "").toLowerCase(),
      message: Messages.CONTENT_ADDED
    }
    this.updateContent(dataToSave)
    this.addContentForm.reset()
  }

  saveContent() {
    let dataToSave = {
      title: this.addContentForm.controls.title.value,
      content: this.addContentForm.controls.content.value,
      searchKey: this.selectedContent.contentName.replace(/\s/g, "").toLowerCase() + '#' + this.addContentForm.controls.title.value.replace(/\s/g, "").toLowerCase(),
      message: Messages.CONTENT_SAVED
    }
    this.updateContent(dataToSave)
    this.addContentForm.reset()
  }

  editContent() {
    this.viewContent = false
    this.addContent = false
    this.editCont = true
    this.autoFillEditContentData()
  }

  autoFillEditContentData() {
    this.addContentForm.patchValue({
      title: this.selectedContent.title,
      content: this.selectedContent.content
    })
  }

  deleteContent() {
    let dataToSave = {
      title: null,
      content: null,
      searchKey: this.selectedContent.contentName,
      message: Messages.CONTENT_DELETED
    }
    this.updateContent(dataToSave)
  }
}