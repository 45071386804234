import { Component } from '@angular/core';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import * as query from 'graphql/queries';
import * as mutation from 'graphql/mutations';
import { ApiService } from '@services/api/api.service';
import { Messages } from '@/Toaster-messages';
import { ROUTES } from '@/redirect-routes';
import _ from 'underscore';
import { CommonService } from '@services/common/common.service';

@Component({
  selector: 'app-create-faq-section',
  templateUrl: './create-faq-section.component.html',
  styleUrl: './create-faq-section.component.scss'
})
export class CreateFaqSectionComponent {
  public Editor = ClassicEditor;
  viewFaq: boolean = true
  addFaq: boolean = false
  editFAQ: boolean = false
  selectedFaq: any = []
  faqSections: any = []
  selectedIndex: any
  faqId: any
  faqAdded: any
  access: any = {}
  addFaqForm = this.formbuilder.group({
    query: ['', [Validators.required]],
    answer: ['', [Validators.required]]
  })
  constructor(private router: Router, private common: CommonService, private messageService: MessageService, private formbuilder: FormBuilder, private activatedRoute: ActivatedRoute, private awsApi: ApiService) { }
  ngOnInit() {
    this.startingFunction()
  }

  async startingFunction() {
    this.getRoleManagement()
    this.viewFaq = true
    this.addFaq = false
    this.editFAQ = false
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.faqId = params.get('id') ?? ''
      if (this.faqId != '')
        this.awsApi.loading.next(true);
      Promise.all([this.GetFaq()])
        .then((response) => {
          this.awsApi.loading.next(false);
          this.selectedFaq = response[0].data.getFAQ
          this.GetFaqSections()
        }).catch((error) => {

          this.awsApi.loading.next(false);
        });
    })
  }

  async getRoleManagement() {
    try {
      await this.common.getPermissionsOfUser('Others', 'FAQ');
      this.access = this.common.access;
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  GetFaqSections() {
    this.faqSections = []
    if (this.selectedFaq?.FAQSections) {
      _.each(this.selectedFaq?.FAQSections, (item) => {
        delete item.__typename
        this.faqSections.push(item)
      })
    }
  }

  async GetFaq() {
    return await this.awsApi.client.graphql({
      query: query.getFAQ,
      variables: {
        pk: 'FAQ#',
        id: this.faqId
      }
    })
  }
  redirectToFaqListPage() {
    this.router.navigate([ROUTES.LIST_FAQ]);
  }

  showAddFaq() {
    this.addFaqForm.reset()
    this.viewFaq = false
    this.addFaq = true
    this.editFAQ = false
  }

  ShowFaq() {
    this.viewFaq = true
    this.editFAQ = false
    this.addFaq = false
  }

  Refresh() {
    this.addFaqForm.reset()
    this.startingFunction()
  }

  updateFaq(dataToSave) {
    if (dataToSave.type == 'add') {
      this.faqSections.push({
        question: this.addFaqForm.controls.query.value,
        answer: this.addFaqForm.controls.answer.value
      })
    }
    if (dataToSave.type == 'edit') {
      this.faqSections[this.selectedIndex].question = this.addFaqForm.controls.query.value,
        this.faqSections[this.selectedIndex].answer = this.addFaqForm.controls.answer.value
    }
    this.awsApi.loading.next(true)
    let data = {
      pk: 'FAQ#',
      id: this.selectedFaq.id,
      FAQSections: this.faqSections,
      updatedAt: new Date().toISOString()
    };
    this.awsApi.client
      .graphql({
        query: mutation.updateFAQ,
        variables: {
          input: {
            ...data
          }
        }
      })
      .then((response) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: dataToSave.message
        });
        this.startingFunction()
      })
      .catch((error) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      });
  }

  AddFaq() {
    let dataToSave = {
      type: 'add',
      message: Messages.FAQ_ADDED
    }
    this.updateFaq(dataToSave)
    this.addFaqForm.reset()
  }

  SaveFaq() {
    let dataToSave = {
      type: "edit",
      message: Messages.FAQ_SAVED
    }
    this.updateFaq(dataToSave)
    this.addFaqForm.reset()
  }

  editFaq(index: any) {
    this.selectedIndex = index
    this.viewFaq = false
    this.addFaq = false
    this.editFAQ = true
    this.autoFillEditFaqData()
  }

  autoFillEditFaqData() {
    this.addFaqForm.patchValue({
      query: this.faqSections[this.selectedIndex].question,
      answer: this.faqSections[this.selectedIndex].answer
    })
  }

  deleteFaq(index) {
    this.faqSections.splice(index, 1)
    let dataToSave = {
      message: Messages.FAQ_DELETED
    }
    this.updateFaq(dataToSave)
  }

  public onReady(editor: ClassicEditor) { }
  public onChange({ editor }: ChangeEvent) { }
}