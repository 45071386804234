import { OrderItemStatusList, OrderReportTypeList, OrderStatusList, PaymentMethodList } from '@/common-list';
import { Messages } from '@/Toaster-messages';
import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import { CommonService } from '@services/common/common.service';
import { ExcelService } from '@services/common/excel.service';
import { UserAuthService } from '@services/user-auth/user-auth.service';
import * as query from 'graphql/queries';
import { MessageService } from 'primeng/api';
import { ROUTES } from '@/redirect-routes';

@Component({
  selector: 'app-orders-report-list',
  templateUrl: './orders-report-list.component.html',
  styleUrl: './orders-report-list.component.scss'
})
export class OrdersReportListComponent {
  maxDate: any
  mainCategoryList: any = [];
  subCategoryList: any = [];
  orderReportDataList: any = []
  type: any
  value: number = 5;
  position: any;
  hide: boolean = false
  displayFilterModalToggle = false
  deleteModalToggle: any
  orderReportList: any = []
  orderResponse: any = []
  isSearchEnabled = false
  copyOrderReportList: any
  summaryStatusList = OrderStatusList;
  detailsStatusList = OrderItemStatusList
  orderReportTypeList = OrderReportTypeList
  header: any
  page: any
  pageSize: any
  loading: boolean
  endOfData: boolean
  filterApplied: boolean = false
  viewAccess: any = false
  today: any = new Date();
  data = {}
  thirtyDaysAgo = new Date((new Date()).setDate(this.today.getDate() - 30))
  companyName: any = ''
  loader: boolean = true

  filterForm = this.fb.group({
    searchText: [''],
    reportType: [''],
    summaryOrderedOn: [],
    detailsOrderedOn: [],
    summaryStatus: [],
    detailStatus: [],
    mainCategory: [],
    subCategory: []
  })

  constructor(private router: Router, private common: CommonService, public auth: UserAuthService, private fb: FormBuilder,
    private excelService: ExcelService, private awsApi: ApiService, private messageService: MessageService, private route: ActivatedRoute) { }

  async ngOnInit(): Promise<void> {
    this.viewAccess = this.route.snapshot.queryParamMap?.get('view') ?? false;
    if (this.viewAccess) {
      Promise.all([this.getAllMainCategory(), this.common.getCompanyName()])
        .then((result: any) => {
          this.mainCategoryList = result[0].data.listCategories.items;
          this.companyName = result[1]
          this.filterForm.patchValue({
            reportType: this.orderReportTypeList.details
          })
          this.header = 'Order Details'
          this.maxDate = new Date()
          this.position = 'right';
        })
        .catch((error) => { });
    }
    else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.ACCESS_DENIED
      });
    }
  }

  onSelectMainCategory() {
    this.subCategoryList = [];
    this.getAllSubCategory(this.filterForm.controls.mainCategory.value.id)
      .then((result) => {
        this.subCategoryList = result.data.listCategories.items;
      })
      .catch((error) => {
      });
  }

  getAllMainCategory() {
    return this.awsApi.client.graphql({
      query: query.listCategories,
      variables: {
        pk: 'MAIN#'
      }
    });
  }

  getAllSubCategory(mainCategoryID) {
    return this.awsApi.client.graphql({
      query: query.listCategories,
      variables: {
        pk: 'SUB#' + mainCategoryID
      }
    });
  }

  async startingFunction() {
    this.awsApi.loading.next(true);
    this.loader = this.awsApi.loading.getValue()
    this.orderReportList = [], this.orderResponse = [], this.orderReportDataList = []
    this.header = this.data['type'] == this.orderReportTypeList.summary ? 'Order Summary' : 'Order Details'
    this.common.orderReports(this.data, await this.common.getHeader()).subscribe(async (result) => {
      if (result.status) {
        this.orderReportList = result.orderreport
          .sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        this.initializePagination()
      }
    });
  }

  initializePagination() {
    this.page = 1;
    this.pageSize = 14;
    this.loading = false;
    this.endOfData = false;
    this.loadData()
  }

  loadData() {
    if (this.orderReportList.length > 0) {
      this.loading = true;
    }
    const startIndex = (this.page - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    setTimeout(() => {
      this.orderReportDataList = this.orderReportList.slice(startIndex, endIndex);
      this.awsApi.loading.next(false);
      this.loader = this.awsApi.loading.getValue()
      this.loading = false;
    }, 1000);
  }

  loadMore() {
    this.page++
    // Loading more data
    this.loading = true;
    const startIndex = (this.page - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    setTimeout(() => {
      this.orderReportDataList = this.orderReportDataList.concat(this.orderReportList.slice(startIndex, endIndex));
      this.loading = false;

      if (this.orderReportDataList.length >= this.orderReportList.length) {
        this.endOfData = true;
      }
    }, 2000);
  }

  onScroll() {
    if (!this.loading && !this.endOfData) {
      const container = document.querySelector('.scroll-container');
      if (
        container &&
        container.scrollHeight - container.scrollTop === container.clientHeight
      ) {
        this.loadMore();
      }
    }
  }

  calculateTotal(price, quantity, taxAmount): number {
    const parsedTaxAmount = parseFloat(taxAmount)
    return (parseFloat(price) * parseFloat(quantity)) + parsedTaxAmount;
  }

  applyFilter() {
    this.orderReportList = [], this.orderResponse = [], this.orderReportDataList = []
    this.filterApplied = false
    this.data = {}
    this.displayFilterModalToggle = false;
    this.data['type'] = this.filterForm.controls.reportType.value
    this.header = (this.data['type'] === this.orderReportTypeList.summary) ? 'Order Summary' : 'Order Details'
    if (this.data['type'] == this.orderReportTypeList.details) {
      this.filterForm.controls.summaryStatus.reset()
      this.filterForm.controls.summaryOrderedOn.reset()
    }
    else {
      this.filterForm.controls.detailStatus.reset()
      this.filterForm.controls.detailsOrderedOn.reset()
      this.filterForm.controls.mainCategory.reset()
      this.filterForm.controls.subCategory.reset()
    }
    this.filterOrderedOn()
    this.filterMainCategory();
    this.filterSubCategory();
    this.filterStatus();
    if (this.filterApplied) {
      this.startingFunction()
    }
  }

  filterOrderedOn() {
    let filterDateValue = this.data['type'] == this.orderReportTypeList.details ?
      this.filterForm.controls.detailsOrderedOn.value : this.filterForm.controls.summaryOrderedOn.value
    if (filterDateValue?.length == 2 && filterDateValue[0] != null) {
      this.filterApplied = true
      const startDate = formatDate(new Date(filterDateValue[0]), 'yyyy-MM-dd', 'en-US');
      this.data['fromdate'] = startDate
      if (filterDateValue[1] != null) {
        let endDate = formatDate(new Date(filterDateValue[1]), 'yyyy-MM-dd', 'en-US');
        this.data['todate'] = endDate
      }
      this.copyOrderReportList = this.orderReportList
    }
  }

  filterStatus() {
    let selectedStatusArray = this.data['type'] === this.orderReportTypeList.details ?
      this.filterForm.controls.detailStatus.value : this.filterForm.controls.summaryStatus.value;
    if (selectedStatusArray?.length > 0) {
      this.filterApplied = true
      const selectedValues = selectedStatusArray.map((item: any) => item.value).join(',');
      this.orderReportList = [];
      this.data['status'] = selectedValues;
    }
  }

  filterMainCategory() {
    if (this.filterForm.controls.mainCategory?.value) {
      this.filterApplied = true
      this.data['mainCategoryId'] = this.filterForm.controls.mainCategory?.value.id
    }
  }

  filterSubCategory() {
    if (this.filterForm.controls.subCategory?.value) {
      this.filterApplied = true
      this.data['subCategoryId'] = this.filterForm.controls.subCategory?.value.id
    }
  }

  exportToExcel() {
    let obj = []
    let exel_sheet_data = []
    let data: any = {}
    const header = this.header == 'Order Summary' ?
      ['Order Ref', 'Customer Name', 'Ordered On', 'Total Products', 'Tax', 'Item Total', 'Payment Method', 'Status'] :
      ['Order Ref', 'Customer Name', 'Product Name', 'Qty', 'Unit Price', 'Tax', 'Total', 'Ordered On', 'Status']

    let title = this.header == 'Order Summary' ? 'Order Summary Report List of ' + this.companyName : 'Order Details Report List of ' + this.companyName
    let worksheet = this.header == 'Order Summary' ? 'Order-Summary List' : 'Order-Details List'
    let merge_cells = this.header == 'Order Summary' ? 'A1:H2' : 'A1:I2'
    let footer_row = this.header == 'Order Summary' ? this.companyName + ' Order-Summary-Report List.' : this.companyName + ' Order-Details-Report List.'
    let filename = this.header == 'Order Summary' ? 'Order-Summary-Report_List' : 'Order-Details-Report_List'

    let excel_array = [
      data['header'] = header,
      data['title'] = title,
      data['worksheet'] = worksheet,
      data['merge_cells'] = merge_cells,
      data['footer_row'] = footer_row,
      data['filename'] = filename
    ]
    const date = new Date();
    const paymentMethods = PaymentMethodList
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit', month: 'short', year: 'numeric'
    }).replace(/ /g, '-');
    if (this.header == 'Order Summary') {
      for (var i in this.orderReportList) {
        let statusFormatted = this.summaryStatusList.find((item) => item.value === this.orderReportList[i].status) ?
          this.summaryStatusList.find((item) => item.value === this.orderReportList[i].status).text : 'N/A'
        obj = [
          this.orderReportList[i].id.toString(),
          (this.orderReportList[i].userName == '' ||
            this.orderReportList[i].userName == ' ' ||
            this.orderReportList[i].userName == null) ? 'N/A' : this.orderReportList[i].userName.toString(),
          (this.common.dateInReverse(this.orderReportList[i].createdAt)).toString(),
          this.orderReportList[i].totalProducts.toString(),
          Number(this.orderReportList[i].orderSummary.tax).toFixed(2),
          Number(this.orderReportList[i].totalAmount).toFixed(2),
          this.orderReportList[i].paymentDetails?.paymentMethod ?
            paymentMethods.find((item) => item.value == this.orderReportList[i].paymentDetails?.paymentMethod).valueToShow : 'N/A',
          statusFormatted.toString()
        ]
        exel_sheet_data.push(obj)
      }
    }
    else {
      for (var i in this.orderReportList) {
        obj = [this.orderReportList[i].pk.split('#')[1].toString(),
        (this.orderReportList[i].userName == '' ||
          this.orderReportList[i].userName == ' ' ||
          this.orderReportList[i].userName == null) ? 'N/A' : this.orderReportList[i].userName.toString(),
        this.orderReportList[i].itemDetail.productName.toString(),
        this.orderReportList[i].quantity.toString(),
        Number(this.orderReportList[i].itemDetail.price).toFixed(2),
        Number(this.orderReportList[i].taxAmount).toFixed(2),
        Number(this.calculateTotal(this.orderReportList[i].itemDetail.price, this.orderReportList[i].quantity, this.orderReportList[i].taxAmount)).toFixed(2),
        (this.common.dateInReverse(this.orderReportList[i].createdAt)).toString(),
        this.orderReportList[i].deliveryStatus.toString() == 'OutForDelivery' ? 'Out For Delivery' : this.orderReportList[i].deliveryStatus.toString()
        ]
        exel_sheet_data.push(obj)
      }
    }
    this.excelService.exportAsExcelFile(exel_sheet_data, formattedDate, excel_array);
  }

  showFilterModalDialog(position: string) {
    this.position = position;
    this.displayFilterModalToggle = true;
  }
  closeFilterModalDialog() {
    this.displayFilterModalToggle = false;
  }
  showDeleteGeneralQueryModalDialog(item: any) {
    this.deleteModalToggle = true;
  }

  cancelFilter() {
    this.displayFilterModalToggle = false;
    this.data = {}
    this.filterApplied = false
    this.filterForm.reset()
    this.filterForm.patchValue({
      reportType: this.orderReportTypeList.details
    })
    this.initializePagination()
    this.startingFunction()
  }

  redirectToReportListPage() {
    this.router.navigate([ROUTES.REPORTS_LIST])
  }
}