import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import { Messages } from '@/Toaster-messages';
import * as mutation from 'graphql/mutations';
import { MessageService } from 'primeng/api';
import { ROUTES } from '@/redirect-routes';
import { FormBuilder } from '@angular/forms';
import { CommonService } from '@services/common/common.service';

@Component({
  selector: 'app-all-notifications',
  templateUrl: './all-notifications.component.html',
  styleUrl: './all-notifications.component.scss'
})
export class AllNotificationsComponent implements OnInit {
  deleteModalToggle: boolean
  notificationList: any = []
  copyNotificationList: any = []
  deleteNotification: any
  filterObject: any = {}
  filterForm = this.fb.group({
    date: ['']
  })

  constructor(private router: Router, private common: CommonService, private awsApi: ApiService, private messageService: MessageService, private fb: FormBuilder) { }
  ngOnInit(): void {
    this.getAllNotification()
  }

  deleteConfirmationModal(item: any) {
    this.deleteModalToggle = true;
    this.deleteNotification = item
  }

  async getAllNotification() {
    this.awsApi.loading.next(true);
    Promise.all([await this.common.getNotifications(this.filterObject)]).then((response) => {
      this.notificationList = response[0].sort((a: any, b: any) => new Date(b.DateTime).getTime() - new Date(a.DateTime).getTime())
      this.copyNotificationList = [...this.notificationList]
      this.awsApi.loading.next(false);
    }).catch((error) => {
      this.awsApi.loading.next(false);
    });
  }

  applyFilter() {
    const dateValue = this.filterForm.controls.date.value;
    if (dateValue) {
      this.filterObject['DateTime'] = { contains: dateValue.toString() }
    } else {
      delete this.filterObject['DateTime'];
    }
    this.getAllNotification();
  }

  getToday() {
    return (new Date().toISOString()).split('T')[0]
  }

  readNotification() {
    this.awsApi.loading.next(true);
    this.notificationList = this.copyNotificationList.filter((arr: any) => {
      return arr.notificationStatus == 2;
    }).sort((a: any, b: any) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
    this.awsApi.loading.next(false);
  }

  unreadNotification() {
    this.awsApi.loading.next(true);
    this.notificationList = this.copyNotificationList.filter((arr: any) => {
      return arr.notificationStatus == 1;
    }).sort((a: any, b: any) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
    this.awsApi.loading.next(false);
  }

  gotoUrl(item: any) {
    this.awsApi.loading.next(true);
    if (item) {
      let data = {
        notificationStatus: 2, pk: item.pk, id: item.id,
        updatedAt: new Date().toISOString()
      }
      this.awsApi.client
        .graphql({
          query: mutation.updateNotifications,
          variables: {
            input: {
              ...data
            }
          }
        }).catch((error) => { });
      const { Title, orderID, referenceID } = item;
      if (Title === 'Order Delivered' || Title === 'Orderitem cancelled' || Title === 'Order Confirmed') {
        this.router.navigate([ROUTES.ORDER_DETAILS], { queryParams: { orderId: orderID } })
      } else if (Title === 'Return Cancelled' || Title === 'Return Item Received') {
        this.router.navigate([ROUTES.RETURN_ITEM_DETAILS], { queryParams: { orderId: orderID, orderItemId: referenceID } });
      } else if (Title === 'Abusive Reported') {
        this.router.navigate([ROUTES.CUSTOMER_REVIEW], { queryParams: { reviewId: referenceID } });
      } else if (Title === 'Product Out Of Stock') {
        this.router.navigate([`edit-product`], { queryParams: { id: referenceID.split('#')[0], scId: referenceID.split('#')[1], type: 'edit' } });
      }
    }
    this.awsApi.loading.next(false);
  }

  confirmDeleteNotification() {
    this.deleteModalToggle = false;
    let data = {
      pk: this.deleteNotification.pk,
      id: this.deleteNotification.id
    };
    this.awsApi.client
      .graphql({
        query: mutation.deleteNotifications,
        variables: {
          input: {
            ...data
          }
        }
      })
      .then((response) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Notification deleted successfully!'
        });
        this.ngOnInit();
      })
      .catch((error) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      });
  }
}
