<pf-dropdown [hideArrow]="true">
    <div slot="head" (click)="setRedirectedToDetailsPage()">
        <a class="nav-link">
            <i class="far fa-bell"></i>
            <span class="badge navbar-badge">{{unReadNotificationLength}}</span>
        </a>
    </div>
    <div slot="body" *ngIf="isPopupVisible" class="notification-body">
        <span class="dropdown-item dropdown-header d-flex align-items-center">Notifications <i
                class="fas fa-times ms-auto" (click)="hidePopup()"></i></span>
        <div class="dropdown-divider"></div>
        <ul class="nav nav-pills ps-2 pe-2" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
                <button class="nav-link active" id="pills-all-tab" data-bs-toggle="pill" data-bs-target="#pills-all"
                    type="button" role="tab" aria-controls="pills-all" aria-selected="true"
                    (click)="getAllNotification()">All <span class="badge">{{notificationLength}}</span></button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="pills-read-tab" data-bs-toggle="pill" data-bs-target="#pills-read"
                    type="button" role="tab" aria-controls="pills-read" aria-selected="false"
                    (click)="unreadNotification()">Unread<span
                        class="badge">{{unReadNotificationLength}}</span></button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="pills-unread-tab" data-bs-toggle="pill" data-bs-target="#pills-unread"
                    type="button" role="tab" aria-controls="pills-unread" aria-selected="false"
                    (click)="readNotification()">Read <span class="badge">{{readNotificationLength}}</span></button>
            </li>
            <li class="nav-item" role="presentation" *ngIf="copyNotificationList.length > 5">
                <button class="nav-link view-all-btn" type="button" (click)="redirectToAllNotificationsPage()">
                    View All</button>
            </li>
        </ul>
        <div class="tab-content ps-2 pe-2" id="pills-tabContent" *ngIf="!redirectedToDetailsPage">
            <div class="tab-pane fade show active" id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab">
                <div class="d-flex gap-2 p-2 divider" *ngFor="let item of notificationList">
                    <div class="notify-image">
                        <img *ngIf="item.Title=='Order Delivered'"
                            src="../../../../../../assets/img/Return Item Received.png">
                        <img *ngIf="item.Title=='Return Item Received'"
                            src="../../../../../../assets/img/dashboardRefundOrders.png">
                        <img *ngIf="item.Title=='Orderitem cancelled'"
                            src="../../../../../../assets/img/Order Cancelled.png">
                        <img *ngIf="item.Title=='Order Confirmed'"
                            src="../../../../../../assets/img/Order Confirmed.png">
                        <img *ngIf="item.Title=='Return Cancelled'"
                            src="../../../../../../assets/img/Return Cancelled.png">
                        <img *ngIf="item.Title=='Abusive Reported'" src="../../../../../../assets/img/abusive.png">
                        <img *ngIf="item.Title=='Product Out Of Stock'"
                            src="../../../../../../assets/img/out-of-stock.png">
                    </div>
                    <div class="notify-captions">
                        <h6 class="cursor-pointer" (click)="gotoUrl(item)">
                            {{item.Title}}
                        </h6>
                        <p (click)="gotoUrl(item)">{{item.Message}}</p>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="pills-read" role="tabpanel" aria-labelledby="pills-read-tab">
                <div class="d-flex gap-2 p-2 divider" *ngFor="let item of notificationList">
                    <div class="notify-image">
                        <img *ngIf="item.Title=='Order Delivered'"
                            src="../../../../../../assets/img/Return Item Received.png">
                        <img *ngIf="item.Title=='Return Item Received'"
                            src="../../../../../../assets/img/dashboardRefundOrders.png">
                        <img *ngIf="item.Title=='Orderitem cancelled'"
                            src="../../../../../../assets/img/Order Cancelled.png">
                        <img *ngIf="item.Title=='Order Confirmed'"
                            src="../../../../../../assets/img/Order Confirmed.png">
                        <img *ngIf="item.Title=='Return Cancelled'"
                            src="../../../../../../assets/img/Return Cancelled.png">
                        <img *ngIf="item.Title=='Abusive Reported'" src="../../../../../../assets/img/abusive.png">
                        <img *ngIf="item.Title=='Product Out Of Stock'"
                            src="../../../../../../assets/img/out-of-stock.png">
                    </div>
                    <div class="notify-captions">
                        <h6 class="cursor-pointer" (click)="gotoUrl(item)">
                            {{item.Title}}
                        </h6>
                        <p (click)="gotoUrl(item)">{{item.Message}}</p>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="pills-unread" role="tabpanel" aria-labelledby="pills-unread-tab">
                <div class="d-flex gap-2 p-2 divider" *ngFor="let item of notificationList">
                    <div class="notify-image">
                        <img *ngIf="item.Title=='Order Delivered'"
                            src="../../../../../../assets/img/Return Item Received.png">
                        <img *ngIf="item.Title=='Return Item Received'"
                            src="../../../../../../assets/img/dashboardRefundOrders.png">
                        <img *ngIf="item.Title=='Orderitem cancelled'"
                            src="../../../../../../assets/img/Order Cancelled.png">
                        <img *ngIf="item.Title=='Order Confirmed'"
                            src="../../../../../../assets/img/Order Confirmed.png">
                        <img *ngIf="item.Title=='Return Cancelled'"
                            src="../../../../../../assets/img/Return Cancelled.png">
                        <img *ngIf="item.Title=='Abusive Reported'" src="../../../../../../assets/img/abusive.png">
                        <img *ngIf="item.Title=='Product Out Of Stock'"
                            src="../../../../../../assets/img/out-of-stock.png">
                    </div>
                    <div class="notify-captions">
                        <h6 class="cursor-pointer" (click)="gotoUrl(item)">
                            {{item.Title}}
                        </h6>
                        <p (click)="gotoUrl(item)">{{item.Message}}</p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</pf-dropdown>