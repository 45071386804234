<ul class="navbar-nav">
    <li class="nav-item">
        <a class="nav-link" (click)="onToggleMenuSidebar()" role="button"><i class="fas fa-bars"></i></a>
    </li>
</ul>

<form class="form-inline ms-3 w-50 ms-auto d-none d-sm-block" [formGroup]="searchForm" (ngSubmit)="search()"
    *ngIf="true">
    <div class="input-group input-group-sm w-100">
        <div class="input-group-append">
            <button class="btn btn-navbar" type="submit">
                <i class="fas fa-search" (click)="search()"></i>
            </button>
        </div>
        <input class="form-control" type="search" formControlName="commonSearch" (keyup.enter)="search()"
            placeholder="Search" aria-label="Search" />
    </div>
</form>

<ul class="navbar-nav ms-auto">
    <app-notifications></app-notifications>
    <li class="nav-item" *ngIf="role=='ADMIN'">
        <button class="nav-link" (click)="redirectToSettingsPage()">
            <i class="fas fa-cog"></i>
        </button>
    </li>
    <app-user></app-user>
    <!-- <li class="nav-item">
        <button class="nav-link" (click)="onToggleControlSidebar()">
            <i class="fas fa-th-large"></i>
        </button>
    </li> -->
</ul>