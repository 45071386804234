import { Messages } from '@/Toaster-messages';
import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import { CommonService } from '@services/common/common.service';
import { ExcelService } from '@services/common/excel.service';
import { UserAuthService } from '@services/user-auth/user-auth.service';
import * as query from 'graphql/queries';
import { MessageService } from 'primeng/api';
import { ROUTES } from '@/redirect-routes';
import { CategoryType } from 'API';

@Component({
  selector: 'app-categories-report-list',
  templateUrl: './categories-report-list.component.html',
  styleUrl: './categories-report-list.component.scss'
})
export class CategoriesReportListComponent {
  subCategoryList: any = [];
  value: number = 5;
  position: any;
  displayFilterModalToggle = false
  deleteModalToggle: any
  categoryList: any = []
  categoryDataList: any = []
  filterApplied: boolean = false
  hide: boolean = false
  isSearchEnabled = false
  defaultSubSactegory: string | null = null;
  page: any
  pageSize: any
  loading: boolean
  endOfData: boolean
  viewAccess: any = false
  data: any = {}
  loader: boolean = true
  companyName: any = ''

  filterForm = this.fb.group({
    subCategory: [],
    searchText: ['']
  })

  constructor(private router: Router, private awsApi: ApiService,
    private common: CommonService, public auth: UserAuthService, private fb: FormBuilder,
    private excelService: ExcelService, private messageService: MessageService,
    private route: ActivatedRoute
  ) { }


  getAllSubCategory() {
    return this.awsApi.client.graphql({
      query: query.listCategories,
    });
  }

  initializePagination() {
    this.page = 1;
    this.pageSize = 14;
    this.loading = false;
    this.endOfData = false;
    this.loadData()
  }

  async ngOnInit(): Promise<void> {
    try {
      this.position = 'right';
      this.viewAccess = this.route.snapshot.queryParamMap?.get('view') ?? false;
      if (this.viewAccess) {
        let categories = await this.getAllSubCategory()
        this.subCategoryList = categories?.data.listCategories.items.filter((category: any) => {
          return category.categoryType == 'MAIN';
        });
        this.companyName = await this.common.getCompanyName()
        this.common.categoryFilter$.subscribe(async value => {
          if (value) {
            let selectedCategory = this.subCategoryList.find((item) => item.id === value)
            this.filterForm.patchValue({ subCategory: [selectedCategory] })
            await this.filterSubCategory()
          }
        })
      }
      else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.ACCESS_DENIED
        });
      }
    } catch (error) { }
  }

  async startingFunction() {
    this.common.categoryReports(this.data, await this.common.getHeader()).subscribe((result) => {
      if (result.status) {
        this.categoryList = result.category
        this.initializePagination()
      }
    })
  }

  loadData() {
    if (this.categoryList.length > 0) {
      this.loading = true;
    }
    const startIndex = (this.page - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    setTimeout(() => {
      this.categoryDataList = this.categoryList.slice(startIndex, endIndex);
      this.awsApi.loading.next(false);
      this.loader = this.awsApi.loading.getValue()
      this.loading = false;
    }, 1000);
  }

  loadMore() {
    this.page++
    this.loading = true;
    const startIndex = (this.page - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    setTimeout(() => {
      this.categoryDataList = this.categoryDataList.concat(this.categoryList.slice(startIndex, endIndex));
      this.loading = false;

      if (this.categoryDataList.length >= this.categoryList.length) {
        this.endOfData = true;
      }
    }, 2000);
  }

  onScroll() {
    if (!this.loading && !this.endOfData) {
      const container = document.querySelector('.scroll-container');
      if (
        container &&
        container.scrollHeight - container.scrollTop === container.clientHeight
      ) {
        this.loadMore();
      }
    }
  }

  filterSubCategory() {
    this.displayFilterModalToggle = false;
    this.awsApi.loading.next(true);
    this.loader = this.awsApi.loading.getValue()
    const subCategoryValues = this.filterForm.controls.subCategory?.value;
    let subCategoryNames: string[] = [];
    if (subCategoryValues) {
      if (Array.isArray(subCategoryValues)) {
        subCategoryNames = subCategoryValues.map((item: any) => item.id);
      } else {
        subCategoryNames = [subCategoryValues];
      }
    }
    if (subCategoryNames && subCategoryNames.length > 0) {
      this.filterApplied = true
      this.data['mainCategoryID'] = subCategoryNames.join(',');
    }
    this.startingFunction()
  }

  exportToExcel() {
    let obj = []
    let exel_sheet_data = []
    let data: any = {}
    const header = ['Product Category', 'Total Products', 'Total Qty', 'Current Stock', 'Sold Products', 'Sold Qty', 'Sold Value'];
    let title = 'Category-Report of ' + this.companyName;
    let worksheet = 'Category-Report'
    let merge_cells = 'A1:E2'
    let footer_row = this.companyName + ' Category-Report.'
    let filename = 'Category-Report'
    let excel_array = [
      data['header'] = header,
      data['title'] = title,
      data['worksheet'] = worksheet,
      data['merge_cells'] = merge_cells,
      data['footer_row'] = footer_row,
      data['filename'] = filename
    ]
    const date = new Date();
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit', month: 'short', year: 'numeric'
    }).replace(/ /g, '-');
    for (var i in this.categoryList) {
      obj = [this.categoryList[i].categoryName.toString(),
      this.categoryList[i].totalproducts.toString(),
      this.categoryList[i].totalquantity.toString(),
      Number(this.categoryList[i].currentstock).toFixed(2),
      this.categoryList[i].soldproducts.toString(),
      this.categoryList[i].soldquantity.toString(),
      Number(this.categoryList[i].soldvalue).toFixed(2)]
      exel_sheet_data.push(obj)
    }
    this.excelService.exportAsExcelFile(exel_sheet_data, formattedDate, excel_array);
  }

  showFilterModalDialog(position: string) {
    this.position = position;
    this.displayFilterModalToggle = true;
  }

  closeFilterModalDialog() {
    this.displayFilterModalToggle = false;
  }

  cancelFilter() {
    this.displayFilterModalToggle = false;
    this.filterApplied = false
    this.data = {}
    this.filterForm.reset()
    this.categoryList = []
  }

  showDeleteGeneralQueryModalDialog(item: any) {
    this.deleteModalToggle = true;
  }

  redirectToAddProductPage() {
    this.router.navigate([`admin/product/add`]);
  }

  redirectToReportListPage() {
    this.router.navigate([ROUTES.REPORTS_LIST])
  }
}