import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import * as query from 'graphql/queries';
import * as mutation from 'graphql/mutations';
import { CommonService } from '@services/common/common.service';
import _ from 'underscore';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import { Status } from 'API';
import { Messages } from '@/Toaster-messages';

@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrl: './edit-role.component.scss'
})
export class EditRoleComponent implements OnInit {
  roleList: any = []
  roleData: any = []
  roleID: any
  accordionOpen: any = []
  pk: any
  access: any = {}
  submitted = false
  moduleList: any = []
  missingModulesList: any = []
  modules: any = []
  subModulesList: any = []
  modulesList: any = []
  subMod = []
  hasSubModules: any[] = [];
  reportsList: any = []
  processingUpdate = false

  actions: any = {
    'view': false,
    'add': false,
    'edit': false,
    'delete': false
  }

  roleForm = this.fb.group({
    role: ['', [Validators.required]],
  })

  constructor(private router: Router, private common: CommonService, private awsApi: ApiService, private messageService: MessageService, private fb: FormBuilder,
    private _activatedroute: ActivatedRoute) { }

  async ngOnInit(): Promise<void> {
    this.getRoleManagement()
    await this.getAllRole()
  }

  async getRoleManagement() {
    this.awsApi.loading.next(true);
    try {
      await this.common.getPermissionsOfUser('Users', 'Manage Roles');
      this.access = this.common.access;
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  async getAllRole() {
    this._activatedroute.queryParamMap.subscribe((params) => {
      this.roleID = params.get('id') ?? '';
      this.pk = params.get('pk') ?? '';
      if (this.roleID != '' && this.pk != '') {
        this.awsApi.loading.next(true);
        Promise.all([this.getAllMainRole(), this.getRoleByID(), this.getAllMainModules()])
          .then(async (response) => {
            this.roleList = [
              ...response[0].data.listRoleManagements.items
            ];
            this.roleData = response[1].data.getRoleManagement;
            this.roleForm.patchValue({
              role: this.roleData.roleName,
            });
            this.moduleList = this.roleData.module
            await await this.addMissingModules();
            this.awsApi.loading.next(false);
          })
          .catch((error) => {
            this.awsApi.loading.next(false);
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: Messages.SOMETHING_WENT_WRONG
            });
          });
      }
    });
  }

  async addMissingModules() {
    const modulePairs = new Set(this.moduleList.map(module => `${module.moduleId}-${module.menu}`));
    this.missingModulesList.forEach((actualModule, index) => {
      const moduleKey = `${actualModule.moduleId}-${actualModule.menu}`;
      if (!modulePairs.has(moduleKey)) {
        this.moduleList.splice(index, 0, actualModule);
        modulePairs.add(moduleKey);
      }
    });
  }
  async getModuleMaster() {
    return this.awsApi.client
      .graphql({
        query: query.listModuleMasters
      })
  }

  async getAllMainModules() {
    this.awsApi.loading.next(true);
    try {
      let filterReport = { status: { eq: Status.ACTIVE } }
      Promise.all([this.getModuleMaster(), this.common.getReportMaster(filterReport)]).then(async (response) => {
        this.modules = response[0].data.listModuleMasters.items
        this.reportsList = response[1].data.listReportMasters.items.sort((a: any, b: any) => a.sequenceNumber - b.sequenceNumber);
        this.modulesList = this.modules.filter((arr: any) => {
          return arr.pk == 'MAIN#';
        });
        this.modulesList = this.modulesList.sort((a: any, b: any) => a.sequenceNumber - b.sequenceNumber);
        await this.setModuleList()
        this.awsApi.loading.next(false);
      })
        .catch((error) => {
          this.awsApi.loading.next(false);
        });
    } catch (error) { }
  }

  isIdInModulesList(id: string): boolean {
    return this.modulesList.some(module => module.id === id);
  }

  async setModuleList() {
    for (let index = 0; index < this.modulesList.length; index++) {
      this.missingModulesList.push({
        moduleId: this.modulesList[index].id,
        menu: this.modulesList[index].moduleName,
        view: false,
        add: false,
        edit: false,
        delete: false
      })
      await this.checkSubMenusExist(this.modulesList[index], index)
    }
  }

  async checkSubMenusExist(module, index) {
    let subModulesLength
    let length = this.hasSubModules.length
    let ifExist = this.isSubMenusExist(module, index)
    if (ifExist || module.moduleName == 'Reports') {
      if (!this.hasSubModules[length]) {
        this.hasSubModules[length] = {};
      }
      this.hasSubModules[length].status = true
      this.hasSubModules[length].index = index
      subModulesLength = module.moduleName == 'Reports' ? this.reportsList.length : this.subMod.length
      for (let i = 1; i <= subModulesLength; i++) {
        if (!this.hasSubModules[length + i]) {
          this.hasSubModules[length + i] = {};
        }
        this.hasSubModules[length + i].status = false
        this.hasSubModules[length + i].index = index
      }
    }
    else {
      if (!this.hasSubModules[length]) {
        this.hasSubModules[length] = {};
      }
      this.hasSubModules[length].status = false
    }
    this.accordionOpen[index] = true
  }

  isSubMenusExist(module, index): boolean {
    this.subMod = this.modules.filter((arr: any) => {
      return arr.pk == 'SUB#' + module.id;
    });
    if (this.subMod.length > 0) {
      this.subModulesList[index] = this.subMod.sort((a: any, b: any) => a.sequenceNumber - b.sequenceNumber);
      for (const item of this.subModulesList[index]) {
        if (!this.moduleList.some(module => module.moduleId === item.id)) {
          this.missingModulesList.push({
            moduleId: item.id,
            menu: item.moduleName,
            view: false,
            add: false,
            edit: false,
            delete: false
          });
        }
      }
      return true;
    }
    else {
      if (module.moduleName == 'Reports') {
        for (const item of this.reportsList) {
          this.missingModulesList.push({
            moduleId: item.id,
            menu: item.reportName,
            view: false,
            add: false,
            edit: false,
            delete: false
          });
        }
        return true;
      }
      this.subModulesList[index] = []
      return false;
    }
  }

  async getAllMainRole() {
    return await this.awsApi.client.graphql({
      query: query.listRoleManagements,
      variables: {
        pk: 'ROLEM#'
      }
    });
  }

  async getRoleByID() {
    return await this.awsApi.client.graphql({
      query: query.getRoleManagement,
      variables: {
        pk: this.pk,
        id: this.roleID
      }
    });
  }

  updateRole() {
    if (this.roleForm.valid) {
      this.processingUpdate = true;
      let item = {
        pk: this.roleData.pk,
        id: this.roleData.id,
        roleName: this.roleForm.controls.role.value,
        module: this.moduleList.map(moduleItem => ({
          moduleId: moduleItem.moduleId,
          menu: moduleItem.menu,
          view: moduleItem.view,
          add: moduleItem.add,
          edit: moduleItem.edit,
          delete: moduleItem.delete
        }))
      }
      this.awsApi.client
        .graphql({
          query: mutation.updateRoleManagement,
          variables: {
            input: {
              ...item
            }
          }
        })
        .then((response) => {
          this.awsApi.loading.next(false);
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Role updated successfully!'
          });
          this.processingUpdate = false;
          this.router.navigate(['/list-role']);
        })
        .catch((error) => {
          this.awsApi.loading.next(false);
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: Messages.SOMETHING_WENT_WRONG
          });
        });
    }
  }

  routeListRole() {
    this.router.navigate(['/list-role']);
  }

  selectAllModules(action: any) {
    this.actions[action] = !this.actions[action]
    for (var i = 0; i < this.moduleList.length; i++) {
      this.moduleList[i][action] = this.actions[action];
    }
  }

  cancel() {
    this.router.navigate(['/list-role']);
  }
}