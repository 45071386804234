import { Component, OnInit } from '@angular/core';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import * as mutation from 'graphql/mutations';
import { uploadData } from 'aws-amplify/storage';
import amplifyconfig from 'amplifyconfiguration.json';
import { v4 as uuidv4 } from 'uuid';
import { ApiService } from '@services/api/api.service';
import * as query from 'graphql/queries';
import { Status } from 'API';
import { CommonService } from '@services/common/common.service';
import { Messages } from '@/Toaster-messages';

@Component({
    selector: 'app-brand-add',
    templateUrl: './brand-add.component.html',
    styleUrl: './brand-add.component.scss'
})
export class BrandAddComponent implements OnInit {
    public Editor = ClassicEditor;
    access: any = {}
    public onReady(editor: ClassicEditor) { }
    public onChange({ editor }: ChangeEvent) { }
    countryList: any = [];
    addBrandForm: any = this.fb.group({
        brandName: ['', [Validators.required, Validators.pattern(/^(?!\s*$)(?=.*[a-zA-Z])[a-zA-Z0-9\s]+$/)]],
        manufacturerName: [''],
        countryOfOrigin: ['', [Validators.required]],
        brandURL: [''],
        brandLogo: ['', [Validators.required]],
        description: ['']
    });
    constructor(
        private router: Router,
        private common: CommonService,
        private awsApi: ApiService,
        private _router: Router,
        private fb: FormBuilder,
        private messageService: MessageService
    ) { }

    ngOnInit(): void {
        this.getRoleManagement()
        this.awsApi.client.graphql({
            query: query.listCountries,
            variables: {
                limit: 9999,
                nextToken: null,
                sortDirection: null
            }
        }).then((response) => {
            this.countryList = response.data.listCountries.items;
        })
    }

    async getRoleManagement() {
        try {
            await this.common.getPermissionsOfUser('Products', 'Manage Brand');
            this.access = this.common.access;
            this.awsApi.loading.next(false);
        } catch (error) {
            this.awsApi.loading.next(false);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: Messages.SOMETHING_WENT_WRONG
            });
        }
    }

    async isBrandAlreadyExists(): Promise<Boolean> {
        let res = await this.common.getAllMainBrand()
        if (res.data.listBrands.items.length > 0 && res.data.listBrands.items
            .some((item) => item.brandName.replace(/\s/g, "").toLowerCase().trim() == this.addBrandForm.value.brandName.replace(/\s/g, "").toLowerCase().trim())) {
            return true
        }
        return false
    }

    async deleteUrl(type) {
        if (type == 'brandLogo') {
            this.addBrandForm.patchValue({
                brandLogo: ''
            });
            const fileInput = document.getElementById('brand-logo-upload') as HTMLInputElement;
            if (fileInput) {
                fileInput.value = '';
            }
        }
    }

    async saveBrand() {
        this.awsApi.loading.next(true);
        if (this.addBrandForm.valid) {
            if (!await this.isBrandAlreadyExists()) {
                let id = uuidv4();
                let str = this.addBrandForm.value.brandName.toLowerCase()
                str = str.replace(/\s{2,}/g, ' ');
                str = (str.trim()).replace(/\s+/g, '')
                let data = {
                    pk: 'BRAND#',
                    id: id,
                    brandName: this.addBrandForm.value.brandName,
                    manufacturerName: this.addBrandForm.value.manufacturerName ? this.addBrandForm.value.manufacturerName : undefined,
                    countryOfOrigin: this.addBrandForm.value.countryOfOrigin,
                    brandLogo: this.addBrandForm.value.brandLogo,
                    brandUrl: this.addBrandForm.value.brandURL ? this.addBrandForm.value.brandURL : undefined,
                    brandDescription: this.addBrandForm.value.description ? this.addBrandForm.value.description : undefined,
                    status: Status.ACTIVE,
                    searchKey: str
                };
                this.awsApi.client
                    .graphql({
                        query: mutation.createBrand,
                        variables: {
                            input: {
                                ...data
                            }
                        }
                    })
                    .then((response) => {
                        this.awsApi.loading.next(false);
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Success',
                            detail: 'Brand created successfully!'
                        });
                        this._router.navigate(['/list-brand']);
                    })
                    .catch((error) => {
                        this.awsApi.loading.next(false);
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: Messages.SOMETHING_WENT_WRONG
                        });
                    });
            } else {
                this.awsApi.loading.next(false);
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: Messages.BRAND_ALREADY_EXISTS
                });
            }

        } else {
            this.awsApi.loading.next(false);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: Messages.PLEASE_FILL_REQUIRED_FIELDS
            });
        }
    }

    async fileSave(event: any, type: any) {
        this.awsApi.loading.next(true);
        if (event.target instanceof HTMLInputElement && event.target.files) {
            let file = event.target.files[0];
            let isValid = await this.common.isValidFileType(event)
            if (!isValid) { return; }
            try {
                this.awsApi.loading.next(false);
                let imageUrl = await this.common.getS3Url(file)
                if (type == 'brand') {
                    this.addBrandForm.patchValue({
                        brandLogo: imageUrl
                    });
                }
            } catch (error) {
                this.awsApi.loading.next(false);
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: Messages.SOMETHING_WENT_WRONG
                });
            }
        }
    }

    redirectToListingPage() {
        this.router.navigate([`list-brand`]);
    }
}