import { Component } from '@angular/core';
import { ApiService } from '@services/api/api.service';
import { MessageService } from 'primeng/api';
import * as query from 'graphql/queries';
import { StatusList } from '@/common-list';
import { FormBuilder, Validators } from '@angular/forms';
import slugify from '@sindresorhus/slugify';
import * as mutation from 'graphql/mutations';
import { Status } from 'API';
import { Messages } from '@/Toaster-messages';
import { CommonService } from '@services/common/common.service';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-variant-list',
  templateUrl: './variant-list.component.html',
  styleUrl: './variant-list.component.scss'
})
export class VariantListComponent {
  position: any;
  displayFilterModalToggle = false
  deleteModalToggle: any
  searchText: any
  searchKey: any
  access: any = {}
  add: any = false
  edit: any = false
  statusList = StatusList
  selectedVariantItem: any = []
  filterObject: any = {}
  variantList: any = []
  copyVariantList: any = []
  variantForm = this.formBuilder.group({
    variantName: ['', [Validators.required, Validators.pattern(/^(?!\s*$)(?=.*[a-zA-Z])[a-zA-Z0-9\s]+$/)]]
  })

  constructor(private common: CommonService, private awsApi: ApiService, private formBuilder: FormBuilder, private messageService: MessageService) { }

  ngOnInit(): void {
    this.getRoleManagement()
    this.startingFunction()
  }

  async getRoleManagement() {
    this.awsApi.loading.next(true);
    try {
      await this.common.getPermissionsOfUser('Settings', 'Manage Variant');
      this.access = this.common.access;
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  async startingFunction() {
    this.awsApi.loading.next(true);
    this.getvariantList()
  }

  getvariantList() {
    this.awsApi.loading.next(true);
    this.awsApi.client
      .graphql({
        query: query.listAttributeNames
      }).then((response) => {
        this.variantList = response.data.listAttributeNames.items.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        this.copyVariantList = this.variantList
        this.awsApi.loading.next(false);
      }).catch((error) => {

        this.awsApi.loading.next(false);
      });
  }

  showAdd() {
    this.variantForm.reset()
    this.add = true
    this.edit = false
  }

  showEdit(variantItem) {
    this.selectedVariantItem = variantItem
    this.variantForm.reset()
    this.edit = true
    this.add = false
    this.autoFillEditForm()
  }

  autoFillEditForm() {
    this.variantForm.patchValue({
      variantName: this.selectedVariantItem.attrName
    })
  }

  async createAttribute(data) {
    return this.awsApi.client
      .graphql({
        query: mutation.createAttributeName,
        variables: {
          input: {
            ...data
          }
        }
      })
  }

  catchError() {
    this.awsApi.loading.next(false);
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: Messages.SOMETHING_WENT_WRONG
    });
  }

  successMessage(succMessage: any) {
    this.awsApi.loading.next(false);
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: succMessage
    });
  }

  async deleteAttribute(data) {
    return this.awsApi.client
      .graphql({
        query: mutation.deleteAttributeName,
        variables: {
          input: {
            ...data
          }
        }
      })
  }

  addvariant() {
    if (this.variantForm.valid) {
      this.awsApi.loading.next(true);
      let data = {
        pk: 'ATTRNAME#',
        slug: slugify(this.variantForm.controls.variantName.value),
        id: uuidv4(),
        status: Status.ACTIVE,
        attrName: this.variantForm.controls.variantName.value
      };
      this.awsApi.client.graphql({
        query: mutation.createAttributeName,
        variables: {
          input: {
            ...data
          }
        }
      }).then((response) => {
        this.successMessage(Messages.VARIANT_ADDED)
        this.variantForm.reset()
        this.add = false
        this.startingFunction()
      }).catch((error) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.VARIENT_NAME_ALREADY_EXIST
        });
      });
    }
    else {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.PLEASE_FILL_REQUIRED_FIELDS
      });
    }
  }

  async editvariant() {
    let response;
    if (this.variantForm.valid) {
      this.awsApi.loading.next(true);
      let searchKeyFormatted = ((this.variantForm.controls.variantName.value.replace(/\s/g, "")).trim()).toLowerCase()
      if (this.variantList.filter((item) => item.slug === slugify(this.variantForm.controls.variantName.value) && item.id != this.selectedVariantItem.id).length > 0) {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.VARIENT_NAME_ALREADY_EXIST
        });
        return;
      }
      let deleteFilter = {
        pk: 'ATTRNAME#',
        slug: this.selectedVariantItem.slug
      }

      let inputData: any = {
        pk: 'ATTRNAME#',
        slug: slugify(this.variantForm.controls.variantName.value),
        id: this.selectedVariantItem.id,
        sequenceNumber: this.selectedVariantItem.sequenceNumber,
        searchKey: searchKeyFormatted,
        status: this.selectedVariantItem.status,
        attrName: this.variantForm.controls.variantName.value
      };
      response = await this.deleteAttribute({ ...deleteFilter })
      response = await this.createAttribute({ ...inputData })
      if (response) {
        this.successMessage(Messages.VARIANT_EDITED)
        this.variantForm.reset()
        this.edit = false
        this.startingFunction()
      }
      else {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      }
    }
    else {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.PLEASE_FILL_REQUIRED_FIELDS
      });
    }
  }

  editStatus(variantSlug: any, type: any) {
    let data = {
      pk: 'ATTRNAME#',
      slug: variantSlug,
      status: type == 'Deactivate' ? Status.INACTIVE : Status.ACTIVE
    }
    this.awsApi.client
      .graphql({
        query: mutation.updateAttributeName,
        variables: {
          input: {
            ...data
          }
        }
      }).then((response) => {
        this.awsApi.loading.next(false);
        this.ngOnInit();
      }).catch((error) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      })
  }

  async deleteVariant(variantItem) {
    this.selectedVariantItem = variantItem
    this.awsApi.loading.next(true);
    try {
      let filter = {}
      filter['isDeleted'] = { ne: true }
      filter['attrNameID'] = { eq: this.selectedVariantItem.id }
      let products = await this.common.getProductDetailsWithEditQuery(filter, ['pk', 'id'], 'Variant Delete')
      if (products.length == 0) {
        let data = {
          pk: 'ATTRNAME#',
          slug: this.selectedVariantItem.slug
        };
        this.awsApi.client.graphql({
          query: mutation.deleteAttributeName,
          variables: {
            input: {
              ...data
            }
          }
        }).then((response) => {
          this.successMessage(Messages.VARIANT_DELETED)
          this.variantForm.reset()
          this.startingFunction()
        }).catch((error) => {
          this.catchError()
        });
      } else {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.VARIANT_CANNOT_BE_DELETED
        });
      }
    } catch (error) { }
  }

  showDeleteConfirmationModalDialog(item: any) {
    this.deleteModalToggle = true;
  }

  redirectTovariantList() {
    this.variantForm.reset()
    this.edit = false
    this.add = false
  }
}