<section id="editMobileCarouselImagesSec">
    <div class="container-fluid">
        <div class="row mb-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card mb-2 heading-card">
                    <div class="card-body">
                        <div class="d-flex justify-content-start align-items-center">
                            <i class="fas fa-chevron-left" (click)="redirectToCarouselListingPage()"></i>
                            <h6>{{type}}</h6>
                        </div>
                    </div>
                </div>
                <div class="card mb-2 carousel-img-card">
                    <div class="card-body" [formGroup]="carouselForm">
                        <div class="row">
                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2">
                                <label>Animation Effect</label>
                                <select class="form-select form-control" [(ngModel)]="animationEffect"
                                    formControlName="animationEffect" aria-label="Default select example">
                                    <option disabled [ngValue]="null">Choose</option>
                                    <option *ngFor="let effect of animationEffects">{{ effect.value }}</option>
                                </select>
                            </div>
                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2">
                                <label>Pause Time Between Transitions (in seconds)</label>
                                <input type="number" (keypress)="preventE($event)" step="0.01" min="0.00"
                                    class="form-control" formControlName="pauseTime" placeholder="Enter" />
                            </div>
                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2">
                                <div>
                                    <label>Slide Transition Speed (in seconds)</label>
                                    <input type="number" (keypress)="preventE($event)" step="0.01" min="0.00"
                                        class="form-control" formControlName="slideTransSpeed" placeholder="Enter" />
                                </div>
                            </div>
                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 mb-2">
                                <div class="form-switch">
                                    <input class="form-check-input" type="checkbox" formControlName="stopAnimation"
                                        role="switch" id="stopAnimation" />
                                    <label class="form-check-label" for="stopAnimation">Stop Animation While Mouse Is On
                                        The Banner</label>
                                </div>
                            </div>
                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 mb-2">
                                <div class="form-switch">
                                    <input class="form-check-input" type="checkbox" formControlName="displayArrows"
                                        role="switch" id="displayArrows" />
                                    <label class="form-check-label" for="displayArrows">Display Arrows</label>
                                </div>
                            </div>
                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 mb-2">
                                <div class="form-switch">
                                    <input class="form-check-input" formControlName="displayNavBullets" type="checkbox"
                                        role="switch" id="navigationBullets" />
                                    <label class="form-check-label" for="navigationBullets">Display Navigation
                                        Bullets</label>
                                </div>
                            </div>
                        </div>
                        <div class="row" formArrayName="carouselImages">
                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12"
                                *ngFor="let item of carouselImages.controls; let i = index" [formGroupName]="i">
                                <div class="carousel-image-upload-modal" *ngIf="!item.get('url').value"
                                    data-bs-toggle="modal" href="#carouselImageModalToggle"
                                    (click)="openUploadcarouselImageModal(item,i)">
                                    <div>
                                        <i class="far fa-image"></i>
                                        <div class="upload-caption">
                                            <h5>Banner Image</h5>
                                            <h6>The image size should be 461x170</h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="after-upload-carousel-img" *ngIf="item.get('url').value">
                                    <div class="d-flex justify-content-end align-items-baseline gap-2 m-2">
                                        <div href="#carouselImageModalToggle" data-bs-toggle="modal">
                                            <i class="far fa-edit" (click)="openUploadcarouselImageModal(item,i)"></i>
                                        </div>
                                        <i class="fas fa-trash-alt" (click)="deleteUrl('carouselImage-mobile',i)"></i>
                                    </div>
                                    <img [src]="item.get('url').value" />
                                </div>
                            </div>
                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <div class="carousel-image-upload-modal" data-bs-toggle="modal"
                                    href="#carouselImageModalToggle" (click)="addNewImage()">
                                    <div>
                                        <i class="far fa-image"></i>
                                        <div class="upload-caption">
                                            <h5>Banner Image</h5>
                                            <h6>The image size should be 461x170</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                <label>Status</label>
                                <div class="form-switch">
                                    <input class="form-check-input" type="checkbox" [checked]="sts"
                                        (change)="changeStatus()" role="switch" id="active" />
                                    <label class="form-check-label" for="active" *ngIf="sts">Active</label>
                                    <label class="form-check-label" for="active" *ngIf="!sts">Inactive</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pb-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="d-flex justify-content-end gap-2">
                    <button type="button" class="btn cancel-btn" (click)="redirectToCarouselListingPage()">
                        Cancel
                    </button>
                    <button type="submit" class="btn save-btn" (click)="saveBanner()">
                        Save Banner
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="modal fade" id="carouselImageModalToggle" aria-hidden="true" aria-labelledby="carouselImageModalToggleLabel"
    tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h6>Image Upload</h6>
                <i class="fas fa-times" data-bs-dismiss="modal"></i>
            </div>
            <div class="modal-body">
                <div class="row" [formGroup]="imageForm">
                    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <form id="image-upload-form" class="image-uploader">
                            <input id="image-upload" (change)="fileSave($event)" type="file" name="fileUpload"
                                accept="image/*" />
                            <label for="image-upload" id="file-drag" *ngIf="!imageFormControl.url.value">
                                <div id="start">
                                    <div class="upload-caption">
                                        <i class="far fa-image"></i>
                                        <h5>Banner Image</h5>
                                        <h6>The image size should be 461x170</h6>
                                    </div>
                                </div>
                            </label>
                            <div id="after-upload" *ngIf="imageFormControl.url.value">
                                <img [src]="imageFormControl.url.value" />
                                <i class="fas fa-trash-alt ms-3 pt-1" (click)="removeUrl()"></i>
                            </div>
                        </form>
                    </div>
                    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div>
                            <h6>Attachment Details</h6>
                        </div>
                        <div>
                            <label>Alternate Text to Image
                                <span class="mandatory">*</span></label>
                            <input type="text" formControlName="alterText" class="form-control"
                                placeholder="Enter Alternate Text to Image" />
                        </div>
                        <div>
                            <label>Destination URL</label>
                            <input type="text" class="form-control" formControlName="description" placeholder="Enter" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn cancel-btn" data-bs-dismiss="modal" #closeImageUploadModal>
                    Cancel
                </button>
                <button class="btn save-btn" data-bs-dismiss="modal" [disabled]="imageForm.invalid"
                    (click)="saveImage()">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>