import { AppState } from '@/store/state';
import { ToggleControlSidebar, ToggleSidebarMenu } from '@/store/ui/actions';
import { UiState } from '@/store/ui/state';
import { Component, HostBinding, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ApiService } from '@services/api/api.service';
import { AppService } from '@services/app.service';
import { Observable } from 'rxjs';
import { CommonService } from '@services/common/common.service';

const BASE_CLASSES = 'main-header navbar navbar-expand';
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    @HostBinding('class') classes: string = BASE_CLASSES;
    public ui: Observable<UiState>;
    public searchForm: UntypedFormGroup;
    productList: any
    ordersList: any
    role: any

    constructor(
        private appService: AppService,
        private store: Store<AppState>,
        private router: Router,
        private awsApi: ApiService,
        private common: CommonService
    ) { }

    async ngOnInit() {
        this.ui = this.store.select('ui');
        this.ui.subscribe((state: UiState) => {
            this.classes = `${BASE_CLASSES} ${state.navbarVariant}`;
        });
        this.searchForm = new UntypedFormGroup({
            commonSearch: new UntypedFormControl(null)
        });
        Promise.all([await this.common.getOrders({}, ['pk', 'id', 'searchKey'])]).then((response) => {
            this.ordersList = response[0]
            this.role = localStorage.getItem('role')
        })
    }

    search() {
        let strWithoutSpaces
        this.awsApi.loading.next(false);
        const searchText = this.searchForm.controls.commonSearch.value
        let searchKey, productMatch = false, ordersMatch = false
        if (searchText == '') {
            this.router.navigate([''])
        }
        else {
            searchKey = searchText.toLowerCase().replace(/\s{2,}/g, ' ').trim();
            strWithoutSpaces = searchKey.replace(/\s+/g, '');

            productMatch = (JSON.parse(localStorage.getItem("products"))).some((product) => {
                if (product.searchKey) {
                    let str = (((product.searchKey).toLowerCase()).replace(/\s{2,}/g, ' ')).trim();
                    let searchKeyWithoutSpaces = str.replace(/\s+/g, '');
                    if (str.includes(searchKey) || searchKeyWithoutSpaces.includes(strWithoutSpaces)) {
                        return true;
                    }
                }
                return false;
            });

            if (productMatch) {
                this.router.navigate(['list-product'], { queryParams: { searchText: strWithoutSpaces } });
                return;
            }
            else {
                ordersMatch = this.ordersList.some((order) => {
                    if (order.searchKey) {
                        let str = (((order.searchKey.toLowerCase()).replace(/\s{2,}/g, ' ')).trim());
                        let searchKeyWithoutSpaces = str.replace(/\s+/g, '');
                        if (searchKey.includes(str) || searchKeyWithoutSpaces.includes(strWithoutSpaces)) {
                            return true;
                        }
                    }
                    return false;
                });
                if (ordersMatch) {
                    this.router.navigate(['order-list'], { queryParams: { searchText: searchKey } });
                    return;
                }
            }
        }
    }

    logout() {
        this.appService.logout();
    }

    onToggleMenuSidebar() {
        this.appService.toggleShowMenu()
        this.store.dispatch(new ToggleSidebarMenu());
    }

    onToggleControlSidebar() {
        this.store.dispatch(new ToggleControlSidebar());
    }
    redirectToSettingsPage() {
        this.router.navigate([`settings`]);
    }
}