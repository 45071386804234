<section id="contentListSec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 my-auto">
                <div class="card ms-0 me-0 mt-2 mb-2 ms-sm-2 me-sm-2">
                    <div class="card-body">
                        <!-- content-list-heading-row start -->
                        <div class="row content-list-heading-row">
                            <div class="col-xxl-6 col-xl-6 col-lg-4 col-md-4 col-sm-12 col-xs-12 my-auto">
                                <div class="text-center text-md-left mt-2 mt-md-0">
                                    <h2>Contents</h2>
                                    <p class="mb-2 mb-md-0">{{contentsList.length}} Results Found</p>
                                </div>
                            </div>
                            <div class="col-xxl-6 col-xl-6 col-lg-8 col-md-8 col-sm-12 col-xs-12 my-auto">
                                <div
                                    class="has-search-d-flex justify-content-center justify-content-md-end align-items-start align-items-md-center flex-nowrap flex-md-wrap">
                                    <app-common-search (onEnter)="search($event)"
                                        class="mb-2 mb-md-0"></app-common-search>
                                    <!-- <div>
                                        <button type="button" class="btn add-btn d-none d-md-block"
                                            (click)="redirectToAddContentPage('add')">
                                            Create Content
                                        </button>
                                        <button type="button" class="btn add-btn d-block d-md-none" (click)="redirectToAddContentPage('add')">
                                            <span class="material-symbols-outlined">
                                                add
                                            </span>
                                        </button>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- content-list-heading-row end -->
                <!-- content-list-table-row start -->
                <div class="card mb-1 table-card" *ngFor="let content of contentsList">
                    <div class="card-body">
                        <!-- content-list-table-row start -->
                        <div class="row content-list-table-row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="table-row action-table-responsive table-responsive">
                                    <table class="table table-borderless">
                                        <tbody>
                                            <tr>
                                                <td (click)="redirectToCreateContentSectionPage(content)">
                                                    <h6>{{content.contentName}}</h6>
                                                </td>
                                                <!-- <td class="text-end">
                                                    <div class="dropdown dropstart">
                                                        <i class="fas fa-ellipsis-v" id="dropdownMenuButton1"
                                                            data-bs-toggle="dropdown" aria-expanded="false"
                                                            data-bs-auto-close="inside"
                                                            data-bs-auto-close="outside"></i>
                                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li>
                                                                <a (click)="redirectToAddContentPage('edit',content.id)"
                                                                    class="dropdown-item">
                                                                    <span
                                                                        class="material-symbols-outlined edit-icon me-3"
                                                                        title="Edit">
                                                                        edit_square
                                                                    </span>
                                                                    Edit
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td> -->
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
                                                  <app-no-result-found></app-no-result-found>
                                                </div> -->
                        </div>
                    </div>
                </div>
                <!-- content-list-table-row end -->

                <!-- <div class="card m-2">
                    <div class="card-body">
                        <div class="row">   
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                <h6>Content List</h6>
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-3" *ngFor="let content of contentsList">
                                <button type="button" class="btn" (click)="redirectToCreateContentSectionPage(content)">{{content.contentName}} 
                                </button>
                            </div>                            
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</section>