import { StockStatusList } from '@/common-list';
import { Messages } from '@/Toaster-messages';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import { CommonService } from '@services/common/common.service';
import { ExcelService } from '@services/common/excel.service';
import { UserAuthService } from '@services/user-auth/user-auth.service';
import * as query from 'graphql/queries';
import { MessageService } from 'primeng/api';
import { ROUTES } from '@/redirect-routes';

@Component({
  selector: 'app-stocks-report-list',
  templateUrl: './stocks-report-list.component.html',
  styleUrl: './stocks-report-list.component.scss'
})
export class StocksReportListComponent implements OnInit {
  value: number = 5;
  position: any;
  displayFilterModalToggle = false;
  deleteModalToggle: any;
  stockReportList: any = [];
  stockResponse: any = []
  mainCategoryList: any = [];
  allBrands: any = [];
  copyStockReport: any = [];
  stockReportData: any = []
  filterApplied: boolean = false
  hide: boolean = false
  isSearchEnabled = false
  stockResult: any = []
  page: any = 1;
  pageSize = 10;
  loading: boolean = false;
  endOfData: boolean = false;
  viewAccess: any = false
  companyName: any = ''
  loader: boolean = false
  filterForm = this.fb.group({
    searchText: [''],
    stockStatus: [''],
    mainCategory: [''],
    brand: ['']
  });

  stockStatusList = StockStatusList

  constructor(
    private awsApi: ApiService,
    private router: Router,
    private common: CommonService,
    public auth: UserAuthService,
    private fb: FormBuilder,
    private excelService: ExcelService,
    private route: ActivatedRoute,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.viewAccess = this.route.snapshot.queryParamMap?.get('view') ?? false;
    if (this.viewAccess) {
      this.position = 'right';
      this.startingFunction()
    }
    else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.ACCESS_DENIED
      });
    }
  }

  async startingFunction() {
    Promise.all([this.getAllMainCategory(), this.getAllBrands(), this.common.getCompanyName()])
      .then((result: any) => {
        this.mainCategoryList = result[0].data.listCategories.items;
        this.allBrands = result[1].data.listBrands.items;
        this.companyName = result[2]
      })
      .catch((error) => { });
  }

  async getStockReportResult() {
    let data: { [key: string]: any } = {};
    const selectedMainCategories = this.filterForm.controls.mainCategory?.value || [];
    const selectedBrands = this.filterForm.controls.brand?.value || [];
    const selectedStockStatus = this.filterForm.controls.stockStatus.value;
    if (selectedMainCategories.length > 0) {
      this.filterApplied = true;
      data.mainCategoryID = selectedMainCategories;
    }
    if (selectedBrands.length > 0) {
      this.filterApplied = true;
      data.brandID = selectedBrands;
    }
    if (selectedStockStatus) {
      this.filterApplied = true;
      data.status = selectedStockStatus == 'In stock' ? 'INSTOCK' : 'OUTOFSTOCK';
    }
    this.common.stockReports(data, await this.common.getHeader()).subscribe((result) => {
      if (result.status) {
        this.stockReportList = result.stockreport.sort((a: any, b: any) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
        this.copyStockReport = this.stockReportList;
        this.stockResponse = [...this.stockReportList]
        this.initializePagination()
      }
    });
  }

  initializePagination() {
    this.page = 1;
    this.pageSize = 12;
    this.loading = false;
    this.endOfData = false;
    this.loadData()
  }

  loadData() {
    if (this.stockReportList.length > 0) { this.loading = true; }
    const startIndex = (this.page - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    setTimeout(() => {
      this.stockReportData = this.stockReportList.slice(startIndex, endIndex);
      this.awsApi.loading.next(false);
      this.loader = this.awsApi.loading.getValue()
      this.loading = false;
    }, 1000);
  }

  loadMore() {
    this.page++
    this.loading = true;
    const startIndex = (this.page - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    setTimeout(() => {
      this.stockReportData = this.stockReportData.concat(this.stockReportList.slice(startIndex, endIndex));
      this.loading = false;
      if (this.stockReportData.length >= this.stockReportList.length) {
        this.endOfData = true;
      }
    }, 2000);
  }

  onScroll() {
    if (!this.loading && !this.endOfData) {
      const container = document.querySelector('.scroll-container');
      if (
        container &&
        container.scrollHeight - container.scrollTop === container.clientHeight
      ) {
        this.loadMore();
      }
    }
  }

  getAllMainCategory() {
    return this.awsApi.client.graphql({
      query: query.listCategories,
      variables: {
        pk: 'MAIN#'
      }
    });
  }

  getAllBrands() {
    return this.awsApi.client.graphql({
      query: query.listBrands
    });
  }

  exportToExcel() {
    let obj = [];
    let excel_sheet_data = [];
    let data: any = {};
    const header = ['SKU', 'Variant Value', 'Product Name', 'Brand', 'Category', 'Stock Qty', 'Reserved Qty', 'Stock Unit',
      'Items Per Unit', 'Regular Price', 'Sale Price', 'Updated On', 'Status'];
    let title = 'Stock-Report of ' + this.companyName;
    let worksheet = 'Stock-Report';
    let merge_cells = 'A1:I2';
    let footer_row = this.companyName + ' Stock-Report.';
    let filename = 'Stock-Report';
    let excel_array = [
      (data['header'] = header),
      (data['title'] = title),
      (data['worksheet'] = worksheet),
      (data['merge_cells'] = merge_cells),
      (data['footer_row'] = footer_row),
      (data['filename'] = filename)
    ];
    const date = new Date();
    const formattedDate = date
      .toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      })
      .replace(/ /g, '-');
    for (var i in this.stockReportList) {
      obj = [
        this.stockReportList[i].sku ? this.stockReportList[i].sku.toString() : 'N/A',
        this.stockReportList[i].attrValue ? this.stockReportList[i].attrName.toString() + ' - ' + this.stockReportList[i].attrValue.toString() : 'N/A',
        this.stockReportList[i].productName.toString(),
        this.stockReportList[i].brandName.toString(),
        this.stockReportList[i].mainCategory.toString(),
        this.stockReportList[i].stockQuantity ? this.stockReportList[i].stockQuantity.toString() : '00',
        this.stockReportList[i].reservedStock ? this.stockReportList[i].reservedStock.toString() : '00',
        this.stockReportList[i].stockUnit,
        this.stockReportList[i].itemsPerUnit ? this.stockReportList[i].itemsPerUnit.toString() : '00',
        this.stockReportList[i].regularPrice ? Number(this.stockReportList[i].regularPrice.toString()).toFixed(2) : '00',
        this.stockReportList[i].salePrice ? Number(this.stockReportList[i].salePrice.toString()).toFixed(2) : '00',
        (this.common.dateInReverse(this.stockReportList[i].updatedAt)).toString(),
        this.stockReportList[i].stockStatus == 'INSTOCK' ? 'In Stock' : 'Out Of Stock'
      ];
      excel_sheet_data.push(obj);
    }
    this.excelService.exportAsExcelFile(
      excel_sheet_data,
      formattedDate,
      excel_array
    );
  }

  async applyFilter() {
    try {
      this.displayFilterModalToggle = false;
      this.awsApi.loading.next(true);
      this.loader = this.awsApi.loading.getValue()
      this.copyStockReport = [...this.stockResponse]
      await this.getStockReportResult()
    } catch (error) { }
  }

  showFilterModalDialog(position: string) {
    this.position = position;
    this.displayFilterModalToggle = true;
  }

  closeFilterModalDialog() {
    this.displayFilterModalToggle = false;
  }

  showDeleteGeneralQueryModalDialog(item: any) {
    this.deleteModalToggle = true;
  }

  redirectToAddProductPage() {
    this.router.navigate([`admin/product/add`]);
  }

  cancelFilter() {
    this.displayFilterModalToggle = false;
    this.filterApplied = false
    this.resetFilter()
    this.copyStockReport = this.stockResponse
    this.getStockReportResult()
  }

  resetFilter() {
    this.filterForm.controls.brand.reset()
    this.filterForm.controls.mainCategory.reset()
    this.filterForm.controls.stockStatus.reset()
  }

  redirectToReportListPage() {
    this.router.navigate([ROUTES.REPORTS_LIST])
  }
}