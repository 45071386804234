<section id="editRoleSec">
    <div class="container-fluid">
        <div class="row mb-1 mt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card mb-1 heading-card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="d-flex justify-content-start align-items-center">
                                    <i class="fas fa-chevron-left" (click)="routeListRole()"></i>
                                    <h6>Edit Role</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-1" [formGroup]="roleForm">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <h5> {{roleData.roleName}} </h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-1 table-card">
                    <div class="card-body">
                        <div class="row mt-3">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="table-responsive">
                                    <table class="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th>Menu</th>
                                                <th>
                                                    <div class="form-check">
                                                        <input class="form-check-input" [checked]="actions['view']"
                                                            (change)="selectAllModules('view')" type="checkbox"
                                                            id="view" />
                                                        <label class="form-check-label ms-2 mt-1" for="view">
                                                            View
                                                        </label>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="form-check">
                                                        <input class="form-check-input" [checked]="actions['add']"
                                                            (change)="selectAllModules('add')" type="checkbox"
                                                            id="add" />
                                                        <label class="form-check-label ms-2 mt-1" for="add">
                                                            Add
                                                        </label>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="form-check">
                                                        <input class="form-check-input" [checked]="actions['edit']"
                                                            (change)="selectAllModules('edit')" type="checkbox"
                                                            id="edit" />
                                                        <label class="form-check-label ms-2 mt-1" for="edit">
                                                            Edit
                                                        </label>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="form-check">
                                                        <input class="form-check-input" [checked]="actions['delete']"
                                                            (change)="selectAllModules('delete')" type="checkbox"
                                                            id="delete" />
                                                        <label class="form-check-label ms-2 mt-1" for="delete">
                                                            Delete
                                                        </label>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngFor="let item of moduleList; let moduleListIndex = index">
                                                <tr
                                                    *ngIf="isIdInModulesList(item.moduleId) && !hasSubModules[moduleListIndex].status">
                                                    <th>{{ item.menu }}</th>
                                                    <td>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox"
                                                                [checked]="item.view" (change)="item.view = !item.view"
                                                                [value]="item.view" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox"
                                                                [checked]="item.add" (change)="item.add = !item.add"
                                                                [value]="item.add" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox"
                                                                [checked]="item.edit" (change)="item.edit = !item.edit"
                                                                [value]="item.edit" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox"
                                                                [checked]="item.delete"
                                                                (change)="item.delete = !item.delete"
                                                                [value]="item.delete" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr
                                                    *ngIf="hasSubModules[moduleListIndex]?.status &&
                                                    subModulesList[hasSubModules[moduleListIndex].index] && 
                                                     modulesList[hasSubModules[moduleListIndex]?.index].id == (subModulesList[hasSubModules[moduleListIndex]?.index][0].pk).split('#')[1]">
                                                    <td colspan="5" class="ps-0 pe-0">
                                                        <div class="accordion" id="ordersAccordion">
                                                            <div class="accordion-item">
                                                                <h2 class="accordion-header" id="ordersHeadingOne">
                                                                    <button class="accordion-button" type="button"
                                                                        data-bs-toggle="collapse"
                                                                        [attr.data-bs-target]="'#ordersAccordionCollapse' + moduleListIndex"
                                                                        aria-expanded="true"
                                                                        [attr.aria-controls]="'ordersAccordionCollapse' + moduleListIndex">
                                                                        {{ item.menu }}
                                                                    </button>
                                                                </h2>
                                                                <div [id]="'ordersAccordionCollapse' + moduleListIndex"
                                                                    class="accordion-collapse collapse"
                                                                    [class.show]="accordionOpen[moduleListIndex]"
                                                                    [attr.aria-labelledby]="'ordersHeadingOne' + moduleListIndex"
                                                                    data-bs-parent="#ordersAccordion">
                                                                    <div class="accordion-body">
                                                                        <table class="table table-borderless">
                                                                            <tbody>
                                                                                <tr
                                                                                    *ngFor="let ite of subModulesList[hasSubModules[moduleListIndex].index];let i=index">
                                                                                    <td
                                                                                        *ngIf="(ite.pk).split('#')[1] == moduleList[moduleListIndex].moduleId">
                                                                                        {{
                                                                                        moduleList[moduleListIndex+i+1].menu
                                                                                        }}</td>
                                                                                    <td
                                                                                        *ngIf="(ite.pk).split('#')[1] == moduleList[moduleListIndex].moduleId">
                                                                                        <div class="form-check">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="checkbox"
                                                                                                [checked]="moduleList[moduleListIndex+i+1].view"
                                                                                                (change)="moduleList[moduleListIndex+i+1].view = !moduleList[moduleListIndex+i+1].view"
                                                                                                [value]="moduleList[moduleListIndex+i+1].view" />
                                                                                        </div>
                                                                                    </td>
                                                                                    <td
                                                                                        *ngIf="(ite.pk).split('#')[1] == moduleList[moduleListIndex].moduleId">
                                                                                        <div class="form-check">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="checkbox"
                                                                                                [checked]="moduleList[moduleListIndex+i+1].add"
                                                                                                (change)="moduleList[moduleListIndex+i+1].add = !moduleList[moduleListIndex+i+1].add"
                                                                                                [value]="moduleList[moduleListIndex+i+1].add" />
                                                                                        </div>
                                                                                    </td>
                                                                                    <td
                                                                                        *ngIf="(ite.pk).split('#')[1] == moduleList[moduleListIndex].moduleId">
                                                                                        <div class="form-check">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="checkbox"
                                                                                                [checked]="moduleList[moduleListIndex+i+1].edit"
                                                                                                (change)="moduleList[moduleListIndex+i+1].edit = !moduleList[moduleListIndex+i+1].edit"
                                                                                                [value]="moduleList[moduleListIndex+i+1].edit" />
                                                                                        </div>
                                                                                    </td>
                                                                                    <td
                                                                                        *ngIf="(ite.pk).split('#')[1] == moduleList[moduleListIndex].moduleId">
                                                                                        <div class="form-check">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="checkbox"
                                                                                                [checked]="moduleList[moduleListIndex+i+1].delete"
                                                                                                (change)="moduleList[moduleListIndex+i+1].delete = !moduleList[moduleListIndex+i+1].delete"
                                                                                                [value]="moduleList[moduleListIndex+i+1].delete" />
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="hasSubModules[moduleListIndex]?.status &&
                                                !subModulesList[hasSubModules[moduleListIndex].index] && 
                                                moduleList[moduleListIndex].menu == 'Reports'">
                                                    <td colspan="5" class="ps-0 pe-0">
                                                        <div class="accordion" id="ordersAccordion">
                                                            <div class="accordion-item">
                                                                <h2 class="accordion-header" id="ordersHeadingOne">
                                                                    <button class="accordion-button" type="button"
                                                                        data-bs-toggle="collapse"
                                                                        [attr.data-bs-target]="'#ordersAccordionCollapse' + moduleListIndex"
                                                                        aria-expanded="true"
                                                                        [attr.aria-controls]="'ordersAccordionCollapse' + moduleListIndex">
                                                                        {{ item.menu }}
                                                                    </button>
                                                                </h2>
                                                                <div [id]="'ordersAccordionCollapse' + moduleListIndex"
                                                                    class="accordion-collapse collapse"
                                                                    [class.show]="accordionOpen[moduleListIndex]"
                                                                    [attr.aria-labelledby]="'ordersHeadingOne' + moduleListIndex"
                                                                    data-bs-parent="#ordersAccordion">
                                                                    <div class="accordion-body">
                                                                        <table class="table table-borderless">
                                                                            <tbody>
                                                                                <tr
                                                                                    *ngFor="let reportItem of reportsList;let i=index">
                                                                                    <td>{{
                                                                                        reportItem.reportName
                                                                                        }}</td>
                                                                                    <td>
                                                                                        <div class="form-check">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="checkbox"
                                                                                                [checked]="moduleList[moduleListIndex+i+1].view"
                                                                                                (change)="moduleList[moduleListIndex+i+1].view = !moduleList[moduleListIndex+i+1].view"
                                                                                                [value]="moduleList[moduleListIndex+i+1].view" />
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div class="form-check">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="checkbox"
                                                                                                [checked]="moduleList[moduleListIndex+i+1].add"
                                                                                                (change)="moduleList[moduleListIndex+i+1].add = !moduleList[moduleListIndex+i+1].add"
                                                                                                [value]="moduleList[moduleListIndex+i+1].add" />
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div class="form-check">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="checkbox"
                                                                                                [checked]="moduleList[moduleListIndex+i+1].edit"
                                                                                                (change)="moduleList[moduleListIndex+i+1].edit = !moduleList[moduleListIndex+i+1].edit"
                                                                                                [value]="moduleList[moduleListIndex+i+1].edit" />
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div class="form-check">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="checkbox"
                                                                                                [checked]="moduleList[moduleListIndex+i+1].delete"
                                                                                                (change)="moduleList[moduleListIndex+i+1].delete = !moduleList[moduleListIndex+i+1].delete"
                                                                                                [value]="moduleList[moduleListIndex+i+1].delete" />
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="d-flex justify-content-end gap-2">
                    <button type="button" class="btn cancel-btn" (click)="cancel()">Cancel</button>
                    <button type="button" class="btn save-btn" [disabled]="!access['edit'] || processingUpdate"
                        (click)="updateRole()">Update</button>
                </div>
            </div>
        </div>
    </div>
</section>