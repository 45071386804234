<section id="customerReviewSec">
  <div class="container-fluid">
    <div class="row mb-1 mt-3">
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card mb-1 heading-card">
          <div class="card-body">
            <div class="row">
              <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="d-flex justify-content-start align-items-center">
                  <i class="fas fa-chevron-left" (click)="redirectToProductReviewsPage()"></i>
                  <h6>Customer Review</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" [formGroup]="reviewResponseForm">
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card customer-info-card">
          <div class="card-body">
            <div class="row">
              <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                <label>Product Name</label>
                <p>{{ selectedreview?.productName }}</p>
              </div>
              <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12 mb-2">
                <label>Customer Name</label>
                <p>{{ selectedreview?.userName }}</p>
              </div>
              <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12 mb-2">
                <label>Email</label>
                <p>
                  {{ selectedreview?.email }}
                </p>
              </div>
              <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12 mb-2">
                <label>Order Reference</label>
                <p>{{orderId}}
                </p>
              </div>
              <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12 mb-2">
                <label>Order Date</label>
                <p>
                  {{ selectedreview?.orderedDate | date: 'dd/MM/yyyy' : 'UTC' }}
                </p>
              </div>
              <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12 mb-2">
                <label>Review Date and Time</label>
                <p>
                  {{ selectedreview?.reviewedDate | date: 'dd/MM/yyyy' : 'UTC' }}, {{ selectedreview?.reviewedDate |
                  date:'shortTime' }}
                </p>
              </div>
              <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12 mb-2">
                <label>Title</label>
                <p>
                  {{ selectedreview?.title }}
                </p>
              </div>
              <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2"
                *ngIf="selectedreview?.reviewComment && selectedreview?.reviewComment !=''">
                <label>Review Remarks</label>
                <p>
                  {{ selectedreview?.reviewComment }}
                </p>
              </div>
              <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2"
                *ngIf="selectedreview?.attachments && selectedreview?.attachments.length>0">
                <label>Attachments</label>
                <div class="attachments-div">
                  <div class="attachments-img" *ngFor="let attachment of selectedreview?.attachments">
                    <img [src]="attachment" />
                    <div class="view-icon">
                      <i class="fas fa-eye" (click)="showImageViewModal(attachment)"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row abusive-comment-reported-row"
              *ngIf="selectedreview?.abusiveReportedBy && selectedreview?.abusiveReportedBy.length>0">
              <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                <h6>Abusive Reports</h6>
              </div>
              <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                <div class="table-row table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Reported By</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Date</th>
                        <th>Reason</th>
                      </tr>
                    </thead>
                    <tbody *ngFor="let item of selectedreview?.abusiveReportedBy">
                      <tr>
                        <td>{{item.userName}}</td>
                        <td>{{item.email}}</td>
                        <td>{{item.phoneNumber}}</td>
                        <td>{{item.reportedDate | date: 'dd/MM/yyyy' : 'UTC' }}</td>
                        <td>
                          <span *ngFor="let reason of item.reasons" class="d-flex">
                            <p>{{ reason }}</p>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3 mb-3">
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="d-flex justify-content-end gap-2">
          <button type="button" class="btn cancel-btn" (click)="redirectToProductReviewsPage()">
            Cancel
          </button>
          <button type="button" class="btn send-btn" *ngIf="!removeAbusiveComment"
            [disabled]="!access['edit'] || processingUpdate" (click)="updateCommentStatus(true)">
            Hide comment
          </button>
          <button type="button" class="btn send-btn" *ngIf="removeAbusiveComment"
            [disabled]="!access['edit'] || processingUpdate" (click)="updateCommentStatus(false)">
            Show comment
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- end -->