import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as mutation from 'graphql/mutations';
import { NgOtpInputComponent, NgOtpInputConfig } from 'ng-otp-input';
import * as query from 'graphql/queries';
import {
    FormBuilder,
    FormGroup,
    Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAuthService } from '@services/user-auth/user-auth.service';
import { ApiService } from '@services/api/api.service';
import { AppService } from '@services/app.service';
import { MessageService } from 'primeng/api';
import { Messages } from '@/Toaster-messages';
import { CommonService } from '@services/common/common.service';
import { HttpErrorResponse } from '@angular/common/http';
import { resetPassword, type ResetPasswordOutput } from 'aws-amplify/auth';

@Component({
    selector: 'app-user-confirm-code',
    templateUrl: './user-confirm-code.component.html',
    styleUrl: './user-confirm-code.component.scss'
})
export class UserConfirmCodeComponent implements OnInit {
    @ViewChild('ngOtpInput') ngOtpInputRef: any;
    email: any
    type: any
    otp: string
    userId: any
    remainingTime: string
    interval: any
    showOtpComponent = true;
    isTimerExpired: boolean = false
    focusToFirstElementAfterValueUpdate: boolean = false;
    @ViewChild(NgOtpInputComponent, { static: false }) ngOtpInput: NgOtpInputComponent;
    config: NgOtpInputConfig = {
        allowNumbersOnly: false,
        length: 5,
        isPasswordInput: false,
        disableAutoFocus: false,
        placeholder: ''
    };

    username: any;
    confirmForm: FormGroup;
    get codeInput() {
        return this.confirmForm.get('code');
    }

    constructor(
        private common: CommonService,
        private _router: Router,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private auth: UserAuthService,
        private awsApi: ApiService,
        private messageService: MessageService,
    ) {
        this.confirmForm = this.fb.group({
            username: [{ value: '', disabled: true }],
            code: ['', [Validators.required, Validators.min(3)]]
        });
    }

    ngOnInit() {
        this.startTimer(119)
        this.email = this.route.snapshot.queryParamMap?.get('email')
        this.userId = this.route.snapshot.queryParamMap?.get('id')
        this.type = this.route.snapshot.queryParamMap?.get('type')
        if (environment.confirm.attributeName === 'email') {
            this.confirmForm.patchValue({
                username: environment.confirm.email
            });
        } else if (environment.confirm.attributeName === 'phone_number') {
            this.confirmForm.patchValue({
                username: environment.confirm.phone_number
            });
        }
    }

    async sendAgain() {
        this.awsApi.loading.next(true);
        if (this.type == 'verify_user') {
            this.auth
                .handleResendSignUpCode({
                    username: this.email
                })
                .then((response) => {
                    this.awsApi.loading.next(false);
                })
                .catch((error) => {
                    this.awsApi.loading.next(false);
                });
        }
        if (this.type == 'forgot_password') {
            try {
                let data = {
                    "email": this.email
                }
                this.common.forgotPassword(data).subscribe(async (result) => {
                    if (result.status) {
                        this.awsApi.loading.next(false);
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Success',
                            detail: 'OTP has been sent to your email'
                        });
                    }
                })
            }
            catch (error) {
                if (error.name == 'LimitExceededException') {
                    this.awsApi.loading.next(false);
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Attempt limit exceeded, please try again after some time.'
                    });
                }
            }
        }
        if (this.type == 'multifactor_auth') {
            let reqData: any = {
                "userId": this.userId,
                "email": this.email
            };
            this.common.sendOtp(reqData).subscribe((result) => {
                if (result.success && result.message == 'Email sent successfully!') {
                    this.awsApi.loading.next(false);
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: 'OTP has been sent to your email'
                    });
                    this.ngOtpInputRef.setValue(0);
                }
            }), (error) => {
            }
        }
        this.startTimer(119)
    }

    confirmCode() {
        if (this.remainingTime == '00:00') {
            this.awsApi.loading.next(false);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: Messages.OTP_EXPIRED
            });
            this.ngOtpInputRef.setValue(0);
        }
        else {
            if (this.otp.length == 6) {
                if (this.type == 'verify_user' || this.type == 'signUp') {
                    this.awsApi.loading.next(true);
                    this.auth
                        .handleSignUpConfirmation({
                            username: this.email,
                            confirmationCode: this.otp || ''
                        })
                        .then((data: any) => {
                            this.awsApi.loading.next(false);
                            this.ngOtpInputRef.setValue(0);
                            this.messageService.add({
                                severity: 'success',
                                summary: 'Success',
                                detail: 'Verification completed successfully'
                            });
                            this._router.navigate([`login`])
                        })
                        .catch((error: any) => {
                            this.awsApi.loading.next(false);
                            switch (error.name) {
                                case "CodeMismatchException":
                                    this.messageService.add({
                                        severity: 'error',
                                        summary: 'Error',
                                        detail: Messages.INVALID_OTP
                                    });
                                    this.ngOtpInputRef.setValue(0);
                                    break;
                                case "InvalidLambdaResponseException":
                                    this.messageService.add({
                                        severity: 'success',
                                        summary: 'Success',
                                        detail: 'Verification completed successfully'
                                    });
                                    this._router.navigate([`login`])
                                    break;
                                case 'LimitExceededException':
                                    this.messageService.add({
                                        severity: 'error',
                                        summary: 'Error',
                                        detail: 'Attempt limit exceeded, please try again after some time.'
                                    });
                                    break;
                            }
                        });
                }
                if (this.type == 'forgot_password') {
                    try {
                        let data = {
                            "email": this.email ?? '',
                            "otp": this.otp ?? ''
                        }
                        this.common.forgotPassword(data).subscribe(async (result) => {
                            if (result.status) {
                                this.awsApi.loading.next(false);
                                this._router.navigate(['confirm-reset-password'], { queryParams: { email: this.email, confirmationCode: this.otp } })
                            } else {
                                this.awsApi.loading.next(false);
                                this.messageService.add({
                                    severity: 'error',
                                    summary: 'Error',
                                    detail: Messages.INVALID_OTP
                                });
                            }
                        })
                    } catch (error) {
                        this.awsApi.loading.next(false);
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: Messages.SOMETHING_WENT_WRONG
                        });
                    }
                }
                if (this.type == 'multifactor_auth') {
                    this.awsApi.loading.next(true);
                    let reqData: any = {
                        "email": this.email,
                        "otp": this.otp,
                        "userId": this.userId
                    };
                    this.common.sendOtp(reqData).subscribe((result) => {
                        if (result.success && result.message == 'verified') {
                            this.awsApi.loading.next(false);
                            this.messageService.add({
                                severity: 'success',
                                summary: 'Success',
                                detail: 'Welcome back'
                            });
                            this._router.navigate(['']);
                        }
                        if (!result.success && result.message == 'invalid OTP') {
                            this.awsApi.loading.next(false);
                            this.messageService.add({
                                severity: 'error',
                                summary: 'Error',
                                detail: Messages.INVALID_OTP
                            });
                            this.ngOtpInputRef.setValue(0);
                            this._router.navigate(['confirm'], { queryParams: { email: this.email, id: reqData.userId, type: "multifactor_auth" } })
                        }
                    }), (error: HttpErrorResponse) => {
                        this.awsApi.loading.next(false);
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: Messages.INVALID_OTP
                        });
                        this.ngOtpInputRef.setValue(0);
                        this._router.navigate(['confirm'], { queryParams: { email: this.email, id: reqData.userId, type: "multifactor_auth" } })
                    }
                }
            }
            else {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: Messages.PLEASE_ENTER_OTP
                });
            }
        }
    }

    onOtpChange(otp) {
        this.otp = otp;
    }

    redirectToPreviousPage() {
        if (this.type == 'forgot_password') {
            this._router.navigate([`forgot-password`]);
        }
        if (this.type == 'verify_user' || this.type == 'multifactor_auth') {
            this._router.navigate([`login`]);
        }
    }

    startTimer(duration: number) {
        this.isTimerExpired = false
        clearInterval(this.interval);
        this.remainingTime = this.formatTime(duration);
        this.interval = setInterval(() => {
            duration--;
            if (duration === 0) {
                this.isTimerExpired = true
                clearInterval(this.interval);
            }
            this.remainingTime = this.formatTime(duration);
        }, 1000);
    }

    formatTime(time: number): string {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');
        return `${formattedMinutes}:${formattedSeconds}`;
    }
}