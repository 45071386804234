import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import convert from 'convert-units';
import * as _ from 'underscore';
import { environment } from 'environments/environment';
import { UserRolesList } from '@/common-list';
import { ApiService } from '@services/api/api.service';
import * as query from 'graphql/queries';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { Amplify } from 'aws-amplify';
import awsconfig from '../../../aws-exports';
import { MessageService } from 'primeng/api';
import * as mutation from 'graphql/mutations';
import { v4 as uuidv4 } from 'uuid';
import { Router } from '@angular/router';
import { NgZone } from '@angular/core';
import { Messages } from '@/Toaster-messages';
import * as AWS from 'aws-sdk';
import { ModelSortDirection, ProductDeliveryStatus, productStatus, ReturnActivityStatus } from 'API';
import { uploadData } from 'aws-amplify/storage';
import amplifyconfig from 'amplifyconfiguration.json';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private profileImageValue = new BehaviorSubject<string>('');
  profileImage$ = this.profileImageValue.asObservable();
  private categoryFilterValue = new BehaviorSubject<string>('');
  categoryFilter$ = this.categoryFilterValue.asObservable();
  private ordersFilterValue = new BehaviorSubject<string>('');
  emailIdSearchFilter$ = this.ordersFilterValue.asObservable();
  access: any = {}
  roles: any = []
  roleOfUser: any = []
  userPool: any
  accessToken: any
  currency: any
  primaryLogo: any
  secondaryLogo: any
  companyName: any

  unitTypes: any = [
    { name: 'Length', value: 'length' },
    { name: 'Area', value: 'area' },
    { name: 'Mass', value: 'mass' },
    { name: 'Volume', value: 'volume' },
    { name: 'Pressure', value: 'pressure' },
    { name: 'Time', value: 'time' },
    { name: 'Temperature', value: 'temperature' },
    { name: 'Others', value: 'others' }
  ];
  unitConvert: any;
  constructor(private httpClient: HttpClient, private ngZone: NgZone, private router: Router, private awsApi: ApiService, private messageService: MessageService) {
    this.unitConvert = convert();
    this.currency = null
    this.primaryLogo = null
    this.secondaryLogo = null
    this.companyName = null
  }

  setCategoryFilterValue(value: string) {
    this.categoryFilterValue.next(value)
  }

  setOrdersFilterValue(value: string) {
    this.ordersFilterValue.next(value)
  }

  async listRoleManagement(filter: any = {}, fieldsToFetch: string[]) {
    const query = this.editListQuery('listRoleManagements', 'ModelRoleManagementFilterInput', fieldsToFetch);

    return this.awsApi.client.graphql({
      query,
      variables: {
        pk: 'ROLEM#',
        filter: filter,
        limit: 20,
        nextToken: null,
        sortDirection: null
      }
    })
  }

  async getIdOfMainModule(mainModuleName) {
    return await this.awsApi.client.graphql({
      query: query.listModuleMasters,
      variables: {
        pk: 'MAIN#',
        filter: { moduleName: { eq: mainModuleName } },
        limit: 9999,
        nextToken: null,
        sortDirection: null
      }
    })
  }

  async getIdOfSubModule(subModuleName, mainModuleId) {
    return await this.awsApi.client.graphql({
      query: query.listModuleMasters,
      variables: {
        pk: 'SUB#' + mainModuleId,
        filter: { moduleName: { eq: subModuleName } },
        limit: 9999,
        nextToken: null,
        sortDirection: null
      }
    })
  }

  async getPermissionsOfUser(mainModuleName, subModuleName: any = null) {
    try {
      let moduleId;

      const [mainModuleResponse, roleManagementResponse] = await Promise.all([
        this.getIdOfMainModule(mainModuleName),
        this.listRoleManagement({}, ['pk', 'id', 'roleName', 'module {', 'moduleId', 'menu', 'view', 'add', 'edit', 'delete', '}']),
      ]);
      moduleId = mainModuleResponse.data.listModuleMasters.items[0].id;
      if (subModuleName) {
        const subModuleResponse = await this.getIdOfSubModule(subModuleName, moduleId);
        moduleId = subModuleResponse.data.listModuleMasters.items[0].id;
      }
      const roleName = UserRolesList.find(role => role.value === localStorage.getItem('role')).text;
      const roleManagementResult = roleManagementResponse['data'].listRoleManagements.items;
      this.roleOfUser = roleManagementResult.filter((item: any) => item.roleName === roleName);
      if (this.roleOfUser.length > 0) {
        const moduleIndex = this.roleOfUser[0].module.findIndex(module => module.moduleId === moduleId);
        if (moduleIndex !== -1) {
          const { view, add, edit, delete: del } = this.roleOfUser[0].module[moduleIndex];
          this.access = { view, add, edit, delete: del };
          return this.access;
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: Messages.SOMETHING_WENT_WRONG
          });
        }
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      }
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  async getRole() {
    const roleName = UserRolesList.find(role => role.value === localStorage.getItem('role')).text;
    const response = await this.listRoleManagement({}, ['pk', 'id', 'roleName', 'module {', 'moduleId', 'menu', 'view', 'add', 'edit', 'delete', '}'])
    const roleManagementResponse = response['data'].listRoleManagements.items;
    this.roleOfUser = roleManagementResponse.filter((item: any) => item.roleName === roleName);
    return this.roleOfUser[0].module
  }

  async denyUserPermisssions() {
    this.access = {}
  }

  getAmplifyConfigurationDetails() {
    Amplify.configure(awsconfig);
    this.userPool = new CognitoUserPool({
      UserPoolId: awsconfig.aws_user_pools_id,
      ClientId: awsconfig.aws_user_pools_web_client_id
    });
  }

  async updateUserAttributes(username: string, attributes: { [key: string]: string }) {
    AWS.config.update({
      region: awsconfig.aws_project_region
    })
    const cognitoISP = new AWS.CognitoIdentityServiceProvider();
    const params = {
      UserAttributes: Object.keys(attributes).map(key => ({
        Name: key,
        Value: attributes[key]
      })),
      Username: username,
      UserPoolId: awsconfig.aws_user_pools_id
    };
    return cognitoISP.adminUpdateUserAttributes(params).promise();
  }

  async getTokenDetails(userSub: any) {
    return await this.awsApi.client.graphql({
      query: query.tokebByUserId,
      variables: {
        pk: 'TOKEN#',
        userID: { eq: userSub }
      }
    });
  }

  saveTokenDetails(refreshToken: any, expiryTime: any) {
    this.getTokenDetails(localStorage.getItem("userId")).then((response) => {
      if (response.data.tokebByUserId.items.length > 0) {
        let data = {
          pk: 'TOKEN#',
          id: response.data.tokebByUserId.items[0].id,
          token: refreshToken,
        }
        this.awsApi.client.graphql({
          query: mutation.updateTokenDetails,
          variables: {
            input: {
              ...data
            }
          }
        }).then((response) => {
          this.awsApi.loading.next(false);
        }).catch((error) => {
          this.awsApi.loading.next(false);

        })
      }
      else {
        let data = {
          pk: 'TOKEN#',
          id: uuidv4(),
          expiresIn: new Date(expiryTime).toISOString(),
          token: refreshToken,
          tokenType: 'Bearer',
          userID: localStorage.getItem("userId"),
          loginAt: new Date().toISOString()
        }
        this.awsApi.client.graphql({
          query: mutation.createTokenDetails,
          variables: {
            input: {
              ...data
            }
          }
        }).then((response) => {
          this.awsApi.loading.next(false);
        }).catch((error) => {
          this.awsApi.loading.next(false);

        });
      }
    }).catch((error) => {
      this.awsApi.loading.next(false);

    });
  }

  saveAccessToken() {
    this.getAmplifyConfigurationDetails()
    try {
      const currentUser = this.userPool.getCurrentUser();
      if (currentUser) {
        currentUser.getSession((err, session) => {
          if (err) {
            return;
          }
          const accessToken = session.getAccessToken().getJwtToken();
          localStorage.setItem('accessToken', accessToken)
          this.saveTokenDetails(session.getRefreshToken().getToken(),
            (JSON.parse(atob(accessToken.split('.')[1]))).exp * 1000)
        });
      }
    } catch (error) {
    }
  }

  async getSession() {
    this.getAmplifyConfigurationDetails();
    try {
      const currentUser = await this.userPool.getCurrentUser();
      let sessionExpired = true
      currentUser.getSession(async (err, session) => {
        const currentTime = Math.floor(new Date().getTime() / 1000);
        if ((session.isValid() && session.getAccessToken().getExpiration() < currentTime) || currentUser.signInUserSession == null) {
          currentUser.refreshSession(session.getRefreshToken(), async (refreshErr, refreshedSession) => {
            if (refreshedSession) {
              const accessToken = refreshedSession.getAccessToken().getJwtToken();
              this.saveTokenDetails(refreshedSession.getRefreshToken().getToken(),
                (JSON.parse(atob(accessToken.split('.')[1]))).exp * 1000)
              await this.setAccessToken(accessToken);
            }
          });
        }
        else {
          sessionExpired = !sessionExpired
          await this.setAccessToken(session.getAccessToken().getJwtToken());
        }
      });
      if (sessionExpired) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SESSION_EXPIRED
        });
        this.router.navigate(['login'])
      }
    } catch (error) { }
  }

  async validateImageDimensions(file: File, type: any, device: any = null): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      let img = new Image();
      img.onload = () => {
        let isValid = false;
        switch (type) {
          case 'carousel':
            if (device == 'web') {
              isValid = img.width === 1920 && img.height === 700;
            } else if (device == 'mobile') {
              isValid = img.width === 461 && img.height === 170;
            }
            break;
          case 'category':
            if (device == 'web') {
              isValid = img.width === 1920 && img.height === 500;
            } else if (device == 'mobile') {
              isValid = img.width === 461 && img.height === 170;
            }
            break;
          case 'primaryLogo':
            isValid = img.width === 250 && img.height === 140;
            break;
          case 'secondaryLogo':
            isValid = img.width === 140 && img.height === 140;
            break;
          case 'mobileAppsIcon1':
            isValid = img.width === 117 && img.height === 36;
            break;
          case 'mobileApp':
            isValid = img.width === 117 && img.height === 36;
            break;
          case 'mobileAppsIcon2':
            isValid = img.width === 117 && img.height === 36;
            break;
          case 'payment':
            isValid = img.width === 250 && img.height === 140;
            break;
          default:
            isValid = false
            break;
        }
        resolve(isValid)
      };
      img.src = URL.createObjectURL(file)
    })
  }

  async getHeader(): Promise<HttpHeaders> {
    await this.getSession();
    return new HttpHeaders({
      'Authorization': localStorage.getItem('accessToken')
    });
  }

  async setAccessToken(token: string) {
    this.accessToken = token;
    this.ngZone.run(() => {
      localStorage.removeItem('accessToken')
      localStorage.setItem('accessToken', token);
    });
  }

  async unitDetails() {
    var unitList: any = {};
    _.each(this.unitTypes, (item) => {
      unitList[item.value] = this.unitConvert.list(item.value);
    });
    return await unitList;
  }

  async unitSymbol() {
    var unitList: any = {};
    _.each(this.unitTypes, (item) => {
      unitList[item.value] = [];
      _.each(this.unitConvert.list(item.value), (mapItem) => {
        unitList[item.value].push(mapItem.abbr);
      });
    });
    return await unitList;
  }

  async unitMapDetail() {
    var unitMap: any = {};
    _.each(this.unitTypes, (item) => {
      _.each(this.unitConvert.list(item.value), (mapItem) => {
        unitMap[mapItem.abbr] = mapItem;
      });
    });
    return await unitMap;
  }

  async setInfoOfCompany() {
    let companyResponse, companyDetail
    companyResponse = await this.getCompanyDetail();
    if (companyResponse.data.listCompanyDetails.items.length > 0) {
      companyDetail = companyResponse.data.listCompanyDetails.items[0]
    }
    else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
    this.currency = companyDetail?.currency || ''
    this.primaryLogo = companyDetail?.primaryLogo?.url || ''
    this.secondaryLogo = companyDetail?.secondaryLogo?.url || ''
    this.companyName = companyDetail?.companyName || ''
  }

  async getCurrencyOfCompany(): Promise<any> {
    if (this.currency == null) {
      await this.setInfoOfCompany()
    }
    return this.currency
  }

  async setProfileImage(value: string) {
    this.profileImageValue.next(value)
  }

  initializeProfileImage(value: string): void {
    this.profileImageValue.next(value);
  }

  async getPrimaryLogoOfCompany(): Promise<any> {
    if (this.primaryLogo == null) {
      await this.setInfoOfCompany()
    }
    return this.primaryLogo
  }

  async getCompanyName(): Promise<any> {
    if (this.companyName == null) {
      await this.setInfoOfCompany()
    }
    return this.companyName
  }

  async getSecondaryLogoOfCompany(): Promise<any> {
    if (this.secondaryLogo == null) {
      await this.setInfoOfCompany()
    }
    return this.secondaryLogo
  }

  async markAllControlsAsTouched(form: any) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      if (control) {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  async getProductByBrandId(brandId): Promise<any> {
    let filterObj = {}
    filterObj['status'] = { ne: productStatus.deleted }
    let items = [];
    let nextToken = null;
    do {
      const response = await this.awsApi.client.graphql({
        query: query.productByBrandIdGsi,
        variables: {
          brandID: brandId,
          createdAt: null,
          filter: filterObj,
          limit: 650,
          nextToken: nextToken,
          sortDirection: null
        }
      })
      items = items.concat(response.data.productByBrandIdGsi.items);
      if (items.length > 0) { return items; }
      nextToken = response.data.productByBrandIdGsi.nextToken;
    } while (nextToken !== null)
    return items
  }

  async updateLatestProductsInLocalStore() {
    let productList = []
    let fieldsToFetch = ['pk', 'id', 'featuredImage {', 'url', '}', 'productName', 'productDescription', 'scheduleDate', 'status', 'brandName',
      'mainCategoryID', 'mainCategory', 'subCategoryID', 'brandID', 'subCategory', 'createdAt', 'totalStockQuantity', 'totalReservedStock', 'createdAt', 'updatedAt', 'searchKey']

    let savedProducts = localStorage.getItem("products") !== undefined ? JSON.parse(localStorage.getItem("products")) : null
    if (savedProducts && savedProducts.length > 0) {
      let lastUpdatedTime = new Date(savedProducts[0].updatedAt)
      let now = new Date()
      if (lastUpdatedTime < now) {
        let filterObject = {}
        filterObject['updatedAt'] = { ge: lastUpdatedTime }
        let updatedProducts = await this.getProducts(filterObject, fieldsToFetch)
        const idsInsavedProducts = new Set(savedProducts.map(item => item.id)); // getting Id of saved products
        const latestUpdatedProducts = new Map(updatedProducts.map(item => [item.id, item]));
        const combined = [...savedProducts, ...updatedProducts.filter(item => !idsInsavedProducts.has(item.id))]; // combining saved products and newly added products from updated products
        productList = Array.from(new Map(combined.map(item => [item.id, latestUpdatedProducts.get(item.id) || item])).values()) // to remove the deleted products from saveproducts
          .filter((item) => item.status != productStatus.deleted)
          .sort((a: any, b: any) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
        localStorage.setItem('products', JSON.stringify(productList))
      }
    }
    else {
      productList = (await this.getProducts({}, fieldsToFetch))
        .sort((a: any, b: any) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
      localStorage.setItem('products', JSON.stringify(productList))
    }
  }

  async isAnyProductAvailable(filter: any, type: any): Promise<Boolean> {
    let products: any
    if (type == 'country') { products = await this.getProducts(filter, ['pk', 'id'], 'Country Delete'); }
    else if (type == 'category') { products = await this.getProducts(filter, ['pk', 'id'], 'Category Delete'); }
    else {
      products = await this.getProductByBrandId(filter)
    }
    if (products.length > 0) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'This ' + type + ' cannot be deleted as there are products associated with it.'
      });
      return true;
    }
    return false;
  }

  async isAnyOrdersOpen(filter: any = {}, brandName: any = null): Promise<Boolean> {
    let valid = true
    let orderItems = await this.getOrderItems(filter, null, brandName);
    if (!brandName) {
      for (let item of orderItems) {
        let orderStatus = item.deliveryStatus;
        if (orderStatus === ProductDeliveryStatus.Delivered && item.returnRefundActivity) {
          let returnStatus = item.returnRefundActivity.status;
          if (!(returnStatus !== ReturnActivityStatus.Cancelled ||
            returnStatus !== ReturnActivityStatus.Received ||
            returnStatus !== ReturnActivityStatus.RefundApproved ||
            returnStatus !== ReturnActivityStatus.RefundRejected)) {
            this.awsApi.loading.next(false);
            valid = false
          }
        } else if (!(orderStatus == ProductDeliveryStatus.Delivered || orderStatus == ProductDeliveryStatus.Cancelled)) {
          this.awsApi.loading.next(false);
          valid = false
        }
      }
      if (!valid) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to delete as open orders exist'
        });
        return true;
      }
      return false;
    } else {
      if (orderItems.length > 0) {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to delete this brand as open orders exist'
        });
        return true;
      }
      return false;
    }
  }

  async getAllCategory(filterObject: any = {}, fieldsToFetch: string[]) {
    const query = this.editListQuery('listCategories', 'ModelCategoryFilterInput', fieldsToFetch);
    let items = [];
    let nextToken = null;
    do {
      const response = await this.awsApi.client.graphql({
        query,
        variables: {
          filter: filterObject,
          limit: 650,
          nextToken: nextToken,
          sortDirection: null
        }
      })
      const responseData = response['data'].listCategories;
      items = items.concat(responseData.items);
      nextToken = responseData.nextToken;
    } while (nextToken !== null)
    return items
  }

  async getAllMainBrand() {
    return await this.awsApi.client.graphql({
      query: query.listBrands,
      variables: {
        pk: 'BRAND#'
      }
    });
  }

  async getReportMaster(filter: any = {}) {
    return this.awsApi.client
      .graphql({
        query: query.listReportMasters,
        variables: {
          pk: 'REPORT#',
          id: null,
          filter: filter,
          limit: 9999,
          nextToken: null,
          sortDirection: null
        }
      })
  }

  async listUsers(filterObject: any = {}, fieldsToFetch: string[], iamMode: boolean) {
    try {
      const query = this.editListQuery('listUsers', 'ModelUserFilterInput', fieldsToFetch);
      let items = [];
      let nextToken = null;
      do {
        const response = await this.awsApi.client.graphql({
          query,
          authMode: iamMode ? 'iam' : 'userPool',
          variables: {
            filter: filterObject,
            limit: 650,
            nextToken: nextToken,
            sortDirection: null
          }
        })
        const responseData = response['data'].listUsers;
        items = items.concat(responseData.items);
        nextToken = responseData.nextToken;
      } while (nextToken !== null)
      return items
    } catch (error) { }
  }

  async listUsersAuthorized(filterObject: any = {}) {
    try {
      let items = [];
      let nextToken = null;
      do {
        const response = await this.awsApi.client.graphql({
          query: query.listUsers,
          variables: {
            filter: filterObject,
            limit: 650,
            nextToken: nextToken,
            sortDirection: null
          }
        })
        const responseData = response['data'].listUsers;
        items = items.concat(responseData.items);
        nextToken = responseData.nextToken;
      } while (nextToken !== null)
      return items
    } catch (error) { }
  }

  async getProductReviews(filterObject: any = {}, fieldsToFetch: string[]) {
    const query = this.editListQuery('listProductReviews', 'ModelProductReviewFilterInput', fieldsToFetch);
    let items = [];
    let nextToken = null;
    do {
      const response = await this.awsApi.client.graphql({
        query,
        variables: {
          filter: filterObject,
          limit: 650,
          nextToken: nextToken,
          sortDirection: null
        }
      })
      const responseData = response['data'].listProductReviews;
      items = items.concat(responseData.items);
      nextToken = responseData.nextToken;
    } while (nextToken !== null)
    return items
  }

  async getCart(fieldsToFetch: string[]) {
    let items = [];
    const query = this.editListQuery('listCarts', 'ModelCartFilterInput', fieldsToFetch);
    let nextToken = null;
    do {
      const response = await this.awsApi.client.graphql({
        query,
        variables: {
          filter: {},
          limit: 650,
          nextToken: nextToken,
          sortDirection: null
        }
      })
      const responseData = response['data'].listCarts;
      items = items.concat(responseData.items);
      nextToken = responseData.nextToken;
    } while (nextToken !== null)
    return items
  }

  editListQuery(queryName: string, filterType: string, fieldsToFetch: string[]) {
    const fieldsString = fieldsToFetch.join('\n');
    return `
    query ${queryName}($filter: ${filterType}, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
      ${queryName}(filter: $filter, limit: $limit, nextToken: $nextToken, sortDirection: $sortDirection) {
        items {
          ${fieldsString}
        }
        nextToken
      }
    }
  `;
  }

  editListAllProductsQuery(queryName: string, filterType: string, fieldsToFetch: string[]) {
    const fieldsString = fieldsToFetch.join('\n');
    return `
      query ${queryName}($globalPk: String!, $filter: ${filterType}, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        ${queryName}(globalPk: $globalPk, filter: $filter, limit: $limit, nextToken: $nextToken, sortDirection: $sortDirection) {
          items {
            ${fieldsString}
          }
          nextToken
        }
      }
    `;
  }

  async getGeneralQueries(filterObject: any = {}, fieldsToFetch: string[]) {
    const query = this.editListQuery('listGeneralContactuses', 'ModelGeneralContactUsFilterInput', fieldsToFetch);
    let items = [];
    let nextToken = null;
    do {
      const response = await this.awsApi.client.graphql({
        query,
        variables: {
          filter: filterObject,
          limit: 650,
          nextToken: nextToken,
          sortDirection: null
        }
      })
      const responseData = response['data'].listGeneralContactuses;
      items = items.concat(responseData.items);
      nextToken = responseData.nextToken;
    } while (nextToken !== null)
    return items
  }

  async getNotifications(filterObject: any = {}) {
    let items = [];
    let nextToken = null;
    do {
      const response = await this.awsApi.client
        .graphql({
          query: query.listNotifications,
          variables: {
            pk: 'NOTI#',
            id: null,
            filter: filterObject,
            limit: 650,
            nextToken: nextToken,
            sortDirection: null
          }
        })
      items = items.concat(response.data.listNotifications.items);
      nextToken = response.data.listNotifications.nextToken;
    } while (nextToken !== null)
    return items
  }

  async getUserDetails(email, role) {
    let items = [];
    let nextToken = null;
    do {
      const response = await this.awsApi.client
        .graphql({
          query: query.listUsers,
          variables: {
            pk: 'USER#',
            id: null,
            filter: {
              email: {
                eq: email
              },
              role: { eq: role }
            },
            limit: 650,
            nextToken: nextToken,
            sortDirection: null
          }
        })
      items = items.concat(response.data.listUsers.items);
      nextToken = response.data.listUsers.nextToken;
    } while (nextToken !== null)
    return items
  }

  async getDeliveryPartners(filterObject: any = {}, fieldsToFetch: string[]) {
    const query = this.editListQuery('listDeliveryPartners', 'ModelDeliveryPartnerFilterInput', fieldsToFetch);

    let items = [];
    let nextToken = null;
    do {
      const response = await this.awsApi.client.graphql({
        query,
        variables: {
          pk: 'DELP#',
          id: null,
          filter: filterObject,
          limit: 650,
          nextToken: nextToken,
          sortDirection: null
        }
      })
      const responseData = response['data'].listDeliveryPartners;
      items = items.concat(responseData.items);
      nextToken = responseData.nextToken;
    } while (nextToken !== null)
    return items
  }

  async getPromoCodes(filterObject: any = {}, fieldsToFetch: string[]) {
    const query = this.editListQuery('listPromoCodes', 'ModelPromoCodesFilterInput', fieldsToFetch);

    let items = [];
    let nextToken = null;
    do {
      const response = await this.awsApi.client.graphql({
        query,
        variables: {
          pk: 'PROMO#',
          id: null,
          filter: filterObject,
          limit: 650,
          nextToken: nextToken,
          sortDirection: null
        }
      })
      const responseData = response['data'].listPromoCodes;
      items = items.concat(responseData.items);
      nextToken = responseData.nextToken;
    } while (nextToken !== null)
    return items
  }

  async getShipping(filterObject: any = {}, fieldsToFetch: string[]) {
    const query = this.editListQuery('listShippings', 'ModelShippingFilterInput', fieldsToFetch);
    let items = [];
    let nextToken = null;
    do {
      const response = await this.awsApi.client.graphql({
        query,
        variables: {
          filter: filterObject,
          limit: 650,
          nextToken: nextToken
        }
      })
      const responseData = response['data'].listShippings;
      items = items.concat(responseData.items);
      nextToken = responseData.nextToken;
    } while (nextToken !== null)
    return items
  }

  async getProducts(filterObject = {}, fieldsToFetch: string[] = null, type: any = null) {
    const query = this.editListAllProductsQuery('listAllProducts', 'ModelProductFilterInput', fieldsToFetch);
    let items = [];
    let nextToken = null;
    try {
      do {
        const response = await this.awsApi.client.graphql({
          query,
          variables: {
            globalPk: 'PRODUCTS#',
            filter: filterObject,
            limit: 650,
            nextToken: nextToken,
            sortDirection: ModelSortDirection.DESC
          }
        });
        const responseData = response['data'].listAllProducts;
        items = items.concat(responseData.items);
        if (items.length > 0 && (type === 'Category Delete' || type === 'Country Delete' || type === 'Warehouse Delete' || type === 'Tax Delete' || type === 'Unit Delete')) {
          return items;
        }
        nextToken = responseData.nextToken;
      } while (nextToken !== null);
    } catch (error) { }
    return items
  }

  async getProductByMainCategoryId(mainCategory) {
    return await this.awsApi.client.graphql({
      query: query.productByMainCategoryIdGsi,
      variables: {
        mainCategoryID: mainCategory.id
      }
    });
  }

  async getProductDetailsWithEditQuery(filterObject: any = {}, fieldsToFetch: string[] = null, type: any = null) {
    const query = this.editListQuery('listProductDetails', 'ModelProductDetailsFilterInput', fieldsToFetch);
    let items = [];
    let nextToken = null;
    do {
      const response = await this.awsApi.client.graphql({
        query,
        variables: {
          filter: filterObject,
          limit: 650,
          nextToken: nextToken,
          sortDirection: null
        }
      })
      const responseData = response['data'].listProductDetails;
      items = items.concat(responseData.items);
      if ((type === 'Variant Delete' || type === 'Stock Unit Delete') && items.length > 0) {
        return items;
      }
      nextToken = responseData.nextToken;
    } while (nextToken !== null)
    return items
  }


  async getCompanyDetail() {
    return await this.awsApi.client.graphql({
      query: query.listCompanyDetails
    })
  }

  async getOrders(filterObject: any = {}, fieldsToFetch: string[]) {
    const query = this.editListQuery('listOrders', 'ModelOrderFilterInput', fieldsToFetch);
    let items = [];
    let nextToken = null;
    do {
      const response = await this.awsApi.client.graphql({
        query,
        variables: {
          filter: filterObject,
          limit: 650,
          nextToken: nextToken,
          sortDirection: null
        }
      })
      const responseData = response['data'].listOrders;
      items = items.concat(responseData.items);
      nextToken = responseData.nextToken;
    } while (nextToken !== null)
    return items
  }

  async getOrderItems(filterObject: any = {}, pk: any = null, brandName: any = null) {
    let items = [];
    let nextToken = null;
    do {
      const response = await this.awsApi.client.graphql({
        query: query.listOrderItems,
        variables: {
          pk: pk,
          id: null,
          filter: filterObject,
          limit: 650,
          nextToken: nextToken,
          sortDirection: null
        }
      })
      items = brandName ? items.concat(response.data.listOrderItems.items.filter(item =>
        item.deliveryStatus !== ProductDeliveryStatus.Delivered &&
        item.deliveryStatus !== ProductDeliveryStatus.Cancelled &&
        item.deliveryStatus !== null
      )) : items.concat(response.data.listOrderItems.items);
      nextToken = response.data.listOrderItems.nextToken;
    } while (nextToken !== null)
    return items
  }

  dateInReverse(date) {
    if (date) {
      var datetime = date
      var bits = datetime.split(/\D/);
      var dateObject = new Date(bits[0], --bits[1], bits[2], bits[3], bits[4]);
      const formattedDate = dateObject.toLocaleDateString('en-GB', {
        day: 'numeric', month: 'short', year: 'numeric'
      }).replace(/ /g, '-');
      return formattedDate
    }
    return '-'
  }

  async isValidFileType(event): Promise<any> {
    let file = event.target.files[0];
    if (!file.type.startsWith('image/')) {
      event.target.value = '';
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.INVALID_FILE_TYPE
      });
      return false;
    }
    return true;
  }

  async getS3Url(file: any): Promise<any> {
    const result = await uploadData({
      key: file.name,
      data: file,
      options: {
        accessLevel: 'guest'
      }
    }).result;
    return 'https://' + amplifyconfig.aws_user_files_s3_bucket + '.s3.' + amplifyconfig.aws_user_files_s3_bucket_region + '.amazonaws.com/public/' + result.key;
  }

  stockReports(obj: any, headers: any): Observable<any> {
    let url = environment.stockReport
    return this.httpClient.post(url, obj, { headers })
  }

  categoryReports(obj: any, headers: any): Observable<any> {
    let url = environment.categoryReport
    return this.httpClient.post(url, obj, { headers })
  }

  productReports(obj: any, headers: any): Observable<any> {
    let url = environment.productReport
    return this.httpClient.post(url, obj, { headers })
  }

  returnAndRefundReports(obj: any, headers: any): Observable<any> {
    let url = environment.returnAndRefundReport
    return this.httpClient.post(url, obj, { headers })
  }

  deleteUser(obj: any, headers: any): Observable<any> {
    let url = environment.deleteUser
    return this.httpClient.post(url, obj, { headers })
  }

  editUser(obj: any, headers: any): Observable<any> {
    let url = environment.editUser
    return this.httpClient.post(url, obj, { headers })
  }

  orderReports(obj: any, headers: any): Observable<any> {
    let url = environment.orderReport
    return this.httpClient.post(url, obj, { headers })
  }

  addUser(obj: any, headers: any): Observable<any> {
    let url = environment.addUser
    return this.httpClient.post(url, obj, { headers })
  }

  sendOtp(obj: any): Observable<any> {
    let url = environment.sendOtp
    return this.httpClient.post(url, obj)
  }

  paymentLedgers(obj: any, headers: any): Observable<any> {
    let url = environment.paymentLedger
    return this.httpClient.post(url, obj, { headers })
  }

  orderDetailsPdf(obj: any, headers: any): Observable<any> {
    let url = environment.orderDetailsPdf
    return this.httpClient.post(url, obj, { headers })
  }

  returnDetailsPdf(obj: any, headers: any): Observable<any> {
    let url = environment.returnDetailsPdf
    return this.httpClient.post(url, obj, { headers })
  }

  categoryEditInProducts(obj: any, headers: any): Observable<any> {
    let url = environment.categoryEditInProducts
    return this.httpClient.post(url, obj, { headers })
  }

  forgotPassword(obj: any): Observable<any> {
    let url = environment.forgotPassword
    return this.httpClient.post(url, obj)
  }

  readyforpickupnotification(obj: any, headers: any): Observable<any> {
    let url = environment.readyforpickupnotification
    return this.httpClient.post(url, obj, { headers })
  }
}
