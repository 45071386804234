export const environment = {
    production: true,
    GA_ID: '',
    confirm: {
        email: '',
        password: '',
        attributeName: '',
        phone_number: ''
    },
    env: 'prod',
    esApi: 'https://hnuwzfuvoh.execute-api.us-east-1.amazonaws.com/prod/signing_requests_es',
    package:
        'https://hnuwzfuvoh.execute-api.us-east-1.amazonaws.com/prod/packaging_list',
    elasticSearchEndpoint:
        'https://search-amplify-elasti-1emxaqdcuvsq6-5us7notgxhzj5vknkgaldvf464.us-east-1.es.amazonaws.com/',

    stockReport: 'https://arubauaf6c.execute-api.me-south-1.amazonaws.com/Prod/stockreport',
    productReport: 'https://ttexqxjbti.execute-api.me-south-1.amazonaws.com/Prod/productsreport',
    categoryReport: 'https://lcu2rmolp4.execute-api.me-south-1.amazonaws.com/Prod/categoryreport',
    returnAndRefundReport: 'https://xlgh3l4zjg.execute-api.me-south-1.amazonaws.com/Prod/returnandrefundreport',
    deleteUser: 'https://r8h8fzn0p6.execute-api.me-south-1.amazonaws.com/Prod/userdelete',
    editUser: 'https://wn8fpw11qe.execute-api.me-south-1.amazonaws.com/Prod/userupdate',
    orderReport: 'https://8geu1ofebj.execute-api.me-south-1.amazonaws.com/Prod/ordersreport',
    addUser: 'https://fpgeckgc0f.execute-api.me-south-1.amazonaws.com/Prod/sendemailpasswordmail',
    paymentLedger: 'https://cr8ntycom5.execute-api.me-south-1.amazonaws.com/Prod/paymentsledger',
    orderDetailsPdf: 'https://yftmc3ned4.execute-api.me-south-1.amazonaws.com/Prod/orderanddetailspdf',
    returnDetailsPdf: 'https://eiigaby3bk.execute-api.me-south-1.amazonaws.com/Prod/returnandrefurndpdf',
    sendOtp: 'https://6jpeijbtv5.execute-api.me-south-1.amazonaws.com/Prod/mail_otp',
    categoryEditInProducts: 'https://bnuur6o086.execute-api.me-south-1.amazonaws.com/Prod/triggercategorytableapi',
    forgotPassword: 'https://5bo9pjh0fg.execute-api.me-south-1.amazonaws.com/Prod/changepassword',
    readyforpickupnotification: 'https://g8s5l3mldf.execute-api.me-south-1.amazonaws.com/Prod/readyforpickupnotification'
};
