<section id="dashboardSec">
    <div class="container-fluid">
        <div class="row mb-2 pt-2">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="d-flex justify-content-end align-items-center gap-2">
                    <label class="mb-0">Select Date Range</label>
                    <p-calendar [iconDisplay]="'input'" [showIcon]="true" [maxDate]="maxDate" inputId="icondisplay"
                        selectionMode="range" placeholder="From-To" [(ngModel)]="dateRange"
                        (onSelect)="onSelectDateRange()"></p-calendar>
                </div>
            </div>
        </div>
        <div class="row products-order-row">
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-start align-items-center gap-2">
                            <div>
                                <img src="../../../assets/img/dashboardProducts.png">
                            </div>
                            <div>
                                <h2>Products</h2>
                                <h1>{{currency}} {{ (productsValue | number: '.2') }}</h1>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="d-flex justify-content-between align-items-center">
                            <label>Total Products</label>
                            <h3>{{noOfProducts}}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-start align-items-center gap-2">
                            <div>
                                <img src="../../../assets/img/dashboardOrders.png">
                            </div>
                            <div>
                                <h2>Orders</h2>
                                <h1>{{currency}} {{(ordersValue | number: '.2')}}</h1>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="d-flex justify-content-between align-items-center">
                            <label>Total Orders</label>
                            <h3>{{noOfOrders}}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-start align-items-center gap-2">
                            <div>
                                <img src="../../../assets/img/dashboardPendingOrders.png">
                            </div>
                            <div>
                                <h2>Pending Orders</h2>
                                <h1>{{currency}} {{ pendingOrdersValue | number: '.2' }}</h1>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="d-flex justify-content-between align-items-center">
                            <label>Total Pending Orders</label>
                            <h3>{{noOfPendingOrders}}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-start align-items-center gap-2">
                            <div>
                                <img src="../../../assets/img/dashboardRefundOrders.png">
                            </div>
                            <div>
                                <h2>Refund orders</h2>
                                <h1>{{currency}} {{ refundOrdersValue | number: '.2'}}</h1>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="d-flex justify-content-between align-items-center">
                            <label>Total Refund orders</label>
                            <h3>{{ refundOrders}}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row stock-sales-row">
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="card">
                    <div class="card-header">
                        <h4>Category Stock</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                <p-chart type="bar" [data]="categoryStockData" [options]="categoryStockOptions" />
                            </div>
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="row">
                                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12"
                                        *ngFor="let item of categoryNames;let i=index">
                                        <div class="d-flex justify-content-start align-items-center gap-2 mb-2">
                                            <div class="square-box"
                                                [style.background-color]="randomColorsForCategoryStock[i]"></div>
                                            <p>{{item.name}}: <span>{{categoryStockSet[item.id] | number }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="card">
                    <div class="card-header">
                        <h4>Orders and Refunds Over Last 6 Months</h4>
                    </div>
                    <div class="card-body">
                        <p-chart type="bar" [data]="salesReturnData" [options]="salesReturnOptions" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row dashboard-table-row">
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex justify-content-between align-items-center">
                            <h4>Top 10 Selling Products</h4>
                        </div>
                    </div>
                    <div class="card-body parent-card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="card mb-0" *ngFor="let item of topSellingProducts">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div class="table-row action-table-responsive table-responsive">
                                                    <table class="table table-borderless">
                                                        <thead>
                                                            <tr class="cursor-pointer"
                                                                (click)="redirectToProductDetails(item)">
                                                                <th colspan="3" class="pb-2">
                                                                    <div>
                                                                        <p>Product Name</p>
                                                                        <h6 *ngIf="item.productName.length>177">
                                                                            {{item.productName.slice(0,177)}}...
                                                                        </h6>
                                                                        <h6 *ngIf="item.productName.length<=177">
                                                                            {{item.productName}}</h6>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <div>
                                                                        <p>Brand</p>
                                                                        <h6
                                                                            *ngIf="item.brand && item.brand != 'undefined'">
                                                                            {{item.brand}}</h6>
                                                                        <h6
                                                                            *ngIf="!item.brand || item.brand == 'undefined'">
                                                                            N/A</h6>
                                                                    </div>
                                                                </td>
                                                                <td class="text-end">
                                                                    <div>
                                                                        <p>Quantity</p>
                                                                        <h6>{{item.totalQty}}</h6>
                                                                    </div>
                                                                </td>
                                                                <td class="text-end">
                                                                    <div>
                                                                        <p>Amount</p>
                                                                        <h6>{{item.totalAmount | number: '.2'}}</h6>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex justify-content-between align-items-center">
                            <h4>Top 10 Selling Categories</h4>
                        </div>
                    </div>
                    <div class="card-body parent-card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="card mb-0" *ngFor="let item of topSellingCategories">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div class="table-row action-table-responsive table-responsive">
                                                    <table class="table table-borderless">
                                                        <tbody>
                                                            <tr (click)="redirectToCategory(item)"
                                                                class="cursor-pointer">
                                                                <td>
                                                                    <div>
                                                                        <p>Category Name</p>
                                                                        <h6>{{item.mainCategory}}</h6>
                                                                    </div>
                                                                </td>
                                                                <td class="text-end">
                                                                    <div>
                                                                        <p>Quantity</p>
                                                                        <h6>{{item.totalQty}}</h6>
                                                                    </div>
                                                                </td>
                                                                <td class="text-end">
                                                                    <div>
                                                                        <p>Amount</p>
                                                                        <h6>{{ item.totalAmount | number: '.2' }}</h6>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex justify-content-between align-items-center">
                            <h4>Pending Orders</h4>
                        </div>
                    </div>
                    <div class="card-body parent-card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="card mb-0" *ngFor="let item of pendingOrdersList">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div class="table-row action-table-responsive table-responsive">
                                                    <table class="table table-borderless">
                                                        <tbody>
                                                            <tr (click)="redirectToOrderDetailsPage(item.id)"
                                                                class="cursor-pointer">
                                                                <td>
                                                                    <div>
                                                                        <p>Order Ref</p>
                                                                        <h6>{{item.id}}</h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <p>Ordered On</p>
                                                                        <h6>{{ item.createdAt | date: 'dd/MM/yyyy' : 'UTC' }}
                                                                        </h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <p>Customer Name</p>
                                                                        <h6>{{item.userName}}</h6>
                                                                    </div>
                                                                </td>
                                                                <td nowrap>
                                                                    <div>
                                                                        <p>Email</p>
                                                                        <h6>{{item.email}}</h6>
                                                                    </div>
                                                                </td>
                                                                <td nowrap>
                                                                    <div>
                                                                        <p>Payment Type</p>
                                                                        <h6 *ngIf="item.paymentDetails?.paymentMethod">
                                                                            {{item.paymentDetails?.paymentMethod |
                                                                            formatDocType}}</h6>
                                                                        <h6 *ngIf="!item.paymentDetails?.paymentMethod">
                                                                            N/A</h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <p>Delivery Type</p>
                                                                        <h6>{{item.deliveryInfo?.deliveryType}}</h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <p>Order Value</p>
                                                                        <h6>{{currency}} {{item.totalAmount | number:
                                                                            '.2'}}</h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <p>Status</p>
                                                                        <h6 class="blue-color">{{item.status |
                                                                            formatDocType}}</h6>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex justify-content-between align-items-center">
                            <h4>Pending Return Items</h4>
                        </div>
                    </div>
                    <div class="card-body parent-card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="card mb-0" *ngFor="let item of pendingReturnRequests">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div class="table-row action-table-responsive table-responsive">
                                                    <table class="table table-borderless">
                                                        <thead>
                                                            <tr (click)="redirectToReturnItemDetails(item)"
                                                                class="cursor-pointer">
                                                                <th class="pb-2">
                                                                    <div>
                                                                        <p>Product Name</p>
                                                                        <h6
                                                                            *ngIf="item.itemDetail.productName.length>75">
                                                                            {{item.itemDetail.productName.slice(0,75)}}...
                                                                        </h6>
                                                                        <h6
                                                                            *ngIf="item.itemDetail.productName.length<=75">
                                                                            {{item.itemDetail.productName}}</h6>
                                                                    </div>
                                                                </th>
                                                                <th class="pb-2">
                                                                    <div>
                                                                        <p>Return Subject</p>
                                                                        <h6>{{item.returnRefundActivity.reason}}</h6>
                                                                    </div>
                                                                </th>
                                                                <th class="pb-2">
                                                                    <div>
                                                                        <p>Return Ref</p>
                                                                        <h6>{{item.returnRefundActivity.returnReference}}
                                                                        </h6>
                                                                    </div>
                                                                </th>
                                                                <th class="pb-2">
                                                                    <div>
                                                                        <p>Requested Date</p>
                                                                        <h6>{{item.returnRefundActivity.createdAt
                                                                            | date: 'dd/MM/yyyy' : 'UTC' }}</h6>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <th>
                                                                    <div>
                                                                        <p>Email</p>
                                                                        <h6>{{item.email}}</h6>
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div>
                                                                        <p>Order Ref</p>
                                                                        <h6>{{item.pk.split('#')[1]}}</h6>
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div>
                                                                        <p>Delivery Date</p>
                                                                        <h6>{{item.deliveryTracking[item.deliveryTracking.length-1].date
                                                                            | date: 'dd/MM/yyyy' : 'UTC' }}</h6>
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div>
                                                                        <p>Return Value</p>
                                                                        <h6>{{currency}} {{(item.itemDetail.price *
                                                                            item.returnRefundActivity.quantity) +
                                                                            (item.taxAmount*item.returnRefundActivity.quantity)
                                                                            | number: '.2'}}</h6>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex justify-content-between align-items-center">
                            <h4>Top 10 Buying Customers</h4>
                        </div>
                    </div>
                    <div class="card-body parent-card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="card mb-0" *ngFor="let item of buyingCustomers">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div class="table-row action-table-responsive table-responsive">
                                                    <table class="table table-borderless">
                                                        <thead>
                                                            <tr (click)="redirectToCustomers(item)"
                                                                class="cursor-pointer">
                                                                <th class="pb-2">
                                                                    <div>
                                                                        <p>Customers Name</p>
                                                                        <h6>{{item.userName}}</h6>
                                                                    </div>
                                                                </th>
                                                                <th class="pb-2 text-end">
                                                                    <div>
                                                                        <p>Number of Orders</p>
                                                                        <h6>{{item.noOfOrders}}</h6>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <div>
                                                                        <p>Email</p>
                                                                        <h6>{{item.email}}</h6>
                                                                    </div>
                                                                </td>
                                                                <td class="text-end">
                                                                    <div>
                                                                        <p>Value Of Orders</p>
                                                                        <h6>{{currency}} {{item.valueOfOrders | number:
                                                                            '.2'}}
                                                                        </h6>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex justify-content-between align-items-center">
                            <h4>Latest Enquiries</h4>
                            <button type="button" class="btn" (click)="redirectToCustomerQueries()">View all</button>
                        </div>
                    </div>
                    <div class="card-body parent-card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="card mb-0" *ngFor="let item of queriesList">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div class="table-row action-table-responsive table-responsive">
                                                    <table class="table table-borderless">
                                                        <thead>
                                                            <tr (click)="redirectToGeneralQueries(item)"
                                                                class="cursor-pointer">
                                                                <th class="pb-2">
                                                                    <div>
                                                                        <p>Enquiry</p>
                                                                        <h6>{{item.subject}}</h6>
                                                                    </div>
                                                                </th>
                                                                <th class="pb-2">
                                                                    <div>
                                                                        <p>Enquiry Date</p>
                                                                        <h6>{{item.createdAt | date: 'dd/MM/yyyy' : 'UTC' }}</h6>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <div>
                                                                        <p>Email</p>
                                                                        <h6>{{item.email}}</h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <p>Status</p>
                                                                        <h6 class="blue-color">{{item.enquiryStatus |
                                                                            formatDocType}}</h6>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>