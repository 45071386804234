<h5>Customize Site</h5>
<hr class="mb-2" />
<div style="padding: 8px 0">
    <div style="display: none; align-items: center">
        <pf-checkbox id="darkMode" (change)="handleDarkModeChange($event)" />
        <label for="darkMode" style="margin: 0; padding: 0; padding-left: 4px">
            Dark mode
        </label>
    </div>
    <div class="mt-2">
        <label>Light Navbar Variants</label>
        <pf-select [value]="navbarVariant" [options]="navbarLightVariants" type="custom" label="Light Navbar Variants"
            (change)="onNavbarVariantChange($event)">
        </pf-select>
    </div>
    <div class="mt-2">
        <label>Dark Navbar Variants</label>
        <pf-select [value]="navbarVariant" [options]="navbarDarkVariants" type="custom" label="Dark Navbar Variants"
            (change)="onNavbarVariantChange($event)">
        </pf-select>
    </div>
    <div class="mt-2">
        <label>Light Sidebar Variants</label>
        <pf-select [value]="sidebarSkin" [options]="lightSidebarSkins" type="custom" label="Light Sidebar Variants"
            (change)="onSidebarSkinChange($event)">
        </pf-select>
    </div>
    <div class="mt-2">
        <label>Dark Sidebar Variants</label>
        <pf-select [value]="sidebarSkin" [options]="darkSidebarSkins" type="custom" label="Dark Sidebar Variants"
            (change)="onSidebarSkinChange($event)">
        </pf-select>
    </div>
</div>