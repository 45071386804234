import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import * as query from 'graphql/queries';
import * as mutation from 'graphql/mutations';
import { MessageService } from 'primeng/api';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Status } from 'API';
import { Messages } from '@/Toaster-messages';
import { uploadData } from '@aws-amplify/storage';
import amplifyconfig from 'amplifyconfiguration.json';
import _ from 'underscore';
import { AnimationEffects } from '@/common-list';
import { CommonService } from '@services/common/common.service';

@Component({
  selector: 'app-edit-web-carousel-images',
  templateUrl: './edit-web-carousel-images.component.html',
  styleUrl: './edit-web-carousel-images.component.scss'
})
export class EditWebCarouselImagesComponent {
  filterObject: any = {};
  webCarouselImageList: any = [];
  index: any
  carouselImage: any
  url: any
  carouselForm: FormGroup
  sts: any = true
  selectedcarouselImageId: any
  selectedcarouselImage: any = []
  animationEffects = AnimationEffects
  animationEffect: string | null = null;
  imageForm: FormGroup;
  carouselImagesCount: number = 0;
  addNew: boolean

  constructor(
    private router: Router,
    private awsApi: ApiService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private common: CommonService
  ) {
    this.imageForm = this.formBuilder.group({
      alterText: ['', [Validators.required]],
      url: ['', [Validators.required]],
      description: [''],
    });
  }

  async getWebCarouselImage() {
    return await this.awsApi.client.graphql({
      query: query.listCarouselImages,
      variables: {
        pk: 'CARO#',
        id: { eq: this.selectedcarouselImageId }
      }
    });
  }

  ngOnInit(): void {
    this.carouselForm = this.formBuilder.group({
      carouselImages: this.formBuilder.array([]),
      animationEffect: [''],
      pauseTime: [''],
      slideTransSpeed: [''],
      stopAnimation: [false],
      displayArrows: [false],
      displayNavBullets: [false]
    });
    this.carouselForm.get('carouselImages').valueChanges.subscribe((value) => {
      this.carouselImagesCount = value.length;
    });
    this.selectedcarouselImageId = this.route.snapshot.queryParamMap?.get('id')
    this.getWebCarouselImage().then((response) => {
      this.selectedcarouselImage = response.data.listCarouselImages.items[0]
      if (this.selectedcarouselImage.image && this.selectedcarouselImage.image.length > 0) {
        this.addcarouselImages(this.selectedcarouselImage.image.length);
        this.selectedcarouselImage.image.forEach((item, index) => {
          this.carouselImages.at(index).patchValue({
            alterText: item.alterText,
            url: item.url,
            description: item.description ? item.description : '',
          });
        });
      }
      this.sts = this.selectedcarouselImage.status == 'ACTIVE' ? true : false
      this.carouselImage = this.selectedcarouselImage.type
      this.carouselForm.patchValue({
        animationEffect: this.selectedcarouselImage.animation ? this.selectedcarouselImage.animation : null,
        pauseTime: this.selectedcarouselImage.pauseTime ? this.selectedcarouselImage.pauseTime : '',
        slideTransSpeed: this.selectedcarouselImage.transitionSpeed ? this.selectedcarouselImage.transitionSpeed : '',
        stopAnimation: this.selectedcarouselImage.stopAnimation ? this.selectedcarouselImage.stopAnimation : false,
        displayArrows: this.selectedcarouselImage.displayArrows ? this.selectedcarouselImage.displayArrows : false,
        displayNavBullets: this.selectedcarouselImage.displayNavigationBullets ? this.selectedcarouselImage.displayNavigationBullets : false,
      })
    })
  }

  addcarouselImages(numCalls: number) {
    for (let i = 0; i < numCalls; i++) {
      this.carouselImages.push(
        this.formBuilder.group({
          alterText: ['', [Validators.required]],
          url: ['', [Validators.required]],
          description: [''],
        })
      );
    }
  }

  addNewImage() {
    this.imageForm.reset()
    this.addNew = true;
  }

  get carouselImages(): FormArray {
    return this.carouselForm.get('carouselImages') as FormArray;
  }

  get imageFormControl() {
    return this.imageForm.controls;
  }

  changeStatus() {
    this.sts = !this.sts
  }

  async fileSave(event: any) {
    if (event.target instanceof HTMLInputElement && event.target.files) {
      let file = event.target.files[0];
      let isValid = await this.common.isValidFileType(event)
      if (!isValid) { return; }
      let isImageValid = await this.common.validateImageDimensions(file, 'carousel', 'web');
      if (!isImageValid) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.INVALID_IMAGE_SIZE
        });
        return;
      }
      this.awsApi.loading.next(true);
      let uploadedUrl = await this.common.getS3Url(file)
      this.imageForm.patchValue({
        url: uploadedUrl
      });
      this.awsApi.loading.next(false);
    }
  }

  saveImage() {
    if (this.addNew) {
      (this.carouselForm.get('carouselImages') as FormArray).push(
        this.formBuilder.group({
          alterText: ['', [Validators.required]],
          url: ['', [Validators.required]],
          description: [''],
        })
      );
      this.index = this.carouselImagesCount - 1
    }
    _.forEach(this.carouselImages.controls, (item, index) => {
      if (index == this.index) {
        item.patchValue(this.imageForm.value);
      }
    });
    this.imageForm.reset()
    this.hideUploadProImageModel();
  }

  @ViewChild('closeImageUploadModal') private closeImageUploadModal: ElementRef;
  hideUploadProImageModel() {
    this.closeImageUploadModal.nativeElement.click();
  }

  openUploadcarouselImageModal(item: any, index: any) {
    this.addNew = false
    this.index = index;
    this.imageForm.patchValue({
      url: item.value.url,
      alterText: item.value.alterText,
      description: item.value.description ? item.value.description : ''
    });
  }

  saveBanner() {
    let validcarouselImages = [];
    this.carouselImages.controls.forEach((item) => {
      if (item.valid) {
        validcarouselImages.push(item.value);
      }
      else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.PLEASE_FILL_REQUIRED_FIELDS
        });
      }
    })

    let data = {
      pk: 'CARO#',
      id: this.selectedcarouselImageId,
      animation: this.carouselForm.controls.animationEffect?.value ? this.carouselForm.controls.animationEffect.value : '',
      pauseTime: this.carouselForm.controls.pauseTime?.value ? parseFloat(this.carouselForm.controls.pauseTime.value) : 0.0,
      transitionSpeed: this.carouselForm.controls.slideTransSpeed?.value ? parseFloat(this.carouselForm.controls.slideTransSpeed.value) : 0.0,
      stopAnimation: this.carouselForm.controls.stopAnimation?.value ? this.carouselForm.controls.stopAnimation.value : false,
      displayArrows: this.carouselForm.controls.displayArrows?.value ? this.carouselForm.controls.displayArrows.value : false,
      displayNavigationBullets: this.carouselForm.controls.displayNavBullets?.value ? this.carouselForm.controls.displayNavBullets.value : false,
      image: validcarouselImages,
      status: this.sts ? Status.ACTIVE : Status.INACTIVE,
      updatedAt: new Date().toISOString()
    }
    this.awsApi.client.graphql({
      query: mutation.updateCarouselImage,
      variables: {
        input: {
          ...data
        }
      }
    }).then((response) => {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: Messages.CAROUSELIMAGE_UPDATED
      });
      this.redirectToCarouselListingPage()
    }).catch((error) => {

      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    });
  }

  deleteUrl(type, index: any) {
    this.imageForm.reset();
    let ind = index
    if (type == 'carouselImage-web') {
      (this.carouselForm.get('carouselImages') as FormArray).removeAt(ind);
    }
  }

  redirectToCarouselListingPage() {
    this.router.navigate([`carousel-images-list`])
  }
  preventE(event) {
    if (event.which === 101) {
      event.preventDefault();
    }
  }

  removeUrl() {
    this.imageForm.controls.url.reset()
  }
}