import { RefundStatusList, ReturnItemStatusList, ReturnPaymentMethodList, ReturnStatuses } from '@/common-list';
import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import { CommonService } from '@services/common/common.service';
import { ExcelService } from '@services/common/excel.service';
import { UserAuthService } from '@services/user-auth/user-auth.service';
import { MessageService } from 'primeng/api';
import * as query from 'graphql/queries';
import { Messages } from '@/Toaster-messages';
import { ROUTES } from '@/redirect-routes';

@Component({
  selector: 'app-return-refund-report-list',
  templateUrl: './return-refund-report-list.component.html',
  styleUrl: './return-refund-report-list.component.scss'
})
export class ReturnRefundReportListComponent {
  maxDate: any
  returnAndRefundList: any = []
  position: any;
  displayFilterModalToggle = false
  isSearchEnabled = false
  hide: boolean = false
  deleteModalToggle: any
  filterApplied: boolean = false
  returnStatusList = ReturnItemStatusList.concat(RefundStatusList)
  returnStatuses = ReturnStatuses
  returnPaymentMethodList = ReturnPaymentMethodList
  selectedMainCategory: string | null = null;
  mainCategoryList: any = []
  subCategoryList: any = []
  returnDataList: any = []
  companyName: any = ''
  page: any
  pageSize: any
  loading: boolean
  endOfData: boolean
  viewAccess: any = false
  today: any = new Date();
  data: any = {}
  thirtyDaysAgo = new Date((new Date()).setDate(this.today.getDate() - 30))
  loader: boolean = false

  filterForm = this.fb.group({
    mainCategory: [],
    subCategory: [],
    status: [],
    returnedOn: [],
    searchText: ['']
  });

  constructor(private common: CommonService, public auth: UserAuthService, private fb: FormBuilder, private router: Router,
    private excelService: ExcelService, private awsApi: ApiService, private messageService: MessageService, private route: ActivatedRoute) { }

  async ngOnInit() {
    this.maxDate = new Date()
    this.viewAccess = this.route.snapshot.queryParamMap?.get('view') ?? false;
    if (this.viewAccess) {
      await this.preFetchAllData()
    }
    else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.ACCESS_DENIED
      });
    }
  }

  getAllMainCategory() {
    return this.awsApi.client.graphql({
      query: query.listCategories,
      variables: {
        pk: 'MAIN#',
        slug: null,
        filter: null,
        limit: 9999,
        nextToken: null,
        sortDirection: null
      }
    });
  }

  async preFetchAllData() {
    Promise.all([this.getAllMainCategory(), this.common.getCompanyName()]).then((result: any) => {
      this.mainCategoryList = result[0].data.listCategories.items;
      this.companyName = result[1]
    })
  }

  async startingFunction() {
    this.common.returnAndRefundReports(this.data, await this.common.getHeader()).subscribe(async (result) => {
      if (result.status) {
        this.returnAndRefundList = result.returnandrefundreport
          .filter((item) => item.returnRefundActivity.status)
          .sort((a: any, b: any) => new Date(b.returnRefundActivity.createdAt).getTime() - new Date(a.returnRefundActivity.createdAt).getTime())
        this.initializePagination()
      }
    })
  }

  initializePagination() {
    this.page = 1;
    this.pageSize = 12;
    this.endOfData = false;
    this.loadData('initial')
  }

  loadData(type) {
    if (this.returnAndRefundList.length > 0) {
      this.loading = true;
    }
    const startIndex = (this.page - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    if (type == 'initial') {
      setTimeout(() => {
        this.returnDataList = this.returnAndRefundList.slice(startIndex, endIndex);
        this.awsApi.loading.next(false);
        this.loader = this.awsApi.loading.getValue()
        this.loading = false;
      }, 1000);
    }
    if (type == 'more') {
      setTimeout(() => {
        this.returnDataList = this.returnDataList.concat(this.returnAndRefundList.slice(startIndex, endIndex));
        this.loading = false;

        if (this.returnDataList.length >= this.returnAndRefundList.length) {
          this.endOfData = true;
        }
      }, 2000);
    }
  }

  onScroll() {
    if (!this.loading && !this.endOfData) {
      const container = document.querySelector('.scroll-container');
      if (
        container &&
        container.scrollHeight - container.scrollTop === container.clientHeight
      ) {
        this.page++
        this.loadData('more');
      }
    }
  }

  async filterMainCategory() {
    if (this.filterForm.controls.mainCategory?.value) {
      this.filterApplied = true
      const selectedMainCategory = this.filterForm.controls.mainCategory.value.id
      this.data['mainCategoryId'] = selectedMainCategory;
    }
  }

  async filterSubCategory() {
    if (this.filterForm.controls.subCategory?.value) {
      this.filterApplied = true;
      const selectedCategoryId = this.filterForm.controls.subCategory.value.id;
      this.data['subCategoryId'] = selectedCategoryId;
    }
  }

  async filterStatus() {
    if (this.filterForm.controls.status?.value && this.filterForm.controls.status?.value.length > 0) {
      this.filterApplied = true;
      const selectedStatuses = this.filterForm.controls.status.value.map((status: any) => status.value);
      this.data['returnstatus'] = selectedStatuses.join(',');
    }
  }

  async filterDate() {
    if (this.filterForm.controls.returnedOn.value?.length == 2) {
      this.filterApplied = true
      if (this.filterForm.controls.returnedOn.value[0] != null) {
        var formattedDate1 = formatDate(new Date(this.filterForm.controls.returnedOn.value[0]), 'yyyy-MM-dd', 'en-US');
        this.data['fromdate'] = formattedDate1
      }
      if (this.filterForm.controls.returnedOn.value[1] != null) {
        var formattedDate2 = formatDate(this.filterForm.controls.returnedOn.value[1], 'yyyy-MM-dd', 'en-US');
        this.data['todate'] = formattedDate2
      }
    }
  }

  async applyFilter() {
    this.data = {}
    this.displayFilterModalToggle = false;
    this.awsApi.loading.next(true);
    this.loader = this.awsApi.loading.getValue()
    await this.filterMainCategory()
    await this.filterSubCategory()
    await this.filterStatus()
    await this.filterDate()
    if (this.filterApplied) {
      await this.startingFunction()
    }
  }

  onSelectMainCategory() {
    this.filterForm.controls.subCategory.reset()
    this.subCategoryList = []
    let mainCategoryId = this.filterForm.controls.mainCategory?.value.id
    this.getAllSubCategory(mainCategoryId)
      .then((result) => {
        this.subCategoryList = result.data.listCategories.items;
      })
      .catch((error) => {
      });
  }

  getAllSubCategory(mainCategoryID) {
    return this.awsApi.client.graphql({
      query: query.listCategories,
      variables: {
        pk: 'SUB#' + mainCategoryID
      }
    });
  }

  exportToExcel() {
    let obj = []
    let excel_sheet_data = []
    let data: any = {}
    const header = ['Return Ref	', 'Customer Name	', 'Return Type', 'Return Date', 'Product Name', 'Qty', 'Unit Price', 'Total', 'Refund Method', 'Refund Amount', 'Current Status'];
    let title = 'Return and Refund Report of ' + this.companyName;
    let worksheet = 'Return and Refund-Report'
    let merge_cells = 'A1:K2'
    let footer_row = this.companyName + ' Return and Refund Report.'
    let filename = 'Return and Refund Report'
    let excel_array = [
      data['header'] = header,
      data['title'] = title,
      data['worksheet'] = worksheet,
      data['merge_cells'] = merge_cells,
      data['footer_row'] = footer_row,
      data['filename'] = filename
    ]
    const date = new Date();
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit', month: 'short', year: 'numeric'
    }).replace(/ /g, '-');
    for (var i in this.returnAndRefundList) {
      obj = [this.returnAndRefundList[i].returnRefundActivity.returnReference.toString(),
      (this.returnAndRefundList[i].userName && this.returnAndRefundList[i].userName != '') ?
        this.returnAndRefundList[i].userName.toString() : 'N/A',
      this.returnAndRefundList[i].returnRefundActivity.returnType.toString(),
      (this.common.dateInReverse(this.returnAndRefundList[i].returnRefundActivity.createdAt)).toString(),
      this.returnAndRefundList[i].itemDetail.productName.toString(),
      this.returnAndRefundList[i].returnRefundActivity.quantity.toString(),
      Number(this.returnAndRefundList[i].itemDetail.price).toFixed(2),
      Number(this.returnAndRefundList[i].totalPrice).toFixed(2),
      this.returnAndRefundList[i].returnRefundActivity.refundInfo?.paymentMethod ?
        this.returnAndRefundList[i].returnRefundActivity.refundInfo?.paymentMethod != this.returnPaymentMethodList[2]['value'] ?
          this.returnPaymentMethodList.find(item => item.value === this.returnAndRefundList[i].returnRefundActivity.refundInfo?.paymentMethod).title :
          this.returnPaymentMethodList[2]['title'] : 'N/A',
      this.returnAndRefundList[i].returnRefundActivity.refundInfo?.refundAmount ?
        Number(this.returnAndRefundList[i].returnRefundActivity.refundInfo?.refundAmount).toFixed(2) : 'N/A',
      this.returnStatusList.find(item => item.value === this.returnAndRefundList[i].returnRefundActivity.status) ?
        this.returnStatusList.find(item => item.value === this.returnAndRefundList[i].returnRefundActivity.status).valueToShow : this.returnStatuses.rejected
      ]
      excel_sheet_data.push(obj)
    }
    this.excelService.exportAsExcelFile(excel_sheet_data, formattedDate, excel_array);
  }

  showFilterModalDialog(position: string) {
    this.position = position;
    this.displayFilterModalToggle = true;
  }
  closeFilterModalDialog() {
    this.displayFilterModalToggle = false;
  }
  cancelFilter() {
    this.filterApplied = false
    this.displayFilterModalToggle = false;
    this.filterForm.reset()
    this.returnAndRefundList = []
    this.data = {}
  }
  showDeleteGeneralQueryModalDialog(item: any) {
    this.deleteModalToggle = true;
  }

  redirectToReportListPage() {
    this.router.navigate([ROUTES.REPORTS_LIST])
  }
}