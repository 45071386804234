import { HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from '@services/common/common.service';
import { UserAuthService } from '@services/user-auth/user-auth.service';

@Component({
  selector: 'app-payments-ledger',
  templateUrl: './payments-ledger.component.html',
  styleUrl: './payments-ledger.component.scss'
})
export class PaymentsLedgerComponent {

  value: number = 5;
  position: any;
  displayFilterModalToggle = false
  deleteModalToggle: any
  paymentLedgerList: any = []
  filterForm = this.fb.group({
    searchText: [''],
    type: [''],
    date: ['']
  })
  filterList: any = [
    { name: "Credit", value: "#credit" },
    { name: "Debit", value: "#debit" }
  ];

  constructor(private router: Router, private common: CommonService, public auth: UserAuthService, private fb: FormBuilder,) { }

  ngOnInit(): void {
    this.position = 'right';
    this.startingFunction()
  }

  async startingFunction() {
    let data: any = { "userid": localStorage.getItem("userId") };
    if (this.filterForm.controls.searchText.value?.length > 0) {
      data['search'] = this.filterForm.controls.searchText.value
    }
    if (this.filterForm.controls.type.value?.length > 0) {
      data['transType'] = this.filterForm.controls.type.value
    }
    if (this.filterForm.controls.date.value?.length > 0) {
      data['transDate'] = this.filterForm.controls.date.value
    }
    this.common.paymentLedgers(data, await this.common.getHeader()).subscribe((result) => {
      this.paymentLedgerList = result.ledger
    });
  }

  showFilterModalDialog(position: string) {
    this.position = position;
    this.displayFilterModalToggle = true;
  }
  closeFilterModalDialog() {
    this.displayFilterModalToggle = false;
  }
  showDeleteGeneralQueryModalDialog(item: any) {
    this.deleteModalToggle = true;
  }
  redirectToAddProductPage() {
    this.router.navigate([`admin/product/add`]);
  }
}

