<section id="backButtonSec">
  <div class="container-fluid">
    <div class="row pt-3">
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="text-start">
          <button type="button" class="btn back-btn" (click)="redirectToSigninPage()">
            <i class="fas fa-angle-left me-2"></i> Back
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
<section id="forgotPasswordSec">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 my-auto">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <form [formGroup]="forgotForm">
                  <div class="contact">
                    <div class="text-center">
                      <img src="../../../../assets/img/forgotPassword.png" />
                      <h3 class="mt-3">
                        Forgot password
                      </h3>
                      <p>
                        Please enter your registered email address to receive a verification code
                      </p>
                    </div>
                    <div>
                      <label>Email
                        <span class="mandatory">*</span></label>
                      <input autofocus type="text" class="form-control" placeholder="Enter email here"
                        formControlName="email" />
                      <span class="text-danger"
                        *ngIf="(forgotForm.controls.email.touched || submitted) && !emailInput?.valid">
                        <div *ngIf="forgotForm.controls.email.errors!['required']">
                          Email is required.
                        </div>
                        <div *ngIf="forgotForm.controls.email.errors!['email']">
                          Enter valid Email.
                        </div>
                      </span>
                    </div>
                    <div class="mt-3">
                      <button class="btn send-btn" type="button" [disabled]="!forgotForm.valid"
                        (click)="handleResetPassword()">
                        Send
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>