import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import * as mutation from 'graphql/mutations';
import * as query from 'graphql/queries';
import slugify from '@sindresorhus/slugify';
import * as _ from 'underscore';
import { ApiService } from '@services/api/api.service';
import { ROUTES } from '@/redirect-routes';
import { Messages } from '@/Toaster-messages';

@Component({
  selector: 'app-attribute-edit',
  templateUrl: './attribute-edit.component.html',
  styleUrl: './attribute-edit.component.scss'
})
export class AttributeEditComponent implements OnInit {
  attrID: string;
  pk: string;

  addAttrForm: FormGroup;
  attrData: any;

  constructor(
    private _activatedroute: ActivatedRoute,
    private awsApi: ApiService,
    private _router: Router,
    private fb: FormBuilder,
    private messageService: MessageService
  ) {
    this.addAttrForm = this.fb.group({
      attrName: [{ value: '', disabled: true }, [Validators.required]]
    });
  }

  async getAttributeByID() {
    return await this.awsApi.client.graphql({
      query: query.attributeNameByID,
      variables: {
        pk: this.pk,
        id: { eq: this.attrID }
      }
    });
  }

  ngOnInit(): void {
    this._activatedroute.queryParamMap.subscribe((params) => {
      this.attrID = params.get('id') ?? '';
      this.pk = params.get('pk') ?? '';
      if (this.attrID != '' && this.pk != '') {
        this.awsApi.loading.next(true);
        Promise.all([this.getAttributeByID()])
          .then((response) => {
            this.attrData = response[0].data.attributeNameByID.items[0];
            this.addAttrForm.patchValue({
              attrName: this.attrData.attrName
            });
            this.awsApi.loading.next(false);
          })
          .catch((error) => {
            this.awsApi.loading.next(false);
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: Messages.SOMETHING_WENT_WRONG
            });

          });
      }
    });
  }

  get addAttrFormControl() {
    return this.addAttrForm.controls;
  }

  onOptionChange() { }

  onSubmit() {
    ;
    try {
      if (this.addAttrForm.valid) {
        this.awsApi.loading.next(true);

        let inputData: any = {
          slug: slugify(this.addAttrFormControl['attrName'].value),
          attrName: this.addAttrFormControl['attrName'].value,
          pk: 'ATTRNAME#'
        };

        this.awsApi.client
          .graphql({
            query: mutation.updateAttributeName,
            variables: {
              input: {
                ...inputData
              }
            }
          })
          .then((response) => {
            this.awsApi.loading.next(false);
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Attribute updated successfully!'
            });
            this._router.navigate(['/list-category']);
          })
          .catch((error) => {
            this.awsApi.loading.next(false);
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: Messages.SOMETHING_WENT_WRONG
            });

          });
      }
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });

    }
  }
  redirectToAttributeListPage() {
    this._router.navigate([ROUTES.ATTRIBUTE_LIST]);
  }
}
