import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES } from '@/redirect-routes';
import { ApiService } from '@services/api/api.service';
import { MessageService } from 'primeng/api';
import * as mutation from 'graphql/mutations';
import { FormBuilder } from '@angular/forms';
import { CommonService } from '@services/common/common.service';
import { PromoStatusList } from '@/common-list';
import { Messages } from '@/Toaster-messages';

@Component({
  selector: 'app-promocode-list',
  templateUrl: './promocode-list.component.html',
  styleUrl: './promocode-list.component.scss'
})
export class PromocodeListComponent {
  position: any;
  displayFilterModalToggle = false
  deleteModalToggle: any
  filterObject: any = {}
  promoCodeList: any = []
  copyPromoCodeList: any = []
  promoDataList: any = []
  discount: any
  deletePromoCodes: any
  searchKey: any
  searchText: any = ''
  access: any = {}
  statusList = PromoStatusList
  page: any
  pageSize: any
  loading: boolean
  endOfData: boolean
  filterApplied: boolean = false

  filterForm = this.fb.group({
    status: [],
    type: ['']
  })

  constructor(private router: Router, private common: CommonService, private awsApi: ApiService, private messageService: MessageService, private fb: FormBuilder) { }
  ngOnInit(): void {
    this.position = 'right';
    this.filterForm.patchValue({ status: [this.statusList[0], this.statusList[3]] })
    this.getRoleManagement()
    this.getAllPromoCodes()
  }

  async getRoleManagement() {
    this.awsApi.loading.next(true);
    try {
      await this.common.getPermissionsOfUser('Promo Codes');
      this.access = this.common.access;
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  async getAllPromoCodes() {
    this.awsApi.loading.next(true);
    let fieldsToFetch = ['id', 'pk', 'code', 'redemptionType', 'validFrom', 'validTo', 'discount {', 'type', 'value', '}', 'status', 'updatedAt', 'createdAt']
    Promise.all([await this.common.getPromoCodes(this.filterObject, fieldsToFetch)]).then((response) => {
      this.promoCodeList = response[0].sort((a: any, b: any) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
      this.copyPromoCodeList = this.promoCodeList
      if (this.filterForm.controls.status.value?.length > 0) {
        this.filterApplied = true
        let statusList = this.filterForm.controls.status.value.map((status: any) => status.value);
        this.promoCodeList = this.copyPromoCodeList.filter((arr: any) => {
          return statusList.includes(arr.status)
        });
        this.copyPromoCodeList = this.promoCodeList
      }
      this.initializePagination()
      this.awsApi.loading.next(false);
    })
      .catch((error) => {
        this.awsApi.loading.next(false);
      });
  }

  initializePagination() {
    this.page = 1;
    this.pageSize = 11;
    this.loading = false;
    this.endOfData = false;
    this.loadData()
  }

  onScroll() {
    if (!this.loading && !this.endOfData) {
      const container = document.querySelector('.scroll-container');
      if (
        container &&
        container.scrollHeight - container.scrollTop === container.clientHeight
      ) {
        this.loadMore();
      }
    }
  }

  loadMore() {
    this.page++
    // Loading more data
    this.loading = true;
    const startIndex = (this.page - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    setTimeout(() => {
      this.promoDataList = this.promoDataList.concat(this.promoCodeList.slice(startIndex, endIndex));
      this.loading = false;

      if (this.promoDataList.length >= this.promoCodeList.length) {
        this.endOfData = true;
      }
    }, 2000);
  }

  loadData() {
    // Initial data loading   
    this.loading = true;
    const startIndex = (this.page - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    setTimeout(() => {
      this.promoDataList = this.promoCodeList.slice(startIndex, endIndex);
      this.loading = false;
    }, 1000);
  }

  deletePromoCodesItem(item: any) {
    this.deleteModalToggle = true;
    this.deletePromoCodes = item;
  }

  confirmDeletePromoCodes() {
    this.deleteModalToggle = false;
    let data = {
      pk: this.deletePromoCodes.pk,
      id: this.deletePromoCodes.id
    };
    this.awsApi.client
      .graphql({
        query: mutation.deletePromoCodes,
        variables: {
          input: {
            ...data
          }
        }
      })
      .then((response) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Promocode deleted successfully!'
        });
        this.getAllPromoCodes();
      })
      .catch((error) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      });
  }

  changeStatus(userId: any, type: any) {
    let data = {
      pk: 'PROMO#',
      id: userId,
      status: (type === 'Activate' || type === 'Draft') ? 'ACTIVE' : 'INACTIVE'
    };
    this.awsApi.client.graphql({
      query: mutation.updatePromoCodes,
      variables: {
        input: {
          pk: data.pk,
          id: data.id,
          status: data.status
        }
      }
    })
      .then((response) => {
        this.awsApi.loading.next(false);
        this.getAllPromoCodes();
      })
      .catch((error) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      });
  }

  search(searchText) {
    this.searchText = searchText
    if (this.searchText != '') {
      this.searchKey = this.searchText.toLowerCase()
      this.searchKey = this.searchKey.replace(/\s{2,}/g, ' ');
      this.searchKey = (this.searchKey.trim()).replace(/\s+/g, '');

      this.filterObject['searchKey'] = { contains: this.searchKey }
      this.getAllPromoCodes()
    } else {
      delete this.filterObject['searchKey']
      delete this.searchKey
      this.getAllPromoCodes()
    }
  }

  async applyFilter() {
    this.awsApi.loading.next(true);
    this.clearFilterObject()
    this.displayFilterModalToggle = false;
    if (this.filterForm.controls.type.value?.length > 0) {
      this.filterApplied = true
      this.filterObject['redemptionType'] = {
        eq: this.filterForm.controls.type.value
      }
    }
    await this.getAllPromoCodes()
    this.awsApi.loading.next(false);
  }

  redirectToDetailsPage(item) {
    this.router.navigate(['edit-promocode'], { queryParams: { id: item.id } });
  }
  redirectToAddPromocodePage() {
    this.router.navigate([ROUTES.ADD_PROMOCODE]);
  }
  showFilterModalDialog(position: string) {
    this.position = position;
    this.displayFilterModalToggle = true;
  }
  closeFilterModalDialog() {
    this.displayFilterModalToggle = false;
  }

  clearFilterObject() {
    delete this.filterObject['redemptionType']
  }

  cancelFilterModalDialog() {
    this.clearFilterObject()
    this.filterForm.reset()
    this.filterApplied = false
    this.displayFilterModalToggle = false;
    this.getAllPromoCodes()
  }
  showDeleteConfirmationModalDialog() {
    this.deleteModalToggle = true;
  }
}
