import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'formatDocType'
  })
export class customPipe implements PipeTransform {
    transform(value: string) {
      if(value != null){
        let isNumber = /^\d+$/.test(value);
        if(!isNumber){
          if(value.includes('_')) {
            value = value.replace(/_/g, ' ').split(' ')[0][0].toUpperCase()+value.replace(/_/g, ' ').split(' ')[0].slice(1).toLowerCase()+value.replace(/_/g, ' ').split(' ')[1][0].toUpperCase()+value.replace(/_/g, ' ').split(' ')[1].slice(1).toLowerCase();
            value = value.replace(/ /g, '')
          }
          if(value === value.toUpperCase()){
            value = value.toLowerCase()          
          }
          else{           
            value = value.replace(/([a-z])([A-Z])/g, '$1 $2');
          }   
          value = value[0].toUpperCase() + value.slice(1)           
    }
   }
  return value;
    }
  }