import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule, isDevMode } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from '@/app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from '@modules/main/main.component';
import { LoginComponent } from '@modules/login/login.component';
import { HeaderComponent } from '@modules/main/header/header.component';
import { FooterComponent } from '@modules/main/footer/footer.component';
import { MenuSidebarComponent } from '@modules/main/menu-sidebar/menu-sidebar.component';
import { BlankComponent } from '@pages/blank/blank.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileComponent } from '@pages/profile/profile.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RegisterComponent } from '@modules/register/register.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { ToastrModule } from 'ngx-toastr';
import { MessagesComponent } from '@modules/main/header/messages/messages.component';
import { NotificationsComponent } from '@modules/main/header/notifications/notifications.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { CommonModule, registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { UserComponent } from '@modules/main/header/user/user.component';
import { ForgotPasswordComponent } from '@modules/forgot-password/forgot-password.component';
import { RecoverPasswordComponent } from '@modules/recover-password/recover-password.component';
import { LanguageComponent } from '@modules/main/header/language/language.component';
import { MainMenuComponent } from './pages/main-menu/main-menu.component';
import { SubMenuComponent } from './pages/main-menu/sub-menu/sub-menu.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { ControlSidebarComponent } from './modules/main/control-sidebar/control-sidebar.component';
import { StoreModule } from '@ngrx/store';
import { authReducer } from './store/auth/reducer';
import { uiReducer } from './store/ui/reducer';
import { ProfabricComponentsModule } from '@profabric/angular-components';
import { SidebarSearchComponent } from './components/sidebar-search/sidebar-search.component';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { environment } from 'environments/environment';
import { SpinnerComponent } from './spinner/spinner.component';
import { UserSigninComponent } from './user-auth/user-signin/user-signin.component';
import { ConfirmResetPasswordComponent } from './user-auth/confirm-reset-password/confirm-reset-password.component';
import { UserConfirmCodeComponent } from './user-auth/user-confirm-code/user-confirm-code.component';
import { UserForgotPasswordComponent } from './user-auth/user-forgot-password/user-forgot-password.component';
import { UserSignupComponent } from './user-auth/user-signup/user-signup.component';
import { NgPrimeModule } from '@modules/ng-prime/ng-prime/ng-prime.module';
import { MessageService } from 'primeng/api';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CategoryAddComponent } from './pages/category/category-add/category-add.component';
import { CategoryListComponent } from './pages/category/category-list/category-list.component';
import { CategoryEditComponent } from './pages/category/category-edit/category-edit.component';
import { BrandAddComponent } from './pages/brand/brand-add/brand-add.component';
import { BrandListComponent } from './pages/brand/brand-list/brand-list.component';
import { BrandEditComponent } from './pages/brand/brand-edit/brand-edit.component';
import { ProductAddComponent } from './pages/product/product-add/product-add.component';
import { ProductListComponent } from './pages/product/product-list/product-list.component';
import { SettingsComponent } from './modules/main/header/settings/settings.component';
import { UsersListComponent } from './pages/users/users-list/users-list.component';
import { UserAddComponent } from './pages/users/user-add/user-add.component';
import { UserEditComponent } from './pages/users/user-edit/user-edit.component';
import { CustomerReviewComponent } from './pages/product-review/customer-review/customer-review.component';
import { GeneralQueriesListComponent } from './pages/general-queries/general-queries-list/general-queries-list.component';
import { ProductReviewsListComponent } from './pages/product-review/product-reviews-list/product-reviews-list.component';
import { CustomerQueryComponent } from './pages/general-queries/customer-query/customer-query.component';
import { OrdersListComponent } from './pages/order/orders-list/orders-list.component';
import { OrderDetailsComponent } from './pages/order/order-details/order-details.component';
import { ReturnAndRefundDetailsComponent } from './pages/return&refund/return-and-refund-details/return-and-refund-details.component';
import { ReturnAndRefundListComponent } from './pages/return&refund/return-and-refund-list/return-and-refund-list.component';
import { CalendarModule } from 'primeng/calendar';
import { CategoriesReportListComponent } from './pages/reports/categories-report-list/categories-report-list.component';
import { OrdersReportListComponent } from './pages/reports/orders-report-list/orders-report-list.component';
import { ProductsReportListComponent } from './pages/reports/products-report-list/products-report-list.component';
import { StocksReportListComponent } from './pages/reports/stocks-report-list/stocks-report-list.component';
import { PaymentsLedgerComponent } from './pages/reports/payments-ledger/payments-ledger.component';
import { AddRoleComponent } from './pages/role/add-role/add-role.component';
import { ListWarehouseComponent } from './pages/warehouse/list-warehouse/list-warehouse.component';
import { AddWarehouseComponent } from './pages/warehouse/add-warehouse/add-warehouse.component';
import { FaqListComponent } from './pages/others/faq-list/faq-list.component';
import { AddFaqComponent } from './pages/others/add-faq/add-faq.component';
import { ContentsListComponent } from './pages/others/contents-list/contents-list.component';
import { AddContentComponent } from './pages/others/add-content/add-content.component';
import { AddCarouselImagesComponent } from './pages/others/add-carousel-images/add-carousel-images.component';
import { CarouselImagesListComponent } from './pages/others/carousel-images-list/carousel-images-list.component';
import { CreateContentSectionComponent } from './pages/others/create-content-section/create-content-section.component';
import { CreateFaqSectionComponent } from './pages/others/create-faq-section/create-faq-section.component';
import { EditWebCarouselImagesComponent } from './pages/others/edit-web-carousel-images/edit-web-carousel-images.component';
import { EditMobileCarouselImagesComponent } from './pages/others/edit-mobile-carousel-images/edit-mobile-carousel-images.component';
import { AttributeAddComponent } from '@pages/product/attribute-add/attribute-add.component';
import { AttributeListComponent } from '@pages/product/attribute-list/attribute-list.component';
import { AttributeEditComponent } from '@pages/product/attribute-edit/attribute-edit.component';
import { CountryAddComponent } from '@pages/country/country-add/country-add.component';
import { CountryListComponent } from '@pages/country/country-list/country-list.component';
import { CountryEditComponent } from '@pages/country/country-edit/country-edit.component';
import { TaxListComponent } from './pages/tax/tax-list/tax-list.component';
import { customPipe } from '@services/customPipe';
import { AbbreviateNamePipe } from './services/abbreviate-name.pipe';
import { AllNotificationsComponent } from './modules/main/header/notifications/all-notifications/all-notifications.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { ListRoleComponent } from '@pages/role/list-role/list-role.component';
import { SavedCartsComponent } from './pages/cart/saved-carts/saved-carts.component';
import { CartDetailsComponent } from './pages/cart/cart-details/cart-details.component';
import { CommonSearchComponent } from './shared/common-search/common-search.component';
import { AddDeliveryPartnerComponent } from './pages/delivery-partners/add-delivery-partner/add-delivery-partner.component';
import { ProductEditComponent } from './pages/product/product-edit/product-edit.component';
import { ListDeliveryPartnerComponent } from '@pages/delivery-partners/list-delivery-partner/list-delivery-partner.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { PromocodeListComponent } from './pages/promocode/promocode-list/promocode-list.component';
import { PromocodeAddComponent } from './pages/promocode/promocode-add/promocode-add.component';
import { CheckboxModule } from 'primeng/checkbox';
import { ListCustomersComponent } from './pages/customers/list-customers/list-customers.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { ReturnRefundReportListComponent } from './pages/reports/return-refund-report-list/return-refund-report-list.component';
import { VariantListComponent } from '@pages/Variant/variant-list/variant-list.component';
import { UnitsListComponent } from '@pages/Units/units-list/units-list.component';
import { EditRoleComponent } from '@pages/role/edit-role/edit-role.component';
import { DropdownModule } from 'primeng/dropdown';
import { ChartModule } from 'primeng/chart';
import { NoResultFoundComponent } from './shared/no-result-found/no-result-found.component';
import { ShippingListComponent } from './pages/shipping/shipping-list/shipping-list.component';
import { ShippingAddComponent } from './pages/shipping/shipping-add/shipping-add.component';
import { ShippingEditComponent } from './pages/shipping/shipping-edit/shipping-edit.component';
import { ReportListComponent } from '@pages/reports/report-list/report-list.component';
import { ImageViewPopupModalComponent } from './shared/image-view-popup-modal/image-view-popup-modal.component';
import { DialogService } from 'primeng/dynamicdialog';
import { NgxFlagsModule } from 'ngx-flags';

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        LoginComponent,
        HeaderComponent,
        FooterComponent,
        MenuSidebarComponent,
        BlankComponent,
        ProfileComponent,
        RegisterComponent,
        DashboardComponent,
        MessagesComponent,
        NotificationsComponent,
        UserComponent,
        ForgotPasswordComponent,
        RecoverPasswordComponent,
        LanguageComponent,
        MainMenuComponent,
        SubMenuComponent,
        MenuItemComponent,
        ControlSidebarComponent,
        SidebarSearchComponent,
        ConfirmResetPasswordComponent,
        UserConfirmCodeComponent,
        UserForgotPasswordComponent,
        UserSignupComponent,
        SpinnerComponent,
        UserSigninComponent,
        CategoryAddComponent,
        CategoryListComponent,
        CategoryEditComponent,
        BrandAddComponent,
        BrandListComponent,
        BrandEditComponent,
        ProductAddComponent,
        ProductListComponent,
        SettingsComponent,
        UsersListComponent,
        UserAddComponent,
        UserEditComponent,
        CustomerReviewComponent,
        GeneralQueriesListComponent,
        ProductReviewsListComponent,
        CustomerQueryComponent,
        OrdersListComponent,
        OrderDetailsComponent,
        ReturnAndRefundDetailsComponent,
        ReturnAndRefundListComponent,
        PaymentsLedgerComponent,
        CategoriesReportListComponent,
        OrdersReportListComponent,
        ProductsReportListComponent,
        StocksReportListComponent,
        ListRoleComponent,
        AddRoleComponent,
        ListWarehouseComponent,
        AddWarehouseComponent,
        FaqListComponent,
        AddFaqComponent,
        ContentsListComponent,
        AddContentComponent,
        AddCarouselImagesComponent,
        CarouselImagesListComponent,
        CreateContentSectionComponent,
        CreateFaqSectionComponent,
        EditWebCarouselImagesComponent,
        EditMobileCarouselImagesComponent,
        AttributeAddComponent,
        AttributeListComponent,
        AttributeEditComponent,
        CountryAddComponent,
        CountryListComponent,
        CountryEditComponent,
        TaxListComponent,
        VariantListComponent,
        UnitsListComponent,
        customPipe,
        AbbreviateNamePipe,
        AllNotificationsComponent,
        SavedCartsComponent,
        CartDetailsComponent,
        CommonSearchComponent,
        ListDeliveryPartnerComponent,
        AddDeliveryPartnerComponent,
        ProductEditComponent,
        PromocodeListComponent,
        PromocodeAddComponent,
        ListCustomersComponent,
        AbbreviateNamePipe,
        ReturnRefundReportListComponent,
        EditRoleComponent,
        NoResultFoundComponent,
        ShippingListComponent,
        ShippingAddComponent,
        ShippingEditComponent,
        ReportListComponent,
        ImageViewPopupModalComponent
    ],
    imports: [
        ProfabricComponentsModule,
        CommonModule,
        BrowserModule,
        StoreModule.forRoot({ auth: authReducer, ui: uiReducer }),
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgPrimeModule,
        CalendarModule,
        MultiSelectModule,
        NgImageSliderModule,
        CheckboxModule,
        NgOtpInputModule,
        DropdownModule,
        ChartModule,
        NgxFlagsModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-right',
            preventDuplicates: true
        }),
        NgxGoogleAnalyticsModule.forRoot(environment.GA_ID),
        CKEditorModule,
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() })
    ],
    providers: [MessageService, DialogService],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule { }
