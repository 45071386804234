<section id="deliveryPartnerEditSec">
    <div class="container-fluid">
        <div class="row mb-3 pt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                [formGroup]="addDeliveryPartnersForm">
                <div class="card mb-1 heading-card">
                    <div class="card-body">
                        <div class="d-flex justify-content-start align-items-center">
                            <i class="fas fa-chevron-left" [routerLink]="['/list-delivery-partners']"></i>
                            <h6>Edit Delivery Partner</h6>
                        </div>
                    </div>
                </div>
                <div class="card delivery-partner-info-card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                <h5>Delivery Partner Information</h5>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-4 col-xs-12 mb-2">
                                <label>Logo</label>
                                <form id="image-upload-form" class="image-uploader">
                                    <input id="image-upload" type="file" name="fileUpload" accept="image/*"
                                        (change)="fileSave($event, 'logo')" />
                                    <label for="image-upload" id="file-drag">
                                        @if (
                                        addDeliveryPartnersForm.get(
                                        'logoUrl'
                                        )?.value == ''
                                        ) {
                                        <div id="start">
                                            <div class="upload-caption">
                                                <i class="far fa-image"></i>
                                                <h6>Click here to upload logo</h6>
                                            </div>
                                        </div>
                                        }@else {
                                        <div id="start">
                                            <div class="after-upload-gallery-image">
                                                <img [src]="
                                                addDeliveryPartnersForm.get(
                                                    'logoUrl'
                                                )?.value
                                            " alt="" />
                                                <i class="fas fa-trash-alt pt-1" (click)="deleteUrl('logoUrl')"></i>
                                            </div>
                                        </div>
                                        }
                                    </label>
                                    <!-- <div id="after-upload">
                                        <div class="d-flex">
                                            <div class="uploaded-filename"></div>
                                            <div>
                                                <i class="fas fa-trash-alt pt-1"></i>
                                            </div>
                                        </div>
                                    </div> -->
                                </form>
                            </div>
                            <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-8 col-xs-12 mb-2">
                                <div class="row">
                                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-2">
                                        <label>Company Name <span class="mandatory">*</span></label>
                                        <input type="text" class="form-control" placeholder="Enter Company Name"
                                            formControlName="companyName" />
                                        <span class="text-danger"
                                            *ngIf="addDeliveryPartnersForm.get('companyName').invalid && addDeliveryPartnersForm.get('companyName').touched">
                                            <div *ngIf="addDeliveryPartnersForm.get('companyName').errors.required">
                                                Company name is required</div>
                                            <div *ngIf="addDeliveryPartnersForm.get('companyName').errors.pattern">
                                                Invalid company name</div>
                                        </span>
                                    </div>
                                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-2">
                                        <label>Display Name <span class="mandatory">*</span></label>
                                        <input type="text" class="form-control" placeholder="Enter Display Name"
                                            formControlName="displayName" />
                                        <span class="text-danger"
                                            *ngIf="addDeliveryPartnersForm.get('displayName').invalid && addDeliveryPartnersForm.get('displayName').touched">
                                            <div *ngIf="addDeliveryPartnersForm.get('displayName').errors.required">
                                                display name is required</div>
                                        </span>
                                    </div>
                                    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <label>API Key</label>
                                        <input type="text" class="form-control" placeholder="Enter API Key"
                                            formControlName="apiKey" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12 mb-2">
                                <div class="row">
                                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-2">
                                        <label>API Key Expiry Date</label>
                                        <input type="date" class="form-control" placeholder="Select date"
                                            [min]="getMinDate()" formControlName="expiryDate">
                                        <!-- <p-calendar [maxDate]="maxDate" [iconDisplay]="'input'" [showIcon]="true"
                                            inputId="icondisplay" selectionMode="range" formControlName="expiryDate"
                                            placeholder="From-To"></p-calendar> -->
                                    </div>
                                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-2">
                                        <label>Contact Person</label>
                                        <input type="text" class="form-control" placeholder="Enter Contact Person"
                                            formControlName="contactPerson" />
                                    </div>
                                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-2">
                                        <label>Email</label>
                                        <input type="text" class="form-control" placeholder="Enter Email"
                                            formControlName="email" />
                                        <span class="text-danger"
                                            *ngIf="(addDeliveryPartnersForm.controls.email.touched) && !addDeliveryPartnersForm.controls.email?.valid">
                                            <div *ngIf="addDeliveryPartnersForm.controls.email.errors['pattern']">
                                                Enter a valid email address
                                            </div>
                                        </span>
                                    </div>
                                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-2">
                                        <label>Phone Number</label>
                                        <input type="text" class="form-control" placeholder="Enter Phone Number"
                                            formControlName="phoneNumber" maxlength="14" />
                                        <span class="text-danger"
                                            *ngIf="(addDeliveryPartnersForm.controls.phoneNumber.touched) && !addDeliveryPartnersForm.controls.phoneNumber?.valid">
                                            <div *ngIf="addDeliveryPartnersForm.controls.phoneNumber.errors['pattern']">
                                                Invalid phone number
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 mb-2">
                                <label>Address</label>
                                <textarea rows="5" class="form-control" placeholder="Enter Address"
                                    formControlName="address"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pb-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="d-flex justify-content-end gap-2">
                    <button type="button" class="btn cancel-btn"
                        [routerLink]="['/list-delivery-partners']">Cancel</button>
                    <button type="button" class="btn save-btn" [disabled]="!access['edit'] && processingUpdate"
                        (click)="updateDeliveryPartners()">Update</button>
                </div>
            </div>
        </div>
    </div>
</section>