<section id="paymentLedgerListSec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 my-auto">
                <div class="card m-2">
                    <div class="card-body">
                        <!-- payment-ledger-list-heading-row start -->
                        <div class="row payment-ledger-list-heading-row">
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 my-auto">
                                <div class="text-center text-md-left mt-2 mt-md-0">
                                    <h2>Payments Ledger</h2>
                                    <p class="mb-2 mb-md-0">{{paymentLedgerList?.length}} Results Found</p>
                                </div>
                            </div>
                            <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-6 col-xs-12 my-auto">
                                <div class="d-flex gap-2">
                                    <button type="button" class="btn from-to-btn">From - To</button>
                                    <p-calendar [iconDisplay]="'input'" [showIcon]="true" inputId="icondisplay"
                                        selectionMode="range" placeholder="From-To"></p-calendar>
                                </div>
                            </div>

                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 my-auto"
                                [formGroup]="filterForm">
                                <div
                                    class="has-search-d-flex justify-content-center justify-content-md-end align-items-start align-items-md-center flex-nowrap flex-md-wrap">
                                    <div class="input-group has-search me-3 mb-0">
                                        <input type="text" class="form-control" placeholder="Search"
                                            formControlName="searchText" (keyup.enter)="startingFunction()" />
                                        <span class="fa fa-search input-group-text"></span>
                                        <span class="fa fa-times input-group-text"></span>
                                    </div>
                                    <button type="button" class="btn filter-btn me-3"
                                        (click)="showFilterModalDialog('right')">
                                        <span class="material-symbols-outlined me-1">
                                            filter_alt
                                        </span>
                                        Filter
                                    </button>
                                    <div class="d-none">
                                        <button type="button" class="btn after-filter-btn me-3 d-none d-md-block">
                                            <i class="fas fa-filter"></i>
                                            Filter
                                        </button>
                                        <button type="button" class="btn after-filter-btn me-3 d-block d-md-none">
                                            <i class="fas fa-filter"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- payment-ledger-list-heading-row end -->
                <div class="card mb-1 ms-2 me-2">
                    <div class="card-body p-0">
                        <!-- payment-ledger-list-table-row start -->
                        <div class="row payment-ledger-list-table-row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="table-row table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Transaction Type</th>
                                                <th>Transaction Ref</th>
                                                <th>Customer</th>
                                                <th>Sale Type</th>
                                                <th>Payment Method</th>
                                                <th>Payment Ref</th>
                                                <th>Transaction Date</th>
                                                <th>Credit/Debit</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let item of paymentLedgerList;let i=index">
                                            <tr>
                                                <td>Online Payment</td>
                                                <td>2658453125</td>
                                                <td>Mohammed Ashraf</td>
                                                <td>Sales-Order</td>
                                                <td>Credit Card</td>
                                                <td>456784352</td>
                                                <td>04/02/2024</td>
                                                <td>Credit</td>
                                                <td>QR 12,000</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="content mb-2">
                                    <div class="flex-column">
                                        <label>Sl No</label>
                                        <h6>{{i+1}}</h6>
                                    </div>
                                    <div class="flex-column">
                                        <label>Transaction Ref</label>
                                        <h6>{{item.transReference}}</h6>
                                    </div>
                                    <div class="flex-column">
                                        <label>Order Ref</label>
                                        <h6>{{item.orderRef}}</h6>
                                    </div>
                                    <div class="flex-column">
                                        <label>Customer</label>
                                        <h6 *ngIf="item.buyerName == 'null'">-</h6>
                                        <h6 *ngIf="item.buyerName != 'null'">{{item.buyerName}}</h6>
                                    </div>
                                    <div class="flex-column">
                                        <label>Sale Type</label>
                                        <h6>{{item.type.replace('#', '').split(' ')[0]}}</h6>
                                    </div>
                                    <div class="flex-column">
                                        <label>Payment Type</label>
                                        <h6>{{item.transType.replace('#', '').split(' ')[0]}}</h6>
                                    </div>
                                    <div class="flex-column">
                                        <label>Transaction Date</label>
                                        <h6>{{item.transDate | date:'dd/MM/yyyy' : 'UTC' }}</h6>
                                    </div>
                                    <div class="flex-column">
                                        <label>Credit/Debit</label>
                                        <h6>{{item.transType.replace('#', '').split(' ')[0]}}</h6>
                                    </div>
                                    <div class="flex-column">
                                        <label>Amount</label>
                                        <h6 class="green-color">QR {{item.transAmount}}</h6>
                                    </div>
                                </div>
                            </div> -->
                            <!-- <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
              <app-no-result-found></app-no-result-found>
            </div> -->
                        </div>
                        <!-- payment-ledger-list-table-row end -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- displayFilterModalToggle start -->
<p-dialog [draggable]="false" [(visible)]="displayFilterModalToggle" [position]="position" [modal]="true"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false" [showHeader]="false"
    [style]="{width: '34vw', minHeight: '100%', maxHeight: '100%', margin: '0'}"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [formGroup]="filterForm">
    <div id="displayFilterModalToggle">
        <div class="modal-header">
            <h5 class="modal-title">Filter</h5>
            <i class="fas fa-times" (click)="closeFilterModalDialog()"></i>
        </div>
        <div class="body mt-3">
            <div class="row">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Credit/Debit</label>
                    <select class="form-select form-control" aria-label="Default select example" formControlName="type">
                        <option selected>Choose.. </option>
                        <option *ngFor="let item of filterList" [ngValue]="item.value">{{item.name}}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="d-flex justify-content-end gap-2">
            <p-button label="Cancel" styleClass="filter-cancel-btn" (click)="closeFilterModalDialog()"></p-button>
            <p-button label="Apply" styleClass="filter-apply-btn" (click)="startingFunction()"></p-button>
        </div>
    </ng-template>
</p-dialog>
<!-- displayFilterModalToggle end -->