import { ApiService } from '@services/api/api.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Messages } from '@/Toaster-messages';
import * as _ from 'underscore';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CommonService } from '@services/common/common.service';

@Component({
  selector: 'app-carousel-images-list',
  templateUrl: './carousel-images-list.component.html',
  styleUrl: './carousel-images-list.component.scss'
})
export class CarouselImagesListComponent {

  deleteModalToggle: any;
  filterObject: any = {}
  carouselImageList: any = []
  copyCarouselImageList: any = []
  searchKey: any
  isWebsite: boolean = true
  access: any = {}

  constructor(private common: CommonService, private awsApi: ApiService, private router: Router, private messageService: MessageService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.getRoleManagement()
    this.getAllCarouselImages()
  }

  async getRoleManagement() {
    this.awsApi.loading.next(true);
    try {
      await this.common.getPermissionsOfUser('Others', 'Carousel Images');
      this.access = this.common.access;
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  async getCarouselImages() {
    let fieldsToFetch = ['pk', 'id', 'type', 'deviceType', 'createdAt', 'status']
    const query = this.common.editListQuery('listCarouselImages', 'ModelCarouselImageFilterInput', fieldsToFetch);

    return this.awsApi.client
      .graphql({
        query,
        variables: {
          filter: this.filterObject,
          limit: 9999,
          nextToken: null
        }
      })
  }

  async getAllCarouselImages() {
    this.awsApi.loading.next(true);
    this.filterObject['deviceType'] = { eq: this.isWebsite ? 'web' : 'mobile' }
    await this.getCarouselImages().then((response) => {
      this.carouselImageList = response['data'].listCarouselImages.items.sort((a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
      this.copyCarouselImageList = this.carouselImageList
      this.awsApi.loading.next(false);
    }).catch((error) => {
      this.awsApi.loading.next(false);
    });
  }

  selectTab(deviceType) {
    this.isWebsite = deviceType == 'web' ? true : false
    this.getAllCarouselImages()
  }

  search(searchText: any) {
    if (searchText != '') {
      this.searchKey = searchText.toLowerCase();
      this.searchKey = this.searchKey.replace(/\s{2,}/g, ' ');
      this.searchKey = (this.searchKey.trim()).replace(/\s+/g, '');
      ;
      this.carouselImageList = this.copyCarouselImageList.filter((item: any) => {
        return (((item.type?.toLowerCase()).replace(/\s{2,}/g, ' ')).trim()).includes(this.searchKey) || (((item.deviceType?.toLowerCase()).replace(/\s{2,}/g, ' ')).trim()).includes(this.searchKey);
      });
    } else {
      this.getAllCarouselImages();
    }
  }

  showDeleteConfirmationModalDialog(item: any) {
    this.deleteModalToggle = true;
  }
  redirectToAdd() {
    this.router.navigate([`edit-web-carousel-images`])
  }

  redirectToEditPage(id: any, deviceType) {
    if (deviceType == 'web') {
      this.router.navigate([`edit-web-carousel-images`], { queryParams: { id: id } });
    }
    else {
      this.router.navigate([`edit-mobile-carousel-images`], { queryParams: { id: id } });
    }
  }
}