import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'abbreviateName'
})
export class AbbreviateNamePipe implements PipeTransform {
  transform(value: string) {
    let shortName = '';
    if (!value) return '';
    if (value.includes(' ')) {
      const names = value.split(' ');
      names.forEach((name) => {
        if (shortName.length < 2) {
          shortName += name.charAt(0).toUpperCase();
        }
      });
    }
    else {
      shortName += value.charAt(0).toUpperCase() + value.charAt(value.length - 1).toUpperCase()
    }
    return shortName;
  }
}
