import {NgModule} from '@angular/core';
import {ToastModule} from 'primeng/toast';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {RatingModule} from 'primeng/rating';

@NgModule({
    declarations: [],
    imports: [ToastModule, DialogModule, ButtonModule, RatingModule],
    exports: [ToastModule, DialogModule, ButtonModule, RatingModule]
})
export class NgPrimeModule {}
