import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import { ROUTES } from '@/redirect-routes';
import { CommonService } from '@services/common/common.service';
import { MessageService } from 'primeng/api';
import { Messages } from '@/Toaster-messages';

@Component({
  selector: 'app-contents-list',
  templateUrl: './contents-list.component.html',
  styleUrl: './contents-list.component.scss'
})
export class ContentsListComponent {
  contentsList: any = []
  copyContentsList: any = []
  searchText: any = ''
  searchKey: any
  filterObject: any = {}
  access: any = {}

  constructor(private router: Router, private common: CommonService, private messageService: MessageService, private awsApi: ApiService) { }

  ngOnInit(): void {
    this.getRoleManagement()
    this.startingFunction()
  }

  async getRoleManagement() {
    this.awsApi.loading.next(true);
    try {
      await this.common.getPermissionsOfUser('Others', 'Contents');
      this.access = this.common.access;
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  startingFunction() {
    this.awsApi.loading.next(true);
    this.GetContentsList()
  }

  async getContentList() {
    let fieldsToFetch = ['pk', 'id', 'contentName', 'sequenceNumber']
    const query = this.common.editListQuery('listContents', 'ModelContentFilterInput', fieldsToFetch);
    return this.awsApi.client
      .graphql({
        query,
        variables: {
          filter: this.filterObject,
          limit: 9999,
          nextToken: null
        }
      })
  }

  async GetContentsList() {
    try {
      await this.getContentList().then((response) => {
        this.contentsList = response['data'].listContents.items.sort((a: any, b: any) => new Date(a.sequenceNumber).getTime() - new Date(b.sequenceNumber).getTime())
        this.copyContentsList = this.contentsList
        this.awsApi.loading.next(false);
      }).catch((error) => {
        this.awsApi.loading.next(false);
      });
    } catch (error) { }
  }

  redirectToAddContentPage(contentType: any, contentId: any = null) {
    this.router.navigate([ROUTES.CREATE_CONTENT], { queryParams: { type: contentType, id: contentId } });
  }
  redirectToCreateContentSectionPage(selectedContent) {
    this.router.navigate([ROUTES.CREATE_CONTENT_SECTION], { queryParams: { id: selectedContent.id } });
  }

  search(searchText) {
    if (searchText != '') {
      this.searchKey = searchText.toLowerCase()
      this.searchKey = this.searchKey.replace(/\s{2,}/g, ' ');
      this.searchKey = (this.searchKey.trim()).replace(/\s+/g, '');

      this.filterObject['searchKey'] = { contains: this.searchKey }
      this.startingFunction()
    }
    else {
      delete this.filterObject['searchKey']
      this.startingFunction()
    }
  }

  EditContentName() {

  }
}
