<section id="attributeListSec">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 my-auto">
        <div class="card m-2">
          <div class="card-body">
            <!-- attribute-list-heading-row start -->
            <div class="row attribute-list-heading-row">
              <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 my-auto">
                <div class="text-center text-md-left mt-2 mt-md-0">
                  <h2>Attributes</h2>
                  <p class="mb-2 mb-md-0">12 Results Found</p>
                </div>
              </div>
              <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 my-auto">
                <div
                  class="has-search-d-flex justify-content-center justify-content-md-end align-items-start align-items-md-center flex-nowrap flex-md-wrap">
                  <div class="input-group has-search me-3 mb-0">
                    <input type="text" class="form-control" placeholder="Search" />
                    <span class="fa fa-search input-group-text"></span>
                    <span class="fa fa-times input-group-text"></span>
                  </div>
                  <div>
                    <button type="button" class="btn add-btn" (click)="redirectToAddAttributePage()">
                      Add Attribute
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- attribute-list-heading-row end -->
        @for (attribute of attributeList; track attribute.id) {
        <div class="card mb-1 ms-2 me-2 table-card">
          <div class="card-body">
            <!-- attribute-list-table-row start -->
            <div class="row attribute-list-table-row">
              <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="table-row action-table-responsive table-responsive">
                  <table class="table table-borderless">
                    <tbody>
                      <tr>
                        <td>
                          <div>
                            <h6>
                              {{ attribute.attrName }}
                            </h6>
                          </div>
                        </td>
                        <td class="text-end">
                          <div class="dropdown dropstart">
                            <i class="fas fa-ellipsis-v" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                              aria-expanded="false" data-bs-auto-close="inside" data-bs-auto-close="outside"></i>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              <li>
                                <a [routerLink]="['/edit-attribute']" [queryParams]="{
                                      id: attribute.id,
                                      pk: attribute.pk,
                                      slug: attribute.slug
                                    }" class="dropdown-item">
                                  <span class="material-symbols-outlined edit-icon me-3" title="Edit">
                                    edit_square
                                  </span>
                                  Edit
                                </a>
                              </li>
                              <li (click)="
                                    showDeleteAttributeModalDialog(
                                      $event,
                                      attribute
                                    )
                                  ">
                                <a class="dropdown-item">
                                  <span class="material-symbols-outlined delete-icon" title="Delete">
                                    delete
                                  </span>
                                  Delete</a>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
              <app-no-result-found></app-no-result-found>
            </div> -->
            </div>
            <!-- attribute-list-table-row end -->
          </div>
        </div>
        }
      </div>
    </div>
  </div>
</section>
<!-- attributeListSec end -->
<!-- displayFIlterModalToggle start -->
<p-dialog [draggable]="false" [(visible)]="displayFIlterModalToggle" [position]="position" [modal]="true"
  [baseZIndex]="10000" [draggable]="false" [resizable]="false" [showHeader]="false"
  [style]="{width: '34vw', minHeight: '100%', maxHeight: '100%'}" [breakpoints]="{'960px': '75vw', '640px': '100vw'}">
  <div id="displayFilterModalToggle">
    <div class="modal-header">
      <h5 class="modal-title">Filter</h5>
      <i class="fas fa-times" (click)="closeNewEmailTemplateModalDialog()"></i>
    </div>
    <div class="body mt-3">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
          <label>Template title <span class="mandatory">*</span></label>
          <input type="text" class="form-control" placeholder="Enter" />
        </div>
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
          <label>Subject <span class="mandatory">*</span></label>
          <input type="text" class="form-control" placeholder="Enter" />
        </div>
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
          <label>Email Content <span class="mandatory">*</span></label>
          <textarea class="form-control" rows="5" placeholder="Enter"></textarea>
        </div>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <p-button label="Cancel" styleClass="filter-cancel-btn"></p-button>
    <p-button label="Save" styleClass="filter-save-btn"></p-button>
  </ng-template>
</p-dialog>
<!-- displayFIlterModalToggle end -->
<!-- deleteModalToggle start -->
<p-dialog [showHeader]="false" [(visible)]="deleteModalToggle" [style]="{width: '25vw'}" [baseZIndex]="10000"
  [modal]="true">
  <div class="body mt-3" id="deleteModalToggle">
    <div class="row">
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="delete-image">
          <img src="../../../../../assets/img/delete.png" />
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="confirm-msg">
          <h6>Are You Sure?</h6>
          <p>You will not be able to recover this attribute!</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="confirm-btns">
          <button type="button" class="btn cancel-btn" (click)="deleteModalToggle = false">
            Cancel
          </button>
          <button type="button" class="btn delete-confirm-btn">Delete</button>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
<!-- {/* // deleteModalToggle end */} -->