<section id="profileSec">
    <div class="container-fluid">
        <div class="row mb-1 mt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card mb-1">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="d-flex justify-content-start align-items-center">
                                    <i class="fas fa-chevron-left" (click)="redirectToHomePage()"></i>
                                    <h6>Profile</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12">
                <div class="card mb-1 profile-img-card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div [formGroup]="personalInfoForm">
                                    <form id="profile-logo-upload-form" class="image-uploader">
                                        <input id="profile-logo-upload" type="file" name="fileUpload" accept="image/*"
                                            formControlName="profileImage" (change)="fileSave($event)" />
                                        @if (
                                        personalInfoForm.get(
                                        'profileImage'
                                        )?.value == '' || personalInfoForm.get(
                                        'profileImage'
                                        )?.value == null
                                        ) {
                                        <label for="profile-logo-upload" id="file-drag">
                                            <div id="start">
                                                <div class="upload-caption">
                                                    <i class="far fa-image"></i>
                                                    <h5>Upload User Image</h5>
                                                </div>
                                            </div>
                                        </label>
                                        } @else {
                                        <div class="after-upload-profile-image">
                                            <i *ngIf="showPersonalButton" class="fas fa-trash-alt pt-1"
                                                (click)="deleteUrl('profileImage')"></i>
                                            <img id="file-image" [src]="personalInfoForm.get('profileImage')?.value" />
                                        </div>
                                        }
                                    </form>
                                </div>
                                <div class="d-flex align-items-start mt-2">
                                    <div class="nav flex-column nav-pills w-100" id="v-pills-tab" role="tablist"
                                        aria-orientation="vertical">
                                        <button class="nav-link active" id="v-pills-personal-info-tab"
                                            data-bs-toggle="pill" data-bs-target="#v-pills-personal-info" type="button"
                                            role="tab" aria-controls="v-pills-personal-info" aria-selected="false"
                                            (click)="toggleButtons('v-pills-personal-info')">
                                            Personal Info
                                        </button>
                                        <button class="nav-link" id="v-pills-change-password-tab" data-bs-toggle="pill"
                                            data-bs-target="#v-pills-change-password" type="button" role="tab"
                                            aria-controls="v-pills-change-password" aria-selected="true"
                                            (click)="toggleButtons('v-pills-change-password')">
                                            Change Password
                                        </button>
                                        <button class="nav-link" type="button" role="tab" aria-selected="false"
                                            (click)="logout()">
                                            Logout
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xxl-9 col-xl-9 col-lg-9 col-md-8 col-sm-12 col-xs-12">
                <div class="card mb-1 tabs-body-card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="tab-content" id="v-pills-tabContent">
                                    <div class="tab-pane fade show active" id="v-pills-personal-info" role="tabpanel"
                                        aria-labelledby="v-pills-personal-info-tab">
                                        <div class="row" [formGroup]="personalInfoForm">
                                            <div
                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                <div class="d-flex justify-content-between align-items-center caption">
                                                    <h6>Personal Info</h6>
                                                    <i class="far fa-edit" (click)="toggleEditMode()"></i>
                                                </div>
                                            </div>
                                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">
                                                <label>First Name <span class="mandatory">*</span></label>
                                                <input type="text" class="form-control" placeholder="Enter First Name"
                                                    formControlName="firstName" [readonly]="!isEditMode" />
                                            </div>
                                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">
                                                <label>Last Name <span class="mandatory">*</span></label>
                                                <input type="text" class="form-control" placeholder="Enter Last Name"
                                                    [readonly]="!isEditMode" formControlName="lastName" />
                                            </div>
                                            <div
                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                <label>Phone Number <span class="mandatory">*</span></label>
                                                <input type="text" class="form-control" [readonly]="!isEditMode"
                                                    placeholder="Enter Phone Number" formControlName="phoneNumber" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="v-pills-change-password" role="tabpanel"
                                        aria-labelledby="v-pills-change-password-tab">
                                        <div class="row" [formGroup]="changePasswordForm">
                                            <div
                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                <div class="d-flex justify-content-between align-items-center caption">
                                                    <h6>Change Password</h6>
                                                </div>
                                            </div>
                                            <div
                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                <label>Current Password <span class="mandatory">*</span></label>
                                                <input type="text" class="form-control"
                                                    placeholder="Enter Current Password"
                                                    formControlName="oldPassword" />
                                                <span class="text-danger"
                                                    *ngIf="(changePasswordForm.controls.oldPassword.touched) && !changePasswordForm.controls.oldPassword?.valid">
                                                    <div
                                                        *ngIf="changePasswordForm.controls.oldPassword.errors['required']">
                                                        Current password is required.
                                                    </div>
                                                    <div
                                                        *ngIf="changePasswordForm.controls.oldPassword.errors['pattern']">
                                                        Enter a valid password
                                                    </div>
                                                </span>
                                            </div>
                                            <div
                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                <label>New Password <span class="mandatory">*</span></label>
                                                <input type="text" class="form-control" placeholder="Enter New Password"
                                                    formControlName="newPassword" />
                                                <span class="text-danger"
                                                    *ngIf="(changePasswordForm.controls.newPassword.touched) && !changePasswordForm.controls.newPassword?.valid">
                                                    <div
                                                        *ngIf="changePasswordForm.controls.newPassword.errors['required']">
                                                        New password is required.
                                                    </div>
                                                    <div
                                                        *ngIf="changePasswordForm.controls.newPassword.errors['pattern']">
                                                        Enter a valid password
                                                    </div>
                                                </span>
                                            </div>
                                            <div
                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                <label>Confirm Password <span class="mandatory">*</span></label>
                                                <input type="text" class="form-control"
                                                    placeholder="Enter Confirm Password"
                                                    formControlName="confirmPassword" />
                                                <span class="text-danger"
                                                    *ngIf="(changePasswordForm.controls.confirmPassword.touched) && !changePasswordForm.controls.confirmPassword?.valid">
                                                    <div
                                                        *ngIf="changePasswordForm.controls.confirmPassword.errors['required']">
                                                        Confirm password is required.
                                                    </div>
                                                    <div
                                                        *ngIf="changePasswordForm.controls.confirmPassword.errors['pattern']">
                                                        Enter a valid password
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="v-pills-logout" role="tabpanel"
                                        aria-labelledby="v-pills-logout-tab">
                                        ...
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3 mb-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="d-flex justify-content-end align-items-center gap-2">
                    <button class="btn cancel-btn" (click)="redirectToHomePage()">Cancel</button>
                    <button class="btn save-btn" (click)="updatePersonalInfo()"
                        [disabled]="!(personalInfoForm.valid && isEditMode)" *ngIf="showPersonalButton">Save</button>
                    <button class="btn save-btn" (click)="handleUpdatePassword()"
                        [disabled]="changePasswordForm.invalid" *ngIf="!showPersonalButton">Save</button>
                </div>
            </div>
        </div>
    </div>
</section>