import * as query from 'graphql/queries';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import { MessageService } from 'primeng/api';
import * as mutation from 'graphql/mutations';
import { CommonService } from '@services/common/common.service';
import { Messages } from '@/Toaster-messages';

@Component({
  selector: 'app-list-role',
  templateUrl: './list-role.component.html',
  styleUrl: './list-role.component.scss'
})
export class ListRoleComponent implements OnInit {
  value: number = 5;
  position: any;
  displayFilterModalToggle = false;
  deleteModalToggle: any;
  roleList: any = []
  deleteRole: any
  filterObject: any = {}
  searchKey: any
  searchText: any = ''
  access: any = {}
  randomColorsForRole: any = []

  constructor(private router: Router,
    private awsApi: ApiService, private common: CommonService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.getAllRole()
    this.getRoleManagement()
  }

  async getRoleManagement() {
    this.awsApi.loading.next(true);
    try {
      await this.common.getPermissionsOfUser('Users', 'Manage Roles');
      this.access = this.common.access;
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  getAllRole() {
    this.awsApi.loading.next(true);
    this.awsApi.client
      .graphql({
        query: query.listRoleManagements,
        variables: {
          pk: 'ROLEM#',
          id: null,
          filter: this.filterObject,
          limit: 9999999,
          nextToken: null,
          sortDirection: null
        }
      })
      .then((response) => {
        this.roleList = response.data.listRoleManagements.items.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        this.randomColorsForRole = this.generateRandomColors(this.roleList.length)
        this.awsApi.loading.next(false);
      })
      .catch((error) => {
        this.awsApi.loading.next(false);
      });
  }

  showFilterModalDialog(position: string) {
    this.position = position;
    this.displayFilterModalToggle = true;
  }
  closeFilterModalDialog() {
    this.displayFilterModalToggle = false;
  }
  showDeleteConfirmationModalDialog(item: any) {
    this.deleteModalToggle = true;
    this.deleteRole = item
  }
  redirectToAddRolePage() {
    this.router.navigate([`add-role`]);
  }

  redirectToEditRolePage() {
    this.router.navigate([`edit-role`]);
  }

  confirmDeleteBrand() {
    this.deleteModalToggle = false;
    let data = {
      pk: this.deleteRole.pk,
      id: this.deleteRole.id
    };
    this.awsApi.client
      .graphql({
        query: mutation.deleteRoleManagement,
        variables: {
          input: {
            ...data
          }
        }
      })
      .then((response) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Role deleted successfully!'
        });
        this.ngOnInit();
      })
      .catch((error) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      });
  }

  search(searchText) {
    this.searchText = searchText
    if (this.searchText != '') {
      this.searchKey = this.searchText.toLowerCase()
      this.searchKey = this.searchKey.replace(/\s{2,}/g, ' ');
      this.searchKey = (this.searchKey.trim()).replace(/\s+/g, '');

      this.filterObject['searchKey'] = { contains: this.searchKey }
      this.ngOnInit()
    } else {
      delete this.filterObject['searchKey']
      delete this.searchKey
      this.searchText
      this.ngOnInit()
    }
  }

  redirectToEditRole(item) {
    this.router.navigate([`edit-role`], { queryParams: { id: item.id, pk: item.pk } });
  }

  generateRandomColors(count: number): string[] {
    const colors: string[] = [];
    for (let i = 0; i < count; i++) {
      colors.push(this.getRandomColor());
    }
    return colors;
  }

  getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
}