import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '@modules/main/main.component';
import { BlankComponent } from '@pages/blank/blank.component';
import { ProfileComponent } from '@pages/profile/profile.component';
import { RegisterComponent } from '@modules/register/register.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { AuthGuard } from '@guards/auth.guard';
import { NonAuthGuard } from '@guards/non-auth.guard';
import { ForgotPasswordComponent } from '@modules/forgot-password/forgot-password.component';
import { RecoverPasswordComponent } from '@modules/recover-password/recover-password.component';
import { SubMenuComponent } from '@pages/main-menu/sub-menu/sub-menu.component';
import { UserSigninComponent } from './user-auth/user-signin/user-signin.component';
import { UserSignupComponent } from './user-auth/user-signup/user-signup.component';
import { UserConfirmCodeComponent } from './user-auth/user-confirm-code/user-confirm-code.component';
import { ConfirmResetPasswordComponent } from './user-auth/confirm-reset-password/confirm-reset-password.component';
import { UserForgotPasswordComponent } from './user-auth/user-forgot-password/user-forgot-password.component';
import { CategoryListComponent } from '@pages/category/category-list/category-list.component';
import { CategoryAddComponent } from '@pages/category/category-add/category-add.component';
import { CategoryEditComponent } from '@pages/category/category-edit/category-edit.component';
import { BrandAddComponent } from '@pages/brand/brand-add/brand-add.component';
import { BrandEditComponent } from '@pages/brand/brand-edit/brand-edit.component';
import { BrandListComponent } from '@pages/brand/brand-list/brand-list.component';
import { ProductAddComponent } from '@pages/product/product-add/product-add.component';
import { ProductListComponent } from '@pages/product/product-list/product-list.component';
import { SettingsComponent } from '@modules/main/header/settings/settings.component';
import { UsersListComponent } from '@pages/users/users-list/users-list.component';
import { UserAddComponent } from '@pages/users/user-add/user-add.component';
import { UserEditComponent } from '@pages/users/user-edit/user-edit.component';
import { ProductReviewsListComponent } from '@pages/product-review/product-reviews-list/product-reviews-list.component';
import { CustomerReviewComponent } from '@pages/product-review/customer-review/customer-review.component';
import { GeneralQueriesListComponent } from '@pages/general-queries/general-queries-list/general-queries-list.component';
import { CustomerQueryComponent } from '@pages/general-queries/customer-query/customer-query.component';
import { OrdersListComponent } from '@pages/order/orders-list/orders-list.component';
import { OrderDetailsComponent } from '@pages/order/order-details/order-details.component';
import { ReturnAndRefundDetailsComponent } from '@pages/return&refund/return-and-refund-details/return-and-refund-details.component';
import { ReturnAndRefundListComponent } from '@pages/return&refund/return-and-refund-list/return-and-refund-list.component';
import { PaymentsLedgerComponent } from '@pages/reports/payments-ledger/payments-ledger.component';
import { StocksReportListComponent } from '@pages/reports/stocks-report-list/stocks-report-list.component';
import { CategoriesReportListComponent } from '@pages/reports/categories-report-list/categories-report-list.component';
import { OrdersReportListComponent } from '@pages/reports/orders-report-list/orders-report-list.component';
import { ProductsReportListComponent } from '@pages/reports/products-report-list/products-report-list.component';
import { AddRoleComponent } from '@pages/role/add-role/add-role.component';
import { AddWarehouseComponent } from '@pages/warehouse/add-warehouse/add-warehouse.component';
import { ListWarehouseComponent } from '@pages/warehouse/list-warehouse/list-warehouse.component';
import { FaqListComponent } from '@pages/others/faq-list/faq-list.component';
import { AddFaqComponent } from '@pages/others/add-faq/add-faq.component';
import { AddContentComponent } from '@pages/others/add-content/add-content.component';
import { ContentsListComponent } from '@pages/others/contents-list/contents-list.component';
import { AddCarouselImagesComponent } from '@pages/others/add-carousel-images/add-carousel-images.component';
import { CarouselImagesListComponent } from '@pages/others/carousel-images-list/carousel-images-list.component';
import { CreateContentSectionComponent } from '@pages/others/create-content-section/create-content-section.component';
import { CreateFaqSectionComponent } from '@pages/others/create-faq-section/create-faq-section.component';
import { EditMobileCarouselImagesComponent } from '@pages/others/edit-mobile-carousel-images/edit-mobile-carousel-images.component';
import { EditWebCarouselImagesComponent } from '@pages/others/edit-web-carousel-images/edit-web-carousel-images.component';
import { AttributeAddComponent } from '@pages/product/attribute-add/attribute-add.component';
import { AttributeListComponent } from '@pages/product/attribute-list/attribute-list.component';
import { AttributeEditComponent } from '@pages/product/attribute-edit/attribute-edit.component';
import { CountryAddComponent } from '@pages/country/country-add/country-add.component';
import { CountryListComponent } from '@pages/country/country-list/country-list.component';
import { CountryEditComponent } from '@pages/country/country-edit/country-edit.component';
import { EditWarehouseComponent } from '@pages/warehouse/edit-warehouse/edit-warehouse.component';
import { TaxListComponent } from '@pages/tax/tax-list/tax-list.component';
import { AllNotificationsComponent } from '@modules/main/header/notifications/all-notifications/all-notifications.component';
import { ListRoleComponent } from '@pages/role/list-role/list-role.component';
import { EditRoleComponent } from '@pages/role/edit-role/edit-role.component';
import { SavedCartsComponent } from './pages/cart/saved-carts/saved-carts.component';
import { CartDetailsComponent } from './pages/cart/cart-details/cart-details.component';
import { AddDeliveryPartnerComponent } from '@pages/delivery-partners/add-delivery-partner/add-delivery-partner.component';
import { ProductEditComponent } from '@pages/product/product-edit/product-edit.component';
import { ListDeliveryPartnerComponent } from '@pages/delivery-partners/list-delivery-partner/list-delivery-partner.component';
import { EditDeliveryPartnerComponent } from '@pages/delivery-partners/edit-delivery-partner/edit-delivery-partner.component';
import { PromocodeAddComponent } from '@pages/promocode/promocode-add/promocode-add.component';
import { PromocodeListComponent } from '@pages/promocode/promocode-list/promocode-list.component';
import { ListCustomersComponent } from '@pages/customers/list-customers/list-customers.component';
import { PromocodeEditComponent } from '@pages/promocode/promocode-edit/promocode-edit.component';
import { ReturnRefundReportListComponent } from '@pages/reports/return-refund-report-list/return-refund-report-list.component';
import { VariantListComponent } from '@pages/Variant/variant-list/variant-list.component';
import { UnitsListComponent } from '@pages/Units/units-list/units-list.component';
import { ShippingAddComponent } from '@pages/shipping/shipping-add/shipping-add.component';
import { ShippingEditComponent } from '@pages/shipping/shipping-edit/shipping-edit.component';
import { ShippingListComponent } from '@pages/shipping/shipping-list/shipping-list.component';
import { ReportListComponent } from '@pages/reports/report-list/report-list.component';

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: 'list-category',
                component: CategoryListComponent
            },
            {
                path: 'add-category',
                component: CategoryAddComponent
            },
            {
                path: 'edit-category',
                component: CategoryEditComponent
            },
            {
                path: 'list-brand',
                component: BrandListComponent
            },
            {
                path: 'add-brand',
                component: BrandAddComponent
            },
            {
                path: 'edit-brand',
                component: BrandEditComponent
            },
            {
                path: 'list-product',
                component: ProductListComponent
            },
            {
                path: 'add-product',
                component: ProductAddComponent
            },
            {
                path: 'edit-product',
                component: ProductEditComponent
            },
            {
                path: 'list-tax',
                component: TaxListComponent
            },
            {
                path: 'list-variant',
                component: VariantListComponent
            },
            {
                path: 'list-units',
                component: UnitsListComponent
            },
            {
                path: 'add-attribute',
                component: AttributeAddComponent
            },

            {
                path: 'list-attribute',
                component: AttributeListComponent
            },

            {
                path: 'edit-attribute',
                component: AttributeEditComponent
            },
            {
                path: 'add-country',
                component: CountryAddComponent
            },

            {
                path: 'list-country',
                component: CountryListComponent
            },
            {
                path: 'edit-country',
                component: CountryEditComponent
            },
            {
                path: 'add-shipping',
                component: ShippingAddComponent
            },

            {
                path: 'list-shipping',
                component: ShippingListComponent
            },

            {
                path: 'edit-shipping',
                component: ShippingEditComponent
            },
            {
                path: 'list-customer',
                component: ListCustomersComponent
            },
            {
                path: 'list-user',
                component: UsersListComponent
            },
            {
                path: 'add-user',
                component: UserAddComponent
            },
            {
                path: 'edit-user',
                component: UserEditComponent
            },
            {
                path: 'product-reviews',
                component: ProductReviewsListComponent
            },
            {
                path: 'customer-review',
                component: CustomerReviewComponent
            },
            {
                path: 'general-queries',
                component: GeneralQueriesListComponent
            },
            {
                path: 'customer-query',
                component: CustomerQueryComponent
            },
            {
                path: 'order-list',
                component: OrdersListComponent
            },
            {
                path: 'order-details',
                component: OrderDetailsComponent
            },
            {
                path: 'return-refund-list',
                component: ReturnAndRefundListComponent
            },
            {
                path: 'return-refund-details',
                component: ReturnAndRefundDetailsComponent
            },
            {
                path: 'payments-ledger',
                component: PaymentsLedgerComponent
            },
            {
                path: 'report-list',
                component: ReportListComponent
            },
            {
                path: 'stocks-report-list',
                component: StocksReportListComponent
            },
            {
                path: 'products-report-list',
                component: ProductsReportListComponent
            },
            {
                path: 'categories-report-list',
                component: CategoriesReportListComponent
            },
            {
                path: 'return-refund-report-list',
                component: ReturnRefundReportListComponent
            },
            {
                path: 'orders-report-list',
                component: OrdersReportListComponent
            },
            {
                path: 'list-role',
                component: ListRoleComponent
            },
            {
                path: 'add-role',
                component: AddRoleComponent
            },
            {
                path: 'edit-role',
                component: EditRoleComponent
            },
            {
                path: 'list-warehouse',
                component: ListWarehouseComponent
            },
            {
                path: 'add-warehouse',
                component: AddWarehouseComponent
            },
            {
                path: 'edit-warehouse',
                component: EditWarehouseComponent
            },
            {
                path: 'faq-list',
                component: FaqListComponent
            },
            {
                path: 'add-faq',
                component: AddFaqComponent
            },
            {
                path: 'content-list',
                component: ContentsListComponent
            },
            {
                path: 'add-content',
                component: AddContentComponent
            },
            {
                path: 'add-content-section',
                component: CreateContentSectionComponent
            },
            {
                path: 'add-faq-section',
                component: CreateFaqSectionComponent
            },
            {
                path: 'carousel-images-list',
                component: CarouselImagesListComponent
            },
            {
                path: 'add-carousel-images',
                component: AddCarouselImagesComponent
            },
            {
                path: 'edit-mobile-carousel-images',
                component: EditMobileCarouselImagesComponent
            },
            {
                path: 'edit-web-carousel-images',
                component: EditWebCarouselImagesComponent
            },
            {
                path: 'profile',
                component: ProfileComponent
            },
            {
                path: 'list-cart',
                component: SavedCartsComponent
            },
            {
                path: 'cart-details',
                component: CartDetailsComponent
            },
            {
                path: 'list-delivery-partners',
                component: ListDeliveryPartnerComponent
            },
            {
                path: 'add-delivery-partners',
                component: AddDeliveryPartnerComponent
            },
            {
                path: 'edit-delivery-partners',
                component: EditDeliveryPartnerComponent
            },
            {
                path: 'settings',
                component: SettingsComponent
            },
            {
                path: 'all-notifications',
                component: AllNotificationsComponent
            },
            {
                path: 'list-promocode',
                component: PromocodeListComponent
            },
            {
                path: 'add-promocode',
                component: PromocodeAddComponent
            },
            {
                path: 'edit-promocode',
                component: PromocodeEditComponent
            },
            {
                path: 'blank',
                component: BlankComponent
            },
            {
                path: 'sub-menu-1',
                component: SubMenuComponent
            },
            {
                path: 'sub-menu-2',
                component: BlankComponent
            },
            {
                path: '',
                component: DashboardComponent
            }
        ]
    },
    {
        path: 'login',
        component: UserSigninComponent,
        canActivate: [NonAuthGuard]
    },

    {
        path: 'signup',
        component: UserSignupComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'confirm',
        component: UserConfirmCodeComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'confirm-reset-password',
        component: ConfirmResetPasswordComponent,
        canActivate: [NonAuthGuard]
    },

    {
        path: 'forgot-password',
        component: UserForgotPasswordComponent,
        canActivate: [NonAuthGuard]
    },

    {
        path: 'register',
        component: RegisterComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'forgot-password-admin',
        component: ForgotPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'recover-password',
        component: RecoverPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
