import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import { MessageService } from 'primeng/api';
import * as query from 'graphql/queries';
import { FormBuilder } from '@angular/forms';
import { formatDate } from '@angular/common';
import { ROUTES } from '@/redirect-routes';
import { RefundStatusList, ReturnItemStatusList } from '@/common-list';
import { CommonService } from '@services/common/common.service';
import { ReturnActivityStatus } from 'API';
import { Messages } from '@/Toaster-messages';

@Component({
  selector: 'app-return-and-refund-list',
  templateUrl: './return-and-refund-list.component.html',
  styleUrl: './return-and-refund-list.component.scss'
})
export class ReturnAndRefundListComponent {
  value: number = 5;
  position: any;
  displayFilterModalToggle = false
  deleteModalToggle: any
  returnItemsList: any = []
  copyReturnItemsList: any = []
  returnDataList: any = []
  returnItemsRes: any = []
  searchText: any = ''
  searchKey: any
  status: any = []
  orderedOn: any = []
  returnDate: any = []
  filterObject: any = {}
  access: any = {}
  filter: boolean
  orderId: any
  orderDetails: any = []
  statusIndex: any = 0
  statusList: any = []
  filterApplied: boolean = false
  returnItemStatusList = ReturnItemStatusList.concat(RefundStatusList);
  today: any = new Date();
  thirtyDaysAgo = new Date((new Date()).setDate(this.today.getDate() - 30))
  page: any
  pageSize: any
  loading: boolean
  endOfData: boolean
  loader: boolean = true

  filterForm = this.formBuilder.group({
    status: [],
    returnDate: []
  })

  constructor(private common: CommonService, private router: Router, private awsApi: ApiService, private formBuilder: FormBuilder, private messageService: MessageService) { }

  async ngOnInit(): Promise<void> {
    this.returnItemStatusList = this.returnItemStatusList.concat({ title: '8', value: ReturnActivityStatus.Rejected, valueToShow: 'Rejected' })
    this.position = 'right';
    this.filter = true
    this.filterForm.patchValue({ status: this.returnItemStatusList })
    this.filterForm.patchValue({ returnDate: [this.thirtyDaysAgo, this.today] });
    try {
      await this.common.getPermissionsOfUser('Orders', 'Return and Refund');
      this.access = this.common.access;
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
    this.applyFilter()
  }

  async startingFunction() {
    await this.getReturnItemsList()
    this.initializePagination()
  }

  async getReturnItems() {
    let items = [];
    let nextToken = null;
    do {
      const response = await this.awsApi.client.graphql({
        query: query.listOrderItems,
        variables: {
          filter: this.filterObject,
          limit: 650,
          nextToken: nextToken,
          sortDirection: null
        }
      })
      items = items.concat(response.data.listOrderItems.items);
      nextToken = response.data.listOrderItems.nextToken;
    } while (nextToken !== null)
    return items
  }

  async getReturnItemsList() {
    this.returnItemsList = await this.getReturnItems()
    this.returnItemsList = this.returnItemsList.filter((returnItem: any) => {
      if (returnItem.returnRefundActivity && returnItem.returnRefundActivity.returnStatusUpdate && returnItem.returnRefundActivity.returnStatusUpdate.length > 0) {
        this.statusIndex++
        return returnItem
      }
    })
    this.copyReturnItemsList = this.returnItemsList.sort((a: any, b: any) => {
      const dateA = new Date(a.returnRefundActivity?.createdAt).getTime();
      const dateB = new Date(b.returnRefundActivity?.createdAt).getTime();
      return dateB - dateA;
    });
    this.returnItemsRes = this.copyReturnItemsList
    this.orderId = this.returnItemsList.orderId
  }

  initializePagination() {
    this.page = 1;
    this.pageSize = 11;
    this.loading = false;
    this.endOfData = false;
    this.loadData()
  }

  onScroll() {
    if (!this.loading && !this.endOfData) {
      const container = document.querySelector('.scroll-container');
      if (
        container &&
        container.scrollHeight - container.scrollTop === container.clientHeight
      ) {
        this.loadMore();
      }
    }
  }

  loadMore() {
    this.page++
    // Loading more data
    this.loading = true;
    const startIndex = (this.page - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    setTimeout(() => {
      this.returnDataList = this.returnDataList.concat(this.copyReturnItemsList.slice(startIndex, endIndex));
      this.loading = false;

      if (this.returnDataList.length >= this.copyReturnItemsList.length) {
        this.endOfData = true;
      }
    }, 2000);
  }

  loadData() {
    // Initial data loading   
    this.loading = true;
    const startIndex = (this.page - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    setTimeout(() => {
      this.returnDataList = this.copyReturnItemsList.slice(startIndex, endIndex);
      this.awsApi.loading.next(false)
      this.loader = this.awsApi.loading.getValue()
      this.loading = false;
    }, 1000);
  }

  getOrderDetails() {
    this.awsApi.client
      .graphql({
        query: query.getOrder,
        variables: {
          pk: 'ORDER#',
          id: this.orderId
        }
      }).then((response) => {
        this.orderDetails = response.data.getOrder;
      })
  }

  getOrderItemDetails() {
    this.awsApi.client
      .graphql({
        query: query.listOrderItems,
        variables: {
          pk: 'ORDERI#' + this.orderId,
          id: this.orderId
        }
      }).then((response) => {
        this.orderDetails = response.data.listOrderItems.items[0];
      })
  }

  async search(searchText) {
    if (searchText != '') {
      this.searchKey = searchText.toLowerCase()
      this.searchKey = this.searchKey.replace(/\s{2,}/g, ' ');
      this.searchKey = (this.searchKey.trim()).replace(/\s+/g, '');
      this.filterObject['searchKey'] = { contains: this.searchKey }
    }
    else {
      delete this.filterObject['searchKey']
    }
    this.applyFilter()
  }

  async applyFilter() {
    this.displayFilterModalToggle = false;
    this.awsApi.loading.next(true)
    this.loader = this.awsApi.loading.getValue()
    await this.getReturnItemsList()
    this.filter = false
    this.copyReturnItemsList = this.returnItemsRes
    this.filterStatus()
    this.filterReturnDate()
    this.initializePagination()
  }

  filterStatus() {
    if (this.filterForm.controls.status.value?.length > 0) {
      this.filterApplied = true
      this.status = this.filterForm.controls.status.value
      this.returnItemsList = this.copyReturnItemsList.filter((returnItem: any) => {
        if (returnItem.returnRefundActivity.status) {
          for (const status of this.status) {
            if (returnItem.returnRefundActivity.status === status.value) {
              return true;
            }
          }
          return false;
        }
      })
      this.copyReturnItemsList = this.returnItemsList
    }
  }

  filterReturnDate() {
    if (this.filterForm.controls.returnDate.value?.length == 2 && this.filterForm.controls.returnDate.value[0] != null && this.filterForm.controls.returnDate.value[1] != null) {
      this.filterApplied = true
      this.returnDate[0] = formatDate(new Date(this.filterForm.controls.returnDate.value[0]), 'yyyy-MM-dd', 'en-US')
      this.returnDate[1] = formatDate(new Date(this.filterForm.controls.returnDate.value[1]), 'yyyy-MM-dd', 'en-US')

      this.returnItemsList = this.copyReturnItemsList.filter((returnItem: any) => {
        if (returnItem.returnRefundActivity.createdAt) {
          return formatDate(new Date(returnItem.returnRefundActivity.createdAt), 'yyyy-MM-dd', 'en') >= this.returnDate[0] && formatDate(new Date(returnItem.returnRefundActivity.createdAt), 'yyyy-MM-dd', 'en') <= this.returnDate[1];
        }
      })
      this.copyReturnItemsList = this.returnItemsList
    }
  }

  showFilterModalDialog(position: string) {
    this.position = position;
    this.displayFilterModalToggle = true;
  }

  cancelFilter() {
    this.filterForm.reset()
    this.filterApplied = false
    this.applyFilter()
  }

  closeFilterModalDialog() {
    this.displayFilterModalToggle = false;
  }
  showDeleteGeneralQueryModalDialog(item: any) {
    this.deleteModalToggle = true;
  }
  redirectToReturnItemDetails(returnItem) {
    this.router.navigate([ROUTES.RETURN_ITEM_DETAILS], { queryParams: { orderId: returnItem.pk.split('#')[1], orderItemId: returnItem.id } });
  }
}