<section id="orderListSec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 my-auto">
                <div class="card m-2">
                    <div class="card-body">
                        <!-- order-list-heading-row start -->
                        <div class="row order-list-heading-row">
                            <div class="col-xxl-6 col-xl-6 col-lg-4 col-md-4 col-sm-12 col-xs-12 my-auto">
                                <div class="text-center text-md-left mt-2 mt-md-0">
                                    <h2>Orders</h2>
                                    <p class="mb-2 mb-md-0" *ngIf="ordersList.length>1">{{ordersList.length}} Results
                                        Found</p>
                                    <p class="mb-2 mb-md-0" *ngIf="ordersList.length==1">1 Result Found</p>
                                </div>
                            </div>
                            <div class="col-xxl-6 col-xl-6 col-lg-8 col-md-8 col-sm-12 col-xs-12 my-auto">
                                <div
                                    class="has-search-d-flex justify-content-center justify-content-md-end align-items-start align-items-md-center flex-nowrap flex-md-wrap">
                                    <div class="input-group has-search me-3 mb-0">
                                        <input type="text" class="form-control" placeholder="Search"
                                            [(ngModel)]="searchTextValue" (keyup.enter)="search($event)" />
                                        <span class="fa fa-search input-group-text" *ngIf="searchTextValue==''"
                                            (click)="search(searchTextValue)"></span>
                                        <span class="fa fa-times input-group-text" *ngIf="searchTextValue!=''"
                                            (click)="clearText()"></span>
                                    </div>
                                    <div *ngIf="!filterApplied">
                                        <button type="button" class="btn filter-btn d-none d-md-block"
                                            (click)="showFilterModalDialog('right')" *ngIf="ordersList?.length>10">
                                            <span class="material-symbols-outlined me-1">
                                                filter_alt
                                            </span>
                                            Filter
                                        </button>
                                        <button type="button" class="btn filter-btn d-block d-md-none"
                                            (click)="showFilterModalDialog('right')" *ngIf="ordersList?.length>10">
                                            <span class="material-symbols-outlined">
                                                filter_alt
                                            </span>
                                        </button>
                                    </div>
                                    <div *ngIf="filterApplied">
                                        <button type="button" class="btn after-filter-btn me-3 d-none d-md-block"
                                            (click)="showFilterModalDialog('right')">
                                            <i class="fas fa-filter"></i>
                                            Filter
                                        </button>
                                        <button type="button" class="btn after-filter-btn me-3 d-block d-md-none"
                                            (click)="showFilterModalDialog('right')">
                                            <i class="fas fa-filter"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- order-list-heading-row end -->
                <div class="card mb-1 ms-2 me-2 scroll-container table-card" (window:scroll)="onScroll()"
                    *ngFor="let order of copyOrdersList">
                    <div class="card-body">
                        <!-- order-list-table-row start -->
                        <div class="row order-list-table-row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="content mb-2" (click)="redirectToOrderDetails(order)">
                                    <div class="flex-column">
                                        <label>Order Ref</label>
                                        <h6>{{order.id}}</h6>
                                    </div>
                                    <div class="flex-column">
                                        <label>Customer Name</label>
                                        <h6
                                            *ngIf="order.userName && order.userName!='undefined' && order.userName != 'null' && order.userName != ' '">
                                            {{order.userName}}</h6>
                                        <h6
                                            *ngIf="!order.userName || order.userName=='undefined' || order.userName == 'null' || order.userName==' '">
                                            N/A</h6>
                                    </div>
                                    <div class="flex-column">
                                        <label>Email</label>
                                        <h6>{{order.email}}</h6>
                                    </div>
                                    <div class="flex-column">
                                        <label>Phone Number</label>
                                        <h6
                                            *ngIf="order.phoneNumber && order.phoneNumber != 'undefined' && order.phoneNumber != 'null'">
                                            {{order.phoneNumber}}</h6>
                                        <h6
                                            *ngIf="!order.phoneNumber || order.phoneNumber == 'undefined' || order.phoneNumber == 'null'">
                                            N/A</h6>
                                    </div>
                                    <div class="flex-column">
                                        <label>Status</label>
                                        <h6 [ngSwitch]="order.status">
                                            <span *ngSwitchCase="'PENDING'" class="pending">Pending</span>
                                            <span *ngSwitchCase="'CONFIRMED'" class="confirmed">Confirmed</span>
                                            <span *ngSwitchCase="'PROCESSING'" class="processing">Processing</span>
                                            <span *ngSwitchCase="'DELIVERED'" class="delivered">Delivered</span>
                                            <span *ngSwitchCase="'CANCELLED'" class="cancelled">Cancelled</span>
                                            <span *ngSwitchCase="'FAILED'" class="failed">Failed</span>
                                            <span *ngSwitchDefault>{{ order.status | formatDocType }}</span>
                                        </h6>
                                    </div>
                                </div>
                                <div class="content">
                                    <div class="flex-column">
                                        <label>Ordered On</label>
                                        <h6>{{ order.createdAt | date: 'dd/MM/yyyy' : 'UTC'}}, {{order.createdAt | date:'shortTime'}}</h6>
                                    </div>
                                    <div class="flex-column">
                                        <label>Billing To</label>
                                        <h6
                                            *ngIf="order.userName && order.userName!='undefined' && order.userName != 'null' && order.userName != ' '">
                                            {{ order.userName.split('undefined')[0] }}</h6>
                                        <h6
                                            *ngIf="!order.userName || order.userName=='undefined' || order.userName == 'null' || order.userName==' '">
                                            N/A</h6>
                                    </div>
                                    <div class="flex-column">
                                        <label>Delivery Type</label>
                                        <h6 *ngIf="order.deliveryInfo?.deliveryType">{{ order.deliveryInfo?.deliveryType
                                            }}</h6>
                                        <h6 *ngIf="!order.deliveryInfo?.deliveryType">{{
                                            order.deliveryInfo?.deliveryType }}</h6>
                                    </div>
                                    <div class="flex-column">
                                        <label>Payment Method</label>
                                        <h6 *ngIf="order.paymentDetails?.paymentMethod">
                                            {{order.paymentDetails?.paymentMethod | formatDocType}}</h6>
                                        <h6 *ngIf="!order.paymentDetails?.paymentMethod">N/A</h6>
                                    </div>
                                    <div class="flex-column">
                                        <label>Total</label>
                                        <h6>{{currency}} {{ order.totalAmount | number: '.2' }}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- order-list-table-row end -->
                    </div>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3"
                    *ngIf="ordersList.length==0 && !loader">
                    <app-no-result-found></app-no-result-found>
                </div>
                <div *ngIf="loading && ordersList.length>10">Loading...</div>
            </div>
        </div>
    </div>
</section>

<!-- displayFilterModalToggle start -->
<p-dialog [draggable]="false" [(visible)]="displayFilterModalToggle" [position]="position" [modal]="true"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false" [showHeader]="false"
    [style]="{width: '34vw', minHeight: '100%', maxHeight: '100%', margin: '0'}"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}">
    <div id="displayFilterModalToggle">
        <div class="modal-header">
            <h5 class="modal-title">Filter</h5>
            <i class="fas fa-times" (click)="closeFilterModalDialog()"></i>
        </div>
        <div class="body mt-3" [formGroup]="filterForm">
            <div class="row">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Status</label>
                    <p-multiSelect display="chip" formControlName="status" [options]="orderStatusList"
                        optionLabel="text" [filter]="true" [defaultLabel]="'Choose Status'">
                    </p-multiSelect>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Payment Method </label>
                    <select class="form-select form-control" aria-label="Default select example"
                        formControlName="paymentMethod">
                        <option [ngValue]="null" selected>Choose payment method</option>
                        <option selected *ngFor="let paymentMethod of paymentMethodList"
                            [ngValue]="paymentMethod['value']">
                            {{ paymentMethod['value'] | formatDocType }}
                    </select>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Ordered On</label>
                    <p-calendar [iconDisplay]="'input'" [showIcon]="true" inputId="icondisplay" selectionMode="range"
                        formControlName="orderedOn" placeholder="From-To"></p-calendar>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="d-flex justify-content-end gap-2">
            <p-button label="Cancel" styleClass="filter-cancel-btn" (click)="refreshPage()"></p-button>
            <p-button label="Apply" styleClass="filter-apply-btn" (click)="applyFilter()"></p-button>
        </div>
    </ng-template>
</p-dialog>
<!-- displayFilterModalToggle end -->
<!-- deleteModalToggle start -->
<p-dialog [showHeader]="false" [(visible)]="deleteModalToggle" [style]="{width: 'auto'}" [baseZIndex]="10000"
    [modal]="true">
    <div class="body mt-3" id="deleteModalToggle">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="delete-image">
                    <img src="../../../../assets/img/delete.png" />
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="confirm-msg">
                    <h6>Are You Sure?</h6>
                    <p>You will not be able to recover this product!</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="confirm-btns">
                    <button type="button" class="btn cancel-btn" (click)="deleteModalToggle = false">
                        Cancel
                    </button>
                    <button type="button" class="btn delete-confirm-btn">
                        Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</p-dialog>
<!-- deleteModalToggle end -->