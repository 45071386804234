<section id="stocksReportListSec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 my-auto" id="stocks-list">
                <div class="card m-2">
                    <div class="card-body">
                        <!-- stocks-list-heading-row start -->
                        <div class="row stocks-list-heading-row">
                            <div class="col-xxl-5 col-xl-5 col-lg-4 col-md-4 col-sm-12 col-xs-12 my-auto">
                                <div class="text-center text-md-left mt-2 mt-md-0">
                                    <div class="d-flex justify-content-start align-items-center">
                                        <i class="fas fa-chevron-left" (click)="redirectToReportListPage()"></i>
                                        <h6>Current Stock Report</h6>
                                    </div>
                                    <p class="mb-2 mb-md-0 mt-2" *ngIf="stockReportList?.length > 0">
                                        {{stockReportList?.length}} Results Found
                                    </p>
                                </div>
                            </div>
                            <div class="col-xxl-7 col-xl-7 col-lg-8 col-md-8 col-sm-12 col-xs-12 my-auto">
                                <div class="has-search-d-flex justify-content-center justify-content-md-end align-items-start align-items-md-center flex-nowrap flex-md-wrap"
                                    [formGroup]="filterForm">
                                    <div class="input-group has-search me-3 mb-2 mb-md-0" *ngIf="hide">
                                        <input type="text" class="form-control" placeholder="Search"
                                            formControlName="searchText" (keyup.enter)="applyFilter()" />
                                        <span class="fa fa-search input-group-text"
                                            *ngIf="!filterForm.controls.searchText.value"
                                            (click)="applyFilter()"></span>
                                        <span class="fa fa-times input-group-text"
                                            *ngIf="filterForm.controls.searchText.value"></span>
                                    </div>
                                    <div *ngIf="!filterApplied">
                                        <button type="button" class="btn filter-btn me-3 d-none d-md-block" (click)="
                                        showFilterModalDialog('right')">
                                            <span class="material-symbols-outlined me-1">
                                                filter_alt
                                            </span>
                                            Filter
                                        </button>
                                        <button type="button" class="btn filter-btn me-3 d-block d-md-none"
                                            (click)="showFilterModalDialog('right')">
                                            <span class="material-symbols-outlined">
                                                filter_alt
                                            </span>
                                        </button>
                                    </div>
                                    <div *ngIf="filterApplied">
                                        <button type="button" class="btn after-filter-btn me-3 d-none d-md-block"
                                            (click)="showFilterModalDialog('right')">
                                            <i class="fas fa-filter"></i>
                                            Filter
                                        </button>
                                        <button type="button" class="btn after-filter-btn me-3 d-block d-md-none"
                                            (click)="showFilterModalDialog('right')">
                                            <i class="fas fa-filter"></i>
                                        </button>
                                    </div>
                                    <div>
                                        <button type="button" class="btn export-btn d-none d-md-block"
                                            (click)="exportToExcel()" *ngIf="stockReportList?.length>0">
                                            <i class="fas fa-file-export me-1"></i> Export
                                        </button>
                                        <button type="button" class="btn export-btn d-block d-md-none"
                                            (click)="exportToExcel()" *ngIf="stockReportList?.length>0">
                                            <i class="fas fa-file-export"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-1 ms-2 me-2" *ngIf="!filterApplied && stockReportList?.length == 0">
                    <div class="card-body p-0 order-selection">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <h2>Please select a filter to view the report</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- stocks-list-heading-row end -->
                <div class="card mb-1 ms-2 me-2 scroll-container" (window:scroll)="onScroll()">
                    <div class="card-body p-0">
                        <!-- stocks-list-table-row start -->
                        <div class="row stocks-list-table-row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                *ngIf="stockReportData?.length>0">
                                <div class="table-row table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>SKU</th>
                                                <th>Variant Value</th>
                                                <th>Product Name</th>
                                                <th>Brand</th>
                                                <th>Category</th>
                                                <th>Stock Qty</th>
                                                <th>Reserved Qty</th>
                                                <th>Stock Unit</th>
                                                <th>Items Per Unit</th>
                                                <th class="text-end">Regular Price</th>
                                                <th class="text-end">Sale Price</th>
                                                <th>Updated On</th>
                                                <th nowrap>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let item of stockReportData">
                                            <tr>
                                                <td>
                                                    <span *ngIf="item.sku">{{item.sku}}</span>
                                                    <span *ngIf="!item.sku">N/A</span>
                                                </td>
                                                <td>
                                                    <span
                                                        *ngIf="item.attrValue">{{item.attrName}}-{{item.attrValue}}</span>
                                                    <span *ngIf="!item.attrValue">N/A</span>
                                                </td>
                                                <td>{{item.productName}}</td>
                                                <td>{{item.brandName}}</td>
                                                <td nowrap>{{item.mainCategory}}
                                                    <p *ngIf="item.subCategory">{{item.subCategory}}</p>
                                                </td>
                                                <td>
                                                    <span *ngIf="item.stockQuantity">{{item.stockQuantity }}</span>
                                                    <span *ngIf="!item.stockQuantity">00</span>
                                                </td>
                                                <td>
                                                    <span *ngIf="item.reservedStock">{{item.reservedStock }}</span>
                                                    <span *ngIf="!item.reservedStock">00</span>
                                                </td>
                                                <td>{{item.stockUnit }}</td>
                                                <td>
                                                    <span *ngIf="item.itemsPerUnit">{{item.itemsPerUnit }}</span>
                                                    <span *ngIf="!item.itemsPerUnit">00</span>
                                                </td>
                                                <td class="text-end">
                                                    <span *ngIf="item.regularPrice">{{ item.regularPrice | number:
                                                        '.2'}}</span>
                                                    <span *ngIf="!item.regularPrice">0.00</span>
                                                </td>
                                                <td class="text-end">
                                                    <span *ngIf="item.salePrice">{{item.salePrice | number:
                                                        '.2'}}</span>
                                                    <span *ngIf="!item.salePrice">0.00</span>
                                                </td>
                                                <td>
                                                    <span *ngIf="item.updatedAt">{{item.updatedAt |
                                                        date:'dd/MM/YYY'}}</span>
                                                    <span *ngIf="!item.updatedAt">0.00</span>
                                                </td>
                                                <td nowrap>
                                                    <span *ngIf="item.stockStatus == 'INSTOCK'">In Stock</span>
                                                    <span *ngIf="item.stockStatus == 'OUTOFSTOCK'">Out Of Stock</span>
                                                    <span *ngIf="!item.stockStatus">N/A</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3"
                                *ngIf="page=='1'&& !loader && stockReportData.length==0">
                                <app-no-result-found></app-no-result-found>
                            </div>
                        </div>
                        <!-- stocks-list-table-row end -->
                    </div>
                </div>
                <div *ngIf="loading && stockReportData.length>10">
                    Loading...</div>
            </div>
        </div>
    </div>
</section>
<!-- displayFilterModalToggle start -->
<p-dialog [draggable]="false" [(visible)]="displayFilterModalToggle" [position]="position" [modal]="true"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false" [showHeader]="false"
    [style]="{width: '34vw', minHeight: '100%', maxHeight: '100%', margin: '0'}"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}">
    <div id="displayFilterModalToggle">
        <div class="modal-header">
            <h5 class="modal-title">Filter</h5>
            <i class="fas fa-times" (click)="closeFilterModalDialog()"></i>
        </div>
        <div class="body mt-3" [formGroup]="filterForm">
            <div class="row">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Category</label>
                    <select formControlName="mainCategory" class="form-select form-control"
                        aria-label="Default select example">
                        <option [ngValue]="null" selected>Choose Category</option>
                        <option *ngFor="let mainCategory of mainCategoryList" [ngValue]="mainCategory.id">
                            {{ mainCategory.catName }}
                        </option>
                    </select>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Brand</label>
                    <select formControlName="brand" class="form-select form-control"
                        aria-label="Default select example">
                        <option [ngValue]="null" selected>Choose Brand</option>
                        <option *ngFor="let brand of allBrands" [ngValue]="brand.id">
                            {{ brand.brandName }}
                        </option>
                    </select>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Status</label>
                    <select formControlName="stockStatus" class="form-select form-control"
                        aria-label="Default select example">
                        <option [ngValue]="null" selected>Choose Status</option>
                        <option *ngFor="let stockStatus of stockStatusList" [ngValue]="stockStatus">
                            {{ stockStatus }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="d-flex justify-content-end gap-2">
            <p-button label="Cancel" styleClass="filter-cancel-btn" (click)="cancelFilter()"></p-button>
            <p-button label="Apply" styleClass="filter-apply-btn" (click)="applyFilter()"></p-button>
        </div>
    </ng-template>
</p-dialog>
<!-- displayFilterModalToggle end -->