<section id="customerQuerySec">
    <div class="container-fluid">
        <div class="row mb-1 pt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card mb-1 heading-card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="d-flex justify-content-start align-items-center">
                                    <i class="fas fa-chevron-left" (click)="redirectToGeneralQueryListingPage()"></i>
                                    <h6>{{selectedGeneralQuery.subject}}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card customer-info-card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2">
                                <label>Customer Name</label>
                                <p *ngIf="selectedGeneralQuery?.userName">{{ selectedGeneralQuery?.userName }}
                                </p>
                                <p *ngIf="!selectedGeneralQuery?.userName">Anonymous</p>
                            </div>
                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2">
                                <label>Email</label>
                                <p>{{ selectedGeneralQuery?.email }}</p>
                            </div>
                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2">
                                <label>Company Name</label>
                                <p *ngIf="selectedGeneralQuery?.companyName">
                                    {{ selectedGeneralQuery?.companyName }}</p>
                                <p *ngIf="!selectedGeneralQuery?.companyName">N/A</p>
                            </div>
                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2">
                                <label>Phone</label>
                                <p *ngIf="selectedGeneralQuery?.phoneNumber">{{
                                    selectedGeneralQuery?.phoneNumber }}</p>
                                <p *ngIf="!selectedGeneralQuery?.phoneNumber">N/A</p>
                            </div>
                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2">
                                <label>Subject</label>
                                <p>{{ selectedGeneralQuery?.subject }}</p>
                            </div>
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                <label>Query</label>
                                <p>{{ selectedGeneralQuery?.message }}</p>
                            </div>
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2"
                                *ngIf="selectedGeneralQuery.attachments && selectedGeneralQuery.attachments.length>0">
                                <label>Attachments</label>
                                <div class="attachments-div">
                                    <div class="attachments-img"
                                        *ngFor="let attachment of selectedGeneralQuery.attachments">
                                        <img [src]="attachment" />
                                        <div class="view-icon">
                                            <i class="fas fa-eye" (click)="showImageViewModal(attachment)"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2"
                                *ngIf="isQueryOpen">
                                <label>Resolution <span class="mandatory">*</span></label>
                                <textarea class="form-control" rows="4"
                                    placeholder="Provide the resolution which was sent by email" [(ngModel)]="reply">
                                </textarea>
                            </div>
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2"
                                *ngIf="selectedGeneralQuery?.replyMessage">
                                <label>Resolution</label>
                                <p>{{ selectedGeneralQuery?.replyMessage }}</p>
                            </div>
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                *ngIf="isQueryOpen">
                                <div class="form-check ps-0">
                                    <input class="form-check-input" type="checkbox" id="Resolution"
                                        (change)="updateResolutionSent()" [checked]="replySent" />
                                    <label class="ps-4">Resolution already sent by email</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3 mb-3" *ngIf="isQueryOpen">
                <div class="d-flex justify-content-end gap-2">
                    <button type="button" class="btn reply-btn" (click)="cancel()">Cancel</button>
                    <button type="button" class="btn assigned-btn" [disabled]="!access['edit']"
                        (click)="sendQueryRes()">Save</button>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- end -->