import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { ApiService } from '@services/api/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import * as mutation from 'graphql/mutations';
import * as query from 'graphql/queries';
import { uploadData } from 'aws-amplify/storage';
import amplifyconfig from 'amplifyconfiguration.json';
import { v4 as uuidv4 } from 'uuid';
import * as _ from 'underscore';
import { CommonService } from '@services/common/common.service';
import { objectRequiredValidator } from '@/custom-validators';
import { ProductDeliveryStatus, ReturnActivityStatus, Status, UnitType, productStatus, stockStatus } from 'API';
import { formatDate } from '@angular/common';
import { Messages } from '@/Toaster-messages';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrl: './product-edit.component.scss'
})
export class ProductEditComponent implements OnInit, OnDestroy {
  defaults: any = {}
  defaultWidth: any = []
  defaultHeight: any = []
  defaultWeight: any = []
  defaultLength: any = []
  productObj: any
  visible: boolean = false;
  title: any
  public Editor = ClassicEditor;
  public onReady(editor: ClassicEditor) { }
  public onChange({ editor }: ChangeEvent) { }

  productForm: FormGroup;
  imageLibrary: any = []
  images: any = []
  wasSingleVariant: boolean
  productsByCategory: any = []
  searchText: any
  multipleVariDict = {};
  mainCategoryList: any = [];
  subCategoryList: any = [];
  brandList: any = [];
  countryList: any = [];
  keywordArray: any = [];
  attrValueArray: any = [];
  unitdetail: any;
  type: any
  valid: any = true
  unitSymbol: any;
  attrNameArray: any = [];
  productBaseImageList: any = [];
  productSubImageList: any = [];
  relatedImageList: any = [];
  unitList: any = [];
  baseUnitList: any = [];
  unitDict: any = {};
  unitMap: any = {};
  access: any = {}
  dataSheet: any = ''
  submittal: any = ''
  catalog: any = ''
  iom: any = ''
  variantValidationError: any
  currency: any
  productEdited: boolean = false
  pk: any
  searchKeyFormatted: any = ''

  stockStatusList: any = [
    {
      name: 'In Stock',
      value: 'INSTOCK'
    },
    {
      name: 'Out of Stock',
      value: 'OUTOFSTOCK'
    }
  ];
  productStatusList: any = [
    { name: "Draft", value: productStatus.draft },
    { name: "Published", value: productStatus.published },
    { name: "Scheduled", value: productStatus.scheduled },
    { name: "Inactive", value: productStatus.inactive }
  ];
  imageForm: FormGroup;
  proImageFormSubmitted: boolean = false;
  packageImageList: any = [];
  imgIndex: any;
  @ViewChild('productFileUpload') productFileUpload: ElementRef;

  imgGIndex: any;
  attributeValues: any = []
  priceOptionIndex: any;
  validationStatus: boolean = false;
  sumbitted: boolean = false;
  productID: string;
  fetchProductData: any;
  fetchVariantDetail: any;
  fetchAllProductImages: any;
  taxList: any = []
  wareHouseList: any = []
  stockUnitList: any = []
  lengthUnitsList: any = []
  massUnitsList: any = []

  toggleAccordionItem(selectedItem) {
    _.forEach(this.priceOptionItems.controls, (item) => {
      if (
        item.get('attrValueID').value == selectedItem.get('attrValueID').value
      ) {
        item.get('expanded').setValue(!item.get('expanded').value);
      } else {
        item.get('expanded').setValue(false);
      }
    });
  }

  constructor(
    private awsApi: ApiService,
    private router: Router,
    private fb: FormBuilder,
    private common: CommonService,
    private messageService: MessageService,
    private commonService: CommonService,
    private _activatedroute: ActivatedRoute
  ) {
    this.defaults = {
      defaultStockunit: null,
      defaultMainCategory: null,
      defaultSubCategory: null,
      defaultBrand: null,
      defaultCountry: null,
      defaultStockStatus: null,
      defaultWarehouse: null,
      defaultVariantName: null,
      defaultTaxName: null,
      defaultWidth: null,
      defaultHeight: null,
      defaultWeight: null,
      defaultLength: null
    };

    this.imageForm = this.fb.group({
      alterText: ['', [Validators.required]],
      title: [''],
      url: ['', [Validators.required]],
      description: '',
      type: ['', [Validators.required]]
    });

    this.productForm = this.fb.group({
      status: ['', [Validators.required]],
      scheduleDate: [],
      name: ['', [Validators.required, Validators.maxLength(500)]],
      mainCategory: [{}, objectRequiredValidator()],
      subCategory: [{}, objectRequiredValidator()],
      brand: [{}, objectRequiredValidator()],
      countryOfOrigin: ['', [Validators.required]],
      productDescription: ['', [Validators.required, Validators.maxLength(1500)]],
      showWhenZeroStock: [],
      allowPreOrder: [],
      leadTime: ['', [Validators.pattern('^\\d+$')]],
      featuredImage: [{}, objectRequiredValidator()],
      galleryImages: this.fb.array([]),
      specificationList: this.fb.array([]),
      specificationForm: this.fb.group({
        name: [''],
        value: ['']
      }),
      taxList: this.fb.array([]),
      taxForm: this.fb.group({
        name: [{}, objectRequiredValidator()],
        value: ['', [Validators.pattern(/^\d{1,2}(\.\d{0,2})?$/)]]
      }),
      productFeatures: [''],
      seoDescription: [''],
      keyWord: '',

      singleVariant: ['true', [Validators.required]],
      attrName: [{}, objectRequiredValidator()],
      attrValue: '',
      priceOptionItems: this.fb.array([]),

      singleProductDetail: this.fb.group({
        model: [''],
        manufacturePartCode: [''],
        sku: ['', [Validators.required, Validators.pattern(/^[a-zA-Z\d\-]+$/)]],
        stockStatus: ['INSTOCK', Validators.required],
        stockQuantity: ['', Validators.required],
        reservedStock: [''],
        stockUnit: [{}, objectRequiredValidator()],
        itemsPerUnit: [],
        regularPrice: ['', Validators.required],
        salePrice: ['', Validators.required],
        dataSheet: [''],
        submittal: [''],
        catalog: [''],
        iom: [''],
        storageInfo: this.fb.group({
          wareHouse: [{}, objectRequiredValidator()],
          rackNo: ['', [Validators.pattern(/^(?=.*\d)[a-zA-Z\d ]+$/)]],
          rowNo: ['', [Validators.pattern(/^(?=.*\d)[a-zA-Z\d ]+$/)]],
          binNo: ['', [Validators.pattern(/^(?=.*\d)[a-zA-Z\d ]+$/)]],
          note: ['']
        }),
        shipplingInfo: this.fb.group({
          weightValue: [''],
          weightUnit: [''],
          lengthValue: [''],
          lengthUnit: [''],
          heightValue: [''],
          heightUnit: [''],
          widthValue: [''],
          widthUnit: ['']
        })
      })
    });
  }

  get imageFormControl() {
    return this.imageForm.controls;
  }

  get priceOptionItems(): FormArray {
    return this.productForm.get('priceOptionItems') as FormArray;
  }

  get galleryImages(): FormArray {
    return this.productForm.get('galleryImages') as FormArray;
  }

  get singleProductDetail() {
    return this.productFormControl.singleProductDetail as FormGroup;
  }

  get storageInfoControls() {
    return this.singleProductDetail.get('storageInfo') as FormGroup;
  }

  addImage(index) {
    this.imageForm.patchValue({
      url: this.imageLibrary[index].url
    });
    this.imageLibrary = this.imageLibrary.map((item, imageIndex) =>
      ({ ...item, class: imageIndex === index ? 'primary active' : 'primary' }));
  }

  async getProductImage(filterObject: any = {}) {
    let items = [];
    let nextToken = null;
    do {
      const response = await this.awsApi.client.graphql({
        query: query.listProductImages,
        variables: {
          pk: 'PROIMG#',
          id: null,
          filter: filterObject,
          limit: 650,
          nextToken: nextToken,
          sortDirection: null
        }
      })
      items = items.concat(response.data.listProductImages.items);
      nextToken = response.data.listProductImages.nextToken;
    } while (nextToken !== null)
    return items
  }

  async loadImageLibrary() {
    this.awsApi.loading.next(true);
    try {
      this.images = []
      this.imageLibrary = []
      let selectedCategory = this.productForm.controls.mainCategory.value
      let filter = {
        mainCategoryID: { eq: selectedCategory.id },
        isDeleted: { ne: true }
      }
      let productImageResp = await this.getProductImage(filter)
      if (productImageResp.length > 0) {
        _.each(productImageResp, (productImage) => {
          if (productImage.url && !this.images.some((item) => item.url === productImage.url)) {
            this.images.push({
              'url': productImage.url,
              'productName': productImage.title,
              'class': this.imageForm.controls.url.value === productImage.url ? 'primary active' : 'primary'
            })
          }
        })
      }
      this.imageLibrary = this.images
    } catch (error) { }
    finally {
      this.awsApi.loading.next(false);
    }
  }

  searchImage() {
    this.searchImageLibrary()
  }

  async searchImageLibrary() {
    this.imageLibrary = []
    if (this.searchText) {
      _.each(this.images, (image) => {
        if (image.productName.toLowerCase().replace(/\s+/g, '').trim()
          .includes(this.searchText.toLowerCase().replace(/\s+/g, '').trim())) {
          this.imageLibrary.push(image)
        }
      })
    }
    else {
      await this.loadImageLibrary()
    }
  }

  async openUploadProImageModal(item: any, type: any, index: any = -1) {
    if (this.productForm.controls.mainCategory.status == 'INVALID') {
      this.imageLibrary = []
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please choose category before uploading images'
      });
    }
    else {
      await this.loadImageLibrary()
      if (type == 'featuredImg') {
        this.imageForm.reset();
        if (item.status == 'INVALID') {
          this.imageForm.patchValue({
            type: 'featuredImg',
            title: this.productFormControl.name.value
          });
        } else {
          this.imageForm.patchValue({
            type: item.value.type,
            title: this.productFormControl.name.value,
            url: item.value.url,
            alterText: item.value.alterText,
            description: item.value.description
          });
        }
      } else if (type == 'galleryImg' || type == 'featuredImgAttr') {
        this.imgGIndex = index;
        this.imageForm.reset();
        this.imageForm.patchValue({
          type: type,
          title: this.productFormControl.name.value,
          url: item.value.url,
          alterText: item.value.alterText,
          description: item.value.description
        });
      }
      this.proImageFormSubmitted = false;
      this.productFileUpload.nativeElement.value = '';
    }
  }

  clearImageUploadModal() {
    this.searchText = null
    this.imageForm.reset()
  }

  @ViewChild('closeImageUploadModal')
  private closeImageUploadModal: ElementRef;
  hideUploadProImageModel() {
    this.closeImageUploadModal.nativeElement.click();
  }

  saveProductImage() {
    this.proImageFormSubmitted = true;
    if (this.imageForm.valid) {
      if (!!!this.imageFormControl.title.value) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please add product name before choosing featured image.'
        });
        this.proImageFormSubmitted = false;
        return;
      }
      if (this.imageFormControl.type.value == 'featuredImg') {
        this.productFormControl.featuredImage.setValue(this.imageForm.value);
        this.proImageFormSubmitted = false;
        this.hideUploadProImageModel();
      } else if (this.imageFormControl.type.value == 'featuredImgAttr') {
        _.forEach(this.priceOptionItems.controls, (item, index) => {
          if (index == this.imgGIndex) {
            item.patchValue({ featuredImage: this.imageForm.value });
          }
        });
        this.proImageFormSubmitted = false;
        this.hideUploadProImageModel();
      } else if (this.imageFormControl.type.value == 'galleryImg') {
        _.forEach(this.galleryImages.controls, (item, index) => {
          if (index == this.imgGIndex) {
            item.patchValue(this.imageForm.value);
          }
        });

        this.proImageFormSubmitted = false;
        this.hideUploadProImageModel();
      }
    } else {
      this.proImageFormSubmitted = false;
    }
  }

  commonDictValue() {
    return {
      pk: 'PROD#' + this.productFormControl.subCategory.value.id,
      globalPk: 'PRODUCTS#',
      productName: this.productFormControl.name.value,
      scheduleDate: this.productFormControl.status.value == productStatus.scheduled ? new Date(this.productFormControl.scheduleDate.value).toISOString() : undefined,
      mainCategory: this.productFormControl.mainCategory.value.catName,
      mainCategoryID: this.productFormControl.mainCategory.value.id,
      subCategory: this.productFormControl.subCategory.value.catName,
      subCategoryID: this.productFormControl.subCategory.value.id,
      brandName: this.productFormControl.brand.value.brandName,
      brandID: this.productFormControl.brand.value.id,
      countryName: this.productFormControl.countryOfOrigin.value.name,
      countryID: this.productFormControl.countryOfOrigin.value.id,
      featuredImage: this.productFormControl.featuredImage.value
    };
  }

  updateProductVariantDB(obj) {
    return this.awsApi.client.graphql({
      query: mutation.updateProductDetails,
      variables: {
        input: {
          ...obj
        }
      }
    });
  }

  deleteProductVariantDB(obj) {
    return this.awsApi.client.graphql({
      query: mutation.deleteProductDetails,
      variables: {
        input: {
          ...obj
        }
      }
    });
  }

  createProductVariantDB(obj) {
    return this.awsApi.client.graphql({
      query: mutation.createProductDetails,
      variables: {
        input: {
          ...obj
        }
      }
    });
  }

  createProductDB(obj) {
    return this.awsApi.client.graphql({
      query: mutation.createProduct,
      variables: {
        input: {
          ...obj
        }
      }
    });
  }


  updateProductDB(obj) {
    return this.awsApi.client.graphql({
      query: mutation.updateProduct,
      variables: {
        input: {
          ...obj
        }
      }
    });
  }

  async deleteProduct(data) {
    return this.awsApi.client
      .graphql({
        query: mutation.deleteProduct,
        variables: {
          input: {
            ...data
          }
        }
      })
  }

  prefetchProductDetail() {
    let mainCategory = _.find(this.mainCategoryList, (category) => {
      return category.id == this.fetchProductData['mainCategoryID'];
    });
    let subCategory = _.find(this.subCategoryList, (category) => {
      return category.id == this.fetchProductData['subCategoryID'];
    });
    let brand = _.find(this.brandList, (brand) => {
      return brand.id == this.fetchProductData['brandID'];
    });
    let countryOfOrigin = _.find(this.countryList, (country) => {
      return country.id == this.fetchProductData['countryID'];
    });
    this.fetchProductData['productSpecification']?.forEach((item) => {
      this.addedSpecList.push(
        this.fb.group({
          name: item.name,
          value: item.value
        })
      );
    });
    this.fetchProductData['taxDetail']?.forEach((item) => {
      this.searchKeyFormatted += item.taxID
      this.addedTaxList.push(
        this.fb.group({
          taxName: item.taxName,
          taxID: item.taxID,
          taxCharge: item.taxCharge
        })
      );
    });
    this.keywordArray = this.fetchProductData['keyWord'];
    if (this.fetchProductData['featuredImage']) {
      delete this.fetchProductData['featuredImage'].__typename;
    }
    const formattedDate = this.fetchProductData.scheduleDate ? formatDate(this.fetchProductData.scheduleDate, 'yyyy-MM-dd', 'en-US') : null;
    this.productForm.patchValue({
      status: this.fetchProductData['status'] ? this.fetchProductData['status'] : productStatus.draft,
      showWhenZeroStock: this.fetchProductData['showWhenZeroStock'] ? this.fetchProductData['showWhenZeroStock'] : false,
      allowPreOrder: this.fetchProductData['allowPreOrder'] ? this.fetchProductData['allowPreOrder'] : false,
      leadTime: this.fetchProductData['leadTime'] && this.fetchProductData['allowPreOrder'] ? parseInt(this.fetchProductData['leadTime']) : '',
      name: this.fetchProductData['productName'],
      scheduleDate: formattedDate,
      mainCategory: mainCategory,
      subCategory: subCategory,
      brand: brand,
      countryOfOrigin: countryOfOrigin,
      featuredImage: this.fetchProductData['featuredImage'] ? this.fetchProductData['featuredImage'] : {},
      productDescription: this.fetchProductData['productDescription'],
      productFeatures: this.fetchProductData['productFeatures'],
      seoDescription: this.fetchProductData['seoDescription'] ? this.fetchProductData['seoDescription'] : '',
      keyWord: '',
      productSpecification: this.fetchProductData['productSpecification'] ? this.fetchProductData['productSpecification'] : ''
    });

    if (this.fetchProductData['galleryImages'] && this.fetchProductData['galleryImages'].length > 0) {
      this.fetchProductData['galleryImages'].forEach((item, index) => {
        this.galleryImages.at(index).patchValue({
          alterText: item.alterText,
          title: item.title ? item.title : '',
          url: item.url,
          description: item.description ? item.description : '',
          type: item.type
        });
      });
    }
    if (this.fetchProductData['singleVariant'] == true) {
      let storageInfo = this.fetchVariantDetail[0]['storageInfo'];
      delete storageInfo.__typename;
      let wareHouse = _.find(this.wareHouseList, (item) => {
        return item.id == storageInfo.wareHouseId;
      });
      delete storageInfo.wareHouseId;
      storageInfo['wareHouse'] = wareHouse;
      let shipplingInfo
      if (this.fetchVariantDetail[0]['shipplingInfo']) {
        shipplingInfo = this.fetchVariantDetail[0]['shipplingInfo'];
        delete shipplingInfo.__typename;
      }
      shipplingInfo['widthUnit'] = _.find(this.lengthUnitsList, (item) => {
        return item.id == shipplingInfo.widthUnit;
      });
      shipplingInfo['heightUnit'] = _.find(this.lengthUnitsList, (item) => {
        return item.id == shipplingInfo.heightUnit;
      });
      shipplingInfo['lengthUnit'] = _.find(this.lengthUnitsList, (item) => {
        return item.id == shipplingInfo.lengthUnit;
      });
      shipplingInfo['weightUnit'] = _.find(this.massUnitsList, (item) => {
        return item.id == shipplingInfo.weightUnit;
      });

      let stockUnit = _.find(this.stockUnitList, (item) => {
        return item.value == this.fetchVariantDetail[0]['stockUnit'];
      });

      this.productForm.patchValue({
        singleVariant: 'true',
        singleProductDetail: {
          model: this.fetchVariantDetail[0]['model'] ? this.fetchVariantDetail[0]['model'] : '',
          manufacturePartCode:
            this.fetchVariantDetail[0]['manufacturePartCode'] ? this.fetchVariantDetail[0]['manufacturePartCode'] : '',
          sku: this.fetchVariantDetail[0]['sku'],
          stockStatus: this.fetchVariantDetail[0]['stockStatus'] ? this.fetchVariantDetail[0]['stockStatus'] : stockStatus.INSTOCK,
          stockQuantity: this.fetchVariantDetail[0]['stockQuantity'],
          reservedStock: this.fetchVariantDetail[0]['reservedStock'] ? this.fetchVariantDetail[0]['reservedStock'] : parseFloat('0'),
          stockUnit: stockUnit,
          itemsPerUnit: this.fetchVariantDetail[0]['itemsPerUnit'] ? this.fetchVariantDetail[0]['itemsPerUnit'] : null,
          regularPrice: this.fetchVariantDetail[0]['regularPrice'],
          salePrice: this.fetchVariantDetail[0]['salePrice'] ? parseFloat(this.fetchVariantDetail[0]['salePrice']) : parseFloat(this.fetchVariantDetail[0]['regularPrice']),
          dataSheet: this.fetchVariantDetail[0]['dataSheet']
            ? this.fetchVariantDetail[0]['dataSheet']
            : '',
          submittal: this.fetchVariantDetail[0]['submittal']
            ? this.fetchVariantDetail[0]['submittal']
            : '',
          catalog: this.fetchVariantDetail[0]['catalog']
            ? this.fetchVariantDetail[0]['catalog']
            : '',
          iom: this.fetchVariantDetail[0]['iom']
            ? this.fetchVariantDetail[0]['iom']
            : '',
          storageInfo: storageInfo,
          shipplingInfo: shipplingInfo
        }
      });
    } else {
      this.fetchVariantDetail.forEach((item) => {
        this.multipleVariDict[item['attrValueID']] = item;
        if (item.dataSheet != '' && this.dataSheet == '') {
          this.dataSheet = item.dataSheet
        }
        if (item.submittal != '' && this.submittal == '') {
          this.submittal = item.submittal
        }
        if (item.catalog != '' && this.catalog == '') {
          this.catalog = item.catalog
        }
        if (item.iom != '' && this.iom == '') {
          this.iom = item.iom
        }
      });
      let attrName = _.find(this.attrNameArray, (attr) => {
        return attr.id == this.fetchProductData['attrName'];
      });
      this.productForm.get('attrName').setValue(attrName);
      this.attrValueArray = this.fetchProductData['attrValue'].map((item) => {
        delete item.__typename;
        this.addPriceOptionItem(item);
        return item;
      });
      this.priceOptionItems.controls.map((item, index) => {
        if (this.multipleVariDict[item.get('attrValueID').value]) {
          let fetchedValue = this.multipleVariDict[item.get('attrValueID').value];
          let storageInfo = fetchedValue['storageInfo'];
          delete storageInfo.__typename;
          let wareHouse = _.find(this.wareHouseList, (item) => {
            return item.id == storageInfo.wareHouseId;
          });
          delete storageInfo.wareHouseId;
          storageInfo['wareHouse'] = wareHouse;
          let shipplingInfo
          if (fetchedValue['shipplingInfo']) {
            shipplingInfo = fetchedValue['shipplingInfo'];
            delete shipplingInfo.__typename;
          }
          shipplingInfo['widthUnit'] = _.find(this.lengthUnitsList, (item) => {
            return item.id == shipplingInfo.widthUnit;
          });
          shipplingInfo['heightUnit'] = _.find(this.lengthUnitsList, (item) => {
            return item.id == shipplingInfo.heightUnit;
          });
          shipplingInfo['lengthUnit'] = _.find(this.lengthUnitsList, (item) => {
            return item.id == shipplingInfo.lengthUnit;
          });
          shipplingInfo['weightUnit'] = _.find(this.massUnitsList, (item) => {
            return item.id == shipplingInfo.weightUnit;
          });
          this.defaultWidth[index] = shipplingInfo['widthUnit']
          this.defaultHeight[index] = shipplingInfo['heightUnit']
          this.defaultLength[index] = shipplingInfo['lengthUnit']
          this.defaultWeight[index] = shipplingInfo['weightUnit']
          let stockUnit = _.find(this.stockUnitList, (stockUnt) => {
            return stockUnt.value == fetchedValue['stockUnit'];
          });
          let featuredImage
          if (fetchedValue['featuredImage']) {
            featuredImage = fetchedValue['featuredImage'];
            delete featuredImage.__typename;
          }
          item.patchValue({
            model: fetchedValue['model'] ? fetchedValue['model'] : '',
            manufacturePartCode: fetchedValue['manufacturePartCode'] ? fetchedValue['manufacturePartCode'] : '',
            sku: fetchedValue['sku'],
            stockStatus: fetchedValue['stockStatus'] ? fetchedValue['stockStatus'] : stockStatus.INSTOCK,
            stockQuantity: fetchedValue['stockQuantity'],
            reservedStock: fetchedValue['reservedStock'] ? fetchedValue['reservedStock'] : parseFloat('0'),
            stockUnit: stockUnit,
            itemsPerUnit: fetchedValue['itemsPerUnit'] ? fetchedValue['itemsPerUnit'] : null,
            regularPrice: fetchedValue['regularPrice'],
            salePrice: fetchedValue['salePrice'] ? parseFloat(fetchedValue['salePrice']) : parseFloat(fetchedValue['regularPrice']),
            dataSheet: fetchedValue['dataSheet']
              ? fetchedValue['dataSheet']
              : '',
            submittal: fetchedValue['submittal']
              ? fetchedValue['submittal']
              : '',
            catalog: fetchedValue['catalog'] ? fetchedValue['catalog'] : '',
            iom: fetchedValue['iom'] ? fetchedValue['iom'] : '',
            storageInfo: storageInfo,
            shipplingInfo: shipplingInfo,
            featuredImage: featuredImage,
            id: fetchedValue['id']
          });
        }
      });
      this.productForm.patchValue({
        singleVariant: 'false',
        attrValue: ''
      });
    }
  }

  async displayError() {
    this.productEdited = false
    this.awsApi.loading.next(false);
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: this.variantValidationError
    });
  }

  async isAnyOrderPending(validationFor, attrValueId = null) {
    this.valid = true;
    if (this.type === 'edit') {
      if (validationFor == 'variantNameChange' || validationFor == 'variantChange') {
        this.variantValidationError = Messages.UNABLE_TO_CHANGE_VARIANT
      }
      else if (validationFor === 'variantValueChange') {
        this.variantValidationError = Messages.UNABLE_TO_CHANGE_VARIANT_VALUE
      }
      else {
        this.variantValidationError = Messages.UNABLE_TO_REMOVE_VARIANT
      }

      let filter = { productId: { eq: this.productID } };
      if (validationFor === 'variantDelete' || validationFor === 'variantValueChange') {
        filter['attrValueID'] = { eq: attrValueId };
      }
      if ((validationFor === 'variantChange' &&
        ((this.productFormControl.singleVariant.value === 'true' && !this.wasSingleVariant) ||
          (this.productFormControl.singleVariant.value === 'false' && this.wasSingleVariant))) ||
        validationFor === 'variantValueChange' || validationFor === 'variantDelete') {
        await this.setValid(filter)
        if (!this.valid && validationFor === 'variantChange') {
          this.productForm.patchValue({
            singleVariant: this.wasSingleVariant ? 'true' : 'false'
          })
        }
      }
      if (validationFor === 'variantNameChange') {
        const promises = this.priceOptionItems.controls.map(async (item) => {
          let attrId = item.get('attrValueID').value;

          let filterWithAttrId = { ...filter, attrValueID: { eq: attrId } };
          await this.setValid(filterWithAttrId);
        });
        await Promise.all(promises);
      }
    }
    if (!this.valid) {
      this.displayError();
    }
  }

  async setValid(filter): Promise<any> {
    if (this.valid) {
      let orderItems = await this.common.getOrderItems(filter);
      for (let orderItem of orderItems) {
        let orderStatus = orderItem.deliveryStatus;
        if (orderStatus === ProductDeliveryStatus.Delivered && orderItem.returnRefundActivity) {
          let returnStatus = orderItem.returnRefundActivity.status;
          if (!(returnStatus == ReturnActivityStatus.Cancelled ||
            returnStatus == ReturnActivityStatus.Received ||
            returnStatus == ReturnActivityStatus.RefundApproved ||
            returnStatus == ReturnActivityStatus.RefundRejected)) {
            this.valid = false;
            break;
          }
        } else if (!(orderStatus == ProductDeliveryStatus.Delivered || orderStatus == ProductDeliveryStatus.Cancelled)) {
          this.valid = false;
          break;
        }
      }
    }
  }

  async validateSku(skuValue: any, idValue: any = null, index: any = null) {
    let filter = {
      'sku': { eq: skuValue }
    }
    filter['isDeleted'] = { ne: true }
    if (this.type == 'edit') {
      filter['productID'] = { ne: this.productID }
    }
    if (this.type == 'edit' && idValue) {
      filter['id'] = { ne: idValue }
    }
    let response = await this.common.getProductDetailsWithEditQuery(filter, ['pk', 'id'])
    if (response.length != 0 ||
      (this.productFormControl.singleVariant.value == 'false' && !this.wasSingleVariant ||
        this.productFormControl.singleVariant.value == 'true' && this.wasSingleVariant) &&
      (this.priceOptionItems.controls.find((item, i) => item.value['sku'] == skuValue && i != index))) {
      this.productEdited = false
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'SKU - ' + skuValue + ' is already assigned to another product. Please use a different SKU.'
      });
      return false;
    }
    return true;
  }

  async validateSalePrice(item): Promise<boolean> {
    if (item.value['regularPrice'] < item.value['salePrice']) {
      this.productEdited = false
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Invalid sale price'
      });
      return false;
    } else { return true; }
  }

  async validateShipping(item): Promise<boolean> {
    let shippingInput = item.value['shipplingInfo']
    let weightValue = (shippingInput.weightValue == '' || shippingInput.weightValue == null)
    let weightUnit = (shippingInput.weightUnit == '' || shippingInput.weightUnit == null)
    let lengthValue = (shippingInput.lengthValue == '' || shippingInput.lengthValue == null)
    let lengthUnit = (shippingInput.lengthUnit == '' || shippingInput.lengthUnit == null)
    let heightValue = (shippingInput.heightValue == '' || shippingInput.heightValue == null)
    let heightUnit = (shippingInput.heightUnit == '' || shippingInput.heightUnit == null)
    let widthValue = (shippingInput.widthValue == '' || shippingInput.widthValue == null)
    let widthUnit = (shippingInput.widthUnit == '' || shippingInput.widthUnit == null)
    if ((weightValue && !weightUnit) || (!weightValue && weightUnit) ||
      (lengthValue && !lengthUnit) || (!lengthValue && lengthUnit) ||
      (heightValue && !heightUnit) || (!heightValue && heightUnit) ||
      (widthValue && !widthUnit) || (!widthValue && widthUnit)) {
      this.productEdited = false
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: this.productFormControl.singleVariant.value == 'true' ? Messages.MISSING_SINGLE_VARIANT_DETAILS : Messages.PLEASE_FILL_REQUIRED_FIELDS_VARIANT
      });
      return false;
    } else { return true; }
  }

  async validateStockStatus(item) {
    const reservedStock = this.type === 'copy' ? parseFloat('0') : item.value['reservedStock']
    const availableStock = parseFloat(item.value['stockQuantity']) - parseFloat(reservedStock);
    const stockStatus = item.value['stockStatus'];

    if (availableStock <= 0 && stockStatus === 'INSTOCK') {
      this.productEdited = false
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.INVALID_STOCK_STATUS
      });
      return false;
    } else if (availableStock > 0 && stockStatus === 'OUTOFSTOCK') {
      this.productEdited = false
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.INVALID_STOCK_STATUS
      });
      return false;
    } else {
      return true;
    }
  }
  async validateScheduleDate() {
    if (this.productFormControl.status.value == productStatus.scheduled &&
      this.productFormControl.scheduleDate.value == null) {
      this.productEdited = false
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.MISSING_SCHEDULE_DATE
      })
      return false;
    } else { return true; }
  }

  async validateLeadTime(productObject: any) {
    if (productObject['allowPreOrder'] == true && (productObject['leadTime'] == '' || productObject['leadTime'] == null)) {
      this.productEdited = false
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.MISSING_LEADTIME
      });
      return false;
    } else { return true; }
  }

  async editProduct() {
    let deleteProductFilter = {
      pk: this.fetchProductData['pk'],
      id: this.fetchProductData['id']
    }
    let delResponse = await this.deleteProduct({ ...deleteProductFilter })
    if (delResponse) {
      let createResponse = await this.createProductDB(this.productObj)
      if (createResponse) { return; }
      else {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      }
    } else {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  async checkValidationProductVarient() {
    if (!this.productEdited) {
      this.awsApi.loading.next(true);
      let valid: any
      let promiseSaveArray: any = [];
      if (this.type == 'copy') {
        this.productID = uuidv4()
        this.pk = 'PROD#' + this.productFormControl.subCategory.value.id
      }
      this.productObj = {
        pk: 'PROD#' + this.productFormControl.subCategory.value.id,
        id: this.productID
      };
      this.productObj = { ...this.productObj, ...this.commonDictValue() };
      let imageDBCommonObj: any = {
        mainCategory: this.productFormControl.mainCategory.value.catName,
        mainCategoryID: this.productFormControl.mainCategory.value.id,
        subCategory: this.productFormControl.subCategory.value.catName,
        subCategoryID: this.productFormControl.subCategory.value.id,
        productID: this.productID,
        pk: 'PROIMG#',
        thumbnailUrl: undefined
      };
      this.productObj['productDescription'] =
        this.productFormControl.productDescription.value;
      this.productObj['productFeatures'] =
        this.productFormControl.productFeatures.value;
      this.productObj['seoDescription'] = this.productFormControl.seoDescription.value;
      this.productObj['keyWord'] = this.keywordArray;
      this.productObj['productSpecification'] = this.addedSpecList.value;
      this.productObj['taxDetail'] = this.addedTaxList.value;
      this.productObj['status'] = this.productFormControl.status.value;
      this.productObj['showWhenZeroStock'] = this.productFormControl.showWhenZeroStock.value;
      this.productObj['allowPreOrder'] = this.productFormControl.allowPreOrder.value;
      this.productObj['leadTime'] = this.productFormControl.leadTime.value;
      let productNameFormatted = this.productFormControl.name.value.toLowerCase()
      productNameFormatted = productNameFormatted.replace(/\s{2,}/g, ' ');
      this.searchKeyFormatted += (productNameFormatted.trim()).replace(/\s+/g, '')

      let validGalleryImages = [];
      this.fetchAllProductImages.forEach((item) => {
        promiseSaveArray.push(this.deleteProductImageDB(item.pk, item.id));
      });
      this.galleryImages.controls.forEach((item) => {
        if (item.valid) {
          validGalleryImages.push(item.value);
          promiseSaveArray.push(
            this.createProductImageDB({
              ...imageDBCommonObj,
              ...item.value,
              type: 'galleryImg'
            })
          );
        }
      });
      this.productObj['galleryImages'] = validGalleryImages;
      promiseSaveArray.push(
        this.createProductImageDB({
          ...imageDBCommonObj,
          ...this.productFormControl.featuredImage.value,
          type: 'featuredImg'
        })
      );
      this.productObj['id'] = this.productID;
      this.productObj['createdAt'] = this.fetchProductData['createdAt'];
      if (this.productFormControl.singleVariant.value == 'true' && this.wasSingleVariant) {
        valid = true
        try {
          // save only single variant
          if (this.singleProductDetail.valid) {
            this.singleProductDetail.value['stockUnit'] = this.singleProductDetail.value['stockUnit'].value
            if (valid && !await this.validateSalePrice(this.singleProductDetail)) { valid = false }
            if (valid && !await this.validateStockStatus(this.singleProductDetail)) { valid = false }
            if (valid && !await this.validateSku(this.singleProductDetail.value['sku'], this.fetchVariantDetail[0]['id'])) {
              valid = false
            }
            if (valid && !await this.validateShipping(this.singleProductDetail)) { valid = false }
          } else {
            this.productEdited = false
            this.awsApi.loading.next(false);
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: Messages.MISSING_SINGLE_VARIANT_DETAILS
            });
          }
        }
        catch (error) { }
        if (this.singleProductDetail.valid && valid && await this.validateLeadTime(this.productObj)) {
          if (await this.validateScheduleDate()) {
            this.searchKeyFormatted += '#' + (this.singleProductDetail.value['sku']).toLowerCase().trim().replace(/\s+/g, '')
            this.productObj['singleVariant'] = true;
            this.productObj['stockStatus'] = this.singleProductDetail.value['stockStatus'];
            this.productObj['leadTime'] = this.productObj['allowPreOrder'] === false ? undefined : this.productObj['leadTime']

            let modStorageInfo = this.singleProductDetail.value['storageInfo'];
            modStorageInfo['wareHouseId'] = modStorageInfo['wareHouse'].id
            modStorageInfo['wareHouse'] = modStorageInfo['wareHouse'].name
            delete this.singleProductDetail.value['storageInfo'];
            this.singleProductDetail.value['storageInfo'] = modStorageInfo

            let shipplingInfo = this.singleProductDetail.value['shipplingInfo']
            shipplingInfo['heightUnit'] = this.singleProductDetail.value['shipplingInfo']?.heightUnit?.id
            shipplingInfo['lengthUnit'] = this.singleProductDetail.value['shipplingInfo']?.lengthUnit?.id
            shipplingInfo['weightUnit'] = this.singleProductDetail.value['shipplingInfo']?.weightUnit?.id
            shipplingInfo['widthUnit'] = this.singleProductDetail.value['shipplingInfo']?.widthUnit?.id
            this.singleProductDetail.value['shipplingInfo'] = shipplingInfo
            this.searchKeyFormatted += '#' + modStorageInfo['wareHouseId'] + '#' + shipplingInfo['heightUnit'] + '#' + shipplingInfo['lengthUnit'] + '#' + shipplingInfo['weightUnit'] + '#' + shipplingInfo['widthUnit']

            this.productObj['totalStockQuantity'] = this.singleProductDetail.value['stockQuantity']
            this.productObj['totalReservedStock'] = this.singleProductDetail.value['reservedStock']
            this.productObj['searchKey'] = this.searchKeyFormatted

            if (this.type == 'edit') {
              this.productObj['createdAt'] = this.fetchProductData['createdAt']
              if (this.fetchProductData['pk'] != this.productObj['pk']) {
                await this.editProduct()
              }
              else {
                promiseSaveArray.push(this.updateProductDB(this.productObj));
              }
              promiseSaveArray.push(
                this.updateProductVariantDB({
                  ...this.singleProductDetail.value,
                  productID: this.productID,
                  type: 'single',
                  pk: 'PRODETAIL#',
                  id: this.fetchVariantDetail[0]['id']
                })
              );
            }
            if (this.type == 'copy') {
              this.singleProductDetail.value['reservedStock'] = parseFloat('0')
              this.productObj['totalReservedStock'] = parseFloat('0')
              this.productObj['createdAt'] = new Date().toISOString()
              promiseSaveArray.push(this.createProductDB(this.productObj));
              promiseSaveArray.push(
                this.createProductVariantDB({
                  ...this.singleProductDetail.value,
                  productID: this.productID,
                  type: 'single',
                  pk: 'PRODETAIL#',
                  id: uuidv4()
                })
              );
            }
            Promise.all(promiseSaveArray)
              .then((result) => {
                this.productEdited = true
                this.awsApi.loading.next(false);
                if (this.type == 'edit') {
                  this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Product is updated successfully'
                  });
                }
                if (this.type == 'copy') {
                  this.productEdited = true
                  this.awsApi.loading.next(false);
                  this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Product is added successfully'
                  });
                }
                this.router.navigate(['/list-product']);
              }).catch((error) => {
                this.productEdited = false
                this.awsApi.loading.next(false);
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: Messages.SOMETHING_WENT_WRONG
                });
              });
          }
        }
      }

      if (this.productFormControl.singleVariant.value == 'true' && !this.wasSingleVariant) {
        if (this.valid) {
          valid = true
          try {
            // save only single variant
            if (this.singleProductDetail.valid) {
              this.singleProductDetail.value['stockUnit'] = this.singleProductDetail.value['stockUnit'].value
              if (valid && !await this.validateSalePrice(this.singleProductDetail)) { valid = false }
              if (valid && !await this.validateStockStatus(this.singleProductDetail)) { valid = false }
              if (valid &&
                !await this.validateSku(this.singleProductDetail.value['sku'], this.fetchVariantDetail[0]['id'], null)) {
                valid = false
              }
              if (valid && !await this.validateShipping(this.singleProductDetail)) { valid = false }
            } else {
              this.productEdited = false
              this.awsApi.loading.next(false);
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: Messages.MISSING_SINGLE_VARIANT_DETAILS
              });
            }
          }
          catch (error) { }
          if (this.singleProductDetail.valid && valid && await this.validateLeadTime(this.productObj)) {
            if (await this.validateScheduleDate()) {
              try {
                this.searchKeyFormatted += '#' + (this.singleProductDetail.value['sku']).toLowerCase().trim().replace(/\s+/g, '')
                this.singleProductDetail.value['reservedStock'] = parseFloat('0')
                this.productObj['singleVariant'] = true;
                this.productObj['attrName'] = null;
                this.productObj['attrValue'] = [];
                this.productObj['stockStatus'] = this.singleProductDetail.value['stockStatus'];

                this.productObj['leadTime'] = this.productObj['allowPreOrder'] === false ? null : this.productObj['leadTime']
                this.productObj['totalStockQuantity'] = this.singleProductDetail.value['stockQuantity']
                this.productObj['totalReservedStock'] = this.singleProductDetail.value['reservedStock']
                this.productObj['searchKey'] = this.searchKeyFormatted

                if (this.type == 'edit') {
                  this.productObj['createdAt'] = this.fetchProductData['createdAt']
                  if (this.fetchProductData['pk'] != this.productObj['pk']) {
                    await this.editProduct()
                  }
                  else {
                    promiseSaveArray.push(this.updateProductDB(this.productObj));
                  }
                }
                if (this.type == 'copy') {
                  this.productObj['totalReservedStock'] = parseFloat('0')
                  this.productObj['createdAt'] = new Date().toISOString()
                  promiseSaveArray.push(this.createProductDB(this.productObj));
                }
                let modStorageInfo = this.singleProductDetail.value['storageInfo'];
                modStorageInfo['wareHouseId'] = modStorageInfo['wareHouse'].id;
                modStorageInfo['wareHouse'] = modStorageInfo['wareHouse'].name;
                delete this.singleProductDetail.value['storageInfo'];
                this.singleProductDetail.value['storageInfo'] = modStorageInfo

                let shipplingInfo = this.singleProductDetail.value['shipplingInfo']
                shipplingInfo['heightUnit'] = this.singleProductDetail.value['shipplingInfo']?.heightUnit?.id
                shipplingInfo['lengthUnit'] = this.singleProductDetail.value['shipplingInfo']?.lengthUnit?.id
                shipplingInfo['weightUnit'] = this.singleProductDetail.value['shipplingInfo']?.weightUnit?.id
                shipplingInfo['widthUnit'] = this.singleProductDetail.value['shipplingInfo']?.widthUnit?.id
                this.singleProductDetail.value['shipplingInfo'] = shipplingInfo
                this.searchKeyFormatted += '#' + modStorageInfo['wareHouseId'] + '#' + shipplingInfo['heightUnit'] + '#' + shipplingInfo['lengthUnit'] + '#' + shipplingInfo['weightUnit'] + '#' + shipplingInfo['widthUnit']

                promiseSaveArray.push(
                  this.createProductVariantDB({
                    ...this.singleProductDetail.value,
                    productID: this.productID,
                    type: 'single',
                    pk: 'PRODETAIL#',
                    id: uuidv4()
                  })
                );
                Promise.all(promiseSaveArray)
                  .then(async (result) => {
                    if (this.type == 'edit') {
                      //delete the product details which already exists and create a product detail with single variant details
                      for (let i = 0; i < this.priceOptionItems.controls.length; i++) {
                        let item = this.priceOptionItems.controls[i]
                        delete item.value['expanded'];
                        await this.deleteProductVariantDB({
                          pk: 'PRODETAIL#',
                          id: item.value['id']
                        })
                      }
                    }
                    this.productEdited = true
                    this.awsApi.loading.next(false);
                    if (this.type == 'edit') {
                      this.messageService.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Product is updated successfully'
                      });
                    }
                    if (this.type == 'copy') {
                      this.productEdited = true
                      this.awsApi.loading.next(false);
                      this.messageService.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Product is added successfully'
                      });
                    }
                    this.router.navigate(['/list-product']);
                  })
                  .catch((error) => {
                    this.productEdited = false
                    this.awsApi.loading.next(false);
                    this.messageService.add({
                      severity: 'error',
                      summary: 'Error',
                      detail: Messages.SOMETHING_WENT_WRONG
                    });
                  });
              } catch (error) { }
            }
          }
        }
        else {
          this.displayError()
        }
      }

      if (this.productFormControl.singleVariant.value == 'false' && !this.wasSingleVariant) {
        if (this.priceOptionItems.length > 0) {
          this.valid = true
          if (this.priceOptionItems.at(0).get('attrNameID').value != this.productFormControl.attrName.value.id) {
            await this.isAnyOrderPending('variantNameChange')
          }
          if (this.valid) {
            this.productObj['attrName'] = this.productFormControl.attrName.value.id;
            this.productObj['attrValue'] = this.attrValueArray;
            this.productObj['singleVariant'] = false;
            try {
              // save only multiple variant
              if (this.priceOptionItems.valid) {
                valid = true
                for (let i = 0; i < this.priceOptionItems.controls.length; i++) {
                  let item = this.priceOptionItems.controls[i]
                  delete item.value['expanded'];
                  if (this.multipleVariDict[item.get('attrValueID').value] && this.multipleVariDict[item.get('attrValueID')?.value]['attrValue'] != item.value['attrValue']) {
                    await this.isAnyOrderPending('variantValueChange', this.multipleVariDict[item.get('attrValueID')?.value]['attrValueID'])
                    if (!this.valid) {
                      this.priceOptionItems.at(i).patchValue({
                        attrValue: this.multipleVariDict[item.get('attrValueID')?.value]['attrValue']
                      })
                      this.productObj['attrValue'][i].value = this.multipleVariDict[item.get('attrValueID')?.value]['attrValue']
                      valid = false
                    }
                    else {
                      this.productObj['attrValue'][i].value = item.value['attrValue']
                    }
                  }
                  if (valid) {
                    this.priceOptionItems.controls[i].value['stockUnit'] = item.value['stockUnit'].value
                    if (valid && !await this.validateSalePrice(item)) { valid = false }
                    if (valid && !await this.validateStockStatus(item)) { valid = false }
                    if (valid && !await this.validateSku(item.value['sku'], item.value['id'], i)) { valid = false }
                    if (valid && !await this.validateShipping(item)) { valid = false }
                  }
                }
              }
              else {
                this.productEdited = false
                this.awsApi.loading.next(false);
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: Messages.PLEASE_FILL_REQUIRED_FIELDS_VARIANT
                });
              }
            } catch (error) { }
            if (this.priceOptionItems.valid && valid && await this.validateLeadTime(this.productObj)) {
              if (await this.validateScheduleDate()) {
                try {
                  this.productObj['stockStatus'] = this.priceOptionItems.controls.filter(item => item.value['stockStatus'] == 'INSTOCK').length == 0 ?
                    'OUTOFSTOCK' : 'INSTOCK'
                  this.productObj['leadTime'] = this.productObj['allowPreOrder'] === false ? undefined : this.productObj['leadTime']
                  let totalStock = 0;
                  let totalReserved = 0;
                  for (let i = 0; i < this.priceOptionItems.controls.length; i++) {
                    let item = this.priceOptionItems.controls[i]
                    this.searchKeyFormatted += '#' + (item.value['sku']).toLowerCase().trim().replace(/\s+/g, '')
                    totalStock += parseFloat(item.value['stockQuantity'])
                    totalReserved += parseFloat(item.value['reservedStock'])
                    item.value['attrName'] = this.productFormControl.attrName.value.attrName
                    item.value['attrNameID'] = this.productFormControl.attrName.value.id
                    let modStorageInfo = item.value['storageInfo'];
                    modStorageInfo['wareHouseId'] = modStorageInfo['wareHouse'].id;
                    modStorageInfo['wareHouse'] = modStorageInfo['wareHouse'].name;
                    delete item.value['storageInfo']
                    item.value['storageInfo'] = modStorageInfo

                    let shipplingInfo = item.value['shipplingInfo']
                    shipplingInfo['heightUnit'] = item.value['shipplingInfo']?.heightUnit?.id
                    shipplingInfo['lengthUnit'] = item.value['shipplingInfo']?.lengthUnit?.id
                    shipplingInfo['weightUnit'] = item.value['shipplingInfo']?.weightUnit?.id
                    shipplingInfo['widthUnit'] = item.value['shipplingInfo']?.widthUnit?.id
                    item.value['shipplingInfo'] = shipplingInfo
                    this.searchKeyFormatted += '#' + modStorageInfo['wareHouseId'] + '#' + shipplingInfo['heightUnit'] + '#' + shipplingInfo['lengthUnit'] + '#' + shipplingInfo['weightUnit'] + '#' + shipplingInfo['widthUnit']

                    if (this.type == 'edit') {
                      if (item.value['id'] != '' && item.value['id'] != null) {
                        promiseSaveArray.push(
                          this.updateProductVariantDB({
                            ...item.value,
                            productID: this.productID,
                            type: 'multiple',
                            pk: 'PRODETAIL#'
                          })
                        );
                      } else {
                        delete item.value['id'];
                        promiseSaveArray.push(
                          this.createProductVariantDB({
                            ...item.value,
                            productID: this.productID,
                            type: 'multiple',
                            pk: 'PRODETAIL#',
                            id: uuidv4()
                          })
                        );
                      }
                    }
                    if (this.type == 'copy') {
                      item.value['reservedStock'] = parseFloat('0')
                      promiseSaveArray.push(
                        this.createProductVariantDB({
                          ...item.value,
                          productID: this.productID,
                          type: 'multiple',
                          pk: 'PRODETAIL#',
                          id: uuidv4()
                        })
                      );
                    }
                    promiseSaveArray.push(
                      this.createProductImageDB({
                        ...imageDBCommonObj,
                        ...item.value.featuredImage,
                        type: 'featuredImgAttr'
                      })
                    );
                  }
                  this.productObj['totalStockQuantity'] = totalStock
                  this.productObj['searchKey'] = this.searchKeyFormatted
                  if (this.type == 'edit') {
                    this.productObj['totalReservedStock'] = totalReserved
                    this.productObj['createdAt'] = this.fetchProductData['createdAt']
                    if (this.fetchProductData['pk'] != this.productObj['pk']) {
                      await this.editProduct()
                    }
                    else {
                      promiseSaveArray.push(this.updateProductDB(this.productObj));
                    }
                  }
                  if (this.type == 'copy') {
                    this.productObj['totalReservedStock'] = parseFloat('0')
                    this.productObj['createdAt'] = new Date().toISOString()
                    promiseSaveArray.push(this.createProductDB(this.productObj));
                  }
                  Promise.all(promiseSaveArray)
                    .then((result) => {
                      this.productEdited = true
                      this.awsApi.loading.next(false);
                      if (this.type == 'edit') {
                        this.messageService.add({
                          severity: 'success',
                          summary: 'Success',
                          detail: 'Product is updated successfully'
                        });
                      }
                      if (this.type == 'copy') {
                        this.awsApi.loading.next(false);
                        this.messageService.add({
                          severity: 'success',
                          summary: 'Success',
                          detail: 'Product is added successfully'
                        });
                      }
                      this.router.navigate(['/list-product']);
                    })
                    .catch((error) => {
                      this.productEdited = false
                      this.awsApi.loading.next(false);
                      this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: Messages.SOMETHING_WENT_WRONG
                      });
                    });
                } catch (error) { }
              }

            }
          }
        }
        else {
          this.productEdited = false
          this.awsApi.loading.next(false);
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: Messages.VARIANT_MISSING
          });
        }
      }

      if (this.productFormControl.singleVariant.value == 'false' && this.wasSingleVariant) {
        try {
          if (this.priceOptionItems.length > 0) {
            // save only multiple variant
            if (this.valid) {
              if (await this.validateVariantsForm() == true) {
                if (await this.validateLeadTime(this.productObj)) {
                  if (await this.validateScheduleDate()) {
                    try {
                      this.productObj['attrName'] = this.productFormControl.attrName.value.id;
                      this.productObj['attrValue'] = this.attrValueArray;
                      this.productObj['singleVariant'] = false
                      this.productObj['stockStatus'] = this.priceOptionItems.controls
                        .filter(item => item.value['stockStatus'] == 'INSTOCK').length == 0 ?
                        'OUTOFSTOCK' : 'INSTOCK'
                      this.productObj['leadTime'] = this.productObj['allowPreOrder'] === false ? undefined : this.productObj['leadTime']
                      let totalStock = 0;
                      let totalReserved = 0;
                      _.forEach(this.priceOptionItems.controls, (item, i) => {
                        totalStock += parseFloat(item.value['stockQuantity'])
                        totalReserved += parseFloat(item.value['reservedStock'])
                        this.searchKeyFormatted += '#' + (item.value['sku']).toLowerCase().trim().replace(/\s+/g, '')

                        let modStorageInfo = item.value['storageInfo'];
                        modStorageInfo['wareHouseId'] = modStorageInfo['wareHouse'].id;
                        modStorageInfo['wareHouse'] = modStorageInfo['wareHouse'].name;
                        delete item.value['storageInfo']
                        item.value['storageInfo'] = modStorageInfo

                        let shipplingInfo = item.value['shipplingInfo']
                        shipplingInfo['heightUnit'] = item.value['shipplingInfo']?.heightUnit?.id
                        shipplingInfo['lengthUnit'] = item.value['shipplingInfo']?.lengthUnit?.id
                        shipplingInfo['weightUnit'] = item.value['shipplingInfo']?.weightUnit?.id
                        shipplingInfo['widthUnit'] = item.value['shipplingInfo']?.widthUnit?.id
                        item.value['shipplingInfo'] = shipplingInfo
                        this.searchKeyFormatted += '#' + modStorageInfo['wareHouseId'] + '#' + shipplingInfo['heightUnit'] + '#' + shipplingInfo['lengthUnit'] + '#' + shipplingInfo['weightUnit'] + '#' + shipplingInfo['widthUnit']
                        item.value['attrName'] = this.productFormControl.attrName.value.attrName
                        item.value['attrNameID'] = this.productFormControl.attrName.value.id
                        if (item.value['id']) { delete item.value['id']; }
                        item.value['reservedStock'] = parseFloat('0')
                        promiseSaveArray.push(
                          this.createProductVariantDB({
                            ...item.value,
                            productID: this.productID,
                            type: 'multiple',
                            pk: 'PRODETAIL#',
                            id: uuidv4()
                          })
                        );
                        promiseSaveArray.push(
                          this.createProductImageDB({
                            ...imageDBCommonObj,
                            ...item.value.featuredImage,
                            type: 'featuredImgAttr'
                          })
                        );
                      });
                      this.productObj['totalStockQuantity'] = totalStock
                      this.productObj['searchKey'] = this.searchKeyFormatted
                      if (this.type == 'edit') {
                        this.productObj['totalReservedStock'] = totalReserved
                        this.productObj['createdAt'] = this.fetchProductData['createdAt']
                        if (this.fetchProductData['pk'] != this.productObj['pk']) {
                          await this.editProduct()
                        }
                        else {
                          promiseSaveArray.push(this.updateProductDB(this.productObj));
                        }
                      }
                      if (this.type == 'copy') {
                        this.productObj['totalReservedStock'] = parseFloat('0')
                        this.productObj['createdAt'] = new Date().toISOString()
                        promiseSaveArray.push(this.createProductDB(this.productObj));
                      }
                      Promise.all(promiseSaveArray)
                        .then(async (result) => {
                          if (this.type == 'edit') {
                            // deleting product detail of single variant and then inserting details for multiple variant
                            await this.deleteProductVariantDB({ pk: 'PRODETAIL#', id: this.fetchVariantDetail[0]['id'] })
                          }
                          this.productEdited = true
                          this.awsApi.loading.next(false);
                          if (this.type == 'edit') {
                            this.messageService.add({
                              severity: 'success',
                              summary: 'Success',
                              detail: 'Product is updated successfully'
                            });
                          }
                          if (this.type == 'copy') {
                            this.awsApi.loading.next(false);
                            this.messageService.add({
                              severity: 'success',
                              summary: 'Success',
                              detail: 'Product is added successfully'
                            });
                          }
                          this.router.navigate(['/list-product']);
                        })
                        .catch((error) => {
                          this.productEdited = false
                          this.awsApi.loading.next(false);
                          this.messageService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: Messages.SOMETHING_WENT_WRONG
                          });
                        });
                    } catch (error) { }
                  }
                }
              }
            }
            else {
              this.displayError()
            }
          } else {
            this.productEdited = false
            this.awsApi.loading.next(false);
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: Messages.VARIANT_MISSING
            });
          }
        } catch (error) { }
      }
    }
  }

  async validateVariantsForm(): Promise<Boolean> {
    if (this.priceOptionItems.valid) {
      for (let i = 0; i < this.priceOptionItems.controls.length; i++) {
        let item = this.priceOptionItems.controls[i]
        this.priceOptionItems.controls[i].value['stockUnit'] = item.value['stockUnit'].value
        delete item.value['expanded'];
        if (!await this.validateSalePrice(item)) { return false; }
        if (!await this.validateStockStatus(item)) { return false; }
        if (!await this.validateSku(item.value['sku'], null, i)) { return false; }
        if (!await this.validateShipping(item)) { return false; }
      }
      return true;
    }
    this.productEdited = false
    this.awsApi.loading.next(false);
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: Messages.PLEASE_FILL_REQUIRED_FIELDS_VARIANT
    });
    return false;
  }

  async validateProductForm() {
    this.sumbitted = true;
    this.productFormControl.name.valid &&
      this.productFormControl.mainCategory.valid &&
      this.productFormControl.subCategory.valid &&
      this.productFormControl.brand.valid &&
      this.productFormControl.countryOfOrigin.valid &&
      this.productFormControl.featuredImage.valid &&
      this.productFormControl.productDescription.valid
      ? await this.checkValidationProductVarient()
      : this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill all the required details'
      });
    this.awsApi.loading.next(false);
  }

  addPriceOptionItem(attrValueObj, type: any = null) {
    let priceOptionItem = this.fb.group({
      id: [''],
      featuredImage: [{}],
      expanded: [false],
      attrName: [this.productFormControl.attrName.value.attrName],
      attrNameID: [this.productFormControl.attrName.value.id],
      attrValue: [attrValueObj.value],
      attrValueID: [attrValueObj.attrValueID],
      model: [''],
      manufacturePartCode: [''],
      sku: ['', [Validators.required, Validators.pattern(/^[a-zA-Z\d\-]+$/)]],
      stockStatus: ['INSTOCK', Validators.required],
      stockQuantity: ['', Validators.required],
      reservedStock: [''],
      stockUnit: [{}, objectRequiredValidator()],
      itemsPerUnit: [],
      regularPrice: ['', Validators.required],
      salePrice: ['', Validators.required],
      dataSheet: [this.dataSheet],
      submittal: [this.submittal],
      catalog: [this.catalog],
      iom: [this.iom],
      storageInfo: this.fb.group({
        wareHouse: [{}, objectRequiredValidator()],
        rackNo: ['', [Validators.pattern(/^(?=.*\d)[a-zA-Z\d ]+$/)]],
        rowNo: ['', [Validators.pattern(/^(?=.*\d)[a-zA-Z\d ]+$/)]],
        binNo: ['', [Validators.pattern(/^(?=.*\d)[a-zA-Z\d ]+$/)]],
        note: ['']
      }),
      shipplingInfo: this.fb.group({
        weightValue: [''],
        weightUnit: [''],
        lengthValue: [''],
        lengthUnit: [''],
        heightValue: [''],
        heightUnit: [''],
        widthValue: [''],
        widthUnit: ['']
      })
    });
    priceOptionItem.patchValue({
      reservedStock: '0'
    })
    this.priceOptionItems.push(priceOptionItem);
    this.attributeValues.push(attrValueObj.value.toLowerCase().replace(/\s+/g, '').trim())
  }

  resetPriceOptionField(type: any, index: any = 0) {
    if (type == 'dataSheet') {
      this.priceOptionItems.at(index).patchValue({
        dataSheet: ''
      });
    } else if (type == 'submittal') {
      this.priceOptionItems.at(index).patchValue({
        submittal: ''
      });
    } else if (type == 'catalog') {
      this.priceOptionItems.at(index).patchValue({
        catalog: ''
      });
    } else if (type == 'iom') {
      this.priceOptionItems.at(index).patchValue({
        iom: ''
      });
    } else if (type == 'singledataSheet') {
      this.singleProductDetail.patchValue({
        dataSheet: ''
      });
    } else if (type == 'singlesubmittal') {
      this.singleProductDetail.patchValue({
        submittal: ''
      });
    } else if (type == 'singlecatalog') {
      this.singleProductDetail.patchValue({
        catalog: ''
      });
    } else if (type == 'singleiom') {
      this.singleProductDetail.patchValue({
        iom: ''
      });
    }
  }

  addGalleryImages(numCalls: number) {
    for (let i = 0; i < numCalls; i++) {
      this.galleryImages.push(
        this.fb.group({
          alterText: ['', [Validators.required]],
          title: [''],
          url: ['', [Validators.required]],
          description: '',
          type: ['', [Validators.required]]
        })
      );
    }
  }

  removePriceOptionItem(index: number): void {
    this.priceOptionItems.removeAt(index);

  }

  get addedSpecList(): FormArray {
    return this.productForm.get('specificationList') as FormArray;
  }

  get addedTaxList(): FormArray {
    return this.productForm.get('taxList') as FormArray;
  }

  get specificationFormControl() {
    return (this.productForm.get('specificationForm') as FormGroup).controls;
  }

  get taxFormControl() {
    return (this.productForm.get('taxForm') as FormGroup).controls;
  }

  showDialog() {
    this.visible = true;
  }

  get productFormControl() {
    return this.productForm.controls;
  }

  addingProductKeyword() {
    if (this.productFormControl.keyWord.value != '') {
      this.keywordArray = this.keywordArray.concat(
        this.productFormControl.keyWord.value.split(',')
      );
      this.productFormControl.keyWord.setValue('');
    }
  }

  addingAttrValue() {
    if (this.productFormControl.attrName.valid) {
      let attributeValues = [];

      if (this.productFormControl.attrValue.value.indexOf(',') === -1 &&
        typeof this.productFormControl.attrValue.value === 'string' &&
        this.productFormControl.attrValue.value.trim()) {
        attributeValues.push(this.productFormControl.attrValue.value.trim());
      } else {
        this.productFormControl.attrValue.value.split(',').forEach(item => {
          if (item && typeof item === 'string' && item.trim()) {
            attributeValues.push(item.trim());
          }
        })
      }
      attributeValues.forEach((value, index) => {
        const trimmedAttrValue = value.toLowerCase().replace(/\s+/g, '').trim();
        if (!this.attributeValues.includes(trimmedAttrValue)) {
          const attrValueObj = {
            value: value,
            attrValueID: uuidv4()
          };
          this.attrValueArray.push(attrValueObj);
          this.attributeValues.push(trimmedAttrValue);
          this.defaultWidth[index] = null
          this.defaultHeight[index] = null
          this.defaultWeight[index] = null
          this.defaultLength[index] = null
          this.addPriceOptionItem(attrValueObj, 'new');
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Variant value already exists!'
          });
        }
      })
      this.productFormControl.attrValue.setValue('');
    }
    else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please choose a variant before adding values'
      });
    }
  }

  deleteKeyword(index: any) {
    this.keywordArray.splice(index, 1);
  }

  async deleteAttrValue(index: any) {
    await this.isAnyOrderPending('variantDelete', this.priceOptionItems.at(index).get('attrValueID').value)
    if (this.valid) {
      this.attrValueArray.splice(index, 1);
      this.attributeValues.splice(index, 1)
      this.defaultWidth.splice(index, 1)
      this.defaultHeight.splice(index, 1)
      this.defaultWeight.splice(index, 1)
      this.defaultLength.splice(index, 1)
      await this.deleteProductVariantDB({ pk: 'PRODETAIL#', id: this.priceOptionItems.controls[index].value['id'] })
      this.removePriceOptionItem(index);
    }
  }

  addSpecificationItem() {
    if (
      !!this.specificationFormControl.name.value &&
      !!this.specificationFormControl.value.value
    ) {
      this.addedSpecList.push(
        this.fb.group({
          name: this.specificationFormControl.name.value,
          value: this.specificationFormControl.value.value
        })
      );
      // this.specificationList = this.productForm.get('specificationList').value;
      this.specificationFormControl.name.reset();
      this.specificationFormControl.value.reset();
    }
  }

  addTaxItem() {
    if (
      !!this.taxFormControl.name.value &&
      !!this.taxFormControl.value.value
    ) {

      let existItem = this.addedTaxList.controls.find(x => x.get('taxID').value == this.taxFormControl.name.value.id)

      _.forEach(this.addedTaxList.controls, (item, index) => {
        if (item.get('taxID').value == this.taxFormControl.name.value.id) {
          item.get('taxCharge').setValue(this.taxFormControl.value.value)
        }
      });
      if (!existItem) {
        this.searchKeyFormatted += this.taxFormControl.name.value.id
        this.addedTaxList.push(
          this.fb.group({
            taxName: this.taxFormControl.name.value.taxCode,
            taxID: this.taxFormControl.name.value.id,
            taxCharge: this.taxFormControl.value.value
          })
        );
      }
      this.taxFormControl.name.reset();
      this.taxFormControl.value.reset();
    }
  }

  removeTaxItem(index: number) {
    this.addedTaxList.removeAt(index);
  }

  removeSpecItem(index: number) {
    this.addedSpecList.removeAt(index);
  }

  async fileSave(event: any, type: any, index: any = -1) {
    this.awsApi.loading.next(true);
    if (index > -1) {
      this.priceOptionIndex = index;
    }
    if (event.target instanceof HTMLInputElement && event.target.files) {
      let file = event.target.files[0];
      if (type == 'productImage' && (!file || !file.type.startsWith('image/'))) {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.INVALID_FILE_TYPE
        });
        return;
      }
      try {
        let uploadedUrl = await this.common.getS3Url(file)
        if (type == 'productImage') {
          this.imageForm.patchValue({
            url: uploadedUrl
          });
        } else if (type == 'dataSheet') {
          this.priceOptionItems.at(this.priceOptionIndex).patchValue({
            dataSheet: uploadedUrl
          });
        } else if (type == 'submittal') {
          this.priceOptionItems.at(this.priceOptionIndex).patchValue({
            submittal: uploadedUrl
          });
        } else if (type == 'catalog') {
          this.priceOptionItems.at(this.priceOptionIndex).patchValue({
            catalog: uploadedUrl
          });
        } else if (type == 'iom') {
          this.priceOptionItems.at(this.priceOptionIndex).patchValue({
            iom: uploadedUrl
          });
        } else if (type == 'singledataSheet') {
          this.singleProductDetail.patchValue({
            dataSheet: uploadedUrl
          });
        } else if (type == 'singlesubmittal') {
          this.singleProductDetail.patchValue({
            submittal: uploadedUrl
          });
        } else if (type == 'singlecatalog') {
          this.singleProductDetail.patchValue({
            catalog: uploadedUrl
          });
        } else if (type == 'singleiom') {
          this.singleProductDetail.patchValue({
            iom: uploadedUrl
          });
        }
        this.awsApi.loading.next(false);
      } catch (error) {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      }
      finally {
        event.target.value = '';
      }
    }
  }

  getAllMainCategory() {
    return this.awsApi.client.graphql({
      query: query.listCategories,
      variables: {
        pk: 'MAIN#'
      }
    });
  }

  getAllSubCategory(mainCategoryID) {
    return this.awsApi.client.graphql({
      query: query.listCategories,
      variables: {
        pk: 'SUB#' + mainCategoryID
      }
    });
  }

  async getAllVariantDetails(productID, singleVariant) {
    let varientType = null;
    let items = [];
    let nextToken = null;
    if (singleVariant == true) {
      varientType = 'single';
    } else {
      varientType = 'multiple';
    }
    do {
      const response = await this.awsApi.client.graphql({
        query: query.listProductDetails,
        variables: {
          pk: 'PRODETAIL#',
          id: null,
          filter: {
            productID: {
              eq: productID
            },
            type: {
              eq: varientType
            }
          },
          limit: 650,
          nextToken: nextToken,
          sortDirection: null
        }
      });
      items = items.concat(response.data.listProductDetails.items);
      nextToken = response.data.listProductDetails.nextToken;
    } while (nextToken !== null)
    return items
  }

  createProductImageDB(imageObj) {
    return this.awsApi.client.graphql({
      query: mutation.createProductImage,
      variables: {
        input: {
          ...imageObj
        }
      }
    });
  }

  deleteProductImageDB(pk, id) {
    return this.awsApi.client.graphql({
      query: mutation.deleteProductImage,
      variables: {
        input: {
          pk: pk,
          id: id
        }
      }
    });
  }

  getAllBrand() {
    return this.awsApi.client.graphql({
      query: query.listBrands
    });
  }

  async getAllProductImage() {
    let items = [];
    let nextToken = null;
    do {
      const response = await this.awsApi.client.graphql({
        query: query.listProductImages,
        variables: {
          pk: 'PROIMG#',
          id: null,
          filter: { productID: { eq: this.productID } },
          limit: 650,
          nextToken: nextToken,
          sortDirection: null
        }
      })
      items = items.concat(response.data.listProductImages.items);
      nextToken = response.data.listProductImages.nextToken;
    } while (nextToken !== null)
    return items
  }

  getAllCountry() {
    return this.awsApi.client.graphql({
      query: query.listCountries,
      variables: {
        limit: 9999,
        nextToken: null,
        sortDirection: null
      }
    });
  }

  getAllAttribute() {
    return this.awsApi.client.graphql({
      query: query.listAttributeNames
    });
  }

  fetchProductDetails() {
    return this.awsApi.client.graphql({
      query: query.getProduct,
      variables: {
        id: this.productID,
        pk: this.pk
      }
    });
  }

  getTaxCodes() {
    return this.awsApi.client.graphql({
      query: query.listTaxes
    });
  }

  getWareHouseList() {
    return this.awsApi.client.graphql({
      query: query.listWareHouses
    });
  }

  getUnits(type: any) {
    let filter = { 'status': { eq: Status.ACTIVE }, 'type': { eq: type } }
    return this.awsApi.client.graphql({
      query: query.listUnits,
      variables: {
        pk: 'UNIT#',
        id: null,
        filter: filter,
        limit: 9999999,
        nextToken: null,
        sortDirection: null
      }
    });
  }

  async getRoleManagement() {
    try {
      await this.common.getPermissionsOfUser('Products', 'Manage Product');
      this.access = this.common.access;
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  async ngOnInit() {
    this.getRoleManagement()
    this.currency = await this.common.getCurrencyOfCompany()
    this._activatedroute.queryParamMap.subscribe((params) => {
      if (this.productID != '') {
        this.awsApi.loading.next(true);
        this.productID = params.get('id') ?? '';
        this.pk = 'PROD#' + (params.get('scId') ?? '')
        this.type = params.get('type') ?? '';
        var patchPromiseArray: any = [];
        patchPromiseArray.push(this.fetchProductDetails());

        Promise.all(patchPromiseArray).then((result: any) => {
          this.fetchProductData = result[0].data.getProduct;
          this.wasSingleVariant = this.fetchProductData['singleVariant']
          this.subCategoryList = [];
          var promiseArray: any = [];
          this.addGalleryImages(4);
          promiseArray.push(
            this.getAllMainCategory(),
            this.getAllBrand(),
            this.getAllCountry(),
            this.commonService.unitDetails(),
            this.commonService.unitMapDetail(),
            this.commonService.unitSymbol(),
            this.getAllAttribute(),
            this.getAllSubCategory(this.fetchProductData['mainCategoryID']),
            this.getAllVariantDetails(
              this.fetchProductData['id'],
              this.fetchProductData['singleVariant']
            ),
            this.getAllProductImage(),
            this.getTaxCodes(),
            this.getWareHouseList(),
            this.getUnits(UnitType.StockUnit),
            this.getUnits(UnitType.Mass),
            this.getUnits(UnitType.Distance)
          );
          Promise.all(promiseArray)
            .then((result: any) => {
              this.mainCategoryList = result[0].data.listCategories.items;
              this.brandList = result[1].data.listBrands.items;
              this.countryList = result[2].data.listCountries.items;
              this.unitdetail = result[3];
              this.unitMap = result[4];
              this.unitSymbol = result[5];
              this.attrNameArray = result[6].data.listAttributeNames.items;
              this.subCategoryList = result[7].data.listCategories.items;
              this.fetchVariantDetail = result[8];
              this.fetchAllProductImages = result[9];
              this.taxList = result[10].data.listTaxes.items;
              this.wareHouseList = result[11].data.listWareHouses.items;
              this.stockUnitList = result[12].data.listUnits.items.sort((a: any, b: any) => a.sequenceNumber - b.sequenceNumber);
              this.massUnitsList = result[13].data.listUnits.items.sort((a: any, b: any) => a.sequenceNumber - b.sequenceNumber);
              this.lengthUnitsList = result[14].data.listUnits.items.sort((a: any, b: any) => a.sequenceNumber - b.sequenceNumber);
              this.prefetchProductDetail();
              this.awsApi.loading.next(false);
            })
            .catch((error) => {
              this.awsApi.loading.next(false);
            });
        });
      }
    });
    this.startingFunction()
  }

  async startingFunction() {
    this.title = this.type == 'edit' ? "Edit Product" : "Copy Product"
  }

  onSelectMainCategory(item: any = null) {
    this.subCategoryList = [];
    let mainCategoryId = item ? item.value.id : this.productFormControl.mainCategory.value.id
    this.getAllSubCategory(mainCategoryId)
      .then((result) => {
        this.subCategoryList = result.data.listCategories.items;
      })
      .catch((error) => {
      });
  }

  cancel() {
    this.router.navigate(['list-product']);
  }

  refreshList(type: any) {
    switch (type) {
      case 'mainCategory':
        this.getAllMainCategory().then((result: any) => {
          this.mainCategoryList = result.data.listCategories.items;
        })
        break;
      case 'brand':
        this.getAllBrand().then((result: any) => {
          this.brandList = result.data.listBrands.items;
        })
        break;
      case 'country':
        this.getAllCountry().then((result: any) => {
          this.countryList = result.data.listCountries.items;
        })
        break;
      case 'variant':
        this.getAllAttribute().then((result: any) => {
          this.attrNameArray = result.data.listAttributeNames.items;
        })
        break;
      case 'taxName':
        this.getTaxCodes().then((result: any) => {
          this.taxList = result.data.listTaxes.items;
        })
        break;
      case 'warehouse':
        this.getWareHouseList().then((result: any) => {
          this.wareHouseList = result.data.listWareHouses.items;
        })
        break;
      case 'stockUnits':
        this.getUnits(UnitType.StockUnit).then((result: any) => {
          this.stockUnitList = result.data.listUnits.items.sort((a: any, b: any) => a.sequenceNumber - b.sequenceNumber);
        })
        break;
      case 'weightUnits':
        this.getUnits(UnitType.Mass).then((result: any) => {
          this.massUnitsList = result.data.listUnits.items.sort((a: any, b: any) => a.sequenceNumber - b.sequenceNumber);
        })
        break;
      case 'lengthUnits':
        this.getUnits(UnitType.Distance).then((result: any) => {
          this.lengthUnitsList = result.data.listUnits.items.sort((a: any, b: any) => a.sequenceNumber - b.sequenceNumber);
        })
        break;
      default: { }
    }
  }

  ngOnDestroy(): void { }

  deleteUrl(type, index: any = -1) {
    if (type == 'prodImage') {
      this.productFormControl.featuredImage.setValue('')
    }
    if (type == 'galleryImage' && index > -1) {
      (this.productForm.get('galleryImages') as FormArray).at(index).patchValue('');
      (this.productForm.get('galleryImages') as FormArray).controls[index].patchValue({
        alterText: '',
        description: '',
        title: '',
        type: '',
        url: ''
      })
    }
    if (type == 'featuredImgAttr' && index > -1) {
      (this.productForm.get('priceOptionItems') as FormArray).at(index).get('featuredImage').patchValue({});
    }
  }

  preventE(event) {
    if (event.which === 101) {
      event.preventDefault();
    }
  }

  getCurrentDate(): string {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    return currentDate.toISOString().split('T')[0];
  }

  onSelectAllowPreorder() {
    if (!(this.productForm.controls.allowPreOrder.value)) {
      this.productForm.patchValue({
        leadTime:
          this.fetchProductData['leadTime'] && this.fetchProductData['allowPreOrder'] ? parseInt(this.fetchProductData['leadTime']) : ''
      })
    }
  }

  openFile(file) {
    window.open(file)
  }
}