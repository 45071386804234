<section id="addContentSec">
    <div class="container-fluid">
        <div class="row mb-1 mt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card mb-1 heading-card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="d-flex justify-content-start align-items-center">
                                    <i class="fas fa-chevron-left" (click)="redirectToListContentsPage()"></i>
                                    <h6 *ngIf="type=='add'">Create New Content</h6>
                                    <h6 *ngIf="type=='edit'">Edit Content</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-1" [formGroup]="addContentForm">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                <label>Content Name <span class="mandatory">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter Content Name"
                                    formControlName="contentName" />
                                <span class="text-danger"
                                    *ngIf="(addContentForm.controls.contentName.touched) && !addContentForm.controls.contentName?.valid">
                                    <div *ngIf="addContentForm.controls.contentName.errors['required']">
                                        Content name is required.
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="d-flex justify-content-end gap-2">
                    <button type="button" class="btn cancel-btn" *ngIf="type=='add'"
                        (click)="RefreshPage()">Cancel</button>
                    <button type="button" class="btn cancel-btn" *ngIf="type=='edit'"
                        (click)="redirectToListContentsPage()">Cancel</button>
                    <button type="button" class="btn add-btn" *ngIf="type=='add'" [disabled]="addContentForm.invalid"
                        (click)="AddContent()">Add</button>
                    <button type="button" class="btn add-btn" *ngIf="type=='edit'" [disabled]="addContentForm.invalid"
                        (click)="EditContent()">Edit</button>
                </div>
            </div>
        </div>
    </div>
</section>