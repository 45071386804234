<section id="attributeEditSec">
  <div class="container-fluid">
    <form [formGroup]="addAttrForm" (ngSubmit)="onSubmit()">
      <div class="row mb-3">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="card mb-2 heading-card">
            <div class="card-body">
              <div class="d-flex justify-content-start align-items-center">
                <i class="fas fa-chevron-left" (click)="redirectToAttributeListPage()"></i>
                <h6>Update Attribute</h6>
              </div>
            </div>
          </div>
          <div class="card mb-2 attribute-img-card">
            <div class="card-body">
              <div class="row">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">
                  <label>Attribute Name <span class="mandatory">*</span></label>
                  <input type="text" class="form-control" placeholder="Enter Attribute Name"
                    formControlName="attrName" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="d-flex justify-content-end gap-2">
            <button type="button" class="btn cancel-btn" (click)="redirectToAttributeListPage()">Cancel</button>
            <button type="submit" [disabled]="!addAttrForm.valid" class="btn save-btn">
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>