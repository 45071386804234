<section id="addNewFaqSectionSec">
    <div class="container-fluid">
        <div class="row mb-1 mt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card mb-1 heading-card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex justify-content-start align-items-center">
                                        <i class="fas fa-chevron-left" (click)="redirectToFaqListPage()"></i>
                                        <h6>{{selectedFaq.name}}</h6>
                                    </div>
                                    <div>
                                        <button type="button" class="btn" [disabled]="!access['add']"
                                            *ngIf="!(addFaq || editFAQ)" (click)="showAddFaq()">Create FAQ</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-1" *ngIf="faqSections.length>0 && viewFaq">
                    <div class="card-body">
                        <div class="row" *ngFor="let faqSection of faqSections;let i = index;">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                <div class="accordion" id="accordionExample">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header d-flex w-100 align-items-center gap-2"
                                            id="headingOne">
                                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne" aria-expanded="true"
                                                aria-controls="collapseOne">
                                                {{faqSection.question}}
                                            </button>
                                            <span
                                                [ngClass]="!access['edit'] ? 'material-symbols-outlined edit-icon me-2 disable' : 'material-symbols-outlined edit-icon me-2'"
                                                (click)="editFaq(i)" title="Edit">
                                                edit_square
                                            </span>
                                            <span [ngClass]="{
                                                'material-symbols-outlined': true,
                                                'edit-icon': true,
                                                'me-2': true,
                                                'disable': !access['delete']
                                            }" (click)="deleteFaq(i)" title="Delete">
                                                delete
                                            </span>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse show"
                                            aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p [innerHTML]="faqSection.answer">
                                                    {{faqSection.answer}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="d-flex justify-content-end gap-2">
                    <button type="button" class="btn cancel-btn">Cancel</button>
                    <button type="button" class="btn add-btn">Add</button>
                </div>
            </div>
        </div> -->
    </div>
</section>
<section id="addNewFaqSectionSec" *ngIf="addFaq || editFAQ">
    <div class="container-fluid">
        <div class="row mb-1 mt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card mb-1 heading-card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex justify-content-start align-items-center">
                                        <i class="fas fa-chevron-left" (click)="ShowFaq()"></i>
                                        <h6 *ngIf="addFaq">Add FAQ Section</h6>
                                        <h6 *ngIf="editFAQ">Edit FAQ Section</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-1" [formGroup]="addFaqForm">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                <div>
                                    <label>Query <span class="mandatory">*</span></label>
                                    <input type="text" class="form-control" placeholder="Enter" formControlName="query">
                                    <span class="text-danger"
                                        *ngIf="(addFaqForm.controls.query.touched) && !addFaqForm.controls.query?.valid">
                                        <div *ngIf="addFaqForm.controls.query.errors['required']">
                                            Query is required.
                                        </div>
                                    </span>
                                </div>
                                <div>
                                    <label>Answer<span class="mandatory">*</span></label>
                                    <ckeditor [editor]="Editor" data="<h1>Hello from CKEditor 5!</h1>"
                                        (ready)="onReady($event)" (change)="onChange($event)" formControlName="answer">
                                    </ckeditor>
                                    <span class="text-danger"
                                        *ngIf="(addFaqForm.controls.answer.touched) && !addFaqForm.controls.answer?.valid">
                                        <div *ngIf="addFaqForm.controls.answer.errors['required']">
                                            Answer is required.
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="d-flex justify-content-end gap-2">
                    <button type="button" class="btn cancel-btn" (click)="Refresh()">Cancel</button>
                    <button type="button" class="btn add-btn" [disabled]="addFaqForm.invalid && !access['add']"
                        *ngIf="addFaq" (click)="AddFaq()">Add</button>
                    <button type="button" class="btn add-btn" [disabled]="addFaqForm.invalid && !access['edit']"
                        *ngIf="editFAQ" (click)="SaveFaq()">Save</button>
                </div>
            </div>
        </div>
    </div>
</section>