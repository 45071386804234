<section id="backButtonSec">
    <div class="container-fluid">
        <div class="row pt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="text-start">
                    <button type="button" class="btn back-btn" (click)="redirectToPreviousPage()">
                        <i class="fas fa-angle-left me-2"></i> Back
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="confirmOtpCodeSec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 my-auto">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <form [formGroup]="confirmForm">
                                    <div class="contact">
                                        <div class="text-center">
                                            <img src="../../../../assets/img/authenticate.png" />
                                            <h3 class="mt-3">
                                                Authenticate Your Account
                                            </h3>
                                            <p>
                                                Please enter the 6 digit code that was send to your email
                                                <span>{{email}}</span>
                                            </p>
                                        </div>
                                        <div>
                                            <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)"
                                                [config]="{length:6,allowNumbersOnly:true}"></ng-otp-input>
                                        </div>
                                        <div class="mt-3 text-center">
                                            <button class="btn send-btn" type="button" (click)="confirmCode()">
                                                Verify
                                            </button>
                                        </div>
                                        <div class="d-flex justify-content-between mt-3">
                                            <p>Time remaining <span>{{ remainingTime }}</span></p>
                                            <button type="btn" [disabled]="!isTimerExpired" class="btn btn-link"
                                                (click)="sendAgain()">Resend
                                                code</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>