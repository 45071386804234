import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-common-search',
  templateUrl: './common-search.component.html',
  styleUrl: './common-search.component.scss'
})
export class CommonSearchComponent {
  searchText: any = ''
  isSearchEnabled: boolean = false
  @Output() onEnter = new EventEmitter<any>();

  search() {
    this.isSearchEnabled = true
    this.onEnter.emit(this.searchText)
  }

  clearText() {
    this.searchText = ''
    if (this.isSearchEnabled) {
      this.onEnter.emit(this.searchText)
      this.isSearchEnabled = false
    }
  }
}
