import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ApiService } from '@services/api/api.service';
import { MessageService } from 'primeng/api';
import { uploadData } from 'aws-amplify/storage';
import * as mutation from 'graphql/mutations';
import { v4 as uuidv4 } from 'uuid';
import * as query from 'graphql/queries';
import { Messages } from '@/Toaster-messages';
import { FontWeight, MobileFont, NotificationsTypeList, PaymentMethodList, TypeOfApp, WebFont } from '@/common-list';
import { Status } from 'API';
import { CommonService } from '@services/common/common.service';
import _ from 'underscore';

interface City {
    name: string;
    code: string;
}
@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrl: './settings.component.scss'
})
export class SettingsComponent implements OnInit {
    cities: City[] | undefined;
    selectedCity: City | undefined;
    themeObject: any = {}
    defaultPaymentMethod: string | any = null
    companyDetailsForm: FormGroup;
    paymentForm: FormGroup;
    emailSettingForm: FormGroup;
    themeForm: FormGroup;
    mobileThemeForm: FormGroup;
    isActive: boolean = false;
    showThemesButton: boolean = false;
    submitted: boolean = false;
    showEmailButton: boolean = false;
    showCompanyButton: boolean = false;
    showPaymentButton: boolean = false;
    notificationType = NotificationsTypeList
    hide: boolean
    isAddPayment: boolean = false;
    isEditPayment: boolean = false
    selectedPaymentMode: any = []
    companyDetails: any = []
    paymentMethod: any = []
    listSettings: any = []
    countryList: any = []
    typeList: any = TypeOfApp
    selectedPreferredTheme: boolean;
    emailToList: any = []
    defaultType: any = null
    paymentStatus: any
    statusList: any = [Status.ACTIVE, Status.INACTIVE]
    paymentMethodList: any = PaymentMethodList
    secondaryLogo = ''
    isEditAppDetailsEnabled: boolean = false
    selectedAppIndex: any
    defaultAppType: any = null
    webFonts = WebFont
    mobileFonts = MobileFont
    fontWeights = FontWeight
    fontChecked: any = []
    editEmailSetting = false
    validEmailInput: boolean
    selectedEmailSettingItem: any = []
    selectedMode: any = 'web'

    constructor(
        private common: CommonService,
        private awsApi: ApiService,
        private messageService: MessageService,
        private fb: FormBuilder,
    ) {
        this.companyDetailsForm = this.fb.group({
            primaryLogo: [''],
            secondaryLogo: ['', [Validators.required]],
            companyName: ['', [Validators.required]],
            webSite: ['', [Validators.required]],
            phoneNumber: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
            country: ['', [Validators.required]],
            state: ['', [Validators.required]],
            city: ['', [Validators.required]],
            addressLine1: ['', [Validators.required]],
            addressLine2: ['', [Validators.required]],
            zipCode: ['', [Validators.required]],
            currency: [''],
            categorySequenced: [false],
            copyrightText: [''],
            seoDescription: ['', Validators.maxLength(150)],
            mobileAppUrls: this.fb.array([]),
            appUrlForm: this.fb.group({
                type: [''],
                title: [''],
                alterText: [''],
                url: [''],
                certificationName: [''],
                redirectTo: ['']
            }),
            daysToReturn: ['', [Validators.required, Validators.pattern('^[1-9][0-9]*$')]],
            brandName: ['', [Validators.required]],
            iconUrl: ['', [Validators.required]],
        });
        this.paymentForm = this.fb.group({
            paymentMethod: ['', [Validators.required]],
            provider: [],
            logoUrl: [''],
            stagingUrl: [''],
            productionUrl: ['']
        });
        this.emailSettingForm = this.fb.group({
            type: [, [Validators.required]],
            emailTo: ['', [Validators.required]],
        });

        this.themeForm = this.fb.group({
            preferredTheme: ['#ffffff'],
            font: [this.webFonts[3].value],
            // header
            headerBackgroundColor: ['#fffefe'],
            headerTextColor: ['#040404'],
            headerIconColor: ['#311a1c'],
            // footer
            footerBackgroundColor: ['#05387d'],
            footerTextColor: ['#dae6fa'],
            footerHeadingColor: ['#ffffff'],
            // navigation
            navigationMenuFontWeight: ['Bold'],
            navigationMenuFontSize: ['14'],
            navigationMenuColor: ['#371d21'],
            navigationSubMenuFontWeight: ['Bold'],
            navigationSubMenuFontSize: ['16'],
            navigationSubMenuColor: ['#311a1d'],
            // product
            productTitleFontWeight: ['Regular'],
            productTitleFontSize: ['14'],
            productSubTitleFontWeight: ['Regular'],
            productSubTitleFontSize: ['16'],
            productPriceFontWeight: ['Bold'],
            productPriceFontSize: ['18'],
            // product details
            productDetailNameFontWeight: ['Bold'],
            productDetailNameFontSize: ['20'],
            productDetailSubTitleFontWeight: ['Regular'],
            productDetailSubTitleFontSize: ['16'],
            productDetailPriceFontWeight: ['Bold'],
            productDetailPriceFontSize: ['22'],
            productDescriptionHeadingFontWeight: ['Bold'],
            productDescriptionHeadingFontSize: ['18'],
            productDescriptionFontWeight: ['Regular'],
            productDescriptionFontSize: ['16'],
            // customer review
            customerReviewsTitleFontWeight: ['Semi Bold'],
            customerReviewsTitleFontSize: ['15'],
            customerReviewsDetailsFontWeight: ['Regular'],
            customerReviewsDetailsFontSize: ['14'],
            // notification
            notificationsTitleFontWeight: ['Regular'],
            notificationsTitleFontSize: ['14'],
            notificationsSubTitleFontWeight: ['Regular'],
            notificationsSubTitleFontSize: ['13'],

            profileHeadingFontWeight: ['Bold'],
            profileHeadingFontSize: ['16'],
            profileContentFontWeight: ['Semi Bold'],
            profileContentFontSize: ['14'],
            // content
            contentTitleFontWeight: ['Bold'],
            contentTitleFontSize: ['24'],
            contentDescriptionFontWeight: ['Regular'],
            contentDescriptionFontSize: ['16'],
            // faq
            titleHeaderFontWeight: ['Bold'],
            titleHeaderFontSize: ['15'],
            titleDetailsFontWeight: ['Regular'],
            titleDetailsFontSize: ['14'],
            // primaryButton
            primaryButtonFontWeight: ['Semi Bold'],
            primaryButtonFontSize: ['14'],
            primaryButtonBackgroundColor: ['#ca0015'],
            primaryButtonTextColor: ['#fffefd'],
            // secondaryButton
            secondaryButtonFontWeight: ['Semi Bold'],
            secondaryButtonFontSize: ['14'],
            secondaryButtonBackgroundColor: ['#083879'],
            secondaryButtonTextColor: ['fffeff']
        });
        this.setFont(3, 'web')
        this.mobileThemeForm = this.fb.group({
            font: [this.mobileFonts[3].value],
            locationBackgroundColor: ['#A9CCE3'],
            locationTextColor: ['#A9CCE3'],
            locationIconColor: ['#04A0C6'],
            searchBarBackgroundColor: ['#5888C9'],
            newArrivalsColor: ['#F5FEFD'],
            bestSellersColor: ['#FFFAF6'],
            recentlyViewedColor: ['#E3F2FD'],
            navBackgroundColor: ['#083879'],
            navIconColor: ['#FFFFFF'],
            primaryButtonBackgroundColor: ['#1C60BE'],
            primaryButtonTextColor: ['#FFFFFF'],
            secondaryButtonBackgroundColor: ['#CA0015'],
            secondaryButtonTextColor: ['#FFFFFF']
        });
        this.setFont(3, 'mobile')
    }

    resetMobileTheme() {
        try {
            this.mobileThemeForm.patchValue({
                font: this.mobileFonts[3].value,
                locationBackgroundColor: '#A9CCE3',
                locationTextColor: '#483335',
                locationIconColor: '#04A0C6',
                searchBarBackgroundColor: '#5888C9',
                newArrivalsColor: '#F5FEFD',
                bestSellersColor: '#FFFAF6',
                recentlyViewedColor: '#E3F2FD',
                navBackgroundColor: '#083879',
                navIconColor: '#FFFFFF',
                primaryButtonBackgroundColor: '#CA0015',
                primaryButtonTextColor: '#FFFFFF',
                secondaryButtonBackgroundColor: '#083879',
                secondaryButtonTextColor: '#FFFFFF'
            })
        } catch (error) { }
    }

    async setMobileThemeForm() {
        let selectedTheme = this.companyDetails[0]?.mobileTheme || {}
        try {
            this.mobileThemeForm.patchValue({
                font: selectedTheme?.font || '',
                locationBackgroundColor: selectedTheme.locationBackgroundColor ? selectedTheme.locationBackgroundColor : '#A9CCE3',
                locationTextColor: selectedTheme.locationTextColor ? selectedTheme.locationTextColor : '#483335',
                locationIconColor: selectedTheme.locationIconColor ? selectedTheme.locationIconColor : '#04A0C6',
                searchBarBackgroundColor: selectedTheme.searchBarBackgroundColor ? selectedTheme.searchBarBackgroundColor : '#5888C9',
                newArrivalsColor: selectedTheme.newArrivalsColor ? selectedTheme.newArrivalsColor : '#F5FEFD',
                bestSellersColor: selectedTheme.bestSellersColor ? selectedTheme.bestSellersColor : '#FFFAF6',
                recentlyViewedColor: selectedTheme.recentlyViewedColor ? selectedTheme.recentlyViewedColor : '#E3F2FD',
                navBackgroundColor: selectedTheme.navBackgroundColor ? selectedTheme.navBackgroundColor : '#083879',
                navIconColor: selectedTheme.navIconColor ? selectedTheme.navIconColor : '#FFFFFF',
                primaryButtonBackgroundColor: selectedTheme.primaryButtonBackgroundColor ? selectedTheme.primaryButtonBackgroundColor : '#1C60BE',
                primaryButtonTextColor: selectedTheme.primaryButtonTextColor ? selectedTheme.primaryButtonTextColor : '#FFFFFF',
                secondaryButtonBackgroundColor: selectedTheme.secondaryButtonBackgroundColor ? selectedTheme.secondaryButtonBackgroundColor : '#CA0015',
                secondaryButtonTextColor: selectedTheme.secondaryButtonTextColor ? selectedTheme.secondaryButtonTextColor : '#FFFFFF'
            })
        } catch (error) {
        }
        if (selectedTheme.font) {
            this.setFont(this.mobileFonts.findIndex((item) => item.value == selectedTheme.font), 'mobile')
        } else {
            this.setFont(3, 'mobile')
        }
    }

    resetTheme() {
        try {
            this.themeForm.patchValue({
                preferredTheme: '#ffffff',
                font: this.webFonts[3].value,
                // header
                headerBackgroundColor: '#fffefe',
                headerTextColor: '#040404',
                headerIconColor: '#311a1c',
                // footer
                footerBackgroundColor: '#05387d',
                footerTextColor: '#dae6fa',
                footerHeadingColor: '#ffffff',
                // navigation
                navigationMenuFontWeight: 'Bold',
                navigationMenuFontSize: '14',
                navigationMenuColor: '#371d21',
                navigationSubMenuFontWeight: 'Bold',
                navigationSubMenuFontSize: '16',
                navigationSubMenuColor: '#311a1d',
                // product
                productTitleFontWeight: 'Regular',
                productTitleFontSize: '14',
                productSubTitleFontWeight: 'Regular',
                productSubTitleFontSize: '16',
                productPriceFontWeight: 'Bold',
                productPriceFontSize: '18',
                // product details
                productDetailNameFontWeight: 'Bold',
                productDetailNameFontSize: '20',
                productDetailSubTitleFontWeight: 'Regular',
                productDetailSubTitleFontSize: '16',
                productDetailPriceFontWeight: 'Bold',
                productDetailPriceFontSize: '22',
                productDescriptionHeadingFontWeight: 'Bold',
                productDescriptionHeadingFontSize: '18',
                productDescriptionFontWeight: 'Regular',
                productDescriptionFontSize: '16',
                // customer review
                customerReviewsTitleFontWeight: 'Semi Bold',
                customerReviewsTitleFontSize: '15',
                customerReviewsDetailsFontWeight: 'Regular',
                customerReviewsDetailsFontSize: '14',
                // notification
                notificationsTitleFontWeight: 'Regular',
                notificationsTitleFontSize: '14',
                notificationsSubTitleFontWeight: 'Regular',
                notificationsSubTitleFontSize: '13',

                profileHeadingFontWeight: 'Bold',
                profileHeadingFontSize: '16',
                profileContentFontWeight: 'Semi Bold',
                profileContentFontSize: '14',
                // content
                contentTitleFontWeight: 'Bold',
                contentTitleFontSize: '24',
                contentDescriptionFontWeight: 'Regular',
                contentDescriptionFontSize: '16',
                // faq
                titleHeaderFontWeight: 'Bold',
                titleHeaderFontSize: '15',
                titleDetailsFontWeight: 'Regular',
                titleDetailsFontSize: '14',
                // primaryButton
                primaryButtonFontWeight: 'Semi Bold',
                primaryButtonFontSize: '14',
                primaryButtonBackgroundColor: '#ca0015',
                primaryButtonTextColor: '#fffefd',
                // secondaryButton
                secondaryButtonFontWeight: 'Semi Bold',
                secondaryButtonFontSize: '14',
                secondaryButtonBackgroundColor: '#083879',
                secondaryButtonTextColor: '#fffeff'
            })
            this.setFont(3, 'web')
        } catch (error) { }
    }

    async setThemeForm() {
        let selectedTheme = this.companyDetails[0]?.theme || {}
        try {
            this.themeForm.patchValue({
                font: selectedTheme?.font || '',

                headerBackgroundColor: selectedTheme?.headerBackgroundColor ? selectedTheme.headerBackgroundColor : '#fffefe',
                headerTextColor: selectedTheme?.headerTextColor || '#040404',
                headerIconColor: selectedTheme?.headerIconColor || '#311a1c',

                footerBackgroundColor: selectedTheme?.footerBackgroundColor || '#05387d',
                footerTextColor: selectedTheme?.footerTextColor || '#dae6fa',
                footerHeadingColor: selectedTheme?.footerHeadingColor || '#ffffff',

                navigationMenuFontWeight: selectedTheme?.navigationMenu?.fontWeight ? selectedTheme.navigationMenu.fontWeight : 'Bold',
                navigationMenuFontSize: (selectedTheme?.navigationMenu?.fontSize) ? (selectedTheme.navigationMenu.fontSize).split('px')[0] : '14',
                navigationMenuColor: selectedTheme?.navigationMenuColor || '#371d21',
                navigationSubMenuFontWeight: selectedTheme?.navigationSubMenu?.fontWeight ? selectedTheme.navigationSubMenu.fontWeight : 'Bold',
                navigationSubMenuFontSize: (selectedTheme?.navigationSubMenu?.fontSize) ? (selectedTheme.navigationSubMenu.fontSize).split('px')[0] : '16',
                navigationSubMenuColor: selectedTheme?.navigationSubMenuColor || '#311a1d',

                productTitleFontWeight: selectedTheme?.productTitle?.fontWeight ? selectedTheme.productTitle.fontWeight : 'Regular',
                productTitleFontSize: (selectedTheme?.productTitle?.fontSize) ? (selectedTheme.productTitle.fontSize).split('px')[0] : '14',
                productSubTitleFontWeight: selectedTheme?.productSubTitle?.fontWeight ? selectedTheme.productSubTitle.fontWeight : 'Regular',
                productSubTitleFontSize: (selectedTheme?.productSubTitle?.fontSize) ? (selectedTheme.productSubTitle.fontSize).split('px')[0] : '16',
                productPriceFontWeight: selectedTheme?.productPrice?.fontWeight ? selectedTheme.productPrice.fontWeight : 'Bold',
                productPriceFontSize: (selectedTheme?.productPrice?.fontSize) ? (selectedTheme.productPrice.fontSize).split('px')[0] : '18',

                productDetailNameFontWeight: selectedTheme?.productDetailName?.fontWeight ? selectedTheme.productDetailName.fontWeight : 'Bold',
                productDetailNameFontSize: (selectedTheme?.productDetailName?.fontSize) ? (selectedTheme.productDetailName.fontSize).split('px')[0] : '20',
                productDetailSubTitleFontWeight: selectedTheme?.productDetailSubTitle?.fontWeight ? selectedTheme.productDetailSubTitle.fontWeight : 'Regular',
                productDetailSubTitleFontSize: (selectedTheme?.productDetailSubTitle?.fontSize) ? (selectedTheme.productDetailSubTitle.fontSize).split('px')[0] : '16',
                productDetailPriceFontWeight: selectedTheme?.productDetailPrice?.fontWeight ? selectedTheme.productDetailPrice.fontWeight : 'Bold',
                productDetailPriceFontSize: (selectedTheme?.productDetailPrice?.fontSize) ? (selectedTheme.productDetailPrice.fontSize).split('px')[0] : '22',
                productDescriptionHeadingFontWeight: selectedTheme?.productDescriptionHeading?.fontWeight ? selectedTheme.productDescriptionHeading.fontWeight : 'Bold',
                productDescriptionHeadingFontSize: (selectedTheme?.productDescriptionHeading?.fontSize) ? (selectedTheme.productDescriptionHeading.fontSize).split('px')[0] : '18',
                productDescriptionFontWeight: selectedTheme?.productDescription?.fontWeight ? selectedTheme.productDescription.fontWeight : 'Regular',
                productDescriptionFontSize: (selectedTheme?.productDescription?.fontSize) ? (selectedTheme.productDescription.fontSize).split('px')[0] : '16',

                customerReviewsTitleFontWeight: selectedTheme?.customerReviewsTitle?.fontWeight ? selectedTheme.customerReviewsTitle.fontWeight : 'Semi Bold',
                customerReviewsTitleFontSize: (selectedTheme?.customerReviewsTitle?.fontSize) ? (selectedTheme.customerReviewsTitle.fontSize).split('px')[0] : '15',
                customerReviewsDetailsFontWeight: selectedTheme?.customerReviewsDetails?.fontWeight ? selectedTheme.customerReviewsDetails.fontWeight : 'Regular',
                customerReviewsDetailsFontSize: (selectedTheme?.customerReviewsDetails?.fontSize) ? (selectedTheme.customerReviewsDetails.fontSize).split('px')[0] : '14',

                notificationsTitleFontSize: (selectedTheme?.notificationsTitle?.fontSize) ? (selectedTheme.notificationsTitle.fontSize).split('px')[0] : '14',
                notificationsTitleFontWeight: selectedTheme?.notificationsTitle?.fontWeight ? selectedTheme.notificationsTitle.fontWeight : 'Regular',
                notificationsSubTitleFontWeight: selectedTheme?.notificationsSubTitle?.fontWeight ? selectedTheme.notificationsSubTitle.fontWeight : 'Regular',
                notificationsSubTitleFontSize: (selectedTheme?.notificationsSubTitle?.fontSize) ? (selectedTheme.notificationsSubTitle.fontSize).split('px')[0] : '13',

                profileHeadingFontWeight: selectedTheme?.profileHeading?.fontWeight ? selectedTheme.profileHeading.fontWeight : 'Bold',
                profileHeadingFontSize: (selectedTheme?.profileHeading?.fontSize) ? (selectedTheme.profileHeading.fontSize).split('px')[0] : '16',
                profileContentFontWeight: selectedTheme?.profileContent?.fontWeight ? selectedTheme.profileContent.fontWeight : 'Semi Bold',
                profileContentFontSize: (selectedTheme?.profileContent?.fontSize) ? (selectedTheme.profileContent.fontSize).split('px')[0] : '14',

                contentTitleFontWeight: selectedTheme?.contentTitle?.fontWeight ? selectedTheme.contentTitle.fontWeight : 'Bold',
                contentTitleFontSize: (selectedTheme?.contentTitle?.fontSize) ? (selectedTheme.contentTitle.fontSize).split('px')[0] : '24',
                contentDescriptionFontWeight: selectedTheme?.contentDescription?.fontWeight ? selectedTheme.contentDescription.fontWeight : 'Regular',
                contentDescriptionFontSize: (selectedTheme?.contentDescription?.fontSize) ? (selectedTheme.contentDescription.fontSize).split('px')[0] : '16',
                titleHeaderFontWeight: selectedTheme?.titleHeader?.fontWeight ? selectedTheme.titleHeader.fontWeight : 'Bold',
                titleHeaderFontSize: (selectedTheme?.titleHeader?.fontSize) ? (selectedTheme.titleHeader.fontSize).split('px')[0] : '15',
                titleDetailsFontWeight: selectedTheme?.titleDetails?.fontWeight ? selectedTheme.titleDetails.fontWeight : 'Regular',
                titleDetailsFontSize: (selectedTheme?.titleDetails?.fontSize) ? (selectedTheme.titleDetails.fontSize).split('px')[0] : '14',

                primaryButtonFontWeight: selectedTheme?.primaryButton?.fontWeight ? selectedTheme.primaryButton.fontWeight : 'Semi Bold',
                primaryButtonFontSize: (selectedTheme?.primaryButton?.fontSize) ? (selectedTheme.primaryButton.fontSize).split('px')[0] : '14',
                primaryButtonBackgroundColor: selectedTheme?.primaryButtonBackgroundColor || '#ca0015',
                primaryButtonTextColor: selectedTheme?.primaryButtonTextColor || '#fffefd',

                secondaryButtonFontWeight: selectedTheme?.secondaryButton?.fontWeight ? selectedTheme.secondaryButton.fontWeight : 'Semi Bold',
                secondaryButtonFontSize: (selectedTheme?.secondaryButton?.fontSize) ? (selectedTheme.secondaryButton.fontSize).split('px')[0] : '14',
                secondaryButtonBackgroundColor: selectedTheme?.secondaryButtonBackgroundColor || '#083879',
                secondaryButtonTextColor: selectedTheme?.secondaryButtonTextColor || '#fffeff'
            })
            if (selectedTheme.font) {
                this.setFont(this.webFonts.findIndex((item) => item.value == selectedTheme.font), 'web')
            } else {
                this.setFont(3, 'web')
            }
        } catch (error) {
        }
    }

    get themeFormControl() {
        return this.themeForm.controls;
    }

    get mobileThemeFormControl() {
        return this.mobileThemeForm.controls;
    }

    get appUrlFormControl() {
        return (this.companyDetailsForm.get('appUrlForm') as FormGroup).controls;
    }

    get addedUrlsList(): FormArray {
        return this.companyDetailsForm.get('mobileAppUrls') as FormArray;
    }

    async ngOnInit(): Promise<void> {
        await this.getAllSettings()
    }

    async getAllSettings() {
        this.awsApi.client
            .graphql({
                query: query.listPaymentModes
            })
            .then((response) => {
                this.paymentMethod = response.data.listPaymentModes.items.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
            })
        this.awsApi.client
            .graphql({
                query: query.listSettings
            })
            .then(async (response) => {
                this.listSettings = response.data.listSettings.items.sort((a: any, b: any) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
                this.secondaryLogo = await this.common.getSecondaryLogoOfCompany()
            })
        this.awsApi.client
            .graphql({
                query: query.listCountries,
                variables: {
                    limit: 9999,
                    nextToken: null,
                    sortDirection: null
                }
            })
            .then((response) => {
                this.countryList = response.data.listCountries.items
            })
        this.awsApi.client
            .graphql({
                query: query.listCompanyDetails
            })
            .then(async (response) => {
                this.companyDetails = response.data.listCompanyDetails.items
                if (this.companyDetails.length > 0) {
                    if (this.companyDetails[0]?.mobileAppUrls && this.companyDetails[0]?.mobileAppUrls.length > 0) {
                        this.companyDetails[0]?.mobileAppUrls.forEach((item) => {
                            this.addedUrlsList.push(
                                this.fb.group({
                                    type: item.taxName,
                                    title: item.title,
                                    alterText: item.alterText,
                                    url: item.url,
                                    certificationName: item.certificationName,
                                    redirectTo: item.redirectTo
                                })
                            );
                        });
                    }
                    this.companyDetailsForm.patchValue({
                        companyName: this.companyDetails[0].companyName,
                        primaryLogo: this.companyDetails[0].primaryLogo.url,
                        secondaryLogo: this.companyDetails[0].secondaryLogo.url,
                        webSite: this.companyDetails[0].webSite,
                        phoneNumber: this.companyDetails[0].phoneNumber,
                        email: this.companyDetails[0].email,
                        country: this.companyDetails[0].address.country,
                        state: this.companyDetails[0].address.state,
                        city: this.companyDetails[0].address.city,
                        addressLine1: this.companyDetails[0].address.addressLine1,
                        addressLine2: this.companyDetails[0].address.addressLine2,
                        zipCode: this.companyDetails[0].address.postalCode,
                        currency: this.companyDetails[0].currency,
                        categorySequenced: this.companyDetails[0].categorySequenced,
                        copyrightText: this.companyDetails[0]?.copyrightText || '',
                        seoDescription: this.companyDetails[0]?.seoDescription || '',
                        mobileAppUrls: this.companyDetails[0]?.mobileAppUrls || [],
                        daysToReturn: this.companyDetails[0]?.daysToReturn || '',
                        brandName: this.companyDetails[0]?.brandName || '',
                        iconUrl: this.companyDetails[0]?.iconUrl || ''
                    })
                    this.isActive = this.companyDetails[0].status
                    await this.setThemeForm()
                    await this.setMobileThemeForm()
                }
            })
            .catch((error) => {
            });
    }

    editAppDetails(index: any) {
        this.isEditAppDetailsEnabled = true
        this.selectedAppIndex = index
        this.appUrlFormControl.url.setValue(this.addedUrlsList.controls[index]?.get('url').value)
        this.appUrlFormControl.type.setValue(this.addedUrlsList.controls[index]?.get('type').value)
        this.appUrlFormControl.title.setValue(this.addedUrlsList.controls[index]?.get('title').value)
        this.appUrlFormControl.alterText.setValue(this.addedUrlsList.controls[index]?.get('alterText').value)
        this.appUrlFormControl.certificationName.setValue(this.addedUrlsList.controls[index]?.get('certificationName').value)
        this.appUrlFormControl.redirectTo.setValue(this.addedUrlsList.controls[index]?.get('redirectTo').value)
    }

    deleteAppDetails(index: any) {
        this.addedUrlsList.removeAt(index);
    }

    cancelAppItem() {
        this.isEditAppDetailsEnabled = false
        this.resetAppUrlFormControl()
    }

    editAppItem() {
        if ((this.appUrlFormControl.type.value &&
            (this.appUrlFormControl.certificationName.value != '' && this.appUrlFormControl.type.value == this.typeList[2]) &&
            this.appUrlFormControl.redirectTo.value && this.appUrlFormControl.url.value) ||
            (this.appUrlFormControl.type.value != this.typeList[2] && this.appUrlFormControl.redirectTo.value)) {
            this.addedUrlsList.setControl(this.selectedAppIndex,
                this.fb.group({
                    type: this.appUrlFormControl.type.value,
                    title: this.appUrlFormControl.title.value,
                    alterText: this.appUrlFormControl.alterText.value,
                    url: this.appUrlFormControl.url.value,
                    certificationName: this.appUrlFormControl.certificationName.value,
                    redirectTo: this.appUrlFormControl.redirectTo.value
                }))
            this.isEditAppDetailsEnabled = false
            this.resetAppUrlFormControl()
        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: Messages.PLEASE_FILL_REQUIRED_FIELDS
            });
        }
    }

    addAppItem() {
        if (this.appUrlFormControl.type.value &&
            ((this.appUrlFormControl.certificationName.value != '' && this.appUrlFormControl.type.value == this.typeList[2] &&
                this.appUrlFormControl.redirectTo.value && this.appUrlFormControl.url.value) ||
                (this.appUrlFormControl.type.value != this.typeList[2] && this.appUrlFormControl.redirectTo.value))) {

            let existItem = this.addedUrlsList.controls.find(app =>
            ((app.value['type'] == this.typeList[0] && this.appUrlFormControl.type.value == this.typeList[0]) ||
                (app.value['type'] == this.typeList[1] && this.appUrlFormControl.type.value == this.typeList[1])))
            if (!existItem) {
                this.addedUrlsList.push(
                    this.fb.group({
                        type: this.appUrlFormControl.type.value,
                        title: this.appUrlFormControl.title.value,
                        alterText: this.appUrlFormControl.alterText.value,
                        url: this.appUrlFormControl.url.value,
                        certificationName: this.appUrlFormControl.certificationName.value,
                        redirectTo: this.appUrlFormControl.redirectTo.value
                    })
                );
            }
            else {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: Messages.TYPE_ALREADY_EXISTS
                });
            }
            this.resetAppUrlFormControl()
        }
        else {
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: Messages.PLEASE_FILL_REQUIRED_FIELDS
            });
        }
    }

    onSelectType() {
        Object.keys(this.appUrlFormControl).forEach(key => {
            if (key !== 'type') {
                this.appUrlFormControl[key].reset();
            }
        });

    }

    resetAppUrlFormControl() {
        this.appUrlFormControl.type.reset()
        this.appUrlFormControl.title.reset()
        this.appUrlFormControl.alterText.reset()
        this.appUrlFormControl.url.setValue('')
        this.appUrlFormControl.certificationName.reset()
        this.appUrlFormControl.redirectTo.reset()
    }

    async fileSave(event: any, type: any) {
        let isImageValid
        if (event.target instanceof HTMLInputElement && event.target.files) {
            let file = event.target.files[0];
            let isValid = await this.common.isValidFileType(event)
            if (!isValid) { return; }
            if (type === 'companyIcon') {
                isImageValid = true
            }
            else {
                isImageValid = await this.common.validateImageDimensions(file, type);
            }
            if (!isImageValid) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: Messages.INVALID_IMAGE_SIZE
                });
                return;
            }
            try {
                const result = await uploadData({
                    key: file.name,
                    data: file,
                    options: {
                        accessLevel: 'guest'
                    }
                }).result;
                let imageUrl = await this.common.getS3Url(file)
                if (type == 'primaryLogo') {
                    this.companyDetailsForm.patchValue({
                        primaryLogo: imageUrl
                    });
                } else if (type == 'secondaryLogo') {
                    this.companyDetailsForm.patchValue({
                        secondaryLogo: imageUrl
                    });
                } else if (type == 'companyIcon') {
                    this.companyDetailsForm.patchValue({
                        iconUrl: imageUrl
                    });
                } else if (type == 'payment') {
                    this.paymentForm.patchValue({
                        logoUrl: imageUrl
                    });
                } else if (type == 'mobileApp') {
                    this.appUrlFormControl.alterText.setValue(result.key)
                    this.appUrlFormControl.title.setValue(result.key)
                    this.appUrlFormControl.url.setValue(imageUrl)
                }
            } catch (error) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: Messages.SOMETHING_WENT_WRONG
                });
            }
        }
    }

    resetPaymentForm() {
        this.paymentForm.reset()
        this.paymentForm.patchValue({ logoUrl: '' })
        this.defaultPaymentMethod = null
    }

    toggleRow() {
        this.resetPaymentForm()
        this.isAddPayment = !this.isAddPayment;
    }

    cancelForm() {
        this.isAddPayment = false;
        this.isEditPayment = false
    }

    addCompanyDetails() {
        if (this.companyDetailsForm.valid) {
            this.awsApi.loading.next(true);
            let id = uuidv4();
            let primaryLogoUrl = this.companyDetailsForm.controls.primaryLogo.value;
            let secondaryLogoUrl = this.companyDetailsForm.controls.secondaryLogo.value;
            let primaryLogo = { url: primaryLogoUrl, title: '', alterText: '', description: '', type: null };
            let secondaryLogo = { url: secondaryLogoUrl, title: '', alterText: '', description: '', type: null };
            if (this.companyDetails.length == 0 || this.companyDetails.length == null) {
                let data = {
                    pk: 'COMP#',
                    id: id,
                    primaryLogo: primaryLogo,
                    secondaryLogo: secondaryLogo,
                    companyName: this.companyDetailsForm.controls.companyName.value,
                    webSite: this.companyDetailsForm.controls.webSite.value,
                    phoneNumber: this.companyDetailsForm.controls.phoneNumber.value,
                    email: this.companyDetailsForm.controls.email.value,
                    address: {
                        addressLine1: this.companyDetailsForm.controls.addressLine1.value,
                        addressLine2: this.companyDetailsForm.controls.addressLine2.value,
                        state: this.companyDetailsForm.controls.state.value,
                        city: this.companyDetailsForm.controls.city.value,
                        postalCode: this.companyDetailsForm.controls.zipCode.value,
                        country: this.companyDetailsForm.controls.country.value,
                    },
                    status: true,
                    currency: this.companyDetailsForm.controls.currency.value,
                    categorySequenced: this.companyDetailsForm.controls.categorySequenced.value,
                    mobileAppUrls: this.addedUrlsList.value,
                    seoDescription: this.companyDetailsForm.controls.seoDescription.value,
                    copyrightText: this.companyDetailsForm.controls.copyrightText.value,
                    daysToReturn: parseInt(this.companyDetailsForm.controls.daysToReturn.value),
                    brandName: this.companyDetailsForm.controls.brandName.value,
                    iconUrl: this.companyDetailsForm.controls.iconUrl.value
                };
                this.awsApi.client.graphql({
                    query: mutation.createCompanyDetails,
                    variables: {
                        input: {
                            ...data
                        }
                    }
                }).then((response) => {
                    this.awsApi.loading.next(false);
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: 'company created successfully!'
                    });
                })
                    .catch((error) => {
                        this.awsApi.loading.next(false);
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: Messages.SOMETHING_WENT_WRONG
                        });
                    });
            } else {
                let item = {
                    pk: this.companyDetails[0].pk,
                    id: this.companyDetails[0].id,
                    primaryLogo: primaryLogo,
                    secondaryLogo: secondaryLogo,
                    companyName: this.companyDetailsForm.controls.companyName.value,
                    webSite: this.companyDetailsForm.controls.webSite.value,
                    phoneNumber: this.companyDetailsForm.controls.phoneNumber.value,
                    email: this.companyDetailsForm.controls.email.value,
                    address: {
                        addressLine1: this.companyDetailsForm.controls.addressLine1.value,
                        addressLine2: this.companyDetailsForm.controls.addressLine2.value,
                        state: this.companyDetailsForm.controls.state.value,
                        city: this.companyDetailsForm.controls.city.value,
                        postalCode: this.companyDetailsForm.controls.zipCode.value,
                        country: this.companyDetailsForm.controls.country.value,
                    },
                    status: this.isActive,
                    categorySequenced: this.companyDetailsForm.controls.categorySequenced.value,
                    seoDescription: this.companyDetailsForm.controls.seoDescription.value,
                    copyrightText: this.companyDetailsForm.controls.copyrightText.value,
                    mobileAppUrls: this.addedUrlsList.value,
                    daysToReturn: parseInt(this.companyDetailsForm.controls.daysToReturn.value),
                    brandName: this.companyDetailsForm.controls.brandName.value,
                    iconUrl: this.companyDetailsForm.controls.iconUrl.value
                };
                this.awsApi.client.graphql({
                    query: mutation.updateCompanyDetails,
                    variables: {
                        input: {
                            ...item
                        }
                    }
                }).then((response) => {
                    this.awsApi.loading.next(false);
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Company details updated successfully!'
                    });
                })
                    .catch((error) => {
                        this.awsApi.loading.next(false);
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: Messages.SOMETHING_WENT_WRONG
                        });
                    });
            }
        } else {
            this.awsApi.loading.next(false);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: Messages.PLEASE_FILL_REQUIRED_FIELDS
            });
        }
    }

    onSwitchChange() {
        this.isActive = !this.isActive;
    }

    cancelEmailSetting() {
        this.editEmailSetting = false
        this.emailSettingForm.reset()
    }

    toggleButtons(tabId: string) {
        if (tabId === 'v-pills-themes') {
            this.showThemesButton = true;
            this.showEmailButton = false;
            this.showCompanyButton = false;
            this.showPaymentButton = false;
        } else if (tabId === 'v-pills-email-settings') {
            this.showThemesButton = false;
            this.showEmailButton = true;
            this.showCompanyButton = false;
            this.showPaymentButton = false;
        }
        else if (tabId === 'v-pills-company-details') {
            this.showThemesButton = false;
            this.showEmailButton = false;
            this.showCompanyButton = true;
            this.showPaymentButton = false;
        }
        else if (tabId === 'v-pills-payments') {
            this.showThemesButton = false;
            this.showEmailButton = false;
            this.showCompanyButton = false;
            this.showPaymentButton = true;
        } else {
            this.showThemesButton = false;
            this.showEmailButton = false;
            this.showCompanyButton = false;
            this.showPaymentButton = false;
        }
    }

    deletePaymentMethod(paymentMethod) {
        let data = {
            pk: 'PAYMODE#',
            id: paymentMethod.id
        }
        this.awsApi.client.graphql({
            query: mutation.deletePaymentMode,
            variables: {
                input: {
                    ...data
                }
            }
        }).then((response) => {
            this.awsApi.loading.next(false);
            this.getAllSettings()
            this.resetPaymentForm()
            this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Payment method deleted successfully!'
            });
        }).catch((error) => {
            this.awsApi.loading.next(false);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: Messages.SOMETHING_WENT_WRONG
            });
        });
    }

    getPaymentModeDetails(id) {
        return this.awsApi.client.graphql({
            query: query.listPaymentModes,
            variables: {
                pk: 'PAYMODE#',
                id: { eq: id },
                filter: {},
                limit: 9999,
                nextToken: null,
                sortDirection: null
            }
        })
    }

    showEditPaymentMode(selectedPaymentMode) {
        this.isEditPayment = true
        this.isAddPayment = false
        this.autoFillPaymentMode(selectedPaymentMode.id)
    }

    autoFillPaymentMode(id: any) {
        this.selectedPaymentMode = this.paymentMethod.filter(item => item.id == id)[0]
        this.paymentForm.patchValue({
            provider: this.selectedPaymentMode.provider,
            logoUrl: this.selectedPaymentMode.logoUrl,
            stagingUrl: this.selectedPaymentMode.stagingUrl,
            productionUrl: this.selectedPaymentMode.productionUrl
        })
        this.paymentStatus = this.selectedPaymentMode.status

        this.defaultPaymentMethod = this.paymentMethodList.find(paymentMethodItem => paymentMethodItem.value == this.selectedPaymentMode.method)['value']
    }

    addPayment() {
        let id = uuidv4();
        let data = {
            pk: 'PAYMODE#',
            id: id,
            method: this.paymentForm.controls.paymentMethod.value,
            provider: this.paymentForm.controls.provider.value,
            logoUrl: this.paymentForm.controls.logoUrl.value,
            stagingUrl: this.paymentForm.controls.stagingUrl.value,
            productionUrl: this.paymentForm.controls.productionUrl.value,
            status: Status.ACTIVE,
            createdAt: new Date().toISOString()
        }
        this.awsApi.client.graphql({
            query: mutation.createPaymentMode,
            variables: {
                input: {
                    ...data
                }
            }
        }).then((response) => {
            this.awsApi.loading.next(false);
            this.resetPaymentForm()
            this.isAddPayment = false
            this.getAllSettings()
            this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Payment method created successfully!'
            });
        }).catch((error) => {
            this.awsApi.loading.next(false);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: Messages.SOMETHING_WENT_WRONG
            });
        });
    }

    editPayment() {
        let data = {
            pk: 'PAYMODE#',
            id: this.selectedPaymentMode.id,
            method: this.paymentForm.controls.paymentMethod.value,
            provider: this.paymentForm.controls.provider.value,
            logoUrl: this.paymentForm.controls.logoUrl.value,
            stagingUrl: this.paymentForm.controls.stagingUrl.value,
            productionUrl: this.paymentForm.controls.productionUrl.value,
            status: this.paymentStatus
        }
        this.awsApi.client.graphql({
            query: mutation.updatePaymentMode,
            variables: {
                input: {
                    ...data
                }
            }
        }).then((response) => {
            this.awsApi.loading.next(false);
            this.resetPaymentForm()
            this.isEditPayment = false
            this.getAllSettings()
            this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Payment method updated successfully!'
            });
        }).catch((error) => {
            this.awsApi.loading.next(false);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: Messages.SOMETHING_WENT_WRONG
            });
        });
    }

    validateEmailSettingInputs() {
        this.validEmailInput = true
        this.emailToList = []
        let existingEmailDetailsList = []
        let emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        let emailInput = this.emailSettingForm.controls.emailTo.value;
        if (this.editEmailSetting) {
            existingEmailDetailsList = this.listSettings.filter((item) => item.notificationType == this.emailSettingForm.controls.type.value && item.id != this.selectedEmailSettingItem.id)
        } else {
            existingEmailDetailsList = this.listSettings.filter((item) => item.notificationType == this.emailSettingForm.controls.type.value)
        }
        if (existingEmailDetailsList.length > 0) {
            this.validEmailInput = false
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: Messages.NOTIFICATION_TYPE_EXISTS
            });
        } else {
            if (emailInput.toString().indexOf(',') <= -1 &&
                typeof emailInput === 'string' && emailInput.trim()) {
                let trimmedEmailId = emailInput.trim()
                if (emailPattern.test(trimmedEmailId)) {
                    if (!this.emailToList.includes(trimmedEmailId)) {
                        this.emailToList.push(trimmedEmailId);
                    }
                } else {
                    this.validEmailInput = false
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: Messages.INVALID_EMAIL_INPUTS
                    });
                }
            } else if (emailInput.toString().indexOf(',') > -1) {
                emailInput.split(',').forEach((item) => {
                    if (item.trim() && emailPattern.test(item.trim())) {
                        let trimmedEmailId = item.trim()
                        if (!this.emailToList.includes(trimmedEmailId)) {
                            this.emailToList.push(trimmedEmailId);
                        }
                    }
                    else {
                        this.validEmailInput = false
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: Messages.INVALID_EMAIL_INPUTS
                        });
                    }
                });
            }
        }
    }

    addEmailSetting() {
        this.validateEmailSettingInputs()
        if (this.validEmailInput && this.emailToList.length > 0) {
            if (this.editEmailSetting) {
                let data = {
                    pk: 'SETT#',
                    id: this.selectedEmailSettingItem.id,
                    notificationType: this.emailSettingForm.controls.type.value,
                    emailTo: this.emailToList
                }
                this.awsApi.client.graphql({
                    query: mutation.updateSettings,
                    variables: {
                        input: {
                            ...data
                        }
                    }
                }).then((response) => {
                    this.awsApi.loading.next(false);
                    this.emailSettingForm.reset()
                    this.getAllSettings()
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Email setting updated successfully!'
                    });
                }).catch((error) => {
                    this.awsApi.loading.next(false);
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: Messages.SOMETHING_WENT_WRONG
                    });
                });
            }
            else {
                let data = {
                    pk: 'SETT#',
                    id: uuidv4(),
                    notificationType: this.emailSettingForm.controls.type.value,
                    emailTo: this.emailToList,
                }
                this.awsApi.client.graphql({
                    query: mutation.createSettings,
                    variables: {
                        input: {
                            ...data
                        }
                    }
                }).then((response) => {
                    this.awsApi.loading.next(false);
                    this.emailSettingForm.reset()
                    this.getAllSettings()
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Email setting added successfully!'
                    });
                }).catch((error) => {
                    this.awsApi.loading.next(false);
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: Messages.SOMETHING_WENT_WRONG
                    });
                });
            }
        }
    }

    editEmailSettings(item) {
        this.selectedEmailSettingItem = item
        this.editEmailSetting = true
        this.emailSettingForm.patchValue({
            type: item.notificationType,
            emailTo: (item.emailTo).join(',')
        })
    }

    setFont(selectedIndex, type) {
        if (type === 'web') {
            this.webFonts.forEach(font => { font.checked = false; });
            this.webFonts[selectedIndex]['checked'] = true
            this.themeForm.patchValue({ font: this.webFonts[selectedIndex]['checked'] ? this.webFonts[selectedIndex]['value'] : '' })
        } else {
            this.mobileFonts.forEach(font => { font.checked = false; });
            this.mobileFonts[selectedIndex]['checked'] = true
            this.mobileThemeForm.patchValue({ font: this.mobileFonts[selectedIndex]['checked'] ? this.mobileFonts[selectedIndex]['value'] : '' })
        }
    }

    toggleColorMode(mode: any) {
        if (mode == 'Light') {
            this.themeForm.patchValue({ preferredTheme: '#ffffff' });
        }
        else {
            this.themeForm.patchValue({ preferredTheme: '#000000' });
        }
    }

    onChangeColor(color: any, type: any, selectedMode: any) {
        switch (type) {
            case 'primary button Text':
                selectedMode == 'mobile' ? this.mobileThemeForm.patchValue({ primaryButtonTextColor: color }) : this.themeForm.patchValue({ primaryButtonTextColor: color });
                break;
            case 'primary button Background':
                selectedMode == 'mobile' ? this.mobileThemeForm.patchValue({ primaryButtonBackgroundColor: color }) : this.themeForm.patchValue({ primaryButtonBackgroundColor: color });
                break;
            case 'secondary button Text':
                selectedMode == 'mobile' ? this.mobileThemeForm.patchValue({ secondaryButtonTextColor: color }) : this.themeForm.patchValue({ secondaryButtonTextColor: color });
                break;
            case 'secondary button Background':
                selectedMode == 'mobile' ? this.mobileThemeForm.patchValue({ secondaryButtonBackgroundColor: color }) : this.themeForm.patchValue({ secondaryButtonBackgroundColor: color });
                break;
            case 'navigation Menu Color':
                this.themeForm.patchValue({ navigationMenuColor: color });
                break;
            case 'navigation Sub Menu Color':
                this.themeForm.patchValue({ navigationSubMenuColor: color });
                break;
            default: { }
        }
    }

    onColorChange(event: any = null) {
        if (event) {
            const color = event.target.value;
            this.themeForm.patchValue({ preferredTheme: color });
        }
    }

    async setThemeObject(type: any): Promise<any> {
        return type === 'web' ?
            {
                headerBackgroundColor: this.themeForm.controls.headerBackgroundColor.value,
                headerTextColor: this.themeForm.controls.headerTextColor.value,
                headerIconColor: this.themeForm.controls.headerIconColor.value,
                footerBackgroundColor: this.themeForm.controls.footerBackgroundColor.value,
                footerTextColor: this.themeForm.controls.footerTextColor.value,
                footerHeadingColor: this.themeForm.controls.footerHeadingColor.value,
                font: this.themeForm.controls.font.value,
                primaryButtonBackgroundColor: this.themeForm.controls.primaryButtonBackgroundColor.value,
                primaryButtonTextColor: this.themeForm.controls.primaryButtonTextColor.value,
                secondaryButtonBackgroundColor: this.themeForm.controls.secondaryButtonBackgroundColor.value,
                secondaryButtonTextColor: this.themeForm.controls.secondaryButtonTextColor.value,
                navigationMenu: {
                    title: 'Navigation Menu',
                    fontWeight: this.themeForm.controls.navigationMenuFontWeight.value,
                    fontSize: (this.themeForm.controls.navigationMenuFontSize.value) + 'px'
                },
                navigationMenuColor: this.themeForm.controls.navigationMenuColor.value,
                navigationSubMenu: {
                    title: 'Navigation Sub Menu',
                    fontWeight: this.themeForm.controls.navigationSubMenuFontWeight.value,
                    fontSize: (this.themeForm.controls.navigationSubMenuFontSize.value) + 'px'
                },
                navigationSubMenuColor: this.themeForm.controls.navigationSubMenuColor.value,
                productTitle: {
                    title: 'Product Title',
                    fontWeight: this.themeForm.controls.productTitleFontWeight.value,
                    fontSize: (this.themeForm.controls.productTitleFontSize.value) + 'px'
                },
                productSubTitle: {
                    title: 'Product Sub Title',
                    fontWeight: this.themeForm.controls.productSubTitleFontWeight.value,
                    fontSize: (this.themeForm.controls.productSubTitleFontSize.value) + 'px'
                },
                productPrice: {
                    title: 'Product Price',
                    fontWeight: this.themeForm.controls.productPriceFontWeight.value,
                    fontSize: (this.themeForm.controls.productPriceFontSize.value) + 'px'
                },
                productDetailName: {
                    title: 'Product Detail Name',
                    fontWeight: this.themeForm.controls.productDetailNameFontWeight.value,
                    fontSize: (this.themeForm.controls.productDetailNameFontSize.value) + 'px'
                },
                productDetailSubTitle: {
                    title: 'Product Detail Sub Title',
                    fontWeight: this.themeForm.controls.productDetailSubTitleFontWeight.value,
                    fontSize: (this.themeForm.controls.productDetailSubTitleFontSize.value) + 'px'
                },
                productDetailPrice: {
                    title: 'Product Detail Price',
                    fontWeight: this.themeForm.controls.productDetailPriceFontWeight.value,
                    fontSize: (this.themeForm.controls.productDetailPriceFontSize.value) + 'px'
                },
                productDescriptionHeading: {
                    title: 'Product Description Heading',
                    fontWeight: this.themeForm.controls.productDescriptionHeadingFontWeight.value,
                    fontSize: (this.themeForm.controls.productDescriptionHeadingFontSize.value) + 'px'
                },
                productDescription: {
                    title: 'Product Description',
                    fontWeight: this.themeForm.controls.productDescriptionFontWeight.value,
                    fontSize: (this.themeForm.controls.productDescriptionFontSize.value) + 'px'
                },
                customerReviewsTitle: {
                    title: 'Customer Reviews Title',
                    fontWeight: this.themeForm.controls.customerReviewsTitleFontWeight.value,
                    fontSize: (this.themeForm.controls.customerReviewsTitleFontSize.value) + 'px'
                },
                customerReviewsDetails: {
                    title: 'Customer Reviews Detail',
                    fontWeight: this.themeForm.controls.customerReviewsDetailsFontWeight.value,
                    fontSize: (this.themeForm.controls.customerReviewsDetailsFontSize.value) + 'px'
                },
                notificationsTitle: {
                    title: 'Notifications Title',
                    fontWeight: this.themeForm.controls.notificationsTitleFontWeight.value,
                    fontSize: (this.themeForm.controls.notificationsTitleFontSize.value) + 'px'
                },
                notificationsSubTitle: {
                    title: 'Notifications Sub Title',
                    fontWeight: this.themeForm.controls.notificationsSubTitleFontWeight.value,
                    fontSize: (this.themeForm.controls.notificationsSubTitleFontSize.value) + 'px'
                },
                profileHeading: {
                    title: 'Profile Heading',
                    fontWeight: this.themeForm.controls.profileHeadingFontWeight.value,
                    fontSize: (this.themeForm.controls.profileHeadingFontSize.value) + 'px'
                },
                profileContent: {
                    title: 'Profile Content',
                    fontWeight: this.themeForm.controls.profileContentFontWeight.value,
                    fontSize: (this.themeForm.controls.profileContentFontSize.value) + 'px'
                },
                contentTitle: {
                    title: 'Content Title',
                    fontWeight: this.themeForm.controls.contentTitleFontWeight.value,
                    fontSize: (this.themeForm.controls.contentTitleFontSize.value) + 'px'
                },
                contentDescription: {
                    title: 'Content Description',
                    fontWeight: this.themeForm.controls.contentDescriptionFontWeight.value,
                    fontSize: (this.themeForm.controls.contentDescriptionFontSize.value) + 'px'
                },
                titleHeader: {
                    title: 'Title Header',
                    fontWeight: this.themeForm.controls.titleHeaderFontWeight.value,
                    fontSize: (this.themeForm.controls.titleHeaderFontSize.value) + 'px'
                },
                titleDetails: {
                    title: 'Title Details',
                    fontWeight: this.themeForm.controls.titleDetailsFontWeight.value,
                    fontSize: (this.themeForm.controls.titleDetailsFontSize.value) + 'px'
                },
                primaryButton: {
                    title: 'Primary Button',
                    fontWeight: this.themeForm.controls.primaryButtonFontWeight.value,
                    fontSize: (this.themeForm.controls.primaryButtonFontSize.value) + 'px'
                },
                secondaryButton: {
                    title: 'Secondary Button',
                    fontWeight: this.themeForm.controls.secondaryButtonFontWeight.value,
                    fontSize: (this.themeForm.controls.secondaryButtonFontSize.value) + 'px'
                }
            } :
            Object.fromEntries(
                Object.keys(this.mobileThemeForm.controls).map(controlName => [
                    controlName,
                    this.mobileThemeForm.controls[controlName].value
                ])
            )
    }

    async saveTheme() {
        this.awsApi.loading.next(true);
        if (this.companyDetails.length > 0) {
            let data = {
                pk: this.companyDetails[0].pk,
                id: this.companyDetails[0].id,
                theme: await this.setThemeObject('web'),
                mobileTheme: await this.setThemeObject('mobile')
            }
            this.awsApi.client.graphql({
                query: mutation.updateCompanyDetails,
                variables: {
                    input: {
                        ...data
                    }
                }
            }).then(async (response) => {
                await this.getAllSettings()
                this.awsApi.loading.next(false);
                this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: Messages.THEME_UPDATED
                });
            }).catch((error) => {
                this.awsApi.loading.next(false);
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: Messages.SOMETHING_WENT_WRONG
                });
            });
        } else {
            let id = uuidv4();
            let data = {
                pk: 'COMP#',
                id: id,
                theme: await this.setThemeObject('web'),
                mobileTheme: await this.setThemeObject('mobile'),
                createdAt: new Date().toISOString()
            }
            this.awsApi.client.graphql({
                query: mutation.createCompanyDetails,
                variables: {
                    input: {
                        ...data
                    }
                }
            }).then(async (response) => {
                this.awsApi.loading.next(false);
                this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: Messages.THEME_ADDED
                });
            }).catch((error) => {
                this.awsApi.loading.next(false);
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: Messages.SOMETHING_WENT_WRONG
                });
            });
        }
    }

    deleteEmailSettings(item) {
        this.awsApi.loading.next(true);
        let inputData = {
            pk: item.pk,
            id: item.id
        }
        this.awsApi.client
            .graphql({
                query: mutation.deleteSettings,
                variables: {
                    input: {
                        ...inputData
                    }
                }
            }).then((response) => {
                this.awsApi.loading.next(false);
                this.getAllSettings()
                this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: Messages.EMAIL_DELETED_SUCCESS
                });
            }).catch((error) => {
                this.awsApi.loading.next(false);
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: Messages.SOMETHING_WENT_WRONG
                });
            });
    }

    deleteLogo(type) {
        if (type == 'primary') {
            this.companyDetailsForm.controls.primaryLogo.reset()
            this.companyDetailsForm.patchValue({
                primaryLogo: ''
            })
        }
        if (type == 'secondary') {
            this.companyDetailsForm.controls.secondaryLogo.reset()
            this.companyDetailsForm.patchValue({
                secondaryLogo: ''
            })
        }
        if (type == 'companyIcon') {
            this.companyDetailsForm.controls.iconUrl.reset()
            this.companyDetailsForm.patchValue({
                iconUrl: ''
            })
        }
        if (type == 'payment') {
            this.paymentForm.controls.logoUrl.reset()
            this.paymentForm.patchValue({ logoUrl: '' })
        }
        if (type == 'mobileApp') {
            this.appUrlFormControl.url.reset()
            this.appUrlFormControl.alterText.reset()
            this.appUrlFormControl.title.reset()
        }
    }

    preventE(event) {
        if (event.which === 101) {
            event.preventDefault();
        }
    }
}