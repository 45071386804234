import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import * as mutation from 'graphql/mutations';
import { v4 as uuidv4 } from 'uuid';
import { ApiService } from '@services/api/api.service';
import { Messages } from '@/Toaster-messages';
import { ROUTES } from '@/redirect-routes';
import * as query from 'graphql/queries';
import { FaqTypeList } from '@/common-list';

@Component({
  selector: 'app-add-faq',
  templateUrl: './add-faq.component.html',
  styleUrl: './add-faq.component.scss'
})
export class AddFaqComponent {
  type: any
  faqId: any
  selectedFaq: any
  faqTypeList = FaqTypeList
  faqs: any
  addFaqForm = this.fb.group({
    type: ['', [Validators.required]],
    seqNo: ['', [Validators.required]],
    faqName: ['', [Validators.required, Validators.pattern(/^(?!\s*$)(?=.*[a-zA-Z])[a-zA-Z0-9\s]+$/)]]
  })

  constructor(private router: Router, private awsApi: ApiService, private activatedRoute: ActivatedRoute, private fb: FormBuilder, private messageService: MessageService) { }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.type = params.get('type') ?? ''
      this.faqId = params.get('id') ?? ''
      if (this.type != '') {
        this.getFaq().then((response) => {
          this.awsApi.loading.next(false);
          this.selectedFaq = response.data.getFAQ
          this.autoFillEditForm()
        }).catch((error) => {
          this.awsApi.loading.next(false);
        });
      }
      this.listFaqs().then((response) => {
        this.faqs = response.data.listFAQS.items
      })
    })
  }

  autoFillEditForm() {
    this.addFaqForm.patchValue({
      type: this.selectedFaq.type,
      seqNo: this.selectedFaq.sequenceNumber,
      faqName: this.selectedFaq.name
    })
  }

  async listFaqs() {
    return await this.awsApi.client
      .graphql({
        query: query.listFAQS
      })
  }

  async getFaq() {
    return await this.awsApi.client.graphql({
      query: query.getFAQ,
      variables: {
        pk: 'FAQ#',
        id: this.faqId
      }
    })
  }

  sequenceNumberExists(type, number, isEdit: boolean = false): boolean {
    if (isEdit) {
      let sequenceNumbers = this.faqs
        .filter((faqItem) => { return faqItem.type == type && faqItem.id != this.faqId; })
        .map((item) => { return item.sequenceNumber; })
      return sequenceNumbers.includes(number)
    }
    else {
      let sequenceNumbers = this.faqs
        .filter((faqItem) => { return faqItem.type == type; })
        .map((item) => { return item.sequenceNumber; })
      return sequenceNumbers.includes(number)
    }
  }

  addFaq() {
    if (this.sequenceNumberExists(this.addFaqForm.controls.type.value, parseInt(this.addFaqForm.controls.seqNo.value), true)) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SEQUENCE_NUMBER_EXISTS
      })
    }
    else {
      this.awsApi.loading.next(true);
      let data = {
        pk: 'FAQ#',
        id: uuidv4(),
        type: this.addFaqForm.controls.type.value,
        sequenceNumber: parseInt(this.addFaqForm.controls.seqNo.value),
        name: this.addFaqForm.controls.faqName.value,
        FAQSections: [],
        searchKey: this.addFaqForm.controls.faqName.value.replace(/\s/g, "").toLowerCase(),
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      }
      this.awsApi.client.graphql({
        query: mutation.createFAQ,
        variables: {
          input: {
            ...data
          }
        }
      }).then((response) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: Messages.FAQ_CREATED
        });
        this.addFaqForm.reset()
        this.router.navigate([ROUTES.LIST_FAQ]);
      }).catch((error) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      });
    }
  }

  editFaq() {
    if (this.sequenceNumberExists(this.addFaqForm.controls.type.value, parseInt(this.addFaqForm.controls.seqNo.value), true)) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SEQUENCE_NUMBER_EXISTS
      })
    }
    else {
      this.awsApi.loading.next(true);
      let data = {
        pk: 'FAQ#',
        id: this.faqId,
        type: this.addFaqForm.controls.type.value,
        sequenceNumber: parseInt(this.addFaqForm.controls.seqNo.value),
        name: this.addFaqForm.controls.faqName.value,
        searchKey: this.addFaqForm.controls.faqName.value.replace(/\s/g, "").toLowerCase(),
        updatedAt: new Date().toISOString()
      };

      this.awsApi.client.graphql({
        query: mutation.updateFAQ,
        variables: {
          input: {
            ...data
          }
        }
      }).then((response) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: Messages.FAQ_EDITED
        });
        this.addFaqForm.reset()
        this.router.navigate([ROUTES.LIST_FAQ]);
      }).catch((error) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      });
    }
  }

  redirectToListFaqsPage() {
    this.router.navigate([ROUTES.LIST_FAQ]);
  }

  refreshPage() {
    this.addFaqForm.reset()
  }

  preventE(event) {
    if (event.which === 101) {
      event.preventDefault();
    }
  }
}