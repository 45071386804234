import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import { MessageService } from 'primeng/api';
import * as mutation from 'graphql/mutations';
import { CommonService } from '@services/common/common.service';
import { Messages } from '@/Toaster-messages';

@Component({
  selector: 'app-saved-carts',
  templateUrl: './saved-carts.component.html',
  styleUrl: './saved-carts.component.scss'
})
export class SavedCartsComponent implements OnInit {
  deleteModalToggle: any;
  allCartItems: any = []
  cartList: any = []
  copyCartList: any = []
  groupedCarts: any = []
  filterObject: any = {}
  searchKey: any
  searchText: any = ''
  deleteCart: any
  access: any = {}
  currency: any
  id: any = []
  deleteSuccess = false

  constructor(private common: CommonService, private router: Router, private awsApi: ApiService, private messageService: MessageService) { }

  async ngOnInit() {
    this.currency = await this.common.getCurrencyOfCompany()
    this.getRoleManagement()
    await this.getAllCart()
  }

  async getAllCart() {
    this.awsApi.loading.next(true);
    let fieldsToFetch = ['id', 'pk', 'userId', 'cartURL', 'salesPrice', 'selectedQuantity', 'expiryDate', 'createdAt', 'userMail', 'userName']
    this.allCartItems = await this.common.getCart(fieldsToFetch)
    const groupedOrders: { [key: string]: { id: string[], userId: string, searchKey: string, cartUrl: string; customerName: String, email: string; addedSince: string, cartTotal: number, expiringOn: string } } = {};

    this.allCartItems.forEach((item) => {
      if (groupedOrders[item.userId]) {
        groupedOrders[item.userId].cartTotal += (item.salesPrice * item.selectedQuantity);
        groupedOrders[item.userId].id.push(item.id)
      } else {
        groupedOrders[item.userId] = {
          id: [item.id],
          userId: item.userId,
          searchKey: ((((item.userName)?.toLowerCase())?.replace(/\s{2,}/g, ' '))?.trim())?.replace(/\s+/g, '') + '#' +
            ((((item.userMail)?.toLowerCase())?.replace(/\s{2,}/g, ' '))?.trim())?.replace(/\s+/g, ''),
          cartUrl: item.cartURL,
          customerName: item.userName ? item.userName : 'N/A',
          email: item.userMail ? item.userMail : 'N/A',
          addedSince: item.createdAt,
          cartTotal: (item.salesPrice * item.selectedQuantity),
          expiringOn: item.expiryDate ? item.expiryDate : 'N/A'
        };
      }
    });
    this.cartList = Object.values(groupedOrders).sort((a: any, b: any) => new Date(b.addedSince).getTime() - new Date(a.addedSince).getTime())
    this.copyCartList = [...this.cartList]
    this.awsApi.loading.next(false);
  }

  async getRoleManagement() {
    try {
      await this.common.getPermissionsOfUser('Orders', 'Manage Carts');
      this.access = this.common.access;
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  search(searchText) {
    if (searchText) {
      this.searchKey = ((((searchText).toLowerCase()).replace(/\s{2,}/g, ' ')).trim()).replace(/\s+/g, '')
      this.cartList = this.copyCartList.filter((item) => (item.searchKey).includes(this.searchKey))
    } else {
      this.cartList = this.copyCartList
    }
  }

  deleteCartItem(item: any) {
    this.deleteModalToggle = true;
    this.deleteCart = item;
  }

  async deletedCartItems(): Promise<Boolean> {
    try {
      (this.deleteCart.id || []).forEach(item => {
        let data = {
          pk: 'CART#',
          id: item
        };
        this.awsApi.client
          .graphql({
            query: mutation.deleteCart,
            variables: {
              input: {
                ...data
              }
            }
          })
          .then((response) => {
          })
          .catch((error) => {
          });
      })
      await this.getAllCart();
      return true
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
      return false
    }
  }

  async confirmDeleteCart() {
    this.awsApi.loading.next(true);
    this.deleteSuccess = false
    this.deleteModalToggle = false;
    if (await this.deletedCartItems()) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Cart deleted successfully!'
      });
    }
  }
}
