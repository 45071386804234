<section id="reportListSec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 my-auto">
                <div class="card m-2" *ngIf="reportsList.length>0">
                    <div class="card-body">
                        <!-- report-list-heading-row start -->
                        <div class="row report-list-heading-row">
                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 my-auto">
                                <div class="text-center text-md-left mt-2 mt-md-0">
                                    <h2>Reports</h2>
                                    <p class="mb-2 mb-md-0" *ngIf="reportsList.length>1">{{reportsList.length}} Results
                                        Found</p>
                                    <p class="mb-2 mb-md-0" *ngIf="reportsList.length==1">1 Results Found</p>
                                </div>
                            </div>
                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 my-auto">
                                <div
                                    class="has-search-d-flex justify-content-center justify-content-md-end align-items-start align-items-md-center flex-nowrap flex-md-wrap">
                                    <app-common-search (onEnter)="search($event)"></app-common-search>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- report-list-heading-row end -->
                <div class="card mb-1 table-card" *ngFor="let reportItem of reportsList">
                    <div class="card-body">
                        <!-- report-list-table-row start -->
                        <div class="row report-list-table-row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="table-row action-table-responsive table-responsive">
                                    <table class="table table-borderless">
                                        <tbody>
                                            <tr>
                                                <td (click)="redirectToReportView(reportItem)">
                                                    <div>
                                                        <h6>{{reportItem.reportName}}</h6>
                                                        <label>{{reportItem.description}}</label>
                                                    </div>
                                                </td>
                                                <td class="text-end">
                                                    <i class="fas fa-chevron-right"
                                                        (click)="redirectToReportView(reportItem)"></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div *ngIf="reportsList.length==0"
                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
                                <app-no-result-found></app-no-result-found>
                            </div>
                        </div>
                        <!-- report-list-table-row end -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>