import { Component } from '@angular/core';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { v4 as uuidv4 } from 'uuid';
import { ApiService } from '@services/api/api.service';
import { UserRolesList } from '@/common-list';
import { Status } from 'API';
import { Messages } from '@/Toaster-messages';
import { ROUTES } from '@/redirect-routes';
import * as query from 'graphql/queries';
import { CommonService } from '@services/common/common.service';
import * as mutation from 'graphql/mutations';
import { parsePhoneNumber, getCountries, getCountryCallingCode, CountryCode } from 'libphonenumber-js';
import countries from 'i18n-iso-countries';
import i18n from 'i18n-iso-countries/langs/en.json';
countries.registerLocale(i18n);

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrl: './user-add.component.scss'
})
export class UserAddComponent {
  public Editor = ClassicEditor;
  userRolesList = UserRolesList
  users: any = []
  multiFactorAuth: boolean = false
  emailNotification: boolean = false
  passwordChange: boolean = false
  userPresent: Boolean
  selectedRole: any = null;
  lengthOfLastName: any
  access: any = {}
  userId: any
  type: any
  selectedUser: any = []
  defaultCountry: any
  countryCodes: { code: CountryCode; name: string; dialCode: string }[] = [];
  addUserForm = this.fb.group({
    firstName: ['', [Validators.required]],
    lastName: [''],
    companyName: ['', Validators.pattern(/^(?!\s*$)(?=.*[a-zA-Z])[a-zA-Z0-9\s]+$/)],
    email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
    country: [this.countryCodes.find((item) => item.code === 'QA'), [Validators.required]],
    phoneNumber: ['', [Validators.required]],
    role: ['', [Validators.required]],
    password: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]],
    confirmPassword: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]]
  });

  constructor(private activatedRoute: ActivatedRoute,
    private common: CommonService, private router: Router,
    private awsApi: ApiService, private _router: Router, private fb: FormBuilder,
    private messageService: MessageService) { }

  ngOnInit(): void {
    this.getRoleManagement()
    this.loadCountries();
    this.defaultCountry = this.countryCodes.find((item) => item.code === 'QA')
    this.awsApi.loading.next(true);
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.userId = params.get('id') ?? ''
      this.type = params.get('type') ?? ''
      this.getUsers().then((response) => {
        this.users = response
        if (this.type == 'edit') {
          this.selectedUser = this.users.filter((user) => user.id == this.userId)[0]
          this.autoFillData()
        }
        this.awsApi.loading.next(false);
      })
        .catch((error) => {
          this.awsApi.loading.next(false);
        });
    })
  }

  onSelectCountry() { }

  loadCountries() {
    this.countryCodes = getCountries()
      .filter(code => countries.getName(code, 'en'))
      .map(code => ({
        code,
        name: countries.getName(code, 'en'),
        dialCode: `+${getCountryCallingCode(code)}`,
      }));
  }

  autoFillData() {
    let code: any
    if (this.selectedUser.phoneNumber) {
      code = (parsePhoneNumber(this.selectedUser.phoneNumber)).country
      this.addUserForm.patchValue({
        country: this.countryCodes.find((item) => item.code === code)
      });
      this.defaultCountry = this.countryCodes.find((item) => item.code === code)
    }
    this.addUserForm.patchValue({
      firstName: this.selectedUser.firstName,
      lastName: this.selectedUser.lastName,
      companyName: this.selectedUser.companyName,
      phoneNumber: (this.selectedUser.phoneNumber).split(getCountryCallingCode(code))[1]
    })
    this.selectedRole = this.userRolesList
      .find((item) => item.value == this.selectedUser.role).id
    this.multiFactorAuth = this.selectedUser.multiFactorAuth
    this.emailNotification = this.selectedUser.emailNotification
  }

  async getUsers() {
    let items = [];
    let nextToken = null;
    do {
      const response = await this.awsApi.client.graphql({
        query: query.listUsers,
        variables: {
          limit: 650,
          nextToken: nextToken,
          sortDirection: null
        }
      })
      items = items.concat(response.data.listUsers.items);
      nextToken = response.data.listUsers.nextToken;
    } while (nextToken !== null)
    return items
  }

  async getRoleManagement() {
    this.awsApi.loading.next(true);
    try {
      await this.common.getPermissionsOfUser('Users', 'Manage Users');
      this.access = this.common.access;
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  toggleMultiFactorAuth() { this.multiFactorAuth = !this.multiFactorAuth }

  togglePasswordChange() { this.passwordChange = !this.passwordChange }

  toggleEmailNotification() { this.emailNotification = !this.emailNotification }

  isUserAlreadyPresent() {
    this.userPresent = this.users.filter((user: any) => {
      return user.email == this.addUserForm.controls.email.value;
    }).length > 0 ? true : false
  }

  async editUser() {
    if (this.addUserForm.controls.firstName.value && this.addUserForm.controls.phoneNumber.value && this.addUserForm.controls.role.value) {
      this.lengthOfLastName = this.addUserForm.controls.lastName.value ? this.addUserForm.controls.lastName.value.length : 0
      if (this.lengthOfLastName != 0 && this.lengthOfLastName < 2) {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.LASTNAME_SHOULD_HAVE_2_LETTERS
        });
      } else {
        this.awsApi.loading.next(true);
        try {
          let data = {
            email: this.selectedUser.email,
            companyName: this.addUserForm.controls.companyName.value,
            firstName: this.addUserForm.controls.firstName.value,
            lastName: this.addUserForm.controls.lastName.value,
            phoneNumber: (parsePhoneNumber(this.addUserForm.controls.phoneNumber.value, this.addUserForm.controls.country.value.code as CountryCode)).number,
            role: this.userRolesList
              .find((item) => item.id == parseInt(this.addUserForm.controls.role.value)).value
          }
          this.common.editUser(data, await this.common.getHeader()).subscribe((result) => {
            if (result.status) {
              this.awsApi.loading.next(false);
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: Messages.USER_UPDATED
              });
              this._router.navigate([ROUTES.LIST_USER]);
            }
            else {
              this.awsApi.loading.next(false);
            }
          });
        } catch (error) {
          this.awsApi.loading.next(false);
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: Messages.SOMETHING_WENT_WRONG
          })
        }
      }
    } else {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.PLEASE_FILL_REQUIRED_FIELDS
      });
    }
  }

  async addUser() {
    if (this.addUserForm.valid) {
      this.awsApi.loading.next(true);
      if (this.addUserForm.controls.password.value != this.addUserForm.controls.confirmPassword.value) {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.PASSWORD_MISMATCH
        });
      }
      else {
        this.isUserAlreadyPresent()
        if (!this.userPresent) {
          let str = (this.addUserForm.controls.firstName.value + '#' + this.addUserForm.controls.lastName.value + '#' + this.addUserForm.controls.email.value).toLowerCase()
          str = str.replace(/\s+/g, '');
          str = str.trim()
          let id = uuidv4();
          let data = {
            pk: 'USER#',
            id: id,
            userName: this.addUserForm.controls.firstName.value + ' ' + this.addUserForm.controls.lastName.value,
            firstName: this.addUserForm.controls.firstName.value,
            lastName: this.addUserForm.controls.lastName.value,
            companyName: this.addUserForm.controls.companyName.value,
            email: this.addUserForm.controls.email.value,
            phoneNumber: (parsePhoneNumber(this.addUserForm.controls.phoneNumber.value, this.addUserForm.controls.country.value.code as CountryCode)).number,
            role: this.userRolesList.find((item) => item.id == parseInt(this.addUserForm.controls.role.value)).value,
            password: btoa(this.addUserForm.controls.password.value),
            status: Status.ACTIVE,
            emailVerified: false,
            multiFactorAuth: this.multiFactorAuth,
            emailNotification: this.emailNotification,
            mandatePasswordChange: this.passwordChange,
            searchKey: str,
            createdAt: new Date().toISOString()
          };
          this.awsApi.client
            .graphql({
              query: mutation.createUser,
              variables: {
                input: {
                  ...data
                }
              }
            }).then(async (response) => {
              let reqData: any = {
                "userid": id,
                "username": data.email,
                "password": btoa(this.addUserForm.controls.password.value)
              };
              this.common.addUser(reqData, await this.common.getHeader()).subscribe((result) => {
                this.awsApi.loading.next(false);
                this.messageService.add({
                  severity: 'success',
                  summary: 'Success',
                  detail: Messages.USER_ADDED
                });
                this._router.navigate([ROUTES.LIST_USER]);
              }), (error) => {
                this.awsApi.loading.next(false);

              }
            })
        }
        else {
          this.awsApi.loading.next(false);
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: Messages.USER_EXISTS
          })
        }
      }
    }
    else {
      this.lengthOfLastName = this.addUserForm.controls.lastName.value ? this.addUserForm.controls.lastName.value.length : 0
      if (this.lengthOfLastName == 0 || this.lengthOfLastName > 2) {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.PLEASE_FILL_REQUIRED_FIELDS
        });
      }
      else {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.LASTNAME_SHOULD_HAVE_2_LETTERS
        });
      }
    }
  }

  capitalizeFirstLetter(controlName: string) {
    const name = this.addUserForm.controls[controlName];
    const capitalizeString = (input: string): string => {
      const cleanInput = input.replace(/[^a-zA-Z\s]/g, '');
      const trimmedInput = cleanInput.trim().replace(/\s+/g, ' ');
      return trimmedInput.charAt(0).toUpperCase() + trimmedInput.slice(1);
    };
    if (name && name.value) {
      const capitalized = capitalizeString(name.value);
      name.setValue(capitalized);
    }
  }

  redirectToListUser() {
    this.router.navigate([ROUTES.LIST_USER]);
  }

  public onReady(editor: ClassicEditor) { }
  public onChange({ editor }: ChangeEvent) { }
}