/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateSettingsInput = {
  id?: string | null,
  pk: string,
  notificationType?: NotificationType | null,
  createdAt?: string | null,
  emailTo?: Array< string | null > | null,
  productExpiryDuration?: string | null,
  theme?: string | null,
};

export enum NotificationType {
  ZeroStock = "ZeroStock",
  FastMoving = "FastMoving",
  Payment = "Payment",
  ReturnAndRefund = "ReturnAndRefund",
  OrderPending = "OrderPending",
  OrderFailed = "OrderFailed",
  OrderProcessing = "OrderProcessing",
  OrderConfirmed = "OrderConfirmed",
  OrderDelivered = "OrderDelivered",
  OrderCancelled = "OrderCancelled",
  ReturnRequested = "ReturnRequested",
  ReturnCancelled = "ReturnCancelled",
  ReturnAccepted = "ReturnAccepted",
  ReturnShipped = "ReturnShipped",
  ReturnReceived = "ReturnReceived",
  RefundApproved = "RefundApproved",
  RefundRejected = "RefundRejected",
  GeneralQueries = "GeneralQueries",
  ProductReviews = "ProductReviews",
  Reports = "Reports",
}


export type ModelSettingsConditionInput = {
  notificationType?: ModelNotificationTypeInput | null,
  createdAt?: ModelStringInput | null,
  emailTo?: ModelStringInput | null,
  productExpiryDuration?: ModelStringInput | null,
  theme?: ModelStringInput | null,
  and?: Array< ModelSettingsConditionInput | null > | null,
  or?: Array< ModelSettingsConditionInput | null > | null,
  not?: ModelSettingsConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type ModelNotificationTypeInput = {
  eq?: NotificationType | null,
  ne?: NotificationType | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type Settings = {
  __typename: "Settings",
  id: string,
  pk: string,
  notificationType?: NotificationType | null,
  createdAt: string,
  emailTo?: Array< string | null > | null,
  productExpiryDuration?: string | null,
  theme?: string | null,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateSettingsInput = {
  id: string,
  pk: string,
  notificationType?: NotificationType | null,
  createdAt?: string | null,
  emailTo?: Array< string | null > | null,
  productExpiryDuration?: string | null,
  theme?: string | null,
};

export type DeleteSettingsInput = {
  pk: string,
  id: string,
};

export type CreateCompanyDetailsInput = {
  id?: string | null,
  pk: string,
  createdAt?: string | null,
  primaryLogo?: ImageUploadInput | null,
  secondaryLogo?: ImageUploadInput | null,
  companyName?: string | null,
  webSite?: string | null,
  phoneNumber?: string | null,
  email?: string | null,
  ccEmail?: string | null,
  address?: AddressInput | null,
  status?: boolean | null,
  currency?: string | null,
  categorySequenced?: boolean | null,
  copyrightText?: string | null,
  mobileAppUrls?: Array< UrlDetailsInput | null > | null,
  theme?: ThemeInput | null,
  mobileTheme?: MobileThemeInput | null,
  seoDescription?: string | null,
  daysToReturn?: number | null,
  brandName?: string | null,
  iconUrl?: string | null,
};

export type ImageUploadInput = {
  alterText?: string | null,
  title?: string | null,
  url?: string | null,
  description?: string | null,
  type?: ImageType | null,
};

export enum ImageType {
  featuredImg = "featuredImg",
  featuredImgAttr = "featuredImgAttr",
  galleryImg = "galleryImg",
  companyLogo = "companyLogo",
}


export type AddressInput = {
  addressLine1?: string | null,
  addressLine2?: string | null,
  street?: string | null,
  landmark?: string | null,
  state?: string | null,
  city?: string | null,
  postalCode?: string | null,
  country?: string | null,
};

export type UrlDetailsInput = {
  type?: string | null,
  title?: string | null,
  alterText?: string | null,
  url?: string | null,
  certificationName?: string | null,
  redirectTo?: string | null,
};

export type ThemeInput = {
  preferredTheme?: string | null,
  headerBackgroundColor?: string | null,
  headerTextColor?: string | null,
  headerIconColor?: string | null,
  footerBackgroundColor?: string | null,
  footerTextColor?: string | null,
  footerHeadingColor?: string | null,
  font?: string | null,
  navigationMenu?: FontPropertyInput | null,
  navigationMenuColor?: string | null,
  navigationSubMenu?: FontPropertyInput | null,
  navigationSubMenuColor?: string | null,
  productTitle?: FontPropertyInput | null,
  productSubTitle?: FontPropertyInput | null,
  productPrice?: FontPropertyInput | null,
  productDetailName?: FontPropertyInput | null,
  productDetailSubTitle?: FontPropertyInput | null,
  productDetailPrice?: FontPropertyInput | null,
  productDescriptionHeading?: FontPropertyInput | null,
  productDescription?: FontPropertyInput | null,
  customerReviewsTitle?: FontPropertyInput | null,
  customerReviewsDetails?: FontPropertyInput | null,
  notificationsTitle?: FontPropertyInput | null,
  notificationsSubTitle?: FontPropertyInput | null,
  profileHeading?: FontPropertyInput | null,
  profileContent?: FontPropertyInput | null,
  contentTitle?: FontPropertyInput | null,
  contentDescription?: FontPropertyInput | null,
  titleHeader?: FontPropertyInput | null,
  titleDetails?: FontPropertyInput | null,
  primaryButton?: FontPropertyInput | null,
  primaryButtonBackgroundColor?: string | null,
  primaryButtonTextColor?: string | null,
  secondaryButton?: FontPropertyInput | null,
  secondaryButtonBackgroundColor?: string | null,
  secondaryButtonTextColor?: string | null,
};

export type FontPropertyInput = {
  title?: string | null,
  example?: string | null,
  fontWeight?: string | null,
  fontSize?: string | null,
};

export type MobileThemeInput = {
  locationBackgroundColor?: string | null,
  locationTextColor?: string | null,
  locationIconColor?: string | null,
  searchBarBackgroundColor?: string | null,
  newArrivalsColor?: string | null,
  bestSellersColor?: string | null,
  recentlyViewedColor?: string | null,
  navBackgroundColor?: string | null,
  navIconColor?: string | null,
  font?: string | null,
  productListTitle?: string | null,
  productListBrand?: string | null,
  productListStock?: string | null,
  productListPrice?: string | null,
  productDetailName?: string | null,
  productDetailPrice?: string | null,
  productDetailStock?: string | null,
  productDetailBrand?: string | null,
  customerReviewsTitle?: string | null,
  customerReviewsDetails?: string | null,
  notificationsTitle?: string | null,
  notificationsSubTitle?: string | null,
  profileName?: string | null,
  profileHeadings?: string | null,
  primaryButtonText?: string | null,
  primaryButtonBackgroundColor?: string | null,
  primaryButtonTextColor?: string | null,
  secondaryButtonText?: string | null,
  secondaryButtonBackgroundColor?: string | null,
  secondaryButtonTextColor?: string | null,
};

export type ModelCompanyDetailsConditionInput = {
  createdAt?: ModelStringInput | null,
  companyName?: ModelStringInput | null,
  webSite?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  email?: ModelStringInput | null,
  ccEmail?: ModelStringInput | null,
  status?: ModelBooleanInput | null,
  currency?: ModelStringInput | null,
  categorySequenced?: ModelBooleanInput | null,
  copyrightText?: ModelStringInput | null,
  seoDescription?: ModelStringInput | null,
  daysToReturn?: ModelIntInput | null,
  brandName?: ModelStringInput | null,
  iconUrl?: ModelStringInput | null,
  and?: Array< ModelCompanyDetailsConditionInput | null > | null,
  or?: Array< ModelCompanyDetailsConditionInput | null > | null,
  not?: ModelCompanyDetailsConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type CompanyDetails = {
  __typename: "CompanyDetails",
  id: string,
  pk: string,
  createdAt: string,
  primaryLogo?: imageUpload | null,
  secondaryLogo?: imageUpload | null,
  companyName?: string | null,
  webSite?: string | null,
  phoneNumber?: string | null,
  email?: string | null,
  ccEmail?: string | null,
  address?: Address | null,
  status?: boolean | null,
  currency?: string | null,
  categorySequenced?: boolean | null,
  copyrightText?: string | null,
  mobileAppUrls?:  Array<UrlDetails | null > | null,
  theme?: Theme | null,
  mobileTheme?: MobileTheme | null,
  seoDescription?: string | null,
  daysToReturn?: number | null,
  brandName?: string | null,
  iconUrl?: string | null,
  updatedAt: string,
  owner?: string | null,
};

export type imageUpload = {
  __typename: "imageUpload",
  alterText?: string | null,
  title?: string | null,
  url?: string | null,
  description?: string | null,
  type?: ImageType | null,
};

export type Address = {
  __typename: "Address",
  addressLine1?: string | null,
  addressLine2?: string | null,
  street?: string | null,
  landmark?: string | null,
  state?: string | null,
  city?: string | null,
  postalCode?: string | null,
  country?: string | null,
};

export type UrlDetails = {
  __typename: "UrlDetails",
  type?: string | null,
  title?: string | null,
  alterText?: string | null,
  url?: string | null,
  certificationName?: string | null,
  redirectTo?: string | null,
};

export type Theme = {
  __typename: "Theme",
  preferredTheme?: string | null,
  headerBackgroundColor?: string | null,
  headerTextColor?: string | null,
  headerIconColor?: string | null,
  footerBackgroundColor?: string | null,
  footerTextColor?: string | null,
  footerHeadingColor?: string | null,
  font?: string | null,
  navigationMenu?: FontProperty | null,
  navigationMenuColor?: string | null,
  navigationSubMenu?: FontProperty | null,
  navigationSubMenuColor?: string | null,
  productTitle?: FontProperty | null,
  productSubTitle?: FontProperty | null,
  productPrice?: FontProperty | null,
  productDetailName?: FontProperty | null,
  productDetailSubTitle?: FontProperty | null,
  productDetailPrice?: FontProperty | null,
  productDescriptionHeading?: FontProperty | null,
  productDescription?: FontProperty | null,
  customerReviewsTitle?: FontProperty | null,
  customerReviewsDetails?: FontProperty | null,
  notificationsTitle?: FontProperty | null,
  notificationsSubTitle?: FontProperty | null,
  profileHeading?: FontProperty | null,
  profileContent?: FontProperty | null,
  contentTitle?: FontProperty | null,
  contentDescription?: FontProperty | null,
  titleHeader?: FontProperty | null,
  titleDetails?: FontProperty | null,
  primaryButton?: FontProperty | null,
  primaryButtonBackgroundColor?: string | null,
  primaryButtonTextColor?: string | null,
  secondaryButton?: FontProperty | null,
  secondaryButtonBackgroundColor?: string | null,
  secondaryButtonTextColor?: string | null,
};

export type FontProperty = {
  __typename: "FontProperty",
  title?: string | null,
  example?: string | null,
  fontWeight?: string | null,
  fontSize?: string | null,
};

export type MobileTheme = {
  __typename: "MobileTheme",
  locationBackgroundColor?: string | null,
  locationTextColor?: string | null,
  locationIconColor?: string | null,
  searchBarBackgroundColor?: string | null,
  newArrivalsColor?: string | null,
  bestSellersColor?: string | null,
  recentlyViewedColor?: string | null,
  navBackgroundColor?: string | null,
  navIconColor?: string | null,
  font?: string | null,
  productListTitle?: string | null,
  productListBrand?: string | null,
  productListStock?: string | null,
  productListPrice?: string | null,
  productDetailName?: string | null,
  productDetailPrice?: string | null,
  productDetailStock?: string | null,
  productDetailBrand?: string | null,
  customerReviewsTitle?: string | null,
  customerReviewsDetails?: string | null,
  notificationsTitle?: string | null,
  notificationsSubTitle?: string | null,
  profileName?: string | null,
  profileHeadings?: string | null,
  primaryButtonText?: string | null,
  primaryButtonBackgroundColor?: string | null,
  primaryButtonTextColor?: string | null,
  secondaryButtonText?: string | null,
  secondaryButtonBackgroundColor?: string | null,
  secondaryButtonTextColor?: string | null,
};

export type UpdateCompanyDetailsInput = {
  id: string,
  pk: string,
  createdAt?: string | null,
  primaryLogo?: ImageUploadInput | null,
  secondaryLogo?: ImageUploadInput | null,
  companyName?: string | null,
  webSite?: string | null,
  phoneNumber?: string | null,
  email?: string | null,
  ccEmail?: string | null,
  address?: AddressInput | null,
  status?: boolean | null,
  currency?: string | null,
  categorySequenced?: boolean | null,
  copyrightText?: string | null,
  mobileAppUrls?: Array< UrlDetailsInput | null > | null,
  theme?: ThemeInput | null,
  mobileTheme?: MobileThemeInput | null,
  seoDescription?: string | null,
  daysToReturn?: number | null,
  brandName?: string | null,
  iconUrl?: string | null,
};

export type DeleteCompanyDetailsInput = {
  pk: string,
  id: string,
};

export type CreatePaymentModeInput = {
  id?: string | null,
  pk: string,
  createdAt?: string | null,
  method?: string | null,
  description?: string | null,
  provider?: string | null,
  logoUrl?: string | null,
  stagingUrl?: string | null,
  productionUrl?: string | null,
  status?: string | null,
};

export type ModelPaymentModeConditionInput = {
  createdAt?: ModelStringInput | null,
  method?: ModelStringInput | null,
  description?: ModelStringInput | null,
  provider?: ModelStringInput | null,
  logoUrl?: ModelStringInput | null,
  stagingUrl?: ModelStringInput | null,
  productionUrl?: ModelStringInput | null,
  status?: ModelStringInput | null,
  and?: Array< ModelPaymentModeConditionInput | null > | null,
  or?: Array< ModelPaymentModeConditionInput | null > | null,
  not?: ModelPaymentModeConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type PaymentMode = {
  __typename: "PaymentMode",
  id: string,
  pk: string,
  createdAt: string,
  method?: string | null,
  description?: string | null,
  provider?: string | null,
  logoUrl?: string | null,
  stagingUrl?: string | null,
  productionUrl?: string | null,
  status?: string | null,
  updatedAt: string,
  owner?: string | null,
};

export type UpdatePaymentModeInput = {
  id: string,
  pk: string,
  createdAt?: string | null,
  method?: string | null,
  description?: string | null,
  provider?: string | null,
  logoUrl?: string | null,
  stagingUrl?: string | null,
  productionUrl?: string | null,
  status?: string | null,
};

export type DeletePaymentModeInput = {
  pk: string,
  id: string,
};

export type DeleteUserInput = {
  pk: string,
  id: string,
};

export type ModelUserConditionInput = {
  userName?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  companyName?: ModelStringInput | null,
  profileImageUrl?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  role?: ModelUserRoleInput | null,
  password?: ModelStringInput | null,
  multiFactorAuth?: ModelBooleanInput | null,
  emailNotification?: ModelBooleanInput | null,
  mandatePasswordChange?: ModelBooleanInput | null,
  emailVerified?: ModelBooleanInput | null,
  otp?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  searchKey?: ModelStringInput | null,
  loginAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  platform?: ModelPlatformInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
  owner?: ModelStringInput | null,
};

export type ModelUserRoleInput = {
  eq?: UserRole | null,
  ne?: UserRole | null,
};

export enum UserRole {
  ADMIN = "ADMIN",
  STOREKEEPER = "STOREKEEPER",
  CUSTOMER = "CUSTOMER",
  MARKETING_HEAD = "MARKETING_HEAD",
  MANAGER = "MANAGER",
  WAREHOUSE_MANAGER = "WAREHOUSE_MANAGER",
}


export type ModelStatusInput = {
  eq?: Status | null,
  ne?: Status | null,
};

export enum Status {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  DELETED = "DELETED",
}


export type ModelPlatformInput = {
  eq?: Platform | null,
  ne?: Platform | null,
};

export enum Platform {
  Mobile = "Mobile",
  Web = "Web",
}


export type User = {
  __typename: "User",
  id: string,
  pk: string,
  userName?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  companyName?: string | null,
  profileImageUrl?: string | null,
  email: string,
  phoneNumber?: string | null,
  role?: UserRole | null,
  password?: string | null,
  multiFactorAuth?: boolean | null,
  emailNotification?: boolean | null,
  mandatePasswordChange?: boolean | null,
  emailVerified?: boolean | null,
  otp?: string | null,
  status?: Status | null,
  searchKey?: string | null,
  addressDetails?:  Array<AddressDetails | null > | null,
  loginAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  platform?: Platform | null,
  owner?: string | null,
};

export type AddressDetails = {
  __typename: "AddressDetails",
  name?: string | null,
  phoneNumber?: string | null,
  email?: string | null,
  address?: Address | null,
  type?: string | null,
  addressName?: string | null,
  default?: boolean | null,
  latitude?: string | null,
  longitude?: string | null,
};

export type CreateCategoryInput = {
  pk: string,
  slug: string,
  id?: string | null,
  catName?: string | null,
  mainCategory?: string | null,
  mainCategoryID?: string | null,
  categoryImg?: string | null,
  description?: string | null,
  webBannerImg?: string | null,
  webBannerImgBottom?: string | null,
  mobBannerImg?: string | null,
  mobBannerImgBottom?: string | null,
  categoryType?: CategoryType | null,
  searchKey?: string | null,
  catStatus?: boolean | null,
  sequenceNumber?: number | null,
};

export enum CategoryType {
  MAIN = "MAIN",
  SUB = "SUB",
}


export type ModelCategoryConditionInput = {
  catName?: ModelStringInput | null,
  mainCategory?: ModelStringInput | null,
  mainCategoryID?: ModelStringInput | null,
  categoryImg?: ModelStringInput | null,
  description?: ModelStringInput | null,
  webBannerImg?: ModelStringInput | null,
  webBannerImgBottom?: ModelStringInput | null,
  mobBannerImg?: ModelStringInput | null,
  mobBannerImgBottom?: ModelStringInput | null,
  categoryType?: ModelCategoryTypeInput | null,
  searchKey?: ModelStringInput | null,
  catStatus?: ModelBooleanInput | null,
  sequenceNumber?: ModelIntInput | null,
  and?: Array< ModelCategoryConditionInput | null > | null,
  or?: Array< ModelCategoryConditionInput | null > | null,
  not?: ModelCategoryConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type ModelCategoryTypeInput = {
  eq?: CategoryType | null,
  ne?: CategoryType | null,
};

export type Category = {
  __typename: "Category",
  pk: string,
  slug: string,
  id: string,
  catName?: string | null,
  mainCategory?: string | null,
  mainCategoryID?: string | null,
  categoryImg?: string | null,
  description?: string | null,
  webBannerImg?: string | null,
  webBannerImgBottom?: string | null,
  mobBannerImg?: string | null,
  mobBannerImgBottom?: string | null,
  categoryType?: CategoryType | null,
  searchKey?: string | null,
  catStatus?: boolean | null,
  sequenceNumber?: number | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateCategoryInput = {
  pk: string,
  slug: string,
  id?: string | null,
  catName?: string | null,
  mainCategory?: string | null,
  mainCategoryID?: string | null,
  categoryImg?: string | null,
  description?: string | null,
  webBannerImg?: string | null,
  webBannerImgBottom?: string | null,
  mobBannerImg?: string | null,
  mobBannerImgBottom?: string | null,
  categoryType?: CategoryType | null,
  searchKey?: string | null,
  catStatus?: boolean | null,
  sequenceNumber?: number | null,
};

export type DeleteCategoryInput = {
  pk: string,
  slug: string,
};

export type CreateBrandInput = {
  pk: string,
  id?: string | null,
  brandName?: string | null,
  searchKey?: string | null,
  manufacturerName?: string | null,
  status?: Status | null,
  countryOfOrigin?: string | null,
  brandLogo?: string | null,
  brandUrl?: string | null,
  brandDescription?: string | null,
};

export type ModelBrandConditionInput = {
  brandName?: ModelStringInput | null,
  searchKey?: ModelStringInput | null,
  manufacturerName?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  countryOfOrigin?: ModelStringInput | null,
  brandLogo?: ModelStringInput | null,
  brandUrl?: ModelStringInput | null,
  brandDescription?: ModelStringInput | null,
  and?: Array< ModelBrandConditionInput | null > | null,
  or?: Array< ModelBrandConditionInput | null > | null,
  not?: ModelBrandConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type Brand = {
  __typename: "Brand",
  pk: string,
  id: string,
  brandName?: string | null,
  searchKey?: string | null,
  manufacturerName?: string | null,
  status?: Status | null,
  countryOfOrigin?: string | null,
  brandLogo?: string | null,
  brandUrl?: string | null,
  brandDescription?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateBrandInput = {
  pk: string,
  id: string,
  brandName?: string | null,
  searchKey?: string | null,
  manufacturerName?: string | null,
  status?: Status | null,
  countryOfOrigin?: string | null,
  brandLogo?: string | null,
  brandUrl?: string | null,
  brandDescription?: string | null,
};

export type DeleteBrandInput = {
  pk: string,
  id: string,
};

export type CreateAttributeNameInput = {
  pk: string,
  slug: string,
  id?: string | null,
  sequenceNumber?: number | null,
  searchKey?: string | null,
  status?: Status | null,
  attrName?: string | null,
};

export type ModelAttributeNameConditionInput = {
  sequenceNumber?: ModelIntInput | null,
  searchKey?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  attrName?: ModelStringInput | null,
  and?: Array< ModelAttributeNameConditionInput | null > | null,
  or?: Array< ModelAttributeNameConditionInput | null > | null,
  not?: ModelAttributeNameConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type AttributeName = {
  __typename: "AttributeName",
  pk: string,
  slug: string,
  id: string,
  sequenceNumber?: number | null,
  searchKey?: string | null,
  status?: Status | null,
  attrName?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateAttributeNameInput = {
  pk: string,
  slug: string,
  id?: string | null,
  sequenceNumber?: number | null,
  searchKey?: string | null,
  status?: Status | null,
  attrName?: string | null,
};

export type DeleteAttributeNameInput = {
  pk: string,
  slug: string,
};

export type CreateCountryInput = {
  pk: string,
  slug: string,
  id?: string | null,
  name?: string | null,
  shortName?: string | null,
  searchKey?: string | null,
};

export type ModelCountryConditionInput = {
  name?: ModelStringInput | null,
  shortName?: ModelStringInput | null,
  searchKey?: ModelStringInput | null,
  and?: Array< ModelCountryConditionInput | null > | null,
  or?: Array< ModelCountryConditionInput | null > | null,
  not?: ModelCountryConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type Country = {
  __typename: "Country",
  pk: string,
  slug: string,
  id: string,
  name?: string | null,
  shortName?: string | null,
  searchKey?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateCountryInput = {
  pk: string,
  slug: string,
  id?: string | null,
  name?: string | null,
  shortName?: string | null,
  searchKey?: string | null,
};

export type DeleteCountryInput = {
  pk: string,
  slug: string,
};

export type CreateDropDownMasterInput = {
  pk: string,
  id?: string | null,
  value?: string | null,
  status?: Status | null,
  searchKey?: string | null,
  sequenceNumber?: number | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelDropDownMasterConditionInput = {
  value?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  searchKey?: ModelStringInput | null,
  sequenceNumber?: ModelIntInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDropDownMasterConditionInput | null > | null,
  or?: Array< ModelDropDownMasterConditionInput | null > | null,
  not?: ModelDropDownMasterConditionInput | null,
  owner?: ModelStringInput | null,
};

export type DropDownMaster = {
  __typename: "DropDownMaster",
  pk: string,
  id: string,
  value?: string | null,
  status?: Status | null,
  searchKey?: string | null,
  sequenceNumber?: number | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateDropDownMasterInput = {
  pk: string,
  id: string,
  value?: string | null,
  status?: Status | null,
  searchKey?: string | null,
  sequenceNumber?: number | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteDropDownMasterInput = {
  pk: string,
  id: string,
};

export type CreateCartInput = {
  pk: string,
  id?: string | null,
  userId: string,
  productID: string,
  createdAt?: string | null,
  productImage?: string | null,
  productName?: string | null,
  mainCategoryId?: string | null,
  mainCategory?: string | null,
  subCategoryId?: string | null,
  subCategoryN?: string | null,
  attrValueID?: string | null,
  price?: number | null,
  salesPrice?: number | null,
  stockUnit?: string | null,
  itemsPerUnit?: number | null,
  expiryDate?: string | null,
  selectedQuantity?: number | null,
  brandDetail?: BrandDetailInput | null,
  wareHouseId?: string | null,
  wareHouseName?: string | null,
  attrName?: string | null,
  attrValue?: string | null,
  shippingInfo?: ShippingDetailsInput | null,
  userName?: string | null,
  userMail?: string | null,
  model?: string | null,
  cartURL?: string | null,
  taxCode?: string | null,
  taxCharge?: number | null,
  taxDetail?: Array< TaxDetailInput | null > | null,
  sku?: string | null,
  searchKey?: string | null,
  totalPrice?: number | null,
  mobileNumber?: string | null,
  currentStock?: string | null,
  brandId?: string | null,
};

export type BrandDetailInput = {
  brandID?: string | null,
  brandName?: string | null,
  brandDescription?: string | null,
  manufacturerName?: string | null,
  imageUrl?: string | null,
};

export type ShippingDetailsInput = {
  weightValue?: string | null,
  weightUnit?: string | null,
  lengthValue?: string | null,
  lengthUnit?: string | null,
  heightValue?: string | null,
  heightUnit?: string | null,
  widthValue?: string | null,
  widthUnit?: string | null,
};

export type TaxDetailInput = {
  taxCharge?: number | null,
  taxID?: string | null,
  taxName?: string | null,
};

export type ModelCartConditionInput = {
  userId?: ModelStringInput | null,
  productID?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  productImage?: ModelStringInput | null,
  productName?: ModelStringInput | null,
  mainCategoryId?: ModelStringInput | null,
  mainCategory?: ModelStringInput | null,
  subCategoryId?: ModelStringInput | null,
  subCategoryN?: ModelStringInput | null,
  attrValueID?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  salesPrice?: ModelFloatInput | null,
  stockUnit?: ModelStringInput | null,
  itemsPerUnit?: ModelIntInput | null,
  expiryDate?: ModelStringInput | null,
  selectedQuantity?: ModelIntInput | null,
  wareHouseId?: ModelStringInput | null,
  wareHouseName?: ModelStringInput | null,
  attrName?: ModelStringInput | null,
  attrValue?: ModelStringInput | null,
  userName?: ModelStringInput | null,
  userMail?: ModelStringInput | null,
  model?: ModelStringInput | null,
  cartURL?: ModelStringInput | null,
  taxCode?: ModelStringInput | null,
  taxCharge?: ModelFloatInput | null,
  sku?: ModelStringInput | null,
  searchKey?: ModelStringInput | null,
  totalPrice?: ModelFloatInput | null,
  mobileNumber?: ModelStringInput | null,
  currentStock?: ModelStringInput | null,
  brandId?: ModelStringInput | null,
  and?: Array< ModelCartConditionInput | null > | null,
  or?: Array< ModelCartConditionInput | null > | null,
  not?: ModelCartConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Cart = {
  __typename: "Cart",
  pk: string,
  id: string,
  userId: string,
  productID: string,
  createdAt: string,
  productImage?: string | null,
  productName?: string | null,
  mainCategoryId?: string | null,
  mainCategory?: string | null,
  subCategoryId?: string | null,
  subCategoryN?: string | null,
  attrValueID?: string | null,
  price?: number | null,
  salesPrice?: number | null,
  stockUnit?: string | null,
  itemsPerUnit?: number | null,
  expiryDate?: string | null,
  selectedQuantity?: number | null,
  brandDetail?: BrandDetail | null,
  wareHouseId?: string | null,
  wareHouseName?: string | null,
  attrName?: string | null,
  attrValue?: string | null,
  shippingInfo?: shippingDetails | null,
  userName?: string | null,
  userMail?: string | null,
  model?: string | null,
  cartURL?: string | null,
  taxCode?: string | null,
  taxCharge?: number | null,
  taxDetail?:  Array<taxDetail | null > | null,
  sku?: string | null,
  searchKey?: string | null,
  totalPrice?: number | null,
  mobileNumber?: string | null,
  currentStock?: string | null,
  brandId?: string | null,
  updatedAt: string,
  owner?: string | null,
};

export type BrandDetail = {
  __typename: "BrandDetail",
  brandID?: string | null,
  brandName?: string | null,
  brandDescription?: string | null,
  manufacturerName?: string | null,
  imageUrl?: string | null,
};

export type shippingDetails = {
  __typename: "shippingDetails",
  weightValue?: string | null,
  weightUnit?: string | null,
  lengthValue?: string | null,
  lengthUnit?: string | null,
  heightValue?: string | null,
  heightUnit?: string | null,
  widthValue?: string | null,
  widthUnit?: string | null,
};

export type taxDetail = {
  __typename: "taxDetail",
  taxCharge?: number | null,
  taxID?: string | null,
  taxName?: string | null,
};

export type UpdateCartInput = {
  pk: string,
  id: string,
  userId?: string | null,
  productID?: string | null,
  createdAt?: string | null,
  productImage?: string | null,
  productName?: string | null,
  mainCategoryId?: string | null,
  mainCategory?: string | null,
  subCategoryId?: string | null,
  subCategoryN?: string | null,
  attrValueID?: string | null,
  price?: number | null,
  salesPrice?: number | null,
  stockUnit?: string | null,
  itemsPerUnit?: number | null,
  expiryDate?: string | null,
  selectedQuantity?: number | null,
  brandDetail?: BrandDetailInput | null,
  wareHouseId?: string | null,
  wareHouseName?: string | null,
  attrName?: string | null,
  attrValue?: string | null,
  shippingInfo?: ShippingDetailsInput | null,
  userName?: string | null,
  userMail?: string | null,
  model?: string | null,
  cartURL?: string | null,
  taxCode?: string | null,
  taxCharge?: number | null,
  taxDetail?: Array< TaxDetailInput | null > | null,
  sku?: string | null,
  searchKey?: string | null,
  totalPrice?: number | null,
  mobileNumber?: string | null,
  currentStock?: string | null,
  brandId?: string | null,
};

export type DeleteCartInput = {
  pk: string,
  id: string,
};

export type CreateWareHouseInput = {
  pk: string,
  id?: string | null,
  name: string,
  createdAt?: string | null,
  addressLine1?: string | null,
  addressLine2?: string | null,
  searchKey?: string | null,
  countryId?: string | null,
  country?: string | null,
  state?: string | null,
  city?: string | null,
  zipCode?: string | null,
  contactPerson?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  workingHoursFrom?: string | null,
  workingHoursTo?: string | null,
  status?: boolean | null,
  latitude?: string | null,
  longitude?: string | null,
};

export type ModelWareHouseConditionInput = {
  name?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  addressLine1?: ModelStringInput | null,
  addressLine2?: ModelStringInput | null,
  searchKey?: ModelStringInput | null,
  countryId?: ModelStringInput | null,
  country?: ModelStringInput | null,
  state?: ModelStringInput | null,
  city?: ModelStringInput | null,
  zipCode?: ModelStringInput | null,
  contactPerson?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  workingHoursFrom?: ModelStringInput | null,
  workingHoursTo?: ModelStringInput | null,
  status?: ModelBooleanInput | null,
  latitude?: ModelStringInput | null,
  longitude?: ModelStringInput | null,
  and?: Array< ModelWareHouseConditionInput | null > | null,
  or?: Array< ModelWareHouseConditionInput | null > | null,
  not?: ModelWareHouseConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type WareHouse = {
  __typename: "WareHouse",
  pk: string,
  id: string,
  name: string,
  createdAt: string,
  addressLine1?: string | null,
  addressLine2?: string | null,
  searchKey?: string | null,
  countryId?: string | null,
  country?: string | null,
  state?: string | null,
  city?: string | null,
  zipCode?: string | null,
  contactPerson?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  workingHoursFrom?: string | null,
  workingHoursTo?: string | null,
  status?: boolean | null,
  latitude?: string | null,
  longitude?: string | null,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateWareHouseInput = {
  pk: string,
  id: string,
  name?: string | null,
  createdAt?: string | null,
  addressLine1?: string | null,
  addressLine2?: string | null,
  searchKey?: string | null,
  countryId?: string | null,
  country?: string | null,
  state?: string | null,
  city?: string | null,
  zipCode?: string | null,
  contactPerson?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  workingHoursFrom?: string | null,
  workingHoursTo?: string | null,
  status?: boolean | null,
  latitude?: string | null,
  longitude?: string | null,
};

export type DeleteWareHouseInput = {
  pk: string,
  id: string,
};

export type CreateNotificationsInput = {
  pk: string,
  id?: string | null,
  userId?: string | null,
  userName?: string | null,
  notificationType?: string | null,
  Title?: string | null,
  Message?: string | null,
  orderID?: string | null,
  DateTime?: string | null,
  notificationStatus?: number | null,
  notificationModule?: string | null,
  referenceID?: string | null,
  targetURL?: string | null,
  searchKey?: string | null,
  expiryDate?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelNotificationsConditionInput = {
  userId?: ModelStringInput | null,
  userName?: ModelStringInput | null,
  notificationType?: ModelStringInput | null,
  Title?: ModelStringInput | null,
  Message?: ModelStringInput | null,
  orderID?: ModelStringInput | null,
  DateTime?: ModelStringInput | null,
  notificationStatus?: ModelIntInput | null,
  notificationModule?: ModelStringInput | null,
  referenceID?: ModelStringInput | null,
  targetURL?: ModelStringInput | null,
  searchKey?: ModelStringInput | null,
  expiryDate?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelNotificationsConditionInput | null > | null,
  or?: Array< ModelNotificationsConditionInput | null > | null,
  not?: ModelNotificationsConditionInput | null,
  owner?: ModelStringInput | null,
};

export type Notifications = {
  __typename: "Notifications",
  pk: string,
  id: string,
  userId?: string | null,
  userName?: string | null,
  notificationType?: string | null,
  Title?: string | null,
  Message?: string | null,
  orderID?: string | null,
  DateTime?: string | null,
  notificationStatus?: number | null,
  notificationModule?: string | null,
  referenceID?: string | null,
  targetURL?: string | null,
  searchKey?: string | null,
  expiryDate?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateNotificationsInput = {
  pk: string,
  id: string,
  userId?: string | null,
  userName?: string | null,
  notificationType?: string | null,
  Title?: string | null,
  Message?: string | null,
  orderID?: string | null,
  DateTime?: string | null,
  notificationStatus?: number | null,
  notificationModule?: string | null,
  referenceID?: string | null,
  targetURL?: string | null,
  searchKey?: string | null,
  expiryDate?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteNotificationsInput = {
  pk: string,
  id: string,
};

export type CreateModuleMasterInput = {
  pk: string,
  id?: string | null,
  moduleName?: string | null,
  sequenceNumber?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelModuleMasterConditionInput = {
  moduleName?: ModelStringInput | null,
  sequenceNumber?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelModuleMasterConditionInput | null > | null,
  or?: Array< ModelModuleMasterConditionInput | null > | null,
  not?: ModelModuleMasterConditionInput | null,
  owner?: ModelStringInput | null,
};

export type ModuleMaster = {
  __typename: "ModuleMaster",
  pk: string,
  id: string,
  moduleName?: string | null,
  sequenceNumber?: number | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateModuleMasterInput = {
  pk: string,
  id: string,
  moduleName?: string | null,
  sequenceNumber?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteModuleMasterInput = {
  pk: string,
  id: string,
};

export type CreateRoleManagementInput = {
  pk: string,
  id?: string | null,
  menuId?: string | null,
  roleName: string,
  module?: Array< ModuleInput | null > | null,
  status?: Status | null,
  searchKey?: string | null,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type ModuleInput = {
  moduleId?: string | null,
  menu?: string | null,
  view?: boolean | null,
  add?: boolean | null,
  edit?: boolean | null,
  delete?: boolean | null,
};

export type ModelRoleManagementConditionInput = {
  menuId?: ModelStringInput | null,
  roleName?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  searchKey?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelRoleManagementConditionInput | null > | null,
  or?: Array< ModelRoleManagementConditionInput | null > | null,
  not?: ModelRoleManagementConditionInput | null,
  owner?: ModelStringInput | null,
};

export type RoleManagement = {
  __typename: "RoleManagement",
  pk: string,
  id: string,
  menuId?: string | null,
  roleName: string,
  module?:  Array<Module | null > | null,
  status?: Status | null,
  searchKey?: string | null,
  updatedAt: string,
  createdAt: string,
  owner?: string | null,
};

export type Module = {
  __typename: "Module",
  moduleId?: string | null,
  menu?: string | null,
  view?: boolean | null,
  add?: boolean | null,
  edit?: boolean | null,
  delete?: boolean | null,
};

export type UpdateRoleManagementInput = {
  pk: string,
  id: string,
  menuId?: string | null,
  roleName?: string | null,
  module?: Array< ModuleInput | null > | null,
  status?: Status | null,
  searchKey?: string | null,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type DeleteRoleManagementInput = {
  pk: string,
  id: string,
};

export type CreateContentInput = {
  pk: string,
  id?: string | null,
  contentName: string,
  title?: string | null,
  sequenceNumber?: number | null,
  content?: string | null,
  searchKey?: string | null,
  docUrl?: string | null,
  visibleToOthers?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelContentConditionInput = {
  contentName?: ModelStringInput | null,
  title?: ModelStringInput | null,
  sequenceNumber?: ModelIntInput | null,
  content?: ModelStringInput | null,
  searchKey?: ModelStringInput | null,
  docUrl?: ModelStringInput | null,
  visibleToOthers?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelContentConditionInput | null > | null,
  or?: Array< ModelContentConditionInput | null > | null,
  not?: ModelContentConditionInput | null,
  owner?: ModelStringInput | null,
};

export type Content = {
  __typename: "Content",
  pk: string,
  id: string,
  contentName: string,
  title?: string | null,
  sequenceNumber?: number | null,
  content?: string | null,
  searchKey?: string | null,
  docUrl?: string | null,
  visibleToOthers?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export type UpdateContentInput = {
  pk: string,
  id: string,
  contentName?: string | null,
  title?: string | null,
  sequenceNumber?: number | null,
  content?: string | null,
  searchKey?: string | null,
  docUrl?: string | null,
  visibleToOthers?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteContentInput = {
  pk: string,
  id: string,
};

export type CreateFAQInput = {
  pk: string,
  id?: string | null,
  type?: string | null,
  sequenceNumber?: number | null,
  name?: string | null,
  FAQSections?: Array< FAQSectionsInput | null > | null,
  searchKey?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type FAQSectionsInput = {
  question?: string | null,
  answer?: string | null,
};

export type ModelFAQConditionInput = {
  type?: ModelStringInput | null,
  sequenceNumber?: ModelIntInput | null,
  name?: ModelStringInput | null,
  searchKey?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFAQConditionInput | null > | null,
  or?: Array< ModelFAQConditionInput | null > | null,
  not?: ModelFAQConditionInput | null,
  owner?: ModelStringInput | null,
};

export type FAQ = {
  __typename: "FAQ",
  pk: string,
  id: string,
  type?: string | null,
  sequenceNumber?: number | null,
  name?: string | null,
  FAQSections?:  Array<FAQSections | null > | null,
  searchKey?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export type FAQSections = {
  __typename: "FAQSections",
  question?: string | null,
  answer?: string | null,
};

export type UpdateFAQInput = {
  pk: string,
  id: string,
  type?: string | null,
  sequenceNumber?: number | null,
  name?: string | null,
  FAQSections?: Array< FAQSectionsInput | null > | null,
  searchKey?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteFAQInput = {
  pk: string,
  id: string,
};

export type CreateProductReviewInput = {
  pk: string,
  id?: string | null,
  orderItemId: string,
  productId?: string | null,
  productImage?: string | null,
  productName?: string | null,
  userId?: string | null,
  userName?: string | null,
  email?: string | null,
  overallRating?: number | null,
  title?: string | null,
  reviewComment?: string | null,
  removeAbusiveComment?: boolean | null,
  abusiveReportedBy?: Array< AbusiveReportInput | null > | null,
  attachments?: Array< string | null > | null,
  createdAt?: string | null,
  reply?: string | null,
  repliedOnDate?: string | null,
  orderedDate?: string | null,
  reviewedDate?: string | null,
  reviewStatus?: EnquiryStatus | null,
  searchKey?: string | null,
};

export type AbusiveReportInput = {
  userId?: string | null,
  userName?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  reasons?: Array< string | null > | null,
  reportedDate?: string | null,
};

export enum EnquiryStatus {
  Open = "Open",
  Resolved = "Resolved",
}


export type ModelProductReviewConditionInput = {
  orderItemId?: ModelStringInput | null,
  productId?: ModelStringInput | null,
  productImage?: ModelStringInput | null,
  productName?: ModelStringInput | null,
  userId?: ModelStringInput | null,
  userName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  overallRating?: ModelFloatInput | null,
  title?: ModelStringInput | null,
  reviewComment?: ModelStringInput | null,
  removeAbusiveComment?: ModelBooleanInput | null,
  attachments?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  reply?: ModelStringInput | null,
  repliedOnDate?: ModelStringInput | null,
  orderedDate?: ModelStringInput | null,
  reviewedDate?: ModelStringInput | null,
  reviewStatus?: ModelEnquiryStatusInput | null,
  searchKey?: ModelStringInput | null,
  and?: Array< ModelProductReviewConditionInput | null > | null,
  or?: Array< ModelProductReviewConditionInput | null > | null,
  not?: ModelProductReviewConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type ModelEnquiryStatusInput = {
  eq?: EnquiryStatus | null,
  ne?: EnquiryStatus | null,
};

export type ProductReview = {
  __typename: "ProductReview",
  pk: string,
  id: string,
  orderItemId: string,
  productId?: string | null,
  productImage?: string | null,
  productName?: string | null,
  userId?: string | null,
  userName?: string | null,
  email?: string | null,
  overallRating?: number | null,
  title?: string | null,
  reviewComment?: string | null,
  removeAbusiveComment?: boolean | null,
  abusiveReportedBy?:  Array<AbusiveReport | null > | null,
  attachments?: Array< string | null > | null,
  createdAt?: string | null,
  reply?: string | null,
  repliedOnDate?: string | null,
  orderedDate?: string | null,
  reviewedDate?: string | null,
  reviewStatus?: EnquiryStatus | null,
  searchKey?: string | null,
  updatedAt: string,
  owner?: string | null,
};

export type AbusiveReport = {
  __typename: "AbusiveReport",
  userId?: string | null,
  userName?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  reasons?: Array< string | null > | null,
  reportedDate?: string | null,
};

export type UpdateProductReviewInput = {
  pk: string,
  id: string,
  orderItemId?: string | null,
  productId?: string | null,
  productImage?: string | null,
  productName?: string | null,
  userId?: string | null,
  userName?: string | null,
  email?: string | null,
  overallRating?: number | null,
  title?: string | null,
  reviewComment?: string | null,
  removeAbusiveComment?: boolean | null,
  abusiveReportedBy?: Array< AbusiveReportInput | null > | null,
  attachments?: Array< string | null > | null,
  createdAt?: string | null,
  reply?: string | null,
  repliedOnDate?: string | null,
  orderedDate?: string | null,
  reviewedDate?: string | null,
  reviewStatus?: EnquiryStatus | null,
  searchKey?: string | null,
};

export type DeleteProductReviewInput = {
  pk: string,
  id: string,
};

export type CreateTokenDetailsInput = {
  pk: string,
  id?: string | null,
  expiresIn?: string | null,
  token: string,
  tokenType?: string | null,
  userID: string,
  loginAt?: string | null,
};

export type ModelTokenDetailsConditionInput = {
  expiresIn?: ModelStringInput | null,
  token?: ModelStringInput | null,
  tokenType?: ModelStringInput | null,
  userID?: ModelStringInput | null,
  loginAt?: ModelStringInput | null,
  and?: Array< ModelTokenDetailsConditionInput | null > | null,
  or?: Array< ModelTokenDetailsConditionInput | null > | null,
  not?: ModelTokenDetailsConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type TokenDetails = {
  __typename: "TokenDetails",
  pk: string,
  id: string,
  expiresIn?: string | null,
  token: string,
  tokenType?: string | null,
  userID: string,
  loginAt?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateTokenDetailsInput = {
  pk: string,
  id: string,
  expiresIn?: string | null,
  token?: string | null,
  tokenType?: string | null,
  userID?: string | null,
  loginAt?: string | null,
};

export type DeleteTokenDetailsInput = {
  pk: string,
  id: string,
};

export type CreateTaxInput = {
  pk: string,
  id?: string | null,
  taxCode: string,
  taxType?: string | null,
  status?: Status | null,
  searchKey?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelTaxConditionInput = {
  taxCode?: ModelStringInput | null,
  taxType?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  searchKey?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTaxConditionInput | null > | null,
  or?: Array< ModelTaxConditionInput | null > | null,
  not?: ModelTaxConditionInput | null,
  owner?: ModelStringInput | null,
};

export type Tax = {
  __typename: "Tax",
  pk: string,
  id: string,
  taxCode: string,
  taxType?: string | null,
  status?: Status | null,
  searchKey?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export type UpdateTaxInput = {
  pk: string,
  id: string,
  taxCode?: string | null,
  taxType?: string | null,
  status?: Status | null,
  searchKey?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteTaxInput = {
  pk: string,
  id: string,
};

export type CreateCarouselImageInput = {
  pk: string,
  id?: string | null,
  type?: string | null,
  deviceType?: string | null,
  animation?: animationType | null,
  pauseTime?: number | null,
  transitionSpeed?: number | null,
  stopAnimation?: boolean | null,
  displayArrows?: boolean | null,
  displayNavigationBullets?: boolean | null,
  image?: Array< ImageUploadInput | null > | null,
  status?: Status | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export enum animationType {
  top = "top",
  bottom = "bottom",
  left = "left",
  right = "right",
}


export type ModelCarouselImageConditionInput = {
  type?: ModelStringInput | null,
  deviceType?: ModelStringInput | null,
  animation?: ModelanimationTypeInput | null,
  pauseTime?: ModelFloatInput | null,
  transitionSpeed?: ModelFloatInput | null,
  stopAnimation?: ModelBooleanInput | null,
  displayArrows?: ModelBooleanInput | null,
  displayNavigationBullets?: ModelBooleanInput | null,
  status?: ModelStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCarouselImageConditionInput | null > | null,
  or?: Array< ModelCarouselImageConditionInput | null > | null,
  not?: ModelCarouselImageConditionInput | null,
  owner?: ModelStringInput | null,
};

export type ModelanimationTypeInput = {
  eq?: animationType | null,
  ne?: animationType | null,
};

export type CarouselImage = {
  __typename: "CarouselImage",
  pk: string,
  id: string,
  type?: string | null,
  deviceType?: string | null,
  animation?: animationType | null,
  pauseTime?: number | null,
  transitionSpeed?: number | null,
  stopAnimation?: boolean | null,
  displayArrows?: boolean | null,
  displayNavigationBullets?: boolean | null,
  image?:  Array<imageUpload | null > | null,
  status?: Status | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export type UpdateCarouselImageInput = {
  pk: string,
  id: string,
  type?: string | null,
  deviceType?: string | null,
  animation?: animationType | null,
  pauseTime?: number | null,
  transitionSpeed?: number | null,
  stopAnimation?: boolean | null,
  displayArrows?: boolean | null,
  displayNavigationBullets?: boolean | null,
  image?: Array< ImageUploadInput | null > | null,
  status?: Status | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteCarouselImageInput = {
  pk: string,
  id: string,
};

export type CreateWishListInput = {
  pk: string,
  id?: string | null,
  userId?: string | null,
  productId?: string | null,
  productName?: string | null,
  productImage?: string | null,
  mainCategory?: string | null,
  mainCategoryId?: string | null,
  subCategory?: string | null,
  subCategoryId?: string | null,
  brandName?: string | null,
  brandId?: string | null,
  model?: string | null,
  rating?: number | null,
  regularPrice?: number | null,
  salePrice?: number | null,
  createdAt?: string | null,
};

export type ModelWishListConditionInput = {
  userId?: ModelStringInput | null,
  productId?: ModelStringInput | null,
  productName?: ModelStringInput | null,
  productImage?: ModelStringInput | null,
  mainCategory?: ModelStringInput | null,
  mainCategoryId?: ModelStringInput | null,
  subCategory?: ModelStringInput | null,
  subCategoryId?: ModelStringInput | null,
  brandName?: ModelStringInput | null,
  brandId?: ModelStringInput | null,
  model?: ModelStringInput | null,
  rating?: ModelFloatInput | null,
  regularPrice?: ModelFloatInput | null,
  salePrice?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelWishListConditionInput | null > | null,
  or?: Array< ModelWishListConditionInput | null > | null,
  not?: ModelWishListConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type WishList = {
  __typename: "WishList",
  pk: string,
  id: string,
  userId?: string | null,
  productId?: string | null,
  productName?: string | null,
  productImage?: string | null,
  mainCategory?: string | null,
  mainCategoryId?: string | null,
  subCategory?: string | null,
  subCategoryId?: string | null,
  brandName?: string | null,
  brandId?: string | null,
  model?: string | null,
  rating?: number | null,
  regularPrice?: number | null,
  salePrice?: number | null,
  createdAt?: string | null,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateWishListInput = {
  pk: string,
  id: string,
  userId?: string | null,
  productId?: string | null,
  productName?: string | null,
  productImage?: string | null,
  mainCategory?: string | null,
  mainCategoryId?: string | null,
  subCategory?: string | null,
  subCategoryId?: string | null,
  brandName?: string | null,
  brandId?: string | null,
  model?: string | null,
  rating?: number | null,
  regularPrice?: number | null,
  salePrice?: number | null,
  createdAt?: string | null,
};

export type DeleteWishListInput = {
  pk: string,
  id: string,
};

export type DeleteConfigurationInput = {
  pk: string,
  id: string,
};

export type ModelConfigurationConditionInput = {
  pk?: ModelStringInput | null,
  configurationDescription?: ModelStringInput | null,
  type?: ModelStringInput | null,
  modifiedTime?: ModelStringInput | null,
  modifiedBy?: ModelStringInput | null,
  and?: Array< ModelConfigurationConditionInput | null > | null,
  or?: Array< ModelConfigurationConditionInput | null > | null,
  not?: ModelConfigurationConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type configuration = {
  __typename: "configuration",
  pk: string,
  id: string,
  configurationDescription?: string | null,
  continuousSettings?: ContinuousSettings | null,
  type?: string | null,
  modifiedTime?: string | null,
  modifiedBy?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type ContinuousSettings = {
  __typename: "ContinuousSettings",
  prefixValue?: string | null,
  numberOfDigits?: number | null,
  includeYearSuffix?: boolean | null,
  startingNumber?: number | null,
  runningNumber?: number | null,
};

export type CreateMailLogInput = {
  pk: string,
  id?: string | null,
  referenceId?: string | null,
  referenceForm?: string | null,
  userId?: string | null,
  mailDetails?: Array< EmailDetailsInput | null > | null,
  updatedAt?: string | null,
};

export type EmailDetailsInput = {
  sentTo?: string | null,
  sentDate?: string | null,
};

export type ModelMailLogConditionInput = {
  referenceId?: ModelStringInput | null,
  referenceForm?: ModelStringInput | null,
  userId?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMailLogConditionInput | null > | null,
  or?: Array< ModelMailLogConditionInput | null > | null,
  not?: ModelMailLogConditionInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type MailLog = {
  __typename: "MailLog",
  pk: string,
  id: string,
  referenceId?: string | null,
  referenceForm?: string | null,
  userId?: string | null,
  mailDetails?:  Array<EmailDetails | null > | null,
  updatedAt?: string | null,
  createdAt: string,
  owner?: string | null,
};

export type EmailDetails = {
  __typename: "EmailDetails",
  sentTo?: string | null,
  sentDate?: string | null,
};

export type UpdateMailLogInput = {
  pk: string,
  id: string,
  referenceId?: string | null,
  referenceForm?: string | null,
  userId?: string | null,
  mailDetails?: Array< EmailDetailsInput | null > | null,
  updatedAt?: string | null,
};

export type DeleteMailLogInput = {
  pk: string,
  id: string,
};

export type CreateDeliveryPartnerInput = {
  pk: string,
  id?: string | null,
  logo?: string | null,
  logoUrl?: string | null,
  companyName?: string | null,
  displayName?: string | null,
  apiKey?: string | null,
  apiKeyExpiryDate?: string | null,
  contactPerson?: string | null,
  address?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  status?: Status | null,
  searchKey?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelDeliveryPartnerConditionInput = {
  logo?: ModelStringInput | null,
  logoUrl?: ModelStringInput | null,
  companyName?: ModelStringInput | null,
  displayName?: ModelStringInput | null,
  apiKey?: ModelStringInput | null,
  apiKeyExpiryDate?: ModelStringInput | null,
  contactPerson?: ModelStringInput | null,
  address?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  searchKey?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDeliveryPartnerConditionInput | null > | null,
  or?: Array< ModelDeliveryPartnerConditionInput | null > | null,
  not?: ModelDeliveryPartnerConditionInput | null,
  owner?: ModelStringInput | null,
};

export type DeliveryPartner = {
  __typename: "DeliveryPartner",
  pk: string,
  id: string,
  logo?: string | null,
  logoUrl?: string | null,
  companyName?: string | null,
  displayName?: string | null,
  apiKey?: string | null,
  apiKeyExpiryDate?: string | null,
  contactPerson?: string | null,
  address?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  status?: Status | null,
  searchKey?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export type UpdateDeliveryPartnerInput = {
  pk: string,
  id: string,
  logo?: string | null,
  logoUrl?: string | null,
  companyName?: string | null,
  displayName?: string | null,
  apiKey?: string | null,
  apiKeyExpiryDate?: string | null,
  contactPerson?: string | null,
  address?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  status?: Status | null,
  searchKey?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteDeliveryPartnerInput = {
  pk: string,
  id: string,
};

export type CreateLedgerInput = {
  pk: string,
  id?: string | null,
  type?: string | null,
  transDate?: string | null,
  transReference?: string | null,
  transDetails?: string | null,
  transTo?: string | null,
  transToId?: string | null,
  transAmount?: number | null,
  transType?: string | null,
  paymentStatus?: string | null,
  buyerName?: string | null,
  orderRef?: string | null,
  orderAmount?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelLedgerConditionInput = {
  type?: ModelStringInput | null,
  transDate?: ModelStringInput | null,
  transReference?: ModelStringInput | null,
  transDetails?: ModelStringInput | null,
  transTo?: ModelStringInput | null,
  transToId?: ModelStringInput | null,
  transAmount?: ModelFloatInput | null,
  transType?: ModelStringInput | null,
  paymentStatus?: ModelStringInput | null,
  buyerName?: ModelStringInput | null,
  orderRef?: ModelStringInput | null,
  orderAmount?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelLedgerConditionInput | null > | null,
  or?: Array< ModelLedgerConditionInput | null > | null,
  not?: ModelLedgerConditionInput | null,
  owner?: ModelStringInput | null,
};

export type Ledger = {
  __typename: "Ledger",
  pk: string,
  id: string,
  type?: string | null,
  transDate?: string | null,
  transReference?: string | null,
  transDetails?: string | null,
  transTo?: string | null,
  transToId?: string | null,
  transAmount?: number | null,
  transType?: string | null,
  paymentStatus?: string | null,
  buyerName?: string | null,
  orderRef?: string | null,
  orderAmount?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export type UpdateLedgerInput = {
  pk: string,
  id: string,
  type?: string | null,
  transDate?: string | null,
  transReference?: string | null,
  transDetails?: string | null,
  transTo?: string | null,
  transToId?: string | null,
  transAmount?: number | null,
  transType?: string | null,
  paymentStatus?: string | null,
  buyerName?: string | null,
  orderRef?: string | null,
  orderAmount?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteLedgerInput = {
  pk: string,
  id: string,
};

export type DeleteProductVisitInput = {
  pk: string,
  id: string,
};

export type ModelProductVisitConditionInput = {
  prodId?: ModelStringInput | null,
  userId?: ModelStringInput | null,
  userName?: ModelStringInput | null,
  visitedAt?: ModelStringInput | null,
  place?: ModelStringInput | null,
  and?: Array< ModelProductVisitConditionInput | null > | null,
  or?: Array< ModelProductVisitConditionInput | null > | null,
  not?: ModelProductVisitConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type ProductVisit = {
  __typename: "ProductVisit",
  pk: string,
  id: string,
  prodId?: string | null,
  userId?: string | null,
  userName?: string | null,
  visitedAt?: string | null,
  place?: string | null,
  visitedInfo?:  Array<VisitedInfo | null > | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type VisitedInfo = {
  __typename: "VisitedInfo",
  platform?: string | null,
  location?: string | null,
  time?: string | null,
  ipAddress?: string | null,
};

export type CreatePromoCodesInput = {
  pk: string,
  id?: string | null,
  promoImage?: string | null,
  promoURL?: string | null,
  code?: string | null,
  discount?: DiscountTypeInput | null,
  redemptionType?: string | null,
  searchKey?: string | null,
  validFrom?: string | null,
  validTo?: string | null,
  description?: string | null,
  terms?: string | null,
  advancedSetting?: AdvancedSettingsInput | null,
  status?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DiscountTypeInput = {
  type?: string | null,
  value?: string | null,
};

export type AdvancedSettingsInput = {
  appliedTo?: string | null,
  specificType?: Array< SpecificTypeInput | null > | null,
};

export type SpecificTypeInput = {
  name?: string | null,
  id?: string | null,
};

export type ModelPromoCodesConditionInput = {
  promoImage?: ModelStringInput | null,
  promoURL?: ModelStringInput | null,
  code?: ModelStringInput | null,
  redemptionType?: ModelStringInput | null,
  searchKey?: ModelStringInput | null,
  validFrom?: ModelStringInput | null,
  validTo?: ModelStringInput | null,
  description?: ModelStringInput | null,
  terms?: ModelStringInput | null,
  status?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPromoCodesConditionInput | null > | null,
  or?: Array< ModelPromoCodesConditionInput | null > | null,
  not?: ModelPromoCodesConditionInput | null,
  owner?: ModelStringInput | null,
};

export type PromoCodes = {
  __typename: "PromoCodes",
  pk: string,
  id: string,
  promoImage?: string | null,
  promoURL?: string | null,
  code?: string | null,
  discount?: discountType | null,
  redemptionType?: string | null,
  searchKey?: string | null,
  validFrom?: string | null,
  validTo?: string | null,
  description?: string | null,
  terms?: string | null,
  advancedSetting?: AdvancedSettings | null,
  status?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type discountType = {
  __typename: "discountType",
  type?: string | null,
  value?: string | null,
};

export type AdvancedSettings = {
  __typename: "AdvancedSettings",
  appliedTo?: string | null,
  specificType?:  Array<SpecificType | null > | null,
};

export type SpecificType = {
  __typename: "SpecificType",
  name?: string | null,
  id?: string | null,
};

export type UpdatePromoCodesInput = {
  pk: string,
  id: string,
  promoImage?: string | null,
  promoURL?: string | null,
  code?: string | null,
  discount?: DiscountTypeInput | null,
  redemptionType?: string | null,
  searchKey?: string | null,
  validFrom?: string | null,
  validTo?: string | null,
  description?: string | null,
  terms?: string | null,
  advancedSetting?: AdvancedSettingsInput | null,
  status?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeletePromoCodesInput = {
  pk: string,
  id: string,
};

export type CreateJwtTokenIdentifierInput = {
  pk: string,
  jtiValue: string,
  issueDate?: string | null,
  expiryDateTime?: string | null,
  status?: string | null,
};

export type ModelJwtTokenIdentifierConditionInput = {
  pk?: ModelStringInput | null,
  jtiValue?: ModelStringInput | null,
  issueDate?: ModelStringInput | null,
  expiryDateTime?: ModelStringInput | null,
  status?: ModelStringInput | null,
  and?: Array< ModelJwtTokenIdentifierConditionInput | null > | null,
  or?: Array< ModelJwtTokenIdentifierConditionInput | null > | null,
  not?: ModelJwtTokenIdentifierConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type jwtTokenIdentifier = {
  __typename: "jwtTokenIdentifier",
  pk: string,
  jtiValue: string,
  issueDate?: string | null,
  expiryDateTime?: string | null,
  status?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateJwtTokenIdentifierInput = {
  pk: string,
  jtiValue: string,
  issueDate?: string | null,
  expiryDateTime?: string | null,
  status?: string | null,
};

export type DeleteJwtTokenIdentifierInput = {
  pk: string,
  jtiValue: string,
};

export type CreateUnitsInput = {
  pk: string,
  id?: string | null,
  value?: string | null,
  sequenceNumber?: number | null,
  searchKey?: string | null,
  type?: UnitType | null,
  status?: Status | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export enum UnitType {
  StockUnit = "StockUnit",
  Mass = "Mass",
  Distance = "Distance",
}


export type ModelUnitsConditionInput = {
  value?: ModelStringInput | null,
  sequenceNumber?: ModelIntInput | null,
  searchKey?: ModelStringInput | null,
  type?: ModelUnitTypeInput | null,
  status?: ModelStatusInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUnitsConditionInput | null > | null,
  or?: Array< ModelUnitsConditionInput | null > | null,
  not?: ModelUnitsConditionInput | null,
  owner?: ModelStringInput | null,
};

export type ModelUnitTypeInput = {
  eq?: UnitType | null,
  ne?: UnitType | null,
};

export type Units = {
  __typename: "Units",
  pk: string,
  id: string,
  value?: string | null,
  sequenceNumber?: number | null,
  searchKey?: string | null,
  type?: UnitType | null,
  status?: Status | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export type UpdateUnitsInput = {
  pk: string,
  id: string,
  value?: string | null,
  sequenceNumber?: number | null,
  searchKey?: string | null,
  type?: UnitType | null,
  status?: Status | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteUnitsInput = {
  pk: string,
  id: string,
};

export type CreateShippingInput = {
  id?: string | null,
  pk: string,
  city?: string | null,
  countryId?: string | null,
  countryName?: string | null,
  state?: string | null,
  deliveryCharge?: number | null,
  coverage?: Coverage | null,
  zipCodes?: Array< string | null > | null,
  latitude?: string | null,
  longitude?: string | null,
  radius?: string | null,
  searchKey?: string | null,
  status?: Status | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
};

export enum Coverage {
  Zipcode = "Zipcode",
  LatitudeAndLongitude = "LatitudeAndLongitude",
}


export type ModelShippingConditionInput = {
  city?: ModelStringInput | null,
  countryId?: ModelStringInput | null,
  countryName?: ModelStringInput | null,
  state?: ModelStringInput | null,
  deliveryCharge?: ModelFloatInput | null,
  coverage?: ModelCoverageInput | null,
  zipCodes?: ModelStringInput | null,
  latitude?: ModelStringInput | null,
  longitude?: ModelStringInput | null,
  radius?: ModelStringInput | null,
  searchKey?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  modifiedBy?: ModelStringInput | null,
  and?: Array< ModelShippingConditionInput | null > | null,
  or?: Array< ModelShippingConditionInput | null > | null,
  not?: ModelShippingConditionInput | null,
  owner?: ModelStringInput | null,
};

export type ModelCoverageInput = {
  eq?: Coverage | null,
  ne?: Coverage | null,
};

export type Shipping = {
  __typename: "Shipping",
  id: string,
  pk: string,
  city?: string | null,
  countryId?: string | null,
  countryName?: string | null,
  state?: string | null,
  deliveryCharge?: number | null,
  coverage?: Coverage | null,
  zipCodes?: Array< string | null > | null,
  latitude?: string | null,
  longitude?: string | null,
  radius?: string | null,
  searchKey?: string | null,
  status?: Status | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  owner?: string | null,
};

export type DeleteShippingInput = {
  pk: string,
  id: string,
};

export type CreateProductInput = {
  createdAt?: string | null,
  id?: string | null,
  pk: string,
  globalPk: string,
  productName: string,
  mainCategory?: string | null,
  mainCategoryID?: string | null,
  subCategory?: string | null,
  subCategoryID?: string | null,
  brandName?: string | null,
  brandID?: string | null,
  countryName?: string | null,
  countryID?: string | null,
  productDescription?: string | null,
  productFeatures?: string | null,
  keyWord?: Array< string | null > | null,
  featuredImage?: ImageUploadInput | null,
  galleryImages?: Array< ImageUploadInput | null > | null,
  singleVariant?: boolean | null,
  attrName?: string | null,
  attrNameID?: string | null,
  attrValue?: Array< AttributeValuesInput | null > | null,
  productSpecification?: Array< ProductSpecificationInput | null > | null,
  seoDescription?: string | null,
  stockStatus?: stockStatus | null,
  searchKey?: string | null,
  status?: productStatus | null,
  scheduleDate?: string | null,
  taxDetail?: Array< TaxDetailInput | null > | null,
  showWhenZeroStock?: boolean | null,
  allowPreOrder?: boolean | null,
  overallRating?: number | null,
  noOfRating?: number | null,
  leadTime?: string | null,
  totalStockQuantity?: number | null,
  totalReservedStock?: number | null,
  displaySalePrice?: number | null,
  displayRegularPrice?: number | null,
  thumbnailUrl?: string | null,
};

export type AttributeValuesInput = {
  value?: string | null,
  attrValueID?: string | null,
};

export type ProductSpecificationInput = {
  name?: string | null,
  value?: string | null,
};

export enum stockStatus {
  INSTOCK = "INSTOCK",
  OUTOFSTOCK = "OUTOFSTOCK",
}


export enum productStatus {
  draft = "draft",
  published = "published",
  scheduled = "scheduled",
  inactive = "inactive",
  deleted = "deleted",
}


export type ModelProductConditionInput = {
  createdAt?: ModelStringInput | null,
  globalPk?: ModelStringInput | null,
  productName?: ModelStringInput | null,
  mainCategory?: ModelStringInput | null,
  mainCategoryID?: ModelStringInput | null,
  subCategory?: ModelStringInput | null,
  subCategoryID?: ModelStringInput | null,
  brandName?: ModelStringInput | null,
  brandID?: ModelStringInput | null,
  countryName?: ModelStringInput | null,
  countryID?: ModelStringInput | null,
  productDescription?: ModelStringInput | null,
  productFeatures?: ModelStringInput | null,
  keyWord?: ModelStringInput | null,
  singleVariant?: ModelBooleanInput | null,
  attrName?: ModelStringInput | null,
  attrNameID?: ModelStringInput | null,
  seoDescription?: ModelStringInput | null,
  stockStatus?: ModelstockStatusInput | null,
  searchKey?: ModelStringInput | null,
  status?: ModelproductStatusInput | null,
  scheduleDate?: ModelStringInput | null,
  showWhenZeroStock?: ModelBooleanInput | null,
  allowPreOrder?: ModelBooleanInput | null,
  overallRating?: ModelFloatInput | null,
  noOfRating?: ModelIntInput | null,
  leadTime?: ModelStringInput | null,
  totalStockQuantity?: ModelFloatInput | null,
  totalReservedStock?: ModelFloatInput | null,
  displaySalePrice?: ModelFloatInput | null,
  displayRegularPrice?: ModelFloatInput | null,
  thumbnailUrl?: ModelStringInput | null,
  and?: Array< ModelProductConditionInput | null > | null,
  or?: Array< ModelProductConditionInput | null > | null,
  not?: ModelProductConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type ModelstockStatusInput = {
  eq?: stockStatus | null,
  ne?: stockStatus | null,
};

export type ModelproductStatusInput = {
  eq?: productStatus | null,
  ne?: productStatus | null,
};

export type Product = {
  __typename: "Product",
  createdAt: string,
  id: string,
  pk: string,
  globalPk: string,
  productName: string,
  mainCategory?: string | null,
  mainCategoryID?: string | null,
  subCategory?: string | null,
  subCategoryID?: string | null,
  brandName?: string | null,
  brandID?: string | null,
  countryName?: string | null,
  countryID?: string | null,
  productDescription?: string | null,
  productFeatures?: string | null,
  keyWord?: Array< string | null > | null,
  featuredImage?: imageUpload | null,
  galleryImages?:  Array<imageUpload | null > | null,
  singleVariant?: boolean | null,
  attrName?: string | null,
  attrNameID?: string | null,
  attrValue?:  Array<AttributeValues | null > | null,
  productSpecification?:  Array<productSpecification | null > | null,
  seoDescription?: string | null,
  stockStatus?: stockStatus | null,
  searchKey?: string | null,
  status?: productStatus | null,
  scheduleDate?: string | null,
  taxDetail?:  Array<taxDetail | null > | null,
  showWhenZeroStock?: boolean | null,
  allowPreOrder?: boolean | null,
  overallRating?: number | null,
  noOfRating?: number | null,
  leadTime?: string | null,
  totalStockQuantity?: number | null,
  totalReservedStock?: number | null,
  displaySalePrice?: number | null,
  displayRegularPrice?: number | null,
  thumbnailUrl?: string | null,
  updatedAt: string,
  owner?: string | null,
};

export type AttributeValues = {
  __typename: "AttributeValues",
  value?: string | null,
  attrValueID?: string | null,
};

export type productSpecification = {
  __typename: "productSpecification",
  name?: string | null,
  value?: string | null,
};

export type UpdateProductInput = {
  createdAt?: string | null,
  id: string,
  pk: string,
  globalPk?: string | null,
  productName?: string | null,
  mainCategory?: string | null,
  mainCategoryID?: string | null,
  subCategory?: string | null,
  subCategoryID?: string | null,
  brandName?: string | null,
  brandID?: string | null,
  countryName?: string | null,
  countryID?: string | null,
  productDescription?: string | null,
  productFeatures?: string | null,
  keyWord?: Array< string | null > | null,
  featuredImage?: ImageUploadInput | null,
  galleryImages?: Array< ImageUploadInput | null > | null,
  singleVariant?: boolean | null,
  attrName?: string | null,
  attrNameID?: string | null,
  attrValue?: Array< AttributeValuesInput | null > | null,
  productSpecification?: Array< ProductSpecificationInput | null > | null,
  seoDescription?: string | null,
  stockStatus?: stockStatus | null,
  searchKey?: string | null,
  status?: productStatus | null,
  scheduleDate?: string | null,
  taxDetail?: Array< TaxDetailInput | null > | null,
  showWhenZeroStock?: boolean | null,
  allowPreOrder?: boolean | null,
  overallRating?: number | null,
  noOfRating?: number | null,
  leadTime?: string | null,
  totalStockQuantity?: number | null,
  totalReservedStock?: number | null,
  displaySalePrice?: number | null,
  displayRegularPrice?: number | null,
  thumbnailUrl?: string | null,
};

export type DeleteProductInput = {
  pk: string,
  id: string,
};

export type CreateProductImageInput = {
  pk: string,
  id?: string | null,
  alterText?: string | null,
  title?: string | null,
  url?: string | null,
  description?: string | null,
  mainCategory?: string | null,
  mainCategoryID?: string | null,
  subCategory?: string | null,
  subCategoryID?: string | null,
  thumbnailUrl?: string | null,
  type?: ImageType | null,
  productID?: string | null,
  isDeleted?: boolean | null,
};

export type ModelProductImageConditionInput = {
  alterText?: ModelStringInput | null,
  title?: ModelStringInput | null,
  url?: ModelStringInput | null,
  description?: ModelStringInput | null,
  mainCategory?: ModelStringInput | null,
  mainCategoryID?: ModelStringInput | null,
  subCategory?: ModelStringInput | null,
  subCategoryID?: ModelStringInput | null,
  thumbnailUrl?: ModelStringInput | null,
  type?: ModelImageTypeInput | null,
  productID?: ModelStringInput | null,
  isDeleted?: ModelBooleanInput | null,
  and?: Array< ModelProductImageConditionInput | null > | null,
  or?: Array< ModelProductImageConditionInput | null > | null,
  not?: ModelProductImageConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type ModelImageTypeInput = {
  eq?: ImageType | null,
  ne?: ImageType | null,
};

export type ProductImage = {
  __typename: "ProductImage",
  pk: string,
  id: string,
  alterText?: string | null,
  title?: string | null,
  url?: string | null,
  description?: string | null,
  mainCategory?: string | null,
  mainCategoryID?: string | null,
  subCategory?: string | null,
  subCategoryID?: string | null,
  thumbnailUrl?: string | null,
  type?: ImageType | null,
  productID?: string | null,
  isDeleted?: boolean | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateProductImageInput = {
  pk: string,
  id: string,
  alterText?: string | null,
  title?: string | null,
  url?: string | null,
  description?: string | null,
  mainCategory?: string | null,
  mainCategoryID?: string | null,
  subCategory?: string | null,
  subCategoryID?: string | null,
  thumbnailUrl?: string | null,
  type?: ImageType | null,
  productID?: string | null,
  isDeleted?: boolean | null,
};

export type DeleteProductImageInput = {
  pk: string,
  id: string,
};

export type CreateProductDetailsInput = {
  id?: string | null,
  pk: string,
  productID?: string | null,
  attrName?: string | null,
  attrNameID?: string | null,
  attrValue?: string | null,
  attrValueID?: string | null,
  featuredImage?: ImageUploadInput | null,
  model?: string | null,
  manufacturePartCode?: string | null,
  sku?: string | null,
  stockStatus?: stockStatus | null,
  stockQuantity?: number | null,
  reservedStock?: number | null,
  stockUnit?: string | null,
  itemsPerUnit?: number | null,
  regularPrice?: number | null,
  salePrice?: number | null,
  dataSheet?: string | null,
  submittal?: string | null,
  catalog?: string | null,
  iom?: string | null,
  storageInfo?: StorageInfoDetailInput | null,
  shipplingInfo?: ShippingDetailsInput | null,
  partialReturn?: boolean | null,
  type?: VariantType | null,
  isDeleted?: boolean | null,
};

export type StorageInfoDetailInput = {
  wareHouseId?: string | null,
  wareHouse?: string | null,
  rackNo?: string | null,
  rowNo?: string | null,
  binNo?: string | null,
  note?: string | null,
};

export enum VariantType {
  single = "single",
  multiple = "multiple",
}


export type ModelProductDetailsConditionInput = {
  productID?: ModelStringInput | null,
  attrName?: ModelStringInput | null,
  attrNameID?: ModelStringInput | null,
  attrValue?: ModelStringInput | null,
  attrValueID?: ModelStringInput | null,
  model?: ModelStringInput | null,
  manufacturePartCode?: ModelStringInput | null,
  sku?: ModelStringInput | null,
  stockStatus?: ModelstockStatusInput | null,
  stockQuantity?: ModelFloatInput | null,
  reservedStock?: ModelFloatInput | null,
  stockUnit?: ModelStringInput | null,
  itemsPerUnit?: ModelIntInput | null,
  regularPrice?: ModelFloatInput | null,
  salePrice?: ModelFloatInput | null,
  dataSheet?: ModelStringInput | null,
  submittal?: ModelStringInput | null,
  catalog?: ModelStringInput | null,
  iom?: ModelStringInput | null,
  partialReturn?: ModelBooleanInput | null,
  type?: ModelVariantTypeInput | null,
  isDeleted?: ModelBooleanInput | null,
  and?: Array< ModelProductDetailsConditionInput | null > | null,
  or?: Array< ModelProductDetailsConditionInput | null > | null,
  not?: ModelProductDetailsConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type ModelVariantTypeInput = {
  eq?: VariantType | null,
  ne?: VariantType | null,
};

export type ProductDetails = {
  __typename: "ProductDetails",
  id: string,
  pk: string,
  productID?: string | null,
  attrName?: string | null,
  attrNameID?: string | null,
  attrValue?: string | null,
  attrValueID?: string | null,
  featuredImage?: imageUpload | null,
  model?: string | null,
  manufacturePartCode?: string | null,
  sku?: string | null,
  stockStatus?: stockStatus | null,
  stockQuantity?: number | null,
  reservedStock?: number | null,
  stockUnit?: string | null,
  itemsPerUnit?: number | null,
  regularPrice?: number | null,
  salePrice?: number | null,
  dataSheet?: string | null,
  submittal?: string | null,
  catalog?: string | null,
  iom?: string | null,
  storageInfo?: storageInfoDetail | null,
  shipplingInfo?: shippingDetails | null,
  partialReturn?: boolean | null,
  type?: VariantType | null,
  isDeleted?: boolean | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type storageInfoDetail = {
  __typename: "storageInfoDetail",
  wareHouseId?: string | null,
  wareHouse?: string | null,
  rackNo?: string | null,
  rowNo?: string | null,
  binNo?: string | null,
  note?: string | null,
};

export type UpdateProductDetailsInput = {
  id: string,
  pk: string,
  productID?: string | null,
  attrName?: string | null,
  attrNameID?: string | null,
  attrValue?: string | null,
  attrValueID?: string | null,
  featuredImage?: ImageUploadInput | null,
  model?: string | null,
  manufacturePartCode?: string | null,
  sku?: string | null,
  stockStatus?: stockStatus | null,
  stockQuantity?: number | null,
  reservedStock?: number | null,
  stockUnit?: string | null,
  itemsPerUnit?: number | null,
  regularPrice?: number | null,
  salePrice?: number | null,
  dataSheet?: string | null,
  submittal?: string | null,
  catalog?: string | null,
  iom?: string | null,
  storageInfo?: StorageInfoDetailInput | null,
  shipplingInfo?: ShippingDetailsInput | null,
  partialReturn?: boolean | null,
  type?: VariantType | null,
  isDeleted?: boolean | null,
};

export type DeleteProductDetailsInput = {
  pk: string,
  id: string,
};

export type CreateOrderInput = {
  pk: string,
  id?: string | null,
  userId: string,
  createdAt?: string | null,
  userName?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  orderType?: string | null,
  totalProducts?: number | null,
  orderSummary?: OrderSummaryInput | null,
  totalAmount?: number | null,
  paymentDetails?: PaymentDetailsInput | null,
  paymentStatus?: PaymentStatus | null,
  status?: OrderStatus | null,
  wishListId?: string | null,
  couponDetails?: CouponDetailsInput | null,
  discountByCoupon?: number | null,
  shippingUserDetails?: UserDetailsInput | null,
  shippingAddress?: AddressInput | null,
  deliveryInfo?: DeliveryInfoInput | null,
  billingUserDetails?: UserDetailsInput | null,
  billingAddress?: AddressInput | null,
  deliveryDate?: string | null,
  invoiceNumber?: string | null,
  invoiceURL?: string | null,
  orderPdf?: string | null,
  searchKey?: string | null,
};

export type OrderSummaryInput = {
  cartTotal?: number | null,
  productDiscount?: string | null,
  deliveryFee?: number | null,
  tax?: number | null,
};

export type PaymentDetailsInput = {
  paymentMode?: string | null,
  paymentMethod?: PaymentMethod | null,
  cardType?: string | null,
  cardHolderName?: string | null,
  cardNumber?: string | null,
  CardExpiryDate?: string | null,
  UPI?: boolean | null,
  paymentDate?: string | null,
  referenceNumber?: string | null,
  chequeNumber?: string | null,
  bankName?: string | null,
  chequeDate?: string | null,
  receivedAmount?: number | null,
  remaningAmount?: number | null,
  chequeStatus?: ChequeStatus | null,
  partialPaymentDetails?: Array< CashOnDeliveryCollectionInput | null > | null,
};

export enum PaymentMethod {
  CreditCard = "CreditCard",
  BankTransfer = "BankTransfer",
  CashOnDelivery = "CashOnDelivery",
  Others = "Others",
}


export enum ChequeStatus {
  Received = "Received",
  Cleared = "Cleared",
  Bounced = "Bounced",
}


export type CashOnDeliveryCollectionInput = {
  chequeNumber?: string | null,
  bankName?: string | null,
  chequeDate?: string | null,
  receivedAmount?: number | null,
  chequeStatus?: ChequeStatus | null,
  paymentDate?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export enum PaymentStatus {
  Pending = "Pending",
  Confirmed = "Confirmed",
  Failed = "Failed",
}


export enum OrderStatus {
  PENDING = "PENDING",
  CONFIRMED = "CONFIRMED",
  PROCESSING = "PROCESSING",
  DELIVERED = "DELIVERED",
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
}


export type CouponDetailsInput = {
  couponCode?: string | null,
  amount?: number | null,
  discount?: DiscountTypeInput | null,
  advancedSetting?: AdvancedSettingsInput | null,
};

export type UserDetailsInput = {
  userName?: string | null,
  phoneNumber?: string | null,
  email?: string | null,
};

export type DeliveryInfoInput = {
  deliveryPartner?: string | null,
  deliveryType?: string | null,
  productWeight?: string | null,
};

export type ModelOrderConditionInput = {
  userId?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  userName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  orderType?: ModelStringInput | null,
  totalProducts?: ModelIntInput | null,
  totalAmount?: ModelFloatInput | null,
  paymentStatus?: ModelPaymentStatusInput | null,
  status?: ModelOrderStatusInput | null,
  wishListId?: ModelStringInput | null,
  discountByCoupon?: ModelFloatInput | null,
  deliveryDate?: ModelStringInput | null,
  invoiceNumber?: ModelStringInput | null,
  invoiceURL?: ModelStringInput | null,
  orderPdf?: ModelStringInput | null,
  searchKey?: ModelStringInput | null,
  and?: Array< ModelOrderConditionInput | null > | null,
  or?: Array< ModelOrderConditionInput | null > | null,
  not?: ModelOrderConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type ModelPaymentStatusInput = {
  eq?: PaymentStatus | null,
  ne?: PaymentStatus | null,
};

export type ModelOrderStatusInput = {
  eq?: OrderStatus | null,
  ne?: OrderStatus | null,
};

export type Order = {
  __typename: "Order",
  pk: string,
  id: string,
  userId: string,
  createdAt: string,
  userName?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  orderType?: string | null,
  totalProducts?: number | null,
  orderSummary?: OrderSummary | null,
  totalAmount?: number | null,
  paymentDetails?: PaymentDetails | null,
  paymentStatus?: PaymentStatus | null,
  status?: OrderStatus | null,
  wishListId?: string | null,
  couponDetails?: CouponDetails | null,
  discountByCoupon?: number | null,
  shippingUserDetails?: UserDetails | null,
  shippingAddress?: Address | null,
  deliveryInfo?: DeliveryInfo | null,
  billingUserDetails?: UserDetails | null,
  billingAddress?: Address | null,
  deliveryDate?: string | null,
  invoiceNumber?: string | null,
  invoiceURL?: string | null,
  orderPdf?: string | null,
  searchKey?: string | null,
  updatedAt: string,
  owner?: string | null,
};

export type OrderSummary = {
  __typename: "OrderSummary",
  cartTotal?: number | null,
  productDiscount?: string | null,
  deliveryFee?: number | null,
  tax?: number | null,
};

export type PaymentDetails = {
  __typename: "PaymentDetails",
  paymentMode?: string | null,
  paymentMethod?: PaymentMethod | null,
  cardType?: string | null,
  cardHolderName?: string | null,
  cardNumber?: string | null,
  CardExpiryDate?: string | null,
  UPI?: boolean | null,
  paymentDate?: string | null,
  referenceNumber?: string | null,
  chequeNumber?: string | null,
  bankName?: string | null,
  chequeDate?: string | null,
  receivedAmount?: number | null,
  remaningAmount?: number | null,
  chequeStatus?: ChequeStatus | null,
  partialPaymentDetails?:  Array<CashOnDeliveryCollection | null > | null,
};

export type CashOnDeliveryCollection = {
  __typename: "CashOnDeliveryCollection",
  chequeNumber?: string | null,
  bankName?: string | null,
  chequeDate?: string | null,
  receivedAmount?: number | null,
  chequeStatus?: ChequeStatus | null,
  paymentDate?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type CouponDetails = {
  __typename: "CouponDetails",
  couponCode?: string | null,
  amount?: number | null,
  discount?: discountType | null,
  advancedSetting?: AdvancedSettings | null,
};

export type UserDetails = {
  __typename: "UserDetails",
  userName?: string | null,
  phoneNumber?: string | null,
  email?: string | null,
};

export type DeliveryInfo = {
  __typename: "DeliveryInfo",
  deliveryPartner?: string | null,
  deliveryType?: string | null,
  productWeight?: string | null,
};

export type UpdateOrderInput = {
  pk: string,
  id: string,
  userId?: string | null,
  createdAt?: string | null,
  userName?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  orderType?: string | null,
  totalProducts?: number | null,
  orderSummary?: OrderSummaryInput | null,
  totalAmount?: number | null,
  paymentDetails?: PaymentDetailsInput | null,
  paymentStatus?: PaymentStatus | null,
  status?: OrderStatus | null,
  wishListId?: string | null,
  couponDetails?: CouponDetailsInput | null,
  discountByCoupon?: number | null,
  shippingUserDetails?: UserDetailsInput | null,
  shippingAddress?: AddressInput | null,
  deliveryInfo?: DeliveryInfoInput | null,
  billingUserDetails?: UserDetailsInput | null,
  billingAddress?: AddressInput | null,
  deliveryDate?: string | null,
  invoiceNumber?: string | null,
  invoiceURL?: string | null,
  orderPdf?: string | null,
  searchKey?: string | null,
};

export type DeleteOrderInput = {
  pk: string,
  id: string,
};

export type CreateOrderItemInput = {
  pk: string,
  id?: string | null,
  productId?: string | null,
  mainCategoryId?: string | null,
  mainCategory?: string | null,
  subCategoryId?: string | null,
  subCategory?: string | null,
  userId: string,
  wareHouseId?: string | null,
  attrValueID?: string | null,
  wareHouseName?: string | null,
  userName?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  createdAt?: string | null,
  quantity?: number | null,
  stockUnit?: string | null,
  itemsPerUnit?: number | null,
  taxCode?: string | null,
  taxAmount?: number | null,
  taxDetail?: Array< TaxDetailInput | null > | null,
  regularPrice?: number | null,
  totalPrice?: number | null,
  itemDetail?: ItemDetailInput | null,
  sku?: string | null,
  attrName?: string | null,
  attrValue?: string | null,
  deliveryStatus?: ProductDeliveryStatus | null,
  deliveryBy?: string | null,
  deliveryTracking?: Array< DeliveryTrackingInput | null > | null,
  deliveryPartner?: string | null,
  outForDeliveryDate?: string | null,
  outForDeliveryTime?: string | null,
  expectedDeliveryDate?: string | null,
  trackingID?: string | null,
  trackingUrl?: string | null,
  deliveryPersonInfo?: DeliveryPersonInfoInput | null,
  orderedOn?: string | null,
  returnRefundActivity?: ReturnRefundActivityInput | null,
  eta?: string | null,
  paymentStatus?: PaymentStatus | null,
  billOfLading?: string | null,
  packingList?: string | null,
  searchKey?: string | null,
  shippingInfo?: ShippingDetailsInput | null,
  returnAvailable?: boolean | null,
  disputeResolved?: boolean | null,
  brandId?: string | null,
  returnValue?: number | null,
};

export type ItemDetailInput = {
  productName?: string | null,
  price?: number | null,
  image?: string | null,
  brand?: string | null,
  model?: string | null,
  tax?: number | null,
  currentStock?: string | null,
  sku?: string | null,
  qrcode?: string | null,
};

export enum ProductDeliveryStatus {
  Received = "Received",
  Processing = "Processing",
  Packing = "Packing",
  OutForDelivery = "OutForDelivery",
  Delivered = "Delivered",
  Cancelled = "Cancelled",
  Refunded = "Refunded",
}


export type DeliveryTrackingInput = {
  status?: ProductDeliveryStatus | null,
  date?: string | null,
  trackingBy?: string | null,
  phoneNumber?: string | null,
  remarks?: string | null,
  receipt?: string | null,
};

export type DeliveryPersonInfoInput = {
  name?: string | null,
  contactNumber?: string | null,
  vehiclenumber?: string | null,
  notes?: string | null,
};

export type ReturnRefundActivityInput = {
  returnReference?: string | null,
  reason?: string | null,
  comment?: string | null,
  quantity?: string | null,
  subTotal?: number | null,
  total?: number | null,
  attachments?: Array< string | null > | null,
  paymentMode?: string | null,
  paymentMethod?: PaymentMethod | null,
  returnType?: ReturnType | null,
  returnAddress?: AddressInput | null,
  pickUpDetails?: PickUpDetailsInput | null,
  receivedInfo?: ReceivedInfoInput | null,
  updateInStock?: boolean | null,
  updateInStockQty?: string | null,
  refundInfo?: RefundInfoInput | null,
  status?: ReturnActivityStatus | null,
  returnStatusUpdate?: Array< ReturnUpdatesInput | null > | null,
  rejectReason?: string | null,
  createdAt?: string | null,
};

export enum ReturnType {
  Replace = "Replace",
  Refund = "Refund",
}


export type PickUpDetailsInput = {
  pickUpAddress?: AddressInput | null,
  pickUpBy?: string | null,
  deliveryPartner?: string | null,
  name?: string | null,
  phoneNumber?: string | null,
  expectedPickUpDate?: string | null,
  time?: string | null,
};

export type ReceivedInfoInput = {
  receivedOn?: string | null,
  receivedAs?: string | null,
  images?: Array< string | null > | null,
};

export type RefundInfoInput = {
  date?: string | null,
  paymentMethod?: PaymentMethod | null,
  refundAmount?: number | null,
  refundInfo?: string | null,
  proofImage?: string | null,
  proofImageUrl?: string | null,
  rejectedReason?: string | null,
};

export enum ReturnActivityStatus {
  Requested = "Requested",
  Cancelled = "Cancelled",
  Accepted = "Accepted",
  Rejected = "Rejected",
  Shipped = "Shipped",
  Received = "Received",
  RefundApproved = "RefundApproved",
  RefundRejected = "RefundRejected",
}


export type ReturnUpdatesInput = {
  returnStatus?: ReturnActivityStatus | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type ModelOrderItemConditionInput = {
  productId?: ModelStringInput | null,
  mainCategoryId?: ModelStringInput | null,
  mainCategory?: ModelStringInput | null,
  subCategoryId?: ModelStringInput | null,
  subCategory?: ModelStringInput | null,
  userId?: ModelStringInput | null,
  wareHouseId?: ModelStringInput | null,
  attrValueID?: ModelStringInput | null,
  wareHouseName?: ModelStringInput | null,
  userName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  quantity?: ModelIntInput | null,
  stockUnit?: ModelStringInput | null,
  itemsPerUnit?: ModelIntInput | null,
  taxCode?: ModelStringInput | null,
  taxAmount?: ModelFloatInput | null,
  regularPrice?: ModelFloatInput | null,
  totalPrice?: ModelFloatInput | null,
  sku?: ModelStringInput | null,
  attrName?: ModelStringInput | null,
  attrValue?: ModelStringInput | null,
  deliveryStatus?: ModelProductDeliveryStatusInput | null,
  deliveryBy?: ModelStringInput | null,
  deliveryPartner?: ModelStringInput | null,
  outForDeliveryDate?: ModelStringInput | null,
  outForDeliveryTime?: ModelStringInput | null,
  expectedDeliveryDate?: ModelStringInput | null,
  trackingID?: ModelStringInput | null,
  trackingUrl?: ModelStringInput | null,
  orderedOn?: ModelStringInput | null,
  eta?: ModelStringInput | null,
  paymentStatus?: ModelPaymentStatusInput | null,
  billOfLading?: ModelStringInput | null,
  packingList?: ModelStringInput | null,
  searchKey?: ModelStringInput | null,
  returnAvailable?: ModelBooleanInput | null,
  disputeResolved?: ModelBooleanInput | null,
  brandId?: ModelStringInput | null,
  returnValue?: ModelFloatInput | null,
  and?: Array< ModelOrderItemConditionInput | null > | null,
  or?: Array< ModelOrderItemConditionInput | null > | null,
  not?: ModelOrderItemConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type ModelProductDeliveryStatusInput = {
  eq?: ProductDeliveryStatus | null,
  ne?: ProductDeliveryStatus | null,
};

export type OrderItem = {
  __typename: "OrderItem",
  pk: string,
  id: string,
  productId?: string | null,
  mainCategoryId?: string | null,
  mainCategory?: string | null,
  subCategoryId?: string | null,
  subCategory?: string | null,
  userId: string,
  wareHouseId?: string | null,
  attrValueID?: string | null,
  wareHouseName?: string | null,
  userName?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  createdAt: string,
  quantity?: number | null,
  stockUnit?: string | null,
  itemsPerUnit?: number | null,
  taxCode?: string | null,
  taxAmount?: number | null,
  taxDetail?:  Array<taxDetail | null > | null,
  regularPrice?: number | null,
  totalPrice?: number | null,
  itemDetail?: ItemDetail | null,
  sku?: string | null,
  attrName?: string | null,
  attrValue?: string | null,
  deliveryStatus?: ProductDeliveryStatus | null,
  deliveryBy?: string | null,
  deliveryTracking?:  Array<DeliveryTracking | null > | null,
  deliveryPartner?: string | null,
  outForDeliveryDate?: string | null,
  outForDeliveryTime?: string | null,
  expectedDeliveryDate?: string | null,
  trackingID?: string | null,
  trackingUrl?: string | null,
  deliveryPersonInfo?: DeliveryPersonInfo | null,
  orderedOn?: string | null,
  returnRefundActivity?: ReturnRefundActivity | null,
  eta?: string | null,
  paymentStatus?: PaymentStatus | null,
  billOfLading?: string | null,
  packingList?: string | null,
  searchKey?: string | null,
  shippingInfo?: shippingDetails | null,
  returnAvailable?: boolean | null,
  disputeResolved?: boolean | null,
  brandId?: string | null,
  returnValue?: number | null,
  updatedAt: string,
  owner?: string | null,
};

export type ItemDetail = {
  __typename: "ItemDetail",
  productName?: string | null,
  price?: number | null,
  image?: string | null,
  brand?: string | null,
  model?: string | null,
  tax?: number | null,
  currentStock?: string | null,
  sku?: string | null,
  qrcode?: string | null,
};

export type DeliveryTracking = {
  __typename: "DeliveryTracking",
  status?: ProductDeliveryStatus | null,
  date?: string | null,
  trackingBy?: string | null,
  phoneNumber?: string | null,
  remarks?: string | null,
  receipt?: string | null,
};

export type DeliveryPersonInfo = {
  __typename: "DeliveryPersonInfo",
  name?: string | null,
  contactNumber?: string | null,
  vehiclenumber?: string | null,
  notes?: string | null,
};

export type ReturnRefundActivity = {
  __typename: "ReturnRefundActivity",
  returnReference?: string | null,
  reason?: string | null,
  comment?: string | null,
  quantity?: string | null,
  subTotal?: number | null,
  total?: number | null,
  attachments?: Array< string | null > | null,
  paymentMode?: string | null,
  paymentMethod?: PaymentMethod | null,
  returnType?: ReturnType | null,
  returnAddress?: Address | null,
  pickUpDetails?: PickUpDetails | null,
  receivedInfo?: ReceivedInfo | null,
  updateInStock?: boolean | null,
  updateInStockQty?: string | null,
  refundInfo?: RefundInfo | null,
  status?: ReturnActivityStatus | null,
  returnStatusUpdate?:  Array<ReturnUpdates | null > | null,
  rejectReason?: string | null,
  createdAt?: string | null,
};

export type PickUpDetails = {
  __typename: "PickUpDetails",
  pickUpAddress?: Address | null,
  pickUpBy?: string | null,
  deliveryPartner?: string | null,
  name?: string | null,
  phoneNumber?: string | null,
  expectedPickUpDate?: string | null,
  time?: string | null,
};

export type ReceivedInfo = {
  __typename: "ReceivedInfo",
  receivedOn?: string | null,
  receivedAs?: string | null,
  images?: Array< string | null > | null,
};

export type RefundInfo = {
  __typename: "RefundInfo",
  date?: string | null,
  paymentMethod?: PaymentMethod | null,
  refundAmount?: number | null,
  refundInfo?: string | null,
  proofImage?: string | null,
  proofImageUrl?: string | null,
  rejectedReason?: string | null,
};

export type ReturnUpdates = {
  __typename: "ReturnUpdates",
  returnStatus?: ReturnActivityStatus | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type UpdateOrderItemInput = {
  pk: string,
  id: string,
  productId?: string | null,
  mainCategoryId?: string | null,
  mainCategory?: string | null,
  subCategoryId?: string | null,
  subCategory?: string | null,
  userId?: string | null,
  wareHouseId?: string | null,
  attrValueID?: string | null,
  wareHouseName?: string | null,
  userName?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  createdAt?: string | null,
  quantity?: number | null,
  stockUnit?: string | null,
  itemsPerUnit?: number | null,
  taxCode?: string | null,
  taxAmount?: number | null,
  taxDetail?: Array< TaxDetailInput | null > | null,
  regularPrice?: number | null,
  totalPrice?: number | null,
  itemDetail?: ItemDetailInput | null,
  sku?: string | null,
  attrName?: string | null,
  attrValue?: string | null,
  deliveryStatus?: ProductDeliveryStatus | null,
  deliveryBy?: string | null,
  deliveryTracking?: Array< DeliveryTrackingInput | null > | null,
  deliveryPartner?: string | null,
  outForDeliveryDate?: string | null,
  outForDeliveryTime?: string | null,
  expectedDeliveryDate?: string | null,
  trackingID?: string | null,
  trackingUrl?: string | null,
  deliveryPersonInfo?: DeliveryPersonInfoInput | null,
  orderedOn?: string | null,
  returnRefundActivity?: ReturnRefundActivityInput | null,
  eta?: string | null,
  paymentStatus?: PaymentStatus | null,
  billOfLading?: string | null,
  packingList?: string | null,
  searchKey?: string | null,
  shippingInfo?: ShippingDetailsInput | null,
  returnAvailable?: boolean | null,
  disputeResolved?: boolean | null,
  brandId?: string | null,
  returnValue?: number | null,
};

export type DeleteOrderItemInput = {
  pk: string,
  id: string,
};

export type CreateReportMasterInput = {
  pk: string,
  id?: string | null,
  reportName?: string | null,
  description?: string | null,
  sequenceNumber?: number | null,
  searchKey?: string | null,
  status?: Status | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelReportMasterConditionInput = {
  reportName?: ModelStringInput | null,
  description?: ModelStringInput | null,
  sequenceNumber?: ModelIntInput | null,
  searchKey?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelReportMasterConditionInput | null > | null,
  or?: Array< ModelReportMasterConditionInput | null > | null,
  not?: ModelReportMasterConditionInput | null,
  owner?: ModelStringInput | null,
};

export type ReportMaster = {
  __typename: "ReportMaster",
  pk: string,
  id: string,
  reportName?: string | null,
  description?: string | null,
  sequenceNumber?: number | null,
  searchKey?: string | null,
  status?: Status | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export type UpdateReportMasterInput = {
  pk: string,
  id: string,
  reportName?: string | null,
  description?: string | null,
  sequenceNumber?: number | null,
  searchKey?: string | null,
  status?: Status | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteReportMasterInput = {
  pk: string,
  id: string,
};

export type CreateUserInput = {
  id?: string | null,
  pk: string,
  userName?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  companyName?: string | null,
  profileImageUrl?: string | null,
  email: string,
  phoneNumber?: string | null,
  role?: UserRole | null,
  password?: string | null,
  multiFactorAuth?: boolean | null,
  emailNotification?: boolean | null,
  mandatePasswordChange?: boolean | null,
  emailVerified?: boolean | null,
  otp?: string | null,
  status?: Status | null,
  searchKey?: string | null,
  addressDetails?: Array< AddressDetailsInput | null > | null,
  loginAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  platform?: Platform | null,
};

export type AddressDetailsInput = {
  name?: string | null,
  phoneNumber?: string | null,
  email?: string | null,
  address?: AddressInput | null,
  type?: string | null,
  addressName?: string | null,
  default?: boolean | null,
  latitude?: string | null,
  longitude?: string | null,
};

export type UpdateUserInput = {
  id: string,
  pk: string,
  userName?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  companyName?: string | null,
  profileImageUrl?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  role?: UserRole | null,
  password?: string | null,
  multiFactorAuth?: boolean | null,
  emailNotification?: boolean | null,
  mandatePasswordChange?: boolean | null,
  emailVerified?: boolean | null,
  otp?: string | null,
  status?: Status | null,
  searchKey?: string | null,
  addressDetails?: Array< AddressDetailsInput | null > | null,
  loginAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  platform?: Platform | null,
};

export type CreateGeneralContactUsInput = {
  pk: string,
  id?: string | null,
  referenceNumber?: string | null,
  userName?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  companyName?: string | null,
  subject?: string | null,
  message?: string | null,
  replyMessage?: string | null,
  enquiryStatus?: EnquiryStatus | null,
  attachments?: Array< string | null > | null,
  searchKey?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelGeneralContactUsConditionInput = {
  referenceNumber?: ModelStringInput | null,
  userName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  companyName?: ModelStringInput | null,
  subject?: ModelStringInput | null,
  message?: ModelStringInput | null,
  replyMessage?: ModelStringInput | null,
  enquiryStatus?: ModelEnquiryStatusInput | null,
  attachments?: ModelStringInput | null,
  searchKey?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGeneralContactUsConditionInput | null > | null,
  or?: Array< ModelGeneralContactUsConditionInput | null > | null,
  not?: ModelGeneralContactUsConditionInput | null,
  owner?: ModelStringInput | null,
};

export type GeneralContactUs = {
  __typename: "GeneralContactUs",
  pk: string,
  id: string,
  referenceNumber?: string | null,
  userName?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  companyName?: string | null,
  subject?: string | null,
  message?: string | null,
  replyMessage?: string | null,
  enquiryStatus?: EnquiryStatus | null,
  attachments?: Array< string | null > | null,
  searchKey?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export type UpdateGeneralContactUsInput = {
  pk: string,
  id: string,
  referenceNumber?: string | null,
  userName?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  companyName?: string | null,
  subject?: string | null,
  message?: string | null,
  replyMessage?: string | null,
  enquiryStatus?: EnquiryStatus | null,
  attachments?: Array< string | null > | null,
  searchKey?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteGeneralContactUsInput = {
  pk: string,
  id: string,
};

export type CreateConfigurationInput = {
  pk: string,
  id?: string | null,
  configurationDescription?: string | null,
  continuousSettings?: ContinuousSettingsInput | null,
  type?: string | null,
  modifiedTime?: string | null,
  modifiedBy?: string | null,
};

export type ContinuousSettingsInput = {
  prefixValue?: string | null,
  numberOfDigits?: number | null,
  includeYearSuffix?: boolean | null,
  startingNumber?: number | null,
  runningNumber?: number | null,
};

export type UpdateConfigurationInput = {
  pk: string,
  id: string,
  configurationDescription?: string | null,
  continuousSettings?: ContinuousSettingsInput | null,
  type?: string | null,
  modifiedTime?: string | null,
  modifiedBy?: string | null,
};

export type CreateProductVisitInput = {
  pk: string,
  id?: string | null,
  prodId?: string | null,
  userId?: string | null,
  userName?: string | null,
  visitedAt?: string | null,
  place?: string | null,
  visitedInfo?: Array< VisitedInfoInput | null > | null,
};

export type VisitedInfoInput = {
  platform?: string | null,
  location?: string | null,
  time?: string | null,
  ipAddress?: string | null,
};

export type UpdateProductVisitInput = {
  pk: string,
  id: string,
  prodId?: string | null,
  userId?: string | null,
  userName?: string | null,
  visitedAt?: string | null,
  place?: string | null,
  visitedInfo?: Array< VisitedInfoInput | null > | null,
};

export type UpdateShippingInput = {
  id: string,
  pk: string,
  city?: string | null,
  countryId?: string | null,
  countryName?: string | null,
  state?: string | null,
  deliveryCharge?: number | null,
  coverage?: Coverage | null,
  zipCodes?: Array< string | null > | null,
  latitude?: string | null,
  longitude?: string | null,
  radius?: string | null,
  searchKey?: string | null,
  status?: Status | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelSettingsFilterInput = {
  id?: ModelIDInput | null,
  pk?: ModelStringInput | null,
  notificationType?: ModelNotificationTypeInput | null,
  createdAt?: ModelStringInput | null,
  emailTo?: ModelStringInput | null,
  productExpiryDuration?: ModelStringInput | null,
  theme?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSettingsFilterInput | null > | null,
  or?: Array< ModelSettingsFilterInput | null > | null,
  not?: ModelSettingsFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelSettingsConnection = {
  __typename: "ModelSettingsConnection",
  items:  Array<Settings | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelCompanyDetailsFilterInput = {
  id?: ModelIDInput | null,
  pk?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  companyName?: ModelStringInput | null,
  webSite?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  email?: ModelStringInput | null,
  ccEmail?: ModelStringInput | null,
  status?: ModelBooleanInput | null,
  currency?: ModelStringInput | null,
  categorySequenced?: ModelBooleanInput | null,
  copyrightText?: ModelStringInput | null,
  seoDescription?: ModelStringInput | null,
  daysToReturn?: ModelIntInput | null,
  brandName?: ModelStringInput | null,
  iconUrl?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCompanyDetailsFilterInput | null > | null,
  or?: Array< ModelCompanyDetailsFilterInput | null > | null,
  not?: ModelCompanyDetailsFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelCompanyDetailsConnection = {
  __typename: "ModelCompanyDetailsConnection",
  items:  Array<CompanyDetails | null >,
  nextToken?: string | null,
};

export type ModelPaymentModeFilterInput = {
  id?: ModelIDInput | null,
  pk?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  method?: ModelStringInput | null,
  description?: ModelStringInput | null,
  provider?: ModelStringInput | null,
  logoUrl?: ModelStringInput | null,
  stagingUrl?: ModelStringInput | null,
  productionUrl?: ModelStringInput | null,
  status?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPaymentModeFilterInput | null > | null,
  or?: Array< ModelPaymentModeFilterInput | null > | null,
  not?: ModelPaymentModeFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelPaymentModeConnection = {
  __typename: "ModelPaymentModeConnection",
  items:  Array<PaymentMode | null >,
  nextToken?: string | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  pk?: ModelStringInput | null,
  userName?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  companyName?: ModelStringInput | null,
  profileImageUrl?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  role?: ModelUserRoleInput | null,
  password?: ModelStringInput | null,
  multiFactorAuth?: ModelBooleanInput | null,
  emailNotification?: ModelBooleanInput | null,
  mandatePasswordChange?: ModelBooleanInput | null,
  emailVerified?: ModelBooleanInput | null,
  otp?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  searchKey?: ModelStringInput | null,
  loginAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  platform?: ModelPlatformInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelCategoryFilterInput = {
  pk?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  id?: ModelIDInput | null,
  catName?: ModelStringInput | null,
  mainCategory?: ModelStringInput | null,
  mainCategoryID?: ModelStringInput | null,
  categoryImg?: ModelStringInput | null,
  description?: ModelStringInput | null,
  webBannerImg?: ModelStringInput | null,
  webBannerImgBottom?: ModelStringInput | null,
  mobBannerImg?: ModelStringInput | null,
  mobBannerImgBottom?: ModelStringInput | null,
  categoryType?: ModelCategoryTypeInput | null,
  searchKey?: ModelStringInput | null,
  catStatus?: ModelBooleanInput | null,
  sequenceNumber?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCategoryFilterInput | null > | null,
  or?: Array< ModelCategoryFilterInput | null > | null,
  not?: ModelCategoryFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelCategoryConnection = {
  __typename: "ModelCategoryConnection",
  items:  Array<Category | null >,
  nextToken?: string | null,
};

export type ModelBrandFilterInput = {
  pk?: ModelStringInput | null,
  id?: ModelIDInput | null,
  brandName?: ModelStringInput | null,
  searchKey?: ModelStringInput | null,
  manufacturerName?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  countryOfOrigin?: ModelStringInput | null,
  brandLogo?: ModelStringInput | null,
  brandUrl?: ModelStringInput | null,
  brandDescription?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBrandFilterInput | null > | null,
  or?: Array< ModelBrandFilterInput | null > | null,
  not?: ModelBrandFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelBrandConnection = {
  __typename: "ModelBrandConnection",
  items:  Array<Brand | null >,
  nextToken?: string | null,
};

export type ModelAttributeNameFilterInput = {
  pk?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  id?: ModelIDInput | null,
  sequenceNumber?: ModelIntInput | null,
  searchKey?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  attrName?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAttributeNameFilterInput | null > | null,
  or?: Array< ModelAttributeNameFilterInput | null > | null,
  not?: ModelAttributeNameFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelAttributeNameConnection = {
  __typename: "ModelAttributeNameConnection",
  items:  Array<AttributeName | null >,
  nextToken?: string | null,
};

export type ModelCountryFilterInput = {
  pk?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  shortName?: ModelStringInput | null,
  searchKey?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCountryFilterInput | null > | null,
  or?: Array< ModelCountryFilterInput | null > | null,
  not?: ModelCountryFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelCountryConnection = {
  __typename: "ModelCountryConnection",
  items:  Array<Country | null >,
  nextToken?: string | null,
};

export type ModelDropDownMasterFilterInput = {
  pk?: ModelStringInput | null,
  id?: ModelIDInput | null,
  value?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  searchKey?: ModelStringInput | null,
  sequenceNumber?: ModelIntInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDropDownMasterFilterInput | null > | null,
  or?: Array< ModelDropDownMasterFilterInput | null > | null,
  not?: ModelDropDownMasterFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelDropDownMasterConnection = {
  __typename: "ModelDropDownMasterConnection",
  items:  Array<DropDownMaster | null >,
  nextToken?: string | null,
};

export type ModelCartFilterInput = {
  pk?: ModelStringInput | null,
  id?: ModelIDInput | null,
  userId?: ModelStringInput | null,
  productID?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  productImage?: ModelStringInput | null,
  productName?: ModelStringInput | null,
  mainCategoryId?: ModelStringInput | null,
  mainCategory?: ModelStringInput | null,
  subCategoryId?: ModelStringInput | null,
  subCategoryN?: ModelStringInput | null,
  attrValueID?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  salesPrice?: ModelFloatInput | null,
  stockUnit?: ModelStringInput | null,
  itemsPerUnit?: ModelIntInput | null,
  expiryDate?: ModelStringInput | null,
  selectedQuantity?: ModelIntInput | null,
  wareHouseId?: ModelStringInput | null,
  wareHouseName?: ModelStringInput | null,
  attrName?: ModelStringInput | null,
  attrValue?: ModelStringInput | null,
  userName?: ModelStringInput | null,
  userMail?: ModelStringInput | null,
  model?: ModelStringInput | null,
  cartURL?: ModelStringInput | null,
  taxCode?: ModelStringInput | null,
  taxCharge?: ModelFloatInput | null,
  sku?: ModelStringInput | null,
  searchKey?: ModelStringInput | null,
  totalPrice?: ModelFloatInput | null,
  mobileNumber?: ModelStringInput | null,
  currentStock?: ModelStringInput | null,
  brandId?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCartFilterInput | null > | null,
  or?: Array< ModelCartFilterInput | null > | null,
  not?: ModelCartFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelCartConnection = {
  __typename: "ModelCartConnection",
  items:  Array<Cart | null >,
  nextToken?: string | null,
};

export type ModelWareHouseFilterInput = {
  pk?: ModelStringInput | null,
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  addressLine1?: ModelStringInput | null,
  addressLine2?: ModelStringInput | null,
  searchKey?: ModelStringInput | null,
  countryId?: ModelStringInput | null,
  country?: ModelStringInput | null,
  state?: ModelStringInput | null,
  city?: ModelStringInput | null,
  zipCode?: ModelStringInput | null,
  contactPerson?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  workingHoursFrom?: ModelStringInput | null,
  workingHoursTo?: ModelStringInput | null,
  status?: ModelBooleanInput | null,
  latitude?: ModelStringInput | null,
  longitude?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelWareHouseFilterInput | null > | null,
  or?: Array< ModelWareHouseFilterInput | null > | null,
  not?: ModelWareHouseFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelWareHouseConnection = {
  __typename: "ModelWareHouseConnection",
  items:  Array<WareHouse | null >,
  nextToken?: string | null,
};

export type ModelGeneralContactUsFilterInput = {
  pk?: ModelStringInput | null,
  id?: ModelIDInput | null,
  referenceNumber?: ModelStringInput | null,
  userName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  companyName?: ModelStringInput | null,
  subject?: ModelStringInput | null,
  message?: ModelStringInput | null,
  replyMessage?: ModelStringInput | null,
  enquiryStatus?: ModelEnquiryStatusInput | null,
  attachments?: ModelStringInput | null,
  searchKey?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGeneralContactUsFilterInput | null > | null,
  or?: Array< ModelGeneralContactUsFilterInput | null > | null,
  not?: ModelGeneralContactUsFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelGeneralContactUsConnection = {
  __typename: "ModelGeneralContactUsConnection",
  items:  Array<GeneralContactUs | null >,
  nextToken?: string | null,
};

export type ModelNotificationsFilterInput = {
  pk?: ModelStringInput | null,
  id?: ModelIDInput | null,
  userId?: ModelStringInput | null,
  userName?: ModelStringInput | null,
  notificationType?: ModelStringInput | null,
  Title?: ModelStringInput | null,
  Message?: ModelStringInput | null,
  orderID?: ModelStringInput | null,
  DateTime?: ModelStringInput | null,
  notificationStatus?: ModelIntInput | null,
  notificationModule?: ModelStringInput | null,
  referenceID?: ModelStringInput | null,
  targetURL?: ModelStringInput | null,
  searchKey?: ModelStringInput | null,
  expiryDate?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelNotificationsFilterInput | null > | null,
  or?: Array< ModelNotificationsFilterInput | null > | null,
  not?: ModelNotificationsFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelNotificationsConnection = {
  __typename: "ModelNotificationsConnection",
  items:  Array<Notifications | null >,
  nextToken?: string | null,
};

export type ModelModuleMasterFilterInput = {
  pk?: ModelStringInput | null,
  id?: ModelIDInput | null,
  moduleName?: ModelStringInput | null,
  sequenceNumber?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelModuleMasterFilterInput | null > | null,
  or?: Array< ModelModuleMasterFilterInput | null > | null,
  not?: ModelModuleMasterFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelModuleMasterConnection = {
  __typename: "ModelModuleMasterConnection",
  items:  Array<ModuleMaster | null >,
  nextToken?: string | null,
};

export type ModelRoleManagementFilterInput = {
  pk?: ModelStringInput | null,
  id?: ModelIDInput | null,
  menuId?: ModelStringInput | null,
  roleName?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  searchKey?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelRoleManagementFilterInput | null > | null,
  or?: Array< ModelRoleManagementFilterInput | null > | null,
  not?: ModelRoleManagementFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelRoleManagementConnection = {
  __typename: "ModelRoleManagementConnection",
  items:  Array<RoleManagement | null >,
  nextToken?: string | null,
};

export type ModelContentFilterInput = {
  pk?: ModelStringInput | null,
  id?: ModelIDInput | null,
  contentName?: ModelStringInput | null,
  title?: ModelStringInput | null,
  sequenceNumber?: ModelIntInput | null,
  content?: ModelStringInput | null,
  searchKey?: ModelStringInput | null,
  docUrl?: ModelStringInput | null,
  visibleToOthers?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelContentFilterInput | null > | null,
  or?: Array< ModelContentFilterInput | null > | null,
  not?: ModelContentFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelContentConnection = {
  __typename: "ModelContentConnection",
  items:  Array<Content | null >,
  nextToken?: string | null,
};

export type ModelFAQFilterInput = {
  pk?: ModelStringInput | null,
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  sequenceNumber?: ModelIntInput | null,
  name?: ModelStringInput | null,
  searchKey?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFAQFilterInput | null > | null,
  or?: Array< ModelFAQFilterInput | null > | null,
  not?: ModelFAQFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelFAQConnection = {
  __typename: "ModelFAQConnection",
  items:  Array<FAQ | null >,
  nextToken?: string | null,
};

export type ModelProductReviewFilterInput = {
  pk?: ModelStringInput | null,
  id?: ModelIDInput | null,
  orderItemId?: ModelStringInput | null,
  productId?: ModelStringInput | null,
  productImage?: ModelStringInput | null,
  productName?: ModelStringInput | null,
  userId?: ModelStringInput | null,
  userName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  overallRating?: ModelFloatInput | null,
  title?: ModelStringInput | null,
  reviewComment?: ModelStringInput | null,
  removeAbusiveComment?: ModelBooleanInput | null,
  attachments?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  reply?: ModelStringInput | null,
  repliedOnDate?: ModelStringInput | null,
  orderedDate?: ModelStringInput | null,
  reviewedDate?: ModelStringInput | null,
  reviewStatus?: ModelEnquiryStatusInput | null,
  searchKey?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelProductReviewFilterInput | null > | null,
  or?: Array< ModelProductReviewFilterInput | null > | null,
  not?: ModelProductReviewFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelProductReviewConnection = {
  __typename: "ModelProductReviewConnection",
  items:  Array<ProductReview | null >,
  nextToken?: string | null,
};

export type ModelTokenDetailsFilterInput = {
  pk?: ModelStringInput | null,
  id?: ModelIDInput | null,
  expiresIn?: ModelStringInput | null,
  token?: ModelStringInput | null,
  tokenType?: ModelStringInput | null,
  userID?: ModelStringInput | null,
  loginAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTokenDetailsFilterInput | null > | null,
  or?: Array< ModelTokenDetailsFilterInput | null > | null,
  not?: ModelTokenDetailsFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelTokenDetailsConnection = {
  __typename: "ModelTokenDetailsConnection",
  items:  Array<TokenDetails | null >,
  nextToken?: string | null,
};

export type ModelTaxFilterInput = {
  pk?: ModelStringInput | null,
  id?: ModelIDInput | null,
  taxCode?: ModelStringInput | null,
  taxType?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  searchKey?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTaxFilterInput | null > | null,
  or?: Array< ModelTaxFilterInput | null > | null,
  not?: ModelTaxFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelTaxConnection = {
  __typename: "ModelTaxConnection",
  items:  Array<Tax | null >,
  nextToken?: string | null,
};

export type ModelCarouselImageFilterInput = {
  pk?: ModelStringInput | null,
  id?: ModelStringInput | null,
  type?: ModelStringInput | null,
  deviceType?: ModelStringInput | null,
  animation?: ModelanimationTypeInput | null,
  pauseTime?: ModelFloatInput | null,
  transitionSpeed?: ModelFloatInput | null,
  stopAnimation?: ModelBooleanInput | null,
  displayArrows?: ModelBooleanInput | null,
  displayNavigationBullets?: ModelBooleanInput | null,
  status?: ModelStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCarouselImageFilterInput | null > | null,
  or?: Array< ModelCarouselImageFilterInput | null > | null,
  not?: ModelCarouselImageFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelCarouselImageConnection = {
  __typename: "ModelCarouselImageConnection",
  items:  Array<CarouselImage | null >,
  nextToken?: string | null,
};

export type ModelWishListFilterInput = {
  pk?: ModelStringInput | null,
  id?: ModelIDInput | null,
  userId?: ModelStringInput | null,
  productId?: ModelStringInput | null,
  productName?: ModelStringInput | null,
  productImage?: ModelStringInput | null,
  mainCategory?: ModelStringInput | null,
  mainCategoryId?: ModelStringInput | null,
  subCategory?: ModelStringInput | null,
  subCategoryId?: ModelStringInput | null,
  brandName?: ModelStringInput | null,
  brandId?: ModelStringInput | null,
  model?: ModelStringInput | null,
  rating?: ModelFloatInput | null,
  regularPrice?: ModelFloatInput | null,
  salePrice?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelWishListFilterInput | null > | null,
  or?: Array< ModelWishListFilterInput | null > | null,
  not?: ModelWishListFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelWishListConnection = {
  __typename: "ModelWishListConnection",
  items:  Array<WishList | null >,
  nextToken?: string | null,
};

export type ModelConfigurationFilterInput = {
  pk?: ModelStringInput | null,
  id?: ModelIDInput | null,
  configurationDescription?: ModelStringInput | null,
  type?: ModelStringInput | null,
  modifiedTime?: ModelStringInput | null,
  modifiedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelConfigurationFilterInput | null > | null,
  or?: Array< ModelConfigurationFilterInput | null > | null,
  not?: ModelConfigurationFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelConfigurationConnection = {
  __typename: "ModelConfigurationConnection",
  items:  Array<configuration | null >,
  nextToken?: string | null,
};

export type ModelMailLogFilterInput = {
  pk?: ModelStringInput | null,
  id?: ModelIDInput | null,
  referenceId?: ModelStringInput | null,
  referenceForm?: ModelStringInput | null,
  userId?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMailLogFilterInput | null > | null,
  or?: Array< ModelMailLogFilterInput | null > | null,
  not?: ModelMailLogFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelMailLogConnection = {
  __typename: "ModelMailLogConnection",
  items:  Array<MailLog | null >,
  nextToken?: string | null,
};

export type ModelDeliveryPartnerFilterInput = {
  pk?: ModelStringInput | null,
  id?: ModelIDInput | null,
  logo?: ModelStringInput | null,
  logoUrl?: ModelStringInput | null,
  companyName?: ModelStringInput | null,
  displayName?: ModelStringInput | null,
  apiKey?: ModelStringInput | null,
  apiKeyExpiryDate?: ModelStringInput | null,
  contactPerson?: ModelStringInput | null,
  address?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  searchKey?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDeliveryPartnerFilterInput | null > | null,
  or?: Array< ModelDeliveryPartnerFilterInput | null > | null,
  not?: ModelDeliveryPartnerFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelDeliveryPartnerConnection = {
  __typename: "ModelDeliveryPartnerConnection",
  items:  Array<DeliveryPartner | null >,
  nextToken?: string | null,
};

export type ModelLedgerFilterInput = {
  pk?: ModelStringInput | null,
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  transDate?: ModelStringInput | null,
  transReference?: ModelStringInput | null,
  transDetails?: ModelStringInput | null,
  transTo?: ModelStringInput | null,
  transToId?: ModelStringInput | null,
  transAmount?: ModelFloatInput | null,
  transType?: ModelStringInput | null,
  paymentStatus?: ModelStringInput | null,
  buyerName?: ModelStringInput | null,
  orderRef?: ModelStringInput | null,
  orderAmount?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelLedgerFilterInput | null > | null,
  or?: Array< ModelLedgerFilterInput | null > | null,
  not?: ModelLedgerFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelLedgerConnection = {
  __typename: "ModelLedgerConnection",
  items:  Array<Ledger | null >,
  nextToken?: string | null,
};

export type ModelProductVisitFilterInput = {
  pk?: ModelStringInput | null,
  id?: ModelIDInput | null,
  prodId?: ModelStringInput | null,
  userId?: ModelStringInput | null,
  userName?: ModelStringInput | null,
  visitedAt?: ModelStringInput | null,
  place?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelProductVisitFilterInput | null > | null,
  or?: Array< ModelProductVisitFilterInput | null > | null,
  not?: ModelProductVisitFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelProductVisitConnection = {
  __typename: "ModelProductVisitConnection",
  items:  Array<ProductVisit | null >,
  nextToken?: string | null,
};

export type ModelPromoCodesFilterInput = {
  pk?: ModelStringInput | null,
  id?: ModelIDInput | null,
  promoImage?: ModelStringInput | null,
  promoURL?: ModelStringInput | null,
  code?: ModelStringInput | null,
  redemptionType?: ModelStringInput | null,
  searchKey?: ModelStringInput | null,
  validFrom?: ModelStringInput | null,
  validTo?: ModelStringInput | null,
  description?: ModelStringInput | null,
  terms?: ModelStringInput | null,
  status?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPromoCodesFilterInput | null > | null,
  or?: Array< ModelPromoCodesFilterInput | null > | null,
  not?: ModelPromoCodesFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelPromoCodesConnection = {
  __typename: "ModelPromoCodesConnection",
  items:  Array<PromoCodes | null >,
  nextToken?: string | null,
};

export type ModelJwtTokenIdentifierFilterInput = {
  pk?: ModelStringInput | null,
  jtiValue?: ModelStringInput | null,
  issueDate?: ModelStringInput | null,
  expiryDateTime?: ModelStringInput | null,
  status?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelJwtTokenIdentifierFilterInput | null > | null,
  or?: Array< ModelJwtTokenIdentifierFilterInput | null > | null,
  not?: ModelJwtTokenIdentifierFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelJwtTokenIdentifierConnection = {
  __typename: "ModelJwtTokenIdentifierConnection",
  items:  Array<jwtTokenIdentifier | null >,
  nextToken?: string | null,
};

export type ModelUnitsFilterInput = {
  pk?: ModelStringInput | null,
  id?: ModelIDInput | null,
  value?: ModelStringInput | null,
  sequenceNumber?: ModelIntInput | null,
  searchKey?: ModelStringInput | null,
  type?: ModelUnitTypeInput | null,
  status?: ModelStatusInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUnitsFilterInput | null > | null,
  or?: Array< ModelUnitsFilterInput | null > | null,
  not?: ModelUnitsFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelUnitsConnection = {
  __typename: "ModelUnitsConnection",
  items:  Array<Units | null >,
  nextToken?: string | null,
};

export type ModelShippingFilterInput = {
  id?: ModelIDInput | null,
  pk?: ModelStringInput | null,
  city?: ModelStringInput | null,
  countryId?: ModelStringInput | null,
  countryName?: ModelStringInput | null,
  state?: ModelStringInput | null,
  deliveryCharge?: ModelFloatInput | null,
  coverage?: ModelCoverageInput | null,
  zipCodes?: ModelStringInput | null,
  latitude?: ModelStringInput | null,
  longitude?: ModelStringInput | null,
  radius?: ModelStringInput | null,
  searchKey?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  modifiedBy?: ModelStringInput | null,
  and?: Array< ModelShippingFilterInput | null > | null,
  or?: Array< ModelShippingFilterInput | null > | null,
  not?: ModelShippingFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelShippingConnection = {
  __typename: "ModelShippingConnection",
  items:  Array<Shipping | null >,
  nextToken?: string | null,
};

export type ModelProductFilterInput = {
  createdAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  pk?: ModelStringInput | null,
  globalPk?: ModelStringInput | null,
  productName?: ModelStringInput | null,
  mainCategory?: ModelStringInput | null,
  mainCategoryID?: ModelStringInput | null,
  subCategory?: ModelStringInput | null,
  subCategoryID?: ModelStringInput | null,
  brandName?: ModelStringInput | null,
  brandID?: ModelStringInput | null,
  countryName?: ModelStringInput | null,
  countryID?: ModelStringInput | null,
  productDescription?: ModelStringInput | null,
  productFeatures?: ModelStringInput | null,
  keyWord?: ModelStringInput | null,
  singleVariant?: ModelBooleanInput | null,
  attrName?: ModelStringInput | null,
  attrNameID?: ModelStringInput | null,
  seoDescription?: ModelStringInput | null,
  stockStatus?: ModelstockStatusInput | null,
  searchKey?: ModelStringInput | null,
  status?: ModelproductStatusInput | null,
  scheduleDate?: ModelStringInput | null,
  showWhenZeroStock?: ModelBooleanInput | null,
  allowPreOrder?: ModelBooleanInput | null,
  overallRating?: ModelFloatInput | null,
  noOfRating?: ModelIntInput | null,
  leadTime?: ModelStringInput | null,
  totalStockQuantity?: ModelFloatInput | null,
  totalReservedStock?: ModelFloatInput | null,
  displaySalePrice?: ModelFloatInput | null,
  displayRegularPrice?: ModelFloatInput | null,
  thumbnailUrl?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelProductFilterInput | null > | null,
  or?: Array< ModelProductFilterInput | null > | null,
  not?: ModelProductFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelProductConnection = {
  __typename: "ModelProductConnection",
  items:  Array<Product | null >,
  nextToken?: string | null,
};

export type ModelProductImageFilterInput = {
  pk?: ModelStringInput | null,
  id?: ModelIDInput | null,
  alterText?: ModelStringInput | null,
  title?: ModelStringInput | null,
  url?: ModelStringInput | null,
  description?: ModelStringInput | null,
  mainCategory?: ModelStringInput | null,
  mainCategoryID?: ModelStringInput | null,
  subCategory?: ModelStringInput | null,
  subCategoryID?: ModelStringInput | null,
  thumbnailUrl?: ModelStringInput | null,
  type?: ModelImageTypeInput | null,
  productID?: ModelStringInput | null,
  isDeleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelProductImageFilterInput | null > | null,
  or?: Array< ModelProductImageFilterInput | null > | null,
  not?: ModelProductImageFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelProductImageConnection = {
  __typename: "ModelProductImageConnection",
  items:  Array<ProductImage | null >,
  nextToken?: string | null,
};

export type ModelProductImageProductImageByMainCategoryIDSubCategoryIDIndexCompositeKeyConditionInput = {
  eq?: ModelProductImageProductImageByMainCategoryIDSubCategoryIDIndexCompositeKeyInput | null,
  le?: ModelProductImageProductImageByMainCategoryIDSubCategoryIDIndexCompositeKeyInput | null,
  lt?: ModelProductImageProductImageByMainCategoryIDSubCategoryIDIndexCompositeKeyInput | null,
  ge?: ModelProductImageProductImageByMainCategoryIDSubCategoryIDIndexCompositeKeyInput | null,
  gt?: ModelProductImageProductImageByMainCategoryIDSubCategoryIDIndexCompositeKeyInput | null,
  between?: Array< ModelProductImageProductImageByMainCategoryIDSubCategoryIDIndexCompositeKeyInput | null > | null,
  beginsWith?: ModelProductImageProductImageByMainCategoryIDSubCategoryIDIndexCompositeKeyInput | null,
};

export type ModelProductImageProductImageByMainCategoryIDSubCategoryIDIndexCompositeKeyInput = {
  mainCategoryID?: string | null,
  subCategoryID?: string | null,
};

export type ModelProductDetailsFilterInput = {
  id?: ModelIDInput | null,
  pk?: ModelStringInput | null,
  productID?: ModelStringInput | null,
  attrName?: ModelStringInput | null,
  attrNameID?: ModelStringInput | null,
  attrValue?: ModelStringInput | null,
  attrValueID?: ModelStringInput | null,
  model?: ModelStringInput | null,
  manufacturePartCode?: ModelStringInput | null,
  sku?: ModelStringInput | null,
  stockStatus?: ModelstockStatusInput | null,
  stockQuantity?: ModelFloatInput | null,
  reservedStock?: ModelFloatInput | null,
  stockUnit?: ModelStringInput | null,
  itemsPerUnit?: ModelIntInput | null,
  regularPrice?: ModelFloatInput | null,
  salePrice?: ModelFloatInput | null,
  dataSheet?: ModelStringInput | null,
  submittal?: ModelStringInput | null,
  catalog?: ModelStringInput | null,
  iom?: ModelStringInput | null,
  partialReturn?: ModelBooleanInput | null,
  type?: ModelVariantTypeInput | null,
  isDeleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelProductDetailsFilterInput | null > | null,
  or?: Array< ModelProductDetailsFilterInput | null > | null,
  not?: ModelProductDetailsFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelProductDetailsConnection = {
  __typename: "ModelProductDetailsConnection",
  items:  Array<ProductDetails | null >,
  nextToken?: string | null,
};

export type ModelOrderFilterInput = {
  pk?: ModelStringInput | null,
  id?: ModelIDInput | null,
  userId?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  userName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  orderType?: ModelStringInput | null,
  totalProducts?: ModelIntInput | null,
  totalAmount?: ModelFloatInput | null,
  paymentStatus?: ModelPaymentStatusInput | null,
  status?: ModelOrderStatusInput | null,
  wishListId?: ModelStringInput | null,
  discountByCoupon?: ModelFloatInput | null,
  deliveryDate?: ModelStringInput | null,
  invoiceNumber?: ModelStringInput | null,
  invoiceURL?: ModelStringInput | null,
  orderPdf?: ModelStringInput | null,
  searchKey?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOrderFilterInput | null > | null,
  or?: Array< ModelOrderFilterInput | null > | null,
  not?: ModelOrderFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelOrderConnection = {
  __typename: "ModelOrderConnection",
  items:  Array<Order | null >,
  nextToken?: string | null,
};

export type ModelOrderItemFilterInput = {
  pk?: ModelStringInput | null,
  id?: ModelIDInput | null,
  productId?: ModelStringInput | null,
  mainCategoryId?: ModelStringInput | null,
  mainCategory?: ModelStringInput | null,
  subCategoryId?: ModelStringInput | null,
  subCategory?: ModelStringInput | null,
  userId?: ModelStringInput | null,
  wareHouseId?: ModelStringInput | null,
  attrValueID?: ModelStringInput | null,
  wareHouseName?: ModelStringInput | null,
  userName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  quantity?: ModelIntInput | null,
  stockUnit?: ModelStringInput | null,
  itemsPerUnit?: ModelIntInput | null,
  taxCode?: ModelStringInput | null,
  taxAmount?: ModelFloatInput | null,
  regularPrice?: ModelFloatInput | null,
  totalPrice?: ModelFloatInput | null,
  sku?: ModelStringInput | null,
  attrName?: ModelStringInput | null,
  attrValue?: ModelStringInput | null,
  deliveryStatus?: ModelProductDeliveryStatusInput | null,
  deliveryBy?: ModelStringInput | null,
  deliveryPartner?: ModelStringInput | null,
  outForDeliveryDate?: ModelStringInput | null,
  outForDeliveryTime?: ModelStringInput | null,
  expectedDeliveryDate?: ModelStringInput | null,
  trackingID?: ModelStringInput | null,
  trackingUrl?: ModelStringInput | null,
  orderedOn?: ModelStringInput | null,
  eta?: ModelStringInput | null,
  paymentStatus?: ModelPaymentStatusInput | null,
  billOfLading?: ModelStringInput | null,
  packingList?: ModelStringInput | null,
  searchKey?: ModelStringInput | null,
  returnAvailable?: ModelBooleanInput | null,
  disputeResolved?: ModelBooleanInput | null,
  brandId?: ModelStringInput | null,
  returnValue?: ModelFloatInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOrderItemFilterInput | null > | null,
  or?: Array< ModelOrderItemFilterInput | null > | null,
  not?: ModelOrderItemFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelOrderItemConnection = {
  __typename: "ModelOrderItemConnection",
  items:  Array<OrderItem | null >,
  nextToken?: string | null,
};

export type ModelOrderItemByStatusCompositeKeyConditionInput = {
  eq?: ModelOrderItemByStatusCompositeKeyInput | null,
  le?: ModelOrderItemByStatusCompositeKeyInput | null,
  lt?: ModelOrderItemByStatusCompositeKeyInput | null,
  ge?: ModelOrderItemByStatusCompositeKeyInput | null,
  gt?: ModelOrderItemByStatusCompositeKeyInput | null,
  between?: Array< ModelOrderItemByStatusCompositeKeyInput | null > | null,
  beginsWith?: ModelOrderItemByStatusCompositeKeyInput | null,
};

export type ModelOrderItemByStatusCompositeKeyInput = {
  deliveryStatus?: ProductDeliveryStatus | null,
  createdAt?: string | null,
};

export type ModelOrderItemByMainCategoryCompositeKeyConditionInput = {
  eq?: ModelOrderItemByMainCategoryCompositeKeyInput | null,
  le?: ModelOrderItemByMainCategoryCompositeKeyInput | null,
  lt?: ModelOrderItemByMainCategoryCompositeKeyInput | null,
  ge?: ModelOrderItemByMainCategoryCompositeKeyInput | null,
  gt?: ModelOrderItemByMainCategoryCompositeKeyInput | null,
  between?: Array< ModelOrderItemByMainCategoryCompositeKeyInput | null > | null,
  beginsWith?: ModelOrderItemByMainCategoryCompositeKeyInput | null,
};

export type ModelOrderItemByMainCategoryCompositeKeyInput = {
  mainCategoryId?: string | null,
  mainCategory?: string | null,
};

export type ModelOrderItemBySubCategoryCompositeKeyConditionInput = {
  eq?: ModelOrderItemBySubCategoryCompositeKeyInput | null,
  le?: ModelOrderItemBySubCategoryCompositeKeyInput | null,
  lt?: ModelOrderItemBySubCategoryCompositeKeyInput | null,
  ge?: ModelOrderItemBySubCategoryCompositeKeyInput | null,
  gt?: ModelOrderItemBySubCategoryCompositeKeyInput | null,
  between?: Array< ModelOrderItemBySubCategoryCompositeKeyInput | null > | null,
  beginsWith?: ModelOrderItemBySubCategoryCompositeKeyInput | null,
};

export type ModelOrderItemBySubCategoryCompositeKeyInput = {
  subCategoryId?: string | null,
  subCategory?: string | null,
};

export type ModelOrderItemByWarehouseCompositeKeyConditionInput = {
  eq?: ModelOrderItemByWarehouseCompositeKeyInput | null,
  le?: ModelOrderItemByWarehouseCompositeKeyInput | null,
  lt?: ModelOrderItemByWarehouseCompositeKeyInput | null,
  ge?: ModelOrderItemByWarehouseCompositeKeyInput | null,
  gt?: ModelOrderItemByWarehouseCompositeKeyInput | null,
  between?: Array< ModelOrderItemByWarehouseCompositeKeyInput | null > | null,
  beginsWith?: ModelOrderItemByWarehouseCompositeKeyInput | null,
};

export type ModelOrderItemByWarehouseCompositeKeyInput = {
  wareHouseId?: string | null,
  wareHouseName?: string | null,
};

export type ModelReportMasterFilterInput = {
  pk?: ModelStringInput | null,
  id?: ModelIDInput | null,
  reportName?: ModelStringInput | null,
  description?: ModelStringInput | null,
  sequenceNumber?: ModelIntInput | null,
  searchKey?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelReportMasterFilterInput | null > | null,
  or?: Array< ModelReportMasterFilterInput | null > | null,
  not?: ModelReportMasterFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelReportMasterConnection = {
  __typename: "ModelReportMasterConnection",
  items:  Array<ReportMaster | null >,
  nextToken?: string | null,
};

export type ModelSubscriptionSettingsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  pk?: ModelSubscriptionStringInput | null,
  notificationType?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  emailTo?: ModelSubscriptionStringInput | null,
  productExpiryDuration?: ModelSubscriptionStringInput | null,
  theme?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSettingsFilterInput | null > | null,
  or?: Array< ModelSubscriptionSettingsFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionCompanyDetailsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  pk?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  companyName?: ModelSubscriptionStringInput | null,
  webSite?: ModelSubscriptionStringInput | null,
  phoneNumber?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  ccEmail?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionBooleanInput | null,
  currency?: ModelSubscriptionStringInput | null,
  categorySequenced?: ModelSubscriptionBooleanInput | null,
  copyrightText?: ModelSubscriptionStringInput | null,
  seoDescription?: ModelSubscriptionStringInput | null,
  daysToReturn?: ModelSubscriptionIntInput | null,
  brandName?: ModelSubscriptionStringInput | null,
  iconUrl?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCompanyDetailsFilterInput | null > | null,
  or?: Array< ModelSubscriptionCompanyDetailsFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionPaymentModeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  pk?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  method?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  provider?: ModelSubscriptionStringInput | null,
  logoUrl?: ModelSubscriptionStringInput | null,
  stagingUrl?: ModelSubscriptionStringInput | null,
  productionUrl?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPaymentModeFilterInput | null > | null,
  or?: Array< ModelSubscriptionPaymentModeFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  pk?: ModelSubscriptionStringInput | null,
  userName?: ModelSubscriptionStringInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  companyName?: ModelSubscriptionStringInput | null,
  profileImageUrl?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  phoneNumber?: ModelSubscriptionStringInput | null,
  role?: ModelSubscriptionStringInput | null,
  password?: ModelSubscriptionStringInput | null,
  multiFactorAuth?: ModelSubscriptionBooleanInput | null,
  emailNotification?: ModelSubscriptionBooleanInput | null,
  mandatePasswordChange?: ModelSubscriptionBooleanInput | null,
  emailVerified?: ModelSubscriptionBooleanInput | null,
  otp?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  searchKey?: ModelSubscriptionStringInput | null,
  loginAt?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  platform?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionCategoryFilterInput = {
  pk?: ModelSubscriptionStringInput | null,
  slug?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  catName?: ModelSubscriptionStringInput | null,
  mainCategory?: ModelSubscriptionStringInput | null,
  mainCategoryID?: ModelSubscriptionStringInput | null,
  categoryImg?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  webBannerImg?: ModelSubscriptionStringInput | null,
  webBannerImgBottom?: ModelSubscriptionStringInput | null,
  mobBannerImg?: ModelSubscriptionStringInput | null,
  mobBannerImgBottom?: ModelSubscriptionStringInput | null,
  categoryType?: ModelSubscriptionStringInput | null,
  searchKey?: ModelSubscriptionStringInput | null,
  catStatus?: ModelSubscriptionBooleanInput | null,
  sequenceNumber?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCategoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionCategoryFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionBrandFilterInput = {
  pk?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  brandName?: ModelSubscriptionStringInput | null,
  searchKey?: ModelSubscriptionStringInput | null,
  manufacturerName?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  countryOfOrigin?: ModelSubscriptionStringInput | null,
  brandLogo?: ModelSubscriptionStringInput | null,
  brandUrl?: ModelSubscriptionStringInput | null,
  brandDescription?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBrandFilterInput | null > | null,
  or?: Array< ModelSubscriptionBrandFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionAttributeNameFilterInput = {
  pk?: ModelSubscriptionStringInput | null,
  slug?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  sequenceNumber?: ModelSubscriptionIntInput | null,
  searchKey?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  attrName?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAttributeNameFilterInput | null > | null,
  or?: Array< ModelSubscriptionAttributeNameFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionCountryFilterInput = {
  pk?: ModelSubscriptionStringInput | null,
  slug?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  shortName?: ModelSubscriptionStringInput | null,
  searchKey?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCountryFilterInput | null > | null,
  or?: Array< ModelSubscriptionCountryFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionDropDownMasterFilterInput = {
  pk?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  value?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  searchKey?: ModelSubscriptionStringInput | null,
  sequenceNumber?: ModelSubscriptionIntInput | null,
  createdBy?: ModelSubscriptionStringInput | null,
  updatedBy?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDropDownMasterFilterInput | null > | null,
  or?: Array< ModelSubscriptionDropDownMasterFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionCartFilterInput = {
  pk?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionStringInput | null,
  productID?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  productImage?: ModelSubscriptionStringInput | null,
  productName?: ModelSubscriptionStringInput | null,
  mainCategoryId?: ModelSubscriptionStringInput | null,
  mainCategory?: ModelSubscriptionStringInput | null,
  subCategoryId?: ModelSubscriptionStringInput | null,
  subCategoryN?: ModelSubscriptionStringInput | null,
  attrValueID?: ModelSubscriptionStringInput | null,
  price?: ModelSubscriptionFloatInput | null,
  salesPrice?: ModelSubscriptionFloatInput | null,
  stockUnit?: ModelSubscriptionStringInput | null,
  itemsPerUnit?: ModelSubscriptionIntInput | null,
  expiryDate?: ModelSubscriptionStringInput | null,
  selectedQuantity?: ModelSubscriptionIntInput | null,
  wareHouseId?: ModelSubscriptionStringInput | null,
  wareHouseName?: ModelSubscriptionStringInput | null,
  attrName?: ModelSubscriptionStringInput | null,
  attrValue?: ModelSubscriptionStringInput | null,
  userName?: ModelSubscriptionStringInput | null,
  userMail?: ModelSubscriptionStringInput | null,
  model?: ModelSubscriptionStringInput | null,
  cartURL?: ModelSubscriptionStringInput | null,
  taxCode?: ModelSubscriptionStringInput | null,
  taxCharge?: ModelSubscriptionFloatInput | null,
  sku?: ModelSubscriptionStringInput | null,
  searchKey?: ModelSubscriptionStringInput | null,
  totalPrice?: ModelSubscriptionFloatInput | null,
  mobileNumber?: ModelSubscriptionStringInput | null,
  currentStock?: ModelSubscriptionStringInput | null,
  brandId?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCartFilterInput | null > | null,
  or?: Array< ModelSubscriptionCartFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionWareHouseFilterInput = {
  pk?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  addressLine1?: ModelSubscriptionStringInput | null,
  addressLine2?: ModelSubscriptionStringInput | null,
  searchKey?: ModelSubscriptionStringInput | null,
  countryId?: ModelSubscriptionStringInput | null,
  country?: ModelSubscriptionStringInput | null,
  state?: ModelSubscriptionStringInput | null,
  city?: ModelSubscriptionStringInput | null,
  zipCode?: ModelSubscriptionStringInput | null,
  contactPerson?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  phoneNumber?: ModelSubscriptionStringInput | null,
  workingHoursFrom?: ModelSubscriptionStringInput | null,
  workingHoursTo?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionBooleanInput | null,
  latitude?: ModelSubscriptionStringInput | null,
  longitude?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionWareHouseFilterInput | null > | null,
  or?: Array< ModelSubscriptionWareHouseFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionGeneralContactUsFilterInput = {
  pk?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  referenceNumber?: ModelSubscriptionStringInput | null,
  userName?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  phoneNumber?: ModelSubscriptionStringInput | null,
  companyName?: ModelSubscriptionStringInput | null,
  subject?: ModelSubscriptionStringInput | null,
  message?: ModelSubscriptionStringInput | null,
  replyMessage?: ModelSubscriptionStringInput | null,
  enquiryStatus?: ModelSubscriptionStringInput | null,
  attachments?: ModelSubscriptionStringInput | null,
  searchKey?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionGeneralContactUsFilterInput | null > | null,
  or?: Array< ModelSubscriptionGeneralContactUsFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionNotificationsFilterInput = {
  pk?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionStringInput | null,
  userName?: ModelSubscriptionStringInput | null,
  notificationType?: ModelSubscriptionStringInput | null,
  Title?: ModelSubscriptionStringInput | null,
  Message?: ModelSubscriptionStringInput | null,
  orderID?: ModelSubscriptionStringInput | null,
  DateTime?: ModelSubscriptionStringInput | null,
  notificationStatus?: ModelSubscriptionIntInput | null,
  notificationModule?: ModelSubscriptionStringInput | null,
  referenceID?: ModelSubscriptionStringInput | null,
  targetURL?: ModelSubscriptionStringInput | null,
  searchKey?: ModelSubscriptionStringInput | null,
  expiryDate?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionNotificationsFilterInput | null > | null,
  or?: Array< ModelSubscriptionNotificationsFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionModuleMasterFilterInput = {
  pk?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  moduleName?: ModelSubscriptionStringInput | null,
  sequenceNumber?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionModuleMasterFilterInput | null > | null,
  or?: Array< ModelSubscriptionModuleMasterFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionRoleManagementFilterInput = {
  pk?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  menuId?: ModelSubscriptionStringInput | null,
  roleName?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  searchKey?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionRoleManagementFilterInput | null > | null,
  or?: Array< ModelSubscriptionRoleManagementFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionContentFilterInput = {
  pk?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  contentName?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  sequenceNumber?: ModelSubscriptionIntInput | null,
  content?: ModelSubscriptionStringInput | null,
  searchKey?: ModelSubscriptionStringInput | null,
  docUrl?: ModelSubscriptionStringInput | null,
  visibleToOthers?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionContentFilterInput | null > | null,
  or?: Array< ModelSubscriptionContentFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionFAQFilterInput = {
  pk?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  sequenceNumber?: ModelSubscriptionIntInput | null,
  name?: ModelSubscriptionStringInput | null,
  searchKey?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFAQFilterInput | null > | null,
  or?: Array< ModelSubscriptionFAQFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionProductReviewFilterInput = {
  pk?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  orderItemId?: ModelSubscriptionStringInput | null,
  productId?: ModelSubscriptionStringInput | null,
  productImage?: ModelSubscriptionStringInput | null,
  productName?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionStringInput | null,
  userName?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  overallRating?: ModelSubscriptionFloatInput | null,
  title?: ModelSubscriptionStringInput | null,
  reviewComment?: ModelSubscriptionStringInput | null,
  removeAbusiveComment?: ModelSubscriptionBooleanInput | null,
  attachments?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  reply?: ModelSubscriptionStringInput | null,
  repliedOnDate?: ModelSubscriptionStringInput | null,
  orderedDate?: ModelSubscriptionStringInput | null,
  reviewedDate?: ModelSubscriptionStringInput | null,
  reviewStatus?: ModelSubscriptionStringInput | null,
  searchKey?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProductReviewFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductReviewFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionTokenDetailsFilterInput = {
  pk?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  expiresIn?: ModelSubscriptionStringInput | null,
  token?: ModelSubscriptionStringInput | null,
  tokenType?: ModelSubscriptionStringInput | null,
  userID?: ModelSubscriptionStringInput | null,
  loginAt?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTokenDetailsFilterInput | null > | null,
  or?: Array< ModelSubscriptionTokenDetailsFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionTaxFilterInput = {
  pk?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  taxCode?: ModelSubscriptionStringInput | null,
  taxType?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  searchKey?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTaxFilterInput | null > | null,
  or?: Array< ModelSubscriptionTaxFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionCarouselImageFilterInput = {
  pk?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  deviceType?: ModelSubscriptionStringInput | null,
  animation?: ModelSubscriptionStringInput | null,
  pauseTime?: ModelSubscriptionFloatInput | null,
  transitionSpeed?: ModelSubscriptionFloatInput | null,
  stopAnimation?: ModelSubscriptionBooleanInput | null,
  displayArrows?: ModelSubscriptionBooleanInput | null,
  displayNavigationBullets?: ModelSubscriptionBooleanInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCarouselImageFilterInput | null > | null,
  or?: Array< ModelSubscriptionCarouselImageFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionWishListFilterInput = {
  pk?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionStringInput | null,
  productId?: ModelSubscriptionStringInput | null,
  productName?: ModelSubscriptionStringInput | null,
  productImage?: ModelSubscriptionStringInput | null,
  mainCategory?: ModelSubscriptionStringInput | null,
  mainCategoryId?: ModelSubscriptionStringInput | null,
  subCategory?: ModelSubscriptionStringInput | null,
  subCategoryId?: ModelSubscriptionStringInput | null,
  brandName?: ModelSubscriptionStringInput | null,
  brandId?: ModelSubscriptionStringInput | null,
  model?: ModelSubscriptionStringInput | null,
  rating?: ModelSubscriptionFloatInput | null,
  regularPrice?: ModelSubscriptionFloatInput | null,
  salePrice?: ModelSubscriptionFloatInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionWishListFilterInput | null > | null,
  or?: Array< ModelSubscriptionWishListFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionConfigurationFilterInput = {
  pk?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  configurationDescription?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  modifiedTime?: ModelSubscriptionStringInput | null,
  modifiedBy?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionConfigurationFilterInput | null > | null,
  or?: Array< ModelSubscriptionConfigurationFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionMailLogFilterInput = {
  pk?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  referenceId?: ModelSubscriptionStringInput | null,
  referenceForm?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMailLogFilterInput | null > | null,
  or?: Array< ModelSubscriptionMailLogFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionDeliveryPartnerFilterInput = {
  pk?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  logo?: ModelSubscriptionStringInput | null,
  logoUrl?: ModelSubscriptionStringInput | null,
  companyName?: ModelSubscriptionStringInput | null,
  displayName?: ModelSubscriptionStringInput | null,
  apiKey?: ModelSubscriptionStringInput | null,
  apiKeyExpiryDate?: ModelSubscriptionStringInput | null,
  contactPerson?: ModelSubscriptionStringInput | null,
  address?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  phoneNumber?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  searchKey?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDeliveryPartnerFilterInput | null > | null,
  or?: Array< ModelSubscriptionDeliveryPartnerFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionLedgerFilterInput = {
  pk?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  transDate?: ModelSubscriptionStringInput | null,
  transReference?: ModelSubscriptionStringInput | null,
  transDetails?: ModelSubscriptionStringInput | null,
  transTo?: ModelSubscriptionStringInput | null,
  transToId?: ModelSubscriptionStringInput | null,
  transAmount?: ModelSubscriptionFloatInput | null,
  transType?: ModelSubscriptionStringInput | null,
  paymentStatus?: ModelSubscriptionStringInput | null,
  buyerName?: ModelSubscriptionStringInput | null,
  orderRef?: ModelSubscriptionStringInput | null,
  orderAmount?: ModelSubscriptionFloatInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionLedgerFilterInput | null > | null,
  or?: Array< ModelSubscriptionLedgerFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionProductVisitFilterInput = {
  pk?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  prodId?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionStringInput | null,
  userName?: ModelSubscriptionStringInput | null,
  visitedAt?: ModelSubscriptionStringInput | null,
  place?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProductVisitFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductVisitFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionPromoCodesFilterInput = {
  pk?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  promoImage?: ModelSubscriptionStringInput | null,
  promoURL?: ModelSubscriptionStringInput | null,
  code?: ModelSubscriptionStringInput | null,
  redemptionType?: ModelSubscriptionStringInput | null,
  searchKey?: ModelSubscriptionStringInput | null,
  validFrom?: ModelSubscriptionStringInput | null,
  validTo?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  terms?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPromoCodesFilterInput | null > | null,
  or?: Array< ModelSubscriptionPromoCodesFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionJwtTokenIdentifierFilterInput = {
  pk?: ModelSubscriptionStringInput | null,
  jtiValue?: ModelSubscriptionStringInput | null,
  issueDate?: ModelSubscriptionStringInput | null,
  expiryDateTime?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionJwtTokenIdentifierFilterInput | null > | null,
  or?: Array< ModelSubscriptionJwtTokenIdentifierFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionUnitsFilterInput = {
  pk?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  value?: ModelSubscriptionStringInput | null,
  sequenceNumber?: ModelSubscriptionIntInput | null,
  searchKey?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionStringInput | null,
  updatedBy?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUnitsFilterInput | null > | null,
  or?: Array< ModelSubscriptionUnitsFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionShippingFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  pk?: ModelSubscriptionStringInput | null,
  city?: ModelSubscriptionStringInput | null,
  countryId?: ModelSubscriptionStringInput | null,
  countryName?: ModelSubscriptionStringInput | null,
  state?: ModelSubscriptionStringInput | null,
  deliveryCharge?: ModelSubscriptionFloatInput | null,
  coverage?: ModelSubscriptionStringInput | null,
  zipCodes?: ModelSubscriptionStringInput | null,
  latitude?: ModelSubscriptionStringInput | null,
  longitude?: ModelSubscriptionStringInput | null,
  radius?: ModelSubscriptionStringInput | null,
  searchKey?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionStringInput | null,
  modifiedBy?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionShippingFilterInput | null > | null,
  or?: Array< ModelSubscriptionShippingFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionProductFilterInput = {
  createdAt?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  pk?: ModelSubscriptionStringInput | null,
  globalPk?: ModelSubscriptionStringInput | null,
  productName?: ModelSubscriptionStringInput | null,
  mainCategory?: ModelSubscriptionStringInput | null,
  mainCategoryID?: ModelSubscriptionStringInput | null,
  subCategory?: ModelSubscriptionStringInput | null,
  subCategoryID?: ModelSubscriptionStringInput | null,
  brandName?: ModelSubscriptionStringInput | null,
  brandID?: ModelSubscriptionStringInput | null,
  countryName?: ModelSubscriptionStringInput | null,
  countryID?: ModelSubscriptionStringInput | null,
  productDescription?: ModelSubscriptionStringInput | null,
  productFeatures?: ModelSubscriptionStringInput | null,
  keyWord?: ModelSubscriptionStringInput | null,
  singleVariant?: ModelSubscriptionBooleanInput | null,
  attrName?: ModelSubscriptionStringInput | null,
  attrNameID?: ModelSubscriptionStringInput | null,
  seoDescription?: ModelSubscriptionStringInput | null,
  stockStatus?: ModelSubscriptionStringInput | null,
  searchKey?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  scheduleDate?: ModelSubscriptionStringInput | null,
  showWhenZeroStock?: ModelSubscriptionBooleanInput | null,
  allowPreOrder?: ModelSubscriptionBooleanInput | null,
  overallRating?: ModelSubscriptionFloatInput | null,
  noOfRating?: ModelSubscriptionIntInput | null,
  leadTime?: ModelSubscriptionStringInput | null,
  totalStockQuantity?: ModelSubscriptionFloatInput | null,
  totalReservedStock?: ModelSubscriptionFloatInput | null,
  displaySalePrice?: ModelSubscriptionFloatInput | null,
  displayRegularPrice?: ModelSubscriptionFloatInput | null,
  thumbnailUrl?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProductFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionProductImageFilterInput = {
  pk?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  alterText?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  url?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  mainCategory?: ModelSubscriptionStringInput | null,
  mainCategoryID?: ModelSubscriptionStringInput | null,
  subCategory?: ModelSubscriptionStringInput | null,
  subCategoryID?: ModelSubscriptionStringInput | null,
  thumbnailUrl?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  productID?: ModelSubscriptionStringInput | null,
  isDeleted?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProductImageFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductImageFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionProductDetailsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  pk?: ModelSubscriptionStringInput | null,
  productID?: ModelSubscriptionStringInput | null,
  attrName?: ModelSubscriptionStringInput | null,
  attrNameID?: ModelSubscriptionStringInput | null,
  attrValue?: ModelSubscriptionStringInput | null,
  attrValueID?: ModelSubscriptionStringInput | null,
  model?: ModelSubscriptionStringInput | null,
  manufacturePartCode?: ModelSubscriptionStringInput | null,
  sku?: ModelSubscriptionStringInput | null,
  stockStatus?: ModelSubscriptionStringInput | null,
  stockQuantity?: ModelSubscriptionFloatInput | null,
  reservedStock?: ModelSubscriptionFloatInput | null,
  stockUnit?: ModelSubscriptionStringInput | null,
  itemsPerUnit?: ModelSubscriptionIntInput | null,
  regularPrice?: ModelSubscriptionFloatInput | null,
  salePrice?: ModelSubscriptionFloatInput | null,
  dataSheet?: ModelSubscriptionStringInput | null,
  submittal?: ModelSubscriptionStringInput | null,
  catalog?: ModelSubscriptionStringInput | null,
  iom?: ModelSubscriptionStringInput | null,
  partialReturn?: ModelSubscriptionBooleanInput | null,
  type?: ModelSubscriptionStringInput | null,
  isDeleted?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProductDetailsFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductDetailsFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionOrderFilterInput = {
  pk?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  userName?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  phoneNumber?: ModelSubscriptionStringInput | null,
  orderType?: ModelSubscriptionStringInput | null,
  totalProducts?: ModelSubscriptionIntInput | null,
  totalAmount?: ModelSubscriptionFloatInput | null,
  paymentStatus?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  wishListId?: ModelSubscriptionStringInput | null,
  discountByCoupon?: ModelSubscriptionFloatInput | null,
  deliveryDate?: ModelSubscriptionStringInput | null,
  invoiceNumber?: ModelSubscriptionStringInput | null,
  invoiceURL?: ModelSubscriptionStringInput | null,
  orderPdf?: ModelSubscriptionStringInput | null,
  searchKey?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOrderFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrderFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionOrderItemFilterInput = {
  pk?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  productId?: ModelSubscriptionStringInput | null,
  mainCategoryId?: ModelSubscriptionStringInput | null,
  mainCategory?: ModelSubscriptionStringInput | null,
  subCategoryId?: ModelSubscriptionStringInput | null,
  subCategory?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionStringInput | null,
  wareHouseId?: ModelSubscriptionStringInput | null,
  attrValueID?: ModelSubscriptionStringInput | null,
  wareHouseName?: ModelSubscriptionStringInput | null,
  userName?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  phoneNumber?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  quantity?: ModelSubscriptionIntInput | null,
  stockUnit?: ModelSubscriptionStringInput | null,
  itemsPerUnit?: ModelSubscriptionIntInput | null,
  taxCode?: ModelSubscriptionStringInput | null,
  taxAmount?: ModelSubscriptionFloatInput | null,
  regularPrice?: ModelSubscriptionFloatInput | null,
  totalPrice?: ModelSubscriptionFloatInput | null,
  sku?: ModelSubscriptionStringInput | null,
  attrName?: ModelSubscriptionStringInput | null,
  attrValue?: ModelSubscriptionStringInput | null,
  deliveryStatus?: ModelSubscriptionStringInput | null,
  deliveryBy?: ModelSubscriptionStringInput | null,
  deliveryPartner?: ModelSubscriptionStringInput | null,
  outForDeliveryDate?: ModelSubscriptionStringInput | null,
  outForDeliveryTime?: ModelSubscriptionStringInput | null,
  expectedDeliveryDate?: ModelSubscriptionStringInput | null,
  trackingID?: ModelSubscriptionStringInput | null,
  trackingUrl?: ModelSubscriptionStringInput | null,
  orderedOn?: ModelSubscriptionStringInput | null,
  eta?: ModelSubscriptionStringInput | null,
  paymentStatus?: ModelSubscriptionStringInput | null,
  billOfLading?: ModelSubscriptionStringInput | null,
  packingList?: ModelSubscriptionStringInput | null,
  searchKey?: ModelSubscriptionStringInput | null,
  returnAvailable?: ModelSubscriptionBooleanInput | null,
  disputeResolved?: ModelSubscriptionBooleanInput | null,
  brandId?: ModelSubscriptionStringInput | null,
  returnValue?: ModelSubscriptionFloatInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOrderItemFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrderItemFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionReportMasterFilterInput = {
  pk?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  reportName?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  sequenceNumber?: ModelSubscriptionIntInput | null,
  searchKey?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionReportMasterFilterInput | null > | null,
  or?: Array< ModelSubscriptionReportMasterFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type CreateSettingsMutationVariables = {
  input: CreateSettingsInput,
  condition?: ModelSettingsConditionInput | null,
};

export type CreateSettingsMutation = {
  createSettings?:  {
    __typename: "Settings",
    id: string,
    pk: string,
    notificationType?: NotificationType | null,
    createdAt: string,
    emailTo?: Array< string | null > | null,
    productExpiryDuration?: string | null,
    theme?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateSettingsMutationVariables = {
  input: UpdateSettingsInput,
  condition?: ModelSettingsConditionInput | null,
};

export type UpdateSettingsMutation = {
  updateSettings?:  {
    __typename: "Settings",
    id: string,
    pk: string,
    notificationType?: NotificationType | null,
    createdAt: string,
    emailTo?: Array< string | null > | null,
    productExpiryDuration?: string | null,
    theme?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteSettingsMutationVariables = {
  input: DeleteSettingsInput,
  condition?: ModelSettingsConditionInput | null,
};

export type DeleteSettingsMutation = {
  deleteSettings?:  {
    __typename: "Settings",
    id: string,
    pk: string,
    notificationType?: NotificationType | null,
    createdAt: string,
    emailTo?: Array< string | null > | null,
    productExpiryDuration?: string | null,
    theme?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateCompanyDetailsMutationVariables = {
  input: CreateCompanyDetailsInput,
  condition?: ModelCompanyDetailsConditionInput | null,
};

export type CreateCompanyDetailsMutation = {
  createCompanyDetails?:  {
    __typename: "CompanyDetails",
    id: string,
    pk: string,
    createdAt: string,
    primaryLogo?:  {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null,
    secondaryLogo?:  {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null,
    companyName?: string | null,
    webSite?: string | null,
    phoneNumber?: string | null,
    email?: string | null,
    ccEmail?: string | null,
    address?:  {
      __typename: "Address",
      addressLine1?: string | null,
      addressLine2?: string | null,
      street?: string | null,
      landmark?: string | null,
      state?: string | null,
      city?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    status?: boolean | null,
    currency?: string | null,
    categorySequenced?: boolean | null,
    copyrightText?: string | null,
    mobileAppUrls?:  Array< {
      __typename: "UrlDetails",
      type?: string | null,
      title?: string | null,
      alterText?: string | null,
      url?: string | null,
      certificationName?: string | null,
      redirectTo?: string | null,
    } | null > | null,
    theme?:  {
      __typename: "Theme",
      preferredTheme?: string | null,
      headerBackgroundColor?: string | null,
      headerTextColor?: string | null,
      headerIconColor?: string | null,
      footerBackgroundColor?: string | null,
      footerTextColor?: string | null,
      footerHeadingColor?: string | null,
      font?: string | null,
      navigationMenu?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      navigationMenuColor?: string | null,
      navigationSubMenu?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      navigationSubMenuColor?: string | null,
      productTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productSubTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productPrice?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDetailName?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDetailSubTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDetailPrice?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDescriptionHeading?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDescription?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      customerReviewsTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      customerReviewsDetails?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      notificationsTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      notificationsSubTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      profileHeading?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      profileContent?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      contentTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      contentDescription?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      titleHeader?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      titleDetails?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      primaryButton?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      primaryButtonBackgroundColor?: string | null,
      primaryButtonTextColor?: string | null,
      secondaryButton?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      secondaryButtonBackgroundColor?: string | null,
      secondaryButtonTextColor?: string | null,
    } | null,
    mobileTheme?:  {
      __typename: "MobileTheme",
      locationBackgroundColor?: string | null,
      locationTextColor?: string | null,
      locationIconColor?: string | null,
      searchBarBackgroundColor?: string | null,
      newArrivalsColor?: string | null,
      bestSellersColor?: string | null,
      recentlyViewedColor?: string | null,
      navBackgroundColor?: string | null,
      navIconColor?: string | null,
      font?: string | null,
      productListTitle?: string | null,
      productListBrand?: string | null,
      productListStock?: string | null,
      productListPrice?: string | null,
      productDetailName?: string | null,
      productDetailPrice?: string | null,
      productDetailStock?: string | null,
      productDetailBrand?: string | null,
      customerReviewsTitle?: string | null,
      customerReviewsDetails?: string | null,
      notificationsTitle?: string | null,
      notificationsSubTitle?: string | null,
      profileName?: string | null,
      profileHeadings?: string | null,
      primaryButtonText?: string | null,
      primaryButtonBackgroundColor?: string | null,
      primaryButtonTextColor?: string | null,
      secondaryButtonText?: string | null,
      secondaryButtonBackgroundColor?: string | null,
      secondaryButtonTextColor?: string | null,
    } | null,
    seoDescription?: string | null,
    daysToReturn?: number | null,
    brandName?: string | null,
    iconUrl?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCompanyDetailsMutationVariables = {
  input: UpdateCompanyDetailsInput,
  condition?: ModelCompanyDetailsConditionInput | null,
};

export type UpdateCompanyDetailsMutation = {
  updateCompanyDetails?:  {
    __typename: "CompanyDetails",
    id: string,
    pk: string,
    createdAt: string,
    primaryLogo?:  {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null,
    secondaryLogo?:  {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null,
    companyName?: string | null,
    webSite?: string | null,
    phoneNumber?: string | null,
    email?: string | null,
    ccEmail?: string | null,
    address?:  {
      __typename: "Address",
      addressLine1?: string | null,
      addressLine2?: string | null,
      street?: string | null,
      landmark?: string | null,
      state?: string | null,
      city?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    status?: boolean | null,
    currency?: string | null,
    categorySequenced?: boolean | null,
    copyrightText?: string | null,
    mobileAppUrls?:  Array< {
      __typename: "UrlDetails",
      type?: string | null,
      title?: string | null,
      alterText?: string | null,
      url?: string | null,
      certificationName?: string | null,
      redirectTo?: string | null,
    } | null > | null,
    theme?:  {
      __typename: "Theme",
      preferredTheme?: string | null,
      headerBackgroundColor?: string | null,
      headerTextColor?: string | null,
      headerIconColor?: string | null,
      footerBackgroundColor?: string | null,
      footerTextColor?: string | null,
      footerHeadingColor?: string | null,
      font?: string | null,
      navigationMenu?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      navigationMenuColor?: string | null,
      navigationSubMenu?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      navigationSubMenuColor?: string | null,
      productTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productSubTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productPrice?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDetailName?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDetailSubTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDetailPrice?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDescriptionHeading?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDescription?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      customerReviewsTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      customerReviewsDetails?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      notificationsTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      notificationsSubTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      profileHeading?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      profileContent?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      contentTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      contentDescription?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      titleHeader?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      titleDetails?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      primaryButton?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      primaryButtonBackgroundColor?: string | null,
      primaryButtonTextColor?: string | null,
      secondaryButton?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      secondaryButtonBackgroundColor?: string | null,
      secondaryButtonTextColor?: string | null,
    } | null,
    mobileTheme?:  {
      __typename: "MobileTheme",
      locationBackgroundColor?: string | null,
      locationTextColor?: string | null,
      locationIconColor?: string | null,
      searchBarBackgroundColor?: string | null,
      newArrivalsColor?: string | null,
      bestSellersColor?: string | null,
      recentlyViewedColor?: string | null,
      navBackgroundColor?: string | null,
      navIconColor?: string | null,
      font?: string | null,
      productListTitle?: string | null,
      productListBrand?: string | null,
      productListStock?: string | null,
      productListPrice?: string | null,
      productDetailName?: string | null,
      productDetailPrice?: string | null,
      productDetailStock?: string | null,
      productDetailBrand?: string | null,
      customerReviewsTitle?: string | null,
      customerReviewsDetails?: string | null,
      notificationsTitle?: string | null,
      notificationsSubTitle?: string | null,
      profileName?: string | null,
      profileHeadings?: string | null,
      primaryButtonText?: string | null,
      primaryButtonBackgroundColor?: string | null,
      primaryButtonTextColor?: string | null,
      secondaryButtonText?: string | null,
      secondaryButtonBackgroundColor?: string | null,
      secondaryButtonTextColor?: string | null,
    } | null,
    seoDescription?: string | null,
    daysToReturn?: number | null,
    brandName?: string | null,
    iconUrl?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCompanyDetailsMutationVariables = {
  input: DeleteCompanyDetailsInput,
  condition?: ModelCompanyDetailsConditionInput | null,
};

export type DeleteCompanyDetailsMutation = {
  deleteCompanyDetails?:  {
    __typename: "CompanyDetails",
    id: string,
    pk: string,
    createdAt: string,
    primaryLogo?:  {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null,
    secondaryLogo?:  {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null,
    companyName?: string | null,
    webSite?: string | null,
    phoneNumber?: string | null,
    email?: string | null,
    ccEmail?: string | null,
    address?:  {
      __typename: "Address",
      addressLine1?: string | null,
      addressLine2?: string | null,
      street?: string | null,
      landmark?: string | null,
      state?: string | null,
      city?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    status?: boolean | null,
    currency?: string | null,
    categorySequenced?: boolean | null,
    copyrightText?: string | null,
    mobileAppUrls?:  Array< {
      __typename: "UrlDetails",
      type?: string | null,
      title?: string | null,
      alterText?: string | null,
      url?: string | null,
      certificationName?: string | null,
      redirectTo?: string | null,
    } | null > | null,
    theme?:  {
      __typename: "Theme",
      preferredTheme?: string | null,
      headerBackgroundColor?: string | null,
      headerTextColor?: string | null,
      headerIconColor?: string | null,
      footerBackgroundColor?: string | null,
      footerTextColor?: string | null,
      footerHeadingColor?: string | null,
      font?: string | null,
      navigationMenu?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      navigationMenuColor?: string | null,
      navigationSubMenu?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      navigationSubMenuColor?: string | null,
      productTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productSubTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productPrice?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDetailName?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDetailSubTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDetailPrice?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDescriptionHeading?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDescription?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      customerReviewsTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      customerReviewsDetails?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      notificationsTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      notificationsSubTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      profileHeading?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      profileContent?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      contentTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      contentDescription?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      titleHeader?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      titleDetails?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      primaryButton?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      primaryButtonBackgroundColor?: string | null,
      primaryButtonTextColor?: string | null,
      secondaryButton?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      secondaryButtonBackgroundColor?: string | null,
      secondaryButtonTextColor?: string | null,
    } | null,
    mobileTheme?:  {
      __typename: "MobileTheme",
      locationBackgroundColor?: string | null,
      locationTextColor?: string | null,
      locationIconColor?: string | null,
      searchBarBackgroundColor?: string | null,
      newArrivalsColor?: string | null,
      bestSellersColor?: string | null,
      recentlyViewedColor?: string | null,
      navBackgroundColor?: string | null,
      navIconColor?: string | null,
      font?: string | null,
      productListTitle?: string | null,
      productListBrand?: string | null,
      productListStock?: string | null,
      productListPrice?: string | null,
      productDetailName?: string | null,
      productDetailPrice?: string | null,
      productDetailStock?: string | null,
      productDetailBrand?: string | null,
      customerReviewsTitle?: string | null,
      customerReviewsDetails?: string | null,
      notificationsTitle?: string | null,
      notificationsSubTitle?: string | null,
      profileName?: string | null,
      profileHeadings?: string | null,
      primaryButtonText?: string | null,
      primaryButtonBackgroundColor?: string | null,
      primaryButtonTextColor?: string | null,
      secondaryButtonText?: string | null,
      secondaryButtonBackgroundColor?: string | null,
      secondaryButtonTextColor?: string | null,
    } | null,
    seoDescription?: string | null,
    daysToReturn?: number | null,
    brandName?: string | null,
    iconUrl?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreatePaymentModeMutationVariables = {
  input: CreatePaymentModeInput,
  condition?: ModelPaymentModeConditionInput | null,
};

export type CreatePaymentModeMutation = {
  createPaymentMode?:  {
    __typename: "PaymentMode",
    id: string,
    pk: string,
    createdAt: string,
    method?: string | null,
    description?: string | null,
    provider?: string | null,
    logoUrl?: string | null,
    stagingUrl?: string | null,
    productionUrl?: string | null,
    status?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdatePaymentModeMutationVariables = {
  input: UpdatePaymentModeInput,
  condition?: ModelPaymentModeConditionInput | null,
};

export type UpdatePaymentModeMutation = {
  updatePaymentMode?:  {
    __typename: "PaymentMode",
    id: string,
    pk: string,
    createdAt: string,
    method?: string | null,
    description?: string | null,
    provider?: string | null,
    logoUrl?: string | null,
    stagingUrl?: string | null,
    productionUrl?: string | null,
    status?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeletePaymentModeMutationVariables = {
  input: DeletePaymentModeInput,
  condition?: ModelPaymentModeConditionInput | null,
};

export type DeletePaymentModeMutation = {
  deletePaymentMode?:  {
    __typename: "PaymentMode",
    id: string,
    pk: string,
    createdAt: string,
    method?: string | null,
    description?: string | null,
    provider?: string | null,
    logoUrl?: string | null,
    stagingUrl?: string | null,
    productionUrl?: string | null,
    status?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    pk: string,
    userName?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    companyName?: string | null,
    profileImageUrl?: string | null,
    email: string,
    phoneNumber?: string | null,
    role?: UserRole | null,
    password?: string | null,
    multiFactorAuth?: boolean | null,
    emailNotification?: boolean | null,
    mandatePasswordChange?: boolean | null,
    emailVerified?: boolean | null,
    otp?: string | null,
    status?: Status | null,
    searchKey?: string | null,
    addressDetails?:  Array< {
      __typename: "AddressDetails",
      name?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      address?:  {
        __typename: "Address",
        addressLine1?: string | null,
        addressLine2?: string | null,
        street?: string | null,
        landmark?: string | null,
        state?: string | null,
        city?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      type?: string | null,
      addressName?: string | null,
      default?: boolean | null,
      latitude?: string | null,
      longitude?: string | null,
    } | null > | null,
    loginAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    platform?: Platform | null,
    owner?: string | null,
  } | null,
};

export type CreateCategoryMutationVariables = {
  input: CreateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type CreateCategoryMutation = {
  createCategory?:  {
    __typename: "Category",
    pk: string,
    slug: string,
    id: string,
    catName?: string | null,
    mainCategory?: string | null,
    mainCategoryID?: string | null,
    categoryImg?: string | null,
    description?: string | null,
    webBannerImg?: string | null,
    webBannerImgBottom?: string | null,
    mobBannerImg?: string | null,
    mobBannerImgBottom?: string | null,
    categoryType?: CategoryType | null,
    searchKey?: string | null,
    catStatus?: boolean | null,
    sequenceNumber?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCategoryMutationVariables = {
  input: UpdateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type UpdateCategoryMutation = {
  updateCategory?:  {
    __typename: "Category",
    pk: string,
    slug: string,
    id: string,
    catName?: string | null,
    mainCategory?: string | null,
    mainCategoryID?: string | null,
    categoryImg?: string | null,
    description?: string | null,
    webBannerImg?: string | null,
    webBannerImgBottom?: string | null,
    mobBannerImg?: string | null,
    mobBannerImgBottom?: string | null,
    categoryType?: CategoryType | null,
    searchKey?: string | null,
    catStatus?: boolean | null,
    sequenceNumber?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCategoryMutationVariables = {
  input: DeleteCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type DeleteCategoryMutation = {
  deleteCategory?:  {
    __typename: "Category",
    pk: string,
    slug: string,
    id: string,
    catName?: string | null,
    mainCategory?: string | null,
    mainCategoryID?: string | null,
    categoryImg?: string | null,
    description?: string | null,
    webBannerImg?: string | null,
    webBannerImgBottom?: string | null,
    mobBannerImg?: string | null,
    mobBannerImgBottom?: string | null,
    categoryType?: CategoryType | null,
    searchKey?: string | null,
    catStatus?: boolean | null,
    sequenceNumber?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateBrandMutationVariables = {
  input: CreateBrandInput,
  condition?: ModelBrandConditionInput | null,
};

export type CreateBrandMutation = {
  createBrand?:  {
    __typename: "Brand",
    pk: string,
    id: string,
    brandName?: string | null,
    searchKey?: string | null,
    manufacturerName?: string | null,
    status?: Status | null,
    countryOfOrigin?: string | null,
    brandLogo?: string | null,
    brandUrl?: string | null,
    brandDescription?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateBrandMutationVariables = {
  input: UpdateBrandInput,
  condition?: ModelBrandConditionInput | null,
};

export type UpdateBrandMutation = {
  updateBrand?:  {
    __typename: "Brand",
    pk: string,
    id: string,
    brandName?: string | null,
    searchKey?: string | null,
    manufacturerName?: string | null,
    status?: Status | null,
    countryOfOrigin?: string | null,
    brandLogo?: string | null,
    brandUrl?: string | null,
    brandDescription?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteBrandMutationVariables = {
  input: DeleteBrandInput,
  condition?: ModelBrandConditionInput | null,
};

export type DeleteBrandMutation = {
  deleteBrand?:  {
    __typename: "Brand",
    pk: string,
    id: string,
    brandName?: string | null,
    searchKey?: string | null,
    manufacturerName?: string | null,
    status?: Status | null,
    countryOfOrigin?: string | null,
    brandLogo?: string | null,
    brandUrl?: string | null,
    brandDescription?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateAttributeNameMutationVariables = {
  input: CreateAttributeNameInput,
  condition?: ModelAttributeNameConditionInput | null,
};

export type CreateAttributeNameMutation = {
  createAttributeName?:  {
    __typename: "AttributeName",
    pk: string,
    slug: string,
    id: string,
    sequenceNumber?: number | null,
    searchKey?: string | null,
    status?: Status | null,
    attrName?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateAttributeNameMutationVariables = {
  input: UpdateAttributeNameInput,
  condition?: ModelAttributeNameConditionInput | null,
};

export type UpdateAttributeNameMutation = {
  updateAttributeName?:  {
    __typename: "AttributeName",
    pk: string,
    slug: string,
    id: string,
    sequenceNumber?: number | null,
    searchKey?: string | null,
    status?: Status | null,
    attrName?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteAttributeNameMutationVariables = {
  input: DeleteAttributeNameInput,
  condition?: ModelAttributeNameConditionInput | null,
};

export type DeleteAttributeNameMutation = {
  deleteAttributeName?:  {
    __typename: "AttributeName",
    pk: string,
    slug: string,
    id: string,
    sequenceNumber?: number | null,
    searchKey?: string | null,
    status?: Status | null,
    attrName?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateCountryMutationVariables = {
  input: CreateCountryInput,
  condition?: ModelCountryConditionInput | null,
};

export type CreateCountryMutation = {
  createCountry?:  {
    __typename: "Country",
    pk: string,
    slug: string,
    id: string,
    name?: string | null,
    shortName?: string | null,
    searchKey?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCountryMutationVariables = {
  input: UpdateCountryInput,
  condition?: ModelCountryConditionInput | null,
};

export type UpdateCountryMutation = {
  updateCountry?:  {
    __typename: "Country",
    pk: string,
    slug: string,
    id: string,
    name?: string | null,
    shortName?: string | null,
    searchKey?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCountryMutationVariables = {
  input: DeleteCountryInput,
  condition?: ModelCountryConditionInput | null,
};

export type DeleteCountryMutation = {
  deleteCountry?:  {
    __typename: "Country",
    pk: string,
    slug: string,
    id: string,
    name?: string | null,
    shortName?: string | null,
    searchKey?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateDropDownMasterMutationVariables = {
  input: CreateDropDownMasterInput,
  condition?: ModelDropDownMasterConditionInput | null,
};

export type CreateDropDownMasterMutation = {
  createDropDownMaster?:  {
    __typename: "DropDownMaster",
    pk: string,
    id: string,
    value?: string | null,
    status?: Status | null,
    searchKey?: string | null,
    sequenceNumber?: number | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateDropDownMasterMutationVariables = {
  input: UpdateDropDownMasterInput,
  condition?: ModelDropDownMasterConditionInput | null,
};

export type UpdateDropDownMasterMutation = {
  updateDropDownMaster?:  {
    __typename: "DropDownMaster",
    pk: string,
    id: string,
    value?: string | null,
    status?: Status | null,
    searchKey?: string | null,
    sequenceNumber?: number | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteDropDownMasterMutationVariables = {
  input: DeleteDropDownMasterInput,
  condition?: ModelDropDownMasterConditionInput | null,
};

export type DeleteDropDownMasterMutation = {
  deleteDropDownMaster?:  {
    __typename: "DropDownMaster",
    pk: string,
    id: string,
    value?: string | null,
    status?: Status | null,
    searchKey?: string | null,
    sequenceNumber?: number | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateCartMutationVariables = {
  input: CreateCartInput,
  condition?: ModelCartConditionInput | null,
};

export type CreateCartMutation = {
  createCart?:  {
    __typename: "Cart",
    pk: string,
    id: string,
    userId: string,
    productID: string,
    createdAt: string,
    productImage?: string | null,
    productName?: string | null,
    mainCategoryId?: string | null,
    mainCategory?: string | null,
    subCategoryId?: string | null,
    subCategoryN?: string | null,
    attrValueID?: string | null,
    price?: number | null,
    salesPrice?: number | null,
    stockUnit?: string | null,
    itemsPerUnit?: number | null,
    expiryDate?: string | null,
    selectedQuantity?: number | null,
    brandDetail?:  {
      __typename: "BrandDetail",
      brandID?: string | null,
      brandName?: string | null,
      brandDescription?: string | null,
      manufacturerName?: string | null,
      imageUrl?: string | null,
    } | null,
    wareHouseId?: string | null,
    wareHouseName?: string | null,
    attrName?: string | null,
    attrValue?: string | null,
    shippingInfo?:  {
      __typename: "shippingDetails",
      weightValue?: string | null,
      weightUnit?: string | null,
      lengthValue?: string | null,
      lengthUnit?: string | null,
      heightValue?: string | null,
      heightUnit?: string | null,
      widthValue?: string | null,
      widthUnit?: string | null,
    } | null,
    userName?: string | null,
    userMail?: string | null,
    model?: string | null,
    cartURL?: string | null,
    taxCode?: string | null,
    taxCharge?: number | null,
    taxDetail?:  Array< {
      __typename: "taxDetail",
      taxCharge?: number | null,
      taxID?: string | null,
      taxName?: string | null,
    } | null > | null,
    sku?: string | null,
    searchKey?: string | null,
    totalPrice?: number | null,
    mobileNumber?: string | null,
    currentStock?: string | null,
    brandId?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCartMutationVariables = {
  input: UpdateCartInput,
  condition?: ModelCartConditionInput | null,
};

export type UpdateCartMutation = {
  updateCart?:  {
    __typename: "Cart",
    pk: string,
    id: string,
    userId: string,
    productID: string,
    createdAt: string,
    productImage?: string | null,
    productName?: string | null,
    mainCategoryId?: string | null,
    mainCategory?: string | null,
    subCategoryId?: string | null,
    subCategoryN?: string | null,
    attrValueID?: string | null,
    price?: number | null,
    salesPrice?: number | null,
    stockUnit?: string | null,
    itemsPerUnit?: number | null,
    expiryDate?: string | null,
    selectedQuantity?: number | null,
    brandDetail?:  {
      __typename: "BrandDetail",
      brandID?: string | null,
      brandName?: string | null,
      brandDescription?: string | null,
      manufacturerName?: string | null,
      imageUrl?: string | null,
    } | null,
    wareHouseId?: string | null,
    wareHouseName?: string | null,
    attrName?: string | null,
    attrValue?: string | null,
    shippingInfo?:  {
      __typename: "shippingDetails",
      weightValue?: string | null,
      weightUnit?: string | null,
      lengthValue?: string | null,
      lengthUnit?: string | null,
      heightValue?: string | null,
      heightUnit?: string | null,
      widthValue?: string | null,
      widthUnit?: string | null,
    } | null,
    userName?: string | null,
    userMail?: string | null,
    model?: string | null,
    cartURL?: string | null,
    taxCode?: string | null,
    taxCharge?: number | null,
    taxDetail?:  Array< {
      __typename: "taxDetail",
      taxCharge?: number | null,
      taxID?: string | null,
      taxName?: string | null,
    } | null > | null,
    sku?: string | null,
    searchKey?: string | null,
    totalPrice?: number | null,
    mobileNumber?: string | null,
    currentStock?: string | null,
    brandId?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCartMutationVariables = {
  input: DeleteCartInput,
  condition?: ModelCartConditionInput | null,
};

export type DeleteCartMutation = {
  deleteCart?:  {
    __typename: "Cart",
    pk: string,
    id: string,
    userId: string,
    productID: string,
    createdAt: string,
    productImage?: string | null,
    productName?: string | null,
    mainCategoryId?: string | null,
    mainCategory?: string | null,
    subCategoryId?: string | null,
    subCategoryN?: string | null,
    attrValueID?: string | null,
    price?: number | null,
    salesPrice?: number | null,
    stockUnit?: string | null,
    itemsPerUnit?: number | null,
    expiryDate?: string | null,
    selectedQuantity?: number | null,
    brandDetail?:  {
      __typename: "BrandDetail",
      brandID?: string | null,
      brandName?: string | null,
      brandDescription?: string | null,
      manufacturerName?: string | null,
      imageUrl?: string | null,
    } | null,
    wareHouseId?: string | null,
    wareHouseName?: string | null,
    attrName?: string | null,
    attrValue?: string | null,
    shippingInfo?:  {
      __typename: "shippingDetails",
      weightValue?: string | null,
      weightUnit?: string | null,
      lengthValue?: string | null,
      lengthUnit?: string | null,
      heightValue?: string | null,
      heightUnit?: string | null,
      widthValue?: string | null,
      widthUnit?: string | null,
    } | null,
    userName?: string | null,
    userMail?: string | null,
    model?: string | null,
    cartURL?: string | null,
    taxCode?: string | null,
    taxCharge?: number | null,
    taxDetail?:  Array< {
      __typename: "taxDetail",
      taxCharge?: number | null,
      taxID?: string | null,
      taxName?: string | null,
    } | null > | null,
    sku?: string | null,
    searchKey?: string | null,
    totalPrice?: number | null,
    mobileNumber?: string | null,
    currentStock?: string | null,
    brandId?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateWareHouseMutationVariables = {
  input: CreateWareHouseInput,
  condition?: ModelWareHouseConditionInput | null,
};

export type CreateWareHouseMutation = {
  createWareHouse?:  {
    __typename: "WareHouse",
    pk: string,
    id: string,
    name: string,
    createdAt: string,
    addressLine1?: string | null,
    addressLine2?: string | null,
    searchKey?: string | null,
    countryId?: string | null,
    country?: string | null,
    state?: string | null,
    city?: string | null,
    zipCode?: string | null,
    contactPerson?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    workingHoursFrom?: string | null,
    workingHoursTo?: string | null,
    status?: boolean | null,
    latitude?: string | null,
    longitude?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateWareHouseMutationVariables = {
  input: UpdateWareHouseInput,
  condition?: ModelWareHouseConditionInput | null,
};

export type UpdateWareHouseMutation = {
  updateWareHouse?:  {
    __typename: "WareHouse",
    pk: string,
    id: string,
    name: string,
    createdAt: string,
    addressLine1?: string | null,
    addressLine2?: string | null,
    searchKey?: string | null,
    countryId?: string | null,
    country?: string | null,
    state?: string | null,
    city?: string | null,
    zipCode?: string | null,
    contactPerson?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    workingHoursFrom?: string | null,
    workingHoursTo?: string | null,
    status?: boolean | null,
    latitude?: string | null,
    longitude?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteWareHouseMutationVariables = {
  input: DeleteWareHouseInput,
  condition?: ModelWareHouseConditionInput | null,
};

export type DeleteWareHouseMutation = {
  deleteWareHouse?:  {
    __typename: "WareHouse",
    pk: string,
    id: string,
    name: string,
    createdAt: string,
    addressLine1?: string | null,
    addressLine2?: string | null,
    searchKey?: string | null,
    countryId?: string | null,
    country?: string | null,
    state?: string | null,
    city?: string | null,
    zipCode?: string | null,
    contactPerson?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    workingHoursFrom?: string | null,
    workingHoursTo?: string | null,
    status?: boolean | null,
    latitude?: string | null,
    longitude?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateNotificationsMutationVariables = {
  input: CreateNotificationsInput,
  condition?: ModelNotificationsConditionInput | null,
};

export type CreateNotificationsMutation = {
  createNotifications?:  {
    __typename: "Notifications",
    pk: string,
    id: string,
    userId?: string | null,
    userName?: string | null,
    notificationType?: string | null,
    Title?: string | null,
    Message?: string | null,
    orderID?: string | null,
    DateTime?: string | null,
    notificationStatus?: number | null,
    notificationModule?: string | null,
    referenceID?: string | null,
    targetURL?: string | null,
    searchKey?: string | null,
    expiryDate?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateNotificationsMutationVariables = {
  input: UpdateNotificationsInput,
  condition?: ModelNotificationsConditionInput | null,
};

export type UpdateNotificationsMutation = {
  updateNotifications?:  {
    __typename: "Notifications",
    pk: string,
    id: string,
    userId?: string | null,
    userName?: string | null,
    notificationType?: string | null,
    Title?: string | null,
    Message?: string | null,
    orderID?: string | null,
    DateTime?: string | null,
    notificationStatus?: number | null,
    notificationModule?: string | null,
    referenceID?: string | null,
    targetURL?: string | null,
    searchKey?: string | null,
    expiryDate?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteNotificationsMutationVariables = {
  input: DeleteNotificationsInput,
  condition?: ModelNotificationsConditionInput | null,
};

export type DeleteNotificationsMutation = {
  deleteNotifications?:  {
    __typename: "Notifications",
    pk: string,
    id: string,
    userId?: string | null,
    userName?: string | null,
    notificationType?: string | null,
    Title?: string | null,
    Message?: string | null,
    orderID?: string | null,
    DateTime?: string | null,
    notificationStatus?: number | null,
    notificationModule?: string | null,
    referenceID?: string | null,
    targetURL?: string | null,
    searchKey?: string | null,
    expiryDate?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateModuleMasterMutationVariables = {
  input: CreateModuleMasterInput,
  condition?: ModelModuleMasterConditionInput | null,
};

export type CreateModuleMasterMutation = {
  createModuleMaster?:  {
    __typename: "ModuleMaster",
    pk: string,
    id: string,
    moduleName?: string | null,
    sequenceNumber?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateModuleMasterMutationVariables = {
  input: UpdateModuleMasterInput,
  condition?: ModelModuleMasterConditionInput | null,
};

export type UpdateModuleMasterMutation = {
  updateModuleMaster?:  {
    __typename: "ModuleMaster",
    pk: string,
    id: string,
    moduleName?: string | null,
    sequenceNumber?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteModuleMasterMutationVariables = {
  input: DeleteModuleMasterInput,
  condition?: ModelModuleMasterConditionInput | null,
};

export type DeleteModuleMasterMutation = {
  deleteModuleMaster?:  {
    __typename: "ModuleMaster",
    pk: string,
    id: string,
    moduleName?: string | null,
    sequenceNumber?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateRoleManagementMutationVariables = {
  input: CreateRoleManagementInput,
  condition?: ModelRoleManagementConditionInput | null,
};

export type CreateRoleManagementMutation = {
  createRoleManagement?:  {
    __typename: "RoleManagement",
    pk: string,
    id: string,
    menuId?: string | null,
    roleName: string,
    module?:  Array< {
      __typename: "Module",
      moduleId?: string | null,
      menu?: string | null,
      view?: boolean | null,
      add?: boolean | null,
      edit?: boolean | null,
      delete?: boolean | null,
    } | null > | null,
    status?: Status | null,
    searchKey?: string | null,
    updatedAt: string,
    createdAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateRoleManagementMutationVariables = {
  input: UpdateRoleManagementInput,
  condition?: ModelRoleManagementConditionInput | null,
};

export type UpdateRoleManagementMutation = {
  updateRoleManagement?:  {
    __typename: "RoleManagement",
    pk: string,
    id: string,
    menuId?: string | null,
    roleName: string,
    module?:  Array< {
      __typename: "Module",
      moduleId?: string | null,
      menu?: string | null,
      view?: boolean | null,
      add?: boolean | null,
      edit?: boolean | null,
      delete?: boolean | null,
    } | null > | null,
    status?: Status | null,
    searchKey?: string | null,
    updatedAt: string,
    createdAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteRoleManagementMutationVariables = {
  input: DeleteRoleManagementInput,
  condition?: ModelRoleManagementConditionInput | null,
};

export type DeleteRoleManagementMutation = {
  deleteRoleManagement?:  {
    __typename: "RoleManagement",
    pk: string,
    id: string,
    menuId?: string | null,
    roleName: string,
    module?:  Array< {
      __typename: "Module",
      moduleId?: string | null,
      menu?: string | null,
      view?: boolean | null,
      add?: boolean | null,
      edit?: boolean | null,
      delete?: boolean | null,
    } | null > | null,
    status?: Status | null,
    searchKey?: string | null,
    updatedAt: string,
    createdAt: string,
    owner?: string | null,
  } | null,
};

export type CreateContentMutationVariables = {
  input: CreateContentInput,
  condition?: ModelContentConditionInput | null,
};

export type CreateContentMutation = {
  createContent?:  {
    __typename: "Content",
    pk: string,
    id: string,
    contentName: string,
    title?: string | null,
    sequenceNumber?: number | null,
    content?: string | null,
    searchKey?: string | null,
    docUrl?: string | null,
    visibleToOthers?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateContentMutationVariables = {
  input: UpdateContentInput,
  condition?: ModelContentConditionInput | null,
};

export type UpdateContentMutation = {
  updateContent?:  {
    __typename: "Content",
    pk: string,
    id: string,
    contentName: string,
    title?: string | null,
    sequenceNumber?: number | null,
    content?: string | null,
    searchKey?: string | null,
    docUrl?: string | null,
    visibleToOthers?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteContentMutationVariables = {
  input: DeleteContentInput,
  condition?: ModelContentConditionInput | null,
};

export type DeleteContentMutation = {
  deleteContent?:  {
    __typename: "Content",
    pk: string,
    id: string,
    contentName: string,
    title?: string | null,
    sequenceNumber?: number | null,
    content?: string | null,
    searchKey?: string | null,
    docUrl?: string | null,
    visibleToOthers?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateFAQMutationVariables = {
  input: CreateFAQInput,
  condition?: ModelFAQConditionInput | null,
};

export type CreateFAQMutation = {
  createFAQ?:  {
    __typename: "FAQ",
    pk: string,
    id: string,
    type?: string | null,
    sequenceNumber?: number | null,
    name?: string | null,
    FAQSections?:  Array< {
      __typename: "FAQSections",
      question?: string | null,
      answer?: string | null,
    } | null > | null,
    searchKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateFAQMutationVariables = {
  input: UpdateFAQInput,
  condition?: ModelFAQConditionInput | null,
};

export type UpdateFAQMutation = {
  updateFAQ?:  {
    __typename: "FAQ",
    pk: string,
    id: string,
    type?: string | null,
    sequenceNumber?: number | null,
    name?: string | null,
    FAQSections?:  Array< {
      __typename: "FAQSections",
      question?: string | null,
      answer?: string | null,
    } | null > | null,
    searchKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteFAQMutationVariables = {
  input: DeleteFAQInput,
  condition?: ModelFAQConditionInput | null,
};

export type DeleteFAQMutation = {
  deleteFAQ?:  {
    __typename: "FAQ",
    pk: string,
    id: string,
    type?: string | null,
    sequenceNumber?: number | null,
    name?: string | null,
    FAQSections?:  Array< {
      __typename: "FAQSections",
      question?: string | null,
      answer?: string | null,
    } | null > | null,
    searchKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateProductReviewMutationVariables = {
  input: CreateProductReviewInput,
  condition?: ModelProductReviewConditionInput | null,
};

export type CreateProductReviewMutation = {
  createProductReview?:  {
    __typename: "ProductReview",
    pk: string,
    id: string,
    orderItemId: string,
    productId?: string | null,
    productImage?: string | null,
    productName?: string | null,
    userId?: string | null,
    userName?: string | null,
    email?: string | null,
    overallRating?: number | null,
    title?: string | null,
    reviewComment?: string | null,
    removeAbusiveComment?: boolean | null,
    abusiveReportedBy?:  Array< {
      __typename: "AbusiveReport",
      userId?: string | null,
      userName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      reasons?: Array< string | null > | null,
      reportedDate?: string | null,
    } | null > | null,
    attachments?: Array< string | null > | null,
    createdAt?: string | null,
    reply?: string | null,
    repliedOnDate?: string | null,
    orderedDate?: string | null,
    reviewedDate?: string | null,
    reviewStatus?: EnquiryStatus | null,
    searchKey?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateProductReviewMutationVariables = {
  input: UpdateProductReviewInput,
  condition?: ModelProductReviewConditionInput | null,
};

export type UpdateProductReviewMutation = {
  updateProductReview?:  {
    __typename: "ProductReview",
    pk: string,
    id: string,
    orderItemId: string,
    productId?: string | null,
    productImage?: string | null,
    productName?: string | null,
    userId?: string | null,
    userName?: string | null,
    email?: string | null,
    overallRating?: number | null,
    title?: string | null,
    reviewComment?: string | null,
    removeAbusiveComment?: boolean | null,
    abusiveReportedBy?:  Array< {
      __typename: "AbusiveReport",
      userId?: string | null,
      userName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      reasons?: Array< string | null > | null,
      reportedDate?: string | null,
    } | null > | null,
    attachments?: Array< string | null > | null,
    createdAt?: string | null,
    reply?: string | null,
    repliedOnDate?: string | null,
    orderedDate?: string | null,
    reviewedDate?: string | null,
    reviewStatus?: EnquiryStatus | null,
    searchKey?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteProductReviewMutationVariables = {
  input: DeleteProductReviewInput,
  condition?: ModelProductReviewConditionInput | null,
};

export type DeleteProductReviewMutation = {
  deleteProductReview?:  {
    __typename: "ProductReview",
    pk: string,
    id: string,
    orderItemId: string,
    productId?: string | null,
    productImage?: string | null,
    productName?: string | null,
    userId?: string | null,
    userName?: string | null,
    email?: string | null,
    overallRating?: number | null,
    title?: string | null,
    reviewComment?: string | null,
    removeAbusiveComment?: boolean | null,
    abusiveReportedBy?:  Array< {
      __typename: "AbusiveReport",
      userId?: string | null,
      userName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      reasons?: Array< string | null > | null,
      reportedDate?: string | null,
    } | null > | null,
    attachments?: Array< string | null > | null,
    createdAt?: string | null,
    reply?: string | null,
    repliedOnDate?: string | null,
    orderedDate?: string | null,
    reviewedDate?: string | null,
    reviewStatus?: EnquiryStatus | null,
    searchKey?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateTokenDetailsMutationVariables = {
  input: CreateTokenDetailsInput,
  condition?: ModelTokenDetailsConditionInput | null,
};

export type CreateTokenDetailsMutation = {
  createTokenDetails?:  {
    __typename: "TokenDetails",
    pk: string,
    id: string,
    expiresIn?: string | null,
    token: string,
    tokenType?: string | null,
    userID: string,
    loginAt?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateTokenDetailsMutationVariables = {
  input: UpdateTokenDetailsInput,
  condition?: ModelTokenDetailsConditionInput | null,
};

export type UpdateTokenDetailsMutation = {
  updateTokenDetails?:  {
    __typename: "TokenDetails",
    pk: string,
    id: string,
    expiresIn?: string | null,
    token: string,
    tokenType?: string | null,
    userID: string,
    loginAt?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteTokenDetailsMutationVariables = {
  input: DeleteTokenDetailsInput,
  condition?: ModelTokenDetailsConditionInput | null,
};

export type DeleteTokenDetailsMutation = {
  deleteTokenDetails?:  {
    __typename: "TokenDetails",
    pk: string,
    id: string,
    expiresIn?: string | null,
    token: string,
    tokenType?: string | null,
    userID: string,
    loginAt?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateTaxMutationVariables = {
  input: CreateTaxInput,
  condition?: ModelTaxConditionInput | null,
};

export type CreateTaxMutation = {
  createTax?:  {
    __typename: "Tax",
    pk: string,
    id: string,
    taxCode: string,
    taxType?: string | null,
    status?: Status | null,
    searchKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateTaxMutationVariables = {
  input: UpdateTaxInput,
  condition?: ModelTaxConditionInput | null,
};

export type UpdateTaxMutation = {
  updateTax?:  {
    __typename: "Tax",
    pk: string,
    id: string,
    taxCode: string,
    taxType?: string | null,
    status?: Status | null,
    searchKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteTaxMutationVariables = {
  input: DeleteTaxInput,
  condition?: ModelTaxConditionInput | null,
};

export type DeleteTaxMutation = {
  deleteTax?:  {
    __typename: "Tax",
    pk: string,
    id: string,
    taxCode: string,
    taxType?: string | null,
    status?: Status | null,
    searchKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateCarouselImageMutationVariables = {
  input: CreateCarouselImageInput,
  condition?: ModelCarouselImageConditionInput | null,
};

export type CreateCarouselImageMutation = {
  createCarouselImage?:  {
    __typename: "CarouselImage",
    pk: string,
    id: string,
    type?: string | null,
    deviceType?: string | null,
    animation?: animationType | null,
    pauseTime?: number | null,
    transitionSpeed?: number | null,
    stopAnimation?: boolean | null,
    displayArrows?: boolean | null,
    displayNavigationBullets?: boolean | null,
    image?:  Array< {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null > | null,
    status?: Status | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateCarouselImageMutationVariables = {
  input: UpdateCarouselImageInput,
  condition?: ModelCarouselImageConditionInput | null,
};

export type UpdateCarouselImageMutation = {
  updateCarouselImage?:  {
    __typename: "CarouselImage",
    pk: string,
    id: string,
    type?: string | null,
    deviceType?: string | null,
    animation?: animationType | null,
    pauseTime?: number | null,
    transitionSpeed?: number | null,
    stopAnimation?: boolean | null,
    displayArrows?: boolean | null,
    displayNavigationBullets?: boolean | null,
    image?:  Array< {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null > | null,
    status?: Status | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteCarouselImageMutationVariables = {
  input: DeleteCarouselImageInput,
  condition?: ModelCarouselImageConditionInput | null,
};

export type DeleteCarouselImageMutation = {
  deleteCarouselImage?:  {
    __typename: "CarouselImage",
    pk: string,
    id: string,
    type?: string | null,
    deviceType?: string | null,
    animation?: animationType | null,
    pauseTime?: number | null,
    transitionSpeed?: number | null,
    stopAnimation?: boolean | null,
    displayArrows?: boolean | null,
    displayNavigationBullets?: boolean | null,
    image?:  Array< {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null > | null,
    status?: Status | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateWishListMutationVariables = {
  input: CreateWishListInput,
  condition?: ModelWishListConditionInput | null,
};

export type CreateWishListMutation = {
  createWishList?:  {
    __typename: "WishList",
    pk: string,
    id: string,
    userId?: string | null,
    productId?: string | null,
    productName?: string | null,
    productImage?: string | null,
    mainCategory?: string | null,
    mainCategoryId?: string | null,
    subCategory?: string | null,
    subCategoryId?: string | null,
    brandName?: string | null,
    brandId?: string | null,
    model?: string | null,
    rating?: number | null,
    regularPrice?: number | null,
    salePrice?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateWishListMutationVariables = {
  input: UpdateWishListInput,
  condition?: ModelWishListConditionInput | null,
};

export type UpdateWishListMutation = {
  updateWishList?:  {
    __typename: "WishList",
    pk: string,
    id: string,
    userId?: string | null,
    productId?: string | null,
    productName?: string | null,
    productImage?: string | null,
    mainCategory?: string | null,
    mainCategoryId?: string | null,
    subCategory?: string | null,
    subCategoryId?: string | null,
    brandName?: string | null,
    brandId?: string | null,
    model?: string | null,
    rating?: number | null,
    regularPrice?: number | null,
    salePrice?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteWishListMutationVariables = {
  input: DeleteWishListInput,
  condition?: ModelWishListConditionInput | null,
};

export type DeleteWishListMutation = {
  deleteWishList?:  {
    __typename: "WishList",
    pk: string,
    id: string,
    userId?: string | null,
    productId?: string | null,
    productName?: string | null,
    productImage?: string | null,
    mainCategory?: string | null,
    mainCategoryId?: string | null,
    subCategory?: string | null,
    subCategoryId?: string | null,
    brandName?: string | null,
    brandId?: string | null,
    model?: string | null,
    rating?: number | null,
    regularPrice?: number | null,
    salePrice?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteConfigurationMutationVariables = {
  input: DeleteConfigurationInput,
  condition?: ModelConfigurationConditionInput | null,
};

export type DeleteConfigurationMutation = {
  deleteConfiguration?:  {
    __typename: "configuration",
    pk: string,
    id: string,
    configurationDescription?: string | null,
    continuousSettings?:  {
      __typename: "ContinuousSettings",
      prefixValue?: string | null,
      numberOfDigits?: number | null,
      includeYearSuffix?: boolean | null,
      startingNumber?: number | null,
      runningNumber?: number | null,
    } | null,
    type?: string | null,
    modifiedTime?: string | null,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateMailLogMutationVariables = {
  input: CreateMailLogInput,
  condition?: ModelMailLogConditionInput | null,
};

export type CreateMailLogMutation = {
  createMailLog?:  {
    __typename: "MailLog",
    pk: string,
    id: string,
    referenceId?: string | null,
    referenceForm?: string | null,
    userId?: string | null,
    mailDetails?:  Array< {
      __typename: "EmailDetails",
      sentTo?: string | null,
      sentDate?: string | null,
    } | null > | null,
    updatedAt?: string | null,
    createdAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateMailLogMutationVariables = {
  input: UpdateMailLogInput,
  condition?: ModelMailLogConditionInput | null,
};

export type UpdateMailLogMutation = {
  updateMailLog?:  {
    __typename: "MailLog",
    pk: string,
    id: string,
    referenceId?: string | null,
    referenceForm?: string | null,
    userId?: string | null,
    mailDetails?:  Array< {
      __typename: "EmailDetails",
      sentTo?: string | null,
      sentDate?: string | null,
    } | null > | null,
    updatedAt?: string | null,
    createdAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteMailLogMutationVariables = {
  input: DeleteMailLogInput,
  condition?: ModelMailLogConditionInput | null,
};

export type DeleteMailLogMutation = {
  deleteMailLog?:  {
    __typename: "MailLog",
    pk: string,
    id: string,
    referenceId?: string | null,
    referenceForm?: string | null,
    userId?: string | null,
    mailDetails?:  Array< {
      __typename: "EmailDetails",
      sentTo?: string | null,
      sentDate?: string | null,
    } | null > | null,
    updatedAt?: string | null,
    createdAt: string,
    owner?: string | null,
  } | null,
};

export type CreateDeliveryPartnerMutationVariables = {
  input: CreateDeliveryPartnerInput,
  condition?: ModelDeliveryPartnerConditionInput | null,
};

export type CreateDeliveryPartnerMutation = {
  createDeliveryPartner?:  {
    __typename: "DeliveryPartner",
    pk: string,
    id: string,
    logo?: string | null,
    logoUrl?: string | null,
    companyName?: string | null,
    displayName?: string | null,
    apiKey?: string | null,
    apiKeyExpiryDate?: string | null,
    contactPerson?: string | null,
    address?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    status?: Status | null,
    searchKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateDeliveryPartnerMutationVariables = {
  input: UpdateDeliveryPartnerInput,
  condition?: ModelDeliveryPartnerConditionInput | null,
};

export type UpdateDeliveryPartnerMutation = {
  updateDeliveryPartner?:  {
    __typename: "DeliveryPartner",
    pk: string,
    id: string,
    logo?: string | null,
    logoUrl?: string | null,
    companyName?: string | null,
    displayName?: string | null,
    apiKey?: string | null,
    apiKeyExpiryDate?: string | null,
    contactPerson?: string | null,
    address?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    status?: Status | null,
    searchKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteDeliveryPartnerMutationVariables = {
  input: DeleteDeliveryPartnerInput,
  condition?: ModelDeliveryPartnerConditionInput | null,
};

export type DeleteDeliveryPartnerMutation = {
  deleteDeliveryPartner?:  {
    __typename: "DeliveryPartner",
    pk: string,
    id: string,
    logo?: string | null,
    logoUrl?: string | null,
    companyName?: string | null,
    displayName?: string | null,
    apiKey?: string | null,
    apiKeyExpiryDate?: string | null,
    contactPerson?: string | null,
    address?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    status?: Status | null,
    searchKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateLedgerMutationVariables = {
  input: CreateLedgerInput,
  condition?: ModelLedgerConditionInput | null,
};

export type CreateLedgerMutation = {
  createLedger?:  {
    __typename: "Ledger",
    pk: string,
    id: string,
    type?: string | null,
    transDate?: string | null,
    transReference?: string | null,
    transDetails?: string | null,
    transTo?: string | null,
    transToId?: string | null,
    transAmount?: number | null,
    transType?: string | null,
    paymentStatus?: string | null,
    buyerName?: string | null,
    orderRef?: string | null,
    orderAmount?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateLedgerMutationVariables = {
  input: UpdateLedgerInput,
  condition?: ModelLedgerConditionInput | null,
};

export type UpdateLedgerMutation = {
  updateLedger?:  {
    __typename: "Ledger",
    pk: string,
    id: string,
    type?: string | null,
    transDate?: string | null,
    transReference?: string | null,
    transDetails?: string | null,
    transTo?: string | null,
    transToId?: string | null,
    transAmount?: number | null,
    transType?: string | null,
    paymentStatus?: string | null,
    buyerName?: string | null,
    orderRef?: string | null,
    orderAmount?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteLedgerMutationVariables = {
  input: DeleteLedgerInput,
  condition?: ModelLedgerConditionInput | null,
};

export type DeleteLedgerMutation = {
  deleteLedger?:  {
    __typename: "Ledger",
    pk: string,
    id: string,
    type?: string | null,
    transDate?: string | null,
    transReference?: string | null,
    transDetails?: string | null,
    transTo?: string | null,
    transToId?: string | null,
    transAmount?: number | null,
    transType?: string | null,
    paymentStatus?: string | null,
    buyerName?: string | null,
    orderRef?: string | null,
    orderAmount?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteProductVisitMutationVariables = {
  input: DeleteProductVisitInput,
  condition?: ModelProductVisitConditionInput | null,
};

export type DeleteProductVisitMutation = {
  deleteProductVisit?:  {
    __typename: "ProductVisit",
    pk: string,
    id: string,
    prodId?: string | null,
    userId?: string | null,
    userName?: string | null,
    visitedAt?: string | null,
    place?: string | null,
    visitedInfo?:  Array< {
      __typename: "VisitedInfo",
      platform?: string | null,
      location?: string | null,
      time?: string | null,
      ipAddress?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreatePromoCodesMutationVariables = {
  input: CreatePromoCodesInput,
  condition?: ModelPromoCodesConditionInput | null,
};

export type CreatePromoCodesMutation = {
  createPromoCodes?:  {
    __typename: "PromoCodes",
    pk: string,
    id: string,
    promoImage?: string | null,
    promoURL?: string | null,
    code?: string | null,
    discount?:  {
      __typename: "discountType",
      type?: string | null,
      value?: string | null,
    } | null,
    redemptionType?: string | null,
    searchKey?: string | null,
    validFrom?: string | null,
    validTo?: string | null,
    description?: string | null,
    terms?: string | null,
    advancedSetting?:  {
      __typename: "AdvancedSettings",
      appliedTo?: string | null,
      specificType?:  Array< {
        __typename: "SpecificType",
        name?: string | null,
        id?: string | null,
      } | null > | null,
    } | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdatePromoCodesMutationVariables = {
  input: UpdatePromoCodesInput,
  condition?: ModelPromoCodesConditionInput | null,
};

export type UpdatePromoCodesMutation = {
  updatePromoCodes?:  {
    __typename: "PromoCodes",
    pk: string,
    id: string,
    promoImage?: string | null,
    promoURL?: string | null,
    code?: string | null,
    discount?:  {
      __typename: "discountType",
      type?: string | null,
      value?: string | null,
    } | null,
    redemptionType?: string | null,
    searchKey?: string | null,
    validFrom?: string | null,
    validTo?: string | null,
    description?: string | null,
    terms?: string | null,
    advancedSetting?:  {
      __typename: "AdvancedSettings",
      appliedTo?: string | null,
      specificType?:  Array< {
        __typename: "SpecificType",
        name?: string | null,
        id?: string | null,
      } | null > | null,
    } | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeletePromoCodesMutationVariables = {
  input: DeletePromoCodesInput,
  condition?: ModelPromoCodesConditionInput | null,
};

export type DeletePromoCodesMutation = {
  deletePromoCodes?:  {
    __typename: "PromoCodes",
    pk: string,
    id: string,
    promoImage?: string | null,
    promoURL?: string | null,
    code?: string | null,
    discount?:  {
      __typename: "discountType",
      type?: string | null,
      value?: string | null,
    } | null,
    redemptionType?: string | null,
    searchKey?: string | null,
    validFrom?: string | null,
    validTo?: string | null,
    description?: string | null,
    terms?: string | null,
    advancedSetting?:  {
      __typename: "AdvancedSettings",
      appliedTo?: string | null,
      specificType?:  Array< {
        __typename: "SpecificType",
        name?: string | null,
        id?: string | null,
      } | null > | null,
    } | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateJwtTokenIdentifierMutationVariables = {
  input: CreateJwtTokenIdentifierInput,
  condition?: ModelJwtTokenIdentifierConditionInput | null,
};

export type CreateJwtTokenIdentifierMutation = {
  createJwtTokenIdentifier?:  {
    __typename: "jwtTokenIdentifier",
    pk: string,
    jtiValue: string,
    issueDate?: string | null,
    expiryDateTime?: string | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateJwtTokenIdentifierMutationVariables = {
  input: UpdateJwtTokenIdentifierInput,
  condition?: ModelJwtTokenIdentifierConditionInput | null,
};

export type UpdateJwtTokenIdentifierMutation = {
  updateJwtTokenIdentifier?:  {
    __typename: "jwtTokenIdentifier",
    pk: string,
    jtiValue: string,
    issueDate?: string | null,
    expiryDateTime?: string | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteJwtTokenIdentifierMutationVariables = {
  input: DeleteJwtTokenIdentifierInput,
  condition?: ModelJwtTokenIdentifierConditionInput | null,
};

export type DeleteJwtTokenIdentifierMutation = {
  deleteJwtTokenIdentifier?:  {
    __typename: "jwtTokenIdentifier",
    pk: string,
    jtiValue: string,
    issueDate?: string | null,
    expiryDateTime?: string | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateUnitsMutationVariables = {
  input: CreateUnitsInput,
  condition?: ModelUnitsConditionInput | null,
};

export type CreateUnitsMutation = {
  createUnits?:  {
    __typename: "Units",
    pk: string,
    id: string,
    value?: string | null,
    sequenceNumber?: number | null,
    searchKey?: string | null,
    type?: UnitType | null,
    status?: Status | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateUnitsMutationVariables = {
  input: UpdateUnitsInput,
  condition?: ModelUnitsConditionInput | null,
};

export type UpdateUnitsMutation = {
  updateUnits?:  {
    __typename: "Units",
    pk: string,
    id: string,
    value?: string | null,
    sequenceNumber?: number | null,
    searchKey?: string | null,
    type?: UnitType | null,
    status?: Status | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteUnitsMutationVariables = {
  input: DeleteUnitsInput,
  condition?: ModelUnitsConditionInput | null,
};

export type DeleteUnitsMutation = {
  deleteUnits?:  {
    __typename: "Units",
    pk: string,
    id: string,
    value?: string | null,
    sequenceNumber?: number | null,
    searchKey?: string | null,
    type?: UnitType | null,
    status?: Status | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateShippingMutationVariables = {
  input: CreateShippingInput,
  condition?: ModelShippingConditionInput | null,
};

export type CreateShippingMutation = {
  createShipping?:  {
    __typename: "Shipping",
    id: string,
    pk: string,
    city?: string | null,
    countryId?: string | null,
    countryName?: string | null,
    state?: string | null,
    deliveryCharge?: number | null,
    coverage?: Coverage | null,
    zipCodes?: Array< string | null > | null,
    latitude?: string | null,
    longitude?: string | null,
    radius?: string | null,
    searchKey?: string | null,
    status?: Status | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteShippingMutationVariables = {
  input: DeleteShippingInput,
  condition?: ModelShippingConditionInput | null,
};

export type DeleteShippingMutation = {
  deleteShipping?:  {
    __typename: "Shipping",
    id: string,
    pk: string,
    city?: string | null,
    countryId?: string | null,
    countryName?: string | null,
    state?: string | null,
    deliveryCharge?: number | null,
    coverage?: Coverage | null,
    zipCodes?: Array< string | null > | null,
    latitude?: string | null,
    longitude?: string | null,
    radius?: string | null,
    searchKey?: string | null,
    status?: Status | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateProductMutationVariables = {
  input: CreateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type CreateProductMutation = {
  createProduct?:  {
    __typename: "Product",
    createdAt: string,
    id: string,
    pk: string,
    globalPk: string,
    productName: string,
    mainCategory?: string | null,
    mainCategoryID?: string | null,
    subCategory?: string | null,
    subCategoryID?: string | null,
    brandName?: string | null,
    brandID?: string | null,
    countryName?: string | null,
    countryID?: string | null,
    productDescription?: string | null,
    productFeatures?: string | null,
    keyWord?: Array< string | null > | null,
    featuredImage?:  {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null,
    galleryImages?:  Array< {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null > | null,
    singleVariant?: boolean | null,
    attrName?: string | null,
    attrNameID?: string | null,
    attrValue?:  Array< {
      __typename: "AttributeValues",
      value?: string | null,
      attrValueID?: string | null,
    } | null > | null,
    productSpecification?:  Array< {
      __typename: "productSpecification",
      name?: string | null,
      value?: string | null,
    } | null > | null,
    seoDescription?: string | null,
    stockStatus?: stockStatus | null,
    searchKey?: string | null,
    status?: productStatus | null,
    scheduleDate?: string | null,
    taxDetail?:  Array< {
      __typename: "taxDetail",
      taxCharge?: number | null,
      taxID?: string | null,
      taxName?: string | null,
    } | null > | null,
    showWhenZeroStock?: boolean | null,
    allowPreOrder?: boolean | null,
    overallRating?: number | null,
    noOfRating?: number | null,
    leadTime?: string | null,
    totalStockQuantity?: number | null,
    totalReservedStock?: number | null,
    displaySalePrice?: number | null,
    displayRegularPrice?: number | null,
    thumbnailUrl?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateProductMutationVariables = {
  input: UpdateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type UpdateProductMutation = {
  updateProduct?:  {
    __typename: "Product",
    createdAt: string,
    id: string,
    pk: string,
    globalPk: string,
    productName: string,
    mainCategory?: string | null,
    mainCategoryID?: string | null,
    subCategory?: string | null,
    subCategoryID?: string | null,
    brandName?: string | null,
    brandID?: string | null,
    countryName?: string | null,
    countryID?: string | null,
    productDescription?: string | null,
    productFeatures?: string | null,
    keyWord?: Array< string | null > | null,
    featuredImage?:  {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null,
    galleryImages?:  Array< {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null > | null,
    singleVariant?: boolean | null,
    attrName?: string | null,
    attrNameID?: string | null,
    attrValue?:  Array< {
      __typename: "AttributeValues",
      value?: string | null,
      attrValueID?: string | null,
    } | null > | null,
    productSpecification?:  Array< {
      __typename: "productSpecification",
      name?: string | null,
      value?: string | null,
    } | null > | null,
    seoDescription?: string | null,
    stockStatus?: stockStatus | null,
    searchKey?: string | null,
    status?: productStatus | null,
    scheduleDate?: string | null,
    taxDetail?:  Array< {
      __typename: "taxDetail",
      taxCharge?: number | null,
      taxID?: string | null,
      taxName?: string | null,
    } | null > | null,
    showWhenZeroStock?: boolean | null,
    allowPreOrder?: boolean | null,
    overallRating?: number | null,
    noOfRating?: number | null,
    leadTime?: string | null,
    totalStockQuantity?: number | null,
    totalReservedStock?: number | null,
    displaySalePrice?: number | null,
    displayRegularPrice?: number | null,
    thumbnailUrl?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteProductMutationVariables = {
  input: DeleteProductInput,
  condition?: ModelProductConditionInput | null,
};

export type DeleteProductMutation = {
  deleteProduct?:  {
    __typename: "Product",
    createdAt: string,
    id: string,
    pk: string,
    globalPk: string,
    productName: string,
    mainCategory?: string | null,
    mainCategoryID?: string | null,
    subCategory?: string | null,
    subCategoryID?: string | null,
    brandName?: string | null,
    brandID?: string | null,
    countryName?: string | null,
    countryID?: string | null,
    productDescription?: string | null,
    productFeatures?: string | null,
    keyWord?: Array< string | null > | null,
    featuredImage?:  {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null,
    galleryImages?:  Array< {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null > | null,
    singleVariant?: boolean | null,
    attrName?: string | null,
    attrNameID?: string | null,
    attrValue?:  Array< {
      __typename: "AttributeValues",
      value?: string | null,
      attrValueID?: string | null,
    } | null > | null,
    productSpecification?:  Array< {
      __typename: "productSpecification",
      name?: string | null,
      value?: string | null,
    } | null > | null,
    seoDescription?: string | null,
    stockStatus?: stockStatus | null,
    searchKey?: string | null,
    status?: productStatus | null,
    scheduleDate?: string | null,
    taxDetail?:  Array< {
      __typename: "taxDetail",
      taxCharge?: number | null,
      taxID?: string | null,
      taxName?: string | null,
    } | null > | null,
    showWhenZeroStock?: boolean | null,
    allowPreOrder?: boolean | null,
    overallRating?: number | null,
    noOfRating?: number | null,
    leadTime?: string | null,
    totalStockQuantity?: number | null,
    totalReservedStock?: number | null,
    displaySalePrice?: number | null,
    displayRegularPrice?: number | null,
    thumbnailUrl?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateProductImageMutationVariables = {
  input: CreateProductImageInput,
  condition?: ModelProductImageConditionInput | null,
};

export type CreateProductImageMutation = {
  createProductImage?:  {
    __typename: "ProductImage",
    pk: string,
    id: string,
    alterText?: string | null,
    title?: string | null,
    url?: string | null,
    description?: string | null,
    mainCategory?: string | null,
    mainCategoryID?: string | null,
    subCategory?: string | null,
    subCategoryID?: string | null,
    thumbnailUrl?: string | null,
    type?: ImageType | null,
    productID?: string | null,
    isDeleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateProductImageMutationVariables = {
  input: UpdateProductImageInput,
  condition?: ModelProductImageConditionInput | null,
};

export type UpdateProductImageMutation = {
  updateProductImage?:  {
    __typename: "ProductImage",
    pk: string,
    id: string,
    alterText?: string | null,
    title?: string | null,
    url?: string | null,
    description?: string | null,
    mainCategory?: string | null,
    mainCategoryID?: string | null,
    subCategory?: string | null,
    subCategoryID?: string | null,
    thumbnailUrl?: string | null,
    type?: ImageType | null,
    productID?: string | null,
    isDeleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteProductImageMutationVariables = {
  input: DeleteProductImageInput,
  condition?: ModelProductImageConditionInput | null,
};

export type DeleteProductImageMutation = {
  deleteProductImage?:  {
    __typename: "ProductImage",
    pk: string,
    id: string,
    alterText?: string | null,
    title?: string | null,
    url?: string | null,
    description?: string | null,
    mainCategory?: string | null,
    mainCategoryID?: string | null,
    subCategory?: string | null,
    subCategoryID?: string | null,
    thumbnailUrl?: string | null,
    type?: ImageType | null,
    productID?: string | null,
    isDeleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateProductDetailsMutationVariables = {
  input: CreateProductDetailsInput,
  condition?: ModelProductDetailsConditionInput | null,
};

export type CreateProductDetailsMutation = {
  createProductDetails?:  {
    __typename: "ProductDetails",
    id: string,
    pk: string,
    productID?: string | null,
    attrName?: string | null,
    attrNameID?: string | null,
    attrValue?: string | null,
    attrValueID?: string | null,
    featuredImage?:  {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null,
    model?: string | null,
    manufacturePartCode?: string | null,
    sku?: string | null,
    stockStatus?: stockStatus | null,
    stockQuantity?: number | null,
    reservedStock?: number | null,
    stockUnit?: string | null,
    itemsPerUnit?: number | null,
    regularPrice?: number | null,
    salePrice?: number | null,
    dataSheet?: string | null,
    submittal?: string | null,
    catalog?: string | null,
    iom?: string | null,
    storageInfo?:  {
      __typename: "storageInfoDetail",
      wareHouseId?: string | null,
      wareHouse?: string | null,
      rackNo?: string | null,
      rowNo?: string | null,
      binNo?: string | null,
      note?: string | null,
    } | null,
    shipplingInfo?:  {
      __typename: "shippingDetails",
      weightValue?: string | null,
      weightUnit?: string | null,
      lengthValue?: string | null,
      lengthUnit?: string | null,
      heightValue?: string | null,
      heightUnit?: string | null,
      widthValue?: string | null,
      widthUnit?: string | null,
    } | null,
    partialReturn?: boolean | null,
    type?: VariantType | null,
    isDeleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateProductDetailsMutationVariables = {
  input: UpdateProductDetailsInput,
  condition?: ModelProductDetailsConditionInput | null,
};

export type UpdateProductDetailsMutation = {
  updateProductDetails?:  {
    __typename: "ProductDetails",
    id: string,
    pk: string,
    productID?: string | null,
    attrName?: string | null,
    attrNameID?: string | null,
    attrValue?: string | null,
    attrValueID?: string | null,
    featuredImage?:  {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null,
    model?: string | null,
    manufacturePartCode?: string | null,
    sku?: string | null,
    stockStatus?: stockStatus | null,
    stockQuantity?: number | null,
    reservedStock?: number | null,
    stockUnit?: string | null,
    itemsPerUnit?: number | null,
    regularPrice?: number | null,
    salePrice?: number | null,
    dataSheet?: string | null,
    submittal?: string | null,
    catalog?: string | null,
    iom?: string | null,
    storageInfo?:  {
      __typename: "storageInfoDetail",
      wareHouseId?: string | null,
      wareHouse?: string | null,
      rackNo?: string | null,
      rowNo?: string | null,
      binNo?: string | null,
      note?: string | null,
    } | null,
    shipplingInfo?:  {
      __typename: "shippingDetails",
      weightValue?: string | null,
      weightUnit?: string | null,
      lengthValue?: string | null,
      lengthUnit?: string | null,
      heightValue?: string | null,
      heightUnit?: string | null,
      widthValue?: string | null,
      widthUnit?: string | null,
    } | null,
    partialReturn?: boolean | null,
    type?: VariantType | null,
    isDeleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteProductDetailsMutationVariables = {
  input: DeleteProductDetailsInput,
  condition?: ModelProductDetailsConditionInput | null,
};

export type DeleteProductDetailsMutation = {
  deleteProductDetails?:  {
    __typename: "ProductDetails",
    id: string,
    pk: string,
    productID?: string | null,
    attrName?: string | null,
    attrNameID?: string | null,
    attrValue?: string | null,
    attrValueID?: string | null,
    featuredImage?:  {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null,
    model?: string | null,
    manufacturePartCode?: string | null,
    sku?: string | null,
    stockStatus?: stockStatus | null,
    stockQuantity?: number | null,
    reservedStock?: number | null,
    stockUnit?: string | null,
    itemsPerUnit?: number | null,
    regularPrice?: number | null,
    salePrice?: number | null,
    dataSheet?: string | null,
    submittal?: string | null,
    catalog?: string | null,
    iom?: string | null,
    storageInfo?:  {
      __typename: "storageInfoDetail",
      wareHouseId?: string | null,
      wareHouse?: string | null,
      rackNo?: string | null,
      rowNo?: string | null,
      binNo?: string | null,
      note?: string | null,
    } | null,
    shipplingInfo?:  {
      __typename: "shippingDetails",
      weightValue?: string | null,
      weightUnit?: string | null,
      lengthValue?: string | null,
      lengthUnit?: string | null,
      heightValue?: string | null,
      heightUnit?: string | null,
      widthValue?: string | null,
      widthUnit?: string | null,
    } | null,
    partialReturn?: boolean | null,
    type?: VariantType | null,
    isDeleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateOrderMutationVariables = {
  input: CreateOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type CreateOrderMutation = {
  createOrder?:  {
    __typename: "Order",
    pk: string,
    id: string,
    userId: string,
    createdAt: string,
    userName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    orderType?: string | null,
    totalProducts?: number | null,
    orderSummary?:  {
      __typename: "OrderSummary",
      cartTotal?: number | null,
      productDiscount?: string | null,
      deliveryFee?: number | null,
      tax?: number | null,
    } | null,
    totalAmount?: number | null,
    paymentDetails?:  {
      __typename: "PaymentDetails",
      paymentMode?: string | null,
      paymentMethod?: PaymentMethod | null,
      cardType?: string | null,
      cardHolderName?: string | null,
      cardNumber?: string | null,
      CardExpiryDate?: string | null,
      UPI?: boolean | null,
      paymentDate?: string | null,
      referenceNumber?: string | null,
      chequeNumber?: string | null,
      bankName?: string | null,
      chequeDate?: string | null,
      receivedAmount?: number | null,
      remaningAmount?: number | null,
      chequeStatus?: ChequeStatus | null,
      partialPaymentDetails?:  Array< {
        __typename: "CashOnDeliveryCollection",
        chequeNumber?: string | null,
        bankName?: string | null,
        chequeDate?: string | null,
        receivedAmount?: number | null,
        chequeStatus?: ChequeStatus | null,
        paymentDate?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null > | null,
    } | null,
    paymentStatus?: PaymentStatus | null,
    status?: OrderStatus | null,
    wishListId?: string | null,
    couponDetails?:  {
      __typename: "CouponDetails",
      couponCode?: string | null,
      amount?: number | null,
      discount?:  {
        __typename: "discountType",
        type?: string | null,
        value?: string | null,
      } | null,
      advancedSetting?:  {
        __typename: "AdvancedSettings",
        appliedTo?: string | null,
        specificType?:  Array< {
          __typename: "SpecificType",
          name?: string | null,
          id?: string | null,
        } | null > | null,
      } | null,
    } | null,
    discountByCoupon?: number | null,
    shippingUserDetails?:  {
      __typename: "UserDetails",
      userName?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
    } | null,
    shippingAddress?:  {
      __typename: "Address",
      addressLine1?: string | null,
      addressLine2?: string | null,
      street?: string | null,
      landmark?: string | null,
      state?: string | null,
      city?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    deliveryInfo?:  {
      __typename: "DeliveryInfo",
      deliveryPartner?: string | null,
      deliveryType?: string | null,
      productWeight?: string | null,
    } | null,
    billingUserDetails?:  {
      __typename: "UserDetails",
      userName?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
    } | null,
    billingAddress?:  {
      __typename: "Address",
      addressLine1?: string | null,
      addressLine2?: string | null,
      street?: string | null,
      landmark?: string | null,
      state?: string | null,
      city?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    deliveryDate?: string | null,
    invoiceNumber?: string | null,
    invoiceURL?: string | null,
    orderPdf?: string | null,
    searchKey?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateOrderMutationVariables = {
  input: UpdateOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type UpdateOrderMutation = {
  updateOrder?:  {
    __typename: "Order",
    pk: string,
    id: string,
    userId: string,
    createdAt: string,
    userName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    orderType?: string | null,
    totalProducts?: number | null,
    orderSummary?:  {
      __typename: "OrderSummary",
      cartTotal?: number | null,
      productDiscount?: string | null,
      deliveryFee?: number | null,
      tax?: number | null,
    } | null,
    totalAmount?: number | null,
    paymentDetails?:  {
      __typename: "PaymentDetails",
      paymentMode?: string | null,
      paymentMethod?: PaymentMethod | null,
      cardType?: string | null,
      cardHolderName?: string | null,
      cardNumber?: string | null,
      CardExpiryDate?: string | null,
      UPI?: boolean | null,
      paymentDate?: string | null,
      referenceNumber?: string | null,
      chequeNumber?: string | null,
      bankName?: string | null,
      chequeDate?: string | null,
      receivedAmount?: number | null,
      remaningAmount?: number | null,
      chequeStatus?: ChequeStatus | null,
      partialPaymentDetails?:  Array< {
        __typename: "CashOnDeliveryCollection",
        chequeNumber?: string | null,
        bankName?: string | null,
        chequeDate?: string | null,
        receivedAmount?: number | null,
        chequeStatus?: ChequeStatus | null,
        paymentDate?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null > | null,
    } | null,
    paymentStatus?: PaymentStatus | null,
    status?: OrderStatus | null,
    wishListId?: string | null,
    couponDetails?:  {
      __typename: "CouponDetails",
      couponCode?: string | null,
      amount?: number | null,
      discount?:  {
        __typename: "discountType",
        type?: string | null,
        value?: string | null,
      } | null,
      advancedSetting?:  {
        __typename: "AdvancedSettings",
        appliedTo?: string | null,
        specificType?:  Array< {
          __typename: "SpecificType",
          name?: string | null,
          id?: string | null,
        } | null > | null,
      } | null,
    } | null,
    discountByCoupon?: number | null,
    shippingUserDetails?:  {
      __typename: "UserDetails",
      userName?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
    } | null,
    shippingAddress?:  {
      __typename: "Address",
      addressLine1?: string | null,
      addressLine2?: string | null,
      street?: string | null,
      landmark?: string | null,
      state?: string | null,
      city?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    deliveryInfo?:  {
      __typename: "DeliveryInfo",
      deliveryPartner?: string | null,
      deliveryType?: string | null,
      productWeight?: string | null,
    } | null,
    billingUserDetails?:  {
      __typename: "UserDetails",
      userName?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
    } | null,
    billingAddress?:  {
      __typename: "Address",
      addressLine1?: string | null,
      addressLine2?: string | null,
      street?: string | null,
      landmark?: string | null,
      state?: string | null,
      city?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    deliveryDate?: string | null,
    invoiceNumber?: string | null,
    invoiceURL?: string | null,
    orderPdf?: string | null,
    searchKey?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteOrderMutationVariables = {
  input: DeleteOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type DeleteOrderMutation = {
  deleteOrder?:  {
    __typename: "Order",
    pk: string,
    id: string,
    userId: string,
    createdAt: string,
    userName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    orderType?: string | null,
    totalProducts?: number | null,
    orderSummary?:  {
      __typename: "OrderSummary",
      cartTotal?: number | null,
      productDiscount?: string | null,
      deliveryFee?: number | null,
      tax?: number | null,
    } | null,
    totalAmount?: number | null,
    paymentDetails?:  {
      __typename: "PaymentDetails",
      paymentMode?: string | null,
      paymentMethod?: PaymentMethod | null,
      cardType?: string | null,
      cardHolderName?: string | null,
      cardNumber?: string | null,
      CardExpiryDate?: string | null,
      UPI?: boolean | null,
      paymentDate?: string | null,
      referenceNumber?: string | null,
      chequeNumber?: string | null,
      bankName?: string | null,
      chequeDate?: string | null,
      receivedAmount?: number | null,
      remaningAmount?: number | null,
      chequeStatus?: ChequeStatus | null,
      partialPaymentDetails?:  Array< {
        __typename: "CashOnDeliveryCollection",
        chequeNumber?: string | null,
        bankName?: string | null,
        chequeDate?: string | null,
        receivedAmount?: number | null,
        chequeStatus?: ChequeStatus | null,
        paymentDate?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null > | null,
    } | null,
    paymentStatus?: PaymentStatus | null,
    status?: OrderStatus | null,
    wishListId?: string | null,
    couponDetails?:  {
      __typename: "CouponDetails",
      couponCode?: string | null,
      amount?: number | null,
      discount?:  {
        __typename: "discountType",
        type?: string | null,
        value?: string | null,
      } | null,
      advancedSetting?:  {
        __typename: "AdvancedSettings",
        appliedTo?: string | null,
        specificType?:  Array< {
          __typename: "SpecificType",
          name?: string | null,
          id?: string | null,
        } | null > | null,
      } | null,
    } | null,
    discountByCoupon?: number | null,
    shippingUserDetails?:  {
      __typename: "UserDetails",
      userName?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
    } | null,
    shippingAddress?:  {
      __typename: "Address",
      addressLine1?: string | null,
      addressLine2?: string | null,
      street?: string | null,
      landmark?: string | null,
      state?: string | null,
      city?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    deliveryInfo?:  {
      __typename: "DeliveryInfo",
      deliveryPartner?: string | null,
      deliveryType?: string | null,
      productWeight?: string | null,
    } | null,
    billingUserDetails?:  {
      __typename: "UserDetails",
      userName?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
    } | null,
    billingAddress?:  {
      __typename: "Address",
      addressLine1?: string | null,
      addressLine2?: string | null,
      street?: string | null,
      landmark?: string | null,
      state?: string | null,
      city?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    deliveryDate?: string | null,
    invoiceNumber?: string | null,
    invoiceURL?: string | null,
    orderPdf?: string | null,
    searchKey?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateOrderItemMutationVariables = {
  input: CreateOrderItemInput,
  condition?: ModelOrderItemConditionInput | null,
};

export type CreateOrderItemMutation = {
  createOrderItem?:  {
    __typename: "OrderItem",
    pk: string,
    id: string,
    productId?: string | null,
    mainCategoryId?: string | null,
    mainCategory?: string | null,
    subCategoryId?: string | null,
    subCategory?: string | null,
    userId: string,
    wareHouseId?: string | null,
    attrValueID?: string | null,
    wareHouseName?: string | null,
    userName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    createdAt: string,
    quantity?: number | null,
    stockUnit?: string | null,
    itemsPerUnit?: number | null,
    taxCode?: string | null,
    taxAmount?: number | null,
    taxDetail?:  Array< {
      __typename: "taxDetail",
      taxCharge?: number | null,
      taxID?: string | null,
      taxName?: string | null,
    } | null > | null,
    regularPrice?: number | null,
    totalPrice?: number | null,
    itemDetail?:  {
      __typename: "ItemDetail",
      productName?: string | null,
      price?: number | null,
      image?: string | null,
      brand?: string | null,
      model?: string | null,
      tax?: number | null,
      currentStock?: string | null,
      sku?: string | null,
      qrcode?: string | null,
    } | null,
    sku?: string | null,
    attrName?: string | null,
    attrValue?: string | null,
    deliveryStatus?: ProductDeliveryStatus | null,
    deliveryBy?: string | null,
    deliveryTracking?:  Array< {
      __typename: "DeliveryTracking",
      status?: ProductDeliveryStatus | null,
      date?: string | null,
      trackingBy?: string | null,
      phoneNumber?: string | null,
      remarks?: string | null,
      receipt?: string | null,
    } | null > | null,
    deliveryPartner?: string | null,
    outForDeliveryDate?: string | null,
    outForDeliveryTime?: string | null,
    expectedDeliveryDate?: string | null,
    trackingID?: string | null,
    trackingUrl?: string | null,
    deliveryPersonInfo?:  {
      __typename: "DeliveryPersonInfo",
      name?: string | null,
      contactNumber?: string | null,
      vehiclenumber?: string | null,
      notes?: string | null,
    } | null,
    orderedOn?: string | null,
    returnRefundActivity?:  {
      __typename: "ReturnRefundActivity",
      returnReference?: string | null,
      reason?: string | null,
      comment?: string | null,
      quantity?: string | null,
      subTotal?: number | null,
      total?: number | null,
      attachments?: Array< string | null > | null,
      paymentMode?: string | null,
      paymentMethod?: PaymentMethod | null,
      returnType?: ReturnType | null,
      returnAddress?:  {
        __typename: "Address",
        addressLine1?: string | null,
        addressLine2?: string | null,
        street?: string | null,
        landmark?: string | null,
        state?: string | null,
        city?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      pickUpDetails?:  {
        __typename: "PickUpDetails",
        pickUpAddress?:  {
          __typename: "Address",
          addressLine1?: string | null,
          addressLine2?: string | null,
          street?: string | null,
          landmark?: string | null,
          state?: string | null,
          city?: string | null,
          postalCode?: string | null,
          country?: string | null,
        } | null,
        pickUpBy?: string | null,
        deliveryPartner?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        expectedPickUpDate?: string | null,
        time?: string | null,
      } | null,
      receivedInfo?:  {
        __typename: "ReceivedInfo",
        receivedOn?: string | null,
        receivedAs?: string | null,
        images?: Array< string | null > | null,
      } | null,
      updateInStock?: boolean | null,
      updateInStockQty?: string | null,
      refundInfo?:  {
        __typename: "RefundInfo",
        date?: string | null,
        paymentMethod?: PaymentMethod | null,
        refundAmount?: number | null,
        refundInfo?: string | null,
        proofImage?: string | null,
        proofImageUrl?: string | null,
        rejectedReason?: string | null,
      } | null,
      status?: ReturnActivityStatus | null,
      returnStatusUpdate?:  Array< {
        __typename: "ReturnUpdates",
        returnStatus?: ReturnActivityStatus | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null > | null,
      rejectReason?: string | null,
      createdAt?: string | null,
    } | null,
    eta?: string | null,
    paymentStatus?: PaymentStatus | null,
    billOfLading?: string | null,
    packingList?: string | null,
    searchKey?: string | null,
    shippingInfo?:  {
      __typename: "shippingDetails",
      weightValue?: string | null,
      weightUnit?: string | null,
      lengthValue?: string | null,
      lengthUnit?: string | null,
      heightValue?: string | null,
      heightUnit?: string | null,
      widthValue?: string | null,
      widthUnit?: string | null,
    } | null,
    returnAvailable?: boolean | null,
    disputeResolved?: boolean | null,
    brandId?: string | null,
    returnValue?: number | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateOrderItemMutationVariables = {
  input: UpdateOrderItemInput,
  condition?: ModelOrderItemConditionInput | null,
};

export type UpdateOrderItemMutation = {
  updateOrderItem?:  {
    __typename: "OrderItem",
    pk: string,
    id: string,
    productId?: string | null,
    mainCategoryId?: string | null,
    mainCategory?: string | null,
    subCategoryId?: string | null,
    subCategory?: string | null,
    userId: string,
    wareHouseId?: string | null,
    attrValueID?: string | null,
    wareHouseName?: string | null,
    userName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    createdAt: string,
    quantity?: number | null,
    stockUnit?: string | null,
    itemsPerUnit?: number | null,
    taxCode?: string | null,
    taxAmount?: number | null,
    taxDetail?:  Array< {
      __typename: "taxDetail",
      taxCharge?: number | null,
      taxID?: string | null,
      taxName?: string | null,
    } | null > | null,
    regularPrice?: number | null,
    totalPrice?: number | null,
    itemDetail?:  {
      __typename: "ItemDetail",
      productName?: string | null,
      price?: number | null,
      image?: string | null,
      brand?: string | null,
      model?: string | null,
      tax?: number | null,
      currentStock?: string | null,
      sku?: string | null,
      qrcode?: string | null,
    } | null,
    sku?: string | null,
    attrName?: string | null,
    attrValue?: string | null,
    deliveryStatus?: ProductDeliveryStatus | null,
    deliveryBy?: string | null,
    deliveryTracking?:  Array< {
      __typename: "DeliveryTracking",
      status?: ProductDeliveryStatus | null,
      date?: string | null,
      trackingBy?: string | null,
      phoneNumber?: string | null,
      remarks?: string | null,
      receipt?: string | null,
    } | null > | null,
    deliveryPartner?: string | null,
    outForDeliveryDate?: string | null,
    outForDeliveryTime?: string | null,
    expectedDeliveryDate?: string | null,
    trackingID?: string | null,
    trackingUrl?: string | null,
    deliveryPersonInfo?:  {
      __typename: "DeliveryPersonInfo",
      name?: string | null,
      contactNumber?: string | null,
      vehiclenumber?: string | null,
      notes?: string | null,
    } | null,
    orderedOn?: string | null,
    returnRefundActivity?:  {
      __typename: "ReturnRefundActivity",
      returnReference?: string | null,
      reason?: string | null,
      comment?: string | null,
      quantity?: string | null,
      subTotal?: number | null,
      total?: number | null,
      attachments?: Array< string | null > | null,
      paymentMode?: string | null,
      paymentMethod?: PaymentMethod | null,
      returnType?: ReturnType | null,
      returnAddress?:  {
        __typename: "Address",
        addressLine1?: string | null,
        addressLine2?: string | null,
        street?: string | null,
        landmark?: string | null,
        state?: string | null,
        city?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      pickUpDetails?:  {
        __typename: "PickUpDetails",
        pickUpAddress?:  {
          __typename: "Address",
          addressLine1?: string | null,
          addressLine2?: string | null,
          street?: string | null,
          landmark?: string | null,
          state?: string | null,
          city?: string | null,
          postalCode?: string | null,
          country?: string | null,
        } | null,
        pickUpBy?: string | null,
        deliveryPartner?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        expectedPickUpDate?: string | null,
        time?: string | null,
      } | null,
      receivedInfo?:  {
        __typename: "ReceivedInfo",
        receivedOn?: string | null,
        receivedAs?: string | null,
        images?: Array< string | null > | null,
      } | null,
      updateInStock?: boolean | null,
      updateInStockQty?: string | null,
      refundInfo?:  {
        __typename: "RefundInfo",
        date?: string | null,
        paymentMethod?: PaymentMethod | null,
        refundAmount?: number | null,
        refundInfo?: string | null,
        proofImage?: string | null,
        proofImageUrl?: string | null,
        rejectedReason?: string | null,
      } | null,
      status?: ReturnActivityStatus | null,
      returnStatusUpdate?:  Array< {
        __typename: "ReturnUpdates",
        returnStatus?: ReturnActivityStatus | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null > | null,
      rejectReason?: string | null,
      createdAt?: string | null,
    } | null,
    eta?: string | null,
    paymentStatus?: PaymentStatus | null,
    billOfLading?: string | null,
    packingList?: string | null,
    searchKey?: string | null,
    shippingInfo?:  {
      __typename: "shippingDetails",
      weightValue?: string | null,
      weightUnit?: string | null,
      lengthValue?: string | null,
      lengthUnit?: string | null,
      heightValue?: string | null,
      heightUnit?: string | null,
      widthValue?: string | null,
      widthUnit?: string | null,
    } | null,
    returnAvailable?: boolean | null,
    disputeResolved?: boolean | null,
    brandId?: string | null,
    returnValue?: number | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteOrderItemMutationVariables = {
  input: DeleteOrderItemInput,
  condition?: ModelOrderItemConditionInput | null,
};

export type DeleteOrderItemMutation = {
  deleteOrderItem?:  {
    __typename: "OrderItem",
    pk: string,
    id: string,
    productId?: string | null,
    mainCategoryId?: string | null,
    mainCategory?: string | null,
    subCategoryId?: string | null,
    subCategory?: string | null,
    userId: string,
    wareHouseId?: string | null,
    attrValueID?: string | null,
    wareHouseName?: string | null,
    userName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    createdAt: string,
    quantity?: number | null,
    stockUnit?: string | null,
    itemsPerUnit?: number | null,
    taxCode?: string | null,
    taxAmount?: number | null,
    taxDetail?:  Array< {
      __typename: "taxDetail",
      taxCharge?: number | null,
      taxID?: string | null,
      taxName?: string | null,
    } | null > | null,
    regularPrice?: number | null,
    totalPrice?: number | null,
    itemDetail?:  {
      __typename: "ItemDetail",
      productName?: string | null,
      price?: number | null,
      image?: string | null,
      brand?: string | null,
      model?: string | null,
      tax?: number | null,
      currentStock?: string | null,
      sku?: string | null,
      qrcode?: string | null,
    } | null,
    sku?: string | null,
    attrName?: string | null,
    attrValue?: string | null,
    deliveryStatus?: ProductDeliveryStatus | null,
    deliveryBy?: string | null,
    deliveryTracking?:  Array< {
      __typename: "DeliveryTracking",
      status?: ProductDeliveryStatus | null,
      date?: string | null,
      trackingBy?: string | null,
      phoneNumber?: string | null,
      remarks?: string | null,
      receipt?: string | null,
    } | null > | null,
    deliveryPartner?: string | null,
    outForDeliveryDate?: string | null,
    outForDeliveryTime?: string | null,
    expectedDeliveryDate?: string | null,
    trackingID?: string | null,
    trackingUrl?: string | null,
    deliveryPersonInfo?:  {
      __typename: "DeliveryPersonInfo",
      name?: string | null,
      contactNumber?: string | null,
      vehiclenumber?: string | null,
      notes?: string | null,
    } | null,
    orderedOn?: string | null,
    returnRefundActivity?:  {
      __typename: "ReturnRefundActivity",
      returnReference?: string | null,
      reason?: string | null,
      comment?: string | null,
      quantity?: string | null,
      subTotal?: number | null,
      total?: number | null,
      attachments?: Array< string | null > | null,
      paymentMode?: string | null,
      paymentMethod?: PaymentMethod | null,
      returnType?: ReturnType | null,
      returnAddress?:  {
        __typename: "Address",
        addressLine1?: string | null,
        addressLine2?: string | null,
        street?: string | null,
        landmark?: string | null,
        state?: string | null,
        city?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      pickUpDetails?:  {
        __typename: "PickUpDetails",
        pickUpAddress?:  {
          __typename: "Address",
          addressLine1?: string | null,
          addressLine2?: string | null,
          street?: string | null,
          landmark?: string | null,
          state?: string | null,
          city?: string | null,
          postalCode?: string | null,
          country?: string | null,
        } | null,
        pickUpBy?: string | null,
        deliveryPartner?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        expectedPickUpDate?: string | null,
        time?: string | null,
      } | null,
      receivedInfo?:  {
        __typename: "ReceivedInfo",
        receivedOn?: string | null,
        receivedAs?: string | null,
        images?: Array< string | null > | null,
      } | null,
      updateInStock?: boolean | null,
      updateInStockQty?: string | null,
      refundInfo?:  {
        __typename: "RefundInfo",
        date?: string | null,
        paymentMethod?: PaymentMethod | null,
        refundAmount?: number | null,
        refundInfo?: string | null,
        proofImage?: string | null,
        proofImageUrl?: string | null,
        rejectedReason?: string | null,
      } | null,
      status?: ReturnActivityStatus | null,
      returnStatusUpdate?:  Array< {
        __typename: "ReturnUpdates",
        returnStatus?: ReturnActivityStatus | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null > | null,
      rejectReason?: string | null,
      createdAt?: string | null,
    } | null,
    eta?: string | null,
    paymentStatus?: PaymentStatus | null,
    billOfLading?: string | null,
    packingList?: string | null,
    searchKey?: string | null,
    shippingInfo?:  {
      __typename: "shippingDetails",
      weightValue?: string | null,
      weightUnit?: string | null,
      lengthValue?: string | null,
      lengthUnit?: string | null,
      heightValue?: string | null,
      heightUnit?: string | null,
      widthValue?: string | null,
      widthUnit?: string | null,
    } | null,
    returnAvailable?: boolean | null,
    disputeResolved?: boolean | null,
    brandId?: string | null,
    returnValue?: number | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateReportMasterMutationVariables = {
  input: CreateReportMasterInput,
  condition?: ModelReportMasterConditionInput | null,
};

export type CreateReportMasterMutation = {
  createReportMaster?:  {
    __typename: "ReportMaster",
    pk: string,
    id: string,
    reportName?: string | null,
    description?: string | null,
    sequenceNumber?: number | null,
    searchKey?: string | null,
    status?: Status | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateReportMasterMutationVariables = {
  input: UpdateReportMasterInput,
  condition?: ModelReportMasterConditionInput | null,
};

export type UpdateReportMasterMutation = {
  updateReportMaster?:  {
    __typename: "ReportMaster",
    pk: string,
    id: string,
    reportName?: string | null,
    description?: string | null,
    sequenceNumber?: number | null,
    searchKey?: string | null,
    status?: Status | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteReportMasterMutationVariables = {
  input: DeleteReportMasterInput,
  condition?: ModelReportMasterConditionInput | null,
};

export type DeleteReportMasterMutation = {
  deleteReportMaster?:  {
    __typename: "ReportMaster",
    pk: string,
    id: string,
    reportName?: string | null,
    description?: string | null,
    sequenceNumber?: number | null,
    searchKey?: string | null,
    status?: Status | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    pk: string,
    userName?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    companyName?: string | null,
    profileImageUrl?: string | null,
    email: string,
    phoneNumber?: string | null,
    role?: UserRole | null,
    password?: string | null,
    multiFactorAuth?: boolean | null,
    emailNotification?: boolean | null,
    mandatePasswordChange?: boolean | null,
    emailVerified?: boolean | null,
    otp?: string | null,
    status?: Status | null,
    searchKey?: string | null,
    addressDetails?:  Array< {
      __typename: "AddressDetails",
      name?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      address?:  {
        __typename: "Address",
        addressLine1?: string | null,
        addressLine2?: string | null,
        street?: string | null,
        landmark?: string | null,
        state?: string | null,
        city?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      type?: string | null,
      addressName?: string | null,
      default?: boolean | null,
      latitude?: string | null,
      longitude?: string | null,
    } | null > | null,
    loginAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    platform?: Platform | null,
    owner?: string | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    pk: string,
    userName?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    companyName?: string | null,
    profileImageUrl?: string | null,
    email: string,
    phoneNumber?: string | null,
    role?: UserRole | null,
    password?: string | null,
    multiFactorAuth?: boolean | null,
    emailNotification?: boolean | null,
    mandatePasswordChange?: boolean | null,
    emailVerified?: boolean | null,
    otp?: string | null,
    status?: Status | null,
    searchKey?: string | null,
    addressDetails?:  Array< {
      __typename: "AddressDetails",
      name?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      address?:  {
        __typename: "Address",
        addressLine1?: string | null,
        addressLine2?: string | null,
        street?: string | null,
        landmark?: string | null,
        state?: string | null,
        city?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      type?: string | null,
      addressName?: string | null,
      default?: boolean | null,
      latitude?: string | null,
      longitude?: string | null,
    } | null > | null,
    loginAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    platform?: Platform | null,
    owner?: string | null,
  } | null,
};

export type CreateGeneralContactUsMutationVariables = {
  input: CreateGeneralContactUsInput,
  condition?: ModelGeneralContactUsConditionInput | null,
};

export type CreateGeneralContactUsMutation = {
  createGeneralContactUs?:  {
    __typename: "GeneralContactUs",
    pk: string,
    id: string,
    referenceNumber?: string | null,
    userName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    companyName?: string | null,
    subject?: string | null,
    message?: string | null,
    replyMessage?: string | null,
    enquiryStatus?: EnquiryStatus | null,
    attachments?: Array< string | null > | null,
    searchKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateGeneralContactUsMutationVariables = {
  input: UpdateGeneralContactUsInput,
  condition?: ModelGeneralContactUsConditionInput | null,
};

export type UpdateGeneralContactUsMutation = {
  updateGeneralContactUs?:  {
    __typename: "GeneralContactUs",
    pk: string,
    id: string,
    referenceNumber?: string | null,
    userName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    companyName?: string | null,
    subject?: string | null,
    message?: string | null,
    replyMessage?: string | null,
    enquiryStatus?: EnquiryStatus | null,
    attachments?: Array< string | null > | null,
    searchKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteGeneralContactUsMutationVariables = {
  input: DeleteGeneralContactUsInput,
  condition?: ModelGeneralContactUsConditionInput | null,
};

export type DeleteGeneralContactUsMutation = {
  deleteGeneralContactUs?:  {
    __typename: "GeneralContactUs",
    pk: string,
    id: string,
    referenceNumber?: string | null,
    userName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    companyName?: string | null,
    subject?: string | null,
    message?: string | null,
    replyMessage?: string | null,
    enquiryStatus?: EnquiryStatus | null,
    attachments?: Array< string | null > | null,
    searchKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateConfigurationMutationVariables = {
  input: CreateConfigurationInput,
  condition?: ModelConfigurationConditionInput | null,
};

export type CreateConfigurationMutation = {
  createConfiguration?:  {
    __typename: "configuration",
    pk: string,
    id: string,
    configurationDescription?: string | null,
    continuousSettings?:  {
      __typename: "ContinuousSettings",
      prefixValue?: string | null,
      numberOfDigits?: number | null,
      includeYearSuffix?: boolean | null,
      startingNumber?: number | null,
      runningNumber?: number | null,
    } | null,
    type?: string | null,
    modifiedTime?: string | null,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateConfigurationMutationVariables = {
  input: UpdateConfigurationInput,
  condition?: ModelConfigurationConditionInput | null,
};

export type UpdateConfigurationMutation = {
  updateConfiguration?:  {
    __typename: "configuration",
    pk: string,
    id: string,
    configurationDescription?: string | null,
    continuousSettings?:  {
      __typename: "ContinuousSettings",
      prefixValue?: string | null,
      numberOfDigits?: number | null,
      includeYearSuffix?: boolean | null,
      startingNumber?: number | null,
      runningNumber?: number | null,
    } | null,
    type?: string | null,
    modifiedTime?: string | null,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateProductVisitMutationVariables = {
  input: CreateProductVisitInput,
  condition?: ModelProductVisitConditionInput | null,
};

export type CreateProductVisitMutation = {
  createProductVisit?:  {
    __typename: "ProductVisit",
    pk: string,
    id: string,
    prodId?: string | null,
    userId?: string | null,
    userName?: string | null,
    visitedAt?: string | null,
    place?: string | null,
    visitedInfo?:  Array< {
      __typename: "VisitedInfo",
      platform?: string | null,
      location?: string | null,
      time?: string | null,
      ipAddress?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateProductVisitMutationVariables = {
  input: UpdateProductVisitInput,
  condition?: ModelProductVisitConditionInput | null,
};

export type UpdateProductVisitMutation = {
  updateProductVisit?:  {
    __typename: "ProductVisit",
    pk: string,
    id: string,
    prodId?: string | null,
    userId?: string | null,
    userName?: string | null,
    visitedAt?: string | null,
    place?: string | null,
    visitedInfo?:  Array< {
      __typename: "VisitedInfo",
      platform?: string | null,
      location?: string | null,
      time?: string | null,
      ipAddress?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateShippingMutationVariables = {
  input: UpdateShippingInput,
  condition?: ModelShippingConditionInput | null,
};

export type UpdateShippingMutation = {
  updateShipping?:  {
    __typename: "Shipping",
    id: string,
    pk: string,
    city?: string | null,
    countryId?: string | null,
    countryName?: string | null,
    state?: string | null,
    deliveryCharge?: number | null,
    coverage?: Coverage | null,
    zipCodes?: Array< string | null > | null,
    latitude?: string | null,
    longitude?: string | null,
    radius?: string | null,
    searchKey?: string | null,
    status?: Status | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    owner?: string | null,
  } | null,
};

export type GetSettingsQueryVariables = {
  pk: string,
  id: string,
};

export type GetSettingsQuery = {
  getSettings?:  {
    __typename: "Settings",
    id: string,
    pk: string,
    notificationType?: NotificationType | null,
    createdAt: string,
    emailTo?: Array< string | null > | null,
    productExpiryDuration?: string | null,
    theme?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListSettingsQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelSettingsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSettingsQuery = {
  listSettings?:  {
    __typename: "ModelSettingsConnection",
    items:  Array< {
      __typename: "Settings",
      id: string,
      pk: string,
      notificationType?: NotificationType | null,
      createdAt: string,
      emailTo?: Array< string | null > | null,
      productExpiryDuration?: string | null,
      theme?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SettingsByDateQueryVariables = {
  pk: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSettingsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SettingsByDateQuery = {
  settingsByDate?:  {
    __typename: "ModelSettingsConnection",
    items:  Array< {
      __typename: "Settings",
      id: string,
      pk: string,
      notificationType?: NotificationType | null,
      createdAt: string,
      emailTo?: Array< string | null > | null,
      productExpiryDuration?: string | null,
      theme?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCompanyDetailsQueryVariables = {
  pk: string,
  id: string,
};

export type GetCompanyDetailsQuery = {
  getCompanyDetails?:  {
    __typename: "CompanyDetails",
    id: string,
    pk: string,
    createdAt: string,
    primaryLogo?:  {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null,
    secondaryLogo?:  {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null,
    companyName?: string | null,
    webSite?: string | null,
    phoneNumber?: string | null,
    email?: string | null,
    ccEmail?: string | null,
    address?:  {
      __typename: "Address",
      addressLine1?: string | null,
      addressLine2?: string | null,
      street?: string | null,
      landmark?: string | null,
      state?: string | null,
      city?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    status?: boolean | null,
    currency?: string | null,
    categorySequenced?: boolean | null,
    copyrightText?: string | null,
    mobileAppUrls?:  Array< {
      __typename: "UrlDetails",
      type?: string | null,
      title?: string | null,
      alterText?: string | null,
      url?: string | null,
      certificationName?: string | null,
      redirectTo?: string | null,
    } | null > | null,
    theme?:  {
      __typename: "Theme",
      preferredTheme?: string | null,
      headerBackgroundColor?: string | null,
      headerTextColor?: string | null,
      headerIconColor?: string | null,
      footerBackgroundColor?: string | null,
      footerTextColor?: string | null,
      footerHeadingColor?: string | null,
      font?: string | null,
      navigationMenu?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      navigationMenuColor?: string | null,
      navigationSubMenu?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      navigationSubMenuColor?: string | null,
      productTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productSubTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productPrice?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDetailName?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDetailSubTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDetailPrice?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDescriptionHeading?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDescription?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      customerReviewsTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      customerReviewsDetails?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      notificationsTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      notificationsSubTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      profileHeading?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      profileContent?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      contentTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      contentDescription?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      titleHeader?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      titleDetails?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      primaryButton?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      primaryButtonBackgroundColor?: string | null,
      primaryButtonTextColor?: string | null,
      secondaryButton?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      secondaryButtonBackgroundColor?: string | null,
      secondaryButtonTextColor?: string | null,
    } | null,
    mobileTheme?:  {
      __typename: "MobileTheme",
      locationBackgroundColor?: string | null,
      locationTextColor?: string | null,
      locationIconColor?: string | null,
      searchBarBackgroundColor?: string | null,
      newArrivalsColor?: string | null,
      bestSellersColor?: string | null,
      recentlyViewedColor?: string | null,
      navBackgroundColor?: string | null,
      navIconColor?: string | null,
      font?: string | null,
      productListTitle?: string | null,
      productListBrand?: string | null,
      productListStock?: string | null,
      productListPrice?: string | null,
      productDetailName?: string | null,
      productDetailPrice?: string | null,
      productDetailStock?: string | null,
      productDetailBrand?: string | null,
      customerReviewsTitle?: string | null,
      customerReviewsDetails?: string | null,
      notificationsTitle?: string | null,
      notificationsSubTitle?: string | null,
      profileName?: string | null,
      profileHeadings?: string | null,
      primaryButtonText?: string | null,
      primaryButtonBackgroundColor?: string | null,
      primaryButtonTextColor?: string | null,
      secondaryButtonText?: string | null,
      secondaryButtonBackgroundColor?: string | null,
      secondaryButtonTextColor?: string | null,
    } | null,
    seoDescription?: string | null,
    daysToReturn?: number | null,
    brandName?: string | null,
    iconUrl?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCompanyDetailsQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelCompanyDetailsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCompanyDetailsQuery = {
  listCompanyDetails?:  {
    __typename: "ModelCompanyDetailsConnection",
    items:  Array< {
      __typename: "CompanyDetails",
      id: string,
      pk: string,
      createdAt: string,
      primaryLogo?:  {
        __typename: "imageUpload",
        alterText?: string | null,
        title?: string | null,
        url?: string | null,
        description?: string | null,
        type?: ImageType | null,
      } | null,
      secondaryLogo?:  {
        __typename: "imageUpload",
        alterText?: string | null,
        title?: string | null,
        url?: string | null,
        description?: string | null,
        type?: ImageType | null,
      } | null,
      companyName?: string | null,
      webSite?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      ccEmail?: string | null,
      address?:  {
        __typename: "Address",
        addressLine1?: string | null,
        addressLine2?: string | null,
        street?: string | null,
        landmark?: string | null,
        state?: string | null,
        city?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      status?: boolean | null,
      currency?: string | null,
      categorySequenced?: boolean | null,
      copyrightText?: string | null,
      mobileAppUrls?:  Array< {
        __typename: "UrlDetails",
        type?: string | null,
        title?: string | null,
        alterText?: string | null,
        url?: string | null,
        certificationName?: string | null,
        redirectTo?: string | null,
      } | null > | null,
      theme?:  {
        __typename: "Theme",
        preferredTheme?: string | null,
        headerBackgroundColor?: string | null,
        headerTextColor?: string | null,
        headerIconColor?: string | null,
        footerBackgroundColor?: string | null,
        footerTextColor?: string | null,
        footerHeadingColor?: string | null,
        font?: string | null,
        navigationMenu?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        navigationMenuColor?: string | null,
        navigationSubMenu?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        navigationSubMenuColor?: string | null,
        productTitle?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        productSubTitle?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        productPrice?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        productDetailName?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        productDetailSubTitle?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        productDetailPrice?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        productDescriptionHeading?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        productDescription?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        customerReviewsTitle?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        customerReviewsDetails?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        notificationsTitle?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        notificationsSubTitle?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        profileHeading?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        profileContent?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        contentTitle?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        contentDescription?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        titleHeader?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        titleDetails?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        primaryButton?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        primaryButtonBackgroundColor?: string | null,
        primaryButtonTextColor?: string | null,
        secondaryButton?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        secondaryButtonBackgroundColor?: string | null,
        secondaryButtonTextColor?: string | null,
      } | null,
      mobileTheme?:  {
        __typename: "MobileTheme",
        locationBackgroundColor?: string | null,
        locationTextColor?: string | null,
        locationIconColor?: string | null,
        searchBarBackgroundColor?: string | null,
        newArrivalsColor?: string | null,
        bestSellersColor?: string | null,
        recentlyViewedColor?: string | null,
        navBackgroundColor?: string | null,
        navIconColor?: string | null,
        font?: string | null,
        productListTitle?: string | null,
        productListBrand?: string | null,
        productListStock?: string | null,
        productListPrice?: string | null,
        productDetailName?: string | null,
        productDetailPrice?: string | null,
        productDetailStock?: string | null,
        productDetailBrand?: string | null,
        customerReviewsTitle?: string | null,
        customerReviewsDetails?: string | null,
        notificationsTitle?: string | null,
        notificationsSubTitle?: string | null,
        profileName?: string | null,
        profileHeadings?: string | null,
        primaryButtonText?: string | null,
        primaryButtonBackgroundColor?: string | null,
        primaryButtonTextColor?: string | null,
        secondaryButtonText?: string | null,
        secondaryButtonBackgroundColor?: string | null,
        secondaryButtonTextColor?: string | null,
      } | null,
      seoDescription?: string | null,
      daysToReturn?: number | null,
      brandName?: string | null,
      iconUrl?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CompanyDetailsByDateQueryVariables = {
  pk: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCompanyDetailsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CompanyDetailsByDateQuery = {
  companyDetailsByDate?:  {
    __typename: "ModelCompanyDetailsConnection",
    items:  Array< {
      __typename: "CompanyDetails",
      id: string,
      pk: string,
      createdAt: string,
      primaryLogo?:  {
        __typename: "imageUpload",
        alterText?: string | null,
        title?: string | null,
        url?: string | null,
        description?: string | null,
        type?: ImageType | null,
      } | null,
      secondaryLogo?:  {
        __typename: "imageUpload",
        alterText?: string | null,
        title?: string | null,
        url?: string | null,
        description?: string | null,
        type?: ImageType | null,
      } | null,
      companyName?: string | null,
      webSite?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      ccEmail?: string | null,
      address?:  {
        __typename: "Address",
        addressLine1?: string | null,
        addressLine2?: string | null,
        street?: string | null,
        landmark?: string | null,
        state?: string | null,
        city?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      status?: boolean | null,
      currency?: string | null,
      categorySequenced?: boolean | null,
      copyrightText?: string | null,
      mobileAppUrls?:  Array< {
        __typename: "UrlDetails",
        type?: string | null,
        title?: string | null,
        alterText?: string | null,
        url?: string | null,
        certificationName?: string | null,
        redirectTo?: string | null,
      } | null > | null,
      theme?:  {
        __typename: "Theme",
        preferredTheme?: string | null,
        headerBackgroundColor?: string | null,
        headerTextColor?: string | null,
        headerIconColor?: string | null,
        footerBackgroundColor?: string | null,
        footerTextColor?: string | null,
        footerHeadingColor?: string | null,
        font?: string | null,
        navigationMenu?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        navigationMenuColor?: string | null,
        navigationSubMenu?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        navigationSubMenuColor?: string | null,
        productTitle?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        productSubTitle?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        productPrice?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        productDetailName?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        productDetailSubTitle?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        productDetailPrice?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        productDescriptionHeading?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        productDescription?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        customerReviewsTitle?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        customerReviewsDetails?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        notificationsTitle?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        notificationsSubTitle?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        profileHeading?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        profileContent?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        contentTitle?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        contentDescription?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        titleHeader?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        titleDetails?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        primaryButton?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        primaryButtonBackgroundColor?: string | null,
        primaryButtonTextColor?: string | null,
        secondaryButton?:  {
          __typename: "FontProperty",
          title?: string | null,
          example?: string | null,
          fontWeight?: string | null,
          fontSize?: string | null,
        } | null,
        secondaryButtonBackgroundColor?: string | null,
        secondaryButtonTextColor?: string | null,
      } | null,
      mobileTheme?:  {
        __typename: "MobileTheme",
        locationBackgroundColor?: string | null,
        locationTextColor?: string | null,
        locationIconColor?: string | null,
        searchBarBackgroundColor?: string | null,
        newArrivalsColor?: string | null,
        bestSellersColor?: string | null,
        recentlyViewedColor?: string | null,
        navBackgroundColor?: string | null,
        navIconColor?: string | null,
        font?: string | null,
        productListTitle?: string | null,
        productListBrand?: string | null,
        productListStock?: string | null,
        productListPrice?: string | null,
        productDetailName?: string | null,
        productDetailPrice?: string | null,
        productDetailStock?: string | null,
        productDetailBrand?: string | null,
        customerReviewsTitle?: string | null,
        customerReviewsDetails?: string | null,
        notificationsTitle?: string | null,
        notificationsSubTitle?: string | null,
        profileName?: string | null,
        profileHeadings?: string | null,
        primaryButtonText?: string | null,
        primaryButtonBackgroundColor?: string | null,
        primaryButtonTextColor?: string | null,
        secondaryButtonText?: string | null,
        secondaryButtonBackgroundColor?: string | null,
        secondaryButtonTextColor?: string | null,
      } | null,
      seoDescription?: string | null,
      daysToReturn?: number | null,
      brandName?: string | null,
      iconUrl?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPaymentModeQueryVariables = {
  pk: string,
  id: string,
};

export type GetPaymentModeQuery = {
  getPaymentMode?:  {
    __typename: "PaymentMode",
    id: string,
    pk: string,
    createdAt: string,
    method?: string | null,
    description?: string | null,
    provider?: string | null,
    logoUrl?: string | null,
    stagingUrl?: string | null,
    productionUrl?: string | null,
    status?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListPaymentModesQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelPaymentModeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPaymentModesQuery = {
  listPaymentModes?:  {
    __typename: "ModelPaymentModeConnection",
    items:  Array< {
      __typename: "PaymentMode",
      id: string,
      pk: string,
      createdAt: string,
      method?: string | null,
      description?: string | null,
      provider?: string | null,
      logoUrl?: string | null,
      stagingUrl?: string | null,
      productionUrl?: string | null,
      status?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PaymentModeByDateQueryVariables = {
  pk: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPaymentModeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaymentModeByDateQuery = {
  paymentModeByDate?:  {
    __typename: "ModelPaymentModeConnection",
    items:  Array< {
      __typename: "PaymentMode",
      id: string,
      pk: string,
      createdAt: string,
      method?: string | null,
      description?: string | null,
      provider?: string | null,
      logoUrl?: string | null,
      stagingUrl?: string | null,
      productionUrl?: string | null,
      status?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  pk: string,
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    pk: string,
    userName?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    companyName?: string | null,
    profileImageUrl?: string | null,
    email: string,
    phoneNumber?: string | null,
    role?: UserRole | null,
    password?: string | null,
    multiFactorAuth?: boolean | null,
    emailNotification?: boolean | null,
    mandatePasswordChange?: boolean | null,
    emailVerified?: boolean | null,
    otp?: string | null,
    status?: Status | null,
    searchKey?: string | null,
    addressDetails?:  Array< {
      __typename: "AddressDetails",
      name?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      address?:  {
        __typename: "Address",
        addressLine1?: string | null,
        addressLine2?: string | null,
        street?: string | null,
        landmark?: string | null,
        state?: string | null,
        city?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      type?: string | null,
      addressName?: string | null,
      default?: boolean | null,
      latitude?: string | null,
      longitude?: string | null,
    } | null > | null,
    loginAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    platform?: Platform | null,
    owner?: string | null,
  } | null,
};

export type ListUsersQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      pk: string,
      userName?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      profileImageUrl?: string | null,
      email: string,
      phoneNumber?: string | null,
      role?: UserRole | null,
      password?: string | null,
      multiFactorAuth?: boolean | null,
      emailNotification?: boolean | null,
      mandatePasswordChange?: boolean | null,
      emailVerified?: boolean | null,
      otp?: string | null,
      status?: Status | null,
      searchKey?: string | null,
      addressDetails?:  Array< {
        __typename: "AddressDetails",
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        address?:  {
          __typename: "Address",
          addressLine1?: string | null,
          addressLine2?: string | null,
          street?: string | null,
          landmark?: string | null,
          state?: string | null,
          city?: string | null,
          postalCode?: string | null,
          country?: string | null,
        } | null,
        type?: string | null,
        addressName?: string | null,
        default?: boolean | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null > | null,
      loginAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      platform?: Platform | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserByDateQueryVariables = {
  pk: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserByDateQuery = {
  userByDate?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      pk: string,
      userName?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      profileImageUrl?: string | null,
      email: string,
      phoneNumber?: string | null,
      role?: UserRole | null,
      password?: string | null,
      multiFactorAuth?: boolean | null,
      emailNotification?: boolean | null,
      mandatePasswordChange?: boolean | null,
      emailVerified?: boolean | null,
      otp?: string | null,
      status?: Status | null,
      searchKey?: string | null,
      addressDetails?:  Array< {
        __typename: "AddressDetails",
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        address?:  {
          __typename: "Address",
          addressLine1?: string | null,
          addressLine2?: string | null,
          street?: string | null,
          landmark?: string | null,
          state?: string | null,
          city?: string | null,
          postalCode?: string | null,
          country?: string | null,
        } | null,
        type?: string | null,
        addressName?: string | null,
        default?: boolean | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null > | null,
      loginAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      platform?: Platform | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserByEmailQueryVariables = {
  pk: string,
  email?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserByEmailQuery = {
  userByEmail?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      pk: string,
      userName?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      profileImageUrl?: string | null,
      email: string,
      phoneNumber?: string | null,
      role?: UserRole | null,
      password?: string | null,
      multiFactorAuth?: boolean | null,
      emailNotification?: boolean | null,
      mandatePasswordChange?: boolean | null,
      emailVerified?: boolean | null,
      otp?: string | null,
      status?: Status | null,
      searchKey?: string | null,
      addressDetails?:  Array< {
        __typename: "AddressDetails",
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        address?:  {
          __typename: "Address",
          addressLine1?: string | null,
          addressLine2?: string | null,
          street?: string | null,
          landmark?: string | null,
          state?: string | null,
          city?: string | null,
          postalCode?: string | null,
          country?: string | null,
        } | null,
        type?: string | null,
        addressName?: string | null,
        default?: boolean | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null > | null,
      loginAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      platform?: Platform | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCategoryQueryVariables = {
  pk: string,
  slug: string,
};

export type GetCategoryQuery = {
  getCategory?:  {
    __typename: "Category",
    pk: string,
    slug: string,
    id: string,
    catName?: string | null,
    mainCategory?: string | null,
    mainCategoryID?: string | null,
    categoryImg?: string | null,
    description?: string | null,
    webBannerImg?: string | null,
    webBannerImgBottom?: string | null,
    mobBannerImg?: string | null,
    mobBannerImgBottom?: string | null,
    categoryType?: CategoryType | null,
    searchKey?: string | null,
    catStatus?: boolean | null,
    sequenceNumber?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCategoriesQueryVariables = {
  pk?: string | null,
  slug?: ModelStringKeyConditionInput | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCategoriesQuery = {
  listCategories?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      pk: string,
      slug: string,
      id: string,
      catName?: string | null,
      mainCategory?: string | null,
      mainCategoryID?: string | null,
      categoryImg?: string | null,
      description?: string | null,
      webBannerImg?: string | null,
      webBannerImgBottom?: string | null,
      mobBannerImg?: string | null,
      mobBannerImgBottom?: string | null,
      categoryType?: CategoryType | null,
      searchKey?: string | null,
      catStatus?: boolean | null,
      sequenceNumber?: number | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CategoryByIDQueryVariables = {
  pk: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CategoryByIDQuery = {
  categoryByID?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      pk: string,
      slug: string,
      id: string,
      catName?: string | null,
      mainCategory?: string | null,
      mainCategoryID?: string | null,
      categoryImg?: string | null,
      description?: string | null,
      webBannerImg?: string | null,
      webBannerImgBottom?: string | null,
      mobBannerImg?: string | null,
      mobBannerImgBottom?: string | null,
      categoryType?: CategoryType | null,
      searchKey?: string | null,
      catStatus?: boolean | null,
      sequenceNumber?: number | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBrandQueryVariables = {
  pk: string,
  id: string,
};

export type GetBrandQuery = {
  getBrand?:  {
    __typename: "Brand",
    pk: string,
    id: string,
    brandName?: string | null,
    searchKey?: string | null,
    manufacturerName?: string | null,
    status?: Status | null,
    countryOfOrigin?: string | null,
    brandLogo?: string | null,
    brandUrl?: string | null,
    brandDescription?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListBrandsQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelBrandFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListBrandsQuery = {
  listBrands?:  {
    __typename: "ModelBrandConnection",
    items:  Array< {
      __typename: "Brand",
      pk: string,
      id: string,
      brandName?: string | null,
      searchKey?: string | null,
      manufacturerName?: string | null,
      status?: Status | null,
      countryOfOrigin?: string | null,
      brandLogo?: string | null,
      brandUrl?: string | null,
      brandDescription?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAttributeNameQueryVariables = {
  pk: string,
  slug: string,
};

export type GetAttributeNameQuery = {
  getAttributeName?:  {
    __typename: "AttributeName",
    pk: string,
    slug: string,
    id: string,
    sequenceNumber?: number | null,
    searchKey?: string | null,
    status?: Status | null,
    attrName?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListAttributeNamesQueryVariables = {
  pk?: string | null,
  slug?: ModelStringKeyConditionInput | null,
  filter?: ModelAttributeNameFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAttributeNamesQuery = {
  listAttributeNames?:  {
    __typename: "ModelAttributeNameConnection",
    items:  Array< {
      __typename: "AttributeName",
      pk: string,
      slug: string,
      id: string,
      sequenceNumber?: number | null,
      searchKey?: string | null,
      status?: Status | null,
      attrName?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AttributeNameByIDQueryVariables = {
  pk: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAttributeNameFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AttributeNameByIDQuery = {
  attributeNameByID?:  {
    __typename: "ModelAttributeNameConnection",
    items:  Array< {
      __typename: "AttributeName",
      pk: string,
      slug: string,
      id: string,
      sequenceNumber?: number | null,
      searchKey?: string | null,
      status?: Status | null,
      attrName?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCountryQueryVariables = {
  pk: string,
  slug: string,
};

export type GetCountryQuery = {
  getCountry?:  {
    __typename: "Country",
    pk: string,
    slug: string,
    id: string,
    name?: string | null,
    shortName?: string | null,
    searchKey?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCountriesQueryVariables = {
  pk?: string | null,
  slug?: ModelStringKeyConditionInput | null,
  filter?: ModelCountryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCountriesQuery = {
  listCountries?:  {
    __typename: "ModelCountryConnection",
    items:  Array< {
      __typename: "Country",
      pk: string,
      slug: string,
      id: string,
      name?: string | null,
      shortName?: string | null,
      searchKey?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDropDownMasterQueryVariables = {
  pk: string,
  id: string,
};

export type GetDropDownMasterQuery = {
  getDropDownMaster?:  {
    __typename: "DropDownMaster",
    pk: string,
    id: string,
    value?: string | null,
    status?: Status | null,
    searchKey?: string | null,
    sequenceNumber?: number | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListDropDownMastersQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelDropDownMasterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDropDownMastersQuery = {
  listDropDownMasters?:  {
    __typename: "ModelDropDownMasterConnection",
    items:  Array< {
      __typename: "DropDownMaster",
      pk: string,
      id: string,
      value?: string | null,
      status?: Status | null,
      searchKey?: string | null,
      sequenceNumber?: number | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCartQueryVariables = {
  pk: string,
  id: string,
};

export type GetCartQuery = {
  getCart?:  {
    __typename: "Cart",
    pk: string,
    id: string,
    userId: string,
    productID: string,
    createdAt: string,
    productImage?: string | null,
    productName?: string | null,
    mainCategoryId?: string | null,
    mainCategory?: string | null,
    subCategoryId?: string | null,
    subCategoryN?: string | null,
    attrValueID?: string | null,
    price?: number | null,
    salesPrice?: number | null,
    stockUnit?: string | null,
    itemsPerUnit?: number | null,
    expiryDate?: string | null,
    selectedQuantity?: number | null,
    brandDetail?:  {
      __typename: "BrandDetail",
      brandID?: string | null,
      brandName?: string | null,
      brandDescription?: string | null,
      manufacturerName?: string | null,
      imageUrl?: string | null,
    } | null,
    wareHouseId?: string | null,
    wareHouseName?: string | null,
    attrName?: string | null,
    attrValue?: string | null,
    shippingInfo?:  {
      __typename: "shippingDetails",
      weightValue?: string | null,
      weightUnit?: string | null,
      lengthValue?: string | null,
      lengthUnit?: string | null,
      heightValue?: string | null,
      heightUnit?: string | null,
      widthValue?: string | null,
      widthUnit?: string | null,
    } | null,
    userName?: string | null,
    userMail?: string | null,
    model?: string | null,
    cartURL?: string | null,
    taxCode?: string | null,
    taxCharge?: number | null,
    taxDetail?:  Array< {
      __typename: "taxDetail",
      taxCharge?: number | null,
      taxID?: string | null,
      taxName?: string | null,
    } | null > | null,
    sku?: string | null,
    searchKey?: string | null,
    totalPrice?: number | null,
    mobileNumber?: string | null,
    currentStock?: string | null,
    brandId?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCartsQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelCartFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCartsQuery = {
  listCarts?:  {
    __typename: "ModelCartConnection",
    items:  Array< {
      __typename: "Cart",
      pk: string,
      id: string,
      userId: string,
      productID: string,
      createdAt: string,
      productImage?: string | null,
      productName?: string | null,
      mainCategoryId?: string | null,
      mainCategory?: string | null,
      subCategoryId?: string | null,
      subCategoryN?: string | null,
      attrValueID?: string | null,
      price?: number | null,
      salesPrice?: number | null,
      stockUnit?: string | null,
      itemsPerUnit?: number | null,
      expiryDate?: string | null,
      selectedQuantity?: number | null,
      brandDetail?:  {
        __typename: "BrandDetail",
        brandID?: string | null,
        brandName?: string | null,
        brandDescription?: string | null,
        manufacturerName?: string | null,
        imageUrl?: string | null,
      } | null,
      wareHouseId?: string | null,
      wareHouseName?: string | null,
      attrName?: string | null,
      attrValue?: string | null,
      shippingInfo?:  {
        __typename: "shippingDetails",
        weightValue?: string | null,
        weightUnit?: string | null,
        lengthValue?: string | null,
        lengthUnit?: string | null,
        heightValue?: string | null,
        heightUnit?: string | null,
        widthValue?: string | null,
        widthUnit?: string | null,
      } | null,
      userName?: string | null,
      userMail?: string | null,
      model?: string | null,
      cartURL?: string | null,
      taxCode?: string | null,
      taxCharge?: number | null,
      taxDetail?:  Array< {
        __typename: "taxDetail",
        taxCharge?: number | null,
        taxID?: string | null,
        taxName?: string | null,
      } | null > | null,
      sku?: string | null,
      searchKey?: string | null,
      totalPrice?: number | null,
      mobileNumber?: string | null,
      currentStock?: string | null,
      brandId?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CartByDateQueryVariables = {
  pk: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCartFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CartByDateQuery = {
  cartByDate?:  {
    __typename: "ModelCartConnection",
    items:  Array< {
      __typename: "Cart",
      pk: string,
      id: string,
      userId: string,
      productID: string,
      createdAt: string,
      productImage?: string | null,
      productName?: string | null,
      mainCategoryId?: string | null,
      mainCategory?: string | null,
      subCategoryId?: string | null,
      subCategoryN?: string | null,
      attrValueID?: string | null,
      price?: number | null,
      salesPrice?: number | null,
      stockUnit?: string | null,
      itemsPerUnit?: number | null,
      expiryDate?: string | null,
      selectedQuantity?: number | null,
      brandDetail?:  {
        __typename: "BrandDetail",
        brandID?: string | null,
        brandName?: string | null,
        brandDescription?: string | null,
        manufacturerName?: string | null,
        imageUrl?: string | null,
      } | null,
      wareHouseId?: string | null,
      wareHouseName?: string | null,
      attrName?: string | null,
      attrValue?: string | null,
      shippingInfo?:  {
        __typename: "shippingDetails",
        weightValue?: string | null,
        weightUnit?: string | null,
        lengthValue?: string | null,
        lengthUnit?: string | null,
        heightValue?: string | null,
        heightUnit?: string | null,
        widthValue?: string | null,
        widthUnit?: string | null,
      } | null,
      userName?: string | null,
      userMail?: string | null,
      model?: string | null,
      cartURL?: string | null,
      taxCode?: string | null,
      taxCharge?: number | null,
      taxDetail?:  Array< {
        __typename: "taxDetail",
        taxCharge?: number | null,
        taxID?: string | null,
        taxName?: string | null,
      } | null > | null,
      sku?: string | null,
      searchKey?: string | null,
      totalPrice?: number | null,
      mobileNumber?: string | null,
      currentStock?: string | null,
      brandId?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CartByUserIdQueryVariables = {
  pk: string,
  userId?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCartFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CartByUserIdQuery = {
  cartByUserId?:  {
    __typename: "ModelCartConnection",
    items:  Array< {
      __typename: "Cart",
      pk: string,
      id: string,
      userId: string,
      productID: string,
      createdAt: string,
      productImage?: string | null,
      productName?: string | null,
      mainCategoryId?: string | null,
      mainCategory?: string | null,
      subCategoryId?: string | null,
      subCategoryN?: string | null,
      attrValueID?: string | null,
      price?: number | null,
      salesPrice?: number | null,
      stockUnit?: string | null,
      itemsPerUnit?: number | null,
      expiryDate?: string | null,
      selectedQuantity?: number | null,
      brandDetail?:  {
        __typename: "BrandDetail",
        brandID?: string | null,
        brandName?: string | null,
        brandDescription?: string | null,
        manufacturerName?: string | null,
        imageUrl?: string | null,
      } | null,
      wareHouseId?: string | null,
      wareHouseName?: string | null,
      attrName?: string | null,
      attrValue?: string | null,
      shippingInfo?:  {
        __typename: "shippingDetails",
        weightValue?: string | null,
        weightUnit?: string | null,
        lengthValue?: string | null,
        lengthUnit?: string | null,
        heightValue?: string | null,
        heightUnit?: string | null,
        widthValue?: string | null,
        widthUnit?: string | null,
      } | null,
      userName?: string | null,
      userMail?: string | null,
      model?: string | null,
      cartURL?: string | null,
      taxCode?: string | null,
      taxCharge?: number | null,
      taxDetail?:  Array< {
        __typename: "taxDetail",
        taxCharge?: number | null,
        taxID?: string | null,
        taxName?: string | null,
      } | null > | null,
      sku?: string | null,
      searchKey?: string | null,
      totalPrice?: number | null,
      mobileNumber?: string | null,
      currentStock?: string | null,
      brandId?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetWareHouseQueryVariables = {
  pk: string,
  id: string,
};

export type GetWareHouseQuery = {
  getWareHouse?:  {
    __typename: "WareHouse",
    pk: string,
    id: string,
    name: string,
    createdAt: string,
    addressLine1?: string | null,
    addressLine2?: string | null,
    searchKey?: string | null,
    countryId?: string | null,
    country?: string | null,
    state?: string | null,
    city?: string | null,
    zipCode?: string | null,
    contactPerson?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    workingHoursFrom?: string | null,
    workingHoursTo?: string | null,
    status?: boolean | null,
    latitude?: string | null,
    longitude?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListWareHousesQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelWareHouseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListWareHousesQuery = {
  listWareHouses?:  {
    __typename: "ModelWareHouseConnection",
    items:  Array< {
      __typename: "WareHouse",
      pk: string,
      id: string,
      name: string,
      createdAt: string,
      addressLine1?: string | null,
      addressLine2?: string | null,
      searchKey?: string | null,
      countryId?: string | null,
      country?: string | null,
      state?: string | null,
      city?: string | null,
      zipCode?: string | null,
      contactPerson?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      workingHoursFrom?: string | null,
      workingHoursTo?: string | null,
      status?: boolean | null,
      latitude?: string | null,
      longitude?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type WareHouseByDateQueryVariables = {
  pk: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWareHouseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type WareHouseByDateQuery = {
  wareHouseByDate?:  {
    __typename: "ModelWareHouseConnection",
    items:  Array< {
      __typename: "WareHouse",
      pk: string,
      id: string,
      name: string,
      createdAt: string,
      addressLine1?: string | null,
      addressLine2?: string | null,
      searchKey?: string | null,
      countryId?: string | null,
      country?: string | null,
      state?: string | null,
      city?: string | null,
      zipCode?: string | null,
      contactPerson?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      workingHoursFrom?: string | null,
      workingHoursTo?: string | null,
      status?: boolean | null,
      latitude?: string | null,
      longitude?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGeneralContactUsQueryVariables = {
  pk: string,
  id: string,
};

export type GetGeneralContactUsQuery = {
  getGeneralContactUs?:  {
    __typename: "GeneralContactUs",
    pk: string,
    id: string,
    referenceNumber?: string | null,
    userName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    companyName?: string | null,
    subject?: string | null,
    message?: string | null,
    replyMessage?: string | null,
    enquiryStatus?: EnquiryStatus | null,
    attachments?: Array< string | null > | null,
    searchKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type ListGeneralContactusesQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelGeneralContactUsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListGeneralContactusesQuery = {
  listGeneralContactuses?:  {
    __typename: "ModelGeneralContactUsConnection",
    items:  Array< {
      __typename: "GeneralContactUs",
      pk: string,
      id: string,
      referenceNumber?: string | null,
      userName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      companyName?: string | null,
      subject?: string | null,
      message?: string | null,
      replyMessage?: string | null,
      enquiryStatus?: EnquiryStatus | null,
      attachments?: Array< string | null > | null,
      searchKey?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNotificationsQueryVariables = {
  pk: string,
  id: string,
};

export type GetNotificationsQuery = {
  getNotifications?:  {
    __typename: "Notifications",
    pk: string,
    id: string,
    userId?: string | null,
    userName?: string | null,
    notificationType?: string | null,
    Title?: string | null,
    Message?: string | null,
    orderID?: string | null,
    DateTime?: string | null,
    notificationStatus?: number | null,
    notificationModule?: string | null,
    referenceID?: string | null,
    targetURL?: string | null,
    searchKey?: string | null,
    expiryDate?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListNotificationsQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelNotificationsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListNotificationsQuery = {
  listNotifications?:  {
    __typename: "ModelNotificationsConnection",
    items:  Array< {
      __typename: "Notifications",
      pk: string,
      id: string,
      userId?: string | null,
      userName?: string | null,
      notificationType?: string | null,
      Title?: string | null,
      Message?: string | null,
      orderID?: string | null,
      DateTime?: string | null,
      notificationStatus?: number | null,
      notificationModule?: string | null,
      referenceID?: string | null,
      targetURL?: string | null,
      searchKey?: string | null,
      expiryDate?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NotificationByDateQueryVariables = {
  pk: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificationByDateQuery = {
  notificationByDate?:  {
    __typename: "ModelNotificationsConnection",
    items:  Array< {
      __typename: "Notifications",
      pk: string,
      id: string,
      userId?: string | null,
      userName?: string | null,
      notificationType?: string | null,
      Title?: string | null,
      Message?: string | null,
      orderID?: string | null,
      DateTime?: string | null,
      notificationStatus?: number | null,
      notificationModule?: string | null,
      referenceID?: string | null,
      targetURL?: string | null,
      searchKey?: string | null,
      expiryDate?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetModuleMasterQueryVariables = {
  pk: string,
  id: string,
};

export type GetModuleMasterQuery = {
  getModuleMaster?:  {
    __typename: "ModuleMaster",
    pk: string,
    id: string,
    moduleName?: string | null,
    sequenceNumber?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListModuleMastersQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelModuleMasterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListModuleMastersQuery = {
  listModuleMasters?:  {
    __typename: "ModelModuleMasterConnection",
    items:  Array< {
      __typename: "ModuleMaster",
      pk: string,
      id: string,
      moduleName?: string | null,
      sequenceNumber?: number | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRoleManagementQueryVariables = {
  pk: string,
  id: string,
};

export type GetRoleManagementQuery = {
  getRoleManagement?:  {
    __typename: "RoleManagement",
    pk: string,
    id: string,
    menuId?: string | null,
    roleName: string,
    module?:  Array< {
      __typename: "Module",
      moduleId?: string | null,
      menu?: string | null,
      view?: boolean | null,
      add?: boolean | null,
      edit?: boolean | null,
      delete?: boolean | null,
    } | null > | null,
    status?: Status | null,
    searchKey?: string | null,
    updatedAt: string,
    createdAt: string,
    owner?: string | null,
  } | null,
};

export type ListRoleManagementsQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelRoleManagementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListRoleManagementsQuery = {
  listRoleManagements?:  {
    __typename: "ModelRoleManagementConnection",
    items:  Array< {
      __typename: "RoleManagement",
      pk: string,
      id: string,
      menuId?: string | null,
      roleName: string,
      module?:  Array< {
        __typename: "Module",
        moduleId?: string | null,
        menu?: string | null,
        view?: boolean | null,
        add?: boolean | null,
        edit?: boolean | null,
        delete?: boolean | null,
      } | null > | null,
      status?: Status | null,
      searchKey?: string | null,
      updatedAt: string,
      createdAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RoleByStatusQueryVariables = {
  pk: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRoleManagementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RoleByStatusQuery = {
  roleByStatus?:  {
    __typename: "ModelRoleManagementConnection",
    items:  Array< {
      __typename: "RoleManagement",
      pk: string,
      id: string,
      menuId?: string | null,
      roleName: string,
      module?:  Array< {
        __typename: "Module",
        moduleId?: string | null,
        menu?: string | null,
        view?: boolean | null,
        add?: boolean | null,
        edit?: boolean | null,
        delete?: boolean | null,
      } | null > | null,
      status?: Status | null,
      searchKey?: string | null,
      updatedAt: string,
      createdAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetContentQueryVariables = {
  pk: string,
  id: string,
};

export type GetContentQuery = {
  getContent?:  {
    __typename: "Content",
    pk: string,
    id: string,
    contentName: string,
    title?: string | null,
    sequenceNumber?: number | null,
    content?: string | null,
    searchKey?: string | null,
    docUrl?: string | null,
    visibleToOthers?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type ListContentsQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListContentsQuery = {
  listContents?:  {
    __typename: "ModelContentConnection",
    items:  Array< {
      __typename: "Content",
      pk: string,
      id: string,
      contentName: string,
      title?: string | null,
      sequenceNumber?: number | null,
      content?: string | null,
      searchKey?: string | null,
      docUrl?: string | null,
      visibleToOthers?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFAQQueryVariables = {
  pk: string,
  id: string,
};

export type GetFAQQuery = {
  getFAQ?:  {
    __typename: "FAQ",
    pk: string,
    id: string,
    type?: string | null,
    sequenceNumber?: number | null,
    name?: string | null,
    FAQSections?:  Array< {
      __typename: "FAQSections",
      question?: string | null,
      answer?: string | null,
    } | null > | null,
    searchKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type ListFAQSQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelFAQFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListFAQSQuery = {
  listFAQS?:  {
    __typename: "ModelFAQConnection",
    items:  Array< {
      __typename: "FAQ",
      pk: string,
      id: string,
      type?: string | null,
      sequenceNumber?: number | null,
      name?: string | null,
      FAQSections?:  Array< {
        __typename: "FAQSections",
        question?: string | null,
        answer?: string | null,
      } | null > | null,
      searchKey?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductReviewQueryVariables = {
  pk: string,
  id: string,
};

export type GetProductReviewQuery = {
  getProductReview?:  {
    __typename: "ProductReview",
    pk: string,
    id: string,
    orderItemId: string,
    productId?: string | null,
    productImage?: string | null,
    productName?: string | null,
    userId?: string | null,
    userName?: string | null,
    email?: string | null,
    overallRating?: number | null,
    title?: string | null,
    reviewComment?: string | null,
    removeAbusiveComment?: boolean | null,
    abusiveReportedBy?:  Array< {
      __typename: "AbusiveReport",
      userId?: string | null,
      userName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      reasons?: Array< string | null > | null,
      reportedDate?: string | null,
    } | null > | null,
    attachments?: Array< string | null > | null,
    createdAt?: string | null,
    reply?: string | null,
    repliedOnDate?: string | null,
    orderedDate?: string | null,
    reviewedDate?: string | null,
    reviewStatus?: EnquiryStatus | null,
    searchKey?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListProductReviewsQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelProductReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListProductReviewsQuery = {
  listProductReviews?:  {
    __typename: "ModelProductReviewConnection",
    items:  Array< {
      __typename: "ProductReview",
      pk: string,
      id: string,
      orderItemId: string,
      productId?: string | null,
      productImage?: string | null,
      productName?: string | null,
      userId?: string | null,
      userName?: string | null,
      email?: string | null,
      overallRating?: number | null,
      title?: string | null,
      reviewComment?: string | null,
      removeAbusiveComment?: boolean | null,
      abusiveReportedBy?:  Array< {
        __typename: "AbusiveReport",
        userId?: string | null,
        userName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        reasons?: Array< string | null > | null,
        reportedDate?: string | null,
      } | null > | null,
      attachments?: Array< string | null > | null,
      createdAt?: string | null,
      reply?: string | null,
      repliedOnDate?: string | null,
      orderedDate?: string | null,
      reviewedDate?: string | null,
      reviewStatus?: EnquiryStatus | null,
      searchKey?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductReviewByOrderItemIdQueryVariables = {
  pk: string,
  orderItemId?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductReviewByOrderItemIdQuery = {
  productReviewByOrderItemId?:  {
    __typename: "ModelProductReviewConnection",
    items:  Array< {
      __typename: "ProductReview",
      pk: string,
      id: string,
      orderItemId: string,
      productId?: string | null,
      productImage?: string | null,
      productName?: string | null,
      userId?: string | null,
      userName?: string | null,
      email?: string | null,
      overallRating?: number | null,
      title?: string | null,
      reviewComment?: string | null,
      removeAbusiveComment?: boolean | null,
      abusiveReportedBy?:  Array< {
        __typename: "AbusiveReport",
        userId?: string | null,
        userName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        reasons?: Array< string | null > | null,
        reportedDate?: string | null,
      } | null > | null,
      attachments?: Array< string | null > | null,
      createdAt?: string | null,
      reply?: string | null,
      repliedOnDate?: string | null,
      orderedDate?: string | null,
      reviewedDate?: string | null,
      reviewStatus?: EnquiryStatus | null,
      searchKey?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductReviewByProductIdQueryVariables = {
  productId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductReviewByProductIdQuery = {
  productReviewByProductId?:  {
    __typename: "ModelProductReviewConnection",
    items:  Array< {
      __typename: "ProductReview",
      pk: string,
      id: string,
      orderItemId: string,
      productId?: string | null,
      productImage?: string | null,
      productName?: string | null,
      userId?: string | null,
      userName?: string | null,
      email?: string | null,
      overallRating?: number | null,
      title?: string | null,
      reviewComment?: string | null,
      removeAbusiveComment?: boolean | null,
      abusiveReportedBy?:  Array< {
        __typename: "AbusiveReport",
        userId?: string | null,
        userName?: string | null,
        email?: string | null,
        phoneNumber?: string | null,
        reasons?: Array< string | null > | null,
        reportedDate?: string | null,
      } | null > | null,
      attachments?: Array< string | null > | null,
      createdAt?: string | null,
      reply?: string | null,
      repliedOnDate?: string | null,
      orderedDate?: string | null,
      reviewedDate?: string | null,
      reviewStatus?: EnquiryStatus | null,
      searchKey?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTokenDetailsQueryVariables = {
  pk: string,
  id: string,
};

export type GetTokenDetailsQuery = {
  getTokenDetails?:  {
    __typename: "TokenDetails",
    pk: string,
    id: string,
    expiresIn?: string | null,
    token: string,
    tokenType?: string | null,
    userID: string,
    loginAt?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListTokenDetailsQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelTokenDetailsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTokenDetailsQuery = {
  listTokenDetails?:  {
    __typename: "ModelTokenDetailsConnection",
    items:  Array< {
      __typename: "TokenDetails",
      pk: string,
      id: string,
      expiresIn?: string | null,
      token: string,
      tokenType?: string | null,
      userID: string,
      loginAt?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TokebByUserIdQueryVariables = {
  pk: string,
  userID?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTokenDetailsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TokebByUserIdQuery = {
  tokebByUserId?:  {
    __typename: "ModelTokenDetailsConnection",
    items:  Array< {
      __typename: "TokenDetails",
      pk: string,
      id: string,
      expiresIn?: string | null,
      token: string,
      tokenType?: string | null,
      userID: string,
      loginAt?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTaxQueryVariables = {
  pk: string,
  id: string,
};

export type GetTaxQuery = {
  getTax?:  {
    __typename: "Tax",
    pk: string,
    id: string,
    taxCode: string,
    taxType?: string | null,
    status?: Status | null,
    searchKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type ListTaxesQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelTaxFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTaxesQuery = {
  listTaxes?:  {
    __typename: "ModelTaxConnection",
    items:  Array< {
      __typename: "Tax",
      pk: string,
      id: string,
      taxCode: string,
      taxType?: string | null,
      status?: Status | null,
      searchKey?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TaxByCodeQueryVariables = {
  pk: string,
  taxCode?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaxFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TaxByCodeQuery = {
  taxByCode?:  {
    __typename: "ModelTaxConnection",
    items:  Array< {
      __typename: "Tax",
      pk: string,
      id: string,
      taxCode: string,
      taxType?: string | null,
      status?: Status | null,
      searchKey?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCarouselImageQueryVariables = {
  pk: string,
  id: string,
};

export type GetCarouselImageQuery = {
  getCarouselImage?:  {
    __typename: "CarouselImage",
    pk: string,
    id: string,
    type?: string | null,
    deviceType?: string | null,
    animation?: animationType | null,
    pauseTime?: number | null,
    transitionSpeed?: number | null,
    stopAnimation?: boolean | null,
    displayArrows?: boolean | null,
    displayNavigationBullets?: boolean | null,
    image?:  Array< {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null > | null,
    status?: Status | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type ListCarouselImagesQueryVariables = {
  pk?: string | null,
  id?: ModelStringKeyConditionInput | null,
  filter?: ModelCarouselImageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCarouselImagesQuery = {
  listCarouselImages?:  {
    __typename: "ModelCarouselImageConnection",
    items:  Array< {
      __typename: "CarouselImage",
      pk: string,
      id: string,
      type?: string | null,
      deviceType?: string | null,
      animation?: animationType | null,
      pauseTime?: number | null,
      transitionSpeed?: number | null,
      stopAnimation?: boolean | null,
      displayArrows?: boolean | null,
      displayNavigationBullets?: boolean | null,
      image?:  Array< {
        __typename: "imageUpload",
        alterText?: string | null,
        title?: string | null,
        url?: string | null,
        description?: string | null,
        type?: ImageType | null,
      } | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetWishListQueryVariables = {
  pk: string,
  id: string,
};

export type GetWishListQuery = {
  getWishList?:  {
    __typename: "WishList",
    pk: string,
    id: string,
    userId?: string | null,
    productId?: string | null,
    productName?: string | null,
    productImage?: string | null,
    mainCategory?: string | null,
    mainCategoryId?: string | null,
    subCategory?: string | null,
    subCategoryId?: string | null,
    brandName?: string | null,
    brandId?: string | null,
    model?: string | null,
    rating?: number | null,
    regularPrice?: number | null,
    salePrice?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListWishListsQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelWishListFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListWishListsQuery = {
  listWishLists?:  {
    __typename: "ModelWishListConnection",
    items:  Array< {
      __typename: "WishList",
      pk: string,
      id: string,
      userId?: string | null,
      productId?: string | null,
      productName?: string | null,
      productImage?: string | null,
      mainCategory?: string | null,
      mainCategoryId?: string | null,
      subCategory?: string | null,
      subCategoryId?: string | null,
      brandName?: string | null,
      brandId?: string | null,
      model?: string | null,
      rating?: number | null,
      regularPrice?: number | null,
      salePrice?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type WishListByUserIdQueryVariables = {
  pk: string,
  userId?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWishListFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type WishListByUserIdQuery = {
  wishListByUserId?:  {
    __typename: "ModelWishListConnection",
    items:  Array< {
      __typename: "WishList",
      pk: string,
      id: string,
      userId?: string | null,
      productId?: string | null,
      productName?: string | null,
      productImage?: string | null,
      mainCategory?: string | null,
      mainCategoryId?: string | null,
      subCategory?: string | null,
      subCategoryId?: string | null,
      brandName?: string | null,
      brandId?: string | null,
      model?: string | null,
      rating?: number | null,
      regularPrice?: number | null,
      salePrice?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type WishListByProductIdQueryVariables = {
  pk: string,
  productId?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWishListFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type WishListByProductIdQuery = {
  wishListByProductId?:  {
    __typename: "ModelWishListConnection",
    items:  Array< {
      __typename: "WishList",
      pk: string,
      id: string,
      userId?: string | null,
      productId?: string | null,
      productName?: string | null,
      productImage?: string | null,
      mainCategory?: string | null,
      mainCategoryId?: string | null,
      subCategory?: string | null,
      subCategoryId?: string | null,
      brandName?: string | null,
      brandId?: string | null,
      model?: string | null,
      rating?: number | null,
      regularPrice?: number | null,
      salePrice?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetConfigurationQueryVariables = {
  pk: string,
  id: string,
};

export type GetConfigurationQuery = {
  getConfiguration?:  {
    __typename: "configuration",
    pk: string,
    id: string,
    configurationDescription?: string | null,
    continuousSettings?:  {
      __typename: "ContinuousSettings",
      prefixValue?: string | null,
      numberOfDigits?: number | null,
      includeYearSuffix?: boolean | null,
      startingNumber?: number | null,
      runningNumber?: number | null,
    } | null,
    type?: string | null,
    modifiedTime?: string | null,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListConfigurationsQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelConfigurationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListConfigurationsQuery = {
  listConfigurations?:  {
    __typename: "ModelConfigurationConnection",
    items:  Array< {
      __typename: "configuration",
      pk: string,
      id: string,
      configurationDescription?: string | null,
      continuousSettings?:  {
        __typename: "ContinuousSettings",
        prefixValue?: string | null,
        numberOfDigits?: number | null,
        includeYearSuffix?: boolean | null,
        startingNumber?: number | null,
        runningNumber?: number | null,
      } | null,
      type?: string | null,
      modifiedTime?: string | null,
      modifiedBy?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMailLogQueryVariables = {
  pk: string,
  id: string,
};

export type GetMailLogQuery = {
  getMailLog?:  {
    __typename: "MailLog",
    pk: string,
    id: string,
    referenceId?: string | null,
    referenceForm?: string | null,
    userId?: string | null,
    mailDetails?:  Array< {
      __typename: "EmailDetails",
      sentTo?: string | null,
      sentDate?: string | null,
    } | null > | null,
    updatedAt?: string | null,
    createdAt: string,
    owner?: string | null,
  } | null,
};

export type ListMailLogsQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelMailLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListMailLogsQuery = {
  listMailLogs?:  {
    __typename: "ModelMailLogConnection",
    items:  Array< {
      __typename: "MailLog",
      pk: string,
      id: string,
      referenceId?: string | null,
      referenceForm?: string | null,
      userId?: string | null,
      mailDetails?:  Array< {
        __typename: "EmailDetails",
        sentTo?: string | null,
        sentDate?: string | null,
      } | null > | null,
      updatedAt?: string | null,
      createdAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDeliveryPartnerQueryVariables = {
  pk: string,
  id: string,
};

export type GetDeliveryPartnerQuery = {
  getDeliveryPartner?:  {
    __typename: "DeliveryPartner",
    pk: string,
    id: string,
    logo?: string | null,
    logoUrl?: string | null,
    companyName?: string | null,
    displayName?: string | null,
    apiKey?: string | null,
    apiKeyExpiryDate?: string | null,
    contactPerson?: string | null,
    address?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    status?: Status | null,
    searchKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type ListDeliveryPartnersQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelDeliveryPartnerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDeliveryPartnersQuery = {
  listDeliveryPartners?:  {
    __typename: "ModelDeliveryPartnerConnection",
    items:  Array< {
      __typename: "DeliveryPartner",
      pk: string,
      id: string,
      logo?: string | null,
      logoUrl?: string | null,
      companyName?: string | null,
      displayName?: string | null,
      apiKey?: string | null,
      apiKeyExpiryDate?: string | null,
      contactPerson?: string | null,
      address?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      status?: Status | null,
      searchKey?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLedgerQueryVariables = {
  pk: string,
  id: string,
};

export type GetLedgerQuery = {
  getLedger?:  {
    __typename: "Ledger",
    pk: string,
    id: string,
    type?: string | null,
    transDate?: string | null,
    transReference?: string | null,
    transDetails?: string | null,
    transTo?: string | null,
    transToId?: string | null,
    transAmount?: number | null,
    transType?: string | null,
    paymentStatus?: string | null,
    buyerName?: string | null,
    orderRef?: string | null,
    orderAmount?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type ListLedgersQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelLedgerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListLedgersQuery = {
  listLedgers?:  {
    __typename: "ModelLedgerConnection",
    items:  Array< {
      __typename: "Ledger",
      pk: string,
      id: string,
      type?: string | null,
      transDate?: string | null,
      transReference?: string | null,
      transDetails?: string | null,
      transTo?: string | null,
      transToId?: string | null,
      transAmount?: number | null,
      transType?: string | null,
      paymentStatus?: string | null,
      buyerName?: string | null,
      orderRef?: string | null,
      orderAmount?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LedgerByTransDateQueryVariables = {
  pk: string,
  transDate?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLedgerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LedgerByTransDateQuery = {
  ledgerByTransDate?:  {
    __typename: "ModelLedgerConnection",
    items:  Array< {
      __typename: "Ledger",
      pk: string,
      id: string,
      type?: string | null,
      transDate?: string | null,
      transReference?: string | null,
      transDetails?: string | null,
      transTo?: string | null,
      transToId?: string | null,
      transAmount?: number | null,
      transType?: string | null,
      paymentStatus?: string | null,
      buyerName?: string | null,
      orderRef?: string | null,
      orderAmount?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LedgerByTransReferenceQueryVariables = {
  pk: string,
  transReference?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLedgerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LedgerByTransReferenceQuery = {
  ledgerByTransReference?:  {
    __typename: "ModelLedgerConnection",
    items:  Array< {
      __typename: "Ledger",
      pk: string,
      id: string,
      type?: string | null,
      transDate?: string | null,
      transReference?: string | null,
      transDetails?: string | null,
      transTo?: string | null,
      transToId?: string | null,
      transAmount?: number | null,
      transType?: string | null,
      paymentStatus?: string | null,
      buyerName?: string | null,
      orderRef?: string | null,
      orderAmount?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LedgerByTransToIdQueryVariables = {
  pk: string,
  transToId?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLedgerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LedgerByTransToIdQuery = {
  ledgerByTransToId?:  {
    __typename: "ModelLedgerConnection",
    items:  Array< {
      __typename: "Ledger",
      pk: string,
      id: string,
      type?: string | null,
      transDate?: string | null,
      transReference?: string | null,
      transDetails?: string | null,
      transTo?: string | null,
      transToId?: string | null,
      transAmount?: number | null,
      transType?: string | null,
      paymentStatus?: string | null,
      buyerName?: string | null,
      orderRef?: string | null,
      orderAmount?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductVisitQueryVariables = {
  pk: string,
  id: string,
};

export type GetProductVisitQuery = {
  getProductVisit?:  {
    __typename: "ProductVisit",
    pk: string,
    id: string,
    prodId?: string | null,
    userId?: string | null,
    userName?: string | null,
    visitedAt?: string | null,
    place?: string | null,
    visitedInfo?:  Array< {
      __typename: "VisitedInfo",
      platform?: string | null,
      location?: string | null,
      time?: string | null,
      ipAddress?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListProductVisitsQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelProductVisitFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListProductVisitsQuery = {
  listProductVisits?:  {
    __typename: "ModelProductVisitConnection",
    items:  Array< {
      __typename: "ProductVisit",
      pk: string,
      id: string,
      prodId?: string | null,
      userId?: string | null,
      userName?: string | null,
      visitedAt?: string | null,
      place?: string | null,
      visitedInfo?:  Array< {
        __typename: "VisitedInfo",
        platform?: string | null,
        location?: string | null,
        time?: string | null,
        ipAddress?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductVisitByVisitedAtQueryVariables = {
  pk: string,
  visitedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductVisitFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductVisitByVisitedAtQuery = {
  productVisitByVisitedAt?:  {
    __typename: "ModelProductVisitConnection",
    items:  Array< {
      __typename: "ProductVisit",
      pk: string,
      id: string,
      prodId?: string | null,
      userId?: string | null,
      userName?: string | null,
      visitedAt?: string | null,
      place?: string | null,
      visitedInfo?:  Array< {
        __typename: "VisitedInfo",
        platform?: string | null,
        location?: string | null,
        time?: string | null,
        ipAddress?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPromoCodesQueryVariables = {
  pk: string,
  id: string,
};

export type GetPromoCodesQuery = {
  getPromoCodes?:  {
    __typename: "PromoCodes",
    pk: string,
    id: string,
    promoImage?: string | null,
    promoURL?: string | null,
    code?: string | null,
    discount?:  {
      __typename: "discountType",
      type?: string | null,
      value?: string | null,
    } | null,
    redemptionType?: string | null,
    searchKey?: string | null,
    validFrom?: string | null,
    validTo?: string | null,
    description?: string | null,
    terms?: string | null,
    advancedSetting?:  {
      __typename: "AdvancedSettings",
      appliedTo?: string | null,
      specificType?:  Array< {
        __typename: "SpecificType",
        name?: string | null,
        id?: string | null,
      } | null > | null,
    } | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListPromoCodesQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelPromoCodesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPromoCodesQuery = {
  listPromoCodes?:  {
    __typename: "ModelPromoCodesConnection",
    items:  Array< {
      __typename: "PromoCodes",
      pk: string,
      id: string,
      promoImage?: string | null,
      promoURL?: string | null,
      code?: string | null,
      discount?:  {
        __typename: "discountType",
        type?: string | null,
        value?: string | null,
      } | null,
      redemptionType?: string | null,
      searchKey?: string | null,
      validFrom?: string | null,
      validTo?: string | null,
      description?: string | null,
      terms?: string | null,
      advancedSetting?:  {
        __typename: "AdvancedSettings",
        appliedTo?: string | null,
        specificType?:  Array< {
          __typename: "SpecificType",
          name?: string | null,
          id?: string | null,
        } | null > | null,
      } | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PromoCodesByDateQueryVariables = {
  pk: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPromoCodesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PromoCodesByDateQuery = {
  promoCodesByDate?:  {
    __typename: "ModelPromoCodesConnection",
    items:  Array< {
      __typename: "PromoCodes",
      pk: string,
      id: string,
      promoImage?: string | null,
      promoURL?: string | null,
      code?: string | null,
      discount?:  {
        __typename: "discountType",
        type?: string | null,
        value?: string | null,
      } | null,
      redemptionType?: string | null,
      searchKey?: string | null,
      validFrom?: string | null,
      validTo?: string | null,
      description?: string | null,
      terms?: string | null,
      advancedSetting?:  {
        __typename: "AdvancedSettings",
        appliedTo?: string | null,
        specificType?:  Array< {
          __typename: "SpecificType",
          name?: string | null,
          id?: string | null,
        } | null > | null,
      } | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetJwtTokenIdentifierQueryVariables = {
  pk: string,
  jtiValue: string,
};

export type GetJwtTokenIdentifierQuery = {
  getJwtTokenIdentifier?:  {
    __typename: "jwtTokenIdentifier",
    pk: string,
    jtiValue: string,
    issueDate?: string | null,
    expiryDateTime?: string | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListJwtTokenIdentifiersQueryVariables = {
  pk?: string | null,
  jtiValue?: ModelStringKeyConditionInput | null,
  filter?: ModelJwtTokenIdentifierFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListJwtTokenIdentifiersQuery = {
  listJwtTokenIdentifiers?:  {
    __typename: "ModelJwtTokenIdentifierConnection",
    items:  Array< {
      __typename: "jwtTokenIdentifier",
      pk: string,
      jtiValue: string,
      issueDate?: string | null,
      expiryDateTime?: string | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUnitsQueryVariables = {
  pk: string,
  id: string,
};

export type GetUnitsQuery = {
  getUnits?:  {
    __typename: "Units",
    pk: string,
    id: string,
    value?: string | null,
    sequenceNumber?: number | null,
    searchKey?: string | null,
    type?: UnitType | null,
    status?: Status | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type ListUnitsQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelUnitsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUnitsQuery = {
  listUnits?:  {
    __typename: "ModelUnitsConnection",
    items:  Array< {
      __typename: "Units",
      pk: string,
      id: string,
      value?: string | null,
      sequenceNumber?: number | null,
      searchKey?: string | null,
      type?: UnitType | null,
      status?: Status | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UnitsByTypeQueryVariables = {
  pk: string,
  type?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUnitsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UnitsByTypeQuery = {
  unitsByType?:  {
    __typename: "ModelUnitsConnection",
    items:  Array< {
      __typename: "Units",
      pk: string,
      id: string,
      value?: string | null,
      sequenceNumber?: number | null,
      searchKey?: string | null,
      type?: UnitType | null,
      status?: Status | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetShippingQueryVariables = {
  pk: string,
  id: string,
};

export type GetShippingQuery = {
  getShipping?:  {
    __typename: "Shipping",
    id: string,
    pk: string,
    city?: string | null,
    countryId?: string | null,
    countryName?: string | null,
    state?: string | null,
    deliveryCharge?: number | null,
    coverage?: Coverage | null,
    zipCodes?: Array< string | null > | null,
    latitude?: string | null,
    longitude?: string | null,
    radius?: string | null,
    searchKey?: string | null,
    status?: Status | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    owner?: string | null,
  } | null,
};

export type ListShippingsQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelShippingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListShippingsQuery = {
  listShippings?:  {
    __typename: "ModelShippingConnection",
    items:  Array< {
      __typename: "Shipping",
      id: string,
      pk: string,
      city?: string | null,
      countryId?: string | null,
      countryName?: string | null,
      state?: string | null,
      deliveryCharge?: number | null,
      coverage?: Coverage | null,
      zipCodes?: Array< string | null > | null,
      latitude?: string | null,
      longitude?: string | null,
      radius?: string | null,
      searchKey?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductQueryVariables = {
  pk: string,
  id: string,
};

export type GetProductQuery = {
  getProduct?:  {
    __typename: "Product",
    createdAt: string,
    id: string,
    pk: string,
    globalPk: string,
    productName: string,
    mainCategory?: string | null,
    mainCategoryID?: string | null,
    subCategory?: string | null,
    subCategoryID?: string | null,
    brandName?: string | null,
    brandID?: string | null,
    countryName?: string | null,
    countryID?: string | null,
    productDescription?: string | null,
    productFeatures?: string | null,
    keyWord?: Array< string | null > | null,
    featuredImage?:  {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null,
    galleryImages?:  Array< {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null > | null,
    singleVariant?: boolean | null,
    attrName?: string | null,
    attrNameID?: string | null,
    attrValue?:  Array< {
      __typename: "AttributeValues",
      value?: string | null,
      attrValueID?: string | null,
    } | null > | null,
    productSpecification?:  Array< {
      __typename: "productSpecification",
      name?: string | null,
      value?: string | null,
    } | null > | null,
    seoDescription?: string | null,
    stockStatus?: stockStatus | null,
    searchKey?: string | null,
    status?: productStatus | null,
    scheduleDate?: string | null,
    taxDetail?:  Array< {
      __typename: "taxDetail",
      taxCharge?: number | null,
      taxID?: string | null,
      taxName?: string | null,
    } | null > | null,
    showWhenZeroStock?: boolean | null,
    allowPreOrder?: boolean | null,
    overallRating?: number | null,
    noOfRating?: number | null,
    leadTime?: string | null,
    totalStockQuantity?: number | null,
    totalReservedStock?: number | null,
    displaySalePrice?: number | null,
    displayRegularPrice?: number | null,
    thumbnailUrl?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListProductsQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListProductsQuery = {
  listProducts?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      createdAt: string,
      id: string,
      pk: string,
      globalPk: string,
      productName: string,
      mainCategory?: string | null,
      mainCategoryID?: string | null,
      subCategory?: string | null,
      subCategoryID?: string | null,
      brandName?: string | null,
      brandID?: string | null,
      countryName?: string | null,
      countryID?: string | null,
      productDescription?: string | null,
      productFeatures?: string | null,
      keyWord?: Array< string | null > | null,
      featuredImage?:  {
        __typename: "imageUpload",
        alterText?: string | null,
        title?: string | null,
        url?: string | null,
        description?: string | null,
        type?: ImageType | null,
      } | null,
      galleryImages?:  Array< {
        __typename: "imageUpload",
        alterText?: string | null,
        title?: string | null,
        url?: string | null,
        description?: string | null,
        type?: ImageType | null,
      } | null > | null,
      singleVariant?: boolean | null,
      attrName?: string | null,
      attrNameID?: string | null,
      attrValue?:  Array< {
        __typename: "AttributeValues",
        value?: string | null,
        attrValueID?: string | null,
      } | null > | null,
      productSpecification?:  Array< {
        __typename: "productSpecification",
        name?: string | null,
        value?: string | null,
      } | null > | null,
      seoDescription?: string | null,
      stockStatus?: stockStatus | null,
      searchKey?: string | null,
      status?: productStatus | null,
      scheduleDate?: string | null,
      taxDetail?:  Array< {
        __typename: "taxDetail",
        taxCharge?: number | null,
        taxID?: string | null,
        taxName?: string | null,
      } | null > | null,
      showWhenZeroStock?: boolean | null,
      allowPreOrder?: boolean | null,
      overallRating?: number | null,
      noOfRating?: number | null,
      leadTime?: string | null,
      totalStockQuantity?: number | null,
      totalReservedStock?: number | null,
      displaySalePrice?: number | null,
      displayRegularPrice?: number | null,
      thumbnailUrl?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductByIdQueryVariables = {
  id: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductByIdQuery = {
  productById?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      createdAt: string,
      id: string,
      pk: string,
      globalPk: string,
      productName: string,
      mainCategory?: string | null,
      mainCategoryID?: string | null,
      subCategory?: string | null,
      subCategoryID?: string | null,
      brandName?: string | null,
      brandID?: string | null,
      countryName?: string | null,
      countryID?: string | null,
      productDescription?: string | null,
      productFeatures?: string | null,
      keyWord?: Array< string | null > | null,
      featuredImage?:  {
        __typename: "imageUpload",
        alterText?: string | null,
        title?: string | null,
        url?: string | null,
        description?: string | null,
        type?: ImageType | null,
      } | null,
      galleryImages?:  Array< {
        __typename: "imageUpload",
        alterText?: string | null,
        title?: string | null,
        url?: string | null,
        description?: string | null,
        type?: ImageType | null,
      } | null > | null,
      singleVariant?: boolean | null,
      attrName?: string | null,
      attrNameID?: string | null,
      attrValue?:  Array< {
        __typename: "AttributeValues",
        value?: string | null,
        attrValueID?: string | null,
      } | null > | null,
      productSpecification?:  Array< {
        __typename: "productSpecification",
        name?: string | null,
        value?: string | null,
      } | null > | null,
      seoDescription?: string | null,
      stockStatus?: stockStatus | null,
      searchKey?: string | null,
      status?: productStatus | null,
      scheduleDate?: string | null,
      taxDetail?:  Array< {
        __typename: "taxDetail",
        taxCharge?: number | null,
        taxID?: string | null,
        taxName?: string | null,
      } | null > | null,
      showWhenZeroStock?: boolean | null,
      allowPreOrder?: boolean | null,
      overallRating?: number | null,
      noOfRating?: number | null,
      leadTime?: string | null,
      totalStockQuantity?: number | null,
      totalReservedStock?: number | null,
      displaySalePrice?: number | null,
      displayRegularPrice?: number | null,
      thumbnailUrl?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListAllProductsQueryVariables = {
  globalPk: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAllProductsQuery = {
  listAllProducts?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      createdAt: string,
      id: string,
      pk: string,
      globalPk: string,
      productName: string,
      mainCategory?: string | null,
      mainCategoryID?: string | null,
      subCategory?: string | null,
      subCategoryID?: string | null,
      brandName?: string | null,
      brandID?: string | null,
      countryName?: string | null,
      countryID?: string | null,
      productDescription?: string | null,
      productFeatures?: string | null,
      keyWord?: Array< string | null > | null,
      featuredImage?:  {
        __typename: "imageUpload",
        alterText?: string | null,
        title?: string | null,
        url?: string | null,
        description?: string | null,
        type?: ImageType | null,
      } | null,
      galleryImages?:  Array< {
        __typename: "imageUpload",
        alterText?: string | null,
        title?: string | null,
        url?: string | null,
        description?: string | null,
        type?: ImageType | null,
      } | null > | null,
      singleVariant?: boolean | null,
      attrName?: string | null,
      attrNameID?: string | null,
      attrValue?:  Array< {
        __typename: "AttributeValues",
        value?: string | null,
        attrValueID?: string | null,
      } | null > | null,
      productSpecification?:  Array< {
        __typename: "productSpecification",
        name?: string | null,
        value?: string | null,
      } | null > | null,
      seoDescription?: string | null,
      stockStatus?: stockStatus | null,
      searchKey?: string | null,
      status?: productStatus | null,
      scheduleDate?: string | null,
      taxDetail?:  Array< {
        __typename: "taxDetail",
        taxCharge?: number | null,
        taxID?: string | null,
        taxName?: string | null,
      } | null > | null,
      showWhenZeroStock?: boolean | null,
      allowPreOrder?: boolean | null,
      overallRating?: number | null,
      noOfRating?: number | null,
      leadTime?: string | null,
      totalStockQuantity?: number | null,
      totalReservedStock?: number | null,
      displaySalePrice?: number | null,
      displayRegularPrice?: number | null,
      thumbnailUrl?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductByMainCategoryIdGsiQueryVariables = {
  mainCategoryID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductByMainCategoryIdGsiQuery = {
  productByMainCategoryIdGsi?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      createdAt: string,
      id: string,
      pk: string,
      globalPk: string,
      productName: string,
      mainCategory?: string | null,
      mainCategoryID?: string | null,
      subCategory?: string | null,
      subCategoryID?: string | null,
      brandName?: string | null,
      brandID?: string | null,
      countryName?: string | null,
      countryID?: string | null,
      productDescription?: string | null,
      productFeatures?: string | null,
      keyWord?: Array< string | null > | null,
      featuredImage?:  {
        __typename: "imageUpload",
        alterText?: string | null,
        title?: string | null,
        url?: string | null,
        description?: string | null,
        type?: ImageType | null,
      } | null,
      galleryImages?:  Array< {
        __typename: "imageUpload",
        alterText?: string | null,
        title?: string | null,
        url?: string | null,
        description?: string | null,
        type?: ImageType | null,
      } | null > | null,
      singleVariant?: boolean | null,
      attrName?: string | null,
      attrNameID?: string | null,
      attrValue?:  Array< {
        __typename: "AttributeValues",
        value?: string | null,
        attrValueID?: string | null,
      } | null > | null,
      productSpecification?:  Array< {
        __typename: "productSpecification",
        name?: string | null,
        value?: string | null,
      } | null > | null,
      seoDescription?: string | null,
      stockStatus?: stockStatus | null,
      searchKey?: string | null,
      status?: productStatus | null,
      scheduleDate?: string | null,
      taxDetail?:  Array< {
        __typename: "taxDetail",
        taxCharge?: number | null,
        taxID?: string | null,
        taxName?: string | null,
      } | null > | null,
      showWhenZeroStock?: boolean | null,
      allowPreOrder?: boolean | null,
      overallRating?: number | null,
      noOfRating?: number | null,
      leadTime?: string | null,
      totalStockQuantity?: number | null,
      totalReservedStock?: number | null,
      displaySalePrice?: number | null,
      displayRegularPrice?: number | null,
      thumbnailUrl?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductByBrandIdGsiQueryVariables = {
  brandID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductByBrandIdGsiQuery = {
  productByBrandIdGsi?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      createdAt: string,
      id: string,
      pk: string,
      globalPk: string,
      productName: string,
      mainCategory?: string | null,
      mainCategoryID?: string | null,
      subCategory?: string | null,
      subCategoryID?: string | null,
      brandName?: string | null,
      brandID?: string | null,
      countryName?: string | null,
      countryID?: string | null,
      productDescription?: string | null,
      productFeatures?: string | null,
      keyWord?: Array< string | null > | null,
      featuredImage?:  {
        __typename: "imageUpload",
        alterText?: string | null,
        title?: string | null,
        url?: string | null,
        description?: string | null,
        type?: ImageType | null,
      } | null,
      galleryImages?:  Array< {
        __typename: "imageUpload",
        alterText?: string | null,
        title?: string | null,
        url?: string | null,
        description?: string | null,
        type?: ImageType | null,
      } | null > | null,
      singleVariant?: boolean | null,
      attrName?: string | null,
      attrNameID?: string | null,
      attrValue?:  Array< {
        __typename: "AttributeValues",
        value?: string | null,
        attrValueID?: string | null,
      } | null > | null,
      productSpecification?:  Array< {
        __typename: "productSpecification",
        name?: string | null,
        value?: string | null,
      } | null > | null,
      seoDescription?: string | null,
      stockStatus?: stockStatus | null,
      searchKey?: string | null,
      status?: productStatus | null,
      scheduleDate?: string | null,
      taxDetail?:  Array< {
        __typename: "taxDetail",
        taxCharge?: number | null,
        taxID?: string | null,
        taxName?: string | null,
      } | null > | null,
      showWhenZeroStock?: boolean | null,
      allowPreOrder?: boolean | null,
      overallRating?: number | null,
      noOfRating?: number | null,
      leadTime?: string | null,
      totalStockQuantity?: number | null,
      totalReservedStock?: number | null,
      displaySalePrice?: number | null,
      displayRegularPrice?: number | null,
      thumbnailUrl?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductImageQueryVariables = {
  pk: string,
  id: string,
};

export type GetProductImageQuery = {
  getProductImage?:  {
    __typename: "ProductImage",
    pk: string,
    id: string,
    alterText?: string | null,
    title?: string | null,
    url?: string | null,
    description?: string | null,
    mainCategory?: string | null,
    mainCategoryID?: string | null,
    subCategory?: string | null,
    subCategoryID?: string | null,
    thumbnailUrl?: string | null,
    type?: ImageType | null,
    productID?: string | null,
    isDeleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListProductImagesQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelProductImageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListProductImagesQuery = {
  listProductImages?:  {
    __typename: "ModelProductImageConnection",
    items:  Array< {
      __typename: "ProductImage",
      pk: string,
      id: string,
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      mainCategory?: string | null,
      mainCategoryID?: string | null,
      subCategory?: string | null,
      subCategoryID?: string | null,
      thumbnailUrl?: string | null,
      type?: ImageType | null,
      productID?: string | null,
      isDeleted?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ItemsByImgCategoryQueryVariables = {
  pk: string,
  mainCategoryIDSubCategoryID?: ModelProductImageProductImageByMainCategoryIDSubCategoryIDIndexCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductImageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ItemsByImgCategoryQuery = {
  itemsByImgCategory?:  {
    __typename: "ModelProductImageConnection",
    items:  Array< {
      __typename: "ProductImage",
      pk: string,
      id: string,
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      mainCategory?: string | null,
      mainCategoryID?: string | null,
      subCategory?: string | null,
      subCategoryID?: string | null,
      thumbnailUrl?: string | null,
      type?: ImageType | null,
      productID?: string | null,
      isDeleted?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductDetailsQueryVariables = {
  pk: string,
  id: string,
};

export type GetProductDetailsQuery = {
  getProductDetails?:  {
    __typename: "ProductDetails",
    id: string,
    pk: string,
    productID?: string | null,
    attrName?: string | null,
    attrNameID?: string | null,
    attrValue?: string | null,
    attrValueID?: string | null,
    featuredImage?:  {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null,
    model?: string | null,
    manufacturePartCode?: string | null,
    sku?: string | null,
    stockStatus?: stockStatus | null,
    stockQuantity?: number | null,
    reservedStock?: number | null,
    stockUnit?: string | null,
    itemsPerUnit?: number | null,
    regularPrice?: number | null,
    salePrice?: number | null,
    dataSheet?: string | null,
    submittal?: string | null,
    catalog?: string | null,
    iom?: string | null,
    storageInfo?:  {
      __typename: "storageInfoDetail",
      wareHouseId?: string | null,
      wareHouse?: string | null,
      rackNo?: string | null,
      rowNo?: string | null,
      binNo?: string | null,
      note?: string | null,
    } | null,
    shipplingInfo?:  {
      __typename: "shippingDetails",
      weightValue?: string | null,
      weightUnit?: string | null,
      lengthValue?: string | null,
      lengthUnit?: string | null,
      heightValue?: string | null,
      heightUnit?: string | null,
      widthValue?: string | null,
      widthUnit?: string | null,
    } | null,
    partialReturn?: boolean | null,
    type?: VariantType | null,
    isDeleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListProductDetailsQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelProductDetailsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListProductDetailsQuery = {
  listProductDetails?:  {
    __typename: "ModelProductDetailsConnection",
    items:  Array< {
      __typename: "ProductDetails",
      id: string,
      pk: string,
      productID?: string | null,
      attrName?: string | null,
      attrNameID?: string | null,
      attrValue?: string | null,
      attrValueID?: string | null,
      featuredImage?:  {
        __typename: "imageUpload",
        alterText?: string | null,
        title?: string | null,
        url?: string | null,
        description?: string | null,
        type?: ImageType | null,
      } | null,
      model?: string | null,
      manufacturePartCode?: string | null,
      sku?: string | null,
      stockStatus?: stockStatus | null,
      stockQuantity?: number | null,
      reservedStock?: number | null,
      stockUnit?: string | null,
      itemsPerUnit?: number | null,
      regularPrice?: number | null,
      salePrice?: number | null,
      dataSheet?: string | null,
      submittal?: string | null,
      catalog?: string | null,
      iom?: string | null,
      storageInfo?:  {
        __typename: "storageInfoDetail",
        wareHouseId?: string | null,
        wareHouse?: string | null,
        rackNo?: string | null,
        rowNo?: string | null,
        binNo?: string | null,
        note?: string | null,
      } | null,
      shipplingInfo?:  {
        __typename: "shippingDetails",
        weightValue?: string | null,
        weightUnit?: string | null,
        lengthValue?: string | null,
        lengthUnit?: string | null,
        heightValue?: string | null,
        heightUnit?: string | null,
        widthValue?: string | null,
        widthUnit?: string | null,
      } | null,
      partialReturn?: boolean | null,
      type?: VariantType | null,
      isDeleted?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductDetailsByProductIDQueryVariables = {
  pk: string,
  productID?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductDetailsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductDetailsByProductIDQuery = {
  productDetailsByProductID?:  {
    __typename: "ModelProductDetailsConnection",
    items:  Array< {
      __typename: "ProductDetails",
      id: string,
      pk: string,
      productID?: string | null,
      attrName?: string | null,
      attrNameID?: string | null,
      attrValue?: string | null,
      attrValueID?: string | null,
      featuredImage?:  {
        __typename: "imageUpload",
        alterText?: string | null,
        title?: string | null,
        url?: string | null,
        description?: string | null,
        type?: ImageType | null,
      } | null,
      model?: string | null,
      manufacturePartCode?: string | null,
      sku?: string | null,
      stockStatus?: stockStatus | null,
      stockQuantity?: number | null,
      reservedStock?: number | null,
      stockUnit?: string | null,
      itemsPerUnit?: number | null,
      regularPrice?: number | null,
      salePrice?: number | null,
      dataSheet?: string | null,
      submittal?: string | null,
      catalog?: string | null,
      iom?: string | null,
      storageInfo?:  {
        __typename: "storageInfoDetail",
        wareHouseId?: string | null,
        wareHouse?: string | null,
        rackNo?: string | null,
        rowNo?: string | null,
        binNo?: string | null,
        note?: string | null,
      } | null,
      shipplingInfo?:  {
        __typename: "shippingDetails",
        weightValue?: string | null,
        weightUnit?: string | null,
        lengthValue?: string | null,
        lengthUnit?: string | null,
        heightValue?: string | null,
        heightUnit?: string | null,
        widthValue?: string | null,
        widthUnit?: string | null,
      } | null,
      partialReturn?: boolean | null,
      type?: VariantType | null,
      isDeleted?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrderQueryVariables = {
  pk: string,
  id: string,
};

export type GetOrderQuery = {
  getOrder?:  {
    __typename: "Order",
    pk: string,
    id: string,
    userId: string,
    createdAt: string,
    userName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    orderType?: string | null,
    totalProducts?: number | null,
    orderSummary?:  {
      __typename: "OrderSummary",
      cartTotal?: number | null,
      productDiscount?: string | null,
      deliveryFee?: number | null,
      tax?: number | null,
    } | null,
    totalAmount?: number | null,
    paymentDetails?:  {
      __typename: "PaymentDetails",
      paymentMode?: string | null,
      paymentMethod?: PaymentMethod | null,
      cardType?: string | null,
      cardHolderName?: string | null,
      cardNumber?: string | null,
      CardExpiryDate?: string | null,
      UPI?: boolean | null,
      paymentDate?: string | null,
      referenceNumber?: string | null,
      chequeNumber?: string | null,
      bankName?: string | null,
      chequeDate?: string | null,
      receivedAmount?: number | null,
      remaningAmount?: number | null,
      chequeStatus?: ChequeStatus | null,
      partialPaymentDetails?:  Array< {
        __typename: "CashOnDeliveryCollection",
        chequeNumber?: string | null,
        bankName?: string | null,
        chequeDate?: string | null,
        receivedAmount?: number | null,
        chequeStatus?: ChequeStatus | null,
        paymentDate?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null > | null,
    } | null,
    paymentStatus?: PaymentStatus | null,
    status?: OrderStatus | null,
    wishListId?: string | null,
    couponDetails?:  {
      __typename: "CouponDetails",
      couponCode?: string | null,
      amount?: number | null,
      discount?:  {
        __typename: "discountType",
        type?: string | null,
        value?: string | null,
      } | null,
      advancedSetting?:  {
        __typename: "AdvancedSettings",
        appliedTo?: string | null,
        specificType?:  Array< {
          __typename: "SpecificType",
          name?: string | null,
          id?: string | null,
        } | null > | null,
      } | null,
    } | null,
    discountByCoupon?: number | null,
    shippingUserDetails?:  {
      __typename: "UserDetails",
      userName?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
    } | null,
    shippingAddress?:  {
      __typename: "Address",
      addressLine1?: string | null,
      addressLine2?: string | null,
      street?: string | null,
      landmark?: string | null,
      state?: string | null,
      city?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    deliveryInfo?:  {
      __typename: "DeliveryInfo",
      deliveryPartner?: string | null,
      deliveryType?: string | null,
      productWeight?: string | null,
    } | null,
    billingUserDetails?:  {
      __typename: "UserDetails",
      userName?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
    } | null,
    billingAddress?:  {
      __typename: "Address",
      addressLine1?: string | null,
      addressLine2?: string | null,
      street?: string | null,
      landmark?: string | null,
      state?: string | null,
      city?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    deliveryDate?: string | null,
    invoiceNumber?: string | null,
    invoiceURL?: string | null,
    orderPdf?: string | null,
    searchKey?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListOrdersQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOrdersQuery = {
  listOrders?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      pk: string,
      id: string,
      userId: string,
      createdAt: string,
      userName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      orderType?: string | null,
      totalProducts?: number | null,
      orderSummary?:  {
        __typename: "OrderSummary",
        cartTotal?: number | null,
        productDiscount?: string | null,
        deliveryFee?: number | null,
        tax?: number | null,
      } | null,
      totalAmount?: number | null,
      paymentDetails?:  {
        __typename: "PaymentDetails",
        paymentMode?: string | null,
        paymentMethod?: PaymentMethod | null,
        cardType?: string | null,
        cardHolderName?: string | null,
        cardNumber?: string | null,
        CardExpiryDate?: string | null,
        UPI?: boolean | null,
        paymentDate?: string | null,
        referenceNumber?: string | null,
        chequeNumber?: string | null,
        bankName?: string | null,
        chequeDate?: string | null,
        receivedAmount?: number | null,
        remaningAmount?: number | null,
        chequeStatus?: ChequeStatus | null,
        partialPaymentDetails?:  Array< {
          __typename: "CashOnDeliveryCollection",
          chequeNumber?: string | null,
          bankName?: string | null,
          chequeDate?: string | null,
          receivedAmount?: number | null,
          chequeStatus?: ChequeStatus | null,
          paymentDate?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null > | null,
      } | null,
      paymentStatus?: PaymentStatus | null,
      status?: OrderStatus | null,
      wishListId?: string | null,
      couponDetails?:  {
        __typename: "CouponDetails",
        couponCode?: string | null,
        amount?: number | null,
        discount?:  {
          __typename: "discountType",
          type?: string | null,
          value?: string | null,
        } | null,
        advancedSetting?:  {
          __typename: "AdvancedSettings",
          appliedTo?: string | null,
          specificType?:  Array< {
            __typename: "SpecificType",
            name?: string | null,
            id?: string | null,
          } | null > | null,
        } | null,
      } | null,
      discountByCoupon?: number | null,
      shippingUserDetails?:  {
        __typename: "UserDetails",
        userName?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
      } | null,
      shippingAddress?:  {
        __typename: "Address",
        addressLine1?: string | null,
        addressLine2?: string | null,
        street?: string | null,
        landmark?: string | null,
        state?: string | null,
        city?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      deliveryInfo?:  {
        __typename: "DeliveryInfo",
        deliveryPartner?: string | null,
        deliveryType?: string | null,
        productWeight?: string | null,
      } | null,
      billingUserDetails?:  {
        __typename: "UserDetails",
        userName?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
      } | null,
      billingAddress?:  {
        __typename: "Address",
        addressLine1?: string | null,
        addressLine2?: string | null,
        street?: string | null,
        landmark?: string | null,
        state?: string | null,
        city?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      deliveryDate?: string | null,
      invoiceNumber?: string | null,
      invoiceURL?: string | null,
      orderPdf?: string | null,
      searchKey?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrderByDateQueryVariables = {
  pk: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrderByDateQuery = {
  orderByDate?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      pk: string,
      id: string,
      userId: string,
      createdAt: string,
      userName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      orderType?: string | null,
      totalProducts?: number | null,
      orderSummary?:  {
        __typename: "OrderSummary",
        cartTotal?: number | null,
        productDiscount?: string | null,
        deliveryFee?: number | null,
        tax?: number | null,
      } | null,
      totalAmount?: number | null,
      paymentDetails?:  {
        __typename: "PaymentDetails",
        paymentMode?: string | null,
        paymentMethod?: PaymentMethod | null,
        cardType?: string | null,
        cardHolderName?: string | null,
        cardNumber?: string | null,
        CardExpiryDate?: string | null,
        UPI?: boolean | null,
        paymentDate?: string | null,
        referenceNumber?: string | null,
        chequeNumber?: string | null,
        bankName?: string | null,
        chequeDate?: string | null,
        receivedAmount?: number | null,
        remaningAmount?: number | null,
        chequeStatus?: ChequeStatus | null,
        partialPaymentDetails?:  Array< {
          __typename: "CashOnDeliveryCollection",
          chequeNumber?: string | null,
          bankName?: string | null,
          chequeDate?: string | null,
          receivedAmount?: number | null,
          chequeStatus?: ChequeStatus | null,
          paymentDate?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null > | null,
      } | null,
      paymentStatus?: PaymentStatus | null,
      status?: OrderStatus | null,
      wishListId?: string | null,
      couponDetails?:  {
        __typename: "CouponDetails",
        couponCode?: string | null,
        amount?: number | null,
        discount?:  {
          __typename: "discountType",
          type?: string | null,
          value?: string | null,
        } | null,
        advancedSetting?:  {
          __typename: "AdvancedSettings",
          appliedTo?: string | null,
          specificType?:  Array< {
            __typename: "SpecificType",
            name?: string | null,
            id?: string | null,
          } | null > | null,
        } | null,
      } | null,
      discountByCoupon?: number | null,
      shippingUserDetails?:  {
        __typename: "UserDetails",
        userName?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
      } | null,
      shippingAddress?:  {
        __typename: "Address",
        addressLine1?: string | null,
        addressLine2?: string | null,
        street?: string | null,
        landmark?: string | null,
        state?: string | null,
        city?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      deliveryInfo?:  {
        __typename: "DeliveryInfo",
        deliveryPartner?: string | null,
        deliveryType?: string | null,
        productWeight?: string | null,
      } | null,
      billingUserDetails?:  {
        __typename: "UserDetails",
        userName?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
      } | null,
      billingAddress?:  {
        __typename: "Address",
        addressLine1?: string | null,
        addressLine2?: string | null,
        street?: string | null,
        landmark?: string | null,
        state?: string | null,
        city?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      deliveryDate?: string | null,
      invoiceNumber?: string | null,
      invoiceURL?: string | null,
      orderPdf?: string | null,
      searchKey?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrderByUserIdQueryVariables = {
  pk: string,
  userId?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrderByUserIdQuery = {
  orderByUserId?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      pk: string,
      id: string,
      userId: string,
      createdAt: string,
      userName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      orderType?: string | null,
      totalProducts?: number | null,
      orderSummary?:  {
        __typename: "OrderSummary",
        cartTotal?: number | null,
        productDiscount?: string | null,
        deliveryFee?: number | null,
        tax?: number | null,
      } | null,
      totalAmount?: number | null,
      paymentDetails?:  {
        __typename: "PaymentDetails",
        paymentMode?: string | null,
        paymentMethod?: PaymentMethod | null,
        cardType?: string | null,
        cardHolderName?: string | null,
        cardNumber?: string | null,
        CardExpiryDate?: string | null,
        UPI?: boolean | null,
        paymentDate?: string | null,
        referenceNumber?: string | null,
        chequeNumber?: string | null,
        bankName?: string | null,
        chequeDate?: string | null,
        receivedAmount?: number | null,
        remaningAmount?: number | null,
        chequeStatus?: ChequeStatus | null,
        partialPaymentDetails?:  Array< {
          __typename: "CashOnDeliveryCollection",
          chequeNumber?: string | null,
          bankName?: string | null,
          chequeDate?: string | null,
          receivedAmount?: number | null,
          chequeStatus?: ChequeStatus | null,
          paymentDate?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null > | null,
      } | null,
      paymentStatus?: PaymentStatus | null,
      status?: OrderStatus | null,
      wishListId?: string | null,
      couponDetails?:  {
        __typename: "CouponDetails",
        couponCode?: string | null,
        amount?: number | null,
        discount?:  {
          __typename: "discountType",
          type?: string | null,
          value?: string | null,
        } | null,
        advancedSetting?:  {
          __typename: "AdvancedSettings",
          appliedTo?: string | null,
          specificType?:  Array< {
            __typename: "SpecificType",
            name?: string | null,
            id?: string | null,
          } | null > | null,
        } | null,
      } | null,
      discountByCoupon?: number | null,
      shippingUserDetails?:  {
        __typename: "UserDetails",
        userName?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
      } | null,
      shippingAddress?:  {
        __typename: "Address",
        addressLine1?: string | null,
        addressLine2?: string | null,
        street?: string | null,
        landmark?: string | null,
        state?: string | null,
        city?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      deliveryInfo?:  {
        __typename: "DeliveryInfo",
        deliveryPartner?: string | null,
        deliveryType?: string | null,
        productWeight?: string | null,
      } | null,
      billingUserDetails?:  {
        __typename: "UserDetails",
        userName?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
      } | null,
      billingAddress?:  {
        __typename: "Address",
        addressLine1?: string | null,
        addressLine2?: string | null,
        street?: string | null,
        landmark?: string | null,
        state?: string | null,
        city?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      deliveryDate?: string | null,
      invoiceNumber?: string | null,
      invoiceURL?: string | null,
      orderPdf?: string | null,
      searchKey?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrderByStatusQueryVariables = {
  pk: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetOrderByStatusQuery = {
  getOrderByStatus?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      pk: string,
      id: string,
      userId: string,
      createdAt: string,
      userName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      orderType?: string | null,
      totalProducts?: number | null,
      orderSummary?:  {
        __typename: "OrderSummary",
        cartTotal?: number | null,
        productDiscount?: string | null,
        deliveryFee?: number | null,
        tax?: number | null,
      } | null,
      totalAmount?: number | null,
      paymentDetails?:  {
        __typename: "PaymentDetails",
        paymentMode?: string | null,
        paymentMethod?: PaymentMethod | null,
        cardType?: string | null,
        cardHolderName?: string | null,
        cardNumber?: string | null,
        CardExpiryDate?: string | null,
        UPI?: boolean | null,
        paymentDate?: string | null,
        referenceNumber?: string | null,
        chequeNumber?: string | null,
        bankName?: string | null,
        chequeDate?: string | null,
        receivedAmount?: number | null,
        remaningAmount?: number | null,
        chequeStatus?: ChequeStatus | null,
        partialPaymentDetails?:  Array< {
          __typename: "CashOnDeliveryCollection",
          chequeNumber?: string | null,
          bankName?: string | null,
          chequeDate?: string | null,
          receivedAmount?: number | null,
          chequeStatus?: ChequeStatus | null,
          paymentDate?: string | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null > | null,
      } | null,
      paymentStatus?: PaymentStatus | null,
      status?: OrderStatus | null,
      wishListId?: string | null,
      couponDetails?:  {
        __typename: "CouponDetails",
        couponCode?: string | null,
        amount?: number | null,
        discount?:  {
          __typename: "discountType",
          type?: string | null,
          value?: string | null,
        } | null,
        advancedSetting?:  {
          __typename: "AdvancedSettings",
          appliedTo?: string | null,
          specificType?:  Array< {
            __typename: "SpecificType",
            name?: string | null,
            id?: string | null,
          } | null > | null,
        } | null,
      } | null,
      discountByCoupon?: number | null,
      shippingUserDetails?:  {
        __typename: "UserDetails",
        userName?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
      } | null,
      shippingAddress?:  {
        __typename: "Address",
        addressLine1?: string | null,
        addressLine2?: string | null,
        street?: string | null,
        landmark?: string | null,
        state?: string | null,
        city?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      deliveryInfo?:  {
        __typename: "DeliveryInfo",
        deliveryPartner?: string | null,
        deliveryType?: string | null,
        productWeight?: string | null,
      } | null,
      billingUserDetails?:  {
        __typename: "UserDetails",
        userName?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
      } | null,
      billingAddress?:  {
        __typename: "Address",
        addressLine1?: string | null,
        addressLine2?: string | null,
        street?: string | null,
        landmark?: string | null,
        state?: string | null,
        city?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      deliveryDate?: string | null,
      invoiceNumber?: string | null,
      invoiceURL?: string | null,
      orderPdf?: string | null,
      searchKey?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrderItemQueryVariables = {
  pk: string,
  id: string,
};

export type GetOrderItemQuery = {
  getOrderItem?:  {
    __typename: "OrderItem",
    pk: string,
    id: string,
    productId?: string | null,
    mainCategoryId?: string | null,
    mainCategory?: string | null,
    subCategoryId?: string | null,
    subCategory?: string | null,
    userId: string,
    wareHouseId?: string | null,
    attrValueID?: string | null,
    wareHouseName?: string | null,
    userName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    createdAt: string,
    quantity?: number | null,
    stockUnit?: string | null,
    itemsPerUnit?: number | null,
    taxCode?: string | null,
    taxAmount?: number | null,
    taxDetail?:  Array< {
      __typename: "taxDetail",
      taxCharge?: number | null,
      taxID?: string | null,
      taxName?: string | null,
    } | null > | null,
    regularPrice?: number | null,
    totalPrice?: number | null,
    itemDetail?:  {
      __typename: "ItemDetail",
      productName?: string | null,
      price?: number | null,
      image?: string | null,
      brand?: string | null,
      model?: string | null,
      tax?: number | null,
      currentStock?: string | null,
      sku?: string | null,
      qrcode?: string | null,
    } | null,
    sku?: string | null,
    attrName?: string | null,
    attrValue?: string | null,
    deliveryStatus?: ProductDeliveryStatus | null,
    deliveryBy?: string | null,
    deliveryTracking?:  Array< {
      __typename: "DeliveryTracking",
      status?: ProductDeliveryStatus | null,
      date?: string | null,
      trackingBy?: string | null,
      phoneNumber?: string | null,
      remarks?: string | null,
      receipt?: string | null,
    } | null > | null,
    deliveryPartner?: string | null,
    outForDeliveryDate?: string | null,
    outForDeliveryTime?: string | null,
    expectedDeliveryDate?: string | null,
    trackingID?: string | null,
    trackingUrl?: string | null,
    deliveryPersonInfo?:  {
      __typename: "DeliveryPersonInfo",
      name?: string | null,
      contactNumber?: string | null,
      vehiclenumber?: string | null,
      notes?: string | null,
    } | null,
    orderedOn?: string | null,
    returnRefundActivity?:  {
      __typename: "ReturnRefundActivity",
      returnReference?: string | null,
      reason?: string | null,
      comment?: string | null,
      quantity?: string | null,
      subTotal?: number | null,
      total?: number | null,
      attachments?: Array< string | null > | null,
      paymentMode?: string | null,
      paymentMethod?: PaymentMethod | null,
      returnType?: ReturnType | null,
      returnAddress?:  {
        __typename: "Address",
        addressLine1?: string | null,
        addressLine2?: string | null,
        street?: string | null,
        landmark?: string | null,
        state?: string | null,
        city?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      pickUpDetails?:  {
        __typename: "PickUpDetails",
        pickUpAddress?:  {
          __typename: "Address",
          addressLine1?: string | null,
          addressLine2?: string | null,
          street?: string | null,
          landmark?: string | null,
          state?: string | null,
          city?: string | null,
          postalCode?: string | null,
          country?: string | null,
        } | null,
        pickUpBy?: string | null,
        deliveryPartner?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        expectedPickUpDate?: string | null,
        time?: string | null,
      } | null,
      receivedInfo?:  {
        __typename: "ReceivedInfo",
        receivedOn?: string | null,
        receivedAs?: string | null,
        images?: Array< string | null > | null,
      } | null,
      updateInStock?: boolean | null,
      updateInStockQty?: string | null,
      refundInfo?:  {
        __typename: "RefundInfo",
        date?: string | null,
        paymentMethod?: PaymentMethod | null,
        refundAmount?: number | null,
        refundInfo?: string | null,
        proofImage?: string | null,
        proofImageUrl?: string | null,
        rejectedReason?: string | null,
      } | null,
      status?: ReturnActivityStatus | null,
      returnStatusUpdate?:  Array< {
        __typename: "ReturnUpdates",
        returnStatus?: ReturnActivityStatus | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null > | null,
      rejectReason?: string | null,
      createdAt?: string | null,
    } | null,
    eta?: string | null,
    paymentStatus?: PaymentStatus | null,
    billOfLading?: string | null,
    packingList?: string | null,
    searchKey?: string | null,
    shippingInfo?:  {
      __typename: "shippingDetails",
      weightValue?: string | null,
      weightUnit?: string | null,
      lengthValue?: string | null,
      lengthUnit?: string | null,
      heightValue?: string | null,
      heightUnit?: string | null,
      widthValue?: string | null,
      widthUnit?: string | null,
    } | null,
    returnAvailable?: boolean | null,
    disputeResolved?: boolean | null,
    brandId?: string | null,
    returnValue?: number | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListOrderItemsQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelOrderItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOrderItemsQuery = {
  listOrderItems?:  {
    __typename: "ModelOrderItemConnection",
    items:  Array< {
      __typename: "OrderItem",
      pk: string,
      id: string,
      productId?: string | null,
      mainCategoryId?: string | null,
      mainCategory?: string | null,
      subCategoryId?: string | null,
      subCategory?: string | null,
      userId: string,
      wareHouseId?: string | null,
      attrValueID?: string | null,
      wareHouseName?: string | null,
      userName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      createdAt: string,
      quantity?: number | null,
      stockUnit?: string | null,
      itemsPerUnit?: number | null,
      taxCode?: string | null,
      taxAmount?: number | null,
      taxDetail?:  Array< {
        __typename: "taxDetail",
        taxCharge?: number | null,
        taxID?: string | null,
        taxName?: string | null,
      } | null > | null,
      regularPrice?: number | null,
      totalPrice?: number | null,
      itemDetail?:  {
        __typename: "ItemDetail",
        productName?: string | null,
        price?: number | null,
        image?: string | null,
        brand?: string | null,
        model?: string | null,
        tax?: number | null,
        currentStock?: string | null,
        sku?: string | null,
        qrcode?: string | null,
      } | null,
      sku?: string | null,
      attrName?: string | null,
      attrValue?: string | null,
      deliveryStatus?: ProductDeliveryStatus | null,
      deliveryBy?: string | null,
      deliveryTracking?:  Array< {
        __typename: "DeliveryTracking",
        status?: ProductDeliveryStatus | null,
        date?: string | null,
        trackingBy?: string | null,
        phoneNumber?: string | null,
        remarks?: string | null,
        receipt?: string | null,
      } | null > | null,
      deliveryPartner?: string | null,
      outForDeliveryDate?: string | null,
      outForDeliveryTime?: string | null,
      expectedDeliveryDate?: string | null,
      trackingID?: string | null,
      trackingUrl?: string | null,
      deliveryPersonInfo?:  {
        __typename: "DeliveryPersonInfo",
        name?: string | null,
        contactNumber?: string | null,
        vehiclenumber?: string | null,
        notes?: string | null,
      } | null,
      orderedOn?: string | null,
      returnRefundActivity?:  {
        __typename: "ReturnRefundActivity",
        returnReference?: string | null,
        reason?: string | null,
        comment?: string | null,
        quantity?: string | null,
        subTotal?: number | null,
        total?: number | null,
        attachments?: Array< string | null > | null,
        paymentMode?: string | null,
        paymentMethod?: PaymentMethod | null,
        returnType?: ReturnType | null,
        returnAddress?:  {
          __typename: "Address",
          addressLine1?: string | null,
          addressLine2?: string | null,
          street?: string | null,
          landmark?: string | null,
          state?: string | null,
          city?: string | null,
          postalCode?: string | null,
          country?: string | null,
        } | null,
        pickUpDetails?:  {
          __typename: "PickUpDetails",
          pickUpAddress?:  {
            __typename: "Address",
            addressLine1?: string | null,
            addressLine2?: string | null,
            street?: string | null,
            landmark?: string | null,
            state?: string | null,
            city?: string | null,
            postalCode?: string | null,
            country?: string | null,
          } | null,
          pickUpBy?: string | null,
          deliveryPartner?: string | null,
          name?: string | null,
          phoneNumber?: string | null,
          expectedPickUpDate?: string | null,
          time?: string | null,
        } | null,
        receivedInfo?:  {
          __typename: "ReceivedInfo",
          receivedOn?: string | null,
          receivedAs?: string | null,
          images?: Array< string | null > | null,
        } | null,
        updateInStock?: boolean | null,
        updateInStockQty?: string | null,
        refundInfo?:  {
          __typename: "RefundInfo",
          date?: string | null,
          paymentMethod?: PaymentMethod | null,
          refundAmount?: number | null,
          refundInfo?: string | null,
          proofImage?: string | null,
          proofImageUrl?: string | null,
          rejectedReason?: string | null,
        } | null,
        status?: ReturnActivityStatus | null,
        returnStatusUpdate?:  Array< {
          __typename: "ReturnUpdates",
          returnStatus?: ReturnActivityStatus | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null > | null,
        rejectReason?: string | null,
        createdAt?: string | null,
      } | null,
      eta?: string | null,
      paymentStatus?: PaymentStatus | null,
      billOfLading?: string | null,
      packingList?: string | null,
      searchKey?: string | null,
      shippingInfo?:  {
        __typename: "shippingDetails",
        weightValue?: string | null,
        weightUnit?: string | null,
        lengthValue?: string | null,
        lengthUnit?: string | null,
        heightValue?: string | null,
        heightUnit?: string | null,
        widthValue?: string | null,
        widthUnit?: string | null,
      } | null,
      returnAvailable?: boolean | null,
      disputeResolved?: boolean | null,
      brandId?: string | null,
      returnValue?: number | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrderItemByCreatedAtQueryVariables = {
  pk: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrderItemByCreatedAtQuery = {
  orderItemByCreatedAt?:  {
    __typename: "ModelOrderItemConnection",
    items:  Array< {
      __typename: "OrderItem",
      pk: string,
      id: string,
      productId?: string | null,
      mainCategoryId?: string | null,
      mainCategory?: string | null,
      subCategoryId?: string | null,
      subCategory?: string | null,
      userId: string,
      wareHouseId?: string | null,
      attrValueID?: string | null,
      wareHouseName?: string | null,
      userName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      createdAt: string,
      quantity?: number | null,
      stockUnit?: string | null,
      itemsPerUnit?: number | null,
      taxCode?: string | null,
      taxAmount?: number | null,
      taxDetail?:  Array< {
        __typename: "taxDetail",
        taxCharge?: number | null,
        taxID?: string | null,
        taxName?: string | null,
      } | null > | null,
      regularPrice?: number | null,
      totalPrice?: number | null,
      itemDetail?:  {
        __typename: "ItemDetail",
        productName?: string | null,
        price?: number | null,
        image?: string | null,
        brand?: string | null,
        model?: string | null,
        tax?: number | null,
        currentStock?: string | null,
        sku?: string | null,
        qrcode?: string | null,
      } | null,
      sku?: string | null,
      attrName?: string | null,
      attrValue?: string | null,
      deliveryStatus?: ProductDeliveryStatus | null,
      deliveryBy?: string | null,
      deliveryTracking?:  Array< {
        __typename: "DeliveryTracking",
        status?: ProductDeliveryStatus | null,
        date?: string | null,
        trackingBy?: string | null,
        phoneNumber?: string | null,
        remarks?: string | null,
        receipt?: string | null,
      } | null > | null,
      deliveryPartner?: string | null,
      outForDeliveryDate?: string | null,
      outForDeliveryTime?: string | null,
      expectedDeliveryDate?: string | null,
      trackingID?: string | null,
      trackingUrl?: string | null,
      deliveryPersonInfo?:  {
        __typename: "DeliveryPersonInfo",
        name?: string | null,
        contactNumber?: string | null,
        vehiclenumber?: string | null,
        notes?: string | null,
      } | null,
      orderedOn?: string | null,
      returnRefundActivity?:  {
        __typename: "ReturnRefundActivity",
        returnReference?: string | null,
        reason?: string | null,
        comment?: string | null,
        quantity?: string | null,
        subTotal?: number | null,
        total?: number | null,
        attachments?: Array< string | null > | null,
        paymentMode?: string | null,
        paymentMethod?: PaymentMethod | null,
        returnType?: ReturnType | null,
        returnAddress?:  {
          __typename: "Address",
          addressLine1?: string | null,
          addressLine2?: string | null,
          street?: string | null,
          landmark?: string | null,
          state?: string | null,
          city?: string | null,
          postalCode?: string | null,
          country?: string | null,
        } | null,
        pickUpDetails?:  {
          __typename: "PickUpDetails",
          pickUpAddress?:  {
            __typename: "Address",
            addressLine1?: string | null,
            addressLine2?: string | null,
            street?: string | null,
            landmark?: string | null,
            state?: string | null,
            city?: string | null,
            postalCode?: string | null,
            country?: string | null,
          } | null,
          pickUpBy?: string | null,
          deliveryPartner?: string | null,
          name?: string | null,
          phoneNumber?: string | null,
          expectedPickUpDate?: string | null,
          time?: string | null,
        } | null,
        receivedInfo?:  {
          __typename: "ReceivedInfo",
          receivedOn?: string | null,
          receivedAs?: string | null,
          images?: Array< string | null > | null,
        } | null,
        updateInStock?: boolean | null,
        updateInStockQty?: string | null,
        refundInfo?:  {
          __typename: "RefundInfo",
          date?: string | null,
          paymentMethod?: PaymentMethod | null,
          refundAmount?: number | null,
          refundInfo?: string | null,
          proofImage?: string | null,
          proofImageUrl?: string | null,
          rejectedReason?: string | null,
        } | null,
        status?: ReturnActivityStatus | null,
        returnStatusUpdate?:  Array< {
          __typename: "ReturnUpdates",
          returnStatus?: ReturnActivityStatus | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null > | null,
        rejectReason?: string | null,
        createdAt?: string | null,
      } | null,
      eta?: string | null,
      paymentStatus?: PaymentStatus | null,
      billOfLading?: string | null,
      packingList?: string | null,
      searchKey?: string | null,
      shippingInfo?:  {
        __typename: "shippingDetails",
        weightValue?: string | null,
        weightUnit?: string | null,
        lengthValue?: string | null,
        lengthUnit?: string | null,
        heightValue?: string | null,
        heightUnit?: string | null,
        widthValue?: string | null,
        widthUnit?: string | null,
      } | null,
      returnAvailable?: boolean | null,
      disputeResolved?: boolean | null,
      brandId?: string | null,
      returnValue?: number | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrderItemByStatusQueryVariables = {
  pk: string,
  deliveryStatusCreatedAt?: ModelOrderItemByStatusCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrderItemByStatusQuery = {
  orderItemByStatus?:  {
    __typename: "ModelOrderItemConnection",
    items:  Array< {
      __typename: "OrderItem",
      pk: string,
      id: string,
      productId?: string | null,
      mainCategoryId?: string | null,
      mainCategory?: string | null,
      subCategoryId?: string | null,
      subCategory?: string | null,
      userId: string,
      wareHouseId?: string | null,
      attrValueID?: string | null,
      wareHouseName?: string | null,
      userName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      createdAt: string,
      quantity?: number | null,
      stockUnit?: string | null,
      itemsPerUnit?: number | null,
      taxCode?: string | null,
      taxAmount?: number | null,
      taxDetail?:  Array< {
        __typename: "taxDetail",
        taxCharge?: number | null,
        taxID?: string | null,
        taxName?: string | null,
      } | null > | null,
      regularPrice?: number | null,
      totalPrice?: number | null,
      itemDetail?:  {
        __typename: "ItemDetail",
        productName?: string | null,
        price?: number | null,
        image?: string | null,
        brand?: string | null,
        model?: string | null,
        tax?: number | null,
        currentStock?: string | null,
        sku?: string | null,
        qrcode?: string | null,
      } | null,
      sku?: string | null,
      attrName?: string | null,
      attrValue?: string | null,
      deliveryStatus?: ProductDeliveryStatus | null,
      deliveryBy?: string | null,
      deliveryTracking?:  Array< {
        __typename: "DeliveryTracking",
        status?: ProductDeliveryStatus | null,
        date?: string | null,
        trackingBy?: string | null,
        phoneNumber?: string | null,
        remarks?: string | null,
        receipt?: string | null,
      } | null > | null,
      deliveryPartner?: string | null,
      outForDeliveryDate?: string | null,
      outForDeliveryTime?: string | null,
      expectedDeliveryDate?: string | null,
      trackingID?: string | null,
      trackingUrl?: string | null,
      deliveryPersonInfo?:  {
        __typename: "DeliveryPersonInfo",
        name?: string | null,
        contactNumber?: string | null,
        vehiclenumber?: string | null,
        notes?: string | null,
      } | null,
      orderedOn?: string | null,
      returnRefundActivity?:  {
        __typename: "ReturnRefundActivity",
        returnReference?: string | null,
        reason?: string | null,
        comment?: string | null,
        quantity?: string | null,
        subTotal?: number | null,
        total?: number | null,
        attachments?: Array< string | null > | null,
        paymentMode?: string | null,
        paymentMethod?: PaymentMethod | null,
        returnType?: ReturnType | null,
        returnAddress?:  {
          __typename: "Address",
          addressLine1?: string | null,
          addressLine2?: string | null,
          street?: string | null,
          landmark?: string | null,
          state?: string | null,
          city?: string | null,
          postalCode?: string | null,
          country?: string | null,
        } | null,
        pickUpDetails?:  {
          __typename: "PickUpDetails",
          pickUpAddress?:  {
            __typename: "Address",
            addressLine1?: string | null,
            addressLine2?: string | null,
            street?: string | null,
            landmark?: string | null,
            state?: string | null,
            city?: string | null,
            postalCode?: string | null,
            country?: string | null,
          } | null,
          pickUpBy?: string | null,
          deliveryPartner?: string | null,
          name?: string | null,
          phoneNumber?: string | null,
          expectedPickUpDate?: string | null,
          time?: string | null,
        } | null,
        receivedInfo?:  {
          __typename: "ReceivedInfo",
          receivedOn?: string | null,
          receivedAs?: string | null,
          images?: Array< string | null > | null,
        } | null,
        updateInStock?: boolean | null,
        updateInStockQty?: string | null,
        refundInfo?:  {
          __typename: "RefundInfo",
          date?: string | null,
          paymentMethod?: PaymentMethod | null,
          refundAmount?: number | null,
          refundInfo?: string | null,
          proofImage?: string | null,
          proofImageUrl?: string | null,
          rejectedReason?: string | null,
        } | null,
        status?: ReturnActivityStatus | null,
        returnStatusUpdate?:  Array< {
          __typename: "ReturnUpdates",
          returnStatus?: ReturnActivityStatus | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null > | null,
        rejectReason?: string | null,
        createdAt?: string | null,
      } | null,
      eta?: string | null,
      paymentStatus?: PaymentStatus | null,
      billOfLading?: string | null,
      packingList?: string | null,
      searchKey?: string | null,
      shippingInfo?:  {
        __typename: "shippingDetails",
        weightValue?: string | null,
        weightUnit?: string | null,
        lengthValue?: string | null,
        lengthUnit?: string | null,
        heightValue?: string | null,
        heightUnit?: string | null,
        widthValue?: string | null,
        widthUnit?: string | null,
      } | null,
      returnAvailable?: boolean | null,
      disputeResolved?: boolean | null,
      brandId?: string | null,
      returnValue?: number | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrderItemByMainCategoryQueryVariables = {
  pk: string,
  mainCategoryIdMainCategory?: ModelOrderItemByMainCategoryCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrderItemByMainCategoryQuery = {
  orderItemByMainCategory?:  {
    __typename: "ModelOrderItemConnection",
    items:  Array< {
      __typename: "OrderItem",
      pk: string,
      id: string,
      productId?: string | null,
      mainCategoryId?: string | null,
      mainCategory?: string | null,
      subCategoryId?: string | null,
      subCategory?: string | null,
      userId: string,
      wareHouseId?: string | null,
      attrValueID?: string | null,
      wareHouseName?: string | null,
      userName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      createdAt: string,
      quantity?: number | null,
      stockUnit?: string | null,
      itemsPerUnit?: number | null,
      taxCode?: string | null,
      taxAmount?: number | null,
      taxDetail?:  Array< {
        __typename: "taxDetail",
        taxCharge?: number | null,
        taxID?: string | null,
        taxName?: string | null,
      } | null > | null,
      regularPrice?: number | null,
      totalPrice?: number | null,
      itemDetail?:  {
        __typename: "ItemDetail",
        productName?: string | null,
        price?: number | null,
        image?: string | null,
        brand?: string | null,
        model?: string | null,
        tax?: number | null,
        currentStock?: string | null,
        sku?: string | null,
        qrcode?: string | null,
      } | null,
      sku?: string | null,
      attrName?: string | null,
      attrValue?: string | null,
      deliveryStatus?: ProductDeliveryStatus | null,
      deliveryBy?: string | null,
      deliveryTracking?:  Array< {
        __typename: "DeliveryTracking",
        status?: ProductDeliveryStatus | null,
        date?: string | null,
        trackingBy?: string | null,
        phoneNumber?: string | null,
        remarks?: string | null,
        receipt?: string | null,
      } | null > | null,
      deliveryPartner?: string | null,
      outForDeliveryDate?: string | null,
      outForDeliveryTime?: string | null,
      expectedDeliveryDate?: string | null,
      trackingID?: string | null,
      trackingUrl?: string | null,
      deliveryPersonInfo?:  {
        __typename: "DeliveryPersonInfo",
        name?: string | null,
        contactNumber?: string | null,
        vehiclenumber?: string | null,
        notes?: string | null,
      } | null,
      orderedOn?: string | null,
      returnRefundActivity?:  {
        __typename: "ReturnRefundActivity",
        returnReference?: string | null,
        reason?: string | null,
        comment?: string | null,
        quantity?: string | null,
        subTotal?: number | null,
        total?: number | null,
        attachments?: Array< string | null > | null,
        paymentMode?: string | null,
        paymentMethod?: PaymentMethod | null,
        returnType?: ReturnType | null,
        returnAddress?:  {
          __typename: "Address",
          addressLine1?: string | null,
          addressLine2?: string | null,
          street?: string | null,
          landmark?: string | null,
          state?: string | null,
          city?: string | null,
          postalCode?: string | null,
          country?: string | null,
        } | null,
        pickUpDetails?:  {
          __typename: "PickUpDetails",
          pickUpAddress?:  {
            __typename: "Address",
            addressLine1?: string | null,
            addressLine2?: string | null,
            street?: string | null,
            landmark?: string | null,
            state?: string | null,
            city?: string | null,
            postalCode?: string | null,
            country?: string | null,
          } | null,
          pickUpBy?: string | null,
          deliveryPartner?: string | null,
          name?: string | null,
          phoneNumber?: string | null,
          expectedPickUpDate?: string | null,
          time?: string | null,
        } | null,
        receivedInfo?:  {
          __typename: "ReceivedInfo",
          receivedOn?: string | null,
          receivedAs?: string | null,
          images?: Array< string | null > | null,
        } | null,
        updateInStock?: boolean | null,
        updateInStockQty?: string | null,
        refundInfo?:  {
          __typename: "RefundInfo",
          date?: string | null,
          paymentMethod?: PaymentMethod | null,
          refundAmount?: number | null,
          refundInfo?: string | null,
          proofImage?: string | null,
          proofImageUrl?: string | null,
          rejectedReason?: string | null,
        } | null,
        status?: ReturnActivityStatus | null,
        returnStatusUpdate?:  Array< {
          __typename: "ReturnUpdates",
          returnStatus?: ReturnActivityStatus | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null > | null,
        rejectReason?: string | null,
        createdAt?: string | null,
      } | null,
      eta?: string | null,
      paymentStatus?: PaymentStatus | null,
      billOfLading?: string | null,
      packingList?: string | null,
      searchKey?: string | null,
      shippingInfo?:  {
        __typename: "shippingDetails",
        weightValue?: string | null,
        weightUnit?: string | null,
        lengthValue?: string | null,
        lengthUnit?: string | null,
        heightValue?: string | null,
        heightUnit?: string | null,
        widthValue?: string | null,
        widthUnit?: string | null,
      } | null,
      returnAvailable?: boolean | null,
      disputeResolved?: boolean | null,
      brandId?: string | null,
      returnValue?: number | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrderItemBySubCategoryQueryVariables = {
  pk: string,
  subCategoryIdSubCategory?: ModelOrderItemBySubCategoryCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrderItemBySubCategoryQuery = {
  orderItemBySubCategory?:  {
    __typename: "ModelOrderItemConnection",
    items:  Array< {
      __typename: "OrderItem",
      pk: string,
      id: string,
      productId?: string | null,
      mainCategoryId?: string | null,
      mainCategory?: string | null,
      subCategoryId?: string | null,
      subCategory?: string | null,
      userId: string,
      wareHouseId?: string | null,
      attrValueID?: string | null,
      wareHouseName?: string | null,
      userName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      createdAt: string,
      quantity?: number | null,
      stockUnit?: string | null,
      itemsPerUnit?: number | null,
      taxCode?: string | null,
      taxAmount?: number | null,
      taxDetail?:  Array< {
        __typename: "taxDetail",
        taxCharge?: number | null,
        taxID?: string | null,
        taxName?: string | null,
      } | null > | null,
      regularPrice?: number | null,
      totalPrice?: number | null,
      itemDetail?:  {
        __typename: "ItemDetail",
        productName?: string | null,
        price?: number | null,
        image?: string | null,
        brand?: string | null,
        model?: string | null,
        tax?: number | null,
        currentStock?: string | null,
        sku?: string | null,
        qrcode?: string | null,
      } | null,
      sku?: string | null,
      attrName?: string | null,
      attrValue?: string | null,
      deliveryStatus?: ProductDeliveryStatus | null,
      deliveryBy?: string | null,
      deliveryTracking?:  Array< {
        __typename: "DeliveryTracking",
        status?: ProductDeliveryStatus | null,
        date?: string | null,
        trackingBy?: string | null,
        phoneNumber?: string | null,
        remarks?: string | null,
        receipt?: string | null,
      } | null > | null,
      deliveryPartner?: string | null,
      outForDeliveryDate?: string | null,
      outForDeliveryTime?: string | null,
      expectedDeliveryDate?: string | null,
      trackingID?: string | null,
      trackingUrl?: string | null,
      deliveryPersonInfo?:  {
        __typename: "DeliveryPersonInfo",
        name?: string | null,
        contactNumber?: string | null,
        vehiclenumber?: string | null,
        notes?: string | null,
      } | null,
      orderedOn?: string | null,
      returnRefundActivity?:  {
        __typename: "ReturnRefundActivity",
        returnReference?: string | null,
        reason?: string | null,
        comment?: string | null,
        quantity?: string | null,
        subTotal?: number | null,
        total?: number | null,
        attachments?: Array< string | null > | null,
        paymentMode?: string | null,
        paymentMethod?: PaymentMethod | null,
        returnType?: ReturnType | null,
        returnAddress?:  {
          __typename: "Address",
          addressLine1?: string | null,
          addressLine2?: string | null,
          street?: string | null,
          landmark?: string | null,
          state?: string | null,
          city?: string | null,
          postalCode?: string | null,
          country?: string | null,
        } | null,
        pickUpDetails?:  {
          __typename: "PickUpDetails",
          pickUpAddress?:  {
            __typename: "Address",
            addressLine1?: string | null,
            addressLine2?: string | null,
            street?: string | null,
            landmark?: string | null,
            state?: string | null,
            city?: string | null,
            postalCode?: string | null,
            country?: string | null,
          } | null,
          pickUpBy?: string | null,
          deliveryPartner?: string | null,
          name?: string | null,
          phoneNumber?: string | null,
          expectedPickUpDate?: string | null,
          time?: string | null,
        } | null,
        receivedInfo?:  {
          __typename: "ReceivedInfo",
          receivedOn?: string | null,
          receivedAs?: string | null,
          images?: Array< string | null > | null,
        } | null,
        updateInStock?: boolean | null,
        updateInStockQty?: string | null,
        refundInfo?:  {
          __typename: "RefundInfo",
          date?: string | null,
          paymentMethod?: PaymentMethod | null,
          refundAmount?: number | null,
          refundInfo?: string | null,
          proofImage?: string | null,
          proofImageUrl?: string | null,
          rejectedReason?: string | null,
        } | null,
        status?: ReturnActivityStatus | null,
        returnStatusUpdate?:  Array< {
          __typename: "ReturnUpdates",
          returnStatus?: ReturnActivityStatus | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null > | null,
        rejectReason?: string | null,
        createdAt?: string | null,
      } | null,
      eta?: string | null,
      paymentStatus?: PaymentStatus | null,
      billOfLading?: string | null,
      packingList?: string | null,
      searchKey?: string | null,
      shippingInfo?:  {
        __typename: "shippingDetails",
        weightValue?: string | null,
        weightUnit?: string | null,
        lengthValue?: string | null,
        lengthUnit?: string | null,
        heightValue?: string | null,
        heightUnit?: string | null,
        widthValue?: string | null,
        widthUnit?: string | null,
      } | null,
      returnAvailable?: boolean | null,
      disputeResolved?: boolean | null,
      brandId?: string | null,
      returnValue?: number | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrderItemByWarehouseQueryVariables = {
  pk: string,
  wareHouseIdWareHouseName?: ModelOrderItemByWarehouseCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrderItemByWarehouseQuery = {
  orderItemByWarehouse?:  {
    __typename: "ModelOrderItemConnection",
    items:  Array< {
      __typename: "OrderItem",
      pk: string,
      id: string,
      productId?: string | null,
      mainCategoryId?: string | null,
      mainCategory?: string | null,
      subCategoryId?: string | null,
      subCategory?: string | null,
      userId: string,
      wareHouseId?: string | null,
      attrValueID?: string | null,
      wareHouseName?: string | null,
      userName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      createdAt: string,
      quantity?: number | null,
      stockUnit?: string | null,
      itemsPerUnit?: number | null,
      taxCode?: string | null,
      taxAmount?: number | null,
      taxDetail?:  Array< {
        __typename: "taxDetail",
        taxCharge?: number | null,
        taxID?: string | null,
        taxName?: string | null,
      } | null > | null,
      regularPrice?: number | null,
      totalPrice?: number | null,
      itemDetail?:  {
        __typename: "ItemDetail",
        productName?: string | null,
        price?: number | null,
        image?: string | null,
        brand?: string | null,
        model?: string | null,
        tax?: number | null,
        currentStock?: string | null,
        sku?: string | null,
        qrcode?: string | null,
      } | null,
      sku?: string | null,
      attrName?: string | null,
      attrValue?: string | null,
      deliveryStatus?: ProductDeliveryStatus | null,
      deliveryBy?: string | null,
      deliveryTracking?:  Array< {
        __typename: "DeliveryTracking",
        status?: ProductDeliveryStatus | null,
        date?: string | null,
        trackingBy?: string | null,
        phoneNumber?: string | null,
        remarks?: string | null,
        receipt?: string | null,
      } | null > | null,
      deliveryPartner?: string | null,
      outForDeliveryDate?: string | null,
      outForDeliveryTime?: string | null,
      expectedDeliveryDate?: string | null,
      trackingID?: string | null,
      trackingUrl?: string | null,
      deliveryPersonInfo?:  {
        __typename: "DeliveryPersonInfo",
        name?: string | null,
        contactNumber?: string | null,
        vehiclenumber?: string | null,
        notes?: string | null,
      } | null,
      orderedOn?: string | null,
      returnRefundActivity?:  {
        __typename: "ReturnRefundActivity",
        returnReference?: string | null,
        reason?: string | null,
        comment?: string | null,
        quantity?: string | null,
        subTotal?: number | null,
        total?: number | null,
        attachments?: Array< string | null > | null,
        paymentMode?: string | null,
        paymentMethod?: PaymentMethod | null,
        returnType?: ReturnType | null,
        returnAddress?:  {
          __typename: "Address",
          addressLine1?: string | null,
          addressLine2?: string | null,
          street?: string | null,
          landmark?: string | null,
          state?: string | null,
          city?: string | null,
          postalCode?: string | null,
          country?: string | null,
        } | null,
        pickUpDetails?:  {
          __typename: "PickUpDetails",
          pickUpAddress?:  {
            __typename: "Address",
            addressLine1?: string | null,
            addressLine2?: string | null,
            street?: string | null,
            landmark?: string | null,
            state?: string | null,
            city?: string | null,
            postalCode?: string | null,
            country?: string | null,
          } | null,
          pickUpBy?: string | null,
          deliveryPartner?: string | null,
          name?: string | null,
          phoneNumber?: string | null,
          expectedPickUpDate?: string | null,
          time?: string | null,
        } | null,
        receivedInfo?:  {
          __typename: "ReceivedInfo",
          receivedOn?: string | null,
          receivedAs?: string | null,
          images?: Array< string | null > | null,
        } | null,
        updateInStock?: boolean | null,
        updateInStockQty?: string | null,
        refundInfo?:  {
          __typename: "RefundInfo",
          date?: string | null,
          paymentMethod?: PaymentMethod | null,
          refundAmount?: number | null,
          refundInfo?: string | null,
          proofImage?: string | null,
          proofImageUrl?: string | null,
          rejectedReason?: string | null,
        } | null,
        status?: ReturnActivityStatus | null,
        returnStatusUpdate?:  Array< {
          __typename: "ReturnUpdates",
          returnStatus?: ReturnActivityStatus | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null > | null,
        rejectReason?: string | null,
        createdAt?: string | null,
      } | null,
      eta?: string | null,
      paymentStatus?: PaymentStatus | null,
      billOfLading?: string | null,
      packingList?: string | null,
      searchKey?: string | null,
      shippingInfo?:  {
        __typename: "shippingDetails",
        weightValue?: string | null,
        weightUnit?: string | null,
        lengthValue?: string | null,
        lengthUnit?: string | null,
        heightValue?: string | null,
        heightUnit?: string | null,
        widthValue?: string | null,
        widthUnit?: string | null,
      } | null,
      returnAvailable?: boolean | null,
      disputeResolved?: boolean | null,
      brandId?: string | null,
      returnValue?: number | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrderItemsByDeliveryStatusQueryVariables = {
  deliveryStatus: ProductDeliveryStatus,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrderItemsByDeliveryStatusQuery = {
  orderItemsByDeliveryStatus?:  {
    __typename: "ModelOrderItemConnection",
    items:  Array< {
      __typename: "OrderItem",
      pk: string,
      id: string,
      productId?: string | null,
      mainCategoryId?: string | null,
      mainCategory?: string | null,
      subCategoryId?: string | null,
      subCategory?: string | null,
      userId: string,
      wareHouseId?: string | null,
      attrValueID?: string | null,
      wareHouseName?: string | null,
      userName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      createdAt: string,
      quantity?: number | null,
      stockUnit?: string | null,
      itemsPerUnit?: number | null,
      taxCode?: string | null,
      taxAmount?: number | null,
      taxDetail?:  Array< {
        __typename: "taxDetail",
        taxCharge?: number | null,
        taxID?: string | null,
        taxName?: string | null,
      } | null > | null,
      regularPrice?: number | null,
      totalPrice?: number | null,
      itemDetail?:  {
        __typename: "ItemDetail",
        productName?: string | null,
        price?: number | null,
        image?: string | null,
        brand?: string | null,
        model?: string | null,
        tax?: number | null,
        currentStock?: string | null,
        sku?: string | null,
        qrcode?: string | null,
      } | null,
      sku?: string | null,
      attrName?: string | null,
      attrValue?: string | null,
      deliveryStatus?: ProductDeliveryStatus | null,
      deliveryBy?: string | null,
      deliveryTracking?:  Array< {
        __typename: "DeliveryTracking",
        status?: ProductDeliveryStatus | null,
        date?: string | null,
        trackingBy?: string | null,
        phoneNumber?: string | null,
        remarks?: string | null,
        receipt?: string | null,
      } | null > | null,
      deliveryPartner?: string | null,
      outForDeliveryDate?: string | null,
      outForDeliveryTime?: string | null,
      expectedDeliveryDate?: string | null,
      trackingID?: string | null,
      trackingUrl?: string | null,
      deliveryPersonInfo?:  {
        __typename: "DeliveryPersonInfo",
        name?: string | null,
        contactNumber?: string | null,
        vehiclenumber?: string | null,
        notes?: string | null,
      } | null,
      orderedOn?: string | null,
      returnRefundActivity?:  {
        __typename: "ReturnRefundActivity",
        returnReference?: string | null,
        reason?: string | null,
        comment?: string | null,
        quantity?: string | null,
        subTotal?: number | null,
        total?: number | null,
        attachments?: Array< string | null > | null,
        paymentMode?: string | null,
        paymentMethod?: PaymentMethod | null,
        returnType?: ReturnType | null,
        returnAddress?:  {
          __typename: "Address",
          addressLine1?: string | null,
          addressLine2?: string | null,
          street?: string | null,
          landmark?: string | null,
          state?: string | null,
          city?: string | null,
          postalCode?: string | null,
          country?: string | null,
        } | null,
        pickUpDetails?:  {
          __typename: "PickUpDetails",
          pickUpAddress?:  {
            __typename: "Address",
            addressLine1?: string | null,
            addressLine2?: string | null,
            street?: string | null,
            landmark?: string | null,
            state?: string | null,
            city?: string | null,
            postalCode?: string | null,
            country?: string | null,
          } | null,
          pickUpBy?: string | null,
          deliveryPartner?: string | null,
          name?: string | null,
          phoneNumber?: string | null,
          expectedPickUpDate?: string | null,
          time?: string | null,
        } | null,
        receivedInfo?:  {
          __typename: "ReceivedInfo",
          receivedOn?: string | null,
          receivedAs?: string | null,
          images?: Array< string | null > | null,
        } | null,
        updateInStock?: boolean | null,
        updateInStockQty?: string | null,
        refundInfo?:  {
          __typename: "RefundInfo",
          date?: string | null,
          paymentMethod?: PaymentMethod | null,
          refundAmount?: number | null,
          refundInfo?: string | null,
          proofImage?: string | null,
          proofImageUrl?: string | null,
          rejectedReason?: string | null,
        } | null,
        status?: ReturnActivityStatus | null,
        returnStatusUpdate?:  Array< {
          __typename: "ReturnUpdates",
          returnStatus?: ReturnActivityStatus | null,
          updatedAt?: string | null,
          updatedBy?: string | null,
        } | null > | null,
        rejectReason?: string | null,
        createdAt?: string | null,
      } | null,
      eta?: string | null,
      paymentStatus?: PaymentStatus | null,
      billOfLading?: string | null,
      packingList?: string | null,
      searchKey?: string | null,
      shippingInfo?:  {
        __typename: "shippingDetails",
        weightValue?: string | null,
        weightUnit?: string | null,
        lengthValue?: string | null,
        lengthUnit?: string | null,
        heightValue?: string | null,
        heightUnit?: string | null,
        widthValue?: string | null,
        widthUnit?: string | null,
      } | null,
      returnAvailable?: boolean | null,
      disputeResolved?: boolean | null,
      brandId?: string | null,
      returnValue?: number | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReportMasterQueryVariables = {
  pk: string,
  id: string,
};

export type GetReportMasterQuery = {
  getReportMaster?:  {
    __typename: "ReportMaster",
    pk: string,
    id: string,
    reportName?: string | null,
    description?: string | null,
    sequenceNumber?: number | null,
    searchKey?: string | null,
    status?: Status | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type ListReportMastersQueryVariables = {
  pk?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelReportMasterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListReportMastersQuery = {
  listReportMasters?:  {
    __typename: "ModelReportMasterConnection",
    items:  Array< {
      __typename: "ReportMaster",
      pk: string,
      id: string,
      reportName?: string | null,
      description?: string | null,
      sequenceNumber?: number | null,
      searchKey?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateSettingsSubscriptionVariables = {
  filter?: ModelSubscriptionSettingsFilterInput | null,
  owner?: string | null,
};

export type OnCreateSettingsSubscription = {
  onCreateSettings?:  {
    __typename: "Settings",
    id: string,
    pk: string,
    notificationType?: NotificationType | null,
    createdAt: string,
    emailTo?: Array< string | null > | null,
    productExpiryDuration?: string | null,
    theme?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateSettingsSubscriptionVariables = {
  filter?: ModelSubscriptionSettingsFilterInput | null,
  owner?: string | null,
};

export type OnUpdateSettingsSubscription = {
  onUpdateSettings?:  {
    __typename: "Settings",
    id: string,
    pk: string,
    notificationType?: NotificationType | null,
    createdAt: string,
    emailTo?: Array< string | null > | null,
    productExpiryDuration?: string | null,
    theme?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteSettingsSubscriptionVariables = {
  filter?: ModelSubscriptionSettingsFilterInput | null,
  owner?: string | null,
};

export type OnDeleteSettingsSubscription = {
  onDeleteSettings?:  {
    __typename: "Settings",
    id: string,
    pk: string,
    notificationType?: NotificationType | null,
    createdAt: string,
    emailTo?: Array< string | null > | null,
    productExpiryDuration?: string | null,
    theme?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateCompanyDetailsSubscriptionVariables = {
  filter?: ModelSubscriptionCompanyDetailsFilterInput | null,
  owner?: string | null,
};

export type OnCreateCompanyDetailsSubscription = {
  onCreateCompanyDetails?:  {
    __typename: "CompanyDetails",
    id: string,
    pk: string,
    createdAt: string,
    primaryLogo?:  {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null,
    secondaryLogo?:  {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null,
    companyName?: string | null,
    webSite?: string | null,
    phoneNumber?: string | null,
    email?: string | null,
    ccEmail?: string | null,
    address?:  {
      __typename: "Address",
      addressLine1?: string | null,
      addressLine2?: string | null,
      street?: string | null,
      landmark?: string | null,
      state?: string | null,
      city?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    status?: boolean | null,
    currency?: string | null,
    categorySequenced?: boolean | null,
    copyrightText?: string | null,
    mobileAppUrls?:  Array< {
      __typename: "UrlDetails",
      type?: string | null,
      title?: string | null,
      alterText?: string | null,
      url?: string | null,
      certificationName?: string | null,
      redirectTo?: string | null,
    } | null > | null,
    theme?:  {
      __typename: "Theme",
      preferredTheme?: string | null,
      headerBackgroundColor?: string | null,
      headerTextColor?: string | null,
      headerIconColor?: string | null,
      footerBackgroundColor?: string | null,
      footerTextColor?: string | null,
      footerHeadingColor?: string | null,
      font?: string | null,
      navigationMenu?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      navigationMenuColor?: string | null,
      navigationSubMenu?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      navigationSubMenuColor?: string | null,
      productTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productSubTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productPrice?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDetailName?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDetailSubTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDetailPrice?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDescriptionHeading?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDescription?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      customerReviewsTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      customerReviewsDetails?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      notificationsTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      notificationsSubTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      profileHeading?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      profileContent?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      contentTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      contentDescription?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      titleHeader?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      titleDetails?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      primaryButton?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      primaryButtonBackgroundColor?: string | null,
      primaryButtonTextColor?: string | null,
      secondaryButton?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      secondaryButtonBackgroundColor?: string | null,
      secondaryButtonTextColor?: string | null,
    } | null,
    mobileTheme?:  {
      __typename: "MobileTheme",
      locationBackgroundColor?: string | null,
      locationTextColor?: string | null,
      locationIconColor?: string | null,
      searchBarBackgroundColor?: string | null,
      newArrivalsColor?: string | null,
      bestSellersColor?: string | null,
      recentlyViewedColor?: string | null,
      navBackgroundColor?: string | null,
      navIconColor?: string | null,
      font?: string | null,
      productListTitle?: string | null,
      productListBrand?: string | null,
      productListStock?: string | null,
      productListPrice?: string | null,
      productDetailName?: string | null,
      productDetailPrice?: string | null,
      productDetailStock?: string | null,
      productDetailBrand?: string | null,
      customerReviewsTitle?: string | null,
      customerReviewsDetails?: string | null,
      notificationsTitle?: string | null,
      notificationsSubTitle?: string | null,
      profileName?: string | null,
      profileHeadings?: string | null,
      primaryButtonText?: string | null,
      primaryButtonBackgroundColor?: string | null,
      primaryButtonTextColor?: string | null,
      secondaryButtonText?: string | null,
      secondaryButtonBackgroundColor?: string | null,
      secondaryButtonTextColor?: string | null,
    } | null,
    seoDescription?: string | null,
    daysToReturn?: number | null,
    brandName?: string | null,
    iconUrl?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCompanyDetailsSubscriptionVariables = {
  filter?: ModelSubscriptionCompanyDetailsFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCompanyDetailsSubscription = {
  onUpdateCompanyDetails?:  {
    __typename: "CompanyDetails",
    id: string,
    pk: string,
    createdAt: string,
    primaryLogo?:  {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null,
    secondaryLogo?:  {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null,
    companyName?: string | null,
    webSite?: string | null,
    phoneNumber?: string | null,
    email?: string | null,
    ccEmail?: string | null,
    address?:  {
      __typename: "Address",
      addressLine1?: string | null,
      addressLine2?: string | null,
      street?: string | null,
      landmark?: string | null,
      state?: string | null,
      city?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    status?: boolean | null,
    currency?: string | null,
    categorySequenced?: boolean | null,
    copyrightText?: string | null,
    mobileAppUrls?:  Array< {
      __typename: "UrlDetails",
      type?: string | null,
      title?: string | null,
      alterText?: string | null,
      url?: string | null,
      certificationName?: string | null,
      redirectTo?: string | null,
    } | null > | null,
    theme?:  {
      __typename: "Theme",
      preferredTheme?: string | null,
      headerBackgroundColor?: string | null,
      headerTextColor?: string | null,
      headerIconColor?: string | null,
      footerBackgroundColor?: string | null,
      footerTextColor?: string | null,
      footerHeadingColor?: string | null,
      font?: string | null,
      navigationMenu?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      navigationMenuColor?: string | null,
      navigationSubMenu?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      navigationSubMenuColor?: string | null,
      productTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productSubTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productPrice?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDetailName?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDetailSubTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDetailPrice?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDescriptionHeading?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDescription?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      customerReviewsTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      customerReviewsDetails?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      notificationsTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      notificationsSubTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      profileHeading?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      profileContent?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      contentTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      contentDescription?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      titleHeader?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      titleDetails?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      primaryButton?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      primaryButtonBackgroundColor?: string | null,
      primaryButtonTextColor?: string | null,
      secondaryButton?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      secondaryButtonBackgroundColor?: string | null,
      secondaryButtonTextColor?: string | null,
    } | null,
    mobileTheme?:  {
      __typename: "MobileTheme",
      locationBackgroundColor?: string | null,
      locationTextColor?: string | null,
      locationIconColor?: string | null,
      searchBarBackgroundColor?: string | null,
      newArrivalsColor?: string | null,
      bestSellersColor?: string | null,
      recentlyViewedColor?: string | null,
      navBackgroundColor?: string | null,
      navIconColor?: string | null,
      font?: string | null,
      productListTitle?: string | null,
      productListBrand?: string | null,
      productListStock?: string | null,
      productListPrice?: string | null,
      productDetailName?: string | null,
      productDetailPrice?: string | null,
      productDetailStock?: string | null,
      productDetailBrand?: string | null,
      customerReviewsTitle?: string | null,
      customerReviewsDetails?: string | null,
      notificationsTitle?: string | null,
      notificationsSubTitle?: string | null,
      profileName?: string | null,
      profileHeadings?: string | null,
      primaryButtonText?: string | null,
      primaryButtonBackgroundColor?: string | null,
      primaryButtonTextColor?: string | null,
      secondaryButtonText?: string | null,
      secondaryButtonBackgroundColor?: string | null,
      secondaryButtonTextColor?: string | null,
    } | null,
    seoDescription?: string | null,
    daysToReturn?: number | null,
    brandName?: string | null,
    iconUrl?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCompanyDetailsSubscriptionVariables = {
  filter?: ModelSubscriptionCompanyDetailsFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCompanyDetailsSubscription = {
  onDeleteCompanyDetails?:  {
    __typename: "CompanyDetails",
    id: string,
    pk: string,
    createdAt: string,
    primaryLogo?:  {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null,
    secondaryLogo?:  {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null,
    companyName?: string | null,
    webSite?: string | null,
    phoneNumber?: string | null,
    email?: string | null,
    ccEmail?: string | null,
    address?:  {
      __typename: "Address",
      addressLine1?: string | null,
      addressLine2?: string | null,
      street?: string | null,
      landmark?: string | null,
      state?: string | null,
      city?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    status?: boolean | null,
    currency?: string | null,
    categorySequenced?: boolean | null,
    copyrightText?: string | null,
    mobileAppUrls?:  Array< {
      __typename: "UrlDetails",
      type?: string | null,
      title?: string | null,
      alterText?: string | null,
      url?: string | null,
      certificationName?: string | null,
      redirectTo?: string | null,
    } | null > | null,
    theme?:  {
      __typename: "Theme",
      preferredTheme?: string | null,
      headerBackgroundColor?: string | null,
      headerTextColor?: string | null,
      headerIconColor?: string | null,
      footerBackgroundColor?: string | null,
      footerTextColor?: string | null,
      footerHeadingColor?: string | null,
      font?: string | null,
      navigationMenu?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      navigationMenuColor?: string | null,
      navigationSubMenu?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      navigationSubMenuColor?: string | null,
      productTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productSubTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productPrice?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDetailName?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDetailSubTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDetailPrice?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDescriptionHeading?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      productDescription?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      customerReviewsTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      customerReviewsDetails?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      notificationsTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      notificationsSubTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      profileHeading?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      profileContent?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      contentTitle?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      contentDescription?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      titleHeader?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      titleDetails?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      primaryButton?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      primaryButtonBackgroundColor?: string | null,
      primaryButtonTextColor?: string | null,
      secondaryButton?:  {
        __typename: "FontProperty",
        title?: string | null,
        example?: string | null,
        fontWeight?: string | null,
        fontSize?: string | null,
      } | null,
      secondaryButtonBackgroundColor?: string | null,
      secondaryButtonTextColor?: string | null,
    } | null,
    mobileTheme?:  {
      __typename: "MobileTheme",
      locationBackgroundColor?: string | null,
      locationTextColor?: string | null,
      locationIconColor?: string | null,
      searchBarBackgroundColor?: string | null,
      newArrivalsColor?: string | null,
      bestSellersColor?: string | null,
      recentlyViewedColor?: string | null,
      navBackgroundColor?: string | null,
      navIconColor?: string | null,
      font?: string | null,
      productListTitle?: string | null,
      productListBrand?: string | null,
      productListStock?: string | null,
      productListPrice?: string | null,
      productDetailName?: string | null,
      productDetailPrice?: string | null,
      productDetailStock?: string | null,
      productDetailBrand?: string | null,
      customerReviewsTitle?: string | null,
      customerReviewsDetails?: string | null,
      notificationsTitle?: string | null,
      notificationsSubTitle?: string | null,
      profileName?: string | null,
      profileHeadings?: string | null,
      primaryButtonText?: string | null,
      primaryButtonBackgroundColor?: string | null,
      primaryButtonTextColor?: string | null,
      secondaryButtonText?: string | null,
      secondaryButtonBackgroundColor?: string | null,
      secondaryButtonTextColor?: string | null,
    } | null,
    seoDescription?: string | null,
    daysToReturn?: number | null,
    brandName?: string | null,
    iconUrl?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreatePaymentModeSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentModeFilterInput | null,
  owner?: string | null,
};

export type OnCreatePaymentModeSubscription = {
  onCreatePaymentMode?:  {
    __typename: "PaymentMode",
    id: string,
    pk: string,
    createdAt: string,
    method?: string | null,
    description?: string | null,
    provider?: string | null,
    logoUrl?: string | null,
    stagingUrl?: string | null,
    productionUrl?: string | null,
    status?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdatePaymentModeSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentModeFilterInput | null,
  owner?: string | null,
};

export type OnUpdatePaymentModeSubscription = {
  onUpdatePaymentMode?:  {
    __typename: "PaymentMode",
    id: string,
    pk: string,
    createdAt: string,
    method?: string | null,
    description?: string | null,
    provider?: string | null,
    logoUrl?: string | null,
    stagingUrl?: string | null,
    productionUrl?: string | null,
    status?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeletePaymentModeSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentModeFilterInput | null,
  owner?: string | null,
};

export type OnDeletePaymentModeSubscription = {
  onDeletePaymentMode?:  {
    __typename: "PaymentMode",
    id: string,
    pk: string,
    createdAt: string,
    method?: string | null,
    description?: string | null,
    provider?: string | null,
    logoUrl?: string | null,
    stagingUrl?: string | null,
    productionUrl?: string | null,
    status?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    pk: string,
    userName?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    companyName?: string | null,
    profileImageUrl?: string | null,
    email: string,
    phoneNumber?: string | null,
    role?: UserRole | null,
    password?: string | null,
    multiFactorAuth?: boolean | null,
    emailNotification?: boolean | null,
    mandatePasswordChange?: boolean | null,
    emailVerified?: boolean | null,
    otp?: string | null,
    status?: Status | null,
    searchKey?: string | null,
    addressDetails?:  Array< {
      __typename: "AddressDetails",
      name?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      address?:  {
        __typename: "Address",
        addressLine1?: string | null,
        addressLine2?: string | null,
        street?: string | null,
        landmark?: string | null,
        state?: string | null,
        city?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      type?: string | null,
      addressName?: string | null,
      default?: boolean | null,
      latitude?: string | null,
      longitude?: string | null,
    } | null > | null,
    loginAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    platform?: Platform | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    pk: string,
    userName?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    companyName?: string | null,
    profileImageUrl?: string | null,
    email: string,
    phoneNumber?: string | null,
    role?: UserRole | null,
    password?: string | null,
    multiFactorAuth?: boolean | null,
    emailNotification?: boolean | null,
    mandatePasswordChange?: boolean | null,
    emailVerified?: boolean | null,
    otp?: string | null,
    status?: Status | null,
    searchKey?: string | null,
    addressDetails?:  Array< {
      __typename: "AddressDetails",
      name?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      address?:  {
        __typename: "Address",
        addressLine1?: string | null,
        addressLine2?: string | null,
        street?: string | null,
        landmark?: string | null,
        state?: string | null,
        city?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      type?: string | null,
      addressName?: string | null,
      default?: boolean | null,
      latitude?: string | null,
      longitude?: string | null,
    } | null > | null,
    loginAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    platform?: Platform | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    pk: string,
    userName?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    companyName?: string | null,
    profileImageUrl?: string | null,
    email: string,
    phoneNumber?: string | null,
    role?: UserRole | null,
    password?: string | null,
    multiFactorAuth?: boolean | null,
    emailNotification?: boolean | null,
    mandatePasswordChange?: boolean | null,
    emailVerified?: boolean | null,
    otp?: string | null,
    status?: Status | null,
    searchKey?: string | null,
    addressDetails?:  Array< {
      __typename: "AddressDetails",
      name?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      address?:  {
        __typename: "Address",
        addressLine1?: string | null,
        addressLine2?: string | null,
        street?: string | null,
        landmark?: string | null,
        state?: string | null,
        city?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      type?: string | null,
      addressName?: string | null,
      default?: boolean | null,
      latitude?: string | null,
      longitude?: string | null,
    } | null > | null,
    loginAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    platform?: Platform | null,
    owner?: string | null,
  } | null,
};

export type OnCreateCategorySubscriptionVariables = {
  filter?: ModelSubscriptionCategoryFilterInput | null,
  owner?: string | null,
};

export type OnCreateCategorySubscription = {
  onCreateCategory?:  {
    __typename: "Category",
    pk: string,
    slug: string,
    id: string,
    catName?: string | null,
    mainCategory?: string | null,
    mainCategoryID?: string | null,
    categoryImg?: string | null,
    description?: string | null,
    webBannerImg?: string | null,
    webBannerImgBottom?: string | null,
    mobBannerImg?: string | null,
    mobBannerImgBottom?: string | null,
    categoryType?: CategoryType | null,
    searchKey?: string | null,
    catStatus?: boolean | null,
    sequenceNumber?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCategorySubscriptionVariables = {
  filter?: ModelSubscriptionCategoryFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCategorySubscription = {
  onUpdateCategory?:  {
    __typename: "Category",
    pk: string,
    slug: string,
    id: string,
    catName?: string | null,
    mainCategory?: string | null,
    mainCategoryID?: string | null,
    categoryImg?: string | null,
    description?: string | null,
    webBannerImg?: string | null,
    webBannerImgBottom?: string | null,
    mobBannerImg?: string | null,
    mobBannerImgBottom?: string | null,
    categoryType?: CategoryType | null,
    searchKey?: string | null,
    catStatus?: boolean | null,
    sequenceNumber?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCategorySubscriptionVariables = {
  filter?: ModelSubscriptionCategoryFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCategorySubscription = {
  onDeleteCategory?:  {
    __typename: "Category",
    pk: string,
    slug: string,
    id: string,
    catName?: string | null,
    mainCategory?: string | null,
    mainCategoryID?: string | null,
    categoryImg?: string | null,
    description?: string | null,
    webBannerImg?: string | null,
    webBannerImgBottom?: string | null,
    mobBannerImg?: string | null,
    mobBannerImgBottom?: string | null,
    categoryType?: CategoryType | null,
    searchKey?: string | null,
    catStatus?: boolean | null,
    sequenceNumber?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateBrandSubscriptionVariables = {
  filter?: ModelSubscriptionBrandFilterInput | null,
  owner?: string | null,
};

export type OnCreateBrandSubscription = {
  onCreateBrand?:  {
    __typename: "Brand",
    pk: string,
    id: string,
    brandName?: string | null,
    searchKey?: string | null,
    manufacturerName?: string | null,
    status?: Status | null,
    countryOfOrigin?: string | null,
    brandLogo?: string | null,
    brandUrl?: string | null,
    brandDescription?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateBrandSubscriptionVariables = {
  filter?: ModelSubscriptionBrandFilterInput | null,
  owner?: string | null,
};

export type OnUpdateBrandSubscription = {
  onUpdateBrand?:  {
    __typename: "Brand",
    pk: string,
    id: string,
    brandName?: string | null,
    searchKey?: string | null,
    manufacturerName?: string | null,
    status?: Status | null,
    countryOfOrigin?: string | null,
    brandLogo?: string | null,
    brandUrl?: string | null,
    brandDescription?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteBrandSubscriptionVariables = {
  filter?: ModelSubscriptionBrandFilterInput | null,
  owner?: string | null,
};

export type OnDeleteBrandSubscription = {
  onDeleteBrand?:  {
    __typename: "Brand",
    pk: string,
    id: string,
    brandName?: string | null,
    searchKey?: string | null,
    manufacturerName?: string | null,
    status?: Status | null,
    countryOfOrigin?: string | null,
    brandLogo?: string | null,
    brandUrl?: string | null,
    brandDescription?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateAttributeNameSubscriptionVariables = {
  filter?: ModelSubscriptionAttributeNameFilterInput | null,
  owner?: string | null,
};

export type OnCreateAttributeNameSubscription = {
  onCreateAttributeName?:  {
    __typename: "AttributeName",
    pk: string,
    slug: string,
    id: string,
    sequenceNumber?: number | null,
    searchKey?: string | null,
    status?: Status | null,
    attrName?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateAttributeNameSubscriptionVariables = {
  filter?: ModelSubscriptionAttributeNameFilterInput | null,
  owner?: string | null,
};

export type OnUpdateAttributeNameSubscription = {
  onUpdateAttributeName?:  {
    __typename: "AttributeName",
    pk: string,
    slug: string,
    id: string,
    sequenceNumber?: number | null,
    searchKey?: string | null,
    status?: Status | null,
    attrName?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteAttributeNameSubscriptionVariables = {
  filter?: ModelSubscriptionAttributeNameFilterInput | null,
  owner?: string | null,
};

export type OnDeleteAttributeNameSubscription = {
  onDeleteAttributeName?:  {
    __typename: "AttributeName",
    pk: string,
    slug: string,
    id: string,
    sequenceNumber?: number | null,
    searchKey?: string | null,
    status?: Status | null,
    attrName?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateCountrySubscriptionVariables = {
  filter?: ModelSubscriptionCountryFilterInput | null,
  owner?: string | null,
};

export type OnCreateCountrySubscription = {
  onCreateCountry?:  {
    __typename: "Country",
    pk: string,
    slug: string,
    id: string,
    name?: string | null,
    shortName?: string | null,
    searchKey?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCountrySubscriptionVariables = {
  filter?: ModelSubscriptionCountryFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCountrySubscription = {
  onUpdateCountry?:  {
    __typename: "Country",
    pk: string,
    slug: string,
    id: string,
    name?: string | null,
    shortName?: string | null,
    searchKey?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCountrySubscriptionVariables = {
  filter?: ModelSubscriptionCountryFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCountrySubscription = {
  onDeleteCountry?:  {
    __typename: "Country",
    pk: string,
    slug: string,
    id: string,
    name?: string | null,
    shortName?: string | null,
    searchKey?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateDropDownMasterSubscriptionVariables = {
  filter?: ModelSubscriptionDropDownMasterFilterInput | null,
  owner?: string | null,
};

export type OnCreateDropDownMasterSubscription = {
  onCreateDropDownMaster?:  {
    __typename: "DropDownMaster",
    pk: string,
    id: string,
    value?: string | null,
    status?: Status | null,
    searchKey?: string | null,
    sequenceNumber?: number | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateDropDownMasterSubscriptionVariables = {
  filter?: ModelSubscriptionDropDownMasterFilterInput | null,
  owner?: string | null,
};

export type OnUpdateDropDownMasterSubscription = {
  onUpdateDropDownMaster?:  {
    __typename: "DropDownMaster",
    pk: string,
    id: string,
    value?: string | null,
    status?: Status | null,
    searchKey?: string | null,
    sequenceNumber?: number | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteDropDownMasterSubscriptionVariables = {
  filter?: ModelSubscriptionDropDownMasterFilterInput | null,
  owner?: string | null,
};

export type OnDeleteDropDownMasterSubscription = {
  onDeleteDropDownMaster?:  {
    __typename: "DropDownMaster",
    pk: string,
    id: string,
    value?: string | null,
    status?: Status | null,
    searchKey?: string | null,
    sequenceNumber?: number | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateCartSubscriptionVariables = {
  filter?: ModelSubscriptionCartFilterInput | null,
  owner?: string | null,
};

export type OnCreateCartSubscription = {
  onCreateCart?:  {
    __typename: "Cart",
    pk: string,
    id: string,
    userId: string,
    productID: string,
    createdAt: string,
    productImage?: string | null,
    productName?: string | null,
    mainCategoryId?: string | null,
    mainCategory?: string | null,
    subCategoryId?: string | null,
    subCategoryN?: string | null,
    attrValueID?: string | null,
    price?: number | null,
    salesPrice?: number | null,
    stockUnit?: string | null,
    itemsPerUnit?: number | null,
    expiryDate?: string | null,
    selectedQuantity?: number | null,
    brandDetail?:  {
      __typename: "BrandDetail",
      brandID?: string | null,
      brandName?: string | null,
      brandDescription?: string | null,
      manufacturerName?: string | null,
      imageUrl?: string | null,
    } | null,
    wareHouseId?: string | null,
    wareHouseName?: string | null,
    attrName?: string | null,
    attrValue?: string | null,
    shippingInfo?:  {
      __typename: "shippingDetails",
      weightValue?: string | null,
      weightUnit?: string | null,
      lengthValue?: string | null,
      lengthUnit?: string | null,
      heightValue?: string | null,
      heightUnit?: string | null,
      widthValue?: string | null,
      widthUnit?: string | null,
    } | null,
    userName?: string | null,
    userMail?: string | null,
    model?: string | null,
    cartURL?: string | null,
    taxCode?: string | null,
    taxCharge?: number | null,
    taxDetail?:  Array< {
      __typename: "taxDetail",
      taxCharge?: number | null,
      taxID?: string | null,
      taxName?: string | null,
    } | null > | null,
    sku?: string | null,
    searchKey?: string | null,
    totalPrice?: number | null,
    mobileNumber?: string | null,
    currentStock?: string | null,
    brandId?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCartSubscriptionVariables = {
  filter?: ModelSubscriptionCartFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCartSubscription = {
  onUpdateCart?:  {
    __typename: "Cart",
    pk: string,
    id: string,
    userId: string,
    productID: string,
    createdAt: string,
    productImage?: string | null,
    productName?: string | null,
    mainCategoryId?: string | null,
    mainCategory?: string | null,
    subCategoryId?: string | null,
    subCategoryN?: string | null,
    attrValueID?: string | null,
    price?: number | null,
    salesPrice?: number | null,
    stockUnit?: string | null,
    itemsPerUnit?: number | null,
    expiryDate?: string | null,
    selectedQuantity?: number | null,
    brandDetail?:  {
      __typename: "BrandDetail",
      brandID?: string | null,
      brandName?: string | null,
      brandDescription?: string | null,
      manufacturerName?: string | null,
      imageUrl?: string | null,
    } | null,
    wareHouseId?: string | null,
    wareHouseName?: string | null,
    attrName?: string | null,
    attrValue?: string | null,
    shippingInfo?:  {
      __typename: "shippingDetails",
      weightValue?: string | null,
      weightUnit?: string | null,
      lengthValue?: string | null,
      lengthUnit?: string | null,
      heightValue?: string | null,
      heightUnit?: string | null,
      widthValue?: string | null,
      widthUnit?: string | null,
    } | null,
    userName?: string | null,
    userMail?: string | null,
    model?: string | null,
    cartURL?: string | null,
    taxCode?: string | null,
    taxCharge?: number | null,
    taxDetail?:  Array< {
      __typename: "taxDetail",
      taxCharge?: number | null,
      taxID?: string | null,
      taxName?: string | null,
    } | null > | null,
    sku?: string | null,
    searchKey?: string | null,
    totalPrice?: number | null,
    mobileNumber?: string | null,
    currentStock?: string | null,
    brandId?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCartSubscriptionVariables = {
  filter?: ModelSubscriptionCartFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCartSubscription = {
  onDeleteCart?:  {
    __typename: "Cart",
    pk: string,
    id: string,
    userId: string,
    productID: string,
    createdAt: string,
    productImage?: string | null,
    productName?: string | null,
    mainCategoryId?: string | null,
    mainCategory?: string | null,
    subCategoryId?: string | null,
    subCategoryN?: string | null,
    attrValueID?: string | null,
    price?: number | null,
    salesPrice?: number | null,
    stockUnit?: string | null,
    itemsPerUnit?: number | null,
    expiryDate?: string | null,
    selectedQuantity?: number | null,
    brandDetail?:  {
      __typename: "BrandDetail",
      brandID?: string | null,
      brandName?: string | null,
      brandDescription?: string | null,
      manufacturerName?: string | null,
      imageUrl?: string | null,
    } | null,
    wareHouseId?: string | null,
    wareHouseName?: string | null,
    attrName?: string | null,
    attrValue?: string | null,
    shippingInfo?:  {
      __typename: "shippingDetails",
      weightValue?: string | null,
      weightUnit?: string | null,
      lengthValue?: string | null,
      lengthUnit?: string | null,
      heightValue?: string | null,
      heightUnit?: string | null,
      widthValue?: string | null,
      widthUnit?: string | null,
    } | null,
    userName?: string | null,
    userMail?: string | null,
    model?: string | null,
    cartURL?: string | null,
    taxCode?: string | null,
    taxCharge?: number | null,
    taxDetail?:  Array< {
      __typename: "taxDetail",
      taxCharge?: number | null,
      taxID?: string | null,
      taxName?: string | null,
    } | null > | null,
    sku?: string | null,
    searchKey?: string | null,
    totalPrice?: number | null,
    mobileNumber?: string | null,
    currentStock?: string | null,
    brandId?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateWareHouseSubscriptionVariables = {
  filter?: ModelSubscriptionWareHouseFilterInput | null,
  owner?: string | null,
};

export type OnCreateWareHouseSubscription = {
  onCreateWareHouse?:  {
    __typename: "WareHouse",
    pk: string,
    id: string,
    name: string,
    createdAt: string,
    addressLine1?: string | null,
    addressLine2?: string | null,
    searchKey?: string | null,
    countryId?: string | null,
    country?: string | null,
    state?: string | null,
    city?: string | null,
    zipCode?: string | null,
    contactPerson?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    workingHoursFrom?: string | null,
    workingHoursTo?: string | null,
    status?: boolean | null,
    latitude?: string | null,
    longitude?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateWareHouseSubscriptionVariables = {
  filter?: ModelSubscriptionWareHouseFilterInput | null,
  owner?: string | null,
};

export type OnUpdateWareHouseSubscription = {
  onUpdateWareHouse?:  {
    __typename: "WareHouse",
    pk: string,
    id: string,
    name: string,
    createdAt: string,
    addressLine1?: string | null,
    addressLine2?: string | null,
    searchKey?: string | null,
    countryId?: string | null,
    country?: string | null,
    state?: string | null,
    city?: string | null,
    zipCode?: string | null,
    contactPerson?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    workingHoursFrom?: string | null,
    workingHoursTo?: string | null,
    status?: boolean | null,
    latitude?: string | null,
    longitude?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteWareHouseSubscriptionVariables = {
  filter?: ModelSubscriptionWareHouseFilterInput | null,
  owner?: string | null,
};

export type OnDeleteWareHouseSubscription = {
  onDeleteWareHouse?:  {
    __typename: "WareHouse",
    pk: string,
    id: string,
    name: string,
    createdAt: string,
    addressLine1?: string | null,
    addressLine2?: string | null,
    searchKey?: string | null,
    countryId?: string | null,
    country?: string | null,
    state?: string | null,
    city?: string | null,
    zipCode?: string | null,
    contactPerson?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    workingHoursFrom?: string | null,
    workingHoursTo?: string | null,
    status?: boolean | null,
    latitude?: string | null,
    longitude?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateGeneralContactUsSubscriptionVariables = {
  filter?: ModelSubscriptionGeneralContactUsFilterInput | null,
  owner?: string | null,
};

export type OnCreateGeneralContactUsSubscription = {
  onCreateGeneralContactUs?:  {
    __typename: "GeneralContactUs",
    pk: string,
    id: string,
    referenceNumber?: string | null,
    userName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    companyName?: string | null,
    subject?: string | null,
    message?: string | null,
    replyMessage?: string | null,
    enquiryStatus?: EnquiryStatus | null,
    attachments?: Array< string | null > | null,
    searchKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateGeneralContactUsSubscriptionVariables = {
  filter?: ModelSubscriptionGeneralContactUsFilterInput | null,
  owner?: string | null,
};

export type OnUpdateGeneralContactUsSubscription = {
  onUpdateGeneralContactUs?:  {
    __typename: "GeneralContactUs",
    pk: string,
    id: string,
    referenceNumber?: string | null,
    userName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    companyName?: string | null,
    subject?: string | null,
    message?: string | null,
    replyMessage?: string | null,
    enquiryStatus?: EnquiryStatus | null,
    attachments?: Array< string | null > | null,
    searchKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteGeneralContactUsSubscriptionVariables = {
  filter?: ModelSubscriptionGeneralContactUsFilterInput | null,
  owner?: string | null,
};

export type OnDeleteGeneralContactUsSubscription = {
  onDeleteGeneralContactUs?:  {
    __typename: "GeneralContactUs",
    pk: string,
    id: string,
    referenceNumber?: string | null,
    userName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    companyName?: string | null,
    subject?: string | null,
    message?: string | null,
    replyMessage?: string | null,
    enquiryStatus?: EnquiryStatus | null,
    attachments?: Array< string | null > | null,
    searchKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateNotificationsSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationsFilterInput | null,
  owner?: string | null,
};

export type OnCreateNotificationsSubscription = {
  onCreateNotifications?:  {
    __typename: "Notifications",
    pk: string,
    id: string,
    userId?: string | null,
    userName?: string | null,
    notificationType?: string | null,
    Title?: string | null,
    Message?: string | null,
    orderID?: string | null,
    DateTime?: string | null,
    notificationStatus?: number | null,
    notificationModule?: string | null,
    referenceID?: string | null,
    targetURL?: string | null,
    searchKey?: string | null,
    expiryDate?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateNotificationsSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationsFilterInput | null,
  owner?: string | null,
};

export type OnUpdateNotificationsSubscription = {
  onUpdateNotifications?:  {
    __typename: "Notifications",
    pk: string,
    id: string,
    userId?: string | null,
    userName?: string | null,
    notificationType?: string | null,
    Title?: string | null,
    Message?: string | null,
    orderID?: string | null,
    DateTime?: string | null,
    notificationStatus?: number | null,
    notificationModule?: string | null,
    referenceID?: string | null,
    targetURL?: string | null,
    searchKey?: string | null,
    expiryDate?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteNotificationsSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationsFilterInput | null,
  owner?: string | null,
};

export type OnDeleteNotificationsSubscription = {
  onDeleteNotifications?:  {
    __typename: "Notifications",
    pk: string,
    id: string,
    userId?: string | null,
    userName?: string | null,
    notificationType?: string | null,
    Title?: string | null,
    Message?: string | null,
    orderID?: string | null,
    DateTime?: string | null,
    notificationStatus?: number | null,
    notificationModule?: string | null,
    referenceID?: string | null,
    targetURL?: string | null,
    searchKey?: string | null,
    expiryDate?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateModuleMasterSubscriptionVariables = {
  filter?: ModelSubscriptionModuleMasterFilterInput | null,
  owner?: string | null,
};

export type OnCreateModuleMasterSubscription = {
  onCreateModuleMaster?:  {
    __typename: "ModuleMaster",
    pk: string,
    id: string,
    moduleName?: string | null,
    sequenceNumber?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateModuleMasterSubscriptionVariables = {
  filter?: ModelSubscriptionModuleMasterFilterInput | null,
  owner?: string | null,
};

export type OnUpdateModuleMasterSubscription = {
  onUpdateModuleMaster?:  {
    __typename: "ModuleMaster",
    pk: string,
    id: string,
    moduleName?: string | null,
    sequenceNumber?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteModuleMasterSubscriptionVariables = {
  filter?: ModelSubscriptionModuleMasterFilterInput | null,
  owner?: string | null,
};

export type OnDeleteModuleMasterSubscription = {
  onDeleteModuleMaster?:  {
    __typename: "ModuleMaster",
    pk: string,
    id: string,
    moduleName?: string | null,
    sequenceNumber?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateRoleManagementSubscriptionVariables = {
  filter?: ModelSubscriptionRoleManagementFilterInput | null,
  owner?: string | null,
};

export type OnCreateRoleManagementSubscription = {
  onCreateRoleManagement?:  {
    __typename: "RoleManagement",
    pk: string,
    id: string,
    menuId?: string | null,
    roleName: string,
    module?:  Array< {
      __typename: "Module",
      moduleId?: string | null,
      menu?: string | null,
      view?: boolean | null,
      add?: boolean | null,
      edit?: boolean | null,
      delete?: boolean | null,
    } | null > | null,
    status?: Status | null,
    searchKey?: string | null,
    updatedAt: string,
    createdAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateRoleManagementSubscriptionVariables = {
  filter?: ModelSubscriptionRoleManagementFilterInput | null,
  owner?: string | null,
};

export type OnUpdateRoleManagementSubscription = {
  onUpdateRoleManagement?:  {
    __typename: "RoleManagement",
    pk: string,
    id: string,
    menuId?: string | null,
    roleName: string,
    module?:  Array< {
      __typename: "Module",
      moduleId?: string | null,
      menu?: string | null,
      view?: boolean | null,
      add?: boolean | null,
      edit?: boolean | null,
      delete?: boolean | null,
    } | null > | null,
    status?: Status | null,
    searchKey?: string | null,
    updatedAt: string,
    createdAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteRoleManagementSubscriptionVariables = {
  filter?: ModelSubscriptionRoleManagementFilterInput | null,
  owner?: string | null,
};

export type OnDeleteRoleManagementSubscription = {
  onDeleteRoleManagement?:  {
    __typename: "RoleManagement",
    pk: string,
    id: string,
    menuId?: string | null,
    roleName: string,
    module?:  Array< {
      __typename: "Module",
      moduleId?: string | null,
      menu?: string | null,
      view?: boolean | null,
      add?: boolean | null,
      edit?: boolean | null,
      delete?: boolean | null,
    } | null > | null,
    status?: Status | null,
    searchKey?: string | null,
    updatedAt: string,
    createdAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateContentSubscriptionVariables = {
  filter?: ModelSubscriptionContentFilterInput | null,
  owner?: string | null,
};

export type OnCreateContentSubscription = {
  onCreateContent?:  {
    __typename: "Content",
    pk: string,
    id: string,
    contentName: string,
    title?: string | null,
    sequenceNumber?: number | null,
    content?: string | null,
    searchKey?: string | null,
    docUrl?: string | null,
    visibleToOthers?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateContentSubscriptionVariables = {
  filter?: ModelSubscriptionContentFilterInput | null,
  owner?: string | null,
};

export type OnUpdateContentSubscription = {
  onUpdateContent?:  {
    __typename: "Content",
    pk: string,
    id: string,
    contentName: string,
    title?: string | null,
    sequenceNumber?: number | null,
    content?: string | null,
    searchKey?: string | null,
    docUrl?: string | null,
    visibleToOthers?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteContentSubscriptionVariables = {
  filter?: ModelSubscriptionContentFilterInput | null,
  owner?: string | null,
};

export type OnDeleteContentSubscription = {
  onDeleteContent?:  {
    __typename: "Content",
    pk: string,
    id: string,
    contentName: string,
    title?: string | null,
    sequenceNumber?: number | null,
    content?: string | null,
    searchKey?: string | null,
    docUrl?: string | null,
    visibleToOthers?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateFAQSubscriptionVariables = {
  filter?: ModelSubscriptionFAQFilterInput | null,
  owner?: string | null,
};

export type OnCreateFAQSubscription = {
  onCreateFAQ?:  {
    __typename: "FAQ",
    pk: string,
    id: string,
    type?: string | null,
    sequenceNumber?: number | null,
    name?: string | null,
    FAQSections?:  Array< {
      __typename: "FAQSections",
      question?: string | null,
      answer?: string | null,
    } | null > | null,
    searchKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateFAQSubscriptionVariables = {
  filter?: ModelSubscriptionFAQFilterInput | null,
  owner?: string | null,
};

export type OnUpdateFAQSubscription = {
  onUpdateFAQ?:  {
    __typename: "FAQ",
    pk: string,
    id: string,
    type?: string | null,
    sequenceNumber?: number | null,
    name?: string | null,
    FAQSections?:  Array< {
      __typename: "FAQSections",
      question?: string | null,
      answer?: string | null,
    } | null > | null,
    searchKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteFAQSubscriptionVariables = {
  filter?: ModelSubscriptionFAQFilterInput | null,
  owner?: string | null,
};

export type OnDeleteFAQSubscription = {
  onDeleteFAQ?:  {
    __typename: "FAQ",
    pk: string,
    id: string,
    type?: string | null,
    sequenceNumber?: number | null,
    name?: string | null,
    FAQSections?:  Array< {
      __typename: "FAQSections",
      question?: string | null,
      answer?: string | null,
    } | null > | null,
    searchKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateProductReviewSubscriptionVariables = {
  filter?: ModelSubscriptionProductReviewFilterInput | null,
  owner?: string | null,
};

export type OnCreateProductReviewSubscription = {
  onCreateProductReview?:  {
    __typename: "ProductReview",
    pk: string,
    id: string,
    orderItemId: string,
    productId?: string | null,
    productImage?: string | null,
    productName?: string | null,
    userId?: string | null,
    userName?: string | null,
    email?: string | null,
    overallRating?: number | null,
    title?: string | null,
    reviewComment?: string | null,
    removeAbusiveComment?: boolean | null,
    abusiveReportedBy?:  Array< {
      __typename: "AbusiveReport",
      userId?: string | null,
      userName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      reasons?: Array< string | null > | null,
      reportedDate?: string | null,
    } | null > | null,
    attachments?: Array< string | null > | null,
    createdAt?: string | null,
    reply?: string | null,
    repliedOnDate?: string | null,
    orderedDate?: string | null,
    reviewedDate?: string | null,
    reviewStatus?: EnquiryStatus | null,
    searchKey?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateProductReviewSubscriptionVariables = {
  filter?: ModelSubscriptionProductReviewFilterInput | null,
  owner?: string | null,
};

export type OnUpdateProductReviewSubscription = {
  onUpdateProductReview?:  {
    __typename: "ProductReview",
    pk: string,
    id: string,
    orderItemId: string,
    productId?: string | null,
    productImage?: string | null,
    productName?: string | null,
    userId?: string | null,
    userName?: string | null,
    email?: string | null,
    overallRating?: number | null,
    title?: string | null,
    reviewComment?: string | null,
    removeAbusiveComment?: boolean | null,
    abusiveReportedBy?:  Array< {
      __typename: "AbusiveReport",
      userId?: string | null,
      userName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      reasons?: Array< string | null > | null,
      reportedDate?: string | null,
    } | null > | null,
    attachments?: Array< string | null > | null,
    createdAt?: string | null,
    reply?: string | null,
    repliedOnDate?: string | null,
    orderedDate?: string | null,
    reviewedDate?: string | null,
    reviewStatus?: EnquiryStatus | null,
    searchKey?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteProductReviewSubscriptionVariables = {
  filter?: ModelSubscriptionProductReviewFilterInput | null,
  owner?: string | null,
};

export type OnDeleteProductReviewSubscription = {
  onDeleteProductReview?:  {
    __typename: "ProductReview",
    pk: string,
    id: string,
    orderItemId: string,
    productId?: string | null,
    productImage?: string | null,
    productName?: string | null,
    userId?: string | null,
    userName?: string | null,
    email?: string | null,
    overallRating?: number | null,
    title?: string | null,
    reviewComment?: string | null,
    removeAbusiveComment?: boolean | null,
    abusiveReportedBy?:  Array< {
      __typename: "AbusiveReport",
      userId?: string | null,
      userName?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      reasons?: Array< string | null > | null,
      reportedDate?: string | null,
    } | null > | null,
    attachments?: Array< string | null > | null,
    createdAt?: string | null,
    reply?: string | null,
    repliedOnDate?: string | null,
    orderedDate?: string | null,
    reviewedDate?: string | null,
    reviewStatus?: EnquiryStatus | null,
    searchKey?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateTokenDetailsSubscriptionVariables = {
  filter?: ModelSubscriptionTokenDetailsFilterInput | null,
  owner?: string | null,
};

export type OnCreateTokenDetailsSubscription = {
  onCreateTokenDetails?:  {
    __typename: "TokenDetails",
    pk: string,
    id: string,
    expiresIn?: string | null,
    token: string,
    tokenType?: string | null,
    userID: string,
    loginAt?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateTokenDetailsSubscriptionVariables = {
  filter?: ModelSubscriptionTokenDetailsFilterInput | null,
  owner?: string | null,
};

export type OnUpdateTokenDetailsSubscription = {
  onUpdateTokenDetails?:  {
    __typename: "TokenDetails",
    pk: string,
    id: string,
    expiresIn?: string | null,
    token: string,
    tokenType?: string | null,
    userID: string,
    loginAt?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteTokenDetailsSubscriptionVariables = {
  filter?: ModelSubscriptionTokenDetailsFilterInput | null,
  owner?: string | null,
};

export type OnDeleteTokenDetailsSubscription = {
  onDeleteTokenDetails?:  {
    __typename: "TokenDetails",
    pk: string,
    id: string,
    expiresIn?: string | null,
    token: string,
    tokenType?: string | null,
    userID: string,
    loginAt?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateTaxSubscriptionVariables = {
  filter?: ModelSubscriptionTaxFilterInput | null,
  owner?: string | null,
};

export type OnCreateTaxSubscription = {
  onCreateTax?:  {
    __typename: "Tax",
    pk: string,
    id: string,
    taxCode: string,
    taxType?: string | null,
    status?: Status | null,
    searchKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateTaxSubscriptionVariables = {
  filter?: ModelSubscriptionTaxFilterInput | null,
  owner?: string | null,
};

export type OnUpdateTaxSubscription = {
  onUpdateTax?:  {
    __typename: "Tax",
    pk: string,
    id: string,
    taxCode: string,
    taxType?: string | null,
    status?: Status | null,
    searchKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteTaxSubscriptionVariables = {
  filter?: ModelSubscriptionTaxFilterInput | null,
  owner?: string | null,
};

export type OnDeleteTaxSubscription = {
  onDeleteTax?:  {
    __typename: "Tax",
    pk: string,
    id: string,
    taxCode: string,
    taxType?: string | null,
    status?: Status | null,
    searchKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateCarouselImageSubscriptionVariables = {
  filter?: ModelSubscriptionCarouselImageFilterInput | null,
  owner?: string | null,
};

export type OnCreateCarouselImageSubscription = {
  onCreateCarouselImage?:  {
    __typename: "CarouselImage",
    pk: string,
    id: string,
    type?: string | null,
    deviceType?: string | null,
    animation?: animationType | null,
    pauseTime?: number | null,
    transitionSpeed?: number | null,
    stopAnimation?: boolean | null,
    displayArrows?: boolean | null,
    displayNavigationBullets?: boolean | null,
    image?:  Array< {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null > | null,
    status?: Status | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateCarouselImageSubscriptionVariables = {
  filter?: ModelSubscriptionCarouselImageFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCarouselImageSubscription = {
  onUpdateCarouselImage?:  {
    __typename: "CarouselImage",
    pk: string,
    id: string,
    type?: string | null,
    deviceType?: string | null,
    animation?: animationType | null,
    pauseTime?: number | null,
    transitionSpeed?: number | null,
    stopAnimation?: boolean | null,
    displayArrows?: boolean | null,
    displayNavigationBullets?: boolean | null,
    image?:  Array< {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null > | null,
    status?: Status | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteCarouselImageSubscriptionVariables = {
  filter?: ModelSubscriptionCarouselImageFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCarouselImageSubscription = {
  onDeleteCarouselImage?:  {
    __typename: "CarouselImage",
    pk: string,
    id: string,
    type?: string | null,
    deviceType?: string | null,
    animation?: animationType | null,
    pauseTime?: number | null,
    transitionSpeed?: number | null,
    stopAnimation?: boolean | null,
    displayArrows?: boolean | null,
    displayNavigationBullets?: boolean | null,
    image?:  Array< {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null > | null,
    status?: Status | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateWishListSubscriptionVariables = {
  filter?: ModelSubscriptionWishListFilterInput | null,
  owner?: string | null,
};

export type OnCreateWishListSubscription = {
  onCreateWishList?:  {
    __typename: "WishList",
    pk: string,
    id: string,
    userId?: string | null,
    productId?: string | null,
    productName?: string | null,
    productImage?: string | null,
    mainCategory?: string | null,
    mainCategoryId?: string | null,
    subCategory?: string | null,
    subCategoryId?: string | null,
    brandName?: string | null,
    brandId?: string | null,
    model?: string | null,
    rating?: number | null,
    regularPrice?: number | null,
    salePrice?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateWishListSubscriptionVariables = {
  filter?: ModelSubscriptionWishListFilterInput | null,
  owner?: string | null,
};

export type OnUpdateWishListSubscription = {
  onUpdateWishList?:  {
    __typename: "WishList",
    pk: string,
    id: string,
    userId?: string | null,
    productId?: string | null,
    productName?: string | null,
    productImage?: string | null,
    mainCategory?: string | null,
    mainCategoryId?: string | null,
    subCategory?: string | null,
    subCategoryId?: string | null,
    brandName?: string | null,
    brandId?: string | null,
    model?: string | null,
    rating?: number | null,
    regularPrice?: number | null,
    salePrice?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteWishListSubscriptionVariables = {
  filter?: ModelSubscriptionWishListFilterInput | null,
  owner?: string | null,
};

export type OnDeleteWishListSubscription = {
  onDeleteWishList?:  {
    __typename: "WishList",
    pk: string,
    id: string,
    userId?: string | null,
    productId?: string | null,
    productName?: string | null,
    productImage?: string | null,
    mainCategory?: string | null,
    mainCategoryId?: string | null,
    subCategory?: string | null,
    subCategoryId?: string | null,
    brandName?: string | null,
    brandId?: string | null,
    model?: string | null,
    rating?: number | null,
    regularPrice?: number | null,
    salePrice?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateConfigurationSubscriptionVariables = {
  filter?: ModelSubscriptionConfigurationFilterInput | null,
  owner?: string | null,
};

export type OnCreateConfigurationSubscription = {
  onCreateConfiguration?:  {
    __typename: "configuration",
    pk: string,
    id: string,
    configurationDescription?: string | null,
    continuousSettings?:  {
      __typename: "ContinuousSettings",
      prefixValue?: string | null,
      numberOfDigits?: number | null,
      includeYearSuffix?: boolean | null,
      startingNumber?: number | null,
      runningNumber?: number | null,
    } | null,
    type?: string | null,
    modifiedTime?: string | null,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateConfigurationSubscriptionVariables = {
  filter?: ModelSubscriptionConfigurationFilterInput | null,
  owner?: string | null,
};

export type OnUpdateConfigurationSubscription = {
  onUpdateConfiguration?:  {
    __typename: "configuration",
    pk: string,
    id: string,
    configurationDescription?: string | null,
    continuousSettings?:  {
      __typename: "ContinuousSettings",
      prefixValue?: string | null,
      numberOfDigits?: number | null,
      includeYearSuffix?: boolean | null,
      startingNumber?: number | null,
      runningNumber?: number | null,
    } | null,
    type?: string | null,
    modifiedTime?: string | null,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteConfigurationSubscriptionVariables = {
  filter?: ModelSubscriptionConfigurationFilterInput | null,
  owner?: string | null,
};

export type OnDeleteConfigurationSubscription = {
  onDeleteConfiguration?:  {
    __typename: "configuration",
    pk: string,
    id: string,
    configurationDescription?: string | null,
    continuousSettings?:  {
      __typename: "ContinuousSettings",
      prefixValue?: string | null,
      numberOfDigits?: number | null,
      includeYearSuffix?: boolean | null,
      startingNumber?: number | null,
      runningNumber?: number | null,
    } | null,
    type?: string | null,
    modifiedTime?: string | null,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateMailLogSubscriptionVariables = {
  filter?: ModelSubscriptionMailLogFilterInput | null,
  owner?: string | null,
};

export type OnCreateMailLogSubscription = {
  onCreateMailLog?:  {
    __typename: "MailLog",
    pk: string,
    id: string,
    referenceId?: string | null,
    referenceForm?: string | null,
    userId?: string | null,
    mailDetails?:  Array< {
      __typename: "EmailDetails",
      sentTo?: string | null,
      sentDate?: string | null,
    } | null > | null,
    updatedAt?: string | null,
    createdAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateMailLogSubscriptionVariables = {
  filter?: ModelSubscriptionMailLogFilterInput | null,
  owner?: string | null,
};

export type OnUpdateMailLogSubscription = {
  onUpdateMailLog?:  {
    __typename: "MailLog",
    pk: string,
    id: string,
    referenceId?: string | null,
    referenceForm?: string | null,
    userId?: string | null,
    mailDetails?:  Array< {
      __typename: "EmailDetails",
      sentTo?: string | null,
      sentDate?: string | null,
    } | null > | null,
    updatedAt?: string | null,
    createdAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteMailLogSubscriptionVariables = {
  filter?: ModelSubscriptionMailLogFilterInput | null,
  owner?: string | null,
};

export type OnDeleteMailLogSubscription = {
  onDeleteMailLog?:  {
    __typename: "MailLog",
    pk: string,
    id: string,
    referenceId?: string | null,
    referenceForm?: string | null,
    userId?: string | null,
    mailDetails?:  Array< {
      __typename: "EmailDetails",
      sentTo?: string | null,
      sentDate?: string | null,
    } | null > | null,
    updatedAt?: string | null,
    createdAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateDeliveryPartnerSubscriptionVariables = {
  filter?: ModelSubscriptionDeliveryPartnerFilterInput | null,
  owner?: string | null,
};

export type OnCreateDeliveryPartnerSubscription = {
  onCreateDeliveryPartner?:  {
    __typename: "DeliveryPartner",
    pk: string,
    id: string,
    logo?: string | null,
    logoUrl?: string | null,
    companyName?: string | null,
    displayName?: string | null,
    apiKey?: string | null,
    apiKeyExpiryDate?: string | null,
    contactPerson?: string | null,
    address?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    status?: Status | null,
    searchKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateDeliveryPartnerSubscriptionVariables = {
  filter?: ModelSubscriptionDeliveryPartnerFilterInput | null,
  owner?: string | null,
};

export type OnUpdateDeliveryPartnerSubscription = {
  onUpdateDeliveryPartner?:  {
    __typename: "DeliveryPartner",
    pk: string,
    id: string,
    logo?: string | null,
    logoUrl?: string | null,
    companyName?: string | null,
    displayName?: string | null,
    apiKey?: string | null,
    apiKeyExpiryDate?: string | null,
    contactPerson?: string | null,
    address?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    status?: Status | null,
    searchKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteDeliveryPartnerSubscriptionVariables = {
  filter?: ModelSubscriptionDeliveryPartnerFilterInput | null,
  owner?: string | null,
};

export type OnDeleteDeliveryPartnerSubscription = {
  onDeleteDeliveryPartner?:  {
    __typename: "DeliveryPartner",
    pk: string,
    id: string,
    logo?: string | null,
    logoUrl?: string | null,
    companyName?: string | null,
    displayName?: string | null,
    apiKey?: string | null,
    apiKeyExpiryDate?: string | null,
    contactPerson?: string | null,
    address?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    status?: Status | null,
    searchKey?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateLedgerSubscriptionVariables = {
  filter?: ModelSubscriptionLedgerFilterInput | null,
  owner?: string | null,
};

export type OnCreateLedgerSubscription = {
  onCreateLedger?:  {
    __typename: "Ledger",
    pk: string,
    id: string,
    type?: string | null,
    transDate?: string | null,
    transReference?: string | null,
    transDetails?: string | null,
    transTo?: string | null,
    transToId?: string | null,
    transAmount?: number | null,
    transType?: string | null,
    paymentStatus?: string | null,
    buyerName?: string | null,
    orderRef?: string | null,
    orderAmount?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateLedgerSubscriptionVariables = {
  filter?: ModelSubscriptionLedgerFilterInput | null,
  owner?: string | null,
};

export type OnUpdateLedgerSubscription = {
  onUpdateLedger?:  {
    __typename: "Ledger",
    pk: string,
    id: string,
    type?: string | null,
    transDate?: string | null,
    transReference?: string | null,
    transDetails?: string | null,
    transTo?: string | null,
    transToId?: string | null,
    transAmount?: number | null,
    transType?: string | null,
    paymentStatus?: string | null,
    buyerName?: string | null,
    orderRef?: string | null,
    orderAmount?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteLedgerSubscriptionVariables = {
  filter?: ModelSubscriptionLedgerFilterInput | null,
  owner?: string | null,
};

export type OnDeleteLedgerSubscription = {
  onDeleteLedger?:  {
    __typename: "Ledger",
    pk: string,
    id: string,
    type?: string | null,
    transDate?: string | null,
    transReference?: string | null,
    transDetails?: string | null,
    transTo?: string | null,
    transToId?: string | null,
    transAmount?: number | null,
    transType?: string | null,
    paymentStatus?: string | null,
    buyerName?: string | null,
    orderRef?: string | null,
    orderAmount?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateProductVisitSubscriptionVariables = {
  filter?: ModelSubscriptionProductVisitFilterInput | null,
  owner?: string | null,
};

export type OnCreateProductVisitSubscription = {
  onCreateProductVisit?:  {
    __typename: "ProductVisit",
    pk: string,
    id: string,
    prodId?: string | null,
    userId?: string | null,
    userName?: string | null,
    visitedAt?: string | null,
    place?: string | null,
    visitedInfo?:  Array< {
      __typename: "VisitedInfo",
      platform?: string | null,
      location?: string | null,
      time?: string | null,
      ipAddress?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateProductVisitSubscriptionVariables = {
  filter?: ModelSubscriptionProductVisitFilterInput | null,
  owner?: string | null,
};

export type OnUpdateProductVisitSubscription = {
  onUpdateProductVisit?:  {
    __typename: "ProductVisit",
    pk: string,
    id: string,
    prodId?: string | null,
    userId?: string | null,
    userName?: string | null,
    visitedAt?: string | null,
    place?: string | null,
    visitedInfo?:  Array< {
      __typename: "VisitedInfo",
      platform?: string | null,
      location?: string | null,
      time?: string | null,
      ipAddress?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteProductVisitSubscriptionVariables = {
  filter?: ModelSubscriptionProductVisitFilterInput | null,
  owner?: string | null,
};

export type OnDeleteProductVisitSubscription = {
  onDeleteProductVisit?:  {
    __typename: "ProductVisit",
    pk: string,
    id: string,
    prodId?: string | null,
    userId?: string | null,
    userName?: string | null,
    visitedAt?: string | null,
    place?: string | null,
    visitedInfo?:  Array< {
      __typename: "VisitedInfo",
      platform?: string | null,
      location?: string | null,
      time?: string | null,
      ipAddress?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreatePromoCodesSubscriptionVariables = {
  filter?: ModelSubscriptionPromoCodesFilterInput | null,
  owner?: string | null,
};

export type OnCreatePromoCodesSubscription = {
  onCreatePromoCodes?:  {
    __typename: "PromoCodes",
    pk: string,
    id: string,
    promoImage?: string | null,
    promoURL?: string | null,
    code?: string | null,
    discount?:  {
      __typename: "discountType",
      type?: string | null,
      value?: string | null,
    } | null,
    redemptionType?: string | null,
    searchKey?: string | null,
    validFrom?: string | null,
    validTo?: string | null,
    description?: string | null,
    terms?: string | null,
    advancedSetting?:  {
      __typename: "AdvancedSettings",
      appliedTo?: string | null,
      specificType?:  Array< {
        __typename: "SpecificType",
        name?: string | null,
        id?: string | null,
      } | null > | null,
    } | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdatePromoCodesSubscriptionVariables = {
  filter?: ModelSubscriptionPromoCodesFilterInput | null,
  owner?: string | null,
};

export type OnUpdatePromoCodesSubscription = {
  onUpdatePromoCodes?:  {
    __typename: "PromoCodes",
    pk: string,
    id: string,
    promoImage?: string | null,
    promoURL?: string | null,
    code?: string | null,
    discount?:  {
      __typename: "discountType",
      type?: string | null,
      value?: string | null,
    } | null,
    redemptionType?: string | null,
    searchKey?: string | null,
    validFrom?: string | null,
    validTo?: string | null,
    description?: string | null,
    terms?: string | null,
    advancedSetting?:  {
      __typename: "AdvancedSettings",
      appliedTo?: string | null,
      specificType?:  Array< {
        __typename: "SpecificType",
        name?: string | null,
        id?: string | null,
      } | null > | null,
    } | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeletePromoCodesSubscriptionVariables = {
  filter?: ModelSubscriptionPromoCodesFilterInput | null,
  owner?: string | null,
};

export type OnDeletePromoCodesSubscription = {
  onDeletePromoCodes?:  {
    __typename: "PromoCodes",
    pk: string,
    id: string,
    promoImage?: string | null,
    promoURL?: string | null,
    code?: string | null,
    discount?:  {
      __typename: "discountType",
      type?: string | null,
      value?: string | null,
    } | null,
    redemptionType?: string | null,
    searchKey?: string | null,
    validFrom?: string | null,
    validTo?: string | null,
    description?: string | null,
    terms?: string | null,
    advancedSetting?:  {
      __typename: "AdvancedSettings",
      appliedTo?: string | null,
      specificType?:  Array< {
        __typename: "SpecificType",
        name?: string | null,
        id?: string | null,
      } | null > | null,
    } | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateJwtTokenIdentifierSubscriptionVariables = {
  filter?: ModelSubscriptionJwtTokenIdentifierFilterInput | null,
  owner?: string | null,
};

export type OnCreateJwtTokenIdentifierSubscription = {
  onCreateJwtTokenIdentifier?:  {
    __typename: "jwtTokenIdentifier",
    pk: string,
    jtiValue: string,
    issueDate?: string | null,
    expiryDateTime?: string | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateJwtTokenIdentifierSubscriptionVariables = {
  filter?: ModelSubscriptionJwtTokenIdentifierFilterInput | null,
  owner?: string | null,
};

export type OnUpdateJwtTokenIdentifierSubscription = {
  onUpdateJwtTokenIdentifier?:  {
    __typename: "jwtTokenIdentifier",
    pk: string,
    jtiValue: string,
    issueDate?: string | null,
    expiryDateTime?: string | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteJwtTokenIdentifierSubscriptionVariables = {
  filter?: ModelSubscriptionJwtTokenIdentifierFilterInput | null,
  owner?: string | null,
};

export type OnDeleteJwtTokenIdentifierSubscription = {
  onDeleteJwtTokenIdentifier?:  {
    __typename: "jwtTokenIdentifier",
    pk: string,
    jtiValue: string,
    issueDate?: string | null,
    expiryDateTime?: string | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateUnitsSubscriptionVariables = {
  filter?: ModelSubscriptionUnitsFilterInput | null,
  owner?: string | null,
};

export type OnCreateUnitsSubscription = {
  onCreateUnits?:  {
    __typename: "Units",
    pk: string,
    id: string,
    value?: string | null,
    sequenceNumber?: number | null,
    searchKey?: string | null,
    type?: UnitType | null,
    status?: Status | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateUnitsSubscriptionVariables = {
  filter?: ModelSubscriptionUnitsFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUnitsSubscription = {
  onUpdateUnits?:  {
    __typename: "Units",
    pk: string,
    id: string,
    value?: string | null,
    sequenceNumber?: number | null,
    searchKey?: string | null,
    type?: UnitType | null,
    status?: Status | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteUnitsSubscriptionVariables = {
  filter?: ModelSubscriptionUnitsFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUnitsSubscription = {
  onDeleteUnits?:  {
    __typename: "Units",
    pk: string,
    id: string,
    value?: string | null,
    sequenceNumber?: number | null,
    searchKey?: string | null,
    type?: UnitType | null,
    status?: Status | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateShippingSubscriptionVariables = {
  filter?: ModelSubscriptionShippingFilterInput | null,
  owner?: string | null,
};

export type OnCreateShippingSubscription = {
  onCreateShipping?:  {
    __typename: "Shipping",
    id: string,
    pk: string,
    city?: string | null,
    countryId?: string | null,
    countryName?: string | null,
    state?: string | null,
    deliveryCharge?: number | null,
    coverage?: Coverage | null,
    zipCodes?: Array< string | null > | null,
    latitude?: string | null,
    longitude?: string | null,
    radius?: string | null,
    searchKey?: string | null,
    status?: Status | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateShippingSubscriptionVariables = {
  filter?: ModelSubscriptionShippingFilterInput | null,
  owner?: string | null,
};

export type OnUpdateShippingSubscription = {
  onUpdateShipping?:  {
    __typename: "Shipping",
    id: string,
    pk: string,
    city?: string | null,
    countryId?: string | null,
    countryName?: string | null,
    state?: string | null,
    deliveryCharge?: number | null,
    coverage?: Coverage | null,
    zipCodes?: Array< string | null > | null,
    latitude?: string | null,
    longitude?: string | null,
    radius?: string | null,
    searchKey?: string | null,
    status?: Status | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteShippingSubscriptionVariables = {
  filter?: ModelSubscriptionShippingFilterInput | null,
  owner?: string | null,
};

export type OnDeleteShippingSubscription = {
  onDeleteShipping?:  {
    __typename: "Shipping",
    id: string,
    pk: string,
    city?: string | null,
    countryId?: string | null,
    countryName?: string | null,
    state?: string | null,
    deliveryCharge?: number | null,
    coverage?: Coverage | null,
    zipCodes?: Array< string | null > | null,
    latitude?: string | null,
    longitude?: string | null,
    radius?: string | null,
    searchKey?: string | null,
    status?: Status | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateProductSubscriptionVariables = {
  filter?: ModelSubscriptionProductFilterInput | null,
  owner?: string | null,
};

export type OnCreateProductSubscription = {
  onCreateProduct?:  {
    __typename: "Product",
    createdAt: string,
    id: string,
    pk: string,
    globalPk: string,
    productName: string,
    mainCategory?: string | null,
    mainCategoryID?: string | null,
    subCategory?: string | null,
    subCategoryID?: string | null,
    brandName?: string | null,
    brandID?: string | null,
    countryName?: string | null,
    countryID?: string | null,
    productDescription?: string | null,
    productFeatures?: string | null,
    keyWord?: Array< string | null > | null,
    featuredImage?:  {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null,
    galleryImages?:  Array< {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null > | null,
    singleVariant?: boolean | null,
    attrName?: string | null,
    attrNameID?: string | null,
    attrValue?:  Array< {
      __typename: "AttributeValues",
      value?: string | null,
      attrValueID?: string | null,
    } | null > | null,
    productSpecification?:  Array< {
      __typename: "productSpecification",
      name?: string | null,
      value?: string | null,
    } | null > | null,
    seoDescription?: string | null,
    stockStatus?: stockStatus | null,
    searchKey?: string | null,
    status?: productStatus | null,
    scheduleDate?: string | null,
    taxDetail?:  Array< {
      __typename: "taxDetail",
      taxCharge?: number | null,
      taxID?: string | null,
      taxName?: string | null,
    } | null > | null,
    showWhenZeroStock?: boolean | null,
    allowPreOrder?: boolean | null,
    overallRating?: number | null,
    noOfRating?: number | null,
    leadTime?: string | null,
    totalStockQuantity?: number | null,
    totalReservedStock?: number | null,
    displaySalePrice?: number | null,
    displayRegularPrice?: number | null,
    thumbnailUrl?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateProductSubscriptionVariables = {
  filter?: ModelSubscriptionProductFilterInput | null,
  owner?: string | null,
};

export type OnUpdateProductSubscription = {
  onUpdateProduct?:  {
    __typename: "Product",
    createdAt: string,
    id: string,
    pk: string,
    globalPk: string,
    productName: string,
    mainCategory?: string | null,
    mainCategoryID?: string | null,
    subCategory?: string | null,
    subCategoryID?: string | null,
    brandName?: string | null,
    brandID?: string | null,
    countryName?: string | null,
    countryID?: string | null,
    productDescription?: string | null,
    productFeatures?: string | null,
    keyWord?: Array< string | null > | null,
    featuredImage?:  {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null,
    galleryImages?:  Array< {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null > | null,
    singleVariant?: boolean | null,
    attrName?: string | null,
    attrNameID?: string | null,
    attrValue?:  Array< {
      __typename: "AttributeValues",
      value?: string | null,
      attrValueID?: string | null,
    } | null > | null,
    productSpecification?:  Array< {
      __typename: "productSpecification",
      name?: string | null,
      value?: string | null,
    } | null > | null,
    seoDescription?: string | null,
    stockStatus?: stockStatus | null,
    searchKey?: string | null,
    status?: productStatus | null,
    scheduleDate?: string | null,
    taxDetail?:  Array< {
      __typename: "taxDetail",
      taxCharge?: number | null,
      taxID?: string | null,
      taxName?: string | null,
    } | null > | null,
    showWhenZeroStock?: boolean | null,
    allowPreOrder?: boolean | null,
    overallRating?: number | null,
    noOfRating?: number | null,
    leadTime?: string | null,
    totalStockQuantity?: number | null,
    totalReservedStock?: number | null,
    displaySalePrice?: number | null,
    displayRegularPrice?: number | null,
    thumbnailUrl?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteProductSubscriptionVariables = {
  filter?: ModelSubscriptionProductFilterInput | null,
  owner?: string | null,
};

export type OnDeleteProductSubscription = {
  onDeleteProduct?:  {
    __typename: "Product",
    createdAt: string,
    id: string,
    pk: string,
    globalPk: string,
    productName: string,
    mainCategory?: string | null,
    mainCategoryID?: string | null,
    subCategory?: string | null,
    subCategoryID?: string | null,
    brandName?: string | null,
    brandID?: string | null,
    countryName?: string | null,
    countryID?: string | null,
    productDescription?: string | null,
    productFeatures?: string | null,
    keyWord?: Array< string | null > | null,
    featuredImage?:  {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null,
    galleryImages?:  Array< {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null > | null,
    singleVariant?: boolean | null,
    attrName?: string | null,
    attrNameID?: string | null,
    attrValue?:  Array< {
      __typename: "AttributeValues",
      value?: string | null,
      attrValueID?: string | null,
    } | null > | null,
    productSpecification?:  Array< {
      __typename: "productSpecification",
      name?: string | null,
      value?: string | null,
    } | null > | null,
    seoDescription?: string | null,
    stockStatus?: stockStatus | null,
    searchKey?: string | null,
    status?: productStatus | null,
    scheduleDate?: string | null,
    taxDetail?:  Array< {
      __typename: "taxDetail",
      taxCharge?: number | null,
      taxID?: string | null,
      taxName?: string | null,
    } | null > | null,
    showWhenZeroStock?: boolean | null,
    allowPreOrder?: boolean | null,
    overallRating?: number | null,
    noOfRating?: number | null,
    leadTime?: string | null,
    totalStockQuantity?: number | null,
    totalReservedStock?: number | null,
    displaySalePrice?: number | null,
    displayRegularPrice?: number | null,
    thumbnailUrl?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateProductImageSubscriptionVariables = {
  filter?: ModelSubscriptionProductImageFilterInput | null,
  owner?: string | null,
};

export type OnCreateProductImageSubscription = {
  onCreateProductImage?:  {
    __typename: "ProductImage",
    pk: string,
    id: string,
    alterText?: string | null,
    title?: string | null,
    url?: string | null,
    description?: string | null,
    mainCategory?: string | null,
    mainCategoryID?: string | null,
    subCategory?: string | null,
    subCategoryID?: string | null,
    thumbnailUrl?: string | null,
    type?: ImageType | null,
    productID?: string | null,
    isDeleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateProductImageSubscriptionVariables = {
  filter?: ModelSubscriptionProductImageFilterInput | null,
  owner?: string | null,
};

export type OnUpdateProductImageSubscription = {
  onUpdateProductImage?:  {
    __typename: "ProductImage",
    pk: string,
    id: string,
    alterText?: string | null,
    title?: string | null,
    url?: string | null,
    description?: string | null,
    mainCategory?: string | null,
    mainCategoryID?: string | null,
    subCategory?: string | null,
    subCategoryID?: string | null,
    thumbnailUrl?: string | null,
    type?: ImageType | null,
    productID?: string | null,
    isDeleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteProductImageSubscriptionVariables = {
  filter?: ModelSubscriptionProductImageFilterInput | null,
  owner?: string | null,
};

export type OnDeleteProductImageSubscription = {
  onDeleteProductImage?:  {
    __typename: "ProductImage",
    pk: string,
    id: string,
    alterText?: string | null,
    title?: string | null,
    url?: string | null,
    description?: string | null,
    mainCategory?: string | null,
    mainCategoryID?: string | null,
    subCategory?: string | null,
    subCategoryID?: string | null,
    thumbnailUrl?: string | null,
    type?: ImageType | null,
    productID?: string | null,
    isDeleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateProductDetailsSubscriptionVariables = {
  filter?: ModelSubscriptionProductDetailsFilterInput | null,
  owner?: string | null,
};

export type OnCreateProductDetailsSubscription = {
  onCreateProductDetails?:  {
    __typename: "ProductDetails",
    id: string,
    pk: string,
    productID?: string | null,
    attrName?: string | null,
    attrNameID?: string | null,
    attrValue?: string | null,
    attrValueID?: string | null,
    featuredImage?:  {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null,
    model?: string | null,
    manufacturePartCode?: string | null,
    sku?: string | null,
    stockStatus?: stockStatus | null,
    stockQuantity?: number | null,
    reservedStock?: number | null,
    stockUnit?: string | null,
    itemsPerUnit?: number | null,
    regularPrice?: number | null,
    salePrice?: number | null,
    dataSheet?: string | null,
    submittal?: string | null,
    catalog?: string | null,
    iom?: string | null,
    storageInfo?:  {
      __typename: "storageInfoDetail",
      wareHouseId?: string | null,
      wareHouse?: string | null,
      rackNo?: string | null,
      rowNo?: string | null,
      binNo?: string | null,
      note?: string | null,
    } | null,
    shipplingInfo?:  {
      __typename: "shippingDetails",
      weightValue?: string | null,
      weightUnit?: string | null,
      lengthValue?: string | null,
      lengthUnit?: string | null,
      heightValue?: string | null,
      heightUnit?: string | null,
      widthValue?: string | null,
      widthUnit?: string | null,
    } | null,
    partialReturn?: boolean | null,
    type?: VariantType | null,
    isDeleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateProductDetailsSubscriptionVariables = {
  filter?: ModelSubscriptionProductDetailsFilterInput | null,
  owner?: string | null,
};

export type OnUpdateProductDetailsSubscription = {
  onUpdateProductDetails?:  {
    __typename: "ProductDetails",
    id: string,
    pk: string,
    productID?: string | null,
    attrName?: string | null,
    attrNameID?: string | null,
    attrValue?: string | null,
    attrValueID?: string | null,
    featuredImage?:  {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null,
    model?: string | null,
    manufacturePartCode?: string | null,
    sku?: string | null,
    stockStatus?: stockStatus | null,
    stockQuantity?: number | null,
    reservedStock?: number | null,
    stockUnit?: string | null,
    itemsPerUnit?: number | null,
    regularPrice?: number | null,
    salePrice?: number | null,
    dataSheet?: string | null,
    submittal?: string | null,
    catalog?: string | null,
    iom?: string | null,
    storageInfo?:  {
      __typename: "storageInfoDetail",
      wareHouseId?: string | null,
      wareHouse?: string | null,
      rackNo?: string | null,
      rowNo?: string | null,
      binNo?: string | null,
      note?: string | null,
    } | null,
    shipplingInfo?:  {
      __typename: "shippingDetails",
      weightValue?: string | null,
      weightUnit?: string | null,
      lengthValue?: string | null,
      lengthUnit?: string | null,
      heightValue?: string | null,
      heightUnit?: string | null,
      widthValue?: string | null,
      widthUnit?: string | null,
    } | null,
    partialReturn?: boolean | null,
    type?: VariantType | null,
    isDeleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteProductDetailsSubscriptionVariables = {
  filter?: ModelSubscriptionProductDetailsFilterInput | null,
  owner?: string | null,
};

export type OnDeleteProductDetailsSubscription = {
  onDeleteProductDetails?:  {
    __typename: "ProductDetails",
    id: string,
    pk: string,
    productID?: string | null,
    attrName?: string | null,
    attrNameID?: string | null,
    attrValue?: string | null,
    attrValueID?: string | null,
    featuredImage?:  {
      __typename: "imageUpload",
      alterText?: string | null,
      title?: string | null,
      url?: string | null,
      description?: string | null,
      type?: ImageType | null,
    } | null,
    model?: string | null,
    manufacturePartCode?: string | null,
    sku?: string | null,
    stockStatus?: stockStatus | null,
    stockQuantity?: number | null,
    reservedStock?: number | null,
    stockUnit?: string | null,
    itemsPerUnit?: number | null,
    regularPrice?: number | null,
    salePrice?: number | null,
    dataSheet?: string | null,
    submittal?: string | null,
    catalog?: string | null,
    iom?: string | null,
    storageInfo?:  {
      __typename: "storageInfoDetail",
      wareHouseId?: string | null,
      wareHouse?: string | null,
      rackNo?: string | null,
      rowNo?: string | null,
      binNo?: string | null,
      note?: string | null,
    } | null,
    shipplingInfo?:  {
      __typename: "shippingDetails",
      weightValue?: string | null,
      weightUnit?: string | null,
      lengthValue?: string | null,
      lengthUnit?: string | null,
      heightValue?: string | null,
      heightUnit?: string | null,
      widthValue?: string | null,
      widthUnit?: string | null,
    } | null,
    partialReturn?: boolean | null,
    type?: VariantType | null,
    isDeleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateOrderSubscriptionVariables = {
  filter?: ModelSubscriptionOrderFilterInput | null,
  owner?: string | null,
};

export type OnCreateOrderSubscription = {
  onCreateOrder?:  {
    __typename: "Order",
    pk: string,
    id: string,
    userId: string,
    createdAt: string,
    userName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    orderType?: string | null,
    totalProducts?: number | null,
    orderSummary?:  {
      __typename: "OrderSummary",
      cartTotal?: number | null,
      productDiscount?: string | null,
      deliveryFee?: number | null,
      tax?: number | null,
    } | null,
    totalAmount?: number | null,
    paymentDetails?:  {
      __typename: "PaymentDetails",
      paymentMode?: string | null,
      paymentMethod?: PaymentMethod | null,
      cardType?: string | null,
      cardHolderName?: string | null,
      cardNumber?: string | null,
      CardExpiryDate?: string | null,
      UPI?: boolean | null,
      paymentDate?: string | null,
      referenceNumber?: string | null,
      chequeNumber?: string | null,
      bankName?: string | null,
      chequeDate?: string | null,
      receivedAmount?: number | null,
      remaningAmount?: number | null,
      chequeStatus?: ChequeStatus | null,
      partialPaymentDetails?:  Array< {
        __typename: "CashOnDeliveryCollection",
        chequeNumber?: string | null,
        bankName?: string | null,
        chequeDate?: string | null,
        receivedAmount?: number | null,
        chequeStatus?: ChequeStatus | null,
        paymentDate?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null > | null,
    } | null,
    paymentStatus?: PaymentStatus | null,
    status?: OrderStatus | null,
    wishListId?: string | null,
    couponDetails?:  {
      __typename: "CouponDetails",
      couponCode?: string | null,
      amount?: number | null,
      discount?:  {
        __typename: "discountType",
        type?: string | null,
        value?: string | null,
      } | null,
      advancedSetting?:  {
        __typename: "AdvancedSettings",
        appliedTo?: string | null,
        specificType?:  Array< {
          __typename: "SpecificType",
          name?: string | null,
          id?: string | null,
        } | null > | null,
      } | null,
    } | null,
    discountByCoupon?: number | null,
    shippingUserDetails?:  {
      __typename: "UserDetails",
      userName?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
    } | null,
    shippingAddress?:  {
      __typename: "Address",
      addressLine1?: string | null,
      addressLine2?: string | null,
      street?: string | null,
      landmark?: string | null,
      state?: string | null,
      city?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    deliveryInfo?:  {
      __typename: "DeliveryInfo",
      deliveryPartner?: string | null,
      deliveryType?: string | null,
      productWeight?: string | null,
    } | null,
    billingUserDetails?:  {
      __typename: "UserDetails",
      userName?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
    } | null,
    billingAddress?:  {
      __typename: "Address",
      addressLine1?: string | null,
      addressLine2?: string | null,
      street?: string | null,
      landmark?: string | null,
      state?: string | null,
      city?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    deliveryDate?: string | null,
    invoiceNumber?: string | null,
    invoiceURL?: string | null,
    orderPdf?: string | null,
    searchKey?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateOrderSubscriptionVariables = {
  filter?: ModelSubscriptionOrderFilterInput | null,
  owner?: string | null,
};

export type OnUpdateOrderSubscription = {
  onUpdateOrder?:  {
    __typename: "Order",
    pk: string,
    id: string,
    userId: string,
    createdAt: string,
    userName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    orderType?: string | null,
    totalProducts?: number | null,
    orderSummary?:  {
      __typename: "OrderSummary",
      cartTotal?: number | null,
      productDiscount?: string | null,
      deliveryFee?: number | null,
      tax?: number | null,
    } | null,
    totalAmount?: number | null,
    paymentDetails?:  {
      __typename: "PaymentDetails",
      paymentMode?: string | null,
      paymentMethod?: PaymentMethod | null,
      cardType?: string | null,
      cardHolderName?: string | null,
      cardNumber?: string | null,
      CardExpiryDate?: string | null,
      UPI?: boolean | null,
      paymentDate?: string | null,
      referenceNumber?: string | null,
      chequeNumber?: string | null,
      bankName?: string | null,
      chequeDate?: string | null,
      receivedAmount?: number | null,
      remaningAmount?: number | null,
      chequeStatus?: ChequeStatus | null,
      partialPaymentDetails?:  Array< {
        __typename: "CashOnDeliveryCollection",
        chequeNumber?: string | null,
        bankName?: string | null,
        chequeDate?: string | null,
        receivedAmount?: number | null,
        chequeStatus?: ChequeStatus | null,
        paymentDate?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null > | null,
    } | null,
    paymentStatus?: PaymentStatus | null,
    status?: OrderStatus | null,
    wishListId?: string | null,
    couponDetails?:  {
      __typename: "CouponDetails",
      couponCode?: string | null,
      amount?: number | null,
      discount?:  {
        __typename: "discountType",
        type?: string | null,
        value?: string | null,
      } | null,
      advancedSetting?:  {
        __typename: "AdvancedSettings",
        appliedTo?: string | null,
        specificType?:  Array< {
          __typename: "SpecificType",
          name?: string | null,
          id?: string | null,
        } | null > | null,
      } | null,
    } | null,
    discountByCoupon?: number | null,
    shippingUserDetails?:  {
      __typename: "UserDetails",
      userName?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
    } | null,
    shippingAddress?:  {
      __typename: "Address",
      addressLine1?: string | null,
      addressLine2?: string | null,
      street?: string | null,
      landmark?: string | null,
      state?: string | null,
      city?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    deliveryInfo?:  {
      __typename: "DeliveryInfo",
      deliveryPartner?: string | null,
      deliveryType?: string | null,
      productWeight?: string | null,
    } | null,
    billingUserDetails?:  {
      __typename: "UserDetails",
      userName?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
    } | null,
    billingAddress?:  {
      __typename: "Address",
      addressLine1?: string | null,
      addressLine2?: string | null,
      street?: string | null,
      landmark?: string | null,
      state?: string | null,
      city?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    deliveryDate?: string | null,
    invoiceNumber?: string | null,
    invoiceURL?: string | null,
    orderPdf?: string | null,
    searchKey?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteOrderSubscriptionVariables = {
  filter?: ModelSubscriptionOrderFilterInput | null,
  owner?: string | null,
};

export type OnDeleteOrderSubscription = {
  onDeleteOrder?:  {
    __typename: "Order",
    pk: string,
    id: string,
    userId: string,
    createdAt: string,
    userName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    orderType?: string | null,
    totalProducts?: number | null,
    orderSummary?:  {
      __typename: "OrderSummary",
      cartTotal?: number | null,
      productDiscount?: string | null,
      deliveryFee?: number | null,
      tax?: number | null,
    } | null,
    totalAmount?: number | null,
    paymentDetails?:  {
      __typename: "PaymentDetails",
      paymentMode?: string | null,
      paymentMethod?: PaymentMethod | null,
      cardType?: string | null,
      cardHolderName?: string | null,
      cardNumber?: string | null,
      CardExpiryDate?: string | null,
      UPI?: boolean | null,
      paymentDate?: string | null,
      referenceNumber?: string | null,
      chequeNumber?: string | null,
      bankName?: string | null,
      chequeDate?: string | null,
      receivedAmount?: number | null,
      remaningAmount?: number | null,
      chequeStatus?: ChequeStatus | null,
      partialPaymentDetails?:  Array< {
        __typename: "CashOnDeliveryCollection",
        chequeNumber?: string | null,
        bankName?: string | null,
        chequeDate?: string | null,
        receivedAmount?: number | null,
        chequeStatus?: ChequeStatus | null,
        paymentDate?: string | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null > | null,
    } | null,
    paymentStatus?: PaymentStatus | null,
    status?: OrderStatus | null,
    wishListId?: string | null,
    couponDetails?:  {
      __typename: "CouponDetails",
      couponCode?: string | null,
      amount?: number | null,
      discount?:  {
        __typename: "discountType",
        type?: string | null,
        value?: string | null,
      } | null,
      advancedSetting?:  {
        __typename: "AdvancedSettings",
        appliedTo?: string | null,
        specificType?:  Array< {
          __typename: "SpecificType",
          name?: string | null,
          id?: string | null,
        } | null > | null,
      } | null,
    } | null,
    discountByCoupon?: number | null,
    shippingUserDetails?:  {
      __typename: "UserDetails",
      userName?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
    } | null,
    shippingAddress?:  {
      __typename: "Address",
      addressLine1?: string | null,
      addressLine2?: string | null,
      street?: string | null,
      landmark?: string | null,
      state?: string | null,
      city?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    deliveryInfo?:  {
      __typename: "DeliveryInfo",
      deliveryPartner?: string | null,
      deliveryType?: string | null,
      productWeight?: string | null,
    } | null,
    billingUserDetails?:  {
      __typename: "UserDetails",
      userName?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
    } | null,
    billingAddress?:  {
      __typename: "Address",
      addressLine1?: string | null,
      addressLine2?: string | null,
      street?: string | null,
      landmark?: string | null,
      state?: string | null,
      city?: string | null,
      postalCode?: string | null,
      country?: string | null,
    } | null,
    deliveryDate?: string | null,
    invoiceNumber?: string | null,
    invoiceURL?: string | null,
    orderPdf?: string | null,
    searchKey?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateOrderItemSubscriptionVariables = {
  filter?: ModelSubscriptionOrderItemFilterInput | null,
  owner?: string | null,
};

export type OnCreateOrderItemSubscription = {
  onCreateOrderItem?:  {
    __typename: "OrderItem",
    pk: string,
    id: string,
    productId?: string | null,
    mainCategoryId?: string | null,
    mainCategory?: string | null,
    subCategoryId?: string | null,
    subCategory?: string | null,
    userId: string,
    wareHouseId?: string | null,
    attrValueID?: string | null,
    wareHouseName?: string | null,
    userName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    createdAt: string,
    quantity?: number | null,
    stockUnit?: string | null,
    itemsPerUnit?: number | null,
    taxCode?: string | null,
    taxAmount?: number | null,
    taxDetail?:  Array< {
      __typename: "taxDetail",
      taxCharge?: number | null,
      taxID?: string | null,
      taxName?: string | null,
    } | null > | null,
    regularPrice?: number | null,
    totalPrice?: number | null,
    itemDetail?:  {
      __typename: "ItemDetail",
      productName?: string | null,
      price?: number | null,
      image?: string | null,
      brand?: string | null,
      model?: string | null,
      tax?: number | null,
      currentStock?: string | null,
      sku?: string | null,
      qrcode?: string | null,
    } | null,
    sku?: string | null,
    attrName?: string | null,
    attrValue?: string | null,
    deliveryStatus?: ProductDeliveryStatus | null,
    deliveryBy?: string | null,
    deliveryTracking?:  Array< {
      __typename: "DeliveryTracking",
      status?: ProductDeliveryStatus | null,
      date?: string | null,
      trackingBy?: string | null,
      phoneNumber?: string | null,
      remarks?: string | null,
      receipt?: string | null,
    } | null > | null,
    deliveryPartner?: string | null,
    outForDeliveryDate?: string | null,
    outForDeliveryTime?: string | null,
    expectedDeliveryDate?: string | null,
    trackingID?: string | null,
    trackingUrl?: string | null,
    deliveryPersonInfo?:  {
      __typename: "DeliveryPersonInfo",
      name?: string | null,
      contactNumber?: string | null,
      vehiclenumber?: string | null,
      notes?: string | null,
    } | null,
    orderedOn?: string | null,
    returnRefundActivity?:  {
      __typename: "ReturnRefundActivity",
      returnReference?: string | null,
      reason?: string | null,
      comment?: string | null,
      quantity?: string | null,
      subTotal?: number | null,
      total?: number | null,
      attachments?: Array< string | null > | null,
      paymentMode?: string | null,
      paymentMethod?: PaymentMethod | null,
      returnType?: ReturnType | null,
      returnAddress?:  {
        __typename: "Address",
        addressLine1?: string | null,
        addressLine2?: string | null,
        street?: string | null,
        landmark?: string | null,
        state?: string | null,
        city?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      pickUpDetails?:  {
        __typename: "PickUpDetails",
        pickUpAddress?:  {
          __typename: "Address",
          addressLine1?: string | null,
          addressLine2?: string | null,
          street?: string | null,
          landmark?: string | null,
          state?: string | null,
          city?: string | null,
          postalCode?: string | null,
          country?: string | null,
        } | null,
        pickUpBy?: string | null,
        deliveryPartner?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        expectedPickUpDate?: string | null,
        time?: string | null,
      } | null,
      receivedInfo?:  {
        __typename: "ReceivedInfo",
        receivedOn?: string | null,
        receivedAs?: string | null,
        images?: Array< string | null > | null,
      } | null,
      updateInStock?: boolean | null,
      updateInStockQty?: string | null,
      refundInfo?:  {
        __typename: "RefundInfo",
        date?: string | null,
        paymentMethod?: PaymentMethod | null,
        refundAmount?: number | null,
        refundInfo?: string | null,
        proofImage?: string | null,
        proofImageUrl?: string | null,
        rejectedReason?: string | null,
      } | null,
      status?: ReturnActivityStatus | null,
      returnStatusUpdate?:  Array< {
        __typename: "ReturnUpdates",
        returnStatus?: ReturnActivityStatus | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null > | null,
      rejectReason?: string | null,
      createdAt?: string | null,
    } | null,
    eta?: string | null,
    paymentStatus?: PaymentStatus | null,
    billOfLading?: string | null,
    packingList?: string | null,
    searchKey?: string | null,
    shippingInfo?:  {
      __typename: "shippingDetails",
      weightValue?: string | null,
      weightUnit?: string | null,
      lengthValue?: string | null,
      lengthUnit?: string | null,
      heightValue?: string | null,
      heightUnit?: string | null,
      widthValue?: string | null,
      widthUnit?: string | null,
    } | null,
    returnAvailable?: boolean | null,
    disputeResolved?: boolean | null,
    brandId?: string | null,
    returnValue?: number | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateOrderItemSubscriptionVariables = {
  filter?: ModelSubscriptionOrderItemFilterInput | null,
  owner?: string | null,
};

export type OnUpdateOrderItemSubscription = {
  onUpdateOrderItem?:  {
    __typename: "OrderItem",
    pk: string,
    id: string,
    productId?: string | null,
    mainCategoryId?: string | null,
    mainCategory?: string | null,
    subCategoryId?: string | null,
    subCategory?: string | null,
    userId: string,
    wareHouseId?: string | null,
    attrValueID?: string | null,
    wareHouseName?: string | null,
    userName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    createdAt: string,
    quantity?: number | null,
    stockUnit?: string | null,
    itemsPerUnit?: number | null,
    taxCode?: string | null,
    taxAmount?: number | null,
    taxDetail?:  Array< {
      __typename: "taxDetail",
      taxCharge?: number | null,
      taxID?: string | null,
      taxName?: string | null,
    } | null > | null,
    regularPrice?: number | null,
    totalPrice?: number | null,
    itemDetail?:  {
      __typename: "ItemDetail",
      productName?: string | null,
      price?: number | null,
      image?: string | null,
      brand?: string | null,
      model?: string | null,
      tax?: number | null,
      currentStock?: string | null,
      sku?: string | null,
      qrcode?: string | null,
    } | null,
    sku?: string | null,
    attrName?: string | null,
    attrValue?: string | null,
    deliveryStatus?: ProductDeliveryStatus | null,
    deliveryBy?: string | null,
    deliveryTracking?:  Array< {
      __typename: "DeliveryTracking",
      status?: ProductDeliveryStatus | null,
      date?: string | null,
      trackingBy?: string | null,
      phoneNumber?: string | null,
      remarks?: string | null,
      receipt?: string | null,
    } | null > | null,
    deliveryPartner?: string | null,
    outForDeliveryDate?: string | null,
    outForDeliveryTime?: string | null,
    expectedDeliveryDate?: string | null,
    trackingID?: string | null,
    trackingUrl?: string | null,
    deliveryPersonInfo?:  {
      __typename: "DeliveryPersonInfo",
      name?: string | null,
      contactNumber?: string | null,
      vehiclenumber?: string | null,
      notes?: string | null,
    } | null,
    orderedOn?: string | null,
    returnRefundActivity?:  {
      __typename: "ReturnRefundActivity",
      returnReference?: string | null,
      reason?: string | null,
      comment?: string | null,
      quantity?: string | null,
      subTotal?: number | null,
      total?: number | null,
      attachments?: Array< string | null > | null,
      paymentMode?: string | null,
      paymentMethod?: PaymentMethod | null,
      returnType?: ReturnType | null,
      returnAddress?:  {
        __typename: "Address",
        addressLine1?: string | null,
        addressLine2?: string | null,
        street?: string | null,
        landmark?: string | null,
        state?: string | null,
        city?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      pickUpDetails?:  {
        __typename: "PickUpDetails",
        pickUpAddress?:  {
          __typename: "Address",
          addressLine1?: string | null,
          addressLine2?: string | null,
          street?: string | null,
          landmark?: string | null,
          state?: string | null,
          city?: string | null,
          postalCode?: string | null,
          country?: string | null,
        } | null,
        pickUpBy?: string | null,
        deliveryPartner?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        expectedPickUpDate?: string | null,
        time?: string | null,
      } | null,
      receivedInfo?:  {
        __typename: "ReceivedInfo",
        receivedOn?: string | null,
        receivedAs?: string | null,
        images?: Array< string | null > | null,
      } | null,
      updateInStock?: boolean | null,
      updateInStockQty?: string | null,
      refundInfo?:  {
        __typename: "RefundInfo",
        date?: string | null,
        paymentMethod?: PaymentMethod | null,
        refundAmount?: number | null,
        refundInfo?: string | null,
        proofImage?: string | null,
        proofImageUrl?: string | null,
        rejectedReason?: string | null,
      } | null,
      status?: ReturnActivityStatus | null,
      returnStatusUpdate?:  Array< {
        __typename: "ReturnUpdates",
        returnStatus?: ReturnActivityStatus | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null > | null,
      rejectReason?: string | null,
      createdAt?: string | null,
    } | null,
    eta?: string | null,
    paymentStatus?: PaymentStatus | null,
    billOfLading?: string | null,
    packingList?: string | null,
    searchKey?: string | null,
    shippingInfo?:  {
      __typename: "shippingDetails",
      weightValue?: string | null,
      weightUnit?: string | null,
      lengthValue?: string | null,
      lengthUnit?: string | null,
      heightValue?: string | null,
      heightUnit?: string | null,
      widthValue?: string | null,
      widthUnit?: string | null,
    } | null,
    returnAvailable?: boolean | null,
    disputeResolved?: boolean | null,
    brandId?: string | null,
    returnValue?: number | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteOrderItemSubscriptionVariables = {
  filter?: ModelSubscriptionOrderItemFilterInput | null,
  owner?: string | null,
};

export type OnDeleteOrderItemSubscription = {
  onDeleteOrderItem?:  {
    __typename: "OrderItem",
    pk: string,
    id: string,
    productId?: string | null,
    mainCategoryId?: string | null,
    mainCategory?: string | null,
    subCategoryId?: string | null,
    subCategory?: string | null,
    userId: string,
    wareHouseId?: string | null,
    attrValueID?: string | null,
    wareHouseName?: string | null,
    userName?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    createdAt: string,
    quantity?: number | null,
    stockUnit?: string | null,
    itemsPerUnit?: number | null,
    taxCode?: string | null,
    taxAmount?: number | null,
    taxDetail?:  Array< {
      __typename: "taxDetail",
      taxCharge?: number | null,
      taxID?: string | null,
      taxName?: string | null,
    } | null > | null,
    regularPrice?: number | null,
    totalPrice?: number | null,
    itemDetail?:  {
      __typename: "ItemDetail",
      productName?: string | null,
      price?: number | null,
      image?: string | null,
      brand?: string | null,
      model?: string | null,
      tax?: number | null,
      currentStock?: string | null,
      sku?: string | null,
      qrcode?: string | null,
    } | null,
    sku?: string | null,
    attrName?: string | null,
    attrValue?: string | null,
    deliveryStatus?: ProductDeliveryStatus | null,
    deliveryBy?: string | null,
    deliveryTracking?:  Array< {
      __typename: "DeliveryTracking",
      status?: ProductDeliveryStatus | null,
      date?: string | null,
      trackingBy?: string | null,
      phoneNumber?: string | null,
      remarks?: string | null,
      receipt?: string | null,
    } | null > | null,
    deliveryPartner?: string | null,
    outForDeliveryDate?: string | null,
    outForDeliveryTime?: string | null,
    expectedDeliveryDate?: string | null,
    trackingID?: string | null,
    trackingUrl?: string | null,
    deliveryPersonInfo?:  {
      __typename: "DeliveryPersonInfo",
      name?: string | null,
      contactNumber?: string | null,
      vehiclenumber?: string | null,
      notes?: string | null,
    } | null,
    orderedOn?: string | null,
    returnRefundActivity?:  {
      __typename: "ReturnRefundActivity",
      returnReference?: string | null,
      reason?: string | null,
      comment?: string | null,
      quantity?: string | null,
      subTotal?: number | null,
      total?: number | null,
      attachments?: Array< string | null > | null,
      paymentMode?: string | null,
      paymentMethod?: PaymentMethod | null,
      returnType?: ReturnType | null,
      returnAddress?:  {
        __typename: "Address",
        addressLine1?: string | null,
        addressLine2?: string | null,
        street?: string | null,
        landmark?: string | null,
        state?: string | null,
        city?: string | null,
        postalCode?: string | null,
        country?: string | null,
      } | null,
      pickUpDetails?:  {
        __typename: "PickUpDetails",
        pickUpAddress?:  {
          __typename: "Address",
          addressLine1?: string | null,
          addressLine2?: string | null,
          street?: string | null,
          landmark?: string | null,
          state?: string | null,
          city?: string | null,
          postalCode?: string | null,
          country?: string | null,
        } | null,
        pickUpBy?: string | null,
        deliveryPartner?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        expectedPickUpDate?: string | null,
        time?: string | null,
      } | null,
      receivedInfo?:  {
        __typename: "ReceivedInfo",
        receivedOn?: string | null,
        receivedAs?: string | null,
        images?: Array< string | null > | null,
      } | null,
      updateInStock?: boolean | null,
      updateInStockQty?: string | null,
      refundInfo?:  {
        __typename: "RefundInfo",
        date?: string | null,
        paymentMethod?: PaymentMethod | null,
        refundAmount?: number | null,
        refundInfo?: string | null,
        proofImage?: string | null,
        proofImageUrl?: string | null,
        rejectedReason?: string | null,
      } | null,
      status?: ReturnActivityStatus | null,
      returnStatusUpdate?:  Array< {
        __typename: "ReturnUpdates",
        returnStatus?: ReturnActivityStatus | null,
        updatedAt?: string | null,
        updatedBy?: string | null,
      } | null > | null,
      rejectReason?: string | null,
      createdAt?: string | null,
    } | null,
    eta?: string | null,
    paymentStatus?: PaymentStatus | null,
    billOfLading?: string | null,
    packingList?: string | null,
    searchKey?: string | null,
    shippingInfo?:  {
      __typename: "shippingDetails",
      weightValue?: string | null,
      weightUnit?: string | null,
      lengthValue?: string | null,
      lengthUnit?: string | null,
      heightValue?: string | null,
      heightUnit?: string | null,
      widthValue?: string | null,
      widthUnit?: string | null,
    } | null,
    returnAvailable?: boolean | null,
    disputeResolved?: boolean | null,
    brandId?: string | null,
    returnValue?: number | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateReportMasterSubscriptionVariables = {
  filter?: ModelSubscriptionReportMasterFilterInput | null,
  owner?: string | null,
};

export type OnCreateReportMasterSubscription = {
  onCreateReportMaster?:  {
    __typename: "ReportMaster",
    pk: string,
    id: string,
    reportName?: string | null,
    description?: string | null,
    sequenceNumber?: number | null,
    searchKey?: string | null,
    status?: Status | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateReportMasterSubscriptionVariables = {
  filter?: ModelSubscriptionReportMasterFilterInput | null,
  owner?: string | null,
};

export type OnUpdateReportMasterSubscription = {
  onUpdateReportMaster?:  {
    __typename: "ReportMaster",
    pk: string,
    id: string,
    reportName?: string | null,
    description?: string | null,
    sequenceNumber?: number | null,
    searchKey?: string | null,
    status?: Status | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteReportMasterSubscriptionVariables = {
  filter?: ModelSubscriptionReportMasterFilterInput | null,
  owner?: string | null,
};

export type OnDeleteReportMasterSubscription = {
  onDeleteReportMaster?:  {
    __typename: "ReportMaster",
    pk: string,
    id: string,
    reportName?: string | null,
    description?: string | null,
    sequenceNumber?: number | null,
    searchKey?: string | null,
    status?: Status | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};
