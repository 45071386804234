export const Messages = {
    STATUS_UPDATED: 'Status updated successfully',
    PAYMENT_UPDATED: 'Payment updated successfully',
    SHIPPING_DETAILS_SAVED: 'Shipping details saved successfully',
    GENERAL_QUERIES_UPDATED: 'Query resolution saved successfully',
    GENERAL_QUERIES_DELETED: 'General query deleted successfully',
    GENERAL_QUERIES_CANCELLED: 'General query cancelled successfully',
    PRODUCT_REVIEWS_UPDATED: 'Status updated successfully',
    PRODUCT_REVIEWS_DELETED: 'Product review deleted successfully',
    USER_ADDED: 'User added successfully',
    USER_UPDATED: 'User details updated successfully',
    USER_DELETED: 'User deleted successfully',
    USER_EXISTS: 'User already exists',
    CONTENT_CREATED: 'Content created successfully',
    CONTENT_EDIED: 'Content updated successfully',
    CONTENT_ADDED: 'Content added successfully',
    CONTENT_SAVED: 'Content saved successfully',
    CONTENT_DELETED: 'Content deleted successfully',
    FAQ_CREATED: 'FAQ created successfully',
    FAQ_EDITED: 'FAQ updated successfully',
    FAQ_ADDED: 'FAQ section added successfully',
    FAQ_SAVED: 'FAQ section saved successfully',
    FAQ_DELETED: 'FAQ section deleted successfully',
    TAX_EDITED: 'Tax details updated successfully',
    TAX_ADDED: 'Tax details added successfully',
    TAX_DELETED: 'Tax details deleted successfully',
    VARIANT_EDITED: 'Variant details updated successfully',
    VARIANT_ADDED: 'Variant details added successfully',
    VARIANT_DELETED: 'Variant details deleted successfully',
    UNIT_EDITED: 'Unit details updated successfully',
    UNIT_ADDED: 'Unit details added successfully',
    UNIT_DELETED: 'Unit details deleted successfully',
    SEQUENCE_NUMBER_EXISTS: 'Sequence number already exists',
    INVALID_SEQUENCE_NUMBER: 'Invalid sequence number!',
    UNIT_EXISTS: 'Unit already exists',
    INVALID_STATUS: 'Invalid status',
    SOMETHING_WENT_WRONG: 'Something went wrong',
    PLEASE_FILL_REQUIRED_FIELDS: 'Please fill all the required details',
    INVALID_REFUND_AMOUNT: 'Invalid refund amount!',
    INVALID_DISCOUNT_TYPE: 'Invalid discount type!',
    LASTNAME_SHOULD_HAVE_2_LETTERS: 'Last name should have minimum 2 letters',
    PASSWORD_MISMATCH: 'Password mismatch',
    PLEASE_ENTER_OTP: 'Please enter the OTP',
    INVALID_OTP: 'Invalid OTP',
    SHIPPING_ADDED: 'Shipping details added successfully',
    SHIPPING_UPDATED: 'Shipping details updated successfully',
    SHIPPING_DELETED: 'Shipping details deleted successfully',
    PASSWORD_CHANGED_SUCCESS: 'Password changed successfully',
    CAROUSELIMAGE_UPDATED: 'Carousel image updated successfully',
    EMAIL_DELETED_SUCCESS: 'Email deleted successfully',
    UNABLE_TO_CHANGE_VARIANT: 'Unable to change the variant as open orders exist.',
    UNABLE_TO_CHANGE_VARIANT_VALUE: 'Unable to change variant value as open orders exist.',
    UNABLE_TO_REMOVE_VARIANT: 'Unable to remove the variant as open orders exist.',
    SESSION_EXPIRED: 'Session Expired.',
    INVALID_FILE_TYPE: 'Invalid file type. Please upload an image.',
    INVALID_IMAGE_SIZE: 'Invalid image size',
    VARIANT_MISSING: 'Variant values are missing',
    PLEASE_FILL_REQUIRED_FIELDS_VARIANT: 'Please fill all the required details of multiple variant product',
    WAREHOUSE_MISSING: 'Please choose a warehouse',
    ACCESS_DENIED: 'Access denied!',
    CATEGORY_ALREADY_EXISTS: 'Category name already exists!',
    BRAND_ALREADY_EXISTS: 'Brand already exists!',
    INSUFFICIENT_STOCK: 'Insufficient stock!',
    PARTIAL_DATA_ERROR: 'Server error during login. please contact support.',
    CHOOSE_ANYONE: 'Please choose SKU or product name',
    MISSING_LEADTIME: 'Please enter leadtime',
    MISSING_SCHEDULE_DATE: 'Please enter schedule date',
    MISSING_PAYMENT_METHOD: 'Please choose a payment method',
    INVALID_STOCK_STATUS: 'Invalid stock status',
    OTP_EXPIRED: 'OTP has expired.',
    MISSING_SINGLE_VARIANT_DETAILS: 'Please fill all the required details of single variant product',
    SHIPPING_CHARGE_ALREADY_ADDED: 'Shipping charge for this location already available. Please check and do the changes.',
    TAXTYPE_ALREADY_EXISTS: 'Tax type already exists!',
    NAME_ALREADY_EXISTS: 'Warehouse name already exists!',
    VARIENT_NAME_ALREADY_EXIST: 'Variant name already exists!',
    DELIVERY_PARTNER_UPDATED: 'Delivery partner details updated successfully!',
    VARIANT_CANNOT_BE_DELETED: 'This variant cannot be deleted as there are products associated with it.',
    UNIT_CANNOT_BE_DELETED: 'This unit cannot be deleted as there are products associated with it.',
    WAREHOUSE_CANNOT_BE_DELETED: 'This warehouse cannot be deleted as there are products associated with it.',
    TAX_CANNOT_BE_DELETED: 'This tax code cannot be deleted as there are products associated with it.',
    EMAIL_ID_NOT_FOUND: 'Email-id not found',
    PASSWORD_SHOULD_NOT_BE_SAME: 'Current password and new password should not be same!',
    PASSWORD_SHOULD_BE_SAME: 'New password and confirm password should be same!',
    INVALID_CURRENT_PASSWORD: 'Invalid current password',
    TRY_AGAIN: 'Attempt limit exceeded, please try again after some time.',
    TYPE_ALREADY_EXISTS: 'Type already exists!',
    THEME_ADDED: 'Theme added successfully!',
    THEME_UPDATED: 'Theme updated successfully!',
    INVALID_EMAIL_INPUTS: 'Invalid email id!',
    NOTIFICATION_TYPE_EXISTS: 'Type already exists!',
    EMAIL_ALREADY_EXISTS: 'Email id already exists!'
}