import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import * as query from 'graphql/queries';
import { CommonService } from '@services/common/common.service';
import _ from 'underscore';
import { Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import { Status } from 'API';
import { ReportsUrl, UserRolesList } from '@/common-list';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrl: './report-list.component.scss'
})
export class ReportListComponent {
  userRolesList = UserRolesList
  reports: any = []
  reportsList: any = []
  access: any = {}
  modulesList: any = []
  roleManagementRes: any = []
  searchText: any = ''
  filterReport: any = {}
  searchKey: any

  constructor(private router: Router, private common: CommonService, private awsApi: ApiService, private messageService: MessageService) { }

  async listModuleMasters() {
    return await this.awsApi.client.graphql({
      query: query.listModuleMasters
    })
  }

  ngOnInit(): void {
    this.getAllReports()
  }

  async getAllReports() {
    this.awsApi.loading.next(true);
    this.filterReport['status'] = { eq: Status.ACTIVE }
    let filterRoleManagement = {
      roleName:
        { eq: this.userRolesList.find((item) => item.value == localStorage.getItem('role')).text }
    }

    Promise.all([this.common.getReportMaster(this.filterReport), this.common.listRoleManagement(filterRoleManagement, ['pk', 'id', 'module {', 'moduleId', 'menu', 'view', 'add', 'edit', 'delete', '}'])]).then((response) => {
      this.reports = response[0].data.listReportMasters.items.sort((a: any, b: any) => a.sequenceNumber - b.sequenceNumber);
      this.roleManagementRes = response[1]['data'].listRoleManagements.items[0]
      this.findReportsToShow()
      this.awsApi.loading.next(false);
    })
  }

  findReportsToShow() {
    this.reportsList = []
    for (const item of this.reports) {
      if (this.roleManagementRes.module.find((modItem) => modItem.moduleId == item.id && modItem.view)) {
        this.reportsList.push(item)
      }
    }
  }

  search(searchText) {
    this.searchText = searchText
    if (this.searchText != '') {
      this.searchKey = this.searchText.toLowerCase()
      this.searchKey = this.searchKey.replace(/\s{2,}/g, ' ');
      this.searchKey = (this.searchKey.trim()).replace(/\s+/g, '');

      this.filterReport['searchKey'] = { contains: this.searchKey }
      this.getAllReports()
    } else {
      delete this.filterReport['searchKey']
      delete this.searchKey
      this.getAllReports()
    }
  }

  redirectToReportView(selectedReport) {
    this.router.navigate([ReportsUrl.find((item) => item.sequenceNumber == selectedReport.sequenceNumber).redirectTo], { queryParams: { view: true } });
  }
}
