<section id="categoryListSec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 my-auto">
                <div class="card m-2">
                    <div class="card-body">
                        <!-- category-list-heading-row start -->
                        <div class="row category-list-heading-row">
                            <div class="col-xxl-5 col-xl-5 col-lg-4 col-md-4 col-sm-12 col-xs-12 my-auto">
                                <div class="text-center text-md-left mt-2 mt-md-0">
                                    <h2>Category</h2>
                                    <p class="mb-2 mb-md-0">{{categoryList?.length}} Results Found</p>
                                </div>
                            </div>
                            <div class="col-xxl-7 col-xl-7 col-lg-8 col-md-8 col-sm-12 col-xs-12 my-auto">
                                <div
                                    class="has-search-d-flex justify-content-center justify-content-md-end align-items-start align-items-md-center flex-nowrap flex-md-wrap">
                                    <app-common-search (onEnter)="search($event)"
                                        class="mb-2 mb-md-0"></app-common-search>
                                    <div *ngIf="!filterApplied">
                                        <button type="button" class="btn filter-btn me-3 d-none d-md-block" (click)="
                                                showFilterModalDialog('right')" *ngIf="categoryList?.length>10">
                                            <span class="material-symbols-outlined me-1">
                                                filter_alt
                                            </span>
                                            Filter
                                        </button>
                                        <button type="button" class="btn filter-btn me-3 d-block d-md-none"
                                            *ngIf="categoryList?.length>10" (click)="
                                                                                    showFilterModalDialog('right')">
                                            <span class="material-symbols-outlined">
                                                filter_alt
                                            </span>
                                        </button>
                                    </div>
                                    <div *ngIf="filterApplied">
                                        <button type="button" class="btn after-filter-btn me-3 d-none d-md-block"
                                            (click)="showFilterModalDialog('right')">
                                            <i class="fas fa-filter"></i>
                                            Filter
                                        </button>
                                        <button type="button" class="btn after-filter-btn me-3 d-block d-md-none"
                                            (click)="showFilterModalDialog('right')">
                                            <i class="fas fa-filter"></i>
                                        </button>
                                    </div>
                                    <div>
                                        <button [disabled]="!access['add']" type="button"
                                            class="btn add-btn d-none d-md-block" (click)="
                                                redirectToAddCategoryPage()
                                            ">
                                            Add Category
                                        </button>
                                        <button [disabled]="!access['add']" type="button"
                                            class="btn add-btn d-block d-md-none" (click)="
                                                                                        redirectToAddCategoryPage()
                                                                                    ">
                                            <span class="material-symbols-outlined">
                                                add
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- category-list-heading-row end -->
                @for (
                category of categoryList;
                track category.id
                ) {
                <div class="card mb-1 ms-2 me-2 table-card">
                    <div class="card-body">
                        <!-- category-list-table-row start -->
                        <div class="row category-list-table-row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="table-row action-table-responsive table-responsive">
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <img [src]="
                                                                category.categoryImg
                                                            " />
                                                </td>
                                                <td>
                                                    <div (click)="redirectToEditCategoryPage(category)">
                                                        <h6>
                                                            {{
                                                            category.catName
                                                            }}
                                                        </h6>
                                                        <p class="truncate-lines">
                                                            {{removeHtmlTags(category.description)}}
                                                        </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <label>Type </label>
                                                    <h6>
                                                        {{ category.categoryType | formatDocType }}
                                                    </h6>
                                                </td>
                                                <td>
                                                    <label>Main Category </label>
                                                    <h6 *ngIf="category.categoryType=='MAIN'">N/A</h6>
                                                    <h6 *ngIf="category.categoryType=='SUB'">
                                                        {{category.mainCategory}}
                                                    </h6>
                                                </td>
                                                <td class="text-end">
                                                    <div class="dropdown dropstart">
                                                        <i class="fas fa-ellipsis-v" id="dropdownMenuButton1"
                                                            data-bs-toggle="dropdown" aria-expanded="false"
                                                            data-bs-auto-close="inside"
                                                            data-bs-auto-close="outside"></i>
                                                        <ul class="dropdown-menu dropdown-menu-position"
                                                            aria-labelledby="dropdownMenuButton1">
                                                            <li [ngClass]="{ disable: !access['edit'] }">
                                                                <a [routerLink]="[
                                                                            '/edit-category'
                                                                        ]" [queryParams]="{
                                                                            id: category.id,
                                                                            pk: category.pk,
                                                                            slug: category.slug
                                                                        }" class="dropdown-item">
                                                                    <span
                                                                        class="material-symbols-outlined edit-icon me-3"
                                                                        title="Edit">
                                                                        edit_square
                                                                    </span>
                                                                    Edit
                                                                </a>
                                                            </li>
                                                            <li [ngClass]="{ disable: !access['delete'] }" (click)="
                                                                        showDeleteCategoryModalDialog(
                                                                            category
                                                                        )
                                                                    ">
                                                                <a class="dropdown-item">
                                                                    <span class="material-symbols-outlined delete-icon"
                                                                        title="Delete">
                                                                        delete
                                                                    </span>
                                                                    Delete</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
              <app-no-result-found></app-no-result-found>
            </div> -->
                        </div>
                        <!-- category-list-table-row end -->
                    </div>
                </div>
                }
            </div>
        </div>
    </div>
</section>

<!-- displayFIlterModalToggle start -->
<p-dialog [draggable]="false" [(visible)]="displayFilterModalToggle" [position]="position" [modal]="true"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false" [showHeader]="false"
    [style]="{width: '34vw', minHeight: '100%', maxHeight: '100%', margin: '0'}"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}">
    <div id="displayFilterModalToggle">
        <div class="modal-header">
            <h5 class="modal-title">Filter</h5>
            <i class="fas fa-times" (click)="closeFilterModalDialog()"></i>
        </div>
        <div class="body mt-3" [formGroup]="filterForm">
            <div class="row">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <input class="form-check-input" type="checkbox" id="showMainCategories" [checked]="false"
                        formControlName="showMainCategories" (change)="onShow()" />
                    <label class="ms-2 mb-0">Show all the main categories</label>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2"
                    *ngIf="filterForm.controls.showMainCategories.value==false">
                    <label>Category</label>
                    <select formControlName="mainCategory" class="form-select form-control"
                        (change)="onSelectMainCategory()" aria-label="Default select example">
                        <option [ngValue]="null" selected>Select</option>
                        <option *ngFor="
                        let mainCategory of mainCategoryList" [ngValue]="mainCategory">
                            {{ mainCategory.catName }}
                        </option>
                    </select>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2"
                    *ngIf="filterForm.controls.mainCategory.value && filterForm.controls.showMainCategories.value==false">
                    <label>Sub Category</label>
                    <select formControlName="subCategory" class="form-select form-control"
                        aria-label="Default select example">
                        <option [ngValue]="null" selected>Select</option>
                        <option *ngFor="let subCategory of subCategoryList" [ngValue]="subCategory">
                            {{ subCategory.catName }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="d-flex justify-content-end gap-2">
            <p-button label="Cancel" styleClass="filter-cancel-btn" (click)="cancelFilter()"></p-button>
            <p-button label="Apply" styleClass="filter-apply-btn" (click)="applyFilter()"></p-button>
        </div>
    </ng-template>
</p-dialog>
<!-- displayFIlterModalToggle end -->
<!-- deleteModalToggle start -->
<p-dialog [showHeader]="false" [(visible)]="deleteModalToggle" [style]="{width: '25vw'}" [baseZIndex]="10000"
    [modal]="true">
    <div class="body mt-3" id="deleteModalToggle">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="delete-image">
                    <img src="../../../../../assets/img/delete.png" />
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="confirm-msg">
                    <h6>Are You Sure?</h6>
                    <p>You will not be able to recover this category!</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="confirm-btns">
                    <button type="button" class="btn cancel-btn" (click)="deleteModalToggle = false">
                        Cancel
                    </button>
                    <button type="button" class="btn delete-confirm-btn" (click)="confirmDeleteCategory()">
                        Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</p-dialog>
<!-- {/* // deleteModalToggle end */} -->