import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import { MessageService } from 'primeng/api';
import { v4 as uuidv4 } from 'uuid';
import * as mutation from 'graphql/mutations';
import { CommonService } from '@services/common/common.service';
import * as query from 'graphql/queries';
import * as _ from 'underscore';
import { Messages } from '@/Toaster-messages';

@Component({
  selector: 'app-add-warehouse',
  templateUrl: './add-warehouse.component.html',
  styleUrl: './add-warehouse.component.scss'
})
export class AddWarehouseComponent implements OnInit {
  addWareHouseForm: FormGroup;
  status: boolean = false
  access: any = {}
  countryList: any = [];

  constructor(
    private awsApi: ApiService,
    private common: CommonService,
    private _router: Router,
    private fb: FormBuilder,
    private messageService: MessageService
  ) {
    this.addWareHouseForm = this.fb.group({
      name: ['', [Validators.required, Validators.pattern(/^(?!\s*$)(?=.*[a-zA-Z])[a-zA-Z0-9\s]+$/)]],
      addressLine1: ['', [Validators.required]],
      addressLine2: [''],
      country: ['', [Validators.required]],
      state: [''],
      city: [''],
      zipCode: [''],
      contactPerson: ['', [Validators.required]],
      email: ['', [Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
      phoneNumber: ['', [Validators.required, Validators.pattern('[-+0-9]{10,13}')]],
      workingHoursFrom: ['', [Validators.required]],
      workingHoursTo: ['', [Validators.required]],
      latitude: [],
      longitude: []
    });
  }

  async getAllCountry() {
    return this.awsApi.client.graphql({
      query: query.listCountries,
      variables: {
        limit: 9999
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.getRoleManagement()
  }

  async getRoleManagement() {
    this.awsApi.loading.next(true);
    try {
      await this.common.getPermissionsOfUser('Settings', 'Manage Warehouse');
      this.access = this.common.access;
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
    this.countryList = (await this.getAllCountry()).data.listCountries.items
  }

  saveWareHouse() {
    this.awsApi.loading.next(true);
    if (this.addWareHouseForm.valid &&
      ((this.addWareHouseForm.value.latitude && this.addWareHouseForm.value.longitude) ||
        (!this.addWareHouseForm.value.latitude && !this.addWareHouseForm.value.longitude))) {
      this.awsApi.client.graphql({
        query: query.listWareHouses,
        variables: {
          filter: null,
          limit: 9999999,
          nextToken: null,
          sortDirection: null
        }
      }).then((response) => {
        const wareHosueList = response.data.listWareHouses.items;
        let newWareHosueName = this.addWareHouseForm.value.name.toLowerCase().replace(/\s+/g, '');
        const isDuplicate = wareHosueList.some((wareHouse) => {
          let existingName = wareHouse.name.toLowerCase().replace(/\s+/g, '');
          return existingName === newWareHosueName;
        });
        if (isDuplicate) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: Messages.NAME_ALREADY_EXISTS
          });
          this.awsApi.loading.next(false);
          return;
        }
        let id = uuidv4();
        let str = this.addWareHouseForm.value.name.toLowerCase()
        str = str.replace(/\s{2,}/g, ' ');
        str = (str.trim()).replace(/\s+/g, '')
        let data = {
          pk: 'WARE#',
          id: id,
          name: this.addWareHouseForm.value.name,
          addressLine1: this.addWareHouseForm.value.addressLine1,
          addressLine2: this.addWareHouseForm.value.addressLine2,
          country: this.addWareHouseForm.value.country.name,
          countryId: this.addWareHouseForm.value.country.id,
          state: this.addWareHouseForm.value.state,
          city: this.addWareHouseForm.value.city,
          zipCode: this.addWareHouseForm.value.zipCode,
          contactPerson: this.addWareHouseForm.value.contactPerson,
          email: this.addWareHouseForm.value.email,
          phoneNumber: this.addWareHouseForm.value.phoneNumber,
          workingHoursFrom: this.addWareHouseForm.value.workingHoursFrom,
          workingHoursTo: this.addWareHouseForm.value.workingHoursTo,
          status: true,
          searchKey: str,
          latitude: this.addWareHouseForm.value.latitude,
          longitude: this.addWareHouseForm.value.longitude
        };
        this.awsApi.client
          .graphql({
            query: mutation.createWareHouse,
            variables: {
              input: {
                ...data
              }
            }
          })
          .then((response) => {
            this.awsApi.loading.next(false);
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Warehouse created successfully!'
            });
            this._router.navigate(['/list-warehouse']);
          })
          .catch((error) => {
            this.awsApi.loading.next(false);
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: Messages.SOMETHING_WENT_WRONG
            });
          });
      })
    } else {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill all required details!'
      });
    }
  }

  onSwitchChange() {
    this.status = !this.status;
  }

  routeListWareHouse() {
    this._router.navigate(['/list-warehouse']);
  }

  capitalizeFirstLetter(controlName: string) {
    const name = this.addWareHouseForm.controls[controlName];
    const capitalizeString = (input: string): string => {
      const cleanInput = input.replace(/[^a-zA-Z\s]/g, '');
      const trimmedInput = cleanInput.trim().replace(/\s+/g, ' ');
      return trimmedInput.charAt(0).toUpperCase() + trimmedInput.slice(1);
    };
    if (name && name.value) {
      const capitalized = capitalizeString(name.value);
      name.setValue(capitalized);
    }
  }
}
