import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import { Messages } from '@/Toaster-messages';
import { ROUTES } from '@/redirect-routes';
import { CommonService } from '@services/common/common.service';
import { MessageService } from 'primeng/api';
import * as mutation from 'graphql/mutations';

@Component({
  selector: 'app-faq-list',
  templateUrl: './faq-list.component.html',
  styleUrl: './faq-list.component.scss'
})
export class FaqListComponent {
  faqList: any = []
  copyFaqList: any = []
  searchText: any = ''
  deleteModalToggle: boolean;
  searchKey: any
  filterObject: any = {}
  access: any = {}
  itemTobeDeleted: any

  constructor(private router: Router, private common: CommonService, private messageService: MessageService,
    private awsApi: ApiService) { }

  ngOnInit(): void {
    this.getRoleManagement()
    this.startingFunction()
  }

  async getRoleManagement() {
    this.awsApi.loading.next(true);
    try {
      await this.common.getPermissionsOfUser('Others', 'FAQ');
      this.access = this.common.access;
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  async startingFunction() {
    this.awsApi.loading.next(true);
    this.GetFaqList()
  }

  async getFaqList() {
    let fieldsToFetch = ['id', 'pk', 'name', 'sequenceNumber']
    const query = this.common.editListQuery('listFAQS', 'ModelFAQFilterInput', fieldsToFetch);
    return this.awsApi.client
      .graphql({
        query,
        variables: {
          filter: this.filterObject,
          limit: 9999,
          nextToken: null,
          sortDirection: null
        }
      })
  }

  GetFaqList() {
    Promise.all([this.getFaqList()]).then((response) => {
      this.faqList = response[0]['data'].listFAQS.items.sort((a: any, b: any) => a.sequenceNumber - b.sequenceNumber)
      this.copyFaqList = this.faqList
      this.awsApi.loading.next(false);
    }).catch((error) => {
      this.awsApi.loading.next(false);
    });
  }

  search(searchText) {
    if (searchText != '') {
      this.searchKey = searchText.toLowerCase()
      this.searchKey = this.searchKey.replace(/\s{2,}/g, ' ');
      this.searchKey = (this.searchKey.trim()).replace(/\s+/g, '');

      this.filterObject['searchKey'] = { contains: this.searchKey }
      this.startingFunction()
    }
    else {
      delete this.filterObject['searchKey']
      this.startingFunction()
    }
  }

  redirectToAddFaqPage(faqType: any, faqId: any = null) {
    this.router.navigate([ROUTES.CREATE_FAQ], { queryParams: { type: faqType, id: faqId } });
  }
  redirectToCreateFaqSectionPage(selectedFaq) {
    this.router.navigate([ROUTES.CREATE_FAQ_SECTION], { queryParams: { id: selectedFaq.id } });
  }
  deleteFaq(faq) {
    this.deleteModalToggle = true;
    this.itemTobeDeleted = faq;
  }

  async confirmDeleteFaq() {
    this.awsApi.loading.next(true);
    this.deleteModalToggle = false;
    let data = {
      pk: this.itemTobeDeleted.pk,
      id: this.itemTobeDeleted.id
    };
    this.awsApi.client
      .graphql({
        query: mutation.deleteFAQ,
        variables: {
          input: {
            ...data
          }
        }
      })
      .then((response) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'FAQ deleted successfully!'
        });
        this.ngOnInit();
      })
      .catch((error) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      });
  }
}