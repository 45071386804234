<section id="ordersReportListSec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 my-auto">
                <div class="card m-2">
                    <div class="card-body">
                        <!-- orders-list-heading-row start -->
                        <div class="row orders-list-heading-row">
                            <div class="col-xxl-3 col-xl-3 col-lg-12 col-md-12 col-sm-12 col-xs-12 my-auto">
                                <div class="text-center text-xl-left mt-2 mt-xl-0">
                                    <div class="d-flex justify-content-start align-items-center">
                                        <i class="fas fa-chevron-left" (click)="redirectToReportListPage()"></i>
                                        <h6>{{header}}</h6>
                                    </div>
                                    <p class="mb-2 mb-md-0 mt-2">
                                        <span *ngIf="orderReportList?.length > 0">{{orderReportList.length}}
                                            Results Found</span>
                                    </p>
                                </div>
                            </div>
                            <div class="col-xxl-9 col-xl-9 col-lg-12 col-md-12 col-sm-12 col-xs-12 my-auto">
                                <div class="has-search-d-flex justify-content-center justify-content-xl-end align-items-start align-items-xl-center flex-nowrap flex-xl-wrap"
                                    [formGroup]="filterForm">
                                    <div *ngIf="!filterApplied">
                                        <button type="button" class="btn filter-btn me-3 d-none d-lg-block" (click)="
                                                                                showFilterModalDialog('right')">
                                            <span class="material-symbols-outlined me-1">
                                                filter_alt
                                            </span>
                                            Filter
                                        </button>
                                        <button type="button" class="btn filter-btn me-3 d-block d-md-none"
                                            (click)="showFilterModalDialog('right')">
                                            <span class="material-symbols-outlined">
                                                filter_alt
                                            </span>
                                        </button>
                                    </div>
                                    <div *ngIf="filterApplied">
                                        <button type="button" class="btn after-filter-btn me-3 d-none d-md-block"
                                            (click)="showFilterModalDialog('right')">
                                            <i class="fas fa-filter"></i>
                                            Filter
                                        </button>
                                        <button type="button" class="btn after-filter-btn me-3 d-block d-md-none"
                                            (click)="showFilterModalDialog('right')">
                                            <i class="fas fa-filter"></i>
                                        </button>
                                    </div>
                                    <div *ngIf="orderReportList.length>0">
                                        <button type="button" class="btn export-btn d-none d-lg-block"
                                            (click)="exportToExcel()" *ngIf="orderReportList?.length>0">
                                            <i class="fas fa-file-export me-1"></i> Export
                                        </button>
                                        <button type="button" class="btn export-btn d-block d-lg-none"
                                            (click)="exportToExcel()" *ngIf="orderReportList?.length>0">
                                            <i class="fas fa-file-export"></i>
                                        </button>
                                    </div>
                                    <div class="d-flex align-items-baseline gap-2 ms-2">
                                        <label class="d-none d-md-block">Report Type</label>
                                        <select class="form-select form-control" (change)="applyFilter()"
                                            aria-label="Default select example" formControlName="reportType">
                                            <option value="ordersummary">Order Summary</option>
                                            <option value="orderdetail">Order Details</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-1 ms-2 me-2" *ngIf="!filterApplied && orderReportList?.length == 0">
                    <div class="card-body p-0 order-selection">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <h2>Please select a filter to view the report</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- orders-list-heading-row end -->
                <div class="card mb-1 ms-2 me-2 scroll-container" (window:scroll)="onScroll()">
                    <div class="card-body p-0">
                        <!-- orders-list-table-row start -->
                        <div class="row orders-list-table-row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                *ngIf="filterApplied && orderReportList.length > 0 && filterForm.controls.reportType.value == orderReportTypeList.summary">
                                <div class="table-row table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Order Ref</th>
                                                <th>Customer Name</th>
                                                <th>Ordered On</th>
                                                <th>Total Products</th>
                                                <th class="text-end">Tax</th>
                                                <th class="text-end">Item Total</th>
                                                <th class="text-center">Payment Method</th>
                                                <th class="text-center">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let item of orderReportDataList">
                                            <tr>
                                                <td>{{item.id}}</td>
                                                <td>
                                                    <span
                                                        *ngIf="item.userName && item.userName != ' ' && item.userName != '' && !item.userName.includes('null')">{{item.userName}}</span>
                                                    <span
                                                        *ngIf="!item.userName || item.userName == ' ' || item.userName == '' || item.userName.includes('null')">N/A</span>
                                                </td>
                                                <td>{{item.createdAt | date : "dd/MM/YYYY" }}</td>
                                                <td>{{item.totalProducts}}</td>
                                                <td class="text-end">{{item.orderSummary?.tax | number: '.2'}}</td>
                                                <td class="text-end">{{item.totalAmount | number: '.2' }}</td>
                                                <td class="text-center">
                                                    <span *ngIf="item.paymentDetails?.paymentMethod">
                                                        {{item.paymentDetails?.paymentMethod | formatDocType}}</span>
                                                    <span *ngIf="!item.paymentDetails?.paymentMethod">N/A</span>
                                                </td>
                                                <td class="text-center">
                                                    <span *ngIf="item.status"> {{item.status | formatDocType }}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                *ngIf="filterApplied && orderReportList.length > 0 && filterForm.controls.reportType.value == orderReportTypeList.details">
                                <div class="table-row table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Order Ref</th>
                                                <th>Customer Name</th>
                                                <th>Product Name</th>
                                                <th>Qty</th>
                                                <th class="text-end">Unit Price</th>
                                                <th class="text-end">Tax</th>
                                                <th class="text-end">Total</th>
                                                <th class="text-center">Ordered On</th>
                                                <th class="text-center">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let item of orderReportDataList">
                                            <tr>
                                                <td>{{(item.pk).split('#')[1]}}</td>
                                                <td>
                                                    <span
                                                        *ngIf="item.userName && item.userName != ' ' && item.userName != '' && !item.userName.includes('null')">{{item.userName}}</span>
                                                    <span
                                                        *ngIf="!item.userName || item.userName == ' ' || item.userName == '' || item.userName.includes('null')">N/A</span>
                                                </td>
                                                <td>{{item.itemDetail?.productName}}</td>
                                                <td>{{item.quantity}}</td>
                                                <td class="text-end">{{item.itemDetail?.price | number: '.2'}}</td>
                                                <td class="text-end">{{item.taxAmount | number: '.2'}}</td>
                                                <td class="text-end">{{ calculateTotal(item.itemDetail?.price,
                                                    item.quantity,
                                                    item.taxAmount) | number:'1.2-2' }}</td>
                                                <td class="text-center">{{item.createdAt | date : "dd/MM/YYYY"}}</td>
                                                <td class="text-center"><span *ngIf="item.deliveryStatus">
                                                        {{item.deliveryStatus | formatDocType}}</span>
                                                    <span *ngIf="!item.deliveryStatus">Pending</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3"
                                *ngIf="page=='1' && !loader && orderReportList.length==0">
                                <app-no-result-found></app-no-result-found>
                            </div>
                        </div>
                        <!-- orders-list-table-row end -->
                    </div>
                </div>
                <div *ngIf="loading && orderReportDataList.length>10">Loading...</div>
            </div>
        </div>
    </div>
</section>
<!-- displayFilterModalToggle start -->
<p-dialog [draggable]="false" [(visible)]="displayFilterModalToggle" [position]="position" [modal]="true"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false" [showHeader]="false"
    [style]="{width: '34vw', minHeight: '100%', maxHeight: '100%', margin: '0'}"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}">
    <div id="displayFilterModalToggle">
        <div class="modal-header">
            <h5 class="modal-title">Filter</h5>
            <i class="fas fa-times" (click)="closeFilterModalDialog()"></i>
        </div>
        <div class="body mt-3" [formGroup]="filterForm">
            <div class="row" *ngIf="filterForm.controls.reportType.value == orderReportTypeList.summary">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Status</label>
                    <p-multiSelect display="chip" [options]="summaryStatusList" formControlName="summaryStatus"
                        [filter]="true" optionLabel="text" placeholder="Choose Status">
                    </p-multiSelect>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Ordered On</label>
                    <p-calendar [maxDate]="maxDate" formControlName="summaryOrderedOn" [iconDisplay]="'input'"
                        [showIcon]="true" inputId="icondisplay" selectionMode="range"
                        placeholder="From-To"></p-calendar>
                </div>
            </div>
            <div class="row" *ngIf="filterForm.controls.reportType.value == orderReportTypeList.details">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Status</label>
                    <p-multiSelect display="chip" [options]="detailsStatusList" formControlName="detailStatus"
                        [filter]="true" optionLabel="title" placeholder="Choose Status">
                    </p-multiSelect>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Ordered On</label>
                    <p-calendar [maxDate]="maxDate" [iconDisplay]="'input'" [showIcon]="true" inputId="icondisplay"
                        selectionMode="range" placeholder="From-To" formControlName="detailsOrderedOn"></p-calendar>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Main Category</label>
                    <select formControlName="mainCategory" (change)="onSelectMainCategory()"
                        class="form-select form-control" aria-label="Default select example">
                        <option [ngValue]="null" selected>Choose Category</option>
                        <option *ngFor="let mainCategory of mainCategoryList" [ngValue]="mainCategory">
                            {{ mainCategory.catName }}
                        </option>
                    </select>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2"
                    *ngIf="filterForm.get('mainCategory').value">
                    <label>Sub Category</label>
                    <select class="form-select form-control" formControlName="subCategory"
                        aria-label="Default select example">
                        <option [ngValue]="null" selected>Choose Category</option>
                        <option *ngFor="let subCategory of subCategoryList" [ngValue]="subCategory">
                            {{ subCategory.catName }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="d-flex justify-content-end gap-2">
            <p-button label="Cancel" styleClass="filter-cancel-btn" (click)="cancelFilter()"></p-button>
            <p-button label="Apply" styleClass="filter-apply-btn" (click)="applyFilter()"></p-button>
        </div>
    </ng-template>
</p-dialog>
<!-- displayFilterModalToggle end -->