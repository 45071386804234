<pf-dropdown [hideArrow]="true" class="user-menu">
    <div slot="head">
        <a class="nav-link" (click)="enableShow()" *ngIf="!profileImageUrl || profileImageUrl=='null'">
            <i class="fas fa-user"></i>
        </a>
        <a class="nav-link" (click)="enableShow()" *ngIf="profileImageUrl && profileImageUrl!='null'">
            <img [src]="profileImageUrl" fallbackSrc="assets/img/dummyUser.png" class="user-image-small"
                alt="User Image" width="20" height="20" />
        </a>
    </div>
    <div slot="body" *ngIf="show">
        <li class="user-header">
            <pf-image *ngIf="!profileImageUrl || profileImageUrl === 'null'" fallbackSrc="assets/img/dummyUser.png"
                class="user-image-big" alt="User Image" width="90" height="90" />
            <pf-image *ngIf="profileImageUrl && profileImageUrl !== 'null'" [src]="profileImageUrl"
                fallbackSrc="assets/img/dummyUser.png" class="user-image-big" alt="User Image" width="90" height="90" />
            <p>
                <span>{{ email }}</span>
            </p>
        </li>
        <!-- Menu Body -->
        <li class="user-body d-none">
            <div class="row">
                <div class="col-4 text-center">
                    <a [routerLink]="['/']">Followers</a>
                </div>
                <div class="col-4 text-center">
                    <a [routerLink]="['/']">Sales</a>
                </div>
                <div class="col-4 text-center">
                    <a [routerLink]="['/']">Friends</a>
                </div>
            </div>
            <!-- /.row -->
        </li>
        <!-- Menu Footer-->
        <li class="user-footer">
            <a (click)="redirectToProfilePage()" class="btn btn-default btn-flat">
                Profile
            </a>
            <a (click)="logout()" class="btn btn-default btn-flat float-right">
                Sign out
            </a>
        </li>
    </div>
</pf-dropdown>