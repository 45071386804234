import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import { MessageService } from 'primeng/api';
import { OrderStatusList, PaymentMethodList } from '@/common-list';
import { FormBuilder } from '@angular/forms';
import { formatDate } from '@angular/common';
import { ROUTES } from '@/redirect-routes';
import { Subscription } from 'rxjs';
import { Messages } from '@/Toaster-messages';
import { CommonService } from '@services/common/common.service';

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrl: './orders-list.component.scss'
})
export class OrdersListComponent {
  private routeParamsSubscription: Subscription;
  value: number = 5;
  position: any;
  displayFilterModalToggle = false
  deleteModalToggle: any
  ordersList: any = []
  copyOrdersList: any = []
  searchText: any;
  filterApplied: boolean = false
  filterObject: any = {}
  orderStatusList: any = OrderStatusList
  paymentMethodList: any = PaymentMethodList
  searchKey: any
  commonSearchText: any
  access: any = {}
  searchTextValue: any = ''

  page: any = 1;
  pageSize = 10;
  loading: boolean = false;
  endOfData: boolean = false;
  loader: boolean = true

  today: any = new Date();
  thirtyDaysAgo = new Date((new Date()).setDate(this.today.getDate() - 30))
  currency: any

  filterForm = this.formBuilder.group({
    status: [],
    paymentMethod: [],
    orderedOn: []
  })
  constructor(private common: CommonService, private route: ActivatedRoute, private router: Router, private awsApi: ApiService, private formBuilder: FormBuilder, private messageService: MessageService) { }

  async ngOnInit() {
    this.getRoleManagement()
    this.currency = await this.common.getCurrencyOfCompany()
    this.position = 'right';
    this.filterForm.patchValue({ status: this.orderStatusList })
    this.filterForm.patchValue({ orderedOn: [this.thirtyDaysAgo, this.today] });
    this.filterDate()
    this.startingFunction()
    this.common.emailIdSearchFilter$.subscribe(async value => {
      if (value) {
        this.refreshPage()
        this.searchTextValue = value
        this.search(value)
      }
    })
  }

  async startingFunction() {
    this.awsApi.loading.next(true);
    // common search functionality
    this.routeParamsSubscription = this.route.queryParams.subscribe(async params => {
      this.commonSearchText = params['searchText'];
      if (this.commonSearchText) {
        const currentUrl = this.router.url;
        const urlWithoutParams = currentUrl.split('?')[0];
        this.router.navigateByUrl(urlWithoutParams);
        this.filterObject['searchKey'] = { contains: this.commonSearchText }
        this.filterForm.reset()
      }
      this.page = 1;
      this.pageSize = 10;
      this.loading = false;
      this.endOfData = false;
      await this.getOrdersList()
    });
  }

  async getRoleManagement() {
    try {
      await this.common.getPermissionsOfUser('Orders', 'Manage Orders');
      this.access = this.common.access;
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  ngOnDestroy(): void {
    this.routeParamsSubscription?.unsubscribe();
  }

  async getOrdersList() {
    let fieldsToFetch = ['id', 'pk', 'userName', 'email', 'phoneNumber', 'status', 'createdAt', 'deliveryInfo {', 'deliveryType', '}', 'paymentDetails {', 'paymentMethod', '}', 'totalAmount']

    Promise.all([await this.common.getOrders(this.filterObject, fieldsToFetch)]).then((result) => {
      this.ordersList = result[0].sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      this.copyOrdersList = this.ordersList;
      if (this.filterForm.controls.status.value?.length > 0) {
        this.filterApplied = true
        this.ordersList = this.copyOrdersList.filter((order: any) => {
          for (const status of this.filterForm.controls.status.value) {
            if (order.status === status.value) {
              return true;
            }
          }
          return false;
        });
      }
      this.copyOrdersList = this.ordersList
      if (this.filterForm.controls.paymentMethod.value?.length > 0) {
        this.filterApplied = true
        this.ordersList = this.copyOrdersList.filter((arr: any) => {
          if (arr.paymentDetails) {
            return arr.paymentDetails['paymentMethod'] == this.filterForm.controls.paymentMethod.value
          }
        });
      }
      this.copyOrdersList = this.ordersList
      this.loadData()
      this.closeFilterModalDialog()
      this.awsApi.loading.next(false);
    }).catch((error) => {
      this.awsApi.loading.next(false);
    });
    this.loader = this.awsApi.loading.getValue()
  }

  loadData() {
    // Initial data loading
    this.loading = true;
    const startIndex = (this.page - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    setTimeout(() => {
      this.copyOrdersList = this.ordersList.slice(startIndex, endIndex);
      this.loading = false;
    }, 2000);
  }

  onScroll() {
    if (!this.loading && !this.endOfData) {
      const container = document.querySelector('.scroll-container');
      if (
        container &&
        container.scrollHeight - container.scrollTop === container.clientHeight
      ) {
        this.loadMore();
      }
    }
  }

  loadMore() {
    this.page++
    // Loading more data
    this.loading = true;
    const startIndex = (this.page - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    setTimeout(() => {
      this.copyOrdersList = this.copyOrdersList.concat(this.ordersList.slice(startIndex, endIndex));
      this.loading = false;

      if (this.copyOrdersList.length >= this.ordersList.length) {
        this.endOfData = true;
      }
    }, 3000);
  }

  search(event) {
    if (this.searchTextValue != '') {
      this.searchKey = this.searchTextValue.toLowerCase()
      this.searchKey = this.searchKey.replace(/\s{2,}/g, ' ');
      this.searchKey = (this.searchKey.trim()).replace(/\s+/g, '');
      this.filterObject['searchKey'] = { contains: this.searchKey }
    }
    else {
      delete this.filterObject['searchKey']
    }
    this.startingFunction()
  }

  clearText() {
    this.searchTextValue = ''
    delete this.filterObject['searchKey']
    this.startingFunction()
  }

  applyFilter() {
    this.clearFilterObject()
    this.filterDate()
    this.startingFunction()
  }

  clearFilterObject() {
    delete this.filterObject['status']
    delete this.filterObject['createdAt']
  }

  filterDate() {
    if (this.filterForm.controls.orderedOn.value?.length == 2) {
      this.filterApplied = true
      if (this.filterForm.controls.orderedOn.value[0] != null) {
        var selectedDate1 = new Date(this.filterForm.controls.orderedOn.value[0])
      }
      if (this.filterForm.controls.orderedOn.value[1] != null) {
        let selectedDate2 = new Date(this.filterForm.controls.orderedOn.value[1])
        var selectedDate2Formatted = selectedDate2.setDate(selectedDate2.getDate() + 1)
        this.filterObject['createdAt'] = { ge: formatDate(selectedDate1, 'yyyy-MM-dd', 'en-US'), le: formatDate(selectedDate2Formatted, 'yyyy-MM-dd', 'en-US') }
      } if (this.filterForm.controls.orderedOn.value[1] == null) {
        this.filterObject['createdAt'] = { contains: formatDate(selectedDate1, 'yyyy-MM-dd', 'en-US') }
      }
    }
  }

  showFilterModalDialog(position: string) {
    this.position = position;
    this.displayFilterModalToggle = true;
  }

  closeFilterModalDialog() {
    this.displayFilterModalToggle = false;
  }
  showDeleteGeneralQueryModalDialog(item: any) {
    this.deleteModalToggle = true;
  }
  redirectToOrderDetails(order) {
    this.router.navigate([ROUTES.ORDER_DETAILS], { queryParams: { orderId: order.id } });
  }
  refreshPage() {
    this.filterForm.reset()
    this.displayFilterModalToggle = false;
    this.clearFilterObject()
    this.filterApplied = false
    this.filterForm.reset()
    this.startingFunction()
  }
}