import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import * as mutation from 'graphql/mutations';
import { AppService } from '@services/app.service';
import { ApiService } from '@services/api/api.service';
import { Coverage, Status } from 'API';
import { Messages } from '@/Toaster-messages';
import * as query from 'graphql/queries';
import { CommonService } from '@services/common/common.service';
import { exit } from 'process';
import { CoverageList, ShippingTypeList } from '@/common-list';

@Component({
  selector: 'app-shipping-edit',
  templateUrl: './shipping-edit.component.html',
  styleUrl: './shipping-edit.component.scss'
})
export class ShippingEditComponent {
  zipCodes = []
  countryList: any = []
  access: any = {}
  valid: boolean = false
  selectedCoverage: any
  selectedShippingId: any
  shippingDetails: any = []
  currency: any
  updateCompleted = false
  coverageList: any = CoverageList
  inputData: any = {}
  shippingForm = this.fb.group({
    city: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/)]],
    country: [],
    state: ['', [Validators.pattern(/^[a-zA-Z ]*$/)]],
    deliveryFee: ['', [Validators.required]],
    coverage: [],
    zipCodeValues: [''],
    latitude: [''],
    longitude: [''],
    radius: [''],
  });

  constructor(private route: ActivatedRoute, private common: CommonService, private appService: AppService, private router: Router, private awsApi: ApiService, private _router: Router, private fb: FormBuilder, private messageService: MessageService) { }
  async ngOnInit() {
    this.getRoleManagement()
    this.currency = await this.common.getCurrencyOfCompany()
    this.selectedShippingId = this.route.snapshot.queryParamMap?.get('id')
    Promise.all([this.getAllCountry(), this.getShippingItem()]).then((result: any) => {
      this.countryList = result[0].data.listCountries.items;
      this.selectedCoverage = 'Zipcode'
      this.zipCodes = []
      this.shippingDetails = result[1].data.getShipping;
      this.autoFillDetails()
    })
  }

  async getRoleManagement() {
    this.awsApi.loading.next(true);
    try {
      await this.common.getPermissionsOfUser('Settings', 'Manage Shipping');
      this.access = this.common.access;
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  getShippingItem() {
    return this.awsApi.client.graphql({
      query: query.getShipping,
      variables: {
        pk: 'SHIPPING#',
        id: this.selectedShippingId
      }
    });
  }

  getAllCountry() {
    return this.awsApi.client.graphql({
      query: query.listCountries,
      variables: {
        pk: null,
        slug: null,
        filter: null,
        limit: 9999,
        nextToken: null,
        sortDirection: null
      }
    });
  }

  autoFillDetails() {
    this.shippingForm.patchValue({
      city: this.shippingDetails.city,
      country: this.countryList.find(item => item.name === this.shippingDetails.countryName),
      state: this.shippingDetails.state,
      deliveryFee: this.shippingDetails.deliveryCharge.toString(),
      coverage: this.coverageList.find(item => item.value == this.shippingDetails.coverage),
      latitude: this.shippingDetails.latitude,
      longitude: this.shippingDetails.longitude,
      radius: this.shippingDetails.radius
    })
    this.zipCodes = this.shippingDetails.zipCodes ? this.shippingDetails.zipCodes : []
  }

  async isShippingAlreadyAdded(): Promise<boolean> {
    let shippingRes
    let filter = {
      'pk': { eq: 'SHIPPING#' },
      'id': { ne: this.inputData.id },
      'countryId': { eq: this.inputData.countryId },
    }
    try {
      let shippingResponse = await this.getShippingDetails(filter);
      let formatValue = value => value ? value.replace(/\s/g, "").toLowerCase().trim() : '';

      if (shippingResponse.length > 0) {
        shippingRes = shippingResponse.filter((item) =>
          formatValue(item.city) === formatValue(this.inputData.city) &&
          formatValue(item.state) === formatValue(this.inputData.state) &&
          formatValue(item.countryName) === formatValue(this.inputData.countryName) &&
          formatValue(item.coverage) === formatValue(this.inputData.coverage)
        )
      }
      return shippingRes.length > 0;
    } catch (error) { }
  }

  async getShippingDetails(filterObject: any = {}) {
    let items = [];
    let nextToken = null;
    do {
      const response = await this.awsApi.client.graphql({
        query: query.listShippings,
        variables: {
          filter: filterObject,
          limit: 650,
          nextToken: nextToken,
          sortDirection: null
        }
      })
      items = items.concat(response.data.listShippings.items);
      nextToken = response.data.listShippings.nextToken;
    } while (nextToken !== null)
    return items
  }

  async editShipping() {
    if (!this.updateCompleted) {
      this.valid = false
      if (this.shippingForm.valid && this.shippingForm.controls.country.value) {
        this.valid = true
      }
      if (this.valid) {
        this.awsApi.loading.next(true);
        this.inputData = {
          pk: 'SHIPPING#',
          id: this.shippingDetails.id,
          city: this.shippingForm.controls.city.value,
          countryId: this.shippingForm.controls.country.value.id,
          countryName: this.shippingForm.controls.country.value.name,
          state: this.shippingForm.controls.state?.value ? this.shippingForm.controls.state.value : null,
          deliveryCharge: parseFloat(this.shippingForm.controls.deliveryFee.value),
          coverage: this.zipCodes.length > 0 ? Coverage.Zipcode : null,
          zipCodes: this.zipCodes.length == 0 ? null : this.zipCodes,
          latitude: this.shippingForm.controls.latitude?.value ? this.shippingForm.controls.latitude.value : null,
          longitude: this.shippingForm.controls.longitude?.value ? this.shippingForm.controls.longitude.value : null,
          radius: this.shippingForm.controls.radius?.value ? this.shippingForm.controls.radius.value : null,
          status: Status.ACTIVE,
          updatedAt: new Date().toISOString(),
          modifiedBy: localStorage.getItem('userName') + '#' + localStorage.getItem('role')
        }
        this.inputData['searchKey'] = (((
          (this.inputData.city || '') + '#' +
          (this.inputData.state || '') + '#' +
          (this.inputData.countryName || '') + '#' +
          (this.inputData.coverage || '')).replace(/\s/g, "")).toLowerCase()).trim()
        if (!await this.isShippingAlreadyAdded()) {
          this.awsApi.client
            .graphql({
              query: mutation.updateShipping,
              variables: {
                input: {
                  ...this.inputData
                }
              }
            }).then((response) => {
              this.updateCompleted = true
              this.awsApi.loading.next(false);
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: Messages.SHIPPING_UPDATED
              });
              this.router.navigate([`list-shipping`]);
            })
        } else {
          this.updateCompleted = false
          this.awsApi.loading.next(false);
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: Messages.SHIPPING_CHARGE_ALREADY_ADDED
          });
        }
      }
      else {
        this.awsApi.loading.next(false);
        this.updateCompleted = false
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.PLEASE_FILL_REQUIRED_FIELDS
        });
      }
    }
  }

  onSelectCoverage() {
    this.selectedCoverage = this.shippingForm.controls.coverage.value ? this.shippingForm.controls.coverage.value.value : null
    this.shippingForm.controls.zipCodeValues.reset()
    this.shippingForm.controls.latitude.reset()
    this.shippingForm.controls.longitude.reset()
    this.shippingForm.controls.radius.reset()
  }

  addZipCode($event: any = null) {
    let isValid = true;
    let zipCodeValues = this.shippingForm.controls.zipCodeValues.value;

    const processZipCode = (zipCode: string) => {
      if (/^\d+$/.test(zipCode)) {
        if (!this.zipCodes.includes(zipCode)) {
          this.zipCodes.push(zipCode);
        }
      } else if (zipCode.includes('-')) {
        let start = parseInt(zipCode.split('-')[0].trim(), 10);
        let end = parseInt(zipCode.split('-')[1].trim(), 10);
        if (start < end) {
          for (let i = start; i <= end; i++) {
            if (!this.zipCodes.includes(i.toString())) {
              this.zipCodes.push(i.toString());
            }
          }
        } else {
          isValid = false;
        }
      } else {
        isValid = false;
      }
    };

    zipCodeValues.split(',').forEach(item => {
      if (item != null && typeof item == 'string' && item.trim()) {
        processZipCode(item.trim());
      }
    });

    if (!isValid) {
      this.displayValidationErrorMessage();
    }
  }

  displayValidationErrorMessage() {
    this.updateCompleted = false
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Invalid Zipcodes'
    });
  }

  deleteZipCode(selectedZipCode) {
    this.zipCodes = this.zipCodes.filter(zipCode => zipCode != selectedZipCode)
  }

  redirectToListing() {
    this.router.navigate([`list-shipping`]);
  }

  preventE(event) {
    const invalidChars = ['e', 'E', '+', '-', '.'];
    if (invalidChars.includes(event.key)) {
      event.preventDefault();
    }
  }
}
