import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import { Status } from 'API';
import { MessageService } from 'primeng/api';
import * as query from 'graphql/queries';
import * as mutation from 'graphql/mutations';
import { v4 as uuidv4 } from 'uuid';
import _ from 'underscore';
import { CommonService } from '@services/common/common.service';
import { Messages } from '@/Toaster-messages';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrl: './add-role.component.scss'
})
export class AddRoleComponent implements OnInit {
  modulesList: any = []
  accordionOpen: any = []
  modules: any = []
  subModulesList: any = []
  subMod = []
  hasSubModules: any[] = [];
  filterObject: any = {}
  moduleList = []
  idList = []
  reportsList: any = []
  submitted = false
  actions: any = {
    'view': false,
    'add': false,
    'edit': false,
    'delete': false
  }
  roleForm = this.fb.group({
    role: ['', [Validators.required]],
  })

  constructor(private router: Router, private common: CommonService, private awsApi: ApiService, private messageService: MessageService, private fb: FormBuilder) { }

  async getModuleMaster() {
    return this.awsApi.client
      .graphql({
        query: query.listModuleMasters
      })
  }

  ngOnInit(): void {
    this.getAllMainModules()
  }

  getAllMainModules() {
    this.awsApi.loading.next(true);
    let filterReport = { status: { eq: Status.ACTIVE } }
    Promise.all([this.getModuleMaster(), this.common.getReportMaster(filterReport)]).then((response) => {
      this.modules = response[0].data.listModuleMasters.items
      this.reportsList = response[1].data.listReportMasters.items.sort((a: any, b: any) => a.sequenceNumber - b.sequenceNumber);
      this.modulesList = this.modules.filter((arr: any) => {
        return arr.pk == 'MAIN#';
      });
      this.modulesList = this.modulesList.sort((a: any, b: any) => a.sequenceNumber - b.sequenceNumber);
      this.setModuleList()
      this.awsApi.loading.next(false);
    })
      .catch((error) => {
        this.awsApi.loading.next(false);
      });
  }

  getModuleName(id): any {
    return this.modulesList
  }

  isIdInModulesList(id: string): boolean {
    return this.modulesList.some(module => module.id === id);
  }

  setModuleList() {
    _.each(this.modulesList, (module, index) => {
      this.moduleList.push({
        moduleId: module.id,
        menu: module.moduleName,
        view: false,
        add: false,
        edit: false,
        delete: false
      })
      this.checkSubMenusExist(module, index)
    })
  }

  checkSubMenusExist(module, index) {
    let subModulesLength
    let length = this.hasSubModules.length
    let ifExist = this.isSubMenusExist(module, index)
    if (ifExist || module.moduleName == 'Reports') {
      if (!this.hasSubModules[length]) {
        this.hasSubModules[length] = {};
      }
      this.hasSubModules[length].status = true
      this.hasSubModules[length].index = index
      subModulesLength = module.moduleName == 'Reports' ? this.reportsList.length : this.subMod.length
      for (let i = 1; i <= subModulesLength; i++) {
        if (!this.hasSubModules[length + i]) {
          this.hasSubModules[length + i] = {};
        }
        this.hasSubModules[length + i].status = false
        this.hasSubModules[length + i].index = index
      }
    }
    else {
      if (!this.hasSubModules[length]) {
        this.hasSubModules[length] = {};
      }
      this.hasSubModules[length].status = false
    }
    this.accordionOpen[index] = true
  }

  isSubMenusExist(module, index): boolean {
    this.subMod = this.modules.filter((arr: any) => {
      return arr.pk == 'SUB#' + module.id;
    });
    if (this.subMod.length > 0) {
      this.subModulesList[index] = this.subMod.sort((a: any, b: any) => a.sequenceNumber - b.sequenceNumber);
      for (const item of this.subModulesList[index]) {
        if (!this.moduleList.some(module => module.moduleId === item.id)) {
          this.moduleList.push({
            moduleId: item.id,
            menu: item.moduleName,
            view: false,
            add: false,
            edit: false,
            delete: false
          });
        }
      }
      return true;
    }
    else {
      if (module.moduleName == 'Reports') {
        for (const item of this.reportsList) {
          this.moduleList.push({
            moduleId: item.id,
            menu: item.reportName,
            view: false,
            add: false,
            edit: false,
            delete: false
          });
        }
        return true;
      }
      this.subModulesList[index] = []
      return false;
    }
  }

  saveRole() {
    if (this.roleForm.valid) {
      let role = this.roleForm.controls.role.value
      let str = this.roleForm.controls.role.value.toLowerCase()
      str = str.replace(/\s{2,}/g, ' ');
      str = str.trim()
      let id = uuidv4();
      let data = {
        pk: 'ROLEM#',
        id: id,
        roleName: role,
        module: this.moduleList,
        searchKey: str,
        status: Status.ACTIVE
      }
      this.awsApi.client
        .graphql({
          query: mutation.createRoleManagement,
          variables: {
            input: {
              ...data
            }
          }
        })
        .then((response) => {
          this.awsApi.loading.next(false);
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Role created successfully!'
          });
          this.router.navigate(['/list-role']);
        })
        .catch((error) => {
          this.awsApi.loading.next(false);
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: Messages.SOMETHING_WENT_WRONG
          });
        });
    }
  }

  selectAllModules(action: any) {
    this.actions[action] = !this.actions[action]
    for (var i = 0; i < this.moduleList.length; i++) {
      this.moduleList[i][action] = this.actions[action];
      if (this.subModulesList[i]) {
        for (var j = 0; j < this.subModulesList[i].length; j++) {
          this.subModulesList[i][j][action] = this.actions[action]
        }
      }
    }
  }

  cancel() {
    this.router.navigate(['/list-role']);
  }
}