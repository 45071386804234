import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import { MessageService } from 'primeng/api';
import { uploadData } from 'aws-amplify/storage';
import amplifyconfig from 'amplifyconfiguration.json';
import * as mutation from 'graphql/mutations';
import { v4 as uuidv4 } from 'uuid';
import { ROUTES } from '@/redirect-routes';
import { Status } from 'API';
import { CommonService } from '@services/common/common.service';
import { Messages } from '@/Toaster-messages';

@Component({
    selector: 'app-add-delivery-partner',
    templateUrl: './add-delivery-partner.component.html',
    styleUrl: './add-delivery-partner.component.scss'
})
export class AddDeliveryPartnerComponent implements OnInit {
    access: any = {}
    addDeliveryPartnersForm: FormGroup;
    maxDate: any
    processingAdd = false

    constructor(private awsApi: ApiService,
        private _router: Router,
        private common: CommonService,
        private fb: FormBuilder,
        private messageService: MessageService
    ) {
        this.addDeliveryPartnersForm = this.fb.group({
            logo: [''],
            logoUrl: [''],
            companyName: ['', [Validators.required, Validators.pattern(/^(?!\s*$)(?=.*[a-zA-Z])[a-zA-Z0-9\s]+$/)]],
            displayName: ['', [Validators.required]],
            apiKey: [''],
            expiryDate: [''],
            contactPerson: [''],
            address: [''],
            email: ['', [Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
            phoneNumber: ['', [Validators.pattern('[- +0-9]{10,15}')]]
        });
    }

    ngOnInit(): void {
        this.getRoleManagement()
        this.maxDate = new Date()
    }

    async getRoleManagement() {
        this.awsApi.loading.next(true);
        try {
            await this.common.getPermissionsOfUser('Users', 'Delivery Partners');
            this.access = this.common.access;
            this.awsApi.loading.next(false);
        } catch (error) {
            this.awsApi.loading.next(false);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: Messages.SOMETHING_WENT_WRONG
            });
        }
    }

    async fileSave(event: any, type: any) {
        this.awsApi.loading.next(true);
        if (event.target instanceof HTMLInputElement && event.target.files) {
            let file = event.target.files[0];
            let isValid = await this.common.isValidFileType(event)
            if (!isValid) { return; }
            try {
                this.awsApi.loading.next(false);
                let imageUrl = await this.common.getS3Url(file)
                if (type == 'logo') {
                    this.addDeliveryPartnersForm.patchValue({
                        logoUrl: imageUrl
                    });
                }
            } catch (error) {
                this.awsApi.loading.next(false);
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: Messages.SOMETHING_WENT_WRONG
                });
            }
        }
    }

    async deleteUrl(type) {
        if (type == 'logoUrl') {
            this.addDeliveryPartnersForm.patchValue({
                logoUrl: ''
            });
            const fileInput = document.getElementById('image-upload') as HTMLInputElement;
            if (fileInput) {
                fileInput.value = '';
            }
        }
    }

    saveDeliveryPartners() {
        if (this.addDeliveryPartnersForm.valid) {
            this.processingAdd = true;
            let id = uuidv4();
            let str = this.addDeliveryPartnersForm.value.companyName.toLowerCase()
            str = str.replace(/\s{2,}/g, ' ');
            str = (str.trim()).replace(/\s+/g, '')
            let data = {
                pk: 'DELP#',
                id: id,
                logoUrl: this.addDeliveryPartnersForm.value.logoUrl,
                logo: this.addDeliveryPartnersForm.value.logoUrl,
                companyName: this.addDeliveryPartnersForm.value.companyName,
                displayName: this.addDeliveryPartnersForm.value.displayName,
                apiKey: this.addDeliveryPartnersForm.value.apiKey,
                apiKeyExpiryDate: this.addDeliveryPartnersForm.value.expiryDate ? new Date(this.addDeliveryPartnersForm.value.expiryDate).toISOString() : undefined,
                contactPerson: this.addDeliveryPartnersForm.value.contactPerson,
                address: this.addDeliveryPartnersForm.value.address,
                email: this.addDeliveryPartnersForm.value.email,
                phoneNumber: this.addDeliveryPartnersForm.value.phoneNumber,
                searchKey: str,
                status: Status.ACTIVE
            };
            this.awsApi.client
                .graphql({
                    query: mutation.createDeliveryPartner,
                    variables: {
                        input: {
                            ...data
                        }
                    }
                })
                .then((response) => {
                    this.awsApi.loading.next(false);
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Delivery-partner created successfully!'
                    });
                    this.processingAdd = false;
                    this._router.navigate([ROUTES.LIST_DELIVERY_PARTNER]);
                })
                .catch((error) => {
                    this.awsApi.loading.next(false);
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: Messages.SOMETHING_WENT_WRONG
                    });
                });
        } else {
            this.awsApi.loading.next(false);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Please fill all required details!'
            });
        }
    }

    getMinDate(): string {
        const today = new Date();
        return today.toISOString().split('T')[0];
    }

    preventE(event) {
        if (event.which === 101) {
            event.preventDefault();
        }
    }
}
