import { Component } from '@angular/core';
import { ApiService } from '@services/api/api.service';
import { MessageService } from 'primeng/api';
import * as query from 'graphql/queries';
import { StatusList, TaxTypeList } from '@/common-list';
import { FormBuilder, Validators } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import * as mutation from 'graphql/mutations';
import { productStatus, Status } from 'API';
import { Messages } from '@/Toaster-messages';
import { CommonService } from '@services/common/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tax-list',
  templateUrl: './tax-list.component.html',
  styleUrl: './tax-list.component.scss'
})
export class TaxListComponent {
  position: any;
  displayFilterModalToggle = false
  deleteModalToggle: any
  searchText: any
  searchKey: any
  access: any = {}
  add: any = false
  edit: any = false
  statusList = StatusList
  taxTypeList = TaxTypeList
  selectedTaxItem: any = []
  filterObject: any = {}
  taxList: any = []
  copyTaxList: any = []
  taxForm = this.formBuilder.group({
    taxCode: ['', [Validators.required]],
    type: ['', [Validators.required]]
  })

  constructor(private common: CommonService, private router: Router, private awsApi: ApiService, private formBuilder: FormBuilder, private messageService: MessageService) { }

  ngOnInit(): void {
    this.getRoleManagement()
    this.startingFunction()
  }

  async getRoleManagement() {
    this.awsApi.loading.next(true);
    try {
      await this.common.getPermissionsOfUser('Settings', 'Manage Tax');
      this.access = this.common.access;
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  async startingFunction() {
    this.awsApi.loading.next(true);
    this.GetTaxList()
  }

  GetTaxList() {
    this.awsApi.loading.next(true);
    this.awsApi.client
      .graphql({
        query: query.listTaxes,
        variables: {
          pk: 'TAX#',
          id: null,
          filter: this.filterObject,
          limit: 9999999,
          nextToken: null,
          sortDirection: null
        }
      }).then((response) => {
        this.taxList = response.data.listTaxes.items;
        this.copyTaxList = this.taxList
        this.awsApi.loading.next(false);
      }).catch((error) => {
        this.awsApi.loading.next(false);
      });
  }

  search(searchText) {
    this.searchKey = searchText.toLowerCase()
    this.searchKey = this.searchKey.replace(/\s{2,}/g, ' ');
    this.searchKey = (this.searchKey.trim()).replace(/\s+/g, '');

    this.filterObject['searchKey'] = { contains: this.searchKey }
    this.startingFunction()
  }

  showAdd() {
    this.taxForm.reset()
    this.add = true
    this.edit = false
  }

  showEdit(taxItem) {
    this.selectedTaxItem = taxItem
    this.taxForm.reset()
    this.edit = true
    this.add = false
    this.AutoFillEditForm()
  }

  AutoFillEditForm() {
    this.taxForm.patchValue({
      taxCode: this.selectedTaxItem.taxCode,
      type: this.selectedTaxItem.taxType
    })
  }

  addTax() {
    this.awsApi.loading.next(true);
    const newTaxCode = this.taxForm.controls.taxCode.value.toLowerCase().replace(/\s+/g, '');
    const newTaxType = this.taxForm.controls.type.value.toLowerCase();
    const isDuplicate = this.taxList.some((tax) => {
      let existingTaxCode = tax.taxCode.toLowerCase().replace(/\s+/g, '');
      let existingTaxType = tax.taxType.toLowerCase();
      return existingTaxCode === newTaxCode && existingTaxType === newTaxType;
    });
    if (isDuplicate) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.TAXTYPE_ALREADY_EXISTS
      });
      this.awsApi.loading.next(false);
      return;
    }
    let data = {
      pk: 'TAX#',
      id: uuidv4(),
      taxCode: this.taxForm.controls.taxCode.value,
      taxType: this.taxForm.controls.type.value,
      status: Status.ACTIVE,
      createdAt: new Date().toISOString(),
      searchKey: this.taxForm.controls.taxCode.value.replace(/\s/g, "").toLowerCase(),
      updatedAt: new Date().toISOString()
    };
    this.awsApi.client.graphql({
      query: mutation.createTax,
      variables: {
        input: {
          ...data
        }
      }
    }).then((response) => {
      this.SuccessMessage(Messages.TAX_ADDED)
      this.taxForm.reset()
      this.add = false
      this.startingFunction()
    }).catch((error) => {
      this.CatchError()
    });
  }

  CatchError() {
    this.awsApi.loading.next(false);
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: Messages.SOMETHING_WENT_WRONG
    });
  }

  SuccessMessage(succMessage: any) {
    this.awsApi.loading.next(false);
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: succMessage
    });
  }

  EditTax() {
    this.awsApi.loading.next(true);
    const newTaxCode = this.taxForm.controls.taxCode.value.toLowerCase().replace(/\s+/g, '');
    const newTaxType = this.taxForm.controls.type.value.toLowerCase();
    const isDuplicate = this.taxList.some((tax) => {
      let existingTaxCode = tax.taxCode.toLowerCase().replace(/\s+/g, '');
      let existingTaxType = tax.taxType.toLowerCase();
      return existingTaxCode === newTaxCode && existingTaxType === newTaxType;
    });
    if (isDuplicate) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.TAXTYPE_ALREADY_EXISTS
      });
      this.awsApi.loading.next(false);
      return;
    }
    let data = {
      pk: 'TAX#',
      id: this.selectedTaxItem.id,
      taxCode: this.taxForm.controls.taxCode.value,
      taxType: this.taxForm.controls.type.value,
      createdAt: this.selectedTaxItem.createdAt,
      searchKey: this.taxForm.controls.taxCode.value.replace(/\s/g, "").toLowerCase(),
      updatedAt: new Date().toISOString()
    };
    this.awsApi.client.graphql({
      query: mutation.updateTax,
      variables: {
        input: {
          ...data
        }
      }
    }).then((response) => {
      this.SuccessMessage(Messages.TAX_EDITED)
      this.taxForm.reset()
      this.edit = false
      this.startingFunction()
    }).catch((error) => {
      this.CatchError()
    });
  }

  editStatus(taxId: any, type: any) {
    let data = {
      pk: 'TAX#',
      id: taxId,
      status: type == 'Deactivate' ? Status.INACTIVE : Status.ACTIVE,
      updatedAt: new Date().toISOString()
    }
    this.awsApi.client
      .graphql({
        query: mutation.updateTax,
        variables: {
          input: {
            ...data
          }
        }
      }).then((response) => {
        this.awsApi.loading.next(false);
        this.ngOnInit();
      }).catch((error) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      })
  }

  async DeleteTax(taxItem) {
    this.selectedTaxItem = taxItem
    let filter = {}
    filter['status'] = { ne: productStatus.deleted }
    filter['searchKey'] = { contains: this.selectedTaxItem.id }
    let products = await this.common.getProducts(filter, ['pk', 'id'], 'Tax Delete')
    if (products.length > 0) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.TAX_CANNOT_BE_DELETED
      });
      return;
    }

    this.awsApi.loading.next(true);
    let data = {
      pk: 'TAX#',
      id: this.selectedTaxItem.id
    };
    this.awsApi.client.graphql({
      query: mutation.deleteTax,
      variables: {
        input: {
          ...data
        }
      }
    }).then((response) => {
      this.SuccessMessage(Messages.TAX_DELETED)
      this.taxForm.reset()
      this.startingFunction()
    }).catch((error) => {
      this.CatchError()
    });
  }

  showDeleteConfirmationModalDialog(item: any) {
    this.deleteModalToggle = true;
  }

  capitalizeFirstLetter(controlName: string) {
    const name = this.taxForm.controls[controlName];
    const capitalizeString = (input: string): string => {
      const cleanInput = input.replace(/[^a-zA-Z\s]/g, '');
      const trimmedInput = cleanInput.trim().replace(/\s+/g, ' ');
      return trimmedInput.charAt(0).toUpperCase() + trimmedInput.slice(1);
    };
    if (name && name.value) {
      const capitalized = capitalizeString(name.value);
      name.setValue(capitalized);
    }
  }

  redirectToTaxList() {
    this.taxForm.reset()
    this.edit = false
    this.add = false
  }
}