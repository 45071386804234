import { formatDate } from '@angular/common';
import { ApiService } from '@services/api/api.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import * as _ from 'underscore';
import { Component } from '@angular/core';
import { ROUTES } from '@/redirect-routes';
import * as mutation from 'graphql/mutations';
import { MessageService } from 'primeng/api';
import { Messages } from '@/Toaster-messages';
import { CommonService } from '@services/common/common.service';

@Component({
  selector: 'app-product-reviews-list',
  templateUrl: './product-reviews-list.component.html',
  styleUrl: './product-reviews-list.component.scss'
})
export class ProductReviewsListComponent {
  value: number = 5;
  position: any;
  displayFilterModalToggle = false
  deleteModalToggle: any
  filterObject: any = {}
  reviewsList: any = []
  copyReviewsList: any = []
  selectedReview: any = []
  searchKey: any
  searchText: any = ''
  status: any = ''
  rating: any = ''
  date: any = ''
  access: any = {}
  today: any = new Date();
  filterApplied: boolean = false
  thirtyDaysAgo = new Date((new Date()).setDate(this.today.getDate() - 30))
  filterForm = this.formBuilder.group({
    rating: [''],
    date: []
  })

  constructor(private common: CommonService, private awsApi: ApiService, private router: Router, private messageService: MessageService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.position = 'right';
    this.awsApi.loading.next(true);
    this.filterForm.patchValue({ date: [this.thirtyDaysAgo, this.today] });
    this.FilterDate()
    this.startingFunction()
  }

  async startingFunction() {
    try {
      await this.common.getPermissionsOfUser('Products', 'Product Reviews');
      this.access = this.common.access;
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
    this.awsApi.loading.next(true);
    this.getReviews()
  }

  getReviews() {
    this.awsApi.loading.next(true);
    let fieldsToFetch = ['id', 'pk', 'productImage', 'productName', 'title', 'removeAbusiveComment', 'reviewComment', 'userName', 'email', 'reviewStatus', 'createdAt', 'repliedOnDate', 'overallRating']

    this.common.getProductReviews(this.filterObject, fieldsToFetch).then((response) => {
      this.reviewsList = response.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      this.awsApi.loading.next(false);
    }).catch((error) => {
      this.awsApi.loading.next(false);
    });
  }

  search(searchText) {
    if (searchText != '') {
      this.searchKey = searchText.toLowerCase()
      this.searchKey = this.searchKey.replace(/\s+/g, '');
      this.searchKey = (this.searchKey.trim()).replace(/\s+/g, '');

      this.filterObject['searchKey'] = { contains: this.searchKey }
      this.startingFunction()
    }
    else {
      delete this.filterObject['searchKey']
      this.startingFunction()
    }
  }

  applyFilter() {
    this.ClearFilterObject()
    this.FilterRating()
    this.FilterDate()
    this.displayFilterModalToggle = false;
    this.startingFunction()
  }

  ClearFilterObject() {
    delete this.filterObject['overallRating']
    delete this.filterObject['createdAt']
    this.filterApplied = false
  }

  FilterRating() {
    if (this.filterForm.controls.rating.value?.length > 0) {
      this.filterApplied = true
      this.filterObject['overallRating'] = { eq: this.filterForm.controls.rating.value }
    }
  }

  FilterDate() {
    if (this.filterForm.controls.date.value?.length == 2) {
      this.filterApplied = true
      if (this.filterForm.controls.date.value[0] != null) {
        var selectedDate1 = new Date(this.filterForm.controls.date.value[0])
      }
      if (this.filterForm.controls.date.value[1] != null) {
        let selectedDate2 = new Date(this.filterForm.controls.date.value[1])
        var selectedDate2Formatted = selectedDate2.setDate(selectedDate2.getDate() + 1)
        this.filterObject['createdAt'] = { ge: formatDate(selectedDate1, 'yyyy-MM-dd', 'en-US'), le: formatDate(selectedDate2Formatted, 'yyyy-MM-dd', 'en-US') }
      } if (this.filterForm.controls.date.value[1] == null) {
        this.filterObject['createdAt'] = { contains: formatDate(selectedDate1, 'yyyy-MM-dd', 'en-US') }
      }
    }
  }

  deleteProductReview() {
    this.awsApi.loading.next(true);
    let inputData = {
      pk: this.selectedReview.pk,
      id: this.selectedReview.id
    }
    this.awsApi.client
      .graphql({
        query: mutation.deleteProductReview,
        variables: {
          input: {
            ...inputData
          }
        }
      }).then((response) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: Messages.PRODUCT_REVIEWS_DELETED
        });
        this.startingFunction()
      }).catch((error) => {
        this.awsApi.loading.next(false);

        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      });
    this.deleteModalToggle = false;
  }

  redirectToCustomerReviewPage(review: any) {
    this.router.navigate([ROUTES.CUSTOMER_REVIEW], { queryParams: { reviewId: review.id } });
  }

  showFilterModalDialog(position: string) {
    this.position = position;
    this.displayFilterModalToggle = true;
    this.getReviews()
  }
  closeFilterModalDialog() {
    this.displayFilterModalToggle = false;
    this.ClearFilterObject()
    this.filterForm.reset()
    this.startingFunction()
  }

  closeFilter() {
    this.displayFilterModalToggle = false;
  }
  showDeleteConfirmationModalDialog(item: any) {
    this.deleteModalToggle = true;
    this.selectedReview = item
  }

  isAbusive(comment) {
    const abusiveWords = ["badword1", "badword2", "@##&*****!@#%$^&***"];
    const hasAbusiveWords = abusiveWords.some(word => comment.includes(word));
    const hasAsterisksSequence = /\*{3,}/.test(comment);
    return hasAbusiveWords || hasAsterisksSequence;
  }
}