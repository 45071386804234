<section id="brandEditSec">
    <div class="container-fluid">
        <form [formGroup]="editBrandForm">
            <div class="row mb-3">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card mb-1 heading-card">
                        <div class="card-body">
                            <div class="d-flex justify-content-start align-items-center">
                                <i class="fas fa-chevron-left" [routerLink]="['/list-brand']"></i>
                                <h6>Edit Brand</h6>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-1 brand-img-card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 mb-2">
                                    <div>
                                        <label>Brand Logo
                                            <span class="mandatory">*</span></label>
                                        <form id="brand-logo-upload-form" class="brand-logo-uploader">
                                            <input id="brand-logo-upload" type="file" name="fileUpload" accept="image/*"
                                                (change)="
                                                    fileSave($event, 'brand')
                                                " />
                                            @if (
                                            editBrandForm.get(
                                            'brandLogo'
                                            )?.value == ''
                                            ) {
                                            <label for="brand-logo-upload" id="file-drag">
                                                <div id="start">
                                                    <i class="far fa-image"></i>
                                                    <div class="upload-caption">
                                                        <h5>
                                                            Click here to
                                                            upload brand
                                                            logo
                                                        </h5>
                                                    </div>
                                                </div>
                                            </label>
                                            } @else {
                                            <div id="after-upload">
                                                <i class="fas fa-trash-alt pt-1" (click)="deleteUrl('brandLogo')"></i>
                                                <img id="file-image" [src]="
                                                                editBrandForm.get(
                                                                    'brandLogo'
                                                                )?.value
                                                            " />
                                            </div>
                                            }
                                        </form>
                                    </div>
                                </div>
                                <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-8 col-sm-12 col-xs-12 mb-2">
                                    <div class="row">
                                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">
                                            <label>Brand Name
                                                <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control" placeholder="Enter brand Name"
                                                formControlName="brandName" />
                                            <span class="text-danger"
                                                *ngIf="(editBrandForm.controls.brandName.touched) && !editBrandForm.controls.brandName?.valid">
                                                <div *ngIf="editBrandForm.controls.brandName.errors['required']">
                                                    Brand name is required.
                                                </div>
                                                <div *ngIf="editBrandForm.controls.brandName.errors['pattern']">
                                                    Invalid brand name.
                                                </div>
                                            </span>
                                        </div>
                                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">
                                            <label>Manufacturer Name</label>
                                            <input type="text" class="form-control"
                                                placeholder="Enter Manufacturer Name"
                                                formControlName="manufacturerName" />
                                        </div>

                                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">
                                            <label>Country of Origin
                                                <span class="mandatory">*</span></label>
                                            <select class="form-select form-control" aria-label="Default select example"
                                                formControlName="countryOfOrigin">
                                                <option selected [ngValue]="null" disabled>
                                                    Choose Country
                                                </option>
                                                <option
                                                    *ngFor="let countryItem of countryList;let countryItemIndex = index"
                                                    [ngValue]="countryItem.name">
                                                    {{ countryItem.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">
                                            <label>Brand URL</label>
                                            <input type="text" class="form-control" placeholder="Enter Brand URL"
                                                formControlName="brandURL" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                    <label>Description</label>
                                    <ckeditor [editor]="Editor" formControlName="content"
                                        data="<h1>Hello from CKEditor 5!</h1>" (ready)="onReady($event)"
                                        (change)="onChange($event)" formControlName="description">
                                    </ckeditor>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="d-flex justify-content-end gap-2">
                        <button type="button" class="btn cancel-btn" (click)="redirectToListingPage()">
                            Cancel
                        </button>
                        <button type="button" class="btn save-btn" [disabled]="!(access['edit'] && editBrandForm.valid)"
                            (click)="updateBrand()">
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>