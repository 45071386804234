import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function objectRequiredValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value || Object.keys(value).length === 0) {
      return {objectRequired: true};
    }
    return null;
  };
}
