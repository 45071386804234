<section id="returnRefundListSec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 my-auto">
                <div class="card m-2">
                    <div class="card-body">
                        <!-- return-refund-list-heading-row start -->
                        <div class="row return-refund-list-heading-row">
                            <div class="col-xxl-6 col-xl-6 col-lg-4 col-md-4 col-sm-12 col-xs-12 my-auto">
                                <div class="text-center text-md-left mt-2 mt-md-0">
                                    <h2>Return and Refund</h2>
                                    <p class="mb-2 mb-md-0" *ngIf="copyReturnItemsList.length>1">
                                        {{copyReturnItemsList.length}} Results
                                        Found</p>
                                    <p class="mb-2 mb-md-0" *ngIf="copyReturnItemsList.length == 1">1 Result Found</p>
                                </div>
                            </div>
                            <div class="col-xxl-6 col-xl-6 col-lg-8 col-md-8 col-sm-12 col-xs-12 my-auto">
                                <div
                                    class="has-search-d-flex justify-content-center justify-content-md-end align-items-start align-items-md-center flex-nowrap flex-md-wrap">
                                    <app-common-search (onEnter)="search($event)"
                                        class="mb-2 mb-md-0"></app-common-search>
                                    <div *ngIf="!filterApplied">
                                        <button *ngIf="statusIndex>10" type="button"
                                            class="btn filter-btn d-none d-md-block"
                                            (click)="showFilterModalDialog('right')">
                                            <span class="material-symbols-outlined me-1">
                                                filter_alt
                                            </span>
                                            Filter
                                        </button>
                                        <button *ngIf="statusIndex>10" type="button"
                                            class="btn filter-btn d-block d-md-none"
                                            (click)="showFilterModalDialog('right')">
                                            <span class="material-symbols-outlined">
                                                filter_alt
                                            </span>
                                        </button>
                                    </div>
                                    <div *ngIf="filterApplied">
                                        <button type="button" class="btn after-filter-btn me-3 d-none d-md-block"
                                            (click)="showFilterModalDialog('right')">
                                            <i class="fas fa-filter"></i>
                                            Filter
                                        </button>
                                        <button type="button" class="btn after-filter-btn me-3 d-block d-md-none"
                                            (click)="showFilterModalDialog('right')">
                                            <i class="fas fa-filter"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- return-refund-list-heading-row end -->
                <div class="card mb-1 ms-2 me-2 table-card scroll-container" (window:scroll)="onScroll()"
                    *ngFor="let returnItem of returnDataList;let i=index">
                    <div class="card-body">
                        <!-- return-refund-list-table-row start -->
                        <div class="row return-refund-list-table-row" *ngIf="returnItem?.returnRefundActivity?.status">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="content mb-2" (click)="redirectToReturnItemDetails(returnItem)">
                                    <div class="flex-column">
                                        <label>Return Ref</label>
                                        <h6>{{returnItem.returnRefundActivity.returnReference}}
                                        </h6>
                                    </div>
                                    <div class="flex-column">
                                        <label>Order Ref</label>
                                        <h6>{{returnItem.pk.split('#')[1]}}</h6>
                                    </div>
                                    <div class="flex-column">
                                        <label>Customer Name</label>
                                        <h6>{{returnItem.userName.split('undefined')[0]}}</h6>
                                    </div>
                                    <div class="flex-column">
                                        <label>Ordered On</label>
                                        <h6>{{returnItem.createdAt | date:'dd/MM/yyyy' : 'UTC' }}</h6>
                                        <h6 *ngIf="!returnItem.createdAt">N/A</h6>
                                    </div>
                                    <div class="flex-column">
                                        <label>Return Date</label>
                                        <h6 *ngIf="returnItem.returnRefundActivity.createdAt">{{
                                            returnItem.returnRefundActivity.createdAt |
                                            date:'dd/MM/yyyy' : 'UTC' }}</h6>
                                        <h6 *ngIf="!returnItem.returnRefundActivity.createdAt ">
                                            N/A</h6>
                                    </div>
                                    <div class="flex-column">
                                        <label>Status</label>
                                        <h6 [ngSwitch]="returnItem.returnRefundActivity.status">
                                            <span *ngSwitchCase="'Requested'" class="requested">Requested</span>
                                            <span *ngSwitchCase="'Accepted'" class="accepted">Accepted</span>
                                            <span *ngSwitchCase="'Shipped'" class="shipped">Item Picked</span>
                                            <span *ngSwitchCase="'Received'" class="received">Received</span>
                                            <span *ngSwitchCase="'RefundApproved'" class="refundApproved">Refund
                                                Approved</span>
                                            <span *ngSwitchCase="'RefundRejected'" class="refundRejected">Refund
                                                Rejected</span>
                                            <span *ngSwitchCase="'Cancelled'" class="refundRejected">Cancelled</span>
                                            <span *ngSwitchCase="'Rejected'" class="refundRejected">Refund
                                                Rejected</span>
                                            <span *ngSwitchDefault>N/A</span>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- return-refund-list-table-row end -->
                    </div>
                </div>
                <div *ngIf="loading && returnDataList.length>10">Loading...</div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3"
                    *ngIf="copyReturnItemsList.length==0 && page === '1' && !loader">
                    <app-no-result-found></app-no-result-found>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- displayFilterModalToggle start -->
<p-dialog [draggable]="false" [(visible)]="displayFilterModalToggle" [position]="position" [modal]="true"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false" [showHeader]="false"
    [style]="{width: '34vw', minHeight: '100%', maxHeight: '100%', margin: '0'}"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}">
    <div id="displayFilterModalToggle">
        <div class="modal-header">
            <h5 class="modal-title">Filter</h5>
            <i class="fas fa-times" (click)="displayFilterModalToggle = false"></i>
        </div>
        <div class="body mt-3" [formGroup]="filterForm">
            <div class="row">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Status</label>
                    <p-multiSelect display="chip" formControlName="status" [options]="returnItemStatusList"
                        optionLabel="valueToShow" [filter]="true" [defaultLabel]="'Choose Status'">
                    </p-multiSelect>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Return Date</label>
                    <p-calendar [iconDisplay]="'input'" formControlName="returnDate" [showIcon]="true"
                        inputId="icondisplay" selectionMode="range" placeholder="From-To"></p-calendar>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="d-flex justify-content-end gap-2">
            <p-button label="Cancel" styleClass="filter-cancel-btn" (click)="cancelFilter()"></p-button>
            <p-button label="Apply" styleClass="filter-apply-btn" (click)="applyFilter()"></p-button>
        </div>
    </ng-template>
</p-dialog>
<!-- displayFilterModalToggle end -->
<!-- deleteModalToggle start -->
<p-dialog [showHeader]="false" [(visible)]="deleteModalToggle" [style]="{width: '25vw'}" [baseZIndex]="10000"
    [modal]="true">
    <div class="body mt-3" id="deleteModalToggle">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="delete-image">
                    <img src="../../../../assets/img/delete.png" />
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="confirm-msg">
                    <h6>Are You Sure?</h6>
                    <p>You will not be able to recover this product!</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="confirm-btns">
                    <button type="button" class="btn cancel-btn" (click)="deleteModalToggle = false">
                        Cancel
                    </button>
                    <button type="button" class="btn delete-confirm-btn">
                        Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</p-dialog>
<!-- {/* // deleteModalToggle end */} -->