<section id="categoryAddSec">
  <div class="container-fluid">
    <form [formGroup]="addCountryForm" (ngSubmit)="onSubmit()">
      <div class="row mb-3">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="card mb-2 heading-card">
            <div class="card-body">
              <div class="d-flex justify-content-start align-items-center">
                <i class="fas fa-chevron-left" (click)="redirectToCountryListPage()"></i>
                <h6>Add New Country</h6>
              </div>
            </div>
          </div>
          <div class="card mb-2 category-img-card">
            <div class="card-body">
              <div class="row">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">
                  <label>Name <span class="mandatory">*</span></label>
                  <input type="text" class="form-control" placeholder="Enter country name" formControlName="name" />
                  <span class="text-danger"
                    *ngIf="(addCountryForm.controls.name.touched) && !addCountryForm.controls.name?.valid">
                    <div *ngIf="addCountryForm.controls.name.errors['required']">
                      Name is required.
                    </div>
                    <div *ngIf="addCountryForm.controls.name.errors['pattern']">
                      Invalid name.
                    </div>
                  </span>
                </div>

                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">
                  <label>Short Name <span class="mandatory">*</span></label>
                  <input type="text" class="form-control" placeholder="Enter country code"
                    formControlName="shortName" />
                  <span class="text-danger"
                    *ngIf="(addCountryForm.controls.shortName.touched) && !addCountryForm.controls.shortName?.valid">
                    <div *ngIf="addCountryForm.controls.shortName.errors['required']">
                      Short name is required.
                    </div>
                    <div *ngIf="addCountryForm.controls.shortName.errors['pattern']">
                      Invalid short name.
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="d-flex justify-content-end gap-2">
            <button type="button" class="btn cancel-btn" (click)="redirectToCountryListPage()">Cancel</button>
            <button type="submit" [disabled]="!addCountryForm.valid && !access['add']" class="btn save-btn">
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>