import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { fetchAuthSession, updateUserAttributes } from 'aws-amplify/auth';
import { MessageService } from 'primeng/api';
import { updatePassword } from 'aws-amplify/auth';
import { AppService } from '@services/app.service';
import { ApiService } from '@services/api/api.service';
import { UserRolesList } from '@/common-list';
import { UserAuthService } from '@services/user-auth/user-auth.service';
import * as mutation from 'graphql/mutations';
import { Messages } from '@/Toaster-messages';
import { CommonService } from '@services/common/common.service';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
    userDetails: any
    getUserDetails: any
    isEditMode: boolean = false;
    showPersonalButton: boolean = true;
    showPasswordButton: boolean = false;
    userRolesList = UserRolesList
    profileImageUrl: any = ''
    personalInfoForm = this.fb.group({
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        phoneNumber: ['', [Validators.required]],
        profileImage: ['']
    });

    changePasswordForm = this.fb.group({
        oldPassword: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]],
        newPassword: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]],
        confirmPassword: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]]
    });

    constructor(
        private _router: Router,
        private fb: FormBuilder,
        private common: CommonService,
        private messageService: MessageService, private auth: UserAuthService,
        private appService: AppService, private awsApi: ApiService) { }

    ngOnInit(): void {
        (async () => {
            const { idToken } = (await fetchAuthSession()).tokens ?? {};
            this.userDetails = idToken.payload;
            this.startingFunction();
        })();
    }

    async startingFunction() {
        this.awsApi.loading.next(true);
        try {
            let userDetails = await this.common.getUserDetails(this.userDetails['email'], this.userDetails['custom:role'])
            if (userDetails.length > 0) {
                this.getUserDetails = userDetails[0]
                this.getUser()
                this.awsApi.loading.next(false);
            }
        }
        catch (error) {
            this.awsApi.loading.next(false);
        }
    }

    getUser() {
        this.personalInfoForm.patchValue({
            firstName: this.getUserDetails?.firstName,
            lastName: this.getUserDetails?.lastName,
            phoneNumber: this.getUserDetails?.phoneNumber,
            profileImage: this.getUserDetails?.profileImageUrl
        });
    }

    toggleEditMode() {
        this.isEditMode = this.isEditMode ? false : true;
    }

    handleUpdatePassword() {
        const oldPassword = this.changePasswordForm.controls.oldPassword.value
        const newPassword = this.changePasswordForm.controls.newPassword.value
        const confirmPassword = this.changePasswordForm.controls.confirmPassword.value
        if (oldPassword === newPassword) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: Messages.PASSWORD_SHOULD_NOT_BE_SAME });
        }
        else if (newPassword != confirmPassword) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: Messages.PASSWORD_SHOULD_BE_SAME });
        }
        else {
            this.awsApi.loading.next(true);
            (async () => {
                try {
                    await updatePassword({ oldPassword, newPassword });
                    this.awsApi.loading.next(false);
                    this.messageService.add({ severity: 'success', summary: 'Success', detail: Messages.PASSWORD_CHANGED_SUCCESS });
                    this.changePasswordForm.reset()
                    this.appService.logout()
                }
                catch (error) {
                    this.awsApi.loading.next(false);
                    if (error.name === 'LimitExceededException') {
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: Messages.TRY_AGAIN });
                    } else {
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: Messages.INVALID_CURRENT_PASSWORD });
                    }
                }
            })();
        }
    }

    logout() {
        this.appService.logout();
    }

    toggleButtons(tabId: string) {
        if (tabId === 'v-pills-personal-info') {
            this.showPersonalButton = true;
            this.showPasswordButton = false;
        } else if (tabId === 'v-pills-change-password') {
            this.showPersonalButton = false;
            this.showPasswordButton = true;
        } else {
            this.showPersonalButton = false;
            this.showPasswordButton = false;
        }
    }

    async deleteUrl(type) {
        if (type == 'profileImage') {
            this.personalInfoForm.patchValue({
                profileImage: ''
            });
            const fileInput = document.getElementById('profile-logo-upload') as HTMLInputElement;
            if (fileInput) {
                fileInput.value = '';
            }
        }
        this.isEditMode = true
    }

    async fileSave(event: any) {
        if (event.target instanceof HTMLInputElement && event.target.files) {
            let file = event.target.files[0];
            let isValid = await this.common.isValidFileType(event)
            if (!isValid) { return; }
            try {
                let imageUrl = await this.common.getS3Url(file)
                this.personalInfoForm.patchValue({
                    profileImage: imageUrl
                });
                this.isEditMode = true
            } catch (error) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: Messages.SOMETHING_WENT_WRONG
                });
            }
        }
    }

    async updatePersonalInfo() {
        this.awsApi.loading.next(true);
        let data = {
            pk: this.getUserDetails.pk,
            id: this.getUserDetails.id,
            firstName: this.personalInfoForm.controls.firstName.value,
            lastName: this.personalInfoForm.controls.lastName.value,
            phoneNumber: this.personalInfoForm.controls.phoneNumber.value,
            profileImageUrl: this.personalInfoForm.controls.profileImage.value
        };
        try {
            await updateUserAttributes({
                userAttributes: {
                    'phone_number': data['phoneNumber'],
                    'custom:firstName': data['firstName'],
                    'custom:lastName': data['lastName']
                }
            })
            this.awsApi.client
                .graphql({
                    query: mutation.updateUser,
                    variables: {
                        input: {
                            ...data
                        }
                    }
                })
                .then((response) => {
                    this.common.setProfileImage(this.personalInfoForm.controls.profileImage.value)
                    this.isEditMode = false
                    this.awsApi.loading.next(false);
                    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Profile updated successfully' });
                })
                .catch((error) => {
                    this.awsApi.loading.next(false);
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: Messages.SOMETHING_WENT_WRONG
                    });
                });
            this.awsApi.loading.next(false);
        }
        catch (error) {
            this.awsApi.loading.next(false);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'something went wrong!' });
        }
    }

    redirectToHomePage() {
        this._router.navigate(['']);
    }
}
