import { Component, Inject } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
@Component({
  selector: 'app-image-view-popup-modal',
  templateUrl: './image-view-popup-modal.component.html',
  styleUrls: ['./image-view-popup-modal.component.scss'] // Note: Change from styleUrl to styleUrls
})
export class ImageViewPopupModalComponent {
  image: any; // Define a property to hold the image data

  constructor(public config: DynamicDialogConfig) {
    this.image = this.config.data.image;
  }
}
