<div *ngIf="loader == true">
    <app-spinner></app-spinner>
</div>
<section id="backButtonSec">
    <div class="container-fluid">
        <div class="row pt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="text-start">
                    <button type="button" class="btn back-btn" (click)="redirectToForgotPasswordPage()">
                        <i class="fas fa-angle-left me-2"></i> Back
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="confirmResetPasswordSec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 my-auto">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <form [formGroup]="resetPasswordForm">
                                    <div class="contact">
                                        <div class="text-center">
                                            <img src="../../../../assets/img/forgotPassword.png" />
                                            <h3 class="mt-3">
                                                Create New Password
                                            </h3>
                                            <p>Please set a new password to login your account</p>
                                        </div>
                                        <div>
                                            <label>New Password <span class="mandatory">*</span></label>
                                            <input autofocus type="text" class="form-control"
                                                placeholder="Enter New Password" formControlName="newPassword" />
                                            <span class="text-danger"
                                                *ngIf="(resetPasswordForm.controls.newPassword.touched) && !resetPasswordForm.controls.newPassword?.valid">
                                                <div *ngIf="resetPasswordForm.controls.newPassword.errors['required']">
                                                    New password is required.
                                                </div>
                                                <div *ngIf="resetPasswordForm.controls.newPassword.errors['pattern']">
                                                    Enter a valid new password
                                                </div>
                                            </span>
                                        </div>
                                        <div>
                                            <label>Confirm Password <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control" placeholder="Enter Confirm Password"
                                                formControlName="confirmPassword" />
                                            <span class="text-danger"
                                                *ngIf="(resetPasswordForm.controls.confirmPassword.touched) && !resetPasswordForm.controls.confirmPassword?.valid">
                                                <div
                                                    *ngIf="resetPasswordForm.controls.confirmPassword.errors['required']">
                                                    Confirm password is required.
                                                </div>
                                                <div
                                                    *ngIf="resetPasswordForm.controls.confirmPassword.errors['pattern']">
                                                    Enter a valid confirm password
                                                </div>
                                            </span>
                                        </div>
                                        <div class="mt-3">
                                            <button class="btn send-btn" type="button"
                                                (click)="handleConfirmResetPassword()">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>