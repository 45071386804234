import { Component, input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import * as mutation from 'graphql/mutations';
import { v4 as uuidv4 } from 'uuid';
import { ApiService } from '@services/api/api.service';
import { Coverage, Status } from 'API';
import { Messages } from '@/Toaster-messages';
import * as query from 'graphql/queries';
import { CommonService } from '@services/common/common.service';
import { exit } from 'process';
import { CoverageList, ShippingTypeList } from '@/common-list';

@Component({
  selector: 'app-shipping-add',
  templateUrl: './shipping-add.component.html',
  styleUrl: './shipping-add.component.scss'
})
export class ShippingAddComponent {
  zipCodes = []
  countryList: any = []
  access: any = {}
  valid: any
  selectedCoverage: any
  currency: any
  shippingType: any
  shippingTypeList = ShippingTypeList
  coverageList: any = CoverageList
  shippingAdded = false
  defaultChargeType: any = null
  inputData: any = {}
  shippingForm = this.fb.group({
    city: ['', [Validators.pattern(/^[a-zA-Z ]*$/)]],
    country: [],
    state: ['', [Validators.pattern(/^[a-zA-Z ]*$/)]],
    deliveryFee: ['', [Validators.required]],
    coverage: [],
    zipCodeValues: [''],
    latitude: [''],
    longitude: [''],
    radius: [''],
    chargeType: ['']
  });

  constructor(private common: CommonService, private router: Router, private awsApi: ApiService, private fb: FormBuilder, private messageService: MessageService) { }
  async ngOnInit() {
    this.getRoleManagement()
    this.currency = await this.common.getCurrencyOfCompany()
    this.getAllCountry().then((result: any) => {
      this.countryList = result.data.listCountries.items;
      this.selectedCoverage = 'Zipcode'
      this.zipCodes = []
    })
  }

  async getRoleManagement() {
    this.awsApi.loading.next(true);
    try {
      await this.common.getPermissionsOfUser('Settings', 'Manage Shipping');
      this.access = this.common.access;
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  getAllCountry() {
    return this.awsApi.client.graphql({
      query: query.listCountries,
      variables: {
        pk: null,
        slug: null,
        filter: null,
        limit: 9999,
        nextToken: null,
        sortDirection: null
      }
    });
  }

  async isShippingAlreadyAdded(): Promise<boolean> {
    let shippingRes
    let filter = {
      'pk': { eq: 'SHIPPING#' },
      'countryId': { eq: this.inputData.countryId },
    }
    try {
      let shippingResponse = await this.getShippingDetails(filter);
      let formatValue = value => value ? value.replace(/\s/g, "").toLowerCase().trim() : '';
      if (shippingResponse.length > 0) {
        shippingRes = shippingResponse.filter((item) =>
          formatValue(item.city) === formatValue(this.inputData.city) &&
          formatValue(item.state) === formatValue(this.inputData.state) &&
          formatValue(item.countryName) === formatValue(this.inputData.countryName) &&
          formatValue(item.coverage) === formatValue(this.inputData.coverage)
        )
      }
      return shippingRes.length > 0;
    } catch (error) { }
  }

  async getShippingDetails(filterObject: any = {}) {
    let items = [];
    let nextToken = null;
    do {
      const response = await this.awsApi.client.graphql({
        query: query.listShippings,
        variables: {
          filter: filterObject,
          limit: 650,
          nextToken: nextToken,
          sortDirection: null
        }
      })
      items = items.concat(response.data.listShippings.items);
      nextToken = response.data.listShippings.nextToken;
    } while (nextToken !== null)
    return items
  }

  async addShipping() {
    if (!this.shippingAdded) {
      this.valid = this.shippingForm.valid
      if (this.valid) {
        if (this.shippingType == this.shippingTypeList[0]) {
          this.valid = this.shippingForm.controls.country.value && this.shippingForm.controls.city.value
        } else {
          this.valid = this.shippingForm.controls.country.value
        }
        if (this.valid) {
          this.awsApi.loading.next(true);
          this.inputData = {
            pk: 'SHIPPING#',
            id: uuidv4(),
            city: this.shippingForm.controls.city?.value ? this.shippingForm.controls.city.value : null,
            countryId: this.shippingForm.controls.country.value.id,
            countryName: this.shippingForm.controls.country.value.name,
            state: this.shippingForm.controls.state?.value ? this.shippingForm.controls.state.value : null,
            deliveryCharge: parseFloat(this.shippingForm.controls.deliveryFee.value),
            coverage: this.zipCodes.length > 0 ? Coverage.Zipcode : null,
            zipCodes: this.zipCodes.length == 0 ? null : this.zipCodes,
            latitude: this.shippingForm.controls.latitude?.value ? this.shippingForm.controls.latitude.value : null,
            longitude: this.shippingForm.controls.longitude?.value ? this.shippingForm.controls.longitude.value : null,
            radius: this.shippingForm.controls.radius?.value ? this.shippingForm.controls.radius.value : null,
            status: Status.ACTIVE,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            createdBy: localStorage.getItem('userName') + '#' + localStorage.getItem('role'),
            modifiedBy: localStorage.getItem('userName') + '#' + localStorage.getItem('role')
          }
          this.inputData['searchKey'] = (((
            (this.inputData.city || '') + '#' +
            (this.inputData.state || '') + '#' +
            (this.inputData.countryName || '') + '#' +
            (this.inputData.coverage || '')).replace(/\s/g, "")).toLowerCase()).trim()
          if (!await this.isShippingAlreadyAdded()) {
            this.awsApi.loading.next(false);
            this.awsApi.client
              .graphql({
                query: mutation.createShipping,
                variables: {
                  input: {
                    ...this.inputData
                  }
                }
              }).then((response) => {
                this.shippingAdded = true
                this.awsApi.loading.next(false);
                this.messageService.add({
                  severity: 'success',
                  summary: 'Success',
                  detail: Messages.SHIPPING_ADDED
                });
                this.router.navigate([`list-shipping`]);
              })
          } else {
            this.shippingAdded = false
            this.awsApi.loading.next(false);
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: Messages.SHIPPING_CHARGE_ALREADY_ADDED
            });
          }
        }
        else {
          this.shippingAdded = false
          this.awsApi.loading.next(false);
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: Messages.PLEASE_FILL_REQUIRED_FIELDS
          });
        }
      }
      else {
        this.shippingAdded = false
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.PLEASE_FILL_REQUIRED_FIELDS
        });
      }
    }
  }

  onSelectShippingType() {
    this.shippingType = this.shippingForm.controls.chargeType.value ? this.shippingForm.controls.chargeType.value : null
    Object.keys(this.shippingForm.controls).forEach(key => {
      if (key !== 'chargeType') {
        this.shippingForm.controls[key].reset();
      }
    });
  }

  onSelectCoverage() {
    this.selectedCoverage = this.shippingForm.controls.coverage.value ? this.shippingForm.controls.coverage.value.value : null
    this.zipCodes = []
    this.shippingForm.controls.zipCodeValues.reset()
    this.shippingForm.controls.latitude.reset()
    this.shippingForm.controls.longitude.reset()
    this.shippingForm.controls.radius.reset()
  }

  addZipCode($event: any = null) {
    this.zipCodes = [];
    let isValid = true;
    let zipCodeValues = this.shippingForm.controls.zipCodeValues.value;

    const processZipCode = (zipCode: string) => {
      if (/^\d+$/.test(zipCode)) {
        if (!this.zipCodes.includes(zipCode)) {
          this.zipCodes.push(zipCode);
        }
      } else if (zipCode.includes('-')) {
        let start = parseInt(zipCode.split('-')[0].trim(), 10);
        let end = parseInt(zipCode.split('-')[1].trim(), 10);
        if (start < end) {
          for (let i = start; i <= end; i++) {
            if (!this.zipCodes.includes(i.toString())) {
              this.zipCodes.push(i.toString());
            }
          }
        } else {
          isValid = false;
        }
      } else {
        isValid = false;
      }
    };

    zipCodeValues.split(',').forEach(item => {
      if (item != null && typeof item == 'string' && item.trim()) {
        processZipCode(item.trim());
      }
    });

    if (!isValid) {
      this.displayValidationErrorMessage();
    }
  }

  displayValidationErrorMessage() {
    this.shippingAdded = false
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Invalid Zipcodes'
    });
  }

  deleteZipCode(selectedZipCode) {
    this.zipCodes = this.zipCodes.filter(zipCode => zipCode != selectedZipCode)
  }

  redirectToListing() {
    this.router.navigate([`list-shipping`]);
  }

  preventE(event: KeyboardEvent) {
    const invalidChars = ['e', 'E', '+', '-', '.'];
    if (invalidChars.includes(event.key)) {
      event.preventDefault();
    }
  }
}
