import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import * as query from 'graphql/queries';
import { Messages } from '@/Toaster-messages';

@Component({
  selector: 'app-attribute-list',
  templateUrl: './attribute-list.component.html',
  styleUrl: './attribute-list.component.scss'
})
export class AttributeListComponent implements OnInit {
  position: any;
  displayFIlterModalToggle = false;
  deleteModalToggle: any;

  attributeList: any = [];

  constructor(
    private router: Router,
    private awsApi: ApiService
  ) { }

  ngOnInit(): void {
    this.position = 'right';
    this.getAllAttribute();
  }

  getAllAttribute() {
    this.awsApi.loading.next(true);
    this.awsApi.client
      .graphql({
        query: query.listAttributeNames
      })
      .then((response) => {
        this.attributeList = response.data.listAttributeNames.items;
        this.awsApi.loading.next(false);
      })
      .catch((error) => {
        this.awsApi.loading.next(false);


      });
  }

  showNewEmailTemplateModalDialog(position: string) {
    this.position = position;
    this.displayFIlterModalToggle = true;
  }
  closeNewEmailTemplateModalDialog() {
    this.displayFIlterModalToggle = false;
  }
  showDeleteAttributeModalDialog(item: any, attribute: any) {
    this.deleteModalToggle = true;
  }
  redirectToAddAttributePage() {
    this.router.navigate(['/add-attribute']);
  }
}
