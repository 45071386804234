import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { ApiService } from '@services/api/api.service';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import * as mutation from 'graphql/mutations';
import * as query from 'graphql/queries';
import { uploadData } from 'aws-amplify/storage';
import amplifyconfig from 'amplifyconfiguration.json';
import { v4 as uuidv4 } from 'uuid';
import * as _ from 'underscore';
import { CommonService } from '@services/common/common.service';
import { objectRequiredValidator } from '@/custom-validators';
import { Status, UnitType, productStatus } from 'API';
import { Messages } from '@/Toaster-messages';

@Component({
  selector: 'app-product-add',
  templateUrl: './product-add.component.html',
  styleUrl: './product-add.component.scss'
})
export class ProductAddComponent implements OnInit, OnDestroy {
  defaults: any = {}
  defaultWidth: any = []
  defaultHeight: any = []
  defaultWeight: any = []
  defaultLength: any = []
  visible: boolean = false;
  public Editor = ClassicEditor;
  public onReady(editor: ClassicEditor) { }
  public onChange({ editor }: ChangeEvent) { }

  productForm: FormGroup;
  attributeValues: any = []
  mainCategoryList: any = [];
  subCategoryList: any = [];
  productsByCategory: any = []
  imageLibrary: any = []
  images: any = []
  brandList: any = [];
  searchText: any
  countryList: any = [];
  keywordArray: any = [];
  attrValueArray: any = [];
  unitdetail: any;
  unitSymbol: any;
  attrNameArray: any = [];
  productBaseImageList: any = [];
  productSubImageList: any = [];
  relatedImageList: any = [];
  unitList: any = [];
  baseUnitList: any = [];
  unitDict: any = {};
  unitMap: any = {};
  showChangeImage: boolean = false
  addActivated: boolean = false
  searchKeyFormatted: any = ''

  stockStatusList: any = [
    {
      name: 'In Stock',
      value: 'INSTOCK'
    },
    {
      name: 'Out of Stock',
      value: 'OUTOFSTOCK'
    }
  ];
  productStatusList: any = [
    { name: "Draft", value: productStatus.draft },
    { name: "Published", value: productStatus.published },
    { name: "Scheduled", value: productStatus.scheduled },
    { name: "Inactive", value: productStatus.inactive }
  ];
  imageForm: FormGroup;
  proImageFormSubmitted: boolean = false;
  packageImageList: any = [];
  imgIndex: any;
  currency: any
  @ViewChild('productFileUpload') productFileUpload: ElementRef;

  imgGIndex: any;
  priceOptionIndex: any;
  sumbitted: boolean = false;
  productID: string;
  taxList: any = []
  wareHouseList: any = []
  stockUnitList: any = []
  lengthUnitsList: any = []
  massUnitsList: any = []
  access: any = {}

  toggleAccordionItem(selectedIndex) {
    _.forEach(this.priceOptionItems.controls, (item, index) => {
      if (index == selectedIndex) {
        this.priceOptionItems.at(selectedIndex).get('expanded').setValue(!this.priceOptionItems.at(selectedIndex).get('expanded').value)
      }
      else {
        this.priceOptionItems.at(index).get('expanded').setValue(false)
      }
    })
  }

  constructor(
    private awsApi: ApiService,
    private router: Router,
    private common: CommonService,
    private fb: FormBuilder,
    private messageService: MessageService,
    private commonService: CommonService
  ) {

    this.defaults = {
      defaultMainCategory: null,
      defaultSubCategory: null,
      defaultBrand: null,
      defaultCountry: null,
      defaultStockStatus: null,
      defaultWarehouse: null,
      defaultVariantName: null,
      defaultTaxName: null,
      defaultWidth: null,
      defaultHeight: null,
      defaultWeight: null,
      defaultLength: null,
    };

    this.imageForm = this.fb.group({
      alterText: ['', [Validators.required]],
      title: [''],
      url: ['', [Validators.required]],
      description: '',
      type: ['', [Validators.required]]
    });

    this.productForm = this.fb.group({
      status: [productStatus.draft, [Validators.required]],
      scheduleDate: [],
      name: ['', [Validators.required, Validators.pattern(/^(?=.*[a-zA-Z])[a-zA-Z\d\s\S]+$/), Validators.maxLength(500)]],
      mainCategory: [{}, objectRequiredValidator()],
      subCategory: [{}, objectRequiredValidator()],
      brand: [{}, objectRequiredValidator()],
      countryOfOrigin: ['', [Validators.required]],
      productDescription: ['', [Validators.required, Validators.maxLength(1500)]],
      showWhenZeroStock: [false],
      allowPreOrder: [false],
      leadTime: ['', [Validators.pattern('^\\d+$')]],
      featuredImage: [{}, objectRequiredValidator()],
      galleryImages: this.fb.array([]),
      specificationList: this.fb.array([]),
      specificationForm: this.fb.group({
        name: [''],
        value: ['']
      }),
      taxList: this.fb.array([]),
      taxForm: this.fb.group({
        name: [{}, objectRequiredValidator()],
        value: ['', [Validators.pattern(/^\d{1,2}(\.\d{0,2})?$/)]]
      }),
      productFeatures: [''],
      seoDescription: [''],
      keyWord: '',

      singleVariant: ['true', [Validators.required]],
      attrName: [{}, objectRequiredValidator()],
      attrValue: ['', [Validators.required]],
      priceOptionItems: this.fb.array([]),

      singleProductDetail: this.fb.group({
        model: [''],
        manufacturePartCode: [''],
        sku: ['', [Validators.required, Validators.pattern(/^[a-zA-Z\d\-]+$/)]],
        stockStatus: ['INSTOCK', Validators.required],
        stockQuantity: ['', [Validators.required, Validators.pattern(/^\d*\.?\d+$/)]],
        reservedStock: [0],
        stockUnit: [{}, objectRequiredValidator()],
        itemsPerUnit: [],
        regularPrice: ['', Validators.required],
        salePrice: ['', Validators.required],
        dataSheet: [''],
        submittal: [''],
        catalog: [''],
        partialReturn: ['false'],
        iom: [''],
        storageInfo: this.fb.group({
          wareHouse: [{}, objectRequiredValidator()],
          rackNo: ['', [Validators.pattern(/^(?=.*\d)[a-zA-Z\d ]+$/)]],
          rowNo: ['', [Validators.pattern(/^(?=.*\d)[a-zA-Z\d ]+$/)]],
          binNo: ['', [Validators.pattern(/^(?=.*\d)[a-zA-Z\d ]+$/)]],
          note: [''],
        }),
        shipplingInfo: this.fb.group({
          weightValue: [''],
          weightUnit: [''],
          lengthValue: [''],
          lengthUnit: [''],
          heightValue: [''],
          heightUnit: [''],
          widthValue: [''],
          widthUnit: ['']
        })
      })
    });
  }

  get imageFormControl() {
    return this.imageForm.controls;
  }

  get priceOptionItems(): FormArray {
    return this.productForm.get('priceOptionItems') as FormArray;
  }

  get galleryImages(): FormArray {
    return this.productForm.get('galleryImages') as FormArray;
  }

  get singleProductDetail() {
    return this.productFormControl.singleProductDetail as FormGroup;
  }

  get storageInfoControls() {
    return this.singleProductDetail.get('storageInfo') as FormGroup;
  }

  addImage(index) {
    this.imageForm.patchValue({
      url: this.imageLibrary[index].url
    });
    this.imageLibrary = this.imageLibrary.map((item, imageIndex) =>
      ({ ...item, class: imageIndex === index ? 'primary active' : 'primary' }));
  }

  async getProductImage(filterObject: any = {}) {
    let items = [];
    let nextToken = null;
    do {
      const response = await this.awsApi.client.graphql({
        query: query.listProductImages,
        variables: {
          pk: 'PROIMG#',
          id: null,
          filter: filterObject,
          limit: 650,
          nextToken: nextToken,
          sortDirection: null
        }
      })
      items = items.concat(response.data.listProductImages.items);
      nextToken = response.data.listProductImages.nextToken;
    } while (nextToken !== null)
    return items
  }

  async loadImageLibrary() {
    try {
      this.awsApi.loading.next(true);
      this.images = []
      this.imageLibrary = []
      let selectedCategory = this.productForm.controls.mainCategory.value
      let filter = {
        mainCategoryID: { eq: selectedCategory.id },
        isDeleted: { ne: true }
      }
      let productImageResp = await this.getProductImage(filter)
      if (productImageResp.length > 0) {
        _.each(productImageResp, (productImage) => {
          if (productImage.url && !this.images.some((item) => item.url === productImage.url)) {
            this.images.push({
              'url': productImage.url,
              'productName': productImage.title,
              'class': 'primary'
            })
          }
        })
      }
      this.imageLibrary = this.images
    } catch (error) { }
    finally {
      this.awsApi.loading.next(false);
    }
  }

  searchImage() {
    this.searchImageLibrary()
  }

  searchImageLibrary() {
    this.imageLibrary = []
    if (this.searchText) {
      _.each(this.images, (image) => {
        if (image.productName.toLowerCase().replace(/\s+/g, '').trim()
          .includes(this.searchText.toLowerCase().replace(/\s+/g, '').trim())) {
          this.imageLibrary.push(image)
        }
      })
    }
    else {
      this.loadImageLibrary()
    }
  }

  async openUploadProImageModal(item: any, type: any, index: any = -1) {
    if (this.productForm.controls.mainCategory.status == 'INVALID') {
      this.imageLibrary = []
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please choose category before uploading images'
      });
    }
    else {
      await this.loadImageLibrary()
      if (type == 'featuredImg') {
        this.imageForm.reset();
        if (item.status == 'INVALID') {
          this.imageForm.patchValue({
            type: 'featuredImg',
            title: this.productFormControl.name.value
          });
        } else {
          this.imageForm.patchValue({
            type: item.value.type,
            title: this.productFormControl.name.value,
            url: item.value.url,
            alterText: item.value.alterText,
            description: item.value.description
          });
        }
      } else if (type == 'galleryImg' || type == 'featuredImgAttr') {
        if (index > -1) { this.imgGIndex = index }
        this.imageForm.reset();
        this.imageForm.patchValue({
          type: type,
          title: this.productFormControl.name.value,
          url: item.value.url,
          alterText: item.value.alterText,
          description: item.value.description
        });
      }
      this.proImageFormSubmitted = false;
      this.productFileUpload.nativeElement.value = '';
      this.showChangeImage = true
    }
  }

  clearImageUploadModal() {
    this.searchText = null
    this.imageForm.reset()
  }

  @ViewChild('closeImageUploadModal') private closeImageUploadModal: ElementRef;
  hideUploadProImageModel() {
    this.closeImageUploadModal.nativeElement.click();
  }

  saveProductImage() {
    this.proImageFormSubmitted = true;
    if (this.imageForm.valid) {
      if (!!!this.imageFormControl.title.value) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please add product name before choosing featured image.'
        });
        this.proImageFormSubmitted = false;
        return;
      }
      if (this.imageFormControl.type.value == 'featuredImg') {
        this.productFormControl.featuredImage.setValue(this.imageForm.value);
        this.proImageFormSubmitted = false;
        this.hideUploadProImageModel();
      } else if (this.imageFormControl.type.value == 'featuredImgAttr') {
        _.forEach(this.priceOptionItems.controls, (item, index) => {
          if (index == this.imgGIndex) {
            item.patchValue({ featuredImage: this.imageForm.value });
          }
        });
        this.proImageFormSubmitted = false;
        this.hideUploadProImageModel();
      } else if (this.imageFormControl.type.value == 'galleryImg') {
        _.forEach(this.galleryImages.controls, (item, index) => {
          if (index == this.imgGIndex) {
            item.patchValue(this.imageForm.value);
          }
        });
        this.proImageFormSubmitted = false;
        this.hideUploadProImageModel();
      }
    } else {
      this.proImageFormSubmitted = false;
    }
  }

  commonDictValue() {
    return {
      pk: 'PROD#' + this.productFormControl.subCategory.value.id,
      globalPk: 'PRODUCTS#',
      productName: this.productFormControl.name.value,
      scheduleDate: this.productFormControl.status.value == productStatus.scheduled ? new Date(this.productFormControl.scheduleDate.value).toISOString() : undefined,
      mainCategory: this.productFormControl.mainCategory.value.catName,
      mainCategoryID: this.productFormControl.mainCategory.value.id,
      subCategory: this.productFormControl.subCategory.value.catName,
      subCategoryID: this.productFormControl.subCategory.value.id,
      brandName: this.productFormControl.brand.value.brandName,
      brandID: this.productFormControl.brand.value.id,
      countryName: this.productFormControl.countryOfOrigin.value.name,
      countryID: this.productFormControl.countryOfOrigin.value.id,
      featuredImage: this.productFormControl.featuredImage.value
    };
  }

  saveProductVariantDB(obj) {
    return this.awsApi.client.graphql({
      query: mutation.createProductDetails,
      variables: {
        input: {
          ...obj
        }
      }
    });
  }

  saveProductDB(obj) {
    return this.awsApi.client.graphql({
      query: mutation.createProduct,
      variables: {
        input: {
          ...obj
        }
      }
    });
  }

  async validateSku(skuValue: any = null, index: any = null) {
    if (this.productFormControl.singleVariant.value != 'true') {
      skuValue = this.priceOptionItems.controls[index].value['sku']
    }
    let filter = {}
    filter['sku'] = { eq: skuValue }
    filter['isDeleted'] = { ne: true }
    let response = await this.common.getProductDetailsWithEditQuery(filter, ['pk', 'id'])
    if (response.length != 0) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'SKU - ' + skuValue + ' is already assigned to another product. Please use a different SKU.'
      });
      return false;
    }
    return true;
  }
  displayErrorToaster(message: any) {
    this.addActivated = false
    this.awsApi.loading.next(false);
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: message
    });
  }

  async checkValidationProductVarient() {
    if (!this.addActivated) {
      this.awsApi.loading.next(true);
      this.productID = uuidv4();
      let promiseSaveArray: any = [];
      let valid

      let productObj: any = { id: this.productID };
      productObj = { ...productObj, ...this.commonDictValue() };

      let imageDBCommonObj: any = {
        mainCategory: this.productFormControl.mainCategory.value.catName,
        mainCategoryID: this.productFormControl.mainCategory.value.id,
        subCategory: this.productFormControl.subCategory.value.catName,
        subCategoryID: this.productFormControl.subCategory.value.id,
        productID: this.productID,
        pk: 'PROIMG#',
        thumbnailUrl: undefined
      };
      productObj['productDescription'] =
        this.productFormControl.productDescription.value;
      productObj['productFeatures'] =
        this.productFormControl.productFeatures.value;
      productObj['seoDescription'] = this.productFormControl.seoDescription.value;
      productObj['keyWord'] = this.keywordArray;
      productObj['productSpecification'] = this.addedSpecList.value;
      productObj['taxDetail'] = this.addedTaxList.value;
      productObj['status'] = this.productFormControl.status.value;
      productObj['showWhenZeroStock'] = this.productFormControl.showWhenZeroStock.value;
      productObj['allowPreOrder'] = this.productFormControl.allowPreOrder.value;
      productObj['leadTime'] = this.productFormControl.leadTime.value;
      let validGalleryImages = [];

      this.galleryImages.controls.forEach((item) => {
        if (item.valid) {
          validGalleryImages.push(item.value);
          promiseSaveArray.push(
            this.saveProductImageDB({
              ...imageDBCommonObj,
              ...item.value,
              type: 'galleryImg'
            })
          );
        }
      });
      productObj['galleryImages'] = validGalleryImages;
      let productNameFormatted = this.productFormControl.name.value.toLowerCase()
      productNameFormatted = productNameFormatted.replace(/\s{2,}/g, ' ');
      this.searchKeyFormatted += (productNameFormatted.trim()).replace(/\s+/g, '')

      if (this.productFormControl.singleVariant.value == 'true') {
        valid = true
        // save only single variant
        try {
          if (this.singleProductDetail.valid) {
            this.singleProductDetail.value['stockUnit'] = this.singleProductDetail.value['stockUnit'].value
            if (valid && !await this.validateSalePrice(this.singleProductDetail)) { valid = false }
            if (valid && !await this.validateStockStatus(this.singleProductDetail)) { valid = false }
            if (valid && !await this.validateSku(this.singleProductDetail.value['sku'], null)) { valid = false }
            if (valid && !await this.validateShipping(this.singleProductDetail)) { valid = false }
          } else {
            await this.common.markAllControlsAsTouched(this.singleProductDetail);
            this.displayErrorToaster(Messages.MISSING_SINGLE_VARIANT_DETAILS)
          }
        } catch (error) { }
        if (valid && this.singleProductDetail.valid && await this.validateLeadTime(productObj)) {
          if (await this.validateScheduleDate()) {
            this.searchKeyFormatted += '#' + (this.singleProductDetail.value['sku']).toLowerCase().trim().replace(/\s+/g, '')
            productObj['singleVariant'] = true;
            productObj['stockStatus'] = this.singleProductDetail.value['stockStatus'];

            let modStorageInfo = this.singleProductDetail.value['storageInfo'];
            modStorageInfo['wareHouseId'] = modStorageInfo['wareHouse'].id
            modStorageInfo['wareHouse'] = modStorageInfo['wareHouse'].name
            delete this.singleProductDetail.value['storageInfo'];
            this.singleProductDetail.value['storageInfo'] = modStorageInfo
            this.singleProductDetail.value['stockQuantity'] = parseFloat(this.singleProductDetail.value['stockQuantity'])
            this.singleProductDetail.value['reservedStock'] = parseFloat(this.singleProductDetail.value['reservedStock'])
            let shipplingInfo = this.singleProductDetail.value['shipplingInfo']
            shipplingInfo['heightUnit'] = this.singleProductDetail.value['shipplingInfo']?.heightUnit?.id
            shipplingInfo['lengthUnit'] = this.singleProductDetail.value['shipplingInfo']?.lengthUnit?.id
            shipplingInfo['weightUnit'] = this.singleProductDetail.value['shipplingInfo']?.weightUnit?.id
            shipplingInfo['widthUnit'] = this.singleProductDetail.value['shipplingInfo']?.widthUnit?.id
            this.singleProductDetail.value['shipplingInfo'] = shipplingInfo
            this.searchKeyFormatted += '#' + modStorageInfo['wareHouseId'] + '#' + shipplingInfo['heightUnit'] + '#' + shipplingInfo['lengthUnit'] + '#' + shipplingInfo['weightUnit'] + '#' + shipplingInfo['widthUnit']
            productObj['totalStockQuantity'] = this.singleProductDetail.value['stockQuantity']
            productObj['totalReservedStock'] = this.singleProductDetail.value['reservedStock']
            productObj['searchKey'] = this.searchKeyFormatted
            promiseSaveArray.push(this.saveProductDB(productObj));

            promiseSaveArray.push(
              this.saveProductVariantDB({
                ...this.singleProductDetail.value,
                productID: this.productID,
                type: 'single',
                pk: 'PRODETAIL#'
              })
            );
            promiseSaveArray.push(
              this.saveProductImageDB({
                ...imageDBCommonObj,
                ...this.productFormControl.featuredImage.value,
                type: 'featuredImg'
              })
            );
            Promise.all(promiseSaveArray)
              .then((result) => {
                this.addActivated = true
                this.awsApi.loading.next(false);
                this.messageService.add({
                  severity: 'success',
                  summary: 'Success',
                  detail: 'Product is created successfully'
                });
                this.router.navigate(['/list-product']);
              })
              .catch((error) => { this.displayErrorToaster('Error:' + error + Messages.SOMETHING_WENT_WRONG) });
          }
        }
      }
      else {
        if (this.priceOptionItems.length > 0) {
          // save multiple variant product
          productObj['attrName'] = this.productFormControl.attrName.value.id;
          productObj['attrValue'] = this.attrValueArray;
          try {
            if (this.priceOptionItems.valid) {
              valid = true
              for (let i = 0; i < this.priceOptionItems.controls.length; i++) {
                delete this.priceOptionItems.controls[i].value['expanded'];
                let item = this.priceOptionItems.controls[i]
                if (valid) {
                  this.priceOptionItems.controls[i].value['stockUnit'] = item.value['stockUnit'].value
                  if (valid && !await this.validateSalePrice(item)) { valid = false }
                  if (valid && !await this.validateStockStatus(item)) { valid = false }
                  if (valid && !await this.validateSku(null, i)) { valid = false }
                  if (valid && !await this.validateShipping(item)) { valid = false }
                }
              }
            }
            else {
              this.displayErrorToaster(Messages.PLEASE_FILL_REQUIRED_FIELDS_VARIANT)
            }
          } catch (error) { }
          if (this.priceOptionItems.valid && valid && await this.validateLeadTime(productObj)) {
            if (await this.validateScheduleDate()) {
              this.addActivated = true
              productObj['stockStatus'] = this.priceOptionItems.controls.filter(item => item.value['stockStatus'] == 'INSTOCK').length == 0 ?
                'OUTOFSTOCK' : 'INSTOCK'
              promiseSaveArray.push(
                this.saveProductImageDB({
                  ...imageDBCommonObj,
                  ...this.productFormControl.featuredImage.value,
                  type: 'featuredImg'
                })
              );
              let totalStock = 0;
              let totalReserved = 0;
              for (let i = 0; i < this.priceOptionItems.controls.length; i++) {
                let item = this.priceOptionItems.controls[i]
                this.searchKeyFormatted += '#' + (item.value['sku']).toLowerCase().trim().replace(/\s+/g, '')
                totalStock += parseFloat(item.value['stockQuantity'])
                totalReserved += parseFloat(item.value['reservedStock'])

                let modStorageInfo = item.value['storageInfo'];
                modStorageInfo['wareHouseId'] = modStorageInfo['wareHouse'].id
                modStorageInfo['wareHouse'] = modStorageInfo['wareHouse'].name
                delete item.value['wareHouse']
                item.value['storageInfo'] = modStorageInfo

                let shipplingInfo = item.value['shipplingInfo']
                shipplingInfo['heightUnit'] = item.value['shipplingInfo']?.heightUnit?.id
                shipplingInfo['lengthUnit'] = item.value['shipplingInfo']?.lengthUnit?.id
                shipplingInfo['weightUnit'] = item.value['shipplingInfo']?.weightUnit?.id
                shipplingInfo['widthUnit'] = item.value['shipplingInfo']?.widthUnit?.id
                item.value['shipplingInfo'] = shipplingInfo
                this.searchKeyFormatted += '#' + modStorageInfo['wareHouseId'] + '#' + shipplingInfo['heightUnit'] + '#' + shipplingInfo['lengthUnit'] + '#' + shipplingInfo['weightUnit'] + '#' + shipplingInfo['widthUnit']

                promiseSaveArray.push(
                  this.saveProductVariantDB({
                    ...item.value,
                    productID: this.productID,
                    type: 'multiple',
                    pk: 'PRODETAIL#'
                  })
                );
                promiseSaveArray.push(
                  this.saveProductImageDB({
                    ...imageDBCommonObj,
                    ...item.value.featuredImage,
                    type: 'featuredImgAttr'
                  })
                );
              }
              productObj['totalStockQuantity'] = totalStock
              productObj['totalReservedStock'] = totalReserved
              productObj['searchKey'] = this.searchKeyFormatted

              promiseSaveArray.push(this.saveProductDB(productObj));
              Promise.all(promiseSaveArray)
                .then((result) => {
                  this.addActivated = true
                  this.awsApi.loading.next(false);
                  this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Product is created successfully'
                  });
                  this.router.navigate(['/list-product']);
                })
                .catch((error) => {
                  this.addActivated = false
                  this.awsApi.loading.next(false);
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: Messages.SOMETHING_WENT_WRONG
                  });
                });
            }
          }
        }
        else {
          this.addActivated = false
          this.awsApi.loading.next(false);
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: Messages.VARIANT_MISSING
          });
        }
      }
    }
  }

  async validateSalePrice(item): Promise<boolean> {
    if (item.value['regularPrice'] < item.value['salePrice']) {
      this.addActivated = false
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Invalid sale price'
      });
      return false;
    } else { return true; }
  }

  async validateShipping(item): Promise<boolean> {
    let shippingInput = item.value['shipplingInfo']
    let weightValue = (shippingInput.weightValue == '' || shippingInput.weightValue == null)
    let weightUnit = (shippingInput.weightUnit == '' || shippingInput.weightUnit == null)
    let lengthValue = (shippingInput.lengthValue == '' || shippingInput.lengthValue == null)
    let lengthUnit = (shippingInput.lengthUnit == '' || shippingInput.lengthUnit == null)
    let heightValue = (shippingInput.heightValue == '' || shippingInput.heightValue == null)
    let heightUnit = (shippingInput.heightUnit == '' || shippingInput.heightUnit == null)
    let widthValue = (shippingInput.widthValue == '' || shippingInput.widthValue == null)
    let widthUnit = (shippingInput.widthUnit == '' || shippingInput.widthUnit == null)
    if ((weightValue && !weightUnit) || (!weightValue && weightUnit) ||
      (lengthValue && !lengthUnit) || (!lengthValue && lengthUnit) ||
      (heightValue && !heightUnit) || (!heightValue && heightUnit) ||
      (widthValue && !widthUnit) || (!widthValue && widthUnit)) {
      this.addActivated = false
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: this.productFormControl.singleVariant.value == 'true' ? Messages.MISSING_SINGLE_VARIANT_DETAILS : Messages.PLEASE_FILL_REQUIRED_FIELDS_VARIANT
      });
      return false;
    } else { return true; }
  }

  async validateStockStatus(item) {
    if ((((parseFloat(item.value['stockQuantity']) - parseFloat(item.value['reservedStock'])) <= 0 &&
      item.value['stockStatus'] == 'INSTOCK') ||
      ((parseFloat(item.value['stockQuantity']) - parseFloat(item.value['reservedStock'])) > 0 &&
        item.value['stockStatus'] == 'OUTOFSTOCK'))) {
      this.addActivated = false
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Invalid stock status'
      })
      return false;
    }
    else { return true; }
  }

  async validateScheduleDate() {
    if (this.productFormControl.status.value == productStatus.scheduled &&
      this.productFormControl.scheduleDate.value == null) {
      this.addActivated = false
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.MISSING_SCHEDULE_DATE
      })
      return false;
    } else { return true; }
  }

  async validateLeadTime(productObject: any) {
    if (productObject['allowPreOrder'] == true && (productObject['leadTime'] == '' || productObject['leadTime'] == null)) {
      this.addActivated = false
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.MISSING_LEADTIME
      });
      return false;
    } else { return true; }
  }

  async validateProductForm() {
    this.sumbitted = true;
    this.productFormControl.name.valid &&
      this.productFormControl.mainCategory.valid &&
      this.productFormControl.subCategory.valid &&
      this.productFormControl.brand.valid &&
      this.productFormControl.countryOfOrigin.valid &&
      this.productFormControl.featuredImage.valid &&
      this.productFormControl.productDescription.valid
      ? this.checkValidationProductVarient()
      : this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill all the required details'
      });
    await this.common.markAllControlsAsTouched(this.productForm);
    this.addActivated = false
  }

  addPriceOptionItem(attrValueObj) {
    let priceOptionItem = this.fb.group({
      featuredImage: [{}],
      expanded: [false],
      attrName: [this.productFormControl.attrName.value.attrName],
      attrNameID: [this.productFormControl.attrName.value.id],
      attrValue: [attrValueObj.value],
      attrValueID: [attrValueObj.attrValueID],
      model: [''],
      manufacturePartCode: [''],
      sku: ['', [Validators.required, Validators.pattern(/^[a-zA-Z\d\-]+$/)]],
      stockStatus: ['INSTOCK', Validators.required],
      stockQuantity: ['', [Validators.required, Validators.pattern(/^\d*\.?\d+$/)]],
      reservedStock: [0],
      stockUnit: [{}, objectRequiredValidator()],
      itemsPerUnit: [],
      regularPrice: ['', Validators.required],
      salePrice: ['', Validators.required],
      dataSheet: [''],
      submittal: [''],
      catalog: [''],
      partialReturn: ['false'],
      iom: [''],
      storageInfo: this.fb.group({
        wareHouse: [{}, objectRequiredValidator()],
        rackNo: ['', [Validators.pattern(/^(?=.*\d)[a-zA-Z\d ]+$/)]],
        rowNo: ['', [Validators.pattern(/^(?=.*\d)[a-zA-Z\d ]+$/)]],
        binNo: ['', [Validators.pattern(/^(?=.*\d)[a-zA-Z\d ]+$/)]],
        note: [''],
      }),
      shipplingInfo: this.fb.group({
        weightValue: [''],
        weightUnit: [''],
        lengthValue: [''],
        lengthUnit: [''],
        heightValue: [''],
        heightUnit: [''],
        widthValue: [''],
        widthUnit: ['']
      })
    });
    this.priceOptionItems.push(priceOptionItem);
  }

  resetPriceOptionField(type: any, index: any = 0) {
    if (type == 'dataSheet') {
      this.priceOptionItems.at(index).patchValue({
        dataSheet: ''
      });
    } else if (type == 'submittal') {
      this.priceOptionItems.at(index).patchValue({
        submittal: ''
      });
    } else if (type == 'catalog') {
      this.priceOptionItems.at(index).patchValue({
        catalog: ''
      });
    } else if (type == 'iom') {
      this.priceOptionItems.at(index).patchValue({
        iom: ''
      });
    } else if (type == 'singledataSheet') {
      this.singleProductDetail.patchValue({
        dataSheet: ''
      });
    } else if (type == 'singlesubmittal') {
      this.singleProductDetail.patchValue({
        submittal: ''
      });
    } else if (type == 'singlecatalog') {
      this.singleProductDetail.patchValue({
        catalog: ''
      });
    } else if (type == 'singleiom') {
      this.singleProductDetail.patchValue({
        iom: ''
      });
    }
  }

  addGalleryImages(numCalls: number) {
    for (let i = 0; i < numCalls; i++) {
      this.galleryImages.push(
        this.fb.group({
          alterText: ['', [Validators.required]],
          title: [''],
          url: ['', [Validators.required]],
          description: '',
          type: ['', [Validators.required]]
        })
      );
    }
  }

  removePriceOptionItem(index: number): void {
    this.priceOptionItems.removeAt(index);
  }

  get addedSpecList(): FormArray {
    return this.productForm.get('specificationList') as FormArray;
  }

  get addedTaxList(): FormArray {
    return this.productForm.get('taxList') as FormArray;
  }

  get specificationFormControl() {
    return (this.productForm.get('specificationForm') as FormGroup).controls;
  }

  get taxFormControl() {
    return (this.productForm.get('taxForm') as FormGroup).controls;
  }

  showDialog() {
    this.visible = true;
  }

  get productFormControl() {
    return this.productForm.controls;
  }

  addingProductKeyword() {
    if (this.productFormControl.keyWord.value != '') {
      this.keywordArray = this.keywordArray.concat(
        this.productFormControl.keyWord.value.split(',')
      );
      this.productFormControl.keyWord.setValue('');
    }
  }

  addingAttrValue() {
    if (this.productFormControl.attrName.valid) {
      let attributeValues = [];

      if (this.productFormControl.attrValue.value.indexOf(',') === -1 && typeof this.productFormControl.attrValue.value === 'string' && this.productFormControl.attrValue.value.trim()) {
        attributeValues.push(this.productFormControl.attrValue.value.trim());
      } else {
        this.productFormControl.attrValue.value.split(',').forEach(item => {
          if (item && typeof item === 'string' && item.trim()) {
            attributeValues.push(item.trim());
          }
        })
      }
      attributeValues.forEach((value, index) => {
        const trimmedAttrValue = value.toLowerCase().replace(/\s+/g, '').trim();
        if (!this.attributeValues.includes(trimmedAttrValue)) {
          const attrValueObj = {
            value: value,
            attrValueID: uuidv4()
          };
          this.attrValueArray.push(attrValueObj);
          this.attributeValues.push(trimmedAttrValue);
          this.defaultWidth[index] = null
          this.defaultHeight[index] = null
          this.defaultWeight[index] = null
          this.defaultLength[index] = null

          this.addPriceOptionItem(attrValueObj);
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Variant value already exists!'
          });
        }
      })
      this.productFormControl.attrValue.setValue('');
    }
    else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please choose a variant before adding values'
      });
    }
  }

  deleteKeyword(index: any) {
    this.keywordArray.splice(index, 1);
  }

  deleteAttrValue(index: any) {
    this.attrValueArray.splice(index, 1);
    this.attributeValues.splice(index, 1)
    this.removePriceOptionItem(index);
  }

  addSpecificationItem() {
    if (
      !!this.specificationFormControl.name.value &&
      !!this.specificationFormControl.value.value
    ) {
      this.addedSpecList.push(
        this.fb.group({
          name: this.specificationFormControl.name.value,
          value: this.specificationFormControl.value.value
        })
      );
      this.specificationFormControl.name.reset();
      this.specificationFormControl.value.reset();
    }
  }

  addTaxItem() {
    if (
      !!this.taxFormControl.name.value &&
      !!this.taxFormControl.value.value
    ) {

      let existItem = this.addedTaxList.controls.find(x => x.get('taxID').value == this.taxFormControl.name.value.id)

      _.forEach(this.addedTaxList.controls, (item, index) => {
        if (item.get('taxID').value == this.taxFormControl.name.value.id) {
          item.get('taxCharge').setValue(this.taxFormControl.value.value)
        }
      });
      if (!existItem) {
        this.searchKeyFormatted += this.taxFormControl.name.value.id
        this.addedTaxList.push(
          this.fb.group({
            taxName: this.taxFormControl.name.value.taxCode,
            taxID: this.taxFormControl.name.value.id,
            taxCharge: this.taxFormControl.value.value
          })
        );
      }
      this.taxFormControl.name.reset();
      this.taxFormControl.value.reset();
    }
  }

  removeTaxItem(index: number) {
    this.addedTaxList.removeAt(index);
  }

  removeSpecItem(index: number) {
    this.addedSpecList.removeAt(index);
  }

  async fileSave(event: any, type: any, index: any = -1) {
    if (type != 'productImage' && index > -1) {
      this.priceOptionIndex = index;
    }
    if (event.target instanceof HTMLInputElement && event.target.files) {
      let file = event.target.files[0];
      if (type == 'productImage' && (!file || !file.type.startsWith('image/'))) {
        event.target.value = '';
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.INVALID_FILE_TYPE
        });
        return;
      }
      try {
        let uploadedUrl = await this.common.getS3Url(file)
        if (type == 'productImage') {
          this.imageForm.patchValue({
            url: uploadedUrl
          });
        } else if (type == 'dataSheet') {
          this.priceOptionItems.at(this.priceOptionIndex).patchValue({
            dataSheet: uploadedUrl
          });
        } else if (type == 'submittal') {
          this.priceOptionItems.at(this.priceOptionIndex).patchValue({
            submittal: uploadedUrl
          });
        } else if (type == 'catalog') {
          this.priceOptionItems.at(this.priceOptionIndex).patchValue({
            catalog: uploadedUrl
          });
        } else if (type == 'iom') {
          this.priceOptionItems.at(this.priceOptionIndex).patchValue({
            iom: uploadedUrl
          });
        } else if (type == 'singledataSheet') {
          this.singleProductDetail.patchValue({
            dataSheet: uploadedUrl
          });
        } else if (type == 'singlesubmittal') {
          this.singleProductDetail.patchValue({
            submittal: uploadedUrl
          });
        } else if (type == 'singlecatalog') {
          this.singleProductDetail.patchValue({
            catalog: uploadedUrl
          });
        } else if (type == 'singleiom') {
          this.singleProductDetail.patchValue({
            iom: uploadedUrl
          });
        }
      } catch (error) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      }
    }
  }

  getAllMainCategory() {
    return this.awsApi.client.graphql({
      query: query.listCategories,
      variables: {
        pk: 'MAIN#'
      }
    });
  }

  getAllSubCategory(mainCategoryID) {
    return this.awsApi.client.graphql({
      query: query.listCategories,
      variables: {
        pk: 'SUB#' + mainCategoryID
      }
    });
  }

  saveProductImageDB(imageObj) {
    return this.awsApi.client.graphql({
      query: mutation.createProductImage,
      variables: {
        input: {
          ...imageObj
        }
      }
    });
  }

  getAllBrand() {
    let filter = { 'status': { eq: Status.ACTIVE } }
    return this.awsApi.client.graphql({
      query: query.listBrands,
      variables: {
        pk: 'BRAND#',
        id: null,
        filter: filter,
        limit: 9999,
        nextToken: null,
        sortDirection: null
      }
    });
  }

  getAllCountry() {
    return this.awsApi.client.graphql({
      query: query.listCountries,
      variables: {
        limit: 9999,
        nextToken: null,
        sortDirection: null
      }
    });
  }

  getAllAttribute() {
    let filter = { 'status': { eq: Status.ACTIVE } }
    return this.awsApi.client.graphql({
      query: query.listAttributeNames,
      variables: {
        pk: 'ATTRNAME#',
        slug: null,
        filter: filter,
        limit: 9999,
        nextToken: null,
        sortDirection: null
      }
    });
  }

  getTaxCodes() {
    let filter = { 'status': { eq: Status.ACTIVE } }
    return this.awsApi.client.graphql({
      query: query.listTaxes,
      variables: {
        pk: 'TAX#',
        id: null,
        filter: filter,
        limit: 9999,
        nextToken: null,
        sortDirection: null
      }
    });
  }

  getWareHouseList() {
    return this.awsApi.client.graphql({
      query: query.listWareHouses
    });
  }

  getUnits(type: any) {
    let filter = { 'status': { eq: Status.ACTIVE }, 'type': { eq: type } }
    return this.awsApi.client.graphql({
      query: query.listUnits,
      variables: {
        pk: 'UNIT#',
        id: null,
        filter: filter,
        limit: 9999,
        nextToken: null,
        sortDirection: null
      }
    });
  }

  refreshList(type: any) {
    switch (type) {
      case 'mainCategory':
        this.getAllMainCategory().then((result: any) => {
          this.mainCategoryList = result.data.listCategories.items;
        })
        break;
      case 'brand':
        this.getAllBrand().then((result: any) => {
          this.brandList = result.data.listBrands.items;
        })
        break;
      case 'country':
        this.getAllCountry().then((result: any) => {
          this.countryList = result.data.listCountries.items;
        })
        break;
      case 'variant':
        this.getAllAttribute().then((result: any) => {
          this.attrNameArray = result.data.listAttributeNames.items;
        })
        break;
      case 'taxName':
        this.getTaxCodes().then((result: any) => {
          this.taxList = result.data.listTaxes.items;
        })
        break;
      case 'warehouse':
        this.getWareHouseList().then((result: any) => {
          this.wareHouseList = result.data.listWareHouses.items;
        })
        break;
      case 'stockUnits':
        this.getUnits(UnitType.StockUnit).then((result: any) => {
          this.stockUnitList = result.data.listUnits.items
            .sort((a: any, b: any) => a.sequenceNumber - b.sequenceNumber);
        })
        break;
      case 'weightUnits':
        this.getUnits(UnitType.Mass).then((result: any) => {
          this.massUnitsList = result.data.listUnits.items
            .sort((a: any, b: any) => a.sequenceNumber - b.sequenceNumber);
        })
        break;
      case 'lengthUnits':
        this.getUnits(UnitType.Distance).then((result: any) => {
          this.lengthUnitsList = result.data.listUnits.items
            .sort((a: any, b: any) => a.sequenceNumber - b.sequenceNumber);
        })
        break;
      default: { }
    }
  }

  async getRoleManagement() {
    try {
      await this.common.getPermissionsOfUser('Products', 'Manage Product');
      this.access = this.common.access;
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  async ngOnInit() {
    this.awsApi.loading.next(true);
    this.getRoleManagement()
    this.currency = await this.common.getCurrencyOfCompany()
    var promiseArray: any = [];
    this.addGalleryImages(4);
    promiseArray.push(
      this.getAllMainCategory(),
      this.getAllBrand(),
      this.getAllCountry(),
      this.commonService.unitDetails(),
      this.commonService.unitMapDetail(),
      this.commonService.unitSymbol(),
      this.getAllAttribute(),
      this.getTaxCodes(),
      this.getWareHouseList(),
      this.getUnits(UnitType.StockUnit),
      this.getUnits(UnitType.Mass),
      this.getUnits(UnitType.Distance)
    );
    Promise.all(promiseArray)
      .then((result: any) => {
        this.mainCategoryList = result[0].data.listCategories.items;
        this.brandList = result[1].data.listBrands.items;
        this.countryList = result[2].data.listCountries.items;
        this.unitdetail = result[3];
        this.unitMap = result[4];
        this.unitSymbol = result[5];
        this.attrNameArray = result[6].data.listAttributeNames.items;
        this.taxList = result[7].data.listTaxes.items;
        this.wareHouseList = result[8].data.listWareHouses.items;
        this.stockUnitList = result[9].data.listUnits.items.sort((a: any, b: any) => a.sequenceNumber - b.sequenceNumber);
        this.massUnitsList = result[10].data.listUnits.items.sort((a: any, b: any) => a.sequenceNumber - b.sequenceNumber);
        this.lengthUnitsList = result[11].data.listUnits.items.sort((a: any, b: any) => a.sequenceNumber - b.sequenceNumber);
        this.awsApi.loading.next(false);
      })
      .catch((error) => {
        this.awsApi.loading.next(false);
      });
  }

  onSelectMainCategory(item: any = null) {
    this.subCategoryList = [];
    let mainCategoryId = item ? item.value.id : this.productFormControl.mainCategory.value.id
    this.getAllSubCategory(mainCategoryId)
      .then((result) => {
        this.subCategoryList = result.data.listCategories.items;
      })
      .catch((error) => {
      });
  }

  cancel() {
    this.router.navigate(['list-product']);
  }

  ngOnDestroy(): void { }

  deleteUrl(type, index: any = -1) {
    if (type == 'prodImage') {
      this.productFormControl.featuredImage.setValue('')
    }
    if (type == 'prodImage' && index > -1) {
      this.productFormControl.featuredImage.setValue('')
    }
    if (type == 'galleryImage') {
      (this.productForm.get('galleryImages') as FormArray).at(index).patchValue('');
      (this.productForm.get('galleryImages') as FormArray).controls[index].patchValue({
        alterText: '',
        description: '',
        title: '',
        type: '',
        url: ''
      })
    }
    if (type == 'featuredImgAttr' && index > -1) {
      (this.productForm.get('priceOptionItems') as FormArray).at(index).get('featuredImage').patchValue({});
    }
  }
  preventE(event) {
    if (event.which === 101) {
      event.preventDefault();
    }
  }

  getCurrentDate(): string {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    return currentDate.toISOString().split('T')[0];
  }

  onSelectAllowPreorder() {
    if (!(this.productForm.controls.allowPreOrder.value)) { this.productForm.patchValue({ leadTime: '' }) }
  }

  openFile(file) {
    window.open(file)
  }
}