import { Component, OnInit } from '@angular/core';
import { AppService } from '@services/app.service';
import { DateTime } from 'luxon';
import { CommonService } from '@services/common/common.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
    public user;
    show: boolean = true
    email: any = ''
    profileImageUrl: any = ''

    constructor(private _router: Router, private appService: AppService, private common: CommonService) { }

    async ngOnInit(): Promise<void> {
        let userDetails = await this.common.getUserDetails(localStorage.getItem('email'), localStorage.getItem('role'))
        this.common.initializeProfileImage(userDetails[0]?.profileImageUrl)
        this.common.profileImage$.subscribe((newValue) => {
            this.profileImageUrl = newValue;
        });
        this.email = localStorage.getItem('email')
    }


    logout() {
        this.appService.logout();
        window.localStorage.clear();
        this.common.denyUserPermisssions()
    }

    formatDate(date) {
        return DateTime.fromISO(date).toFormat('dd LLL yyyy');
    }

    redirectToProfilePage() {
        this._router.navigate(['/profile']);
        this.show = false
    }

    enableShow() {
        this.show = true
    }
}