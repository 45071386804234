export const ROUTES = {
    ADD_PRODUCT: `/add-product`,
    ADD_CATEGORY: '/add-category',
    ORDER_DETAILS: `/order-details`,
    ORDER_LIST: `/order-list`,
    CATEGORY_LIST: `/list-category`,
    ATTRIBUTE_LIST: `/list-attribute`,
    COUNTRY_LIST: `list-country`,
    RETURN_ITEM_DETAILS: `/return-refund-details`,
    RETURN_AND_REFUND: `/return-refund-list`,
    ADD_USER: `/add-user`,
    LIST_USER: `/list-user`,
    LIST_QUERY: `/general-queries`,
    VIEW_QUERY: `/customer-query`,
    LIST_PRODUCT_REVIEWS: `/product-reviews`,
    CUSTOMER_REVIEW: `/customer-review`,
    LIST_CONTENT: `/content-list`,
    CREATE_CONTENT_SECTION: `add-content-section`,
    CREATE_CONTENT: `add-content`,
    LIST_FAQ: `faq-list`,
    CREATE_FAQ_SECTION: `add-faq-section`,
    CREATE_FAQ: `add-faq`,
    LIST_TAX: 'list-tax',
    ADD_DELIVERY_PARTNER: 'add-delivery-partners',
    LIST_DELIVERY_PARTNER: 'list-delivery-partners',
    PROMOCODE_LIST: `/list-promocode`,
    ADD_PROMOCODE: 'add-promocode',
    COUNTRY_EDIT: 'edit-country',
    STOCK_REPORT: 'stocks-report-list',
    CATEGORY_REPORT: 'categories-report-list',
    ORDERS_REPORT: 'orders-report-list',
    PRODUCT_REPORT: 'products-report-list',
    RETURN_REFUND_REPORT: 'return-refund-report-list',
    PAYMENT_LEDGER_REPORT: 'payments-ledger',
    REPORTS_LIST: 'report-list'
}