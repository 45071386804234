import { Component } from '@angular/core';
import {
    FormBuilder,
    Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { resetPassword, type ResetPasswordOutput } from 'aws-amplify/auth';
import { environment } from '../../../environments/environment';
import { MessageService } from 'primeng/api';
import { ApiService } from '@services/api/api.service';
import { Messages } from '@/Toaster-messages';
import { UserRole } from 'API';
import { CommonService } from '@services/common/common.service';
import { NewUser, UserAuthService } from '@services/user-auth/user-auth.service';

@Component({
    selector: 'app-user-forgot-password',
    templateUrl: './user-forgot-password.component.html',
    styleUrl: './user-forgot-password.component.scss'
})
export class UserForgotPasswordComponent {
    submitted: boolean = false;
    forgotForm = this.fb.group({
        email: ['', [Validators.required, Validators.email]]
    });

    constructor(
        private _router: Router,
        private common: CommonService,
        private fb: FormBuilder,
        private router: Router,
        private messageService: MessageService,
        private awsApi: ApiService,
        public auth: UserAuthService
    ) { }

    get emailInput() {
        return this.forgotForm.get('email');
    }

    async handleResetPassword() {
        this.awsApi.loading.next(true);
        try {
            if (this.forgotForm.valid) {
                if (!this.submitted) {
                    this.submitted = true;
                    let data = {
                        "email": this.forgotForm.value.email ?? ''
                    }
                    this.common.forgotPassword(data).subscribe(async (result) => {
                        if (result.status) {
                            this.awsApi.loading.next(false);
                            this.messageService.add({
                                severity: 'success',
                                summary: 'Success',
                                detail: 'OTP has been sent to your email'
                            });
                            this._router.navigate(['confirm'], { queryParams: { email: data['email'], type: 'forgot_password' } })
                        }
                        else {
                            this.awsApi.loading.next(false);
                            if (result.message == 'Invalid email id') {  // email-id is in Cognito and not confirmed
                                this.messageService.add({
                                    severity: 'error',
                                    summary: 'Error',
                                    detail: 'Invalid email-id'
                                });
                            }
                            else if (result.message == 'Not found') { // email-id is in User table but not in Cognito                               
                                await this.confirmUser()
                            }
                            else {
                                this.messageService.add({
                                    severity: 'error',
                                    summary: 'Error',
                                    detail: Messages.SOMETHING_WENT_WRONG
                                });
                            }
                        }
                    })
                }
            }
        } catch (error) {
            this.awsApi.loading.next(false);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: Messages.SOMETHING_WENT_WRONG
            });
            this.router.navigate([`login`]);
            this.submitted = false;
        }
    }

    async confirmUser() {
        let filter = {
            email: { eq: this.forgotForm.value.email },
            role: { ne: UserRole.CUSTOMER }
        }
        this.common.listUsers(filter, ['id', 'password', 'firstName', 'lastName', 'phoneNumber', 'role'], true).then((response) => {
            let userDetail = response
            if (userDetail.length > 0) {
                this.auth.signUp({
                    username: this.forgotForm.value.email,
                    email: this.forgotForm.value.email,
                    password: atob(userDetail[0].password),
                    firstName: userDetail[0].firstName,
                    lastName: userDetail[0].lastName,
                    phone_number: userDetail[0].phoneNumber,
                    role: userDetail[0].role
                } as NewUser).then((response) => {
                    if (response.nextStep.signUpStep === 'CONFIRM_SIGN_UP') {
                        this.awsApi.loading.next(false);
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Success',
                            detail: 'OTP has been sent to your email'
                        });
                        environment.confirm.attributeName =
                            response.nextStep?.['codeDeliveryDetails'].attributeName;
                        environment.confirm.email = this.forgotForm.value.email ?? '';
                        environment.confirm.password = atob(userDetail[0].password) ?? '';
                        this._router.navigate(['confirm'], { queryParams: { email: this.forgotForm.value.email, type: "verify_user" } })
                    }
                    else {
                        this._router.navigate([''])
                    }
                }).catch((error: any) => {
                    this.awsApi.loading.next(false);
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: Messages.SOMETHING_WENT_WRONG
                    });
                })
            }
            else {
                this.awsApi.loading.next(false);
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'User name or password is incorrect!'
                });
            }
        })
    }

    redirectToSigninPage() {
        this.router.navigate([`login`]);
    }
}
