import { AppState } from '@/store/state';
import { UiState } from '@/store/ui/state';
import { Component, HostBinding, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApiService } from '@services/api/api.service';
import { AppService } from '@services/app.service';
import { CommonService } from '@services/common/common.service';
import { Observable, Subscription } from 'rxjs';
import _ from 'underscore';
import * as query from 'graphql/queries';
import * as mutation from 'graphql/mutations';

const BASE_CLASSES = 'main-sidebar elevation-4';
@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
  @HostBinding('class') classes: string = BASE_CLASSES;
  public ui: Observable<UiState>;
  public user;
  public menu;
  showMenu = false
  subscription: Subscription
  access = {}
  modules: any = []
  modulesList: any = []
  subModulesList: any = []
  viewList: any = []
  roleList: any = []
  HIDEICONSMENU: any = []
  SHOWICONSMENU: any = []
  secondaryLogo: any
  companyName: any
  constructor(
    public appService: AppService,
    private store: Store<AppState>, private common: CommonService, private awsApi: ApiService
  ) {
    this.subscription = this.appService.triggerFunction$.subscribe(() => {
      this.assignMenuType();
    });
  }

  async ngOnInit() {
    try {
      this.ui = this.store.select('ui');
      this.ui.subscribe((state: UiState) => {
        this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
      });
      this.user = this.appService.user;
      this.fetchModuleViewAccess().then(async () => {
        this.startingFunction()
        this.secondaryLogo = await this.common.getSecondaryLogoOfCompany();
        this.companyName = await this.common.getCompanyName()
      }).catch(error => {
      });
    } catch (error) { }
  }

  async startingFunction() {
    this.HIDEICONSMENU = [
      {
        name: 'Dashboard',
        iconClasses: 'fas fa-home',
        path: ['/'],
        view: this.viewList.find(menuItem => menuItem.mainModule === 'Dashboard')?.view ?? false
      },
      {
        name: 'Customers',
        iconClasses: 'fas fa-user-friends',
        children: [
          {
            name: 'Manage Customers',
            iconClasses: 'fas',
            path: ['/list-customer'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Customers' && menuItem.subModule === 'Manage Customers')?.view ?? false
          },
          {
            name: 'General Queries',
            iconClasses: 'fas',
            path: ['/general-queries'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Customers' && menuItem.subModule === 'General Queries')?.view ?? false
          }
        ],
        view: this.viewList.find(menuItem => menuItem.mainModule === 'Customers' && !menuItem.subModule)['view']
      },
      {
        name: 'Products',
        iconClasses: 'fas fa-cube',
        children: [
          {
            name: 'Manage Product',
            iconClasses: 'fas',
            path: ['/list-product'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Products' && menuItem.subModule === 'Manage Product')?.view ?? false
          },
          {
            name: 'Manage Category',
            iconClasses: 'fas',
            path: ['/list-category'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Products' && menuItem.subModule === 'Manage Category')?.view ?? false
          },
          {
            name: 'Manage Brand',
            iconClasses: 'fas',
            path: ['/list-brand'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Products' && menuItem.subModule === 'Manage Brand')?.view ?? false
          },
          {
            name: 'Product Reviews',
            iconClasses: 'fas',
            path: ['/product-reviews'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Products' && menuItem.subModule === 'Product Reviews')?.view ?? false
          }
        ],
        view: this.viewList.find(menuItem => menuItem.mainModule === 'Products' && !menuItem.subModule)['view']
      },
      {
        name: 'Orders',
        iconClasses: 'fas fa-toolbox',
        children: [
          {
            name: 'Manage Carts',
            iconClasses: 'fas',
            path: ['/list-cart'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Orders' && menuItem.subModule === 'Manage Carts')?.view ?? false
          },
          {
            name: 'Manage Orders',
            iconClasses: 'fas',
            path: ['/order-list'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Orders' && menuItem.subModule === 'Manage Orders')?.view ?? false
          },
          {
            name: 'Return & Refund',
            iconClasses: 'fas',
            path: ['/return-refund-list'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Orders' && menuItem.subModule === 'Return and Refund')?.view ?? false
          }
        ],
        view: this.viewList.find(menuItem => menuItem.mainModule === 'Orders' && !menuItem.subModule)['view']
      },
      {
        name: 'Reports',
        iconClasses: 'fas fa-file-invoice',
        path: ['/report-list'],
        view: this.viewList.find(menuItem => menuItem.mainModule === 'Reports' && !menuItem.subModule).view
      },
      {
        name: 'Users',
        iconClasses: 'fas fa-user',
        children: [
          {
            name: 'Manage Users',
            iconClasses: 'fas',
            path: ['/list-user'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Users' && menuItem.subModule === 'Manage Users')?.view ?? false
          },
          {
            name: 'Manage Roles',
            iconClasses: 'fas',
            path: ['/list-role'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Users' && menuItem.subModule === 'Manage Roles')?.view ?? false
          },
          {
            name: 'Delivery Partners',
            iconClasses: 'fas',
            path: ['/list-delivery-partners'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Users' && menuItem.subModule === 'Delivery Partners')?.view ?? false
          },
        ],
        view: this.viewList.find(menuItem => menuItem.mainModule === 'Users' && !menuItem.subModule)['view']
      },
      {
        name: 'Promo Codes',
        iconClasses: 'fas fa-donate',
        path: ['/list-promocode'],
        view: this.viewList.find(menuItem => menuItem.mainModule === 'Promo Codes' && !menuItem.subModule).view
      },
      {
        name: 'Settings',
        iconClasses: 'fas fa-cog',
        children: [
          {
            name: 'Manage Variant',
            iconClasses: 'fas',
            path: ['/list-variant'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Settings' && menuItem.subModule === 'Manage Variant')?.view ?? false
          }, {
            name: 'Manage Unit',
            iconClasses: 'fas',
            path: ['/list-units'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Settings' && menuItem.subModule === 'Manage Unit')?.view ?? false
          },
          {
            name: 'Manage Shipping',
            iconClasses: 'fas',
            path: ['/list-shipping'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Settings' && menuItem.subModule === 'Manage Shipping')?.view ?? false
          },
          {
            name: 'Manage Tax',
            iconClasses: 'fas',
            path: ['/list-tax'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Settings' && menuItem.subModule === 'Manage Tax')?.view ?? false
          },
          {
            name: 'Manage Warehouse',
            iconClasses: 'fas',
            path: ['/list-warehouse'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Settings' && menuItem.subModule === 'Manage Warehouse')?.view ?? false
          },
          {
            name: 'Manage Country',
            iconClasses: 'fas',
            path: ['/list-country'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Settings' && menuItem.subModule === 'Manage Country')?.view ?? false
          }
        ],
        view: this.viewList.find(menuItem => menuItem.mainModule === 'Settings' && !menuItem.subModule)['view']
      },
      {
        name: 'Others',
        iconClasses: 'fas fa-bars',
        children: [
          {
            name: 'FAQ',
            iconClasses: 'fas',
            path: ['/faq-list'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Others' && menuItem.subModule === 'FAQ')?.view ?? false
          },
          {
            name: 'Contents',
            iconClasses: 'fas',
            path: ['/content-list'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Others' && menuItem.subModule === 'Contents')?.view ?? false
          },
          {
            name: 'Carousel Images',
            iconClasses: 'fas',
            path: ['/carousel-images-list'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Others' && menuItem.subModule === 'Carousel Images')?.view ?? false
          }
        ],
        view: this.viewList.find(menuItem => menuItem.mainModule === 'Settings' && !menuItem.subModule)['view']
      }
    ];
    this.SHOWICONSMENU = [
      {
        name: 'Dashboard',
        iconClasses: 'fas fa-home',
        path: ['/'],
        view: this.viewList.find(menuItem => menuItem.mainModule === 'Dashboard')?.view ?? false
      },
      {
        name: 'Customers',
        iconClasses: 'fas fa-user-friends',
        children: [
          {
            name: 'Manage Customers',
            iconClasses: 'fas fa-circle',
            path: ['/list-customer'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Customers' && menuItem.subModule === 'Manage Customers')?.view ?? false
          },
          {
            name: 'General Queries',
            iconClasses: 'fas fa-circle',
            path: ['/general-queries'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Customers' && menuItem.subModule === 'General Queries')?.view ?? false
          }
        ],
        view: this.viewList.find(menuItem => menuItem.mainModule === 'Customers' && !menuItem.subModule)['view']
      },
      {
        name: 'Products',
        iconClasses: 'fas fa-cube',
        children: [
          {
            name: 'Manage Product',
            iconClasses: 'far fa-circle',
            path: ['/list-product'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Products' && menuItem.subModule === 'Manage Product')?.view ?? false
          },
          {
            name: 'Manage Category',
            iconClasses: 'far fa-circle',
            path: ['/list-category'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Products' && menuItem.subModule === 'Manage Category')?.view ?? false
          },
          {
            name: 'Manage Brand',
            iconClasses: 'far fa-circle',
            path: ['/list-brand'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Products' && menuItem.subModule === 'Manage Brand')?.view ?? false
          },
          {
            name: 'Product Reviews',
            iconClasses: 'far fa-circle',
            path: ['/product-reviews'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Products' && menuItem.subModule === 'Product Reviews')?.view ?? false
          }
        ],
        view: this.viewList.find(menuItem => menuItem.mainModule === 'Products' && !menuItem.subModule)['view']
      },
      {
        name: 'Orders',
        iconClasses: 'fas fa-toolbox',
        children: [
          {
            name: 'Manage Carts',
            iconClasses: 'far fa-circle',
            path: ['/list-cart'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Orders' && menuItem.subModule === 'Manage Carts')?.view ?? false
          },
          {
            name: 'Manage Orders',
            iconClasses: 'far fa-circle',
            path: ['/order-list'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Orders' && menuItem.subModule === 'Manage Orders')?.view ?? false
          },
          {
            name: 'Return & Refund',
            iconClasses: 'far fa-circle',
            path: ['/return-refund-list'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Orders' && menuItem.subModule === 'Return and Refund')?.view ?? false
          }
        ],
        view: this.viewList.find(menuItem => menuItem.mainModule === 'Orders' && !menuItem.subModule)['view']
      },
      {
        name: 'Reports',
        iconClasses: 'fas fa-file-invoice',
        path: ['/report-list'],
        view: this.viewList.find(menuItem => menuItem.mainModule === 'Reports' && !menuItem.subModule).view
      },
      {
        name: 'Users',
        iconClasses: 'fas fa-user',
        children: [
          {
            name: 'Manage Users',
            iconClasses: 'far fa-circle',
            path: ['/list-user'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Users' && menuItem.subModule === 'Manage Users')?.view ?? false
          },
          {
            name: 'Manage Roles',
            iconClasses: 'far fa-circle',
            path: ['/list-role'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Users' && menuItem.subModule === 'Manage Roles')?.view ?? false
          },
          {
            name: 'Delivery Partners',
            iconClasses: 'far fa-circle',
            path: ['/list-delivery-partners'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Users' && menuItem.subModule === 'Delivery Partners')?.view ?? false
          },
        ],
        view: this.viewList.find(menuItem => menuItem.mainModule === 'Users' && !menuItem.subModule)['view']
      },
      {
        name: 'Promo Codes',
        iconClasses: 'fas fa-donate',
        path: ['/list-promocode'],
        view: this.viewList.find(menuItem => menuItem.mainModule === 'Promo Codes' && !menuItem.subModule)['view']
      },
      {
        name: 'Settings',
        iconClasses: 'fas fa-cog',
        children: [
          {
            name: 'Manage Variant',
            iconClasses: 'far fa-circle',
            path: ['/list-variant'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Settings' && menuItem.subModule === 'Manage Variant')?.view ?? false
          }, {
            name: 'Manage Unit',
            iconClasses: 'far fa-circle',
            path: ['/list-units'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Settings' && menuItem.subModule === 'Manage Unit')?.view ?? false
          },
          {
            name: 'Manage Shipping',
            iconClasses: 'far fa-circle',
            path: ['/list-shipping'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Settings' && menuItem.subModule === 'Manage Shipping')?.view ?? false
          },
          {
            name: 'Manage Tax',
            iconClasses: 'far fa-circle',
            path: ['/list-tax'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Settings' && menuItem.subModule === 'Manage Tax')?.view ?? false
          },
          {
            name: 'Manage Warehouse',
            iconClasses: 'far fa-circle',
            path: ['/list-warehouse'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Settings' && menuItem.subModule === 'Manage Warehouse')?.view ?? false
          },
          {
            name: 'Manage Country',
            iconClasses: 'far fa-circle',
            path: ['/list-country'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Settings' && menuItem.subModule === 'Manage Country')?.view ?? false
          },
          {
            name: 'Manage Shipping',
            iconClasses: 'far fa-circle',
            path: ['/list-shipping'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Settings' && menuItem.subModule === 'Manage Shipping')?.view ?? false
          }
        ],
      },
      {
        name: 'Others',
        iconClasses: 'fas fa-bars',
        children: [
          {
            name: 'FAQ',
            iconClasses: 'far fa-circle',
            path: ['/faq-list'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Others' && menuItem.subModule === 'FAQ')?.view ?? false
          },
          {
            name: 'Contents',
            iconClasses: 'far fa-circle',
            path: ['/content-list'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Others' && menuItem.subModule === 'Contents')?.view ?? false
          },
          {
            name: 'Carousel Images',
            iconClasses: 'far fa-circle',
            path: ['/carousel-images-list'],
            view: this.viewList.find(menuItem => menuItem.mainModule === 'Others' && menuItem.subModule === 'Carousel Images')?.view ?? false
          }
        ],
        view: this.viewList.find(menuItem => menuItem.mainModule === 'Settings' && !menuItem.subModule)['view']
      }
    ];
    this.menu = this.HIDEICONSMENU
  }

  async fetchModuleViewAccess() {
    try {
      this.awsApi.loading.next(true);
      this.roleList = await this.common.getRole();
      const response = await this.awsApi.client.graphql({
        query: query.listModuleMasters
      });
      this.modules = response.data.listModuleMasters.items;
      this.modulesList = this.modules.filter((arr: any) => arr.pk === 'MAIN#');
      this.modulesList.sort((a: any, b: any) => a.sequenceNumber - b.sequenceNumber);

      await this.setAccessValues();
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
    }
  }

  async setAccessValues() {
    let showAnySubmodules
    _.each(this.modulesList, (module) => {
      showAnySubmodules = false
      this.subModulesList = this.modules
        .filter(arr => arr.pk === 'SUB#' + module.id)
        .sort((a: any, b: any) => a.sequenceNumber - b.sequenceNumber);
      if (this.subModulesList.length > 0) {
        _.each(this.subModulesList, (subModule) => {
          const view = this.roleList.some(role => role.moduleId === subModule.id && role.view);
          if (view) { showAnySubmodules = true }
          this.viewList.push({
            'mainModule': module.moduleName,
            'subModule': subModule.moduleName,
            'view': view
          })
        })

        this.viewList.push({
          'mainModule': module.moduleName,
          'view': showAnySubmodules
        })
      }
      else {
        const view = this.roleList.some(role => role.moduleId === module.id && role.view);
        this.viewList.push({
          'mainModule': module.moduleName,
          'view': view
        })
      }
    })
  }

  assignMenuType() {
    this.showMenu = this.appService.showMenu;
    if (this.showMenu) {
      this.menu = this.HIDEICONSMENU
    }
    else {
      this.menu = this.SHOWICONSMENU
    }
  }
}