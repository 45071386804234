<section id="userAdd">
    <div class="container-fluid">
        <div class="row mb-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card mb-1 heading-card">
                    <div class="card-body">
                        <div class="d-flex justify-content-start align-items-center">
                            <i class="fas fa-chevron-left" (click)="redirectToListUser()"></i>
                            <h6>
                                <span *ngIf="type=='edit'">Edit User</span>
                                <span *ngIf="type==''">Add New User</span>
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="card user-info-card" [formGroup]="addUserForm">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                <h5>User Information</h5>
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 mb-2">
                                <label>First Name
                                    <span class="mandatory">*</span></label>
                                <input type="text" class="form-control" formControlName="firstName" maxlength="50"
                                    (blur)="capitalizeFirstLetter('firstName')" placeholder="Enter First Name" />
                                <span class="text-danger"
                                    *ngIf="(addUserForm.controls.firstName.touched) && !addUserForm.controls.firstName?.valid">
                                    <div *ngIf="addUserForm.controls.firstName.errors['required']">
                                        First name is required.
                                    </div>
                                </span>
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 mb-2">
                                <label>Last Name </label>
                                <input type="text" class="form-control" minlength="2" formControlName="lastName"
                                    maxlength="50" (blur)="capitalizeFirstLetter('lastName')"
                                    placeholder="Enter Last Name" />
                            </div>
                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-2">
                                <label>Company Name</label>
                                <input type="text" class="form-control" formControlName="companyName"
                                    placeholder="Enter Company Name" />
                                <span class="text-danger"
                                    *ngIf="(addUserForm.controls.companyName.touched) && !addUserForm.controls.companyName?.valid">
                                    <div *ngIf="addUserForm.controls.companyName.errors['pattern']">
                                        Invalid company name.
                                    </div>
                                </span>
                            </div>
                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-2"
                                *ngIf="type!='edit'">
                                <label>Email
                                    <span class="mandatory">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter Email"
                                    formControlName="email" />
                                <span class="text-danger"
                                    *ngIf="(addUserForm.controls.email.touched) && !addUserForm.controls.email?.valid">
                                    <div *ngIf="addUserForm.controls.email.errors['required']">
                                        Email is required.
                                    </div>
                                    <div *ngIf="addUserForm.controls.email.errors['pattern']">
                                        Enter a valid email address
                                    </div>
                                </span>
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 mb-2">
                                <label>Phone Number
                                    <span class="mandatory">*</span></label>
                                <div class="input-group mb-3">
                                    <select class="form-select form-control" [(ngModel)]="defaultCountry"
                                        (change)="onSelectCountry()" formControlName="country"
                                        [ngModelOptions]="{standalone: true}">
                                        <option value="null" disabled>Select country</option>
                                        <option *ngFor="let country of countryCodes" [ngValue]="country">
                                            <ngx-flags [country]="country.code.toLowerCase()" [size]="'20px'"
                                                style="margin-right: 8px;"></ngx-flags>
                                            {{ country.name }} {{ country.dialCode }}
                                        </option>
                                    </select>
                                    <input type="text" class="form-control" maxlength="13" formControlName="phoneNumber"
                                        placeholder="9876543210" />
                                </div>
                                <span class="text-danger"
                                    *ngIf="(addUserForm.controls.phoneNumber.touched) && !addUserForm.controls.phoneNumber?.valid">
                                    <div *ngIf="addUserForm.controls.phoneNumber.errors['required']">
                                        Phone number is required.
                                    </div>
                                    <div *ngIf="addUserForm.controls.phoneNumber.errors['pattern']">
                                        Enter a valid phone number (e.g., 9876543210).
                                    </div>
                                </span>
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 mb-2">
                                <label>Role
                                    <span class="mandatory">*</span></label>
                                <select class="form-select form-control" aria-label="Default select example"
                                    [(ngModel)]="selectedRole" formControlName="role">
                                    <option [ngValue]="null" disabled>
                                        Select
                                    </option>
                                    <option *ngFor="let userRole of userRolesList" [ngValue]="userRole.id">
                                        {{ userRole.text }} </option>
                                </select>
                                <span class="text-danger"
                                    *ngIf="(addUserForm.controls.role.touched) && !addUserForm.controls.role?.valid">
                                    <div *ngIf="addUserForm.controls.role.errors['required']">
                                        Role is required.
                                    </div>
                                </span>
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 mb-2"
                                *ngIf="type!='edit'">
                                <label>Password
                                    <span class="mandatory">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter Password"
                                    formControlName="password" />
                                <span class="text-danger"
                                    *ngIf="(addUserForm.controls.password.touched) && !addUserForm.controls.password?.valid">
                                    <div *ngIf="addUserForm.controls.password.errors['required']">
                                        Password is required.
                                    </div>
                                    <div *ngIf="addUserForm.controls.password.errors['pattern']">
                                        Enter a valid password
                                    </div>
                                </span>
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 mb-2"
                                *ngIf="type!='edit'">
                                <label>Confirm Password
                                    <span class="mandatory">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter Confirm Password"
                                    formControlName="confirmPassword" />
                                <span class="text-danger"
                                    *ngIf="(addUserForm.controls.confirmPassword.touched) && !addUserForm.controls.confirmPassword?.valid">
                                    <div *ngIf="addUserForm.controls.confirmPassword.errors['required']">
                                        Confirm Password is required.
                                    </div>
                                    <div *ngIf="addUserForm.controls.confirmPassword.errors['pattern']">
                                        Enter a valid confirm password
                                    </div>
                                </span>
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 mb-2">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="multiFactor"
                                        [checked]="multiFactorAuth" (change)="toggleMultiFactorAuth()" />
                                    <label class="form-check-label ms-2 mt-1" for="multiFactor">
                                        Enable multi-factor authentication
                                    </label>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 mb-2">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="sendEmail"
                                        [checked]="emailNotification" (change)="toggleEmailNotification()" />
                                    <label class="form-check-label ms-2 mt-1" for="sendEmail">
                                        Send an email to new user about their account
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="d-flex justify-content-end gap-2">
                    <button type="button" class="btn cancel-btn" (click)="redirectToListUser()">Cancel</button>
                    <button *ngIf="type!='edit'" type="button" class="btn save-btn" [disabled]="!access['add']"
                        (click)="addUser()">Save</button>
                    <button *ngIf="type=='edit'" type="button" class="btn save-btn" [disabled]="!access['edit']"
                        (click)="editUser()">Update</button>
                </div>
            </div>
        </div>
    </div>
</section>