<section id="settingsSec">
    <div class="container-fluid">
        <div class="row mb-1 mt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card mb-1">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="d-flex justify-content-start align-items-center">
                                    <i class="fas fa-chevron-left" [routerLink]="['/']"></i>
                                    <h6>Settings</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                <div class="card mb-1 company-logo-img-card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <img [src]="secondaryLogo" />
                                <div class="d-flex align-items-start mt-2">
                                    <div class="nav flex-column nav-pills w-100" id="v-pills-tab" role="tablist"
                                        aria-orientation="vertical">
                                        <button class="nav-link active" id="v-pills-company-details-tab"
                                            data-bs-toggle="pill" data-bs-target="#v-pills-company-details"
                                            type="button" role="tab" aria-controls="v-pills-company-details"
                                            aria-selected="true" (click)="toggleButtons('v-pills-company-details')">
                                            Company Details
                                        </button>
                                        <button class="nav-link" id="v-pills-email-settings-tab" data-bs-toggle="pill"
                                            data-bs-target="#v-pills-email-settings" type="button" role="tab"
                                            aria-controls="v-pills-email-settings" aria-selected="false"
                                            (click)="toggleButtons('v-pills-email-settings')">
                                            Email Settings
                                        </button>
                                        <button class="nav-link" id="v-pills-payments-tab" data-bs-toggle="pill"
                                            data-bs-target="#v-pills-payments" type="button" role="tab"
                                            aria-controls="v-pills-payments" aria-selected="false"
                                            (click)="toggleButtons('v-pills-payments')">
                                            Payments
                                        </button>
                                        <button class="nav-link" id="v-pills-themes-tab" data-bs-toggle="pill"
                                            data-bs-target="#v-pills-themes" type="button" role="tab"
                                            aria-controls="v-pills-themes" aria-selected="false"
                                            (click)="toggleButtons('v-pills-themes')">
                                            Themes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-8 col-sm-12 col-xs-12">
                <div class="card mb-1 tabs-body-card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="tab-content" id="v-pills-tabContent">
                                    <div class="tab-pane fade show active" id="v-pills-company-details" role="tabpanel"
                                        aria-labelledby="v-pills-company-details-tab" [formGroup]="companyDetailsForm">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div
                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                        <div class="caption">
                                                            <h6>Company Details</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div
                                                        class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">
                                                        <div class="row">
                                                            <div
                                                                class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">
                                                                <div>
                                                                    <label class="mb-0 d-block">Primary Logo <span
                                                                            class="mandatory">*</span></label>
                                                                    <p>Logo with company name and
                                                                        without background</p>
                                                                </div>
                                                                <form id="primary-image-upload-form"
                                                                    class="primary-logo-uploader">
                                                                    <input id="primary-image-upload" type="file"
                                                                        name="fileUpload" accept="image/*"
                                                                        (change)="fileSave($event, 'primaryLogo')" />
                                                                    @if (
                                                                    companyDetailsForm.get(
                                                                    'primaryLogo'
                                                                    )?.value == ''
                                                                    ) {
                                                                    <label for="primary-image-upload" id="file-drag">
                                                                        <div id="start">
                                                                            <div class="upload-caption">
                                                                                <i class="far fa-image"></i>
                                                                                <h5>Add Logo</h5>
                                                                                <h5>The logo size should be 250x140</h5>
                                                                            </div>
                                                                        </div>
                                                                    </label>
                                                                    } @else {
                                                                    <div id="after-upload">
                                                                        <div class="text-right">
                                                                            <i class="fas fa-trash-alt pe-1"
                                                                                (click)="deleteLogo('primary')"></i>
                                                                        </div>
                                                                        <img id="file-image"
                                                                            [src]="
                                                                                                                                                                        companyDetailsForm.get(
                                                                                                                                                                            'primaryLogo'
                                                                                                                                                                        )?.value
                                                                                                                                                                    " />
                                                                    </div>
                                                                    }
                                                                </form>
                                                            </div>
                                                            <div
                                                                class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">
                                                                <div>
                                                                    <label class="mb-0 d-block">Secondary Logo <span
                                                                            class="mandatory">*</span></label>
                                                                    <p>Company logo without <br>background</p>
                                                                </div>
                                                                <form id="secondary-image-upload-form"
                                                                    class="secondary-logo-uploader">
                                                                    <input id="secondary-image-upload" type="file"
                                                                        name="fileUpload" accept="image/*"
                                                                        (change)="fileSave($event, 'secondaryLogo')" />
                                                                    @if (
                                                                    companyDetailsForm.get(
                                                                    'secondaryLogo'
                                                                    )?.value == ''
                                                                    ) {
                                                                    <label for="secondary-image-upload" id="file-drag">
                                                                        <div id="start">
                                                                            <div class="upload-caption">
                                                                                <i class="far fa-image"></i>
                                                                                <h5>Add Logo</h5>
                                                                                <h5>The logo size should be 140x140</h5>
                                                                            </div>
                                                                        </div>
                                                                    </label>
                                                                    } @else {
                                                                    <div id="after-upload">
                                                                        <div class="text-right">
                                                                            <i class="fas fa-trash-alt pe-1"
                                                                                (click)="deleteLogo('secondary')"></i>
                                                                        </div>
                                                                        <img id="file-image"
                                                                            [src]="
                                                                                                                                                                        companyDetailsForm.get(
                                                                                                                                                                            'secondaryLogo'
                                                                                                                                                                        )?.value
                                                                                                                                                                    " />
                                                                    </div>
                                                                    }
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">
                                                        <div class="row">
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <label>Company Name
                                                                    <span class="mandatory">*</span></label>
                                                                <input type="text" class="form-control"
                                                                    placeholder="Enter Company Name"
                                                                    formControlName="companyName" />
                                                                <span class="text-danger"
                                                                    *ngIf="(companyDetailsForm.controls.companyName.touched || submitted) && !companyDetailsForm.controls.companyName.valid">
                                                                    <div
                                                                        *ngIf="companyDetailsForm.controls.companyName.errors['required']">
                                                                        Company name is required.</div>
                                                                </span>
                                                            </div>
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <label>Company Website
                                                                    <span class="mandatory">*</span></label>
                                                                <input type="text" class="form-control"
                                                                    formControlName="webSite"
                                                                    placeholder="Enter Company Website" />
                                                                <span class="text-danger"
                                                                    *ngIf="(companyDetailsForm.controls.webSite.touched || submitted) && !companyDetailsForm.controls.webSite.valid">
                                                                    <div
                                                                        *ngIf="companyDetailsForm.controls.webSite.errors['required']">
                                                                        Company website is required.</div>
                                                                </span>
                                                            </div>
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <label>Brand Name <span
                                                                        class="mandatory">*</span></label>
                                                                <input type="text" class="form-control"
                                                                    formControlName="brandName"
                                                                    placeholder="Enter Brand Name" />
                                                                <span class="text-danger"
                                                                    *ngIf="(companyDetailsForm.controls.brandName.touched || submitted) && !companyDetailsForm.controls.brandName.valid">
                                                                    <div
                                                                        *ngIf="companyDetailsForm.controls.brandName.errors['required']">
                                                                        Brand Name is required.</div>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card mt-2">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div
                                                        class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2">
                                                        <label>Email
                                                            <span class="mandatory">*</span></label>
                                                        <input type="email" class="form-control" formControlName="email"
                                                            placeholder="Enter Email" />
                                                        <span class="text-danger"
                                                            *ngIf="(companyDetailsForm.controls.email.touched || submitted) && !companyDetailsForm.controls.email.valid">
                                                            <div
                                                                *ngIf="companyDetailsForm.controls.email.errors['required']">
                                                                Email is required.</div>
                                                            <div
                                                                *ngIf="companyDetailsForm.controls.email.errors['pattern']">
                                                                Enter a valid email.</div>
                                                        </span>
                                                    </div>
                                                    <div
                                                        class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2">
                                                        <label>Phone Number
                                                            <span class="mandatory">*</span></label>
                                                        <input type="number" (keypress)="preventE($event)"
                                                            class="form-control" formControlName="phoneNumber"
                                                            placeholder="Enter Phone Number" />
                                                        <span class="text-danger"
                                                            *ngIf="(companyDetailsForm.controls.phoneNumber.touched || submitted) && !companyDetailsForm.controls.phoneNumber.valid">
                                                            <div
                                                                *ngIf="companyDetailsForm.controls.phoneNumber.errors['required']">
                                                                Phone number is required.</div>
                                                        </span>
                                                    </div>
                                                    <div
                                                        class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2">
                                                        <label>Country
                                                            <span class="mandatory">*</span></label>
                                                        <select class="form-select form-control"
                                                            formControlName="country"
                                                            aria-label="Default select example">
                                                            <option [ngValue]="null" selected disabled>
                                                                Choose Country
                                                            </option>
                                                            <option *ngFor="let item of countryList"
                                                                [ngValue]="item.name">
                                                                {{item.name}}
                                                            </option>
                                                        </select>
                                                        <span class="text-danger"
                                                            *ngIf="(companyDetailsForm.controls.country.touched || submitted) && !companyDetailsForm.controls.country.valid">
                                                            <div
                                                                *ngIf="companyDetailsForm.controls.country.errors['required']">
                                                                Country is required.</div>
                                                        </span>
                                                    </div>
                                                    <div
                                                        class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2">
                                                        <label>State
                                                            <span class="mandatory">*</span></label>
                                                        <input type="email" class="form-control" formControlName="state"
                                                            placeholder="Enter State" />
                                                        <span class="text-danger"
                                                            *ngIf="(companyDetailsForm.controls.state.touched || submitted) && !companyDetailsForm.controls.state.valid">
                                                            <div
                                                                *ngIf="companyDetailsForm.controls.state.errors['required']">
                                                                State is required.</div>
                                                        </span>
                                                    </div>
                                                    <div
                                                        class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2">
                                                        <label>City
                                                            <span class="mandatory">*</span></label>
                                                        <input type="email" class="form-control" formControlName="city"
                                                            placeholder="Enter City" />
                                                        <span class="text-danger"
                                                            *ngIf="(companyDetailsForm.controls.city.touched || submitted) && !companyDetailsForm.controls.city.valid">
                                                            <div
                                                                *ngIf="companyDetailsForm.controls.city.errors['required']">
                                                                City is required.</div>
                                                        </span>
                                                    </div>
                                                    <div
                                                        class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2">
                                                        <label>Address Line 1
                                                            <span class="mandatory">*</span></label>
                                                        <input type="email" class="form-control"
                                                            formControlName="addressLine1"
                                                            placeholder="Enter Address Line 1" />
                                                        <span class="text-danger"
                                                            *ngIf="(companyDetailsForm.controls.addressLine1.touched || submitted) && !companyDetailsForm.controls.addressLine1.valid">
                                                            <div
                                                                *ngIf="companyDetailsForm.controls.addressLine1.errors['required']">
                                                                Address is required.</div>
                                                        </span>
                                                    </div>
                                                    <div
                                                        class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2">
                                                        <label>Address Line 2
                                                            <span class="mandatory">*</span></label>
                                                        <input type="email" class="form-control"
                                                            formControlName="addressLine2"
                                                            placeholder="Enter Address Line 2" />
                                                        <span class="text-danger"
                                                            *ngIf="(companyDetailsForm.controls.addressLine2.touched || submitted) && !companyDetailsForm.controls.addressLine2.valid">
                                                            <div
                                                                *ngIf="companyDetailsForm.controls.addressLine2.errors['required']">
                                                                Address is required.</div>
                                                        </span>
                                                    </div>
                                                    <div
                                                        class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2">
                                                        <label>Zip Code
                                                            <span class="mandatory">*</span></label>
                                                        <input type="email" class="form-control"
                                                            formControlName="zipCode" placeholder="Enter Zip Code" />
                                                        <span class="text-danger"
                                                            *ngIf="(companyDetailsForm.controls.zipCode.touched || submitted) && !companyDetailsForm.controls.zipCode.valid">
                                                            <div
                                                                *ngIf="companyDetailsForm.controls.zipCode.errors['required']">
                                                                Zipcode is required.</div>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card mt-2">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div
                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                        <div class="caption">
                                                            <h6>Advance Settings</h6>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                        <label>Copyright Content</label>
                                                        <input type="email" class="form-control"
                                                            formControlName="copyrightText" />
                                                    </div>
                                                    <div
                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                        <label>SEO Content</label>
                                                        <input type="email" class="form-control"
                                                            formControlName="seoDescription" />
                                                        <span class="text-danger"
                                                            *ngIf="companyDetailsForm.controls.seoDescription.touched && !companyDetailsForm.controls.seoDescription?.valid">
                                                            <div
                                                                *ngIf="companyDetailsForm.controls.seoDescription.errors['maxlength']">
                                                                SEO content should not exceed more than 150 characters.
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <div
                                                        class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-2">
                                                        <div>
                                                            <label class="mb-0 d-block">Company Icon <span
                                                                    class="mandatory">*</span></label>
                                                            <p>Icon without
                                                                background</p>
                                                        </div>
                                                        <form id="icon-image-upload-form" class="icon-logo-uploader">
                                                            <input id="icon-image-upload" type="file" name="fileUpload"
                                                                accept="image/*"
                                                                (change)="fileSave($event, 'companyIcon')" />
                                                            @if (
                                                            companyDetailsForm.get(
                                                            'iconUrl'
                                                            )?.value == ''
                                                            ) {
                                                            <label for="icon-image-upload" id="file-drag">
                                                                <div id="start">
                                                                    <div class="upload-caption">
                                                                        <i class="far fa-image"></i>
                                                                        <h5>Add Icon</h5>
                                                                        <h5>Click here to upload an icon</h5>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                            } @else {
                                                            <div id="after-upload">
                                                                <div class="text-right">
                                                                    <i class="fas fa-trash-alt pe-1"
                                                                        (click)="deleteLogo('companyIcon')"></i>
                                                                </div>
                                                                <img id="file-image"
                                                                    [src]="
                                                                                                                                                                        companyDetailsForm.get(
                                                                                                                                                                            'iconUrl'
                                                                                                                                                                        )?.value
                                                                                                                                                                    " />
                                                            </div>
                                                            }
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card mt-2">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div
                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                        <div class="caption">
                                                            <h6>Manage Apps & Certifications</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2"
                                                        formGroupName="appUrlForm">
                                                        <div class="row">
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <label>Select Type <span
                                                                        class="mandatory">*</span></label>
                                                                <select class="form-select form-control"
                                                                    formControlName="type" (change)="onSelectType()"
                                                                    aria-label="Default select example"
                                                                    [(ngModel)]="defaultAppType">
                                                                    <option [ngValue]="null" selected disabled>
                                                                        Choose...
                                                                    </option>
                                                                    <option *ngFor="let type of typeList"
                                                                        [ngValue]="type">
                                                                        {{type}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            <div *ngIf="appUrlFormControl.type?.value ==  typeList[2]"
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <label>Certificate Name <span
                                                                        class="mandatory">*</span></label>
                                                                <input type="text" class="form-control"
                                                                    formControlName="certificationName"
                                                                    placeholder="Enter certificate name" />
                                                            </div>
                                                            <div *ngIf="appUrlFormControl.type?.value ==  typeList[2]"
                                                                class=" col-xxl-12 col-xl-12 col-lg-12 col-md-12
                                                                col-sm-12 col-xs-12 mb-2">
                                                                <label>Target url <span
                                                                        class="mandatory">*</span></label>
                                                                <input type="text" class="form-control"
                                                                    formControlName="redirectTo"
                                                                    placeholder="Enter target url" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2"
                                                        formGroupName="appUrlForm">
                                                        <div class="row">
                                                            <div *ngIf="appUrlFormControl.type?.value ==  typeList[2]"
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div>
                                                                    <label class="mb-0 d-block">Icon <span
                                                                            class="mandatory">*</span>
                                                                    </label>
                                                                </div>
                                                                <form id="mobileAppsIcon1-image-upload-form"
                                                                    class="mobile-apps-icon-uploader">
                                                                    <input id="mobileAppsIcon1-image-upload" type="file"
                                                                        name="fileUpload" accept="image/*"
                                                                        (change)="fileSave($event, 'mobileApp')" />
                                                                    @if (appUrlFormControl.url?.value == '' ||
                                                                    appUrlFormControl.url?.value == null){
                                                                    <label for="mobileAppsIcon1-image-upload"
                                                                        id="file-drag">
                                                                        <div id="start">
                                                                            <div class="upload-caption">
                                                                                <i class="far fa-image"></i>
                                                                                <h5>Add Icon</h5>
                                                                                <h5>The Icon size should be
                                                                                    117x36</h5>
                                                                            </div>
                                                                        </div>
                                                                    </label>
                                                                    } @else {
                                                                    <div id="after-upload">
                                                                        <div class="text-right">
                                                                            <i class="fas fa-trash-alt pe-1"
                                                                                (click)="deleteLogo('mobileApp')"></i>
                                                                        </div>
                                                                        <img id="file-image"
                                                                            [src]="appUrlFormControl.url?.value" />
                                                                    </div>
                                                                    }
                                                                </form>
                                                            </div>
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div *ngIf="appUrlFormControl.type?.value !=  typeList[2]"
                                                                    class=" col-xxl-12 col-xl-12 col-lg-12 col-md-12
                                                                    col-sm-12 col-xs-12 mb-2">
                                                                    <label>Target url <span
                                                                            class="mandatory">*</span></label>
                                                                    <input type="text" class="form-control"
                                                                        formControlName="redirectTo"
                                                                        placeholder="Enter target url" />
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                <div class="text-right"
                                                                    *ngIf="!isEditAppDetailsEnabled">
                                                                    <button type="button" class="btn add-logo-btn"
                                                                        (click)="addAppItem()">
                                                                        Add
                                                                    </button>
                                                                </div>
                                                                <div class="d-flex justify-content-end align-items-center gap-2"
                                                                    *ngIf="isEditAppDetailsEnabled">
                                                                    <button type="button" class="btn cancel-btn"
                                                                        (click)="cancelAppItem()">
                                                                        Cancel
                                                                    </button>
                                                                    <button type="button" class="btn save-btn"
                                                                        (click)="editAppItem()">
                                                                        Save
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <div class="table-responsive" *ngIf="addedUrlsList?.length>0">
                                                            <table class="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th nowrap>Logo</th>
                                                                        <th nowrap>Type</th>
                                                                        <th nowrap>Target url</th>
                                                                        <th nowrap>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody formArrayName="mobileAppUrls">
                                                                    <tr *ngFor="let item of addedUrlsList.controls;let i = index"
                                                                        [formGroupName]="i">
                                                                        <td>
                                                                            <span *ngIf="item.get('url').value">

                                                                                <img
                                                                                    [src]="item.get('url').value"></span>
                                                                            <span
                                                                                *ngIf="!item.get('url').value && item.get('type').value == typeList[1]"><img
                                                                                    src="../../../../../assets/img/appStore.png"></span>
                                                                            <span
                                                                                *ngIf="!item.get('url').value && item.get('type').value == typeList[0]"><img
                                                                                    src="../../../../../assets/img/playStore.png"></span>
                                                                        </td>
                                                                        <td>{{item.get('type').value}}</td>
                                                                        <td [title]="item.get('redirectTo').value">
                                                                            {{item.get('redirectTo').value}}
                                                                        </td>
                                                                        <td>
                                                                            <i class="fas fa-edit me-1"
                                                                                (click)="editAppDetails(i)"></i>
                                                                            <i class="fas fa-trash-alt"
                                                                                (click)="deleteAppDetails(i)"></i>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div
                                                        class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2">
                                                        <label>Currency</label>
                                                        <input type="text" class="form-control"
                                                            formControlName="currency"
                                                            [readonly]="companyDetails?.length>0" />
                                                    </div>
                                                    <div
                                                        class="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 mb-2">
                                                        <div class="mt-38px">
                                                            <input class="form-check-input" type="checkbox"
                                                                id="sequence" [checked]="false"
                                                                formControlName="categorySequenced" />
                                                            <label class="ms-2">List Categories based on
                                                                sequence
                                                                number</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div
                                                        class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-2">
                                                        <div>
                                                            <label>Number of days to return an order <span
                                                                    class="mandatory">*</span></label>
                                                            <div class="input-group mb-3">
                                                                <input formControlName="daysToReturn" type="text"
                                                                    class="form-control"
                                                                    (keypress)="preventE($event)" />
                                                                <div class="input-group-append">
                                                                    <span class="input-group-text"
                                                                        id="basic-addon2">day(s)</span>
                                                                </div>
                                                                <span class="text-danger"
                                                                    *ngIf="(companyDetailsForm.controls.daysToReturn.touched || submitted) && !companyDetailsForm.controls.daysToReturn.valid">
                                                                    <div
                                                                        *ngIf="companyDetailsForm.controls.daysToReturn.errors['required']">
                                                                        Number
                                                                        of days to return an order is
                                                                        required.</div>
                                                                    <div
                                                                        *ngIf="companyDetailsForm.controls.daysToReturn.errors['pattern']">
                                                                        Accepts only digits from 1 to 9. </div>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="v-pills-email-settings" role="tabpanel"
                                        aria-labelledby="v-pills-email-settings-tab">
                                        <div class="row" [formGroup]="emailSettingForm">
                                            <div
                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                <div class="caption">
                                                    <h6>Email Settings</h6>
                                                </div>
                                            </div>
                                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">
                                                <label>Type <span class="mandatory">*</span></label>
                                                <select class="form-select form-control"
                                                    aria-label="Default select example" formControlName="type"
                                                    [(ngModel)]="defaultType">
                                                    <option [ngValue]="null" disabled>Select Type</option>
                                                    <option *ngFor="let item of notificationType" [value]="item">
                                                        {{item | formatDocType}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">
                                                <label>Email To <span class="mandatory">*</span></label>
                                                <input type="text" class="form-control" formControlName="emailTo"
                                                    placeholder="Enter email-id (Separate with comma)" />
                                                <span class="text-danger"
                                                    *ngIf="(emailSettingForm.controls.emailTo.touched || submitted) && !emailSettingForm.controls.emailTo.valid">
                                                    <div *ngIf="emailSettingForm.controls.emailTo.errors['required']">
                                                        Email to is required.</div>
                                                </span>
                                            </div>
                                            <div class="row mt-3 mb-3">
                                                <div
                                                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div class="d-flex justify-content-end align-items-center gap-2">
                                                        <button class="btn cancel-btn" (click)="cancelEmailSetting()"
                                                            *ngIf="!showThemesButton && !showCompanyButton && !showPaymentButton">
                                                            Cancel
                                                        </button>
                                                        <button class="btn save-btn"
                                                            [disabled]="emailSettingForm.invalid"
                                                            *ngIf="!editEmailSetting && !showThemesButton && !showCompanyButton && !showPaymentButton"
                                                            (click)="addEmailSetting()">
                                                            Add
                                                        </button>
                                                        <button class="btn save-btn"
                                                            [disabled]="emailSettingForm.invalid"
                                                            *ngIf="editEmailSetting && !showThemesButton && !showCompanyButton && !showPaymentButton"
                                                            (click)="addEmailSetting()">
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="listSettings?.length>0">
                                            <div
                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                <div class="table-responsive">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Type</th>
                                                                <th>Email To</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            @for (
                                                            setting of listSettings;
                                                            track setting.id
                                                            ) {
                                                            <tr>
                                                                <td nowrap>
                                                                    {{setting.notificationType | formatDocType}}
                                                                </td>
                                                                <td>
                                                                    <div class="chip">
                                                                        <span
                                                                            *ngFor="let item of setting.emailTo;let i=index">
                                                                            <span
                                                                                *ngIf="i==(setting.emailTo)?.length-1">{{item}}</span>
                                                                            <span
                                                                                *ngIf="i!=(setting.emailTo).length-1">{{item}},
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td nowrap>
                                                                    <span class="closebtn"
                                                                        *ngIf="setting.emailTo?.length>0">
                                                                        <i class="fas fa-edit me-1"
                                                                            (click)="editEmailSettings(setting)"></i>
                                                                        <i class="fas fa-trash-alt"
                                                                            (click)="deleteEmailSettings(setting)"></i></span>
                                                                </td>
                                                            </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="v-pills-payments" role="tabpanel"
                                        aria-labelledby="v-pills-payments-tab">
                                        <div class="row">
                                            <div
                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                <div class="d-flex justify-content-between align-items-center caption">
                                                    <h6>Payments</h6>
                                                    <button type="button" *ngIf="!(isEditPayment || isAddPayment)"
                                                        class="btn add-new-btn" (click)="toggleRow()">
                                                        Add New
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="isAddPayment || isEditPayment"
                                            [formGroup]="paymentForm">
                                            <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12 mb-2">
                                                <div>
                                                    <label>Payment Method
                                                        <span class="mandatory">*</span>
                                                    </label>
                                                    <select class="form-select form-control"
                                                        aria-label="Default select example"
                                                        formControlName="paymentMethod"
                                                        [(ngModel)]="defaultPaymentMethod">
                                                        <option [ngValue]="null" selected>Choose payment method
                                                        </option>
                                                        <option selected *ngFor="let paymentMethod of paymentMethodList"
                                                            [ngValue]="paymentMethod['value']">
                                                            {{ paymentMethod['value'] | formatDocType }}
                                                    </select>
                                                    <span class="text-danger"
                                                        *ngIf="(paymentForm.controls.paymentMethod.touched || submitted) && !paymentForm.controls.paymentMethod.valid">
                                                        <div
                                                            *ngIf="paymentForm.controls.paymentMethod.errors['required']">
                                                            Payment method is required.</div>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12 mb-2">
                                                <div>
                                                    <label>Payment Provider</label>
                                                    <input type="text" class="form-control" formControlName="provider"
                                                        placeholder="Enter Payment Provider" />
                                                </div>
                                            </div>
                                            <div
                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                <div class="row">
                                                    <div
                                                        class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-2">
                                                        <div>
                                                            <label class="mb-0">Logo <span
                                                                    class="mandatory">*</span></label>
                                                        </div>
                                                        <form id="image-upload-form" class="logo-uploader">
                                                            <input id="image-upload" type="file" name="fileUpload"
                                                                accept="image/*"
                                                                (change)="fileSave($event, 'payment')" />
                                                            <label *ngIf="paymentForm.get('logoUrl')?.value == ''"
                                                                for="image-upload" id="file-drag">
                                                                <div id="start">
                                                                    <div class="upload-caption">
                                                                        <i class="far fa-image"></i>
                                                                        <h5>Add Logo</h5>
                                                                        <p>Logo without background</p>
                                                                        <h5>The logo size should be 250x140
                                                                        </h5>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                            <div *ngIf="paymentForm.get('logoUrl').value!=''"
                                                                id="after-upload">
                                                                <div class="text-right">
                                                                    <i class="fas fa-trash-alt pe-1"
                                                                        (click)="deleteLogo('payment')"></i>
                                                                </div>
                                                                <img id="file-image"
                                                                    [src]="paymentForm.get('logoUrl')?.value" />
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div
                                                        class="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-12 col-xs-12 mb-2">
                                                        <div class="row">
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div>
                                                                    <label>Staging Url</label>
                                                                    <input type="text" class="form-control"
                                                                        formControlName="stagingUrl"
                                                                        placeholder="Enter Staging Url" />
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-12 col-xs-12 mb-2 mt-5">
                                                                <div>
                                                                    <label>Production Url</label>
                                                                    <input type="text" class="form-control"
                                                                        formControlName="productionUrl"
                                                                        placeholder="Enter Production Url" />
                                                                </div>
                                                            </div>
                                                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-2 mt-5"
                                                                *ngIf="isEditPayment">
                                                                <div>
                                                                    <label>Status
                                                                    </label>
                                                                    <select class="form-select form-control"
                                                                        aria-label="Default select example"
                                                                        [(ngModel)]="paymentStatus"
                                                                        [ngModelOptions]="{standalone: true}">
                                                                        <option [ngValue]="null" disabled>Choose
                                                                            status
                                                                        </option>
                                                                        <option selected
                                                                            *ngFor="let status of statusList"
                                                                            [ngValue]="status">
                                                                            {{ status | formatDocType }}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-3" *ngIf="isAddPayment || isEditPayment">
                                            <div class=" col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div class="d-flex justify-content-end align-items-center gap-2">
                                                    <button class="btn cancel-btn" (click)="cancelForm()">
                                                        Cancel
                                                    </button>
                                                    <button class="btn save-btn" [disabled]="paymentForm.invalid"
                                                        *ngIf="isAddPayment" (click)="addPayment()">
                                                        Save
                                                    </button>
                                                    <button class="btn save-btn" [disabled]="paymentForm.invalid"
                                                        *ngIf="isEditPayment" (click)="editPayment()">
                                                        Update
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-3" *ngIf="!(isAddPayment || isEditPayment)">
                                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2"
                                                *ngFor="let payment of paymentMethod">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <div>
                                                            <h6>
                                                                {{payment.method | formatDocType}}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="row">
                                                            <div class="col-md-12 mb-2">
                                                                <div class="d-flex justify-content-between">
                                                                    <p>
                                                                        Payment
                                                                        Method
                                                                    </p>
                                                                    <div>
                                                                        <i class="fas fa-edit pe-1"
                                                                            (click)="showEditPaymentMode(payment)"></i>
                                                                        <i class="fas fa-trash-alt pt-1"
                                                                            (click)="deletePaymentMethod(payment)"></i>
                                                                    </div>
                                                                </div>
                                                                <h6>
                                                                    {{payment.method | formatDocType}}
                                                                </h6>
                                                            </div>
                                                            <div class="col-md-12 mb-2" *ngIf="payment.provider">
                                                                <p>
                                                                    Provider
                                                                </p>
                                                                <h6>
                                                                    {{payment.provider}}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tab-pane fade" id="v-pills-themes" role="tabpanel"
                                        aria-labelledby="v-pills-themes-tab">
                                        <div class="row">
                                            <div class="col-12">
                                                <ul class="nav nav-pills justify-content-end mb-3" id="pills-tab"
                                                    role="tablist">
                                                    <li class="nav-item" role="presentation">
                                                        <button
                                                            [ngClass]="selectedMode==='web' ? 'nav-link active' : 'nav-link'"
                                                            id="pills-web-tab" data-bs-toggle="pill"
                                                            data-bs-target="#pills-web" type="button" role="tab"
                                                            aria-controls="pills-web" aria-selected="true"
                                                            (click)="selectedMode='web'">Web</button>
                                                    </li>
                                                    <li class="nav-item" role="presentation">
                                                        <button
                                                            [ngClass]="selectedMode==='mobile' ? 'nav-link active' : 'nav-link'"
                                                            id="pills-mobile-tab" data-bs-toggle="pill"
                                                            data-bs-target="#pills-mobile" type="button" role="tab"
                                                            aria-controls="pills-mobile" aria-selected="false"
                                                            (click)="selectedMode='mobile'">Mobile</button>
                                                    </li>
                                                </ul>
                                                <div class="tab-content" id="pills-tabContent">
                                                    <div class="tab-pane fade show active" id="pills-web"
                                                        role="tabpanel" aria-labelledby="pills-web-tab"
                                                        [formGroup]="themeForm">
                                                        <div class="row d-none">
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="theme-caption ps-2">
                                                                    <h6>
                                                                        Select Preferred Theme
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-12 mb-2">
                                                                <div class="card">
                                                                    <div class="card-body"></div>
                                                                    <div class="card-footer">
                                                                        <div
                                                                            class="d-flex justify-content-between align-items-center">
                                                                            <h6>Light</h6>
                                                                            <i class="fas fa-check-circle"
                                                                                (click)="toggleColorMode('Light')"
                                                                                [ngClass]="{ 'active': themeFormControl['preferredTheme']?.value === '#ffffff' }"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-12 mb-2">
                                                                <div class="card">
                                                                    <div class="card-body custom-color">
                                                                        <input type="color"
                                                                            class="form-control color-form-control"
                                                                            placeholder=""
                                                                            (change)="onColorChange($event)"
                                                                            formControlName="preferredTheme">
                                                                    </div>
                                                                    <div class="card-footer">
                                                                        <div
                                                                            class="d-flex justify-content-between align-items-center">
                                                                            <h6>Custom Mode</h6>
                                                                            <i class="fas fa-check-circle"
                                                                                (click)="toggleColorMode('Custom')"
                                                                                [ngClass]="{ 'active': themeFormControl['preferredTheme']?.value != '#ffffff' }"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div
                                                                    class="theme-caption ps-2 d-flex justify-content-between align-items-center">
                                                                    <h6>
                                                                        Header
                                                                    </h6>
                                                                    <button type="button" class="btn btn-link pe-2"
                                                                        (click)="resetTheme()">Reset</button>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                                                <div class="row">
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="theme-caption ps-2">
                                                                            <h6>
                                                                                Header Background
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="card">
                                                                            <div class="card-body">
                                                                                <input type="color"
                                                                                    formControlName="headerBackgroundColor"
                                                                                    class="form-control color-form-control"
                                                                                    placeholder="">
                                                                            </div>
                                                                            <div class="card-footer">
                                                                                <div
                                                                                    class="d-flex justify-content-between align-items-center">
                                                                                    <h6>{{themeFormControl['headerBackgroundColor'].value}}
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                                                <div class="row">
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="theme-caption ps-2">
                                                                            <h6>
                                                                                Header Text
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="card">
                                                                            <div class="card-body">
                                                                                <input type="color"
                                                                                    formControlName="headerTextColor"
                                                                                    class="form-control color-form-control"
                                                                                    placeholder="">
                                                                            </div>
                                                                            <div class="card-footer">
                                                                                <div
                                                                                    class="d-flex justify-content-between align-items-center">
                                                                                    <h6>{{themeFormControl['headerTextColor'].value}}
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                                                <div class="row">
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="theme-caption ps-2">
                                                                            <h6>
                                                                                Header Icon
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="card">
                                                                            <div class="card-body">
                                                                                <input type="color"
                                                                                    formControlName="headerIconColor"
                                                                                    class="form-control color-form-control"
                                                                                    placeholder="">
                                                                            </div>
                                                                            <div class="card-footer">
                                                                                <div
                                                                                    class="d-flex justify-content-between align-items-center">
                                                                                    <h6>{{themeFormControl['headerIconColor'].value}}
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="theme-caption ps-2">
                                                                    <h6>
                                                                        Footer
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                                                <div class="row">
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="theme-caption ps-2">
                                                                            <h6>
                                                                                Footer Background
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="card">
                                                                            <div class="card-body">
                                                                                <input type="color"
                                                                                    formControlName="footerBackgroundColor"
                                                                                    class="form-control color-form-control"
                                                                                    placeholder="">
                                                                            </div>
                                                                            <div class="card-footer">
                                                                                <div
                                                                                    class="d-flex justify-content-between align-items-center">
                                                                                    <h6>{{themeFormControl['footerBackgroundColor'].value}}
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                                                <div class="row">
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="theme-caption ps-2">
                                                                            <h6>
                                                                                Footer Heading
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="card">
                                                                            <div class="card-body">
                                                                                <input type="color"
                                                                                    formControlName="footerHeadingColor"
                                                                                    class="form-control color-form-control"
                                                                                    placeholder="">
                                                                            </div>
                                                                            <div class="card-footer">
                                                                                <div
                                                                                    class="d-flex justify-content-between align-items-center">
                                                                                    <h6>{{themeFormControl['footerHeadingColor'].value}}
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                                                <div class="row">
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="theme-caption ps-2">
                                                                            <h6>
                                                                                Footer Text
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="card">
                                                                            <div class="card-body">
                                                                                <input type="color"
                                                                                    formControlName="footerTextColor"
                                                                                    class="form-control color-form-control"
                                                                                    placeholder="">
                                                                            </div>
                                                                            <div class="card-footer">
                                                                                <div
                                                                                    class="d-flex justify-content-between align-items-center">
                                                                                    <h6>{{themeFormControl['footerTextColor'].value}}
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="theme-caption ps-2">
                                                                    <h6>
                                                                        Select Font
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="table-responsive">
                                                                    <table class="table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td nowrap
                                                                                    *ngFor="let fontItem of webFonts; let fontIndex = index">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input"
                                                                                            type="checkbox"
                                                                                            [attr.id]="'font_'+fontIndex"
                                                                                            (change)="setFont(fontIndex,'web')"
                                                                                            [checked]="fontItem.checked" />
                                                                                        <label class="form-check-label"
                                                                                            [attr.for]="'font_'+fontIndex">
                                                                                            {{fontItem.value}}
                                                                                        </label>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="theme-caption ps-2">
                                                                    <h6>
                                                                        Navigation
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="table-responsive">
                                                                    <table class="table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Navigation Menu
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            formControlName="navigationMenuFontWeight"
                                                                                            aria-label="Default select example">
                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                        <span
                                                                                            *ngIf="themeFormControl['navigationMenuFontSize']?.value">{{themeFormControl['navigationMenuFontSize']?.value}}
                                                                                            Px,</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td class="custm-range" nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <i class="far fa-circle"></i>
                                                                                        <input id="navigation"
                                                                                            type="range"
                                                                                            class="form-range"
                                                                                            id="navigation" min="10"
                                                                                            max="14" step="1"
                                                                                            formControlName="navigationMenuFontSize">
                                                                                        <i class="far fa-circle"></i>
                                                                                    </div>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <p>10 Px</p>
                                                                                        <p>14 Px</p>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Menu Color
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div class="d-flex gap-2">
                                                                                        <input type="color"
                                                                                            class="form-control"
                                                                                            [value]="themeForm.get('navigationMenuColor').value"
                                                                                            (input)="onChangeColor($event.target.value,'navigation Menu Color','web')">
                                                                                        <input type="text"
                                                                                            class="form-control"
                                                                                            readOnly
                                                                                            formControlName="navigationMenuColor">
                                                                                    </div>
                                                                                </td>
                                                                                <td class="custm-range" nowrap>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Sub Menu
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            formControlName="navigationSubMenuFontWeight"
                                                                                            aria-label="Default select example">
                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                        <span
                                                                                            *ngIf="themeFormControl['navigationSubMenuFontSize'].value">{{themeFormControl['navigationSubMenuFontSize']?.value}}
                                                                                            Px,</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td class="custm-range" nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <i class="far fa-circle"></i>
                                                                                        <input id="navigation"
                                                                                            type="range" min="12"
                                                                                            max="16" step="1"
                                                                                            formControlName="navigationSubMenuFontSize"
                                                                                            class="form-range sliderGreen"
                                                                                            id="subMenu">
                                                                                        <i class="far fa-circle"></i>
                                                                                    </div>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <p>12 Px</p>
                                                                                        <p>16 Px</p>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Sub Menu Color
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div class="d-flex gap-2">
                                                                                        <input type="color"
                                                                                            class="form-control"
                                                                                            [value]="themeForm.get('navigationSubMenuColor').value"
                                                                                            (input)="onChangeColor($event.target.value,'navigation Sub Menu Color','web')">
                                                                                        <input type="text"
                                                                                            class="form-control"
                                                                                            readOnly
                                                                                            formControlName="navigationSubMenuColor">
                                                                                    </div>
                                                                                </td>
                                                                                <td class="custm-range" nowrap>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="theme-caption ps-2">
                                                                    <h6>
                                                                        Product List
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="table-responsive">
                                                                    <table class="table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Product Title
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            formControlName="productTitleFontWeight"
                                                                                            aria-label="Default select example">

                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                        <span
                                                                                            *ngIf="themeFormControl['productTitleFontSize'].value">{{
                                                                                            themeFormControl['productTitleFontSize']?.value}}
                                                                                            Px,</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td class="custm-range" nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <i class="far fa-circle"></i>
                                                                                        <input id="navigation"
                                                                                            type="range" min="10"
                                                                                            max="14" step="1"
                                                                                            formControlName="productTitleFontSize"
                                                                                            class="form-range sliderGreen"
                                                                                            id="title">
                                                                                        <i class="far fa-circle"></i>
                                                                                    </div>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <p>10 Px</p>
                                                                                        <p>14 Px</p>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Product Sub Title
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            formControlName="productSubTitleFontWeight"
                                                                                            aria-label="Default select example">

                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select> <span
                                                                                            *ngIf="themeFormControl['productSubTitleFontSize'].value">{{
                                                                                            themeFormControl['productSubTitleFontSize']?.value}}
                                                                                            Px,</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td class="custm-range" nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <i class="far fa-circle"></i>
                                                                                        <input id="navigation"
                                                                                            type="range" min="12"
                                                                                            max="16" step="1"
                                                                                            formControlName="productSubTitleFontSize"
                                                                                            class="form-range sliderGreen"
                                                                                            id="title">
                                                                                        <i class="far fa-circle"></i>
                                                                                    </div>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <p>12 Px</p>
                                                                                        <p>16 Px</p>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Product Price
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            formControlName="productPriceFontWeight"
                                                                                            aria-label="Default select example">

                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                        <span
                                                                                            *ngIf="themeFormControl['productPriceFontSize'].value">{{
                                                                                            themeFormControl['productPriceFontSize']?.value}}
                                                                                            Px,</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td class="custm-range" nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <i class="far fa-circle"></i>
                                                                                        <input id="navigation"
                                                                                            type="range" min="14"
                                                                                            max="18" step="1"
                                                                                            formControlName="productPriceFontSize"
                                                                                            class="form-range sliderGreen"
                                                                                            id="title">
                                                                                        <i class="far fa-circle"></i>
                                                                                    </div>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <p>14 Px</p>
                                                                                        <p>18 Px</p>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="theme-caption ps-2">
                                                                    <h6>
                                                                        Product Detail
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="table-responsive">
                                                                    <table class="table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Product Name
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            formControlName="productDetailNameFontWeight"
                                                                                            aria-label="Default select example">

                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                        <span
                                                                                            *ngIf="themeFormControl['productDetailNameFontSize'].value">{{
                                                                                            themeFormControl['productDetailNameFontSize']?.value}}
                                                                                            Px,</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td class="custm-range" nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <i class="far fa-circle"></i>
                                                                                        <input id="navigation"
                                                                                            type="range" min="16"
                                                                                            max="20" step="1"
                                                                                            formControlName="productDetailNameFontSize"
                                                                                            class="form-range sliderGreen"
                                                                                            id="detail">
                                                                                        <i class="far fa-circle"></i>
                                                                                    </div>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <p>16 Px</p>
                                                                                        <p>20 Px</p>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Product Sub Title
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            formControlName="productDetailSubTitleFontWeight"
                                                                                            aria-label="Default select example">

                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                        <span
                                                                                            *ngIf="themeFormControl['productDetailSubTitleFontSize'].value">{{
                                                                                            themeFormControl['productDetailSubTitleFontSize']?.value}}
                                                                                            Px,</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td class="custm-range" nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <i class="far fa-circle"></i>
                                                                                        <input id="navigation"
                                                                                            type="range" min="12"
                                                                                            max="16" step="1"
                                                                                            formControlName="productDetailSubTitleFontSize"
                                                                                            class="form-range sliderGreen"
                                                                                            id="detail">
                                                                                        <i class="far fa-circle"></i>
                                                                                    </div>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <p>12 Px</p>
                                                                                        <p>16 Px</p>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Product Price
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            formControlName="productDetailPriceFontWeight"
                                                                                            aria-label="Default select example">

                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                        <span
                                                                                            *ngIf="themeFormControl['productDetailPriceFontSize'].value">{{
                                                                                            themeFormControl['productDetailPriceFontSize']?.value}}
                                                                                            Px,</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td class="custm-range" nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <i class="far fa-circle"></i>
                                                                                        <input id="navigation"
                                                                                            type="range" min="18"
                                                                                            max="22" step="1"
                                                                                            formControlName="productDetailPriceFontSize"
                                                                                            class="form-range sliderGreen"
                                                                                            id="detail">
                                                                                        <i class="far fa-circle"></i>
                                                                                    </div>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <p>18 Px</p>
                                                                                        <p>22 Px</p>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Description Heading
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            formControlName="productDescriptionHeadingFontWeight"
                                                                                            aria-label="Default select example">

                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                        <span
                                                                                            *ngIf="themeFormControl['productDescriptionHeadingFontSize'].value">{{
                                                                                            themeFormControl['productDescriptionHeadingFontSize']?.value}}
                                                                                            Px,</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td class="custm-range" nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <i class="far fa-circle"></i>
                                                                                        <input id="navigation"
                                                                                            type="range" min="14"
                                                                                            max="18" step="1"
                                                                                            formControlName="productDescriptionHeadingFontSize"
                                                                                            class="form-range sliderGreen"
                                                                                            id="detail">
                                                                                        <i class="far fa-circle"></i>
                                                                                    </div>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <p>14 Px</p>
                                                                                        <p>18 Px</p>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Description
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            formControlName="productDescriptionFontWeight"
                                                                                            aria-label="Default select example">

                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                        <span
                                                                                            *ngIf="themeFormControl['productDescriptionFontSize'].value">{{
                                                                                            themeFormControl['productDescriptionFontSize']?.value}}
                                                                                            Px,</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td class="custm-range" nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <i class="far fa-circle"></i>
                                                                                        <input id="navigation"
                                                                                            type="range" min="12"
                                                                                            max="16" step="1"
                                                                                            formControlName="productDescriptionFontSize"
                                                                                            class="form-range sliderGreen"
                                                                                            id="detail">
                                                                                        <i class="far fa-circle"></i>
                                                                                    </div>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <p>12 Px</p>
                                                                                        <p>16 Px</p>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="theme-caption ps-2">
                                                                    <h6>
                                                                        Customer Reviews
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="table-responsive">
                                                                    <table class="table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Title
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            formControlName="customerReviewsTitleFontWeight"
                                                                                            aria-label="Default select example">

                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                        <span
                                                                                            *ngIf="themeFormControl['customerReviewsTitleFontSize'].value">{{
                                                                                            themeFormControl['customerReviewsTitleFontSize']?.value}}
                                                                                            Px,</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td class="custm-range" nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <i class="far fa-circle"></i>
                                                                                        <input id="navigation"
                                                                                            type="range" min="12"
                                                                                            max="15" step="1"
                                                                                            formControlName="customerReviewsTitleFontSize"
                                                                                            class="form-range sliderGreen"
                                                                                            id="title">
                                                                                        <i class="far fa-circle"></i>
                                                                                    </div>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <p>12 Px</p>
                                                                                        <p>15 Px</p>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Details
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            formControlName="customerReviewsDetailsFontWeight"
                                                                                            aria-label="Default select example">
                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                        <span
                                                                                            *ngIf="themeFormControl['customerReviewsDetailsFontSize'].value">{{
                                                                                            themeFormControl['customerReviewsDetailsFontSize']?.value}}
                                                                                            Px,</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td class="custm-range" nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <i class="far fa-circle"></i>
                                                                                        <input id="navigation"
                                                                                            type="range" min="10"
                                                                                            max="14" step="1"
                                                                                            formControlName="customerReviewsDetailsFontSize"
                                                                                            class="form-range sliderGreen"
                                                                                            id="detail">
                                                                                        <i class="far fa-circle"></i>
                                                                                    </div>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <p>10 Px</p>
                                                                                        <p>14 Px</p>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="theme-caption ps-2">
                                                                    <h6>
                                                                        Notifications
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="table-responsive">
                                                                    <table class="table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Notifications Title
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            formControlName="notificationsTitleFontWeight"
                                                                                            aria-label="Default select example">
                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                        <span
                                                                                            *ngIf="themeFormControl['notificationsTitleFontSize'].value">{{
                                                                                            themeFormControl['notificationsTitleFontSize']?.value}}
                                                                                            Px,</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td class="custm-range" nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <i class="far fa-circle"></i>
                                                                                        <input id="navigation"
                                                                                            type="range" min="10"
                                                                                            max="15" step="1"
                                                                                            formControlName="notificationsTitleFontSize"
                                                                                            class="form-range sliderGreen"
                                                                                            id="title">
                                                                                        <i class="far fa-circle"></i>
                                                                                    </div>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <p>10 Px</p>
                                                                                        <p>15 Px</p>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Notifications Sub Title
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            formControlName="notificationsSubTitleFontWeight"
                                                                                            aria-label="Default select example">
                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                        <span
                                                                                            *ngIf="themeFormControl['notificationsSubTitleFontSize'].value">{{
                                                                                            themeFormControl['notificationsSubTitleFontSize']?.value}}
                                                                                            Px,</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td class="custm-range" nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <i class="far fa-circle"></i>
                                                                                        <input id="navigation"
                                                                                            type="range" min="10"
                                                                                            max="13" step="1"
                                                                                            formControlName="notificationsSubTitleFontSize"
                                                                                            class="form-range sliderGreen"
                                                                                            id="detail">
                                                                                        <i class="far fa-circle"></i>
                                                                                    </div>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <p>10 Px</p>
                                                                                        <p>13 Px</p>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="theme-caption ps-2">
                                                                    <h6>
                                                                        Profile
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="table-responsive">
                                                                    <table class="table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Heading
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            formControlName="profileHeadingFontWeight"
                                                                                            aria-label="Default select example">

                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                        <span
                                                                                            *ngIf="themeFormControl['profileHeadingFontSize'].value">{{
                                                                                            themeFormControl['profileHeadingFontSize']?.value}}
                                                                                            Px,</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td class="custm-range" nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <i class="far fa-circle"></i>
                                                                                        <input id="navigation"
                                                                                            type="range" min="12"
                                                                                            max="16" step="1"
                                                                                            formControlName="profileHeadingFontSize"
                                                                                            class="form-range sliderGreen"
                                                                                            id="Heading">
                                                                                        <i class="far fa-circle"></i>
                                                                                    </div>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <p>12 Px</p>
                                                                                        <p>16 Px</p>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Content
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            formControlName="profileContentFontWeight"
                                                                                            aria-label="Default select example">

                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                        <span
                                                                                            *ngIf="themeFormControl['profileContentFontSize'].value">{{
                                                                                            themeFormControl['profileContentFontSize']?.value}}
                                                                                            Px,</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td class="custm-range" nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <i class="far fa-circle"></i>
                                                                                        <input id="navigation"
                                                                                            type="range" min="10"
                                                                                            max="14" step="1"
                                                                                            formControlName="profileContentFontSize"
                                                                                            class="form-range sliderGreen"
                                                                                            id="Content">
                                                                                        <i class="far fa-circle"></i>
                                                                                    </div>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <p>10 Px</p>
                                                                                        <p>14 Px</p>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="theme-caption ps-2">
                                                                    <h6>
                                                                        Others
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="table-responsive">
                                                                    <table class="table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Content Title
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            formControlName="contentTitleFontWeight"
                                                                                            aria-label="Default select example">

                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                        <span
                                                                                            *ngIf="themeFormControl['contentTitleFontSize'].value">{{
                                                                                            themeFormControl['contentTitleFontSize']?.value}}
                                                                                            Px,</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td class="custm-range" nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <i class="far fa-circle"></i>
                                                                                        <input id="navigation"
                                                                                            type="range" min="18"
                                                                                            max="24" step="1"
                                                                                            formControlName="contentTitleFontSize"
                                                                                            class="form-range sliderGreen"
                                                                                            id="contentTitle">
                                                                                        <i class="far fa-circle"></i>
                                                                                    </div>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <p>18 Px</p>
                                                                                        <p>24 Px</p>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Content Description
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            formControlName="contentDescriptionFontWeight"
                                                                                            aria-label="Default select example">

                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                        <span
                                                                                            *ngIf="themeFormControl['contentDescriptionFontSize'].value">{{
                                                                                            themeFormControl['contentDescriptionFontSize']?.value}}
                                                                                            Px,</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td class="custm-range" nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <i class="far fa-circle"></i>
                                                                                        <input id="navigation"
                                                                                            type="range" min="12"
                                                                                            max="16" step="1"
                                                                                            formControlName="contentDescriptionFontSize"
                                                                                            class="form-range sliderGreen"
                                                                                            id="description">
                                                                                        <i class="far fa-circle"></i>
                                                                                    </div>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <p>12 Px</p>
                                                                                        <p>16 Px</p>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    FAQ Title Header
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            formControlName="titleHeaderFontWeight"
                                                                                            aria-label="Default select example">

                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                        <span
                                                                                            *ngIf="themeFormControl['titleHeaderFontSize'].value">{{
                                                                                            themeFormControl['titleHeaderFontSize']?.value}}
                                                                                            Px,</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td class="custm-range" nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <i class="far fa-circle"></i>
                                                                                        <input id="navigation"
                                                                                            type="range" min="10"
                                                                                            max="15" step="1"
                                                                                            formControlName="titleHeaderFontSize"
                                                                                            class="form-range sliderGreen"
                                                                                            id="titleHeader">
                                                                                        <i class="far fa-circle"></i>
                                                                                    </div>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <p>10 Px</p>
                                                                                        <p>15 Px</p>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    FAQ Details
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            formControlName="titleDetailsFontWeight"
                                                                                            aria-label="Default select example">

                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                        <span
                                                                                            *ngIf="themeFormControl['titleDetailsFontSize'].value">{{
                                                                                            themeFormControl['titleDetailsFontSize']?.value}}
                                                                                            Px,</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td class="custm-range" nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <i class="far fa-circle"></i>
                                                                                        <input id="navigation"
                                                                                            type="range" min="10"
                                                                                            max="14" step="1"
                                                                                            formControlName="titleDetailsFontSize"
                                                                                            class="form-range sliderGreen"
                                                                                            id="titleDetails">
                                                                                        <i class="far fa-circle"></i>
                                                                                    </div>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <p>10 Px</p>
                                                                                        <p>14 Px</p>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="theme-caption ps-2">
                                                                    <h6>
                                                                        Primary Buttons
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="table-responsive">
                                                                    <table class="table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Text Style
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            formControlName="primaryButtonFontWeight"
                                                                                            aria-label="Default select example">

                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                        <span
                                                                                            *ngIf="themeFormControl['primaryButtonFontSize'].value">{{
                                                                                            themeFormControl['primaryButtonFontSize']?.value}}
                                                                                            Px,</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td class="custm-range" nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <i class="far fa-circle"></i>
                                                                                        <input id="navigation"
                                                                                            type="range" min="10"
                                                                                            max="14" step="1"
                                                                                            formControlName="primaryButtonFontSize"
                                                                                            class="form-range sliderGreen"
                                                                                            id="primaryButton">
                                                                                        <i class="far fa-circle"></i>
                                                                                    </div>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <p>10 Px</p>
                                                                                        <p>14 Px</p>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Button Background Color
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div class="d-flex gap-2">
                                                                                        <input type="color"
                                                                                            class="form-control"
                                                                                            [value]="themeForm.get('primaryButtonBackgroundColor').value"
                                                                                            (input)="onChangeColor($event.target.value,'primary button Background','web')">
                                                                                        <input type="text"
                                                                                            class="form-control"
                                                                                            readOnly
                                                                                            formControlName="primaryButtonBackgroundColor">
                                                                                    </div>
                                                                                </td>
                                                                                <td class="custm-range" nowrap>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Button Text Color
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div class="d-flex gap-2">
                                                                                        <input type="color"
                                                                                            class="form-control"
                                                                                            [value]="themeForm.get('primaryButtonTextColor').value"
                                                                                            (input)="onChangeColor($event.target.value,'primary button Text','web')">
                                                                                        <input type="text"
                                                                                            class="form-control"
                                                                                            readOnly
                                                                                            formControlName="primaryButtonTextColor">
                                                                                    </div>
                                                                                </td>
                                                                                <td class="custm-range" nowrap>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="theme-caption ps-2">
                                                                    <h6>
                                                                        Secondary Buttons
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="table-responsive">
                                                                    <table class="table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Text Style
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            formControlName="secondaryButtonFontWeight"
                                                                                            aria-label="Default select example">

                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                        <span
                                                                                            *ngIf="themeFormControl['secondaryButtonFontSize'].value">{{
                                                                                            themeFormControl['secondaryButtonFontSize']?.value}}
                                                                                            Px,</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td class="custm-range" nowrap>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <i class="far fa-circle"></i>
                                                                                        <input id="navigation"
                                                                                            type="range" min="10"
                                                                                            max="14" step="1"
                                                                                            formControlName="secondaryButtonFontSize"
                                                                                            class="form-range sliderGreen"
                                                                                            id="primaryButton">
                                                                                        <i class="far fa-circle"></i>
                                                                                    </div>
                                                                                    <div
                                                                                        class="d-flex gap-2 align-items-center justify-content-between">
                                                                                        <p>10 Px</p>
                                                                                        <p>14 Px</p>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Button Background Color
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div class="d-flex gap-2">
                                                                                        <input type="color"
                                                                                            class="form-control"
                                                                                            [value]="themeForm.get('secondaryButtonBackgroundColor').value"
                                                                                            (input)="onChangeColor($event.target.value,'secondary button Background','web')">
                                                                                        <input type="text"
                                                                                            class="form-control"
                                                                                            readOnly
                                                                                            formControlName="secondaryButtonBackgroundColor">
                                                                                    </div>
                                                                                </td>
                                                                                <td class="custm-range" nowrap>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Button Text Color
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div class="d-flex gap-2">
                                                                                        <input type="color"
                                                                                            class="form-control"
                                                                                            [value]="themeForm.get('secondaryButtonTextColor').value"
                                                                                            (input)="onChangeColor($event.target.value,'secondary button Text','web')">
                                                                                        <input type="text"
                                                                                            class="form-control"
                                                                                            readOnly
                                                                                            formControlName="secondaryButtonTextColor">
                                                                                    </div>
                                                                                </td>
                                                                                <td class="custm-range" nowrap>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="pills-mobile" role="tabpanel"
                                                        aria-labelledby="pills-mobile-tab"
                                                        [formGroup]="mobileThemeForm">
                                                        <div class=" row d-none">
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div
                                                                    class="theme-caption ps-2 d-flex justify-content-between align-items-center">
                                                                    <h6>
                                                                        Mobile App
                                                                    </h6>
                                                                    <button type="button" class="btn btn-link pe-2"
                                                                        (click)="resetMobileTheme()">Reset</button>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                                                <div class="row">
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="theme-caption ps-2">
                                                                            <h6>
                                                                                App Background
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="card">
                                                                            <div class="card-body">
                                                                                <input type="color"
                                                                                    class="form-control color-form-control"
                                                                                    placeholder="">
                                                                            </div>
                                                                            <div class="card-footer">
                                                                                <div
                                                                                    class="d-flex justify-content-between align-items-center">
                                                                                    <h6>
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div
                                                                    class="theme-caption ps-2 d-flex justify-content-between align-items-center">
                                                                    <h6>
                                                                        Location
                                                                    </h6>
                                                                    <button type="button" class="btn btn-link pe-2"
                                                                        (click)="resetMobileTheme()">Reset</button>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                                                <div class="row">
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="theme-caption ps-2">
                                                                            <h6>
                                                                                Location Background
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="card">
                                                                            <div class="card-body">
                                                                                <input type="color"
                                                                                    formControlName="locationBackgroundColor"
                                                                                    class="form-control color-form-control"
                                                                                    placeholder="">
                                                                            </div>
                                                                            <div class="card-footer">
                                                                                <div
                                                                                    class="d-flex justify-content-between align-items-center">
                                                                                    <h6>{{mobileThemeFormControl['locationBackgroundColor'].value}}
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                                                <div class="row">
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="theme-caption ps-2">
                                                                            <h6>
                                                                                Location Text
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="card">
                                                                            <div class="card-body">
                                                                                <input type="color"
                                                                                    formControlName="locationTextColor"
                                                                                    class="form-control color-form-control"
                                                                                    placeholder="">
                                                                            </div>
                                                                            <div class="card-footer">
                                                                                <div
                                                                                    class="d-flex justify-content-between align-items-center">
                                                                                    <h6>{{mobileThemeFormControl['locationTextColor'].value}}
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                                                <div class="row">
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="theme-caption ps-2">
                                                                            <h6>
                                                                                Location Icon
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="card">
                                                                            <div class="card-body">
                                                                                <input type="color"
                                                                                    formControlName="locationIconColor"
                                                                                    class="form-control color-form-control"
                                                                                    placeholder="">
                                                                            </div>
                                                                            <div class="card-footer">
                                                                                <div
                                                                                    class="d-flex justify-content-between align-items-center">
                                                                                    <h6>{{mobileThemeFormControl['locationIconColor'].value}}
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div
                                                                    class="theme-caption ps-2 d-flex justify-content-between align-items-center">
                                                                    <h6>
                                                                        Search Bar
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                                                <div class="row">
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="theme-caption ps-2">
                                                                            <h6>
                                                                                Search Bar Background
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="card">
                                                                            <div class="card-body">
                                                                                <input type="color"
                                                                                    formControlName="searchBarBackgroundColor"
                                                                                    class="form-control color-form-control"
                                                                                    placeholder="">
                                                                            </div>
                                                                            <div class="card-footer">
                                                                                <div
                                                                                    class="d-flex justify-content-between align-items-center">
                                                                                    <h6>{{mobileThemeFormControl['searchBarBackgroundColor'].value}}
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="theme-caption ps-2">
                                                                    <h6>
                                                                        App Body Background
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                                                <div class="row">
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="theme-caption ps-2">
                                                                            <h6>
                                                                                New Arrivals
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="card">
                                                                            <div class="card-body">
                                                                                <input type="color"
                                                                                    formControlName="newArrivalsColor"
                                                                                    class="form-control color-form-control"
                                                                                    placeholder="">
                                                                            </div>
                                                                            <div class="card-footer">
                                                                                <div
                                                                                    class="d-flex justify-content-between align-items-center">
                                                                                    <h6>{{mobileThemeFormControl['newArrivalsColor'].value}}
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                                                <div class="row">
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="theme-caption ps-2">
                                                                            <h6>
                                                                                Best Sellers
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="card">
                                                                            <div class="card-body">
                                                                                <input type="color"
                                                                                    formControlName="bestSellersColor"
                                                                                    class="form-control color-form-control"
                                                                                    placeholder="">
                                                                            </div>
                                                                            <div class="card-footer">
                                                                                <div
                                                                                    class="d-flex justify-content-between align-items-center">
                                                                                    <h6>{{mobileThemeFormControl['bestSellersColor'].value}}
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                                                <div class="row">
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="theme-caption ps-2">
                                                                            <h6>
                                                                                Recently Viewed
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="card">
                                                                            <div class="card-body">
                                                                                <input type="color"
                                                                                    formControlName="recentlyViewedColor"
                                                                                    class="form-control color-form-control"
                                                                                    placeholder="">
                                                                            </div>
                                                                            <div class="card-footer">
                                                                                <div
                                                                                    class="d-flex justify-content-between align-items-center">
                                                                                    <h6>{{mobileThemeFormControl['recentlyViewedColor'].value}}
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="theme-caption ps-2">
                                                                    <h6>
                                                                        Navigation Menu
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                                                <div class="row">
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="theme-caption ps-2">
                                                                            <h6>
                                                                                Navigation Background
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="card">
                                                                            <div class="card-body">
                                                                                <input type="color"
                                                                                    formControlName="navBackgroundColor"
                                                                                    class="form-control color-form-control"
                                                                                    placeholder="">
                                                                            </div>
                                                                            <div class="card-footer">
                                                                                <div
                                                                                    class="d-flex justify-content-between align-items-center">
                                                                                    <h6>{{mobileThemeFormControl['navBackgroundColor'].value}}
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                                                <div class="row">
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="theme-caption ps-2">
                                                                            <h6>
                                                                                Navigation Icon
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                        <div class="card">
                                                                            <div class="card-body">
                                                                                <input type="color"
                                                                                    formControlName="navIconColor"
                                                                                    class="form-control color-form-control"
                                                                                    placeholder="">
                                                                            </div>
                                                                            <div class="card-footer">
                                                                                <div
                                                                                    class="d-flex justify-content-between align-items-center">
                                                                                    <h6>{{mobileThemeFormControl['navIconColor'].value}}
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="theme-caption ps-2">
                                                                    <h6>
                                                                        Select Font Family
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="table-responsive">
                                                                    <table class="table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td nowrap
                                                                                    *ngFor="let fontItem of mobileFonts; let fontIndex = index">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input"
                                                                                            type="checkbox"
                                                                                            [attr.id]="'font_'+fontIndex"
                                                                                            (change)="setFont(fontIndex,'mobile')"
                                                                                            [checked]="fontItem.checked" />
                                                                                        <label class="form-check-label"
                                                                                            [attr.for]="'font_'+fontIndex">
                                                                                            {{fontItem.value}}
                                                                                        </label>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row d-none">
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="theme-caption ps-2">
                                                                    <h6>
                                                                        Product List
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="table-responsive">
                                                                    <table class="table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Product Title
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div>
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            aria-label="Default select example">
                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Product Brand
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div>
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            aria-label="Default select example">
                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Product Stock
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div>
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            aria-label="Default select example">
                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Product Price
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div>
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            aria-label="Default select example">
                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row d-none">
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="theme-caption ps-2">
                                                                    <h6>
                                                                        Product Detail
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="table-responsive">
                                                                    <table class="table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Product Name
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div>
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            aria-label="Default select example">
                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Product Price
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div>
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            aria-label="Default select example">
                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Product Stock
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div>
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            aria-label="Default select example">

                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Product Brand
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div>
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            aria-label="Default select example">
                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row d-none">
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="theme-caption ps-2">
                                                                    <h6>
                                                                        Customer Reviews
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="table-responsive">
                                                                    <table class="table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Title
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div>
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            aria-label="Default select example">
                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Details
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div>
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            aria-label="Default select example">
                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row d-none">
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="theme-caption ps-2">
                                                                    <h6>
                                                                        Notifications
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="table-responsive">
                                                                    <table class="table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Notifications Title
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div>
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            aria-label="Default select example">
                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Notifications Sub Title
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div>
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            aria-label="Default select example">
                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row d-none">
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="theme-caption ps-2">
                                                                    <h6>
                                                                        Profile
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="table-responsive">
                                                                    <table class="table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Profile Name
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div>
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            aria-label="Default select example">

                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Profile Headings
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div>
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            aria-label="Default select example">

                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="theme-caption ps-2">
                                                                    <h6>
                                                                        Primary Buttons
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="table-responsive">
                                                                    <table class="table">
                                                                        <tbody>
                                                                            <tr class="d-none">
                                                                                <td nowrap>
                                                                                    Text Weight
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div>
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            aria-label="Default select example">
                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Button Background Color
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div class="d-flex gap-2">
                                                                                        <input type="color"
                                                                                            class="form-control"
                                                                                            [value]="mobileThemeForm.get('primaryButtonBackgroundColor').value"
                                                                                            (input)="onChangeColor($event.target.value,'primary button Background','mobile')">
                                                                                        <input type="text"
                                                                                            class="form-control"
                                                                                            readOnly
                                                                                            formControlName="primaryButtonBackgroundColor">
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Button Text Color
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div class="d-flex gap-2">
                                                                                        <input type="color"
                                                                                            class="form-control"
                                                                                            [value]="mobileThemeForm.get('primaryButtonTextColor').value"
                                                                                            (input)="onChangeColor($event.target.value,'primary button Text','mobile')">
                                                                                        <input type="text"
                                                                                            class="form-control"
                                                                                            readOnly
                                                                                            formControlName="primaryButtonTextColor">
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="theme-caption ps-2">
                                                                    <h6>
                                                                        Secondary Buttons
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                                                <div class="table-responsive">
                                                                    <table class="table">
                                                                        <tbody>
                                                                            <tr class="d-none">
                                                                                <td nowrap>
                                                                                    Text Weight
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div>
                                                                                        <select
                                                                                            class="form-select form-control"
                                                                                            aria-label="Default select example">
                                                                                            <option
                                                                                                *ngFor="let fontWeight of fontWeights"
                                                                                                [ngValue]="fontWeight">
                                                                                                {{ fontWeight}}
                                                                                            </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Button Background Color
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div class="d-flex gap-2">
                                                                                        <input type="color"
                                                                                            class="form-control"
                                                                                            [value]="mobileThemeForm.get('secondaryButtonBackgroundColor').value"
                                                                                            (input)="onChangeColor($event.target.value,'secondary button Background','mobile')">
                                                                                        <input type="text"
                                                                                            class="form-control"
                                                                                            readOnly
                                                                                            formControlName="secondaryButtonBackgroundColor">
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td nowrap>
                                                                                    Button Text Color
                                                                                </td>
                                                                                <td nowrap>
                                                                                    <div class="d-flex gap-2">
                                                                                        <input type="color"
                                                                                            class="form-control"
                                                                                            [value]="mobileThemeForm.get('secondaryButtonTextColor').value"
                                                                                            (input)="onChangeColor($event.target.value,'secondary button Text','mobile')">
                                                                                        <input type="text"
                                                                                            class="form-control"
                                                                                            readOnly
                                                                                            formControlName="secondaryButtonTextColor">
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 mb-3">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="d-flex justify-content-end align-items-center gap-2">
                        <button class="btn cancel-btn" *ngIf="showThemesButton">Cancel</button>
                        <button class="btn cancel-btn"
                            *ngIf="!showThemesButton && !showEmailButton && !showPaymentButton"
                            [routerLink]="['/']">Cancel</button>
                        <button class="btn save-btn" *ngIf="showThemesButton" (click)="saveTheme()">Save</button>
                        <button class="btn save-btn" *ngIf="!showThemesButton && !showEmailButton && !showPaymentButton"
                            (click)="addCompanyDetails()">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>