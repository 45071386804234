import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import * as query from 'graphql/queries';
import * as mutation from 'graphql/mutations';
import { MessageService } from 'primeng/api';
import { CommonService } from '@services/common/common.service';
import { Messages } from '@/Toaster-messages';
import { productStatus } from 'API';
@Component({
  selector: 'app--list-warehouse',
  templateUrl: './list-warehouse.component.html',
  styleUrl: './list-warehouse.component.scss'
})
export class ListWarehouseComponent implements OnInit {
  value: number = 5;
  position: any;
  displayFilterModalToggle = false;
  deleteModalToggle: any;
  wareHouseList: any = []
  deleteWareHouse: any
  filterObject: any = {}
  searchKey: any
  searchText: any = ''
  access: any = {}

  constructor(private common: CommonService, private router: Router, private awsApi: ApiService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.position = 'right';
    this.getAllWareHouse()
    this.startingFunction()
  }

  async getRoleManagement() {
    this.awsApi.loading.next(true);
    try {
      await this.common.getPermissionsOfUser('Settings', 'Manage Warehouse');
      this.access = this.common.access;
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  async startingFunction() {
    this.getRoleManagement()
  }

  getAllWareHouse() {
    this.awsApi.loading.next(true);
    this.awsApi.client
      .graphql({
        query: query.listWareHouses,
        variables: {
          pk: 'WARE#',
          id: null,
          filter: this.filterObject,
          limit: 9999999,
          nextToken: null,
          sortDirection: null
        }
      })
      .then((response) => {
        this.wareHouseList = response.data.listWareHouses.items.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        this.awsApi.loading.next(false);
      })
      .catch((error) => {
        this.awsApi.loading.next(false);
      });
  }

  showFilterModalDialog(position: string) {
    this.position = position;
    this.displayFilterModalToggle = true;
  }
  closeFilterModalDialog() {
    this.displayFilterModalToggle = false;
  }

  redirectToAddWarehousePage() {
    this.router.navigate([`add-warehouse`]);
  }

  deleteWareHouseItem(item: any) {
    this.deleteModalToggle = true;
    this.deleteWareHouse = item;
  }

  async confirmDeleteWareHouse() {
    this.deleteModalToggle = false;
    let filter = {}
    filter['status'] = { ne: productStatus.deleted }
    filter['searchKey'] = { contains: this.deleteWareHouse.id }
    let products = await this.common.getProducts(filter, ['pk', 'id'], 'Warehouse Delete')
    if (products.length > 0) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.WAREHOUSE_CANNOT_BE_DELETED
      });
      return;
    }

    let data = {
      pk: this.deleteWareHouse.pk,
      id: this.deleteWareHouse.id
    };
    this.awsApi.client
      .graphql({
        query: mutation.deleteWareHouse,
        variables: {
          input: {
            ...data
          }
        }
      })
      .then((response) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Warehouse deleted successfully!'
        });
        this.ngOnInit();
      })
      .catch((error) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      });
  }

  search(searchText) {
    this.searchText = searchText
    if (this.searchText != '') {
      this.searchKey = this.searchText.toLowerCase()
      this.searchKey = this.searchKey.replace(/\s{2,}/g, ' ');
      this.searchKey = (this.searchKey.trim()).replace(/\s+/g, '');

      this.filterObject['searchKey'] = { contains: this.searchKey }
      this.ngOnInit()
    } else {
      delete this.filterObject['searchKey']
      delete this.searchKey
      this.searchText
      this.ngOnInit()
    }
  }

  redirectToEditWarehouse(item) {
    this.router.navigate([`edit-warehouse`], { queryParams: { id: item.id, pk: item.pk } });
  }
}