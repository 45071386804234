<section id="addFaqSec">
    <div class="container-fluid">
        <div class="row mb-1 pt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card mb-1 heading-card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="d-flex justify-content-start align-items-center">
                                    <i class="fas fa-chevron-left" (click)="redirectToListFaqsPage()"></i>
                                    <h6 *ngIf="type=='add'">Create FAQ Category </h6>
                                    <h6 *ngIf="type=='edit'">Edit FAQ Category</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-1" [formGroup]="addFaqForm">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                <label>FAQ Category <span class="mandatory">*</span></label>
                                <input type="text" class="form-control" formControlName="faqName"
                                    placeholder="Enter FAQ Category" />
                                <span class="text-danger"
                                    *ngIf="(addFaqForm.controls.faqName.touched) && !addFaqForm.controls.faqName?.valid">
                                    <div *ngIf="addFaqForm.controls.faqName.errors['required']">
                                        FAQ category is required.
                                    </div>
                                    <div *ngIf="addFaqForm.controls.faqName.errors['pattern']">
                                        Invalid FAQ category.
                                    </div>
                                </span>
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                <label>FAQ Type <span class="mandatory">*</span></label>
                                <select class="form-control form-select" formControlName="type">
                                    <option [ngValue]="null" selected>
                                        Choose...
                                    </option>
                                    <option *ngFor="let faqType of faqTypeList" [ngValue]="faqType.value">
                                        {{ faqType.value }} </option>
                                </select>
                                <span class="text-danger"
                                    *ngIf="(addFaqForm.controls.type.touched) && !addFaqForm.controls.type?.valid">
                                    <div *ngIf="addFaqForm.controls.type.errors['required']">
                                        FAQ type is required.
                                    </div>
                                </span>
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                <label>Order of Sequence <span class="mandatory">*</span></label>
                                <input type="number" (keypress)="preventE($event)" class="form-control"
                                    formControlName="seqNo" placeholder="Enter Sequence Number" />
                                <span class="text-danger"
                                    *ngIf="(addFaqForm.controls.seqNo.touched) && !addFaqForm.controls.seqNo?.valid">
                                    <div *ngIf="addFaqForm.controls.seqNo.errors['required']">
                                        Order of sequence is required.
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="d-flex justify-content-end gap-2">
                    <button type="button" class="btn cancel-btn" *ngIf="type=='add'"
                        (click)="redirectToListFaqsPage()">Cancel</button>
                    <button type="button" class="btn cancel-btn" *ngIf="type=='edit'"
                        (click)="redirectToListFaqsPage()">Cancel</button>
                    <button type="button" class="btn faq-add-btn" *ngIf="type=='add'" [disabled]="addFaqForm.invalid"
                        (click)="addFaq()">Add</button>
                    <button type="button" class="btn faq-add-btn" *ngIf="type=='edit'" [disabled]="addFaqForm.invalid"
                        (click)="editFaq()">Edit</button>
                </div>
            </div>
        </div>
    </div>
</section>