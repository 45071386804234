<section id="roleListSec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 my-auto">
                <div class="card mb-2 mt-2">
                    <div class="card-body">
                        <!-- role-list-heading-row start -->
                        <div class="row role-list-heading-row">
                            <div class="col-xxl-6 col-xl-6 col-lg-4 col-md-4 col-sm-12 col-xs-12 my-auto">
                                <div class="text-center text-md-left mt-2 mt-md-0">
                                    <h2>Manage Roles</h2>
                                    <p class="mb-2 mb-md-0">{{roleList.length}} Results Found</p>
                                </div>
                            </div>
                            <div class="col-xxl-6 col-xl-6 col-lg-8 col-md-8 col-sm-12 col-xs-12 my-auto">
                                <div
                                    class="has-search-d-flex justify-content-center justify-content-md-end align-items-start align-items-md-center flex-nowrap flex-md-wrap">
                                    <app-common-search (onEnter)="search($event)"
                                        class="mb-2 mb-md-0"></app-common-search>
                                    <!-- <div>
                                        <button (click)="redirectToAddRolePage()"
                                            class="btn add-btn d-none d-md-block">Add
                                            Role</button>
                                        <button (click)="redirectToAddRolePage()" class="btn add-btn d-block d-md-none">
                                            <span class="material-symbols-outlined">
                                                add
                                            </span>
                                        </button>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- role-list-heading-row end -->
                <div class="card mb-1 role-list-card table-card" *ngFor="let item of roleList;let i=index">
                    <div class="card-body">
                        <!-- role-list-table-row start -->
                        <div class="row role-list-table-row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="table-row action-table-responsive table-responsive">
                                    <table class="table table-borderless">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div class="avatar me-2"
                                                        [style.background-color]="randomColorsForRole[i]">
                                                        {{item.roleName | abbreviateName}}</div>
                                                </td>
                                                <td>
                                                    <div (click)="redirectToEditRole(item)">
                                                        <label>Role Name</label>
                                                        <h6>{{item.roleName}}</h6>
                                                    </div>
                                                </td>
                                                <td class="text-end">
                                                    <div class="dropdown dropstart pe-2">
                                                        <i class="fas fa-ellipsis-v" id="dropdownMenuButton1"
                                                            data-bs-toggle="dropdown" aria-expanded="false"
                                                            data-bs-auto-close="inside"
                                                            data-bs-auto-close="outside"></i>
                                                        <ul class="dropdown-menu dropdown-menu-position"
                                                            aria-labelledby="dropdownMenuButton1">
                                                            <li>
                                                                <a class="dropdown-item" [routerLink]="[
                                                                            '/edit-role'
                                                                        ]" [queryParams]="{
                                                                            id: item.id,
                                                                            pk: item.pk
                                                                        }"><span
                                                                        class="material-symbols-outlined edit-icon me-3"
                                                                        title="Edit">edit_square
                                                                    </span>Edit
                                                                </a>
                                                            </li>
                                                            <!-- <li (click)="showDeleteConfirmationModalDialog(item)">
                                                                <a class="dropdown-item"><span
                                                                        class="material-symbols-outlined delete-icon"
                                                                        title="Delete">delete
                                                                    </span>Delete</a>
                                                            </li> -->
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <!-- <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
              <app-no-result-found></app-no-result-found>
            </div> -->
                        </div>
                        <!-- role-list-table-row end -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- deleteModalToggle start -->
<p-dialog [showHeader]="false" [(visible)]="deleteModalToggle" [style]="{width: '25vw'}" [baseZIndex]="10000"
    [modal]="true">
    <div class="body mt-3" id="deleteModalToggle">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="delete-image">
                    <img src="../../../../assets/img/delete.png" />
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="confirm-msg">
                    <h6>Are You Sure?</h6>
                    <p>You will not be able to recover this role!</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="confirm-btns">
                    <button type="button" class="btn cancel-btn" (click)="deleteModalToggle = false">
                        Cancel
                    </button>
                    <button type="button" class="btn delete-confirm-btn" (click)="confirmDeleteBrand()">
                        Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</p-dialog>
<!-- {/* // deleteModalToggle end */} -->