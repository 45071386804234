<section id="addWarehouseSec">
    <div class="container-fluid">
        <div class="row mb-1 mt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card mb-1 heading-card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="d-flex justify-content-start align-items-center">
                                    <i class="fas fa-chevron-left" (click)="routeListWareHouse()"></i>
                                    <h6>Edit Warehouse</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-1 warehouse-details-card" [formGroup]="addWareHouseForm">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                <label>Warehouse Name <span class="mandatory">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter Warehouse Name"
                                    formControlName="name" />
                                <span class="text-danger"
                                    *ngIf="addWareHouseForm.controls.name.touched && !addWareHouseForm.controls.name?.valid">
                                    <div *ngIf="addWareHouseForm.controls.name.errors['required']">
                                        Warehouse name is required.
                                    </div>
                                    <div *ngIf="addWareHouseForm.controls.name.errors['pattern']">
                                        Invalid warehouse name.
                                    </div>
                                </span>
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                <label>Address Line 1 <span class="mandatory">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter Address Line 1"
                                    formControlName="addressLine1" />
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                <label>Address Line 2 </label>
                                <input type="text" class="form-control" placeholder="Enter Address Line 2"
                                    formControlName="addressLine2" />
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                <label>Country <span class="mandatory">*</span></label>
                                <p-dropdown formControlName="country" [options]="countryList" optionLabel="name"
                                    [filter]="true" filterBy="name" placeholder="Choose Country" />
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                <label>State </label>
                                <input type="text" class="form-control" placeholder="Enter State"
                                    formControlName="state" />
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                <label>City </label>
                                <input type="text" class="form-control" placeholder="Enter City"
                                    formControlName="city" />
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                <label>Zip Code </label>
                                <input type="text" class="form-control" placeholder="Enter Zip Code"
                                    formControlName="zipCode" />
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                <label>Contact Person <span class="mandatory">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter Contact Person"
                                    formControlName="contactPerson" maxlength="50"
                                    (blur)="capitalizeFirstLetter('contactPerson')" />
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                <label>Email </label>
                                <input type="text" class="form-control" placeholder="Enter Email"
                                    formControlName="email" />
                                <span class="text-danger"
                                    *ngIf="(addWareHouseForm.controls.email.touched) && !addWareHouseForm.controls.email?.valid">
                                    <div *ngIf="addWareHouseForm.controls.email.errors['pattern']">
                                        Enter a valid email address
                                    </div>
                                </span>
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                <label>Phone Number <span class="mandatory">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter Phone Number"
                                    formControlName="phoneNumber" />
                                <span class="text-danger"
                                    *ngIf="(addWareHouseForm.controls.phoneNumber.touched) && !addWareHouseForm.controls.phoneNumber?.valid">
                                    <div *ngIf="addWareHouseForm.controls.phoneNumber.errors['required']">
                                        Phone number is required.
                                    </div>
                                    <div *ngIf="addWareHouseForm.controls.phoneNumber.errors['pattern']">
                                        Enter a valid phone number
                                    </div>
                                </span>
                            </div>
                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <label>Working Hours <span class="mandatory">*</span></label>
                                <div class="d-flex justify-content-between gap-2">
                                    <div class="input-group mb-3">
                                        <input type="time" class="form-control"
                                            aria-label="Text input with dropdown button" placeholder="Enter height"
                                            formControlName="workingHoursFrom" />
                                    </div>
                                    <div class="mt-2">
                                        <p>To</p>
                                    </div>
                                    <div class="input-group mb-3">
                                        <input type="time" class="form-control"
                                            aria-label="Text input with dropdown button" placeholder="Enter height"
                                            formControlName="workingHoursTo" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                <label>Latitude</label>
                                <input type="text" class="form-control" placeholder="Enter latitude"
                                    formControlName="latitude" />
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                <label>Longitude</label>
                                <input type="text" class="form-control" placeholder="Enter longitude"
                                    formControlName="longitude" />
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 mb-2">
                                <label>Status</label>
                                <div class="form-switch">
                                    <input class="form-check-input" type="checkbox" role="switch" id="status"
                                        [checked]="status" (click)="onSwitchChange()" />
                                    <label class="form-check-label" for="status" *ngIf="status">Active</label>
                                    <label class="form-check-label" for="status" *ngIf="!status">Inactive</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="d-flex justify-content-end gap-2">
                    <button type="button" class="btn cancel-btn" (click)="routeListWareHouse()">Cancel</button>
                    <button type="button" class="btn save-btn" [disabled]="addWareHouseForm.invalid || !access['edit']"
                        (click)="editWareHouse()">Update</button>
                </div>
            </div>
        </div>
    </div>
</section>