<section id="notificationsSec">
    <div class="container-fluid">
        <div class="row mt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 right-side-area">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-9 col-xl-9 col-lg-9 col-md-6 col-sm-12 col-xs-12 my-auto">
                                <ul class="nav nav-pills" id="pills-tab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="pills-all-tab" data-bs-toggle="pill"
                                            data-bs-target="#pills-all" type="button" role="tab"
                                            aria-controls="pills-all" aria-selected="true"
                                            (click)="getAllNotification()">All</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="pills-unread-tab" data-bs-toggle="pill"
                                            data-bs-target="#pills-unread" type="button" role="tab"
                                            aria-controls="pills-unread" aria-selected="false"
                                            (click)="unreadNotification()">Unread</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="pills-read-tab" data-bs-toggle="pill"
                                            data-bs-target="#pills-read" type="button" role="tab"
                                            aria-controls="pills-read" (click)="readNotification()">Read</button>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 my-auto"
                                [formGroup]="filterForm">
                                <input type="date" class="form-control" [max]="getToday()" placeholder="Select date"
                                    formControlName="date" (change)="applyFilter()">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="tab-content" id="v-pills-tabContent">
                                    <div class="tab-pane fade show active" id="v-pills-general" role="tabpanel"
                                        aria-labelledby="v-pills-general-tab">
                                        <div class='row mt-2'>
                                            <div
                                                class='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2'>
                                                <div class='notifications-body'
                                                    *ngFor="let item of notificationList;let i =index">
                                                    <div>
                                                        <img *ngIf="item.Title=='Order Delivered'"
                                                            src="../../../../../../assets/img/Return Item Received.png">
                                                        <img *ngIf="item.Title=='Return Item Received'"
                                                            src="../../../../../../assets/img/dashboardRefundOrders.png">
                                                        <img *ngIf="item.Title=='Orderitem cancelled'"
                                                            src="../../../../../../assets/img/Order Cancelled.png">
                                                        <img *ngIf="item.Title=='Order Confirmed'"
                                                            src="../../../../../../assets/img/Order Confirmed.png">
                                                        <img *ngIf="item.Title=='Return Cancelled'"
                                                            src="../../../../../../assets/img/Return Cancelled.png">
                                                        <img *ngIf="item.Title=='Abusive Reported'"
                                                            src="../../../../../../assets/img/abusive.png">
                                                        <img *ngIf="item.Title=='Product Out Of Stock'"
                                                            src="../../../../../../assets/img/out-of-stock.png">
                                                    </div>
                                                    <div class="ms-3">
                                                        <h5 (click)="gotoUrl(item)">{{item.Message}}</h5>
                                                        <p>{{item.DateTime.split('T')[0].split('-')[2]}}/{{item.DateTime.split('T')[0].split('-')[1]}}/{{item.DateTime.split('T')[0].split('-')[0]}}
                                                            <i class="fas fa-circle ms-2 me-2"></i>
                                                            <span>{{item.DateTime
                                                                |date:'shortTime'}}</span>
                                                        </p>
                                                    </div>
                                                    <div class='contact-edit-options ms-auto'>
                                                        <i class="far fa-trash-alt red-tooltip" title="Delete"
                                                            (click)="deleteConfirmationModal(item)"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<!-- deleteModalToggle start -->
<p-dialog [showHeader]=false [(visible)]="deleteModalToggle" [baseZIndex]="10000" [modal]="true">
    <div class="body mt-3" id="deleteModalToggle">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="delete-image">
                    <img src="../../../../../../assets/img/delete.png">
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="confirm-msg">
                    <h6>Are You Sure?</h6>
                    <p>You will not be able to recover this notification!</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="confirm-btns">
                    <button type="button" class="btn cancel-btn" (click)="deleteModalToggle=false">Cancel</button>
                    <button type="button" class="btn delete-confirm-btn"
                        (click)="confirmDeleteNotification()">Delete</button>
                </div>
            </div>
        </div>
    </div>
</p-dialog>
<!-- deleteModalToggle end -->