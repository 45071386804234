import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import { MessageService } from 'primeng/api';
import * as query from 'graphql/queries';
import { StatusList, UserRolesList } from '@/common-list';
import { FormBuilder } from '@angular/forms';
import { formatDate } from '@angular/common';
import { ROUTES } from '@/redirect-routes';
import { UserAuthService } from '@services/user-auth/user-auth.service';
import * as mutation from 'graphql/mutations';
import { Messages } from '@/Toaster-messages';
import { Status, UserRole } from 'API';
import { CommonService } from '@services/common/common.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrl: './users-list.component.scss'
})
export class UsersListComponent {
  position: any;
  displayFilterModalToggle = false;
  filterApplied: boolean = false
  deleteModalToggle: any;
  usersList: any = [];
  randomColorsForuserNames: any = []
  copyUsersList: any = [];
  filterObject: any = {};
  searchText: any = '';
  status: any = '';
  registeredOn: any = '';
  role: any = '';
  email: any = '';
  maxDate: any;
  selectedUser: any;
  userRolesList = UserRolesList;
  rolesList = UserRolesList;
  statusList = StatusList;
  searchKey: any;
  access: any = {}

  filterForm = this.formBuilder.group({
    status: [],
    registeredOn: [],
    role: ['']
  });

  constructor(
    private router: Router,
    public auth: UserAuthService,
    private common: CommonService,
    private awsApi: ApiService,
    private formBuilder: FormBuilder,
    private messageService: MessageService
  ) { }

  async ngOnInit(): Promise<void> {
    this.position = 'right';
    this.maxDate = new Date();
    this.getRoleManagement()
    this.filterForm.patchValue({ status: this.statusList })
    this.filterObject['role'] = { ne: UserRole.CUSTOMER };
    this.startingFunction();
  }

  async getRoleManagement() {
    this.awsApi.loading.next(true);
    try {
      await this.common.getPermissionsOfUser('Users', 'Manage Users');
      this.access = this.common.access;
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  async startingFunction() {
    this.awsApi.loading.next(true);
    await this.getUsersList();
  }

  async getUsersList() {
    this.awsApi.loading.next(true);
    try {
      this.usersList = (await this.common.listUsersAuthorized(this.filterObject))
        .filter((item) => item.createdAt)
        .sort((a: any, b: any) =>
          new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime() // Sort in descending order
        );
      this.copyUsersList = this.usersList;
      if (this.filterForm.controls.status.value?.length > 0) {
        this.filterApplied = true
        let statusList = this.filterForm.controls.status.value.map((status: any) => status.value);
        this.usersList = this.copyUsersList.filter((arr: any) => {
          return statusList.includes(arr.status)
        });
      }
      this.randomColorsForuserNames = this.generateRandomColors(this.usersList.length)
      this.copyUsersList = this.usersList
      this.awsApi.loading.next(false);
    } catch (error) { }
  }

  clearFilter() {
    delete this.filterObject['createdAt'];
    delete this.filterObject['role'];
  }

  async search(searchText: any) {
    this.awsApi.loading.next(true);
    if (searchText != '') {
      this.searchKey = searchText.toLowerCase();
      this.searchKey = this.searchKey.replace(/\s{2,}/g, ' ');
      this.searchKey = (this.searchKey.trim()).replace(/\s+/g, '');
      this.filterObject['searchKey'] = { contains: this.searchKey }
      this.awsApi.loading.next(false);
    } else {
      this.awsApi.loading.next(false);
      delete this.filterObject['searchKey']
    }
    await this.startingFunction()
  }

  async applyFilter() {
    this.clearFilter()
    this.awsApi.loading.next(true);
    this.filterRole();
    this.filterRegisteredOnDate();
    this.displayFilterModalToggle = false;
    await this.startingFunction();
  }

  async filterRegisteredOnDate() {
    if (this.filterForm.controls.registeredOn.value?.length == 2 && this.filterForm.controls.registeredOn.value[0] != null && this.filterForm.controls.registeredOn.value[1] != null) {
      this.filterApplied = true
      var selectedDate1 = new Date(this.filterForm.controls.registeredOn.value[0])
      let selectedDate2 = new Date(this.filterForm.controls.registeredOn.value[1])

      var selectedDate2Formatted = selectedDate2.setDate(selectedDate2.getDate() + 1)
      this.filterObject['createdAt'] = { ge: formatDate(selectedDate1, 'yyyy-MM-dd', 'en-US'), le: formatDate(selectedDate2Formatted, 'yyyy-MM-dd', 'en-US') }
    }
    if (this.filterForm.controls.registeredOn.value?.length == 2 && this.filterForm.controls.registeredOn.value[0] != null && this.filterForm.controls.registeredOn.value[1] == null) {
      this.filterApplied = true
      var selectedDate1 = new Date(this.filterForm.controls.registeredOn.value[0])
      this.filterObject['createdAt'] = { contains: formatDate(selectedDate1, 'yyyy-MM-dd', 'en-US') }
    }
  }

  filterRole() {
    if (this.filterForm.controls.role.value) {
      this.filterApplied = true
      this.filterObject['role'] = { eq: this.filterForm.controls.role.value };
    }
    else {
      this.filterObject['role'] = { ne: UserRole.CUSTOMER };
    }
  }

  changeStatus(userId: any, type: any) {
    this.awsApi.loading.next(true);
    let data = {
      pk: 'USER#',
      id: userId,
      status: type == 'Deactivate' ? Status.INACTIVE : Status.ACTIVE,
      updatedAt: new Date().toISOString()
    };
    this.awsApi.client
      .graphql({
        query: mutation.updateUser,
        variables: {
          input: {
            ...data
          }
        }
      })
      .then((response) => {
        this.awsApi.loading.next(false);
        this.startingFunction();
      })
      .catch((error) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      });
  }

  async deleteUser() {
    this.deleteModalToggle = false;
    this.awsApi.loading.next(true);
    let data = {
      loginuserid: localStorage.getItem('userId'),
      userid: this.selectedUser.id,
      email: this.selectedUser.email
    };
    this.common.deleteUser(data, await this.common.getHeader())
      .subscribe(async (result) => {
        if (result.status) {
          this.awsApi.loading.next(false);
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: Messages.USER_DELETED
          });
          await this.startingFunction();
        } else {
          this.deleteModalToggle = false;
          this.awsApi.loading.next(false);
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: Messages.SOMETHING_WENT_WRONG
          });
        }
      });
  }

  showFilterModalDialog(position: string) {
    this.position = position;
    this.displayFilterModalToggle = true;
  }

  closeFilterModalDialog() {
    this.displayFilterModalToggle = false;
    this.startingFunction();
  }

  cancelFilterModalDialog() {
    this.displayFilterModalToggle = false;
    this.clearFilter();
    this.filterForm.reset();
    this.filterApplied = false
    this.filterObject['role'] = { ne: UserRole.CUSTOMER };
    this.startingFunction();
  }

  showDeleteConfirmationModalDialog(item: any) {
    this.selectedUser = item;
    this.deleteModalToggle = true;
  }
  redirectToAddUserPage() {
    this.router.navigate([ROUTES.ADD_USER]);
  }
  redirectToEditUser(user) {
    this.router.navigate([ROUTES.ADD_USER], { queryParams: { id: user.id, type: 'edit' } });
  }

  generateRandomColors(count: number): string[] {
    const colors: string[] = [];
    for (let i = 0; i < count; i++) {
      colors.push(this.getRandomColor());
    }
    return colors;
  }

  getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
}