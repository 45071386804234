<section id="addNewContentSectionSec">
    <div class="container-fluid">
        <div class="row mb-1 mt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card mb-1 heading-card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex justify-content-start align-items-center">
                                        <i class="fas fa-chevron-left" (click)="redirectToContentListPage()"></i>
                                        <h6 *ngIf="!editCont">{{selectedContent.contentName}}</h6>
                                        <h6 *ngIf="editCont">Edit {{selectedContent.contentName}}</h6>
                                    </div>
                                    <div>
                                        <button type="button" [disabled]="!access['add']"
                                            *ngIf="!(selectedContent.title && selectedContent.content) && !(addContent || editCont)"
                                            class="btn" (click)="showaddNewContent()">Add Content Data</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-1" *ngIf="selectedContent.title && viewContent">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                <div class="accordion" id="accordionExample">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header d-flex w-100 align-items-center gap-2"
                                            id="headingOne">
                                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne" aria-expanded="true"
                                                aria-controls="collapseOne">
                                                {{selectedContent.title}}
                                            </button>
                                            <span [ngClass]="{ disable: !access['edit'] }"
                                                class="material-symbols-outlined edit-icon me-2" (click)="editContent()"
                                                title="Edit">
                                                edit_square
                                            </span>
                                            <span [ngClass]="{ disable: !access['delete'] }"
                                                class="material-symbols-outlined delete-icon" (click)="deleteContent()"
                                                title="Delete">
                                                delete
                                            </span>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse show"
                                            aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p [innerHTML]="selectedContent.content"></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="addNewContentSectionSec" *ngIf="addContent || editCont">
    <div class="container-fluid">
        <div class="row mb-1">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card mb-1" [formGroup]="addContentForm">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                <div>
                                    <label>Title<span class="mandatory">*</span></label>
                                    <input type="text" class="form-control" placeholder="Enter" formControlName="title">
                                    <span class="text-danger"
                                        *ngIf="(addContentForm.controls.title.touched) && !addContentForm.controls.title?.valid">
                                        <div *ngIf="addContentForm.controls.title.errors['required']">
                                            Title is required.
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                <div>
                                    <label>Content<span class="mandatory">*</span></label>
                                    <ckeditor [editor]="Editor" data="<h1>Hello from CKEditor 5!</h1>"
                                        (ready)="onReady($event)" (change)="onChange($event)" formControlName="content">
                                    </ckeditor>
                                    <span class="text-danger"
                                        *ngIf="(addContentForm.controls.content.touched) && !addContentForm.controls.content?.valid">
                                        <div *ngIf="addContentForm.controls.content.errors['required']">
                                            Content is required.
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="d-flex justify-content-end gap-2">
                    <button type="button" class="btn cancel-btn" (click)="redirectToContentListPage()">Cancel</button>
                    <button type="button" class="btn add-btn" [disabled]="addContentForm.invalid && !access['add']"
                        *ngIf="addContent" (click)="addNewContent()">Add</button>
                    <button type="button" class="btn add-btn" [disabled]="addContentForm.invalid && !access['edit']"
                        *ngIf="editCont" (click)="saveContent()">Update</button>
                </div>
            </div>
        </div>
    </div>
</section>