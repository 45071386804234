import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import * as mutation from 'graphql/mutations';
import { MessageService } from 'primeng/api';
import { ROUTES } from '@/redirect-routes';
import { StatusList } from '@/common-list';
import { FormBuilder } from '@angular/forms';
import { formatDate } from '@angular/common';
import { Status } from 'API';
import { Messages } from '@/Toaster-messages';
import { CommonService } from '@services/common/common.service';

@Component({
  selector: 'app-list-delivery-partner',
  templateUrl: './list-delivery-partner.component.html',
  styleUrl: './list-delivery-partner.component.scss'
})
export class ListDeliveryPartnerComponent implements OnInit {
  position: any;
  displayFilterModalToggle = false;
  filterApplied: boolean = false
  deleteModalToggle: any;
  filterObject: any = {}
  searchKey: any
  searchText: any = ''
  deliveryPartnerList: any = []
  copyDelivPartnersList: any = []
  deleteDeliveryPartner: any
  statusList = StatusList
  maxDate: any
  access: any = {}

  filterForm = this.formBuilder.group({
    status: [],
    registeredOn: []
  })

  constructor(
    private router: Router, private common: CommonService, private awsApi: ApiService, private formBuilder: FormBuilder, private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.maxDate = new Date()
    this.getRoleManagement()
    this.filterForm.patchValue({ status: this.statusList })
    this.applyFilter()
  }

  async getRoleManagement() {
    this.awsApi.loading.next(true);
    try {
      await this.common.getPermissionsOfUser('Users', 'Delivery Partners');
      this.access = this.common.access;
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  async getAllDeliveryPartners() {
    this.awsApi.loading.next(true);
    let fieldsToFetch = ['id', 'pk', 'logo', 'companyName', 'contactPerson', 'email', 'phoneNumber', 'searchKey', 'createdAt', 'status']
    Promise.all([await this.common.getDeliveryPartners(this.filterObject, fieldsToFetch)]).then((response) => {
      this.deliveryPartnerList = response[0].sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      this.copyDelivPartnersList = this.deliveryPartnerList
      if (this.filterForm.controls.status.value?.length > 0) {
        this.filterApplied = true
        let statusList = this.filterForm.controls.status.value.map((status: any) => status.value);
        this.deliveryPartnerList = this.copyDelivPartnersList.filter((arr: any) => {
          return statusList.includes(arr.status)
        });
      }
      this.awsApi.loading.next(false);
    })
      .catch((error) => {
        this.awsApi.loading.next(false);
      });
  }

  changeStatus(deliveryPartnerId: any, type: any) {
    let data = {
      pk: 'DELP#',
      id: deliveryPartnerId,
      status: type == 'Deactivate' ? Status.INACTIVE : Status.ACTIVE,
      updatedAt: new Date().toISOString()
    }
    this.awsApi.client
      .graphql({
        query: mutation.updateDeliveryPartner,
        variables: {
          input: {
            ...data
          }
        }
      }).then((response) => {
        this.awsApi.loading.next(false);
        this.getAllDeliveryPartners();
      }).catch((error) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      })
  }

  deleteDeliveryPartnerItem(item: any) {
    this.deleteModalToggle = true;
    this.deleteDeliveryPartner = item;
  }

  confirmDeleteDeliveryPartner() {
    this.deleteModalToggle = false;
    let data = {
      pk: this.deleteDeliveryPartner.pk,
      id: this.deleteDeliveryPartner.id
    };
    this.awsApi.client
      .graphql({
        query: mutation.deleteDeliveryPartner,
        variables: {
          input: {
            ...data
          }
        }
      })
      .then((response) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Delivery-partner deleted successfully!'
        });
        this.getAllDeliveryPartners();
      })
      .catch((error) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      });
  }

  applyFilter() {
    this.filterRegisteredOnDate()
    this.displayFilterModalToggle = false;
    this.getAllDeliveryPartners()
  }

  filterRegisteredOnDate() {
    if (this.filterForm.controls.registeredOn.value?.length == 2 && this.filterForm.controls.registeredOn.value[0] != null && this.filterForm.controls.registeredOn.value[1] != null) {
      this.filterApplied = true
      var selectedDate1 = new Date(this.filterForm.controls.registeredOn.value[0])
      let selectedDate2 = new Date(this.filterForm.controls.registeredOn.value[1])

      var selectedDate2Formatted = selectedDate2.setDate(selectedDate2.getDate() + 1)
      this.filterObject['createdAt'] = { ge: formatDate(selectedDate1, 'yyyy-MM-dd', 'en-US'), le: formatDate(selectedDate2Formatted, 'yyyy-MM-dd', 'en-US') }
    }
    if (this.filterForm.controls.registeredOn.value?.length == 2 && this.filterForm.controls.registeredOn.value[0] != null && this.filterForm.controls.registeredOn.value[1] == null) {
      this.filterApplied = true
      var selectedDate1 = new Date(this.filterForm.controls.registeredOn.value[0])
      this.filterObject['createdAt'] = { contains: formatDate(selectedDate1, 'yyyy-MM-dd', 'en-US') }
    }
  }

  search(searchText) {
    if (searchText) {
      this.searchKey = searchText.toLowerCase()
      this.searchKey = this.searchKey.replace(/\s{2,}/g, ' ');
      this.searchKey = ((this.searchKey.trim()).replace(/\s+/g, '')).trim();
      this.filterObject['searchKey'] = { contains: this.searchKey }
    }
    else {
      delete this.filterObject['searchKey']
    }
    this.getAllDeliveryPartners()
  }

  redirectToAddDeliveryPartnerPage() {
    this.router.navigate([ROUTES.ADD_DELIVERY_PARTNER]);
  }

  showFilterModalDialog(position: string) {
    this.position = position;
    this.displayFilterModalToggle = true;
  }

  closeFilterModalDialog() {
    this.displayFilterModalToggle = false;
  }

  cancelFilterModalDialog() {
    this.displayFilterModalToggle = false;
    delete this.filterObject['createdAt']
    this.filterForm.reset();
    this.filterApplied = false
    this.getAllDeliveryPartners();
  }

  showDeleteConfirmationModalDialog(item: any) {
    this.deleteModalToggle = true;
  }

  redirectToEditDeliveryPartner(item) {
    this.router.navigate([`edit-delivery-partners`], { queryParams: { id: item.id, pk: item.pk } });
  }
}
