import { ROUTES } from '@/redirect-routes';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import * as query from 'graphql/queries';
import * as mutation from 'graphql/mutations';
import { MessageService } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '@services/common/common.service';
import { Subscription } from 'rxjs';
import { formatDate } from '@angular/common';
import { productStatus } from 'API';
import { Messages } from '@/Toaster-messages';
import _ from 'underscore';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrl: './product-list.component.scss'
})
export class ProductListComponent {
  private routeParamsSubscription: Subscription;
  value: number = 5;
  defaultSubCategory: String | null = null
  position: any;
  displayFilterModalToggle = false;
  displayViewCountsModalToggle = false;
  deleteModalToggle: any;
  subCategoryList: any = []
  filterApplied: boolean = false
  prodList: any = []
  productList: any[] = [];
  productDetailsList: any = []
  copyProductList: any[] = []
  productDataList: any = []
  page: any
  pageSize: any
  isSearchEnabled: boolean = false
  loading: boolean
  endOfData: boolean
  deleteProduct: any
  deleteProductDetails: any
  deleteProductImages: any
  filterForm: FormGroup;
  filterObject: any = {}
  access: any = {}
  noOfProducts: any = 0
  brandList: any = []
  mainCategoryList: any = []
  productVisitList: any = []
  selectedCategory: string | null = null;
  selectedBrand: string | null = null;
  selectedMainCategory: string | null = null;
  commonSearchText: any
  visitorsList: any = []
  usersList: any = []
  domainName: any
  totalStocks: any = []
  reservedStocks: any = []
  availableStocks: any = []
  visitCount: any = []
  today: any = new Date();
  thirtyDaysAgo = new Date((new Date()).setDate(this.today.getDate() - 30))
  productStatusList: any = [
    { name: "Draft", value: productStatus.draft },
    { name: "Published", value: productStatus.published },
    { name: "Scheduled", value: productStatus.scheduled },
    { name: "Inactive", value: productStatus.inactive }
  ];

  constructor(
    private router: Router,
    private awsApi: ApiService,
    private fb: FormBuilder,
    private messageService: MessageService,
    private common: CommonService,
    private route: ActivatedRoute
  ) {
    this.filterForm = this.fb.group({
      brand: [''],
      mainCategory: [''],
      subCategory: [''],
      status: [''],
      createOn: [''],
      searchText: ['']
    });
  }

  async ngOnInit() {
    this.domainName = window.location.href.split('list-product')[0] + 'edit-product?id=';
    this.awsApi.loading.next(true);
    this.position = 'right';
    var promiseArray: any = [];
    promiseArray.push(
      this.getAllMainCategory(),
      this.getAllBrand(),
      this.getUsers()
    );
    Promise.all(promiseArray)
      .then((result: any) => {
        this.mainCategoryList = result[0].data.listCategories.items;
        this.brandList = result[1].data.listBrands.items;
        this.usersList = result[3]
      })
    // common search functionality
    await this.startingFunction()
    await this.getAllCategory();
    this.filterForm.patchValue({ status: this.productStatusList })
    this.filterForm.patchValue({ createOn: [this.thirtyDaysAgo, this.today] });
    await this.filterDate();
    await this.filterStatus();
    this.noOfProducts = this.productList.length;
    try {
      this.routeParamsSubscription = this.route.queryParams.subscribe(async params => {
        const newSearchText = params['searchText'];
        if (newSearchText !== this.commonSearchText) {
          this.commonSearchText = newSearchText;
          this.awsApi.loading.next(true);

          if (this.commonSearchText) {
            const currentUrl = this.router.url;
            const urlWithoutParams = currentUrl.split('?')[0];

            if (currentUrl !== urlWithoutParams) {
              this.router.navigateByUrl(urlWithoutParams);
            }
            this.productList = JSON.parse(localStorage.getItem("products"))
            this.filterProducts();
            this.filterForm.reset()
            await this.filterDate();
            await this.filterStatus();
            this.noOfProducts = this.productList.length;
          }
        }
        this.awsApi.loading.next(false);
      });
    } catch (error) { }
    await this.setStockCounts();
  }

  filterProducts() {
    const str = this.commonSearchText.toLowerCase().trim(); // Normalize search text
    // Filter products based on common search key
    this.copyProductList = this.productList
    this.productList = this.copyProductList.filter((item: any) => {
      if (item.searchKey) {
        const searchKey = item.searchKey.toLowerCase().replace(/\s{2,}/g, ' ').trim();
        const searchKeyWithoutSpaces = searchKey.replace(/\s+/g, '');
        return searchKey.includes(str) || searchKeyWithoutSpaces.includes(str);
      }
      return false;
    });
    this.copyProductList = this.productList
  }

  ngOnDestroy(): void {
    this.routeParamsSubscription?.unsubscribe();
  }

  async startingFunction() {
    try {
      await this.common.getPermissionsOfUser('Products', 'Manage Product');
      this.access = this.common.access;
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  onScroll() {
    if (!this.loading && !this.endOfData) {
      const container = document.querySelector('.scroll-container');
      if (
        container &&
        container.scrollHeight - container.scrollTop === container.clientHeight
      ) {
        this.loadMore();
      }
    }
  }

  async loadMore() {
    this.page++
    // Loading more data
    this.loading = true;
    const startIndex = (this.page - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    setTimeout(() => {
      this.productDataList = this.productDataList.concat(this.productList.slice(startIndex, endIndex));
      this.loading = false;

      if (this.productDataList.length >= this.productList.length) {
        this.endOfData = true;
      }
    }, 2000);
  }

  loadData() {
    // Initial data loading   
    this.loading = true;
    const startIndex = (this.page - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    if (this.prodList.length == 0) {
      this.endOfData = true;
    } else {
      this.loading = false;
      setTimeout(() => {
        this.productDataList = this.productList.slice(startIndex, endIndex);
      }, 0);
    }
  }

  async initializePagination() {
    this.page = 1;
    this.pageSize = 20;
    this.loading = false;
    this.endOfData = false;
    this.loadData()
  }

  async getProducts() {
    try {
      await this.common.updateLatestProductsInLocalStore();
      this.productList = JSON.parse(localStorage.getItem("products")) || [];
      this.copyProductList = [...this.productList];
      this.prodList = this.productList;
      this.noOfProducts = this.productList.length;
    } catch (error) { }
  }
  async getAllCategory() {
    await this.getProducts()
    await this.initializePagination()
    this.awsApi.loading.next(false);
  }

  async setStockCounts() {
    try {
      this.visitCount = new Array(this.productList.length).fill(0);
      this.productVisitList = []
      for (let index = 0; index < this.productList.length; index++) {
        this.productVisitList[index] = await this.getProductVisit({ prodId: { eq: this.productList[index].id } })
        this.visitCount[index] = this.productVisitList[index].reduce((visitCount, productVisit) => {
          if (productVisit.prodId == this.productList[index].id) {
            return visitCount + productVisit?.visitedInfo.length;
          }
          return visitCount;
        }, 0);
      }
    }
    catch (error) { }
  }

  deleteProductItem(item: any) {
    this.deleteModalToggle = true;
    this.deleteProduct = item;
  }

  async getProductImagesList() {
    let items = [];
    let nextToken = null;
    do {
      const response = await this.awsApi.client.graphql({
        query: query.listProductImages,
        variables: {
          pk: 'PROIMG#',
          id: null,
          filter: { productID: { eq: this.deleteProduct.id } },
          limit: 650,
          nextToken: nextToken,
          sortDirection: null
        }
      })
      items = items.concat(response.data.listProductImages.items);
      nextToken = response.data.listProductImages.nextToken;
    } while (nextToken !== null)
    return items
  }

  async updateProduct(data) {
    return this.awsApi.client
      .graphql({
        query: mutation.updateProduct,
        variables: {
          input: {
            ...data
          }
        }
      })
  }

  async updateProductImage(data) {
    return this.awsApi.client
      .graphql({
        query: mutation.updateProductImage,
        variables: {
          input: {
            ...data
          }
        }
      })
  }

  async updateProductDetail(data) {
    return this.awsApi.client
      .graphql({
        query: mutation.updateProductDetails,
        variables: {
          input: {
            ...data
          }
        }
      })
  }

  async confirmDeleteProduct() {
    this.deleteModalToggle = false;
    let promiseArray = []
    let filter = { productId: { eq: this.deleteProduct.id } };
    if (!await this.common.isAnyOrdersOpen(filter)) {
      // Updating product with 'deleted' status 
      let productDdata = {
        pk: this.deleteProduct.pk,
        id: this.deleteProduct.id,
        status: productStatus.deleted
      };
      promiseArray.push(this.updateProduct({ ...productDdata }))

      this.deleteProductImages = await this.getProductImagesList()
      if (this.deleteProductImages.length > 0) {
        // Updating product images 
        _.each(this.deleteProductImages, (image) => {
          let productImageData = {
            pk: image.pk,
            id: image.id
          }
          promiseArray.push(this.updateProductImage({ ...productImageData, isDeleted: true }))
        })
      }
      let productDetails = await this.common.getProductDetailsWithEditQuery({ productID: { eq: this.deleteProduct.id } }, ['pk', 'id'])
      if (productDetails.length > 0) {
        // Updating product details 
        _.each(productDetails, (detail) => {
          let productDetail = {
            pk: detail.pk,
            id: detail.id
          }
          promiseArray.push(this.updateProductDetail({ ...productDetail, isDeleted: true }))
        })
      }
      Promise.all(promiseArray).then(async (response) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Product deleted successfully!'
        });
        this.ngOnInit();
      })
        .catch((error) => {
          this.awsApi.loading.next(false);
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: Messages.SOMETHING_WENT_WRONG
          });
        });
    }
  }

  showFilterModalDialog(position: string) {
    this.position = position;
    this.displayFilterModalToggle = true;
  }

  closeFilter() {
    this.displayFilterModalToggle = false;
  }

  closeFilterModalDialog() {
    this.filterApplied = false
    this.displayFilterModalToggle = false;
    this.filterForm.reset()
    this.getAllCategory()
  }

  showDeleteConfirmationModalDialog(item: any) {
    this.deleteModalToggle = true;
    this.deleteProduct = item
  }

  redirectToAddProductPage() {
    this.router.navigate([ROUTES.ADD_PRODUCT]);
  }

  getAllBrand() {
    return this.awsApi.client.graphql({
      query: query.listBrands,
      variables: {
        pk: 'BRAND#',
        id: null,
        filter: null,
        limit: 9999,
        nextToken: null,
        sortDirection: null
      }
    });
  }

  async getProductVisit(filterObject: any = {}) {
    let items = [];
    let nextToken = null;
    do {
      const response = await this.awsApi.client.graphql({
        query: query.listProductVisits,
        variables: {
          filter: filterObject,
          limit: 650,
          nextToken: nextToken,
          sortDirection: null
        }
      })
      items = items.concat(response.data.listProductVisits.items);
      nextToken = response.data.listProductVisits.nextToken;
    } while (nextToken !== null)
    return items
  }

  getAllMainCategory() {
    return this.awsApi.client.graphql({
      query: query.listCategories,
      variables: {
        pk: 'MAIN#',
        slug: null,
        filter: null,
        limit: 9999,
        nextToken: null,
        sortDirection: null
      }
    });
  }

  async getUsers() {
    let items = [];
    let nextToken = null;
    do {
      const response = await this.awsApi.client.graphql({
        query: query.listUsers,
        variables: {
          limit: 650,
          nextToken: nextToken,
          sortDirection: null
        }
      })
      items = items.concat(response.data.listUsers.items);
      nextToken = response.data.listUsers.nextToken;
    } while (nextToken !== null)
    return items
  }

  getAllSubCategory(mainCategoryID) {
    return this.awsApi.client.graphql({
      query: query.listCategories,
      variables: {
        pk: 'SUB#' + mainCategoryID
      }
    });
  }

  onSelectMainCategory() {
    this.filterForm.controls.subCategory.reset()
    this.subCategoryList = []
    let mainCategoryId = this.filterForm.controls.mainCategory?.value.id
    this.getAllSubCategory(mainCategoryId)
      .then((result) => {
        this.subCategoryList = result.data.listCategories.items;
      })
      .catch((error) => {
      });
  }

  filterBrand() {
    if (this.filterForm.controls.brand?.value && this.filterForm.controls.brand?.value.length > 0) {
      this.filterApplied = true
      const selectedBrandNames = this.filterForm.controls.brand.value.map((brand: any) => brand.id);
      this.productList = this.copyProductList.filter((item: any) => {
        if (item.brandID) {
          return selectedBrandNames.includes(item.brandID);
        } else {
          return false;
        }
      })
      this.copyProductList = this.productList
    }
  }

  filterMainCategory() {
    if (this.filterForm.controls.mainCategory?.value) {
      this.filterApplied = true
      const selectedMainCategory = this.filterForm.controls.mainCategory?.value.id
      this.productList = this.copyProductList.filter((item: any) => {
        if (item.mainCategory) {
          return item.mainCategoryID == selectedMainCategory;
        } else {
          return false;
        }
      });
      this.copyProductList = this.productList
    }
  }

  filterSubCategory() {
    if (this.filterForm.controls.subCategory?.value && this.filterForm.controls.subCategory?.value.length > 0) {
      this.filterApplied = true
      const selectedCategories = this.filterForm.controls.subCategory.value.map((category: any) => category.id);
      this.productList = this.copyProductList.filter((item: any) => {
        if (item.subCategoryID) {
          return selectedCategories.includes(item.subCategoryID);
        } else {
          return false;
        }
      });
      this.copyProductList = this.productList
    }
  }

  filterStatus() {
    if (this.filterForm.controls.status?.value && this.filterForm.controls.status?.value.length > 0) {
      this.filterApplied = true
      const selectedStatuses = this.filterForm.controls.status.value.map((status: any) => status.value);
      this.productList = this.copyProductList.filter((item: any) => {
        if (item.status) {
          return selectedStatuses.includes(item.status);
        } else {
          return false;
        }
      })
      this.copyProductList = this.productList
    }
  }

  filterDate() {
    if (this.filterForm.controls.createOn.value?.length == 2 && this.filterForm.controls.createOn.value[0] != null) {
      this.filterApplied = true
      var formattedDate1 = formatDate(new Date(this.filterForm.controls.createOn.value[0]), 'yyyy-MM-dd', 'en-US');
      if (this.filterForm.controls.createOn.value[1] != null) {
        var formattedDate2 = formatDate(this.filterForm.controls.createOn.value[1], 'yyyy-MM-dd', 'en-US');

        this.productList = this.copyProductList.filter((item: any) => {
          if (item.createdAt) {
            var itemDate = ((item.createdAt).toString()).split('T')[0];
            if (itemDate >= formattedDate1 && itemDate <= formattedDate2) {
              return true;
            }
          }
          return false;
        })
      }
      else {
        this.productList = this.copyProductList.filter((item: any) => {
          if (item.createdAt) {
            var itemDate = ((item.createdAt).toString()).split('T')[0];
            if (itemDate == formattedDate1) {
              return true;
            }
          }
          return false;
        });
      }
      this.copyProductList = this.productList
    }
  }

  filterSearchText() {
    if (this.filterForm.controls.searchText?.value) {
      this.isSearchEnabled = true;
      let str = this.filterForm.controls.searchText.value.toLowerCase();
      str = str.replace(/\s{2,}/g, ' ').trim();
      let strWithoutSpaces = str.replace(/\s+/g, '');
      this.productList = this.copyProductList.filter((item: any) => {
        if (item.searchKey) {
          let searchKey = item.searchKey.toLowerCase().replace(/\s{2,}/g, ' ').trim();
          let searchKeyWithoutSpaces = searchKey.replace(/\s+/g, '');
          if (searchKey.includes(str) || searchKeyWithoutSpaces.includes(strWithoutSpaces)) {
            return true;
          }
        }
        return false;
      });
      this.copyProductList = this.productList;
    } else {
      this.isSearchEnabled = false;
      if (!this.filterApplied) {
        this.productList = this.prodList;
      }
    }
  }

  async applyFilter() {
    this.awsApi.loading.next(true);
    this.copyProductList = this.prodList
    this.filterBrand()
    this.filterMainCategory()
    this.filterSubCategory()
    this.filterStatus()
    this.filterDate()
    this.filterSearchText()
    this.noOfProducts = this.productList.length
    this.page = 1;
    this.pageSize = 20;
    this.initializePagination()
    this.awsApi.loading.next(false);
    this.displayFilterModalToggle = false;
  }

  redirectToProductEditPage(product: any) {
    this.router.navigate([`edit-product`], { queryParams: { id: product.id, pk: product.pk, type: 'edit' } });
  }

  removeHtmlTags(productDescription): String {
    return ((productDescription.replace(/<\/?[^>]+(>|$)/g, "")).replace(/&nbsp;/g, " ")).replace(/&amp;/g, "&");
  }
  closeViewCountsModalDialog() {
    this.displayViewCountsModalToggle = false;
  }

  async showViewCountsModalDialog(position: string, index) {
    this.position = position;
    if (this.productVisitList[index].length > 0) {
      this.visitorsList = this.productVisitList[index]
        .sort((a: any, b: any) => new Date(b.visitedAt).getTime() - new Date(a.visitedAt).getTime())
      if (this.visitorsList.length > 0) {
        this.displayViewCountsModalToggle = true;
      }
      _.each(this.visitorsList, (visitor) => {
        visitor.visitedInfo.reverse()
        let user = this.usersList
          .filter((item) => { return item.id == visitor.userId })
        if (user.length > 0) {
          visitor['userName'] = user[0].userName ? user[0].userName : 'Anonymous'
        } else {
          visitor['userName'] = 'Anonymous'
        }
      })
    }
  }

  clearText() {
    this.filterForm.patchValue({
      searchText: ''
    })
    if (this.isSearchEnabled) {
      this.isSearchEnabled = false
      this.applyFilter()
    }
  }

  getAvailableStock(product) {
    let totalStock = product.totalStockQuantity || 0
    let reservedStock = product.totalReservedStock || 0
    return totalStock - reservedStock
  }
}