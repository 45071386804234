<section id="productAddSec">
  <form [formGroup]="productForm">
    <div class="container-fluid">
      <div class="row pt-2">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="card mb-1 heading-card">
            <div class="card-body">
              <div class="row">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div class="d-flex justify-content-start align-items-center">
                    <i class="fas fa-chevron-left" [routerLink]="['/list-product']"></i>
                    <h6>Add New Product</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 pe-0">
          <div class="card basic-info-card">
            <div class="card-header">
              <h5>Basic Information</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                  <label>Product Name <span class="mandatory">*</span></label>
                  <input type="text"
                    [ngClass]="(productForm.controls.name.touched && !productForm.controls.name?.valid) ? 'form-control error-focus' : 'form-control'"
                    formControlName="name" placeholder="Enter product Name" />
                  <span class="text-danger"
                    *ngIf="(productForm.controls.name.touched) && !productForm.controls.name?.valid">
                    <div *ngIf="productForm.controls.name.errors['required']">
                      Product name is required.
                    </div>
                    <div *ngIf="productForm.controls.name.errors['maxlength']">
                      Product name should not exceed more than 500 characters.
                    </div>
                    <div *ngIf="productForm.controls.name.errors['pattern']">
                      Product name must contain at least one non-numeric character.
                    </div>
                  </span>
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">
                  <label>Main Category <span class="mandatory">*</span></label>
                  <div class="d-flex justify-content-between gap-2 align-items-center">
                    <div
                      [ngClass]="(productForm.controls.mainCategory.touched && !productForm.controls.mainCategory?.valid) ? 'flex-grow error-focus' : 'flex-grow'">
                      <select class="form-select form-control" *ngIf="mainCategoryList.length<=10"
                        [(ngModel)]="defaults.defaultMainCategory" formControlName="mainCategory"
                        aria-label="Default select example" (change)="onSelectMainCategory()">
                        <option [ngValue]="null" disabled>Choose Main Category</option>
                        <option *ngFor="
                        let mainCategory of mainCategoryList;
                        let mainCategoryIndex = index
                      " [ngValue]="mainCategory">
                          {{ mainCategory.catName }}
                        </option>
                      </select>
                      <p-dropdown formControlName="mainCategory" (onChange)="onSelectMainCategory($event)"
                        *ngIf="mainCategoryList.length>10" [options]="mainCategoryList" optionLabel="catName"
                        [filter]="true" filterBy="catName" placeholder="Choose Main Category" />
                    </div>
                    <div>
                      <button type=" button" class="btn refresh-btn" (click)="refreshList('mainCategory')"><i
                          class="fas fa-redo"></i></button>
                    </div>
                  </div>
                  <span class="text-danger"
                    *ngIf="(productForm.controls.mainCategory.touched) && !productForm.controls.mainCategory?.valid">
                    <div *ngIf="productForm.controls.mainCategory.errors['objectRequired']">
                      Main category is required.
                    </div>
                  </span>
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">
                  <label>Sub Category <span class="mandatory">*</span></label>
                  <select
                    [ngClass]="(productForm.controls.subCategory.touched && !productForm.controls.subCategory?.valid) ? 'form-select form-control error-focus' : 'form-select form-control'"
                    *ngIf="subCategoryList.length<=10" [(ngModel)]="defaults.defaultSubCategory"
                    formControlName="subCategory" aria-label="Default select example">
                    <option [ngValue]="null" selected>Choose Sub Category</option>
                    <option *ngFor="
                        let subCategory of subCategoryList;
                        let subCategoryIndex = index
                      " [ngValue]="subCategory">
                      {{ subCategory.catName }}
                    </option>
                  </select>
                  <p-dropdown formControlName="subCategory" *ngIf="subCategoryList.length>10"
                    [options]="subCategoryList" optionLabel="catName" [filter]="true" filterBy="catName"
                    placeholder="Choose Sub Category" />
                  <span class="text-danger"
                    *ngIf="(productForm.controls.subCategory.touched) && !productForm.controls.subCategory?.valid">
                    <div *ngIf="productForm.controls.subCategory.errors?.objectRequired">
                      Sub category is required.
                    </div>
                  </span>
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">
                  <label>Brand <span class="mandatory">*</span></label>
                  <div class="d-flex justify-content-between gap-2 align-items-center">
                    <div
                      [ngClass]="(productForm.controls.brand.touched && !productForm.controls.brand?.valid) ? 'flex-grow error-focus' : 'flex-grow'">
                      <select class="form-select form-control" *ngIf="brandList.length<=10"
                        [(ngModel)]="defaults.defaultBrand" formControlName="brand" aria-label="Default select example">
                        <option [ngValue]="null" disabled>Choose Brand</option>
                        <option *ngFor="
                        let brandItem of brandList;
                        let brandItemIndex = index
                      " [ngValue]="brandItem">
                          {{ brandItem.brandName }}
                        </option>
                      </select>
                      <p-dropdown formControlName="brand" *ngIf="brandList.length>10" [options]="brandList"
                        optionLabel="brandName" [filter]="true" filterBy="brandName" placeholder="Choose Brand" />
                    </div>
                    <div>
                      <button type="button" class="btn refresh-btn" (click)="refreshList('brand')"><i
                          class="fas fa-redo"></i></button>
                    </div>
                  </div>
                  <span class="text-danger"
                    *ngIf="(productForm.controls.brand.touched) && !productForm.controls.brand?.valid">
                    <div *ngIf="productForm.controls.brand.errors['objectRequired']">
                      Brand is required.
                    </div>
                  </span>
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">
                  <label>Country of Origin <span class="mandatory">*</span></label>
                  <div class="d-flex justify-content-between gap-2 align-items-center">
                    <div
                      [ngClass]="(productForm.controls.countryOfOrigin.touched && !productForm.controls.countryOfOrigin?.valid) ? 'flex-grow error-focus' : 'flex-grow'">
                      <select class="form-select form-control" *ngIf="countryList.length<=10"
                        aria-label="Default select example" [(ngModel)]="defaults.defaultCountry"
                        formControlName="countryOfOrigin">
                        <option [ngValue]="null" disabled>Choose Country of Origin</option>
                        <option *ngFor="
                        let countryItem of countryList;
                        let countryItemIndex = index
                      " [ngValue]="countryItem">
                          {{ countryItem.name }}
                        </option>
                      </select>
                      <p-dropdown formControlName="countryOfOrigin" *ngIf="countryList.length>10"
                        [options]="countryList" optionLabel="name" [filter]="true" filterBy="name"
                        placeholder="Choose Country of Origin" />
                    </div>
                    <div>
                      <button type="button" class="btn refresh-btn" (click)="refreshList('country')"><i
                          class="fas fa-redo"></i></button>
                    </div>
                  </div>
                  <span class="text-danger"
                    *ngIf="(productForm.controls.countryOfOrigin.touched) && !productForm.controls.countryOfOrigin?.valid">
                    <div *ngIf="productForm.controls.countryOfOrigin.errors['required']">
                      Country of origin is required.
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="card product-images-card">
            <div class="card-header">
              <h5>Product Images</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div>
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="mb-0">Featured Image <span class="mandatory">*</span></label>
                    </div>
                    @if (!productFormControl.featuredImage.value.url) {
                    <div class="featured-image-upload-modal" data-bs-toggle="modal" href="#uploadProImageModal"
                      role="button" (click)="
                          openUploadProImageModal(
                            productFormControl.featuredImage,
                            'featuredImg'
                          )
                        ">
                      <div>
                        <i class="far fa-image"></i>
                        <div class="upload-caption">
                          <p>Add product image</p>
                        </div>
                      </div>
                    </div>
                    }
                    @if (!!productFormControl.featuredImage.value.url) {
                    <div class="after-upload-featured-image">
                      <div class="text-right">
                        <i class="fas fa-edit pe-1" data-bs-toggle="modal" href="#uploadProImageModal" (click)="
                        openUploadProImageModal(
                          productFormControl.featuredImage,
                          'featuredImg'
                        )
                      "></i>
                        <i class="fas fa-trash-alt pe-1" (click)="deleteUrl('prodImage')"></i>
                      </div>
                      <img [src]="productFormControl.featuredImage.value?.url" />
                    </div>
                    }
                  </div>
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div class="row" formArrayName="galleryImages">
                    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="d-flex justify-content-between align-items-center" style="margin-bottom: 7px;">
                        <label class="mb-0">Gallery Images</label>
                      </div>
                    </div>
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2"
                      *ngFor="let item of galleryImages.controls; let i = index" [formGroupName]="i">
                      @if (!!item.get('url').value) {
                      <div class="after-upload-gallery-image">
                        <div class="text-right">
                          <i class="fas fa-edit pe-1" data-bs-toggle="modal" href="#uploadProImageModal" (click)="
                            openUploadProImageModal(item, 'galleryImg', i)
                          "></i>
                          <i class="fas fa-trash-alt pe-1" (click)="deleteUrl('galleryImage',i)"></i>
                        </div>
                        <img [src]="item.get('url').value" />
                      </div>
                      } @else {
                      <div class="gallery-image-upload-modal" data-bs-toggle="modal" href="#uploadProImageModal"
                        role="button" (click)="
                              openUploadProImageModal(item, 'galleryImg', i)
                            ">
                        <div>
                          <i class="far fa-image"></i>
                          <div class="upload-caption">
                            <p>Add Gallery Image</p>
                          </div>
                        </div>
                      </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 pe-0">
          <div class="card product-description-card">
            <div class="card-header">
              <h5>Product Description <span class="mandatory">*</span></h5>
            </div>
            <div class="card-body">
              <div class="row mt-2">
                <div
                  [ngClass]="(productForm.controls.productDescription.touched && !productForm.controls.productDescription?.valid) ? 'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2 error-focus' : 'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2'">
                  <ckeditor
                    [config]="{ toolbar: [ 'undo', 'redo', '|','bold','italic','|','link','|','blockquote','bulletedlist','numberedlist','code-block' ] }"
                    [editor]="Editor" data="<h1>Hello from CKEditor 5!</h1>" (ready)="onReady($event)"
                    (change)="onChange($event)" formControlName="productDescription">
                  </ckeditor>
                </div>
                <span class="text-danger"
                  *ngIf="(productForm.controls.productDescription.touched) && !productForm.controls.productDescription?.valid">
                  <div *ngIf="productForm.controls.productDescription.errors['required']">
                    Product description is required.
                  </div>
                  <div *ngIf="productForm.controls.productDescription.errors['maxlength']">
                    Product description should not exceed more than 1500 characters.
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="card specifications-card">
            <div class="card-header">
              <h5>Specifications</h5>
            </div>
            <div class="card-body">
              <div class="row" formGroupName="specificationForm">
                <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12 mb-2">
                  <label>Specification</label>
                  <input type="text" class="form-control" placeholder="Enter Specification" formControlName="name" />
                </div>
                <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12 mb-2">
                  <label>Specification Value</label>
                  <input type="text" class="form-control" placeholder="Enter Specification Value"
                    formControlName="value" />
                </div>
                <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12 mb-2">
                  <button type="button" class="btn add-btn"
                    [disabled]="!(specificationFormControl.name.value && specificationFormControl.value.value)"
                    (click)="addSpecificationItem()" (click)="addSpecificationItem()">
                    Add
                  </button>
                </div>
              </div>
              @if (addedSpecList.controls.length > 0) {
              <div class="row">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                  <div class="table-responsive tableFixHead">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Specification</th>
                          <th>Value</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody formArrayName="specificationList">
                        <tr *ngFor="
                            let item of addedSpecList.controls;
                            let i = index
                          " [formGroupName]="i">
                          <td>{{ item.get('name').value }}</td>
                          <td>{{ item.get('value').value }}</td>
                          <td>
                            <i class="fas fa-trash-alt" (click)="removeSpecItem(i)"></i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 pe-0">
          <div class="card">
            <div class="card-header">
              <h5>Features</h5>
            </div>
            <div class="card-body">
              <div class="row mt-2">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                  <ckeditor
                    [config]="{ toolbar: [ 'undo', 'redo', '|','bold','italic','|','link','|','blockquote','bulletedlist','numberedlist','code-block' ] }"
                    [editor]="Editor" data="<h1>Hello from CKEditor 5!</h1>" (ready)="onReady($event)"
                    (change)="onChange($event)" formControlName="productFeatures">
                  </ckeditor>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="card product-info-card">
            <div class="card-header">
              <h5>SEO Description</h5>
            </div>
            <div class="card-body">
              <div class="row mt-2">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                  <ckeditor
                    [config]="{ toolbar: [ 'undo', 'redo', '|','bold','italic','|','link','|','blockquote','bulletedlist','numberedlist','code-block' ] }"
                    [editor]="Editor" data="<h1>Hello from CKEditor 5!</h1>" (ready)="onReady($event)"
                    (change)="onChange($event)" formControlName="seoDescription">
                  </ckeditor>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="card product-keywords-card">
            <div class="card-header">
              <h5>Keywords</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <label>Product Keywords</label>
                  <input type="text" class="form-control"
                    placeholder="Enter or Search Product Keywords(Separate with comma)" formControlName="keyWord"
                    [ngClass]="
                      productFormControl.keyWord.errors &&
                      productFormControl.keyWord.touched
                        ? 'custom-invalid'
                        : 'custom-valid'
                    " (keyup.enter)="addingProductKeyword()" />
                  <div class="d-flex gap-2 mt-2 mb-2 flex-wrap h-70px" *ngIf="keywordArray !=0">
                    <div class="chip d-flex" *ngFor="
                        let keyWordItem of keywordArray;
                        let keywordIndex = index
                      ">
                      {{ keyWordItem }}
                      <span class="closebtn" (click)="deleteKeyword(keywordIndex)"><i class="fas fa-times"></i></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="card price-options-card">
                <div class="card-header">
                  <h5>Price Options</h5>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label>Product Variant <span class="mandatory">*</span></label>
                    </div>
                    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                      <div class="d-flex gap-2">
                        <div>
                          <input type="radio" class="form-check-input" formControlName="singleVariant" value="true" />
                          <label class="form-check-label ms-4">
                            Single Variant
                          </label>
                        </div>
                        <div>
                          <input type="radio" class="form-check-input" formControlName="singleVariant" value="false" />
                          <label class="form-check-label ms-4">
                            Multiple Variants
                          </label>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="productFormControl.singleVariant.value == 'false'"
                      class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                      <label>Variant <span class="mandatory">*</span></label>
                      <div class="d-flex justify-content-between gap-2 align-items-center">
                        <div class="flex-grow">
                          <select class="form-select form-control" *ngIf="attrNameArray.length<=10"
                            [(ngModel)]="defaults.defaultVariantName" aria-label="Default select example"
                            formControlName="attrName">
                            <option [ngValue]="null" disabled>Choose Variant</option>
                            <option *ngFor="
                        let attrNameItem of attrNameArray;
                        let attrNameIndex = index
                      " [ngValue]="attrNameItem">
                              {{ attrNameItem.attrName }}
                            </option>
                          </select>
                          <p-dropdown formControlName="attrName" *ngIf="attrNameArray.length>10"
                            [options]="attrNameArray" optionLabel="attrName" [filter]="true" filterBy="attrName"
                            [showClear]="true" placeholder="Choose Variant" />
                        </div>
                        <div>
                          <button type="button" class="btn refresh-btn" (click)="refreshList('variant')"><i
                              class="fas fa-redo"></i></button>
                        </div>
                      </div>
                      <span class="text-danger"
                        *ngIf="(productFormControl.attrName.touched) && !productFormControl.attrName?.valid">
                        <div *ngIf="productFormControl.attrName.errors['objectRequired']">
                          Variant is required.
                        </div>
                      </span>
                    </div>
                    <div *ngIf="productFormControl.singleVariant.value == 'false'"
                      class="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
                      <label>Variant Value <span class="mandatory">*</span></label>
                      <input type="text" class="form-control"
                        placeholder="Enter variant values and press Enter (Separate with comma)"
                        formControlName="attrValue" (keyup.enter)="addingAttrValue()" />
                      <span class="text-danger"
                        *ngIf="(productFormControl.attrValue.touched) && !productFormControl.attrValue?.valid">
                        <div *ngIf="productFormControl.attrValue.errors['required'] && attrValueArray.length==0">
                          Variant value is required.
                        </div>
                      </span>
                      <div class="d-flex gap-2 mt-2 mb-2 flex-wrap h-70px" *ngIf="attrValueArray !=0">
                        <div class="chip d-flex" *ngFor="
                        let attrValueItem of attrValueArray;
                        let attrValueIndex = index
                      ">
                          {{ attrValueItem.value }}
                          <span class="closebtn" (click)="deleteAttrValue(attrValueIndex)"><i
                              class="fas fa-times"></i></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="productFormControl.singleVariant.value == 'false'">
            <div
              class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2 attribute-value-accordion-column">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="accordion" id="accordionExample" formArrayName="priceOptionItems">
                        <div class="accordion-item" *ngFor="
                        let item of priceOptionItems.controls;
                        let i = index
                       " [formGroupName]="i">
                          <h2 class="accordion-header">
                            <button class="accordion-button" type="button"
                              [class.collapsed]="!item.get('expanded').value" (click)="toggleAccordionItem(i)"
                              data-bs-toggle="collapse" [attr.data-bs-target]="'#panelsStayOpen-collapse' + i"
                              [attr.aria-expanded]="item.get('expanded').value"
                              [attr.aria-controls]="'panelsStayOpen-collapse' + i">
                              {{ productFormControl.attrName.value.attrName }} -
                              {{ item.get('attrValue').value }}
                            </button>
                          </h2>

                          <div [id]="'panelsStayOpen-collapse' + i" class="accordion-collapse collapse"
                            [class.show]="item.get('expanded').value">
                            <div class="accordion-body">
                              <div class="row">
                                <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12">
                                  <div>
                                    <div class="d-flex justify-content-between align-items-center">
                                      <label class="mb-0">Featured Image </label>
                                    </div>
                                    <div class="featured-image-upload-modal" data-bs-toggle="modal"
                                      href="#uploadProImageModal" role="button"
                                      (click)="openUploadProImageModal(item.get('featuredImage'),'featuredImgAttr',i)">
                                      @if (!!item.get('featuredImage').value?.url) {
                                      <div class="after-upload-gallery-image">
                                        <div class="text-end">
                                          <i class="fas fa-edit pe-1" data-bs-toggle="modal" href="#uploadProImageModal"
                                            (click)="openUploadProImageModal(item.get('featuredImage'),'featuredImgAttr',i)"></i>
                                          <i class="fas fa-trash-alt pe-1" (click)="deleteUrl('featuredImgAttr',i)"></i>
                                        </div>
                                        <img [src]="
                                          item.get('featuredImage').value.url
                                        " />
                                      </div>
                                      } @else {
                                      <div class="before-upload-gallery-image">
                                        <i class="far fa-image"></i>
                                        <br>
                                        <div class="upload-caption">
                                          <p>Add Featured image</p>
                                        </div>
                                      </div>
                                      }
                                    </div>
                                  </div>
                                </div>
                                <div class="col-xxl-9 col-xl-9 col-lg-9 col-md-8 col-sm-12 col-xs-12">
                                  <div class="row">
                                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2">
                                      <label>Model</label>
                                      <input type="text" class="form-control" placeholder="Enter Model"
                                        formControlName="model" />
                                    </div>
                                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2">
                                      <label>Manufacturer Part Code</label>
                                      <input type="text" class="form-control" placeholder="Enter Manufacturer Part Code"
                                        formControlName="manufacturePartCode" />
                                    </div>
                                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2">
                                      <label>SKU <span class="mandatory">*</span></label>
                                      <input type="text"
                                        [ngClass]="(item.get('sku').touched && !item.get('sku').valid) ? 'form-control error-focus' : 'form-control'"
                                        placeholder="Enter SKU" formControlName="sku" />
                                      <span class="text-danger"
                                        *ngIf="(item.get('sku').touched) && !item.get('sku').valid">
                                        <div *ngIf="item.get('sku').errors['required'] ">
                                          SKU is required.
                                        </div>
                                        <div *ngIf="item.get('sku').errors['pattern']">
                                          Only alphanumeric and "-" allowed
                                        </div>
                                      </span>
                                    </div>
                                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2">
                                      <label>Stock Status
                                        <span class="mandatory">*</span></label>
                                      <select class="form-select form-control" aria-label="Default select example"
                                        formControlName="stockStatus" [ngClass]="
                                      item.get('stockStatus').errors &&
                                      (item.get('stockStatus').touched ||
                                        sumbitted)
                                        ? 'custom-invalid'
                                        : 'custom-valid'
                                    ">
                                        <option *ngFor="
                                        let stockStatus of stockStatusList;
                                        let stockStatusIndex = index
                                      " [ngValue]="stockStatus.value">
                                          {{ stockStatus.name }}
                                        </option>
                                      </select>
                                      <span class="text-danger"
                                        *ngIf="(item.get('stockStatus').touched) && !item.get('stockStatus').valid">
                                        <div *ngIf="item.get('stockStatus').errors['required'] ">
                                          Stock status is required.
                                        </div>
                                      </span>
                                    </div>
                                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2">
                                      <label>Stock Quantity
                                        <span class="mandatory">*</span></label>
                                      <input type="number" (keypress)="preventE($event)"
                                        [ngClass]="(item.get('stockQuantity').touched && !item.get('stockQuantity').valid) ? 'form-control error-focus' : 'form-control'"
                                        placeholder="Enter Quantity" formControlName="stockQuantity" />
                                      <span class="text-danger"
                                        *ngIf="(item.get('stockQuantity').touched) && !item.get('stockQuantity').valid">
                                        <div *ngIf="item.get('stockQuantity').errors['required'] ">
                                          Stock quantity is required.
                                        </div>
                                        <div *ngIf="item.get('stockQuantity').errors['pattern']">
                                          Invalid stock quantity.
                                        </div>
                                      </span>
                                    </div>
                                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2">
                                      <label>Stock Unit
                                        <span class="mandatory">*</span></label>
                                      <div class="d-flex justify-content-between gap-2 align-items-center">
                                        <div
                                          [ngClass]="(item.get('stockUnit').touched && !item.get('stockUnit').valid) ? 'flex-grow error-focus' : 'flex-grow'">
                                          <select class="form-select form-control" aria-label="Default select example"
                                            formControlName="stockUnit" *ngIf="stockUnitList.length<=10">
                                            <option [ngValue]="null" disabled>Choose...</option>
                                            <option *ngFor="let stockUnit of stockUnitList" [ngValue]="stockUnit">
                                              {{ stockUnit.value }}
                                            </option>
                                          </select>
                                          <p-dropdown formControlName="stockUnit" *ngIf="stockUnitList.length>10"
                                            [options]="stockUnitList" optionLabel="value" [filter]="true"
                                            filterBy="value" placeholder="Choose..." />
                                        </div>
                                        <div>
                                          <button type="button" class="btn refresh-btn"
                                            (click)="refreshList('stockUnits')"><i class="fas fa-redo"></i></button>
                                        </div>
                                      </div>
                                      <span class="text-danger"
                                        *ngIf="(item.get('stockUnit').touched) && !item.get('stockUnit').valid">
                                        <div *ngIf="item.get('stockUnit').errors['objectRequired'] ">
                                          Stock unit is required.
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12 mb-2"
                                  *ngIf="item.get('stockUnit').value.value">
                                  <label>Items Per {{item.get('stockUnit').value.value}}</label>
                                  <input type="number" (keypress)="preventE($event)" [min]="1" class="form-control"
                                    [attr.placeholder]="'Enter Items Per '+item.get('stockUnit').value.value"
                                    formControlName="itemsPerUnit" />
                                </div>
                                <div
                                  [ngClass]="!item.get('stockUnit').value.value ? 'col-xxl-6 col-xl-6 col-lg-6 col-md-4 col-sm-12 col-xs-12' : 'col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12'">
                                  <label>Regular Price
                                    <span class="mandatory">*</span></label>
                                  <input type="number" (keypress)="preventE($event)" [min]="0"
                                    [ngClass]="(item.get('regularPrice').touched && !item.get('regularPrice').valid) ? 'form-control error-focus' : 'form-control'"
                                    placeholder="Enter Regular Price({{currency}})" formControlName="regularPrice" />
                                  <span class="text-danger"
                                    *ngIf="(item.get('regularPrice').touched) && !item.get('regularPrice').valid">
                                    <div *ngIf="item.get('regularPrice').errors['required']">
                                      Price is required
                                    </div>
                                  </span>
                                </div>
                                <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12 mb-2">
                                  <label>Sale Price <span class="mandatory">*</span></label>
                                  <input type="number" (keypress)="preventE($event)" [min]="0"
                                    [ngClass]="(item.get('salePrice').touched && !item.get('salePrice').valid) ? 'form-control error-focus' : 'form-control'"
                                    placeholder="Enter Sale Price({{currency}})" formControlName="salePrice" />
                                  <span class="text-danger"
                                    *ngIf="(item.get('salePrice').touched) && !item.get('salePrice').valid">
                                    <div *ngIf="item.get('salePrice').errors['required'] ">
                                      Sale Price is required.
                                    </div>
                                  </span>
                                </div>
                                <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12 mb-2">
                                  <div>
                                    <label>Datasheet</label>
                                    <div id="file-upload-form" class="uploader">
                                      <input id="datasheet-file-upload{{ i }}" type="file" name="fileUpload"
                                        accept="pdf/*, image/*" (change)="
                                                  fileSave($event, 'dataSheet', i)
                                                " />

                                      @if (!!item.get('dataSheet').value) {
                                      <div id="after-upload">
                                        <div class="d-flex">
                                          <div class="uploaded-filename">
                                            {{ item.get('dataSheet').value.split('.amazonaws.com/public/')[1] }}
                                          </div>
                                          <div class="d-flex gap-2">
                                            <i class="fas fa-eye pt-1"
                                              (click)="openFile(item.get('dataSheet').value)"></i>
                                            <i class=" fas fa-trash-alt pt-1"
                                              (click)="
                                                                                                                      resetPriceOptionField(
                                                                                                                        'dataSheet',
                                                                                                                        i
                                                                                                                      )
                                                                                                                    "></i>
                                          </div>
                                        </div>
                                      </div>
                                      } @else {
                                      <label for="datasheet-file-upload{{ i }}" id="file-drag">
                                        <div id="start">
                                          <div class="upload-caption">
                                            <h6>Upload Datasheet</h6>
                                            <p>Upload</p>
                                          </div>
                                        </div>
                                      </label>
                                      }
                                    </div>
                                  </div>
                                </div>
                                <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12 mb-2">
                                  <div>
                                    <label>Submittal</label>
                                    <div id="file-upload-form" class="uploader">
                                      <input id="submittal-file-upload{{ i }}" type="file" name="fileUpload"
                                        accept="pdf/*, image/*" (change)="
                                                                        fileSave($event, 'submittal', i)
                                                                      " />

                                      @if (!!item.get('submittal').value) {
                                      <div id="after-upload">
                                        <div class="d-flex">
                                          <div class="uploaded-filename">
                                            {{ item.get('submittal').value.split('.amazonaws.com/public/')[1] }}
                                          </div>
                                          <div class="d-flex gap-2">
                                            <i class="fas fa-eye pt-1"
                                              (click)="openFile(item.get('submittal').value)"></i>
                                            <i class=" fas fa-trash-alt pt-1" (click)="
                                                                                resetPriceOptionField(
                                                                                  'submittal',
                                                                                  i
                                                                                )
                                                                              "></i>
                                          </div>
                                        </div>
                                      </div>
                                      } @else {
                                      <label for="submittal-file-upload{{ i }}" id="file-drag">
                                        <div id="start">
                                          <div class="upload-caption">
                                            <h6>Upload Submittal</h6>
                                            <p>Upload</p>
                                          </div>
                                        </div>
                                      </label>
                                      }
                                    </div>
                                  </div>
                                </div>
                                <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12 mb-2">
                                  <div>
                                    <label>Catalog</label>
                                    <div id="file-upload-form" class="uploader">
                                      <input id="catalog-file-upload{{ i }}" type="file" name="fileUpload"
                                        accept="pdf/*, image/*" (change)="
                                                                        fileSave($event, 'catalog', i)
                                                                      " />

                                      @if (!!item.get('catalog').value) {
                                      <div id="after-upload">
                                        <div class="d-flex">
                                          <div class="uploaded-filename">
                                            {{ item.get('catalog').value.split('.amazonaws.com/public/')[1] }}
                                          </div>
                                          <div class="d-flex gap-2">
                                            <i class="fas fa-eye pt-1"
                                              (click)="openFile(item.get('catalog').value)"></i>
                                            <i class=" fas fa-trash-alt pt-1" (click)="
                                                                                resetPriceOptionField(
                                                                                  'catalog',
                                                                                  i
                                                                                )
                                                                              "></i>
                                          </div>
                                        </div>
                                      </div>
                                      } @else {
                                      <label for="catalog-file-upload{{ i }}" id="file-drag">
                                        <div id="start">
                                          <div class="upload-caption">
                                            <h6>Upload Catalog</h6>
                                            <p>Upload</p>
                                          </div>
                                        </div>
                                      </label>
                                      }
                                    </div>
                                  </div>
                                </div>
                                <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12 mb-2">
                                  <div>
                                    <label>IOM - Installation Operation
                                      Manual</label>
                                    <div id="file-upload-form" class="uploader">
                                      <input id="iom-file-upload{{ i }}" type="file" name="fileUpload"
                                        accept="pdf/*, image/*" (change)="fileSave($event, 'iom', i)" />

                                      @if (!!item.get('iom').value) {
                                      <div id="after-upload">
                                        <div class="d-flex">
                                          <div class="uploaded-filename">
                                            {{ item.get('iom').value.split('.amazonaws.com/public/')[1] }}
                                          </div>
                                          <div class="d-flex gap-2">
                                            <i class="fas fa-eye pt-1" (click)="openFile(item.get('iom').value)"></i>
                                            <i class=" fas fa-trash-alt pt-1" (click)="
                                                                                resetPriceOptionField(
                                                                                  'iom',
                                                                                  i
                                                                                )
                                                                              "></i>
                                          </div>
                                        </div>
                                      </div>
                                      } @else {
                                      <label for="iom-file-upload{{ i }}" id="file-drag">
                                        <div id="start">
                                          <div class="upload-caption">
                                            <h6>Upload IOM</h6>
                                            <p>Upload</p>
                                          </div>
                                        </div>
                                      </label>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                  <div class="card" formGroupName="storageInfo">
                                    <div class="card-header">
                                      <h6>Storage Information</h6>
                                    </div>
                                    <div class="card-body">
                                      <div class="row">
                                        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                          <label>Warehouse <span class="mandatory">*</span></label>
                                          <div class="d-flex justify-content-between gap-2 align-items-center">
                                            <div
                                              [ngClass]="(item.get('storageInfo').get('wareHouse').touched && item.get('storageInfo').get('wareHouse').invalid) ? 'flex-grow error-focus' : 'flex-grow'">
                                              <select class=" form-select form-control" *ngIf="wareHouseList.length<=10"
                                                [(ngModel)]="defaults.defaultWarehouse"
                                                aria-label="Default select example" formControlName="wareHouse">
                                                <option [ngValue]="null" disabled>
                                                  Choose Warehouse
                                                </option>
                                                <option *ngFor="let wareHouseItem of wareHouseList"
                                                  [ngValue]="wareHouseItem">
                                                  {{ wareHouseItem.name }} </option>
                                              </select>
                                              <p-dropdown formControlName="wareHouse" *ngIf="wareHouseList.length>10"
                                                [options]="wareHouseList" optionLabel="name" [filter]="true"
                                                filterBy="name" placeholder="Choose Warehouse" />
                                            </div>
                                            <div>
                                              <button type="button" class="btn refresh-btn"
                                                (click)="refreshList('warehouse')"><i class="fas fa-redo"></i></button>
                                            </div>
                                          </div>
                                          <span class="text-danger"
                                            *ngIf="(item.get('storageInfo').get('wareHouse').touched) && item.get('storageInfo').get('wareHouse').invalid">
                                            <div
                                              *ngIf="item.get('storageInfo').get('wareHouse').errors['objectRequired']">
                                              Warehouse is required.
                                            </div>
                                          </span>
                                        </div>
                                        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                          <label>Rack No</label>
                                          <input type="text" class="form-control" placeholder="Enter Rack No"
                                            formControlName="rackNo" />
                                          <span class="text-danger"
                                            *ngIf="item.get('storageInfo').get('rackNo').touched && item.get('storageInfo').get('rackNo').invalid">
                                            <div *ngIf="item.get('storageInfo').get('rackNo').errors['pattern']">
                                              Rack No can contain only alphabets or numerals.
                                            </div>
                                          </span>
                                        </div>
                                        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                          <label>Row No</label>
                                          <input type="text" class="form-control" placeholder="Enter Row No"
                                            formControlName="rowNo" />
                                          <span class="text-danger"
                                            *ngIf="item.get('storageInfo').get('rowNo').touched && item.get('storageInfo').get('rowNo').invalid">
                                            <div *ngIf="item.get('storageInfo').get('rowNo').errors['pattern']">
                                              Row No can contain only alphabets or numerals.
                                            </div>
                                          </span>
                                        </div>
                                        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                          <label>Bin No</label>
                                          <input type="text" class="form-control" placeholder="Enter Bin No"
                                            formControlName="binNo" />
                                          <span class="text-danger"
                                            *ngIf="item.get('storageInfo').get('binNo').touched && item.get('storageInfo').get('binNo').invalid">
                                            <div *ngIf="item.get('storageInfo').get('binNo').errors['pattern']">
                                              Bin No can contain only alphabets or numerals.
                                            </div>
                                          </span>
                                        </div>
                                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                          <label>Notes</label>
                                          <textarea class="form-control" placeholder="Notes" formControlName="note">
                                      </textarea>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                  <div class="card" formGroupName="shipplingInfo">
                                    <div class="card-header">
                                      <h6>Shipping</h6>
                                    </div>
                                    <div class="card-body">
                                      <div class="row">
                                        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                          <label>Weight </label>
                                          <div class="input-group mb-3">
                                            <input type="number" (keypress)="preventE($event)" [min]="0"
                                              class="form-control" aria-label="Text input with dropdown button"
                                              placeholder="Enter Weight" formControlName="weightValue" />
                                            <div class="d-flex justify-content-between gap-2 align-items-center">
                                              <div class="flex-grow">
                                                <select class="form-select form-control" [(ngModel)]="defaultWeight[i]"
                                                  *ngIf="massUnitsList.length<=10" formControlName="weightUnit"
                                                  aria-label="Default select example">
                                                  <option [ngValue]="null" selected>Select...</option>
                                                  <option *ngFor="let massUnit of massUnitsList" [ngValue]="massUnit">
                                                    {{ massUnit.value }}
                                                  </option>
                                                </select>
                                                <p-dropdown formControlName="weightUnit" [options]="massUnitsList"
                                                  optionLabel="value" [filter]="true" filterBy="value"
                                                  [showClear]="true" placeholder="Select"
                                                  *ngIf="massUnitsList.length>10" />
                                              </div>
                                              <div>
                                                <button type="button" class="btn refresh-btn"
                                                  (click)="refreshList('weightUnits')"><i
                                                    class="fas fa-redo"></i></button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                          <label>Length </label>
                                          <div class="input-group mb-3">
                                            <input type="number" (keypress)="preventE($event)" [min]="0"
                                              class="form-control" aria-label="Text input with dropdown button"
                                              placeholder="Enter Length" formControlName="lengthValue" />
                                            <div class="d-flex justify-content-between gap-2 align-items-center">
                                              <div class="flex-grow">
                                                <select class="form-select form-control" [(ngModel)]="defaultLength[i]"
                                                  *ngIf="lengthUnitsList.length<=10" formControlName="lengthUnit"
                                                  aria-label="Default select example">
                                                  <option [ngValue]="null" selected>Select...</option>
                                                  <option *ngFor="let lengthUnit of lengthUnitsList"
                                                    [ngValue]="lengthUnit">
                                                    {{ lengthUnit.value }}
                                                  </option>
                                                </select>
                                                <p-dropdown formControlName="lengthUnit" [options]="lengthUnitsList"
                                                  optionLabel="value" [filter]="true" [showClear]="true"
                                                  filterBy="value" placeholder="Select"
                                                  *ngIf="lengthUnitsList.length>10" />
                                              </div>
                                              <div>
                                                <button type="button" class="btn refresh-btn"
                                                  (click)="refreshList('lengthUnits')"><i
                                                    class="fas fa-redo"></i></button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                          <label>Height </label>
                                          <div class="input-group mb-3">
                                            <input type="number" (keypress)="preventE($event)" [min]="0"
                                              class="form-control" aria-label="Text input with dropdown button"
                                              placeholder="Enter Height" formControlName="heightValue" />
                                            <div class="d-flex justify-content-between gap-2 align-items-center">
                                              <div class="flex-grow">
                                                <select class="form-select form-control"
                                                  *ngIf="lengthUnitsList.length<=10" formControlName="heightUnit"
                                                  aria-label="Default select example" [(ngModel)]="defaultHeight[i]">
                                                  <option [ngValue]="null" selected>Select...</option>
                                                  <option *ngFor="let lengthUnit of lengthUnitsList"
                                                    [ngValue]="lengthUnit">
                                                    {{ lengthUnit.value }}
                                                  </option>
                                                </select>
                                                <p-dropdown formControlName="heightUnit" [options]="lengthUnitsList"
                                                  optionLabel="value" [filter]="true" [showClear]="true"
                                                  filterBy="value" placeholder="Select"
                                                  *ngIf="lengthUnitsList.length>10" />
                                              </div>
                                              <div>
                                                <button type="button" class="btn refresh-btn"
                                                  (click)="refreshList('lengthUnits')"><i
                                                    class="fas fa-redo"></i></button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                          <label>Width </label>
                                          <div class="input-group mb-3">
                                            <input type="number" (keypress)="preventE($event)" [min]="0"
                                              class="form-control" aria-label="Text input with dropdown button"
                                              placeholder="Enter Width" formControlName="widthValue" />
                                            <div class="d-flex justify-content-between gap-2 align-items-center">
                                              <div class="flex-grow">
                                                <select class="form-select form-control" [(ngModel)]="defaultWidth[i]"
                                                  *ngIf="lengthUnitsList.length<=10" formControlName="widthUnit"
                                                  aria-label="Default select example">
                                                  <option [ngValue]="null" selected>Select...</option>
                                                  <option *ngFor="let lengthUnit of lengthUnitsList"
                                                    [ngValue]="lengthUnit">
                                                    {{ lengthUnit.value }}
                                                  </option>
                                                </select>
                                                <p-dropdown formControlName="widthUnit" [options]="lengthUnitsList"
                                                  optionLabel="value" [filter]="true" [showClear]="true"
                                                  filterBy="value" placeholder="Select"
                                                  *ngIf="lengthUnitsList.length>10" />
                                              </div>
                                              <div>
                                                <button type="button" class="btn refresh-btn"
                                                  (click)="refreshList('lengthUnits')"><i
                                                    class="fas fa-redo"></i></button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row product-details-row" *ngIf="productFormControl.singleVariant.value == 'true'">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
              formGroupName="singleProductDetail">
              <div class="card price-options-card">
                <div class="card-header">
                  <h5>Product Details</h5>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12 mb-2">
                      <label>Model</label>
                      <input type="text" class="form-control" placeholder="Enter Model" formControlName="model" />
                    </div>
                    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12 mb-2">
                      <label>Manufacturer Part Code</label>
                      <input type="text" class="form-control" placeholder="Enter Manufacturer Part Code"
                        formControlName="manufacturePartCode" />
                    </div>
                    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12 mb-2">
                      <label>SKU <span class="mandatory">*</span></label>
                      <input type="text"
                        [ngClass]="(singleProductDetail.controls.sku.touched && !singleProductDetail.controls.sku?.valid) ? 'form-control error-focus' : 'form-control'"
                        placeholder="Enter SKU" formControlName="sku" />
                      <span class="text-danger"
                        *ngIf="(singleProductDetail.controls.sku.touched) && !singleProductDetail.controls.sku?.valid">
                        <div *ngIf="singleProductDetail.controls.sku.errors['required']">
                          SKU is required.
                        </div>
                        <div *ngIf="singleProductDetail.controls.sku.errors['pattern']">
                          Only alphanumeric and "-" allowed
                        </div>
                      </span>
                    </div>
                    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12 mb-2">
                      <label>Stock Status
                        <span class="mandatory">*</span></label>
                      <select class="form-select form-control" aria-label="Default select example"
                        formControlName="stockStatus">
                        <option *ngFor="
                                  let stockStatus of stockStatusList;
                                  let stockStatusIndex = index
                                " [ngValue]="stockStatus.value">
                          {{ stockStatus.name }}
                        </option>
                      </select>
                      <span class="text-danger"
                        *ngIf="(singleProductDetail.controls.stockStatus.touched) && !singleProductDetail.controls.stockStatus?.valid">
                        <div *ngIf="singleProductDetail.controls.stockStatus.errors['required']">
                          Stock status is required.
                        </div>
                      </span>
                    </div>
                    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12 mb-2">
                      <label>Stock Quantity
                        <span class="mandatory">*</span></label>
                      <input type="number" (keypress)="preventE($event)"
                        [ngClass]="(singleProductDetail.controls.stockQuantity.touched && !singleProductDetail.controls.stockQuantity?.valid) ? 'form-control error-focus' : 'form-control'"
                        placeholder="Enter Quantity" formControlName="stockQuantity" />
                      <span class="text-danger"
                        *ngIf="(singleProductDetail.controls.stockQuantity.touched) && !singleProductDetail.controls.stockQuantity?.valid">
                        <div *ngIf="singleProductDetail.controls.stockQuantity.errors['required']">
                          Stock quantity is required.
                        </div>
                        <div *ngIf="singleProductDetail.controls.stockQuantity.errors['pattern']">
                          Invalid stock quantity.
                        </div>
                      </span>
                    </div>
                    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12 mb-2">
                      <label>Stock Unit
                        <span class="mandatory">*</span></label>
                      <div class="d-flex justify-content-between gap-2 align-items-center">
                        <div
                          [ngClass]="(singleProductDetail.controls.stockUnit.touched && !singleProductDetail.controls.stockUnit.valid) ? 'flex-grow error-focus' : 'flex-grow'">
                          <select class="form-select form-control" *ngIf="stockUnitList.length<=10"
                            aria-label="Default select example" formControlName="stockUnit">
                            <option [ngValue]="null" disabled>Choose...</option>
                            <option *ngFor="let stockUnit of stockUnitList" [ngValue]="stockUnit">
                              {{ stockUnit.value }}
                            </option>
                          </select>
                          <p-dropdown formControlName="stockUnit" [options]="stockUnitList"
                            *ngIf="stockUnitList.length>10" optionLabel="value" [filter]="true" filterBy="value"
                            placeholder="Choose..." />
                        </div>
                        <div>
                          <button type="button" class="btn refresh-btn" (click)="refreshList('stockUnits')"><i
                              class="fas fa-redo"></i></button>
                        </div>
                      </div>
                      <span class="text-danger"
                        *ngIf="(singleProductDetail.controls.stockUnit.touched) && !singleProductDetail.controls.stockUnit?.valid">
                        <div *ngIf="singleProductDetail.controls.stockUnit.errors['objectRequired']">
                          Stock unit is required.
                        </div>
                      </span>
                    </div>
                    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12 mb-2">
                      <div class="row">
                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12"
                          *ngIf="singleProductDetail.controls.stockUnit.value.value">
                          <label>Items Per {{singleProductDetail.controls.stockUnit.value.value}}</label>
                          <input type="number" (keypress)="preventE($event)" [min]="1" class="form-control"
                            [attr.placeholder]="'Enter Items Per '+singleProductDetail.controls.stockUnit.value.value"
                            formControlName="itemsPerUnit" />
                        </div>
                        <div
                          [ngClass]="!singleProductDetail.controls.stockUnit.value.value ? 'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12' : 'col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12'">
                          <label>Regular Price
                            <span class="mandatory">*</span></label>
                          <input type="number" (keypress)="preventE($event)" [min]="0"
                            [ngClass]="(singleProductDetail.controls.regularPrice.touched && !singleProductDetail.controls.regularPrice?.valid) ? 'form-control error-focus' : 'form-control'"
                            placeholder="Enter Regular Price({{currency}})" formControlName="regularPrice" />
                          <span class="text-danger"
                            *ngIf="(singleProductDetail.controls.regularPrice.touched) && !singleProductDetail.controls.regularPrice?.valid">
                            <div *ngIf="singleProductDetail.controls.regularPrice.errors['required']">
                              Price is required
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12 mb-2">
                      <label>Sale Price <span class="mandatory">*</span></label>
                      <input type="number" (keypress)="preventE($event)" [min]="0"
                        [ngClass]="(singleProductDetail.controls.salePrice.touched && !singleProductDetail.controls.salePrice?.valid) ? 'form-control error-focus' : 'form-control'"
                        placeholder="Enter Sale Price({{currency}})" formControlName="salePrice" />
                      <span class="text-danger"
                        *ngIf="(singleProductDetail.controls.salePrice.touched) && !singleProductDetail.controls.salePrice?.valid">
                        <div *ngIf="singleProductDetail.controls.salePrice.errors['required']">
                          Sale price is required.
                        </div>
                      </span>
                    </div>
                    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12 mb-2">
                      <div>
                        <label>Datasheet</label>
                        <div id="file-upload-form" class="uploader">
                          <input id="datasheet-file-upload" type="file" name="fileUpload" accept="pdf/*, image/*"
                            (change)="fileSave($event, 'singledataSheet')" />

                          @if (
                          !!singleProductDetail.get('dataSheet').value
                          ) {
                          <div id="after-upload">
                            <div class="d-flex">
                              <div class="uploaded-filename">
                                {{
                                singleProductDetail.get('dataSheet')
                                .value.split('.amazonaws.com/public/')[1]
                                }}
                              </div>
                              <div class="d-flex gap-2">
                                <i class="fas fa-eye pt-1"
                                  (click)="openFile(singleProductDetail.get('dataSheet').value)"></i>
                                <i class=" fas fa-trash-alt pt-1" (click)="
                                            resetPriceOptionField(
                                              'singledataSheet'
                                            )
                                          "></i>
                              </div>
                            </div>
                          </div>
                          } @else {
                          <label for="datasheet-file-upload" id="file-drag">
                            <div id="start">
                              <div class="upload-caption">
                                <h6>Upload Datasheet</h6>
                                <p>Upload</p>
                              </div>
                            </div>
                          </label>
                          }
                        </div>
                      </div>
                    </div>
                    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12 mb-2">
                      <div>
                        <label>Submittal</label>
                        <div id="file-upload-form" class="uploader">
                          <input id="submittal-file-upload" type="file" name="fileUpload" accept="pdf/*, image/*"
                            (change)="fileSave($event, 'singlesubmittal')" />

                          @if (
                          !!singleProductDetail.get('submittal').value
                          ) {
                          <div id="after-upload">
                            <div class="d-flex">
                              <div class="uploaded-filename">
                                {{
                                singleProductDetail.get('submittal')
                                .value.split('.amazonaws.com/public/')[1]
                                }}
                              </div>
                              <div class="d-flex gap-2">
                                <i class="fas fa-eye pt-1"
                                  (click)="openFile(singleProductDetail.get('submittal').value)"></i>
                                <i class=" fas fa-trash-alt pt-1" (click)="
                                            resetPriceOptionField(
                                              'singlesubmittal'
                                            )
                                          "></i>
                              </div>
                            </div>
                          </div>
                          } @else {
                          <label for="submittal-file-upload" id="file-drag">
                            <div id="start">
                              <div class="upload-caption">
                                <h6>Upload Submittal</h6>
                                <p>Upload</p>
                              </div>
                            </div>
                          </label>
                          }
                        </div>
                      </div>
                    </div>
                    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12 mb-2">
                      <div>
                        <label>Catalog</label>
                        <div id="file-upload-form" class="uploader">
                          <input id="catalog-file-upload" type="file" name="fileUpload" accept="pdf/*, image/*"
                            (change)="fileSave($event, 'singlecatalog')" />

                          @if (
                          !!singleProductDetail.get('catalog').value
                          ) {
                          <div id="after-upload">
                            <div class="d-flex">
                              <div class="uploaded-filename">
                                {{
                                singleProductDetail.get('catalog')
                                .value.split('.amazonaws.com/public/')[1]
                                }}
                              </div>
                              <div class="d-flex gap-2">
                                <i class="fas fa-eye pt-1"
                                  (click)="openFile(singleProductDetail.get('catalog').value)"></i>
                                <i class=" fas fa-trash-alt pt-1" (click)="
                                            resetPriceOptionField(
                                              'singlecatalog'
                                            )
                                          "></i>
                              </div>
                            </div>
                          </div>
                          } @else {
                          <label for="catalog-file-upload" id="file-drag">
                            <div id="start">
                              <div class="upload-caption">
                                <h6>Upload Catalog</h6>
                                <p>Upload</p>
                              </div>
                            </div>
                          </label>
                          }
                        </div>
                      </div>
                    </div>
                    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12 mb-2">
                      <div>
                        <label>IOM - Installation Operation Manual</label>
                        <div id="file-upload-form" class="uploader">
                          <input id="iom-file-upload" type="file" name="fileUpload" accept="pdf/*, image/*"
                            (change)="fileSave($event, 'singleiom')" />

                          @if (!!singleProductDetail.get('iom').value) {
                          <div id="after-upload">
                            <div class="d-flex">
                              <div class="uploaded-filename">
                                {{
                                singleProductDetail.get('iom').value.split('.amazonaws.com/public/')[1]
                                }}
                              </div>
                              <div class="d-flex gap-2">
                                <i class="fas fa-eye pt-1" (click)="openFile(singleProductDetail.get('iom').value)"></i>
                                <i class="fas fa-trash-alt pt-1" (click)="
                                            resetPriceOptionField('singleiom')
                                          "></i>
                              </div>
                            </div>
                          </div>
                          } @else {
                          <label for="iom-file-upload" id="file-drag">
                            <div id="start">
                              <div class="upload-caption">
                                <h6>Upload IOM</h6>
                                <p>Upload</p>
                              </div>
                            </div>
                          </label>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="card" formGroupName="storageInfo">
                        <div class="card-header">
                          <h6>Storage Information</h6>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                              <label>Warehouse <span class="mandatory">*</span></label>
                              <div class="d-flex justify-content-between gap-2 align-items-center">
                                <div
                                  [ngClass]="(storageInfoControls.get('wareHouse').touched && storageInfoControls.get('wareHouse').invalid) ? 'flex-grow error-focus' : 'flex-grow'">
                                  <select class="form-select form-control" *ngIf="wareHouseList.length<=10"
                                    aria-label="Default select example" formControlName="wareHouse"
                                    [(ngModel)]="defaults.defaultWarehouse">
                                    <option [ngValue]="null" disabled>Choose Warehouse</option>
                                    <option *ngFor="let wareHouseItem of wareHouseList" [ngValue]="wareHouseItem">
                                      {{ wareHouseItem.name }} </option>
                                  </select>
                                  <p-dropdown formControlName="wareHouse" *ngIf="wareHouseList.length>10"
                                    [options]="wareHouseList" optionLabel="name" [filter]="true" filterBy="name"
                                    placeholder="Choose Warehouse" />
                                </div>
                                <div>
                                  <button type="button" class="btn refresh-btn" (click)="refreshList('warehouse')"><i
                                      class="fas fa-redo"></i></button>
                                </div>
                              </div>
                              <span class="text-danger"
                                *ngIf="(storageInfoControls.get('wareHouse').touched) && storageInfoControls.get('wareHouse').invalid">
                                <div *ngIf="storageInfoControls.get('wareHouse').errors['objectRequired']">
                                  Warehouse is required.
                                </div>
                              </span>
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                              <label>Rack No</label>
                              <input type="text" class="form-control" placeholder="Enter Rack No"
                                formControlName="rackNo" />
                              <span class="text-danger"
                                *ngIf="storageInfoControls.get('rackNo').touched && storageInfoControls.get('rackNo').invalid">
                                <div *ngIf="storageInfoControls.get('rackNo').errors['pattern']">
                                  Rack No can contain only alphabets or numerals.
                                </div>
                              </span>
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                              <label>Row No</label>
                              <input type="text" class="form-control" placeholder="Enter Row No"
                                formControlName="rowNo" />
                              <span class="text-danger"
                                *ngIf="storageInfoControls.get('rowNo').touched && storageInfoControls.get('rowNo').invalid">
                                <div *ngIf="storageInfoControls.get('rowNo').errors['pattern']">
                                  Row No can contain only alphabets or numerals.
                                </div>
                              </span>
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                              <label>Bin No</label>
                              <input type="text" class="form-control" placeholder="Enter Bin No"
                                formControlName="binNo" />
                              <span class="text-danger"
                                *ngIf="storageInfoControls.get('binNo').touched && storageInfoControls.get('binNo').invalid">
                                <div *ngIf="storageInfoControls.get('binNo').errors['pattern']">
                                  Bin No can contain only alphabets or numerals.
                                </div>
                              </span>
                            </div>
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <label>Notes</label>
                              <textarea class="form-control" placeholder="Notes" formControlName="note">
                                                                        </textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="card" formGroupName="shipplingInfo">
                        <div class="card-header">
                          <h6>Shipping</h6>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                              <label>Weight </label>
                              <div class="input-group mb-3">
                                <input type="number" (keypress)="preventE($event)" class="form-control"
                                  aria-label="Text input with dropdown button" placeholder="Enter Weight"
                                  formControlName="weightValue" />
                                <div class="d-flex justify-content-between gap-2 align-items-center">
                                  <div class="flex-grow">
                                    <select class="form-select form-control" *ngIf="massUnitsList.length<=10"
                                      formControlName="weightUnit" aria-label="Default select example"
                                      [(ngModel)]="defaults.defaultWeight">
                                      <option [ngValue]="null" selected>Select...</option>
                                      <option *ngFor="let massUnit of massUnitsList" [ngValue]="massUnit">
                                        {{ massUnit.value }}
                                      </option>
                                    </select>
                                    <p-dropdown formControlName="weightUnit" [options]="massUnitsList"
                                      optionLabel="value" [filter]="true" filterBy="value" [showClear]="true"
                                      placeholder="Select" *ngIf="massUnitsList.length>10" />
                                  </div>
                                  <div>
                                    <button type="button" class="btn refresh-btn"
                                      (click)="refreshList('weightUnits')"><i class="fas fa-redo"></i></button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                              <label>Length </label>
                              <div class="input-group mb-3">
                                <input type="number" (keypress)="preventE($event)" class="form-control"
                                  aria-label="Text input with dropdown button" placeholder="Enter Length"
                                  formControlName="lengthValue" />
                                <div class="d-flex justify-content-between gap-2 align-items-center">
                                  <div class="flex-grow">
                                    <select class="form-select form-control" *ngIf="lengthUnitsList.length<=10"
                                      formControlName="lengthUnit" aria-label="Default select example"
                                      [(ngModel)]="defaults.defaultLength">
                                      <option [ngValue]="null" selected>Select...</option>
                                      <option *ngFor="let lengthUnit of lengthUnitsList" [ngValue]="lengthUnit">
                                        {{ lengthUnit.value }}
                                      </option>
                                    </select>
                                    <p-dropdown formControlName="lengthUnit" [options]="lengthUnitsList"
                                      optionLabel="value" [filter]="true" [showClear]="true" filterBy="value"
                                      placeholder="Select" *ngIf="lengthUnitsList.length>10" />
                                  </div>
                                  <div>
                                    <button type="button" class="btn refresh-btn"
                                      (click)="refreshList('lengthUnits')"><i class="fas fa-redo"></i></button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                              <label>Height </label>
                              <div class="input-group mb-3">
                                <input type="number" (keypress)="preventE($event)" class="form-control"
                                  aria-label="Text input with dropdown button" placeholder="Enter Height"
                                  formControlName="heightValue" />
                                <div class="d-flex justify-content-between gap-2 align-items-center">
                                  <div class="flex-grow">
                                    <select class="form-select form-control" *ngIf="lengthUnitsList.length<=10"
                                      formControlName="heightUnit" aria-label="Default select example"
                                      [(ngModel)]="defaults.defaultHeight">
                                      <option [ngValue]="null" selected>Select...</option>
                                      <option *ngFor="let lengthUnit of lengthUnitsList" [ngValue]="lengthUnit">
                                        {{ lengthUnit.value }}
                                      </option>
                                    </select>
                                    <p-dropdown formControlName="heightUnit" [options]="lengthUnitsList"
                                      optionLabel="value" [filter]="true" [showClear]="true" filterBy="value"
                                      placeholder="Select" *ngIf="lengthUnitsList.length>10" />
                                  </div>
                                  <div>
                                    <button type="button" class="btn refresh-btn"
                                      (click)="refreshList('lengthUnits')"><i class="fas fa-redo"></i></button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                              <label>Width</label>
                              <div class="input-group mb-3">
                                <input type="number" (keypress)="preventE($event)" class="form-control"
                                  aria-label="Text input with dropdown button" placeholder="Enter Width"
                                  formControlName="widthValue" />
                                <div class="d-flex justify-content-between gap-2 align-items-center">
                                  <div class="flex-grow">
                                    <select class="form-select form-control" *ngIf="lengthUnitsList.length<=10"
                                      formControlName="widthUnit" aria-label="Default select example"
                                      [(ngModel)]="defaults.defaultWidth">
                                      <option [ngValue]="null" selected>Select...</option>
                                      <option *ngFor="let lengthUnit of lengthUnitsList" [ngValue]="lengthUnit">
                                        {{ lengthUnit.value }}
                                      </option>
                                    </select>
                                    <p-dropdown formControlName="widthUnit" [options]="lengthUnitsList"
                                      optionLabel="value" [filter]="true" [showClear]="true" filterBy="value"
                                      placeholder="Select" *ngIf="lengthUnitsList.length>10" />
                                  </div>
                                  <div>
                                    <button type="button" class="btn refresh-btn"
                                      (click)="refreshList('lengthUnits')"><i class="fas fa-redo"></i></button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12" [formGroup]="productForm">
              <div class="card tax-card">
                <div class="card-header">
                  <h5>Tax</h5>
                </div>
                <div class="card-body">
                  <div class="row" formGroupName="taxForm">
                    <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12 mb-2">
                      <label>Tax Name</label>
                      <div class="d-flex justify-content-between gap-2 align-items-center">
                        <div class="flex-grow">
                          <select class="form-select form-control" [(ngModel)]="defaults.defaultTaxName"
                            aria-label="Default select example" formControlName="name" *ngIf="taxList.length<=10">
                            <option value="null" disabled>
                              Choose Tax Name
                            </option>
                            <option *ngFor="let item of taxList" [ngValue]="item">{{item.taxCode}}</option>
                          </select>
                          <p-dropdown formControlName="name" *ngIf="taxList.length>10" [options]="taxList"
                            optionLabel="taxCode" [filter]="true" filterBy="taxCode" [showClear]="true"
                            placeholder="Choose Tax Name" />
                        </div>
                        <div>
                          <button type="button" class="btn refresh-btn" (click)="refreshList('taxName')"><i
                              class="fas fa-redo"></i></button>
                        </div>
                      </div>
                    </div>
                    <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12 mb-2">
                      <label>Tax Percentage</label>
                      <input type="text" (keypress)="preventE($event)" class="form-control"
                        placeholder="Enter tax charge" formControlName="value" />
                      <span class="text-danger" *ngIf="taxFormControl.value.touched && taxFormControl.value.invalid">
                        <div *ngIf="taxFormControl.value.errors['pattern']">
                          Tax percentage should contain only numbers with an optional 2 decimal points.
                        </div>
                      </span>
                    </div>
                    <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12 mb-2">
                      <button type="button" class="btn add-btn"
                        [disabled]="!(taxFormControl.value.valid && taxFormControl.name.value && taxFormControl.value.value)"
                        (click)="addTaxItem()">
                        Add
                      </button>
                    </div>
                  </div>
                  @if (addedTaxList.controls.length > 0) {
                  <div class="row">
                    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                      <div class="table-responsive tableFixHead">
                        <table class="table">
                          <thead>
                            <tr>
                              <th>Tax Name</th>
                              <th>Tax Percentage</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody formArrayName="taxList">
                            <tr *ngFor="let item of addedTaxList.controls;let i = index" [formGroupName]="i">
                              <td>{{ item.get('taxName').value }}</td>
                              <td>{{ item.get('taxCharge').value }}</td>
                              <td>
                                <i class="fas fa-trash-alt" (click)="removeTaxItem(i)"></i>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  }
                </div>
              </div>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12" [formGroup]="productForm">
              <div class="card tax-card">
                <div class="card-header">
                  <h5>Advanced Settings</h5>
                </div>
                <div class="card-body">
                  <div class="row pt-2">
                    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="zero" formControlName="showWhenZeroStock" />
                        <label class="form-check-label ms-2 mt-1" for="zero">
                          Show this product to buyers even if it’s out of stock
                        </label>
                      </div>
                    </div>
                    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="more" (change)="onSelectAllowPreorder()"
                          formControlName="allowPreOrder" />
                        <label class="form-check-label ms-2 mt-1" for="more">
                          Allow customers to place orders even if the item is out of stock
                        </label>
                      </div>
                    </div>
                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-2"
                      *ngIf="productFormControl.allowPreOrder.value == true">
                      <div>
                        <label>Leadtime</label>
                        <div class="input-group mb-3">
                          <input formControlName="leadTime" type="number" class="form-control" />
                          <div class="input-group-append">
                            <span class="input-group-text" id="basic-addon2">day(s)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="card">
                <div class="card-header">
                  <h5>Status</h5>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12 mb-2">
                      <label>Product Status</label>
                      <select class="form-select form-control" aria-label="Default select example"
                        formControlName="status">
                        <option value="null" disabled>
                          Select status
                        </option>
                        <option *ngFor="let item of productStatusList" [ngValue]="item.value">{{item.name}}
                        </option>
                      </select>
                    </div>
                    <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12 mb-2"
                      *ngIf="productFormControl.status.value === 'scheduled'">
                      <label>Scheduled date</label>
                      <input type="date" class="form-control" [min]="getCurrentDate()" formControlName="scheduleDate">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row pt-3 pb-3 footer-row">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="d-flex justify-content-end gap-2">
            <button type="button" class="btn draft-btn" (click)="cancel()">Cancel</button>
            <button type="button" class="btn publish-btn" (click)="validateProductForm()">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>
<!-- end -->

<div class="modal fade" id="publishProductModalToggle" aria-hidden="true"
  aria-labelledby="publishProductModalToggleLabel" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="image">
              <img src="../../../../../assets/img/publishProduct.png" />
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="confirm-msg">
              <h6>Publish Product?</h6>
              <p>Do you really want to publish this product now?</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="confirm-btns d-flex justify-content-center gap-2">
              <button class="btn schedule-btn" data-bs-toggle="modal" href="#scheduleModalToggle"
                data-bs-dismiss="modal">
                Schedule
              </button>
              <button class="btn publish-btn" data-bs-dismiss="modal">
                Publish
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="scheduleModalToggle" aria-hidden="true" aria-labelledby="scheduleModalToggleLabel"
  tabindex="-1">
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h5>Schedule</h5>
        <i class="fas fa-times" data-bs-dismiss="modal" aria-label="Close"></i>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <label>Schedule Date</label>
            <input type="date" placeholder="Select" class="form-control" />
          </div>
          <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <label>Schedule Time</label>
            <select class="form-control form-select">
              <option selected>Choose...</option>
              <option value="1">cm</option>
              <option value="2">inch</option>
            </select>
          </div>
        </div>
        <div class="row mt-3 pb-3">
          <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="confirm-btns d-flex justify-content-center gap-2">
              <button class="btn cancel-btn" data-bs-dismiss="modal">
                Cancel
              </button>
              <button class="btn publish-btn" data-bs-dismiss="modal">
                Publish
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="uploadProImageModal" aria-hidden="true" aria-labelledby="uploadProImageModalLabel"
  tabindex="-1">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        <form [formGroup]="imageForm">
          <div class="row">
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div>
                @if (
                imageFormControl.type.value == 'featuredImg' ||
                imageFormControl.type.value == 'featuredImgAttr'
                ) {
                <h6>Featured Image Upload</h6>
                } @else if (imageFormControl.type.value == 'galleryImg') {
                <h6>Gallery Image Upload</h6>
                }
              </div>
              <ul class="nav nav-pills justify-content-end mt-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="pills-upload-tab" data-bs-toggle="pill"
                    data-bs-target="#pills-upload" type="button" role="tab" aria-controls="pills-upload"
                    aria-selected="true">
                    <span class="material-symbols-rounded"> upload </span>
                    Upload
                  </button>
                </li>
                <li class="nav-item" role="presentation" *ngIf="imageLibrary.length>0">
                  <button class=" nav-link" id="pills-library-app-tab" data-bs-toggle="pill"
                    data-bs-target="#pills-library-app" type="button" role="tab" aria-controls="pills-library-app"
                    aria-selected="false">
                    <span class="material-symbols-rounded"> library_books </span>Library
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-upload" role="tabpanel"
                  aria-labelledby="pills-upload-tab">
                  <div class="row mt-2">
                    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <form id="image-upload-form" class="image-uploader">
                        <input #productFileUpload id="image-upload" type="file" name="fileUpload" accept="image/*"
                          (change)="fileSave($event, 'productImage')" />
                        <label for="image-upload" id="file-drag">
                          <div id="start">
                            @if (!!imageFormControl.url.value) {
                            <div class="after-upload-gallery-image">
                              <img [src]="imageFormControl.url.value" />
                            </div>
                            } @else {
                            <div class="upload-caption">
                              <i class="far fa-image"></i>
                              <h5 *ngIf="imageFormControl.type.value">Click here to</h5>
                              <h5 *ngIf="!imageFormControl.type.value">Click here</h5>
                              <h5 *ngIf="imageFormControl.type.value == 'featuredImg' ||
                              imageFormControl.type.value == 'featuredImgAttr'">Add Featured Image</h5>
                              <h5 *ngIf="imageFormControl.type.value == 'galleryImg'">Add Gallery Image</h5>
                            </div>
                            }
                          </div>
                        </label>
                      </form>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="pills-library-app" role="tabpanel"
                  aria-labelledby="pills-library-app-tab">
                  <div class="row mt-2">
                    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 library-bg">
                      <div class="row mt-1">
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="input-group flex-nowrap">
                            <span class="input-group-text" id="addon-wrapping">
                              <i class="fas fa-search" (click)="searchImage()"></i></span>
                            <input type="text" class="form-control" placeholder="Search" [(ngModel)]="searchText"
                              [ngModelOptions]="{standalone: true}" (keyup.enter)=" searchImageLibrary()"
                              aria-label="Search" />
                          </div>
                        </div>
                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-1"
                          *ngFor="let image of imageLibrary;let i=index">
                          <div [ngClass]="image.class" (click)="addImage(i)">
                            <img [src]="image.url" />
                            <div class="secondary">
                              <i class="far fa-check-square"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-6 col-sm-12 col-xs-12">
              <div class="d-flex justify-content-end align-items-center">
                <i class="fas fa-times" data-bs-dismiss="modal" (click)="clearImageUploadModal()"></i>
              </div>
              <div class="mt-2">
                <label class="fw-400">Alternate Text to Image
                  <span class="mandatory">*</span></label>
                <input type="text" class="form-control" placeholder="Enter Alternate Text to Image"
                  formControlName="alterText" />
              </div>
              <div class="mt-2">
                <label class="fw-400">Description</label>
                <textarea class="form-control" placeholder="Enter Description" rows="6"
                  formControlName="description"></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button class="btn cancel-btn" data-bs-dismiss="modal" #closeImageUploadModal (click)="clearImageUploadModal()">
          Cancel
        </button>
        @if (proImageFormSubmitted) {
        <button class="btn save-btn" [disabled]="imageForm.invalid">
          Save
        </button>
        } @else {
        <button class="btn save-btn" (click)="saveProductImage()" [disabled]="imageForm.invalid || !access['add']">
          Save
        </button>
        }
      </div>
    </div>
  </div>
</div>