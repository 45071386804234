<section id="deliveryPartnersListSec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 my-auto">
                <div class="card m-2">
                    <div class="card-body">
                        <!-- delivery-partners-list-heading-row start -->
                        <div class="row delivery-partners-list-heading-row">
                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 my-auto">
                                <div class="text-center text-md-left mt-2 mt-md-0">
                                    <h2>Delivery Partners</h2>
                                    <p class="mb-2 mb-md-0">{{deliveryPartnerList?.length}} Results Found</p>
                                </div>
                            </div>
                            <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 my-auto">
                                <div
                                    class="has-search-d-flex justify-content-center justify-content-md-end align-items-start align-items-md-center flex-nowrap flex-md-wrap">
                                    <app-common-search (onEnter)="search($event)"
                                        class="mb-2 mb-md-0"></app-common-search>
                                    <div *ngIf="!filterApplied">
                                        <button type="button" class="btn filter-btn me-3 d-none d-md-block"
                                            (click)="showFilterModalDialog('right')"
                                            *ngIf="deliveryPartnerList.length>10">
                                            <span class="material-symbols-outlined me-1">
                                                filter_alt
                                            </span>
                                            Filter
                                        </button>
                                        <button type="button" class="btn filter-btn me-3 d-block d-md-none"
                                            (click)="showFilterModalDialog('right')"
                                            *ngIf="deliveryPartnerList.length>10">
                                            <span class="material-symbols-outlined">
                                                filter_alt
                                            </span>
                                        </button>
                                    </div>
                                    <div *ngIf="filterApplied">
                                        <button type="button" class="btn after-filter-btn me-3 d-none d-md-block"
                                            (click)="showFilterModalDialog('right')">
                                            <i class="fas fa-filter"></i>
                                            Filter
                                        </button>
                                        <button type="button" class="btn after-filter-btn me-3 d-block d-md-none"
                                            (click)="showFilterModalDialog('right')">
                                            <i class="fas fa-filter"></i>
                                        </button>
                                    </div>
                                    <div>
                                        <button [disabled]="!access['add']" type="button"
                                            class="btn add-btn d-none d-lg-block"
                                            (click)="redirectToAddDeliveryPartnerPage()">
                                            Add Delivery Partner
                                        </button>
                                        <button [disabled]="!access['add']" type="button"
                                            class="btn add-btn d-none d-md-block d-lg-none"
                                            (click)="redirectToAddDeliveryPartnerPage()">
                                            Add Partner
                                        </button>
                                        <button [disabled]="!access['add']" type="button"
                                            class="btn add-btn d-block d-md-none"
                                            (click)="redirectToAddDeliveryPartnerPage()">
                                            <span class="material-symbols-outlined">
                                                add
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- delivery-partners-list-heading-row end -->
                <div class="card mb-1 ms-2 me-2 table-card" *ngFor="let item of deliveryPartnerList">
                    <div class="card-body">
                        <!-- delivery-partners-list-table-row start -->
                        <div class="row delivery-partners-list-table-row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="table-row action-table-responsive table-responsive">
                                    <table class="table table-borderless">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <img *ngIf="item.logo" [src]="item.logo" />
                                                </td>
                                                <td>
                                                    <div (click)="redirectToEditDeliveryPartner(item)">
                                                        <label>Delivery Partner</label>
                                                        <h6>{{item.companyName}}</h6>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <label>Contact Person</label>
                                                        <h6 *ngIf="item.contactPerson">{{item.contactPerson}}</h6>
                                                        <h6 *ngIf="!item.contactPerson">N/A</h6>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <label>Email</label>
                                                        <h6 *ngIf="item.email">{{item.email}}</h6>
                                                        <h6 *ngIf="!item.email">N/A</h6>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <label>Phone Number</label>
                                                        <h6 *ngIf="item.phoneNumber">{{item.phoneNumber}}</h6>
                                                        <h6 *ngIf="!item.phoneNumber">N/A</h6>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <label>Registered On</label>
                                                        <h6>{{item.createdAt | date:'dd/MM/yyyy' : 'UTC' }}</h6>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <label>Status</label>
                                                        <h6 class="active" *ngIf="item.status=='ACTIVE'">Active</h6>
                                                        <h6 class="deactive" *ngIf="item.status=='INACTIVE'">Inactive
                                                        </h6>
                                                    </div>
                                                </td>
                                                <td class="text-end">
                                                    <div class="dropdown dropstart">
                                                        <i class="fas fa-ellipsis-v" id="dropdownMenuButton1"
                                                            data-bs-toggle="dropdown" aria-expanded="false"
                                                            data-bs-auto-close="inside"
                                                            data-bs-auto-close="outside"></i>
                                                        <ul class="dropdown-menu custom-dropdown-menu-position"
                                                            aria-labelledby="dropdownMenuButton1">
                                                            <li>
                                                                <a class="dropdown-item"
                                                                    (click)="changeStatus(item.id,'Deactivate')"
                                                                    *ngIf="item.status=='ACTIVE'">
                                                                    <span
                                                                        class="material-symbols-rounded deactivate-icon"
                                                                        title="Deactivate">
                                                                        block
                                                                    </span>
                                                                    Deactivate
                                                                </a>
                                                                <a class="dropdown-item"
                                                                    (click)="changeStatus(item.id,'Activate')"
                                                                    *ngIf="item.status=='INACTIVE'">
                                                                    <span class="material-symbols-rounded activate-icon"
                                                                        title="Activate">
                                                                        add_task
                                                                    </span>Activate
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a [routerLink]="['/edit-delivery-partners']"
                                                                    [queryParams]="{id: item.id,pk: item.pk}"
                                                                    class="dropdown-item">
                                                                    <span
                                                                        class="material-symbols-outlined edit-icon me-3"
                                                                        title="Edit">
                                                                        edit_square
                                                                    </span>
                                                                    Edit
                                                                </a>
                                                            </li>
                                                            <li (click)="deleteDeliveryPartnerItem(item)">
                                                                <a class="dropdown-item">
                                                                    <span class="material-symbols-outlined delete-icon"
                                                                        title="Delete">
                                                                        delete
                                                                    </span>
                                                                    Delete</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                        <!-- delivery-partners-list-table-row end -->
                    </div>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3"
                    *ngIf="deliveryPartnerList.length==0">
                    <app-no-result-found></app-no-result-found>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- displayFilterModalToggle start -->
<p-dialog [draggable]="false" [(visible)]="displayFilterModalToggle" [position]="position" [modal]="true"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false" [showHeader]="false"
    [style]="{width: '34vw', minHeight: '100%', maxHeight: '100%', margin: '0'}"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}">
    <div id="displayFilterModalToggle">
        <div class="modal-header">
            <h5 class="modal-title">Filter</h5>
            <i class="fas fa-times" (click)="closeFilterModalDialog()"></i>
        </div>
        <div class="body mt-3" [formGroup]="filterForm">
            <div class="row">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Status</label>
                    <p-multiSelect display="chip" [options]="statusList" formControlName="status" optionLabel="id"
                        [filter]="true" [defaultLabel]="'Choose Status'">
                    </p-multiSelect>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Registered On</label>
                    <p-calendar [maxDate]="maxDate" [iconDisplay]="'input'" [showIcon]="true" inputId="icondisplay"
                        formControlName="registeredOn" selectionMode="range" placeholder="From - To"></p-calendar>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="d-flex justify-content-end gap-2">
            <p-button label="Cancel" styleClass="filter-cancel-btn" (click)="cancelFilterModalDialog()"></p-button>
            <p-button label="Apply" styleClass="filter-apply-btn" (click)="applyFilter()"></p-button>
        </div>
    </ng-template>
</p-dialog>
<!-- displayFilterModalToggle end -->
<!-- deleteModalToggle start -->
<p-dialog [showHeader]="false" [(visible)]="deleteModalToggle" [style]="{width: '25vw'}" [baseZIndex]="10000"
    [modal]="true">
    <div class="body mt-3" id="deleteModalToggle">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="delete-image">
                    <img src="../../../../../assets/img/delete.png" />
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="confirm-msg">
                    <h6>Are You Sure?</h6>
                    <p>You will not be able to recover this delivery partner!</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="confirm-btns">
                    <button type="button" class="btn cancel-btn" (click)="deleteModalToggle = false">
                        Cancel
                    </button>
                    <button type="button" class="btn delete-confirm-btn" (click)="confirmDeleteDeliveryPartner()">
                        Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</p-dialog>
<!-- {/* // deleteModalToggle end */} -->