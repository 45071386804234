import { ApiService } from '@services/api/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import * as query from 'graphql/queries';
import * as _ from 'underscore';
import { Component } from '@angular/core';
import { ROUTES } from '@/redirect-routes';
import * as mutation from 'graphql/mutations';
import { MessageService } from 'primeng/api';
import { Messages } from '@/Toaster-messages';
import { CommonService } from '@services/common/common.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ImageViewPopupModalComponent } from '@/shared/image-view-popup-modal/image-view-popup-modal.component';

@Component({
  selector: 'app-customer-review',
  templateUrl: './customer-review.component.html',
  styleUrl: './customer-review.component.scss'
})

export class CustomerReviewComponent {
  reviewId: any
  pk: any
  access: any = {}
  selectedreview: any = []
  attachments: any = []
  processingUpdate = false
  removeAbusiveComment: boolean = true;
  ref: DynamicDialogRef | undefined;
  orderId: any
  reviewResponseForm = this.formbuilder.group({
    reply: ['']
  });

  constructor(public dialogService: DialogService, private router: Router, private common: CommonService, private formbuilder: FormBuilder, private activatedRoute: ActivatedRoute, private messageService: MessageService, private awsApi: ApiService) { }


  async ngOnInit(): Promise<void> {
    this.getRoleManagement()
    this.startingFunction()
  }

  async getRoleManagement() {
    this.awsApi.loading.next(true);
    try {
      await this.common.getPermissionsOfUser('Products', 'Product Reviews');
      this.access = this.common.access;
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  async startingFunction() {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.reviewId = params.get('reviewId') ?? ''
      if (this.reviewId != '') {
        Promise.all([this.GetProductReview()]).then((response) => {
          this.selectedreview = response[0].data.getProductReview
          this.orderId = (this.selectedreview.searchKey).includes('#ECO') ? ('ECO' + this.selectedreview.searchKey.split('#ECO')[1]) : 'N/A'
          this.removeAbusiveComment = this.selectedreview.removeAbusiveComment;
          this.autoFillReviewResponseForm()
        }).catch((error) => {
        });
      }
    })
  }

  async GetProductReview() {
    return await this.awsApi.client.graphql({
      query: query.getProductReview,
      variables: {
        pk: 'REVP#',
        id: this.reviewId
      }
    })
  }

  autoFillReviewResponseForm() {
    if (this.selectedreview.attachments && this.selectedreview.attachments.length > 0) {
      this.attachments = this.selectedreview.attachments
    }
    this.reviewResponseForm.patchValue({
      reply: this.selectedreview.reply
    })
  }

  updateCommentStatus(status: boolean) {
    this.processingUpdate = true;
    let data = {
      pk: 'REVP#',
      id: this.reviewId,
      removeAbusiveComment: status,
    }
    this.awsApi.client
      .graphql({
        query: mutation.updateProductReview,
        variables: {
          input: {
            ...data
          }
        }
      }).then((response) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: Messages.PRODUCT_REVIEWS_UPDATED
        });
        this.processingUpdate = false;
        this.startingFunction()
      }).catch((error) => {
        this.awsApi.loading.next(false);

        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      });
  }

  redirectToProductReviewsPage() {
    this.router.navigate([ROUTES.LIST_PRODUCT_REVIEWS]);
  }

  onCheckboxChange() {
    this.removeAbusiveComment = !this.removeAbusiveComment
  }

  showImageViewModal(url: any) {
    if (url.toString().slice(-3) === 'pdf') {
      window.open(url)
    }
    else {
      this.ref = this.dialogService.open(ImageViewPopupModalComponent,
        {
          data: {
            image: url
          },
          header: 'View Image',
          width: '50vw',
          modal: true,
          breakpoints: {
            '960px': '75vw',
            '640px': '90vw'
          },
        });
    }
  }
}