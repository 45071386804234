import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import { MessageService } from 'primeng/api';
import * as mutation from 'graphql/mutations';
import * as query from 'graphql/queries';
import { CommonService } from '@services/common/common.service';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import _ from 'underscore';
import { Messages } from '@/Toaster-messages';
@Component({
  selector: 'app-edit-warehouse',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, DropdownModule],
  templateUrl: './edit-warehouse.component.html',
  styleUrl: './edit-warehouse.component.scss'
})

export class EditWarehouseComponent implements OnInit {
  addWareHouseForm: FormGroup;
  status: boolean = false
  wareID: string;
  access: any = {}
  pk: string;
  mainWareHouseList: any = [];
  wareHouseData: any
  countryList: any = []
  constructor(
    private awsApi: ApiService,
    private _router: Router,
    private fb: FormBuilder,
    private common: CommonService,
    private messageService: MessageService,
    private _activatedroute: ActivatedRoute
  ) {
    this.addWareHouseForm = this.fb.group({
      name: ['', [Validators.required, Validators.pattern(/^(?!\s*$)(?=.*[a-zA-Z])[a-zA-Z0-9\s]+$/)]],
      addressLine1: ['', [Validators.required]],
      addressLine2: [''],
      country: [{}, [Validators.required]],
      state: [''],
      city: [''],
      zipCode: [''],
      contactPerson: ['', [Validators.required]],
      email: ['', [Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
      phoneNumber: ['', [Validators.required, Validators.pattern('[-+0-9]{10,13}')]],
      workingHoursFrom: ['', [Validators.required]],
      workingHoursTo: ['', [Validators.required]],
      latitude: [],
      longitude: []
    });
  }

  ngOnInit(): void {
    this.getRoleManagement()
    this._activatedroute.queryParamMap.subscribe((params) => {
      this.wareID = params.get('id') ?? '';
      this.pk = params.get('pk') ?? '';
      if (this.wareID != '' && this.pk != '') {
        this.awsApi.loading.next(true);
        Promise.all([this.getAllMainWareHouse(), this.getWareHouseByID(), this.getAllCountry()])
          .then((response) => {
            this.mainWareHouseList = [
              ...response[0].data.listWareHouses.items
            ];
            this.wareHouseData = response[1].data.getWareHouse;
            this.countryList = response[2].data.listCountries.items;
            let country = _.find(this.countryList, (country) => {
              return country.id === this.wareHouseData.countryId
            });
            if (country) { delete country.__typename }
            this.addWareHouseForm.patchValue({
              name: this.wareHouseData.name,
              addressLine1: this.wareHouseData.addressLine1,
              addressLine2: this.wareHouseData.addressLine2,
              country: country,
              state: this.wareHouseData.state,
              city: this.wareHouseData.city,
              zipCode: this.wareHouseData.zipCode,
              contactPerson: this.wareHouseData.contactPerson,
              email: this.wareHouseData.email,
              phoneNumber: this.wareHouseData.phoneNumber,
              workingHoursFrom: this.wareHouseData.workingHoursFrom,
              workingHoursTo: this.wareHouseData.workingHoursTo,
              latitude: this.wareHouseData.latitude,
              longitude: this.wareHouseData.longitude
            });
            this.status = this.wareHouseData.status
            this.awsApi.loading.next(false);
          })
          .catch((error) => {
            this.awsApi.loading.next(false);
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: Messages.SOMETHING_WENT_WRONG
            });
          });
      }
    });
  }

  async getRoleManagement() {
    this.awsApi.loading.next(true);
    try {
      await this.common.getPermissionsOfUser('Settings', 'Manage Warehouse');
      this.access = this.common.access;
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  getAllCountry() {
    return this.awsApi.client.graphql({
      query: query.listCountries,
      variables: {
        limit: 9999
      }
    });
  }

  async getAllMainWareHouse() {
    return await this.awsApi.client.graphql({
      query: query.listWareHouses,
      variables: {
        pk: 'WARE#'
      }
    });
  }

  async getWareHouseByID() {
    return await this.awsApi.client.graphql({
      query: query.getWareHouse,
      variables: {
        pk: this.pk,
        id: this.wareID
      }
    });
  }

  onSwitchChange() {
    this.status = !this.status;
  }

  routeListWareHouse() {
    this._router.navigate(['/list-warehouse']);
  }

  editWareHouse() {

    this.awsApi.loading.next(true);
    if ((this.addWareHouseForm.value.latitude && this.addWareHouseForm.value.longitude) ||
      (!this.addWareHouseForm.value.latitude && !this.addWareHouseForm.value.longitude)) {
      const updatedWareHouseName = this.addWareHouseForm.value.name.toLowerCase().replace(/\s+/g, '');
      this.awsApi.client.graphql({
        query: query.listWareHouses,
        variables: {
          filter: null,
          limit: 9999999,
          nextToken: null,
          sortDirection: null
        }
      }).then((response) => {
        const wareHouseList = response.data.listWareHouses.items;
        const isDuplicate = wareHouseList.some((wareHouse) => {
          let existingName = wareHouse.name.toLowerCase().replace(/\s+/g, '');
          return existingName === updatedWareHouseName && wareHouse.id !== this.wareHouseData.id;
        });
        if (isDuplicate) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: Messages.NAME_ALREADY_EXISTS
          });
          this.awsApi.loading.next(false);
          return;
        }
        let str = this.addWareHouseForm.value.name.toLowerCase()
        str = str.replace(/\s{2,}/g, ' ');
        str = (str.trim()).replace(/\s+/g, '')
        let data = {
          pk: this.wareHouseData.pk,
          id: this.wareHouseData.id,
          name: this.addWareHouseForm.value.name,
          addressLine1: this.addWareHouseForm.value.addressLine1,
          addressLine2: this.addWareHouseForm.value.addressLine2,
          country: this.addWareHouseForm.value.country.name,
          countryId: this.addWareHouseForm.value.country.id,
          state: this.addWareHouseForm.value.state,
          city: this.addWareHouseForm.value.city,
          zipCode: this.addWareHouseForm.value.zipCode,
          contactPerson: this.addWareHouseForm.value.contactPerson,
          email: this.addWareHouseForm.value.email,
          phoneNumber: this.addWareHouseForm.value.phoneNumber,
          workingHoursFrom: this.addWareHouseForm.value.workingHoursFrom,
          workingHoursTo: this.addWareHouseForm.value.workingHoursTo,
          status: this.status,
          searchKey: str,
          latitude: this.addWareHouseForm.value.latitude,
          longitude: this.addWareHouseForm.value.longitude
        };
        this.awsApi.client
          .graphql({
            query: mutation.updateWareHouse,
            variables: {
              input: {
                ...data
              }
            }
          })
          .then((response) => {
            this.awsApi.loading.next(false);
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Warehouse updated successfully!'
            });
            this._router.navigate(['/list-warehouse']);
          })
          .catch((error) => {
            this.awsApi.loading.next(false);
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: Messages.SOMETHING_WENT_WRONG
            });
          });
      })
    } else {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill all required details!'
      });
    }
  }

  capitalizeFirstLetter(controlName: string) {
    const name = this.addWareHouseForm.controls[controlName];
    const capitalizeString = (input: string): string => {
      const cleanInput = input.replace(/[^a-zA-Z\s]/g, '');
      const trimmedInput = cleanInput.trim().replace(/\s+/g, ' ');
      return trimmedInput.charAt(0).toUpperCase() + trimmedInput.slice(1);
    };
    if (name && name.value) {
      const capitalized = capitalizeString(name.value);
      name.setValue(capitalized);
    }
  }
}