import { Component, OnInit } from '@angular/core';
import {
    FormBuilder,
    FormsModule,
    ReactiveFormsModule,
    Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { NewUser, UserAuthService } from '@services/user-auth/user-auth.service';
import { environment } from 'environments/environment';
import { ApiService } from '@services/api/api.service';

@Component({
    selector: 'app-user-signup',
    templateUrl: './user-signup.component.html',
    styleUrl: './user-signup.component.scss'
})
export class UserSignupComponent implements OnInit {
    hide = true;
    signupForm = this.fb.group({
        email: ['', [Validators.email, Validators.required]],
        password: ['', [Validators.required]],
        phone_number: ['', [Validators.required]],
        fname: ['', [Validators.required, Validators.min(1)]],
        lname: ['', [Validators.required, Validators.min(1)]]
    });

    get emailInput() {
        return this.signupForm.get('email');
    }
    get passwordInput() {
        return this.signupForm.get('password');
    }
    get fnameInput() {
        return this.signupForm.get('fname');
    }
    get lnameInput() {
        return this.signupForm.get('lname');
    }

    get phoneInput() {
        return this.signupForm.get('phone_number');
    }

    constructor(
        private fb: FormBuilder,
        private _authService: UserAuthService,
        private _router: Router,
        private awsApi: ApiService
    ) { }

    ngOnInit(): void { }

    // getEmailInputError() {
    //   if (this.emailInput?.hasError("email")) {
    //     return "Please enter a valid email address.";
    //   }
    //   if (this.emailInput?.hasError("required")) {
    //     return "An Email is required.";
    //   }
    // }

    // getPasswordInputError() {
    //   if (this.passwordInput?.hasError("required")) {
    //     return "A password is required.";
    //   }
    // }

    // shouldEnableSubmit() {
    //   return (
    //     !this.emailInput?.valid ||
    //     !this.passwordInput?.valid ||
    //     !this.fnameInput?.valid
    //   );
    // }

    signUp() {
        this.awsApi.loading.next(true);
        let myuuid = uuidv4();
        this._authService
            .signUp({
                username: this.emailInput?.value,
                email: this.emailInput?.value,
                password: this.passwordInput?.value,
                firstName: this.fnameInput?.value,
                lastName: this.lnameInput?.value,
                phone_number: this.phoneInput?.value,
                role: 'ADMIN'
            } as NewUser)
            .then((data) => {
                this.awsApi.loading.next(false);
                if (data.nextStep.signUpStep === 'CONFIRM_SIGN_UP') {
                    environment.confirm.attributeName =
                        data.nextStep?.['codeDeliveryDetails'].attributeName;
                    environment.confirm.email = this.emailInput?.value ?? '';
                    environment.confirm.password =
                        this.passwordInput?.value ?? '';
                    this._router.navigate(['confirm'], { queryParams: { email: this.emailInput?.value, type: "signUp" } })
                }
            })
            .catch((error) => {
                this.awsApi.loading.next(false);;
            });
    }
}