import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTES } from '@/redirect-routes';
import { CKEditorModule, ChangeEvent } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MessageService } from 'primeng/api';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import * as query from 'graphql/queries';
import * as mutation from 'graphql/mutations';
import { ApiService } from '@services/api/api.service';
import { uploadData } from 'aws-amplify/storage';
import amplifyconfig from 'amplifyconfiguration.json';
import { CommonModule, formatDate } from '@angular/common';
import { MultiSelectModule } from 'primeng/multiselect';
import { CommonService } from '@services/common/common.service';
import { Messages } from '@/Toaster-messages';
import { PromoStatusList, PromoStsList } from '@/common-list';

@Component({
  selector: 'app-promocode-edit',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MultiSelectModule, CKEditorModule],
  templateUrl: './promocode-edit.component.html',
  styleUrl: './promocode-edit.component.scss'
})
export class PromocodeEditComponent implements OnInit {
  discountType: any = [
    { name: "Percentage", value: "percentage" },
    { name: "Amount", value: "amount" }
  ];
  selectedUsers: any = []
  selectedCustomer: any;
  selectedItems: any[] = [];
  selectedCategory: any = []
  promoID: string;
  pk: string;
  minDate: string;
  promocodeList: any = []
  promoData: any
  access: any = {}
  processingUpdate = false
  defaultStatus: string | null
  statusList = PromoStsList
  promoCodeForm = this.fb.group({
    promoImage: [''],
    promoURL: [''],
    code: ['', [Validators.required, Validators.pattern(/^(?=.*[a-zA-Z])[a-zA-Z\d\s\S]+$/)]],
    discountType: ['', [Validators.required]],
    percentageValue: ['', [Validators.required]],
    redemptionType: ['', [Validators.required]],
    validationFrom: ['', [Validators.required]],
    validationTo: ['', [Validators.required]],
    description: [''],
    terms: [''],
    appliedTo: [''],
    specificCustomer: [],
    specificProduct: [],
    status: ['']
  })

  public Editor = ClassicEditor;
  public onReady(editor: ClassicEditor) { }
  public onChange({ editor }: ChangeEvent) { }

  constructor(private common: CommonService, private _router: Router, private awsApi: ApiService, private fb: FormBuilder, private messageService: MessageService,
    private _activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
    const today = new Date();
    this.minDate = today.toISOString().split('T')[0];
    this.getRoleManagement()
    this.getAllPromoCodes()
  }

  getAllPromoCodes() {
    this._activatedroute.queryParamMap.subscribe(async (params) => {
      this.promoID = params.get('id') ?? '';
      if (this.promoID != '') {
        this.awsApi.loading.next(true);
        Promise.all([this.getPromoByID(), this.getAllUsers(), this.getAllCategory()])
          .then((response) => {
            this.promoData = response[0].data.getPromoCodes;
            this.selectedUsers = response[1].data.listUsers.items;
            this.selectedUsers.forEach(user => {
              user.fullName = user.firstName + ' ' + user.lastName;
            });
            this.selectedCategory = response[2].data.listCategories.items;
            const formattedFromDate = formatDate(this.promoData.validFrom, 'yyyy-MM-dd', 'en-US');
            const formattedToDate = formatDate(this.promoData.validTo, 'yyyy-MM-dd', 'en-US');

            let specificData = [];
            let uniqueItemsSet = new Set();
            if (this.promoData.advancedSetting?.appliedTo === 'customers') {
              specificData = this.promoData.advancedSetting.specificType
                .map(identifier => this.selectedUsers.find(user => user.id === identifier.id))
                .filter(Boolean);

              this.selectedItems = [...specificData];
              specificData.forEach(item => uniqueItemsSet.add(item.fullName));
              this.promoCodeForm.controls.specificCustomer.valueChanges.subscribe(value => {
                if (Array.isArray(value)) {
                  value.forEach(item => {
                    if (!uniqueItemsSet.has(item.fullName)) {
                      uniqueItemsSet.add(item.fullName);
                      this.selectedItems.push(item);
                    }
                  });
                } else {
                  this.selectedItems = specificData.slice();
                  uniqueItemsSet.clear();
                  specificData.forEach(item => uniqueItemsSet.add(item.fullName));
                }
              });
            }
            else if (this.promoData.advancedSetting?.appliedTo === 'products') {
              specificData = this.promoData.advancedSetting.specificType
                .map(identifier => this.selectedCategory.find(category => category.id === identifier.id))
                .filter(Boolean);
              this.selectedItems = [...specificData];
              specificData.forEach(item => uniqueItemsSet.add(item.catName));
              this.promoCodeForm.controls.specificProduct.valueChanges.subscribe(value => {
                if (Array.isArray(value)) {
                  value.forEach(item => {
                    if (!uniqueItemsSet.has(item.catName)) {
                      uniqueItemsSet.add(item.catName);
                      this.selectedItems.push(item);
                    }
                  });
                } else {
                  this.selectedItems = specificData.slice();
                  uniqueItemsSet.clear();
                  specificData.forEach(item => uniqueItemsSet.add(item.catName));
                }
              });
            }
            this.promoCodeForm.patchValue({
              promoImage: this.promoData.promoImage,
              promoURL: this.promoData.promoURL,
              code: this.promoData.code,
              discountType: this.promoData.discount.type,
              percentageValue: this.promoData.discount.value,
              redemptionType: this.promoData.redemptionType,
              validationFrom: formattedFromDate,
              validationTo: formattedToDate,
              description: this.promoData.description,
              terms: this.promoData.terms,
              appliedTo: this.promoData.advancedSetting?.appliedTo ?? '',
              specificCustomer: this.promoData.advancedSetting?.appliedTo === 'customers' ? specificData : '',
              specificProduct: this.promoData.advancedSetting?.appliedTo === 'products' ? specificData : '',
              status: this.promoData.status
            });
            if (this.promoData.advancedSetting?.appliedTo === 'customers') {
              this.promoCodeForm.get('specificCustomer').setValue(specificData);
            } else if (this.promoData.advancedSetting?.appliedTo === 'products') {
              this.promoCodeForm.get('specificProduct').setValue(specificData);
            }
            this.defaultStatus = this.promoData.status
            this.awsApi.loading.next(false);
          })
          .catch((error) => {
            this.awsApi.loading.next(false);
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: Messages.SOMETHING_WENT_WRONG
            });
          });
      }
    });
  }

  async getRoleManagement() {
    this.awsApi.loading.next(true);
    try {
      await this.common.getPermissionsOfUser('Promo Codes');
      this.access = this.common.access;
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  onSelectDiscountType() {
    this.promoCodeForm.controls.percentageValue.reset()
  }

  async getPromoByID() {
    return await this.awsApi.client.graphql({
      query: query.getPromoCodes,
      variables: {
        pk: 'PROMO#',
        id: this.promoID
      }
    });
  }

  async getAllCategory() {
    return await this.awsApi.client.graphql({
      query: query.listCategories,
      variables: {
        filter: null,
        limit: 9999999,
        nextToken: null,
        sortDirection: null
      }
    });
  }

  async getAllUsers() {
    return await this.awsApi.client.graphql({
      query: query.listUsers,
      variables: {
        filter: null,
        limit: 9999999,
        nextToken: null,
        sortDirection: null
      }
    });
  }

  async fileSave(event: any) {
    this.awsApi.loading.next(true);
    if (event.target instanceof HTMLInputElement && event.target.files) {
      let file = event.target.files[0];
      let isValid = await this.common.isValidFileType(event)
      if (!isValid) { return; }
      try {
        const result = await uploadData({
          key: file.name,
          data: file,
          options: {
            accessLevel: 'guest'
          }
        }).result;
        this.awsApi.loading.next(false);
        let imageUrl = await this.common.getS3Url(file)
        this.promoCodeForm.patchValue({
          promoURL: imageUrl,
          promoImage: result.key
        });
      } catch (error) {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      }
    }
  }

  redirectToPromocodeListPage() {
    this._router.navigate([ROUTES.PROMOCODE_LIST]);
  }

  deleteUrl(type: string) {
    if (type === 'promo' && this.promoCodeForm) {
      this.promoCodeForm.patchValue({
        promoImage: null,
        promoURL: ''
      });
      const fileInput = document.getElementById('promocode-image-upload') as HTMLInputElement;
      if (fileInput) {
        fileInput.value = '';
      }
    }
  }

  async updatePromoCodes() {
    if (this.promoCodeForm.valid) {
      this.processingUpdate = true;
      let discountValue;
      let advancedSettings;
      let userNameArray = [];
      let catNameArray = [];
      if (this.promoCodeForm.controls.appliedTo.value === 'products' &&
        this.promoCodeForm.controls.discountType.value === 'amount') {
        this.processingUpdate = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.INVALID_DISCOUNT_TYPE
        });
        return;
      }
      let promocodes = await this.common.getPromoCodes({}, ['id', 'code'])
      let newPromoCode = ((this.promoCodeForm.controls.code.value).toLowerCase()).replace(/\s+/g, '');
      if (promocodes.length > 0 && promocodes.some(promo => (((promo.code).toLowerCase()).replace(/\s+/g, '') === newPromoCode && promo.id != this.promoData.id))) {
        this.processingUpdate = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'This promo code already exists!'
        });
        return;
      }
      discountValue = this.promoCodeForm.controls.percentageValue.value;
      const appliedToValue = this.promoCodeForm.controls.appliedTo.value;
      if (this.selectedItems != null) {
        for (let i = 0; i < this.selectedItems.length; i++) {
          const item = this.selectedItems[i];
          if (appliedToValue === 'customers') {
            userNameArray.push({
              name: item.fullName,
              id: item.id
            });
          } else if (appliedToValue === 'products') {
            if (!catNameArray.includes(item.catName)) {
              catNameArray.push({
                name: item.catName,
                id: item.id
              });
            }
          }
        }
        if (appliedToValue === 'customers') {
          advancedSettings = {
            appliedTo: 'customers',
            specificType: userNameArray
          };
        } else if (appliedToValue === 'products') {
          advancedSettings = {
            appliedTo: 'products',
            specificType: catNameArray
          };
        }
      }
      let str = this.promoCodeForm.controls.code.value.toLowerCase();
      str = str.replace(/\s{2,}/g, ' ');
      str = (str.trim()).replace(/\s+/g, '');
      let data = {
        pk: this.promoData.pk,
        id: this.promoData.id,
        promoImage: this.promoCodeForm.controls.promoImage.value,
        promoURL: this.promoCodeForm.controls.promoURL.value,
        code: this.promoCodeForm.controls.code.value,
        discount: {
          type: this.promoCodeForm.controls.discountType.value,
          value: discountValue
        },
        redemptionType: this.promoCodeForm.controls.redemptionType.value,
        searchKey: str,
        validFrom: new Date(this.promoCodeForm.controls.validationFrom.value).toISOString(),
        validTo: new Date(this.promoCodeForm.controls.validationTo.value).toISOString(),
        description: this.promoCodeForm.controls.description.value,
        terms: this.promoCodeForm.controls.terms.value,
        advancedSetting: advancedSettings,
        status: this.promoCodeForm.controls.status.value,
      };
      this.awsApi.client.graphql({
        query: mutation.updatePromoCodes,
        variables: {
          input: {
            ...data
          }
        }
      })
        .then((response) => {
          this.awsApi.loading.next(false);
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Promocode updated successfully!'
          });
          this.processingUpdate = false;
          this._router.navigate(['/list-promocode']);
        })
        .catch((error) => {
          this.awsApi.loading.next(false);
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: Messages.SOMETHING_WENT_WRONG
          });
        });
    } else {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.PLEASE_FILL_REQUIRED_FIELDS
      });
    }
  }

  changeAdvanceSettings() {
    this.selectedItems = null;
    this.promoCodeForm.controls.appliedTo.valueChanges.subscribe(value => {
      this.promoCodeForm.controls.specificCustomer.reset();
      this.promoCodeForm.controls.specificProduct.reset();
    });
    if (this.promoCodeForm.controls.appliedTo.value == 'customers') {
      this.promoCodeForm.valueChanges.subscribe(value => {
        this.selectedItems = Array.isArray(value.specificCustomer) ? value.specificCustomer : [];
      });
    } else if (this.promoCodeForm.controls.appliedTo.value == 'products') {
      this.promoCodeForm.valueChanges.subscribe(value => {
        this.selectedItems = Array.isArray(value.specificProduct) ? value.specificProduct : [];
      });
    }
  }

  clearSelection(item: any, type: string): void {
    this.selectedItems = this.selectedItems.filter(i => i !== item);
    if (type === 'customers') {
      const updatedCustomers = this.promoCodeForm.controls.specificCustomer.value.filter((i: any) => i !== item);
      this.promoCodeForm.controls.specificCustomer.setValue(updatedCustomers);
    } else if (type === 'products') {
      const updatedProducts = this.promoCodeForm.controls.specificProduct.value.filter((i: any) => i !== item);
      this.promoCodeForm.controls.specificProduct.setValue(updatedProducts);
    }
  }

  updateSelectedItems(event: any, type: string): void {
    if (type === 'customers') {
      this.selectedItems = event.value;
      this.promoCodeForm.controls.specificCustomer.setValue(event.value);
    } else if (type === 'products') {
      this.selectedItems = event.value;
      this.promoCodeForm.controls.specificProduct.setValue(event.value);
    }
  }

  preventE(event) {
    const invalidChars = ['e', 'E', '+', '-', '.'];
    if (invalidChars.includes(event.key)) {
      event.preventDefault();
    }
  }
}