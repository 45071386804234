import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import * as mutation from 'graphql/mutations';
import { v4 as uuidv4 } from 'uuid';
import { ApiService } from '@services/api/api.service';
import { Messages } from '@/Toaster-messages';
import { ROUTES } from '@/redirect-routes';
import * as query from 'graphql/queries';

@Component({
  selector: 'app-add-content',
  templateUrl: './add-content.component.html',
  styleUrl: './add-content.component.scss'
})
export class AddContentComponent {
  type: any
  contentId: any
  selectedContent: any
  contentsList: any
  lastIndex: any
  addContentForm = this.fb.group({
    contentName: ['', [Validators.required]]
  })

  constructor(private router: Router, private awsApi: ApiService, private activatedRoute: ActivatedRoute, private fb: FormBuilder, private messageService: MessageService) { }
  RefreshPage() {
    this.addContentForm.reset()
  }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.type = params.get('type') ?? ''
      this.contentId = params.get('id') ?? ''
      if (this.type != '') { }
      if (this.contentId != '') {
        this.GetContent().then((response) => {
          this.awsApi.loading.next(false);
          this.selectedContent = response.data.getContent
          this.autoFillEditForm()
        }).catch((error) => {

          this.awsApi.loading.next(false);
        });
      }
      else {
        this.getContentsList()
      }
    })
  }

  getContentsList() {
    this.awsApi.client
      .graphql({
        query: query.listContents
      }).then((response) => {
        this.contentsList = response.data.listContents.items.sort((a: any, b: any) => new Date(b.sequenceNumber).getTime() - new Date(a.sequenceNumber).getTime())
        this.lastIndex = this.contentsList[0].sequenceNumber
        this.awsApi.loading.next(false);
      }).catch((error) => {

        this.awsApi.loading.next(false);
      });
  }

  autoFillEditForm() {
    this.addContentForm.patchValue({
      contentName: this.selectedContent.contentName
    })
  }

  async GetContent() {
    return await this.awsApi.client.graphql({
      query: query.getContent,
      variables: {
        pk: 'CONT#',
        id: this.contentId
      }
    })
  }

  AddContent() {
    this.awsApi.loading.next(true);
    let data = {
      pk: 'CONT#',
      id: uuidv4(),
      contentName: this.addContentForm.controls.contentName.value,
      sequenceNumber: this.lastIndex + 1,
      searchKey: this.addContentForm.controls.contentName.value.replace(/\s/g, "").toLowerCase(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    };
    this.awsApi.client
      .graphql({
        query: mutation.createContent,
        variables: {
          input: {
            ...data
          }
        }
      }).then((response) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: Messages.CONTENT_CREATED
        });
        this.addContentForm.reset()
        this.router.navigate([ROUTES.LIST_CONTENT]);
      }).catch((error) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      });
  }

  EditContent() {
    this.awsApi.loading.next(true);
    let data = {
      pk: 'CONT#',
      id: this.contentId,
      contentName: this.addContentForm.controls.contentName.value,
      searchKey: this.addContentForm.controls.contentName.value.replace(/\s/g, "").toLowerCase(),
      updatedAt: new Date().toISOString()
    };
    this.awsApi.client
      .graphql({
        query: mutation.updateContent,
        variables: {
          input: {
            ...data
          }
        }
      })
      .then((response) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: Messages.CONTENT_EDIED
        });
        this.addContentForm.reset()
        this.router.navigate([ROUTES.LIST_CONTENT]);
      })
      .catch((error) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      });
  }

  redirectToListContentsPage() {
    this.router.navigate([ROUTES.LIST_CONTENT]);
  }
}
