<section id="editShippingSec">
    <div class="container-fluid">
        <div class="row mb-1 mt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card mb-1 heading-card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="d-flex justify-content-start align-items-center">
                                    <i class="fas fa-chevron-left" (click)="redirectToListing()"></i>
                                    <h6>{{shippingDetails.city}}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-1 shipping-details-card" [formGroup]="shippingForm">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-4 col-sm-6 col-xs-12 mb-2">
                                <label>City/Location <span class="mandatory">*</span></label>
                                <input type="text" formControlName="city" class="form-control"
                                    placeholder="Enter City/Location" />
                                <span class="text-danger"
                                    *ngIf="shippingForm.get('city').touched && shippingForm.get('city').invalid">
                                    <div *ngIf="shippingForm.get('city').errors['pattern']">
                                        Accepts only alphabets and spaces.
                                    </div>
                                </span>
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                <label>Country <span class="mandatory">*</span></label>
                                <p-dropdown [filter]="true" [options]="countryList" formControlName="country"
                                    optionLabel="name" filterBy="name" placeholder="Choose Country" />
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                <label>State/Region</label>
                                <input type="text" formControlName="state" class="form-control"
                                    placeholder="Enter State/Region" />
                                <span class="text-danger"
                                    *ngIf="shippingForm.get('state').touched && shippingForm.get('state').invalid">
                                    <div *ngIf="shippingForm.get('state').errors['pattern']">
                                        Accepts only alphabets and spaces.
                                    </div>
                                </span>
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                <label>Delivery Fee <span class="mandatory">*</span></label>
                                <input type="number" (keypress)="preventE($event)" formControlName="deliveryFee"
                                    class="form-control" placeholder="Enter Delivery Fee({{currency}})" />
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2 d-none">
                                <label>Coverage </label>
                                <p-dropdown formControlName="coverage" [options]="coverageList" [showClear]="true"
                                    placeholder="Choose Coverage" optionLabel="title" (onChange)="onSelectCoverage()" />
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2"
                                *ngIf="selectedCoverage=='LatitudeAndLongitude'">
                                <label>Latitude </label>
                                <input type="number" (keypress)="preventE($event)" formControlName="latitude"
                                    class="form-control" placeholder="Enter" />
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2"
                                *ngIf="selectedCoverage=='LatitudeAndLongitude'">
                                <label>Longitude </label>
                                <input type="number" (keypress)="preventE($event)" formControlName="longitude"
                                    class="form-control" placeholder="Enter" />
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2"
                                *ngIf="selectedCoverage=='LatitudeAndLongitude'">
                                <label>Radius </label>
                                <input type="number" (keypress)="preventE($event)" formControlName="radius"
                                    class="form-control" placeholder="Enter" />
                            </div>
                            <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-4 col-sm-6 col-xs-12 mb-2"
                                *ngIf="selectedCoverage=='Zipcode'">
                                <label>Zipcodes </label>
                                <input type="text" formControlName="zipCodeValues" class="form-control"
                                    placeholder="Enter Zipcodes seperated by commas or ranges seperated by a dash."
                                    (keydown.enter)="addZipCode($event)" />
                            </div>
                            <div class="col-xxl-1 col-xl-1 col-lg-1 col-md-2 col-sm-4 col-xs-12 mb-2"
                                *ngIf="selectedCoverage=='Zipcode'">
                                <button type="button" class="btn zip-add-btn" (click)="addZipCode()">Add</button>
                            </div>
                        </div>
                        <div class="row" *ngIf="selectedCoverage=='Zipcode' && zipCodes.length>0">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                <label class="d-block">Selected Zipcodes</label>
                                <div class="chip-main">
                                    <div class="chip" *ngFor="let zipCode of zipCodes">
                                        <div class="chip-content">
                                            <p>{{zipCode}}</p>
                                        </div>
                                        <div class="chip-close" (click)="deleteZipCode(zipCode)">
                                            <span class="chip-icon"><i class="fas fa-times-circle"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pb-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="d-flex justify-content-end gap-2">
                    <button type="button" class="btn cancel-btn" (click)="redirectToListing()">Cancel</button>
                    <button type="button" class="btn save-btn" (click)="editShipping()">Save</button>
                </div>
            </div>
        </div>
    </div>
</section>