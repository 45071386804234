<section id="loginPage">
  <div class="container-fluid login-container">
    <div class="row">
      <div class="col-lg-12">
        <div class="mockup">
          <img src="../../../assets/img/Laptop.png" class="laptop-img" alt="Laptop">
          <div class="artwork">
            <div class="login-form">
              <div class="logo text-center">
                <img [src]="primaryLogo" alt="Logo" />
              </div>
              <form [formGroup]="signinForm">
                <div>
                  <h1 class="text-center mt-2">
                    Welcome Back !
                  </h1>
                  <h2 class="text-center mb-2">
                    Please Login to Your Account
                  </h2>
                </div>
                <div class="mb-2">
                  <label>Email <span class="mandatory">*</span></label>
                  <input type="email" class="form-control" id="email" name="email" placeholder="Enter Email Id"
                    formControlName="email" required autocomplete="new-email" />
                  <span class="text-danger"
                    *ngIf="(signinForm.controls.email.touched || submitted) && !emailInput?.valid">
                    <div *ngIf="signinForm.controls.email.errors!['required']">
                      Email is required
                    </div>
                    <div *ngIf="signinForm.controls.email.errors!['email']">
                      Enter valid email
                    </div>
                  </span>
                </div>
                <div class="mb-3">
                  <label>Password <span class="mandatory">*</span></label>
                  <div class="input-group">
                    <input type="{{ passwordInputType }}" formControlName="password" class="form-control"
                      placeholder="Enter password" required autocomplete="new-password" />
                    <span class="input-group-text" id="basic-addon1">
                      <i class="far fa-eye-slash" aria-hidden="true" *ngIf="!showPassword"
                        (click)="togglePasswordVisibility()"></i>
                      <i class="far fa-eye" aria-hidden="true" *ngIf="showPassword"
                        (click)="togglePasswordVisibility()"></i>
                    </span>
                  </div>
                  <span class="text-danger"
                    *ngIf="(signinForm.controls.password.touched || submitted) && !passwordInput?.valid">
                    <div *ngIf="signinForm.controls.password.errors!['required']">
                      Password is required
                    </div>
                    <div *ngIf="signinForm.controls.password.errors!['minlength']">
                      Password must contain atleast 6
                      charaters
                    </div>
                  </span>
                </div>
                <div class="mb-2">
                  <button type="button" (click)="signIn()" [disabled]="signinForm.invalid"
                    class="btn login-btn w-100 mb-3" *ngIf="processingSignIn == false">
                    Login
                  </button>
                  <button type="button" class="btn login-btn w-100" *ngIf="processingSignIn == true">
                    Login
                  </button>
                </div>
              </form>
              <div class="form-check">
                <input type="checkbox" class="form-check-input" id="rememberMe" [(ngModel)]="rememberMe" />
                <label class="form-check-label" for="rememberMe">Remember me</label>
                <a [routerLink]="['/forgot-password']" class="text-decoration-none float-end">Forgot Password?</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>