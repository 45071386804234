<section id="cartListSec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 my-auto">
                <div class="card m-2">
                    <div class="card-body">
                        <!-- cart-list-heading-row start -->
                        <div class="row cart-list-heading-row">
                            <div class="col-xxl-6 col-xl-6 col-lg-4 col-md-4 col-sm-12 col-xs-12 my-auto">
                                <div class="text-center text-md-left mt-2 mt-md-0">
                                    <h2>Saved Carts</h2>
                                    <p *ngIf="cartList.length>0" class="mb-2 mb-md-0">{{cartList.length}} Saved Carts
                                        Found</p>
                                </div>
                            </div>
                            <div class="col-xxl-6 col-xl-6 col-lg-8 col-md-8 col-sm-12 col-xs-12 my-auto">
                                <div
                                    class="has-search-d-flex justify-content-center justify-content-md-end align-items-start align-items-md-center flex-nowrap flex-md-wrap">
                                    <app-common-search (onEnter)="search($event)"
                                        class="mb-2 mb-md-0"></app-common-search>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- cart-list-heading-row end -->
                <div class="card mb-1 ms-2 me-2 table-card" *ngFor="let item of cartList">
                    <div class="card-body">
                        <!-- cart-list-table-row start -->
                        <div class="row cart-list-table-row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="content">
                                    <div class="flex">
                                        <label>Cart URL</label>
                                        <h6 [routerLink]="['/cart-details']" [queryParams]="{id: item.userId}">
                                            {{item.cartUrl}}</h6>
                                    </div>
                                    <div class="flex-column">
                                        <label>Cart Total</label>
                                        <h6>{{currency}} {{item.cartTotal | number: '.2'}}</h6>
                                    </div>
                                    <div class="flex-column text-end">
                                        <div class="dropdown dropstart pe-2">
                                            <i class="fas fa-ellipsis-v" id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown" aria-expanded="false"
                                                data-bs-auto-close="inside" data-bs-auto-close="outside"></i>
                                            <ul class="dropdown-menu dropdown-menu-position"
                                                aria-labelledby="dropdownMenuButton1">
                                                <li [ngClass]="{ disable: !access['delete'] }">
                                                    <a class="dropdown-item" (click)="deleteCartItem(item)"><span
                                                            class="material-symbols-outlined delete-icon"
                                                            title="Delete">delete </span>Delete</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="content mt-2">
                                    <div class="flex-column">
                                        <label>Customer Name</label>
                                        <h6 *ngIf="item.customerName!='undefined undefined'">
                                            {{item.customerName.split('undefined')[0]}}</h6>
                                        <h6 *ngIf="item.customerName=='undefined undefined'">N/A</h6>
                                    </div>
                                    <div class="flex-column">
                                        <label>Email</label>
                                        <h6>{{item.email}}</h6>
                                    </div>
                                    <div class="flex-column">
                                        <label>Modified At</label>
                                        <h6>{{item.addedSince | date:'dd/MM/yyyy' : 'UTC' }},<p>{{item.addedSince |
                                                date:'shortTime'}}</p>
                                        </h6>
                                    </div>
                                    <div class="flex-column">
                                        <label>Expire On</label>
                                        <h6>{{item.expiringOn | date:'dd/MM/yyyy' : 'UTC' }}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- cart-list-table-row end -->
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3"
                            *ngIf="cartList.length==0">
                            <app-no-result-found></app-no-result-found>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- deleteModalToggle start -->
<p-dialog [showHeader]="false" [(visible)]="deleteModalToggle" [style]="{width: '25vw'}" [baseZIndex]="10000"
    [modal]="true">
    <div class="body mt-3" id="deleteModalToggle">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="delete-image">
                    <img src="../../../../../assets/img/delete.png" />
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="confirm-msg">
                    <h6>Are You Sure?</h6>
                    <p>You will not be able to recover this product!</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="confirm-btns">
                    <button type="button" class="btn cancel-btn" (click)="deleteModalToggle = false">
                        Cancel
                    </button>
                    <button type="button" class="btn delete-confirm-btn" (click)="confirmDeleteCart()">
                        Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</p-dialog>
<!-- {/* // deleteModalToggle end */} -->