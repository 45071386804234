import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import * as mutation from 'graphql/mutations';
import * as query from 'graphql/queries';
import { uploadData } from 'aws-amplify/storage';
import amplifyconfig from 'amplifyconfiguration.json';
import * as _ from 'underscore';
import { ApiService } from '@services/api/api.service';
import { ROUTES } from '@/redirect-routes';
import { CommonService } from '@services/common/common.service';
import { Messages } from '@/Toaster-messages';
import { Categories, CategoryTypeList } from '@/common-list';
import slugify from '@sindresorhus/slugify';
import { CategoryType } from 'API';

@Component({
    selector: 'app-category-edit',
    templateUrl: './category-edit.component.html',
    styleUrl: './category-edit.component.scss'
})
export class CategoryEditComponent implements OnInit {
    categoryID: string;
    pk: string;

    public Editor = ClassicEditor;

    public onReady(editor: ClassicEditor) { }
    public onChange({ editor }: ChangeEvent) { }

    addCategoryForm: FormGroup;
    mainCategory: any = []
    mainCategoryList: any = [];
    subCategoryList: any = [];
    categoryData: any;
    access: any = {}
    selectedCatType: any = null
    categoryTypeList = CategoryTypeList
    categories = Categories
    defaultCategory: any = null
    visibleAttentionDialog: boolean = false;

    constructor(
        private _activatedroute: ActivatedRoute,
        private awsApi: ApiService,
        private common: CommonService,
        private _router: Router,
        private fb: FormBuilder,
        private messageService: MessageService
    ) {
        this.addCategoryForm = this.fb.group({
            catType: ['', [Validators.required]],
            catName: ['', [Validators.required, Validators.pattern(/^(?!\s*$)(?=.*[a-zA-Z])[a-zA-Z0-9\s]+$/)]],
            mainCategory: [''],
            categoryImg: ['', [Validators.required]],
            description: [''],
            webBannerImg: [''],
            mobBannerImg: [''],
            webBannerImgBottom: [''],
            mobBannerImgBottom: [''],
            sequenceNumber: ['']
        });
    }

    async getCategoryByID() {
        return await this.awsApi.client.graphql({
            query: query.categoryByID,
            variables: {
                pk: this.pk,
                id: { eq: this.categoryID }
            }
        });
    }

    ngOnInit(): void {
        this._activatedroute.queryParamMap.subscribe((params) => {
            this.categoryID = params.get('id') ?? '';
            this.pk = params.get('pk') ?? '';
            if (this.categoryID != '' && this.pk != '') {
                this.awsApi.loading.next(true);
                Promise.all([this.getAllMainCategory(), this.getCategoryByID()])
                    .then((response) => {
                        this.mainCategoryList = [
                            ...response[0].data.listCategories.items
                        ];
                        this.categoryData =
                            response[1].data.categoryByID.items[0];
                        this.addCategoryForm.patchValue({
                            catType: this.categoryData.categoryType == 'SUB' ? this.categories.sub : this.categories.main,
                            catName: this.categoryData.catName,
                            mainCategory: this.categoryData.mainCategoryID ? this.categoryData.mainCategoryID : '',
                            categoryImg: this.categoryData.categoryImg,
                            description: this.categoryData.description,
                            webBannerImg: this.categoryData.webBannerImg,
                            mobBannerImg: this.categoryData.mobBannerImg,
                            webBannerImgBottom: this.categoryData.webBannerImgBottom,
                            mobBannerImgBottom: this.categoryData.mobBannerImgBottom,
                            sequenceNumber: this.categoryData.sequenceNumber
                        });
                        this.defaultCategory = this.categoryData.categoryType == 'SUB' ? this.categories.sub : this.categories.main
                        this.awsApi.loading.next(false);
                    })
                    .catch((error) => {
                        this.awsApi.loading.next(false);
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: Messages.SOMETHING_WENT_WRONG
                        });
                    });
            }
        });
        this.startingFunction()
    }

    async startingFunction() {
        this.getRoleManagement()
    }

    async getRoleManagement() {
        try {
            await this.common.getPermissionsOfUser('Products', 'Manage Category');
            this.access = this.common.access;
            this.awsApi.loading.next(false);
        } catch (error) {
            this.awsApi.loading.next(false);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: Messages.SOMETHING_WENT_WRONG
            });
        }
    }

    get addCategoryFormControl() {
        return this.addCategoryForm.controls;
    }

    async getAllMainCategory() {
        return await this.awsApi.client.graphql({
            query: query.listCategories,
            variables: {
                pk: 'MAIN#'
            }
        });
    }

    onOptionChange() { }
    onUpdate() {
        this.awsApi.loading.next(true);
        if (this.defaultCategory == this.categories.sub &&
            this.addCategoryForm.valid &&
            this.addCategoryForm.value.mainCategory) {
            this.updateCategory()
        }
        else if (this.defaultCategory == this.categories.main &&
            this.addCategoryForm.valid) {
            this.updateCategory()
        }
        else {
            this.awsApi.loading.next(false);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: Messages.PLEASE_FILL_REQUIRED_FIELDS
            });
        }
    }

    async isCategoryAlreadyExists(): Promise<Boolean> {
        let res = await this.common.getAllCategory({}, ['catName', 'pk', 'slug', 'id'])
        if (res.length > 0 &&
            res.some((item) => item.slug === this.categoryData.slug && item.id !== this.categoryData.id)) {
            return true
        }
        return false
    }

    async sequenceNumberAlreadyExists(seqNumber, id): Promise<Boolean> {
        let sequenceNumbers = this.mainCategoryList
            .filter(item => item.id !== id)
            .map((item) => { return item.sequenceNumber; })
        return sequenceNumbers.includes(seqNumber)
    }

    async isAnyProductsActive(): Promise<Boolean> {
        await this.common.updateLatestProductsInLocalStore()
        let products = JSON.parse(localStorage.getItem("products"))
        let filteredProducts = this.categoryData.categoryType == 'MAIN' ?
            products.filter((item: any) => item.mainCategoryID === this.categoryData.id) :
            products.filter((item: any) => item.mainCategoryID === this.categoryData.mainCategoryID && item.subCategoryID === this.categoryData.id)
        return filteredProducts.length > 0
    }

    async deleteCategory(data) {
        return this.awsApi.client
            .graphql({
                query: mutation.deleteCategory,
                variables: {
                    input: {
                        ...data
                    }
                }
            })
    }

    async updateMainCategory(data) {
        return this.awsApi.client
            .graphql({
                query: mutation.updateCategory,
                variables: {
                    input: {
                        ...data
                    }
                }
            })
    }

    async createCategory(data) {
        return this.awsApi.client
            .graphql({
                query: mutation.createCategory,
                variables: {
                    input: {
                        ...data
                    }
                }
            })
    }

    async saveCategory() {
        let response;
        if (this.categoryData.categoryType == CategoryType.SUB) {
            let deleteFilter = {
                pk: this.categoryData.pk,
                slug: this.categoryData.slug
            }
            let str = this.addCategoryFormControl['catName'].value.toLowerCase()
            str = str.replace(/\s+/g, '');
            str = str.trim()

            let inputData: any = {
                catName:
                    this.addCategoryFormControl['catName'].value,
                catStatus: true,
                categoryImg:
                    this.addCategoryFormControl['categoryImg'].value,
                description:
                    this.addCategoryFormControl['description'].value,
                webBannerImg:
                    this.addCategoryFormControl['webBannerImg'].value,
                mobBannerImg:
                    this.addCategoryFormControl['mobBannerImg'].value,
                webBannerImgBottom:
                    this.addCategoryFormControl['webBannerImgBottom'].value,
                mobBannerImgBottom:
                    this.addCategoryFormControl['mobBannerImgBottom'].value,
                id: this.categoryData.id,
                slug: slugify(this.addCategoryFormControl['catName'].value),
                searchKey: str,
                sequenceNumber: this.addCategoryFormControl['sequenceNumber'].value ? parseInt(this.addCategoryFormControl['sequenceNumber'].value) : null
            };
            inputData['pk'] = 'SUB#' + this.addCategoryForm.value.mainCategory;
            inputData['categoryType'] = CategoryType.SUB;
            let mainCategory = _.find(this.mainCategoryList, (item) => {
                return item.id == this.addCategoryForm.value.mainCategory;
            });
            inputData['mainCategory'] = mainCategory.catName;
            inputData['mainCategoryID'] = mainCategory.id;

            response = await this.deleteCategory({ ...deleteFilter })
            response = (await this.createCategory({ ...inputData })).data.createCategory
        } else {
            let str = this.addCategoryFormControl['catName'].value.toLowerCase()
            str = str.replace(/\s+/g, '');
            str = str.trim()
            let updateData = {
                id: this.categoryData.id,
                pk: this.categoryData.pk,
                slug: this.categoryData.slug,
                catName:
                    this.addCategoryFormControl['catName'].value,
                catStatus: true,
                categoryImg:
                    this.addCategoryFormControl['categoryImg'].value,
                description:
                    this.addCategoryFormControl['description'].value,
                webBannerImg:
                    this.addCategoryFormControl['webBannerImg'].value,
                mobBannerImg:
                    this.addCategoryFormControl['mobBannerImg'].value,
                webBannerImgBottom:
                    this.addCategoryFormControl['webBannerImgBottom'].value,
                mobBannerImgBottom:
                    this.addCategoryFormControl['mobBannerImgBottom'].value,
                searchKey: str,
                sequenceNumber: parseInt(this.addCategoryFormControl['sequenceNumber'].value)
            }
            response = (await this.updateMainCategory({ ...updateData })).data.updateCategory
        }
        try {
            if (response) {
                this.awsApi.loading.next(false);
                this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Category updated successfully!'
                });
                this._router.navigate(['/list-category']);
            } else {
                this.awsApi.loading.next(false);
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: Messages.SOMETHING_WENT_WRONG
                });
            }
        } catch (error) {
            this.awsApi.loading.next(false);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: Messages.SOMETHING_WENT_WRONG
            });
        }
    }

    async updateCategory() {
        let seqNumberValid: boolean = true
        try {
            if (this.addCategoryForm.valid) {
                if (this.categoryData.categoryType == CategoryType.SUB) {
                    this.mainCategory = _.find(this.mainCategoryList, (item) => {
                        return (
                            item.id == this.addCategoryForm.value.mainCategory
                        );
                    });
                }
                else {
                    const seqNumber = this.addCategoryFormControl['sequenceNumber'].value ? parseInt(this.addCategoryFormControl['sequenceNumber'].value) : null;
                    if (this.categoryData.categoryType == CategoryType.MAIN && seqNumber !== null) {
                        seqNumberValid = !isNaN(seqNumber) && seqNumber > 0 && !(await this.sequenceNumberAlreadyExists(seqNumber, this.categoryData.id));
                    }
                }
                if (seqNumberValid) {
                    if (!await this.isCategoryAlreadyExists()) {
                        if (!await this.isAnyProductsActive()) {
                            await this.saveCategory()
                        }
                        else {
                            this.showAttentionDialog()
                        }
                    }
                    else {
                        this.awsApi.loading.next(false);
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: Messages.CATEGORY_ALREADY_EXISTS
                        });
                    }
                }
                else {
                    this.awsApi.loading.next(false);
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: Messages.INVALID_SEQUENCE_NUMBER
                    });
                }
            }
        } catch (error) {
            this.awsApi.loading.next(false);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: Messages.SOMETHING_WENT_WRONG
            });

        }
    }

    async fileSave(event: any, type: any) {
        this.awsApi.loading.next(true);
        let isImageValid
        if (event.target instanceof HTMLInputElement && event.target.files) {
            let file = event.target.files[0];
            let isValid = await this.common.isValidFileType(event)
            if (!isValid) { return; }
            isImageValid = type == 'web-banner' ?
                await this.common.validateImageDimensions(file, 'category', 'web') : type == 'mobile-banner' ?
                    await this.common.validateImageDimensions(file, 'category', 'mobile') : true
            if (!isImageValid) {
                this.awsApi.loading.next(false);
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: Messages.INVALID_IMAGE_SIZE
                });
                return;
            }
            try {
                this.awsApi.loading.next(false);
                let imageUrl = await this.common.getS3Url(file)
                if (type == 'catImage') {
                    this.addCategoryForm.patchValue({
                        categoryImg: imageUrl
                    });
                } else if (type == 'web-banner') {
                    this.addCategoryForm.patchValue({
                        webBannerImg: imageUrl
                    });
                } else if (type == 'mobile-banner') {
                    this.addCategoryForm.patchValue({
                        mobBannerImg: imageUrl
                    });
                } else if (type == 'web-banner-bottom') {
                    this.addCategoryForm.patchValue({
                        webBannerImgBottom: imageUrl
                    });
                } else if (type == 'mobile-banner-bottom') {
                    this.addCategoryForm.patchValue({
                        mobBannerImgBottom: imageUrl
                    });
                }
            } catch (error) {
                this.awsApi.loading.next(false);
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: Messages.SOMETHING_WENT_WRONG
                });
            }
        }
    }

    redirectToCategoryListPage() {
        this._router.navigate([ROUTES.CATEGORY_LIST]);
    }

    deleteUrl(type) {
        if (type == 'catImage') {
            this.addCategoryForm.patchValue({
                categoryImg: ''
            });
            const fileInput = document.getElementById('category-image-upload') as HTMLInputElement;
            if (fileInput) {
                fileInput.value = '';
            }
        } else if (type == 'web-banner') {
            this.addCategoryForm.patchValue({
                webBannerImg: ''
            });
            const fileInput = document.getElementById('web-banner-image-upload') as HTMLInputElement;
            if (fileInput) {
                fileInput.value = '';
            }
        }
        else if (type == 'mobile-banner') {
            this.addCategoryForm.patchValue({
                mobBannerImg: ''
            });
            const fileInput = document.getElementById('mobile-banner-image-upload') as HTMLInputElement;
            if (fileInput) {
                fileInput.value = '';
            }
        }
        else if (type == 'web-banner-bottom') {
            this.addCategoryForm.patchValue({
                webBannerImgBottom: ''
            });
            const fileInput = document.getElementById('web-banner-image-bottom-upload') as HTMLInputElement;
            if (fileInput) {
                fileInput.value = '';
            }
        } else if (type == 'mobile-banner-bottom') {
            this.addCategoryForm.patchValue({
                mobBannerImgBottom: ''
            });
            const fileInput = document.getElementById('mobile-banner-image-bottom-upload') as HTMLInputElement;
            if (fileInput) {
                fileInput.value = '';
            }
        }
    }

    showAttentionDialog() {
        this.awsApi.loading.next(false);
        this.visibleAttentionDialog = true;
    }

    closeAttentionDialog() {
        this.awsApi.loading.next(false);
        this.visibleAttentionDialog = false;
    }

    async updateProducts() {
        try {
            this.visibleAttentionDialog = false;
            let data = this.categoryData.categoryType == CategoryType.MAIN ? {
                'catType': 'MAIN',
                'oldrecord': {
                    'mainCategoryID': this.categoryData.id,
                    'mainCategoryName': this.categoryData.catName
                }, 'newrecord': {
                    'mainCategoryID': this.categoryData.id,
                    'mainCategoryName': this.addCategoryFormControl['catName'].value
                }
            } : {
                'catType': 'SUB',
                'oldrecord': {
                    'mainCategoryID': this.categoryData.mainCategoryID,
                    'mainCategoryName': this.categoryData.mainCategory,
                    'subCategoryID': this.categoryData.id,
                    'subCategoryName': this.categoryData.catName
                },
                'newrecord': {
                    'mainCategoryID': this.mainCategory.id,
                    'mainCategoryName': this.mainCategory.catName,
                    'subCategoryID': this.categoryData.id,
                    'subCategoryName': this.addCategoryFormControl['catName'].value
                }
            }
            this.common.categoryEditInProducts(data, await this.common.getHeader()).subscribe(async (result) => {
                if (result.status) {
                    this.awsApi.loading.next(false);
                    await this.saveCategory()
                }
                else {
                    this.awsApi.loading.next(false);
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: Messages.SOMETHING_WENT_WRONG
                    });
                }
            })
        } catch (error) { }
    }
}
