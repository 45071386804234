import { ROUTES } from '@/redirect-routes';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import * as query from 'graphql/queries';
import * as mutation from 'graphql/mutations';
import { MessageService } from 'primeng/api';
import { CommonService } from '@services/common/common.service';
import { CategoryType, productStatus } from 'API';
import { Messages } from '@/Toaster-messages';

interface City {
    name: string,
    code: string
}
@Component({
    selector: 'app-category-list',
    templateUrl: './category-list.component.html',
    styleUrl: './category-list.component.scss'
})
export class CategoryListComponent implements OnInit {
    cities!: City[];
    selectedCities!: City[];
    position: any;
    displayFilterModalToggle = false;
    deleteModalToggle: any;
    filterObject: any = {}
    searchKey: any
    searchText: any = ''
    categoryList: any = [];
    categoryRes: any = []
    copyCategoryList: any = []
    mainCategoryList: any = [];
    subCategoryList: any = [];
    deleteCategory: any
    filterApplied: boolean = false
    filterForm = this.formBuilder.group({
        mainCategory: [],
        subCategory: [],
        showMainCategories: [false]
    })
    access: any = {}

    constructor(
        private router: Router,
        private awsApi: ApiService,
        private formBuilder: FormBuilder,
        private messageService: MessageService,
        private common: CommonService,
    ) { }

    ngOnInit(): void {
        this.position = 'right';
        this.awsApi.loading.next(true);
        this.startingFunction()
    }

    async startingFunction() {
        try {
            await this.common.getPermissionsOfUser('Products', 'Manage Category');
            this.access = this.common.access;
            this.awsApi.loading.next(false);
        } catch (error) {
            this.awsApi.loading.next(false);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: Messages.SOMETHING_WENT_WRONG
            });
        }
        let fieldsToFetch = ['id', 'pk', 'slug', 'categoryImg', 'catName', 'description', 'categoryType', 'mainCategory', 'createdAt', 'mainCategoryID']

        Promise.all([this.common.getAllCategory(this.filterObject, fieldsToFetch), this.getAllMainCategory()]).then((result: any) => {
            this.awsApi.loading.next(true);
            this.categoryList = result[0].sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
            this.copyCategoryList = this.categoryList
            this.categoryRes = this.categoryList
            this.mainCategoryList = result[1].data.listCategories.items
            this.awsApi.loading.next(false);
        }).catch((error) => {
            this.awsApi.loading.next(false);
        });
    }

    getAllMainCategory() {
        return this.awsApi.client.graphql({
            query: query.listCategories,
            variables: {
                pk: 'MAIN#'
            }
        });
    }

    cancelFilter() {
        this.displayFilterModalToggle = false;
        this.filterForm.reset()
        this.filterForm.patchValue({
            showMainCategories: false
        })
        this.filterApplied = false
        this.ngOnInit()
    }

    applyFilter() {
        this.copyCategoryList = this.categoryRes
        let categories = []
        this.awsApi.loading.next(true);
        if (this.filterForm.controls.mainCategory.value?.catName && !this.filterForm.controls.subCategory.value?.catName) {
            this.filterApplied = true
            let mainCategory = this.copyCategoryList.filter((item: any) => {
                return item.pk == 'MAIN#' && item.catName == this.filterForm.controls.mainCategory.value.catName
            })
            categories = this.copyCategoryList.filter((item1: any) => {
                return item1.pk == 'SUB#' + mainCategory[0].id &&
                    item1.categoryType == CategoryType.SUB
            })
        }
        if (this.filterForm.controls.subCategory.value?.catName) {
            this.filterApplied = true
            let mainCategory = this.copyCategoryList.filter((item: any) => {
                return item.pk == 'MAIN#' && item.catName == this.filterForm.controls.mainCategory.value.catName
            })
            categories = this.copyCategoryList.filter((item1: any) => {
                return item1.pk == 'SUB#' + mainCategory[0].id &&
                    item1.catName == this.filterForm.controls.subCategory.value?.catName &&
                    item1.categoryType == CategoryType.SUB
            })
        }
        if (this.filterForm.controls.showMainCategories.value == true) {
            this.filterApplied = true
            categories = this.copyCategoryList.filter((item: any) => {
                return item.pk == 'MAIN#'
            })
        }
        this.categoryList = this.filterApplied ? categories : this.copyCategoryList
        this.displayFilterModalToggle = false;
        this.awsApi.loading.next(false);
    }

    showFilterModalDialog(position: string) {
        this.position = position;
        this.displayFilterModalToggle = true;
    }

    search(searchText) {
        this.searchText = searchText
        if (this.searchText != '') {
            this.searchKey = this.searchText.toLowerCase()
            this.searchKey = this.searchKey.replace(/\s{2,}/g, ' ');
            this.searchKey = (this.searchKey.trim()).replace(/\s+/g, '')
            this.filterObject['searchKey'] = { contains: this.searchKey }
            this.ngOnInit()
        } else {
            delete this.filterObject['searchKey']
            delete this.searchKey
            this.searchText
            this.ngOnInit()
        }
    }

    getAllSubCategory(mainCategoryID) {
        return this.awsApi.client.graphql({
            query: query.listCategories,
            variables: {
                pk: 'SUB#' + mainCategoryID
            }
        });
    }

    onSelectMainCategory() {
        this.subCategoryList = [];
        this.filterForm.controls.subCategory.reset()
        this.getAllSubCategory(this.filterForm.controls.mainCategory.value.id)
            .then((result) => {
                this.subCategoryList = result.data.listCategories.items;
            })
            .catch((error) => {
            });
    }

    closeFilterModalDialog() {
        this.displayFilterModalToggle = false;
    }
    showDeleteCategoryModalDialog(category: any) {
        this.deleteModalToggle = true;
        this.deleteCategory = category;
    }
    redirectToAddCategoryPage() {
        this.router.navigate([ROUTES.ADD_CATEGORY]);
    }

    async delCategory(data) {
        return this.awsApi.client
            .graphql({
                query: mutation.deleteCategory,
                variables: {
                    input: {
                        ...data
                    }
                }
            })
    }

    async confirmDeleteCategory() {
        try {
            let promiseDeleteArray = []
            this.deleteModalToggle = false;
            let filter = {}
            filter = this.deleteCategory.categoryType == 'MAIN' ?
                { mainCategoryID: { eq: this.deleteCategory.id } } :
                { subCategoryID: { eq: this.deleteCategory.id }, mainCategoryID: { eq: this.deleteCategory.mainCategoryID } }
            filter['status'] = { ne: productStatus.deleted }
            if (!await this.common.isAnyProductAvailable(filter, 'category')) {
                let data = {
                    pk: this.deleteCategory.pk,
                    slug: this.deleteCategory.slug
                }
                promiseDeleteArray.push(this.delCategory({ ...data }))
                if (this.deleteCategory.categoryType == 'MAIN') { // deleting all subcategories under this main category
                    let subCategories = (await this.getAllSubCategory(this.deleteCategory.id)).data.listCategories.items
                    if (subCategories.length > 0) {
                        subCategories.forEach(subCategory => {
                            let data = {
                                pk: subCategory.pk,
                                slug: subCategory.slug
                            }
                            promiseDeleteArray.push(this.delCategory({ ...data }))
                        });
                    }
                }
                Promise.all(promiseDeleteArray)
                    .then((response) => {
                        this.awsApi.loading.next(false);
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Success',
                            detail: 'Category deleted successfully!'
                        });
                        this.ngOnInit();
                    })
                    .catch((error) => {
                        this.awsApi.loading.next(false);
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: Messages.SOMETHING_WENT_WRONG
                        });
                    });
            }
        } catch (error) { }
    }

    redirectToEditCategoryPage(category: any) {
        this.router.navigate([`edit-category`], { queryParams: { id: category.id, pk: category.pk, slug: category.slug } });
    }

    removeHtmlTags(description): String {
        return ((description.replace(/<\/?[^>]+(>|$)/g, "")).replace(/&nbsp;/g, " ")).replace(/&amp;/g, "&");
    }

    onShow() {
        this.filterApplied = this.filterForm.controls.showMainCategories.value
        this.filterForm.controls.mainCategory.reset()
        this.filterForm.controls.subCategory.reset()
    }
}