import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES } from '@/redirect-routes';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MessageService } from 'primeng/api';
import { FormBuilder, Validators } from '@angular/forms';
import * as query from 'graphql/queries';
import * as mutation from 'graphql/mutations';
import { ApiService } from '@services/api/api.service';
import { uploadData } from 'aws-amplify/storage';
import amplifyconfig from 'amplifyconfiguration.json';
import { v4 as uuidv4 } from 'uuid';
import { CommonService } from '@services/common/common.service';
import { Messages } from '@/Toaster-messages';
import { PromoStatusList, PromoStsList } from '@/common-list';

@Component({
  selector: 'app-promocode-add',
  templateUrl: './promocode-add.component.html',
  styleUrl: './promocode-add.component.scss'
})
export class PromocodeAddComponent implements OnInit {
  discountType: any = [
    { name: "Percentage", value: "percentage" },
    { name: "Amount", value: "amount" }
  ];
  selectedUsers: any = []
  selectedCustomer: any;
  selectedProduct: any;
  selectedProducts: any[] = [];
  selectedItems: any[] = [];
  selectedCategory: any = []
  access: any = {}
  minDate: string;
  selectedDiscountType: string | null = null;
  processingAdd = false
  statusList = PromoStsList
  defaultStatus: string | null = 'DRAFT';
  promoCodeForm = this.fb.group({
    promoImage: [''],
    promoURL: [''],
    code: ['', [Validators.required, Validators.pattern(/^(?=.*[a-zA-Z])[a-zA-Z\d\s\S]+$/)]],
    discountType: ['', [Validators.required]],
    percentageValue: ['', [Validators.required]],
    redemptionType: ['', [Validators.required]],
    validationFrom: ['', [Validators.required]],
    validationTo: ['', [Validators.required]],
    description: [''],
    terms: [''],
    appliedTo: [''],
    specificCustomer: [[]],
    specificProduct: [''],
    status: ['DRAFT']
  })

  public Editor = ClassicEditor;
  public onReady(editor: ClassicEditor) { }
  public onChange({ editor }: ChangeEvent) { }

  constructor(private common: CommonService, private _router: Router, private awsApi: ApiService, private fb: FormBuilder, private messageService: MessageService) { }

  ngOnInit(): void {
    const today = new Date();
    this.minDate = today.toISOString().split('T')[0];
    this.getRoleManagement()
    this.startingFunction()
    this.promoCodeForm.get('validationFrom')?.valueChanges.subscribe(date => {
      this.onValidationFromDateChange(date);
    });
  }

  async startingFunction() {
    let items = [];
    let nextToken = null;
    do {
      const response = await this.awsApi.client.graphql({
        query: query.listUsers,
        variables: {
          limit: 650,
          nextToken: nextToken,
          sortDirection: null
        }
      })
      items = items.concat(response.data.listUsers.items);
      nextToken = response.data.listUsers.nextToken;
    } while (nextToken !== null)
    this.selectedUsers = items
    this.selectedUsers.forEach(user => {
      user.fullName = user.firstName + ' ' + user.lastName;
    });
    let categories = await this.common.getAllCategory({}, ['pk', 'id', 'slug', 'catName'])
    this.selectedCategory = categories.length > 0 ? categories : []
    this.promoCodeForm.controls.appliedTo.valueChanges.subscribe(value => {
      this.promoCodeForm.controls.specificCustomer.reset();
      this.promoCodeForm.controls.specificProduct.reset();
      this.selectedItems = [];
    });
    this.promoCodeForm.controls.specificCustomer.valueChanges.subscribe(value => {
      this.selectedItems = Array.isArray(value) ? value : [];
    });
    this.promoCodeForm.controls.specificProduct.valueChanges.subscribe(value => {
      this.selectedItems = Array.isArray(value) ? value : [];
    });
  }

  async getRoleManagement() {
    this.awsApi.loading.next(true);
    try {
      await this.common.getPermissionsOfUser('Promo Codes');
      this.access = this.common.access;
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  async fileSave(event: any) {
    this.awsApi.loading.next(true);
    if (event.target instanceof HTMLInputElement && event.target.files) {
      let file = event.target.files[0];
      let isValid = await this.common.isValidFileType(event)
      if (!isValid) { return; }
      try {
        const result = await uploadData({
          key: file.name,
          data: file,
          options: {
            accessLevel: 'guest'
          }
        }).result;
        this.awsApi.loading.next(false);
        let imageUrl = await this.common.getS3Url(file)
        this.promoCodeForm.patchValue({
          promoURL: imageUrl,
          promoImage: result.key
        });
      } catch (error) {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      }
    }
  }

  redirectToPromocodeListPage() {
    this._router.navigate([ROUTES.PROMOCODE_LIST]);
  }

  deleteUrl(type: string) {
    if (type === 'promo' && this.promoCodeForm) {
      this.promoCodeForm.patchValue({
        promoImage: null,
        promoURL: ''
      });
      const fileInput = document.getElementById('promocode-image-upload') as HTMLInputElement;
      if (fileInput) {
        fileInput.value = '';
      }
    }
  }

  async savePromoCodes() {
    if (this.promoCodeForm.valid) {
      this.processingAdd = true;
      let id = uuidv4();
      let discountValue;
      let advancedSettings;
      let userNameSet = new Set();
      let catNameSet = new Set();
      let promocodes = [];
      if (this.promoCodeForm.controls.appliedTo.value === 'products' &&
        this.promoCodeForm.controls.discountType.value === 'amount') {
        this.processingAdd = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.INVALID_DISCOUNT_TYPE
        });
        return;
      }
      promocodes = await this.common.getPromoCodes({}, ['code'])
      let newPromoCode = ((this.promoCodeForm.controls.code.value).toLowerCase()).replace(/\s+/g, '');
      if (promocodes.length > 0 && promocodes.some(promo => ((promo.code).toLowerCase()).replace(/\s+/g, '') === newPromoCode)) {
        this.processingAdd = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'This promo code already exists!'
        });
        return;
      }
      discountValue = this.promoCodeForm.controls.percentageValue.value;
      if (this.selectedItems.length != 0) {
        if (this.promoCodeForm.controls.appliedTo.value === 'customers') {
          for (let i = 0; i < this.selectedItems.length; i++) {
            const item = this.selectedItems[i];
            if (!userNameSet.has(item.id)) {
              userNameSet.add(item.id);
            }
          }
          advancedSettings = {
            appliedTo: 'customers',
            specificType: Array.from(userNameSet).map(id => {
              const customer = this.selectedItems.find(item => item.id === id);
              return {
                name: customer.firstName + ' ' + customer.lastName,
                id: customer.id
              };
            })
          };
        }
        if (this.promoCodeForm.controls.appliedTo.value === 'products') {
          for (let i = 0; i < this.selectedItems.length; i++) {
            const item = this.selectedItems[i];
            if (!catNameSet.has(item.id)) {
              catNameSet.add(item.id);
            }
          }
          advancedSettings = {
            appliedTo: 'products',
            specificType: Array.from(catNameSet).map(id => {
              const category = this.selectedItems.find(item => item.id === id);
              return {
                name: category.catName,
                id: category.id
              };
            })
          };
        }
      }

      let str = this.promoCodeForm.controls.code.value.toLowerCase()
      str = str.replace(/\s{2,}/g, ' ');
      str = (str.trim()).replace(/\s+/g, '')
      let data = {
        pk: 'PROMO#',
        id: id,
        promoImage: this.promoCodeForm.controls.promoImage.value,
        promoURL: this.promoCodeForm.controls.promoURL.value,
        code: this.promoCodeForm.controls.code.value,
        discount: {
          type: this.promoCodeForm.controls.discountType.value,
          value: discountValue
        },
        redemptionType: this.promoCodeForm.controls.redemptionType.value,
        searchKey: str,
        validFrom: new Date(this.promoCodeForm.controls.validationFrom.value).toISOString(),
        validTo: new Date(this.promoCodeForm.controls.validationTo.value).toISOString(),
        description: this.promoCodeForm.controls.description.value,
        terms: this.promoCodeForm.controls.terms.value,
        advancedSetting: advancedSettings,
        status: this.promoCodeForm.controls.status.value
      };
      this.awsApi.client.graphql({
        query: mutation.createPromoCodes,
        variables: {
          input: { ...data }
        }
      })
        .then((response) => {
          this.awsApi.loading.next(false);
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Promocode created successfully!'
          });
          this.processingAdd = false;
          this._router.navigate(['/list-promocode']);
        })
        .catch((error) => {
          this.awsApi.loading.next(false);
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: Messages.SOMETHING_WENT_WRONG
          });
        });
    } else {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.PLEASE_FILL_REQUIRED_FIELDS
      });
    }
  }

  onSelectDiscountType() {
    this.promoCodeForm.controls.percentageValue.reset()
  }

  clearSelection(item: any): void {
    this.selectedItems = this.selectedItems.filter(i => i !== item);
  }

  preventE(event) {
    const invalidChars = ['e', 'E', '+', '-', '.'];
    if (invalidChars.includes(event.key)) {
      event.preventDefault();
    }
  }

  onValidationFromDateChange(date) {
    if (date) {
      this.promoCodeForm.patchValue({
        validationTo: date.split('T')[0]
      })
    }
  }

  getValidToDateMin() {
    const date = this.promoCodeForm.controls.validationFrom.value ? new Date(this.promoCodeForm.controls.validationFrom.value).toISOString() : new Date().toISOString();
    return date.split('T')[0]
  }

}