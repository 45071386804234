<section id="returnRefundReportListSec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 my-auto"
                id="return-refund-report-list">
                <div class="card m-2">
                    <div class="card-body">
                        <!-- return-refund-report-list-heading-row start -->
                        <div class="row return-refund-report-list-heading-row">
                            <div class="col-xxl-5 col-xl-5 col-lg-4 col-md-4 col-sm-12 col-xs-12 my-auto">
                                <div class="text-center text-md-left mt-2 mt-md-0">
                                    <div class="d-flex justify-content-start align-items-center">
                                        <i class="fas fa-chevron-left" (click)="redirectToReportListPage()"></i>
                                        <h6>Order Return and Refund Report</h6>
                                        <p class="mb-2 mb-md-0 mt-2" *ngIf="returnAndRefundList?.length > 0">
                                            {{returnAndRefundList?.length}} Results Found
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-7 col-xl-7 col-lg-8 col-md-8 col-sm-12 col-xs-12 my-auto">
                                <div class="has-search-d-flex justify-content-center justify-content-md-end align-items-start align-items-md-center flex-nowrap flex-md-wrap"
                                    [formGroup]="filterForm">
                                    <div *ngIf="!filterApplied">
                                        <button type="button" class="btn filter-btn me-3 d-none d-md-block" (click)="
                                                                                showFilterModalDialog('right')">
                                            <span class="material-symbols-outlined me-1">
                                                filter_alt
                                            </span>
                                            Filter
                                        </button>
                                        <button type="button" class="btn filter-btn me-3 d-block d-md-none"
                                            (click)="showFilterModalDialog('right')">
                                            <span class="material-symbols-outlined">
                                                filter_alt
                                            </span>
                                        </button>
                                    </div>
                                    <div *ngIf="filterApplied">
                                        <button type="button" class="btn after-filter-btn me-3 d-none d-md-block"
                                            (click)="showFilterModalDialog('right')">
                                            <i class="fas fa-filter"></i>
                                            Filter
                                        </button>
                                        <button type="button" class="btn after-filter-btn me-3 d-block d-md-none"
                                            (click)="showFilterModalDialog('right')">
                                            <i class="fas fa-filter"></i>
                                        </button>
                                    </div>
                                    <div>
                                        <button type="button" class="btn export-btn d-none d-md-block"
                                            (click)="exportToExcel()" *ngIf="returnAndRefundList?.length>0">
                                            <i class="fas fa-file-export me-1"></i> Export
                                        </button>
                                        <button type="button" class="btn export-btn d-block d-md-none"
                                            (click)="exportToExcel()" *ngIf="returnAndRefundList?.length>0">
                                            <i class="fas fa-file-export me-1"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-1 ms-2 me-2" *ngIf="!filterApplied && returnAndRefundList?.length == 0">
                    <div class="card-body p-0 order-selection">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <h2>Please select a filter to view the report</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- return-refund-report-list-heading-row end -->
                <div class="card mb-1 ms-2 me-2 scroll-container" (window:scroll)="onScroll()">
                    <div class="card-body p-0">
                        <!-- return-refund-report-list-table-row start -->
                        <div class="row return-refund-report-list-table-row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                *ngIf="returnAndRefundList?.length>0">
                                <div class="table-row table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Return Ref</th>
                                                <th>Customer Name</th>
                                                <th>Return Type</th>
                                                <th>Return Date</th>
                                                <th>Product Name</th>
                                                <th>Qty</th>
                                                <th class="text-end">Unit Price</th>
                                                <th class="text-end">Total</th>
                                                <th class="text-center">Refund Method</th>
                                                <th class="text-end">Refund Amount</th>
                                                <th class="text-center">Current Status</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let item of returnDataList">
                                            <tr>
                                                <td>{{item.returnRefundActivity?.returnReference}}</td>
                                                <td>
                                                    <span
                                                        *ngIf="item.userName && item.userName != ''">{{item.userName}}</span>
                                                    <span *ngIf="!item.userName || item.userName == ''">N/A</span>
                                                </td>
                                                <td>{{item.returnRefundActivity?.returnType}}</td>
                                                <td>{{item.returnRefundActivity?.createdAt | date:'dd/MM/yyyy' : 'UTC' }}</td>
                                                <td>{{item.itemDetail?.productName}}</td>
                                                <td>{{item.returnRefundActivity?.quantity}}</td>
                                                <td class="text-end">{{item.itemDetail?.price | number: '.2'}}</td>
                                                <td class="text-end">{{item.totalPrice | number: '.2'}}</td>
                                                <td class="text-center">
                                                    <span
                                                        *ngIf="item.returnRefundActivity?.refundInfo?.paymentMethod !=returnPaymentMethodList[2]['value']">
                                                        {{item.returnRefundActivity?.refundInfo?.paymentMethod |
                                                        formatDocType}}
                                                    </span>
                                                    <span
                                                        *ngIf="item.returnRefundActivity?.refundInfo?.paymentMethod ==returnPaymentMethodList[2]['value']">
                                                        {{returnPaymentMethodList[2]['title']}}
                                                    </span>
                                                    <span *ngIf="!item.returnRefundActivity?.refundInfo?.paymentMethod">
                                                        N/A
                                                    </span>
                                                </td>
                                                <td class="text-end">
                                                    <span *ngIf="item.returnRefundActivity?.refundInfo?.refundAmount">
                                                        {{item.returnRefundActivity?.refundInfo?.refundAmount |
                                                        number: '.2'}}
                                                    </span>
                                                    <span *ngIf="!item.returnRefundActivity?.refundInfo?.refundAmount">
                                                        N/A
                                                    </span>
                                                </td>
                                                <td class="text-center">
                                                    <span
                                                        *ngIf="item.returnRefundActivity?.status==returnStatuses.shipped">
                                                        Item Picked
                                                    </span>
                                                    <span
                                                        *ngIf="item.returnRefundActivity?.status!=returnStatuses.shipped">
                                                        {{item.returnRefundActivity?.status | formatDocType}}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3"
                                *ngIf="page=='1' && !loader && returnAndRefundList.length==0">
                                <app-no-result-found></app-no-result-found>
                            </div>
                        </div>
                        <!-- return-refund-report-list-table-row end -->
                    </div>
                </div>
                <div *ngIf="loading && returnDataList.length>10">Loading...</div>
            </div>
        </div>
    </div>
</section>
<!-- displayFilterModalToggle start -->
<p-dialog [draggable]="false" [(visible)]="displayFilterModalToggle" [position]="position" [modal]="true"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false" [showHeader]="false"
    [style]="{width: '34vw', minHeight: '100%', maxHeight: '100%', margin: '0'}"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}">
    <div id="displayFilterModalToggle">
        <div class="modal-header">
            <h5 class="modal-title">Filter</h5>
            <i class="fas fa-times" (click)="closeFilterModalDialog()"></i>
        </div>
        <div class="body mt-3" [formGroup]="filterForm">
            <div class="row">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Status</label>
                    <p-multiSelect display="chip" [options]="returnStatusList" formControlName="status"
                        optionLabel="valueToShow" placeholder="Select Status" />
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Main Category</label>
                    <select class="form-select" [(ngModel)]="selectedMainCategory" formControlName="mainCategory"
                        (change)="onSelectMainCategory()">
                        <option [ngValue]="null">Select Main Category</option>
                        <option *ngFor="let item of mainCategoryList" [ngValue]="item">{{ item.catName }}
                        </option>
                    </select>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2"
                    *ngIf="filterForm.get('mainCategory').value">
                    <label>Sub Category</label>
                    <select class="form-select" formControlName="subCategory">
                        <option [ngValue]="null">Select Sub Category</option>
                        <option *ngFor="let item of subCategoryList" [ngValue]="item">{{ item.catName }}
                        </option>
                    </select>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Returned On</label>
                    <p-calendar [maxDate]="maxDate" formControlName="returnedOn" [iconDisplay]="'input'"
                        [showIcon]="true" inputId="icondisplay" selectionMode="range"
                        placeholder="From-To"></p-calendar>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="d-flex justify-content-end gap-2">
            <p-button label="Cancel" styleClass="filter-cancel-btn" (click)="cancelFilter()"></p-button>
            <p-button label="Apply" styleClass="filter-apply-btn" (click)="applyFilter()"></p-button>
        </div>
    </ng-template>
</p-dialog>
<!-- displayFilterModalToggle end -->