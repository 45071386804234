import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import { NewUser, UserAuthService } from '@services/user-auth/user-auth.service';
import { MessageService } from 'primeng/api';
import * as mutation from 'graphql/mutations';
import { Messages } from '@/Toaster-messages';
import { environment } from 'environments/environment';
import { AppService } from '@services/app.service';
import { CommonService } from '@services/common/common.service';
import { Status } from 'API';
import * as query from 'graphql/queries';
import Cookies from 'js-cookie';

@Component({
    selector: 'app-user-signin',
    templateUrl: './user-signin.component.html',
    styleUrl: './user-signin.component.scss'
})
export class UserSigninComponent implements OnInit, OnDestroy {
    password: any;
    show = false;
    filterObject: any = {}
    old_password: string = '';
    c_password: string = '';
    c_new_password: string = '';
    toggle1: boolean = false;
    toggle2: boolean = false;
    idToken: any
    usersList: any
    user: any
    rememberMe: boolean = false

    signinForm = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(6)]]
    });

    hide = true;
    loader = false;
    submitted = false;
    processingSignIn: boolean = false;
    emailInp: any
    passwordInp: any
    passwordInputType = 'password';
    showPassword: boolean = false;
    primaryLogo: any = ''

    togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
        this.passwordInputType = this.showPassword ? 'text' : 'password';
    }

    get emailInput() {
        return this.signinForm.get('email');
    }
    get passwordInput() {
        return this.signinForm.get('password');
    }

    constructor(
        private awsApi: ApiService,
        public auth: UserAuthService,
        private _router: Router,
        private common: CommonService,
        private fb: FormBuilder,
        private appService: AppService,
        private messageService: MessageService,
    ) { }

    async ngOnInit(): Promise<void> {
        if (Cookies.get('username') && Cookies.get('password')) {
            this.signinForm.patchValue({
                email: Cookies.get('username'),
                password: Cookies.get('password')
            })
            this.rememberMe = true
        }
        try {
            this.appService.logout();
            window.localStorage.clear();
            let companyDetails = (await this.getCompanyDetails()).data.listCompanyDetails.items
            if (companyDetails.length > 0) {
                this.primaryLogo = companyDetails[0].primaryLogo.url
            }
        } catch (error) {
            this.awsApi.loading.next(false);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: Messages.PARTIAL_DATA_ERROR
            })
        }
    }

    async getCompanyDetails() {
        return await this.awsApi.client.graphql({
            query: query.listCompanyDetails,
            authMode: 'iam'
        })
    }

    isUserActive(): boolean {
        let userActive: boolean
        this.user = this.usersList.filter((item) => {
            if (item.role && item.status && item.email) {
                return item.role != 'CUSTOMER' && item.status == 'ACTIVE' && item.email == this.emailInp
            }
        });
        if (this.user.length > 0) {
            userActive = true
        }
        else {
            userActive = false
        }
        return userActive
    }

    saveUserLoginTime() {
        let data = {
            pk: 'USER#',
            id: this.user[0].id,
            email: this.emailInp,
            loginAt: new Date().toISOString()
        }
        this.awsApi.client.graphql({
            query: mutation.updateUser,
            variables: {
                input: {
                    ...data
                }
            }
        }).then((response) => {
            this.awsApi.loading.next(false);
        }).catch((error) => {
            this.awsApi.loading.next(false);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: Messages.SOMETHING_WENT_WRONG
            });
        })
    }

    isMultiFactorAuthEnabled(): boolean {
        return this.user[0]?.multiFactorAuth ? true : false
    }

    async signIn() {
        this.awsApi.loading.next(true);
        this.submitted = true;
        if (this.signinForm.valid) {
            this.usersList = await this.common.listUsers({}, ['id', 'role', 'status', 'email', 'password', 'emailVerified', 'firstName', 'lastName', 'phoneNumber', 'role', 'profileImageUrl'], true)
            this.emailInp = this.emailInput?.value
            this.passwordInp = this.passwordInput?.value
            if (this.isUserActive()) {
                this.processingSignIn = true;
                this.auth
                    .signIn({
                        username: this.emailInput!.value!,
                        password: this.passwordInput?.value || undefined
                    })
                    .then((response) => {
                        if (
                            response.isSignedIn &&
                            response.nextStep['signInStep'] === 'DONE'
                        ) {
                            this.auth.fetchUserAttributes().then((user) => {
                                localStorage.setItem("userId", user.sub)
                                this.common.saveAccessToken()
                                this.saveUserLoginTime()

                                if (user['custom:role'] != 'CUSTOMER') {
                                    localStorage.setItem('userName', user['custom:firstName'])
                                    localStorage.setItem('phone', user['phone_number'])
                                    localStorage.setItem('role', user['custom:role'])
                                    localStorage.setItem('email', this.emailInput!.value)
                                    localStorage.setItem('profileImage', this.user[0].profileImageUrl)
                                    if (this.isMultiFactorAuthEnabled() && this.user[0].loginAt) {
                                        this.redirectToOtpVerification()
                                    }
                                    else {
                                        this.processingSignIn = false;
                                        if (this.rememberMe) {
                                            Cookies.set('username', this.emailInput!.value, { expires: 30 });
                                            Cookies.set('password', this.passwordInput?.value, { expires: 30 });
                                        }
                                        else {
                                            Cookies.remove('username');
                                            Cookies.remove('password');
                                        }
                                        this.signinForm.controls.email.reset()
                                        this.signinForm.controls.password.reset()
                                        this.messageService.add({
                                            severity: 'success',
                                            summary: 'Success',
                                            detail: 'Welcome back'
                                        });
                                        this._router.navigate(['']);
                                    }
                                } else {
                                    this.awsApi.loading.next(false);
                                    this.processingSignIn = false;
                                    this.auth.handleSignOut();
                                    this.messageService.add({
                                        severity: 'error',
                                        summary: 'Error',
                                        detail: 'User does not exist!'
                                    });
                                }
                            });
                        }
                        else {
                            this.awsApi.loading.next(false);
                            this.processingSignIn = false;
                            this.messageService.add({
                                severity: 'error',
                                summary: 'Error',
                                detail: Messages.SOMETHING_WENT_WRONG
                            });
                            this.auth.handleSignOut();
                        }
                    }).catch((error: any) => {
                        this.processingSignIn = false;
                        this.addUserInCognito() // check if this user is added by Admin and adding user details in Cognito and verifying email-id                         }                      
                    });
            }
            else {
                this.awsApi.loading.next(false);
                this.processingSignIn = false;
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Permission denied !'
                });
                this.auth.handleSignOut();
            }
        }
        else {
            this.awsApi.loading.next(false);
            this.processingSignIn = false;
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: Messages.PLEASE_FILL_REQUIRED_FIELDS
            });
        }
    }

    redirectToOtpVerification() {
        let reqData: any = {
            "userId": this.user[0].id,
            "email": this.emailInp
        };
        this.common.sendOtp(reqData).subscribe((result) => {
            this.awsApi.loading.next(true);
            if (result.success && result.message == 'Email sent successfully!') {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'OTP has been sent to your email'
                });
                this.awsApi.loading.next(false);
                this._router.navigate(['confirm'], { queryParams: { email: this.emailInp, id: reqData.userId, type: "multifactor_auth" } })
            }
        }), (error) => {
            this.awsApi.loading.next(false);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: Messages.SOMETHING_WENT_WRONG
            });
        }
    }

    addUserInCognito() {
        this.user = this.usersList.filter(item => {
            return item.role != 'CUSTOMER' && item.status == Status.ACTIVE && item.email == this.emailInp && item.password == btoa(this.passwordInp) && item.emailVerified == false
        });
        if (this.user.length > 0) {
            this.auth.signUp({
                username: this.emailInp,
                email: this.emailInp,
                password: this.passwordInp,
                firstName: this.user[0].firstName,
                lastName: this.user[0].lastName,
                phone_number: this.user[0].phoneNumber,
                role: this.user[0].role
            } as NewUser).then((response) => {
                if (response.nextStep.signUpStep === 'CONFIRM_SIGN_UP') {
                    this.awsApi.loading.next(false);
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: 'OTP has been sent to your email'
                    });
                    environment.confirm.attributeName =
                        response.nextStep?.['codeDeliveryDetails'].attributeName;
                    environment.confirm.email = this.emailInput?.value ?? '';
                    environment.confirm.password = this.passwordInput?.value ?? '';
                    this._router.navigate(['confirm'], { queryParams: { email: this.emailInp, type: "verify_user" } })
                }
                else {
                    this._router.navigate([''])
                }
            }).catch((error: any) => {
                this.awsApi.loading.next(false);
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: Messages.SOMETHING_WENT_WRONG
                });
            })
        }
        else {
            this.awsApi.loading.next(false);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'User name or password is incorrect!'
            });
        }
    }

    redirectToForgotPassword() {
        this._router.navigate(['forgot-password']);
    }

    onClickShowPassword(input_field_password: any, num: any) {
        // https://stackblitz.com/edit/angular-5pmn3r?file=app%2Fapp.component.ts
        if (input_field_password.type == 'password')
            input_field_password.type = 'text';
        else input_field_password.type = 'password';

        if (num == 1) this.toggle1 = !this.toggle1;
        else this.toggle2 = !this.toggle2;
    }

    ngOnDestroy() {
        // this.renderer.removeClass(
        //     document.querySelector('app-root'),
        //     'login-page'
        // );
    }
}