<form [formGroup]="signupForm">
    <h2>Create a new account</h2>

    <div class="form-group">
        <label class="form-group">
            <span>Email</span>
            <input
                autofocus
                type="text"
                class="form-control"
                formControlName="email"
                placeholder="Email"
                required
            />
        </label>
    </div>

    <div class="form-group">
        <label class="form-group">
            <span>Phone number</span>
            <input
                type="text"
                class="form-control"
                formControlName="phone_number"
                placeholder="phone number"
                required
            />
        </label>
    </div>
    <span class="text-danger" *ngIf="!phoneInput?.value"
        >Enter your phone number
    </span>

    <div class="form-group">
        <label class="form-group">
            <span>First Name</span>
            <input
                type="text"
                class="form-control"
                formControlName="fname"
                placeholder="First Name"
                required
            />
        </label>
    </div>
    <span class="text-danger" *ngIf="!fnameInput?.value"
        >Enter your first name
    </span>

    <div class="form-group">
        <label class="form-group">
            <span>Last Name</span>
            <input
                type="text"
                class="form-control"
                formControlName="lname"
                placeholder="Last Name"
            />
        </label>
    </div>
    <span class="text-danger" *ngIf="!lnameInput?.value"
        >Enter your last name
    </span>

    <div class="form-group">
        <label class="form-group">
            <span>Password</span>
            <input
                type="text"
                class="form-control"
                formControlName="password"
                placeholder="Password"
                required
            />
        </label>
    </div>
    <span class="text-danger" *ngIf="!passwordInput?.value"
        >Enter your password
    </span>

    <!-- <mat-form-field>
      <input matInput placeholder="Email" type="email" formControlName="email" autocomplete="email" required>
      <mat-hint *ngIf="!emailInput?.value">Enter your email address</mat-hint>
    </mat-form-field> -->
    <!-- <mat-form-field>
      <input matInput placeholder="First Name" type="text" formControlName="fname" autocomplete="given-name" required>
      <mat-hint *ngIf="!fnameInput?.value">Enter your first name</mat-hint>
    </mat-form-field> -->
    <!-- <mat-form-field>
      <input matInput placeholder="Last Name" type="text" formControlName="lname" autocomplete="family-name" required>
      <mat-hint *ngIf="!lnameInput?.value">Enter your last name</mat-hint>
    </mat-form-field> -->
    <!-- <mat-form-field>
      <input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" formControlName="password" required>
      <mat-icon matSuffix class="cursor-pointer" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}
      </mat-icon>
      <mat-hint *ngIf="!passwordInput?.value">Enter your password</mat-hint>
    </mat-form-field> -->
    <button (click)="signUp()" [disabled]="!signupForm.valid">Sign up</button>
    <p class="center">
        Already have an account? <a routerLink="/login">Sign In</a>
    </p>
</form>
