import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import * as mutation from 'graphql/mutations';
import slugify from '@sindresorhus/slugify';
import { v4 as uuidv4 } from 'uuid';
import * as _ from 'underscore';
import { ApiService } from '@services/api/api.service';
import { ROUTES } from '@/redirect-routes';
import { Messages } from '@/Toaster-messages';

@Component({
  selector: 'app-attribute-add',
  templateUrl: './attribute-add.component.html',
  styleUrl: './attribute-add.component.scss'
})
export class AttributeAddComponent implements OnInit {
  addAttrForm: FormGroup;

  constructor(
    private awsApi: ApiService,
    private _router: Router,
    private fb: FormBuilder,
    private messageService: MessageService
  ) {
    this.addAttrForm = this.fb.group({
      attrName: ['', [Validators.required]]
    });
  }

  get addAttrFormControl() {
    return this.addAttrForm.controls;
  }

  ngOnInit(): void { }

  onOptionChange() { }

  onSubmit() {
    ;
    try {
      if (this.addAttrForm.valid) {
        this.awsApi.loading.next(true);
        let id = uuidv4();
        let inputData: any = {
          slug: slugify(this.addAttrFormControl['attrName'].value),
          attrName: this.addAttrFormControl['attrName'].value,
          pk: 'ATTRNAME#'
        };

        this.awsApi.client
          .graphql({
            query: mutation.createAttributeName,
            variables: {
              input: {
                ...inputData
              }
            }
          })
          .then((response) => {
            this.awsApi.loading.next(false);
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Attribute created successfully!'
            });
            this._router.navigate(['/list-attribute']);
          })
          .catch((error) => {
            this.awsApi.loading.next(false);
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: Messages.SOMETHING_WENT_WRONG
            });

          });
      }
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });

    }
  }
  redirectToAttributeListPage() {
    this._router.navigate([ROUTES.ATTRIBUTE_LIST]);
  }
}