import { Component, OnInit } from '@angular/core';
import * as mutation from 'graphql/mutations';
import * as query from 'graphql/queries';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';

import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import amplifyconfig from 'amplifyconfiguration.json';
import { uploadData } from 'aws-amplify/storage';
import { ApiService } from '@services/api/api.service';
import { CommonService } from '@services/common/common.service';
import { Messages } from '@/Toaster-messages';

@Component({
    selector: 'app-brand-edit',
    templateUrl: './brand-edit.component.html',
    styleUrl: './brand-edit.component.scss'
})
export class BrandEditComponent implements OnInit {
    public Editor = ClassicEditor;
    public onReady(editor: ClassicEditor) { }
    public onChange({ editor }: ChangeEvent) { }
    editBrandForm: FormGroup;
    brandID: string;
    pk: string;
    access: any = {}
    countryList: any = []
    mainBrandList: any = [];
    brandData: any;
    constructor(
        private router: Router,
        private awsApi: ApiService,
        private _router: Router,
        private fb: FormBuilder,
        private common: CommonService,
        private messageService: MessageService,
        private _activatedroute: ActivatedRoute
    ) {
        this.editBrandForm = this.fb.group({
            brandName: ['', [Validators.required, Validators.pattern(/^(?!\s*$)(?=.*[a-zA-Z])[a-zA-Z0-9\s]+$/)]],
            manufacturerName: [''],
            countryOfOrigin: ['', [Validators.required]],
            brandURL: [''],
            brandLogo: ['', [Validators.required]],
            description: ['']
        });
    }

    ngOnInit(): void {
        this.getRoleManagement()
        this.awsApi.client.graphql({
            query: query.listCountries,
            variables: {
                pk: null,
                slug: null,
                filter: null,
                limit: 9999,
                nextToken: null,
                sortDirection: null
            }
        }).then((response) => {
            this.countryList = response.data.listCountries.items;
        })
        this._activatedroute.queryParamMap.subscribe((params) => {
            this.brandID = params.get('id') ?? '';
            this.pk = params.get('pk') ?? '';
            if (this.brandID != '' && this.pk != '') {
                this.awsApi.loading.next(true);
                Promise.all([this.common.getAllMainBrand(), this.getBrandByID()])
                    .then((response) => {
                        this.mainBrandList = [
                            ...response[0].data.listBrands.items
                        ];
                        this.brandData = response[1].data.getBrand;
                        this.editBrandForm.patchValue({
                            brandName: this.brandData.brandName,
                            manufacturerName: this.brandData.manufacturerName,
                            countryOfOrigin: this.brandData.countryOfOrigin,
                            brandURL: this.brandData.brandUrl,
                            brandLogo: this.brandData.brandLogo,
                            description: this.brandData.brandDescription
                        });

                        this.awsApi.loading.next(false);
                    })
                    .catch((error) => {
                        this.awsApi.loading.next(false);
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: Messages.SOMETHING_WENT_WRONG
                        });
                    });
            }
        });
    }

    async getRoleManagement() {
        try {
            await this.common.getPermissionsOfUser('Products', 'Manage Brand');
            this.access = this.common.access;
            this.awsApi.loading.next(false);
        } catch (error) {
            this.awsApi.loading.next(false);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: Messages.SOMETHING_WENT_WRONG
            });
        }
    }

    async getBrandByID() {
        return await this.awsApi.client.graphql({
            query: query.getBrand,
            variables: {
                pk: this.pk,
                id: this.brandID
            }
        });
    }

    async deleteUrl(type) {
        if (type == 'brandLogo') {
            this.editBrandForm.patchValue({
                brandLogo: ''
            });
            const fileInput = document.getElementById('brand-logo-upload') as HTMLInputElement;
            if (fileInput) {
                fileInput.value = '';
            }
        }
    }

    async fileSave(event: any, type: any) {
        this.awsApi.loading.next(true);
        if (event.target instanceof HTMLInputElement && event.target.files) {
            let file = event.target.files[0];
            let isValid = await this.common.isValidFileType(event)
            if (!isValid) { return; }
            try {
                this.awsApi.loading.next(false);
                let imageUrl = await this.common.getS3Url(file)
                if (type == 'brand') {
                    this.editBrandForm.patchValue({
                        brandLogo: imageUrl
                    });
                }
            } catch (error) {
                this.awsApi.loading.next(false);
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: Messages.SOMETHING_WENT_WRONG
                });
            }
        }
    }

    async isBrandAlreadyExists(): Promise<Boolean> {
        let res = await this.common.getAllMainBrand()
        if (res.data.listBrands.items.length > 0 && res.data.listBrands.items
            .some((item) => (item.brandName.replace(/\s/g, "").toLowerCase().trim() == this.editBrandForm.value.brandName.replace(/\s/g, "").toLowerCase().trim()) &&
                item.id !== this.brandData.id)) {
            return true
        }
        return false
    }

    async updateBrand() {
        if (this.editBrandForm.valid) {
            this.awsApi.loading.next(true);
            if (!await this.isBrandAlreadyExists()) {
                let str = this.editBrandForm.value.brandName.toLowerCase()
                str = str.replace(/\s{2,}/g, ' ');
                str = (str.trim()).replace(/\s+/g, '')
                let data = {
                    pk: this.brandData.pk,
                    id: this.brandData.id,
                    brandName: this.editBrandForm.value.brandName,
                    manufacturerName: this.editBrandForm.value.manufacturerName,
                    countryOfOrigin: this.editBrandForm.value.countryOfOrigin,
                    brandLogo: this.editBrandForm.value.brandLogo,
                    brandUrl: this.editBrandForm.value.brandURL,
                    brandDescription: this.editBrandForm.value.description,
                    searchKey: str
                };
                this.awsApi.client
                    .graphql({
                        query: mutation.updateBrand,
                        variables: {
                            input: {
                                ...data
                            }
                        }
                    })
                    .then((response) => {
                        this.awsApi.loading.next(false);
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Success',
                            detail: 'Brand updated successfully!'
                        });
                        this._router.navigate(['/list-brand']);
                    })
                    .catch((error) => {
                        this.awsApi.loading.next(false);
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: Messages.SOMETHING_WENT_WRONG
                        });
                    });
            } else {
                this.awsApi.loading.next(false);
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: Messages.BRAND_ALREADY_EXISTS
                });
            }
        } else {
            this.awsApi.loading.next(false);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Please fill all required details!'
            });
        }
    }
    redirectToListingPage() {
        this.router.navigate([`list-brand`]);
    }
}