import { Component } from '@angular/core';
import { ApiService } from '@services/api/api.service';
import { MessageService } from 'primeng/api';
import { FormBuilder } from '@angular/forms';
import { CommonService } from '@services/common/common.service';
import { Router } from '@angular/router';
import * as mutation from 'graphql/mutations';
import { Messages } from '@/Toaster-messages';

@Component({
  selector: 'app-shipping-list',
  templateUrl: './shipping-list.component.html',
  styleUrl: './shipping-list.component.scss'
})
export class ShippingListComponent {
  access: any = {}
  shippingList: any = []
  selectedItem: any = []
  searchKey: any
  searchText: any = ''
  filterObject: any = {}

  constructor(private common: CommonService, private router: Router, private awsApi: ApiService, private formBuilder: FormBuilder, private messageService: MessageService) { }
  ngOnInit(): void {
    this.getRoleManagement()
    this.startingFunction()
  }

  async getRoleManagement() {
    this.awsApi.loading.next(true);
    try {
      await this.common.getPermissionsOfUser('Settings', 'Manage Shipping');
      this.access = this.common.access;
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  async startingFunction() {
    let fieldsToFetch = ['pk', 'id', 'city', 'state', 'countryName', 'coverage', 'deliveryCharge', 'updatedAt']
    Promise.all([await this.common.getShipping(this.filterObject, fieldsToFetch)]).then((response) => {
      this.shippingList = response[0].sort((a: any, b: any) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
      this.awsApi.loading.next(false);
    }).catch((error) => {
      this.awsApi.loading.next(false);
    });
  }

  search(searchText) {
    if (searchText) {
      this.searchKey = searchText.toLowerCase()
      this.searchKey = this.searchKey.replace(/\s{2,}/g, ' ');
      this.searchKey = (this.searchKey.trim()).replace(/\s+/g, '');

      this.filterObject['searchKey'] = { contains: this.searchKey }
      this.startingFunction()
    }
    else {
      delete this.filterObject['searchKey']
      this.startingFunction()
    }
  }

  deleteShippingZone() {
    this.deleteModalToggle = false
    let data = {
      pk: 'SHIPPING#',
      id: this.selectedItem
    }
    this.awsApi.loading.next(false);
    this.awsApi.client
      .graphql({
        query: mutation.deleteShipping,
        variables: {
          input: {
            ...data
          }
        }
      }).then((response) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: Messages.SHIPPING_DELETED
        });
        this.startingFunction()
      })
  }

  deleteModalToggle: any
  redirectToAddShippingZone() {
    this.router.navigate([`add-shipping`]);
  }

  showDeleteConfirmationModalDialog(item: any) {
    this.selectedItem = item
    this.deleteModalToggle = true;
  }

  redirectToEditShippingZone(shippingId) {
    this.router.navigate([`edit-shipping`], { queryParams: { id: shippingId } });
  }
}
