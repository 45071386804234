import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import { MessageService } from 'primeng/api';
import * as XLSX from 'xlsx';
import { StatusList, UserRolesList } from '@/common-list';
import { FormBuilder } from '@angular/forms';
import { formatDate } from '@angular/common';
import { ROUTES } from '@/redirect-routes';
import { UserAuthService } from '@services/user-auth/user-auth.service';
import * as mutation from 'graphql/mutations';
import { Messages } from '@/Toaster-messages';
import { Status } from 'API';
import { CommonService } from '@services/common/common.service';

@Component({
  selector: 'app-list-customers',
  templateUrl: './list-customers.component.html',
  styleUrl: './list-customers.component.scss'
})
export class ListCustomersComponent {
  access: any = {}
  position: any;
  displayFilterModalToggle = false
  deleteModalToggle: any
  usersList: any = []
  copyUsersList: any = []
  randomColorsForCustomersName: any = []
  filterObject: any = {}
  searchText: any = ''
  status: any = ''
  registeredOn: any = ''
  role: any = ''
  email: any = ''
  maxDate: any
  selectedUser: any
  userRolesList = UserRolesList
  rolesList = UserRolesList
  statusList = StatusList
  searchKey: any
  filterApplied: boolean = false
  today: any = new Date();
  thirtyDaysAgo = new Date((new Date()).setDate(this.today.getDate() - 30))
  filterForm = this.formBuilder.group({
    status: [],
    registeredOn: [],
    role: ['']
  })
  letters = '0123456789ABCDEF';
  color = '#';
  constructor(private router: Router, public auth: UserAuthService, private common: CommonService, private awsApi: ApiService, private formBuilder: FormBuilder, private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.position = 'right';
    this.maxDate = new Date()
    this.startingFunction()
  }

  async startingFunction() {
    try {
      await this.common.getPermissionsOfUser('Customers');
      this.access = this.common.access;
      this.filterForm.patchValue({ status: this.statusList })
      this.filterForm.patchValue({ registeredOn: [this.thirtyDaysAgo, this.today] });
      this.applyFilter()
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  async getUsersList() {
    this.awsApi.loading.next(true);
    this.filterObject['role'] = { eq: 'CUSTOMER' }
    let fieldsToFetch = []
    try {
      fieldsToFetch = ['firstName', 'lastName', 'email', 'companyName', 'addressDetails {', 'address {', 'city', 'state', 'postalCode', 'country',
        '}', '}', 'phoneNumber', 'createdAt', 'loginAt', 'searchKey', 'status', 'id']
      this.usersList = (await this.common.listUsers(this.filterObject, fieldsToFetch, false))
        .sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    } catch (error) { }

    this.copyUsersList = this.usersList
    this.randomColorsForCustomersName = this.generateRandomColors(this.usersList.length)
    if (this.filterForm.controls.status.value?.length > 0) {
      this.filterApplied = true
      let statusList = this.filterForm.controls.status.value.map((status: any) => status.value);
      this.usersList = this.copyUsersList.filter((arr: any) => {
        return statusList.includes(arr.status)
      });
    }
    this.copyUsersList = this.usersList
    this.awsApi.loading.next(false);
  }

  clearFilter() {
    delete this.filterObject['createdAt']
    delete this.filterObject['role']
    this.filterForm.reset()
    this.getUsersList()
  }

  search(searchText: any) {
    if (searchText != '') {
      this.searchKey = searchText.toLowerCase()
      this.searchKey = (this.searchKey.trim()).replace(/\s+/g, '');

      this.filterObject['searchKey'] = { contains: this.searchKey }
      this.applyFilter()
    }
    else {
      delete this.filterObject['searchKey']
      this.applyFilter()
    }
  }

  applyFilter() {
    this.filterRole()
    this.filterRegisteredOnDate()
    this.displayFilterModalToggle = false;
    this.getUsersList()
  }

  filterRegisteredOnDate() {
    if (this.filterForm.controls.registeredOn.value?.length == 2 && this.filterForm.controls.registeredOn.value[0] != null && this.filterForm.controls.registeredOn.value[1] != null) {
      this.filterApplied = true
      var selectedDate1 = new Date(this.filterForm.controls.registeredOn.value[0])
      let selectedDate2 = new Date(this.filterForm.controls.registeredOn.value[1])

      var selectedDate2Formatted = selectedDate2.setDate(selectedDate2.getDate() + 1)
      this.filterObject['createdAt'] = { ge: formatDate(selectedDate1, 'yyyy-MM-dd', 'en-US'), le: formatDate(selectedDate2Formatted, 'yyyy-MM-dd', 'en-US') }
    }
    if (this.filterForm.controls.registeredOn.value?.length == 2 && this.filterForm.controls.registeredOn.value[0] != null && this.filterForm.controls.registeredOn.value[1] == null) {
      this.filterApplied = true
      var selectedDate1 = new Date(this.filterForm.controls.registeredOn.value[0])
      this.filterObject['createdAt'] = { contains: formatDate(selectedDate1, 'yyyy-MM-dd', 'en-US') }
    }
  }

  filterRole() {
    if (this.filterForm.controls.role.value) {
      this.filterApplied = true
      this.filterObject['role'] = { eq: this.filterForm.controls.role.value }
    }
  }

  changeStatus(userId: any, type: any) {
    let data = {
      pk: 'USER#',
      id: userId,
      status: type == 'Deactivate' ? Status.INACTIVE : Status.ACTIVE,
      updatedAt: new Date().toISOString()
    }
    this.awsApi.client
      .graphql({
        query: mutation.updateUser,
        variables: {
          input: {
            ...data
          }
        }
      }).then((response) => {
        this.awsApi.loading.next(false);
        this.ngOnInit();
      }).catch((error) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      })
  }

  async deleteUser() {
    let data = {
      'loginuserid': localStorage.getItem('userId'),
      'userid': this.selectedUser.id
    }
    this.common.deleteUser(data, await this.common.getHeader()).subscribe(async (result) => {
      if (result.status) {
        this.deleteModalToggle = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Customer deleted successfully'
        });
        await this.getUsersList()
      }
    });
  }

  showFilterModalDialog(position: string) {
    this.position = position;
    this.displayFilterModalToggle = true;
  }
  closeFilterModalDialog() {
    this.displayFilterModalToggle = false;
  }
  cancelFilterModalDialog() {
    this.displayFilterModalToggle = false;
    this.clearFilter()
    this.filterApplied = false
  }
  showDeleteConfirmationModalDialog(item: any) {
    this.selectedUser = item
    this.deleteModalToggle = true;
  }
  redirectToAddUserPage() {
    this.router.navigate([ROUTES.ADD_USER]);
  }
  redirectToEditUser(user) {
    this.router.navigate([ROUTES.ADD_USER]);
  }

  generateRandomColors(count: number): string[] {
    const colors: string[] = [];
    for (let i = 0; i < count; i++) {
      colors.push(this.getRandomColor());
    }
    return colors;
  }

  getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  formatTimeIn12Hrs(utcTime: any): string {
    let date = new Date(utcTime)
    let hours = date.getHours() % 12 || 12
    let minutes = date.getMinutes().toString().padStart(2, '0')
    let ampm = date.getHours() >= 12 ? 'PM' : 'AM'
    return `${hours}:${minutes} ${ampm}`;
  }

  downloadInExcel() {
    try {
      let header = ['First Name', 'Last Name', 'Company Name', 'Email', 'Phone Number', 'Address', 'Last login at', 'Status']
      let data = this.usersList.map(user => [
        (user.firstName && user.firstName != null) ? user.firstName : 'N/A',
        (user.lastName && user.lastName != null) ? user.lastName : 'N/A',
        (user.companyName && user.companyName != null) ? user.companyName : 'N/A',
        (user.email && user.email != null) ? user.email : 'N/A',
        (user.phoneNumber && user.phoneNumber != null) ? user.phoneNumber : 'N/A',
        user.addressDetails && user.addressDetails.length > 0 ?
          (user.addressDetails.at(-1).address?.city ? (user.addressDetails.at(-1).address?.city + ', ') : '') +
          (user.addressDetails.at(-1).address?.state ? (user.addressDetails.at(-1).address?.state + ', ') : '') +
          (user.addressDetails.at(-1).address?.country ? (user.addressDetails.at(-1).address?.country) : '') : 'N/A',
        user.loginAt ? ((user.loginAt).split('T')[0] + ', ' + this.formatTimeIn12Hrs(user.loginAt)) : 'N/A',
        user.status == 'ACTIVE' ? 'Active' : 'Inactive',
      ])
      let excelData = [header, ...data];
      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(excelData);  // userslist to worksheet
      ws['!cols'] = [ //setting width for each column
        { wpx: 180 },
        { wpx: 180 },
        { wpx: 180 },
        { wpx: 160 },
        { wpx: 100 },
        { wpx: 200 },
        { wpx: 120 },
        { wpx: 50 }
      ];
      let wb: XLSX.WorkBook = XLSX.utils.book_new() // create a workbook
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1') // merge worksheet into empty workbook
      let excelBase64 = XLSX.write(wb, { bookType: 'xlsx', type: 'base64' }) // write workbook content into base64 format
      let excelBlob = new Blob([new Uint8Array(atob(excelBase64).split("").map(c => c.charCodeAt(0)))], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }) // Convert base64 to a Blob
      let excelUrl = URL.createObjectURL(excelBlob) // convert blob to a url
      let a = document.createElement('a')
      a.href = excelUrl
      a.download = 'customers_list.xlsx'
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) { console.log(error) }
  }
}