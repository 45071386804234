<section id="cartDetailsSec">
    <div class="container-fluid">
        <div class="row mt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card heading-card" *ngIf="cartData.length>0">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="d-flex justify-content-between align-items-center w-100">
                                    <div class="d-flex justify-content-start align-items-center">
                                        <i class="fas fa-chevron-left" [routerLink]="['/list-cart']"></i>
                                        <h6>{{cartData[0]?.cartURL}}</h6>
                                    </div>
                                    <div>
                                        <p>Cart Total: <span>{{currency}} {{cartTotal | number: '.2'}}</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mt-2 customer-information-card" *ngIf="cartData.length>0">
                    <div class="card-body">
                        <div class="row mt-3">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                <h6>Customer Information</h6>
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-12 mb-2">
                                <p>Name : <span *ngIf="cartData[0]?.userName">{{cartData[0]?.userName}}</span>
                                    <span *ngIf="!cartData[0]?.userName">N/A</span>
                                </p>
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-12 mb-2">
                                <p>Email : <span *ngIf="cartData[0]?.userMail">{{cartData[0]?.userMail}}</span>
                                    <span *ngIf="!cartData[0]?.userMail">N/A</span>
                                </p>
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-12 mb-2">
                                <p>Mobile Number :
                                    <span *ngIf="cartData[0]?.mobileNumber!='null'">{{cartData[0]?.mobileNumber}}</span>
                                    <span
                                        *ngIf="cartData[0]?.mobileNumber=='null' || !cartData[0]?.mobileNumber">N/A</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mt-2 cart-items-card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                <h6>Cart Items</h6>
                            </div>
                            <div class="col-md-12">
                                <div class="table-responsive cart-items-table">
                                    <table class="table">
                                        <tbody *ngFor="let cartItem of cartData;let cartIndex=index">
                                            <tr>
                                                <td>
                                                    <img [src]="cartItem?.productImage" width="75px" height="75px" />
                                                </td>
                                                <td colspan="2">
                                                    <div>
                                                        <span>Product Name</span>
                                                        <p>{{cartItem?.productName}}
                                                            <span *ngIf="cartItem?.attrName || cartItem?.attrValue"> -
                                                                {{cartItem?.attrName}}
                                                                {{cartItem?.attrValue}}</span>
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <span>Brand</span>
                                                        <p>{{cartItem?.brandDetail?.brandName}}</p>
                                                    </div>
                                                </td>
                                                <td class="vertical-align-bottom" nowrap>
                                                    <span>SKU</span>
                                                    <p>{{cartItem?.sku}}</p>
                                                </td>
                                                <td class="vertical-align-bottom" nowrap>
                                                    <span>Unit Price</span>
                                                    <p>{{currency}} {{cartItem?.salesPrice | number: '.2'}}</p>
                                                </td>
                                                <td class="vertical-align-bottom" nowrap><span>Qty</span>
                                                    <p>{{cartItem?.selectedQuantity}}</p>
                                                </td>
                                                <td class="vertical-align-bottom"><span>Total</span>
                                                    <p>{{currency}} {{getTotalPrice(cartItem) | number: '.2'}}</p>
                                                </td>
                                                <td nowrap>
                                                    <div>
                                                        <span>Current Stock</span>
                                                        <p>{{currentStock[cartIndex]}}</p>
                                                    </div>
                                                    <div>
                                                        <span>Added Since</span>
                                                        <p>{{cartItem?.createdAt | date:'dd/MM/yyyy' : 'UTC' }}</p>
                                                    </div>
                                                </td>
                                                <td class="vertical-align-top" *ngIf="hide">
                                                    <div class="dropdown pe-2">
                                                        <i class="fas fa-ellipsis-v" id="dropdownMenuButton1"
                                                            data-bs-toggle="dropdown" aria-expanded="false"
                                                            data-bs-auto-close="inside"
                                                            data-bs-auto-close="outside"></i>
                                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li [ngClass]="{ disable: !access['delete'] }">
                                                                <a class="dropdown-item"><span
                                                                        class="material-symbols-outlined delete-icon"
                                                                        title="Delete">delete
                                                                    </span>Delete</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>