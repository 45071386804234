import { ProductReportBy } from '@/common-list';
import { Messages } from '@/Toaster-messages';
import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import { CommonService } from '@services/common/common.service';
import { ExcelService } from '@services/common/excel.service';
import { UserAuthService } from '@services/user-auth/user-auth.service';
import { ProductDeliveryStatus, productStatus } from 'API';
import * as query from 'graphql/queries';
import { MessageService } from 'primeng/api';
import { ROUTES } from '@/redirect-routes';

@Component({
  selector: 'app-products-report-list',
  templateUrl: './products-report-list.component.html',
  styleUrl: './products-report-list.component.scss'
})
export class ProductsReportListComponent {
  value: number = 5;
  position: any;
  displayFilterModalToggle = false
  deleteModalToggle: any
  productReportList: any = []
  productRes: any = []
  copyProductReportList: any = []
  productDataList: any = []
  productsList: any = []
  orderItemsList: any = []
  productName: any = ''
  currentStock: any = 0
  pendingOrders: any = 0
  totalQuantity: any
  total: any
  productDetails: any = []
  mainCategoryList: any = []
  subCategoryList: any = []
  locationList: any = []
  variantList: any = []
  skuList: any = []
  filterObject: any = {}
  companyName: any = ''
  loader: boolean = true

  viewAccess: any = false
  isSearchEnabled = false
  filterApplied = false
  page: any
  pageSize: any
  loading: boolean = false
  singleVariant: boolean = false
  endOfData: boolean
  selectedReportType: string | null = null;
  selectedMainCategory: string | null = null;
  selectedSubCategory: string | null = null;
  selectedProduct: string | null = null;
  productReportBy = ProductReportBy

  filterForm = this.fb.group({
    reportType: [''],
    sku: [''],
    mainCategory: [],
    subCategory: [],
    product: [],
    orderedOn: ['']
  })

  constructor(private awsApi: ApiService, private router: Router, private common: CommonService, public auth: UserAuthService, private fb: FormBuilder,
    private excelService: ExcelService, private messageService: MessageService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.position = 'right';
    this.viewAccess = this.route.snapshot.queryParamMap?.get('view') ?? false;
    if (this.viewAccess) {
      this.startingFunction()
    }
    else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.ACCESS_DENIED
      });
    }
  }

  getAllMainCategory() {
    return this.awsApi.client.graphql({
      query: query.listCategories,
      variables: {
        pk: 'MAIN#'
      }
    });
  }

  async startingFunction() {
    Promise.all([this.getAllMainCategory(), this.common.getCompanyName()]).then((result: any) => {
      this.mainCategoryList = result[0].data.listCategories.items
      this.companyName = result[1]
    }).catch((error) => { });
  }

  async getProductReportList() {
    this.productReportList = []
    Promise.all([this.common.getOrderItems(this.filterObject)]).then(async (result: any) => {
      this.totalQuantity = 0
      this.productReportList = result[0]
        .sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      this.productName = this.productReportList[0] ? this.productReportList[0].itemDetail.productName : ''
      if (this.productReportList.length > 0) {
        this.totalQuantity = this.productReportList
          .reduce((sum, detail) => sum + detail.quantity, 0);
      }
      if (this.filterForm.controls.sku.value) {
        this.locationList = []
        let productDetail = await this.common.getProductDetailsWithEditQuery(
          {
            sku: { eq: this.filterForm.controls.sku.value },
            isDeleted: { ne: true }
          }, ['pk', 'id', 'stockQuantity'])
        this.currentStock = productDetail[0]?.stockQuantity || 'N/A'
        this.pendingOrders = this.productReportList.filter(order => (order.deliveryStatus != ProductDeliveryStatus.Delivered && order.deliveryStatus != ProductDeliveryStatus.Cancelled) && order.sku == this.filterForm.controls.sku.value).length
        if (this.productReportList.length > 0) {
          this.productReportList.forEach((prod, index) => {
            this.total = parseFloat(this.total) + (parseFloat(prod.quantity) * parseFloat(prod.itemDetail.price))
            Promise.all([this.getOrderByID(prod.pk.split('#')[1])]).then((result) => {
              let address = result[0].data.getOrder.shippingAddress ? result[0].data.getOrder.shippingAddress : result[0].data.getOrder.billingAddress
              this.locationList[index] = address.city + ', ' + address.state + ', ' + address.country
            })
          })
        }
      }

      if (this.filterForm.controls.product.value) {
        this.singleVariant = false
        this.locationList = [], this.skuList = [], this.variantList = []
        if (this.productReportList.length > 0) {
          this.productReportList.forEach((prod, index) => {
            this.total = parseFloat(this.total) + (parseFloat(prod.quantity) * parseFloat(prod.itemDetail.price))

            this.skuList[index] = prod.sku
            this.variantList[index] = (prod.attrName && prod.attrName.trim() != 'null') ? (prod.attrName + ' - ' + prod.attrValue) : 'N/A'
            Promise.all([this.getOrderByID(prod.pk.split('#')[1])]).then((result) => {
              if (result[0].data.getOrder) {
                let address = result[0].data.getOrder.shippingAddress ? result[0].data.getOrder.shippingAddress : result[0].data.getOrder.billingAddress
                this.locationList[index] = address.city + ', ' + address.state + ', ' + address.country
              }
            })
            this.singleVariant = this.variantList.every((item) => item == 'N/A')
          })
        }
      }
    })
    this.initializePagination()
  }

  initializePagination() {
    this.page = 1;
    this.pageSize = 12;
    this.loading = false;
    this.endOfData = false;
    this.loadData('initial')
  }

  loadData(type) {
    if (this.productReportList.length > 0) {
      this.loading = true;
    }
    const startIndex = (this.page - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    if (type == 'initial') {
      setTimeout(() => {
        this.productDataList = this.productReportList.slice(startIndex, endIndex);
        this.awsApi.loading.next(false);
        this.loader = this.awsApi.loading.getValue()
        this.loading = false;
      }, 1000);
    }
    if (type == 'more') {
      setTimeout(() => {
        this.productDataList = this.productDataList.concat(this.productReportList.slice(startIndex, endIndex));
        this.loading = false;

        if (this.productDataList.length >= this.productReportList.length) {
          this.endOfData = true;
        }
      }, 2000);
    }
  }

  onScroll() {
    if (!this.loading && !this.endOfData) {
      const container = document.querySelector('.scroll-container');
      if (
        container &&
        container.scrollHeight - container.scrollTop === container.clientHeight
      ) {
        this.page++
        this.loadData('more');
      }
    }
  }

  filterProduct() {
    if (this.filterForm.controls.product?.value) {
      this.filterApplied = true
      const selectedProduct = this.filterForm.controls.product.value
      this.filterObject['productId'] = { eq: selectedProduct.id }
    }
    else {
      delete this.filterObject['productId']
    }
  }

  filterDate() {
    if (this.filterForm.controls.orderedOn.value?.length == 2) {
      this.filterApplied = true
      if (this.filterForm.controls.orderedOn.value[0] != null) {
        var selectedDate1 = new Date(this.filterForm.controls.orderedOn.value[0])
      }
      if (this.filterForm.controls.orderedOn.value[1] != null) {
        let selectedDate2 = new Date(this.filterForm.controls.orderedOn.value[1])
        var selectedDate2Formatted = selectedDate2.setDate(selectedDate2.getDate() + 1)
        this.filterObject['createdAt'] = { ge: formatDate(selectedDate1, 'yyyy-MM-dd', 'en-US'), le: formatDate(selectedDate2Formatted, 'yyyy-MM-dd', 'en-US') }
      } if (this.filterForm.controls.orderedOn.value[1] == null) {
        this.filterObject['createdAt'] = { contains: formatDate(selectedDate1, 'yyyy-MM-dd', 'en-US') }
      }
    }
    else {
      delete this.filterObject['createdAt']
    }
  }

  filterSKU() {
    if (this.filterForm.controls.sku?.value) {
      this.filterApplied = true
      const selectedSKU = this.filterForm.controls.sku.value
      this.filterObject['sku'] = { eq: selectedSKU }
    }
    else {
      delete this.filterObject['sku']
    }
  }

  filterMainCategory() {
    if (this.filterForm.controls.mainCategory?.value) {
      this.filterApplied = true
      const selectedMainCategory = this.filterForm.controls.mainCategory?.value.id
      this.filterObject['mainCategoryId'] = { eq: selectedMainCategory }
    }
    else {
      delete this.filterObject['mainCategoryId']
    }
  }

  filterSubCategory() {
    if (this.filterForm.controls.subCategory?.value) {
      this.filterApplied = true
      const selectedCategory = this.filterForm.controls.subCategory.value.id
      this.filterObject['subCategoryId'] = { eq: selectedCategory }
    }
    else {
      delete this.filterObject['subCategoryId']
    }
  }

  async applyFilter() {
    this.total = 0.00
    this.copyProductReportList = this.productRes
    if (this.filterForm.controls.reportType.value == null) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Choose SKU or product name!'
      });
      this.productReportList = []
    }
    else {
      if (this.filterForm.controls.sku?.value ||
        (this.filterForm.controls.mainCategory?.value && this.filterForm.controls.subCategory?.value && this.filterForm.controls.product?.value)) {
        this.awsApi.loading.next(true);
        this.loader = this.awsApi.loading.getValue()
        this.displayFilterModalToggle = false;
        this.filterSKU()
        this.filterProduct()
        this.filterMainCategory()
        this.filterSubCategory()
        this.filterDate()
        await this.getProductReportList()
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.PLEASE_FILL_REQUIRED_FIELDS
        });
      }
    }
  }

  cancelFilter() {
    this.displayFilterModalToggle = false;
    this.filterForm.reset()
    this.filterApplied = false
    this.productReportList = []
  }

  exportToExcel() {
    let obj = []
    let exel_sheet_data = []
    let data: any = {}
    const header = (this.filterApplied && this.filterForm.controls.sku.value) ?
      ['Order Date', 'Order By', 'Delivery Location', 'Order Qty', 'Unit Price', 'Total', 'Status'] :
      (this.filterApplied && this.filterForm.controls.product.value) ? !this.singleVariant ?
        ['Order Date', 'Order By', 'Delivery Location', 'Variant', 'SKU', 'Order Qty', 'Unit Price', 'Total', 'Status'] :
        ['Order Date', 'Order By', 'Delivery Location', 'Order Qty', 'Unit Price', 'Total', 'Status'] : []

    let title = 'Product-wise Order Summary Report List of ' + this.companyName;
    let worksheet = 'Product Summary Report'
    let merge_cells = (this.filterApplied && this.filterForm.controls.sku.value) ? 'A1:G2' :
      (this.filterApplied && this.filterForm.controls.product.value) ? !this.singleVariant ? 'A1:I2' : 'A1:G2' : ''

    let footer_row = this.companyName + ' Product-Report List.'
    let filename = 'Product-Report_List'
    let excel_array = [
      data['header'] = header,
      data['title'] = title,
      data['worksheet'] = worksheet,
      data['merge_cells'] = merge_cells,
      data['footer_row'] = footer_row,
      data['filename'] = filename
    ]
    const date = new Date();
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit', month: 'short', year: 'numeric'
    }).replace(/ /g, '-');
    if (this.filterApplied && this.filterForm.controls.sku.value) {
      for (var i in this.productReportList) {
        obj = [(this.common.dateInReverse(this.productReportList[i].createdAt)).toString(),
        (this.productReportList[i].userName).toString(),
        (this.locationList[i]).toString(),
        Number(this.productReportList[i].quantity).toFixed(0),
        Number(this.productReportList[i].itemDetail.price).toFixed(2),
        Number(this.getTotalPrice(this.productReportList[i])).toFixed(2),
        !this.productReportList[i].deliveryStatus ? 'Pending' : this.productReportList[i].deliveryStatus == 'Received' ? 'Confirmed' : this.productReportList[i].deliveryStatus == 'OutForDelivery' ? 'Out For Delivery' : this.productReportList[i].deliveryStatus
        ]
        exel_sheet_data.push(obj)
      };
      exel_sheet_data.push(['', '', '', '', '', '', ''])
      exel_sheet_data.push(['', '', 'Total', Number(this.totalQuantity).toFixed(0), '', Number(this.total).toFixed(2), ''])
    }

    if (this.filterApplied && this.filterForm.controls.product.value) {
      for (var i in this.productReportList) {
        if (!this.singleVariant) {
          obj = [
            (this.common.dateInReverse(this.productReportList[i].createdAt)).toString(),
            this.productReportList[i].userName,
            this.locationList[i],
            this.variantList[i],
            this.skuList[i],
            Number(this.productReportList[i].quantity).toFixed(0),
            Number(this.productReportList[i].itemDetail.price).toFixed(2),
            Number(this.getTotalPrice(this.productReportList[i])).toFixed(2),
            !this.productReportList[i].deliveryStatus ? 'Pending' : this.productReportList[i].deliveryStatus == 'Received' ? 'Confirmed' : this.productReportList[i].deliveryStatus == 'OutForDelivery' ? 'Out For Delivery' : this.productReportList[i].deliveryStatus
          ]
        } else {
          obj = [
            (this.common.dateInReverse(this.productReportList[i].createdAt)).toString(),
            this.productReportList[i].userName,
            this.locationList[i],
            Number(this.productReportList[i].quantity).toFixed(0),
            Number(this.productReportList[i].itemDetail.price).toFixed(2),
            Number(this.getTotalPrice(this.productReportList[i])).toFixed(2),
            !this.productReportList[i].deliveryStatus ? 'Pending' : this.productReportList[i].deliveryStatus == 'Received' ? 'Confirmed' : this.productReportList[i].deliveryStatus == 'OutForDelivery' ? 'Out For Delivery' : this.productReportList[i].deliveryStatus
          ]
        }
        exel_sheet_data.push(obj)
      }
      !this.singleVariant ?
        exel_sheet_data.push(['', '', '', '', '', '', '', '', ''], ['', '', 'Total', '', '', Number(this.totalQuantity).toFixed(0), '', Number(this.total).toFixed(2), '']) :
        exel_sheet_data.push(['', '', '', '', '', '', ''], ['', '', 'Total', Number(this.totalQuantity).toFixed(0), '', Number(this.total).toFixed(2), ''])
    }
    this.excelService.exportAsExcelFile(exel_sheet_data, formattedDate, excel_array);
  }

  showFilterModalDialog(position: string) {
    this.position = position;
    this.displayFilterModalToggle = true;
  }
  closeFilterModalDialog() {
    this.displayFilterModalToggle = false;
  }
  showDeleteGeneralQueryModalDialog(item: any) {
    this.deleteModalToggle = true;
  }
  redirectToAddProductPage() {
    this.router.navigate([`admin/product/add`]);
  }

  getAllSubCategory(mainCategoryID) {
    return this.awsApi.client.graphql({
      query: query.listCategories,
      variables: {
        pk: 'SUB#' + mainCategoryID
      }
    });
  }

  async onSelectSubCategory() {
    this.filterForm.controls.product.reset()
    this.productName = ''
    this.productReportList = []
    let subCategoryId = this.filterForm.controls.subCategory?.value.id
    let filterObject = {}
    filterObject['subCategoryID'] = { eq: subCategoryId }
    filterObject['status'] = { ne: productStatus.deleted }
    this.productsList = (await this.common.getProducts(filterObject, ['pk', 'id', 'productName']))
      .sort((a: any, b: any) => a.productName.localeCompare(b.productName));
  }

  async onSelectMainCategory() {
    try {
      this.filterForm.controls.subCategory.reset()
      this.subCategoryList = []
      this.filterForm.controls.product.reset()
      this.productName = ''
      this.productReportList = []

      let mainCategoryId = this.filterForm.controls.mainCategory?.value?.id
      this.getAllSubCategory(mainCategoryId)
        .then(async (result) => {
          this.subCategoryList = result.data.listCategories.items;
        }).catch((error) => {
        });
    } catch (error) { }

  }

  async getOrderByID(orderId) {
    return await this.awsApi.client.graphql({
      query: query.getOrder,
      variables: {
        pk: 'ORDER#',
        id: orderId
      }
    });
  }

  getTotalPrice(item: any): number {
    let totalPrice: any = item.quantity * item.itemDetail.price;
    return totalPrice
  }

  onSelectProductName() {
    this.filterForm.controls.sku.reset()
    delete this.filterObject['sku']
  }

  onSelectReportType() {
    Object.keys(this.filterForm.controls).forEach(key => {
      if (key !== 'reportType') {
        this.filterForm.controls[key].reset();
      }
    });
    this.filterApplied = false
    this.productReportList = []
  }

  redirectToReportListPage() {
    this.router.navigate([ROUTES.REPORTS_LIST])
  }
}