import { Component } from '@angular/core';
import { ApiService } from '@services/api/api.service';
import * as query from 'graphql/queries';
import { ActivatedRoute, Router } from '@angular/router';
import { BankNameList, ChequeStatusList, DeliveryBy, DeliveryByList, DeliveryTypeList, OrderItemStatusList, OrderStatuses, PaymentMethodForCashOnDelivery } from '@/common-list';
import { ROUTES } from '@/redirect-routes';
import { MessageService } from 'primeng/api';
import { Messages } from '@/Toaster-messages';
import * as mutation from 'graphql/mutations';
import { FormBuilder, Validators } from '@angular/forms';
import { ChequeStatus, OrderStatus, PaymentMethod, PaymentStatus, ProductDeliveryStatus, Status } from 'API';
import _ from 'underscore';
import { CommonService } from '@services/common/common.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrl: './order-details.component.scss'
})
export class OrderDetailsComponent {
  orderId: string
  orderData: any
  orderItems: any = []
  itemValue: any = []
  orderItemStatusList: any = OrderItemStatusList
  deliveryTracking: any = []
  updatedStatusList: any = []
  valid: any = []
  isOrderConfirmed: boolean = false
  PackingCompletedForAnyOrderItems: boolean = true
  updateToStatus: any = null
  validStatus: boolean = false
  stockQuantity: any = []
  reservedStock: any = []
  productDetailIds: any = []
  storageInfo: any = []
  deliveryTrackingList: any = []
  deliveryPartnersList: any = []
  paymentDetails: any = []
  productDetail: any = []
  products: any = []
  paymentDetail: any = []
  couponDetails: any = []
  updatedAt: any
  deliveryTrackingIndex: any
  deliveryType: any
  deliveryBy: any = null
  selectedItemIndex: any
  selectedPaymentMethod: any = null
  statusUpdated: boolean = false
  validationToasterEnabled: boolean = false
  visibleAttentionDialog: boolean = false;
  visibleAttentionDialogForAcknowledge: boolean = false;
  displayUpdateStatusModalToggle = false
  displayDeliveryInfoModalToggle = false
  displayDeliveryTrackingModalToggle = false
  displayUpdatePaymentModalToggle = false
  isPaymentReceived: boolean = false
  isPaymentPending: any
  updatePaymentEnabled: boolean = false
  stockAvailable: boolean
  currency: any
  position: any;
  deliveryReceipt: any
  trackingBy: any
  amountLeftToPay: any = 0
  totalOrdersValue: any = 0
  returnAmount = 0
  receivedAmount: any
  removeCouponDiscount: boolean = false
  access: any = {}
  status: string | null = null;
  defaultPaymentMethod: string | null = null;
  defaultStatus: string | null = null;
  deliveryPartner: string | null = null;
  deliveryVar: string | null = null;
  defaultBankName: string | null = null;

  deliveryByList = DeliveryByList
  paymentMethodList = PaymentMethodForCashOnDelivery
  chequeStatusList = ChequeStatusList
  bankNameList = BankNameList
  statusList = OrderStatuses
  deliveryTypeList = DeliveryTypeList
  delivery = DeliveryBy
  statuses = [ProductDeliveryStatus.Delivered, ProductDeliveryStatus.Cancelled]
  selfPickUpForm = this.formbuilder.group({
    status: ['', [Validators.required]],
    pickUpDate: ['', [Validators.required]],
    name: ['', [Validators.required]],
    phoneNumber: ['', [Validators.required, Validators.pattern('[- +0-9]{10,15}')]],
    deliveryNotes: [''],
    deliveryReceipt: ['']
  });

  doorStepDeliveryForm = this.formbuilder.group({
    status: ['', [Validators.required]],
    deliveryBy: [''],
    deliveryPartner: [''],
    deliveryDate: [''],
    expectedDeliveryDate: [''],
    trackingNumber: [''],
    trackingUrl: [''],
    name: [''],
    mobileNumber: ['', Validators.pattern('[- +0-9]{10,15}')],
    vehicleNumber: ['', Validators.pattern('^[A-Z]{2}[ -]?[0-9]{2}[ -]?[A-Z]{1,2}[ -]?[0-9]{4}$')],
    notes: [''],
    reason: [''],
  });

  updatePaymentForm = this.formbuilder.group({
    isPaymentReceived: [false],
    method: ['', [Validators.required]],
    amount: ['', [Validators.required]],
    chequeNumber: ['', Validators.pattern('[0-9]{6} ?[0-9]{9}')],
    bankName: [''],
    chequeDate: [''],
    status: ['']
  });

  constructor(private common: CommonService, private router: Router, private formbuilder: FormBuilder, private messageService: MessageService, private activatedRoute: ActivatedRoute, private awsApi: ApiService) { }

  async getOrderByID() {
    return await this.awsApi.client.graphql({
      query: query.getOrder,
      variables: {
        pk: 'ORDER#',
        id: this.orderId
      }
    });
  }

  async getOrderItems() {
    return await this.awsApi.client.graphql({
      query: query.listOrderItems,
      variables: {
        pk: 'ORDERI#' + this.orderId
      }
    });
  }

  async ngOnInit() {
    this.awsApi.loading.next(true);
    this.getRoleManagement()
    this.currency = await this.common.getCurrencyOfCompany()
    this.position = 'right';
    this.isPaymentPending = false
    this.startingFunction()
    this.doorStepDeliveryForm.get('deliveryDate')?.valueChanges.subscribe(date => {
      this.onOutForDeliveryDateChange(date);
    });
  }

  async getRoleManagement() {
    try {
      await this.common.getPermissionsOfUser('Orders', 'Manage Orders');
      this.access = this.common.access;
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  async startingFunction() {
    try {
      this.trackingBy = localStorage.getItem('userName').toString() + '#' + localStorage.getItem('role').toString()
      this.activatedRoute.queryParamMap.subscribe((params) => {
        this.orderId = params.get('orderId') ?? ''
        if (this.orderId != '') {
          Promise.all([this.getOrderItems(), this.common.getDeliveryPartners({ status: { eq: Status.ACTIVE } }, ['pk', 'id', 'companyName', 'createdAt'])])
            .then(async (response) => {
              this.orderData = (await this.getOrderByID()).data.getOrder
              this.paymentDetails = this.orderData.paymentDetails
              this.orderData.status = this.orderData.status
              this.orderItems = response[0].data.listOrderItems.items.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
              this.deliveryPartnersList = response[1].sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
              await this.getProductDetails()
              await this.getOrderItemValues()
              await this.findReceivedAmount()
              await this.getAmountLeftToPay()
              if (this.amountLeftToPay < 0.00) {
                this.returnAmount = -1 * this.amountLeftToPay
              }
              if (this.orderData?.status == this.statusList.confirmed) {
                this.isOrderConfirmed = true
                this.visibleAttentionDialogForAcknowledge = true
              }
              await this.checkPaymentstatus()
              if (this.orderData.status != OrderStatus.PENDING) {
                this.orderData.status = this.orderItems.every((item) => this.statuses.includes(item.deliveryStatus)) ? this.orderItems.some(item => item.deliveryStatus === ProductDeliveryStatus.Delivered) ? OrderStatus.DELIVERED : OrderStatus.CANCELLED :
                  this.orderItems.some((item) => item.deliveryStatus == ProductDeliveryStatus.Received) ? OrderStatus.CONFIRMED : this.orderItems.every((item) => item.deliveryStatus == ProductDeliveryStatus.Cancelled) ? OrderStatus.CANCELLED :
                    OrderStatus.PROCESSING
              }
              this.awsApi.loading.next(false);
            }).catch((error) => {
              this.awsApi.loading.next(false);
            });
        }
      })
    } catch (error) { }
  }

  async getOrderItemValues() {
    let totalValue = 0
    this.couponDetails = this.orderData?.couponDetails?.amount ? this.orderData?.couponDetails : null
    this.orderItems.forEach((orderItem, index) => {
      this.itemValue[index] = (parseFloat(orderItem.itemDetail.price) * parseFloat(orderItem.quantity))
      totalValue += parseFloat(this.itemValue[index])
    })
    this.orderItems.forEach((orderItem, index) => {
      if (this.couponDetails?.discount?.type == 'percentage' &&
        ((this.couponDetails?.advancedSetting?.appliedTo == 'customers' ||
          (this.couponDetails?.advancedSetting?.appliedTo == 'products' &&
            this.couponDetails?.advancedSetting?.specificType.filter((item) => item.id == orderItem.mainCategoryId))) ||
          (!this.couponDetails?.advancedSetting?.appliedTo || this.couponDetails?.advancedSetting?.appliedTo == '' || this.couponDetails?.advancedSetting?.appliedTo == null))) {
        this.itemValue[index] -= (this.itemValue[index] * parseFloat(this.couponDetails?.discount?.value)) / 100
      }
      if (this.couponDetails?.discount?.type == 'amount' &&
        ((this.couponDetails?.advancedSetting?.appliedTo == 'customers') ||
          (!this.couponDetails?.advancedSetting?.appliedTo || this.couponDetails?.advancedSetting?.appliedTo == '' || this.couponDetails?.advancedSetting?.appliedTo == null))) {
        this.itemValue[index] -= (parseFloat(this.itemValue[index]) / totalValue) * parseFloat(this.couponDetails?.discount.value)
      }
      this.itemValue[index] += (parseFloat(orderItem.quantity) * parseFloat(orderItem.taxAmount))
    })
  }

  async getCoupon(filterObject: any = {}) {
    let items = [];
    let nextToken = null;
    do {
      const response = await this.awsApi.client.graphql({
        query: query.listPromoCodes,
        variables: {
          filter: filterObject,
          limit: 650,
          nextToken: nextToken,
          sortDirection: null
        }
      })
      items = items.concat(response.data.listPromoCodes.items);
      nextToken = response.data.listPromoCodes.nextToken;
    } while (nextToken !== null)
    return items
  }

  async checkPaymentstatus() {
    if ((this.orderData?.paymentStatus == this.statusList.paymentConfirmed && this.amountLeftToPay > 0.00) ||
      (this.orderData?.paymentStatus == this.statusList.paymentPending &&
        this.orderData?.status == this.statusList.processing &&
        (this.orderData?.paymentDetails?.paymentMethod == PaymentMethod.CashOnDelivery || this.orderData?.paymentDetails?.paymentMethod == 'CashOnDelivery'))) {
      await this.isOrderPaymentPending()
    }
  }

  async isOrderPaymentPending() {
    try {
      _.each(this.orderItems, async (orderItem) => {
        if (((orderItem.deliveryStatus == this.statusList.packing &&
          this.orderData.deliveryInfo?.deliveryType == this.deliveryTypeList.selfPickup) ||
          (orderItem.deliveryStatus == this.statusList.outForDelivery &&
            this.orderData.deliveryInfo?.deliveryType == this.deliveryTypeList.doorstep)) && this.updateToStatus === null) {
          this.isPaymentPending = true
          this.showAttentionDialog()
        }
      })
      this.updatePaymentEnabled = this.orderData.deliveryInfo?.deliveryType == this.deliveryTypeList.selfPickup ?
        this.orderItems.some((item) => item.deliveryStatus == this.statusList.packing) :
        this.orderItems.some((item) => item.deliveryStatus == this.statusList.outForDelivery)
    } catch (error) { }
  }

  onChangePaymentReceived() {
    this.isPaymentReceived = this.updatePaymentForm.controls.isPaymentReceived.value
    if (!this.isPaymentReceived) {
      Object.keys(this.updatePaymentForm.controls).forEach(key => {
        if (key !== 'isPaymentReceived') {
          this.updatePaymentForm.controls[key].reset();
        }
      });
      this.selectedPaymentMethod = null
    }
  }

  async onSelectPaymentMethod() {
    await this.getAmountLeftToPay()
    this.selectedPaymentMethod = this.updatePaymentForm.controls.method.value
    this.updatePaymentForm.patchValue({
      amount: this.amountLeftToPay.toString()
    })
  }

  async getPaymentUpdateData(type: any): Promise<any> {
    let data
    let partialPaymentDetailsObj
    let partialPaymentDetailsList = []
    delete this.orderData.paymentDetails.__typename
    data = {
      pk: this.orderData.pk,
      id: this.orderData.id,
      paymentStatus: PaymentStatus.Confirmed
    }
    try {
      if (this.orderData?.paymentDetails?.receivedAmount) { // payment updated once
        // checking if any more payments updated
        let cashOnDeliveryCollection = this.orderData?.paymentDetails?.partialPaymentDetails ?? []
        cashOnDeliveryCollection.forEach(partialPaymentDetail => {
          delete partialPaymentDetail.__typename
          partialPaymentDetailsList.push(partialPaymentDetail)
        });
        if (type == this.paymentMethodList[1]) {
          partialPaymentDetailsObj = {
            chequeNumber: this.updatePaymentForm.controls.chequeNumber.value,
            bankName: this.updatePaymentForm.controls.bankName.value,
            chequeDate: new Date(this.updatePaymentForm.controls.chequeDate.value).toISOString(),
            receivedAmount: parseFloat(this.updatePaymentForm.controls.amount.value),
            chequeStatus: this.updatePaymentForm.controls.status.value,
            updatedAt: new Date().toISOString(),
            updatedBy: localStorage.getItem('userName').toString() + '#' + localStorage.getItem('role').toString()
          }
        } else {
          partialPaymentDetailsObj = {
            paymentDate: new Date().toISOString(),
            receivedAmount: parseFloat(this.updatePaymentForm.controls.amount.value),
            updatedAt: new Date().toISOString(),
            updatedBy: localStorage.getItem('userName').toString() + '#' + localStorage.getItem('role').toString()
          }
        }
        partialPaymentDetailsList.push(partialPaymentDetailsObj)
        data['paymentDetails'] = this.getPaymentDetails({
          partialPaymentDetails: partialPaymentDetailsList
        })
      }
      else {
        let initialPaymentDetails = {}
        if (type == this.paymentMethodList[1]) {
          initialPaymentDetails = {
            paymentDate: new Date().toISOString(),
            chequeNumber: this.updatePaymentForm.controls.chequeNumber.value,
            bankName: this.updatePaymentForm.controls.bankName.value,
            chequeDate: new Date(this.updatePaymentForm.controls.chequeDate.value).toISOString(),
            receivedAmount: parseFloat(this.updatePaymentForm.controls.amount.value),
            chequeStatus: this.updatePaymentForm.controls.status.value
          }
        } else {
          initialPaymentDetails = {
            paymentDate: new Date().toISOString(),
            receivedAmount: parseFloat(this.updatePaymentForm.controls.amount.value)
          }
        }
        data['paymentDetails'] = this.getPaymentDetails(initialPaymentDetails)
      }
      return data
    }
    catch (error) { }
  }

  async findReceivedAmount() {
    try {
      this.receivedAmount = this.orderData?.paymentDetails?.chequeStatus == ChequeStatus.Bounced ? 0 : this.orderData?.paymentDetails?.receivedAmount
      if (this.orderData?.paymentDetails?.partialPaymentDetails) {
        this.orderData?.paymentDetails?.partialPaymentDetails.forEach(paymentDetail => {
          this.receivedAmount += paymentDetail?.chequeStatus == ChequeStatus.Bounced ? 0 : paymentDetail.receivedAmount
        });
      }
      this.receivedAmount = parseFloat(this.receivedAmount).toFixed(2)
    } catch (error) { }
  }

  async updateCashOnDeliveryPayment() {
    let data = null
    let message = ''

    this.awsApi.loading.next(true);
    if (this.selectedPaymentMethod == null) {
      message = Messages.MISSING_PAYMENT_METHOD
    }
    else {
      if ((this.selectedPaymentMethod == this.paymentMethodList[1] &&
        (!this.updatePaymentForm.controls.chequeNumber.value ||
          !this.updatePaymentForm.controls.amount.value ||
          !this.updatePaymentForm.controls.bankName.value ||
          !this.updatePaymentForm.controls.chequeDate.value ||
          !this.updatePaymentForm.controls.status.value)) ||
        (this.selectedPaymentMethod == this.paymentMethodList[0] &&
          !this.updatePaymentForm.controls.amount.value)) {
        message = Messages.PLEASE_FILL_REQUIRED_FIELDS
      }
      else {
        if (parseFloat(this.updatePaymentForm.controls.amount.value) > this.amountLeftToPay) {
          this.updatePaymentForm.get('amount').setValue(this.amountLeftToPay)
          message = 'Amount should not exceed ' + this.currency + ' ' + this.amountLeftToPay
        }
        if (parseFloat(this.updatePaymentForm.controls.amount.value) <= 0.00) {
          message = 'Invalid amount'
        }
        if (message == '') {
          data = await this.getPaymentUpdateData(this.selectedPaymentMethod)
        }
      }

      if (data != null) {
        try {
          this.paymentDetails = data.paymentDetails
          this.orderData.paymentDetails = data.paymentDetails
          await this.updateOrder(data);
          await this.findReceivedAmount()
          await this.getAmountLeftToPay()
          this.awsApi.loading.next(false);
          this.displayUpdatePaymentModalToggle = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: Messages.PAYMENT_UPDATED
          });
          this.isPaymentPending = false
          this.updatePaymentEnabled = false
          this.statusUpdated = false
          this.orderData['paymentStatus'] = PaymentStatus.Confirmed
          this.orderData['paymentDetails'] = data.paymentDetails
        } catch (error) { }
      }
    }
    if (message != '') {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: message
      });
    }
  }

  getPaymentDetail(index: any) {
    this.paymentDetail = this.orderData?.paymentDetails?.partialPaymentDetails[index]
  }

  getPaymentDetails(additionalData: any): any {
    if (this.orderData.paymentDetails) {
      delete this.orderData.paymentDetails.__typename
      return { ...this.orderData.paymentDetails, ...additionalData };
    }
    return additionalData;
  }

  async getPendingOrders(filterObject: any = {}) {
    let items = [];
    let nextToken = null;
    do {
      const response = await this.awsApi.client.graphql({
        query: query.listOrderItems,
        variables: {
          filter: filterObject,
          limit: 650,
          nextToken: nextToken,
          sortDirection: null
        }
      })
      items = items.concat(response.data.listOrderItems.items.filter(item =>
        item.deliveryStatus !== ProductDeliveryStatus.Cancelled &&
        item.deliveryStatus !== ProductDeliveryStatus.Delivered &&
        item.deliveryStatus !== null
      ))
      nextToken = response.data.listOrderItems.nextToken;
    } while (nextToken !== null)
    return items
  }

  async isStockSufficient(index): Promise<boolean> {
    try {
      await this.getCurrentStocks(index)
      if ((parseFloat(this.stockQuantity[index]) >= parseFloat(this.reservedStock[index])) || this.products[index].allowPreorder == false) {
        return true;
      }
      let stockQty = this.stockQuantity[index]
      let filter: any = {}
      filter['productId'] = { eq: this.orderItems[index].productId }
      if ((this.orderItems[index].attrValueID && this.orderItems[index].attrValueID != 'null')) {
        filter['attrValueID'] = { eq: this.orderItems[index].attrValueID }
      }
      let pendingOrders = await this.getPendingOrders(filter)
      if (pendingOrders.length > 0) {
        pendingOrders = pendingOrders.sort((a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
        for (let pendingOrderItemIndex = 0; pendingOrderItemIndex < pendingOrders.length; pendingOrderItemIndex++) {
          if ((stockQty < parseFloat(pendingOrders[pendingOrderItemIndex].quantity) &&
            pendingOrders[pendingOrderItemIndex].id == this.orderItems[index].id)) {
            return false
          }
          stockQty = stockQty - parseFloat(pendingOrders[pendingOrderItemIndex].quantity)
        }
        return true
      }
    } catch (error) { }
  }

  async onSelectStatus() {
    this.stockAvailable = null
    this.awsApi.loading.next(true);
    this.statusUpdated = false
    this.updateToStatus = this.doorStepDeliveryForm.controls.status.value
    let selectedIndex = this.selectedItemIndex != null ? this.selectedItemIndex : 0
    let lastUpdatedStatus = this.orderItems[selectedIndex]?.deliveryTracking?.slice(-1)[0]?.status
    if (lastUpdatedStatus == ProductDeliveryStatus.Processing && this.updateToStatus != ProductDeliveryStatus.Cancelled) {
      let stockAvailableList: any = []
      this.validationToasterEnabled = false
      for (let index = selectedIndex; index <= (this.selectedItemIndex != null ? this.selectedItemIndex : (this.orderItems.length - 1)); index++) {
        stockAvailableList[index] = await this.isStockSufficient(index)
      }
      if ((this.selectedItemIndex !== null && !stockAvailableList[this.selectedItemIndex]) ||
        (this.selectedItemIndex === null && stockAvailableList.every((item) => item == false))) {
        this.stockAvailable = false
        this.displayValidationErrorMessage(-1, Messages.INSUFFICIENT_STOCK)
        this.displayUpdateStatusModalToggle = false;
        this.doorStepDeliveryForm.reset()
      } else {
        this.stockAvailable = true
      }
    } else {
      this.stockAvailable = true
    }
    await this.getAmountLeftToPay()
    if (this.updateToStatus == ProductDeliveryStatus.Delivered && this.stockAvailable &&
      this.orderData?.paymentDetails?.paymentMethod == PaymentMethod.CashOnDelivery &&
      await this.isPaymentPartiallyCompleted()) {
      this.updatePaymentEnabled = this.orderData.deliveryInfo?.deliveryType == this.deliveryTypeList.selfPickup ?
        this.orderItems.some((item) => item.deliveryStatus == this.statusList.packing) :
        this.orderItems.some((item) => item.deliveryStatus == this.statusList.outForDelivery)
      this.doorStepDeliveryForm.reset()
      this.updatePaymentForm.reset()
      this.selectedPaymentMethod = null
      this.updateToStatus = null
      this.displayUpdateStatusModalToggle = false;
      this.showAttentionDialog()
    }
    this.deliveryVar = null
    this.awsApi.loading.next(false);
  }

  async getAmountLeftToPay() {
    // calculating cancelled value
    let cancelledOrdersValue = 0
    this.orderItems.forEach((orderItem, index) => {
      if (orderItem.deliveryStatus == ProductDeliveryStatus.Cancelled) {
        cancelledOrdersValue += this.itemValue[index]
      }
    });
    this.amountLeftToPay = (parseFloat(this.orderData?.totalAmount) - cancelledOrdersValue - this.receivedAmount).toFixed(2)
  }

  async isPaymentPartiallyCompleted() {
    if (this.amountLeftToPay == 0.00) { return false; }
    if (this.receivedAmount == 0.00) { return true; }
    if (this.selectedItemIndex !== null) {
      // calculating delivered value
      let deliveredValue = 0
      this.orderItems.forEach((orderItem, index) => {
        if (orderItem.deliveryStatus == ProductDeliveryStatus.Delivered) {
          deliveredValue += this.itemValue[index]
        }
      })
      return (parseFloat(this.receivedAmount) - deliveredValue) < (this.itemValue[this.selectedItemIndex] + (parseFloat(this.orderData?.orderSummary?.deliveryFee) || parseFloat('0.00')))
    }
    if (this.selectedItemIndex === null) {
      return (parseFloat(this.amountLeftToPay) > parseFloat('0.00'));
    }
  }

  onSelectDeliveryBy() {
    this.deliveryBy = this.doorStepDeliveryForm.controls.deliveryBy.value
    Object.keys(this.doorStepDeliveryForm.controls).forEach(key => {
      if (key !== 'status' && key !== 'deliveryBy') {
        this.doorStepDeliveryForm.controls[key].reset();
      }
    });

    this.doorStepDeliveryForm.patchValue({
      deliveryDate: (new Date().toISOString()).split('T')[0]
    })
    this.doorStepDeliveryForm.controls.expectedDeliveryDate.reset()
  }

  formatPickupDate(date): any {
    const formattedTime = new Date().getUTCHours().toString().padStart(2, '0') + ':' +
      new Date().getUTCMinutes().toString().padStart(2, '0') + ':' +
      new Date().getUTCSeconds().toString().padStart(2, '0') + '.' +
      new Date().getUTCMilliseconds().toString().padStart(3, '0') + 'Z'
    return (new Date(date).toISOString()).split('T')[0] + 'T' + formattedTime
  }

  async isCancelReasonAdded(status, index): Promise<Boolean> {
    if (status == ProductDeliveryStatus.Cancelled && !this.doorStepDeliveryForm.controls.reason?.value) {
      this.valid[index] = false
      await this.displayValidationErrorMessage(index)
      return false;
    }
    else {
      return true;
    }
  }

  async getDeliveryTrackingList(status: any, index: any) {
    this.deliveryTrackingList = []
    if (this.orderItems[index]?.deliveryTracking) {
      _.each(this.orderItems[index]?.deliveryTracking, (item) => {
        delete item.__typename
        this.deliveryTrackingList.push(item)
      })
      if (this.deliveryType == this.deliveryTypeList.selfPickup) {
        this.deliveryTrackingList.push({
          status: status,
          date: this.selfPickUpForm.controls.pickUpDate?.value ? this.formatPickupDate(this.selfPickUpForm.controls.pickUpDate.value) : new Date().toISOString(),
          trackingBy: this.selfPickUpForm.controls.name?.value ? this.selfPickUpForm.controls.name?.value : this.trackingBy,
          phoneNumber: this.selfPickUpForm.controls.phoneNumber?.value ? this.selfPickUpForm.controls.phoneNumber.value : undefined,
          remarks: status == ProductDeliveryStatus.Cancelled ?
            this.doorStepDeliveryForm.controls.reason.value : this.selfPickUpForm.controls.deliveryNotes?.value ?
              this.selfPickUpForm.controls.deliveryNotes.value : undefined,
          receipt: this.selfPickUpForm.controls.deliveryReceipt?.value ? this.selfPickUpForm.controls.deliveryReceipt.value : undefined
        })
      }
      else {
        this.deliveryTrackingList.push({
          status: status,
          date: new Date().toISOString(),
          trackingBy: localStorage.getItem('userName').toString() + '#' + localStorage.getItem('role').toString(),
          remarks: status == ProductDeliveryStatus.Cancelled ?
            this.doorStepDeliveryForm.controls.reason.value : this.selfPickUpForm.controls.deliveryNotes?.value || undefined
        })
      }
      if ((status == ProductDeliveryStatus.Cancelled || status == ProductDeliveryStatus.Delivered) && this.valid[index] === true) {
        await this.getCurrentStocks(index)
        await this.updateStockInProductsTable(status, index)
      }
    }
  }

  async updateStockInProductsTable(status, index) {
    try {
      let reservedStock = this.reservedStock[index] - parseFloat(this.orderItems[index].quantity)
      let data = {
        pk: 'PRODETAIL#',
        id: this.productDetailIds[index],
        reservedStock: reservedStock >= 0 ? reservedStock : 0
      }
      if (status == ProductDeliveryStatus.Delivered) {
        data['stockQuantity'] = parseFloat(this.stockQuantity[index]) - parseFloat(this.orderItems[index].quantity)
      }
      this.awsApi.client.graphql({
        query: mutation.updateProductDetails,
        variables: {
          input: {
            ...data
          }
        }
      }).then((response) => { }).catch((error) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      });
    } catch (error) { }
  }

  async displayValidationErrorMessage(index: any = -1, message: any = null) {
    if (index != -1) { this.valid[index] = false }
    if (!this.validationToasterEnabled) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: message ? message : Messages.PLEASE_FILL_REQUIRED_FIELDS
      });
      this.validationToasterEnabled = true
    }
  }

  async updateStatus() {
    let updatedOrderItemsId = []
    let data: any = {};
    let stockAvailable: any = [];
    let itemIndex = this.selectedItemIndex != null ? this.selectedItemIndex : 0;
    this.statusUpdated = false;
    this.validationToasterEnabled = false;
    let formValid = true;
    try {
      this.awsApi.loading.next(true);
      for (let index = itemIndex; index <= (this.selectedItemIndex != null ? this.selectedItemIndex : (this.orderItems.length - 1)); index++) {
        stockAvailable[index] = true;
        let lastUpdatedStatus = this.orderItems[index]?.deliveryTracking[this.orderItems[index]?.deliveryTracking.length - 1].status;
        if (lastUpdatedStatus == ProductDeliveryStatus.Processing && this.updateToStatus != ProductDeliveryStatus.Cancelled) {
          stockAvailable[index] = await this.isStockSufficient(index);
          if (!stockAvailable[index]) {
            formValid = false;
          }
        }
        if (stockAvailable[index] == true) {
          this.valid[index] = true;
          await this.getOrderItems().then(async (response) => {
            this.orderItems = response.data.listOrderItems.items.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            if (this.doorStepDeliveryForm.controls.status.value == ProductDeliveryStatus.OutForDelivery) {
              if (!this.doorStepDeliveryForm.controls.deliveryBy?.value) {
                this.valid[index] = false;
                formValid = false;
              } else {
                if (this.deliveryBy == this.delivery.company) {
                  if (!this.doorStepDeliveryForm.controls.name?.value ||
                    !this.doorStepDeliveryForm.controls.mobileNumber?.value ||
                    !this.doorStepDeliveryForm.controls.vehicleNumber?.value ||
                    !this.doorStepDeliveryForm.controls.deliveryDate?.value ||
                    !this.doorStepDeliveryForm.controls.expectedDeliveryDate?.value) {
                    this.valid[index] = false;
                    formValid = false;
                  }
                }
                if (this.deliveryBy == this.delivery.deliveryPartner) {
                  if (!this.doorStepDeliveryForm.controls.deliveryPartner?.value ||
                    !this.doorStepDeliveryForm.controls.trackingNumber?.value ||
                    !this.doorStepDeliveryForm.controls.trackingUrl?.value ||
                    !this.doorStepDeliveryForm.controls.deliveryDate?.value ||
                    !this.doorStepDeliveryForm.controls.expectedDeliveryDate?.value) {
                    this.valid[index] = false;
                    formValid = false;
                  }
                }
              }
              if (this.valid[index] === true) {
                await this.getDeliveryTrackingList(this.doorStepDeliveryForm.controls.status.value, index);
                data = {
                  pk: this.orderItems[index].pk,
                  id: this.orderItems[index].id,
                  deliveryBy: this.doorStepDeliveryForm.controls.deliveryBy.value,
                  deliveryStatus: this.doorStepDeliveryForm.controls.status.value,
                  deliveryTracking: this.deliveryTrackingList,
                  deliveryPartner: this.doorStepDeliveryForm.controls.deliveryBy.value == this.delivery.deliveryPartner ? this.doorStepDeliveryForm.controls.deliveryPartner?.value ? this.doorStepDeliveryForm.controls.deliveryPartner.value : undefined : undefined,
                  outForDeliveryDate: this.doorStepDeliveryForm.controls.deliveryDate?.value ? new Date(this.doorStepDeliveryForm.controls.deliveryDate.value).toISOString() : undefined,
                  expectedDeliveryDate: this.doorStepDeliveryForm.controls.expectedDeliveryDate?.value ? new Date(this.doorStepDeliveryForm.controls.expectedDeliveryDate.value).toISOString() : undefined,
                  trackingID: this.doorStepDeliveryForm.controls.deliveryBy.value == this.delivery.deliveryPartner ? this.doorStepDeliveryForm.controls.trackingNumber?.value ? this.doorStepDeliveryForm.controls.trackingNumber.value : undefined : undefined,
                  trackingUrl: this.doorStepDeliveryForm.controls.deliveryBy.value == this.delivery.deliveryPartner ? this.doorStepDeliveryForm.controls.trackingUrl?.value ? this.doorStepDeliveryForm.controls.trackingUrl.value : undefined : undefined,
                  deliveryPersonInfo: this.doorStepDeliveryForm.controls.deliveryBy.value == this.delivery.company ? {
                    name: this.doorStepDeliveryForm.controls.name?.value ? this.doorStepDeliveryForm.controls.name.value : undefined,
                    contactNumber: this.doorStepDeliveryForm.controls.mobileNumber?.value ? this.doorStepDeliveryForm.controls.mobileNumber.value : undefined,
                    vehiclenumber: this.doorStepDeliveryForm.controls.vehicleNumber?.value ? this.doorStepDeliveryForm.controls.vehicleNumber.value : undefined,
                    notes: this.doorStepDeliveryForm.controls.notes?.value ? this.doorStepDeliveryForm.controls.notes.value : undefined,
                  } : undefined,
                  createdAt: this.orderItems[index].createdAt,
                  returnValue: this.itemValue[index].toFixed(2)
                };
                await this.updateOrderItem(data, null, index);
              } else {
                this.displayValidationErrorMessage(index);
              }
            } else {
              if (this.valid[index] === true && await this.isCancelReasonAdded(this.doorStepDeliveryForm.controls.status.value, index)) {
                await this.getDeliveryTrackingList(this.doorStepDeliveryForm.controls.status.value, index);
                data = {
                  pk: this.orderItems[index].pk,
                  id: this.orderItems[index].id,
                  deliveryStatus: this.doorStepDeliveryForm.controls.status.value,
                  deliveryTracking: this.deliveryTrackingList,
                  createdAt: this.orderItems[index].createdAt,
                  returnValue: this.itemValue[index].toFixed(2)
                };
                await this.updateOrderItem(data, null, index);
                if (this.doorStepDeliveryForm.controls.status.value === ProductDeliveryStatus.Packing) {
                  updatedOrderItemsId.push(this.orderItems[index].id)
                }
              } else { formValid = false; }
            }
          });
        }
      }
      if (this.deliveryType == this.deliveryTypeList.selfPickup && this.doorStepDeliveryForm.controls.status.value === ProductDeliveryStatus.Packing) {
        let data = {
          'orderid': this.orderData.id,
          'orderitems': updatedOrderItemsId
        }
        this.common.readyforpickupnotification(data, await this.common.getHeader()).subscribe(async (result) => { });
      }
    } catch (error) { }
    if (formValid) {
      this.displayUpdateStatusModalToggle = false;
      this.displayDeliveryInfoModalToggle = false;
      this.displayDeliveryTrackingModalToggle = false
    }
    for (let index = itemIndex; index <= (this.selectedItemIndex != null ? this.selectedItemIndex : (this.orderItems.length - 1)); index++) {
      if (this.valid[index] === true) { this.clearData(); }
    }
    if ((this.selectedItemIndex !== null && !stockAvailable[this.selectedItemIndex]) ||
      (!this.selectedItemIndex === null && stockAvailable.every((item) => !item))) {
      this.displayValidationErrorMessage(-1, Messages.INSUFFICIENT_STOCK);
    }
    if (!this.validationToasterEnabled) { this.clearData(); }
    await this.startingFunction();
  }

  async onSelectDeliveredStatus() {
    let selectedIndex = this.selectedItemIndex != null ? this.selectedItemIndex : 0
    let lastUpdatedStatus = this.orderItems[selectedIndex]?.deliveryTracking?.slice(-1)[0]?.status
    try {
      await this.getAmountLeftToPay()
      if (this.selfPickUpForm.controls.status.value == ProductDeliveryStatus.Delivered &&
        lastUpdatedStatus == ProductDeliveryStatus.Packing) {
        if (await this.isPaymentPartiallyCompleted()) {
          this.updatePaymentEnabled = this.orderData.deliveryInfo?.deliveryType == this.deliveryTypeList.selfPickup ?
            this.orderItems.some((item) => item.deliveryStatus == this.statusList.packing) :
            this.orderItems.some((item) => item.deliveryStatus == this.statusList.outForDelivery)
          this.showAttentionDialog()
        }
        else {
          this.updateToStatus = this.selfPickUpForm.controls.status.value
        }
      }
    } catch (error) { }
  }

  async saveDeliveryInfo() {
    this.statusUpdated = false
    let selectedIndex = this.selectedItemIndex != null ? this.selectedItemIndex : 0
    try {
      this.awsApi.loading.next(true);
      for (let index = selectedIndex; index <= (this.selectedItemIndex != null ? this.selectedItemIndex : (this.orderItems.length - 1)); index++) {
        await this.getDeliveryTrackingList(ProductDeliveryStatus.Delivered, index)

        let data = {
          pk: this.orderItems[index].pk,
          id: this.orderItems[index].id,
          deliveryStatus: ProductDeliveryStatus.Delivered,
          deliveryTracking: this.deliveryTrackingList,
          createdAt: this.orderItems[index].createdAt,
          returnValue: this.itemValue[index].toFixed(2)
        }
        await this.updateOrderItem(data, null, index)
      }
    } catch (error) { }
    this.clearData()
    await this.startingFunction()
  }

  async getCurrentStocks(index) {
    try {
      let filterObject: any = {}
      filterObject['productID'] = { eq: this.orderItems[index].productId }
      if (this.orderItems[index].attrValueID && this.orderItems[index].attrValueID != 'null') {
        filterObject['attrValueID'] = { eq: this.orderItems[index].attrValueID }
      }
      let response = await this.common.getProductDetailsWithEditQuery(filterObject, ['pk', 'id', 'stockQuantity', 'reservedStock'])
      let prodDetail: any
      if (response.length > 0) {
        prodDetail = response[0]
      }
      if (response.length == 0) {
        prodDetail = null
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      }
      else {
        this.stockQuantity[index] = prodDetail ? parseFloat(prodDetail.stockQuantity) : 0
        this.reservedStock[index] = prodDetail ? parseFloat(prodDetail.reservedStock) : 0
      }
    } catch (error) { }
  }

  async getProductDetails() {
    if (!(this.orderData?.status == OrderStatus.DELIVERED || this.orderData?.status == OrderStatus.CANCELLED)) {
      _.each(this.orderItems, async (item, index) => {
        try {
          let prodDetail: any = null
          let filterObject: any = {}
          filterObject['productID'] = { eq: item.productId }
          if (this.orderItems[index].attrValueID && this.orderItems[index].attrValueID != 'null') {
            filterObject['attrValueID'] = { eq: this.orderItems[index].attrValueID }
          }
          let response = await this.common.getProductDetailsWithEditQuery(filterObject, ['pk', 'id', 'productID', 'storageInfo {', 'wareHouseId', 'wareHouse', 'rackNo', 'rowNo', 'binNo', 'note', '__typename', '}'])
          if (response.length > 0) {
            prodDetail = response[0]
            this.productDetailIds[index] = prodDetail ? prodDetail.id : null
            this.storageInfo[index] = prodDetail ? prodDetail.storageInfo : null
          }
          let resp = (await this.getProductById(prodDetail.productID, this.orderItems[index].subCategoryId)).data.getProduct
          if (resp) {
            this.products[index] = resp
          }
        } catch (error) { }
      })
    }
  }

  async getProductById(prodId: any, subCategoryId: any) {
    return await this.awsApi.client.graphql({
      query: query.getProduct,
      variables: {
        pk: 'PROD#' + subCategoryId,
        id: prodId
      }
    })
  }

  async acknowledgeOrder() {
    this.awsApi.loading.next(true);
    try {
      let data: any = {}
      this.statusUpdated = false
      data = {
        pk: this.orderData.pk,
        id: this.orderData.id,
        status: OrderStatus.PROCESSING
      }
      await this.updateOrder(data).then((response) => {
        this.isOrderConfirmed = false
        this.orderData.status = OrderStatus.PROCESSING
      })
      _.each(this.orderItems, async (item, index) => {
        if (this.orderItems[index].deliveryTracking.slice(-1)[0]?.status == ProductDeliveryStatus.Received) {
          await this.getDeliveryTrackingList(ProductDeliveryStatus.Processing, index)
          data = {
            pk: item.pk,
            id: item.id,
            deliveryStatus: ProductDeliveryStatus.Processing,
            deliveryTracking: this.deliveryTrackingList,
            createdAt: this.orderItems[index].createdAt
          };
          await this.updateOrderItem(data, 'acknowledge').then((response) => {
            this.orderItems[index].deliveryStatus = ProductDeliveryStatus.Processing
          })
        }
      })
      this.awsApi.loading.next(false)
      if (this.orderData?.status == this.statusList.confirmed) {
        this.isOrderConfirmed = true
      }
    } catch (error) { }
  }

  async updateOrderItem(data: any, type: any = null, selectedIndex: any = -1) {
    try {
      this.displayUpdateStatusModalToggle = false;
      this.displayDeliveryInfoModalToggle = false;
      this.displayDeliveryTrackingModalToggle = false
      this.awsApi.client.graphql({
        query: mutation.updateOrderItem,
        variables: {
          input: {
            ...data
          }
        }
      }).then(async (response) => {
        if (selectedIndex > -1) {
          this.orderItems[selectedIndex].deliveryStatus = data.deliveryStatus
        }
        else {
          this.orderItems.forEach(item => item.deliveryStatus = data.deliveryStatus);
        }
        await this.startingFunction()
        if (type != 'acknowledge' && !this.statusUpdated) {
          this.statusUpdated = true
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: Messages.STATUS_UPDATED
          });
        }
      }).catch((error) => {
        if (this.selectedItemIndex != null || !this.statusUpdated) {
          this.awsApi.loading.next(false);
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: Messages.SOMETHING_WENT_WRONG
          });
          this.statusUpdated = true
        }
      });
    }
    catch (error) { }
  }

  async updateOrder(data: any) {
    this.awsApi.client.graphql({
      query: mutation.updateOrder,
      variables: {
        input: {
          ...data
        }
      }
    }).then((response) => {
      this.awsApi.loading.next(false);
    }).catch((error) => {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    });
  }

  clearData() {
    this.updateToStatus = null
    this.updatePaymentForm.reset()
    this.selectedPaymentMethod = null
    this.updatedStatusList = []
    this.doorStepDeliveryForm.reset()
    this.selfPickUpForm.reset()
  }

  redirectToOrdersListPage() {
    this.router.navigate([ROUTES.ORDER_LIST]);
  }

  getCurrentDate(): string {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    return currentDate.toISOString().split('T')[0];
  }

  async fileSave(event: any) {
    this.awsApi.loading.next(true);
    if (event.target instanceof HTMLInputElement && event.target.files) {
      let file = event.target.files[0];
      let isValid = await this.common.isValidFileType(event)
      if (!isValid) { return; }
      try {
        this.awsApi.loading.next(false);
        let imageUrl = await this.common.getS3Url(file)
        this.selfPickUpForm.patchValue({
          deliveryReceipt: imageUrl
        })
      } catch (error) {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      }
    }
  }

  deleteImage() {
    this.selfPickUpForm.controls.deliveryReceipt.reset();
    (document.getElementById('image-upload') as HTMLInputElement).value = '';
  }

  setStatusList() {
    let index = this.selectedItemIndex ? this.selectedItemIndex : 0
    _.each(this.orderItemStatusList, (item) => {
      if (this.orderItems[index].deliveryStatus == item['value']) {
        if (this.deliveryType == this.deliveryTypeList.selfPickup) {
          this.updatedStatusList = this.orderItemStatusList.slice(parseInt(this.orderItemStatusList.indexOf(item)) + 1, 3)
          this.updatedStatusList.push(this.orderItemStatusList[this.orderItemStatusList.length - 1])
        }
        else {
          this.updatedStatusList = this.orderItemStatusList.slice(parseInt(this.orderItemStatusList.indexOf(item)) + 1)
        }
      }
    })
  }

  async getDeliveryTrackingDetails() {
    let index: any, trackingItem, statusList: any = []
    this.deliveryTrackingList = []
    this.getOrderItems().then(async (response) => {
      this.orderItems = response.data.listOrderItems.items.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      _.each(this.orderItemStatusList, (item) => {
        trackingItem = this.orderItems[this.selectedItemIndex].deliveryTracking.find(trackingItem => trackingItem.status === item['value'])
        index = this.orderItems[this.selectedItemIndex]?.deliveryTracking.indexOf(trackingItem)
        if (index >= 0) {
          this.deliveryTrackingList.push({
            deliveryStatus: this.orderItems[this.selectedItemIndex]?.deliveryTracking[index].status,
            trackingListIndex: index,
            isUpdated: true
          })
        }
      })
      if (this.deliveryTrackingList[this.deliveryTrackingList.length - 1].deliveryStatus != this.statusList.delivered ||
        this.deliveryTrackingList[this.deliveryTrackingList.length - 1].deliveryStatus != this.statusList.cancelled) {
        _.each(this.orderItemStatusList, (item) => {
          if (this.deliveryTrackingList[this.deliveryTrackingList.length - 1].deliveryStatus == item['value']) {
            statusList = this.orderItemStatusList.slice(parseInt(this.orderItemStatusList.indexOf(item)) + 1, this.orderItemStatusList.length - 1)
          }
        })
        _.each(statusList, (item) => {
          this.deliveryTrackingList.push({
            deliveryStatus: item.value,
            isUpdated: false
          })
        })
        if (this.deliveryType == this.deliveryTypeList.selfPickup) {
          this.deliveryTrackingList = this.deliveryTrackingList.filter(({ deliveryStatus }) => deliveryStatus !== this.statusList.outForDelivery);
        }
      }
    })
  }

  closeUpdateStatusModalDialog() {
    this.displayUpdateStatusModalToggle = false;
    this.clearData()
  }

  findIndex(index) {
    if (index == -1) {
      this.selectedItemIndex = null
      this.updatedAt = (new Date(Math.max(...(this.orderItems.map((item) => ({ date: item.deliveryTracking.slice(-1)[0]?.date })))
        .map(item => new Date(item.date).getTime())))).toISOString();
    }
    else {
      this.selectedItemIndex = index
      this.updatedAt = this.orderItems[this.selectedItemIndex].deliveryTracking.slice(-1)[0]?.date
    }
  }

  showUpdateStatusDoorDeliveryModalDialog(position: string, index: any = -1) {
    this.position = position;
    this.deliveryType = this.deliveryTypeList.doorstep
    this.displayUpdateStatusModalToggle = true;
    this.findIndex(index)
    this.updatedStatusList = []
    this.setStatusList()
  }

  showUpdateStatusSelfPickUpModalDialog(position: string, index: any = -1) {
    this.position = position;
    this.deliveryType = this.deliveryTypeList.selfPickup
    this.findIndex(index)
    if ((this.selectedItemIndex !== null && this.orderItems[this.selectedItemIndex].deliveryStatus == this.statusList.packing) ||
      (this.selectedItemIndex === null && this.orderItems.every((item) => item.deliveryStatus === this.statusList.packing))) {
      this.displayDeliveryInfoModalToggle = true;
      this.autoFillSelfPickupForm()
    }
    else {
      this.displayUpdateStatusModalToggle = true;
      this.updatedStatusList = []
      this.setStatusList()
    }
  }

  autoFillSelfPickupForm() {
    this.selfPickUpForm.patchValue({
      name: this.orderItems[this.selectedItemIndex != null ? this.selectedItemIndex : 0].userName,
      phoneNumber: this.orderItems[this.selectedItemIndex != null ? this.selectedItemIndex : 0].phoneNumber != 'null' ? this.orderItems[this.selectedItemIndex != null ? this.selectedItemIndex : 0].phoneNumber : null
    })
  }

  closeDeliveryInfoModalDialog() {
    this.displayDeliveryInfoModalToggle = false;
    this.clearData()
  }

  async showDeliveryTrackingModalDialog(position: string, index: any) {
    this.deliveryType = this.orderData.deliveryInfo?.deliveryType ? this.orderData.deliveryInfo?.deliveryType : undefined
    if (this.deliveryType != undefined) {
      this.position = position;
      this.displayDeliveryTrackingModalToggle = true;
      this.selectedItemIndex = index
      await this.getDeliveryTrackingDetails()
    }
  }

  closeDeliveryTrackingModalDialog() {
    this.displayDeliveryTrackingModalToggle = false;
  }

  showAttentionDialog() {
    this.visibleAttentionDialog = true;
  }

  closeAttentionDialog() {
    this.visibleAttentionDialog = false;
  }

  async showUpdatePaymenModalDialog(position: string) {
    this.selfPickUpForm.reset()
    this.updatePaymentForm.reset()
    this.selectedPaymentMethod = null
    this.isPaymentReceived = false
    this.updateToStatus = null
    this.displayDeliveryInfoModalToggle = false;
    this.position = position;
    this.displayUpdatePaymentModalToggle = true;
    this.visibleAttentionDialog = false;
  }

  acknowledge() {
    this.acknowledgeOrder()
    this.visibleAttentionDialogForAcknowledge = false;
  }

  closeAttentionDialogForAcknowledge() {
    this.visibleAttentionDialogForAcknowledge = false;
  }

  closeUpdatePaymenModalDialog() {
    this.displayUpdatePaymentModalToggle = false;
    this.updatePaymentForm.reset()
    this.selectedPaymentMethod = null
    this.isPaymentReceived = false
  }

  showInvoice() {
    if (this.orderData.invoiceURL != '') {
      window.open(this.orderData.invoiceURL, '_blank')
    }
    else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Invoice not found!'
      });
    }
  }

  async showOrderSummary() {
    let data: any = {};
    data['orderid'] = this.orderId
    this.common.orderDetailsPdf(data, await this.common.getHeader()).subscribe((result) => {
      if (result.message == 'Success' && result.objecturl) {
        window.open(result.objecturl, '_blank')
      }
    })
  }

  formatDateString(date): any {
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months start at 0
    const dd = String(date.getDate()).padStart(2, '0');
    return yyyy.toString() + '-' + mm + '-' + dd.toString();
  }

  getToday(): string {
    const today = new Date();
    return this.formatDateString(today)
  }

  getExpectedDeliveryDateMin() {
    const date = this.doorStepDeliveryForm.controls.deliveryDate.value ? new Date(this.doorStepDeliveryForm.controls.deliveryDate.value) : new Date();
    return this.formatDateString(date)
  }

  getLastUpdatedStatusDate() {
    this.selfPickUpForm.patchValue({
      pickUpDate: new Date().toISOString().split('T')[0]
    })
    return this.updatedAt.split('T')[0]
  }

  onOutForDeliveryDateChange(date: any) {
    if (date) {
      this.doorStepDeliveryForm.patchValue({
        expectedDeliveryDate: date.split('T')[0]
      })
    }
  }

  preventE(event) {
    if (event.which === 101) {
      event.preventDefault();
    }
  }

  isAllStatusSame(): boolean {
    if (this.orderItems.length === 0) return false
    const statuses = new Set(this.orderItems.map(item => item.deliveryStatus));
    const hasDelivered = statuses.has(ProductDeliveryStatus.Delivered);
    const hasCancelled = statuses.has(ProductDeliveryStatus.Cancelled);
    if (hasDelivered && hasCancelled && statuses.size === 2) {
      return false;
    }
    let allStatusSame: boolean = this.orderItems.every((item) => item.deliveryStatus === this.orderItems[0].deliveryStatus)
    return allStatusSame &&
      !statuses.has(ProductDeliveryStatus.Delivered) &&
      !statuses.has(ProductDeliveryStatus.Cancelled)
  }
}