<section id="orderDetailsSec">
    <div class="container-fluid">
        <div class="row mt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card heading-card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex justify-content-start align-items-center">
                                        <i class="fas fa-chevron-left" (click)="redirectToOrdersListPage()"></i>
                                        <h6>{{orderId}}</h6>
                                    </div>
                                    <div class="d-flex justify-content-end align-items-center">
                                        <label class="mb-0">Total: <span>{{currency}} {{ orderData?.totalAmount |
                                                number: '.2'
                                                }}</span></label>
                                        <div class="dropdown dropstart" *ngIf="orderData?.status != statusList.pending">
                                            <button class="btn" type="button" id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                <i class="fas fa-print print-icon"></i>
                                            </button>
                                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li><a class="dropdown-item" target="_blank"
                                                        (click)="showInvoice()">Invoice</a></li>
                                                <li><a class="dropdown-item" target="_blank"
                                                        (click)="showOrderSummary()">Order</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mt-2 order-details-card">
                    <div class="card-header">
                        <div class="d-flex justify-content-between align-items-center">
                            <h2>General Information</h2>
                            <button [disabled]="!access['edit']" *ngIf="orderData?.status == statusList.confirmed"
                                type="button" class="btn acknowledge-btn" (click)="acknowledgeOrder()"> Acknowledge
                            </button>
                            <button [disabled]="!access['edit']" *ngIf="orderData?.status == statusList.processing && !updatePaymentEnabled &&
                                !(updateToStatus==statusList.delivered && statusUpdated) &&
                            isAllStatusSame() && orderData?.deliveryInfo.deliveryType === deliveryTypeList.doorstep"
                                type="button" class="btn acknowledge-btn"
                                (click)="showUpdateStatusDoorDeliveryModalDialog('right', -1)">
                                Update Status
                            </button>
                            <button [disabled]="!access['edit']" *ngIf="orderData?.status == statusList.processing && !updatePaymentEnabled &&
                                !(updateToStatus==statusList.delivered && statusUpdated) &&
                            isAllStatusSame() && orderData?.deliveryInfo.deliveryType === deliveryTypeList.selfPickup"
                                type="button" class="btn acknowledge-btn"
                                (click)="showUpdateStatusSelfPickUpModalDialog('right', -1)">
                                Update Status
                            </button>
                            <button [disabled]="!access['edit']" *ngIf="updatePaymentEnabled && isAllStatusSame()"
                                type="button" class="btn acknowledge-btn"
                                (click)="showUpdatePaymenModalDialog('right')"> Update
                                Payment
                            </button>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row mt-3">
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 mb-2">
                                <label>Customer Name</label>
                                <p *ngIf="orderData?.userName">{{ orderData?.userName }}</p>
                                <p *ngIf="!orderData?.userName">N/A</p>
                            </div>
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 mb-2">
                                <label>Ordered On</label>
                                <p>{{orderData?.createdAt | date: 'dd/MM/yyyy' : 'UTC'}}, {{orderData?.createdAt | date:'shortTime'}}</p>
                            </div>
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 mb-2">
                                <label>Delivery Type</label>
                                <p *ngIf="orderData?.deliveryInfo?.deliveryType">{{
                                    orderData?.deliveryInfo?.deliveryType }}</p>
                                <p *ngIf="!orderData?.deliveryInfo?.deliveryType==null">N/A</p>
                            </div>
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 mb-2">
                                <label>Email</label>
                                <p>{{orderData?.email}}</p>
                            </div>
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 mb-2">
                                <label>Phone</label>
                                <p
                                    *ngIf="orderData?.phoneNumber && orderData?.phoneNumber !='undefined' && orderData?.phoneNumber !='null'">
                                    {{orderData?.phoneNumber}}</p>
                                <p
                                    *ngIf="!orderData?.phoneNumber || orderData?.phoneNumber=='undefined' || orderData?.phoneNumber=='null'">
                                    N/A</p>
                            </div>
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 mb-2">
                                <label>Order Status</label>
                                <p>{{ orderData?.status | formatDocType }}</p>
                            </div>
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 mb-2">
                                <label>Payment Method</label>
                                <p *ngIf="orderData?.paymentDetails?.paymentMethod">{{
                                    orderData?.paymentDetails?.paymentMethod | formatDocType }}</p>
                                <p *ngIf="!orderData?.paymentDetails?.paymentMethod">N/A</p>
                            </div>
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 mb-2"
                                *ngIf="orderData?.paymentStatus">
                                <label>Payment Status</label>
                                <p *ngIf="orderData?.paymentStatus =='Confirmed' && amountLeftToPay>0.00">
                                    Partially Received</p>
                                <p
                                    *ngIf="orderData?.paymentStatus =='Confirmed' && (amountLeftToPay==0.00 || amountLeftToPay<0.00)">
                                    Completed
                                </p>
                                <p *ngIf="orderData?.paymentStatus !='Confirmed'">{{ orderData?.paymentStatus |
                                    formatDocType }}</p>
                            </div>
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 mb-2"
                                *ngIf="receivedAmount">
                                <label>Received Amount</label>
                                <p><span *ngIf="orderData?.paymentStatus!='Pending'">
                                        {{currency}} {{receivedAmount | number: '.2'}}
                                    </span>
                                    <span *ngIf="orderData?.paymentStatus=='Pending'">
                                        N/A
                                    </span>
                                </p>
                            </div>
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 mb-2"
                                *ngIf="amountLeftToPay<0.00">
                                <label>Return Amount</label>
                                <p><span *ngIf="orderData?.paymentStatus!='Pending'">
                                        {{currency}} {{returnAmount | number: '.2'}}
                                    </span>
                                </p>
                            </div>
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 mb-2">
                                <label>Paid On</label>
                                <p>
                                    <span
                                        *ngIf="orderData?.paymentStatus!='Pending' && orderData?.paymentDetails?.paymentDate && orderData?.paymentDetails?.partialPaymentDetails">
                                        {{
                                        orderData?.paymentDetails?.partialPaymentDetails[orderData?.paymentDetails?.partialPaymentDetails.length-1].updatedAt
                                        | date: 'dd/MM/yyyy' : 'UTC' }}
                                    </span>
                                    <span
                                        *ngIf="orderData?.paymentStatus=='Pending' || !orderData?.paymentDetails?.paymentDate">N/A</span>
                                    <span
                                        *ngIf="orderData?.paymentStatus!='Pending' && orderData?.paymentDetails?.paymentDate && !orderData?.paymentDetails?.partialPaymentDetails">
                                        {{
                                        orderData?.paymentDetails?.paymentDate | date: 'dd/MM/yyyy' : 'UTC' }}
                                    </span>
                                </p>
                            </div>
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 mb-2">
                                <label>Order Reference</label>
                                <p>{{orderId}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card billing-details-card">
                    <div class="card-header">
                        <h2>Address</h2>
                    </div>
                    <div class="card-body">
                        <div class="row mt-2">
                            <div class="col-md-6 mb-2">
                                <h6>BILLING ADDRESS</h6>
                                <div class="bill-ship-details">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>Building/Flat No :</td>
                                                <th *ngIf="orderData?.billingAddress.addressLine1">
                                                    {{orderData?.billingAddress.addressLine1}}</th>
                                                <th *ngIf="!orderData?.billingAddress.addressLine1">N/A</th>
                                            </tr>
                                            <tr>
                                                <td>Street :</td>
                                                <th *ngIf="orderData?.billingAddress.street">
                                                    {{orderData?.billingAddress.street}}</th>
                                                <th *ngIf="!orderData?.billingAddress.street">N/A</th>
                                            </tr>
                                            <tr>
                                                <td>Nearest Landmark :</td>
                                                <th *ngIf="orderData?.billingAddress.landmark">
                                                    {{orderData?.billingAddress.landmark}}</th>
                                                <th *ngIf="!orderData?.billingAddress.landmark">N/A</th>
                                            </tr>
                                            <tr>
                                                <td>City :</td>
                                                <th *ngIf="orderData?.billingAddress.city">
                                                    {{orderData?.billingAddress.city}}</th>
                                                <th *ngIf="!orderData?.billingAddress.city">N/A</th>
                                            </tr>
                                            <tr>
                                                <td>Postal Code :</td>
                                                <th *ngIf="orderData?.billingAddress.postalCode">
                                                    {{orderData?.billingAddress.postalCode}}</th>
                                                <th *ngIf="!orderData?.billingAddress.postalCode">N/A</th>
                                            </tr>
                                            <tr>
                                                <td>Country :</td>
                                                <th *ngIf="orderData?.billingAddress.country">
                                                    {{orderData?.billingAddress.country}}</th>
                                                <th *ngIf="!orderData?.billingAddress.country">N/A</th>
                                            </tr>
                                            <tr>
                                                <td>Phone Number :</td>
                                                <th *ngIf="orderData?.billingUserDetails.phoneNumber">
                                                    {{orderData?.billingUserDetails.phoneNumber}}</th>
                                                <th *ngIf="!orderData?.billingUserDetails.phoneNumber">N/A</th>
                                            </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="col-md-6 mb-2"
                                *ngIf="orderData?.deliveryInfo?.deliveryType == deliveryTypeList.doorstep">
                                <h6>SHIPPING ADDRESS</h6>
                                <div class="bill-ship-details">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>Building/Flat No :
                                                </td>
                                                <th *ngIf="orderData?.shippingAddress.addressLine1">
                                                    {{orderData?.shippingAddress.addressLine1}}</th>
                                                <th *ngIf="!orderData?.shippingAddress.addressLine1">N/A</th>
                                            </tr>
                                            <tr>
                                                <td>Street :</td>
                                                <th *ngIf="orderData?.shippingAddress.street">
                                                    {{orderData?.shippingAddress.street}}</th>
                                                <th *ngIf="!orderData?.shippingAddress.street">N/A</th>
                                            </tr>
                                            <tr>
                                                <td>Nearest Landmark :</td>
                                                <th *ngIf="orderData?.shippingAddress.landmark">
                                                    {{orderData?.shippingAddress.landmark}}</th>
                                                <th *ngIf="!orderData?.shippingAddress.landmark">N/A</th>
                                            </tr>
                                            <tr>
                                                <td>City :</td>
                                                <th *ngIf="orderData?.shippingAddress.city">
                                                    {{orderData?.shippingAddress.city}}</th>
                                                <th *ngIf="!orderData?.shippingAddress.city">N/A</th>
                                            </tr>
                                            <tr>
                                                <td>Postal Code :</td>
                                                <th *ngIf="orderData?.shippingAddress.postalCode">
                                                    {{orderData?.shippingAddress.postalCode}}</th>
                                                <th *ngIf="!orderData?.shippingAddress.postalCode">N/A</th>
                                            </tr>
                                            <tr>
                                                <td>Country :</td>
                                                <th *ngIf="orderData?.shippingAddress.country">
                                                    {{orderData?.shippingAddress.country}}</th>
                                                <th *ngIf="!orderData?.shippingAddress.country">N/A</th>
                                            </tr>
                                            <tr>
                                                <td>Phone Number :</td>
                                                <th *ngIf="orderData?.shippingUserDetails.phoneNumber">
                                                    {{orderData?.shippingUserDetails.phoneNumber}}</th>
                                                <th *ngIf="!orderData?.shippingUserDetails.phoneNumber">N/A</th>
                                            </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="col-md-6 mb-2"
                                *ngIf="orderData?.deliveryInfo?.deliveryType == deliveryTypeList.selfPickup">
                                <h6>SHIPPING ADDRESS</h6>
                                <div class="bill-ship-details text-center" style="height: 181px;">
                                    <p>
                                        Customer will pick it up from the store
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card order-items-table-card">
                    <div class="card-header">
                        <h2>Order Items</h2>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table mb-0">
                                        <tbody *ngFor="let orderItem of orderItems;let i=index">
                                            <tr class="border-color-transparent">
                                                <td class="border-top-unset border-bottom-unset">
                                                    <img [src]="orderItem.itemDetail?.image" width="75px"
                                                        height="75px" />
                                                </td>
                                                <td colspan="6" class="border-top-unset border-bottom-unset"
                                                    style="width: 72.8%;">
                                                    <div>
                                                        <label>Product Name</label>
                                                        <p>{{ orderItem.itemDetail?.productName }}
                                                            <span *ngIf="orderItem.attrName || orderItem.attrValue"> -
                                                                {{orderItem.attrName}}
                                                                {{orderItem.attrValue}}</span>
                                                        </p>
                                                    </div>
                                                </td>
                                                <td class="border-top-unset" nowrap>
                                                    <div>
                                                        <label>Current Status</label>
                                                        <p
                                                            *ngIf="orderItems[i]?.deliveryStatus != 'Packing' && orderItems[i]?.deliveryStatus != 'Received'">
                                                            {{
                                                            orderItems[i]?.deliveryStatus | formatDocType }}
                                                            <span
                                                                *ngIf="orderItems[i]?.deliveryStatus == statusList.cancelled"
                                                                class="popover__wrapper ms-2">
                                                                <i class="fas fa-info-circle"></i>
                                                                <div class="popover__content">
                                                                    <h6>Cancelled On :
                                                                        <span>{{orderItems[i].deliveryTracking[orderItems[i].deliveryTracking.length-1].date
                                                                            | date: 'dd/MM/yyyy' : 'UTC' }}</span>
                                                                    </h6>
                                                                    <h6
                                                                        *ngIf="orderItems[i].deliveryTracking[orderItems[i].deliveryTracking.length-1].remarks">
                                                                        Reason :
                                                                        <span>{{orderItems[i].deliveryTracking[orderItems[i].deliveryTracking.length-1].remarks
                                                                            }}</span>
                                                                    </h6>
                                                                </div>
                                                            </span>
                                                        </p>
                                                        <p *ngIf="orderItems[i]?.deliveryStatus == 'Received'">
                                                            Confirmed
                                                        </p>
                                                        <p *ngIf="!orderItems[i]?.deliveryStatus">
                                                            Pending
                                                        </p>
                                                        <p *ngIf=" orderItems[i]?.deliveryStatus=='Packing' &&
                                                            orderData?.deliveryInfo?.deliveryType=='Self Pickup'">
                                                            Ready For Pickup
                                                        </p>
                                                        <p
                                                            *ngIf=" orderItems[i]?.deliveryStatus=='Packing' &&
                                                            orderData?.deliveryInfo?.deliveryType==deliveryTypeList.doorstep">
                                                            Ready For Shipping
                                                        </p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class=" border-color-transparent">
                                                <td class="border-top-unset border-bottom-unset pt-0"></td>
                                                <td class="border-top-unset border-bottom-unset pt-0">
                                                    <div>
                                                        <label>Brand</label>
                                                        <p
                                                            *ngIf="orderItem.itemDetail?.brand && orderItem.itemDetail?.brand != 'undefined'">
                                                            {{ orderItem.itemDetail?.brand }}</p>
                                                        <p
                                                            *ngIf="!orderItem.itemDetail?.brand || orderItem.itemDetail?.brand == 'undefined'">
                                                            N/A</p>
                                                    </div>
                                                </td>
                                                <td nowrap class="border-top-unset border-bottom-unset pt-0">
                                                    <label>SKU</label>
                                                    <p *ngIf="orderItem.itemDetail?.sku">{{ orderItem.itemDetail?.sku }}
                                                    </p>
                                                    <p *ngIf="!orderItem.itemDetail?.sku">N/A</p>
                                                </td>
                                                <td nowrap class="border-top-unset border-bottom-unset pt-0">
                                                    <label>Unit Price</label>
                                                    <p>{{currency}} {{ orderItem.itemDetail?.price |
                                                        number: '.2' }}</p>
                                                </td>
                                                <td nowrap class="border-top-unset border-bottom-unset pt-0">
                                                    <label>Qty</label>
                                                    <p>{{ orderItem.quantity }}</p>
                                                </td>
                                                <td nowrap class="border-top-unset border-bottom-unset pt-0">
                                                    <label>Tax</label>
                                                    <p *ngIf="orderItem.taxAmount">{{currency}} {{ orderItem.taxAmount |
                                                        number:
                                                        '.2' }}</p>
                                                    <p *ngIf="!orderItem?.taxAmount">{{currency}} 0.00</p>
                                                </td>
                                                <td nowrap class="border-top-unset border-bottom-unset pt-0">
                                                    <label>Total</label>
                                                    <p *ngIf="orderItem.totalPrice">{{currency}} {{ orderItem.totalPrice
                                                        | number:
                                                        '.2' }}</p>
                                                    <p *ngIf="!orderItem.totalPrice">{{currency}} 0.00 </p>
                                                </td>
                                                <td nowrap class="border-top-unset border-bottom-unset pt-0">
                                                    <div>
                                                        <label>Warehouse
                                                            <span
                                                                *ngIf="storageInfo[i]?.rackNo || storageInfo[i]?.rowNo || storageInfo[i]?.binNo || storageInfo[i]?.note"
                                                                class="popover__wrapper ms-2">
                                                                <i class="fas fa-info-circle"></i>
                                                                <div class="popover__content">
                                                                    <h6>Rack No:
                                                                        <span
                                                                            *ngIf="storageInfo[i]?.rackNo">{{storageInfo[i]?.rackNo}}</span>
                                                                        <span *ngIf="!storageInfo[i]?.rackNo">N/A</span>
                                                                    </h6>
                                                                    <h6>Row No:
                                                                        <span
                                                                            *ngIf="storageInfo[i]?.rowNo">{{storageInfo[i]?.rowNo}}</span>
                                                                        <span *ngIf="!storageInfo[i]?.rowNo">N/A</span>
                                                                    </h6>
                                                                    <h6>Bin No:
                                                                        <span
                                                                            *ngIf="storageInfo[i]?.binNo">{{storageInfo[i]?.binNo}}</span>
                                                                        <span *ngIf="!storageInfo[i]?.binNo">N/A</span>
                                                                    </h6>
                                                                    <h6>Notes:
                                                                        <span
                                                                            *ngIf="storageInfo[i]?.note">{{storageInfo[i]?.note}}</span>
                                                                        <span *ngIf="!storageInfo[i]?.note">N/A</span>
                                                                    </h6>
                                                                </div>
                                                            </span></label>
                                                        <p
                                                            *ngIf="orderItems[i]?.wareHouseName && orderItems[i]?.wareHouseName != 'null' && orderItems[i]?.wareHouseName != 'None'">
                                                            {{orderItems[i]?.wareHouseName}}
                                                        </p>
                                                        <p
                                                            *ngIf="!orderItems[i]?.wareHouseName || orderItems[i]?.wareHouseName == 'null' || orderItems[i]?.wareHouseName == 'None'">
                                                            N/A</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="border-color-transparent">
                                                <td colspan="9" class="border-top-unset border-bottom-unset pt-0">
                                                    <div class="d-flex justify-content-end gap-2">
                                                        <button
                                                            [disabled]="!(access['edit'] && !(updatePaymentEnabled && isAllStatusSame()))"
                                                            *ngIf="orderData?.status == statusList.processing && 
                                                            !(orderItem.deliveryStatus==statusList.delivered || orderItem.deliveryStatus==statusList.cancelled) &&
                                                             orderData?.deliveryInfo.deliveryType==deliveryTypeList.doorstep &&
                                                              !isOrderConfirmed" type="button"
                                                            class="btn update-status-btn"
                                                            (click)="showUpdateStatusDoorDeliveryModalDialog('right',i)"><i
                                                                class="fas fa-file-signature"></i> Update
                                                            Status</button>
                                                        <button
                                                            [disabled]="!(access['edit'] && !(updatePaymentEnabled && isAllStatusSame()))"
                                                            *ngIf="orderData?.status == statusList.processing &&
                                                            !(orderItem.deliveryStatus==statusList.delivered || orderItem.deliveryStatus==statusList.cancelled) 
                                                            && orderData?.deliveryInfo.deliveryType==deliveryTypeList.selfPickup && 
                                                            !isOrderConfirmed" type="button"
                                                            class="btn update-status-btn"
                                                            (click)="showUpdateStatusSelfPickUpModalDialog('right',i)"><i
                                                                class="fas fa-file-signature"></i> Update
                                                            Status</button>
                                                        <button *ngIf="orderData?.status != statusList.pending"
                                                            type="button" class="btn delivery-tracking-btn"
                                                            (click)="showDeliveryTrackingModalDialog('right',i)"><i
                                                                class="fas fa-route"></i> Delivery
                                                            Tracking</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <td class="w-100"></td>
                                                <td class="alignment">
                                                    <p>Item Total:</p>
                                                    <p class="no-wrap">
                                                        Product Discount:
                                                    </p>
                                                    <p class="no-wrap">
                                                        Coupon Discount:
                                                    </p>
                                                    <p
                                                        *ngIf="orderData?.deliveryInfo?.deliveryType == deliveryTypeList.doorstep">
                                                        Delivery Fee:
                                                    </p>
                                                    <p>Tax:</p>
                                                    <p>Total Amount:</p>
                                                </td>
                                                <td class="total-table-row">
                                                    <p>{{currency}} {{orderData?.orderSummary?.cartTotal | number:
                                                        '.2'}}</p>
                                                    <p class="no-wrap">
                                                        {{currency}} {{orderData?.orderSummary?.productDiscount |
                                                        number: '.2'}}
                                                    </p>
                                                    <p class="no-wrap" *ngIf="orderData?.couponDetails?.amount">
                                                        {{currency}} {{orderData?.couponDetails?.amount | number: '.2'}}
                                                    </p>
                                                    <p class="no-wrap" *ngIf="!orderData?.couponDetails?.amount">
                                                        {{currency}} 0.00
                                                    </p>
                                                    <p
                                                        *ngIf="orderData?.deliveryInfo?.deliveryType == deliveryTypeList.doorstep && orderData?.orderSummary?.deliveryFee">
                                                        {{currency}} {{orderData?.orderSummary?.deliveryFee | number:
                                                        '.2'}}</p>
                                                    <p
                                                        *ngIf="orderData?.deliveryInfo?.deliveryType == deliveryTypeList.doorstep && !orderData?.orderSummary?.deliveryFee">
                                                        {{currency}} 0.00</p>
                                                    <p *ngIf="orderData?.orderSummary?.tax">{{currency}}
                                                        {{orderData?.orderSummary?.tax | number: '.2'}}</p>
                                                    <p *ngIf="!orderData?.orderSummary?.tax">{{currency}} 0.00</p>
                                                    <p>{{currency}} {{orderData?.totalAmount | number: '.2'}}</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card payment-details-table-card" *ngIf="orderData?.paymentStatus =='Confirmed'">
                    <div class=" card-header">
                        <h2>Payment Details</h2>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Payment Date</th>
                                                <th class="text-end">Payment Amount({{currency}})</th>
                                                <th class="text-center">Mode Of Payment</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p>{{orderData?.paymentDetails?.paymentDate | date: 'dd/MM/yyyy' : 'UTC' }}
                                                    <p>
                                                </td>
                                                <td>
                                                    <p class="text-end">{{orderData?.paymentDetails?.receivedAmount |
                                                        number: '.2'}}</p>
                                                </td>
                                                <td>
                                                    <p class="text-center">
                                                        <span *ngIf="orderData?.paymentDetails?.chequeNumber">Cheque
                                                            <span class="popover__wrapper ms-2">
                                                                <i class="fas fa-info-circle"></i>
                                                                <div class="popover__content">
                                                                    <h6 *ngIf="orderData?.paymentDetails?.chequeNumber">
                                                                        Cheque Number: <span>{{
                                                                            orderData?.paymentDetails?.chequeNumber
                                                                            }}</span>
                                                                    </h6>
                                                                    <h6 *ngIf="orderData?.paymentDetails?.bankName">
                                                                        Bank Name: <span>{{
                                                                            orderData?.paymentDetails?.bankName
                                                                            }}</span>
                                                                    </h6>
                                                                    <h6 *ngIf="orderData?.paymentDetails?.chequeDate">
                                                                        Cheque Date: <span>{{
                                                                            orderData?.paymentDetails?.chequeDate |
                                                                            date:
                                                                            'dd/MM/yyyy' }}</span>
                                                                    </h6>
                                                                    <h6 *ngIf="orderData?.paymentDetails?.chequeStatus">
                                                                        Status: <span>{{
                                                                            orderData?.paymentDetails?.chequeStatus
                                                                            }}</span>
                                                                    </h6>
                                                                </div>
                                                            </span></span>
                                                        <span *ngIf="orderData?.paymentDetails?.referenceNumber">Credit
                                                            Card
                                                            <span class="popover__wrapper ms-2">
                                                                <i class="fas fa-info-circle"></i>
                                                                <div class="popover__content">
                                                                    <h6
                                                                        *ngIf="orderData?.paymentDetails?.referenceNumber">
                                                                        Reference Number: <span>{{
                                                                            orderData?.paymentDetails?.referenceNumber}}</span>
                                                                    </h6>
                                                                </div>
                                                            </span></span>
                                                        <span
                                                            *ngIf="!orderData?.paymentDetails?.referenceNumber && !orderData?.paymentDetails?.chequeNumber">Cash</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr *ngFor=" let partialPaymentDetail of
                                                            orderData?.paymentDetails?.partialPaymentDetails;let
                                                            i=index">
                                                <td>
                                                    <p>{{partialPaymentDetail.updatedAt | date: 'dd/MM/yyyy' : 'UTC' }}
                                                    <p>
                                                </td>
                                                <td>
                                                    <p class="text-end">{{partialPaymentDetail.receivedAmount | number:
                                                        '.2'}}</p>
                                                </td>
                                                <td>
                                                    <p class="text-center">
                                                        <span *ngIf="partialPaymentDetail.chequeNumber">Cheque
                                                            <span class="popover__wrapper ms-2">
                                                                <i class="fas fa-info-circle"
                                                                    (mouseenter)="getPaymentDetail(i)"></i>
                                                                <div class="popover__content">
                                                                    <h6 *ngIf="paymentDetail?.chequeNumber">
                                                                        Cheque Number: <span>{{
                                                                            paymentDetail?.chequeNumber
                                                                            }}</span>
                                                                    </h6>
                                                                    <h6 *ngIf="paymentDetail?.bankName">
                                                                        Bank Name: <span>{{
                                                                            paymentDetail?.bankName
                                                                            }}</span>
                                                                    </h6>
                                                                    <h6 *ngIf="paymentDetail?.chequeDate">
                                                                        Cheque Date: <span>{{
                                                                            paymentDetail?.chequeDate |
                                                                            date:
                                                                            'dd/MM/yyyy' : 'UTC' }}</span>
                                                                    </h6>
                                                                    <h6 *ngIf="paymentDetail?.chequeStatus">
                                                                        Status: <span>{{
                                                                            paymentDetail?.chequeStatus
                                                                            }}</span>
                                                                    </h6>
                                                                </div>
                                                            </span>
                                                        </span>
                                                        <span
                                                            *ngIf="!partialPaymentDetail.referenceNumber && !partialPaymentDetail.chequeNumber">Cash</span>
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- displayUpdateStatusModalToggle start -->
<p-dialog [draggable]="false" [(visible)]="displayUpdateStatusModalToggle" [position]="position" [modal]="true"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false" [showHeader]="false"
    [style]="{width: '34vw', minHeight: '100%', maxHeight: '100%', margin: '0'}"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}">
    <div id="displayUpdateStatusModalToggle">
        <div class="modal-header">
            <h5 class="modal-title">Update Status</h5>
            <i class="fas fa-times" (click)="closeUpdateStatusModalDialog()"></i>
        </div>
        <div class="body mt-3" [formGroup]="doorStepDeliveryForm">
            <div class="row">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Update Status <span class="mandatory">*</span></label>
                    <select formControlName="status" [(ngModel)]="status" (change)="onSelectStatus()"
                        class="form-select form-control" aria-label="Default select example">
                        <option [ngValue]="null" disabled>
                            Choose
                        </option>
                        <option *ngFor="let updatedStatus of updatedStatusList" [ngValue]="updatedStatus.value">
                            {{ updatedStatus.value | formatDocType}} </option>
                    </select>
                    <span class="text-danger"
                        *ngIf="(doorStepDeliveryForm.controls.status.touched) && !doorStepDeliveryForm.controls.status?.valid">
                        <div *ngIf="doorStepDeliveryForm.controls.status.errors['required']">
                            Update status is required.
                        </div>
                    </span>
                </div>
                <div *ngIf="updateToStatus == 'OutForDelivery'"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Delivery <span class="mandatory">*</span></label>
                    <select formControlName="deliveryBy" (change)="onSelectDeliveryBy()" ([ngModel])="deliveryVar"
                        class="form-select form-control" aria-label="Default select example">
                        <option [ngValue]="null" disabled>Choose..</option>
                        <option *ngFor="let deliveryBy of deliveryByList" [ngValue]="deliveryBy">
                            {{ deliveryBy }} </option>
                    </select>
                </div>
                <div *ngIf="updateToStatus == 'OutForDelivery' && deliveryBy == delivery.deliveryPartner"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Delivery Partner <span class="mandatory">*</span></label>
                    <select formControlName="deliveryPartner" [(ngModel)]="deliveryPartner"
                        class="form-select form-control" aria-label="Default select example">
                        <option [ngValue]="null" selected>Choose a delivery partner</option>
                        <option *ngFor="let deliveryPartner of deliveryPartnersList"
                            [ngValue]="deliveryPartner.companyName">
                            {{ deliveryPartner.companyName }} </option>
                    </select>
                </div>
                <div *ngIf="updateToStatus == 'OutForDelivery' && (deliveryBy == delivery.company || deliveryBy == delivery.deliveryPartner)"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Out for Delivery Date <span class="mandatory">*</span></label>
                    <input formControlName="deliveryDate" type="date" [min]="getToday()" class="form-control"
                        placeholder="Select" />
                </div>
                <div *ngIf="doorStepDeliveryForm.controls.deliveryDate.value && (updateToStatus == 'OutForDelivery') && (deliveryBy == delivery.company || deliveryBy == delivery.deliveryPartner)"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Expected Delivery Date <span class="mandatory">*</span></label>
                    <input formControlName="expectedDeliveryDate" type="date" [min]="getExpectedDeliveryDateMin()"
                        class="form-control" placeholder="Select Expected Delivery Date" />
                </div>
                <div *ngIf="updateToStatus == 'OutForDelivery' && deliveryBy == delivery.deliveryPartner"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Tracking Number <span class="mandatory">*</span></label>
                    <input formControlName="trackingNumber" type="text" class="form-control"
                        placeholder="Enter Tracking Number" />
                </div>
                <div *ngIf="updateToStatus == 'OutForDelivery' && deliveryBy == delivery.deliveryPartner"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Tracking URL <span class="mandatory">*</span></label>
                    <input formControlName="trackingUrl" type="text" class="form-control"
                        placeholder="Enter Tracking URL" />
                </div>
                <div *ngIf="updateToStatus == 'OutForDelivery' && deliveryBy == delivery.company"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <h6>Delivery Person Info</h6>
                </div>
                <div *ngIf="updateToStatus == 'OutForDelivery' && deliveryBy == delivery.company"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Name <span class="mandatory">*</span></label>
                    <input formControlName="name" type="text" class="form-control" placeholder="Enter Name" />
                </div>
                <div *ngIf="updateToStatus == 'OutForDelivery' && deliveryBy == delivery.company"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Mobile Number <span class="mandatory">*</span></label>
                    <input formControlName="mobileNumber" maxlength="14" type="number" (keypress)="preventE($event)"
                        class="form-control" placeholder="Enter Mobile Number" />
                    <span class="text-danger"
                        *ngIf="(doorStepDeliveryForm.controls.mobileNumber.touched) && !doorStepDeliveryForm.controls.mobileNumber?.valid">
                        <div *ngIf="doorStepDeliveryForm.controls.mobileNumber.errors['pattern']">
                            Invalid mobile number.
                        </div>
                    </span>
                </div>
                <div *ngIf="updateToStatus == 'OutForDelivery' && deliveryBy == delivery.company"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Vehicle Number <span class="mandatory">*</span></label>
                    <input formControlName="vehicleNumber" type="text" class="form-control"
                        placeholder="Enter Vehicle Number" />
                    <span class="text-danger"
                        *ngIf="(doorStepDeliveryForm.controls.vehicleNumber.touched) && !doorStepDeliveryForm.controls.vehicleNumber?.valid">
                        <div *ngIf="doorStepDeliveryForm.controls.vehicleNumber.errors['pattern']">
                            Invalid vehicle number.
                        </div>
                    </span>
                </div>
                <div *ngIf="updateToStatus == 'OutForDelivery' && deliveryBy == delivery.company"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Notes</label>
                    <textarea formControlName="notes" class="form-control" rows="5"
                        placeholder="Enter Notes"></textarea>
                </div>
                <div *ngIf="updateToStatus == statusList.cancelled"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Reason <span class="mandatory">*</span></label>
                    <textarea formControlName="reason" class="form-control" rows="5"
                        placeholder="Enter Reason"></textarea>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="d-flex justify-content-end gap-2">
            <p-button label="Cancel" styleClass="filter-cancel-btn" (click)="closeUpdateStatusModalDialog()"></p-button>
            <p-button label="Save" styleClass="filter-apply-btn"
                [disabled]="(orderData?.paymentStatus == this.statusList.paymentPending && updateToStatus==statusList.delivered) || !stockAvailable || doorStepDeliveryForm.invalid"
                (click)="updateStatus()"></p-button>
        </div>
    </ng-template>
</p-dialog>
<!-- displayUpdateStatusModalToggle end -->

<!-- displayDeliveryInfoModalToggle start -->
<p-dialog [draggable]="false" [(visible)]="displayDeliveryInfoModalToggle" [position]="position" [modal]="true"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false" [showHeader]="false"
    [style]="{width: '34vw', minHeight: '100%', maxHeight: '100%', margin: '0'}"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}">
    <div id="displayDeliveryInfoModalToggle">
        <div class="modal-header">
            <h5 class="modal-title">Delivery Info</h5>
            <i class="fas fa-times" (click)="closeDeliveryInfoModalDialog()"></i>
        </div>
        <div class="body mt-3" [formGroup]="selfPickUpForm">
            <div class="row">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Update Status <span class="mandatory">*</span></label>
                    <select formControlName="status" [(ngModel)]="status" (change)="onSelectDeliveredStatus()"
                        class="form-select form-control" aria-label="Default select example">
                        <option [ngValue]="null" disabled>
                            Choose
                        </option>
                        <option value="Delivered">Delivered</option>
                    </select>
                    <span class="text-danger"
                        *ngIf="(selfPickUpForm.controls.status.touched) && !selfPickUpForm.controls.status?.valid">
                        <div *ngIf="selfPickUpForm.controls.status.errors['required']">
                            Update status is required.
                        </div>
                    </span>
                </div>
                <div *ngIf="updateToStatus == statusList.delivered"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Pickup Date <span class="mandatory">*</span></label>
                    <input formControlName="pickUpDate" type="date" [min]="getLastUpdatedStatusDate()"
                        [max]="getCurrentDate()" class="form-control" placeholder="Select" />
                    <span class="text-danger"
                        *ngIf="(selfPickUpForm.controls.pickUpDate.touched) && !selfPickUpForm.controls.pickUpDate?.valid">
                        <div *ngIf="selfPickUpForm.controls.pickUpDate.errors['required']">
                            Pickup date is required.
                        </div>
                    </span>
                </div>
                <div *ngIf="updateToStatus == statusList.delivered"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Name <span class="mandatory">*</span></label>
                    <input formControlName="name" type="text" class="form-control" placeholder="Enter" />
                    <span class="text-danger"
                        *ngIf="(selfPickUpForm.controls.name.touched) && !selfPickUpForm.controls.name?.valid">
                        <div *ngIf="selfPickUpForm.controls.name.errors['required']">
                            Name is required.
                        </div>
                    </span>
                </div>
                <div *ngIf="updateToStatus == statusList.delivered"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Phone Number <span class="mandatory">*</span></label>
                    <input formControlName="phoneNumber" maxlength="14" type="text" class="form-control"
                        placeholder="Enter" />
                    <span class="text-danger"
                        *ngIf="(selfPickUpForm.controls.phoneNumber.touched) && !selfPickUpForm.controls.phoneNumber?.valid">
                        <div *ngIf="selfPickUpForm.controls.phoneNumber.errors['required']">
                            Phone number is required.
                        </div>
                        <div *ngIf="selfPickUpForm.controls.phoneNumber.errors['pattern']">
                            Invalid phone number.
                        </div>
                    </span>
                </div>
                <div *ngIf="updateToStatus == statusList.delivered"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Delivery Notes</label>
                    <textarea formControlName="deliveryNotes" class="form-control" rows="5"
                        placeholder="Enter"></textarea>
                </div>
                <div *ngIf="updateToStatus == statusList.delivered"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Delivery Receipt </label>
                    <div>
                        <form id="image-upload-form" class="image-uploader">
                            <input formControlName="deliveryReceipt" #productFileUpload id="image-upload" type="file"
                                name="fileUpload" accept="image/*" (change)="fileSave($event)" />
                            <label for="image-upload" id="file-drag">
                                <div id="start">
                                    <div class="after-upload-gallery-image"
                                        *ngIf="selfPickUpForm.get('deliveryReceipt')?.value">
                                        <img [src]="selfPickUpForm.get('deliveryReceipt')?.value" alt="Receipt image" />
                                    </div>
                                    <div *ngIf="!selfPickUpForm.get('deliveryReceipt')?.value" class="upload-caption">
                                        <i class="far fa-image"></i>
                                        <h5>Upload Receipt</h5>
                                        <p>add attachments in jpg, png and pdf</p>
                                    </div>

                                </div>
                            </label>
                            <div id="after-upload" *ngIf="selfPickUpForm.get('deliveryReceipt')?.value">
                                <div class="d-flex">
                                    <div class="uploaded-filename">
                                        {{selfPickUpForm.get('deliveryReceipt')?.value.split('public/')[1]}}</div>
                                    <div>
                                        <i (click)="deleteImage()" class="fas fa-trash-alt pt-1"></i>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="d-flex justify-content-end gap-2">
            <p-button label="Cancel" styleClass="filter-cancel-btn" (click)="closeDeliveryInfoModalDialog()"></p-button>
            <p-button label="Save" styleClass="filter-apply-btn" [disabled]="selfPickUpForm.invalid"
                (click)="saveDeliveryInfo()"></p-button>
        </div>
    </ng-template>
</p-dialog>
<!-- displayDeliveryInfoModalToggle end -->

<!-- displayDeliveryTrackingModalToggle start -->
<p-dialog [draggable]="false" [(visible)]="displayDeliveryTrackingModalToggle" [position]="position" [modal]="true"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false" [showHeader]="false"
    [style]="{width: '30vw', minHeight: '100%', maxHeight: '100%', margin: '0'}"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}">
    <div id="displayDeliveryTrackingModalToggle">
        <div class="modal-header">
            <h5 class="modal-title">Delivery Tracking</h5>
            <i class="fas fa-times" (click)="closeDeliveryTrackingModalDialog()"></i>
        </div>
        <div class="body mt-3">
            <div class="row">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <div class="timeline-position">
                        <div class="timeline-body" *ngFor="let deliveryTrackingItem of deliveryTrackingList">
                            <div
                                *ngIf="deliveryTrackingItem.deliveryStatus == 'Received' && deliveryTrackingItem.isUpdated">
                                <img src="../../../../assets/img/orderReceivedColor.png" />
                                <div class="status-txt">
                                    <h5>Order Confirmed</h5>
                                    <h6>{{
                                        orderItems[selectedItemIndex]?.deliveryTracking[deliveryTrackingItem.trackingListIndex]?.date
                                        | date:'dd/MM/yyyy' : 'UTC' }}, {{
                                        orderItems[selectedItemIndex]?.deliveryTracking[deliveryTrackingItem.trackingListIndex]?.date
                                        | date:'shortTime' }}</h6>
                                    <p>Ordered by: <span>{{ orderData?.userName }}</span></p>
                                    <p *ngIf="orderData?.billingAddress?.state">Location: <span>{{
                                            orderData?.billingAddress?.state }}</span></p>
                                    <p *ngIf="!orderData?.billingAddress?.state">Location: <span>N/A</span></p>
                                </div>
                            </div>
                            <div
                                *ngIf="deliveryTrackingItem.deliveryStatus == 'Received'  && !deliveryTrackingItem.isUpdated">
                                <img src="../../../../assets/img/orderReceivedBlack.png" />
                                <div class="status-txt">
                                    <h5>Order Confirmed</h5>
                                </div>
                            </div>
                            <div
                                *ngIf="deliveryTrackingItem.deliveryStatus == 'Processing' && deliveryTrackingItem.isUpdated">
                                <img src="../../../../assets/img/processingColor.png" />
                                <div class="status-txt">
                                    <h5>Processing</h5>
                                    <h6>{{
                                        orderItems[selectedItemIndex]?.deliveryTracking[deliveryTrackingItem.trackingListIndex]?.date
                                        | date:'dd/MM/yyyy' : 'UTC' }}, {{
                                        orderItems[selectedItemIndex]?.deliveryTracking[deliveryTrackingItem.trackingListIndex]?.date
                                        | date:'shortTime' }}</h6>
                                    <p>Processed by: <span>{{
                                            orderItems[selectedItemIndex]?.deliveryTracking[deliveryTrackingItem.trackingListIndex]?.trackingBy.split('#')[0]
                                            }}</span></p>
                                </div>
                            </div>
                            <div
                                *ngIf="deliveryTrackingItem.deliveryStatus == 'Processing' && !deliveryTrackingItem.isUpdated">
                                <img src="../../../../assets/img/processingBlack.png" />
                                <div class="status-txt">
                                    <h5>Processing</h5>
                                </div>
                            </div>
                            <div
                                *ngIf="deliveryTrackingItem.deliveryStatus == 'Packing' && deliveryTrackingItem.isUpdated">
                                <img src="../../../../assets/img/packingColor.png" />
                                <div class="status-txt">
                                    <h5 *ngIf="deliveryType == deliveryTypeList.doorstep">Ready For Shipping</h5>
                                    <h5 *ngIf="deliveryType == deliveryTypeList.selfPickup">Ready For Pickup</h5>
                                    <h6>{{
                                        orderItems[selectedItemIndex]?.deliveryTracking[deliveryTrackingItem.trackingListIndex]?.date
                                        | date:'dd/MM/yyyy' : 'UTC' }}, {{
                                        orderItems[selectedItemIndex]?.deliveryTracking[deliveryTrackingItem.trackingListIndex]?.date
                                        | date:'shortTime' }}</h6>
                                    <p>Packed by: <span>{{
                                            orderItems[selectedItemIndex]?.deliveryTracking[deliveryTrackingItem.trackingListIndex]?.trackingBy.split('#')[0]
                                            }}</span></p>
                                    <p>Warehouse:
                                        <span
                                            *ngIf="orderItems[selectedItemIndex]?.wareHouseName && orderItems[selectedItemIndex]?.wareHouseName != 'null' && orderItems[selectedItemIndex]?.wareHouseName != 'None'">{{
                                            orderItems[selectedItemIndex]?.wareHouseName }}</span>
                                        <span
                                            *ngIf="!orderItems[selectedItemIndex]?.wareHouseName || orderItems[selectedItemIndex]?.wareHouseName == 'null' || orderItems[selectedItemIndex]?.wareHouseName == 'None'">N/A</span>
                                    </p>
                                </div>
                            </div>
                            <div
                                *ngIf="deliveryTrackingItem.deliveryStatus == 'Packing' && !deliveryTrackingItem.isUpdated">
                                <img src="../../../../assets/img/packingBlack.png" />
                                <div class="status-txt">
                                    <h5 *ngIf="deliveryType == deliveryTypeList.doorstep">Ready For Shipping</h5>
                                    <h5 *ngIf="deliveryType == deliveryTypeList.selfPickup">Ready For Pickup</h5>
                                </div>
                            </div>
                            <div
                                *ngIf="deliveryTrackingItem.deliveryStatus == 'OutForDelivery' && deliveryTrackingItem.isUpdated && deliveryType == deliveryTypeList.doorstep">
                                <img src="../../../../assets/img/outForDeliveryColor.png" />
                                <div class="status-txt">
                                    <h5>Out For Delivery</h5>
                                    <h6>{{
                                        orderItems[selectedItemIndex]?.outForDeliveryDate | date:'dd/MM/yyyy' : 'UTC' }},
                                        {{ orderItems[selectedItemIndex]?.outForDeliveryTime }}</h6>
                                    <p
                                        *ngIf="orderItems[selectedItemIndex]?.deliveryBy == delivery.company && orderItems[selectedItemIndex]?.deliveryPersonInfo">
                                        Delivery by: <span>Company</span>
                                    </p>
                                    <p
                                        *ngIf="orderItems[selectedItemIndex]?.deliveryBy == delivery.deliveryPartner && orderItems[selectedItemIndex]?.deliveryPartner">
                                        Delivery by:
                                        <span
                                            *ngIf="orderItems[selectedItemIndex]?.deliveryPartner">{{orderItems[selectedItemIndex]?.deliveryPartner}}</span>
                                    </p>
                                    <p
                                        *ngIf="orderItems[selectedItemIndex]?.deliveryBy == delivery.company && orderItems[selectedItemIndex]?.deliveryPersonInfo">
                                        Contact Person: <span *ngIf="orderItems[selectedItemIndex]?.deliveryPersonInfo">
                                            {{orderItems[selectedItemIndex]?.deliveryPersonInfo.name}}</span>
                                    </p>
                                    <p
                                        *ngIf="orderItems[selectedItemIndex]?.deliveryBy == delivery.company && orderItems[selectedItemIndex]?.deliveryPersonInfo">
                                        Contact Number: <span
                                            *ngIf="orderItems[selectedItemIndex]?.deliveryPersonInfo">{{orderItems[selectedItemIndex]?.deliveryPersonInfo.contactNumber}}</span>
                                    </p>
                                    <p *ngIf="orderItems[selectedItemIndex]?.deliveryBy == delivery.deliveryPartner">
                                        Tracking ID:
                                        <span
                                            *ngIf="orderItems[selectedItemIndex]?.trackingID">{{orderItems[selectedItemIndex]?.trackingID}}</span>
                                        <span *ngIf="!orderItems[selectedItemIndex]?.trackingID">N/A</span>
                                    </p>
                                </div>
                            </div>
                            <div
                                *ngIf="deliveryTrackingItem.deliveryStatus == 'OutForDelivery' && !deliveryTrackingItem.isUpdated && deliveryType == deliveryTypeList.doorstep">
                                <img src="../../../../assets/img/outForDeliveryBlack.png" />
                                <div class="status-txt">
                                    <h5>Out For Delivery</h5>
                                </div>
                            </div>
                            <div
                                *ngIf="deliveryTrackingItem.deliveryStatus == statusList.delivered && !deliveryTrackingItem.isUpdated">
                                <img src="../../../../assets/img/deliveredBlack.png" />
                                <div class="status-txt" style="border-left: 0px;">
                                    <h5>Delivered</h5>
                                </div>
                            </div>
                            <div
                                *ngIf="deliveryTrackingItem.deliveryStatus == statusList.delivered && deliveryTrackingItem.isUpdated">
                                <img src="../../../../assets/img/deliveredColor.png" />
                                <div class="status-txt" style="border-left: 0px;">
                                    <h5>Delivered</h5>
                                    <p
                                        *ngIf="deliveryType == deliveryTypeList.doorstep && orderItems[selectedItemIndex]?.expectedDeliveryDate">
                                        Expected Delivery Date:
                                        <span *ngIf="orderItems[selectedItemIndex]?.expectedDeliveryDate">{{
                                            orderItems[selectedItemIndex]?.expectedDeliveryDate | date:'dd/MM/YYYY'
                                            }}</span>
                                        <span *ngIf="!orderItems[selectedItemIndex]?.expectedDeliveryDate">N/A</span>
                                    </p>
                                    <p
                                        *ngIf="deliveryType == deliveryTypeList.doorstep && !orderItems[selectedItemIndex]?.expectedDeliveryDate">
                                        Delivery Date:
                                        <span
                                            *ngIf="orderItems[selectedItemIndex]?.deliveryTracking[deliveryTrackingItem.trackingListIndex].date">{{
                                            orderItems[selectedItemIndex]?.deliveryTracking[deliveryTrackingItem.trackingListIndex].date
                                            | date:'dd/MM/YYYY'
                                            }}</span>
                                        <span
                                            *ngIf="!orderItems[selectedItemIndex]?.deliveryTracking[deliveryTrackingItem.trackingListIndex].date">N/A</span>
                                    </p>
                                    <p *ngIf="deliveryType == deliveryTypeList.selfPickup">Delivery date:
                                        <span
                                            *ngIf="orderItems[selectedItemIndex]?.deliveryTracking[deliveryTrackingItem.trackingListIndex].date">{{
                                            orderItems[selectedItemIndex]?.deliveryTracking[deliveryTrackingItem.trackingListIndex].date
                                            | date:'dd/MM/YYYY'
                                            }}</span>
                                        <span
                                            *ngIf="!orderItems[selectedItemIndex]?.deliveryTracking[deliveryTrackingItem.trackingListIndex].date">N/A</span>
                                    </p>
                                    <p *ngIf="deliveryType == deliveryTypeList.selfPickup">Pick-up by:
                                        <span
                                            *ngIf="orderItems[selectedItemIndex]?.deliveryTracking[deliveryTrackingItem.trackingListIndex].trackingBy">{{
                                            orderItems[selectedItemIndex]?.deliveryTracking[deliveryTrackingItem.trackingListIndex].trackingBy
                                            }}</span>
                                    </p>
                                    <p *ngIf="deliveryType == deliveryTypeList.selfPickup">Contact:
                                        <span
                                            *ngIf="orderItems[selectedItemIndex]?.deliveryTracking[deliveryTrackingItem.trackingListIndex].phoneNumber">{{
                                            orderItems[selectedItemIndex]?.deliveryTracking[deliveryTrackingItem.trackingListIndex].phoneNumber
                                            }}</span>
                                    </p>
                                </div>
                            </div>
                            <div
                                *ngIf="deliveryTrackingItem.deliveryStatus == statusList.cancelled && deliveryTrackingItem.isUpdated">
                                <img src="../../../../assets/img/Rejected R.png" />
                                <div class="status-txt">
                                    <h5>Cancelled</h5>
                                    <h6>{{
                                        orderItems[selectedItemIndex]?.deliveryTracking[deliveryTrackingItem.trackingListIndex]?.date
                                        | date:'dd/MM/yyyy' : 'UTC' }}, {{
                                        orderItems[selectedItemIndex]?.deliveryTracking[deliveryTrackingItem.trackingListIndex]?.date
                                        | date:'shortTime' }}</h6>
                                    <p>Cancelled by: <span>{{
                                            orderItems[selectedItemIndex]?.deliveryTracking[deliveryTrackingItem.trackingListIndex]?.trackingBy.split('#')[0]
                                            }}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</p-dialog>
<!-- displayDeliveryTrackingModalToggle end -->
<!-- <p-button (click)="showAttentionDialog()" label="Show" /> -->

<!-- attentionModalToggle start -->
<p-dialog [showHeader]="false" [modal]="true" [(visible)]="visibleAttentionDialog" [style]="{ width: 'auto' }"
    [baseZIndex]="10000">
    <div class="body m-3" id="attentionModalToggle">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="text-end">
                    <i class="fas fa-times" (click)="closeAttentionDialog()"></i>
                </div>
            </div>

            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="attention-image">
                    <img src="../../../../assets/img/warning.svg" />
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="confirm-msg">
                    <h6>Attention</h6>
                    <p>Payment not yet collected for this order</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="confirm-btns">
                    <button type="button" class="btn attention-confirm-btn"
                        (click)="showUpdatePaymenModalDialog('right')">
                        Update Payment
                    </button>
                </div>
            </div>
        </div>
    </div>
</p-dialog>
<!-- attentionModalToggle end -->
<!-- attentionModalToggleForAcknowledge start -->
<p-dialog [showHeader]="false" [modal]="true" [(visible)]="visibleAttentionDialogForAcknowledge"
    [style]="{ width: 'auto' }" [baseZIndex]="10000">
    <div class="body m-3" id="attentionModalToggleForAcknowledge">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="text-end">
                    <i class="fas fa-times" (click)="closeAttentionDialogForAcknowledge()"></i>
                </div>
            </div>

            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="attention-image">
                    <img src="../../../../assets/img/warning.svg" />
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="confirm-msg">
                    <h6>Attention</h6>
                    <p>The order has not been acknowledged yet. Please Acknowledge.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="confirm-btns">
                    <button type="button" class="btn attention-acknowledge-btn" (click)="acknowledge()">
                        Acknowledge
                    </button>
                </div>
            </div>
        </div>
    </div>
</p-dialog>
<!-- attentionModalToggleForAcknowledge end -->


<!-- displayUpdatePaymentModalToggle start -->
<p-dialog [draggable]="false" [(visible)]="displayUpdatePaymentModalToggle" [position]="position" [modal]="true"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false" [showHeader]="false"
    [style]="{width: '34vw', minHeight: '100%', maxHeight: '100%', margin: '0'}"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}">
    <div id="displayUpdatePaymentModalToggle">
        <div class="modal-header">
            <h5 class="modal-title">Update Payment</h5>
            <i class="fas fa-times" (click)="closeUpdatePaymenModalDialog()"></i>
        </div>
        <div class="body mt-3" [formGroup]="updatePaymentForm">
            <div class="row">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <div class="form-check mb-0 pl-4">
                        <input class="form-check-input" formControlName="isPaymentReceived"
                            (change)="onChangePaymentReceived()" type="checkbox" id="payment" />
                        <label for="payment">Payment Received</label>
                    </div>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2"
                    *ngIf="isPaymentReceived">
                    <label>Payment Method <span class="mandatory">*</span></label>
                    <select class="form-select form-control" (change)="onSelectPaymentMethod()"
                        [(ngModel)]="defaultPaymentMethod" formControlName="method" aria-label="Default select example">
                        <option [ngValue]="null" disabled>Choose payment method</option>
                        <option *ngFor="let paymentMethod of paymentMethodList" [ngValue]="paymentMethod">
                            {{ paymentMethod }}
                        </option>
                    </select>
                    <span class="text-danger"
                        *ngIf="(updatePaymentForm.controls.method.touched) && !updatePaymentForm.controls.method?.valid">
                        <div *ngIf="updatePaymentForm.controls.method.errors['required']">
                            Payment method is required.
                        </div>
                    </span>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2"
                    *ngIf="isPaymentReceived && selectedPaymentMethod==paymentMethodList[0]">
                    <label>Amount <span class="mandatory">*</span></label>
                    <input type="number" class="form-control" formControlName="amount" placeholder="Enter Amount"
                        (keypress)="preventE($event)">
                    <span class="text-danger"
                        *ngIf="(updatePaymentForm.controls.amount.touched) && !updatePaymentForm.controls.amount?.valid">
                        <div *ngIf="updatePaymentForm.controls.amount.errors['required']">
                            Amount is required.
                        </div>
                    </span>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2"
                    *ngIf="isPaymentReceived && selectedPaymentMethod==paymentMethodList[1]">
                    <label>Cheque Number <span class="mandatory">*</span></label>
                    <input type="text" maxlength="16" class="form-control" formControlName="chequeNumber"
                        placeholder="Enter Cheque Number">
                    <span class="text-danger"
                        *ngIf="updatePaymentForm.controls.chequeNumber.touched && !updatePaymentForm.controls.chequeNumber?.valid">
                        <div *ngIf="updatePaymentForm.controls.chequeNumber.errors['pattern']">
                            Invalid cheque number.
                            Please enter a valid number (6 digits, optional space, up to 9 more digits).
                        </div>
                    </span>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2"
                    *ngIf="isPaymentReceived && selectedPaymentMethod==paymentMethodList[1]">
                    <label>Bank Name <span class="mandatory">*</span></label>
                    <select class="form-select form-control" [(ngModel)]="defaultBankName" formControlName="bankName"
                        aria-label="Default select example">
                        <option [ngValue]="null" disabled>Choose Bank</option>
                        <option *ngFor="let bankName of bankNameList" [ngValue]=" bankName">
                            {{ bankName }}
                        </option>
                    </select>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2"
                    *ngIf="isPaymentReceived && selectedPaymentMethod==paymentMethodList[1]">
                    <label>Cheque Date <span class="mandatory">*</span></label>
                    <input formControlName="chequeDate" type="date" [min]="getCurrentDate()" class="form-control"
                        placeholder="Select" />
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2"
                    *ngIf="isPaymentReceived && selectedPaymentMethod==paymentMethodList[1]">
                    <label>Cheque Amount <span class="mandatory">*</span></label>
                    <input type="text" class="form-control" formControlName="amount" placeholder="Enter Cheque Amount">
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2"
                    *ngIf="isPaymentReceived && selectedPaymentMethod==paymentMethodList[1]">
                    <label>Cheque Status <span class="mandatory">*</span></label>
                    <select class="form-select form-control" [(ngModel)]="defaultStatus" formControlName="status"
                        aria-label="Default select example">
                        <option [ngValue]="null" disabled>Choose Status</option>
                        <option *ngFor="let chequeStatus of chequeStatusList" [ngValue]=" chequeStatus">
                            {{ chequeStatus }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="d-flex justify-content-end gap-2">
            <p-button label="Cancel" styleClass="filter-cancel-btn" (click)="closeUpdatePaymenModalDialog()"></p-button>
            <p-button label="Update" styleClass="filter-apply-btn"
                [disabled]="!isPaymentReceived && updatePaymentForm.invalid"
                (click)="updateCashOnDeliveryPayment()"></p-button>
        </div>
    </ng-template>
</p-dialog>
<!-- displayUpdatePaymentModalToggle end -->