import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import { environment } from 'environments/environment';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'Mep';
    loader = false;
    constructor(
        private router: Router,
        protected $gaService: GoogleAnalyticsService,
        private api: ApiService
    ) {
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd && environment.production) {
                this.$gaService.pageView(event.url);
            }
        });
    }

    ngOnInit(): void {
        this.api.loading.subscribe((v) => {
            this.loader = v;
        });
    }
}
