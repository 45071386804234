import { GeneralQueriesStatusList, UserRolesList } from '@/common-list';
import { formatDate } from '@angular/common';
import { ApiService } from '@services/api/api.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import * as query from 'graphql/queries';
import * as _ from 'underscore';
import { Component } from '@angular/core';
import { ROUTES } from '@/redirect-routes';
import * as mutation from 'graphql/mutations';
import { MessageService } from 'primeng/api';
import { Messages } from '@/Toaster-messages';
import { EnquiryStatus, UserRole } from 'API';
import { CommonService } from '@services/common/common.service';

@Component({
  selector: 'app-general-queries-list',
  templateUrl: './general-queries-list.component.html',
  styleUrl: './general-queries-list.component.scss'
})
export class GeneralQueriesListComponent {
  value: number = 5;
  position: any;
  displayFilterModalToggle = false
  deleteModalToggle: any
  filterObject: any = {}
  generalQueriesList: any = []
  generalResponse: any = []
  copyGeneralQueriesList: any = []
  selectedGeneralQuery: any = []
  searchKey: any
  searchText: any = ''
  status: any = []
  selectedRole: any = ''
  access: any = {}
  generalQueriesStatusList = GeneralQueriesStatusList
  userRoleList = UserRolesList
  page: any
  pageSize: any
  loading: boolean
  endOfData: boolean
  filterApplied: boolean = false

  filterForm = this.formBuilder.group({
    status: [],
  })

  constructor(private common: CommonService, private awsApi: ApiService, private router: Router, private messageService: MessageService, private formBuilder: FormBuilder) { }
  ngOnInit(): void {
    this.position = 'right';
    this.getRoleManagement()
    this.filterForm.patchValue({ status: [this.generalQueriesStatusList[0]] })
    this.filterObject['enquiryStatus'] = { eq: EnquiryStatus.Open }
    this.startingFunction()
  }

  async getRoleManagement() {
    this.awsApi.loading.next(true);
    try {
      await this.common.getPermissionsOfUser('Customers', 'General Queries');
      this.access = this.common.access;
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  async startingFunction() {
    await this.getGeneralRequests()
  }

  async getGeneralRequests() {
    this.awsApi.loading.next(true);
    Promise.all([await this.common.getGeneralQueries(this.filterObject, ['pk', 'id', 'subject', 'referenceNumber', 'userName', 'email', 'enquiryStatus', 'createdAt'])]).then((response) => {
      this.generalQueriesList = response[0].sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      this.copyGeneralQueriesList = this.generalQueriesList
      if (this.filterForm.controls.status.value?.length > 0) {
        this.filterApplied = true
        const selectedStatuses = this.filterForm.controls.status.value.map((status: any) => status.value);
        this.generalQueriesList = this.copyGeneralQueriesList.filter((arr: any) => {
          return selectedStatuses.includes(arr.enquiryStatus);
        });
        this.copyGeneralQueriesList = this.generalQueriesList;
      }
      this.initializePagination()
      this.awsApi.loading.next(false);
    }).catch((error) => {
      this.awsApi.loading.next(false);
    });
  }

  initializePagination() {
    this.page = 1;
    this.pageSize = 9;
    this.loading = false;
    this.endOfData = false;
    this.loadData()
  }

  onScroll() {
    if (!this.loading && !this.endOfData) {
      const container = document.querySelector('.scroll-container');
      if (
        container &&
        container.scrollHeight - container.scrollTop === container.clientHeight
      ) {
        this.loadMore();
      }
    }
  }

  loadMore() {
    this.page++
    // Loading more data
    this.loading = true;
    const startIndex = (this.page - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    setTimeout(() => {
      this.generalResponse = this.generalResponse.concat(this.generalQueriesList.slice(startIndex, endIndex));
      this.loading = false;

      if (this.generalResponse.length >= this.generalQueriesList.length) {
        this.endOfData = true;
      }
    }, 2000);
  }

  loadData() {
    // Initial data loading   
    this.loading = true;
    const startIndex = (this.page - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    setTimeout(() => {
      this.generalResponse = this.generalQueriesList.slice(startIndex, endIndex);
      this.loading = false;
    }, 1000);
  }

  async search(searchText) {
    if (searchText.trim() !== '') {
      this.searchText = searchText.toLowerCase().replace(/\s+/g, '').trim()
      this.filterObject['searchKey'] = { contains: this.searchText }
    } else {
      delete this.filterObject['searchKey']
    }
    await this.getGeneralRequests()
  }

  async applyFilter() {
    this.filterApplied = false
    this.clearFilterObject()
    this.displayFilterModalToggle = false;
    await this.getGeneralRequests()
  }

  clearFilterObject() {
    delete this.filterObject['enquiryStatus']
  }

  redirectToViewQuery(generalQuery) {
    this.router.navigate([ROUTES.VIEW_QUERY], { queryParams: { type: generalQuery.pk, queryId: generalQuery.id } });
  }

  showFilterModalDialog(position: string) {
    this.position = position;
    this.displayFilterModalToggle = true;
  }

  cancelFilter() {
    this.filterApplied = false
    this.displayFilterModalToggle = false;
    this.clearFilterObject()
    this.filterForm.reset()
    this.startingFunction()
  }

  closeFilterModalDialog() {
    this.displayFilterModalToggle = false;
  }
  showDeleteGeneralQueryModalDialog(item: any) {
    this.deleteModalToggle = true;
    this.selectedGeneralQuery = item
  }
  DeleteGeneralRequest() {
    this.awsApi.loading.next(true);
    this.DeleteGeneralQuery()
  }

  DeleteGeneralQuery() {
    let inputData = {
      pk: this.selectedGeneralQuery.pk,
      id: this.selectedGeneralQuery.id,
    }
    this.awsApi.client
      .graphql({
        query: mutation.deleteGeneralContactUs,
        variables: {
          input: {
            ...inputData
          }
        }
      }).then((response) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: Messages.GENERAL_QUERIES_DELETED
        });
        this.startingFunction()
      }).catch((error) => {
        this.awsApi.loading.next(false);

        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      });
    this.deleteModalToggle = false;
  }
}