import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';

import {
    ConfirmSignUpOutput,
    SignUpOutput,
    signUp,
    confirmSignUp,
    type ConfirmSignUpInput,
    signIn,
    type SignInInput,
    getCurrentUser,
    AuthUser,
    fetchUserAttributes,
    fetchAuthSession,
    signOut,
    ResendSignUpCodeInput,
    ResendSignUpCodeOutput,
    resendSignUpCode
} from 'aws-amplify/auth';

export interface NewUser {
    username: string;
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    phone_number: string;
    role: string;
}

@Injectable({
    providedIn: 'root'
})
export class UserAuthService {
    public loggedIn: boolean = false;
    public user: any = null;

    public static SIGN_IN = 'signIn';
    public static SIGN_OUT = 'signOut';
    constructor(private router: Router, private apiServ: ApiService) { }

    signIn({ username, password }: SignInInput) {
        return signIn({ username, password });
    }

    async handleSignOut() {
        try {
            await signOut();
            localStorage.removeItem('token');
            localStorage.removeItem('gatekeeper_token');
            this.user = null;
            this.router.navigate(['/login']);
        } catch (error) {
            throw error;
        }
    }

    async getProfile() {
        try {
            const user = await getCurrentUser();
            if (user) {
                this.user = user;
            } else {
                this.handleSignOut();
            }
        } catch (error) {
            this.handleSignOut();
            throw error;
        }
    }

    async currentSession() {
        return fetchAuthSession()
    }

    signUp({
        username,
        email,
        password,
        firstName,
        lastName,
        phone_number,
        role
    }: NewUser): Promise<SignUpOutput> {
        return signUp({
            username,
            password,
            options: {
                userAttributes: {
                    email,
                    phone_number,
                    'custom:firstName': firstName,
                    'custom:lastName': lastName,
                    'custom:role': role
                }
            }
        });
    }

    currentAuthenticatedUser(): Promise<AuthUser> {
        return getCurrentUser();
    }

    fetchUserAttributes(): Promise<any> {
        return fetchUserAttributes();
    }

    handleSignUpConfirmation({
        username,
        confirmationCode
    }: ConfirmSignUpInput): Promise<ConfirmSignUpOutput> {
        return confirmSignUp({
            username,
            confirmationCode
        });
    }

    handleResendSignUpCode({
        username
    }: ResendSignUpCodeInput): Promise<ResendSignUpCodeOutput> {
        return resendSignUpCode({ username });
    }
}