<section id="categoryAddSec">
    <div class="container-fluid">
        <form [formGroup]="addCategoryForm" (ngSubmit)="onSubmit()">
            <div class="row mb-3">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card mb-1 heading-card">
                        <div class="card-body">
                            <div class="d-flex justify-content-start align-items-center">
                                <i class="fas fa-chevron-left" (click)="redirectToCategoryListPage()"></i>
                                <h6>Add New Category</h6>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-1 category-img-card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                    <label>Category Type
                                        <span class="mandatory">*</span></label>
                                    <select class="form-select form-control" aria-label="Default select example"
                                        formControlName="catType" [(ngModel)]="defaultCategory"
                                        (change)="onSelectCategoryType()">
                                        <option [ngValue]="null" disabled>Choose...</option>
                                        <option *ngFor="let categoryType of categoryTypeList" [ngValue]="categoryType">
                                            {{ categoryType }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2"
                                    *ngIf="selectedCatType==categories.sub">
                                    <label>Main Category
                                        <span class="mandatory">*</span></label>
                                    <select class="form-select form-control" aria-label="Default select example"
                                        formControlName="mainCategory">
                                        <option disabled>Choose</option>
                                        @for (
                                        mainCategory of mainCategoryList;
                                        track mainCategory.id
                                        ) {
                                        <option [ngValue]="mainCategory.id">
                                            {{ mainCategory.catName }}
                                        </option>
                                        }
                                    </select>
                                </div>
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">
                                    <label>Category Name
                                        <span class="mandatory">*</span></label>
                                    <input type="text" class="form-control" placeholder="Enter Category Name"
                                        formControlName="catName" />
                                    <span class="text-danger"
                                        *ngIf="(addCategoryForm.controls.catName.touched) && !addCategoryForm.controls.catName?.valid">
                                        <div *ngIf="addCategoryForm.controls.catName.errors['required']">
                                            Category name is required.
                                        </div>
                                        <div *ngIf="addCategoryForm.controls.catName.errors['pattern']">
                                            Invalid category name.
                                        </div>
                                    </span>
                                </div>
                                <div *ngIf="selectedCatType==categories.main || selectedCatType==null"
                                    class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                    <label>Sequence Number</label>
                                    <input type="number" class="form-control" placeholder="Enter Sequence Number"
                                        formControlName="sequenceNumber" />
                                </div>
                                <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-2">
                                    <div>
                                        <label>Category Image
                                            <span class="mandatory">*</span></label>
                                        <form id="category-image-upload-form" class="category-image-uploader">
                                            <input id="category-image-upload" type="file" name="fileUpload"
                                                accept="image/*" (change)="
                                                    fileSave($event, 'catImage')
                                                " />
                                            @if (
                                            addCategoryFormControl[
                                            'categoryImg'
                                            ].value == ''
                                            ) {
                                            <label for="category-image-upload" id="file-drag">
                                                <div id="start">
                                                    <i class="far fa-image"></i>
                                                    <div class="upload-caption">
                                                        <h5>
                                                            Click here to
                                                            upload category
                                                            image
                                                        </h5>
                                                    </div>
                                                </div>
                                            </label>
                                            } @else {
                                            <div id="after-upload">
                                                <img id="file-image" [src]="
                                                                addCategoryFormControl[
                                                                    'categoryImg'
                                                                ].value
                                                            " />
                                                <div>
                                                    <i class="fas fa-trash-alt pt-1"
                                                        (click)="deleteUrl('catImage')"></i>
                                                </div>
                                            </div>
                                            }
                                        </form>
                                    </div>
                                </div>
                                <div class="col-xxl-9 col-xl-9 col-lg-9 col-md-6 col-sm-12 col-xs-12 mb-2">
                                    <label>Description</label>
                                    <ckeditor [editor]="Editor" formControlName="description"
                                        data="<h2>Hello from CKEditor 5!</h2>" (ready)="onReady($event)"
                                        (change)="onChange($event)">
                                    </ckeditor>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2 banner-img-card">
                        <div class="card-header">
                            <div class="d-flex justify-content-between align-items-center">
                                <h6>Banners</h6>
                                <ul class="nav nav-pills justify-content-end" id="pills-tab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="pills-website-tab" data-bs-toggle="pill"
                                            data-bs-target="#pills-website" type="button" role="tab"
                                            aria-controls="pills-website" aria-selected="true">
                                            Website
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="pills-mobile-app-tab" data-bs-toggle="pill"
                                            data-bs-target="#pills-mobile-app" type="button" role="tab"
                                            aria-controls="pills-mobile-app" aria-selected="false">
                                            Mobile App
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="tab-content" id="pills-tabContent">
                                        <div class="tab-pane fade show active" id="pills-website" role="tabpanel"
                                            aria-labelledby="pills-website-tab">
                                            <div class="row">
                                                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                                    <label>Banner Image</label>
                                                    <form id="web-banner-image-upload-form"
                                                        class="banner-image-uploader">
                                                        <input id="web-banner-image-upload" type="file"
                                                            name="fileUpload" accept="image/*" (change)="
                                                                fileSave(
                                                                    $event,
                                                                    'web-banner'
                                                                )
                                                            " />
                                                        @if (
                                                        addCategoryFormControl[
                                                        'webBannerImg'
                                                        ].value == ''
                                                        ) {
                                                        <label for="web-banner-image-upload" id="file-drag">
                                                            <div id="start">
                                                                <i class="far fa-image"></i>
                                                                <div class="upload-caption">
                                                                    <h5>
                                                                        This
                                                                        image
                                                                        size
                                                                        should
                                                                        be
                                                                        1920
                                                                        x
                                                                        500
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </label>
                                                        }
                                                        @else {
                                                        <div id="after-upload">
                                                            <img id="file-image" [src]="
                                                                            addCategoryFormControl[
                                                                                'webBannerImg'
                                                                            ]
                                                                                .value
                                                                        " />
                                                            <div>
                                                                <i class="fas fa-trash-alt pt-1"
                                                                    (click)="deleteUrl('web-banner')"></i>
                                                            </div>
                                                        </div>
                                                        }
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="pills-mobile-app" role="tabpanel"
                                            aria-labelledby="pills-mobile-app-tab">
                                            <div class="row">
                                                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                                    <label>Banner Image</label>
                                                    <form id="mobile-banner-image-upload-form"
                                                        class="banner-image-uploader">
                                                        <input id="mobile-banner-image-upload" type="file"
                                                            name="fileUpload" accept="image/*" (change)="
                                                                fileSave(
                                                                    $event,
                                                                    'mobile-banner'
                                                                )
                                                            " />

                                                        @if (
                                                        addCategoryFormControl[
                                                        'mobBannerImg'
                                                        ].value == ''
                                                        ) {
                                                        <label for="mobile-banner-image-upload" id="file-drag">
                                                            <div id="start">
                                                                <i class="far fa-image"></i>
                                                                <div class="upload-caption">
                                                                    <h5>
                                                                        This
                                                                        image
                                                                        size
                                                                        should
                                                                        be
                                                                        461
                                                                        x
                                                                        170
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </label>
                                                        } @else {
                                                        <div id="after-upload">
                                                            <img id="file-image" [src]="
                                                                            addCategoryFormControl[
                                                                                'mobBannerImg'
                                                                            ]
                                                                                .value
                                                                        " />
                                                            <div>
                                                                <i class="fas fa-trash-alt pt-1"
                                                                    (click)="deleteUrl('mobile-banner')"></i>
                                                            </div>
                                                        </div>
                                                        }
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="d-flex justify-content-end gap-2">
                        <button type="button" class="btn cancel-btn" (click)="redirectToCategoryListPage()">
                            Cancel
                        </button>
                        <button type="submit" [disabled]="!addCategoryForm.valid && !access['add']"
                            class="btn save-btn">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>