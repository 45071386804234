<section id="categoriesReportListSec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 my-auto">
                <div class="card m-2">
                    <div class=" card-body">
                        <!-- categories-list-heading-row start -->
                        <div class="row categories-list-heading-row">
                            <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12 my-auto">
                                <div class="text-center text-md-left mt-2 mt-md-0">
                                    <div class="d-flex justify-content-start align-items-center">
                                        <i class="fas fa-chevron-left" (click)="redirectToReportListPage()"></i>
                                        <h6>Category-wise Stock Summary Report</h6>
                                    </div>
                                    <p class="mb-2 mb-md-0 mt-2" *ngIf="filterApplied && categoryList?.length > 0">
                                        {{categoryList?.length}}
                                        Results Found</p>
                                </div>
                            </div>
                            <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12 my-auto">
                                <div class="has-search-d-flex justify-content-center justify-content-md-end align-items-start align-items-md-center flex-nowrap flex-md-wrap"
                                    [formGroup]="filterForm">
                                    <div *ngIf="!filterApplied">
                                        <button type="button" class="btn filter-btn me-3 d-none d-lg-block"
                                            (click)="showFilterModalDialog('right')">
                                            <span class="material-symbols-outlined me-1">
                                                filter_alt
                                            </span>
                                            Filter
                                        </button>
                                        <button type="button" class="btn filter-btn me-3 d-block d-md-none"
                                            (click)="showFilterModalDialog('right')">
                                            <span class="material-symbols-outlined">
                                                filter_alt
                                            </span>
                                        </button>
                                    </div>
                                    <div *ngIf="filterApplied">
                                        <button type="button" class="btn after-filter-btn me-3 d-none d-md-block"
                                            (click)="showFilterModalDialog('right')">
                                            <i class="fas fa-filter"></i>
                                            Filter
                                        </button>
                                        <button type="button" class="btn after-filter-btn me-3 d-block d-md-none"
                                            (click)="showFilterModalDialog('right')">
                                            <i class="fas fa-filter"></i>
                                        </button>
                                    </div>
                                    <div>
                                        <button type="button" class="btn export-btn d-none d-lg-block"
                                            (click)="exportToExcel()" *ngIf="categoryList?.length>0">
                                            <i class="fas fa-file-export me-1"></i> Export
                                        </button>
                                        <button type="button" class="btn export-btn d-block d-lg-none"
                                            (click)="exportToExcel()" *ngIf="categoryList?.length>0">
                                            <i class="fas fa-file-export"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-1 ms-2 me-2" *ngIf="!filterApplied && categoryList?.length == 0">
                    <div class="card-body p-0 order-selection">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <h2>Please select a filter to view the report</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- categories-list-heading-row end -->
                <div class="card mb-1 ms-2 me-2 scroll-container" (window:scroll)="onScroll()">
                    <div class="card-body p-0">
                        <!-- categories-list-table-row start -->
                        <div class="row categories-list-table-row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                *ngIf="categoryList?.length > 0">
                                <div class="table-row table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Product Category</th>
                                                <th>Total Products</th>
                                                <th>Total Qty</th>
                                                <th class="text-end">Current Stock</th>
                                                <th class="text-center">Sold Products</th>
                                                <th>Sold Qty</th>
                                                <th class="text-end">Sold Value</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let item of categoryDataList">
                                            <tr>
                                                <td>{{item.categoryName}}</td>
                                                <td>{{item.totalproducts}}</td>
                                                <td>{{item.totalquantity}}</td>
                                                <td class="text-end">{{item.currentstock | number: '.2'}}</td>
                                                <td class="text-center">{{item.soldproducts}}</td>
                                                <td>{{item.soldquantity}}</td>
                                                <td class="text-end">{{item.soldvalue | number: '.2'}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3"
                                *ngIf="page=='1' && categoryList?.length == 0 && !loader">
                                <app-no-result-found></app-no-result-found>
                            </div>
                        </div>
                        <!-- categories-list-table-row end -->
                    </div>
                </div>
                <div *ngIf="loading && categoryDataList.length>10">Loading...</div>
            </div>
        </div>
    </div>
</section>
<!-- displayFilterModalToggle start -->
<p-dialog [draggable]="false" [(visible)]="displayFilterModalToggle" [position]="position" [modal]="true"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false" [showHeader]="false"
    [style]="{width: '34vw', minHeight: '100%', maxHeight: '100%', margin: '0'}"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}">
    <div id="displayFilterModalToggle">
        <div class="modal-header">
            <h5 class="modal-title">Filter</h5>
            <i class="fas fa-times" (click)="closeFilterModalDialog()"></i>
        </div>
        <div class="body mt-3" [formGroup]="filterForm">
            <div class="row">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                    <label>Main Category</label>
                    <p-multiSelect display="chip" [options]="subCategoryList" formControlName="subCategory"
                        optionLabel="catName" placeholder="Choose Main Category" />
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="d-flex justify-content-end gap-2">
            <p-button label="Cancel" styleClass="filter-cancel-btn" (click)="cancelFilter()"></p-button>
            <p-button label="Apply" styleClass="filter-apply-btn" (click)="filterSubCategory()"></p-button>
        </div>
    </ng-template>
</p-dialog>
<!-- displayFilterModalToggle end -->