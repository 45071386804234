import { Component, OnInit } from '@angular/core';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import * as mutation from 'graphql/mutations';
import slugify from '@sindresorhus/slugify';
import { v4 as uuidv4 } from 'uuid';
import * as _ from 'underscore';
import { ApiService } from '@services/api/api.service';
import { ROUTES } from '@/redirect-routes';
import { CommonService } from '@services/common/common.service';
import { Messages } from '@/Toaster-messages';

@Component({
  selector: 'app-country-add',
  templateUrl: './country-add.component.html',
  styleUrl: './country-add.component.scss'
})
export class CountryAddComponent implements OnInit {
  addCountryForm: FormGroup;
  access: any = {}

  constructor(
    private awsApi: ApiService,
    private _router: Router,
    private fb: FormBuilder,
    private messageService: MessageService,
    private common: CommonService,
  ) {
    this.addCountryForm = this.fb.group({
      name: ['', [Validators.required, Validators.pattern(/^(?!\s*$)(?=.*[a-zA-Z])[a-zA-Z\s]+$/)]],
      shortName: ['', [Validators.required, Validators.pattern(/^(?!\s*$)(?=.*[a-zA-Z])[a-zA-Z\s]+$/)]]
    });
  }

  get addCountryFormControl() {
    return this.addCountryForm.controls;
  }

  ngOnInit(): void {
    this.getRoleManagement()
  }

  async getRoleManagement() {
    try {
      await this.common.getPermissionsOfUser('Products', 'Manage Category');
      this.access = this.common.access;
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  onOptionChange() { }

  onSubmit() {
    try {
      if (this.addCountryForm.valid) {
        this.awsApi.loading.next(true);
        let id = uuidv4();
        let str = this.addCountryFormControl['name'].value.toLowerCase()
        str = str.replace(/\s{2,}/g, ' ');
        str = (str.trim()).replace(/\s+/g, '')
        let inputData: any = {
          slug: slugify(this.addCountryFormControl['name'].value),
          name: this.addCountryFormControl['name'].value,
          shortName: this.addCountryFormControl['shortName'].value,
          pk: 'COUNTRY#',
          searchKey: str
        };

        this.awsApi.client
          .graphql({
            query: mutation.createCountry,
            variables: {
              input: {
                ...inputData
              }
            }
          })
          .then((response) => {
            this.awsApi.loading.next(false);
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Country created successfully!'
            });
            this._router.navigate(['/list-country']);
          })
          .catch((error) => {
            this.awsApi.loading.next(false);
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Country name already exist!'
            });

          });
      }
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });

    }
  }
  redirectToCountryListPage() {
    this._router.navigate([ROUTES.COUNTRY_LIST]);
  }
}