<section id="promocodeEditSec">
    <div class="container-fluid">
        <div class="row mb-3" [formGroup]="promoCodeForm">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card mt-2 mb-0 ms-2 me-2 heading-card">
                    <div class="card-body">
                        <div class="d-flex justify-content-start align-items-center">
                            <i class="fas fa-chevron-left" (click)="redirectToPromocodeListPage()"></i>
                            <h6>{{promoData?.code}}</h6>
                        </div>
                    </div>
                </div>
                <div class="card mt-1 mb-2 ms-2 me-2 promocode-img-card">
                    <div class="card-header">
                        <h5>Promocode Information</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-2">
                                <div>
                                    <label>Promo Image</label>
                                    <form id="promocode-image-upload-form" class="promocode-image-uploader">
                                        <input id="promocode-image-upload" type="file" name="fileUpload"
                                            accept="image/*" (change)="fileSave($event)" />
                                        <ng-container
                                            *ngIf="promoCodeForm.get('promoURL').value == ''; else afterUpload">
                                            <label for="promocode-image-upload" id="file-drag">
                                                <div id="start">
                                                    <i class="far fa-image"></i>
                                                    <div class="upload-caption">
                                                        <h5>The image size should be 1920x400 or above</h5>
                                                        <p>Only jpeg, png files with maximum size of 5 MB</p>
                                                    </div>
                                                </div>
                                            </label>
                                        </ng-container>
                                        <ng-template #afterUpload>
                                            <div id="after-upload">
                                                <div class="d-flex">
                                                    <div class="uploaded-filename">
                                                        <img id="file-image"
                                                            [src]="promoCodeForm.controls.promoURL.value" />
                                                    </div>
                                                    <div>
                                                        <i class="fas fa-trash-alt pt-1"
                                                            (click)="deleteUrl('promo')"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </form>
                                </div>
                            </div>
                            <div class="col-xxl-9 col-xl-9 col-lg-9 col-md-6 col-sm-12 col-xs-12 mb-2">
                                <div class="row">
                                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-2">
                                        <label>Promo Code
                                            <span class="mandatory">*</span></label>
                                        <input type="text" class="form-control" placeholder="Enter Promo Code"
                                            formControlName="code" />
                                        <span class="text-danger"
                                            *ngIf="(promoCodeForm.controls.code.touched) && !promoCodeForm.controls.code.valid">
                                            <div *ngIf="promoCodeForm.controls.code.errors['required']">
                                                Promo code is required</div>
                                            <div *ngIf="promoCodeForm.controls.code.errors['pattern']">
                                                Invalid promo code</div>
                                        </span>
                                    </div>
                                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-2">
                                        <label>Discount Type
                                            <span class="mandatory">*</span></label>
                                        <select class="form-select form-control" aria-label="Default select example"
                                            (change)="onSelectDiscountType()" formControlName="discountType">
                                            <option disabled [ngValue]="null">Choose</option>
                                            <option *ngFor="let item of discountType" [ngValue]="item.value">
                                                {{item.name}}</option>
                                        </select>
                                        <span class="text-danger"
                                            *ngIf="(promoCodeForm.controls.discountType.touched) && !promoCodeForm.controls.discountType.valid">
                                            <div *ngIf="promoCodeForm.controls.discountType.errors['required']">
                                                Discount type is required</div>
                                        </span>
                                    </div>
                                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-2">
                                        <label *ngIf="!promoCodeForm.controls.discountType.value">Discount
                                            Value<span class="mandatory">*</span></label>
                                        <label
                                            *ngIf="promoCodeForm.controls.discountType.value == 'percentage'">Discount
                                            Percentage <span class="mandatory">*</span></label>
                                        <label *ngIf="promoCodeForm.controls.discountType.value == 'amount'">Discount
                                            Amount <span class="mandatory">*</span></label>
                                        <input type="number" (keypress)="preventE($event)" [min]="0.0" step="0.01"
                                            class="form-control" placeholder="Enter"
                                            *ngIf="promoCodeForm.controls.discountType.value == 'percentage' || promoCodeForm.controls.discountType.value == 'amount'"
                                            formControlName="percentageValue" />
                                        <span class="text-danger"
                                            *ngIf="(promoCodeForm.controls.percentageValue.touched) && !promoCodeForm.controls.percentageValue.valid && promoCodeForm.controls.discountType.value">
                                            <div *ngIf="promoCodeForm.controls.percentageValue.errors['required']">
                                                Discount {{promoCodeForm.controls.discountType.value}} is required</div>
                                        </span>
                                    </div>
                                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-2">
                                        <label>Redemption Type
                                            <span class="mandatory">*</span></label>
                                        <select class="form-select form-control" aria-label="Default select example"
                                            formControlName="redemptionType">
                                            <option disabled>Choose</option>
                                            <option value="single">Single</option>
                                            <option value="multiple">Multiple</option>
                                        </select>
                                        <span class="text-danger"
                                            *ngIf="(promoCodeForm.controls.redemptionType.touched) && !promoCodeForm.controls.redemptionType.valid">
                                            <div *ngIf="promoCodeForm.controls.redemptionType.errors['required']">
                                                Redemption type is required</div>
                                        </span>
                                    </div>
                                    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                        <label>Valid From
                                            <span class="mandatory">*</span></label>
                                        <input type="date" class="form-control" placeholder="Select"
                                            formControlName="validationFrom" [min]="minDate" />
                                        <span class="text-danger"
                                            *ngIf="(promoCodeForm.controls.validationFrom.touched) && !promoCodeForm.controls.validationFrom.valid">
                                            <div *ngIf="promoCodeForm.controls.validationFrom.errors['required']">
                                                Valid from is required</div>
                                        </span>
                                    </div>
                                    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2">
                                        <label>Valid To
                                            <span class="mandatory">*</span></label>
                                        <input type="date" class="form-control" placeholder="Select"
                                            formControlName="validationTo" [min]="minDate" />
                                        <span class="text-danger"
                                            *ngIf="(promoCodeForm.controls.validationTo.touched) && !promoCodeForm.controls.validationTo.valid">
                                            <div *ngIf="promoCodeForm.controls.validationTo.errors['required']">
                                                Valid to is required</div>
                                        </span>
                                    </div>
                                    <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12 mb-2">
                                        <label>Status</label>
                                        <select class="form-select form-control" aria-label="Default select example"
                                            formControlName="status" [(ngModel)]="defaultStatus">
                                            <option [ngValue]="status.value" *ngFor="let status of statusList">
                                                {{status.id}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                        <label>Description</label>
                                        <textarea class="form-control" rows="2" placeholder="Enter Description"
                                            formControlName="description"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                <label>Terms and Conditions</label>
                                <ckeditor [editor]="Editor" formControlName="description"
                                    data="<h2>Hello from CKEditor 5!</h2>" (ready)="onReady($event)"
                                    (change)="onChange($event)" formControlName="terms">
                                </ckeditor>
                            </div>
                        </div>
                    </div>
                    <div class="card-header">
                        <h5>Advanced Settings</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 mb-2">
                                <label>Applies To</label>
                                <select class="form-select form-control" aria-label="Default select example"
                                    formControlName="appliedTo" (change)="changeAdvanceSettings()">
                                    <option disabled>Choose</option>
                                    <option value="customers">Customers</option>
                                    <option value="products">Products</option>
                                </select>
                            </div>
                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-4 col-sm-6 col-xs-12 mb-2"
                                *ngIf="promoCodeForm.controls.appliedTo.value == 'customers'">
                                <label>Specific Customers</label>
                                <p-multiSelect display="chip"
                                    *ngIf="promoCodeForm.controls.appliedTo.value == 'customers'" [filter]="true"
                                    [options]="selectedUsers" optionLabel="fullName" formControlName="specificCustomer"
                                    placeholder="Select Customers"
                                    (onChange)="updateSelectedItems($event, 'customers')"></p-multiSelect>
                            </div>
                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-4 col-sm-6 col-xs-12 mb-2 specific-categories-column"
                                *ngIf="promoCodeForm.controls.appliedTo.value == 'products'">
                                <label>Specific Categories or Sub Categories</label>
                                <p-multiSelect display="chip"
                                    *ngIf="promoCodeForm.controls.appliedTo.value == 'products'" [filter]="true"
                                    [options]="selectedCategory" optionLabel="catName" formControlName="specificProduct"
                                    placeholder="Select Products"
                                    (onChange)="updateSelectedItems($event, 'products')"></p-multiSelect>
                            </div>
                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2"
                                *ngIf="selectedItems?.length != 0">
                                <label>Selected {{ promoCodeForm.controls.appliedTo.value }}</label>
                                <div class="chip" *ngIf="promoCodeForm.controls.appliedTo.value == 'customers'">
                                    <div class="m-1" *ngFor="let item of selectedItems; let i = index">
                                        <div class="d-flex">
                                            <p>{{ item.fullName }}</p>
                                            <span class="closebtn" (click)="clearSelection(item, 'customers')"><i
                                                    class="fas fa-times"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="chip" *ngIf="promoCodeForm.controls.appliedTo.value == 'products'">
                                    <div class="m-1" *ngFor="let item of selectedItems; let i = index">
                                        <div class="d-flex">
                                            <p>{{ item.catName }}</p>
                                            <span class="closebtn" (click)="clearSelection(item, 'products')"><i
                                                    class="fas fa-times"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pb-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="d-flex justify-content-end gap-2">
                    <button type="button" class="btn cancel-btn" (click)="redirectToPromocodeListPage()">
                        Cancel
                    </button>
                    <button type="submit" class="btn save-btn"
                        [disabled]="promoCodeForm.invalid && !access['edit'] || processingUpdate"
                        (click)="updatePromoCodes()">
                        Update
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>