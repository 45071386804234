import { Component } from '@angular/core';

@Component({
  selector: 'app-add-carousel-images',
  templateUrl: './add-carousel-images.component.html',
  styleUrl: './add-carousel-images.component.scss'
})
export class AddCarouselImagesComponent {

}
