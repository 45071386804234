import { ChequeStatus, Coverage, EnquiryStatus, NotificationType, OrderStatus, PaymentMethod, ProductDeliveryStatus, ReturnActivityStatus, Status, UnitType, UserRole, animationType } from "API";
import { ROUTES } from '@/redirect-routes';

export const OrderStatusList = [
  { title: 'PENDING', value: OrderStatus.PENDING, 'text': 'Pending' },
  { title: 'FAILED', value: OrderStatus.FAILED, 'text': 'Failed' },
  { title: 'PROCESSING', value: OrderStatus.PROCESSING, 'text': 'Processing' },
  { title: 'CONFIRMED', value: OrderStatus.CONFIRMED, 'text': 'Confirmed' },
  { title: 'DELIVERED', value: OrderStatus.DELIVERED, 'text': 'Delivered' },
  { title: 'CANCELLED', value: OrderStatus.CANCELLED, 'text': 'Cancelled' }
];

export const PaymentMethodList = [
  { title: 'BankTransfer', value: PaymentMethod.BankTransfer, valueToShow: 'Bank Transfer' },
  { title: 'CreditCard', value: PaymentMethod.CreditCard, valueToShow: 'Credit Card' },
  { title: 'CashOnDelivery', value: PaymentMethod.CashOnDelivery, valueToShow: 'Cash On Delivery' },
  { title: 'Others', value: PaymentMethod.Others, valueToShow: 'Others' }
];

export const NotificationsTypeList = [
  NotificationType.GeneralQueries,
  NotificationType.ProductReviews,
  NotificationType.ZeroStock,
  NotificationType.OrderConfirmed,
  NotificationType.OrderDelivered,
  NotificationType.OrderCancelled,
  NotificationType.ReturnRequested,
  NotificationType.ReturnAccepted,
  NotificationType.ReturnShipped,
  NotificationType.ReturnReceived,
  NotificationType.RefundApproved,
  NotificationType.RefundRejected
];

export const ReturnPaymentMethodList = [
  { title: 'BankTransfer', value: PaymentMethod.BankTransfer },
  { title: 'CreditCard', value: PaymentMethod.CreditCard },
  { title: 'Cash', value: PaymentMethod.CashOnDelivery },
  { title: 'Others', value: PaymentMethod.Others }
];

export const OrderItemStatusList = [
  { title: 'Received', value: ProductDeliveryStatus.Received },
  { title: 'Processing', value: ProductDeliveryStatus.Processing },
  { title: 'Packing', value: ProductDeliveryStatus.Packing },
  { title: 'Out For Delivery', value: ProductDeliveryStatus.OutForDelivery },
  { title: 'Delivered', value: ProductDeliveryStatus.Delivered },
  { title: 'Cancelled', value: ProductDeliveryStatus.Cancelled }
];

export const DeliveryByList = ['By Company', 'By Delivery Partner'];

export const DeliveryTypeList = {
  doorstep: 'Doorstep',
  selfPickup: 'Self Pickup'
};

export const OrderReportTypeList = {
  summary: 'ordersummary',
  details: 'orderdetail'
};
export const PaymentMethodForCashOnDelivery = ['Cash', 'PDC/CDC'];

export const ProductReportBy = ['SKU', 'Product Name'];

export const ChequeStatusList = [ChequeStatus.Received, ChequeStatus.Cleared, ChequeStatus.Bounced]

export const ReceivedAsList = ['Good Condition', 'Damaged Condition']

export const StockStatusList = ['In stock', 'Out of stock']

export const ShippingTypeList = ['City Specific', 'Country Specific']

export const CoverageList: any = [
  { 'title': 'Zipcode', 'value': Coverage.Zipcode },
  { 'title': 'Latitude and Longitude', 'value': Coverage.LatitudeAndLongitude }]

export const OrderStatuses = {
  pending: 'PENDING',
  cancelled: 'Cancelled',
  delivered: 'Delivered',
  confirmed: 'CONFIRMED',
  paymentPending: 'Pending',
  paymentConfirmed: 'Confirmed',
  processing: 'PROCESSING',
  packing: 'Packing',
  outForDelivery: 'OutForDelivery',
  orderCancelled: 'CANCELLED',
  orderDelivered: 'DELIVERED'
}

export const ReturnStatuses = {
  accepted: 'Accepted',
  shipped: 'Shipped',
  cancelled: 'Cancelled',
  rejected: 'Rejected',
  received: 'Received',
  refundApproved: 'RefundApproved',
  refundRejected: 'RefundRejected',
  requested: 'Requested'
}

export const CategoryTypeList = ['Main Category', 'Sub Category']
export const Categories = {
  main: 'Main Category',
  sub: 'Sub Category'
}
export const DeliveryBy = {
  company: 'By Company',
  deliveryPartner: 'By Delivery Partner'
}

export const UserRolesList = [
  { id: 1, value: UserRole.ADMIN, text: 'Admin' },
  { id: 2, value: UserRole.MANAGER, text: 'Manager' },
  { id: 4, value: UserRole.STOREKEEPER, text: 'Store Keeper' },
  { id: 5, value: UserRole.MARKETING_HEAD, text: 'Marketing Head' },
  { id: 6, value: UserRole.WAREHOUSE_MANAGER, text: 'Warehouse Manager' }
]

export const FaqTypeList = [
  { id: 1, value: 'Buyer' },
  { id: 2, value: 'Admin' }
]

export const GeneralQueriesStatusList = [
  { id: 1, value: EnquiryStatus.Open },
  { id: 2, value: EnquiryStatus.Resolved }
]

export const StatusList = [
  { id: "Active", value: Status.ACTIVE },
  { id: "Inactive", value: Status.INACTIVE }
]

export const PromoStatusList = [
  { id: "Active", value: 'ACTIVE' },
  { id: "Inactive", value: 'INACTIVE' },
  { id: "Expired", value: 'EXPIRED' },
  { id: "Draft", value: 'DRAFT' }
]
export const PromoStsList = [
  { id: "Active", value: 'ACTIVE' },
  { id: "Inactive", value: 'INACTIVE' },
  { id: "Draft", value: 'DRAFT' }
]

export const TaxTypeList = [
  { id: 1, value: 'State' },
  { id: 2, value: 'Country' }
]

export const ReturnItemStatusList = [
  { title: '1', value: ReturnActivityStatus.Requested, valueToShow: 'Requested' },
  { title: '2', value: ReturnActivityStatus.Cancelled, valueToShow: 'Cancelled' },
  { title: '3', value: ReturnActivityStatus.Accepted, valueToShow: 'Accepted' },
  { title: '4', value: ReturnActivityStatus.Shipped, valueToShow: 'Item Picked' },
  { title: '5', value: ReturnActivityStatus.Received, valueToShow: 'Received' }
];

export const ReturnStatusList = [
  { id: 1, value: 'Accept', valueToShow: 'Accept Request' },
  { id: 2, value: 'Reject', valueToShow: 'Reject Request' }
]

export const RefundStatusList = [
  { title: '6', value: ReturnActivityStatus.RefundApproved, valueToShow: 'Refund Approved' },
  { title: '7', value: ReturnActivityStatus.RefundRejected, valueToShow: 'Refund Rejected' }
]

export const AnimationEffects = [
  { id: 1, value: animationType.top },
  { id: 2, value: animationType.bottom },
  { id: 1, value: animationType.left },
  { id: 1, value: animationType.right }
]

export const UnitsTypeList = [
  { id: 'Stock Unit', value: UnitType.StockUnit },
  { id: 'Distance', value: UnitType.Distance },
  { id: 'Mass', value: UnitType.Mass }
]

export const BankNameList = [
  'Qatar National Bank (QNB)',
  'Commercial Bank of Qatar (CBQ)',
  'Doha Bank',
  'Masraf Al Rayan',
  'Qatar Islamic Bank (QIB)',
  'Al Khaliji Commercial Bank',
  'Barwa Bank',
  'Ahlibank',
  'International Bank of Qatar (IBQ)',
  'Qatar Development Bank (QDB)']

export const ReportsUrl = [
  { sequenceNumber: 1, redirectTo: ROUTES.STOCK_REPORT },
  { sequenceNumber: 2, redirectTo: ROUTES.CATEGORY_REPORT },
  { sequenceNumber: 3, redirectTo: ROUTES.ORDERS_REPORT },
  { sequenceNumber: 4, redirectTo: ROUTES.PRODUCT_REPORT },
  { sequenceNumber: 5, redirectTo: ROUTES.RETURN_REFUND_REPORT },
  { sequenceNumber: 6, redirectTo: ROUTES.PAYMENT_LEDGER_REPORT },
]

export const TypeOfApp = ['Android App', 'IOS App', 'Certificate']
export const WebFont = [
  { checked: false, value: 'Roboto' },
  { checked: false, value: 'Poppins' },
  { checked: false, value: 'Ubuntu' },
  { checked: false, value: 'Open Sans' }]


export const MobileFont = [
  { checked: false, value: 'Roboto' },
  { checked: false, value: 'Poppins' },
  { checked: false, value: 'Ubuntu' },
  { checked: false, value: 'Open Sans' }]

export const FontWeight = [
  'Regular',  //400
  'Semi Bold',  //500
  'Bold', //600
  'Bolder'] //700