import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as query from 'graphql/queries';
import * as mutation from 'graphql/mutations';
import { MessageService } from 'primeng/api';
import { ApiService } from '@services/api/api.service';
import { Status } from 'API';
import { CommonService } from '@services/common/common.service';
import { Messages } from '@/Toaster-messages';
@Component({
    selector: 'app-brand-list',
    templateUrl: './brand-list.component.html',
    styleUrl: './brand-list.component.scss'
})
export class BrandListComponent implements OnInit {
    position: any;
    displayFilterModalToggle = false;
    deleteModalToggle: boolean;
    brandList: any = [];
    deleteBrand: any;
    filterObject: any = {}
    searchKey: any
    access: any = {}
    searchText: any = ''
    // status: any = ''

    constructor(
        private router: Router,
        private common: CommonService,
        private awsApi: ApiService,
        private messageService: MessageService
    ) { }

    ngOnInit(): void {
        this.position = 'right';
        this.getAllCategory();
        this.awsApi.loading.next(true);
        this.startingFunction()
    }

    async startingFunction() {
        try {
            await this.common.getPermissionsOfUser('Products', 'Manage Brand');
            this.access = this.common.access;
            this.awsApi.loading.next(false);
        } catch (error) {
            this.awsApi.loading.next(false);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: Messages.SOMETHING_WENT_WRONG
            });
        }
    }

    getAllCategory() {
        this.awsApi.loading.next(true);
        this.awsApi.client
            .graphql({
                query: query.listBrands,
                variables: {
                    pk: 'BRAND#',
                    id: null,
                    filter: this.filterObject,
                    limit: 9999999,
                    nextToken: null,
                    sortDirection: null
                }
            })
            .then((response) => {
                this.brandList = response.data.listBrands.items.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                this.awsApi.loading.next(false);
            })
            .catch((error) => {
                this.awsApi.loading.next(false);
            });
    }

    showFilterModalDialog(position: string) {
        this.position = position;
        this.displayFilterModalToggle = true;
    }
    closeFilterModalDialog() {
        this.displayFilterModalToggle = false;
    }
    deleteBrandItem(item: any) {
        this.deleteModalToggle = true;
        this.deleteBrand = item;
    }
    redirectToAddBrandPage() {
        this.router.navigate([`add-brand`]);
    }

    async confirmDeleteBrand() {
        this.awsApi.loading.next(true);
        this.deleteModalToggle = false;
        let filter = this.deleteBrand.id
        if (!await this.common.isAnyProductAvailable(filter, 'brand')) {
            let data = {
                pk: this.deleteBrand.pk,
                id: this.deleteBrand.id
            };
            this.awsApi.client
                .graphql({
                    query: mutation.deleteBrand,
                    variables: {
                        input: {
                            ...data
                        }
                    }
                })
                .then((response) => {
                    this.awsApi.loading.next(false);
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Brand deleted successfully!'
                    });
                    this.ngOnInit();
                })
                .catch((error) => {
                    this.awsApi.loading.next(false);
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: Messages.SOMETHING_WENT_WRONG
                    });
                });
        }
    }

    search(searchText) {
        this.searchText = searchText
        if (this.searchText != '') {
            this.searchKey = this.searchText.toLowerCase()
            this.searchKey = this.searchKey.replace(/\s{2,}/g, ' ');
            this.searchKey = (this.searchKey.trim()).replace(/\s+/g, '');
            this.filterObject['searchKey'] = { contains: this.searchKey }
            this.ngOnInit()
        } else {
            delete this.filterObject['searchKey']
            delete this.searchKey
            this.searchText
            this.ngOnInit()
        }
    }

    changeStatus(userId: any, type: any) {
        let data = {
            pk: 'BRAND#',
            id: userId,
            status: type === 'Deactivate' ? Status.INACTIVE : Status.ACTIVE
        };

        this.awsApi.client
            .graphql({
                query: mutation.updateBrand,
                variables: {
                    input: {
                        pk: data.pk,
                        id: data.id,
                        status: data.status
                    }
                }
            })
            .then((response) => {
                this.awsApi.loading.next(false);
                this.ngOnInit();
            })
            .catch((error) => {
                this.awsApi.loading.next(false);
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: Messages.SOMETHING_WENT_WRONG
                });
            });
    }
    redirectToEditBrandPage(brand: any) {
        this.router.navigate([`edit-brand`], { queryParams: { id: brand.id, pk: brand.pk } });
    }
}
