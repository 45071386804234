import { Component } from '@angular/core';
import { ApiService } from '@services/api/api.service';
import { MessageService } from 'primeng/api';
import * as query from 'graphql/queries';
import { StatusList, UnitsTypeList } from '@/common-list';
import { FormBuilder, Validators } from '@angular/forms';
import * as mutation from 'graphql/mutations';
import { productStatus, Status, UnitType } from 'API';
import { Messages } from '@/Toaster-messages';
import { CommonService } from '@services/common/common.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-units-list',
  templateUrl: './units-list.component.html',
  styleUrl: './units-list.component.scss'
})

export class UnitsListComponent {
  position: any;
  displayFilterModalToggle = false
  deleteModalToggle: any
  searchText: any
  searchKey: any
  access: any = {}
  add: any = false
  edit: any = false
  unitsTypeList: any = UnitsTypeList
  statusList = StatusList
  selectedUnitsItem: any = []
  filterObject: any = {}
  unitsList: any = []
  copyUnitsList: any = []
  defaultType: String | null = null
  unitsForm = this.formBuilder.group({
    unitsName: ['', [Validators.required, Validators.pattern(/^(?!\s*$)(?=.*[a-zA-Z])[a-zA-Z0-9\s]+$/)]],
    type: ['', [Validators.required]]
  })

  constructor(private common: CommonService, private awsApi: ApiService, private formBuilder: FormBuilder, private messageService: MessageService) { }

  ngOnInit(): void {
    this.getRoleManagement()
    this.startingFunction()
  }

  async getRoleManagement() {
    this.awsApi.loading.next(true);
    try {
      await this.common.getPermissionsOfUser('Settings', 'Manage Unit');
      this.access = this.common.access;
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  async startingFunction() {
    this.awsApi.loading.next(true);
    this.getunitsList()
  }

  getunitsList() {
    this.awsApi.loading.next(true);
    this.awsApi.client
      .graphql({
        query: query.listUnits
      }).then((response) => {
        this.unitsList = response.data.listUnits.items
          .sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        this.copyUnitsList = this.unitsList
        this.awsApi.loading.next(false);
      }).catch((error) => {
        this.awsApi.loading.next(false);
      });
  }

  showAdd() {
    this.unitsForm.reset()
    this.add = true
    this.edit = false
  }

  showEdit(unitsItem) {
    this.selectedUnitsItem = unitsItem
    this.unitsForm.reset()
    this.edit = true
    this.add = false
    this.autoFillEditForm()
  }

  autoFillEditForm() {
    this.unitsForm.patchValue({
      unitsName: this.selectedUnitsItem.value,
      type: this.unitsTypeList.find(unit => unit.value === this.selectedUnitsItem.type)['id']
    })
    this.defaultType = this.unitsTypeList.find(unit => unit.value === this.selectedUnitsItem.type)['id']
  }

  isUnitNameAlreadyExists(type, value, edit: boolean = false): boolean {
    if (!edit) {
      let unitNames = this.unitsList
        .filter((item) => {
          return item.type == type &&
            item.value.toString().toLowerCase().replace(/\s{2,}/g, ' ').trim() == value.toString().toLowerCase().replace(/\s{2,}/g, ' ').trim();
        })
        .map((item) => { return item.value; })
      return unitNames.length > 0 ? true : false
    }
    else {
      let unitNames = this.unitsList
        .filter((item) => {
          return item.type == type && item.id != this.selectedUnitsItem.id &&
            item.value.toString().toLowerCase().replace(/\s{2,}/g, ' ').trim() == value.toString().toLowerCase().replace(/\s{2,}/g, ' ').trim();
        })
        .map((item) => { return item.value; })
      return unitNames.length > 0 ? true : false
    }
  }

  addunits() {
    if (this.unitsForm.valid) {
      let selectedType = this.unitsTypeList.find(unit => unit.id === this.unitsForm.controls.type.value)['value']
      if (this.isUnitNameAlreadyExists(selectedType, this.unitsForm.controls.unitsName.value, false)) {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.UNIT_EXISTS
        })
      }
      else {
        let searchKeyFormatted = this.unitsForm.controls.unitsName.value.toLowerCase()
        searchKeyFormatted = searchKeyFormatted.replace(/\s{2,}/g, ' ');
        searchKeyFormatted = (searchKeyFormatted.trim()).replace(/\s+/g, '')
        this.awsApi.loading.next(true);
        let data = {
          pk: 'UNIT#',
          id: uuidv4(),
          value: this.unitsForm.controls.unitsName.value,
          searchKey: searchKeyFormatted,
          type: selectedType,
          status: Status.ACTIVE,
          createdBy: localStorage.getItem('userName'),
          updatedBy: localStorage.getItem('userName'),
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        };
        this.awsApi.client.graphql({
          query: mutation.createUnits,
          variables: {
            input: {
              ...data
            }
          }
        }).then((response) => {
          this.successMessage(Messages.UNIT_ADDED)
          this.unitsForm.reset()
          this.add = false
          this.awsApi.loading.next(true);
          this.startingFunction()
        }).catch((error) => {
          this.catchError()
        });
      }
    }
    else {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.PLEASE_FILL_REQUIRED_FIELDS
      });
    }
  }

  catchError() {
    this.awsApi.loading.next(false);
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: Messages.SOMETHING_WENT_WRONG
    });
  }

  successMessage(succMessage: any) {
    this.awsApi.loading.next(false);
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: succMessage
    });
  }

  editunits() {
    if (this.unitsForm.valid) {
      let selectedType = this.unitsTypeList.find(unit => unit.id === this.unitsForm.controls.type.value)['value']
      if (this.isUnitNameAlreadyExists(selectedType, this.unitsForm.controls.unitsName.value, true)) {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.UNIT_EXISTS
        })
      }
      else {
        let searchKeyFormatted = this.unitsForm.controls.unitsName.value.toLowerCase()
        searchKeyFormatted = searchKeyFormatted.replace(/\s{2,}/g, ' ');
        searchKeyFormatted = (searchKeyFormatted.trim()).replace(/\s+/g, '')
        this.awsApi.loading.next(true);
        let data = {
          pk: 'UNIT#',
          id: this.selectedUnitsItem.id,
          type: selectedType,
          value: this.unitsForm.controls.unitsName.value,
          searchKey: searchKeyFormatted,
          updatedAt: new Date().toISOString()
        };
        this.awsApi.client.graphql({
          query: mutation.updateUnits,
          variables: {
            input: {
              ...data
            }
          }
        }).then((response) => {
          this.awsApi.loading.next(false);
          this.successMessage(Messages.UNIT_EDITED)
          this.unitsForm.reset()
          this.edit = false
          this.startingFunction()
        }).catch((error) => {
          this.awsApi.loading.next(false);
          this.catchError()
        });
      }
    }
    else {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.PLEASE_FILL_REQUIRED_FIELDS
      });
    }
  }

  editStatus(unitId: any, type: any) {
    let data = {
      pk: 'UNIT#',
      id: unitId,
      status: type == 'Deactivate' ? Status.INACTIVE : Status.ACTIVE
    }
    this.awsApi.client
      .graphql({
        query: mutation.updateUnits,
        variables: {
          input: {
            ...data
          }
        }
      }).then((response) => {
        this.awsApi.loading.next(false);
        this.ngOnInit();
      }).catch((error) => {
        this.awsApi.loading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: Messages.SOMETHING_WENT_WRONG
        });
      })
  }

  async deleteunits(unitsItem) {
    this.selectedUnitsItem = unitsItem
    let filter = {}; let products: any = []
    if (this.selectedUnitsItem.type === UnitType.StockUnit) {
      filter['isDeleted'] = { ne: true }
      filter['stockUnit'] = { eq: this.selectedUnitsItem.value }
      products = await this.common.getProductDetailsWithEditQuery(filter, ['pk', 'id'], 'Stock Unit Delete')
    } else {
      if (this.selectedUnitsItem.type != UnitType.StockUnit) {
        filter['status'] = { ne: productStatus.deleted }
        filter['searchKey'] = { contains: this.selectedUnitsItem.id }
        products = await this.common.getProducts(filter, ['pk', 'id'], 'Unit Delete')
      }
    }
    if (products.length > 0) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.UNIT_CANNOT_BE_DELETED
      });
      return;
    }
    this.awsApi.loading.next(true);
    let data = {
      pk: 'UNIT#',
      id: this.selectedUnitsItem.id
    };
    this.awsApi.client.graphql({
      query: mutation.deleteUnits,
      variables: {
        input: {
          ...data
        }
      }
    }).then((response) => {
      this.successMessage(Messages.UNIT_DELETED)
      this.unitsForm.reset()
      this.startingFunction()
    }).catch((error) => {
      this.catchError()
    });
  }

  showDeleteConfirmationModalDialog(item: any) {
    this.deleteModalToggle = true;
  }

  redirectTounitsList() {
    this.unitsForm.reset()
    this.edit = false
    this.add = false
  }

  preventE(event) {
    if (event.which === 101) {
      event.preventDefault();
    }
  }
}