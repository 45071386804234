import { Component, OnDestroy } from '@angular/core';
import { ApiService } from '@services/api/api.service';
import * as query from 'graphql/queries';
import { ActivatedRoute, Router } from '@angular/router';
import _ from 'underscore';
import * as mutation from 'graphql/mutations';
import { MessageService } from 'primeng/api';
import { Messages } from '@/Toaster-messages';
import { ROUTES } from '@/redirect-routes';
import { FormBuilder } from '@angular/forms';
import { EnquiryStatus } from 'API';
import { CommonService } from '@services/common/common.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ImageViewPopupModalComponent } from '@/shared/image-view-popup-modal/image-view-popup-modal.component';

@Component({
  selector: 'app-customer-query',
  templateUrl: './customer-query.component.html',
  styleUrl: './customer-query.component.scss'
})
export class CustomerQueryComponent {
  queryId: any
  reply: any
  pk: any
  selectedGeneralQuery: any = []
  usersList: any = []
  attachments: any = []
  access: any = {}
  isQueryOpen: boolean
  replySent: boolean = false
  ref: DynamicDialogRef | undefined;

  constructor(private common: CommonService, private router: Router, private formbuilder: FormBuilder, private activatedRoute: ActivatedRoute, private messageService: MessageService, private awsApi: ApiService,
    public dialogService: DialogService
  ) { }
  ngOnInit(): void {
    this.getRoleManagement()
    this.startingFunction()
  }

  startingFunction() {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.queryId = params.get('queryId') ?? ''
      this.pk = params.get('type') ?? ''
      if (this.queryId != '' && this.pk != '') {
        this.awsApi.loading.next(true);
        Promise.all([this.GetGeneralQuery()])
          .then((response) => {
            this.awsApi.loading.next(false);
            this.selectedGeneralQuery = (response[0].data.getGeneralContactUs)
            this.isQueryOpen = this.selectedGeneralQuery.enquiryStatus == EnquiryStatus.Open ? true : false
            this.awsApi.loading.next(false);
          });
      }
    })
  }

  updateResolutionSent() {
    this.replySent = !this.replySent
  }

  async getRoleManagement() {
    this.awsApi.loading.next(true);
    try {
      await this.common.getPermissionsOfUser('Customers', 'General Queries');
      this.access = this.common.access;
      this.awsApi.loading.next(false);
    } catch (error) {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.SOMETHING_WENT_WRONG
      });
    }
  }

  cancel() {
    this.reply = null
    this.replySent = false
    this.router.navigate([ROUTES.LIST_QUERY]);
  }

  sendQueryRes() {
    this.awsApi.loading.next(true);
    if (this.reply && this.replySent) {
      let responseData = {
        pk: this.pk,
        id: this.queryId,
        userId: this.selectedGeneralQuery.userId,
        replyMessage: this.reply,
        enquiryStatus: EnquiryStatus.Resolved,
        createdAt: this.selectedGeneralQuery.createdAt,
        updatedAt: new Date().toISOString()
      }
      this.awsApi.client
        .graphql({
          query: mutation.updateGeneralContactUs,
          variables: {
            input: {
              ...responseData
            }
          }
        }).then((response) => {
          this.awsApi.loading.next(false);
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: Messages.GENERAL_QUERIES_UPDATED
          });
          this.router.navigate([ROUTES.LIST_QUERY]);
        }).catch((error) => {
          this.awsApi.loading.next(false);

          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: Messages.SOMETHING_WENT_WRONG
          });
        });
    }
    else {
      this.awsApi.loading.next(false);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: Messages.PLEASE_FILL_REQUIRED_FIELDS
      });
    }
  }

  async GetGeneralQuery() {
    return await this.awsApi.client.graphql({
      query: query.getGeneralContactUs,
      variables: {
        pk: this.pk,
        id: this.queryId
      }
    })
  }

  redirectToGeneralQueryListingPage() {
    this.router.navigate([ROUTES.LIST_QUERY]);
  }

  showImageViewModal(url: any) {
    if (url.toString().slice(-3) === 'pdf') {
      window.open(url)
    }
    else {
      this.ref = this.dialogService.open(ImageViewPopupModalComponent,
        {
          data: {
            image: url
          },
          header: 'View Image',
          width: '50vw',
          modal: true,
          breakpoints: {
            '960px': '75vw',
            '640px': '90vw'
          },
        });
    }
  }
}