import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import * as mutation from 'graphql/mutations';
import { ROUTES } from '@/redirect-routes';
import { CommonService } from '@services/common/common.service';
import * as subscription from 'graphql/subscriptions';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
    notificationList: any = []
    private subscription: Subscription | undefined;
    copyNotificationList: any = []
    notificationLength: any = 0
    readNotificationLength: any = 0
    unReadNotificationLength: any = 0
    redirectedToDetailsPage: boolean
    isPopupVisible: boolean = true;
    constructor(private router: Router, private awsApi: ApiService, private common: CommonService) { }

    ngOnInit(): void {
        this.subscribeToNotifications();
        this.getAllNotification()
    }

    redirectToAllNotificationsPage() {
        this.redirectedToDetailsPage = true
        this.isPopupVisible = false;
        this.router.navigate([`all-notifications`]);
    }

    subscribeToNotifications() {
        this.subscription = this.awsApi.client.graphql({
            query: subscription.onCreateNotifications
        }).subscribe({
            next: (response) => {
                if (response.data && response.data.onCreateNotifications) {
                    this.handleNewNotification(response.data.onCreateNotifications);
                }
            },
            error: (error) => {
            }
        })
    }

    handleNewNotification(notification): void {
        this.notificationLength++;
        this.unReadNotificationLength++
    }

    hidePopup() {
        this.isPopupVisible = false;
    }

    updateCount() {
        this.notificationLength = this.notificationList.length
        this.readNotificationLength = this.notificationList.filter((arr: any) => {
            return arr.notificationStatus == 2;
        }).length
        this.unReadNotificationLength = this.notificationList.filter((arr: any) => {
            return arr.notificationStatus == 1;
        }).length
    }

    async getAllNotification() {
        this.awsApi.loading.next(true);
        Promise.all([await this.common.getNotifications()]).then((response) => {
            this.notificationList = response[0].sort((a: any, b: any) => new Date(b.DateTime).getTime() - new Date(a.DateTime).getTime())
            this.copyNotificationList = [...this.notificationList]
            this.updateCount()
            this.notificationList = this.notificationList.splice(0, 5)
            this.redirectedToDetailsPage = false
            this.awsApi.loading.next(false);
        })
            .catch((error) => {
                this.awsApi.loading.next(false);
            });
    }

    readNotification() {
        this.awsApi.loading.next(true);
        this.notificationList = this.copyNotificationList
            .filter((notification: any) => notification.notificationStatus == 2)
            .sort((a: any, b: any) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
            .slice(0, 5)
        this.awsApi.loading.next(false);
    }

    unreadNotification() {
        this.awsApi.loading.next(true);
        this.notificationList = this.copyNotificationList
            .filter((notification: any) => notification.notificationStatus == 1)
            .sort((a: any, b: any) => new Date(b.DateTime).getTime() - new Date(a.DateTime).getTime())
            .slice(0, 5)
        this.awsApi.loading.next(false);
    }

    gotoUrl(item: any) {
        this.awsApi.loading.next(true);
        if (item) {
            let data = {
                notificationStatus: 2, pk: item.pk, id: item.id,
                updatedAt: new Date().toISOString()
            }
            this.awsApi.client
                .graphql({
                    query: mutation.updateNotifications,
                    variables: {
                        input: {
                            ...data
                        }
                    }
                }).catch((error) => { });
            this.hidePopup()
            const { Title, orderID, referenceID } = item;
            this.getAllNotification()
            if (Title === 'Order Delivered' || Title === 'Orderitem cancelled' || Title === 'Order Confirmed') {
                this.router.navigate([ROUTES.ORDER_DETAILS], { queryParams: { orderId: orderID } })
            } else if (Title === 'Return Cancelled' || Title === 'Return Item Received') {
                this.router.navigate([ROUTES.RETURN_ITEM_DETAILS], { queryParams: { orderId: orderID, orderItemId: referenceID } });
            } else if (Title === 'Abusive Reported') {
                this.router.navigate([ROUTES.CUSTOMER_REVIEW], { queryParams: { reviewId: referenceID } });
            }
            else if (Title === 'Product Out Of Stock') {
                this.router.navigate([`edit-product`], { queryParams: { id: referenceID.split('#')[0], scId: referenceID.split('#')[1], type: 'edit' } });
            }
            this.awsApi.loading.next(false);
        }
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    setRedirectedToDetailsPage() {
        this.redirectedToDetailsPage = false
        this.isPopupVisible = true
        this.ngOnInit()
    }
}
