import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import { MessageService } from 'primeng/api';
import * as query from 'graphql/queries';
import * as mutation from 'graphql/mutations';
import { CalendarModule } from 'primeng/calendar';
import { ROUTES } from '@/redirect-routes';
import { CommonService } from '@services/common/common.service';
import { formatDate } from '@angular/common';
import { Messages } from '@/Toaster-messages';

@Component({
    selector: 'app-edit-delivery-partner',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, RouterModule, CalendarModule],
    templateUrl: './edit-delivery-partner.component.html',
    styleUrl: './edit-delivery-partner.component.scss'
})
export class EditDeliveryPartnerComponent implements OnInit {
    addDeliveryPartnersForm: FormGroup;
    deliveryPartnerID: string;
    pk: string;
    access: any = {}
    deliveryPartnerData: any = []
    maxDate: any
    processingUpdate = false

    constructor(private awsApi: ApiService,
        private _router: Router,
        private fb: FormBuilder,
        private common: CommonService,
        private messageService: MessageService,
        private _activatedroute: ActivatedRoute
    ) {
        this.addDeliveryPartnersForm = this.fb.group({
            logo: [''],
            logoUrl: [''],
            companyName: ['', [Validators.required, Validators.pattern(/^(?!\s*$)(?=.*[a-zA-Z])[a-zA-Z0-9\s]+$/)]],
            displayName: ['', [Validators.required]],
            apiKey: [''],
            expiryDate: [''],
            contactPerson: [''],
            address: [''],
            email: ['', [Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
            phoneNumber: ['', [Validators.pattern('[- +0-9]{10,15}')]]
        });
    }

    ngOnInit() {
        this.maxDate = new Date()
        this._activatedroute.queryParamMap.subscribe(async (params) => {
            this.deliveryPartnerID = params.get('id') ?? '';
            this.pk = params.get('pk') ?? '';
            if (this.deliveryPartnerID != '' && this.pk != '') {
                this.awsApi.loading.next(true);
                Promise.all([this.getDeliveryPartnerByID()])
                    .then((response) => {
                        this.deliveryPartnerData = response[0].data.getDeliveryPartner;
                        this.addDeliveryPartnersForm.patchValue({
                            logoUrl: this.deliveryPartnerData.logoUrl,
                            logo: this.deliveryPartnerData.logo,
                            companyName: this.deliveryPartnerData.companyName,
                            displayName: this.deliveryPartnerData.displayName,
                            expiryDate: formatDate(this.deliveryPartnerData.apiKeyExpiryDate, 'yyyy-MM-dd', 'en-US'),
                            apiKey: this.deliveryPartnerData.apiKey,
                            contactPerson: this.deliveryPartnerData.contactPerson,
                            address: this.deliveryPartnerData.address,
                            email: this.deliveryPartnerData.email,
                            phoneNumber: this.deliveryPartnerData.phoneNumber
                        });
                        this.awsApi.loading.next(false);
                    })
                    .catch((error) => {
                        this.awsApi.loading.next(false);
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: Messages.SOMETHING_WENT_WRONG
                        });
                    });
            }
        });
        this.startingFunction()
    }

    async startingFunction() {
        this.getRoleManagement()
    }

    async getRoleManagement() {
        try {
            await this.common.getPermissionsOfUser('Users', 'Delivery Partners');
            this.access = this.common.access;
            this.awsApi.loading.next(false);
        } catch (error) {
            this.awsApi.loading.next(false);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: Messages.SOMETHING_WENT_WRONG
            });
        }
    }

    async fileSave(event: any, type: any) {
        this.awsApi.loading.next(true);
        if (event.target instanceof HTMLInputElement && event.target.files) {
            let file = event.target.files[0];
            let isValid = await this.common.isValidFileType(event)
            if (!isValid) { return; }
            try {
                this.awsApi.loading.next(false);
                let imageUrl = await this.common.getS3Url(file)
                if (type == 'logo') {
                    this.addDeliveryPartnersForm.patchValue({
                        logoUrl: imageUrl
                    });
                }
            } catch (error) {
                this.awsApi.loading.next(false);
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: Messages.SOMETHING_WENT_WRONG
                });
            }
        }
    }

    async getDeliveryPartnerByID() {
        return await this.awsApi.client.graphql({
            query: query.getDeliveryPartner,
            variables: {
                pk: this.pk,
                id: this.deliveryPartnerID
            }
        });
    }

    async deleteUrl(type) {
        if (type == 'logoUrl') {
            this.addDeliveryPartnersForm.patchValue({
                logoUrl: ''
            });
            const fileInput = document.getElementById('image-upload') as HTMLInputElement;
            if (fileInput) {
                fileInput.value = '';
            }
        }
    }

    updateDeliveryPartners() {
        if (this.addDeliveryPartnersForm.valid) {
            let str = this.addDeliveryPartnersForm.value.companyName.toLowerCase()
            str = str.replace(/\s{2,}/g, ' ');
            str = (str.trim()).replace(/\s+/g, '')
            this.processingUpdate = true;
            let data = {
                pk: this.deliveryPartnerData.pk,
                id: this.deliveryPartnerData.id,
                logoUrl: this.addDeliveryPartnersForm.value.logoUrl,
                logo: this.addDeliveryPartnersForm.value.logoUrl,
                companyName: this.addDeliveryPartnersForm.value.companyName,
                displayName: this.addDeliveryPartnersForm.value.displayName,
                apiKey: this.addDeliveryPartnersForm.value.apiKey,
                apiKeyExpiryDate: new Date(this.addDeliveryPartnersForm.value.expiryDate).toISOString(),
                contactPerson: this.addDeliveryPartnersForm.value.contactPerson,
                address: this.addDeliveryPartnersForm.value.address,
                email: this.addDeliveryPartnersForm.value.email,
                phoneNumber: this.addDeliveryPartnersForm.value.phoneNumber,
                searchKey: str
            };
            this.awsApi.client
                .graphql({
                    query: mutation.updateDeliveryPartner,
                    variables: {
                        input: {
                            ...data
                        }
                    }
                })
                .then((response) => {
                    this.awsApi.loading.next(false);
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: Messages.DELIVERY_PARTNER_UPDATED
                    });
                    this.processingUpdate = false;
                    this._router.navigate([ROUTES.LIST_DELIVERY_PARTNER]);
                })
                .catch((error) => {
                    this.awsApi.loading.next(false);
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: Messages.SOMETHING_WENT_WRONG
                    });
                });
        } else {
            this.awsApi.loading.next(false);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Please fill all required details!'
            });
        }
    }

    getMinDate(): string {
        const today = new Date();
        return today.toISOString().split('T')[0];
    }
}
