import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import * as query from 'graphql/queries';
import { MessageService } from 'primeng/api';
import { CategoryType, EnquiryStatus, OrderStatus, ReturnActivityStatus, Status, productStatus } from 'API';
import { formatDate } from '@angular/common';
import { CommonService } from '@services/common/common.service';
import { Messages } from '@/Toaster-messages';
import { ROUTES } from '@/redirect-routes';
import slugify from '@sindresorhus/slugify';
import { ReportsUrl, UserRolesList } from '@/common-list';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
    categoryStockData: any;
    categoryStockOptions: any;
    salesReturnData: any;
    salesReturnOptions: any;
    dateRange: any = []
    filterObject: any = {}
    ordersWithFilter: any = []
    orderItemsWithFilter: any = []

    categoryList: any = []
    productsList: any = []
    productDetailsList: any = []
    customersList: any = []
    ordersList: any = []
    refundsList: any = []
    queriesList: any = []
    orderItemsList: any = []
    refundOrders: any
    topSellingProducts: any
    topSellingCategories: any
    pendingOrdersList: any = []
    pendingReturnRequests: any
    buyingCustomers: any
    categoryNames: any
    categoryStockSet: any = {}
    labelsCategoryStockData: any
    dataCategoryStockData: any
    randomColorsForCategoryStock: any
    lastSixMonthsSalesAndReturnData: any
    sales: any
    return: any
    reports: any = []
    reportsList: any = []
    roleManagementRes: any = []

    productsValue: any
    ordersValue: any
    pendingOrdersValue: any
    refundOrdersValue: any
    totalProducts: any
    totalOrders: any
    totalPendingOrders: any
    totalRefundOrders: any
    noOfProducts: any
    noOfOrders: any
    noOfPendingOrders: any
    noOfCategorries: any
    currency: any
    maxDate: any
    userRolesList = UserRolesList

    constructor(
        private router: Router,
        private awsApi: ApiService,
        private messageService: MessageService,
        private common: CommonService
    ) { }

    async ngOnInit() {
        this.awsApi.loading.next(true);
        await new Promise(resolve => setTimeout(resolve, 0));
        try {
            this.currency = await this.common.getCurrencyOfCompany()
            this.maxDate = new Date()
            await this.initialiseListsAndCounts()
            await this.startingFunction()
            const documentStyle = getComputedStyle(document.documentElement);
            const textColor = documentStyle.getPropertyValue('--text-color');
            const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
            const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
            this.randomColorsForCategoryStock = await this.generateRandomColors(this.dataCategoryStockData.length)
            this.categoryStockData = {
                labels: this.labelsCategoryStockData,
                datasets: [
                    {
                        label: 'Category Stock',
                        data: this.dataCategoryStockData,
                        backgroundColor: this.randomColorsForCategoryStock,
                        borderRadius: '4px'
                    }
                ]
            };
            this.categoryStockOptions = {
                plugins: {
                    legend: {
                        display: false
                    }
                },
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            color: textColorSecondary
                        },
                        grid: {
                            color: surfaceBorder,
                            drawBorder: false
                        }
                    },
                    x: {
                        ticks: {
                            color: textColorSecondary
                        },
                        grid: {
                            color: surfaceBorder,
                            drawBorder: false
                        }
                    }
                }
            };

            let labels: any = [], dataSetsLabelsSales: any = [], dataSetsLabelsReturns: any = []
            this.lastSixMonthsSalesAndReturnData.reverse().forEach((item) => {
                labels.push(item.dateAbbr)
                dataSetsLabelsSales.push(item.salesValue)
                dataSetsLabelsReturns.push(item.returnValue)
            })
            this.salesReturnData = {
                labels: labels,
                datasets: [
                    {
                        label: 'Orders: ' + this.sales.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                        backgroundColor: documentStyle.getPropertyValue('#1D75B7'),
                        data: dataSetsLabelsSales
                    },
                    {
                        label: 'Refunds: ' + this.return.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                        backgroundColor: documentStyle.getPropertyValue('#F0B2BC'),
                        data: dataSetsLabelsReturns
                    }
                ]
            };

            this.salesReturnOptions = {
                maintainAspectRatio: false,
                aspectRatio: 0.8,
                plugins: {
                    legend: {
                        labels: {
                            color: textColor
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: textColorSecondary,
                            font: {
                                weight: 500
                            }
                        },
                        grid: {
                            color: surfaceBorder,
                            drawBorder: false
                        }
                    },
                    y: {
                        ticks: {
                            color: textColorSecondary
                        },
                        grid: {
                            color: surfaceBorder,
                            drawBorder: false
                        }
                    }

                }
            };
        }
        catch (error) { this.awsApi.loading.next(false); }
        finally { this.awsApi.loading.next(false); }
    }

    async initialiseListsAndCounts() {
        this.categoryList = [], this.productsList = [], this.productDetailsList = [], this.customersList = [], this.ordersList = []
        this.refundsList = [], this.queriesList = [], this.orderItemsList = [], this.refundOrders = [], this.topSellingProducts = []
        this.topSellingCategories = [], this.pendingOrdersList = [], this.pendingReturnRequests = [], this.buyingCustomers = []
        this.categoryNames = [], this.categoryStockSet = {}, this.labelsCategoryStockData = [], this.dataCategoryStockData = []
        this.randomColorsForCategoryStock = [], this.lastSixMonthsSalesAndReturnData = [], this.productsValue = 0, this.ordersValue = 0, this.pendingOrdersValue = 0
        this.refundOrdersValue = 0, this.totalProducts = 0, this.totalOrders = 0, this.totalPendingOrders = 0, this.totalRefundOrders = 0
        this.noOfProducts = 0, this.noOfOrders = 0, this.noOfPendingOrders = 0, this.noOfCategorries = 0, this.sales = 0, this.return = 0
    }

    onSelectDateRange() {
        if (this.dateRange.length == 2 && this.dateRange[0] != null && this.dateRange[1] != null) {
            var selectedDate1 = new Date(this.dateRange[0])
            let selectedDate2 = new Date(this.dateRange[1])
            var selectedDate2Formatted = selectedDate2.setDate(selectedDate2.getDate() + 1)
            this.filterObject['createdAt'] = { ge: formatDate(selectedDate1, 'yyyy-MM-dd', 'en-US'), le: formatDate(selectedDate2Formatted, 'yyyy-MM-dd', 'en-US') }
            this.ngOnInit()
        }
    }

    async generateRandomColors(count: number): Promise<string[]> {
        const colors: string[] = [];
        for (let i = 0; i < count; i++) {
            colors.push(this.getRandomColor());
        }
        return colors;
    }

    getRandomColor(): string {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    async getAllCategory() {
        return this.awsApi.client
            .graphql({
                query: query.listCategories,
                variables: {
                    limit: 9999,
                    nextToken: null,
                    sortDirection: null
                }
            })
    }

    async getAllMainCategory() {
        return this.awsApi.client.graphql({
            query: query.listCategories,
            variables: {
                pk: 'MAIN#',
                limit: 9999,
                nextToken: null,
                sortDirection: null
            }
        });
    }

    async getAllSubCategory(mainCategoryID) {
        return this.awsApi.client.graphql({
            query: query.listCategories,
            variables: {
                pk: 'SUB#' + mainCategoryID
            }
        });
    }

    async getAllProductDetails() {
        let filterObject = { isDeleted: { ne: true } }
        let items = [];
        let nextToken = null;
        do {
            const response = await this.awsApi.client.graphql({
                query: query.listProductDetails,
                variables: {
                    filter: filterObject,
                    limit: 650,
                    nextToken: nextToken,
                    sortDirection: null
                }
            })
            items = items.concat(response.data.listProductDetails.items);
            nextToken = response.data.listProductDetails.nextToken;
        } while (nextToken !== null)
        return items
    }

    async getAllOrders() {
        let items = [];
        let nextToken = null;
        do {
            const response = await this.awsApi.client.graphql({
                query: query.listOrders,
                variables: {
                    limit: 650,
                    nextToken: nextToken,
                    sortDirection: null
                }
            })
            items = items.concat(response.data.listOrders.items);
            nextToken = response.data.listOrders.nextToken;
        } while (nextToken !== null)
        return items
    }

    async getAllCustomers() {
        let filterObject: any = {}
        filterObject['role'] = { eq: 'CUSTOMER' }

        let items = [];
        let nextToken = null;
        do {
            const response = await this.awsApi.client.graphql({
                query: query.listUsers,
                variables: {
                    pk: 'USER#',
                    id: null,
                    filter: this.filterObject,
                    limit: 650,
                    nextToken: nextToken,
                    sortDirection: null
                }
            })
            items = items.concat(response.data.listUsers.items);
            nextToken = response.data.listUsers.nextToken;
        } while (nextToken !== null)
        return items
    }

    async getAllOrderItems() {
        let items = [];
        let nextToken = null;
        do {
            const response = await this.awsApi.client.graphql({
                query: query.listOrderItems,
                variables: {
                    limit: 650,
                    nextToken: nextToken,
                    sortDirection: null
                }
            })
            items = items.concat(response.data.listOrderItems.items);
            nextToken = response.data.listOrderItems.nextToken;
        } while (nextToken !== null)
        return items
    }

    async getOrderAndOrderItemsWithFilter() {
        try {
            let items = [];
            let nextToken = null;
            do {
                const response = await this.awsApi.client.graphql({
                    query: query.listOrders,
                    variables: {
                        filter: this.filterObject,
                        limit: 650,
                        nextToken: nextToken,
                        sortDirection: null
                    }
                })
                items = items.concat(response.data.listOrders.items);
                nextToken = response.data.listOrders.nextToken;
            } while (nextToken !== null)
            this.ordersWithFilter = items
        }
        catch (error) {
            this.awsApi.loading.next(false);
        }
        try {
            let items = [];
            let nextToken = null;
            do {
                const response = await this.awsApi.client.graphql({
                    query: query.listOrderItems,
                    variables: {
                        filter: this.filterObject,
                        limit: 650,
                        nextToken: nextToken,
                        sortDirection: null
                    }
                })
                items = items.concat(response.data.listOrderItems.items);
                nextToken = response.data.listOrderItems.nextToken;
            } while (nextToken !== null)
            this.orderItemsWithFilter = items
        } catch (error) {
            this.awsApi.loading.next(false);
        }
    }

    findReportsToShow() {
        this.reportsList = []
        for (const item of this.reports) {
            if (this.roleManagementRes.module.find((modItem) => modItem.moduleId == item.id && modItem.view)) {
                this.reportsList.push(item)
            }
        }
    }

    async startingFunction() {
        this.awsApi.loading.next(true);
        await new Promise(resolve => setTimeout(resolve, 0));
        try {
            let filterRoleManagement = {
                roleName: { eq: this.userRolesList.find((item) => item.value === localStorage.getItem('role')).text }
            };
            let filterReport = { status: { eq: Status.ACTIVE } };
            const result = await Promise.all([
                this.getAllMainCategory(),
                this.getAllProductDetails(),
                this.getAllOrders(),
                this.getAllCustomers(),
                this.common.getGeneralQueries({ enquiryStatus: { eq: EnquiryStatus.Open } }, ['subject', 'createdAt', 'email', 'enquiryStatus', 'pk', 'id']),
                this.getAllOrderItems(),
                this.common.getReportMaster(filterReport),
                this.common.listRoleManagement(filterRoleManagement, ['pk', 'id', 'module {', 'moduleId', 'menu', 'view', 'add', 'edit', 'delete', '}'])
            ]);
            this.categoryList = result[0].data.listCategories.items;
            this.productDetailsList = result[1];
            this.ordersList = result[2];
            this.customersList = result[3];
            this.queriesList = result[4].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            this.orderItemsList = result[5];
            this.reports = result[6].data.listReportMasters.items.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
            this.roleManagementRes = result[7]['data'].listRoleManagements.items[0]
            await this.findReportsToShow();
            if (this.dateRange.length === 2 && this.dateRange[0] != null && this.dateRange[1] != null) {
                await this.getOrderAndOrderItemsWithFilter();
            } else {
                this.ordersWithFilter = this.ordersList;
                this.orderItemsWithFilter = this.orderItemsList;
            }
            await this.common.updateLatestProductsInLocalStore(),
                this.productsList = JSON.parse(localStorage.getItem("products"));
            await this.processCounts();
            await this.processLists();
            await this.categoryStockChartValues();
            await this.salesAndReturnChartValues();
            this.awsApi.loading.next(false);
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: Messages.SOMETHING_WENT_WRONG
            });
        }
    }

    async processCounts() {
        // products value,orders value,pending orders value, refund orders value
        const productIds = new Set(this.productsList.map(product => product.id));
        this.productsValue = this.productDetailsList
            .filter(detail => productIds.has(detail.productID))
            .reduce((sum, detail) => sum + detail.salePrice, 0);

        this.ordersValue = this.ordersWithFilter
            .filter(order => order.status == OrderStatus.CONFIRMED || order.status == OrderStatus.PROCESSING || order.status == OrderStatus.DELIVERED)
            .reduce((sum, order) => sum + order.totalAmount, 0);

        this.pendingOrdersValue = this.ordersWithFilter
            .filter(order => order.status == OrderStatus.CONFIRMED || order.status == OrderStatus.PROCESSING)
            .reduce((sum, order) => sum + order.totalAmount, 0);

        this.refundOrdersValue = this.orderItemsWithFilter
            .filter(orderItem => orderItem.returnRefundActivity &&
                (orderItem.returnRefundActivity.status == ReturnActivityStatus.RefundApproved))
            .reduce((sum, orderItem) => sum + orderItem.returnRefundActivity?.refundInfo?.refundAmount, 0)
    }

    async processLists() {
        // products
        this.noOfProducts = this.productsList.length
        // orders
        this.noOfOrders = this.ordersWithFilter
            .filter(order => OrderStatus.CONFIRMED || order.status == OrderStatus.PROCESSING || order.status == OrderStatus.DELIVERED).length
        // pending orders
        this.noOfPendingOrders = this.ordersWithFilter
            .filter(order => order.status == OrderStatus.CONFIRMED || order.status == OrderStatus.PROCESSING).length
        // refund orders
        this.refundOrders = this.orderItemsWithFilter
            .filter(order => order.returnRefundActivity &&
                (order.returnRefundActivity.status == ReturnActivityStatus.RefundApproved)).length

        // top selling products
        const groupedOrders: { [key: string]: { productId: string; subCategoryId: String, productName: String, brand: string; totalQty: number, totalAmount: number } } = {};
        this.orderItemsWithFilter.forEach((item) => {
            if (groupedOrders[item.productId]) {
                groupedOrders[item.productId].totalQty += item.quantity;
                groupedOrders[item.productId].totalAmount += item.totalPrice;
            } else {
                groupedOrders[item.productId] = {
                    productId: item.productId,
                    subCategoryId: item.subCategoryId,
                    productName: item.itemDetail.productName,
                    brand: item.itemDetail.brand,
                    totalQty: item.quantity,
                    totalAmount: item.totalPrice
                };
            }
        });
        this.topSellingProducts = Object.values(groupedOrders).sort((a: any, b: any) => b.totalQty - a.totalQty).splice(0, 10);

        // top selling categories
        const groupedCategories: { [key: string]: { mainCategoryId: string, pk: string, slug: string, mainCategory: string; totalQty: number, totalAmount: number } } = {};
        this.orderItemsWithFilter.forEach((item) => {
            if (item.mainCategory && (item.mainCategory != null || item.mainCategory != undefined || item.mainCategory != 'undefined' || item.mainCategory != ' ' || item.mainCategory != '')) {
                if (groupedCategories[item.mainCategory]) {
                    groupedCategories[item.mainCategory].totalQty += item.quantity;
                    groupedCategories[item.mainCategory].totalAmount += item.totalPrice;
                } else {
                    groupedCategories[item.mainCategory] = {
                        mainCategoryId: item.mainCategoryId,
                        pk: 'MAIN#',
                        slug: slugify(item.mainCategory),
                        mainCategory: item.mainCategory,
                        totalQty: item.quantity,
                        totalAmount: item.totalPrice
                    };
                }
            }
        });
        this.topSellingCategories = Object.values(groupedCategories).sort((a: any, b: any) => b.totalQty - a.totalQty).splice(0, 10);

        //Pending Orders
        this.pendingOrdersList = this.ordersWithFilter
            .filter(order => (order.status == OrderStatus.PROCESSING || order.status == OrderStatus.CONFIRMED)).sort((a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

        this.pendingReturnRequests = this.orderItemsWithFilter
            .filter(orderItem => (orderItem.returnRefundActivity &&
                orderItem.returnRefundActivity.status == ReturnActivityStatus.Requested))
            .sort((a: any, b: any) => new Date(a.returnRefundActivity.createdAt).getTime() - new Date(b.returnRefundActivity.createdAt).getTime());

        // top 10 buying customers
        const customerGroupByName: { [key: string]: { userName: string; noOfOrders: number; email: String; valueOfOrders: number } } = {};
        this.ordersWithFilter.forEach((item) => {
            if (customerGroupByName[item.userName]) {
                customerGroupByName[item.userName].noOfOrders += 1,
                    customerGroupByName[item.userName].valueOfOrders += item.totalAmount
            } else {
                customerGroupByName[item.userName] = {
                    userName: item.userName,
                    noOfOrders: 0,
                    email: item.email,
                    valueOfOrders: item.totalAmount
                };
            }
        });
        this.buyingCustomers = Object.values(customerGroupByName).sort((a: any, b: any) => b.noOfOrders - a.noOfOrders).splice(0, 10);
    }

    async categoryStockChartValues() {
        // category stock
        this.categoryNames = this.categoryList
            .filter((item) => item.pk == 'MAIN#' && item.categoryType == CategoryType.MAIN)
            .map((item) => ({ id: item.id, name: item.catName }));
        const categoryStockSet: { [key: string]: number } = {};
        this.categoryNames.forEach((category) => { categoryStockSet[category.id] = 0; });
        const productAndCategory: { [key: string]: string } = {};
        this.productsList.forEach(product => {
            productAndCategory[product.id] = product.mainCategoryID;
        });
        this.productDetailsList.forEach(productDetail => {
            const category = productAndCategory[productDetail.productID];
            if (category) {
                categoryStockSet[category] += productDetail.salePrice;
            }
        });
        this.categoryStockSet = categoryStockSet;
        this.categoryNames.forEach(category => {
            this.labelsCategoryStockData.push((category['name']).slice(0, 2));
            this.dataCategoryStockData.push(this.categoryStockSet[category['id']]);
        });
    }

    async salesAndReturnChartValues() {
        this.ordersWithFilter.forEach(order => {
            if (order.status == OrderStatus.CONFIRMED || order.status == OrderStatus.PROCESSING || order.status == OrderStatus.DELIVERED) {
                this.sales += order.totalAmount
            }
        })
        this.return = this.orderItemsWithFilter
            .filter(order => order.returnRefundActivity?.refundInfo && order.returnRefundActivity.status == ReturnActivityStatus.RefundApproved)
            .reduce((sum, order) => sum + order.returnRefundActivity?.refundInfo?.refundAmount, 0);
        await this.getLastSixMonthsData()
    }

    async getValueOf(dateSubString, type) {
        if (type == 'sales') {
            return parseFloat(this.ordersWithFilter
                .filter(order => {
                    let orderMonthYear = (order.createdAt).slice(0, 7)
                    return orderMonthYear === dateSubString && order.status == OrderStatus.DELIVERED
                })
                .reduce((sum, order) => sum + order.totalAmount, 0))
        }
        else {
            return parseFloat(this.orderItemsWithFilter
                .filter(orderItem => {
                    if (orderItem.returnRefundActivity?.refundInfo && orderItem.returnRefundActivity.status == ReturnActivityStatus.RefundApproved && orderItem.returnRefundActivity.refundInfo.refundAmount!=0) {
                        let orderMonthYear = (orderItem.returnRefundActivity.createdAt).slice(0, 7)
                        return orderMonthYear === dateSubString
                    }
                })
                .reduce((sum, orderItem) => sum +
                    (orderItem.returnRefundActivity.refundInfo.refundAmount), 0))
        }
    }

    async getLastSixMonthsData() {
        let currentDate = new Date()
        let months = []
        let currentMonth = currentDate.getMonth() + 1
        let currentYear = currentDate.getFullYear()
        for (let i = 0; i < 6; i++) {
            let month = currentMonth - i
            let year = currentYear
            if (month <= 0) {
                month = 12 + month
                year = currentYear - 1
            }
            let formattedMonth = month.toString().padStart(2, '0')
            months.push({
                'date': `${year}-${formattedMonth}`,
                'dateAbbr': this.convertDateFormat(`${year}-${formattedMonth}`),
                'salesValue': await this.getValueOf(`${year}-${formattedMonth}`, 'sales'),
                'returnValue': await this.getValueOf(`${year}-${formattedMonth}`, 'return')
            })
        }
        this.lastSixMonthsSalesAndReturnData = months
    }

    convertDateFormat(input: string): string {
        const months = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        let [yearStr, monthStr] = input.split('-');
        let monthIndex = parseInt(monthStr) - 1;
        let monthName = months[monthIndex];
        let formattedDate = `${monthName} ${yearStr.slice(2)}`;
        return formattedDate;
    }

    redirectToCustomerQueries() {
        this.router.navigate(['general-queries']);
    }

    redirectToOrderDetailsPage(orderId: any) {
        this.router.navigate([ROUTES.ORDER_DETAILS], { queryParams: { orderId: orderId } });
    }

    redirectToProductDetails(product) {
        this.router.navigate([`edit-product`], { queryParams: { id: product.productId, pk: 'PROD#' + product.subCategoryId, type: 'edit' } });
    }

    redirectToCategory(category) {
        if (this.reportsList.find((item) => item.sequenceNumber === ReportsUrl[1].sequenceNumber)) {
            this.common.setCategoryFilterValue(category.mainCategoryId)
            this.router.navigate([ReportsUrl[1].redirectTo], { queryParams: { view: true } });
        }
    }

    redirectToReturnItemDetails(returnItem) {
        this.router.navigate([ROUTES.RETURN_ITEM_DETAILS], { queryParams: { orderId: returnItem.pk.split('#')[1], orderItemId: returnItem.id } });
    }

    redirectToCustomers(customerDetail) {
        this.common.setOrdersFilterValue(customerDetail.email)
        this.router.navigate([`order-list`]);
    }
    

    redirectToGeneralQueries(generalQuery) {
        this.router.navigate([ROUTES.VIEW_QUERY], { queryParams: { type: generalQuery.pk, queryId: generalQuery.id } });
    }
}